import type {
  IBaseItem,
  Timestamp,
  UUID,
} from '@shiftsmartinc/shiftsmart-types';

import { autorun, action, observable, computed, set } from 'mobx';
import _ from 'lodash';

import { getChildLogger, SSMLogger } from '#/shared/utils/client.logger';
import { StoreName } from '#/shared/stores';
import { getStore } from '#/shared/getStores';

declare type CacheGetterProps<T extends IBaseItem> = {
  fields: Array<keyof T>;
  store: StoreName;
};
export default class CacheStore {
  constructor() {
    autorun((reaction) => {
      this.log.silly(
        `5e3856bd-aa59-4839-a489-934f6a34ade4] ${
          _.keys(this.general).length
        } Entries. Reaction Produced: `,
        {
          displayName: _.get(
            this.general,
            '[5e3856bd-aa59-4839-a489-934f6a34ade4].displayName',
          ),
        },
        reaction,
      );
    });
  }

  @observable general: Record<
    UUID,
    Promise<IBaseItem> & {
      $ssm: {
        accessedAt?: Timestamp;
        cachedAt?: Timestamp;
        fields: Array<string>;
        store: StoreName;
      };
    }
  > = {};

  @action
  async get<T extends IBaseItem>(id, props: CacheGetterProps<T>) {
    if (!id) {
      return null;
    }

    let store = props.store;
    const fields = props.fields;

    if (!store) {
      throw new Error('No Store specified');
    }

    if (store === 'reviews') {
      return null;
    }

    if (store === 'partners') {
      store = 'workers';
    }

    let p = this.general[id];

    const existingFields = _.get(this.general[id], '$ssm.fields');
    const missingFields = _(fields)
      .reject((field) => _.includes(existingFields, field))
      .value();

    if (
      _.isEmpty(this.general[id]?.$ssm) ||
      store !== _.get(this.general[id], '$ssm.store') ||
      _.size(missingFields)
    ) {
      this.log.debug(`looking up : ${store}.get`, id);

      p = this.loadAndCacheValue(id, {
        fields,
        store,
      });

      if (_.size(existingFields) && _.size(missingFields)) {
        delete this.general[id];
      }
    } else {
      this.log.silly(`returning from cache: ${store}.get`, id);
    }

    if (this.general[id]?.$ssm) {
      _.merge(this.general[id].__proto__, {
        $ssm: {
          accessedAt: Date.now(),
        },
      });
    } else {
      _.extend(p.__proto__, {
        $ssm: {
          accessedAt: Date.now(),
          fields: fields
            ? _.union(fields, _.get(this.general[id], '$ssm.fields'))
            : _.get(this.general[id], '$ssm.fields'),
          store: store || _.get(this.general[id], '$ssm.store'),
        },
      });

      this.general[id] = p;
    }

    return this.general[id];
  }

  @action
  async set(id: UUID, value, { store, fields } = {}) {
    const existingPromise = this.general[id];
    const existingValue = !!existingPromise && (await existingPromise);

    this.log.debug(
      `Setting Cache Value for UUID ${id}`,
      {
        title:
          _.get(value, 'title') ||
          _.get(value, 'displayName') ||
          _.get(value, 'uuid'),
      },
      value,
    );

    const newVal = _.extend(Promise.resolve(_.extend(existingValue, value)), {
      $ssm: {
        cachedAt: Date.now(),
        fields: fields
          ? _.union(fields, _.get(this.general[id], '$ssm.fields'))
          : _.get(this.general[id], '$ssm.fields'),
        store: store || _.get(this.general[id], '$ssm.store'),
      },
    });

    set(this.general, id, newVal);

    this.log.debug(
      `Done Setting Cache Value for UUID ${id}`,
      { title: _.get(this.general[id], 'title') },
      this.general[id],
    );
  }

  @action
  async loadAndCacheValue(id, { store, fields }) {
    let obj;

    if (!id) {
      return null;
    }

    try {
      const value = await getStore(store).get(id, { select: false });

      obj = _.cloneDeep(
        _.isEmpty(fields) ? value : _.pick(value, _.union(fields, ['uuid'])),
      );
    } catch (err) {
      this.log.error(`Failed to lookup object. Clearing cache entry`, {
        error: err,
        id,
        store,
      });
      delete this.general[id];

      throw err;
    }

    _.merge(this.general[id].__proto__, {
      $ssm: {
        cachedAt: Date.now(),
        fields: fields
          ? _.union(fields, _.get(this.general[id], '$ssm.fields'))
          : _.get(this.general[id], '$ssm.fields'),
        store: store || _.get(this.general[id], '$ssm.store'),
      },
    });

    return obj;
  }

  _log: SSMLogger;

  @computed
  get log() {
    if (_.isEmpty(this._log) || !_.isFunction(this._log.debug)) {
      this._log = getChildLogger(`stores.cache`);
    }

    return this._log;
  }

  set log(val) {
    this._log = val;
  }

  @action
  clear() {
    this.general = {};
  }
}
