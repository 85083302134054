import type { ICampaign, IQuotaDef } from '@shiftsmartinc/shiftsmart-types';

import { observable, action, set, runInAction } from 'mobx';
import _ from 'lodash';
import Promise from 'bluebird';

import { getQuotaKey } from '#/shared/components/campaigns/CampaignQuotas';
import { getStores } from '#/shared/getStores';

import BaseUploader from './BaseUploader';

type QuotaRow = IQuotaDef & {
  err?: string[];
};

export default class CampaignQuotaUploader extends BaseUploader {
  constructor() {
    super({ title: 'ui.campaignQuotaUploader' });

    set(this.saveResults, {
      dupe: [],
      invalid: [],
      new: [],
      unassigned: [],
    });

    return this;
  }

  @observable parseOptions = {
    dynamicTyping: false,
    header: false,
    skipEmptyLines: true,
  };

  @observable
  campaign: Partial<ICampaign> = {};

  @observable
  storeList = {};

  /** foundValues
   * An object, keyed by fields present in the campaign quota format.
   *
   * Each key contains an array of unique values found in the imported
   * file mapping to that field.
   *
   * eg:
   * {
   *   survey: ['WifiOctoberNighthawk', 'WifiOctoberOrbi']
   * }
   *
   * This is used to match up found-values with values present in the
   * campaign configuration, enabling display of missing or invalid
   * values after parsing the file. This allows the importing user to
   * see if any surveys have been imported that don't match the campaign
   * configuration.
   */
  @observable
  foundValues = {};

  @observable
  campaignValues = {};

  @action.bound
  setup(...args) {
    return super.setup(...args);
  }

  @action.bound
  clear() {
    super.clear();

    this.saveResults = {
      dupe: [],
      invalid: [],
      new: [],
      unassigned: [],
    };
  }

  @action.bound
  async processFile({
    campaign,
    storeList,
    options = this.parseOptions,
    ...rest
  }) {
    this.campaign = campaign;
    this.storeList = storeList;

    this.setStatus('loading');

    try {
      this.parseDataFromStoreList();
      this.parseExistingSurveyKeys();

      await super.processFile({ options, ...rest });

      this.setStatus('loaded');
    } catch (err) {
      this.log.error('Failed to process file', err);

      this.setStatus('error');

      throw err;
    }
  }

  knownHeaders = [
    'survey',
    'retailer',
    'persona',
    'region',
    'quota',
    'pay',
    'storeId',
  ];

  @action.bound
  async parse({ rows }) {
    const {
      ui: { loadingModal },
    } = getStores();

    loadingModal.open({
      message: 'Parsing Quotas',
      total: rows.length,
    });

    const casedHeaders = _.map(rows[0], _.toLower);
    const indices = _.reduce(
      this.knownHeaders,
      (acc, header) => {
        // EP-1136 Confirm this change is wanted & doesn't break anything
        acc[header] = _.indexOf(casedHeaders, _.toLower(header));
        return acc;
      },
      {},
    );

    this.log.debug('Loaded Table Indices', { indices });

    // const storeListUUID = _.get(this.campaign, 'storeList.uuid');

    // const storeList = await dispatch('storeLists.get', storeListUUID, {
    //   query: { $client: { loadStores: true } }
    // });

    const { surveys } = this.campaign;

    await new Promise((resolve) => setTimeout(resolve, 500));

    const campaignValues = {};
    const validRows = (rows ?? [])
      .map((row, i) => {
        // this.setStatus(`Parsing Row ${i} of ${rows.length}`);
        setTimeout(() => loadingModal.increment({}), 0);

        if (i === 0) return false;

        if (_.isEmpty(row[indices.quota])) {
          this.log.debug('no quota for row', { row });
          return false;
        }

        const quota: QuotaRow = {
          assignedCount: 0,
          completedCount: 0,
          persona: row[indices.persona] || undefined,
          region: row[indices.region] || undefined,
          retailer: row[indices.retailer] || undefined,
          storeId: row[indices.storeId] || undefined,
          survey: row[indices.survey] || undefined,
        };

        quota.level = _(quota).omitBy(_.isEmpty).keys().size() - 1;

        _.extend(quota, {
          count: _.toNumber(row[indices.quota]),
          key: getQuotaKey(quota),
          pay:
            _.toNumber(row[indices.pay]) ||
            _.get(this.campaign, 'payRate', null),
        });

        const survey = _.find(
          surveys,
          _.omitBy(
            {
              productTitle: quota.product,
              surveyTitle: quota.survey,
            },
            _.isUndefined,
          ),
        );

        const matchedVals = {};

        if (survey) {
          _.extend(matchedVals, {
            productTitle: _.get(survey, 'productTitle'),
            productUUID: _.get(survey, 'productUUID'),
            surveyTitle: _.get(survey, 'surveyTitle'),
            surveyUUID: _.get(survey, 'surveyUUID'),
          });

          const persona = _.find(survey.personas, { title: quota.persona });

          if (persona) {
            _.extend(matchedVals, {
              personaTitle: _.get(persona, 'title'),
              personaUUID: _.get(persona, 'uuid'),
            });
          } else if (!persona && !_.isEmpty(quota.persona)) {
            quota.err = quota.err || [];
            quota.err.push('Unknown Persona');
          }

          _.each(matchedVals, (val, key) => {
            if (val && _.isString(val)) {
              if (!campaignValues[key]) {
                campaignValues[key] = new Set();
              }

              campaignValues[key].add(val);
            }
          });

          if (_.size(survey.personas)) {
            if (!campaignValues.persona) {
              campaignValues.persona = new Set();
            }

            _.each(survey.personas, ({ title }) =>
              campaignValues.persona.add(title),
            );
          }
        } else {
          quota.err = quota.err || [];
          quota.err.push('Unknown Survey');
        }

        if (
          quota.retailer &&
          !_.includes(this.campaignValues.retailer, quota.retailer)
        ) {
          quota.err = quota.err || [];
          quota.err.push('Unknown Retailer');
        }

        if (
          quota.region &&
          !_.includes(this.campaignValues.region, quota.region)
        ) {
          quota.err = quota.err || [];
          quota.err.push('Unknown Region');
        }

        _.extend(quota, matchedVals);

        /**
         * TODO: EP-858 Ensure that all defined quota definitions are found in the campaign
         * definition. If a specific quota-param is missing (eg: can't match survey
         * title to a survey UUID), add the quota to an error queue and log it
         */

        if (/active/i.test(this.campaign.status)) {
          const existingQuota = _.get(this.campaign, 'quotas', []).find((q) => {
            const comparisonKey = JSON.stringify(
              _.omit(JSON.parse(q.key), 'level'),
            );
            return comparisonKey === quota.key;
          });

          if (existingQuota) {
            const countChanged = existingQuota.count !== quota.count;
            const payChanged = existingQuota.pay !== quota.pay;
            let note = 'This quota already exists.';
            if (countChanged) {
              note += ' Quota count has changed.';
            }
            if (payChanged) {
              note += ' Quota pay has changed.';
            }
            runInAction(() =>
              _.extend(existingQuota, {
                count: quota.count,
                countChanged,
                existing: true,
                note,
                pay: quota.pay,
                payChanged,
              }),
            );
            return existingQuota;
          }
          quota.note =
            'This is a new quota, and will be added to the campaign config';
        }

        return quota;
      })
      .filter((x) => !!x);

    this.log.silly('Valid Rows', { validRows });

    this.setStatus('Evaluating');
    loadingModal.open({
      message: 'Evaluating Quotas',
      total: loadingModal.total,
    });

    const reduced = _.reduce(
      validRows,
      (acc, row) => {
        _.each(row, (val, key) => {
          if (val && _.isString(val)) {
            if (!acc[key]) {
              acc[key] = new Set();
            }

            acc[key].add(val);
          }
        });

        return acc;
      },
      {},
    );

    /** Sample Quota Def
     *
     * The result of the map operation above should be an array of objects that look
     * something like the following:
     *
     *  {
     *     "key" : "{\"Persona\":\"Small home-Value Focused\",\"Retailer\":\"Best Buy\",\"Survey\":\"WifiOctoberNighthawk\",\"level\":2}",
     *     "persona" : "Small home-Value Focused",
     *     "retailer" : "Best Buy",
     *     "survey" : "WifiOctoberNighthawk",
     *     "level" : 2,
     *     "count" : 30,
     *     "pay" : 14,
     *     "personaUUID" : "e1cedd49-e741-450e-b82e-5ded0b626772",
     *     "surveyUUID" : "339fcd21-bb38-43ad-a9cd-a66e59b46b57",
     *     "surveyTitle" : "WifiOctoberNighthawk",
     *     "productUUID" : "1da4213b-cca5-4e91-a58c-0e01af4183e8",
     *     "productTitle" : "Google Wifi",
     *     "assignedCount" : 11,
     *     "completedCount" : 34
     * },
     */

    // this.log.debug('Found %d valid rows', validRows.length, { validRows });
    // eslint-disable-next-line no-console
    console.table(validRows);

    runInAction(() => {
      this.list.replace(validRows);
      this.foundValues = _.mapValues(reduced, (vals) => Array.from(vals));
      _.extend(
        this.campaignValues,
        _.mapValues(campaignValues, (vals, key) =>
          _.union(Array.from(vals), this.campaignValues[key]),
        ),
      );

      this.campaignValues.survey = this.campaignValues.surveyTitle;
    });

    loadingModal.close({
      delay: 1000,
      message: `Parsed ${validRows.length} Quotas`,
    });
    this.setStatus('Parsed all Rows');
  }

  @action.bound
  parseExistingSurveyKeys() {
    this.campaignValues.key = _(this.campaign.quotas).map('key').uniq().value();
  }

  parseDataFromStoreList() {
    const unique =
      this.storeList.tuples ||
      _.uniqBy(this.storeList.stores, (s) => [s.retailer, s.region].join('.'));

    const campaignValues = {};

    _.each(unique, ({ retailer, region }) => {
      _.each({ region, retailer }, (val, key) => {
        if (val && _.isString(val)) {
          if (!campaignValues[key]) {
            campaignValues[key] = new Set();
          }

          campaignValues[key].add(val);
        }
      });
    });

    runInAction(() => {
      this.campaignValues.retailer = _.union(
        Array.from(campaignValues.retailer || []),
        this.campaignValues.retailer || [],
      );
      this.campaignValues.region = _.union(
        Array.from(campaignValues.region || []),
        this.campaignValues.region || [],
      );
    });

    this.log.debug('Retailer & Region', {
      rr: _.pick(this.campaignValues, ['retailer', 'region']),
    });
  }

  @action.bound
  async save() {
    this.setStatus('saving');
    this.setSaving(true);

    const {
      campaigns,
      ui: { snackBar },
    } = getStores();

    const quotas = _.filter(this.list, (quota) => _.isEmpty(quota.err));

    const errCt = this.list.length - quotas.length;

    let campaign: ICampaign;
    try {
      const data = {
        quotas,
      };

      const chunks = _.chunk(quotas, 500);

      if (chunks.length > 1) {
        await campaigns.update({
          data: {
            quotas: [],
          },
          id: this.campaign.uuid,
        });

        await Promise.mapSeries(chunks, (chunk) => {
          return campaigns.update({
            data: {
              $push: {
                quotas: {
                  $each: chunk,
                },
              },
            },
            id: this.campaign.uuid,
          });
        });

        campaign = await campaigns.get(this.campaign.uuid);
      } else {
        campaign = await campaigns.update({
          data,
          id: this.campaign.uuid,
        });
      }
      snackBar.open(`Saved ${campaign.quotas.length} quotas to campaign`, {
        body: errCt ? `${errCt} quotas were ignored` : undefined,
        duration: 10000,
      });

      this.setStatus('saved');
    } catch (err) {
      this.log.error('Failed to save quotas to campaign', { error: err });

      snackBar.error('Sorry, something went wrong');
      this.setStatus('error');
    }

    this.setSaving(false);
  }
}
