import type { IInShiftTaskResult } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export default class InShiftTaskResultsStore extends BaseStore<IInShiftTaskResult> {
  constructor() {
    super({
      baseItem: InShiftTaskResultsStore.BASE_ITEM,
      serviceName: 'inShiftTaskResults',
    });

    return this;
  }

  static BASE_ITEM: IInShiftTaskResult = {
    companyId: null,
    createdAt: null,
    end: null,
    escalation: null,
    inShiftTaskId: null,
    inputValues: null,
    inputValuesCompleted: null,
    isDeleted: null,
    modelName: null,
    postImage: null,
    preImage: null,
    shiftId: null,
    start: null,
    updatedAt: null,
    userId: null,
    uuid: null,
  };
}
