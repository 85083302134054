import { action, observable, computed, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { service } from '#/shared/app';
import PartnersStore from '#/shared/stores/partners';

export default class PoolMembersStore extends PartnersStore {
  constructor({ title = 'poolMembers', pool } = {}) {
    super({ title: _.compact([title, _.get(pool, 'uuid', pool)]).join('.') });

    this.log.debug('Created new "PoolMembersStore"');

    if (!_.isEmpty(pool)) {
      this.selectPool({ pool }, { noQuery: true });
    }

    return this;
  }

  initEvents() {
    super.initEvents();
    service('pools').on('userAdded', action(this.onUserAdded));
    service('pools').on('userRemoved', action(this.onUserRemoved));
    service('pools').on('usersActionFinished', action(this.onUsersAddFinished));
  }

  poolMemberFields = [
    'id',
    'uuid',
    'displayName',
    'firstName',
    'lastName',
    'profileImageURL',
    'email',
    'phoneNumber',
    'rating',
    'adherence',
    'companyStatus',
    'certs',
  ];

  @observable
  selectedPool = {};

  /** used for event listeners in poolMembers in order to avoid multiple pushes/renders */
  @observable
  isUpdatingPoolFilterCriteria = false;

  @computed
  get selectedPoolId() {
    return _.get(this.selectedPool, 'uuid', null);
  }

  @action
  async selectPool({ pool, query = {} } = {}, opts = {}) {
    const selectedPool =
      _.isString(pool) && !_.isEmpty(pool)
        ? await dispatch('pools.get', pool, { select: false })
        : pool;

    runInAction(() => {
      this.selectedPool = selectedPool;
    });

    if (!this.selectedPoolId) {
      _.set(this.query.query, '[pools.uuid]', null);
      return this.emptyList();
    }
    return this.findByCompany({
      company: selectedPool.company,
      opts: { ...opts, clear: true },
      query: {
        'pools.uuid': this.selectedPoolId,
        ...query,
      },
    });
  }

  @action
  setIsUpdatingPoolFilterCriteria(val = true) {
    this.log.debug(
      'PoolFilterCriteria update is pre: %s => %s',
      this.isUpdatingPoolFilterCriteria,
      val,
    );
    this.isUpdatingPoolFilterCriteria = val;
    this.log.debug(
      'PoolFilterCriteria update post: %s ?= %s',
      this.isUpdatingPoolFilterCriteria,
      val,
    );
  }

  async find(query, opts = {}) {
    // eslint-disable-next-line no-param-reassign
    opts.hooks = [
      ...(opts.hooks || []),
      () => {
        if (!this.selectedPoolId) {
          this.log.debug(
            'find method cannot be called without a selected pool ID',
          );
          this.emptyList();
          throw new Error(
            'PoolMembers.find must be called with a selected pool iD',
          );
        }
      },
    ];

    _.extend(query, {
      $limit: 20,
      $select: this.poolMemberFields,
      displayName: { $ne: null },
    });

    return super.find(query, opts);
  }

  @action
  updateList(json) {
    super.updateList(json);

    this.log.debug('Updated Pool Members List', {
      list: this.list,
      pagination: this.$pagination,
    });

    return this.list;
  }

  @action
  resetSearch({ preserve = [] } = {}) {
    this.pools.clear();

    return super.resetSearch({ preserve: _.union(preserve, ['companies']) });
  }

  @action
  checkPoolMatch(poolId) {
    if (!this.selectedPoolId) {
      return false;
    }
    if (poolId !== this.selectedPoolId) {
      return false;
    }
    return true;
  }

  /** EVENTS */
  onUserAdded = async (data) => {
    if (this.isUpdatingPoolFilterCriteria) {
      return;
    }
    if (_.isEmpty(data)) {
      return;
    }
    const { pool, workerId } = data;
    if (!this.checkPoolMatch(pool)) {
      return;
    }

    const workerObj = await this.get(workerId, {
      query: { $select: this.poolMemberFields },
      select: false,
    });

    this.pushItem(workerObj);
  };

  onUserRemoved = (data) => {
    if (this.isUpdatingPoolFilterCriteria) {
      return;
    }
    if (_.isEmpty(data)) {
      return;
    }

    const { pool, workerId } = data;

    if (!this.checkPoolMatch(pool)) {
      return;
    }

    this.removeItem(workerId);
  };

  onUsersAddFinished = async (data) => {
    if (!this.isUpdatingPoolFilterCriteria) {
      return;
    }
    if (_.isEmpty(data)) {
      return;
    }
    const { pool, eventAction } = data;
    if (!this.checkPoolMatch(pool)) {
      return;
    }

    if (eventAction === 'add') {
      await this.find({ 'pools.uuid': pool.uuid }, { smart: false });
    }
    dispatch('ui.poolDetails.setLoading', false);
    this.setIsUpdatingPoolFilterCriteria(false);
  };
}
