import React from 'react';
import { observer } from 'mobx-react';
import { Loader, Progress, Dimmer } from 'semantic-ui-react';
import _ from 'lodash';

import { useStore } from '#/shared/hooks/useStores';
import Modal from '#/ssm-ui/Modal';

const LoadingModal = observer(() => {
  const { isOpen, message, total, complete, close, current } =
    useStore('ui.loadingModal');

  return (
    <Modal
      dimmer="blurring"
      onClose={() => {
        if (!!total && complete >= total) {
          close();
        }
      }}
      open={isOpen}
      size="small"
      style={{
        backgroundColor: '#FFFFFFCC',
        zIndex: 2000,
      }}
    >
      <Modal.Content className="h5 flex items-center">
        {total && _.isNumber(total) ? (
          <div className="pt4">
            <Progress
              autoSuccess={true}
              indicating={complete < total}
              label={`${message || 'Loading'}`}
              size="large"
              total={total}
              value={complete}
            />
            {_.isNumber(total) && total > 10 && (
              <div className="w-100 tc pt3">
                {complete}/{total}
              </div>
            )}
          </div>
        ) : (
          <Dimmer active={true}>
            <Loader content={message || 'Loading'} size="huge" />
          </Dimmer>
        )}
        {current && <div className="w-100 tc pa3 b">{current}</div>}
      </Modal.Content>
    </Modal>
  );
});

export default LoadingModal;
