import type {
  ICampaign,
  IStatusFilterable,
} from '@shiftsmartinc/shiftsmart-types';

import { set, observable, action } from 'mobx';
import _ from 'lodash';
import request from 'superagent-bluebird-promise';
import { dispatch } from 'rfx-core';
import { Query } from '@feathersjs/feathers';

import { app } from '#/shared/app';
import { getChildLogger } from '#/shared/utils/client.logger';

import BaseStore from './_baseStore';

const SERVICE = 'campaigns';
const log = getChildLogger(`${SERVICE}.store`);

export default class CampaignStore
  extends BaseStore<ICampaign>
  implements IStatusFilterable<ICampaign>
{
  constructor() {
    const baseItem = {
      actionLog: [],
      allowedCompletionDOWs: [],
      allowedCompletionEndTime: null,
      allowedCompletionStartTime: null,
      autoApproveShops: false,
      campaignRules: null,
      company: null,
      completedAt: null,
      createdAt: null,
      enableSurveyCheckout: false,
      end: null,
      expireShops: false,
      externalLink: null,
      guidelinesURL: null,
      helpCenterLink: null,
      isDeleted: false,
      notes: null,
      notificationPrefs: [],
      payRate: null,
      quotas: [],
      requireCheckin: false,
      requireImage: false,
      start: null,
      startShopTime: null,
      startShopTimeBusinessDaysOnly: false,
      stateStoreCounts: [],
      status: null,
      storeList: null,
      /** Note: Pavan [2018-08-24]
       * These fields aren't in the model
       * Can't remember what they do, but leaving
       * in here when moving to use BaseStore
       */
      surveyTasks: [],

      surveys: [],

      tags: null,

      title: null,

      updatedAt: null,

      uuid: null,
    };
    super({ baseItem, searchFields: ['title'], serviceName: 'campaigns' });
    set(this.selected, _.clone(this.baseItem));

    return this;
  }

  /* ACTIONS */

  @action
  filterBy({ filter, param = 'status' } = {}, opts) {
    const query: Query = {};

    if (param === 'status') {
      _.extend(query, this.setStatusFilter({ filter, opts }));
    } else {
      this.filter[param] = filter;
    }

    return this.find({ query }, opts);
  }

  @action
  setStatusFilter({ filter, opts }) {
    const { setDefault, checked, exclusive, all } = opts ?? {};

    let statusFilter = setDefault
      ? [...this.defaultStatuses]
      : [...this.filter.status];

    if (!setDefault) {
      if (all || /all/i.test(filter)) {
        statusFilter = ['all'];
      } else if (checked) {
        if (exclusive) {
          statusFilter = [filter];
        } else {
          statusFilter.push(filter);
        }
      } else {
        if (statusFilter.includes('all')) {
          statusFilter = this.allStatuses;
        }

        _.remove(statusFilter, filter);
      }
    }

    statusFilter = _.uniq(statusFilter);

    if (_(statusFilter).xor(this.allStatuses).isEmpty()) {
      statusFilter = ['all'];
    }

    this.filter.status.replace(statusFilter);

    const queryStatuses = _(statusFilter)
      .uniq()
      .reduce((a, status) => {
        if (/closed/.test(status)) {
          a.push(...['Canceled', 'Completed', 'Closed']);
        } else if (/pending|draft/i.test(status)) {
          a.push(...['Draft', 'Pending']);
        } else {
          a.push(status);
        }
        return a;
      }, []);

    return {
      status:
        _.isEmpty(this.filter.status) || _.includes(this.filter.status, 'all')
          ? undefined
          : { $in: queryStatuses },
    };
  }

  filter = observable({ status: observable.array(['Active']) });

  get allStatuses() {
    return ['pending', 'Active', 'closed'];
  }
  get defaultStatuses() {
    return ['Active'];
  }

  @action
  async buildReport({ campaigns }) {
    // Using REST method here to load large amount of data
    // Bypassing the timeout set for sockets
    const accessToken = await app().authentication.getAccessToken();
    const uri = dispatch('app.getServerURI');
    return request
      .get(`${uri}/campaigns`)
      .set('Authorization', accessToken)
      .query({ buildReport: true, campaigns })
      .catch((err) => {
        log.error('Error Getting Campaign Shop Status Report', err);

        throw err;
      });
  }

  @action
  exportData(campaignId) {
    const query = {
      runExport: true,
    };
    return this.get(campaignId, { query, reload: true })
      .then((response) => {
        log.debug('Response: ', response);
      })
      .catch((error) => {
        log.error('Error Exporting Campaign Data: ', error, {
          extra: {
            campaignId,
          },
        });
      });
  }

  async loadAvailablePropsForCampaigns({ query }) {
    const pipeline = [
      { $match: query },
      { $project: { storeList: '$storeList.uuid', survey: '$surveys' } },
      { $unwind: '$survey' },
      { $unwind: '$survey.personas' },
      {
        $project: {
          personaTitle: '$survey.personas.title',
          personaUUID: '$survey.personas.uuid',
          productTitle: '$survey.productTitle',
          productUUID: '$survey.productUUID',
          storeList: '$storeList',
          surveyTitle: '$survey.surveyTitle',
          surveyUUID: '$survey.surveyUUID',
        },
      },
    ];

    try {
      const results = await this.runQuery({ _aggregate: pipeline });
      this.log.debug(
        '[loadAvailablePropsForCampaigns] Loaded Props for query',
        { pipeline, query, results },
      );

      return results;
    } catch (err) {
      this.log.error(
        '[loadAvailablePropsForCampaigns] Failed to load Props for specified campaign query',
        err,
        { extra: { query } },
      );
    }
    return [];
  }
}
