import type {
  IRecurringSchedule,
  IShiftTemplate,
} from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import BluebirdPromise from 'bluebird';

import ShiftUploader from '#/shared/stores/ui/ShiftUploader';

export default class RecurringScheduleUploader extends ShiftUploader {
  constructor() {
    super({ title: 'ui.recurringScheduleUploader' });

    this.storeName = 'shiftTemplates';

    this.additionalKnownFields = [
      {
        key: 'startDayOffset',
        note:
          'The offset of the start day in the cycle\n' +
          'the offset is between 0 and the cycleDays number from a recurring schedule',
        transform: _.toNumber,
      },
      {
        key: 'endDayOffset',
        note:
          'The offset of the end day in the cycle\n' +
          'the offset is between 0 and the cycleDays number from a recurring schedule',
        transform: _.toNumber,
      },
      {
        key: 'hourStart',
        note: '00-24',
      },
      {
        key: 'hourEnd',
        note: '00-24',
      },
      {
        exampleVal: '00',
        key: 'minutesStart',
      },
      {
        exampleVal: '00',
        key: 'minutesEnd',
      },
    ];

    this.excludedFields = [
      'startTime',
      'endTime',
      'workingHoursStart',
      'workingHoursEnd',
    ];

    return this;
  }

  @observable recurringSchedule: IRecurringSchedule | null = null;

  @observable saveButtonText: 'Save' | 'Next' = 'Next';

  @observable saveConfirmMessage = '';

  @observable shouldCloseModal = false;

  @observable isLoading = false;

  @observable source: 'importTemplates' | 'scheduleUploader' = null;

  @action closeShiftTemplatesImportModal = () => {
    this.shouldCloseModal = true;
  };

  @action onCloseShiftTemplatesImportModal = () => {
    this.shouldCloseModal = false;
  };

  @action setExistingRecurringSchedule = async (
    recurringSchedule: IRecurringSchedule | null,
  ) => {
    this.recurringSchedule = recurringSchedule;
    this.saveButtonText = recurringSchedule ? 'Save' : 'Next';
  };

  @action
  async setup(opts, ...rest) {
    super.setup(opts, ...rest);

    if (!_.isEmpty(opts.source)) {
      this.source = opts.source;
    }

    // in the case of 'importTemplates' the clear happened already when the modal was initialized
    // we have to skip it because we already set the existing schedule
    // this logic is needed to keep the state of the uploader after submission to be able to show upload info
    if (opts.source !== 'importTemplates') {
      this.clear();
    }

    this.company = opts.company;

    if (_.isString(this.company)) {
      const company = await dispatch('companies.get', this.company, {
        select: false,
      });
      runInAction(() => {
        this.company = company;
      });
    }
  }

  @action
  async clear() {
    super.clear();
    this.recurringSchedule = null;
    this.saveButtonText = 'Next';
    this.shouldCloseModal = false;
    this.isLoading = false;
  }

  @action async save() {
    const log = this.log.getChildLogger('save');
    if (!this.recurringSchedule) {
      // case we also need to create a schedule
      dispatch('ui.recurringSchedules.set', 'openModal', true);
    } else {
      // case add/edit templates for existing schedule
      try {
        await this.saveTemplates();
        this.setExistingRecurringSchedule(null);
        this.closeShiftTemplatesImportModal();
        this.clear();
        dispatch('ui.snackBar.open', 'Successfuly uploaded shift templates');
      } catch (error) {
        log.error('Failed to save shift templates', error);
        dispatch('ui.snackBar.error', 'Failed to upload shift templates', {
          body: error.message,
        });
      }
    }
    return Promise.resolve();
  }

  @action async saveTemplates() {
    if (!this.recurringSchedule) {
      dispatch(
        'ui.snackBar.error',
        `Can't import templates for a non-existent recurring schedule`,
      );
      return;
    }

    const { validTemplates, invalidTemplates } =
      await this.validateShiftTemplates(_.cloneDeep(this.list));

    // processing templates to remove shift only fields and adding extra required fields
    const processedTemplates = validTemplates.map((t) => ({
      ..._.omit(t, 'start', 'end', 'duration'),
      companyId: this.recurringSchedule.companyId,
      recurringScheduleId: this.recurringSchedule.uuid,
      status: 'Active',
    }));

    runInAction(() => {
      this.saveResults.invalid.push(...invalidTemplates);
      this.list.replace(processedTemplates);
    });

    await super.save();
  }

  async validateShiftTemplates(templates: IShiftTemplate[]) {
    const log = this.log.getChildLogger('validateShiftTemplates');
    const invalidTemplates: (IShiftTemplate & { err: { message: string } })[] =
      [];
    const validTemplates = await BluebirdPromise.map(templates, (t) => {
      try {
        // no updates in the schedule Uploader - accepted only when importing templates
        if (this.source === 'scheduleUploader' && !_.isEmpty(t.uuid)) {
          throw new Error(`You can't edit shift templates in the uploader`);
        }

        // 0 <= minuteStart <= 59
        if (t.minutesStart < 0 || t.minutesStart > 59) {
          throw new Error('minutesStart must be between 0 and 59');
        }
        // - 0 <= minuteEnd <= 59
        if (t.minutesEnd < 0 || t.minutesEnd > 59) {
          throw new Error('minutesEnd must be between 0 and 59');
        }

        // 0 <= hourStart <= 24
        if (t.hourStart < 0 || t.hourStart > 24) {
          throw new Error('hourStart must be between 0 and 24');
        }
        // 0 <= hourEnd <= 24
        if (t.hourEnd < 0 || t.hourEnd > 24) {
          throw new Error('hourEnd must be between 0 and 24');
        }

        // startDayOffset > 0
        if (!t.startDayOffset || t.startDayOffset < 1) {
          throw new Error(
            'startDayOffset is required and must be greater than 0',
          );
        }

        // !endDayOffset || endDayOffset >= startDayOffset
        if (t.endDayOffset && t.endDayOffset < t.startDayOffset) {
          throw new Error(
            'endDayOffset must be greater than or equal with startDayOffset',
          );
        }
      } catch (error) {
        log.error('Invalid Shift Template', { error, t });
        invalidTemplates.push(
          _.extend(t, {
            err: {
              message: error.message,
            },
          }),
        );
        return null;
      }
      return t;
    });
    return {
      invalidTemplates,
      validTemplates: _.compact(validTemplates),
    };
  }
}
