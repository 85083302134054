import { observable, action } from 'mobx';

export default class Auth {
  @observable
  showSection = 'signin';

  @observable
  passwordExpirationBannerHidden = false;

  @observable
  ssoLoginFailed = false;

  @action
  closePasswordExpirationBanner() {
    this.passwordExpirationBannerHidden = true;
  }

  @action
  toggleSection(to = 'signin') {
    const validTos = ['signin', 'signup', 'otp'];
    if (validTos.includes(to)) this.showSection = to;
  }

  @action
  setSsoFailed(value = true) {
    this.ssoLoginFailed = value;
  }
}
