import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import { Search, Input } from 'semantic-ui-react';

import { SimpleUserImage } from '#/shared/components/ssm/users/SimpleUserImage';
import styles from '#/shared/styles/partnerSearchPopup.deprecated.css';

/** @deprecated 2022-10-18*/

// const log = logdown.getChildLogger('ssm.PartnerSearchPopup');

/** CP from Search.jsx
 * -----------------------------------------------------------------------------------------
 * @extends {Component<Props>} Search
 * @prop {string} storeName : The store to call `search` against on input change
 * @prop {(value) => any} onChange : Used to override the default `onChange` behavior
 * @prop {(value) => any} afterChange : Invoked in addition to the default or overriden
 *        `onChange` behavior.
 * @prop {bool} fluid : Passes the `fluid` prop to the semantic-ui-react `Input` component.
 * @prop {string} className: sets the className on the semantic-ui-react `Input` component.
 * @prop {string} placeholder: A string to display in the empty state
 */

@observer
class PartnerSearchPopup extends Component {
  static propTypes = {
    afterChange: PropTypes.func,
    excludePartners: PropTypes.array,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    storeName: PropTypes.string,
  };

  static defaultProps = {
    placeholder: 'Begin typing to search ...',
  };

  @observable
  searchValue = '';

  @observable
  open = false;

  @action
  toggleResults = (open = !this.open) => {
    this.open = open;
  };

  @action onValueChange = (e, { value }) => {
    this.searchValue = value;
    if (!this.open) this.toggleResults(true);

    const { onChange, afterChange, storeName } = this.props;

    if (_.isFunction(onChange)) {
      onChange(value);
    } else if (storeName) {
      dispatch(`${storeName}.search`, value);
    }

    if (_.isFunction(afterChange)) {
      afterChange(value);
    }
  };

  @action
  onKeyDown = (e) => {
    // Stop left/right arrow key propagation
    if ([37, 39].includes(e.keyCode)) {
      e.stopPropagation();
    }
  };

  @action
  onResultSelect = (e, { result }) => {
    this.open = false;
    this.props.onSelect(e, { result });
    this.onValueChange(e, { value: '' });
  };

  render() {
    const {
      placeholder = 'Add Partner',
      options,
      excludePartners,
    } = this.props;
    const results = _.differenceBy(options, excludePartners, 'uuid');

    return (
      <Search
        className="ssm-search"
        fluid
        icon={null}
        input={
          <Input
            icon="add circle"
            iconPosition="left"
            ref={(ref) => {
              this.inputRef = ref;
            }}
          />
        }
        onBlur={() => {
          this.toggleResults(false);
        }}
        onFocus={(e) => {
          this.onValueChange(e, { value: '' });
          this.toggleResults(true);
        }}
        onResultSelect={this.onResultSelect}
        onSearchChange={this.onValueChange}
        open={this.open}
        placeholder={placeholder}
        resultRenderer={({ displayName, profileImageURL }) => (
          <div className="flex flex-row items-center">
            <div className={styles.avatar}>
              <SimpleUserImage
                className="w-100 h-100"
                profileImageURL={profileImageURL}
              />
            </div>
            {displayName}
          </div>
        )}
        results={results}
        value={this.searchValue}
      />
    );
  }
}

PartnerSearchPopup.displayName = 'ssm.SearchPopup';
export default PartnerSearchPopup;
