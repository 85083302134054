import cx from 'classnames';
import styled, { css } from 'styled-components';

import { colors } from './colors';
import { effects } from './effects';

export const filterButtonBase = `
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    transition: ${effects.fastTransition};

    font-weight: 300;
    font-size: 14px;

    :hover {
      box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.08);
    }
`;
export interface IButtonProps {
  bare?: boolean;
  basic?: boolean;
  className?: string;
  color?: keyof typeof colors;
  compact?: boolean;
  content?: string;
  disabled?: boolean;
  filter?: boolean;
  fluid?: boolean;
  header?: boolean;
  large?: boolean;
  primary?: boolean;
  secondary?: boolean;

  smallFontSize?: boolean;
  tiny?: boolean;
}
export const Button = styled.div.attrs<IButtonProps>(
  ({
    primary,
    secondary,
    filter,
    header,
    large,
    tiny,
    disabled,
    compact,
    fluid,
    basic,
    bare,
    className,
  }) => ({
    className: cx(
      'flex items-center justify-center tc',
      {
        bare,
        basic,
        compact,
        disabled,
        filter,
        fluid,
        header,
        large,
        primary,
        secondary,
        tiny,
      },
      className,
    ),
    role: 'button',
    tabIndex: 0,
  }),
)<IButtonProps>`
  // TODO: this size is inconsistent with many other elements, most of which are 38px
  // However, changing it here causes some ui elements to look "off" so we need to ID
  // a complete solution before changing.
  height: 38px;
  border-radius: 5px;
  border-width: 0;

  padding-left: 20px;
  padding-right: 20px;

  font-family: Roboto, 'Open Sans', 'avenir next', avenir, 'helvetica neue',
    helvetica, ubuntu, noto, 'segoe ui', arial, sans-serif;
  font-size: ${({ smallFontSize }) => (smallFontSize ? '10px' : '15px')};
  font-weight: 600;
  line-height: 1.27;
  /* font-style: normal; */
  font-stretch: normal;
  transition: ${effects.slowTransition};

  user-select: none;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:active {
        transition: ${effects.fastTransition};
        transform: scale(0.97);
      }
    `}

  &:disabled,
  &.disabled {
    opacity: 0.5;
    filter: grayscale(0.5);
    cursor: not-allowed;

    &:active {
      pointer-events: none;
    }
  }

  &.large {
    height: 42px;
    border-radius: 6px;
    padding-left: 24px;
    padding-right: 24px;

    font-size: 18px;
  }
  &.tiny {
    height: 21px;
    width: max-content;
    border-radius: 3px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 10px;
  }

  &.fluid {
    flex-grow: 1;
    width: 100%;
  }

  & .img {
    -webkit-user-drag: none;
    user-drag: none;
  }

  &:after {
    content: '${({ content }) => (content ? `${content}` : '')}';
  }

  &.primary {
    ${({ basic }) =>
      !basic
        ? `
          background-image: ${effects.gradientPrimary};
          color: #ffffff;
        `
        : `
          border-color: ${({ color }) =>
            colors[color || 'ssm-blue']}; border-width: 1px;
          color: ${({ color }) => colors[color || 'ssm-blue']};
          border-style: solid;
          
        `}

    ${({ disabled, basic }) =>
      !basic &&
      !disabled &&
      css`
        box-shadow: 0 6px 5px 0 ${effects.shadowPrimary};
        :hover {
          box-shadow: 0 6px 8px 4px ${effects.shadowPrimary};
        }

        :active {
          box-shadow: 0 2px 1.7px 0 ${effects.shadowPrimary};
        }
      `}
  }

  // was ".buttonSecondary"
  &.secondary {
    padding: 8px;
    border-radius: 4px;
    box-shadow: ${effects.boxShadowButtonBasic};
    border: solid 1px ${colors.borderDarkAlt};
    background-color: ${colors.bgWhite};
    color: ${colors['text-primary']};
    transition: ${effects.fastTransition};

    font-weight: 300;
    font-size: 14px;

    :hover {
      box-shadow: 0 6px 5px 0 ${effects.shadowBasic};
    }
  }

  &.bare {
    color: ${({ color }) => colors[color || 'text-primary']};
    transition: ${effects.fastTransition};

    :hover {
      text-shadow: ${effects.textShadowBasic};
    }
  }

  &.header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 185px;
    border-radius: 4px;

    &,
    & > span {
      color: #fff;
    }
  }

  &.filter {
    ${filterButtonBase}

    padding: 8px;
    border: solid 1px ${colors['blue-navy']};
    background-color: ${colors['blue-navy']};
    color: #ffffff;
  }

  & .buttonHelpText {
    display: none;
    font-style: italic;
    color: ${colors.steel};
  }
  :hover .buttonHelpText {
    display: block;
  }

  // TODO: Validate compact styling ... this should be handled via props
  // since props don't translate directly to class names
  .compact {
    padding: 4px;

    :hover {
      box-shadow: 0 6px 5px 0 ${effects.shadowBasic};

      & .buttonHelpText {
        display: none;
      }
    }
  }
`;
