import type { ModalRefType } from '#/types/ModalRefType';

import React, { useImperativeHandle, useState } from 'react';
import { Modal, StrictButtonProps, StrictModalProps } from 'semantic-ui-react';
import _ from 'lodash';
import cx from 'classnames';
import { observer } from 'mobx-react';

import { Button } from '#/ssm-ui/Buttons';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('generics.GenericModal');
type GenericModalProps = {
  actions?: JSX.Element;
  cancelAction?: () => void;
  cancelButtonText?: string;
  children?: React.ReactNode;
  closeOnDimmerClick?: boolean;
  extraActions?: React.ReactNode;
  extraModalHeader?: React.ReactNode;
  handleCloseModal?: () => void;
  isModalOpen?: boolean;
  modalContent?: React.ReactNode;
  modalProps?: StrictModalProps;
  modalRef?: ModalRefType;
  modalSize?: StrictModalProps['size'];
  modalTitle?: string;
  /** Don't render the modal header */
  noHeader?: boolean;
  onSetup?: (...args: unknown[]) => void;
  primaryActionProps?: StrictButtonProps;
  styleClass?: string;
  /** Warning: DO NOT USE with mobx-react-form `form.onSubmit` actions */
  submitAction: () => void | unknown | Promise<unknown>;

  submitButtonText?: string;
};

/*
 *Generic Modal Wrapper with ref or store controller
 *@param open - store variable to show the modal or not
 *@param handleCloseModal - store action to close the modal
 *@param modalRef - modal ref to control the modal show/close
 *@param modalSize - modal size
 *@param styleClass - tachyons for modal content
 *@param modalTitle - modal title
 *@param extraModalHeader - extra JSX elements for the modal header
 *@param modalContent - content of the modal
 *@param submitAction - submit modal action function
 *@param submitButtonText - submit modal button text
 *@param cancelAction - cancel modal action function
 *@param cancelButtonText - cancel modal button text
 *@param extraActions - extra actions for the modal
 *@returns React.ReactElement - modal
 */
const GenericModal = observer(
  ({
    children,
    isModalOpen,
    handleCloseModal,
    modalRef,
    modalSize = 'large',
    modalProps,
    styleClass,
    modalTitle,
    extraModalHeader,
    modalContent = children,
    submitAction,
    submitButtonText = 'Save',
    cancelAction,
    cancelButtonText,
    actions,
    extraActions,
    closeOnDimmerClick = true,
    primaryActionProps,
    noHeader,
    ...props
  }: GenericModalProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const setup = async (...args) => {
      await _.invoke(props, 'onSetup', ...args);
      const { open = true } = _.first(args) ?? {};
      setIsOpen(open);
    };

    const clear = () => {
      setIsOpen(false);
    };

    useImperativeHandle(modalRef, () => ({
      clear,
      open: setIsOpen,
      setup,
    }));

    const closeModal = (e) => {
      e.stopPropagation();
      if (modalRef?.current) {
        clear();
      } else {
        handleCloseModal();
      }
    };

    const cancelModal = (e) => {
      if (_.isFunction(cancelAction)) {
        cancelAction();
      }
      closeModal(e);
    };

    return (
      <Modal
        closeOnDimmerClick={closeOnDimmerClick}
        dimmer="inverted"
        onClose={closeModal}
        open={modalRef ? isOpen : isModalOpen}
        size={modalSize}
        {...(!!modalProps && modalProps)}
      >
        {!noHeader && (
          <Modal.Header>
            <div className="flex-column flex justify-between">
              <span>
                <span
                  className="pr3"
                  onClick={(e) => {
                    cancelModal(e);
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <img
                    alt="Close"
                    src="/static/img/icons/ssm-close-modal.svg"
                  />
                </span>
                {modalTitle}
              </span>
              {extraModalHeader}
            </div>
          </Modal.Header>
        )}
        <Modal.Content className={cx(styleClass, noHeader && 'mt2')}>
          {modalContent}
        </Modal.Content>
        <Modal.Actions>
          <div className="flex items-center justify-between">
            <Button
              bare={true}
              content={cancelButtonText || 'cancel'}
              onClick={(e) => {
                e.stopPropagation();
                cancelModal(e);
              }}
            />
            <div className="flex items-center">
              {extraActions}
              {actions ?? (
                <Button
                  content={submitButtonText || 'Save'}
                  primary={true}
                  {...(primaryActionProps && primaryActionProps)}
                  onClick={(e) => {
                    e.stopPropagation();
                    (async () => {
                      try {
                        const result = await submitAction();
                        if (result) {
                          closeModal(e);
                        }
                      } catch (error) {
                        log.error(
                          'Error while trying to submit generic modal form',
                          error,
                        );
                      }
                    })();
                  }}
                />
              )}
            </div>
          </div>
        </Modal.Actions>
      </Modal>
    );
  },
);

GenericModal.displayName = 'GenericModal';
export default GenericModal;
