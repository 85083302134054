import type { INote } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export default class NoteStore extends BaseStore<INote> {
  constructor() {
    super({
      baseItem: {
        author: '',
        company: '',
        createdAt: null,
        text: '',
        user: null,
        uuid: null,
      },
      serviceName: 'notes',
    });

    return this;
  }
}
