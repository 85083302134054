import type { ICompany, IUser } from '@shiftsmartinc/shiftsmart-types';

import React, { useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import cx from 'classnames';
import { Image, Dropdown, Input } from 'semantic-ui-react';
import { Query } from '@feathersjs/feathers';
import styled from 'styled-components';

import { useStores } from '#/shared/hooks/useStores';
import UserImage from '#/shared/components/ssm/users/UserImage';
import SelectedCompanyMenu from '#/shared/components/ssm/SelectedCompanyMenu';
import { useAutorunEffect } from '#/shared/hooks/useHooksWithMobx';
import { SimpleUserImage } from '#/shared/components/ssm/users/SimpleUserImage';
import EmptyState from '#/shared/components/ssm/EmptyState';
import { breakpoints } from '#/ssm-ui';
import { getChildLogger } from '#/shared/utils/client.logger';

import { DarkModeStateToggle } from './DarkModeStateToggle';

const log = getChildLogger('LoggedInUserMenu');

const UserMenuDropdown = styled(Dropdown).attrs(() => ({
  direction: 'left',
  floating: true,
  icon: false,
}))`
  &.ui.upward.dropdown > .menu {
    bottom: unset;
    top: 35px;
  }

  &.ui.active.dropdown > .visible.menu {
    min-width: 22rem;
    max-width: 22rem;

    @media ${breakpoints.xxs} {
      min-width: calc(100vw - 20px);
    }

    & > .input.icon {
      margin-bottom: 0.5rem;
    }

    user-select: none;
  }
`;

const UserMenuTrigger = styled.div.attrs<{ vertical?: boolean }>(
  ({ vertical }) => ({
    className: cx(
      'flex items-center pa2 flex-shrink-0',
      vertical && 'w-100 justify-between',
    ),
  }),
)`
  height: 44px;
  user-select: none;
`;

export const CompactCompanyListItem = observer(({ company, onSelect }) => {
  const { auth, routing } = useStores();
  const { user } = auth;

  const { name, uuid, images = {} } = company;

  const handleItemClick = async () => {
    if (uuid !== auth.companyId) {
      await auth.setCompany({
        company: uuid,
        impersonate: true,
      });

      routing.refresh({ setMainPath: true });
    } else if (user?.isAdmin) {
      routing.push(`/admin/companies/${uuid}`);
    }
    onSelect();
  };

  return (
    <Dropdown.Item
      className={cx(
        'w-100 br3 ph3 pv2 pointer flex items-center justify-between',
      )}
      key={uuid}
      onClick={() => handleItemClick()}
    >
      <div className="ellipsis pa1 flex flex-nowrap items-center overflow-hidden">
        <div className="h2 w2 flex-shrink-0">
          <SimpleUserImage
            circular={true}
            className="h2 w2 shadow-0"
            displayName={name}
            profileImageURL={images?.avatar || images?.logo || images?.square}
          />
        </div>
        <div className="pl2 f5 fw4">{name}</div>
      </div>
    </Dropdown.Item>
  );
});

const LoggedInUserMenu = observer(
  ({
    user,
    company,
    vertical,
    companies: userCompanies,
    ...rest
  }: {
    companies: Array<ICompany>;
    company: ICompany;
    isOpen?: boolean;
    user: IUser;
    vertical?: boolean;
  }) => {
    const {
      ui,
      ui: { theme },
      auth,
      abilities,
      routing,
      prefs,
      companies,
    } = useStores();

    const [searchValue, setSearchValue] = useState('');

    const { toggleUserMenu, isUserMenuOpen = rest.isOpen } = ui.appNav;

    const handleClose = () => toggleUserMenu(false);
    const handleOpen = () => toggleUserMenu(true);

    const handleChange = (e, { value }) => {
      setSearchValue(value);
    };

    const closePopup = () => {
      setSearchValue('');
      handleClose();
    };

    const [companyList, setCompanies] = useState(() => {
      if (_.size(userCompanies)) {
        return userCompanies;
      }
      if (_.size(companies.userCompanies)) {
        return companies.userCompanies;
      }

      if (_.size(prefs.recentCompanies)) {
        return prefs.recentCompanies;
      }
      if (_.size(prefs.companies)) {
        return prefs.companies;
      }

      return [auth.company];
    });
    const [additionalCos, setAdditional] = useState(0);

    useAutorunEffect(() => {
      if (searchValue) {
        const queryCompanies = async () => {
          const query: Query = {
            $select: ['name', 'images', 'uuid'],
            name: { $options: 'i', $regex: searchValue },
            status: 'active',
          };

          if (!user.isAdmin) {
            query.uuid = { $in: _.map(userCompanies, 'uuid') };
          }

          const { data, total } = await companies.runQuery(query);

          setCompanies(data);
          setAdditional(total - _.size(data));
        };
        queryCompanies();
      } else {
        setCompanies(userCompanies);
      }
    }, [searchValue]);

    return (
      <UserMenuDropdown
        onClose={closePopup}
        onOpen={handleOpen}
        trigger={
          <UserMenuTrigger vertical={vertical}>
            {!ui.breakpoints.xxs && (
              <div className="pl2">
                <SelectedCompanyMenu
                  company={company}
                  noClick={true}
                  user={user}
                />
              </div>
            )}
            <UserImage
              circular={true}
              style={{
                height: '40px',
                width: '40px',
              }}
              user={user}
            />
            {!ui.breakpoints.xxs && (
              <div className="pl2">
                <Image
                  src="/static/img/icon-dropdown-arrow.png"
                  style={{ height: '10px', width: '6px' }}
                />
              </div>
            )}
          </UserMenuTrigger>
        }
      >
        <Dropdown.Menu
          open={isUserMenuOpen}
          style={{
            maxHeight: 'calc(100vh - 80px)',
          }}
        >
          {!user.isAdmin && (
            <Dropdown.Header>
              {userCompanies.length > 1 ? 'Your Companies' : 'Your Company'}
            </Dropdown.Header>
          )}
          {(!_.isEmpty(userCompanies) || user?.isAdmin) && (
            <>
              {(userCompanies.length > 10 || user?.isAdmin) && (
                <Input
                  className="icon w-100"
                  icon={{
                    name: 'search',
                  }}
                  onChange={(e, data) => {
                    e.stopPropagation();
                    handleChange(e, data);
                  }}
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => {
                    if (e?.keyCode === 13 && _.size(companyList) === 1) {
                      auth
                        .setCompany({
                          company: _.first(companyList).uuid,
                          impersonate: true,
                        })
                        .then(() => routing.refresh({ setMainPath: true }));
                      // e.target.blur();
                      handleClose();
                    } else {
                      e.stopPropagation();
                    }
                  }}
                  placeholder="Search Companies"
                  value={searchValue}
                />
              )}
              <div
                className="overflow-y-auto overflow-x-hidden"
                style={{ maxHeight: 'calc(100vh - 70px - 300px)' }}
              >
                {_(companyList)
                  .uniqBy('uuid')
                  .map((co) => (
                    <CompactCompanyListItem
                      company={co}
                      key={co?.uuid}
                      onSelect={closePopup}
                    />
                  ))
                  .value()}
                {!!searchValue && additionalCos > 0 && (
                  <div className="silver pa2 i tc">
                    ... and {additionalCos} additional results
                  </div>
                )}
                {_.isEmpty(companyList) && (
                  <div className="max-h5 pt4">
                    <EmptyState
                      compact={true}
                      header={{
                        color: 'grey',
                        content: 'no matching companies',
                        size: 'small',
                      }}
                      style={{ width: '70%' }}
                    />
                  </div>
                )}
              </div>
            </>
          )}

          <Dropdown.Divider />

          <Dropdown.Header className="silver ttu f6 ph3 pt3 pb1">
            Settings
          </Dropdown.Header>

          <Dropdown.Item
            onClick={() => {
              routing.push('/profile');
              closePopup();
            }}
            role="button"
            style={{ color: 'var(--grey10)' }}
            tabIndex={-42}
          >
            <div className="flex items-center justify-between">
              <div className="pr2">My Profile</div>
              <div className="w2 flex justify-center">
                <Image src="/static/img/userMenu/profile.svg" />
              </div>
            </div>
          </Dropdown.Item>

          {abilities.can('update', auth.company) && (
            <Dropdown.Item
              onClick={() => {
                routing.push('/company');
                closePopup();
              }}
              role="button"
              style={{ color: 'var(--grey10)' }}
              tabIndex={-42}
            >
              <div className="flex items-center justify-between">
                <div className="pr2">Company Settings</div>
                <div className="w2 flex justify-center">
                  <Image src="/static/img/userMenu/settings.svg" />
                </div>
              </div>
            </Dropdown.Item>
          )}

          {theme.allowDarkMode && (
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
                theme.toggleDarkMode();
              }}
            >
              <div className="flex items-center justify-between">
                <div className="pr2">Theme: {theme.darkModeState?.theme}</div>
                <div className="w2 flex justify-center">
                  <DarkModeStateToggle />
                </div>
              </div>
            </Dropdown.Item>
          )}

          <Dropdown.Divider />

          <Dropdown.Item
            onClick={() => {
              auth.logout();
              closePopup();
            }}
            role="button"
            style={{ color: 'var(--grey10)' }}
            tabIndex={-42}
          >
            <div className="flex items-center justify-between">
              <div className="pr2">Log Out</div>
              <div className="w2 pl2 flex justify-center">
                <Image src="/static/img/userMenu/logout.svg" />
              </div>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </UserMenuDropdown>
    );
  },
);

LoggedInUserMenu.displayName = 'LoggedInUserMenu';
export default LoggedInUserMenu;
