import { dispatch } from 'rfx-core';
import _ from 'lodash';

// import { service } from '#/shared/app';
import PartnersStore from './partners';

export default class WorkerLocationStore extends PartnersStore {
  constructor({
    serviceName = 'workers/locations',
    title,
    opts,
    ...rest
  } = {}) {
    super({
      baseItem: PartnersStore.BASE_ITEM,
      opts: {
        clearSelectedOnUpdateMismatch: true,
        removeFromListOnUpdateMismatch: true,
        ...opts,
      },
      searchFields: [['displayName', 'phoneNumber', 'uuid']],
      serviceName,
      title: _.compact(['locations', title]).join('.'),
      ...rest,
    });

    return this;
  }

  async findByLocation({
    company = dispatch('auth.getCompany'),
    query = {},
    box,
    opts,
  }) {
    let workers;

    if (_.size(box)) {
      this.log.debug(
        '[findByLocation] Querying workers within map bounds: ',
        JSON.stringify(box, null, 2),
      );

      workers = await dispatch('workerLocations.findByCompany', {
        company,
        opts: {
          // TODO: Unclear what purpose this served, as the `loadLocations` switch triggered
          // a call to the legacy workerLocations store.
          loadLocations: true,
          ...opts,
        },
        query: {
          ...query,
          _box: box,
        },
      });
    } else {
      throw new Error('Must specify bounding box to query `workerLocations`');
    }

    this.log.debug(
      '[findByLocation] Found %d workers within map bounds',
      _.size(workers),
    );

    return workers;
  }
}
