import { action, observable, runInAction } from 'mobx';
import _ from 'lodash';

import { init as initForm } from '#/shared/forms/tagGroup';

export default class TagGroupEditModal {
  @observable
  isOpen = false;

  @observable
  tagGroup = {};

  @observable
  form = {};

  @action
  async setup({ tagGroup = null, open = this.isOpen }) {
    this.tagGroup = tagGroup;

    runInAction(() => {
      this.form = initForm(tagGroup);
      this.isOpen = open;
    });
  }

  @action
  addTag(value) {
    const formValues = this.form.$('tags').value;
    if (_.find(formValues, (f) => f.uuid === value.uuid)) {
      this.form.$('tags').invalidate('Tag is already added');
    } else {
      this.form.$('tags').add([value]);
      this.form.$('tags').validate();
    }
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    this.tagGroup = {};

    if (this.form && this.form.clear) {
      this.form.clear();
    }

    this.isOpen = false;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    _.set(this, key, value);
  }
}
