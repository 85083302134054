/* eslint global-require: 0 */
import { fetchData, hotRehydrate, fetchDataOnLocationMatch } from 'rfx-core';
import React from 'react';
import { render } from 'react-dom';
import { browserHistory, match } from 'react-router';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { log } from '#/shared/utils/client.logger';
import { StoresProvider } from '#/shared/hooks/useStores';
import { stores } from '#/shared/stores'; // initialize stores
import routes from '#/shared/routes';

import { init as datadogInit } from './datadog';
import App from './App';

log.always('Running Client');

// const store = rehydrate();
const store = stores.inject({});
const history = browserHistory;
datadogInit();

store.app.setStoresContext(store);

// This powers `MyComponent.fetchData` in containers
fetchDataOnLocationMatch(history, routes, match, store);

function renderApp(AppComponent) {
  match({ history, routes }, (error, redirect, routerProps) => {
    fetchData(store, routerProps);

    return render(
      <StoresProvider value={store}>
        <DndProvider backend={HTML5Backend}>
          <AppComponent
            routerProps={routerProps}
            store={store ?? hotRehydrate()}
          />
        </DndProvider>
      </StoresProvider>,
      document.getElementById('root'),
    );
  });
}

store.auth.authenticate().then((res) => {
  log.info('res: ', res);
  renderApp(App);
});
