import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Label } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { colors } from '#/ssm-ui';

interface Option {
  badge?: unknown;
  className?: string;
  disabled?: unknown;
  hidden?: boolean;
  key?: string;
  onSelect?: () => void;
  style?: Record<string, unknown>;
  title: string;
  visible?: boolean;
}
interface PillToggleProps {
  hideBadge?: boolean;
  onSelectAction: string | ((value: string) => void);
  options: Array<Option>;
  removeMargins?: boolean;
  selected: string;
}

const PillToggleContainer = styled.div`
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 18px;
  border: 1px solid ${colors.borderDark};
  background: #ffffff;
  margin-left: 10px;
  margin-right: 10px;
`;
interface ToggleItemProps {
  active?: boolean;
  disabled?: boolean;
}
const Item = styled.div<ToggleItemProps>`
  position: relative;
  height: 36px;
  top: -1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors['text-primary']};
  font-size: 14px;
  line-height: 14px;
  font-weight: 300;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 18px;
  transition: color 0.2s linear;

  :first-of-type {
    padding-left: 12px;
  }
  :last-of-type {
    padding-right: 12px;
  }

  :hover {
    color: ${colors['blue-alt']};
  }
  .badge.ui.basic.label {
  }
  .pillToggleBadge {
    border: none !important;
    margin-left: 4px !important;
    font-weight: normal !important;
    font-size: 10px !important;
    line-height: 10px !important;
    background: ${colors.borderDark} !important;
  }
  ${(props) =>
    props.active &&
    css`
      background: ${colors['blue-navy']};
      border: 1px solid ${colors['blue-navy']};
      color: #ffffff !important;
      padding-left: 12px;
      padding-right: 12px;
      .pillToggleBadge {
        color: ${colors['blue-navy']};
        background: #ffffff !important;
      }
    `}
`;

// CP from CaretTabs.jsx
const PillToggle = observer(
  ({
    options,
    hideBadge = false,
    selected,
    onSelectAction = 'setActiveTab',
    removeMargins = false,
    ...rest
  }: PillToggleProps) => (
    <PillToggleContainer
      className="ssm-no-text-select"
      style={{
        marginLeft: removeMargins && '0px',
        marginRight: removeMargins && '0px',
      }}
    >
      {_(options)
        .sortBy('index')
        .reject((tab) => {
          if (!_.has(tab, 'hidden')) return false;

          return _.isFunction(tab.hidden)
            ? tab.hidden({ ...rest })
            : !!tab.hidden;
        })
        .filter((tab) => {
          if (!_.has(tab, 'visible')) return true;

          return _.isFunction(tab.visible)
            ? tab.visible({ ...rest })
            : !!tab.visible;
        })
        .map((tab) => (
          <PillToggleItem
            activeTab={selected}
            hideBadge={hideBadge}
            key={tab.key}
            onSelectAction={onSelectAction}
            rest={rest}
            tab={tab}
          />
        ))
        .value()}
    </PillToggleContainer>
  ),
);

export default PillToggle;

interface PillToggleItemProps {
  activeTab: string;
  hideBadge: boolean;
  onSelectAction: string | ((value: string) => void);
  rest: unknown;
  tab: Option;
}

export const PillToggleItem = observer(
  ({
    tab,
    activeTab,
    onSelectAction,
    hideBadge = false,
    rest,
  }: PillToggleItemProps) => {
    const badgeCt = _.isFunction(tab.badge)
      ? tab.badge({ ...rest })
      : _.get(tab, 'badge', 0);

    const disabled = _.isFunction(tab.disabled)
      ? tab.disabled({ ...rest })
      : !!tab.disabled;

    const active = activeTab === tab.key;

    const content = (
      <div className="flex items-center">
        <div>{tab.title}</div>
        {!hideBadge && (
          <Label
            basic={true}
            circular={true}
            className="pillToggleBadge"
            content={badgeCt}
            size="mini"
          />
        )}
      </div>
    );

    return (
      <Item
        active={active}
        className={tab.className}
        disabled={disabled}
        key={tab.key}
        onClick={() => {
          if (onSelectAction === null) return;
          if (disabled) return;
          if (_.isFunction(onSelectAction)) {
            onSelectAction(tab.key);
            return;
          }

          if (_.isFunction(tab.onSelect)) {
            tab.onSelect({ ...rest });
          }
        }}
        role="button"
        style={tab.style}
        tabIndex={-1}
      >
        {content}
      </Item>
    );
  },
);
