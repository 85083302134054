import type { IPaymentBonus } from '@shiftsmartinc/shiftsmart-types';

import PaymentRulesStore from './paymentRules';

export default class PaymentBonusesStore extends PaymentRulesStore<IPaymentBonus> {
  constructor({
    serviceName = 'paymentRules/bonuses',
    baseItem = {},
    ...rest
  } = {}) {
    super({
      baseItem: { ...PaymentBonusesStore.BASE_ITEM, ...baseItem },
      serviceName,
      ...rest,
    });

    return this;
  }

  static get BASE_ITEM() {
    return {
      amount: null,
      count: null,
      duration: null,
    };
  }
}
