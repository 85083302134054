import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class CreateChatFilterModal {
  log = getChildLogger('ui.CreateChatFilterModal');

  @observable
  isOpen = false;

  @observable
  company = {};

  @observable
  nameValue = '';

  @observable
  filterStatus = '';

  @observable
  filterType = '';

  @observable
  filterTag = '';

  @observable
  filterMessageTag = '';

  @action
  async setup({
    company,
    filterStatus,
    filterType,
    filterTag,
    filterMessageTag,
    open = this.isOpen,
  }) {
    if (company) this.setCompany(company);
    if (filterStatus) this.setFilterStatus(filterStatus);
    if (filterType) this.setFilterType(filterType);
    if (filterTag) this.setFilterTag(filterTag);
    if (filterMessageTag) this.setFilterMessageTag(filterMessageTag);
    this.open(open);
  }

  @action
  setCompany(company) {
    this.company = company;
  }

  @action
  setFilterStatus(filterStatus) {
    this.filterStatus = filterStatus;
  }

  @action
  setFilterType(filterType) {
    this.filterType = filterType;
  }

  @action
  setFilterTag(filterTag) {
    this.filterTag = filterTag;
  }

  @action
  setFilterMessageTag(filterMessageTag) {
    this.filterMessageTag = filterMessageTag;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  name(value) {
    this.nameValue = value;
  }

  @action
  clear(args = {}) {
    this.isOpen = !!args.open;
    this.nameValue = '';
    this.company = {};
    this.filterStatus = '';
    this.filterType = '';
    this.filterTag = '';
    this.filterMessageTag = '';
  }

  @action
  async createNewChatFilter() {
    const log = this.log.getChildLogger('createNewChatFilter');
    const filterObj = {
      messageTag: this.filterMessageTag,
      status: this.filterStatus,
      tag: this.filterTag,
      type: this.filterType,
    };
    try {
      const newChatFilter = await dispatch('savedChatFilters.create', {
        data: {
          company: this.company.uuid,
          filterQueryString: JSON.stringify(filterObj),
          name: this.nameValue,
        },
      });
      dispatch(
        'ui.snackBar.open',
        `Successfully saved ${newChatFilter.name} Filter`,
      );
      return newChatFilter;
    } catch (err) {
      log.error('Failed to create chat filter', err);
      dispatch('ui.snackBar.error', 'Failed to Create Filter');
    }

    return null;
  }
}
