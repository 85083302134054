import { action, observable } from 'mobx';

export default class PostCreateModal {
  @observable
  isOpen = false;

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }
}
