import type { ISurvey } from '@shiftsmartinc/shiftsmart-types';

import { action, observable } from 'mobx';

/**
 * Store for UI Modal where user can assign a worker to their thing
 *
 * @export
 * @class userModal
 */

export default class AssignSurveyModal {
  @observable
  isOpen = false;

  @observable
  survey: ISurvey = null;

  @observable
  onClose = null;

  @action
  setup({ survey, open = this.isOpen }: { open?: boolean; survey: ISurvey }) {
    this.survey = survey ?? null;

    this.isOpen = open;
  }

  @action
  clear() {
    this.survey = null;

    this.isOpen = false;
  }
}
