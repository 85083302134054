import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import cx from 'classnames';
import onClickOutside from 'react-onclickoutside';

import styles from '#/shared/styles/TimePickerDropdown.css';

class TimePickerDropdown extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    minuteStep: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    runOnChangeOnMount: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
      .isRequired,
  };

  static defaultProps = {
    minuteStep: 15,
    runOnChangeOnMount: false,
  };

  handleClickOutside = () => {
    this.setState({ visible: false });
  };

  state = {
    isTyping: false,
    timeOptions: [],
    userValue: null,
    visible: false,
  };

  UNSAFE_componentWillMount() {
    // Calculate time options
    const { minuteStep } = this.props;
    const startTime = moment().set({ hour: 0, minute: 0 });
    const endTime = moment().set({ hour: 11, minute: 59 });

    const timeStops = [];
    while (startTime <= endTime) {
      timeStops.push(moment(startTime).format('h:mm'));
      startTime.add(minuteStep, 'minutes');
    }
    this.setState({ timeOptions: timeStops });
  }

  componentDidMount() {
    const { value, runOnChangeOnMount, onChange } = this.props;
    if (runOnChangeOnMount) onChange(moment(value).toDate());
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.state.visible && !prevState.visible) ||
      this.props.value !== prevProps.value
    ) {
      const selectedTimeElt = this.selectedTime.current;
      if (selectedTimeElt && this.state.visible) {
        selectedTimeElt.parentNode.scrollTop = selectedTimeElt.offsetTop;
      }
    }
  }

  onAmPmClick = (ampm) => {
    const { value, onChange } = this.props;
    const timeValue = moment(value).format('h:mm');
    this.setState({
      isTyping: false,
      visible: false,
    });
    onChange(
      moment(value)
        .set({
          hours: moment(`${timeValue} ${ampm}`, 'h:mm A').get('hours'),
          minutes: moment(`${timeValue} ${ampm}`, 'h:mm A').get('minutes'),
        })
        .toDate(),
    );
  };

  onTimeClick = (t) => {
    const { value, onChange } = this.props;
    const ampmValue = moment(value).format('A');
    this.setState({ isTyping: false });
    onChange(
      moment(value)
        .set({
          hours: moment(`${t} ${ampmValue}`, 'h:mm A').hours(),
          minutes: moment(`${t} ${ampmValue}`, 'h:mm A').minutes(),
        })
        .toDate(),
    );
  };

  onTyping = (e) => {
    if (this.state.isTyping) {
      this.setState({ userValue: e.target.value });
    }
  };

  saveUserValue = (e) => {
    const { value, onChange } = this.props;
    const inputValue = moment(e.target.value, 'LT');
    const isValid = inputValue.isValid();

    if (!this.state.isTyping) return;

    if (isValid && !inputValue.isSame(value, 'minutes')) {
      onChange(
        moment(value)
          .set({
            hours: inputValue.hours(),
            minutes: inputValue.minutes(),
          })
          .toDate(),
      );
      this.setState({ visible: false });
    }
    this.setState({
      isTyping: false,
      userValue: null,
    });
  };

  selectedTime = React.createRef();

  render() {
    const { children, value, className } = this.props;
    const { visible, timeOptions, userValue } = this.state;
    const timeValue = moment(value).format('h:mm');
    const ampmValue = moment(value).format('A');
    const momentValue = value ? moment(value).format('LT') : '';

    const displayValue = _.isString(userValue) ? userValue : momentValue;

    return (
      <div className={cx([styles.container, className])}>
        <children.type
          {...children.props}
          onBlur={this.saveUserValue}
          onChange={this.onTyping}
          onFocus={(e) => {
            e.target.setSelectionRange(0, e.target.value.length);
            this.setState({ visible: true });
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              this.saveUserValue(e);
            } else {
              this.setState({ isTyping: true });
            }
          }}
          value={displayValue}
        />
        {visible && (
          <div className={styles.popup}>
            <div className={styles.timeOptions}>
              {timeOptions.map((t) => (
                <div
                  className={cx(
                    styles.timeOption,
                    timeValue === t && styles.timeOptionSelected,
                  )}
                  key={t}
                  onClick={() => this.onTimeClick(t)}
                  ref={timeValue === t ? this.selectedTime : null}
                  role="button"
                  tabIndex={-1}
                >
                  {t}
                </div>
              ))}
            </div>
            <div className={styles.ampmOptions}>
              <div
                className={cx(
                  styles.ampmOption,
                  ampmValue === 'AM' && styles.timeOptionSelected,
                )}
                onClick={() => this.onAmPmClick('AM')}
                role="button"
                tabIndex={-1}
              >
                AM
              </div>
              <div
                className={cx(
                  styles.ampmOption,
                  ampmValue === 'PM' && styles.timeOptionSelected,
                )}
                onClick={() => this.onAmPmClick('PM')}
                role="button"
                tabIndex={-1}
              >
                PM
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default onClickOutside(TimePickerDropdown);
