import { dispatch } from 'rfx-core';
import moment from 'moment';
import _ from 'lodash';

import Form from './_.extend';

export class TimecardForm extends Form {
  hooks = () => ({
    async onSuccess(form) {
      const data = form.values();
      const isStatusDirty = form.$('noShow').isDirty;
      const shift = dispatch('shifts.retrieve', 'selected');
      const checkIn = moment(data.checkInTime || shift.start);
      const checkOut = moment(data.checkOutTime || shift.end);

      const hasTimeKeepingError = _.compact(
        form.$('timecard').map((tc) => {
          const tcIn = moment(tc.$('in').value);
          const tcOut = moment(tc.$('out').value);
          const { category: tcCategory } = splitCategoryDuration(
            tc.$('categoryDuration').value,
          );

          if (tcIn.isBefore(checkIn)) {
            if (data.checkInTime) {
              tc.$('in').invalidate(
                'Timecard entries cannot start before check in time',
              );
            } else {
              tc.$('in').invalidate(
                'Timecard entries cannot start before shift start time',
              );
            }
            return true;
          }
          if (tcOut.isAfter(checkOut)) {
            if (data.checkOutTime) {
              tc.$('out').invalidate(
                'Timecard entries cannot end after check out time',
              );
            } else {
              tc.$('out').invalidate(
                'Timecard entries cannot end after shift end time',
              );
            }
            return true;
          }

          // check for overlap
          const hasOverlap = _.get(data, 'timecard', []).some((tc2) => {
            const { category: compCategory } = splitCategoryDuration(
              tc2.categoryDuration,
            );

            if (/break/i.test(tcCategory) && !/break/i.test(compCategory)) {
              return false;
            }
            if (!/break/i.test(tcCategory) && tcCategory !== compCategory) {
              return false;
            }

            if (
              tcIn.isBefore(moment(tc2.in)) &&
              tcOut.isAfter(moment(tc2.in))
            ) {
              tc.$('out').invalidate('Timecard entries cannot overlap');
              return true;
            }
            if (
              tcIn.isBefore(moment(tc2.out)) &&
              tcOut.isAfter(moment(tc2.out))
            ) {
              tc.$('in').invalidate('Timecard entries cannot overlap');
              return true;
            }
            return false;
          });
          if (hasOverlap) {
            return true;
          }

          return false;
        }),
      );

      if (!_.isEmpty(hasTimeKeepingError)) {
        return;
      }

      const timecard = _.get(data, 'timecard', []).map((tc) => {
        const { category, expectedDuration } = splitCategoryDuration(
          _.get(tc, 'categoryDuration', ''),
        );

        return _.extend(tc, { category, expectedDuration });
      });
      const assignmentData = {
        timecard,
        uuid: data.uuid,
      };
      const authUser = dispatch('auth.getUser');
      if (form.$('checkInTime').isDirty) {
        if (_.isDate(data.checkInTime)) {
          assignmentData['checkIn.timestamp'] = data.checkInTime;
          assignmentData['checkIn.user'] = authUser.uuid;
        } else if (_.isEmpty(data.checkInTime)) {
          _.set(assignmentData, '$unset.checkIn', '');
        }
      }
      if (form.$('checkOutTime').isDirty) {
        if (_.isDate(data.checkOutTime)) {
          assignmentData['checkOut.timestamp'] = data.checkOutTime;
          assignmentData['checkOut.user'] = authUser.uuid;
        } else if (_.isEmpty(data.checkOutTime)) {
          _.set(assignmentData, '$unset.checkOut', '');
        }
      }
      if (data.noShow) {
        assignmentData.status = 'NoShow';
      } else if (isStatusDirty) {
        const assignment = dispatch('ui.timecardModal.retrieve', 'assignment');
        const lastStatus = _.last(
          _.get(assignment, 'statusLog', []).filter(
            (a) => a.status !== 'NoShow',
          ),
        );
        const isCompleted =
          moment().isAfter(moment(assignment.end)) ||
          assignment.data.status === 'Completed';
        assignmentData.status =
          (!isCompleted && lastStatus?.status) || 'Completed';
      }

      try {
        await dispatch('assignments.update', {
          data: assignmentData,
        });
        dispatch('ui.timecardModal.clear');
        dispatch('ui.snackBar.open', 'Timekeeping Data Updated.');
      } catch (error) {
        dispatch('ui.snackBar.error', 'Error updating timekeeping data');
      }
    },
  });
}

const fields = [
  'uuid',
  'checkInTime',
  'checkOutTime',
  'timecard',
  'timecard[].in',
  'timecard[].out',
  'timecard[].categoryDuration',
  'noShow',
];

const rules = {
  checkInTime: 'date',
  checkOutTime: 'date',
  noShow: 'boolean',
  timecard: 'array',
  'timecard[].categoryDuration': 'string',
  'timecard[].in': 'date',
  'timecard[].out': 'date',
  uuid: 'string',
};

const labels = {
  checkInTime: 'Check-In',
  checkOutTime: 'Check-Out',
  noShow: 'Mark as No Show',
  timecard: 'Breaks',
  'timecard[].categoryDuration': 'Type',
  'timecard[].in': 'Start',
  'timecard[].out': 'End',
};

const defaultValues = {
  checkInTime: new Date(),
  checkOutTime: new Date(),
  noShow: false,
  timecard: [],
};

function splitCategoryDuration(categoryDuration) {
  const [category, expectedDuration] = (categoryDuration ?? '').split('<>');
  return { category, expectedDuration };
}

export function init(assignment = {}) {
  const values = {
    checkInTime: assignment?.checkIn?.timestamp || null,
    checkOutTime: assignment?.checkOut?.timestamp || null,
    status: assignment.status,
    timecard: _.get(assignment, 'timecard', []).map((tc) =>
      _.extend(
        {
          categoryDuration: _.compact([tc.category, tc.expectedDuration]).join(
            '<>',
          ),
          in: null,
          out: null,
        },
        _.pick(tc, ['in', 'out', 'category']),
      ),
    ),
    uuid: assignment.uuid, // ATTN: Assignment Status EP-5523
  };
  const populatedValues = _.extend(defaultValues, values);
  populatedValues.noShow = values.status === 'NoShow';
  return new TimecardForm({ fields, labels, rules, values: populatedValues });
}
