import qs from 'query-string';

export function parseQueryString<T = Record<string, string>>() {
  let queryObject = {} as T;
  if (
    !global.IS_SSR &&
    typeof window !== 'undefined' &&
    window.location.search
  ) {
    queryObject = qs.parse(window.location.search);
  }
  return queryObject;
}
