import { dispatch } from 'rfx-core';
import _ from 'lodash';

import Form from './_.extend';

export class MessageTagForm extends Form {
  hooks = () => ({
    onSuccess(form) {
      const data = form.values();
      const params = { query: { $client: { populateCompanyNames: true } } };
      const action = `messageTags.${
        _.isEmpty(data.uuid) ? 'create' : 'update'
      }`;

      dispatch(action, {
        data,
        params,
      }).then(() => dispatch('ui.messageTagEditModal.clear'));
    },
  });
}

const fields = ['title', 'uuid', 'companies', 'keywords', 'keywords[]'];

const rules = {
  companies: 'array|required',
  keywords: 'array',
  title: 'required|string|between:1,50',
  uuid: 'string',
};

const labels = {
  companies: 'Companies',
  keywords: 'Keywords',
  title: 'Title',
};

export function init(values = {}) {
  const initValues = { companies: [], keywords: [] };
  const allValues = _.extend(initValues, values);
  return new MessageTagForm({
    fields,
    labels,
    rules,
    values: _.pick(allValues, fields),
  });
}
