import logdown, { Logger as LogdownLogger } from 'logdown';
import _ from 'lodash';

export interface SSMLogger extends LogdownLogger {
  always(...args: unknown[]): void;
  getChildLogger(prefix?: string, opts?: logdown.LogdownOptions): SSMLogger;
  readonly opts: logdown.LogdownOptions;

  // Interfaces cannot be constructed, only classes  @typescript-eslint/no-misused-new
  // constructor(prefix: string, opts?: logdown.LogdownOptions);
  silly(...args: unknown[]): void;

  verbose(...args: unknown[]): void;
}

declare module 'logdown' {
  interface Logger {
    opts: logdown.LogdownOptions;
  }

  // interface LogdownConstructor {
  //   new <T = SSMLogger>(prefix: string, opts?: logdown.LogdownOptions): T;
  // }

  let getChildLogger: (
    prefix?: string,
    opts?: logdown.LogdownOptions,
  ) => SSMLogger;
}

export type LogLevel =
  | 'silly'
  | 'verbose'
  | 'debug'
  | 'info'
  | 'warn'
  | 'error'
  | 'always';

const log: SSMLogger = <SSMLogger>(
  (logdown(
    global.LOGGER_PREFIX || (global.TYPE === 'CLIENT' ? 'ssm' : 'ssr'),
  ) as unknown)
);

const globalLogLevel: LogLevel | undefined = global.LOG_LEVEL;

const clientLogLevel: LogLevel | undefined =
  global.TYPE === 'CLIENT' && _.get(window, 'localStorage.ssmLogLevel');

const logLevel: LogLevel =
  clientLogLevel || globalLogLevel || (global.IS_PRODUCTION ? 'error' : 'info');

const baseLevels: Record<LogLevel, boolean> = {
  always: true,
  debug: /silly|verbose|debug/i.test(logLevel),
  error: /silly|verbose|debug|info|warn|error/i.test(logLevel),
  info: /silly|verbose|debug|info/i.test(logLevel),
  silly: /silly/i.test(logLevel),
  verbose: /silly|verbose/i.test(logLevel),
  warn: /silly|verbose|debug|info|warn/i.test(logLevel),
};

initLevels(log, baseLevels);

log.always('Initialized Logger', {
  TYPE: global.TYPE,
  logLevel,
});

const getChildLogger = (
  prefix?: string,
  opts?: { root?: string } & logdown.LogdownOptions,
): SSMLogger => {
  const { root, ...options } = opts ?? {};

  const child: SSMLogger = logdown(
    _([root ?? log.opts.prefix, prefix])
      .uniq()
      .compact()
      .join('.'),
    options,
  ) as unknown as SSMLogger;
  initLevels(child);
  log.silly(`Initialized Instance of "${child.opts.prefix}" logger`);
  return child;
};

logdown.getChildLogger = getChildLogger;
logdown.prototype.getChildLogger = function boundChildLogger(prefix, opts) {
  return getChildLogger(
    _.compact([this?.opts?.prefix, prefix]).join('.'),
    opts,
  );
};

if (global.TYPE === 'CLIENT') {
  window.logdown = logdown;
  window.log = log;
  log.verbose('Initialized **window.log** to `logdown`');
  log.verbose('Log Levels set to: ', JSON.stringify(baseLevels));
} else {
  global.logdown = logdown;
  global.log = log;
  log.verbose('Initialized **global.log** to `logdown`');
  log.verbose('Log Levels set to: ', JSON.stringify(baseLevels));
}

export default logdown;
export { log, getChildLogger };

/* eslint-disable no-param-reassign */

function initLevels(
  logInstance: SSMLogger,
  levels: Record<LogLevel, boolean> = baseLevels,
) {
  logInstance.state.isEnabled = true;
  logInstance.always = logInstance.log || logInstance.info;
  logInstance.silly = levels.silly ? logInstance.debug : () => null;
  logInstance.verbose = levels.verbose ? logInstance.debug : () => null;

  logInstance.debug =
    levels.debug && logInstance.debug ? logInstance.debug : () => null;
  logInstance.info =
    levels.info && logInstance.info ? logInstance.info : () => null;
  logInstance.warn = levels.warn ? logInstance.warn : () => null;
  logInstance.error = levels.error ? logInstance.error : () => null;
}

logdown.transports = [];
