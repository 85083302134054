import { action, observable } from 'mobx';
// import _ from 'lodash';

// const log = logdown.getChildLogger('ui.Notifications');

export default class Notifications {
  @observable
  isLoading = false;

  @observable
  selected = {};

  @action setSelected({ group } = {}) {
    this.selected = group;
  }

  @action
  setLoading(val = true) {
    this.isLoading = val;
  }

  @action
  clear() {
    this.selected = {};
  }
}
