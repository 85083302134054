import type { ICompanyOnboarding } from '@shiftsmartinc/shiftsmart-types';

import { action } from 'mobx';
import _ from 'lodash';

import BaseStore from './_baseStore';

export default class CompanyOnboardingsStore extends BaseStore<ICompanyOnboarding> {
  constructor() {
    super({
      baseItem: CompanyOnboardingsStore.BASE_ITEM,
      serviceName: 'companyOnboardings',
    });

    return this;
  }

  static BASE_ITEM: ICompanyOnboarding = {
    _modelName: null,
    attributeQuestions: null,
    company: null,
    createdAt: null,
    positionId: null,
    rejectionTags: [],
    steps: null,
    title: null,
    updatedAt: null,
    uuid: null,
  };

  @action
  updateCurrentOnboardingCount({ stepId, count, onboardingId }) {
    if (onboardingId === this.selected?.uuid) {
      _.map(this.selected.steps, (step) => {
        if (stepId === step.uuid) {
          step.partnerCount -= count;
        }
        return step;
      });
    }
  }
}
