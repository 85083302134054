import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import { observable } from 'mobx';

import BaseStore from './_baseStore';

export type ITimeOff = IBaseItem & Record<string, unknown>;

export default class TimeOffStore extends BaseStore<ITimeOff> {
  constructor({ serviceName = 'timeOff', title = 'timeOff' } = {}) {
    super({
      baseItem: TimeOffStore.BASE_ITEM,
      searchFields: ['comments'],
      serviceName,
      title,
    });

    this.log.debug('Created new "TimeOffStore"');
    return this;
  }

  static BASE_ITEM = {
    comments: null,
    company: null,
    duration: null,
    employerComments: null,
    end: null,
    fullDay: null,
    respondent: null,
    start: null,
    status: null,
    type: null,
    user: null,
    uuid: null,
  };

  @observable
  timeOffTypeNames = {
    femergency: 'Family Emergency',
    holiday: 'Holiday',
    jury: 'Jury Duty',
    other: 'Other',
    pemergency: 'Personal Emergency',
    pvacation: 'Paid Vacation',
    sick: 'Sick Leave',
    training: 'Company Training',
    uvacation: 'Unpaid Vacation',
  };
}
