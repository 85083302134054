import type { IAssignment, ICompany } from '@shiftsmartinc/shiftsmart-types';
import type { ITimeOff } from '../timeOff';

import { action, computed, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import moment from 'moment';

import { service } from '#/shared/app';
import { getChildLogger } from '#/shared/utils/client.logger';

export default class ShiftApprovalsModal {
  log = getChildLogger('ui.ShiftApprovalsModal');

  @observable
  isOpen = false;

  @observable
  isSimpleOpen = false;

  @observable
  isLoading = false;

  @observable
  selectedRefId = '';

  @observable
  selectedRefType = '';

  @observable
  selectedChildRefId = '';

  @observable
  secondarySelectedId = '';

  @observable
  company: Partial<ICompany> = {};

  @observable companyLimits = [];

  @observable userHours = {};

  @observable
  count = 0;

  @observable
  externalWorkerQualTab = 'work';

  @observable
  shift = {};

  @observable
  assignment = {};

  workerExperience = observable([]);

  @action
  async setup({
    company = {},
    shift = {},
    assignment = {},
    open = this.isOpen,
    isSimple = false,
  }) {
    this.set('company', company);
    this.set('isLoading', true);
    this.set('companyLimits', _.get(company, 'limits', []));
    this.set('shift', shift);
    this.set('assignment', assignment);

    if (isSimple) {
      this.set('isLoading', false);
      return;
    }

    const assignmentsQuery = {
      $client: { populateUser: ['companyStatus', 'certs', 'experience'] },
      $select: [
        'uuid',
        'user',
        'start',
        'end',
        'ref',
        'payment',
        'data.uuid',
        'data.title',
        'data.address',
        'data.addressRef',
        'data.slots',
        'data.assignments', // assignment.data `assignments` field removed in EP-2823
        'data.rate',
        'data.path',
        'data.requireSameWorker',
        'data.children',
        'data.__t', // assignment.data `__t` field removed in EP-2823
        'company',
        'workerRating',
        'workerNotes',
        'requestedAt',
      ],
      company: _.get(company, 'uuid', company),
      dataType: 'shift',
      status: 'PendingApproval',
    };

    const timeOffQuery = {
      $client: { populateUser: true },
      company: _.get(company, 'uuid', company),
      status: { $in: ['seen', 'sent'] },
    };

    dispatch('assignments.setShiftModal', 'approvals');
    this.open(open);
    try {
      await Promise.all([
        dispatch('assignments.loadShiftModalAssignments', {
          query: assignmentsQuery,
        }),
        dispatch('timeOff.find', timeOffQuery),
      ]);
      this.setFirst();
    } catch (error) {
      dispatch('ui.snackBar.open', error.message);
    }
    this.set('isLoading', false);
  }

  @action
  setupSimple(userId, { assignment, shift }) {
    this.isSimpleOpen = true;
    this.loadWorkerExperience(userId);
    this.setup({
      assignment,
      company: dispatch('auth.getCompany'),
      isSimple: true,
      open: false,
      shift,
    });
  }

  @action
  async getCount() {
    const log = this.log.getChildLogger('getCount');
    const company = dispatch('auth.getCompany');
    if (!_.isEmpty(company)) {
      const assignmentsQuery = {
        // ATTN: Assignment Status EP-5523
        $limit: 0,

        company: _.get(company, 'uuid', company),

        dataType: 'shift',
        status: 'PendingApproval',
      };

      const timeOffQuery = {
        $limit: 0,
        company: _.get(company, 'uuid', company),
        status: { $in: ['seen', 'sent'] },
      };

      try {
        const [assignmentResponse, timeOffResponse] = await Promise.all([
          dispatch('assignments.runQuery', assignmentsQuery),
          dispatch('timeOff.runQuery', timeOffQuery),
        ]);
        const assignmentCount = _.get(assignmentResponse, 'total', 0);
        const timeOffCount = _.get(timeOffResponse, 'total', 0);
        const count = assignmentCount + timeOffCount;
        this.set('count', count);
      } catch (error) {
        log.error('Error Loading Pending Approvals Count: ', error);
      }
    }
  }

  @action.bound
  async loadUserHours({ user, shift }) {
    const log = this.log.getChildLogger('loadUserHours');
    if (_.isEmpty(user)) {
      return false;
    }
    this.set('userHours', {});
    try {
      const [hours] = await Promise.all([
        dispatch('availability.getHoursForUser', {
          company: this.company,
          time: shift.start,
          user,
        }).catch((error) => {
          log.error('Error Loading User Hours: ', error);
          return 0;
        }),
        dispatch(
          'userSchedule.find',
          {
            company: _.get(this.company, 'uuid', this.company),
            pts: _.get(shift, 'ref', shift.uuid),
            user: _.get(user, 'uuid', user),
            week: moment(shift.start).week(),
          },
          { clear: true },
        ).catch((error) => {
          log.error('Error Loading User Schedule: ', error);
          return null;
        }),
      ]);
      this.set('userHours', hours);
    } catch (error) {
      this.set('userHours', {});
      log.error('Error loading user hours or schedule: ', error, {
        shiftId: shift.uuid,
        userId: user.uuid,
      });
    }

    return true;
  }

  @action.bound
  async loadWorkerExperience(user) {
    const log = this.log.getChildLogger('loadWorkerExperience');
    const userId = _.get(user, 'uuid', user);

    if (_.isEmpty(userId)) {
      return false;
    }
    this.set('workerExperience', []);
    try {
      const worker = await dispatch('workers.get', userId);
      this.set('workerExperience', worker.experience);
    } catch (error) {
      this.set('workerExperience', []);
      log.error('Error loading worker experience', error, {
        extra: { userId },
      });
    }
    return true;
  }

  @action.bound
  setSecondarySelectedId(id) {
    this.secondarySelectedId = id;
  }

  @action.bound
  setSelectedRefId(refId, refType) {
    this.selectedRefId = refId;
    this.selectedRefType = refType;
    this.selectedChildRefId = '';
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    _.set(this, key, value);
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action.bound
  async submit({ obj, refId, approvalAction, refType }) {
    if (/shift/i.test(refType)) {
      return this.updateAssignment(obj, approvalAction);
    }
    if (/timeOff/i.test(refType)) {
      return this.updateTimeOff(obj, approvalAction);
    }
  }

  @action.bound
  updateAssignment(assignment, approvalAction) {
    const status = /deny/i.test(approvalAction) ? 'denied' : 'approved';
    const authUser = dispatch('auth.getUser');
    const query = {
      approvals: {
        $elemMatch: {
          company: this.company.uuid,
        },
      },
    };
    const data = {
      'approvals.$.status': _.toLower(status),
      'approvals.$.updatedBy': authUser.uuid,
      uuid: assignment.uuid,
    };
    if (/deny/i.test(approvalAction)) {
      _.extend(data, {
        approvalStatus: 'denied',
        'approvals.$.deniedAt': moment().toDate(),
        status: 'Denied',
      });
    } else {
      _.extend(data, {
        'approvals.$.approvedAt': moment().toDate(),
      });
    }
    return dispatch('assignments.update', {
      data,
      query,
    });
  }

  @action.bound
  async updateTimeOff(timeOff, approvalAction) {
    const status = /deny/i.test(approvalAction) ? 'rejected' : 'approved';
    const authUser = dispatch('auth.getUser');
    const data = {
      respondent: authUser.uuid,
      status,
      uuid: timeOff.uuid,
    };
    return dispatch('timeOff.update', { data });
  }

  @computed
  get approvalGroups() {
    return _.groupBy(this.approvalList, 'ref');
  }

  @computed
  get approvalList(): Array<IAssignment | ITimeOff> {
    const assignmentsList: IAssignment[] = dispatch(
      'assignments.retrieve',
      'shiftModalList',
    ).map((assignment) => ({
      ...assignment,

      type: _.get(assignment, 'data.__t', 'shift'), // assignment.data `__t` field removed in EP-2823
    }));

    const timeOffList = dispatch('timeOff.retrieve', 'list')
      .filter((timeOff) => /sent|seen/i.test(timeOff.status))
      .map((timeOff) => _.extend({}, timeOff, { ref: 'timeOffApproval' }));

    return _(_.union(assignmentsList, timeOffList))
      .orderBy(['start'], ['asc'])
      .value();
  }

  @action.bound
  setFirst() {
    const keys = _.keys(this.approvalGroups).sort(
      (a, b) => a.length - b.length,
    );
    const selectedRefId = _.first(keys);
    const refObjs = _.get(this.approvalGroups, selectedRefId, []);
    const firstObj = _.first(refObjs) || {};
    const secondaryList = refObjs;

    const first = _.first(secondaryList);
    const type =
      (firstObj as ITimeOff).ref === 'timeOffApproval'
        ? 'timeOff'
        : firstObj.type;
    const secondarySelectedId = _.get(first, 'uuid', '');
    this.setSelectedRefId(selectedRefId, type);
    this.setSecondarySelectedId(secondarySelectedId);
    if (/shift/i.test(firstObj.type)) {
      this.loadUserHours({
        shift: firstObj,
        user: _.get(first, 'user', first),
      });
      this.loadWorkerExperience(_.get(first, 'user.uuid', first));
    }
  }

  @action.bound
  doNext({ updatedObj, refId }) {
    let newItem;
    const existing = _.get(this.approvalGroups, refId, []).filter(
      (a) => a.uuid !== updatedObj.uuid,
    );
    if (!_.isEmpty(existing)) {
      newItem = _.first(existing);
    }

    if (newItem) {
      if (/shift/i.test(newItem.type)) {
        this.loadUserHours({
          shift: newItem,
          user: _.get(newItem, 'user', newItem),
        });
      }
      return this.setSecondarySelectedId(newItem.uuid);
    }

    return this.setFirst();
  }

  @action
  clear(args: { open?: boolean } = {}) {
    this.isOpen = !!args.open;
    this.selectedRefId = '';
    this.selectedRefType = '';
    this.secondarySelectedId = '';
    this.workerExperience.clear();
    this.getCount();
  }

  @action
  clearSimple() {
    this.isSimpleOpen = false;
    dispatch('assignments.clearSelected');
  }
}
