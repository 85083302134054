import type { IPartnerRoleStat } from '@shiftsmartinc/shiftsmart-types';

import { service } from '#/shared/app';

import BaseStore from './_baseStore';

export default class PartnerRoleStatStore extends BaseStore<IPartnerRoleStat> {
  constructor() {
    super({
      baseItem: PartnerRoleStatStore.BASE_ITEM,
      serviceName: 'partnerRoleStats',
    });

    return this;
  }

  async unsuspendUser({ userId }) {
    await service(this.serviceName).patch(
      null,
      {
        userIds: [userId],
      },
      {
        query: {
          action: 'unsuspendAppealedUsers',
        },
      },
    );
  }

  static BASE_ITEM: IPartnerRoleStat = {
    createdAt: null,
    roleId: null,
    uuid: null,
  };
}
