import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';
import { mapUnifiedToSeparate, mapValuesToFields } from './formUtils';
import { nested as addrFields } from './addressFields';

const log = getChildLogger('forms.paymentAccount');

export class PaymentAccountForm extends Form {
  hooks = () => ({
    onSuccess(form) {
      const storeAction = form.values().uuid
        ? 'paymentAccounts.update'
        : 'paymentAccounts.create';

      const { user, address, ssnLastFour, dobMonth, dobDay, dobYear } =
        form.values();

      const data = {
        legalEntity: {
          address: {
            city: address.city,
            line1: address.street1,
            postal_code: address.zip,
            state: address.state,
          },
          dob: {
            day: dobDay,
            month: dobMonth,
            year: dobYear,
          },
          first_name: user.firstName,
          last_name: user.lastName,
          ssn_last_4: ssnLastFour,
          type: 'individual',
        },
        user: {
          email: user.email,
          phoneNumber: user.phoneNumber,
          uuid: user.uuid,
        },
      };

      dispatch(storeAction, { data })
        .then(() => dispatch('ui.paymentAccountModal.open', false))
        .then(() => dispatch('ui.snackBar.open', 'Payment Account Saved!'))
        .then(() => form.clear())
        .then(() => dispatch('ui.paymentAccountModal.clear'))
        .catch((err) => {
          log.error('Saving Payment Account Failed: ', err);
          form.invalidate(err.message);
          dispatch('ui.snackBar.open', err.message);
        });
    },
  });
}

// NOTE: "Unified" field definitions are not well supported. If defining a form
// in this manner, you MUST use the mapUnifiedToSeparate utility and pass them into
// the constructor in that way.
export const fields = {
  address: {
    fields: addrFields,
    label: 'Address Parent',
  },
  dobDay: {
    label: 'DOB Day',
    rules: 'required|string|min:2',
  },
  dobMonth: {
    label: 'DOB Month',
    rules: 'required|string|min:2',
  },
  dobYear: {
    label: 'DOB Year',
    rules: 'required|string|min:4',
  },
  phoneNumber: {
    label: 'Phone Number',
    rules: 'required|string|min:10',
  },
  ssnLastFour: {
    rules: 'string',
  },
  user: {
    label: 'User',
    rules: 'required',
  },

  uuid: {
    rules: 'string',
  },

  /* ----------- */
};

export function init(values = {}) {
  const fieldDef = mapUnifiedToSeparate(fields);

  const form = new PaymentAccountForm({ ...fieldDef, values });
  log.debug('Created PaymentAccount Form with Values: ', form.values());

  return form;
}
