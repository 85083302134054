import { observable, action, computed, isObservableArray } from 'mobx';
import _ from 'lodash';
import { point, featureCollection } from '@turf/helpers';
import turfCenter from '@turf/center';
import turfExtent from 'turf-extent';
import distance from '@turf/distance';
import geoViewport from '@mapbox/geo-viewport';
import { geocodeByAddress } from 'react-places-autocomplete';
import ngeohash from 'ngeohash';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('stores.ui.mapInstance');

export default class MapInstance {
  defaultCenter = { lat: 38.487129976744995, lng: -97.63761745855135 };

  defaultZoom = 4.0;

  @observable
  zoom = this.defaultZoom;

  @observable
  center = this.defaultCenter;

  @observable
  isDefault = true;

  @observable
  height = 480;

  @observable
  width = 640;

  @action
  setMapDimensions(ref) {
    log.debug('got ref: ', ref);

    const height =
      _.get(ref, 'clientHeight') ||
      _.get(ref, 'parentNode.clientHeight') ||
      _.get(ref, 'children[0].clientHeight');
    const width =
      _.get(ref, 'clientWidth') ||
      _.get(ref, 'parentNode.clientWidth') ||
      _.get(ref, 'children[0].clientWidth');

    log.debug('Setting H: %d x W: %d', height, width);

    this.height = height;
    this.width = width;
  }

  initialization = new Promise((resolve) => {
    this.initialize = resolve;
  });

  @computed
  get centerArray() {
    return [this.center.lng, this.center.lat];
  }

  @action
  async doinit({ map, clear, center, zoom }) {
    log.debug('Init map instance to ', { clear, map });
    if (clear) {
      this.clear();
    }
    if (!_.isEmpty(map)) {
      this.instance = map;

      const c = center || this.instance?.getCenter();
      const z = zoom || this.instance?.getZoom();

      log.debug('setting center: ', { c, method: 'doinit', z });
      this.instance.setCenter(c);
      log.debug('setting zoom: ', { c, method: 'doinit', z });
      this.instance.setZoom(z);
      this.initialize(this.instance);
    }

    return this;
  }

  @action
  setup({ clear, ...rest }) {
    if (clear) {
      this.clear();
    }

    this.initialization.then(() => this.reframe({ ...rest }));
    // if (this.isDefault) {
    // this.reframe({ ...rest });
    // }
  }

  @action
  reframe({ selected, things, home, center, zoom }) {
    const { defaultCenter, defaultZoom, bounds } = this.calcCenterAndZoom({
      center,
      home,
      selected,
      things,
      zoom,
    });

    // if (!_.isEqual(this.centerArray, defaultCenter)) {
    // }

    let z = defaultZoom;
    let c = defaultCenter;

    if (!_.isEmpty(bounds)) {
      try {
        // this.instance.fitBounds(bounds, { padding: 100 });

        const zoomAndCenter = geoViewport.viewport(bounds, [
          this.width,
          this.height,
        ]);

        log.verbose('Z & C', { zoomAndCenter });
        // this.instance.setCenter(zoomAndCenter.center);
        c = _.isArray(zoomAndCenter.center)
          ? { lat: zoomAndCenter.center[1], lng: zoomAndCenter.center[0] }
          : zoomAndCenter.center;
        z = zoomAndCenter.zoom;
        if (things && things.length > 5) {
          z *= 0.93;
        }
        this.isDefault = false;
      } catch (err) {
        log.error('Failed to set bounds: ', err);
      }
    } else {
      c = _.isArray(defaultCenter)
        ? { lat: defaultCenter[1], lng: defaultCenter[0] }
        : defaultCenter;
      this.isDefault = false;

      // this.instance.setCenter(this.center);

      // this.instance.setZoom(this.zoom);
    }

    this.setZoomAndCenter({ center: c, zoom: z });
  }

  setCenter(...rest) {
    this.setZoomAndCenter(...rest);
  }

  setZoom(...rest) {
    this.setZoomAndCenter(...rest);
  }

  @action
  resize() {
    this.instance.resize();
  }

  @action
  setZoomAndCenter({
    zoomIncrement = 0,
    zoom = (this.instance?.getZoom() ?? this.zoom) + zoomIncrement,
    center = this.instance?.getCenter() ?? this.center,
  }) {
    if (zoom !== this.zoom) {
      log.verbose('Setting Zoom to ', { zoom });
      this.zoom = zoom;
      _.invoke(this.instance, 'setZoom', zoom);
    }

    const centerObj = _.isArray(center)
      ? { lat: center[1], lng: center[0] }
      : center;

    if (_.filter([centerObj.lat, centerObj.lng], _.isNumber).length < 2) {
      log.debug('Center has no coordinates, canceling setzoomandcenter');
      return;
    }

    if (
      this.center.lat?.toFixed(3) !== centerObj.lat?.toFixed(3) ||
      this.center.lng?.toFixed(3) !== centerObj.lng?.toFixed(3)
    ) {
      log.verbose('Setting Center to ', { ...centerObj });
      this.center = centerObj;
      _.invoke(this.instance, 'setCenter', centerObj);
    }
  }

  @action
  flyTo({
    zoomIncrement = 0,
    zoom = (this.instance?.getZoom() ?? this.zoom) + zoomIncrement,
    center = this.instance?.getCenter() ?? this.center,
  }) {
    if (zoom !== this.zoom) {
      log.verbose('Setting Zoom to ', { zoom });
      this.zoom = zoom;
    }

    const centerObj = _.isArray(center)
      ? { lat: center[1], lng: center[0] }
      : center;

    if (_.filter([centerObj.lat, centerObj.lng], _.isNumber).length < 2) {
      log.debug('Center has no coordinates, canceling flyto');
      return;
    }

    if (
      this.center.lat?.toFixed(3) !== centerObj.lat?.toFixed(3) ||
      this.center.lng?.toFixed(3) !== centerObj.lng?.toFixed(3)
    ) {
      log.verbose('Setting Center to ', { ...centerObj });
      this.center = centerObj;
    }

    this.instance.flyTo({
      center: centerObj,
      essential: true,
      zoom,
    });
  }

  centerOnZip(zip) {
    return geocodeByAddress(zip).then((res) => {
      const geocodedData = _.first(res);
      if (_.isEmpty(res)) {
        return false;
      }
      const lat = geocodedData.geometry.location.lat();
      const lng = geocodedData.geometry.location.lng();
      return this.setCenter({ center: { lat, lng }, zoom: 11 });
    });
  }

  getMapViewportInfo({ instance = this.instance } = {}) {
    return this.getInstanceViewport({ instance });
  }

  getInstanceViewport({ instance = this.instance } = {}) {
    if (!instance || !_.isFunction(instance.getBounds)) return {};

    const zoom = instance.getZoom();
    const { _ne: ne, _sw: sw } = instance.getBounds();
    const box = [
      [ne.lng, ne.lat],
      [sw.lng, sw.lat],
    ];
    const radius = distance(...box, { units: 'miles' }) / 2;

    const { lat, lng } = instance.getCenter();

    return { box, lat, lng, radius, zoom };
  }

  @action
  refreshViewport(map = this.instance) {
    this.zoom = map?.getZoom?.() ?? this.zoom;
    this.center = map?.getCenter?.() ?? this.center;
  }

  @action
  clear() {
    this.instance = null;
    this.center = this.defaultCenter;
    this.zoom = this.defaultZoom;
    this.isDefault = true;
  }

  /** Utility Functions */
  calcMapBounds({
    things,
    workers,
    company,
    center,
    zoom,
    selected,
    boundBy = ['things', 'workers', 'company'],
  }) {
    const selectedItems = _.isArrayLikeObject(selected) ? selected : [selected];

    const { defaultCenter, defaultZoom, bounds } = this.calcCenterAndZoom({
      center,
      home: _.includes(boundBy, 'company') && company,
      selected: selectedItems,
      things: _.includes(boundBy, 'things') && things,
      workers: _.includes(boundBy, 'workers') && workers,
      zoom,
    });

    log.silly('Rendering at default center/zoom', {
      bounds,
      defaultCenter,
      defaultZoom,
    });

    const fitBounds = !_.isEmpty(bounds) ? bounds : undefined;

    const z = zoom || defaultZoom;
    let c = center || defaultCenter;

    log.silly('Rendering with z&c', {
      defaults: {
        bounds,
        defaultCenter,
        defaultZoom,
      },
      lat: c && (c.lat || c[1]),
      lng: c && (c.lng || c[0]),
      zoom: z,
    });

    if (_.isEmpty(c)) {
      c = defaultCenter ||
        _.get(selectedItems, '[0].loc.coordinates') ||
        _.get(company, 'loc.coordinates') || [
          -97.63761745855135, 38.487129976744995,
        ];

      log.silly('Changed Rendering center', {
        lat: c && (c.lat || c[1]),
        lng: c && (c.lng || c[0]),
      });
    }

    if (isObservableArray(c)) {
      c = c.slice();
    }
    if (_.isObject(c) && _.has(c, 'lat')) {
      c = [c.lng, c.lat];
    }
    return { bounds: fitBounds, center: c, zoom: z };
  }

  getCoordinatesForItem({ item }) {
    let dataType = item?.dataType;
    let coordinates = item?.coordinates;
    let addr;

    if (_.has(item, 'owner')) {
      dataType = 'company';
      coordinates = _.get(item, 'loc.coordinates');
    } else if (_.has(item, 'displayName')) {
      dataType = 'worker';
      coordinates =
        item.location?.loc?.coordinates ??
        item.loc?.coordinates ??
        item?.coordinates;
    } else if (_.has(item, 'customerDetails')) {
      dataType = 'customer';
      coordinates = _.get(item, 'customerDetails.loc.coordinates');
    } else if (_.has(item, 'store')) {
      dataType = 'store';
      coordinates = _.get(item, 'store.loc.coordinates');
    } else if (_.has(item, 'addressObj') && !_.isEmpty(item.addressObj)) {
      dataType = 'address';
      addr = item.addressObj;
      coordinates =
        _.get(addr, 'loc.coordinates') || _.get(item, 'loc.coordinates') || [];
    } else if (_.has(item, 'address') && !_.isEmpty(item.address)) {
      dataType = 'address';
      addr = item.address;
      coordinates =
        _.get(addr, 'loc.coordinates') || _.get(item, 'loc.coordinates') || [];
    } else if (_.has(item, 'location')) {
      // Thing: 'shift', 'task', etc
      dataType = 'address';

      addr = _.get(item.location);
      coordinates =
        _.get(addr, 'loc.coordinates') || _.get(item, 'loc.coordinates') || [];
    } else if (_.has(item, 'loc')) {
      // Thing: 'shift', 'task', etc
      dataType = 'unknown';

      // addr = _.get(object.location);
      addr =
        item.address &&
        !_(item.address).values().compact().isEmpty() &&
        item.address;
      coordinates = _.get(item, 'loc.coordinates') || [];
    } else if (item?.geohash && item?.count >= 1 && item?.points) {
      dataType = 'cluster';
      const coords =
        _.isEmpty(item.coordinates) && ngeohash.decode(item.geohash);
      coordinates = item.coordinates || [coords?.longitude, coords?.latitude];
    }

    return { addr, coordinates, dataType };
  }

  calcSimpleMapBound({ coordinates }) {
    if (_.size(coordinates) <= 1) {
      return {};
    }
    const points = featureCollection(
      _.map(coordinates, (coordinate) => point(coordinate)),
    );

    return {
      bounds: turfExtent(points),
      center: turfCenter(points)?.geometry?.coordinates,
    };
  }

  calcCenterAndZoom({ selected, things, home, center, zoom }) {
    let defaultCenter = [];
    let defaultZoom = zoom || 8;

    let bounds;
    if (things && things.length > 1) {
      const coords = _(things)
        .reject((thing) => {
          const { coordinates } = this.getCoordinatesForItem({ item: thing });

          return _(coordinates).compact().isEmpty();
        })
        .map(({ loc }) => point(loc.coordinates))
        .value();

      bounds = turfExtent(featureCollection(coords));
    }

    let boundsObj;
    if (!_.isEmpty(bounds)) {
      boundsObj = {
        _ne: { lat: bounds[3], lng: bounds[2] },
        _sw: { lat: bounds[1], lng: bounds[0] },
      };
    }

    if (_.isEmpty(bounds)) {
      defaultZoom = 4;
    }

    if (!_(center).compact().isEmpty()) {
      log.verbose('Setting Center');
      defaultCenter = center;
    }

    if (_.isEmpty(defaultCenter)) {
      if (selected && selected.length === 1) {
        const item = _.first(selected);
        defaultCenter = this.getCoordinatesForItem({
          item,
        }).coordinates;
      } else if (selected && selected.length > 1) {
        const points = _(selected)
          .map((i) => this.getCoordinatesForItem({ item: i }).coordinates)
          .filter((o) => !!o)
          .map((o) => o.slice())
          .map((o) => point(o))
          .value();
        const newCenter = turfCenter(featureCollection(points));
        defaultCenter = _.get(newCenter, 'geometry.coordinates', defaultCenter);
      } else if (
        selected &&
        !!selected.uuid &&
        !_(this.getCoordinatesForItem({ item: selected }).coordinates)
          .compact()
          .isEmpty()
      ) {
        defaultCenter = selected.loc.coordinates;
      } else if (_.isEmpty(selected)) {
        const points = _(things)
          .map((i) => this.getCoordinatesForItem({ item: i }).coordinates)
          .filter((o) => !!o)
          .map((o) => o.slice())
          .map((o) => point(o))
          .value();

        if (!_.isEmpty(points)) {
          const newCenter = turfCenter(featureCollection(points));
          defaultCenter = _.get(
            newCenter,
            'geometry.coordinates',
            defaultCenter,
          );
        }
      }
    }

    if (_.isEmpty(defaultCenter) && !_.isEmpty(home)) {
      defaultCenter = this.getCoordinatesForItem({
        item: home,
      }).coordinates;
    }

    if (_.isEmpty(defaultCenter)) {
      log.debug('Unable to find center for items. Using defaults', {
        home,
        selected,
        things,
      });
      defaultCenter = [-97.63761745855135, 38.487129976744995];
      defaultZoom = 8;
    }

    if (_.isObject(defaultCenter) && _.has(defaultCenter, 'lat')) {
      defaultCenter = [defaultCenter.lng, defaultCenter.lat];
    }

    return {
      bounds: _.filter(bounds, _.isFinite),
      boundsObj,
      box: !_.isEmpty(boundsObj) && [
        [bounds[0], bounds[1]],
        [bounds[2], bounds[3]],
      ],
      defaultCenter: defaultCenter.slice(),
      defaultZoom,
    };
  }
}
