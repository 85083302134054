import type { IAuditLog } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { action, observable } from 'mobx';

import BaseStore from './_baseStore';

export default class AuditLogStore extends BaseStore<IAuditLog> {
  constructor() {
    super({
      baseItem: {},
      modelName: 'auditlog',
      serviceName: 'auditLog',
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  @observable
  auditLogOfTarget = true;

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    this[key] = value;
  }

  @action
  clear() {
    super.emptyList();
    this.auditLogOfTarget = true;
  }
}
