import type {
  INotificationPref,
  IPool,
  IShift,
  IThing,
  IWorker,
  IDispatchPrefTemplate,
} from '@shiftsmartinc/shiftsmart-types';

import { action, computed, IObservableArray, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import moment from 'moment';
import Promise from 'bluebird';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';

import {
  getNotificationPrefsData,
  unzipNotificationPrefs,
} from '#/utils/notificationPrefs';
import { getChildLogger } from '#/shared/utils/client.logger';
import { getStore } from '#/shared/getStores';

const isArrayEqual = (x, y) => _(x).xorWith(y, _.isEqual).isEmpty();

const log = getChildLogger('ui.shiftInvitations');

type FormType = 'shift' | 'dispatchPref';

export default class ShiftInvitations {
  @observable
  formType: FormType = 'shift';

  @observable
  isLoading = false;

  @observable
  isSaving = false;

  @observable
  notificationPrefs: IObservableArray<
    Partial<INotificationPref> & {
      isDirty?: boolean;
      partners?: Array<IWorker>;
      pools?: Array<IPool>;
    }
  > = [];

  @observable
  initialNotificationPrefs = [];

  @observable
  listenerId = null;

  @observable
  shiftId = '';

  @observable
  templateId = null;

  @observable
  dispatchPrefModalOpen = false;

  @observable
  dispatchPrefTitle = '';

  @observable
  recommendedPartnersList: Array<
    IWorker & { emphasis: string; pluralReason: string; reason: string }
  > = [];

  @observable
  isRecommendedInvitationsOpen = true;

  @observable
  shouldSetupOnShiftUpdate = true;

  @observable
  addInvitationGroupDelayValue = 0;

  @action.bound
  async setup({
    shiftId,
    notificationPrefs = [],
    template,
    isTemplate = !_.isEmpty(template),
    formType = 'shift',
  }: {
    formType?: FormType;
    isTemplate?: boolean;
    notificationPrefs?: IThing['notificationPrefs'];
    shiftId?: IThing['uuid'];
    template?: IDispatchPrefTemplate;
  }) {
    this.formType = formType;
    this.setLoading(true);
    this.recommendedPartnersList = [];
    if (isTemplate) {
      this.templateId = template.uuid;
      this.setDispatchPrefTitle(template.title);
      this.setPrefTypes(template.prefTypes);
    }

    const existingNPs = _.cloneDeep(this.notificationPrefs);
    const unsentPrefIds = _(this.notificationPrefs)
      .filter((np) => !np.sentAt)
      .map('prefID')
      .value();

    const bonusPrefs = _(existingNPs)
      .filter({ category: 'dynamicBonus' })
      .map(_.cloneDeep)
      .value();

    // TODO: Extract this logic into Store-agnostic

    // Ignore any pref levels arising from "user added to pool" events
    const filteredNotificationPrefs: Array<INotificationPref> =
      notificationPrefs.filter((pref) => !/Auto-Invited/i.test(pref.note));

    const newPrefs = _.map(filteredNotificationPrefs, (pref) => {
      const translatedPref: ShiftInvitations['notificationPrefs'][0] = _.extend(
        { ignoreRestrictions: !!isTemplate, templateId: template?.uuid },
        _.pick(pref, [
          'approval',
          'approvalType',
          'delay',
          'prefID',
          'defaultAssignmentAction',
          'category',
          'sentAt',
          'isPreferred',
          'ignoreRestrictions',
          'status',
          'templateId',
          'isDeleted',
          'pool',
          'users',
          'maxRadius',
          'baselineTime',
          // Dynamic Bonus Fields
          'isActive',
          'slug',
          'poolIds',
          'settings',
          // Event & Status Fields
          'processedIndex',
          'log',
          'note',
        ]),
      );

      if (isTemplate) {
        translatedPref.status = 'draft';
      }

      return translatedPref;
    });

    log.debug('New Prefs: ', {
      deleted: _.map(newPrefs, 'isDeleted'),
      newPrefs,
      status: _.map(newPrefs, 'status'),
    });

    let preservedNPs = [];

    if (formType === 'shift') {
      preservedNPs = _(existingNPs)
        .map((np) => {
          let isDeleted;
          let isDirty;

          if (isTemplate) {
            if (
              !!_.find(bonusPrefs, { prefID: np.prefID }) &&
              !_.includes(template.prefTypes, 'dynamicBonus')
            ) {
              log.debug('preserving bonus pref');
            } else if (_.includes(unsentPrefIds, np.prefID)) {
              isDeleted = template.uuid !== np.templateId;
              isDirty = true;
            }
          } else {
            isDirty = false;
          }

          return {
            ...np,
            isDeleted,
            isDirty,
          } as ShiftInvitations['notificationPrefs'][0];
        })
        .reject((np) => isTemplate && np.isDeleted && np.status === 'draft')
        .value();
    }

    log.debug('Preserved Prefs: ', {
      deleted: _.map(preservedNPs, 'isDeleted'),
      preservedNPs,
      status: _.map(preservedNPs, 'status'),
    });

    const mergedNPs = await Promise.all(
      _([...newPrefs, ...preservedNPs])
        .groupBy('prefID')
        .map((rawNPs) => {
          const tmp = rawNPs.shift();
          while (_.size(rawNPs)) {
            _.merge(tmp, rawNPs.shift());
          }

          return tmp;
        })
        .map(async (pref) => {
          let partners = pref.partners;
          let pools = pref.pools;
          let excludedPools = pref.excludedPools;

          if (!pref.isDeleted) {
            [partners, pools, excludedPools] = await Promise.all([
              partners || this.getPartners(pref.users),
              pools ||
                this.getPools(_.compact([pref.pool, ...(pref.poolIds ?? [])])),
              excludedPools ||
                this.getPools(
                  _(pref.poolIds)
                    .filter((poolId) => /^[!].+/.test(poolId))
                    .map((poolId) => poolId.replace('!', ''))
                    .value(),
                  false,
                ),
            ]);
          }

          return { ...pref, excludedPools, partners, pools };
        })
        .value(),
    );

    log.debug('setting prefs', {
      deleted: _.map(mergedNPs, 'isDeleted'),
      nps: mergedNPs,
      status: _.map(mergedNPs, 'status'),
    });

    this.setNotificationPrefs(mergedNPs);

    if ((shiftId && this.shiftId !== shiftId) || formType === 'dispatchPref') {
      // TODO: validate that this is only set if truly initializing
      this.setInitialNotificationPrefs();
    }

    if (!_.isEmpty(shiftId)) {
      this.initEvents({ shiftId });
      await this.getRecommendedPartners(this.shiftId);
    }

    this.setLoading(false);
  }

  protected initEvents({ shiftId }) {
    this.shiftId = shiftId;
    if (this.listenerId) {
      return;
    }

    const listenerId = dispatch('shifts.registerEventListener', {
      onPatched: this.handleShiftUpdate.bind(this),
      onUpdated: this.handleShiftUpdate.bind(this),
    });

    this.setListenerId(listenerId);
  }

  async getPools(poolIds, populateUserAvatars = true) {
    let pools = [];
    if (!_.isEmpty(poolIds)) {
      try {
        const poolsStore = getStore('pools');
        const { data } = await poolsStore.runQuery({
          query: {
            $client: { populateUserAvatars },
            uuid: { $in: poolIds },
          },
        });
        pools = data;
      } catch (error) {
        log.error('Error getting pool for Notification Pref: ', error, {
          extra: {
            poolIds,
          },
        });
      }
    }

    return pools ?? [];
  }

  async getPartners(userIds) {
    let partners = [];

    if (!_.isEmpty(userIds)) {
      try {
        const response = await dispatch('partners.runQuery', {
          uuid: { $in: userIds },
        });
        partners = _.get(response, 'data', []);
      } catch (error) {
        log.error('Error fetching partners for Notification Pref: ', error, {
          extra: {
            userIds,
          },
        });
      }
    }

    return partners ?? [];
  }

  @computed
  get hasDirtyNPs() {
    return (
      this.notificationPrefsChanged(
        this.notificationPrefs,
        this.initialNotificationPrefs,
      ) || !_.isEmpty(this.dirtyNotificationPrefs)
    );
  }

  @computed
  get isTemplateDirty() {
    const dispatchPrefs = dispatch('dispatchPrefs.retrieve', 'selected');
    if (_.isEmpty(dispatchPrefs)) return true;
    return !_.isEmpty(
      _.differenceBy(this.notificationPrefs, dispatchPrefs.prefs, 'prefID'),
    );
  }

  @computed
  get addedNotificationPrefs() {
    return _.differenceBy(
      this.notificationPrefs,
      this.initialNotificationPrefs,
      'prefID',
    );
  }

  @computed
  get removedNotificationPrefs() {
    return _.differenceBy(
      this.initialNotificationPrefs,
      this.notificationPrefs,
      'prefID',
    );
  }

  @computed
  get dirtyNotificationPrefs() {
    return this.notificationPrefs.filter(
      (pref) =>
        pref.isDirty &&
        _.find(this.initialNotificationPrefs, { prefID: pref.prefID }),
    );
  }

  @computed
  get notificationPrefGroups() {
    return _(this.notificationPrefs)
      .filter(
        (n) =>
          !n.category ||
          n.category === 'dynamicBonus' ||
          n.category === 'workFrequency',
      ) // Conflict group
      .groupBy((n) => (n.sentAt ? 'sent' : 'pending'))
      .value();
  }

  @computed
  get notificationPrefsSent() {
    return _(_.get(this.notificationPrefGroups, 'sent', []))
      .orderBy(['sentAt'], ['asc'])
      .value();
  }

  @computed
  get notificationPrefsPending() {
    return _.get(this.notificationPrefGroups, 'pending', []);
  }

  @computed
  get currentlySelectedTemplate() {
    const t = _(this.notificationPrefs)
      .reject('isDeleted')
      .map('templateId')
      .uniq()
      .value();

    return _.size(t) === 1 ? _.first(t) : null;
  }

  getNotificationPrefs() {
    return this.notificationPrefs;
  }

  @computed
  get recommendedPartners() {
    const notificationPrefs = dispatch(
      'ui.shiftInvitations.retrieve',
      'notificationPrefs',
    );

    return _.filter(
      this.recommendedPartnersList,
      (p) =>
        !_.some(notificationPrefs, (np) =>
          _.find(np.partners, { uuid: p.uuid }),
        ) &&
        !_.some(notificationPrefs, (np) =>
          _.some(np.pools, (pool) =>
            _.some(
              p.pools,
              (partnerPool) =>
                partnerPool.uuid === pool.uuid &&
                moment(partnerPool.addedAt).isSameOrBefore(moment(np.sentAt)),
            ),
          ),
        ),
    );
  }

  @computed
  get recommendedInvitations(): Array<{
    emphasis: string;
    partners: Array<IWorker>;
    pluralReason: string;
    reason: string;
  }> {
    return _.transform(
      _.groupBy(this.recommendedPartners, 'reason'),
      (acc, value, key) => {
        acc.push({
          emphasis: value[0].emphasis,
          partners: value,
          pluralReason: value[0].pluralReason,
          reason: key,
        });
      },
      [],
    );
  }

  /* Setters */

  @action
  setLoading(loading) {
    this.isLoading = loading;
  }

  @action
  setSaving(saving) {
    this.isSaving = saving;
  }

  @action
  setListenerId(id) {
    this.listenerId = id;
  }

  @action
  setInitialNotificationPrefs(prefs = _.cloneDeep(this.notificationPrefs)) {
    this.initialNotificationPrefs = prefs;
  }

  @action
  setNotificationPrefs(prefs) {
    this.notificationPrefs?.replace(prefs);
  }

  @action
  resetNotificationPrefs() {
    this.setNotificationPrefs(_.cloneDeep(this.initialNotificationPrefs));
  }

  @action
  toggleRecommendedInvitations(open = !this.isRecommendedInvitationsOpen) {
    this.isRecommendedInvitationsOpen = open;
  }

  @action
  setShouldSetupOnShiftUpdate(should) {
    this.shouldSetupOnShiftUpdate = should;
  }

  /* Edit NPs */

  @action
  addNotificationPref({
    pools = [],
    partners = [],
    ignoreRestrictions = true,
    delay = 0,
    ...rest
  }) {
    this.notificationPrefs.push({
      approval: false,
      delay,
      ignoreRestrictions,
      partners,
      pools,
      prefID: uuid(),
      status: 'draft',
      ...rest,
    });
    if (this.formType === 'shift') {
      dispatch('dispatchPrefs.clearSelected');
    }
    this.updateDefaultAddInvitationDelay();
  }

  @action
  addDynamicPoolPref({ delay = 0, dynamicPoolCategory }) {
    this.notificationPrefs.push({
      approval: false,
      category: dynamicPoolCategory,
      delay,
      prefID: uuid(),
      status: 'draft',
    });
    if (this.formType === 'shift') {
      dispatch('dispatchPrefs.clearSelected');
    }
    this.updateDefaultAddInvitationDelay();
  }

  @action
  removeNotificationPref(prefID) {
    _.remove(this.notificationPrefs, { prefID });
    dispatch('dispatchPrefs.clearSelected');
    this.updateDefaultAddInvitationDelay();
  }

  @action
  addToNotificationPref({ pools = [], partners = [], prefID }) {
    if (_.isEmpty(prefID)) return;

    // Remove partners from other notification prefs
    partners.forEach((p) => {
      this.notificationPrefs.forEach((np) => {
        if (np.prefID !== prefID && _.find(np.partners, { uuid: p.uuid })) {
          _.remove(np.partners, { uuid: p.uuid });
        }
      });
    });

    // Remove pools from other notification prefs
    pools.forEach((p) => {
      this.notificationPrefs.forEach((np) => {
        if (np.prefID !== prefID && _.find(np.pools, { uuid: p.uuid })) {
          _.remove(np.pools, { uuid: p.uuid });
        }
      });
    });

    const existingPref = _.find(this.notificationPrefs, { prefID });
    existingPref.pools = _.uniqBy(_.concat(pools, existingPref.pools), 'uuid');
    existingPref.partners = _.uniqBy(
      _.concat(partners, existingPref.partners),
      'uuid',
    );
  }

  // Accepts pool or partner as object or uuid and removes
  // from notificationPref specified by prefID
  // If no prefID, search all prefs and remove
  @action
  removeFromNotificationPref({ prefID, pool, partner }) {
    log.debug('removeFromNotificationPref', pool, partner);

    let allPrefs = this.notificationPrefs;
    if (!_.isEmpty(prefID)) {
      const pref = _.find(this.notificationPrefs, { prefID });
      if (_.isEmpty(pref))
        log.error('Could not find notificationPref with prefID: ', prefID);
      allPrefs = [pref];
    }

    allPrefs.forEach((pref) => {
      if (!_.isEmpty(pool)) {
        _.remove(pref.pools, { uuid: pool.uuid || pool });
      }

      if (!_.isEmpty(partner)) {
        _.remove(pref.partners, { uuid: partner.uuid || partner });
      }

      // Delete notificationPref if no partners left
      if (_.isEmpty(pref.pools) && _.isEmpty(pref.partners)) {
        _.remove(this.notificationPrefs, { prefID });
      }
    });
  }

  @action
  updateNotificationPref({
    prefID,
    key,
    value,
    obj = { [key]: value },
  }: {
    key?: keyof INotificationPref;
    obj?: Partial<INotificationPref>;
    prefID: INotificationPref['prefID'];
    value?: unknown;
  } & (
    | {
        key: keyof INotificationPref;
        obj?: never;
        value: unknown;
      }
    | {
        key?: never;
        obj: Partial<INotificationPref>;
        value?: never;
      }
  )) {
    if (this.formType === 'shift') {
      dispatch('dispatchPrefs.clearSelected');
    }
    const npIndex = _.findIndex(this.notificationPrefs, { prefID });

    if (npIndex !== -1) {
      _.extend(this.notificationPrefs[npIndex], obj);

      const og = _.find(this.initialNotificationPrefs, { prefID });

      if (!_.isEqual(_.pick(og, _.keys(obj)), obj)) {
        this.notificationPrefs[npIndex].isDirty = true;
      }
    } else {
      log.error('Unable to ID Selected NP', { prefID, shiftId: this.shiftId });
    }

    this.updateDefaultAddInvitationDelay();
  }

  @action
  updateDefaultAddInvitationDelay() {
    const optionIndex = _.findIndex(this.delayOptions, {
      value: this.currentMaxDelay,
    });
    if (optionIndex + 1 < this.delayOptions.length) {
      this.addInvitationGroupDelayValue =
        this.delayOptions[optionIndex + 1].value;
    }
  }

  @action
  async saveUpdatedNotificationPrefs({ shift }) {
    if (this.isSaving) {
      return;
    }
    this.setSaving(true);

    let response;

    try {
      this.setLoading(true);
      // patch removed notificationPrefs
      log.debug('Removed Notification Prefs:', this.removedNotificationPrefs);
      if (!_.isEmpty(this.removedNotificationPrefs)) {
        const removeData = {
          $pull: {
            notificationPrefs: {
              prefID: {
                $in: this.removedNotificationPrefs.map((np) => np.prefID),
              },
            },
          },
        };
        response = await dispatch(`${_.get(shift, '__t')}s.update`, {
          data: removeData,
          id: shift.uuid,
        });

        log.debug('Removed NPs: ', { removeData, response });
      }

      // If a notification has been updated (for example, send time was changed)
      if (this.hasDirtyNPs && _.size(this.dirtyNotificationPrefs)) {
        // Get and save the updated notification preferences
        await Promise.mapSeries(this.dirtyNotificationPrefs, async (pref) => {
          const initialPref = _.find(this.initialNotificationPrefs, {
            prefID: pref.prefID,
          });
          const patchQuery = {
            notificationPrefs: {
              $elemMatch: { prefID: pref.prefID },
            },
          };

          const patchData = {
            'notificationPrefs.$.delay': pref.delay,
            'notificationPrefs.$.templateId': pref.templateId,
            uuid: shift.uuid,
          };
          if (_.has(pref, 'isDeleted')) {
            patchData['notificationPrefs.$.isDeleted'] = pref.isDeleted;

            if (pref.isDeleted && initialPref?.status !== 'canceled') {
              patchData['notificationPrefs.$.status'] = 'canceled';
            } else if (
              !pref.isDeleted &&
              initialPref?.isDeleted &&
              initialPref?.status === 'canceled'
            ) {
              patchData['notificationPrefs.$.status'] = 'created';
            }
          }

          if (
            !initialPref ||
            !moment(pref.baselineTime).isSame(
              initialPref.baselineTime || undefined,
              'minute',
            ) ||
            (pref.baselineTime === null && !!initialPref.baselineTime)
          ) {
            _.extend(patchData, {
              'notificationPrefs.$.baselineTime': pref.baselineTime,
            });
          }

          log.debug('Patching NP %s with data', pref.prefID, { patchData });
          response = await dispatch(`${_.get(shift, '__t')}s.update`, {
            data: patchData,
            id: shift.uuid,
            query: patchQuery,
          });
        });

        log.debug(
          'Updated %d Dirty NPs: ',
          _.size(this.dirtyNotificationPrefs),
          {
            response: _.map(response, 'notificationPrefs'),
          },
        );
      }

      const newNotificationPrefs = getNotificationPrefsData({
        data: shift,
        prefs: this.addedNotificationPrefs,
      });

      if (!_.isEmpty(newNotificationPrefs)) {
        const data = {
          $push: {
            notificationPrefs: {
              $each: newNotificationPrefs,
            },
          },
        };
        log.debug('Pushing %d NPs with data', _.size(newNotificationPrefs), {
          data,
        });
        response = await dispatch(`${_.get(shift, '__t')}s.update`, {
          data,
          id: shift.uuid,
        });

        log.debug('pushed NP', {
          np: _.find(response.notificationPrefs, {
            prefID: _.first(newNotificationPrefs).prefID,
          }),
          nps: response.notificationPrefs,
        });
      }

      log.debug('Patch Results', { response });

      if (_.has(response, 'notificationPrefs')) {
        await this.setup({
          notificationPrefs: response.notificationPrefs,
        });
        this.setInitialNotificationPrefs();
      }
    } catch (error) {
      log.error('Error Updating Shift Notification Prefs: ', error);
    } finally {
      this.setLoading(false);
      this.setSaving(false);
    }
  }

  /** @deprecated please use the `getPreferredWorkers` param instead */
  @action
  async getRecommendedPartners(shiftId) {
    log.warn(
      'getRecommendedPartners is deprecated, use getPreferredWorkers on the API instead',
    );
    const partners = await dispatch('partners.runQuery', {
      $client: {
        getRecommendedPartnersForShift: shiftId,
      },
    });
    log.debug('Got recommended partners:', partners);
    this.setRecommendedPartners(partners);
  }

  @action
  setRecommendedPartners(partners) {
    this.recommendedPartnersList = partners;
  }

  /* Events */

  @action
  handleShiftUpdate(shift: IShift) {
    if (shift.uuid === this.shiftId && !this.isSaving) {
      if (this.shouldSetupOnShiftUpdate) {
        log.debug('processing shift change event');
        if (
          this.notificationPrefsChanged(
            shift.notificationPrefs,
            this.notificationPrefs,
          )
        ) {
          this.setup({ notificationPrefs: shift.notificationPrefs });
        }
      }

      this.setInitialNotificationPrefs(shift.notificationPrefs);
    }
  }

  /* Dispatch Prefs */
  @action
  toggleDispatchPrefModal(
    open: boolean,
    opts?: {
      prefTypes?: IDispatchPrefTemplate['prefTypes'][0];
      resetTitle?: boolean;
    },
  ) {
    const { resetTitle = true, prefTypes } = opts ?? {};
    this.dispatchPrefModalOpen = open;
    this.setPrefTypes(prefTypes ?? []);
    if (resetTitle) {
      this.setDispatchPrefTitle('');
    }
  }

  prefTypes = observable.array<IDispatchPrefTemplate['prefTypes'][0]>([]);

  @action
  setPrefTypes(prefTypes) {
    this.prefTypes.replace(prefTypes ?? []);
  }

  @action
  setDispatchPrefTitle(value) {
    this.dispatchPrefTitle = value;
  }

  @action.bound
  async saveDispatchPref() {
    const company = dispatch('auth.getCompany');
    if (!company.uuid) {
      return false;
    }

    // TODO: combine with `notificationPrefs.getNotificationPrefsData`
    const prefs = _(this.notificationPrefs)
      .reject({ isDeleted: true })
      .map(unzipNotificationPrefs)
      .flatten()
      .map((pref) =>
        _.extend(
          {
            prefID: uuid(),
          },
          _.pick(pref, [
            'category',
            'prefID',
            'delay',
            'isPreferred',
            'pool',
            'uuid',
            'users',
            'poolIds',
            'settings',
            'maxRadius',
            // Dynamic Bonus Fields
            'isActive',
            'slug',
            'poolIds',
            'settings',
          ]),
        ),
      )
      .value();

    const data = {
      companies: [company.uuid],
      prefTypes: this.prefTypes,
      prefs,
      title: this.dispatchPrefTitle,
    };
    try {
      if (this.formType === 'shift') {
        const response = await dispatch('dispatchPrefs.create', { data });
        dispatch('dispatchPrefs.setSelected', response);
      } else if (this.formType === 'dispatchPref') {
        const response = await dispatch('dispatchPrefs.update', {
          data,
          id: this.templateId,
        });

        this.setup({
          ...this,
          notificationPrefs: response.prefs,
          template: response,
        });
      }
    } catch (e) {
      toast(e.message, { type: 'error' });
    }
    return this.toggleDispatchPrefModal(false);
  }

  /* Helpers */

  getCoreNPFields = (np) => _.omit(np, ['isDirty', 'partners', 'pools']);

  notificationPrefsChanged = (np1, np2) => {
    if (np1.length !== np2.length) {
      return true;
    }

    const one = _(np1).sortBy(['prefID']).map(this.getCoreNPFields).value();
    const two = _(np2).sortBy(['prefID']).map(this.getCoreNPFields).value();

    const npsEqual = isArrayEqual(one, two);
    return !npsEqual;
  };

  @action
  clear() {
    this.notificationPrefs = [];
    this.initialNotificationPrefs = [];
    this.recommendedPartnersList = [];
    this.isLoading = false;
    this.isSaving = false;
    this.shiftId = '';
    this.dispatchPrefModalOpen = false;
    this.dispatchPrefTitle = '';
    this.formType = 'shift';

    if (this.listenerId) {
      log.warn('Clearing Listener ID', this.listenerId);
      dispatch('shifts.deregisterEventListener', this.listenerId);
      this.listenerId = null;
    }
  }

  retrieve(key) {
    return _.get(this, key);
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    this[key] = value;
  }

  @computed
  get currentMaxDelay() {
    let maxDelay = 0;
    this.notificationPrefs.forEach((np) => {
      if (np.delay > 0) maxDelay = np.delay;
    });
    return maxDelay;
  }

  @computed
  get delayOptions() {
    return [
      {
        key: 0,
        text: 'ASAP',
        value: 0,
      },
      {
        key: 1,
        text: 'In 1 Hour',
        value: 60,
      },
      {
        key: 2,
        text: 'In 2 Hours',
        value: 120,
      },
      {
        key: 3,
        text: 'In 4 Hours',
        value: 240,
      },
      {
        key: 4,
        text: 'In 8 Hours',
        value: 480,
      },
      {
        key: 5,
        text: 'In 12 Hours',
        value: 720,
      },
      {
        key: 6,
        text: 'In 18 Hours',
        value: 1080,
      },
      {
        key: 7,
        text: 'In 1 Day',
        value: 1440,
      },
      {
        key: 8,
        text: 'In 2 Days',
        value: 2880,
      },
    ];
  }
}
