import { action, observable } from 'mobx';
// import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class AvatarEditor {
  log = getChildLogger('ui.AvatarEditor');

  @observable
  editor = null;

  @observable
  image = null;

  @observable
  scale = 1.2;

  @observable
  angle = 0;

  @observable
  width = 250;

  @observable
  height = 250;

  @observable
  baseBlob = null;

  fileRef = null;

  @action
  setup({ imageURL, onSave, fileRef, width = 250, height = 250, scale }) {
    this.onSave = onSave;

    // hhmmmm
    // this.isEditing = open;

    if (scale) {
      this.scale = scale;
    } else if (!_.isEmpty(imageURL)) {
      this.image = imageURL;
      this.scale = 1.0;
    } else {
      this.scale = 1.2;
    }

    this.width = width;
    this.height = height;
    this.fileRef = fileRef || null;
  }

  @action
  save({ data = this.getImageBlob } = {}) {
    const log = this.log.getChildLogger('save');
    if (_.isFunction(this.onSave)) {
      return this.onSave({ data }).catch((err) => {
        log.error('failed to save image', err);
      });
    }

    log.error('No Save function defined');
    return Promise.reject('No Save Function Defined');
  }

  @action
  clear() {
    this.scale = 1.2;
    this.angle = 0;
    this.image = null;
    this.editor = null;
    this.baseBlob = null;
  }

  // Migrated

  getFileRef() {
    return this.fileRef;
  }

  getImageBlob(fileType) {
    // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
    // drawn on another canvas, or added to the DOM.
    // const canvas = this.editor.getImage();

    // If you want the image resized to the canvas size (also a HTMLCanvasElement)
    const canvasScaled = this.editor.getImageScaledToCanvas();

    const imageBlob = canvasScaled.toDataURL(fileType);

    return {
      canvas: canvasScaled,
      data: imageBlob,
      isDirty: imageBlob !== this.baseBlob,
    };
  }

  @action
  setImage(image) {
    this.image = image;
  }

  @action
  setEditor = (ref) => {
    this.editor = ref;
  };

  handleNewImage = (e) => {
    this.setImage(e.target.files[0]);
  };

  @action
  setBaseImage() {
    if (this.baseBlob) return;
    this.baseBlob = this.getImageBlob().data;
  }

  @action
  toggleEdit() {
    this.isEditing = !this.isEditing;
  }

  @action
  rotate(degrees) {
    const log = this.log.getChildLogger('rotate');
    this.angle += degrees;
    log.debug('Rotating %d° to %d', degrees, this.angle);
  }

  @action
  handleScale(value) {
    this.scale = value;
  }

  @action
  setHeight(value) {
    this.height = _.toNumber(value);
  }

  @action
  setWidth(value) {
    this.width = _.toNumber(value);
  }
}
