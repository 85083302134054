import type { IconProps } from 'semantic-ui-react';
import type { IUser } from '@shiftsmartinc/shiftsmart-types';

import Promise from 'bluebird';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { service } from '#/shared/app';

import BaseStore from './_baseStore';

export type UserRoleDDLOption = {
  description?: string;
  disabled?: boolean;
  icon?: IconProps['name'] | IconProps;
  key: string;
  text: string;
};
export default class UserStore extends BaseStore<IUser> {
  constructor({
    serviceName = 'user',
    title,
    baseItem = {},
    searchFields = [],
  }) {
    super({
      baseItem: { ...UserStore.BASE_ITEM, ...baseItem },
      searchFields: _.union(searchFields, [
        'displayName',
        'email',
        'phoneNumber',
      ]),
      serviceName,
      title: _.compact(_.uniq(['user', title])).join('.'),
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  static BASE_ITEM = {
    address: {
      city: null,
      state: null,
      street1: null,
      street2: null,
      zip: null,
    },
    addressString: null,
    certs: [],
    companies: [],
    companyStatus: [],
    displayName: null,
    email: null,
    firstName: null,
    isDeleted: false,
    lastName: null,
    loc: {},
    phoneNumber: null,
    pools: [],
    profileImageURL: null,
    roles: [],
    tags: [],
    username: null,
  };

  /** findByCompany
   * @notImplemented
   *
   * This implementation is here to force child stores to implement this functionality for
   * themselves since the required query is substantially different for employers and partners.
   *
   * By assigning it to `noop` the consumer can check for equality while still keeping the
   * method definition for handling by the dispatcher.
   */
  findByCompany = _.noop;

  setAvatar({ userId, data }) {
    const log = this.log.getChildLogger('setAvatar');
    if (_.isEmpty(userId)) {
      return Promise.reject('Must specify UserID');
    }

    const authUser = dispatch('auth.getUser');

    if (!authUser || !(userId === authUser.uuid || authUser.isAdmin)) {
      return Promise.reject('Not Authorized');
    }

    return service('user')
      .patch(userId, { profileImage: data })
      .then((res) => {
        log.debug('Uploaded Image! Response:', res);
        return res;
      })
      .catch((err) => {
        log.error('Failed Uploading Image', err);
      });
  }

  getUserRolesOptions() {
    return [
      'user',
      'worker',
      'employer',
      {
        description:
          'This role is required for agents but considered deprecated',
        key: 'company-agent',
        text: 'Company Agent',
      },
      {
        description: 'SSM Admin can be added from the `admin/employers` route',
        disabled: true,
        icon: 'lock',
        key: 'admin',
      },
      {
        description: 'SSM Admin can be added from the `admin/employers` route',
        disabled: true,
        icon: 'lock',
        key: 'super-admin',
      },
      {
        description:
          'This role is required for external users to access resources like the Customer Portal',
        disabled: false,
        key: 'external',
        text: 'External Portal User',
      },
    ].map((val) =>
      _.isObject(val)
        ? _.extend(
            { key: val.key, text: _.capitalize(val.key), value: val.key },
            val,
          )
        : { key: val, text: _.capitalize(val), value: val },
    );
  }

  setCertFilter = _.noop;

  setAdminFilter = _.noop;

  setFilter = _.noop;
}
