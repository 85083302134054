import PaymentRulesStore from './paymentRules';

export default class PayDifferentialsStore extends PaymentRulesStore {
  constructor({
    serviceName = 'paymentRules/differentials',
    baseItem = {},
    ...rest
  } = {}) {
    super({
      baseItem: { ...PayDifferentialsStore.BASE_ITEM, ...baseItem },
      serviceName,
      ...rest,
    });

    return this;
  }

  static get BASE_ITEM() {
    return {
      days: null,
      differential: 1.0,
      hours: null,
      source: '',
    };
  }
}
