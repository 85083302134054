import { observable, action } from 'mobx';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.workerTagFilter');

export default class WorkerTagFilter {
  @observable
  selectedQuals = [];

  @observable
  selectedPositions = [];

  @action
  setTags({ tags, values, tagType = 'qual' }) {
    const listName = `selected${_.upperFirst(tagType)}`;
    let selected = _.intersectionBy(_.get(this, listName, []), values, 'value');
    selected = _.unionBy(selected, tags, 'value');
    _.set(this, listName, _.uniqBy(selected, 'value'));

    log.silly(`Set "${listName} to:`, { [listName]: this[listName] });
  }
  @action
  clear() {
    this.selectedQuals = [];
    this.selectedPositions = [];
  }
}
