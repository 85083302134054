import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class CampaignReportBuilder {
  log = getChildLogger('ui.campaignReportBuilder');

  @observable
  campaigns = [];

  @observable
  csvData = '';

  @action
  addCampaign({ campaign }) {
    this.campaigns.push(campaign);
  }

  @action
  removeCampaign({ uuid }) {
    _.remove(this.campaigns, (c) => c.uuid === uuid);
  }

  @action
  setCsvData(data) {
    this.csvData = data;
  }

  @action
  buildReport() {
    const log = this.log.getChildLogger('buildReport');
    const campaigns = _.map(this.campaigns, 'uuid');
    if (_.isEmpty(campaigns)) {
      return false;
    }
    if (campaigns.length > 10) {
      // eslint-disable-next-line no-alert
      alert('Choose 10 or less campaigns');
      return false;
    }
    dispatch('ui.loadingModal.open');
    return dispatch('campaigns.buildReport', { campaigns })
      .then((res) => {
        this.setCsvData(res.body.csvData);
        dispatch('ui.loadingModal.close');
        dispatch('audit.create', {
          data: {
            action: 'Campaign Report Export',
            extra: {
              campaigns,
            },
          },
        });
      })
      .catch((err) => {
        log.error('Error Building Report: ', err);
        dispatch('ui.loadingModal.close');
      });
  }

  @action
  clear() {
    this.campaigns = [];
    this.csvData = '';
  }
}
