import React from 'react';
import moment, { MomentInput } from 'moment';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { useRelativeTimezones } from '#/shared/components/shifts/common/ShiftItemUtils';

export const getDateRangeString = ({
  start,
  end,
  dayFormat = null,
  dateFormat = null,
  timeFormat = null,
  joiner = '-',
  timezone,
}: {
  dateFormat?: string;
  dayFormat?: string;
  end?: MomentInput | false;
  joiner?: string;
  start: MomentInput | false;
  timeFormat?: string;
  timezone?: string;
}) => {
  const s = !!start && moment(start);
  const e = !!end && moment(end);

  const isSameDay = s.isSame(e, 'day');
  /**
   * If provided by props, full day format can contains weekday, month and year
   * We use full day format for the start date
   * If start and end day is not same, we also show fullDayFormat for the end date also
   */
  const fullDayFormat = `${dayFormat || ''}${dateFormat || ''}`;

  const formattedStartDate =
    !!start &&
    s.format(
      `${fullDayFormat}${timeFormat || ''}${!end && timezone ? ' z' : ''}` ||
        'ddd, MMM Do LT',
    );
  const formattedJoiner = !!start && !!end && ` ${joiner} `;
  const formattedEndDate =
    !!end &&
    e.format(
      `${!isSameDay ? fullDayFormat : ''}${timeFormat || ''}${
        timezone ? ' z' : ''
      }` || 'ddd, MMM Do LT',
    );

  return _.compact([formattedStartDate, formattedEndDate]).join(
    formattedJoiner ?? ' ',
  );
};

export const TZAwareDateRange = observer(
  ({
    start,
    end,
    dayFormat,
    dateFormat,
    timeFormat,
    joiner,
    timezone,
    ...rest
  }: {
    dateFormat?: string;
    dayFormat?: string;
    end?: MomentInput | false;
    joiner?: string;
    start: MomentInput | false;
    timeFormat?: string;
    timezone?: string;
  }) => {
    const { active } = useRelativeTimezones({
      end: end || null,
      start: start || null,
      timezone,
    });

    return (
      <span {...rest}>
        {getDateRangeString({
          dateFormat,
          dayFormat,
          end: active.end,
          joiner,
          start: active.start,
          timeFormat,
          timezone: active.timezone,
        })}
      </span>
    );
  },
);

/**
 * # DateRangeString
 *
 * @param {string} props.start, start time
 * @param {string} props.end, end time
 * @param {string} props.dayFormat
 * @param {string} props.dateFormat
 * @param {string} props.timeFormat
 * @param {string} props.joiner, separation between start time and end time
 *
 * @returns {React.ReactElement}
 */
export const DateRangeString = observer(
  ({
    start,
    end,
    dayFormat = 'ddd, ',
    dateFormat = 'MMM Do ',
    timeFormat = 'LT',
    joiner = '-',
    ...rest
  }) => (
    <span {...rest}>
      {getDateRangeString({
        dateFormat,
        dayFormat,
        end,
        joiner,
        start,
        timeFormat,
      })}
    </span>
  ),
);

DateRangeString.displayName = 'DateRangeString';

DateRangeString.propTypes = {
  dateFormat: PropTypes.string,
  dayFormat: PropTypes.string,
  end: PropTypes.string.isRequired,
  joiner: PropTypes.string,
  start: PropTypes.string.isRequired,
  timeFormat: PropTypes.string,
};
