import type { IPaymentRule } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export default class PaymentRulesStore<
  T extends IPaymentRule = IPaymentRule,
> extends BaseStore<T> {
  constructor({ serviceName = 'paymentRules', baseItem = {}, ...rest } = {}) {
    super({
      baseItem: { ...PaymentRulesStore.BASE_ITEM, ...baseItem },
      serviceName,
      ...rest,
    });

    return this;
  }

  static get BASE_ITEM() {
    return {
      companies: [],
      description: '',
      key: '',
      period: '',
      title: '',

      validFrom: null,
      validTo: null,
    };
  }
}
