import { action, observable } from 'mobx';
import _ from 'lodash';

import { init as initForm } from '#/shared/forms/employer';

export default class EmployerEditModal {
  @observable
  isOpen = false;

  @observable
  form = {};

  @action
  setup({
    company = {},
    employer = {},
    addressOptional = true,
    open = this.isOpen,
    createAdmin = false,
  }) {
    const companyId = company?.uuid;
    const values = _.extend({ companies: _.compact([companyId]) }, employer, {
      createAdmin,
    });
    this.form = initForm(values, { addressOptional });
    this.isOpen = open;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }
}
