import { action, computed, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.CreateShiftGroupChatModal');

export default class CreateShiftGroupChatModal {
  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  error = '';

  @observable
  shiftId = '';

  @observable
  sendToStatus = 'Assigned';

  @observable
  shift = {};

  @observable
  userList = [];

  @observable
  totalUsers = 0;

  @observable
  aggregateData = {};

  @computed
  get shiftStatuses() {
    const assignedCount = _.get(this.aggregateData, 'assigned', 0);
    const seenCount = _.get(this.aggregateData, 'seen', 0);
    const sentCount = _.get(this.aggregateData, 'sent', 0);
    const notConfirmedCount = _.get(this.aggregateData, 'notConfirmed', 0);
    const checkedInCount = _.get(this.aggregateData, 'checkedIn', 0);
    const appliedCount = _.get(this.aggregateData, 'applied', 0);
    const confirmedCount = _.get(this.aggregateData, 'confirmed', 0);
    const confirmedNoCheckinCount = _.get(
      this.aggregateData,
      'confirmedNoCheckin',
      0,
    );
    const totalCount = _.sum([assignedCount, sentCount, appliedCount]);
    return [
      {
        key: 'Assigned',
        text: `Partners who have been assigned the shift (${assignedCount})`,
        value: 'Assigned',
      },
      {
        key: 'Sent',
        text: `Partners who have been sent the shift (${sentCount})`,
        value: 'Sent',
      },
      {
        key: 'Seen',
        text: `Partners who have seen the shift (${seenCount})`,
        value: 'Seen',
      },
      {
        key: 'notConfirmed',
        text: `Partners who have not yet confirmed the shift (${notConfirmedCount})`,
        value: 'notConfirmed',
      },
      {
        key: 'checkedIn',
        text: `Partners who have checked in for the shift (${checkedInCount})`,
        value: 'checkedIn',
      },
      {
        key: 'Confirmed',
        text: `Partners who have confirmed the shift (${confirmedCount})`,
        value: 'Confirmed',
      },
      {
        key: 'ConfirmedNoCheckin',
        text: `Partners who have confirmed the shift but not checked-in (${confirmedNoCheckinCount})`,
        value: 'ConfirmedNoCheckin',
      },
      {
        key: 'All',
        text: `All partners related to this shift (${totalCount})`,
        value: 'All',
      },
    ];
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    _.set(this, key, value);
  }

  @action
  retrieve(key) {
    return _.get(this, key);
  }

  @action
  async setup({
    open = !this.isOpen,
    defaultStatus = 'Assigned',
    shiftId,
    shifts,
    ui,
  }) {
    this.set('isLoading', true);
    this.set('isOpen', open);
    this.set('sendToStatus', defaultStatus);
    this.set('shiftId', shiftId);
    try {
      let selectedShift = shifts.selected;
      if (selectedShift.uuid !== shiftId) {
        // Only load the shift again if not already the selected one
        selectedShift = await shifts.get(shiftId, {
          select: false,
        });
      }
      this.set('shift', selectedShift);
      await this.loadAssignments();
      const aggregateData = ui.shiftDetails.aggregateData;
      this.set('aggregateData', aggregateData);
    } catch (err) {
      log.error('Error setting up CreateShiftGroupChatModal', err);
    }
    this.set('isLoading', false);
  }

  @action
  async loadAssignments() {
    this.set('isLoading', true);
    try {
      const query = {
        $client: {
          populateUser: true,
        },
        $limit: 6,
        $select: [
          'uuid',
          'user',
          'status',
          'title',
          'start',
          'end',
          'rate',
          'formattedAddress',
          'seenAt',
        ],
        ref: this.shiftId,
        status: { $in: ['Assigned', 'Accepted', 'Approved'] }, // ATTN: Assignment Status EP-5523
      };
      if (/^(seen|sent)$/i.test(this.sendToStatus)) {
        query.status = 'Sent';
        if (/^seen$/i.test(this.sendToStatus)) {
          query.seenAt = { $exists: true };
        }
      }
      if (/^all$/i.test(this.sendToStatus)) {
        query.status = {
          $in: ['Assigned', 'Accepted', 'Approved', 'Sent', 'PendingApproval'],
        };
      }
      if (/^confirmed$/i.test(this.sendToStatus)) {
        query.partnerConfirmedAt = { $exists: true };
      }
      if (/^confirmednocheckin$/i.test(this.sendToStatus)) {
        query.partnerConfirmedAt = { $exists: true };
        query.checkIn = { $exists: false };
      }
      if (/^notConfirmed$/i.test(this.sendToStatus)) {
        query.partnerConfirmedAt = { $exists: false };
      }
      if (/^checkedIn$/i.test(this.sendToStatus)) {
        query.checkIn = { $exists: true };
      }
      const response = await dispatch('assignments.runQuery', query);
      this.set(
        'userList',
        response.data.map((assignment) => assignment.user),
      );
      this.set('totalUsers', response.total);
    } catch (error) {
      log.error('Error loading assignments for reminders', error, {
        extra: {
          shiftId: this.shiftId,
        },
      });
    }

    this.set('isLoading', false);
  }

  @action
  updateStatusToggle(value) {
    this.set('sendToStatus', value);
    this.loadAssignments();
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  async submit() {
    this.set('isLoading', true);
    dispatch('ui.loadingModal.open', { message: 'Creating Chat Channel' });
    const sender = dispatch('auth.getUser');
    const company = dispatch('auth.getCompany');
    try {
      const newChannel = await dispatch(
        'chatChannels.createShiftGroupChannel',
        {
          company,
          sender,
          shift: this.shift,
          status: this.sendToStatus,
          workers: this.userList,
        },
      );
      this.clear();
      // navigate to channel
      dispatch('routing.push', `/chats/${newChannel.uuid}?autoFocus=true`);
    } catch (error) {
      log.error('Error creating shift group channel', error);
      this.set('error', 'There was an issue, please try again');
      dispatch('ui.snackBar.open', error.message);
    }

    this.set('isLoading', false);
    dispatch('ui.loadingModal.close');
  }

  @action
  clear(args = {}) {
    this.isOpen = !!args.open;
    this.isLoading = false;
    this.shiftId = '';
    this.shift = {};
    this.sendToStatus = 'Assigned';
    this.error = '';
    this.totalAssignments = 0;
  }
}
