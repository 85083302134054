import type {
  FormConstructorConfig,
  FormConstructorSetup,
} from '#/shared/forms/_.extend';

import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

import {
  SiteForm,
  fields as siteFields,
  labels as siteLabels,
  rules as siteRules,
} from './site';
import { mapOptionsToFields, mapOptionsToRules } from './formUtils';

const log = getChildLogger('forms.region');

class ZoneForm extends SiteForm {
  constructor(setup: FormConstructorSetup, config?: FormConstructorConfig) {
    super(setup, config);

    this.uiStoreName = 'ui.addressModal';

    return this;
  }

  uiStoreName;

  hooks = () => ({
    onSuccess() {
      log.error(
        'The Region Form does not implement a form.onSuccess handler. Please see `ui.addressModal.save` for this functionality',
      );
    },
  });
}

export const fields = _.concat(siteFields, ['tier']).filter(
  (f) => !['storeNumber'].includes(f),
);

export const rules = _.defaults(
  {
    name: 'string',
    tier: 'numeric|required|between:0,4',
  },
  siteRules,
);

export const labels = _.defaults(
  {
    name: 'Name',
    tier: 'Tier',
  },
  siteLabels,
);

export const extra = {};

/** WARN: Non-Standard second constructor argument; this is _not_ passed to the main form constructor as with other forms */
export function init(values = {}, options) {
  log.debug('Creating Zone Form from Keys: ', _.keys(values));

  const ruleOptions = _.clone(rules);

  if (options) {
    mapOptionsToRules(ruleOptions, options);
  }

  if (!_.get(values, 'loc.coordinates')) {
    _.set(values, 'loc.coordinates', [null, null]);
  }
  if (values.parentObjs) {
    _.set(
      values,
      'parentObjs',
      _.reduce(
        values.parentObjs,
        (acc, obj) => {
          acc[obj?.tier] = obj;
          return acc;
        },
        [null, null, null, null, null],
      ),
    );
  }

  const form = new ZoneForm({
    extra,
    fields,
    labels,
    rules: ruleOptions,
    values,
  });

  if (options) {
    mapOptionsToFields(form, options);

    if (options.uiStoreName) {
      form.uiStoreName = options.uiStoreName;
    }
  }

  log.debug('Created Zone Form with Values: ', form.values());
  return form;
}

export type { ZoneForm };
