import { action, observable, computed, set, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import moment from 'moment';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.chatMessaging');
export default class ChatMessaging {
  @observable
  isLoading = false;

  @observable
  activeDetailsTab = 'summary';

  @observable
  activeListFilters = [];

  @observable
  activeStatusFilter = 'unresolved';

  @observable
  activeSortFilter = 'newToOld';

  @observable
  lastMessageFilter: 'internal' | 'partner' | '' = '';

  @observable
  lastMessageDateFilter = null;

  @observable
  lastMessageDateFilterType: 'before' | 'after' = 'before';

  @observable
  activeTypeFilter = 'all';

  @observable
  activeShiftListFilter = '';

  @observable
  activeCertListFilter = '';

  @observable
  activeCertAggregateListFilter = '';

  @observable
  activeMessageTagListFilter = '';

  @observable
  activeAgentListFilter = '';

  @observable
  activeCustomListFilter = '';

  @observable
  activeSettingsTab = 'participants';

  @observable
  hovered = {};

  @observable
  showInfoPane = true;

  @observable
  recipient = {};

  @observable
  partnerUpcomingShiftAssignments = [];

  @observable
  partnerPreviousShiftAssignments = [];

  @action
  loading(arg = !this.isLoading) {
    this.isLoading = !!arg;
  }

  @action
  setup() {
    if (
      _.isEmpty(this.activeListFilters) &&
      this.activeStatusFilter === 'unresolved'
    ) {
      this.setActiveListFilters(['all'], true);
      this.setActiveStatusFilter(this.activeStatusFilter);
    }
  }

  @action
  async setRecipient(recipient) {
    if (!recipient) {
      this.recipient = {};
    } else {
      const company = dispatch('auth.getCompany');
      const profileMetric =
        company.settings?.partners?.primaryPartnerProfileMetric;
      const workerId = _.get(recipient, 'uuid', recipient);
      const userObj = await dispatch('workers.get', workerId, {
        query: {
          $client: {
            populateUserStats:
              !!profileMetric && profileMetric !== 'rating' && company.uuid,
            skipPopulateExperience: true,
          },
        },
        select: false,
      });
      runInAction(() => {
        this.recipient = userObj;
      });
    }
    // this.recipient = recipient || {};
  }

  @action
  setActiveDetailsTab(tab) {
    this.activeDetailsTab = tab;

    if (tab === 'message') {
      this.toggleInfoPane(true);
    }

    log.debug('set active details pane to "%s"', this.activeDetailsTab);
  }

  applyLastMessageDateFilter() {
    const date = this.lastMessageDateFilter;
    dispatch(
      'chatChannels.find',
      {
        'lastMessage.messageCreated': undefined,
      },
      { noQuery: true },
    );
    if (date) {
      if (/after/.test(this.lastMessageDateFilterType)) {
        dispatch('chatChannels.find', {
          'lastMessage.messageCreated': {
            $gte: date.startOf('day').toDate(),
          },
        });
      } else {
        dispatch('chatChannels.find', {
          'lastMessage.messageCreated': {
            $lte: date.endOf('day').toDate(),
          },
        });
      }
    } else {
      dispatch('chatChannels.find');
    }
  }

  @action
  setLastMessageDateFilterType(type: 'before' | 'after') {
    this.lastMessageDateFilterType = type;
    this.applyLastMessageDateFilter();
  }

  @action
  setLastMessageDateFilter(date) {
    this.lastMessageDateFilter = date;
    this.applyLastMessageDateFilter();
  }

  @action
  setActiveStatusFilter(status) {
    this.activeStatusFilter = status;
    if (status === 'all') {
      this.setActiveListFilters(
        _.concat(
          this.activeListFilters.filter(
            (tab) =>
              !_.includes(
                this.statusFilterOptions.map((t) => t.key),
                tab,
              ),
          ),
        ),
      );
    } else {
      this.setActiveListFilters(
        _.concat(
          this.activeListFilters.filter(
            (tab) =>
              !_.includes(
                this.statusFilterOptions.map((t) => t.key),
                tab,
              ),
          ),
          status,
        ),
      );
    }

    log.debug('set active statusFilter pane to "%s"', this.activeStatusFilter);
  }

  @action
  sortChats(order) {
    this.activeSortFilter = order;
    dispatch(
      'chatChannels.find',
      {
        'lastMessage.messageCreated': { $exists: true },
      },
      { noQuery: true },
    );
    if (order === 'oldToNew') {
      dispatch('chatChannels.sortBy', {
        'lastMessage.messageCreated': 1,
      });
    } else {
      dispatch('chatChannels.sortBy', {
        'lastMessage.messageCreated': -1,
      });
    }
  }

  @action
  setActiveTypeFilter(type) {
    this.activeTypeFilter = type;
    if (type === 'all') {
      this.setActiveListFilters(
        _.concat(
          this.activeListFilters.filter(
            (tab) =>
              !_.includes(
                this.dropdownListTabs.map((t) => t.key),
                tab,
              ),
          ),
        ),
      );
    } else {
      this.setActiveListFilters(
        _.concat(
          this.activeListFilters.filter(
            (tab) =>
              !_.includes(
                this.dropdownListTabs.map((t) => t.key),
                tab,
              ),
          ),
          type,
        ),
      );
    }

    log.debug('set active typeFilter pane to "%s"', this.activeTypeFilter);
  }

  @action
  setActiveShiftListFilter(shift) {
    if (shift?.uuid) {
      this.activeShiftListFilter = shift.uuid;
      this.setActiveListFilters(
        _.concat(
          this.activeListFilters.filter((tab) => tab !== 'shift'),
          'shift',
        ),
      );
    } else {
      this.activeShiftListFilter = '';
      this.setActiveListFilters(
        this.activeListFilters.filter((tab) => tab !== 'shift'),
      );
    }
  }

  @action
  setActiveCertAggregateListFilter(cert) {
    if (cert?.uuid) {
      this.activeCertAggregateListFilter = cert.uuid;
      this.setActiveListFilters(
        _.concat(
          this.activeListFilters.filter((tab) => tab !== 'certAggregate'),
          'certAggregate',
        ),
      );
    } else {
      this.activeCertAggregateListFilter = '';
      this.setActiveListFilters(
        this.activeListFilters.filter((tab) => tab !== 'certAggregate'),
      );
    }
  }

  @action
  setActiveCertListFilter(cert) {
    if (cert?.uuid) {
      this.activeCertListFilter = cert.uuid;
      this.setActiveListFilters(
        _.concat(
          this.activeListFilters.filter((tab) => tab !== 'cert'),
          'cert',
        ),
      );
    } else {
      this.activeCertListFilter = '';
      this.setActiveListFilters(
        this.activeListFilters.filter((tab) => tab !== 'cert'),
      );
    }
  }

  @action
  setActiveMessageTagListFilter(messageTag) {
    if (messageTag?.uuid) {
      this.activeMessageTagListFilter = messageTag.uuid;
      this.setActiveListFilters(
        _.concat(
          this.activeListFilters.filter((tab) => tab !== 'messageTag'),
          'messageTag',
        ),
      );
    } else {
      this.activeMessageTagListFilter = '';
      this.setActiveListFilters(
        this.activeListFilters.filter((tab) => tab !== 'messageTag'),
      );
    }

    log.debug(
      'set active messageTagListFilter pane to "%s"',
      this.activeMessageTagListFilter,
    );
  }

  @action
  setActiveAgentListFilter(agent) {
    if (agent?.uuid) {
      this.activeAgentListFilter = agent.uuid;
      this.setActiveListFilters(
        _.concat(
          this.activeListFilters.filter((tab) => tab !== 'agent'),
          'agent',
        ),
      );
    } else {
      this.activeAgentListFilter = '';
      this.setActiveListFilters(
        this.activeListFilters.filter((tab) => tab !== 'agent'),
      );
    }
  }

  @action
  async setCustomFilter(customFilter) {
    if (customFilter?.uuid) {
      this.activeCustomListFilter = customFilter.uuid;
      this.setActiveListFilters(
        _.concat(
          this.activeListFilters.filter((tab) => tab !== 'custom'),
          'custom',
        ),
      );

      const filterObj = await dispatch(
        'savedChatFilters.get',
        customFilter.uuid,
      );
      const filter = JSON.parse(filterObj.filterQueryString);
      if (filter.status) {
        this.setActiveStatusFilter(filter.status);
      }
      if (filter.type) {
        this.setActiveTypeFilter(filter.type);
      }

      this.setActiveMessageTagListFilter({ uuid: filter.messageTag });
      this.setActiveCertListFilter({ uuid: filter.tag });
    } else {
      this.activeCustomListFilter = '';
      this.setActiveListFilters(
        this.activeListFilters.filter((tab) => tab !== 'custom'),
      );
    }
  }

  @action
  setActiveListFilters(tabs, skipQuery = false) {
    const changedValue = _.first(_.xor(tabs, this.activeListFilters));
    const isRemoved = _.size(tabs) < _.size(this.activeListFilters);

    this.activeListFilters = tabs.filter((tab) => tab !== 'all');
    if (isRemoved && changedValue === 'shift') {
      this.activeShiftListFilter = '';
      this.activeCustomListFilter = '';
    }

    if (isRemoved && changedValue === 'cert') {
      this.activeCertListFilter = '';
      this.activeCustomListFilter = '';
    }

    if (isRemoved && changedValue === 'messageTag') {
      this.activeMessageTagListFilter = '';
      this.activeCustomListFilter = '';
    }

    if (isRemoved && changedValue === 'agent') {
      this.activeAgentListFilter = '';
      this.activeCustomListFilter = '';
    }

    if (isRemoved && changedValue === 'custom') {
      this.activeCustomListFilter = '';
      this.activeShiftListFilter = '';
      this.activeCertListFilter = '';
      this.activeAgentListFilter = '';
      this.activeMessageTagListFilter = '';
    }

    const refs = {
      agent: this.activeAgentListFilter,
      cert: this.activeCertListFilter,
      certAggregate: this.activeCertAggregateListFilter,
      messageTag: this.activeMessageTagListFilter,
      shift: this.activeShiftListFilter,
      sort: this.activeSortFilter,
    };

    dispatch('chatChannels.filterBy', {
      channelTypes: this.activeListFilters,
      refs,
      skipQuery,
    });
  }

  @action
  resetFilters() {
    this.activeListFilters = ['unresolved'];

    this.activeCustomListFilter = '';
    this.activeCertListFilter = '';
    this.activeMessageTagListFilter = '';
    this.activeStatusFilter = 'unresolved';
    this.activeTypeFilter = 'all';
    this.activeSortFilter = 'newToOld';

    const refs = {
      cert: this.activeCertListFilter,
      messageTag: this.activeMessageTagListFilter,
    };

    dispatch('chatChannels.filterBy', {
      channelTypes: this.activeListFilters,
      refs,
    });
  }

  @action
  setActiveSettingsTab(tab) {
    this.activeSettingsTab = tab;
  }

  @action
  async setLastMessageFilter(filter) {
    this.lastMessageFilter = filter;
    // any existing  lastMessage.sender clearing because it always merge updated query
    dispatch(
      'chatChannels.find',
      {
        'lastMessage.sender': undefined,
      },
      { noQuery: true },
    );

    if (filter) {
      const company = dispatch('auth.getCompany');
      const employers = await dispatch('employerUsers.findByCompany', {
        company: company,
        opts: { clear: true },
        query: { $select: ['uuid'] },
      });

      if (/internal/.test(filter)) {
        dispatch('chatChannels.find', {
          'lastMessage.sender': { $in: _.map(employers, 'uuid') },
        });
      }
      if (/partner/.test(filter)) {
        dispatch('chatChannels.find', {
          'lastMessage.sender': { $nin: _.map(employers, 'uuid') },
        });
      }
    } else {
      dispatch('chatChannels.find');
    }
  }

  @action
  toggleInfoPane(state = !this.showInfoPane) {
    this.showInfoPane = state;
  }

  @action async getWorkerShifts(worker) {
    const companyId = _.get(dispatch('auth.getCompany'), 'uuid', null);
    const workerId = worker.uuid;
    const query = {
      company: companyId,
      user: workerId,
    };

    const { data: assignmentList } = await dispatch('assignments.runQuery', {
      ...query,
      start: {
        $gte: moment().subtract(7, 'days').startOf('day').toDate(),
      },
      status: { $in: ['Completed', 'Assigned', 'Accepted', 'Approved'] },
    });

    const upcomingAssignments = _.filter(
      assignmentList,
      ({ start, status }) =>
        moment().isBefore(start) && /assigned|accepted|approved/i.test(status),
    );

    const last7DaysAssignments = _.filter(
      assignmentList,
      ({ start, status }) =>
        !moment().isBefore(start) && /completed/i.test(status),
    );

    /**
     * First load all assignments of last 7 days.
     * If no assignmetns of last 7 days, show the last one shift
     * of the partner.
     */
    let previousAssignments;
    if (!_.size(last7DaysAssignments)) {
      const { data: recentPastAssignment } = await dispatch(
        'assignments.runQuery',
        {
          ...query,
          $limit: 1,
          $sort: {
            start: -1,
          },
          status: { $in: ['Completed'] },
        },
      );
      previousAssignments = recentPastAssignment;
    } else {
      previousAssignments = last7DaysAssignments;
    }

    runInAction(() => {
      this.partnerUpcomingShiftAssignments = upcomingAssignments;
      this.partnerPreviousShiftAssignments = previousAssignments;
    });
  }

  @action
  clear() {
    this.isLoading = false;
    this.setActiveDetailsTab('');
    this.setActiveSettingsTab('participants');
    this.showInfoPane = false;
    this.hovered = {};
    this.partnerUpcomingShiftAssignments = [];
    this.partnerPreviousShiftAssignments = [];
  }

  @action
  hoverStart(id) {
    set(this.hovered, { [id]: true });
  }

  @action
  hoverEnd(id) {
    set(this.hovered, { [id]: false });
  }

  @computed
  get statusFilterOptions() {
    return [
      { key: 'all', text: 'All', value: 'all' },
      { key: 'unresolved', text: 'Unresolved', value: 'unresolved' },
      { key: 'resolved', text: 'Resolved', value: 'resolved' },
    ];
  }

  @computed
  get sortFilterOptions() {
    return [
      { key: 'newToOld', text: 'Newest to Oldest', value: 'newToOld' },
      { key: 'oldToNew', text: 'Oldest to Newest', value: 'oldToNew' },
    ];
  }

  @computed
  get lastMessageFilterOptions() {
    return [
      {
        key: 'internal',
        text: 'Internal',
        value: 'internal',
      },
      {
        key: 'partner',
        text: 'Partner',
        value: 'partner',
      },
    ];
  }

  @computed
  get dropdownListTabs() {
    return this.listTabs.map((tab) => ({
      key: tab.key,
      text: tab.title,
      value: tab.key,
    }));
  }

  @computed
  get listTabs() {
    const company = dispatch('auth.getCompany');
    return _([
      {
        index: 0,
        key: 'all',
        style: { height: '62px' },
        title: 'All',
      },
      {
        index: 1,
        key: 'partners',
        style: { height: '62px' },
        title: 'Partner Chats',
      },
      {
        index: 2,
        key: 'group',
        style: { height: '62px' },
        title: 'Pools',
      },
      {
        index: 3,
        key: 'shifts',
        style: { height: '62px' },
        title: 'Shift Chats',
      },
      company.isShiftsmartManaged && {
        index: 4,
        key: 'readyForShift',
        style: { height: '62px' },
        title: 'Ready For Shift',
      },
      {
        index: 5,
        key: 'shiftsToday',
        style: { height: '62px' },
        title: 'Working Today',
      },
    ])
      .compact()
      .value();
  }

  /* Vars */

  @computed
  get settingsTabs() {
    let settingsTabsBase;
    if (this.activeDetailsTab === 'summary') {
      settingsTabsBase = [
        {
          index: 0,
          key: 'participants',
          title: 'PARTICIPANTS',
        },
        {
          index: 1,
          key: 'settings',
          title: 'TITLE & PHOTO',
        },
      ];
    } else if (this.activeDetailsTab === 'one-on-one') {
      settingsTabsBase = [
        {
          index: 0,
          key: 'shifts',
          title: 'UPCOMING SHIFTS',
        },
        {
          index: 1,
          key: 'tags',
          title: 'TAGS',
        },
      ];
    }

    return _(settingsTabsBase).compact().value();
  }

  @action
  retrieve(key) {
    return _.get(this, key);
  }
}
