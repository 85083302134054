import React, { createRef } from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import { observer } from 'mobx-react';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import {
  Button,
  Image,
  Message,
  Menu,
  Segment,
  Input,
} from 'semantic-ui-react';
import InputRange from 'react-input-range';

import { EditProfilePicButton } from '#/shared/components/workers/EditProfilePicButton';

const EditorToggle = observer(
  ({ user = {}, image = user.profileImageURL, storeName }) => (
    <div className="tc">
      <div className="ma2">
        {_.isEmpty(image) ? (
          <Message
            className="tc"
            compact={true}
            content="No Profile Image Set"
          />
        ) : (
          <Image
            alt="Profile Pic"
            className="center"
            shape="circular"
            size="small"
            src={image}
          />
        )}
      </div>
      <div className="pt2 flex justify-center">
        <EditProfilePicButton
          className="ma1"
          content="Edit Profile Image"
          fileRef={createRef}
          fluid={false}
          icon="edit"
          storeName={storeName}
          user={user}
        />
      </div>
    </div>
  ),
);

const AvatarEditor = observer(
  ({ image, scale, angle, width, height, fileRef }) => {
    return (
      <div>
        <div className="ba b--black-80 br2 tc">
          <AvatarEditorWrapper
            angle={angle}
            height={height}
            image={image}
            scale={scale}
            setRef={(ref) => dispatch('ui.avatarEditor.setEditor', ref)}
            width={width}
          />
        </div>
        <div>
          <Segment basic={true}>
            <InputRange
              maxValue={8.0}
              minValue={1.0}
              onChange={(val) => dispatch('ui.avatarEditor.handleScale', val)}
              step={0.1}
              value={scale}
            />
          </Segment>
          <Menu secondary={true}>
            <Menu.Menu position="left">
              <Menu.Item>
                <Button
                  icon={{ name: 'undo' }}
                  onClick={() => dispatch('ui.avatarEditor.rotate', -90)}
                />
              </Menu.Item>
              <Menu.Item>
                <Button
                  icon={{ flipped: 'horizontally', name: 'undo' }}
                  onClick={() => dispatch('ui.avatarEditor.rotate', 90)}
                />
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu position="right">
              <Menu.Item>
                <input
                  name="newImage"
                  onChange={(e) =>
                    dispatch('ui.avatarEditor.handleNewImage', e)
                  }
                  ref={fileRef}
                  type="file"
                />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </div>
        <div className="flex">
          <div className="ph3">
            Width:{' '}
            <Input
              onChange={(e, { value }) =>
                dispatch('ui.avatarEditor.setWidth', value)
              }
              type="number"
              value={width}
            />
          </div>
          <div className="ph3">
            Height:{' '}
            <Input
              onChange={(e, { value }) =>
                dispatch('ui.avatarEditor.setHeight', value)
              }
              type="number"
              value={height}
            />
          </div>
          <div className="ph3">Scale: {scale}</div>
        </div>
      </div>
    );
  },
);

const AvatarEditorWrapper = observer(
  ({ image, scale, angle, setRef, width = 250, height = 250 }) => (
    <ReactAvatarEditor
      border={50}
      color={[255, 255, 255, 0.6]} // RGBA
      crossOrigin="use-credentials"
      height={height}
      image={image}
      onImageReady={() => dispatch('ui.avatarEditor.setBaseImage')}
      ref={setRef}
      rotate={angle}
      scale={scale}
      width={width}
    />
  ),
);

export default AvatarEditor;

export { EditorToggle };
