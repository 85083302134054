import { Timestamp } from '@shiftsmartinc/shiftsmart-types';

import { service } from '../app';

import BaseStore from './_baseStore';

interface Remap {
  createdAt: Timestamp;
  modelName: string;
  updatedAt: Timestamp;
  uuid: string;
}

export default class TalkstackStore extends BaseStore<Remap> {
  constructor() {
    super({ baseItem: null, serviceName: 'talkstack' });

    return this;
  }

  testCall(data: { userId: string }) {
    return service(this.serviceName)
      .create(data)
      .catch((err) => {
        this.log.error('Error updating test call', err);
        throw err;
      });
  }
}
