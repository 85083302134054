import { action } from 'mobx';
// import { dispatch } from 'rfx-core';
// import { service } from '#/shared/app';
import _ from 'lodash';

import PartnersStore from '#/shared/stores/partners';

export default class PoolPartnersStore extends PartnersStore {
  constructor({ title = 'poolPartners' } = {}) {
    super({ title });

    return this;
  }

  initEvents() {
    super.initEvents();
  }

  @action
  updateList(json) {
    super.updateList(json);

    this.log.debug('Updated Partner List', {
      list: this.list,
      pagination: this.$pagination,
    });

    return this.list;
  }

  @action
  resetSearch({ preserve = [] } = {}) {
    this.pools.clear();

    return super.resetSearch({
      preserve: _.union(preserve, ['companies', 'companyStatus']),
    });
  }
}
