import type { IShiftTemplate } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import BluebirdPromise from 'bluebird';
import { action } from 'mobx';

import ThingStore from '#/shared/stores/things';

export default class ShiftTemplateStore extends ThingStore<IShiftTemplate> {
  constructor({
    serviceName = 'shiftTemplates',
    title = 'shiftTemplates',
    baseItem = ShiftTemplateStore.BASE_ITEM,
  } = {}) {
    super({
      baseItem: { ...ShiftTemplateStore.BASE_ITEM, ...baseItem },
      serviceName,
      title,
    });

    return this;
  }

  static BASE_ITEM = {
    ...ThingStore.BASE_ITEM,
    companyId: null,
    endDayOffset: null,
    hourEnd: null,
    hourStart: null,
    minutesEnd: null,
    minutesStart: null,
    recurringScheduleId: null,
    startDayOffset: null,
    status: null,
    timezone: null,
  };

  @action
  create({ data, params = {} }) {
    const shiftTemplates = _.clone(data);

    if (
      !_.isEmpty(shiftTemplates.address) &&
      _.isObject(shiftTemplates.address)
    ) {
      shiftTemplates.addressObj = shiftTemplates.address;
      shiftTemplates.address = _.get(
        shiftTemplates.addressObj,
        'formattedAddress',
      );
    }

    if (_.isEmpty(shiftTemplates.address)) {
      delete shiftTemplates.address;
    }

    return super.create({ data: shiftTemplates, params });
  }

  @action
  update({
    data = {},
    isBulkEdit = false,
    ids = [],
    id = data.uuid,
    query = {},
  }) {
    const shift = _.clone(data);
    if (isBulkEdit) {
      return this.updateMany({
        data,
        ids,
      });
    }
    if (!_.isEmpty(shift.address) && _.isObject(shift.address)) {
      shift.addressObj = shift.address;
      shift.address = _.get(shift.addressObj, 'formattedAddress');
    }

    if (_.isEmpty(shift.address) && shift.address !== '') {
      delete shift.address;
    }

    return super.update({ data: shift, id, params: { query } });
  }

  @action getExportHeaders = (): { label: string; value: string }[] => [
    {
      label: 'UUID',
      value: 'uuid',
    },
    {
      label: 'Title',
      value: 'title',
    },
    {
      label: 'Description',
      value: 'description',
    },
    {
      label: 'Status',
      value: 'status',
    },
    {
      label: 'StartDayOffset',
      value: 'startDayOffset',
    },
    {
      label: 'HourStart',
      value: 'hourStart',
    },
    {
      label: 'MinutesStart',
      value: 'minutesStart',
    },
    {
      label: 'EndDayOffset',
      value: 'endDayOffset',
    },
    {
      label: 'HourEnd',
      value: 'hourEnd',
    },
    {
      label: 'MinutesEnd',
      value: 'minutesEnd',
    },
    {
      label: 'Timezone',
      value: 'timezone',
    },
    {
      label: 'Slots',
      value: 'slots',
    },
    {
      label: 'Rate',
      value: 'rate',
    },
    {
      label: 'Pay',
      value: 'pay',
    },
    {
      label: 'Currency',
      value: 'currency',
    },
  ];
}
