import { action, observable } from 'mobx';

/**
 * Store for UI Modal where user can Add a Template to their Company
 *
 * @export
 * @class ThingModal
 */

export default class ThingCancelConfirm {
  @observable
  isOpen = false;

  @observable
  thing = {};

  @action
  setup({ thing = {}, open = this.isOpen }) {
    this.thing = thing;

    this.isOpen = open;
  }

  @action
  clear() {
    this.thing = {};

    this.isOpen = false;
  }
}
