import { observable, action } from 'mobx';

export default class SubmitTransactionModal {
  @observable
  isOpen = false;

  @observable
  transaction = {};

  @observable
  userObj = {};

  @action
  setup({ transaction = {}, open = this.isOpen, userObj }) {
    this.isOpen = open;
    this.transaction = transaction;
    this.userObj = userObj;
  }

  @action
  clear() {
    this.isOpen = false;
    this.transaction = {};
  }
}
