import { observable, action, computed } from 'mobx';
import { dispatch } from 'rfx-core';
import { parsePhoneNumber } from 'libphonenumber-js';
import _ from 'lodash';
import Promise from 'bluebird';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class TagUpload {
  displayCount = 5;

  // count of tags to be resolved
  tagCount = 0;

  @observable
  file = null;

  @observable
  fileRef = {};

  @observable
  isLoading = false;

  @observable
  isSaving = false;

  @observable
  isOpen = false;

  @observable
  headers = [];

  @observable
  fileParseError = null;

  @observable
  saveResults = {
    dupe: [],
    invalid: [],
    new: [],
  };

  @observable
  tagUploadError = null;

  // Object is used to store parsed phone number
  // as key and tags as value. This structure will help
  // in capturing  a number only once
  @observable
  parsedData = {};

  @observable
  invalidPhoneNumbers = [];

  log = getChildLogger('ui.tagUpload');

  @action
  saving(arg = !this.isSaving) {
    this.isSaving = !!arg;
  }

  @action
  loading(arg = !this.isLoading) {
    this.isLoading = !!arg;
  }

  @action
  pushtoNewlySaved(item) {
    this.saveResults.new.push(item);
  }

  @action
  clear() {
    this.tagCount = 0;
    this.isOpen = false;
    this.file = null;
    this.fileRef = null;
    this.isLoading = false;
    this.isSaving = false;
    this.fileParseError = null;
    this.tagUploadError = null;
    this.headers.clear();
    this.parsedData = {};
    this.invalidPhoneNumbers.clear();
    this.saveResults = { dupe: [], invalid: [], new: [] };
  }

  setup({ open = this.isOpen }) {
    this.set('isOpen', !!open);
  }

  setFile(file) {
    return this.set('file', file);
  }

  setRef(ref) {
    return this.set('fileRef', ref);
  }

  @computed
  get savePercentage() {
    return (
      (this.saveResults.new.length +
        this.saveResults.invalid.length +
        this.saveResults.dupe.length) /
      (_.size(this.parsedData) + this.tagCount)
    );
  }

  @action
  setTagUploadError(err) {
    this.tagUploadError = err;
  }

  @action
  setTagData(parsedData) {
    const log = this.log.getChildLogger('setTagData');
    _.each(parsedData, (data) => {
      let identifier;
      if (data.uuid) {
        identifier = _.trim(data.uuid);
      } else {
        identifier = _.trim(data.phoneNumber);
        // Add plus to front to make sure parsed properly for country code
        if (identifier.indexOf('+') === -1) {
          identifier = `+${identifier}`;
        }
        identifier = parsePhoneNumber(identifier).number;
      }
      const tagObj = {
        tagAction: _.trim(data.action),
        tagTitle: _.trim(data.tag),
      };

      try {
        // only add identifier if it's already not there
        if (!(identifier in this.parsedData)) {
          this.parsedData[identifier] = [tagObj];
        } else {
          // append tags
          const tags = this.parsedData[identifier];
          tags.push(tagObj);
          const uniqTags = _.uniqBy(tags, 'tagTitle');
          this.parsedData[identifier] = uniqTags;
        }
      } catch (error) {
        this.invalidPhoneNumbers.push(`${identifier}: ${error.message}`);
      }
    });
    // const headers = _.keys(parsedData[0]);
    const headers = ['identifier', 'tag(s)', 'data'];
    log.debug(headers);
    this.set('headers', headers);

    return Promise.resolve(this.parsedData);
  }

  @action
  setFileParseError(error) {
    this.set('fileParseError', error);
    return Promise.resolve(this.fileParseError);
  }

  @action
  parseCSVResults() {
    dispatch('ui.tagUpload.isLoading');
  }

  async uploadTags(company) {
    const log = this.log.getChildLogger('uploadTags');
    dispatch('ui.tagUpload.saving', true);

    const identifiers = _.keys(this.parsedData);

    // container to hold tag objects found in the parsed CSV records
    // if it does not exist, we'll create it and enter here
    // This will ensure that a new tag is only created once
    const tagObjects = {};

    // prepare de-duplicated array of all non-empty tags to be be uploaded for all the users
    const tags = _.uniqBy(_.flattenDeep(_.values(this.parsedData)), 'tagTitle');
    // this.tagCount = _.size(tags);
    dispatch('audit.create', {
      data: {
        action: 'Upload User Tags',
        companyId: company.uuid,
        extra: {
          file: _.pick(this.file, ['name', 'type', 'size']),
        },
      },
    });

    return Promise.all(
      Promise.map(
        tags,
        // find object for existing tag or create a new one
        async (tag) => {
          // check if this tag exist in database
          let tagObject = await dispatch('quals.findOne', {
            query: {
              companies: company.uuid,
              title: tag.tagTitle,
            },
          });
          // create a new tag if it doesn't exist already
          if (!tagObject) {
            tagObject = await dispatch('quals.create', {
              data: {
                companies: [company.uuid],
                title: tag.tagTitle,
              },
            });
          }
          // add tag object in container
          tagObjects[tag.tagTitle] = tagObject;
          action((tagOb) => {
            this.saveResults.new.push(tagOb);
          })(tag);
          return true;
        },
        { concurrency: 20 },
      ),
    ).then(() =>
      Promise.all(
        Promise.map(
          identifiers,
          async (identifier) => {
            try {
              // try to find the worker using uuid or phoneNumber
              const key =
                identifier.indexOf('+') === -1 ? 'uuid' : 'phoneNumber';
              const worker = await dispatch('workers.findOne', {
                query: { [key]: identifier },
              });
              if (!worker) {
                throw new Error('No worker found by this number');
              }
              log.debug(worker.certs);
              // array of existing tags for a worker. If worker already contains a tag
              // we'll not create it
              const existingCerts = _(worker.certs)
                .filter((c) => _.includes(c.companies, company.uuid))
                .filter((c) => c.certType === 'qual')
                .map('title')
                .uniq()
                .value();
              const requestedTags = this.parsedData[identifier].filter(Boolean);
              // tag to be pushed to worker object
              const workerTags = [];

              // loop through each tag attach to the worker
              await Promise.map(requestedTags, async (tag) => {
                // if tagAction === remove pull tag from user certs
                if (tag.tagAction === 'remove') {
                  const tagObj = tagObjects[tag.tagTitle];
                  await dispatch('workers.update', {
                    data: {
                      $pull: {
                        certs: {
                          certType: 'qual',

                          companies: company.uuid,
                          // title: { $regex: `^${tag.tagTitle}$`, $options: 'i' },
                          uuid: tagObj.uuid,
                        },
                      },
                    },
                    id: worker.uuid,
                  });
                } else if (!_.includes(existingCerts, tag.tagTitle)) {
                  // append tag object
                  workerTags.push(tagObjects[tag.tagTitle]);
                } else {
                  log.debug(`Worker already has ${tag.tagTitle} cert`);
                }
              });

              log.debug('Collected tag objects ==>', workerTags);
              /**
               * TODO: EP-728 Use standardized cert manipulation logic as seen in `workers.addCert`
               * and `workers.removeCert`. Logic will need to be updated to handle arrays
               *  */
              // push the tag to users certs
              const res = await dispatch('workers.update', {
                data: { $push: { certs: { $each: workerTags } } },
                id: worker.uuid,
              });

              // call function returned by action
              action((response) => {
                if (response.isPatched) {
                  this.saveResults.dupe.push(response);
                } else {
                  this.saveResults.new.push(response);
                }
              })(res);
              return true;
            } catch (err) {
              log.debug(err);
              action((error) =>
                this.saveResults.invalid.push({
                  error: error.toString(),
                  identifier,
                }),
              )(err);
            }
            return true;
          },
          { concurrency: 20 },
        ),
      )
        .then(() => this.saveResults)
        .catch((err) => {
          dispatch('ui.tagUpload.setTagUploadError', err);
          dispatch('ui.taglUpload.saving', false);
        }),
    );

    // Find worker by phone number and then update the tag
  }

  get(name) {
    return this[name];
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(name, val) {
    if (_.isArrayLikeObject(this[name])) {
      _.each(val, (v) => this[name].push(v));
    } else {
      this[name] = val;
    }
    return Promise.resolve(this[name]);
  }
}
