/* eslint no-confusing-arrow: 0 */
import _ from 'lodash';
import { action, observable, autorun, computed } from 'mobx';
import { extend } from 'rfx-core';

// ui classes

import { Class } from '~/global';
import { breakpoints as breakpointsUiDefinition } from '#/ssm-ui/breakpoints';

import auth from './ui/Auth';
import activateAccount from './ui/ActivateAccount';
import admin from './ui/Admin';
import addressModal from './ui/AddressModal';
import addressUploader from './ui/AddressUploader';
import applySchedulingTemplateModal from './ui/ApplySchedulingTemplateModal';
import appealShiftResultModal from './ui/AppealShiftResultModal';
import appNav from './ui/AppNav';
import activateShiftModal from './ui/ActivateShiftModal';
import assetUploader from './ui/AssetUploader';
import assignmentApprovalModal from './ui/AssignmentApprovalModal';
import thingReassignmentLocationFilter from './ui/ThingReassignmentLocationFilter';
import assignShiftModal from './ui/AssignShiftModal';
import assignSurveyModal from './ui/AssignSurveyModal';
import avatarEditor from './ui/AvatarEditor';
import bulkSubmitTransfersModal from './ui/BulkSubmitTransfersModal';
import bulkRemoveUserUploader from './ui/BulkRemoveUserUploader';
import campaignConstructor from './ui/CampaignConstructor';
import campaignDetails from './ui/CampaignDetails';
import campaignDispatch from './ui/CampaignDispatch';
import campaignWork from './ui/CampaignWork';
import campaignEditModal from './ui/CampaignEditModal';
import campaignCloneModal from './ui/campaign/CampaignCloneModal';
import trainingEditModal from './ui/TrainingEditModal';
import productEditModal from './ui/ProductEditModal';
import campaignReportBuilder from './ui/CampaignReportBuilder';
import campaignQuotaUploader from './ui/CampaignQuotaUploader';
import candidateModal from './ui/NewCandidateModal';
import cannedResponses from './ui/CannedResponses';
import companyOnboardings from './ui/CompanyOnboardings';
import certLinkModal from './ui/CertLinkModal';
import channelMembersModal from './ui/ChannelMembersModal';
import chatMenu from './ui/chat/ChatMenu';
import chatMessaging from './ui/ChatMessaging';
import chatInput from './ui/ChatInput';
import checkInCheckOutModal from './ui/CheckInCheckOutModal';
import company from './ui/Company';
import companyEditModal from './ui/CompanyEditModal';
import companyImageModal from './ui/CompanyImageModal';
import createChannelModal from './ui/CreateChannelModal';
import createChatFilterModal from './ui/CreateChatFilterModal';
import createTransactionModal from './ui/CreateTransactionModal';
import associatedAccountsModal from './ui/AssociatedAccountsModal';
import createSchedulingTemplateModal from './ui/CreateSchedulingTemplateModal.deprecated';
import createShiftGroupChatModal from './ui/CreateShiftGroupChatModal';
import createShiftModal from './ui/CreateShiftModal';
import fillShiftModal from './ui/FillShiftModal';
import customerUpload from './ui/CustomerUpload';
import dashboard from './ui/Dashboard';
import dateTimeSelect from './ui/DateTimeSelect';
import deletePartnerModal from './ui/DeletePartnerModal';
import employerEditModal from './ui/EmployerEditModal';
import experienceEditModal from './ui/ExperienceEditModal';
import userIdsModal from './ui/UserIdsModal';
import userOnboardingModal from './ui/UserOnboardingModal';
import inShiftTasks from './ui/InShiftTasks';
import inspectionDetailsModal from './ui/InspectionDetailsModal';
import launchpad from './ui/Launchpad';
import agendaProgressLoadingModal from './ui/AgendaProgressLoadingModal';
import loadingModal from './ui/LoadingModal';
import manageCompany from './ui/ManageCompany';
import map from './ui/Map';
import mapInstance from './ui/MapInstance';
import messageTagEditModal from './ui/MessageTagEditModal';
import notifications from './ui/Notifications';
import notificationDetailsModal from './ui/NotificationDetailsModal';
import notificationV2Modal from './ui/NotificationV2Modal';
import partnerAvatarModal from './ui/PartnerAvatarModal';
import partnerTagModal from './ui/PartnerTagModal';
import partnerDetails from './ui/PartnerDetails';
import partnersFilters from './ui/PartnersFilters';
import partnerStatsUploader from './ui/PartnerStatsUploader';
import certsUploader from './ui/CertsUploader';
import assignmentsCancelationUploader from './ui/AssignmentsCancelationUploader';
import bulkCreateTransfers from './ui/BulkCreateTransfers';
import bonusFormUploader from './ui/BonusFormUploader';
import invoiceAndCreditUpload from './ui/InvoiceAndCreditUpload';
import payments from './ui/Payments';
import paymentAccountModal from './ui/PaymentAccountModal';
import paymentBonusModal from './ui/PaymentBonusModal';
import poolDetails from './ui/PoolDetails';
import poolDetailsModal from './ui/PoolDetailsModal';
import postCreateModal from './ui/PostCreateModal';
import ptsShareModal from './ui/PTSShareModal';
import quotaBulkToggleModal from './ui/QuotaBulkToggleModal';
import quotaClose from './ui/QuotaClose';
import quotaEdit from './ui/QuotaEdit';
import bonusBulkUpdateModal from './ui/BonusBulkUpdateModal.deprecated';
import removeChannelUserModal from './ui/RemoveChannelUserModal';
import retailShopActions from './ui/RetailShopActions';
import searches from './ui/Searches';
import scheduledReports from './ui/ScheduledReports';
import schedulingTemplateUploader from './ui/SchedulingTemplateUploader';
import sendShiftModal from './ui/SendShiftModal';
import storeEditModal from './ui/StoreEditModal';
import storeLists from './ui/StoreLists';
import storeListModal from './ui/StoreListModal';
import shiftDetails from './ui/ShiftDetails';
import shiftsNextGen from './ui/ShiftsNextGen';
import shiftFormNextGen from './ui/ShiftFormNextGen';
import shiftApprovalsModal from './ui/ShiftApprovalsModal';
import shiftInvitations from './ui/ShiftInvitations';
import shiftInvitationsModal from './ui/ShiftInvitationsModal';
import messagePartnersModal from './ui/MessagePartnersModal';
import assignChatModal from './ui/AssignChatModal';
import shiftReviewsModal from './ui/ShiftReviewsModal';
import shiftRemindersModal from './ui/ShiftRemindersModal';
import shiftUploader from './ui/ShiftUploader';
import shiftRatingUpload from './ui/ShiftRatingUpload';
import shiftPaymentApprovalUpload from './ui/ShiftPaymentApprovalUpload';
import shiftHoursUpload from './ui/ShiftHoursUpload';
import storeUpload from './ui/StoreUpload';
import surveyReviewImageModal from './ui/SurveyReviewImageModal';
import poolUpload from './ui/PoolUpload';
import tagUpload from './ui/TagUpload';
import weeklyScheduleUpload from './ui/WeeklyScheduleUpload';
import monthlyScheduleUpload from './ui/MonthlyScheduleUpload';
import shopApprovalUploader from './ui/ShopApprovalUploader';
import snackBar from './ui/SnackBar';
import submitTransactionModal from './ui/SubmitTransactionModal';
import surveyCloneConfirmModal from './ui/SurveyCloneConfirmModal';
import surveyDefinitionForm from './ui/SurveyDefinitionForm';
import surveyUpload from './ui/SurveyUpload';
import surveyResponseModal from './ui/SurveyResponseModal';
import campaignWorkReview from './ui/CampaignWorkReview';
import surveyResults from './ui/SurveyResults';
import tagModal from './ui/TagModal';
import tagGroupEditModal from './ui/TagGroupEditModal';
import taskEditModal from './ui/TaskEditModal';
import timecardModal from './ui/TimecardModal';
import thingCancelConfirm from './ui/ThingCancelConfirm';
import thingUnassignConfirmModal from './ui/ThingUnassignConfirmModal';
import thingModal from './ui/ThingModal';
import themeStore from './ui/Theme';
import upsellModal from './ui/UpsellModal';
import workerEditModal from './ui/WorkerEditModal';
import workerMembershipModal from './ui/WorkerMembershipModal';
import workerReviewModal from './ui/WorkerReviewModal';
import workerSnoozeConfirm from './ui/WorkerSnoozeConfirm';
import workerPoolModal from './ui/WorkerPoolModal.deprecated';
import workerCertModal from './ui/WorkerCertModal';
import workerCertAdminModal from './ui/WorkerCertAdminModal.deprecated';
import workerDetails from './ui/WorkerDetails';
import workerTagFilter from './ui/WorkerTagFilter';
import workerPoolFilter from './ui/WorkerPoolFilter';
import workerSearchBar from './ui/WorkerSearchBar';
import customUserMetricsUploader from './ui/CustomUserMetricsUploader';
import onboardings from './ui/partnerJourney/Onboardings';
import opportunities from './ui/Opportunities';
import filters from './ui/Filters';
import userOnboardingUploader from './ui/partnerJourney/UserOnboardingUploader';
import recurringScheduleUploader from './ui/RecurringScheduleUploader';
import recurringSchedules from './ui/RecurringSchedules';
import chatTagsModal from './ui/ChatTagsModal';
import attendanceViolationCountUploader from './ui/AttendanceViolationCountUploader';
import removePartnersUploader from './ui/RemovePartnersUploader';
import createInPersonTrainingShiftsModal from './ui/CreateInPersonTrainingShiftsModal';

export type UIStoresType = {
  activateAccount: activateAccount;
  activateShiftModal: activateShiftModal;
  addressModal: addressModal;
  addressUploader: addressUploader;
  admin: admin;
  agendaProgressLoadingModal: agendaProgressLoadingModal;
  appNav: appNav;
  appealShiftResultModal: appealShiftResultModal;
  applySchedulingTemplateModal: applySchedulingTemplateModal;
  assetUploader: assetUploader;
  assignChatModal: assignChatModal;
  assignShiftModal: assignShiftModal;
  assignSurveyModal: assignSurveyModal;
  assignmentApprovalModal: assignmentApprovalModal;
  assignmentsCancelationUploader: assignmentsCancelationUploader;
  associatedAccountsModal: associatedAccountsModal;
  attendanceViolationCountUploader: attendanceViolationCountUploader;
  auth: auth;
  avatarEditor: avatarEditor;

  bonusBulkUpdateModal: bonusBulkUpdateModal;
  bonusFormUploader: bonusFormUploader;
  bulkCreateTransfers: bulkCreateTransfers;
  bulkRemoveUserUploader: bulkRemoveUserUploader;
  bulkSubmitTransfersModal: bulkSubmitTransfersModal;

  campaignCloneModal: campaignCloneModal;
  campaignConstructor: campaignConstructor;
  campaignDetails: campaignDetails;
  campaignDispatch: campaignDispatch;
  campaignEditModal: campaignEditModal;
  campaignQuotaUploader: campaignQuotaUploader;
  campaignReportBuilder: campaignReportBuilder;
  campaignWork: campaignWork;
  campaignWorkReview: campaignWorkReview;
  candidateModal: candidateModal;
  cannedResponses: cannedResponses;
  certLinkModal: certLinkModal;
  certsUploader: certsUploader;
  channelMembersModal: channelMembersModal;
  chatInput: chatInput;
  chatMenu: chatMenu;
  chatMessaging: chatMessaging;
  chatTagsModal: chatTagsModal;
  checkInCheckOutModal: checkInCheckOutModal;
  company: company;
  companyEditModal: companyEditModal;
  companyImageModal: companyImageModal;
  companyOnboardings: companyOnboardings;
  createChannelModal: createChannelModal;
  createChatFilterModal: createChatFilterModal;
  createInPersonTrainingShiftsModal: createInPersonTrainingShiftsModal;
  createSchedulingTemplateModal: createSchedulingTemplateModal;
  createShiftGroupChatModal: createShiftGroupChatModal;
  createShiftModal: createShiftModal;
  createTransactionModal: createTransactionModal;
  customUserMetricsUploader: customUserMetricsUploader;
  customerUpload: customerUpload;

  dashboard: dashboard;
  dateTimeSelect: dateTimeSelect;
  deletePartnerModal: deletePartnerModal;

  employerEditModal: employerEditModal;
  experienceEditModal: experienceEditModal;

  fillShiftModal: fillShiftModal;
  filters: filters;

  inShiftTasks: inShiftTasks;
  inspectionDetailsModal: inspectionDetailsModal;
  invoiceAndCreditUpload: invoiceAndCreditUpload;

  launchpad: launchpad;
  loadingModal: loadingModal;

  manageCompany: manageCompany;
  map: map;
  mapInstance: mapInstance;
  messagePartnersModal: messagePartnersModal;
  messageTagEditModal: messageTagEditModal;
  monthlyScheduleUpload: monthlyScheduleUpload;
  notificationDetailsModal: notificationDetailsModal;
  notificationV2Modal: notificationV2Modal;

  notifications: notifications;

  onboardings: onboardings;
  opportunities: opportunities;
  partnerAvatarModal: partnerAvatarModal;

  partnerDetails: partnerDetails;
  partnerStatsUploader: partnerStatsUploader;
  partnerTagModal: partnerTagModal;
  partnersFilters: partnersFilters;
  paymentAccountModal: paymentAccountModal;
  paymentBonusModal: paymentBonusModal;
  payments: payments;
  poolDetails: poolDetails;
  poolDetailsModal: poolDetailsModal;
  poolUpload: poolUpload;
  postCreateModal: postCreateModal;
  productEditModal: productEditModal;
  ptsShareModal: ptsShareModal;

  quotaBulkToggleModal: quotaBulkToggleModal;
  quotaClose: quotaClose;
  quotaEdit: quotaEdit;

  recurringScheduleUploader: recurringScheduleUploader;
  recurringSchedules: recurringSchedules;
  removeChannelUserModal: removeChannelUserModal;
  removePartnersUploader: removePartnersUploader;
  retailShopActions: retailShopActions;

  scheduleUpload: weeklyScheduleUpload;
  scheduledReports: scheduledReports;
  schedulingTemplateUploader: schedulingTemplateUploader;
  searches: searches;
  sendShiftModal: sendShiftModal;
  shiftApprovalsModal: shiftApprovalsModal;
  shiftDetails: shiftDetails;
  shiftFormNextGen: shiftFormNextGen;
  shiftHoursUpload: shiftHoursUpload;
  shiftInvitations: shiftInvitations;
  shiftInvitationsModal: shiftInvitationsModal;
  shiftPaymentApprovalUpload: shiftPaymentApprovalUpload;
  shiftRatingUpload: shiftRatingUpload;
  shiftRemindersModal: shiftRemindersModal;
  shiftReviewsModal: shiftReviewsModal;
  shiftUploader: shiftUploader;
  shiftsNextGen: shiftsNextGen;
  shopApprovalUploader: shopApprovalUploader;
  snackBar: snackBar;
  storeEditModal: storeEditModal;
  storeListModal: storeListModal;
  storeLists: storeLists;
  storeUpload: storeUpload;
  submitTransactionModal: submitTransactionModal;
  surveyCloneConfirmModal: surveyCloneConfirmModal;
  surveyDefinitionForm: surveyDefinitionForm;
  surveyResponseModal: surveyResponseModal;
  surveyResults: surveyResults;
  surveyReviewImageModal: surveyReviewImageModal;
  surveyUpload: surveyUpload;

  tagGroupEditModal: tagGroupEditModal;
  tagModal: tagModal;
  tagUpload: tagUpload;
  taskEditModal: taskEditModal;
  theme: themeStore;
  thingCancelConfirm: thingCancelConfirm;
  thingModal: thingModal;
  thingReassignmentLocationFilter: thingReassignmentLocationFilter;
  thingUnassignConfirmModal: thingUnassignConfirmModal;
  timecardModal: timecardModal;
  trainingEditModal: trainingEditModal;

  upsellModal: upsellModal;
  userIdsModal: userIdsModal;
  userOnboardingModal: userOnboardingModal;
  userOnboardingUploader: userOnboardingUploader;

  workerCertAdminModal: workerCertAdminModal;
  workerCertModal: workerCertModal;
  workerDetails: workerDetails;
  workerEditModal: workerEditModal;
  workerMembershipModal: workerMembershipModal;
  workerPoolFilter: workerPoolFilter;
  workerPoolModal: workerPoolModal;
  workerReviewModal: workerReviewModal;
  workerSearchBar: workerSearchBar;
  workerSnoozeConfirm: workerSnoozeConfirm;
  workerTagFilter: workerTagFilter;
};

/**
 * This object stores the class objects for each UI Store, which is then fed into
 * the rfx-core's `@extends` method to instantiate and initialize all store instances.
 */
const uiStoreClasses: Record<keyof UIStoresType, Class> = {
  activateAccount,
  activateShiftModal,
  addressModal,
  addressUploader,
  admin,
  agendaProgressLoadingModal,
  appNav,
  appealShiftResultModal,
  applySchedulingTemplateModal,
  assetUploader,
  assignChatModal,
  assignShiftModal,
  assignSurveyModal,
  assignmentApprovalModal,
  assignmentsCancelationUploader,
  associatedAccountsModal,
  attendanceViolationCountUploader,
  auth,
  avatarEditor,

  bonusBulkUpdateModal,
  bonusFormUploader,
  bulkCreateTransfers,
  bulkRemoveUserUploader,
  bulkSubmitTransfersModal,

  campaignCloneModal,
  campaignConstructor,
  campaignDetails,
  campaignDispatch,
  campaignEditModal,
  campaignQuotaUploader,
  campaignReportBuilder,
  campaignWork,
  campaignWorkReview,
  candidateModal,
  cannedResponses,
  certLinkModal,
  certsUploader,
  channelMembersModal,
  chatInput,
  chatMenu,
  chatMessaging,
  chatTagsModal,
  checkInCheckOutModal,
  company,
  companyEditModal,
  companyImageModal,
  companyOnboardings,
  createChannelModal,
  createChatFilterModal,
  createInPersonTrainingShiftsModal,
  createSchedulingTemplateModal,
  createShiftGroupChatModal,
  createShiftModal,
  createTransactionModal,
  customUserMetricsUploader,
  customerUpload,

  dashboard,
  dateTimeSelect,
  deletePartnerModal,

  employerEditModal,
  experienceEditModal,

  fillShiftModal,
  filters,

  inShiftTasks,
  inspectionDetailsModal,
  invoiceAndCreditUpload,

  launchpad,
  loadingModal,

  manageCompany,
  map,
  mapInstance,
  messagePartnersModal,
  messageTagEditModal,
  monthlyScheduleUpload,

  notificationDetailsModal,
  notificationV2Modal,
  notifications,

  onboardings,
  opportunities,

  partnerAvatarModal,
  partnerDetails,
  partnerStatsUploader,
  partnerTagModal,
  partnersFilters,
  paymentAccountModal,
  paymentBonusModal,
  payments,
  poolDetails,
  poolDetailsModal,
  poolUpload,
  postCreateModal,
  productEditModal,
  ptsShareModal,

  quotaBulkToggleModal,
  quotaClose,
  quotaEdit,

  recurringScheduleUploader,
  recurringSchedules,
  removeChannelUserModal,
  removePartnersUploader,
  retailShopActions,

  scheduleUpload: weeklyScheduleUpload,
  scheduledReports,
  schedulingTemplateUploader,
  searches,
  sendShiftModal,
  shiftApprovalsModal,
  shiftDetails,
  shiftFormNextGen,
  shiftHoursUpload,
  shiftInvitations,
  shiftInvitationsModal,
  shiftPaymentApprovalUpload,
  shiftRatingUpload,
  shiftRemindersModal,
  shiftReviewsModal,
  shiftUploader,
  shiftsNextGen,
  shopApprovalUploader,
  snackBar,
  storeEditModal,
  storeListModal,
  storeLists,
  storeUpload,
  submitTransactionModal,
  surveyCloneConfirmModal,
  surveyDefinitionForm,
  surveyResponseModal,
  surveyResults,
  surveyReviewImageModal,
  surveyUpload,

  tagGroupEditModal,
  tagModal,
  tagUpload,
  taskEditModal,
  theme: themeStore,
  thingCancelConfirm,
  thingModal,
  thingReassignmentLocationFilter,
  thingUnassignConfirmModal,
  timecardModal,
  trainingEditModal,

  upsellModal,
  userIdsModal,
  userOnboardingModal,
  userOnboardingUploader,

  workerCertAdminModal,
  workerCertModal,
  workerDetails,
  workerEditModal,
  workerMembershipModal,
  workerPoolFilter,
  workerPoolModal,
  workerReviewModal,
  workerSearchBar,
  workerSnoozeConfirm,
  workerTagFilter,
};
@extend(uiStoreClasses)
class UIStore {
  // On page load the breakpoints variable values are converted from strings
  // booleans in the "MatchMediaProvider" component
  @observable
  // @ts-expect-error UI Breakpoints defined as strings, accessed as booleans
  breakpoints: Record<keyof typeof breakpointsUiDefinition, boolean> =
    breakpointsUiDefinition;

  @observable
  paneWidths = [];

  @observable
  header = {};

  init() {
    autorun(() => {
      if (_.isBoolean(this.breakpoints.ux)) {
        this.setNavCollapsed(this.breakpoints.ux);
        this.toggleNavVisible(!this.breakpoints.xs);
      }
    });

    this.initUI();
  }

  getBreakpoints() {
    return this.breakpoints;
  }

  @action
  initUI() {
    if (_.isEmpty(this.paneWidths)) {
      this.paneWidths = this.getPaneWidths();
    }
  }

  /** @deprecated */
  getPaneWidths() {
    if (global.TYPE === 'CLIENT') {
      if (localStorage.getItem('paneWidths') === null) {
        localStorage.setItem('paneWidths', JSON.stringify([300, null, 375]));
      }

      return [this.getPaneWidth(0), null, this.getPaneWidth(2)];
    }
    return [300, null, 375];
  }

  getPaneWidth(index) {
    let size = 300;
    if (global.TYPE === 'CLIENT') {
      const paneWidths = JSON.parse(localStorage.getItem('paneWidths'));

      if (!_.isEmpty(paneWidths)) {
        size = _.get(paneWidths, `[${index}]`, 300);
      }
    }

    return size;
  }

  @action
  setHeader(header) {
    this.header = header;
  }

  @action
  setPaneWidth(index, size) {
    this.paneWidths[index] = size;

    if (global.TYPE === 'CLIENT') {
      localStorage.setItem('paneWidths', JSON.stringify(this.paneWidths));
    }
  }

  /**
   * ### isNavCollapsed
   * The left hand nav bar can be in either a "collapsed" (~70px) or
   * a "expanded" (~210px) state. This state is controlled in one of
   * two ways:
   * 1. clicking the "handle" on the right hand side of the nav bar
   * 2. resizing the window across the "UnderXtralarge" (`breakpoints.ux`) threshold
   *
   * @memberof UIStore
   */
  @observable
  isNavCollapsed = false;

  /**
   * ### setNavCollapsed
   * Toggles the left hand nav bar's current state, or sets it to the
   * specified value
   *
   * @param {*} [val=!this.isNavCollapsed]
   * @memberof UIStore
   */
  @action
  setNavCollapsed(val = !this.isNavCollapsed) {
    this.isNavCollapsed = val;
  }

  @observable
  _navBarWidth = 210;

  @computed
  get navBarWidth() {
    return this.isNavCollapsed ? 70 : this._navBarWidth ?? 210;
  }

  /**
   * ### isNavVisible
   * On small screens (ie: phones) the nav bar is hidden and acessible via
   * a hamburger menu instaed of being omni-present. This variable handles
   * the current state of whether the nav bar should be shown or not.
   *
   * @memberof UIStore
   */
  @observable
  isNavVisible = true;

  /**
   * ### setNavVisible
   * When in hamburger-menu mode, toggles the visibility of the nav bar, or
   * sets it to the specified value;
   *
   * @param {*} [val=!this.isNavVisible]
   * @memberof UIStore
   */

  @action
  toggleNavVisible(val = !this.isNavVisible) {
    this.isNavVisible = val;
  }
}

// interface UIStore extends UIStoresType {
//   isMixin: true;
// }

export type UIStoreType = UIStore;
export type UIStoreName = keyof UIStoresType;
export type UIStorePath = `ui.${UIStoreName}`;

UIStore.displayName = 'uiStore';

export default UIStore;
