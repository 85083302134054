import type { IUser } from '@shiftsmartinc/shiftsmart-types';

import React, { useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import cx from 'classnames';
import { Icon, Popup, Button, StrictPopupProps } from 'semantic-ui-react';
import styled from 'styled-components';
import moment from 'moment';

import { PhoneNumber } from '#/shared/components/utils/PhoneNumber';
import { GlobalCopyToClipboard } from '#/global/components/GlobalCopyToClipboard';
import { StoreName } from '#/shared/stores';
import { useCachedUserItem } from '#/shared/hooks/useCacheItem';
import { Address } from '#/shared/components/utils';
import { useStores } from '#/shared/hooks/useStores';

const icons = {
  address: {
    height: 14,
    marginLeft: '-4px',
    uri: '/static/img/icon-map-marker.png',
    width: 10,
  },
  email: {
    height: 10,
    uri: '/static/img/icon-email.png',
    width: 16,
  },
  externalId: {
    height: 16,
    uri: '/static/img/icon-credential.png',
    width: 18,
  },
  link: {
    height: 16,
    uri: '/static/img/icon-link.png',
    width: 18,
  },
  phone: {
    height: 12,
    uri: '/static/img/icon-phone.png',
    width: 12,
  },
};

type WorkerAttributeProps = {
  field: keyof IUser;
  format?: (val?: unknown) => JSX.Element;
  storeName?: ('workers' | 'partners' | 'users' | 'employerUsers') & StoreName;
  user?: IUser;
  userId: IUser['uuid'];
} & PartnerAttributeProps;

/**
 * ## WorkerAttribute
 * A simple wrapper on top of the PartnerAttribute component which will
 * lookup the specified worker by ID or via the passed in object.
 *
 * This is a prototyupe approach to a much simpler, much more lightweight
 * version of the "UserObjComponent" mess.
 */
export const WorkerAttribute = observer(
  ({
    userId,
    user,
    storeName = 'workers',
    field,
    format,
    ...rest
  }: WorkerAttributeProps) => {
    const { item: userObj, isLoading } = useCachedUserItem({
      fields: [field],
      storeName,
      user: user?.uuid ? user : undefined,
      userId: userId,
    });

    let formattedValue = userObj?.[field];

    if (_.isFunction(format)) {
      formattedValue = format(formattedValue);
    } else if (_.isDate(formattedValue)) {
      formattedValue = moment(formattedValue).format('L LT');
    }

    return (
      <PartnerAttribute
        content={formattedValue}
        isLoading={isLoading}
        type={field}
        value={userObj?.[field]}
        {...rest}
      />
    );
  },
);

type PartnerAttributeProps = {
  /** Optionally formatted value for display purposes */
  content?: string | unknown;
  icon?: 'only' | true | false;
  iconOnly?: boolean;
  isLoading?: boolean;
  position?: StrictPopupProps['position'];
  textClassName?: string;
  type: string;
  value: string | unknown;
};

export const PartnerAttribute = observer(
  ({
    type,
    value,
    content,
    textClassName,
    iconOnly = false,
    position = 'bottom center',
    isLoading,
    icon: iconProp = iconOnly ? 'only' : true,
  }: PartnerAttributeProps) => {
    const [isCopied, setIsCopied] = useState(false);
    const { abilities } = useStores();
    let icon =
      _.get(icons, type) ?? ((/phone/i.test(type) && icons.phone) || iconProp);

    let val = value;
    let displayVal = value;

    const field = /phone/i.test(type) ? 'phoneNumber' : type;

    if (abilities.cannot('read', 'worker', field)) {
      val = null;
    } else if (!val && isLoading) {
      val = <Icon loading={true} name="spinner" />;
    } else if (content) {
      displayVal = content;
    }

    const isPhone = /phone/i.test(type);
    let formattedValue = isPhone ? (
      <GlobalCopyToClipboard className="nowrap" text={val || 'N/A'}>
        <PhoneNumber className="" defaultText="N/A" val={val} />
      </GlobalCopyToClipboard>
    ) : (
      <GlobalCopyToClipboard className="nowrap" text={val || 'N/A'}>
        {displayVal || val}
      </GlobalCopyToClipboard>
    );

    if (/address/.test(type)) {
      icon = 'map';
      formattedValue = <Address className="pl2" data={val ?? {}} />;
    }

    let copyIcon = null;

    if (isCopied) {
      copyIcon = (
        <Button secondary={true} style={{ padding: 5 }}>
          <Icon
            color="green"
            name="check"
            secondary={true}
            style={{ marginRight: 5 }}
          />
          Copied
        </Button>
      );
    } else if (_.isString(val)) {
      copyIcon = (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(val);
            setIsCopied(true);
          }}
          secondary={true}
          style={{ padding: 5 }}
        >
          <Icon
            color="blue"
            name="copy"
            secondary={true}
            style={{ marginRight: 5 }}
          />
          Copy
        </Button>
      );
    }

    if (/only/i.test(iconProp)) {
      return (
        <Popup
          content={
            <div>
              <span
                className={cx('ph2 fw4', textClassName)}
                style={{
                  boxOrient: 'vertical',
                  color: 'var(--white)',
                  lineClamp: 2,
                  textOverflow: 'ellipsis',
                }}
              >
                {formattedValue}
              </span>
              {copyIcon}
            </div>
          }
          disabled={!val}
          flowing={true}
          hoverable={true}
          inverted={true}
          onOpen={() => setIsCopied(false)}
          popperModifiers={[
            {
              name: 'preventOverflow',
              options: {
                boundariesElement: 'offsetParent',
              },
            },
          ]}
          position={position}
          trigger={
            <div
              className="flex items-center justify-center"
              style={{ height: '16px', width: '16px' }}
            >
              {_.isString(icon) ? (
                <div>
                  <PartnerStyledIcon name={icon} />
                </div>
              ) : (
                icon?.uri && (
                  <img
                    alt=""
                    src={icon.uri}
                    style={{
                      // ...icon,?
                      height: icon.height,
                      opacity: val ? 1 : 0.5,
                      width: icon.width,
                    }}
                  />
                )
              )}
            </div>
          }
        />
      );
    }

    const showIcon = !!icon && !!iconProp;

    return (
      <div className="flex flex-row items-center">
        {showIcon && (
          <div
            className="flex items-center justify-center"
            style={{ height: '16px', width: '16px' }}
          >
            {_.isString(icon) ? (
              <div>
                <PartnerStyledIcon name={icon} />
              </div>
            ) : (
              icon?.uri && (
                <img
                  alt=""
                  src={icon.uri}
                  style={{
                    height: icon.height,
                    width: icon.width,
                  }}
                />
              )
            )}
          </div>
        )}
        <span
          className={cx('fw4', 'pr2', textClassName)}
          style={{
            color: val ? 'var(--text-primary)' : 'var(--text-secondary)',
            textOverflow: 'ellipsis',
          }}
        >
          {formattedValue}
        </span>
      </div>
    );
  },
);

PartnerAttribute.displayName = 'PartnerAttribute';

export default PartnerAttribute;

const PartnerStyledIcon = styled(Icon)`
  &&& {
    color: #7487b6;
  }
`;
