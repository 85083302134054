import React from 'react';
import { observer } from 'mobx-react';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import moment from 'moment';
import cx from 'classnames';
import { Label, Icon, Button, Checkbox } from 'semantic-ui-react';
import Truncate from 'react-truncate';

import { useStores } from '#/shared/hooks/useStores';
import styles from '#/shared/styles/ChannelSummary.css';
import ChannelAvatar from '#/shared/components/chat/ChannelAvatar';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('chat.ChannelSummary');

const ChannelSummary = observer(
  ({ channel, selected = {}, auth, onlineUsers = new Map(), onSelect }) => {
    const { chatChannels } = useStores();
    const debug = false;
    const company = dispatch('auth.getCompany');
    const worker = getChannelWorker({ channel });
    const onlineStatus = getOnlineStatus({ channel, onlineUsers });
    const channelDisplayName = getChannelDisplayName({ channel, worker });

    const channelType = _.get(channel, 'channelType.category');
    const isBroadcast = _.get(channel, 'isBroadcast', false);

    const channelUser = _.find(channel.users, { uuid: auth.user.uuid });
    const lastMessage = _.get(channel, 'lastMessage', {});
    const employerMembers = getChannelEmployers({ channel });
    const otherEmployerMembers = employerMembers.filter(
      (member) => member.uuid !== _.get(channelUser, 'uuid'),
    );
    const unreadMsg =
      _.get(lastMessage, 'index', 0) -
      Math.max(_.get(channelUser, 'lastConsumedIndex', -1), 0);
    const otherEmployerRead = _.find(
      otherEmployerMembers,
      (member) =>
        Math.max(_.get(member, 'lastConsumedIndex', -1), 0) >=
        _.get(lastMessage, 'index', 0),
    );
    const isSelected = selected.uuid === channel.uuid;
    let lastMessageTime;
    if (
      _.has(lastMessage, 'messageCreated') &&
      moment(lastMessage.messageCreated).isValid()
    ) {
      lastMessageTime = moment(lastMessage.messageCreated).format('M/D/YY');
      if (moment(lastMessage.messageCreated).isSame(new Date(), 'day')) {
        lastMessageTime = moment(lastMessage.messageCreated).format('h:mm A');
      }

      if (moment(lastMessage.messageCreated).diff(moment(), 'days') > 7) {
        lastMessageTime = moment(lastMessage.messageCreated).format('ddd');
      }
    }

    const hasChannel = !!channel.sid;
    const canChat =
      hasChannel || dispatch('auth.can', 'update', worker, 'chat');

    const resolved = channel.resolved;

    const unreadMsgOverdue = moment(
      _.get(lastMessage, 'messageCreated'),
    ).isBefore(moment().subtract(5, 'minutes'));

    let unreadMsgColor = 'blue';
    if (otherEmployerRead && channelType === 'one-on-one') {
      unreadMsgColor = 'grey';
    } else if (unreadMsgOverdue) {
      unreadMsgColor = 'red';
    }

    const isChecked = _.some(
      chatChannels.selectedChannels,
      (c) => c?.sid === channel?.sid,
    );

    return (
      <div
        className={cx(
          'w-100 flex',
          'pointer',
          'relative',
          'ssm-no-text-select',
          isSelected ? styles.channelSummaryActive : styles.channelSummary,
        )}
        key={channel.uuid}
        onClick={() => {
          if (_.isFunction(onSelect)) {
            return onSelect({ channel });
          }

          if (auth.isAdmin && !company?.isShiftsmartManaged) {
            dispatch('ui.snackBar.error', 'Admin Users cannot view chats');
            return false;
          }

          return isSelected ? clearChannel() : selectChannel(channel);
        }}
        role="button"
        style={
          !canChat || !hasChannel || channel.isStub
            ? {
                cursor: !canChat ? 'not-allowed' : 'copy',
                opacity: '0.7',
              }
            : {}
        }
        tabIndex={-1}
      >
        <Checkbox
          checked={isChecked}
          className="ml1"
          onClick={(e, { checked }) => {
            e.stopPropagation();
            chatChannels.bulkSelectChannel({ channel, checked });
          }}
          style={{ marginRight: '8px' }}
        />
        <div className={cx('flex justify-center', styles.channelAvatar)}>
          <ChannelAvatar channel={channel} />
          {isBroadcast && (
            <div
              className={styles.broadcastIcon}
              data-inverted=""
              data-position="bottom left"
              data-tooltip="Announcements Only"
            >
              <img
                alt="Announcements Only"
                src="/static/img/icons/ssm-announcements.svg"
              />
            </div>
          )}
        </div>
        <div
          className="w-50 flex-grow-1 flex-column flex"
          style={{ marginLeft: '8px' }}
        >
          <div className="flex justify-between">
            <div
              className={cx(
                'flex items-center',
                styles.channelDisplayName,
                isSelected && styles.channelSelected,
              )}
            >
              <Truncate ellipsis="&hellip;" lines={2} width={140}>
                {channelDisplayName}
              </Truncate>
              {onlineStatus.online && (
                <div
                  className="ml2 br-100 bg-green"
                  style={{ height: '0.4rem', width: '0.4rem' }}
                >
                  &nbsp;
                </div>
              )}
            </div>
            <div
              className="black-50 f7 flex items-center"
              style={{ paddingRight: '15px' }}
            >
              <div
                className={cx(
                  styles.channelTimestamp,
                  isSelected && styles.channelSelected,
                )}
              >
                {lastMessageTime}
              </div>
              {unreadMsg > 0 && (
                <div className="ml2">
                  <Label
                    circular={true}
                    className="ssm-label"
                    color={unreadMsgColor}
                    content={unreadMsg}
                  />
                </div>
              )}
              {resolved && (
                <div className="ml2" style={{ width: '20px' }}>
                  <img
                    alt="Resolve Channel"
                    src="/static/img/shifts/icon-approved.png"
                  />
                </div>
              )}
            </div>
          </div>

          {!!channel.purple && (
            <>
              <div className="f7 silver">
                Stub: {channel.isStub ? 'YES' : 'NO'}
              </div>
              <div className="f7 silver">CA: {channel.createdAt}</div>
              <div className="f7 silver">UA: {channel.updatedAt}</div>
              <div className="f7 silver">SID: {channel.sid}</div>
            </>
          )}

          {_.get(channel, 'channelType.refType', '') === 'shift' && (
            <>
              <div
                className={cx(
                  styles.channelShiftGroupType,
                  isSelected && styles.channelSelected,
                )}
              >
                {getChannelShiftStatus({ channel })}
              </div>
              <div
                className={cx(
                  styles.channelShiftGroupDate,
                  isSelected && styles.channelSelected,
                )}
              >
                {moment(
                  _.get(channel, 'channelType.shiftData.start', ''),
                ).format('MMM Do YYYY - hh:mma')}
              </div>
            </>
          )}
          <div
            className={cx(
              styles.channelPreviewMessage,
              isSelected && styles.channelSelected,
            )}
          >
            {/* TODO: Use ref on parent div to set width properly */}
            <Truncate
              className="w-100 flex-grow-1"
              ellipsis="&hellip;"
              lines={1}
              width={240}
            >
              {lastMessage.body}
            </Truncate>
          </div>
          <div className="flex flex-row">
            <Button
              content={_.isEmpty(channel.tags) ? 'Add Chat Tag' : null}
              icon={
                <Icon
                  color="green"
                  name="plus"
                  style={{
                    fontSize: '10px',
                  }}
                />
              }
              onClick={() =>
                dispatch('ui.chatTagsModal.setup', {
                  channel,
                  company,
                  open: true,
                })
              }
              size="mini"
              style={
                _.isEmpty(channel.tags)
                  ? { padding: '5px 10px' }
                  : { padding: '5px 7px' }
              }
            />
            {!_.isEmpty(channel?.tags ?? []) && (
              <>
                {channel.tags.map((tag) => (
                  <Label
                    basic={true}
                    key={tag.uuid}
                    onClick={() =>
                      dispatch('ui.chatTagsModal.setup', {
                        channel,
                        open: true,
                      })
                    }
                    size="mini"
                  >
                    {tag.title}
                  </Label>
                ))}
              </>
            )}
          </div>
          {debug && (
            <div className="w-100 flex justify-between">
              <Label basic={true} content={channelType} />
              <div>
                {_.isEmpty(lastMessage)
                  ? 'no'
                  : `${new Date(lastMessage.messageCreated).getTime()}`}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);

ChannelSummary.displayName = 'ChannelSummary';

export default ChannelSummary;
export {
  getChannelDisplayName,
  getChannelWorker,
  getOnlineStatus,
  getChannelShiftStatus,
};

function clearChannel() {
  // Do nothing, enforce channel selection if channels exist
  // dispatch('ui.chatMessaging.setActiveDetailsTab', '');
  // dispatch('routing.push', '/chats');
}

function selectChannel(channel) {
  if (channel.isStub) {
    const worker = getChannelWorker({ channel });
    dispatch(
      'routing.push',
      `/chats?userId=${worker.uuid}&isStub=true&autoFocus=true`,
    );
  } else {
    dispatch('routing.push', `/chats/${channel.uuid}?autoFocus=true`);
  }
  dispatch('ui.chatInput.setMessageText', { value: '' });
}

function getChannelDisplayName({
  channel,
  worker = getChannelWorker({ channel }),
  channelType = _.get(channel, 'channelType.category'),
}) {
  let channelDisplayName = channel.channelName;
  if (channelType === 'one-on-one' && !_.isEmpty(worker)) {
    channelDisplayName = worker.displayName;
  }
  if (_.isArray(worker) && _.isEmpty(channelDisplayName)) {
    channelDisplayName = worker.map((w) => w.displayName).join(', ');
  }

  return channelDisplayName;
}

function getChannelWorker({ channel }) {
  let worker = {};

  if (_.isEmpty(channel)) {
    return worker;
  }

  const channelType = _.get(channel, 'channelType.category');
  if (channelType === 'one-on-one') {
    worker = _.find(channel.users, { userType: 'worker' });
  } else if (channelType === 'group') {
    worker = _.filter(channel.users, { userType: 'worker' });
  } else if (channelType === 'internal') {
    worker = channel.users;
  } else {
    log.debug('Unhandled channel type: ', channelType);
    worker =
      channel.worker ||
      _.find(channel.users, ({ roles }) => _.includes(roles, 'worker'));
  }

  return worker;
}

function getChannelEmployers({ channel }) {
  return _.filter(channel.users, { userType: 'employer' });
}

function getOnlineStatus({
  channel,
  user = getChannelWorker({ channel }),
  onlineUsers,
}) {
  let onlineStatus = {};

  const channelType = _.get(channel, 'channelType.category');
  if (!_.isEmpty(user) && channelType === 'one-on-one') {
    const userId = _.get(user, 'uuid', user);
    onlineStatus =
      !!onlineUsers &&
      _.isFunction(onlineUsers.has) &&
      onlineUsers.has(userId) &&
      onlineUsers.get(userId);
  }

  return onlineStatus;
}

function getChannelShiftStatus({ channel }) {
  if (_.get(channel, 'channelType.refType', '') === 'shift') {
    const status = _.toLower(_.get(channel, 'channelType.props.status', ''));
    switch (status) {
      case 'sent':
        return 'Sent Partners';
      case 'seen':
        return 'Seen Partners';
      case 'assigned':
        return 'Assigned Partners';
      case 'confirmed':
        return 'Confirmed Partners';
      case 'confirmednocheckin':
        return 'Confirmed & Not Checked-In Partners';
      case 'all':
        return 'All Partners';
      case 'notconfirmed':
        return 'Not Confirmed Partners';
      case 'checkedin':
        return 'Checked In Partners';
      default:
        return '';
    }
  }
  return '';
}
