import styled, { css } from 'styled-components';
import cx from 'classnames';

/**
 * Used for rendering multiple filter components in a row, ensuring that they are
 * consistently spaced. Can be configured for either left or right (default) alignment.
 */
export const FilterRow = styled.div.attrs(({ className, align }) => ({
  className: cx(
    'flex items-center',
    align === 'center' && 'justify-center',
    className,
  ),
}))<{
  /** Dictates where the margin will be applied to child elements, right (default) will apply
   * a `0.5rem` margin on the left of each child, whereas `left` will apply a `0.5rem` margin on
   * the right of each child. This ensures the filter will be flush against the right or left
   * side respectively of the container
   */
  align?: 'right' | 'left' | 'center';
  /** Set to `true` to fully disable margin between elements, or pass in a css measure unit to apply  */
  compact?: boolean | number;
}>`
  height: 38px;

  &&& > * {
    ${({ align = 'right', compact = '0.5rem' }) => {
      if (align === 'left')
        return css`
          margin-right: ${compact === true ? 0 : compact};
          margin-left: 0rem;
        `;

      if (align === 'right')
        return css`
          margin-right: 0rem;
          margin-left: ${compact === true ? 0 : compact};
        `;

      return css`
        margin-right: ${compact === true ? 0 : compact};
        margin-left: ${compact === true ? 0 : compact};
      `;
    }}

    &:first-child {
      margin-left: 0rem;
    }
    &:last-child {
      margin-right: 0rem;
    }
  }
`;
