import { observable, action } from 'mobx';
import { dispatch } from 'rfx-core';

export default class Onboardings {
  @observable
  activeStepId = '';

  @action
  setup() {}

  @action
  clear() {
    this.activeStepId = '';
  }

  @action
  setActiveStepId(stepId) {
    this.activeStepId = stepId;
    dispatch('ui.partnersFilters.clearFilters');
  }
}
