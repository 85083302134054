import { observable, action, set } from 'mobx';

export default class ScheduledReports {
  @observable
  csvReports = {};

  @action
  setReport({ reportId, csvData, filename }) {
    set(this.csvReports, reportId, { csvData, filename, isLoading: false });
  }

  @action
  setLoading({ reportId }) {
    set(this.csvReports, reportId, { isLoading: true });
  }

  @action
  clearReport({ reportId }) {
    delete this.csvReports[reportId];
  }

  @action
  clear() {
    this.csvReports = {};
  }
}
