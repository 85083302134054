import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { dispatch } from 'rfx-core';
import cx from 'classnames';

import ShiftStatusChip from '#/shared/components/shifts/ShiftStatusChip';
import {
  ShiftLocation,
  ShiftTimes,
} from '#/shared/components/shifts/common/ShiftItemUtils';
import UserStack from '#/shared/components/ssm/users/UserStack';
import styles from '#/shared/styles/shifts.css';

const ShiftSummaryContent = ({
  shift,
  duration = _.get(shift, 'duration'),
  overage,
  isLoadingOvertime,
  showDOW,
  card,
}) => (
  <>
    <div className="ph3 pb3">
      {!/draft/i.test(shift.status) && _.isArray(shift.assignedUsers) && (
        <div
          style={{
            marginBottom: '10px',
            marginTop: card ? '10px' : '-20px',
            pointerEvents: 'none',
          }}
        >
          <UserStack
            hideTooltips={true}
            justify="end"
            limit={5}
            overlap={4}
            reverse={true}
            size={38}
            users={shift.assignedUsers.map((uuid) => ({ uuid }))}
          />
        </div>
      )}

      <div
        className={cx(
          styles.weekPopupShiftHeader,
          'ssm-no-text-select flex items-center justify-between',
        )}
        onClick={() => dispatch('routing.push', `/shifts/${shift.uuid}`)}
        role="button"
        tabIndex={0}
      >
        <span className={styles.weekPopupShiftTitle}>{shift.title}</span>
        <ShiftStatusChip
          className={styles.weekPopupShiftStatus}
          status={shift.status}
        />
      </div>

      <ShiftLocation
        address={shift.formattedAddress}
        addressObj={shift.addressObj}
        company={dispatch('auth.getCompany')}
        isRemote={shift.isRemote}
      />
      <ShiftTimes
        end={shift.end}
        showDOW={showDOW}
        start={shift.start}
        timezone={shift.timezone}
      />
    </div>

    <div
      className={cx(
        'pv1 flex flex-row items-end justify-center',
        styles.weekPopupContentStats,
      )}
    >
      <div className={styles.weekPopupStat}>
        <span className={styles.weekPopupStatValue}>
          {shift.rate ? `$${shift.rate.toFixed(2)}/hr` : 'N/A'}
        </span>
        <span className={styles.weekPopupStatLabel}>Pay Rate</span>
      </div>
      {_.isNumber(shift.bonus) && shift.bonus > 0 && (
        <div className={styles.weekPopupStat}>
          <span className={styles.weekPopupStatValue}>
            {`$${shift.bonus.toFixed(2)}`}
          </span>
          <span className={styles.weekPopupStatLabel}>Bonus</span>
        </div>
      )}
      <div className={styles.weekPopupStat}>
        <span className={styles.weekPopupStatValue}>
          {`${duration} ${duration === 1 ? 'hr' : 'hrs'}`}
        </span>
        <span className={styles.weekPopupStatLabel}>Duration</span>
      </div>
      {!isLoadingOvertime && !!overage && (
        <div className={styles.weekPopupStat}>
          <span className={styles.weekPopupStatValue}>
            {`${(overage * 100).toFixed(1)}%`}
          </span>
          <span className={styles.weekPopupStatLabel}>Overtime</span>
        </div>
      )}
    </div>
  </>
);

ShiftSummaryContent.propTypes = {
  card: PropTypes.bool,
  duration: PropTypes.number,
  isLoadingOvertime: PropTypes.bool,
  overage: PropTypes.number,
  shift: PropTypes.object.isRequired,
  showDOW: PropTypes.bool,
};
ShiftSummaryContent.displayName = 'ShiftSummaryContent';
export { ShiftSummaryContent };
