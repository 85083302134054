import { action, observable, set } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.companyImageModal');

export default class CompanyImageModal {
  @observable
  isOpen = false;

  @observable
  company = {};

  @observable
  isEditing = false;

  @observable
  showCroppingTools = false;

  @observable
  imgKey = 'avatar';

  @observable
  file = null;

  @action
  setup({ company, imgKey, fileRef, open = this.isOpen }) {
    this.company = company;
    this.imgKey = imgKey;

    // hhmmmm
    this.isEditing = open;

    const imageURL = _.get(this.company.images, this.imgKey, '');

    const { width, height } = getRatioForImageKey(imgKey);

    try {
      dispatch('ui.avatarEditor.setup', {
        fileRef,
        height,
        imageURL,

        onSave: this.save,
        scale: 1.0,
        width,
      });

      this.open(open);
    } catch (err) {
      log.error('Failed to setup avatar editor', err);

      dispatch('ui.snackBar.error', 'Unable to edit image', {
        body: err.message,
      });

      this.clear();
    }
  }

  @action
  async save({ cropped } = {}) {
    let fileType = 'image/png';

    let file;
    let fileName;

    try {
      if (cropped) {
        const { data: dataURI, canvas } = dispatch(
          'ui.avatarEditor.getImageBlob',
        );

        fileType = dataURI.split(';')[0].replace('data:', '');

        file = await new Promise((resolve) => canvas.toBlob(resolve));
        fileName = this.getFileName(fileType);
      } else {
        if (!_.get(this.file, 'size')) {
          dispatch('ui.snackBar.open', 'Please Select an Image File');
          return;
        }

        file = this.file;
        fileName = this.getFileName(this.file.type);
        fileType = file.type;
      }

      const bucketService =
        process.env.NODE_ENV === 'production' ? 'USER_ASSETS' : 'DEV';

      const result = await dispatch('s3.upload', {
        bucketService,
        file,
        fileType,
        id: fileName,
      });

      const company = await dispatch(`companies.update`, {
        data: {
          [`images.${this.imgKey}`]: result.data.url,
        },
        id: this.company.uuid,
      });

      log.debug('updated images on company to', { images: company.images });

      this.clear();
    } catch (err) {
      log.error('Upload Failed', err, { fileName });

      dispatch('ui.snackBar.error', 'Failed to save image', {
        body: err.message,
      });
    }
  }

  getFileName(fileType) {
    if (!/image\//i.test(fileType)) {
      dispatch('ui.snackBar.open', 'Please select an Image File');
      throw new Error('Please select an image file', {
        fileType,
      });
    }

    const ext = _.last(fileType.split('/'));

    return `${this.company.uuid}-company-${this.imgKey}-${Date.now()}.${ext}`;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    dispatch('ui.avatarEditor.clear');
    this.company = {};
    this.isOpen = false;
    this.imgKey = 'avatar';
    this.isEditing = false;
    this.showCroppingTools = false;
    this.file = {};

    this.fileURI = null;
  }

  // Migrated

  @action
  setImage(image) {
    dispatch('ui.avatarEditor.setImage', image);
  }

  @observable
  fileURI = null;

  @action
  fileChanged(file) {
    set(this, 'file', file);

    this.fileURI = URL.createObjectURL(file);
  }

  @action
  toggleEdit() {
    dispatch('ui.avatarEditor.toggleEdit');
  }

  @action
  toggleCropping(val = !this.showCroppingTools) {
    this.showCroppingTools = val;
  }
}

function getRatioForImageKey(imgKey) {
  switch (imgKey) {
    case 'banner':
      return {
        height: 200,
        width: 2000,
      };
    case 'square':
      return {
        height: 512,
        width: 512,
      };
    case 'avatar':
      return {
        height: 256,
        width: 256,
      };
    case 'logo':
      return {
        height: 1024,
        width: 1024,
      };
    default:
      return {};
  }
}
