import { action, observable, runInAction } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class TagModal {
  log = getChildLogger('ui.PTSShareModal');

  @observable
  isOpen = false;

  @observable
  channel = {};

  @observable
  selected = {};

  @observable
  sender = {};

  @observable
  isLoading = false;

  @action
  setup({ selected, sender, open = this.isOpen }) {
    this.selected = selected;
    this.sender = sender;

    this.isOpen = open;
  }

  @action.bound
  async selectChannel({ channel }) {
    if (_.isEmpty(channel)) {
      this.channel = {};
    } else if (_.isString(channel) || !_.get(channel, 'sid')) {
      const channelId = _.get(channel, 'uuid', channel);
      const channelObj = await dispatch('chatChannels.get', channelId);

      runInAction(() => {
        this.channel = channelObj;
      });
    } else {
      runInAction(() => {
        this.channel = channel;
      });
    }
  }

  @action.bound
  async send() {
    const log = this.log.getChildLogger('send');
    if (_.isEmpty(this.channel)) {
      dispatch('ui.snackBar.open', 'Please select a channel');
      return;
    }

    runInAction(() => {
      this.isLoading = true;
    });

    try {
      await dispatch('chatChannels.share', {
        channel: this.channel,
        pts: this.selected,
        sender: this.sender,
      });

      dispatch(
        'ui.snackBar.open',
        `Shift shared with ${this.channel.channelName}`,
      );

      this.clear();
    } catch (err) {
      log.error('Failed to share shift', err);
      dispatch('ui.snackBar.open', 'Sorry, unable to share shift at this time');

      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  @action
  clear() {
    this.selected = {};
    this.channel = {};
    this.sender = {};
    this.isLoading = false;

    this.isOpen = false;
  }
}
