import type { IPaymentAccount } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { action, set } from 'mobx';

import { service } from '#/shared/app';

import BaseStore from './_baseStore';

export default class PaymentAccounts extends BaseStore<IPaymentAccount> {
  constructor() {
    const baseItem = {
      externalId: null,
      stripeAccountType: null,
      user: null,
    };
    super({
      baseItem,
      idPath: 'user',
      searchFields: ['displayName'],
      serviceName: 'payments/accounts',
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  initEvents() {
    super.initEvents();
    service(this.serviceName).on('patched', action(this.onUpdatedLocal));
  }

  findByCompany() {
    return Promise.reject(new Error('Not Implemented for Payment Accounts'));
  }

  @action
  onUpdatedLocal = (data) => {
    if (
      _.get(this.selected, 'user') === data.user &&
      data.updatedAt > this.selected.updatedAt
    ) {
      set(this.selected, data);
    }
  };
}
