import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { dispatch } from 'rfx-core';
import { Icon, Input } from 'semantic-ui-react';
import cx from 'classnames';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('InlineEditor');

const defaultOnEdit = () => dispatch('ui.poolDetails.editingTitle');
const getDefaultTitle = (selected) => {
  const hasSelection = !!selected.uuid || !_.isEmpty(selected.company);
  return (
    hasSelection && dispatch('pools.getDisplayProps', { item: selected }).title
  );
};

const InlineEditor = observer(
  ({
    selected,
    isEditing,
    onSave,
    onEdit = defaultOnEdit,
    fieldKey = 'label',
    title = getDefaultTitle(selected),
    placeholder,
    value,
    refFn,
    canEdit = true,
    maxlength,
    className = '',
    ...rest
  }) => {
    log.silly('Rendering Inline Editor');

    const isDeleted = _.get(selected, 'deleted');

    function onSaveAction(...args) {
      if (_.isFunction(onSave)) {
        onSave(...args);
      }
    }
    return (
      <div className="flex items-center">
        {canEdit && (_.isEmpty(selected[fieldKey]) || isEditing) ? (
          <Input
            action={
              _.isFunction(onSave) && {
                color: 'blue',
                content: selected[fieldKey] ? 'Save' : 'Next',
                onClick: (e) => onSaveAction(e, { selected }),
              }
            }
            className={className}
            defaultValue={title}
            input={{ maxlength }}
            onClick={(e) => e.stopPropagation()}
            placeholder={_.get(selected, fieldKey) || placeholder}
            ref={(elem) => {
              if (_.isFunction(refFn)) {
                refFn(_.get(elem, 'inputRef', {}));
              }
            }}
            size="small"
            value={value}
            {...rest}
          />
        ) : (
          <span className={cx(isDeleted ? 'red' : '', className)}>{title}</span>
        )}

        {canEdit &&
          selected[fieldKey] &&
          selected[fieldKey] === title &&
          !isDeleted && (
            <div className="pl2 pb1 o-60 hover-o-100">
              <Icon name={isEditing ? 'remove' : 'edit'} onClick={onEdit} />
            </div>
          )}
      </div>
    );
  },
);

InlineEditor.displayName = 'InlineEditor';

export default InlineEditor;
