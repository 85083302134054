import { action, computed, observable, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { service } from '#/shared/app';
import WorkerStore from '#/shared/stores/workers';

export default class PartnersStore extends WorkerStore {
  constructor({ title, ...rest } = {}) {
    super({
      opts: {
        clearSelectedOnUpdateMismatch: false,
        removeFromListOnUpdateMismatch: true,
      },
      serviceName: 'workers',
      title: _.compact(['partners', title]).join('.'),
      ...rest,
    });

    this.log.debug('Created new "PartnersStore"');

    return this;
  }

  @observable
  pools = [];

  @observable
  partnerPools = [];

  initEvents() {
    super.initEvents();

    service('pools').on('userAdded', action(this.onPoolAdded));
    service('pools').on('userRemoved', action(this.onPoolRemoved));
  }
  /* eslint-enable max-len */

  @action
  updateList(json, { loadLocations } = {}) {
    super.updateList(json);

    if (loadLocations) {
      this.log.warn(
        'The legacy workerLocations query has been removed. Please refactor your code to utilze the new `workerLocations` store',
      );
    }

    this.log.debug('Updated Partner List', {
      list: this.list,
      pagination: this.$pagination,
    });

    return this.list;
  }

  @action
  resetSearch({ preserve = [] } = {}) {
    this.pools.clear();

    return super.resetSearch({
      preserve: _.union(preserve, ['companies', 'companyStatus']),
    });
  }

  @action
  find(query = {}, inputOpts = {}) {
    const coreQuery = _.get(query, 'query', query);

    const opts = _.clone(inputOpts);
    opts.hooks = [
      ...(opts.hooks || []),
      () => {
        if (
          _.has(coreQuery, 'companies') &&
          !_.has(this.query.query, 'companyStatus')
        ) {
          this.query.query.companyStatus = {
            $elemMatch: {
              company: coreQuery.companies,
              status: { $in: this.employmentStatusFilter },
            },
          };
          delete this.query.query.companies;
        }
      },
      () => {
        if (
          !_.get(opts, 'noQuery', false) &&
          _.isEmpty(_.get(this.query, 'query.companies', [])) &&
          _.isEmpty(_.get(this.query, 'query.companyStatus.$elemMatch')) &&
          _.isEmpty(_.get(this.query, 'query["companyStatus.company"]')) &&
          _.isEmpty(_.get(this.query, 'query["companyStatus.path"]'))
        ) {
          this.log.error(
            'Partners query must include at least one company UUID or Path',
          );
          // return Promise.reject('Please specify your company ID');
        }
      },
    ];
    return super.find(query, opts);
  }

  // onRemoved = (id, params) => {};

  /* ACTIONS */

  @action
  findByCompany({ company, query, opts = {} }) {
    const companyUUID = _.get(company, 'uuid', company);

    const user = dispatch('auth.getUser');
    if (!companyUUID && !user.isAdmin && !opts.deselect) {
      this.log.error('Clearing company filter');
    }

    return super.findByCompany({ company, opts, query });
  }

  @action
  async sendInvites({ data }) {
    const company = dispatch('auth.getCompany').uuid;
    let response;
    const invite = { company, user: data };
    this.log.debug('Sending invite: ', data);
    try {
      response = await service('workerInvite').create(invite);
    } catch (err) {
      this.log.error(
        'Failed to invite partner: ',
        err,
        !global.IS_PRODUCTION && { extra: { data } },
      );
    }
    return response;
  }

  @action.bound
  async loadPartnerPools({ company, partner }) {
    const companyId = _.get(company, 'uuid', company);
    const poolIds = _(partner.pools)
      .filter(
        (pool) =>
          pool.company === companyId || _.includes(pool.companies, companyId),
      )
      .filter((pool) => !/auto/i.test(pool.poolType))
      .map('uuid')
      .value();

    try {
      const response = await dispatch('pools.runQuery', {
        $client: { populateUserAvatars: true },
        uuid: { $in: poolIds },
      });

      const pools = _.get(response, 'data', []);

      runInAction(() => {
        this.partnerPools.replace(pools);
      });
    } catch (err) {
      // Swallow Error and clear partnerPools in case of error
      this.log.error('Failed to load partner pools', err, {
        extra: { companyId, poolIds, userId: _.get(partner, 'uuid') },
      });
      runInAction(() => {
        this.partnerPools.clear();
      });
    }
    return this.partnerPools;
  }

  @action
  openDeleteModal({ workerId }) {
    dispatch('ui.deletePartnerModal.setup', { open: true, workerId });
  }

  /* EVENTS */
  /**
   * onPoolAdded
   *
   * Event fired when a new pool is added to a worker
   */
  onPoolAdded = async (data) => {
    const { pool, workerId } = data;
    const company = dispatch('auth.getCompany');

    const companyId = _.get(company, 'uuid', company);
    const poolId = _.get(pool, 'uuid', pool);

    if (workerId === this.selected.uuid) {
      const poolObject = await dispatch('pools.get', poolId, {
        query: {
          $client: { populateUserAvatars: true },
        },
        select: false,
      });

      if (
        companyId &&
        !(
          poolObject.company === companyId ||
          _.includes(poolObject.companies, companyId)
        )
      ) {
        return;
      }
      if (!_.find(this.selected.pools, { uuid: poolId })) {
        runInAction(() => {
          this.selected.pools.push(pool);
        });
      }

      if (!_.find(this.partnerPools, { uuid: poolId })) {
        runInAction(() => {
          this.partnerPools.push(poolObject);
        });
      }
    }
  };

  /**
   * onPoolRemoved
   *
   * Event fired when a worker is removed from a pool
   */
  onPoolRemoved = (data) => {
    const { pool, workerId } = data;

    const poolId = _.get(pool, 'uuid', pool);

    const worker = _.find(this.list, { uuid: workerId });

    if (worker) {
      const p = _.find(worker.pools, { uuid: poolId });

      if (p) {
        worker.pools = _.reject(worker.pools, { uuid: poolId });
      }
    }

    if (workerId === this.selected.uuid) {
      runInAction(() => {
        if (!_.find(this.selected.pools, { uuid: poolId })) {
          runInAction(() => {
            _.remove(this.selected.pools, { uuid: poolId });
          });
        }

        _.remove(this.partnerPools, { uuid: poolId });
      });
    }
  };

  @computed
  get sortOptions() {
    return [
      {
        dir: -1,
        key: 'rating',
        text: 'Sort by highest rating',
        value: 'rating',
      },
      {
        dir: -1,
        key: 'recent',
        text: 'Sort by last active',
        value: 'lastActiveAt',
      },
      {
        dir: 1,
        key: 'name',
        text: 'Sort by name (A-Z)',
        value: 'displayName',
      },
    ];
  }

  @computed
  get sortValue() {
    return _.first(_.keys(this.sort));
  }
}
