import { dispatch } from 'rfx-core';

import { mapUnifiedToSeparate } from '#/shared/forms/formUtils';

import Form from './_.extend';

export class NotificationV2Form extends Form {
  hooks = () => ({
    async onSuccess(form: NotificationV2Form): Promise<void> {
      try {
        await dispatch('notificationsV2.create', { data: form.values() });
        await Promise.all([
          dispatch('ui.notificationV2Modal.clear'),
          dispatch('ui.snackBar.open', 'Notification queued'),
        ]);
        form.clear();
      } catch (err) {
        form.invalidate(err.message);
        await dispatch('ui.snackBar.open', err.message);
      }
    },
  });
}

export const fields = {
  text: {
    label: 'Message Body',
    rules: 'required|string',
  },
  type: {
    rules: 'required|string',
  },
  user: {
    rules: 'required|string',
  },
};

export function init({ values = {} }) {
  return new NotificationV2Form({ ...mapUnifiedToSeparate(fields), values });
}
