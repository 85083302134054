import type { ISite } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';

import { getStore } from '#/shared/getStores';

import AddressStore from './address';
import { StoreConstructorProps } from './_baseStore';

export default class SitesStore<
  T extends ISite = ISite,
> extends AddressStore<T> {
  constructor({
    serviceName = 'sites',
    title = serviceName,
    baseItem,
    searchFields,
    ...rest
  }: Partial<StoreConstructorProps<T>> = {}) {
    super({
      ...rest,
      baseItem: { ...SitesStore.BASE_ITEM, ...((baseItem ?? {}) as T) },
      enableLocalResponseEventing: true,
      searchFields:
        searchFields === false
          ? false
          : _.union(searchFields, ['name', 'externalId', 'storeNumber']),
      serviceName,
      title: _.compact(_.uniq(['sites', title])).join('.'),
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  static BASE_ITEM: ISite = {
    abbr: '',
    externalId: '',
    isActive: null,
    isSubscriptionOnly: false,
    market: '',
    name: '',
    notes: '',
    path: '',
    phone: '',
    region: '',
    shiftLimit: null,
    shiftLimitRotationDays: null,
    source: '',
    storeNumber: '',
    subscriptions: [],
    ...AddressStore.BASE_ITEM,
  };

  find(query, options) {
    if (
      !options?.noQuery &&
      _.has(query, 'isActive') &&
      ['sites', 'zones'].includes(this.serviceName)
    ) {
      const other = this.serviceName === 'sites' ? 'zones' : 'sites';
      getStore(other).find({ isActive: query.isActive }, { noQuery: true });
    }

    return super.find(query, options);
  }
}
