import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { init as initForm } from '#/shared/forms/paymentAccount';

export default class PaymentAccountModal {
  @observable
  isOpen = false;

  @observable
  form = {};

  @action
  setup({
    selected = {},
    company = {},
    user = selected.user,
    open = this.isOpen,
  }) {
    this.company = company;

    if (_.isString(user)) {
      return dispatch('workers.get', user, { select: false })
        .then(
          action((worker) => {
            this.form = initForm({
              ...selected,
              company: this.company,
              user: worker,
            });
          }),
        )
        .then(() => this.open(true));
    }
    this.form = initForm({ company: this.company, user });
    this.isOpen = open;

    return Promise.resolve();
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    this.form.clear();

    this.isOpen = false;
  }
}
