import React, { createContext, PropsWithChildren } from 'react';
import { createContextualCan } from '@casl/react';
import _ from 'lodash';
import { observer } from 'mobx-react';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('Can');

export const AbilityContext = createContext(null);
const ContextualCan = createContextualCan(AbilityContext.Consumer);

export const Can = ({
  passThrough,
  observe = true,
  children,
  ...args
}: PropsWithChildren<{
  [argKey: string]: unknown;
  observe?: boolean;
  passThrough?: boolean;
}>) => (
  <ContextualCan passThrough={passThrough} {...args}>
    {(can) => {
      const doShow = passThrough ? !!can : true;

      log.silly(`Content is ${doShow ? '' : 'NOT '}visible`);

      if (observe && _.isFunction(children)) {
        if (passThrough) {
          const ret = children(doShow);
          const Elem = observer(() => ret);
          return <Elem />;
        }

        if (doShow) {
          const Elem = observer(children);
          return <Elem />;
        }

        return false;
      }

      return (
        doShow &&
        (_.isFunction(children)
          ? children(passThrough ? doShow : true)
          : children)
      );
    }}
  </ContextualCan>
);
