import React from 'react';
import { observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';
import cx from 'classnames';
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import styled from 'styled-components';

const StyledRatingContainer = styled.div.attrs(({ className }) => ({
  className: cx('h-100', className),
}))`
  display: grid;
`;

const StyledStarsContainer = styled.div.attrs(({ className }) => ({
  className: cx('nowrap', className),
}))`
  grid-row-start: 1;
  grid-column-start: 1;
`;

const BGStars = observer(
  ({
    props,
    className,
    empty,
  }: {
    className?: string;
    empty: unknown;
    props: unknown;
  }) => {
    const starClass = empty ? 'star outline' : 'star';
    const color = empty ? 'grey' : props.color;

    return (
      <StyledStarsContainer className={className}>
        <Icon name={starClass} {...props} color={color} disabled={true} />
        <Icon name={starClass} {...props} color={color} disabled={true} />
        <Icon name={starClass} {...props} color={color} disabled={true} />
        <Icon name={starClass} {...props} color={color} disabled={true} />
        <Icon name={starClass} {...props} color={color} disabled={true} />
      </StyledStarsContainer>
    );
  },
);

const Rating = observer(
  ({
    size = 'mini',
    disabled = false,
    rating,
    maxRating = 5,
    className,
  }: {
    className?: string;
    disabled: boolean;
    maxRating?: number;
    rating: number;
    size?: IconSizeProp;
  }) => {
    const percentage = (100 * rating) / maxRating;

    const getIconName = ({ min, max }) => {
      const median = (max - min) / 2;
      const fudge = median / 5;

      if (percentage < min + fudge) {
        return '';
      }
      if (percentage > max - fudge) {
        return 'star';
      }

      return 'star half';
    };

    const iconProps = { className: 'w-20 dib', color: 'blue', disabled, size };

    return (
      <StyledRatingContainer className={className}>
        <BGStars empty={!rating} props={iconProps} />
        <StyledStarsContainer>
          <Icon name={getIconName({ max: 20, min: 0 })} {...iconProps} />
          <Icon name={getIconName({ max: 40, min: 20 })} {...iconProps} />
          <Icon name={getIconName({ max: 60, min: 40 })} {...iconProps} />
          <Icon name={getIconName({ max: 80, min: 60 })} {...iconProps} />
          <Icon name={getIconName({ max: 100, min: 80 })} {...iconProps} />
        </StyledStarsContainer>
      </StyledRatingContainer>
    );
  },
);

Rating.displayName = 'Rating';

export default Rating;
