import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Dropdown, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledDropdown = styled(Dropdown)`
  &.ui.upward.dropdown > .menu {
    bottom: unset;
    top: 40px;
  }
  &&& .visible.menu {
    min-width: 240px !important;
  }
  &.ui.selection.active.dropdown {
    border: 1px solid #d4d4d5 !important;
    box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%),
      0 2px 10px 0 rgb(34 36 38 / 15%);

    .menu {
      margin-top: 8px;
      border: 1px solid #d4d4d5 !important;
      border-radius: 0.28rem;
      font-weight: 400;
      font-style: normal;
      box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%),
        0 2px 10px 0 rgb(34 36 38 / 15%);
      padding: 0 0 0 0;
      width: max-content;
    }
  }
`;

const ItemSelection = ({
  canRemove,
  text,
  onRemove,
}: {
  canRemove: boolean;
  onRemove: (text) => void;
  text: string;
}) => (
  <div className="flex items-center">
    {canRemove && (
      <Icon color="grey" name="remove" onClick={onRemove} size="small" />
    )}
    <div className="blue ml2">{text}</div>
  </div>
);

const MENU_OPTION_HEIGHT = 38;

const GenericMultiselectDropdownFilter = observer(
  ({
    options,
    onSelect,
    onDeselect,
    canUpdate = true,
    selected = [],
    placeholder = 'Select',
    selectedItemsPlaceholder = 'Please select an option to get started',
  }: {
    canUpdate?: boolean;
    onDeselect: (item) => void;
    onSelect: (item) => void;
    options: Array<{ key: string; text: string; value: string }>;
    placeholder?: string;
    selected: string[];
    selectedItemsPlaceholder?: string;
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [paddingBottomValue, setPaddingBottomValue] = useState<number>(0);

    const selectedOptions = _.filter(
      options,
      (option) => !_.isEmpty(_.find(selected, (u) => u === option.value)),
    );

    const optionsLeft = _.filter(options, (option) =>
      _.isEmpty(_.find(selected, (u) => u === option.value)),
    );

    useEffect(() => {
      if (optionsLeft) {
        const paddingValue = _.min([
          6 * MENU_OPTION_HEIGHT + 10, // max height (10 is the margin top the dropdown menu already has)
          MENU_OPTION_HEIGHT * (optionsLeft.length || 1) + 10, // height based on remaining options
        ]);
        setPaddingBottomValue(paddingValue);
      }
    }, [optionsLeft]);

    return (
      <div>
        {canUpdate && (
          <div
            style={{
              paddingBottom: isExpanded ? `${paddingBottomValue}px` : '0px',
            }}
          >
            <StyledDropdown
              compact={true}
              fluid={true}
              onChange={(e, { value }) => onSelect(value)}
              onClose={() => setIsExpanded(false)}
              onOpen={() => setIsExpanded(true)}
              options={optionsLeft}
              placeholder={placeholder}
              search={true}
              selection={true}
              value=""
            />
          </div>
        )}
        <div className="mb2 mt3">
          {_.isEmpty(selectedOptions) ? (
            <div className="w-100 silver i tc">{selectedItemsPlaceholder}</div>
          ) : (
            _(selectedOptions)
              .map((option) => (
                <ItemSelection
                  canRemove={canUpdate}
                  key={option.key}
                  onRemove={() => onDeselect(option.value)}
                  text={option.text}
                />
              ))
              .value()
          )}
        </div>
      </div>
    );
  },
);

export default GenericMultiselectDropdownFilter;
