import type { IShift, IAssignmentQuery } from '@shiftsmartinc/shiftsmart-types';

import { action, computed, observable, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.ShiftDetails');

const statuses = {
  applied: ['PendingApproval'],
  // active: ['EnRoute', 'Active', 'InProgress', 'Completed', 'NoShow', 'Other'],
  assigned: ['Completed', 'Assigned', 'Approved', 'Accepted', 'NoShow'],
  canceled: ['Canceled'],
  declined: ['Declined'],
  pending: ['Pending'],
  seen: ['Sent'],
  sent: ['Sent'],
  unavailable: ['Declined', 'Canceled'],
};
statuses.specified = _(statuses).reduce((acc, val) => _.unionBy(acc, val), []);

export default class ShiftDetails {
  @observable
  partnersActiveTab = 'assigned';

  @observable
  activeTab = 'summary';

  @observable
  shiftId = '';

  @observable
  shift: IShift;

  @observable
  aggregateData: Record<'assigned' | 'applied' | 'sent' | string, number> = {};

  @observable
  isLoading = false;

  @observable
  isLoadingActivity = false;

  @observable
  modules = {};

  /** @deprecated Use the joined `companyObj` on the shift instead (where available) */
  @observable
  shiftCompany = {};

  @observable
  shiftSchedule = {};

  @observable
  isAdmin = false;

  @observable
  confirmStatusChangeModal = false;

  @observable
  listenerId = null;

  @observable
  payoutTotal = 0;

  @observable
  totalHours = 0;

  @observable
  confirmedHours = 0;

  @observable
  confirmedAssignments = 0;

  @action
  setConfirmStatusChangeModal(transition = false) {
    this.confirmStatusChangeModal = transition;
  }

  @observable
  baseShift = null;

  @action
  setBaseShift(shift) {
    this.baseShift = shift;
  }

  @observable
  redundantShifts = [];

  @observable showInShiftTasks = false;

  @action
  setRedundantShifts(shifts) {
    this.redundantShifts = shifts;
  }

  @action
  setShowInShiftTasks(value: boolean) {
    this.showInShiftTasks = value;
  }

  @action.bound
  setup({
    shift,
    modules = this.modules,
    isAdmin = this.isAdmin,
    shiftCompany = this.shiftCompany,
  }) {
    dispatch('notifications.emptyList'); // Fixes old shift activity flashing before loadActivity
    this.isAdmin = isAdmin;
    this.shiftCompany = shiftCompany;
    if (_.get(shift, 'uuid')) {
      this.shiftId = shift.uuid;
      this.shift = shift;
      this.loadAggregateData(shift);
      this.loadTotalHours(shift);
      this.loadConfirmedHours(shift);
      this.loadAssignments({ statusKey: this.partnersActiveTab });
      this.loadBaseShift();
      this.loadRedundantShifts();
      this.loadInShiftTasks();
      this.setShowInShiftTasks(_.get(shiftCompany, 'modules.inShiftTasks'));
      const shiftTemplateId = _.get(shift, 'shiftTemplateId');
      if (shiftTemplateId) {
        this.getShiftSchedule(shiftTemplateId);
      }
      if (global.TYPE === 'CLIENT') {
        this.initEvents();
      }
    }
    this.modules = modules || {};

    const notificationPrefs = _.get(shift, 'notificationPrefs', []);
    return dispatch('ui.shiftInvitations.setup', {
      notificationPrefs,
      shiftId: shift.uuid,
    });
  }

  @action
  async loadTotalHours(shift) {
    this.loading(true);
    const $match = {
      ref: shift.uuid,
      status: { $in: ['Assigned', 'Accepted', 'Approved', 'Completed'] }, // ATTN: Assignment Status EP-5523
    };

    const $group = {
      _id: null,
      total: {
        $sum: '$duration',
      },
    };

    const pipeline = [{ $match }, { $group }];

    const stats = await dispatch('assignments.runAggregate', { pipeline });
    const result = _.first(stats);
    this.set('totalHours', _.get(result, 'total', 0));
  }

  @action
  async loadConfirmedHours(shift) {
    this.loading(true);
    const $match = {
      // ATTN: Assignment Status EP-5523
      partnerConfirmedAt: { $exists: true },

      ref: shift.uuid,
      status: { $in: ['Assigned', 'Accepted', 'Approved', 'Completed'] },
    };

    const $group = {
      _id: null,
      count: { $sum: 1 },
      total: {
        $sum: '$duration',
      },
    };

    const pipeline = [{ $match }, { $group }];

    const stats = await dispatch('assignments.runAggregate', { pipeline });
    const result = _.first(stats);
    this.set('confirmedHours', _.get(result, 'total', 0));
    this.set('confirmedAssignments', _.get(result, 'count', 0));
  }

  @action.bound
  async loadAggregateData(shift) {
    this.loading(true);
    const $match = {
      ref: shift.uuid,
      status: { $in: statuses.specified }, // ATTN: Assignment Status EP-5523
    };
    const $group = {
      _id: '$status',
      count: { $sum: 1 },
      data: {
        $push: {
          approvals: '$approvals',
          canceledAt: '$canceledAt',
          canceledBy: '$canceledBy',
          checkIn: '$checkIn',
          company: '$company',
          declinedAt: '$declinedAt',
          partnerConfirmedAt: '$partnerConfirmedAt',
          seen: '$seenAt',
          status: '$status',
          timecard: '$timecard',
          user: '$user',
          uuid: '$uuid',
        },
      },
    };
    const pipeline = [{ $match }, { $group }];

    const stats = await dispatch('assignments.runAggregate', { pipeline });
    const data = _.reduce(
      stats,
      (acc, curr) => {
        const extraUsers = [];

        const extraUsersCount = extraUsers.length;
        if (/^(completed|assigned|approved|accepted|noshow)$/i.test(curr.id)) {
          _.set(acc, 'assigned', acc.assigned + curr.count);
          let confirmedCount = 0;
          let notConfirmedCount = 0;
          let checkedInCount = 0;
          let confirmedNoCheckinCount = 0;
          let noBreakTakenCount = 0;
          curr.data.forEach((a) => {
            if (_.get(a, 'partnerConfirmedAt', false)) {
              confirmedCount += 1;
              if (_.get(a, 'checkIn', false)) {
                checkedInCount += 1;
                if (_.get(a, 'timecard', []).length === 0) {
                  noBreakTakenCount += 1;
                }
              } else {
                confirmedNoCheckinCount += 1;
              }
            } else {
              notConfirmedCount += 1;
              if (_.get(a, 'checkIn', false)) {
                checkedInCount += 1;
                if (_.get(a, 'timecard', []).length === 0) {
                  noBreakTakenCount += 1;
                }
              }
            }

            return true;
          });
          _.set(acc, 'confirmed', acc.confirmed + confirmedCount);
          _.set(acc, 'notConfirmed', acc.notConfirmed + notConfirmedCount);
          _.set(acc, 'checkedIn', acc.checkedIn + checkedInCount);
          _.set(
            acc,
            'confirmedNoCheckin',
            acc.confirmedNoCheckin + confirmedNoCheckinCount,
          );
          _.set(acc, 'noBreakTaken', acc.noBreakTaken + noBreakTakenCount);
        } else if (/^pendingapproval$/i.test(curr.id)) {
          _.set(acc, 'applied', acc.applied + curr.count - extraUsersCount);
        } else if (/^sent$/i.test(curr.id)) {
          const seen = curr.data.filter((a) => !!a.seen);
          const sent = curr.data;
          _.set(acc, 'sent', sent.length - extraUsersCount);
          _.set(acc, 'seen', seen.length);
        } else if (/^canceled$/i.test(curr.id)) {
          // TODO maybe separate canceled by partner and unassigned (canceled by manager)
          const canceled = curr.data;
          _.set(acc, 'canceled', canceled.length);
          _.set(acc, 'unavailable', acc.unavailable + canceled.length);
        } else if (/^declined$/i.test(curr.id)) {
          const declined = curr.data.filter(
            (a) => /^declined$/i.test(a.status) && a.declinedAt,
          );
          _.set(acc, 'declined', declined.length);
          _.set(acc, 'unavailable', acc.unavailable + declined.length);
        } else {
          _.set(
            acc,
            _.toLower(curr.id),
            _.get(acc, curr.id, 0) + curr.count - extraUsersCount,
          );
        }

        return acc;
      },
      {
        applied: 0,
        assigned: 0,
        checkedIn: 0,
        confirmed: 0,
        confirmedNoCheckin: 0,
        conflict: 0,
        declined: 0,
        noBreakTaken: 0,
        notConfirmed: 0,
        seen: 0,
        sent: 0,
        unavailable: 0,
      },
    );
    this.set('aggregateData', data);
    this.loading(false);
  }

  @action.bound
  async loadAssignments({ statusKey, preservePagination = false }) {
    this.loading(true);
    const statusKeys = _.get(statuses, statusKey, []);
    const $sort = {};

    if (!_.isEmpty(this.inlineSort)) {
      $sort[this.inlineSort.key] = this.inlineSort.direction;
    }

    let $skip = 0;
    if (preservePagination) {
      const assignmentPagination = dispatch(
        'assignments.retrieve',
        'pagination',
      );
      $skip = _.get(assignmentPagination, 'skip', 0);
    }

    let query: IAssignmentQuery = {
      $client: { getAssignmentPartners: true },

      $limit: 10,

      $skip,

      $sort,

      company: this.shift?.company,
      // ATTN: Assignment Status EP-5523
      partnerSearchValue: this.partnerSearchValue,
      ref: this.shiftId,
      status: { $in: statusKeys },
    };

    // Add query for secondary filter (PillToggle filter)
    if (!_.isEmpty(this.secondaryFilterQuery)) {
      _.extend(query, this.secondaryFilterQuery);
    }

    // Add query for tertiary filter (Dropdown filter)
    if (!_.isEmpty(this.tertiaryFilterQuery)) {
      _.extend(query, this.tertiaryFilterQuery);
    }

    if (!_.isEmpty(this.breakCountFilterQuery)) {
      _.extend(query, this.breakCountFilterQuery);
    }

    /** @deprecated 2022-10-24 */
    // payments method does not render anymore
    // different query for payments tab
    if (this.activeTab === 'payments') {
      query = {
        $client: {
          populatePaymentsTransactions: true,
          populateUser: ['companyStatus'],
        },
        $sort: { start: -1 },
        'checkIn.timestamp': { $exists: true },
        'checkOut.timestamp': { $exists: true },
        ref: this.shiftId,
        status: 'Completed',
      };
    }

    try {
      await dispatch('assignments.find', query, {
        clear: true,
        preserve: ['company'],
      });
    } catch (error) {
      log.error('Error Loading Assignments in Shift Details: ', error);
    }
    this.loading(false);
  }

  @action.bound
  async loadAssignmentStats(shift) {
    if (_.get(shift, 'uuid', ''))
      dispatch('shifts.get', shift.uuid, {
        query: { $client: { populateAssignmentStats: true } },
      });
  }

  @action.bound
  async loadBaseShift() {
    const baseShiftId = this.shift?.originalBaseShiftId;
    const query = {
      $select: ['uuid', 'createdAt', 'status', 'slots', 'assignments'],
      uuid: baseShiftId,
    };
    if (baseShiftId) {
      try {
        const { data: result } = await dispatch('shifts.runQuery', query, {
          clear: true,
        });
        this.setBaseShift(result);
      } catch (error) {
        log.error('Error Loading Base Shift: ', error);
      }
    }
  }

  @action.bound
  async loadRedundantShifts() {
    const query = {
      $select: [
        'uuid',
        'createdAt',
        'status',
        'slots',
        'assignments',
        'originalBaseShiftId',
        'redundancyCreationMethod',
        'redundancyType',
      ],
      originalBaseShiftId: this.shiftId,
    };
    try {
      const { data: results } = await dispatch('shifts.runQuery', query, {
        clear: true,
      });
      this.setRedundantShifts(results);
    } catch (error) {
      log.error('Error Loading Redundant Shifts: ', error);
    }
  }

  @action.bound
  async loadInShiftTasks() {
    const query = {
      $client: {
        attachResults: true,
        populateUser: true,
      },
      $limit: 50,
      companyId: this.shift.company,
      isDeleted: { $ne: true },
      shiftId: this.shiftId,
    };
    try {
      await dispatch('inShiftTasks.find', query, {
        clear: true,
      });
      await dispatch(
        'ui.inShiftTasks.loadInShiftTaskTemplates',
        this.shift.company,
      );
    } catch (error) {
      log.error('Error Loading Redundant Shifts: ', error);
    }
  }

  // #region Partner Searching (CP from ShiftsNextGen.js)
  @observable
  partnerSearchValue = '';

  @action.bound
  async setPartnerSearch(value) {
    log.debug('setPartnerSearch', value);
    this.partnerSearchValue = value;
    this.debouncedLoadAssignments({ statusKey: this.partnersActiveTab });
  }

  debouncedLoadAssignments = _.debounce(this.loadAssignments, 500);
  // #endregion Partner Searching

  @action
  loading(arg = !this.isLoading) {
    this.isLoading = !!arg;
  }

  @action
  loadingActivity(arg = !this.isLoadingActivity) {
    this.isLoadingActivity = !!arg;
  }

  initEvents() {
    if (this.listenerId) {
      return;
    }

    const listenerId = dispatch('assignments.registerEventListener', {
      onPatched: this.updateAssignment.bind(this),
      onUpdated: this.updateAssignment.bind(this),
    });

    this.setListenerId(listenerId);
  }

  @action
  updateAssignment = (item) => {
    if (_.isEmpty(item)) {
      return false;
    }
    if (item.ref !== this.shiftId) return false;
    // debounced to prevent tons of requests after many updates
    this.debouncedLoadAggregateData(this.shift);
    this.debouncedLoadAssignments({
      preservePagination: true,
      statusKey: this.partnersActiveTab,
    });
    // this.debouncedLoadAssignmentStats(this.shift);
    this.debouncedLoadTotalHours(this.shift);
    this.debouncedLoadConfirmedHours(this.shift);
    return true;
  };

  debouncedLoadAggregateData = _.debounce(this.loadAggregateData, 500);

  debouncedLoadTotalHours = _.debounce(this.loadTotalHours, 500);

  debouncedLoadConfirmedHours = _.debounce(this.loadConfirmedHours, 500);

  debouncedLoadAssignmentStats = _.debounce(this.loadAssignmentStats, 500);

  @action
  setListenerId(id) {
    this.listenerId = id;
  }

  @action
  loadRouteData = () => {
    const taskQuery = {
      path: { $regex: `^${this.shiftId}[/].+$` },
      populateCustomer: true,
    };
    const company = dispatch('auth.getCompany');

    dispatch(
      'surveyDefinitions.find',
      { company: company.uuid },
      { clear: true },
    );
  };

  @action
  loadMoreActivity = async (notifications) => {
    const pagination = notifications.pagination;
    const { current } = pagination;
    this.loadingActivity(true);
    try {
      await notifications.page(current + 1, { append: true });
    } catch (err) {
      log.error(
        'Failed to load more notifications for latest activity feed',
        err,
      );
    }
    this.loadingActivity(false);
  };

  @action
  async getShiftSchedule(templateId) {
    const shiftTemplate = await dispatch('shiftTemplates.get', templateId);
    const recurringScheduleId = _.get(shiftTemplate, 'recurringScheduleId');
    if (recurringScheduleId) {
      const shiftSchedule = await dispatch(
        'recurringSchedules.get',
        recurringScheduleId,
      );
      runInAction(() => {
        this.shiftSchedule = shiftSchedule;
      });
    }
  }

  @action
  clear() {
    this.activeTab = 'summary';
    this.partnersActiveTab = 'assigned';
    this.shiftId = '';
    this.isLoading = false;
    this.confirmStatusChangeModal = false;
    this.modules = {};
    this.shiftCompany = {};
    this.shift = null;
    this.aggregateData = {};
    this.totalHours = 0;
    this.confirmedHours = 0;
    this.confirmedAssignments = 0;
    this.baseShift = null;
    this.redundantShifts = [];

    dispatch('ui.shiftInvitations.clear');

    if (this.listenerId) {
      log.warn('Clearing Listener ID', this.listenerId);
      dispatch('assignments.deregisterEventListener', this.listenerId);
      this.listenerId = null;
    }
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    _.set(this, key, value);
  }

  @action
  retrieve(key) {
    return _.get(this, key);
  }

  @action
  setActiveTab(tab) {
    this.activeTab = tab;
  }

  /* Tabs ________________________________ */

  @computed
  get menuTabs() {
    const company = dispatch('auth.getCompany');
    const showAuditFeature = _.get(
      company,
      'settings.featureFlags.auditLogVisibility',
      false,
    );
    const hasBaseShift = this.shift?.originalBaseShiftId;
    const hasRedundantShifts =
      !!this.redundantShifts.length && !this.shift?.originalBaseShiftId;
    const hasInShiftTasks = this.showInShiftTasks;
    return _.compact([
      {
        index: 0,
        key: 'summary',
        title: 'Summary',
      },
      dispatch('abilities.can', 'read', 'shift', 'notificationPrefs') && {
        index: 1,
        key: 'invitations',
        title: 'Invitations',
      },
      {
        index: 2,
        key: 'partners',
        onSelect: () =>
          this.loadAssignments({ statusKey: this.partnersActiveTab }),
        title: 'Partners',
      },
      showAuditFeature && {
        index: 5,
        key: 'audit',
        title: 'Audit',
      },
      hasBaseShift && {
        index: 6,
        key: 'baseShifts',
        onSelect: this.loadBaseShift,
        title: 'Base Shift',
      },
      hasRedundantShifts && {
        index: 6,
        key: 'redundantShifts',
        title: 'Redundant Shifts',
      },
      hasInShiftTasks && {
        index: 7,
        key: 'inShiftTasks',
        title: 'Tasks',
      },
    ]);
  }

  @computed
  get partnersTabTabs() {
    return _.compact([
      {
        icon: {
          height: 12,
          uri: '/static/img/shifts/icon-approved.png',
          width: 23,
        },
        index: 0,
        key: 'assigned',
      },
      {
        icon: {
          height: 20,
          uri: '/static/img/shifts/icon-approvals-gray.png',
          width: 21,
        },
        index: 1,
        key: 'applied',
      },
      {
        icon: {
          height: 13,
          uri: '/static/img/shifts/icon-peoples.png',
          width: 17,
        },
        index: 2,
        key: 'sent',
        popupContent:
          this.shift?.inviteRadius > 0
            ? `Only partners within ${this.shift?.inviteRadius} miles were invited. The invited pools may have more partners outside of that radius`
            : '',
      },
      {
        icon: {
          height: 15,
          uri: '/static/img/shifts/icon-cancel.png',
          width: 15,
        },
        index: 3,
        key: 'unavailable',
      },
      {
        icon: {
          height: 15,
          uri: '/static/img/shifts/icon-warning.png',
          width: 16,
        },
        index: 4,
        key: 'conflicts',
      },
      {
        icon: {
          height: 15,
          uri: '/static/img/shifts/icon-clock.png',
          width: 16,
        },
        index: 5,
        key: 'timeOff',
      },
      /hybrid|invites/.test(
        this.shift?.options?.inviteMode ??
          dispatch('auth.getCompany')?.settings?.dispatch?.defaultInviteMode,
      ) && {
        beta: true,
        icon: { name: 'envelope outline' },
        index: 5,
        key: 'invites',
      },
    ]);
  }

  @observable
  secondaryFilter = '';

  @computed
  get secondaryFilterQuery() {
    return _.get(
      _.find(this.secondaryFilterOptions, {
        key: this.secondaryFilter,
      }),
      'extendQuery',
      {},
    );
  }

  @computed
  get secondaryFilterOptions() {
    switch (this.partnersActiveTab) {
      case 'sent':
        return [
          {
            badge: this.aggregateData.seen,
            extendQuery: {
              seenAt: { $exists: true },
            },
            key: 'seen',
            text: 'Seen',
            value: 'seen',
          },
          {
            badge: this.aggregateData.sent - this.aggregateData.seen,
            extendQuery: {
              seenAt: { $exists: false },
            },
            key: 'notseen',
            text: 'Not Seen',
            value: 'notseen',
          },
        ];
      case 'assigned':
        return [
          {
            badge: this.aggregateData.confirmed,
            extendQuery: {
              partnerConfirmedAt: { $exists: true },
            },
            key: 'confirmed',
            text: 'Confirmed',
            value: 'confirmed',
          },
          {
            badge: this.aggregateData.notConfirmed,
            extendQuery: {
              partnerConfirmedAt: { $exists: false },
            },
            key: 'notconfirmed',
            text: 'Not Confirmed',
            value: 'notconfirmed',
          },
        ];
      case 'unavailable':
        return [
          {
            badge: this.aggregateData.canceled,
            extendQuery: {
              status: 'Canceled', // ATTN: Assignment Status EP-5523
            },
            key: 'canceled',
            text: 'Canceled',
            value: 'canceled',
          },
          {
            // ATTN: Assignment Status EP-5523
            badge: this.aggregateData.declined,

            extendQuery: {
              declinedAt: { $exists: true },
              status: 'Declined',
            },

            key: 'declined',
            text: 'Declined',
            value: 'declined',
          },
        ];
      default:
        return [];
    }
  }

  @action
  setSecondaryFilter = (value) => {
    this.secondaryFilter = value;
    this.loadAssignments({ statusKey: this.partnersActiveTab });
  };

  @observable
  tertiaryFilter = '';

  @computed
  get tertiaryFilterQuery() {
    return _.get(
      _.find(this.tertiaryFilterOptions, {
        key: this.tertiaryFilter,
      }),
      'extendQuery',
      {},
    );
  }

  @computed
  get tertiaryFilterOptions() {
    const company = dispatch('auth.getCompany');
    const breakOptionsEnabled = _.get(
      company,
      'settings.timekeeping.breakOptions',
    );
    const breakRequirements = _.get(
      company,
      'settings.timekeeping.breakRequirements',
    );
    return _.compact([
      {
        extendQuery: {
          'checkIn.timestamp': { $exists: true },
        },
        key: 'checkedin',
        text: 'Checked-In',
        value: 'checkedin',
      },
      breakOptionsEnabled && {
        extendQuery: {
          'timecard.in': { $exists: true },
          'timecard.out': { $exists: false },
        },
        key: 'onBreak',
        text: 'On Break',
        value: 'onBreak',
      },
      breakRequirements && {
        extendQuery: breakRequirements.query,
        key: 'needBreak',
        text: 'Needs Breaks',
        value: 'needBreak',
      },
      {
        extendQuery: {
          'checkOut.timestamp': { $exists: true },
        },
        key: 'checkedout',
        text: 'Checked Out',
        value: 'checkedout',
      },
      {
        extendQuery: {
          'checkIn.timestamp': { $exists: false },
        },
        key: 'notcheckedin',
        text: 'Not Checked-In',
        value: 'notcheckedin',
      },
    ]);
  }

  @action
  setTertiaryFilter = (value) => {
    this.tertiaryFilter = value;
    this.loadAssignments({ statusKey: this.partnersActiveTab });
  };

  @observable
  breakCountFilter = '';

  @computed
  get breakCountFilterQuery() {
    return _.get(
      _.find(this.breakCountFilterOptions, {
        key: this.breakCountFilter,
      }),
      'extendQuery',
      {},
    );
  }

  @computed
  get breakCountFilterOptions() {
    return [
      {
        extendQuery: {
          timecard: { $size: 0 },
        },
        key: '0',
        text: '0',
        value: '0',
      },
      {
        extendQuery: {
          timecard: { $size: 1 },
        },
        key: '1',
        text: '1',
        value: '1',
      },
      {
        extendQuery: {
          timecard: { $size: 2 },
        },
        key: '2',
        text: '2',
        value: '2',
      },
      {
        extendQuery: {
          'timecard.2': { $exists: true },
        },
        key: '3',
        text: '3+',
        value: '3',
      },
    ];
  }

  @action
  setBreakCountFilter = (value) => {
    this.breakCountFilter = value;
    this.loadAssignments({ statusKey: this.partnersActiveTab });
  };

  @observable
  sortValue = 'recent';

  @computed
  get sortOptions() {
    return [
      {
        dir: -1,
        key: 'rating',
        sortKey: 'user.rating',
        text: 'Sort by highest rating',
        value: 'rating',
      },
      {
        dir: 1,
        key: 'name',
        sortKey: 'user.displayName',
        text: 'Sort by name',
        value: 'name',
      },
    ];
  }

  @action
  setSort = (value) => {
    this.sortValue = value;
    this.loadAssignments({ statusKey: this.partnersActiveTab });
  };

  @observable
  inlineSort: { direction?: 1 | -1; key?: string } = {};

  @action
  setInlineSort = ({ key, direction = 1 }: typeof this.inlineSort) => {
    this.inlineSort = { direction, key };
    this.loadAssignments({ statusKey: this.partnersActiveTab });
  };
}
