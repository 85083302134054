import type { IChannel } from '@shiftsmartinc/shiftsmart-types';

import { action, observable } from 'mobx';
import _ from 'lodash';

export default class AssignChatModal {
  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  selectedChannels: Array<IChannel> = [];

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    _.set(this, key, value);
  }

  @action
  async setup({
    open = !this.isOpen,
    channels,
  }: {
    channels: Array<IChannel>;
    open: boolean;
  }) {
    this.set('isLoading', true);
    this.set('isOpen', open);
    this.set('selectedChannels', channels);
    this.set('isLoading', false);
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  close() {
    this.clear();
  }

  @action
  clear() {
    this.isOpen = false;
    this.isLoading = false;
    this.selectedChannels = [];
  }
}
