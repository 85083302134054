import { action, observable } from 'mobx';
import moment from 'moment';
import _ from 'lodash';

import { initThingForm } from '#/shared/forms/thing';
/**
 * Store for UI Modal where user can Add a Template to their Company
 *
 * @export
 * @class ThingModal
 */

export default class ThingModal {
  @observable
  isOpen = false;

  @observable
  isTimeSelectOpen = false;

  @observable
  company = {};

  @observable
  thing = {};

  @observable
  customer = {};

  @observable
  template = {};

  @observable
  form = {};

  @observable
  requirements = {};

  @observable
  userFacing = false;

  @observable
  dateRangeStart = moment();

  @observable
  dateTime = moment();

  @action
  setup({
    template = {},
    thing = {},
    company = {},
    customer = {},
    userFacing = false,
    open = this.isOpen,
  }) {
    this.isOpen = open; // tmp

    this.template = template;
    this.company = company;
    this.customer = customer;
    this.thing = thing;
    this.requirements = thing.requirements;
    this.userFacing = userFacing;

    const start = moment();
    start.minute(_.round(start.minute() / 15.0) * 15.0);

    if (this.thing && this.thing.uuid) {
      this.form = initThingForm(thing, { src: 'thing' });
    } else if (this.template && this.template.uuid) {
      this.template.company = this.company;
      this.form = initThingForm(this.template, { src: 'template' });
    } else {
      this.form = initThingForm(
        {
          company: this.company,
          end: start.add(4, 'hours').toDate(),
          start: start.toDate(),
        },
        {
          src: 'company',
        },
      );
    }

    this.dateTime = moment(this.form.values().start).set({ seconds: 0 });

    this.isOpen = open;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  openTimeSelect(arg = !this.openTimeSelect) {
    this.openTimeSelect = !!arg;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    this[key] = value;
    return Promise.resolve(this[key]);
  }

  @action
  clear() {
    this.template = {};
    this.thing = {};
    this.customer = {};
    this.company = {};
    this.requirements = {};
    this.userFacing = false;

    this.form.clear();

    this.isOpen = false;
  }
}
