import { action, observable } from 'mobx';

import { init, NotificationV2Form } from '#/shared/forms/notificationV2';

export default class NotificationDetailsModal {
  @observable
  isOpen = false;

  @observable
  form: NotificationV2Form = {} as unknown as NotificationV2Form;

  @action
  setup({ user }) {
    const values = { user };
    this.form = init({ values });
    this.isOpen = true;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    this.isOpen = false;

    if (typeof this.form?.clear === 'function') {
      this.form.clear();
    }
  }
}
