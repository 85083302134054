import type { ICompany } from '@shiftsmartinc/shiftsmart-types';

import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import cx from 'classnames';

import { SimpleAvatar } from '#/shared/components/chat/ChannelAvatar';
import UserInitials from '#/shared/components/ssm/users/UserInitials';

export const CompanyImage = observer(
  ({
    company,
    width,
    height,
    ...rest
  }: {
    company: Pick<ICompany, 'images' | 'name'>;
    /** @deprecated */
    height: number;
    /** @deprecated */
    width: number;
  } & Parameters<typeof SimpleAvatar>) => {
    if (_.isEmpty(company)) {
      return null;
    }

    const imageURL =
      company.images?.avatar || company.images?.logo || company.images?.square;
    const style = width || height ? { height, width } : undefined;

    return (
      <div
        className={cx(
          !(height || width) && 'h2 w2',
          rest.className,
          !imageURL && /mh\d/.test(rest.className) && 'tw-translate-x-3',
        )}
        style={style}
      >
        {imageURL ? (
          <div className="h2 w2 ">
            <SimpleAvatar imageURL={imageURL} {...rest} />
          </div>
        ) : (
          <UserInitials
            circular={true}
            maxInitials={4}
            style={style}
            user={{ displayName: company.name || 'C O' }}
          />
        )}
      </div>
    );
  },
);
