import React from 'react';
import { observer } from 'mobx-react';
import {
  Popup,
  Button,
  StrictButtonProps,
  StrictPopupProps,
} from 'semantic-ui-react';
import _ from 'lodash';
import cx from 'classnames';

/**
 * # Popup Button
 * Renders a button that displays a tooltip on hover
 *
 * arg0: {
 *  tooltip {string|element} The content to display inside the tooltip
 *  position {string} @see SemanticUI.Popup.position
 *  inverted {boolean=true} By default, the tooltip will be on a dark bg
 *  delay: {boolean|{ open: number, close: number }} set to true to enable
 *          a delayed open and close, or specify open and close specific values
 *          by passing an object.
 *
 * All other props will be passed unchanged to a Semantic UI Button
 * }
 */
export type PopupButtonProps = Partial<
  Pick<StrictPopupProps, 'position' | 'inverted' | 'disabled' | 'style'>
> &
  StrictButtonProps & {
    /** Renders the button with no background and no border; just the content or icon. Like {@see StrictButtonProps['basic']} but without a border */
    bare?: boolean;
    delay?: { close?: number; open?: number };
    tooltip: string;
  };
const PopupButton: React.FC<PopupButtonProps> = observer(
  ({
    tooltip,
    position,
    inverted = true,
    delay,
    bare,
    className,
    color,
    ...rest
  }) => {
    const trigger = (
      <Button
        basic={bare}
        className={cx(className, bare && 'bare')}
        color={color || undefined}
        {...rest}
      />
    );

    return !_.isEmpty(tooltip) ? (
      <Popup
        content={tooltip}
        inverted={inverted}
        mouseEnterDelay={delay ? delay?.open ?? 500 : 100}
        mouseLeaveDelay={delay ? delay?.close ?? 250 : 50}
        position={position}
        trigger={trigger}
      />
    ) : (
      trigger
    );
  },
);

PopupButton.displayName = 'PopupButton';

export default PopupButton;
