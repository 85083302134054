import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';

const log = getChildLogger('forms.task');

export class TaskForm extends Form {
  hooks = () => ({
    onSuccess(form) {
      const storeAction = form.values().uuid
        ? 'things.update'
        : 'things.create';

      dispatch(storeAction, { data: form.values() })
        .then(() => dispatch('ui.thingModal.open', false))
        .then(() => dispatch('ui.snackBar.open', 'Task Saved.'))
        .then(() => form.clear())
        .then(() => dispatch('ui.thingModal.clear'))
        .catch((err) => {
          log.error('Saving Task Failed: ', err);
          form.invalidate(err.message);
          dispatch('ui.snackBar.open', err.message);
        });
    },
  });
}

const fields = [
  'title',
  'description',
  'uuid',
  'company',
  'start',
  'end',
  'date',
  'customerName',
  'primaryContact',
  'phone',
  'customData[].name',
  'customData[].value',
  'isWindow',
  'duration',
  'marketingCode',
];

const values = {
  company: '',
  customData: [
    {
      name: '',
      value: '',
    },
  ],
  customerName: '',
  date: '',
  description: '',
  duration: '',
  end: '',
  isWindow: false,
  marketingCode: '',
  phone: '',
  primaryContact: '',
  start: '',
  title: '',
  uuid: '',
};

const labels = {
  'customData[].name': 'Name',
  'customData[].value': 'Value',
  customerName: 'Customer Name',
  description: 'Description',
  end: 'End Time',
  marketingCode: 'Marketing Code',
  phone: 'Phone Number',
  primaryContact: 'Primary Contact',
  start: 'Start Time',
  title: 'Title',
};

const rules = {
  company: 'string',
  'customData[].name': 'string',
  'customData[].value': 'string',
  customerName: 'string',
  date: 'date',
  description: 'required|string|min:5',
  end: 'date',
  marketingCode: 'string',
  phone: 'string',
  primaryContact: 'string',
  start: 'date',
  title: 'required|string|between:5,100',
  uuid: 'string',
};

export function init(initialValues = {}) {
  let fieldVals = _.clone(values);
  const targetFields = _.keys(fieldVals);
  fieldVals = _.merge(fieldVals, _.pick(initialValues, targetFields));

  const form = new TaskForm({ fields, labels, rules, values: fieldVals });
  log.debug('Created Task Form with Values: ', form.values());

  return form;
}
