import React from 'react';
import { observer } from 'mobx-react';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

// Components:
import { Button, Grid, Icon, Label, Message, Popup } from 'semantic-ui-react';
import cx from 'classnames';

import { getIconDefinition } from './tagUtils';

import AddEndorsement from '#/shared/components/utils/AddEndorsement';
import { isCompanyMember } from '#/shared/components/utils/CompanyStatus';

// Styles:
import styles from '#/shared/styles/CertList.css';
import { CompanyImage } from '#/shared/components/ssm/CompanyImage';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('CertList');

/**
 * @deprecated Please use components found in `/ssm/tags/TagList` instead
 *
 * These components are used on the older pages in the site and should not
 * generally be used when building new functionality.
 *
 * Note, there is a considerable amount of functionality present in these
 * components that has not been replicated in the replacement `TagList` set,
 * and that should not be seen as a universal drop-in replacement.
 */

const CertIcon = observer(({ cert, company, certType, listType }) => {
  const isPromoted = _.get(cert, 'props.isPromoted', false);
  let icon = getIconDefinition(certType);
  let color;

  if (isPromoted) {
    icon = 'star';
    color = 'yellow';
  } else if (cert.isNew) {
    icon = 'plus';
    color = 'green';
  } else if (
    (cert.isAdded ||
      // All quals will have the company in the companies array.
      // this checks the 'listType' to decide whether or not to
      // display it as a "selected"/"verified" state
      _.includes(cert.companies, _.get(company, 'uuid', company))) &&
    certType === 'qual' &&
    listType !== 'available'
  ) {
    color = 'green';
  } else if (cert.isVerified && certType === 'cert') {
    color = 'green';
  }

  const ci = (!!icon && <Icon color={color} name={icon} />) || (
    <Icon color="red" name="question" />
  );

  return ci;
});

CertIcon.displayName = 'CertIcon';

const SkillCount = observer(({ cert, company }) => {
  const color = _.includes(cert.companies, _.get(company, 'uuid', company))
    ? '#21ba45'
    : 'rgba(0, 0, 0, 0.87)';

  if (cert.isAdded) {
    return <Label basic icon="plus" style={{ color: '#21ba45' }} />;
  }
  const endorsementCt =
    _.get(cert, 'companies', []).length + _.get(cert, 'users', []).length;

  if (!endorsementCt) {
    return false;
  }

  return (
    endorsementCt > 0 && (
      <Label basic style={{ color }}>
        {endorsementCt}
      </Label>
    )
  );
});

SkillCount.displayName = 'SkillCount';

const visualStyles = {
  20190122: cx(
    'br2 bg-light-blue ph2 pv1 ma1',
    'black-80 ttu f7 fw7',
    'flex items-center justify-center',
  ),
};

const Cert = observer(
  ({
    cert,
    company,
    action,
    noIcon,
    size,
    selected,
    worker,
    certType = cert.certType,
    compact,
    icon,
    noCount,
    listType,
    style = {},
    visualStyle,
  }) => {
    if (visualStyle >= 20190122) {
      return <div className={visualStyles[20190122]}>{cert.title}</div>;
    }

    if (size === 'mini' && !_.isFunction(action)) {
      return (
        <Label
          basic
          className={cx(styles.micro, 'mb2')}
          color={selected ? 'green' : undefined}
          compact
          content={cert.title}
          icon={
            (worker && !noIcon) || !worker ? (
              <CertIcon
                cert={cert}
                certType={certType}
                company={company}
                listType={listType}
                noIcon={noIcon}
                worker={worker}
              />
            ) : undefined
          }
          key={cert.uuid}
          size="mini"
          style={style}
        />
      );
    }

    let certIcon;

    if (icon) {
      certIcon = icon;
    } else if ((worker && !noIcon) || !worker) {
      certIcon = (
        <CertIcon
          cert={cert}
          certType={certType}
          company={company}
          listType={listType}
          noIcon={noIcon}
          worker={worker}
        />
      );
    }
    let title = cert.title;
    if (certType === 'attribute') {
      title = `${_.get(cert, 'question.shortName')}: ${title}`;
    }
    const isShiftsmartOfficialRole = cert.certType === 'role' && cert.shiftsmartOfficial
    const content = (
      <Button
        basic={!selected}
        className={cx(styles.basic, 'mb2', 'truncate')}
        compact
        content={
          <div className="flex justify-center items-center ">
            {certType == 'qual' && cert.company && (
              <div style={{ paddingRight: 2 }}>
                <CompanyImage company={cert.company} height={15} width={15} />
              </div>
            )}
            {!icon && certIcon} 
            {title}
            {isShiftsmartOfficialRole && <Icon name='star' color='blue'/>}
          </div>
        }
        icon={icon ? certIcon : null}
        key={cert.uuid}
        label={
          noCount ||
          compact ||
          noIcon ||
          !worker ||
          certType === 'cert' ? undefined : (
            <SkillCount
              cert={cert}
              company={company}
              listType={listType}
              noIcon={noIcon}
              worker={worker}
            />
          )
        }
        onClick={_.isFunction(action) ? action(cert) : _.noop}
        positive={selected}
        size={compact ? 'mini' : size}
        style={_.extend({ maxWidth: '45%' }, style || {})}
      />
    );

    if (_.get(cert, 'title.length', 0) < 40) {
      return content;
    }

    return (
      <Popup
        className="no-dimmer"
        content={cert.title}
        on="hover"
        style={{ inline: true, zIndex: 99999 }}
        trigger={content}
      />
    );
  },
);

Cert.displayName = 'Cert';

export { Cert };

const CertList = observer(
  ({
    certType,
    worker,
    certs,
    company,
    selected,
    filterSelected = false,
    action,
    noAdd = false,
    noIcon = false,
    size = 'tiny',
    className = 'tc',
    auth = {},
    isSelected = _.noop,
    compact = false,
    limit = 500,
    showSkills = false,
    showAll = false,
    create = false,
    noCount,
    showAllTags,
    listType,
    overflowIndicator,
    visualStyle,
  }) => {
    log.warn(
      'CertList has been deprecated. Please use the components in /ssm/tags/TagList instead',
    );
    const theCerts = (certs || []).map((c) =>
      _.isString(c) ? dispatch('tags.lookup', c) : c,
    );

    let certList = _(theCerts)
      .filter((c) => !certType || c.certType === certType)
      .uniqBy('uuid')
      .value();

    if (
      /^(qual|position)$/.test(certType) &&
      !_.isEmpty(company) &&
      !showAllTags
    ) {
      const companyUUID = _.isString(company) ? company : company.uuid;
      certList = certList.filter((c) => _.includes(c.companies, companyUUID));
    } else if (
      /^(qual|position)$/.test(certType) &&
      !auth.isAdmin &&
      !showAllTags
    ) {
      log.warn('No company included on Qual display');
      certList = [];
    }

    let filteredSkillCount;

    if (!showAll && worker && certType === 'skill' && !showSkills) {
      filteredSkillCount = certList.length;
      certList = certList.filter(
        (cert) => cert.isAdded || !!_.union(cert.companies, cert.users).length,
      );

      filteredSkillCount -= certList.length;
    }

    const createCertClick =
      ({ cert }) =>
      () => {
        log.debug('Creating new Cert');
        dispatch('ui.tagModal.setup', {
          company,
          open: true,
          value: {
            certType,
            ...cert,
          },
        });
      };

    const selectionArray = (
      (selected && selected.toJS && selected.toJS()) ||
      selected ||
      []
    ).map((s) => _.get(s, 'uuid', s));

    const showCreate =
      create ||
      (!noAdd &&
        (!worker || _.isEmpty(worker.uuid)) &&
        (isCompanyMember(worker, company) || auth.isAdmin));
    
    const createButtonDisabled = (/(role|position)s?/i.test(certType) && !auth.isEngineer)

    let auxClasses = '';

    if (visualStyle >= 20190122) {
      auxClasses = 'flex';
    }

    return (
      <div
        className={cx(
          styles.certList,
          className,
          _.isEmpty(certList) && 'center',
          'w-100',
          auxClasses,
        )}
      >
        {_(certList)
          .take(limit)
          .compact()
          .map((cert) => {
            const active =
              !!isSelected(cert) ||
              (selectionArray && _.includes(selectionArray, cert.uuid));
            return (
              (!filterSelected || !active) && (
                <Cert
                  action={action}
                  cert={cert}
                  certType={certType}
                  compact={compact}
                  company={company}
                  key={cert.uuid}
                  listType={listType}
                  noCount={noCount}
                  noIcon={noIcon}
                  selected={active}
                  size={size}
                  visualStyle={visualStyle}
                  worker={worker}
                />
              )
            );
          })
          .compact()
          .value()}

        {overflowIndicator && certList.length > limit && (
          <Popup
            content={
              <div className="w-100">
                <div className="w-100 bb b--black-10 f5 fw5 tc pb1 mb2">
                  {`Partner ${_.capitalize(certType || 'Tag')}s`}
                </div>
                <div
                  className="flex justify-center flex-wrap"
                  style={{ maxWidth: '20rem' }}
                >
                  {_(certList)
                    .compact()
                    .map((cert) => {
                      const active =
                        !!isSelected(cert) ||
                        (selectionArray &&
                          _.includes(selectionArray, cert.uuid));
                      return (
                        (!filterSelected || !active) && (
                          <Cert
                            action={action}
                            cert={cert}
                            certType={certType}
                            compact={compact}
                            company={company}
                            key={cert.uuid}
                            listType={listType}
                            noCount={noCount}
                            noIcon={noIcon}
                            selected={active}
                            size={size}
                            style={{ marginBottom: '0.25rem' }}
                            visualStyle={visualStyle}
                            worker={worker}
                          />
                        )
                      );
                    })
                    .compact()
                    .value()}
                </div>
              </div>
            }
            flowing
            hideOnScroll
            on="hover"
            position="right center"
            trigger={
              visualStyle >= 20190122 ? (
                <div className={visualStyles[20190122]}>
                  {`+${certList.length - limit}`}
                </div>
              ) : (
                <div className="h-100 flex items-center">
                  <Label
                    basic
                    circular
                    className={cx(size === 'mini' && styles.micro, 'mb2')}
                    compact={compact}
                    content={`+${certList.length - limit}`}
                    size={size}
                  />
                </div>
              )
            }
          />
        )}

        {!!filteredSkillCount && (
          <Message
            className="tc i silver"
            content={`${filteredSkillCount} Unverified Skills are hidden. Click here to show`}
            onClick={() => dispatch('ui.workerDetails.showSkills', true)}
            secondary
          />
        )}

        {!noAdd &&
          worker &&
          !_.isEmpty(worker.uuid) &&
          (isCompanyMember(worker, company) || auth.isAdmin ? (
            <AddEndorsement
              certType={certType}
              company={company}
              isAdmin={auth.isAdmin}
              size={size}
              worker={worker}
            />
          ) : (
            <span className="gray dn">N/A</span>
          ))}

        {showCreate && (
          <Grid.Row>
            <Grid.Column>
              <Button
                content="Add New"
                icon="plus"
                onClick={createCertClick({})}
                disabled={createButtonDisabled}
                primary
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </div>
    );
  },
);

CertList.displayName = 'CertList';

export default CertList;

const CertItems = observer(
  ({ certs, company, certType, auth = {}, className }) => {
    const theCerts = (certs || []).map((c) =>
      _.isString(c) ? dispatch('tags.lookup', c) : c,
    );

    let certList = certType
      ? theCerts.filter((c) => c.certType === certType)
      : theCerts;

    if (certType === 'qual' && company && !auth.isAdmin) {
      const companyUUID = _.isString(company) ? company : company.uuid;
      certList = certList.filter((c) => _.includes(c.companies, companyUUID));
    }

    certList = _.uniqBy(certList, 'uuid');

    return (
      <span className={cx(className)}>
        {certList.map((cert) => cert.title || cert).join(', ')}
      </span>
    );
  },
);

CertItems.displayName = 'CertItems';

export { CertItems };
