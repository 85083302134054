import Promise from 'bluebird';
import _ from 'lodash';
import isUUID from 'uuid-validate';

import { IBaseStore } from '#/shared/stores/_baseStore';
import { StoreName } from '#/shared/stores';
import { getStore } from '#/shared/getStores';

export const flattenStringArrays = (arr: (string | undefined)[]) => {
  return arr.reduce((acc, val) => {
    if (val && val.includes('|')) {
      return [...acc, ...val.split('|')];
    }
    if (_.isEmpty(val)) {
      return acc;
    }
    return [...acc, val];
  }, []);
};

type ChunkifiedLoaderOptions = {
  /** The maximum number of rows to query at once; see `<service>/config.ts[pagination.max] */
  maxChunkSize?: number;
  /* additional query params to be passed to the service */
  query: Record<string, unknown>;
  /* the key in the db model that is to be queried */
  queryKey?: string;
  /* the key in the row that holds the value to be queried  */
  rowFieldKey: string;
  /** The service name to be queried */
  service: Exclude<StoreName, 'app' | 'auth'>;
};
export async function chunkifiedLoader(
  rows,
  {
    rowFieldKey,
    queryKey = 'uuid',
    query,
    service,
    maxChunkSize = 200,
  }: ChunkifiedLoaderOptions,
) {
  if (_.isEmpty(rows)) return {};

  let keyedRows = [...rows];
  let uuidRows;
  let valsByUUID;

  if (queryKey !== 'uuid') {
    ({ uuidRows = [], keyedRows = [] } = _.groupBy(rows, (val) =>
      isUUID(val[rowFieldKey]) ? 'uuidRows' : 'keyedRows',
    ));
    valsByUUID = await chunkifiedLoader(uuidRows, {
      query,
      queryKey: 'uuid',
      rowFieldKey,
      service,
    });
  }
  const rawValueChunks = _(keyedRows)
    .map(rowFieldKey)
    .uniq()
    .chunk(maxChunkSize)
    .value();

  const values = await Promise.map(rawValueChunks, async (values) => {
    let fieldValues = values;
    if (rowFieldKey === 'postShiftSurveyDefinitions' && Boolean(values[0])) {
      fieldValues = flattenStringArrays(values);
    }
    const { data, total } = await (getStore(service) as IBaseStore).runQuery({
      query: {
        $limit: maxChunkSize,
        ...query,
        [queryKey]: {
          $in: fieldValues,
        },
      },
    });

    if (total > _.size(data)) {
      log.error('ChunkifiedLoader: Total count is greater than chunk size', {
        chunkSize: _.size(data),
        total,
      });
    }

    return data;
  });

  const flattenedValues = _.flatten(values);
  const keyedValues = _.keyBy(flattenedValues, queryKey);

  return { ...valsByUUID, ...keyedValues };
}
