import { action, observable } from 'mobx';

export default class UserIdsModal {
  @observable
  isOpen = false;

  @observable
  userIds = [];

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  setup(userIdsList) {
    this.userIds = userIdsList;
  }
}
