import type {
  ICompany,
  IDynamicBonusPref,
  IShift,
  IShiftTemplate,
  Timestamp,
} from '@shiftsmartinc/shiftsmart-types';

import React, { useImperativeHandle, useMemo, useRef, useState } from 'react';
import moment, { MomentInput } from 'moment';
import _ from 'lodash';
import cx from 'classnames';
import { Icon, SemanticCOLORS } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { observable, runInAction, set } from 'mobx';
import { getChildLogger } from 'logdown';

import styles from '#/shared/styles/shiftItemUtils.css';
import { useStore, useStores } from '#/shared/hooks/useStores';
import { PopupIcon } from '#/shared/components/ssm/PopupIcon';
import { getFormattedStartEndDate } from '#/utils/date';
import { useAutorunEffect } from '#/shared/hooks/useHooksWithMobx';
import { StyledStatus } from '#/shared/components/shifts/details/TimecardIndicatorLabels';
import { TZAwareDateRange } from '#/shared/components/utils/DateRangeString';
import { GlobalCopyToClipboard } from '#/global/components/GlobalCopyToClipboard';

import { StaffingFillProgressBar } from './StaffingFillProgressBar';
import Money, { CURRENCY_SYMBOLS, getCurrencySymbol } from './Money';

interface ShiftTimesProps {
  className?: string;
  end: MomentInput | Timestamp;
  noIcon?: boolean;
  showDOW?: boolean;
  start: MomentInput | Timestamp;
  timezone?: string;
  vertical?: boolean;
}

interface ShiftAutoLaunchProps {
  autoLaunchTime: string;
}

const ShiftDateTimeHeader = observer(({ start, end, timezone, shift }) => {
  const ref = useRef(null);
  const { active, inactive } = useRelativeTimezones({
    end,
    ref,
    start,
    timezone,
  });

  return (
    <div className="flex flex-row flex-nowrap items-center">
      <span
        className={cx('f4 pt2 pr2 ssm-text-primary fw300', styles.summaryDate)}
      >
        {active.start.format('dddd, MMM Do YYYY')}
      </span>
      <span
        className={cx('f4 pt2 pr2 ssm-text-primary fw300', styles.summaryTime)}
      >
        {active.start.format('LT')} - {active.end.format('LT')}{' '}
      </span>
      {timezone && timezone !== moment.tz.guess() && (
        <span className={cx('f4 pt1 pr2', styles.summaryTime)}>
          <PopupIcon
            color="blue"
            delay={true}
            name="info circle"
            size="small"
            tooltip={getFormattedStartEndDate({
              end,
              showTimezone: true,
              start,
              timezone: inactive.timezone,
            })}
            trigger={
              <span
                className="pointer br3 pv1 ph2 ssm-bg-blue white tc f6 b lh-solid ssm-no-text-select"
                onClick={(e) => {
                  e.stopPropagation();
                  ref.current.toggle();
                }}
              >
                {active.abbr}
              </span>
            }
          />
        </span>
      )}

      {/flexible/i.test(shift.shiftScheduleType) && (
        <span className={cx('f4 pt1 pr2', styles.summaryTime)}>
          <span className="br3 pv1 ph2 ssm-blue bg-white tc f6 font-bold lh-solid ssm-no-text-select tw-ring-2 tw-ring-inset tw-ring-brand">
            FLEX{' '}
            <TZAwareDateRange
              end={shift.shiftScheduleRules?.workingHoursEnd}
              start={shift.shiftScheduleRules?.workingHoursStart}
              timeFormat="ha"
              timezone={timezone}
            />
          </span>
        </span>
      )}
    </div>
  );
});

const fileLog = getChildLogger('shifts.itemUtils');

const ShiftTimes: React.FC<ShiftTimesProps> = observer(
  ({
    start,
    end,
    vertical = false,
    showDOW = false,
    timezone,
    noIcon,
    className,
  }) => {
    const log = useRef(fileLog.getChildLogger('shiftTimes'));
    const ref = useRef(null);
    const { active } = useRelativeTimezones({
      end,
      ref,
      start,
      timezone,
    });

    return (
      <span
        className={cx(
          'flex items-center',
          vertical ? 'flex-column' : 'flex-row',
          className,
        )}
      >
        {!noIcon && (
          <img
            alt=""
            src="/static/img/icon-time.png"
            style={{
              display: 'block',
              height: 23,
              width: 23,
            }}
          />
        )}
        <span
          className={cx(
            styles.details,
            vertical ? styles.detailsVertical : 'items-center',
          )}
        >
          {showDOW && active.start.format('ddd')} {active.start.format('LT')} -{' '}
          {active.end.format('LT')}
          <span
            className="pl1 pointer ssm-blue tc f7 b lh-solid ssm-no-text-select"
            onClick={(e) => {
              e.stopPropagation();
              ref.current.toggle();
            }}
          >
            {active.abbr}
          </span>
        </span>
      </span>
    );
  },
);
ShiftTimes.displayName = 'ShiftTimes';

const ShiftAutoLaunch: React.FC<ShiftAutoLaunchProps> = observer(
  ({ autoLaunchTime }) => {
    const autoLaunchMoment = moment(autoLaunchTime).tz(moment.tz.guess());
    const launchDate = autoLaunchMoment.format('ddd');
    const launchTime = autoLaunchMoment.format('LT');
    return (
      <PopupIcon
        tooltip={`Shift will be activated on ${launchDate} at ${launchTime}`}
        trigger={
          <img
            alt=""
            src="/static/img/shifts/auto-launch.svg"
            style={{
              display: 'block',
              height: 23,
              width: 23,
            }}
          />
        }
      />
    );
  },
);
ShiftAutoLaunch.displayName = 'ShiftAutoLaunch';

export const ShiftFlexLabel = observer(({ shiftScheduleRules, timezone }) => {
  // const {} = useStores();

  if (!shiftScheduleRules) return null;

  return (
    <div>
      <StyledStatus color="var(--ssm-blue)">
        flex{' '}
        <TZAwareDateRange
          end={shiftScheduleRules.workingHoursEnd}
          start={shiftScheduleRules.workingHoursStart}
          timeFormat="ha"
          timezone={timezone}
        />
      </StyledStatus>
    </div>
  );
});

export function useRelativeTimezones({
  start,
  end,
  timezone,
  ref,
}: {
  end: MomentInput | Timestamp;
  ref?: React.Ref<{ toggle: () => void }>;
  start: MomentInput | Timestamp;
  timezone?: string;
}) {
  const { current: log } = useRef(
    fileLog.getChildLogger('useRelativeTimezones'),
  );

  const { displayLocalTimes, toggleTimezoneDisplay } = useStore('prefs');

  useImperativeHandle(ref, () => ({
    toggle: toggleTimezoneDisplay,
  }));

  useAutorunEffect(() => {
    const activeTZ = (displayLocalTimes ? cpuTZ : timezone) || cpuTZ;
    const tzAbbr = moment.tz
      .zone(activeTZ)
      .abbr(moment(start).toDate().getTime());
    const inactiveTZ = (displayLocalTimes ? timezone : cpuTZ) || cpuTZ;
    const inactiveTZAbbr = moment.tz
      .zone(inactiveTZ)
      .abbr(moment(start).toDate().getTime());

    retval.set({
      active: {
        abbr: tzAbbr,
        timezone: activeTZ,
      },
      inactive: {
        abbr: inactiveTZAbbr,
        timezone: inactiveTZ,
      },
    });

    log.silly('Updating relative timezones', { end, retval, start, timezone });
  }, [displayLocalTimes, `${start}`, `${end}`]);

  const cpuTZ = moment.tz.guess();
  const cpuAbbr = moment.tz.zone(cpuTZ).abbr(moment(start).unix());

  const [retval] = useState(() =>
    observable({
      active: {
        abbr: cpuAbbr,
        end: moment(end).tz(cpuTZ),
        start: moment(start).tz(cpuTZ),
        timezone: cpuTZ,
      },
      inactive: {
        abbr: cpuAbbr,
        end: moment(end).tz(cpuTZ),
        start: moment(start).tz(cpuTZ),
        timezone: cpuTZ,
      },
      set({ active, inactive }) {
        runInAction(() => {
          set(this, {
            active: {
              ...active,
              end: moment(end).tz(active.timezone),
              start: moment(start).tz(active.timezone),
            },
            inactive: {
              ...inactive,
              end: moment(end).tz(inactive.timezone),
              start: moment(start).tz(inactive.timezone),
            },
          });
        });
      },
    }),
  );

  return retval;
}

interface ShiftDateProps {
  start: string;
  vertical?: boolean;
}
const ShiftDate: React.FC<ShiftDateProps> = ({ start, vertical = false }) => (
  <span
    className={cx('flex items-center', vertical ? 'flex-column' : 'flex-row')}
  >
    <img
      alt=""
      src="/static/img/icon-calendar-bold.png"
      style={{
        display: 'block',
        height: 23,
        width: 23,
      }}
    />
    <span className={cx(styles.details, vertical && styles.detailsVertical)}>
      {moment(start).format('MM/DD/YYYY')}
    </span>
  </span>
);
ShiftDate.displayName = 'ShiftDate';

interface ShiftDurationIconProps {
  duration: number;
}
const ShiftDurationIcon: React.FC<ShiftDurationIconProps> = ({ duration }) => (
  <span className={styles.durationIcon}>
    {`${duration}`.replace(/\.\d+$/, '')}
  </span>
);
ShiftDurationIcon.displayName = 'ShiftDurationIcon';

interface ShiftTypeProps {
  shiftType: string;
  /** @deprecated Don't show the standard shift type */
  showStandardType?: true;
}
const ShiftType: React.FC<ShiftTypeProps> = ({
  shiftType,
  showStandardType,
}) => {
  const store = useStores();
  const { shiftTypes } = store.shifts;
  const readableType = _.find(shiftTypes, (type) => type.value === shiftType);

  if (!showStandardType && /Standard/i.test(shiftType)) {
    return null;
  }

  if (!readableType) {
    return null;
  }
  return (
    <span className="flex items-center justify-center">
      <span className={cx('flex-shrink-0', styles.shiftTypeIcon)} />
      <span className="pl1 ssm-text-secondary f5">{readableType.text}</span>
    </span>
  );
};
ShiftType.displayName = 'ShiftType';

interface ShiftPayRateProps {
  currency?: keyof typeof CURRENCY_SYMBOLS;
  duration: number;
  pay: number;
  rate: number;
  useFlatRatePay: boolean;
}

const ShiftDurationAndPay: React.FC<ShiftPayRateProps> = ({
  rate,
  pay,
  useFlatRatePay,
  currency,
  duration,
}) => {
  const showNA = (useFlatRatePay && !pay) || (!useFlatRatePay && !rate);
  const showPay = useFlatRatePay && !!pay;
  const showRate = !useFlatRatePay && !!rate;

  return (
    <span className="ssm-text-secondary f5 flex items-center">
      <ShiftDurationIcon duration={duration} />
      <span className="ph2">
        {showNA && <span>N/A</span>}
        {showPay && <Money currencyCode={currency}>{pay.toFixed(2)}</Money>}
        {showRate && (
          <Money currencyCode={currency}>{`${rate.toFixed(2)}/hr`}</Money>
        )}
      </span>
    </span>
  );
};
ShiftDurationAndPay.displayName = 'ShiftDurationAndPay';

type BonusConfig = {
  amount: number;
  bonusConfigs: Array<IDynamicBonusPref | unknown>;
  category: string;
  max: number;
  min: number;
  title: string;
};

type UseBonusSummaryProps = {
  bonus?: number;
  notificationPrefs: IShift['notificationPrefs'];
};

const useBonusSummary = ({
  bonus,
  notificationPrefs,
}: UseBonusSummaryProps) => {
  const bonusSummary = useMemo(() => {
    return _(notificationPrefs)
      .filter({
        category: 'dynamicBonus',
      })
      .reduce(
        (acc: BonusConfig, np: IDynamicBonusPref) => {
          acc.bonusConfigs.push(np);

          if (!acc.min || np.settings.amount < acc.min) {
            acc.min = np.settings.amount;
          }
          if (!acc.max || np.settings.amount > acc.max) {
            acc.max = np.settings.amount;
          }

          // take either the first in the list, or the last active
          if (!acc.amount || np.isActive) {
            acc.amount = np.settings.amount;
          }

          if (np.category) {
            acc.category = np.category;
            acc.title = _.startCase(np.category);
          }
          return acc;
        },
        {
          amount: bonus,
          bonusConfigs: _.compact([
            bonus && {
              amount: bonus,
              category: 'shiftBonus',
            },
          ]),
          category: 'shiftBonus',
          max: bonus,
          min: bonus,
          title: 'Bonus',
        } as BonusConfig,
      );
  }, [bonus, notificationPrefs]);

  return bonusSummary;
};

type ShiftBonusProps = {
  basic?: boolean;
  bonus?: IShift['bonus'];
  className?: string;
  currency?: IShift['currency'];
  notificationPrefs: IShift['notificationPrefs'];
  size?: 'large';
};

const ShiftBonus = observer((props: ShiftBonusProps) => {
  const { bonus, notificationPrefs, currency, basic, size, className } = props;
  const { bonusConfigs, ...bonusSummary } = useBonusSummary({
    bonus,
    notificationPrefs,
  });

  if (_.isNil(bonusSummary?.amount) || !_.toNumber(bonusSummary?.amount)) {
    return null;
  }

  const dbText = (
    <div className="flex-column w5 flex">
      {_.map(bonusConfigs, (dynamicBonus: IDynamicBonusPref, i) => {
        if (dynamicBonus) {
          const isLast = i + 1 == _.size(bonusConfigs);
          const hours = Math.abs((dynamicBonus.delay || 0) / 60)
            .toFixed(2)
            .replace(/\.?0+/, '');
          const timingText = _.toNumber(hours)
            ? `${hours}${
                dynamicBonus.delay < 0
                  ? 'h before Shift start'
                  : 'h after Shift activation'
              }`
            : 'At Shift Activation';

          let text = `${timingText} a ${getCurrencySymbol(currency)}${
            dynamicBonus.settings?.amount
          } Completion Bonus will be made available to ${
            _.size(dynamicBonus.poolIds)
              ? 'partners in the specified pools'
              : 'all invited partners'
          }${
            dynamicBonus.settings?.testMode
              ? '. TEST MODE ONLY: NO BONUS WILL BE APPLIED TO ASSIGNMENTS'
              : ''
          }`;

          if (moment().isAfter(dynamicBonus.sentAt)) {
            text = text.replace('will be made', 'was made');
          }

          return <div className={cx('ph1', !isLast && 'pb2')}>{text}</div>;
        }
      })}
    </div>
  );

  return (
    <PopupIcon
      tooltip={dbText}
      trigger={
        <div className={cx('ssm-no-text-select flex items-center', className)}>
          <ShiftBonusPill basic={!!basic} size={size}>
            <div>
              <Money currencyCode={currency || undefined}>
                {bonusSummary?.max > bonusSummary?.min
                  ? `${bonusSummary.min}-${bonusSummary.max}`
                  : `${bonusSummary?.amount}`}
              </Money>
            </div>
          </ShiftBonusPill>
          <span className={cx(styles.summaryStaff, 'pl2')}>
            {_.startCase(bonusSummary.category)}
          </span>
        </div>
      }
    />
  );
});

ShiftBonus.displayName = 'ShiftBonus';

const translateSizeToProps = (size) => {
  let dims;
  let font;
  switch (size) {
    case 'large':
      dims = '3rem';
      font = 'f4';
      break;
    default:
      dims = '25px';
  }

  return { dims, font };
};

type ShiftBonusPillProps = {
  basic?: boolean;
  className?: string;
  size?: string;
};
export const ShiftBonusPill = styled.div.attrs<ShiftBonusPillProps>(
  ({ basic, className, size }) => {
    const { font, dims } = translateSizeToProps(size);

    return {
      className: cx(
        'br-pill h2 min-w2 flex items-center justify-center',
        font || 'f5',
        className,
        basic ? 'ssm-blue ba' : 'ssm-bg-blue white',
      ),
      dims,
    };
  },
)<
  ShiftBonusPillProps & {
    dims?: `${number}` | number;
  }
>`
  border-width: 2px;
  height: ${({ dims = '25px' }) => `${dims}`};
  min-width: ${({ dims = '25px' }) => `${dims}`};

  & > div {
    padding-top: 0.1rem;
    padding-left: 0.4rem;
    padding-right: 0.5rem;
  }
`;

const StyledCheckinCodeContainer = styled.span.attrs(() => ({
  className: 'flex flex-row ssm-text-secondary f4 flex-wrap',
}))`
  /* font-size: 16px; */
  font-weight: 500;
  color: var(--text-secondary);

  user-select: none;

  & > :not(:first-child) {
    padding-left: 0.5rem;
  }
`;

const StyledIcon = styled(Icon)`
  position: relative !important;
`;

const ShiftCheckinCodes = observer<
  React.FC<
    Pick<
      IShift,
      | 'requireCheckinCode'
      | 'requireCheckoutCode'
      | 'checkinCode'
      | 'checkoutCode'
      | 'useUniqueAttendanceCodes'
    > & {
      company: ICompany;
      enableCheckinCode?: boolean;
      pill?: boolean;
    }
  >
>(
  ({
    company,
    enableCheckinCode,
    requireCheckinCode,
    requireCheckoutCode,
    checkinCode,
    checkoutCode,
    useUniqueAttendanceCodes,
    pill,
  }) => {
    const { ui } = useStores();
    const { enableOnsiteCheckinCode, enableOnsiteCheckoutCode } =
      company?.settings?.things ?? {};

    const handleClick = async (code, inOrOut) => {
      if (navigator?.clipboard) {
        await navigator.clipboard.writeText(code);
      }

      ui.snackBar.open(`${_.startCase(inOrOut)} copied to clipboard`);
    };

    return enableOnsiteCheckinCode &&
      enableCheckinCode &&
      (checkinCode || checkoutCode) ? (
      <StyledCheckinCodeContainer>
        {_.map({ checkinCode, checkoutCode }, (code, key) => {
          if (!code) {
            return null;
          }

          const isEnabled = /checkin/i.test(key)
            ? enableOnsiteCheckinCode
            : enableOnsiteCheckoutCode;

          if (!isEnabled) {
            return null;
          }

          const isRequired = /checkin/i.test(key)
            ? requireCheckinCode
            : requireCheckoutCode;

          return (
            <div>
              <PopupIcon
                tooltip={
                  <div>
                    <div className="w-100 tc b">Shift {_.startCase(key)}</div>
                    <div className="pb2">
                      When{' '}
                      {/checkin/i.test(key) ? 'checking in to' : 'completing'}{' '}
                      their shifts, workers {isRequired ? 'will' : 'may'} be
                      prompted for a checkin code to verify their attendance.
                      Please make this code available to the shift manager so
                      that all workers will be able to {key.replace('Code', '')}
                      .
                    </div>
                    {useUniqueAttendanceCodes && (
                      <div className="pb2 red">
                        This shift uses a custom checkin code that is unique
                        from other codes for this location on this day. Please
                        be sure all store managers have this code available for
                        partners to checkin to their shift.
                      </div>
                    )}
                    <div className="w-100 tc">click to copy ...</div>
                  </div>
                }
                trigger={
                  <div
                    className={cx(pill && 'ba b--black-50 br2 ph1')}
                    onClick={() => handleClick(code, key)}
                    role="button"
                    style={{ cursor: 'copy', width: 'fit-content' }}
                    tabIndex={-1}
                  >
                    <StyledIcon
                      color="blue"
                      name={/checkin/i.test(key) ? 'sign in' : 'sign out'}
                    />
                    {code}
                    {useUniqueAttendanceCodes && (
                      <StyledIcon
                        className="ph1"
                        color="red"
                        name="ticket alternate"
                      />
                    )}
                  </div>
                }
              />
            </div>
          );
        })}
      </StyledCheckinCodeContainer>
    ) : null;
  },
);

interface ShiftPositionProps {
  position: { title: string };
}
const ShiftPosition: React.FC<ShiftPositionProps> = ({ position }) => {
  const title = _.get(position, 'title');
  if (!title) {
    return null;
  }

  return <div className={cx(styles.position, 'pr3')}>{title}</div>;
};

ShiftPosition.displayName = 'ShiftPosition';

type addressObj = { address: { street1: string }; name: string };
type company = { address: { street1: string } };
interface ShiftLocationProps {
  address?: string;
  addressObj?: addressObj;
  company?: { address: { street1: string } };
  isRemote?: boolean;
  noPadding?: boolean;
}
const ShiftLocation: React.FC<ShiftLocationProps> = ({
  addressObj = {} as addressObj,
  address = '',
  company = {} as company,
  isRemote = false,
  noPadding = false,
}) => {
  const { name } = addressObj ?? {};

  const { routing } = useStores();

  let fullAddressString = address;
  if (_.isEmpty(fullAddressString)) {
    if (!_.isEmpty(addressObj)) {
      fullAddressString = _.compact([
        addressObj?.address?.street1,
        addressObj?.address?.city,
        addressObj?.address?.state,
      ]).join(', ');
    } else {
      fullAddressString = _.compact([
        company?.address?.street1,
        company?.address?.city,
        company?.address?.state,
      ]).join(', ');
    }
  }

  const addressComponents = _([
    name && !/^site for request/i.test(name) && name,
    fullAddressString,
  ])
    .compact()
    .uniq()
    .value();

  return (
    <GlobalCopyToClipboard text={fullAddressString}>
      <div
        className={cx(
          styles.location,
          'flex flex-row items-center',
          !noPadding && 'pv2',
          addressObj?.uuid && 'pointer',
        )}
      >
        <img
          alt=""
          src="/static/img/icon-map-marker.svg"
          style={{
            display: 'block',
            height: 23,
            margin: 0,
            width: 23,
          }}
        />
        <span className="pl2 ssm-text-secondary f5 flex flex-wrap items-center">
          {isRemote
            ? 'Remote'
            : _(addressComponents)
                .map((ac, i) => (
                  <span key={ac}>
                    <span className={cx('nowrap', !i && 'b')}>{ac}</span>
                    {!i && _.size(addressComponents) > 1 && (
                      <span className="ph1">@</span>
                    )}
                  </span>
                ))
                .value()}
        </span>
      </div>
    </GlobalCopyToClipboard>
  );
};
ShiftLocation.displayName = 'ShiftLocation';

const ShiftFillRate = observer(({ assignments, slots, status }) => {
  const {
    ui,
    ui: { shiftDetails },
  } = useStores();

  const { totalHours, confirmedHours, aggregateData } = shiftDetails;

  const [showFillHours, setShowFillHours] = useState(false);
  const percentFilled = useMemo(
    () => Math.round((assignments.length / slots) * 100) || 0,
    [assignments.length, slots],
  );

  const color = useMemo(() => getProgressColor(percentFilled), [percentFilled]);

  return (
    <StaffingFillProgressBar
      color={color || undefined}
      fillRate={` ${percentFilled.toFixed(0)}% `}
      inactive={/draft|pending/i.test(status)}
      indicating={
        /active/i.test(status) && percentFilled && percentFilled < 100
      }
      label={
        <div className="flex-column flex overflow-y-visible">
          {!showFillHours ? (
            <div className="w-100 flex justify-between">
              <div>{`${_.size(assignments)}/${slots} filled`}</div>
              <div>
                {aggregateData?.confirmed || '0'}/{slots} confirmed
              </div>
            </div>
          ) : (
            totalHours > 0 && (
              <div className="w-100 flex justify-between">
                <div> {totalHours > 0 && `${totalHours}h total`}</div>
                <div>{`${confirmedHours || 0}h confirmed`}</div>
              </div>
            )
          )}
        </div>
      }
      labelPosition={ui.breakpoints.mu ? 'left' : 'right'}
      onClick={() => setShowFillHours((prev) => (totalHours ? !prev : prev))}
      percent={percentFilled}
      size="small"
      style={{
        cursor: totalHours ? 'pointer' : 'default',
        userSelect: 'none',
      }}
      success={percentFilled === 100}
    />
  );
});

interface ShiftConflictProps {
  end: string;
  start: string;
  title: string;
}
const ShiftConflict: React.FC<ShiftConflictProps> = ({ title, start, end }) => (
  <div className={styles.shiftConflict}>
    <div className={styles.shiftConflictTitle}>{title}</div>
    <div className="mt2 flex flex-row justify-start">
      <ShiftDate start={start} />
      <ShiftTimes end={end} start={start} />
    </div>
  </div>
);
ShiftConflict.displayName = 'ShiftConflict';

const getProgressColor = (percent: number): SemanticCOLORS => {
  let color: SemanticCOLORS = 'green'; // Only if 100%
  if (percent < 40) {
    color = 'red';
  } else if (percent < 80) {
    color = 'orange';
  } else if (percent < 100) {
    color = 'blue';
  }
  return color;
};

export {
  ShiftDurationIcon,
  ShiftType,
  ShiftDateTimeHeader,
  ShiftDate,
  ShiftTimes,
  ShiftAutoLaunch,
  ShiftCheckinCodes,
  ShiftDurationAndPay,
  ShiftBonus,
  ShiftPosition,
  ShiftLocation,
  ShiftConflict,
  ShiftFillRate,
  getProgressColor,
};

export const ShiftTemplatesTimeInfo: React.FC<{
  shiftTemplate: IShiftTemplate;
  vertical: boolean;
}> = ({ shiftTemplate, vertical = false }) => (
  <>
    {shiftTemplate.startDayOffset !== shiftTemplate.endDayOffset && (
      <span
        className={cx(
          'flex items-center',
          vertical ? 'flex-column' : 'flex-row',
        )}
      >
        <img
          alt="calendar"
          src="/static/img/icon-calendar-bold.png"
          style={{
            display: 'block',
            height: 23,
            width: 23,
          }}
        />
        <span
          className={cx(styles.details, vertical && styles.detailsVertical)}
        >
          Day {shiftTemplate.startDayOffset} - Day {shiftTemplate.endDayOffset}
        </span>
      </span>
    )}

    <span
      className={cx('flex items-center', vertical ? 'flex-column' : 'flex-row')}
    >
      <img
        alt="time"
        src="/static/img/icon-time.png"
        style={{
          display: 'block',
          height: 23,
          width: 23,
        }}
      />
      <span className={cx(styles.details, vertical && styles.detailsVertical)}>
        {shiftTemplate.hourStart}:
        {shiftTemplate.minutesStart.toString().padStart(2, '0')} -{' '}
        {shiftTemplate.hourEnd}:
        {shiftTemplate.minutesEnd.toString().padStart(2, '0')}
      </span>
    </span>
  </>
);
