import type { ICompany } from '@shiftsmartinc/shiftsmart-types';

import {
  observable,
  action,
  runInAction,
  computed,
  set,
  isObservable,
} from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import Papa from 'papaparse';

import { getChildLogger } from '#/shared/utils/client.logger';
import { IS_DEVELOPMENT } from '#/config/settings';

export type KnownFieldConfig = {
  /** When downloading the example CSV, this value will be shown in the specified column */
  exampleVal?: string | number | boolean | Date;
  /** Called after the data has been transformed, allows setting of other, related fields in the row based on the value of the current field */
  extendFn?: (
    val: unknown,
    row: Record<string, unknown>,
  ) => Record<string, unknown> | null | undefined;
  /** Include this field in the example CSV download. @see `exampleVal` to include an example` */
  isExampleField?: boolean;
  /** Applicable _only_ to the AddressUploader [2024-03-27], if a value of "null" or "nil" is entered, the field will be set to `null` */
  isNullable?: boolean;
  /** the column header and default object path. See `mapping` to set a different object path from the key */
  key: string;
  /**
   * An object path used to assign a value from the input column to a specific path in the saved object
   * @example
   * "key": "lat",
   * "mapping": "loc.coordinates[1]"
   */
  mapping?: string;
  /** When flagged, do not include this field in the CSV export of the data. Only implemented in AddressUploader */
  noDL?: boolean;
  /** extra documentation for the field when an example CSV is downloaded */
  note?: string;
  /** @deprecated no obvious sign that this is enforced */
  required?: boolean;
  /** A function to transform the value of the field. eg: `_.toNumber`, `toBoolean`, `_.toSafeInteger` */
  transform?: (
    val: string,
    columnKey?: string,
    row?: Record<string, unknown>,
  ) => unknown;
};

export default abstract class BaseUploader {
  get knownFields(): KnownFieldConfig[] {
    if (IS_DEVELOPMENT) {
      this.log?.warn(
        `Please Implement 'knownFields' in your child class to use the BaseUploader's sample CSV data functionality. See "KnownFieldConfig" for more"`,
      );
    }
    return [];
  }

  constructor({ title = 'ui.baseUploader' }) {
    this.log = getChildLogger(title);
    // @ts-expect-error  too much code to fix to make this default to null
    this.inputRef = {};

    return this;
  }

  log;

  @observable
  errorMessage = '';

  @observable
  status = 'pending';

  @observable
  listName = '';

  @observable
  invalidList = observable.array([]);

  @observable
  file = null;

  @observable
  inputRef: HTMLInputElement;

  @observable
  isLoading = false;

  @observable
  isSaving = false;

  @observable
  isOpen = false;

  @observable
  newEntries = observable.array([]);

  @observable
  dates = observable.array([]);

  @observable
  company: ICompany | Record<string, never> = {};

  @observable parseOptions = {
    dynamicTyping: false,
    header: false,
    skipEmptyLines: true,
  };

  @observable
  saveResults = {
    dupe: [],
    ignored: [],
    incomplete: [],
    invalid: [],
    new: [],
    unassigned: [],
    updated: [],
  };

  formats = [
    'YYYY-MM-DD',
    'M/D/YY',
    'M/D/YYYY',
    'M/D',
    'D-MMM-YY',
    'M-D-YYYY',
    'M-D-YY',
    'M/D/YYYY',
  ];

  timeFormats = ['h:mm a', 'hh:mm a', 'H:mm', 'HH:mm'];

  @computed
  get progress() {
    const total = _.size(this.list);
    const saved = _(this.saveResults).values().map(_.size).sum();
    return 100 * (saved / total);
  }

  /** list
   * Stores an array of rows, Ready for saving.
   */
  @observable
  list = observable.array([]);

  /**
   * Parsed Headers
   * An array of all headers found in the parsed objects
   *
   * Used to render the column headers in a dynamic table.
   *
   * @memberof ShiftUploader
   */
  @observable
  parsedHeaders = observable.array([]);

  @observable
  resultHeaders = observable.array([]);

  @observable
  results = [];

  @observable
  isFileUploaded = false;
  /** KnownFields and KnownHeaders
   *
   * @description While not included in the Base Uploader implementation, the `knownFields` getter
   * pattern offers a simple way to map data between a model and the CSV file, and provide sample
   * data thru a single configuration.
   *
   * @see /src/shared/stores/ui/ShiftUploader for a detailed example.
   */

  @computed
  get isSavedOnlyWithProcessedFile() {
    if (this.isFileUploaded === false) {
      return true;
    }

    if (_.includes(['loaded', 'Parsed all Rows'], this.status)) {
      return true;
    }
    return false;
  }

  @action.bound
  setIsFileUploaded() {
    if (this.inputRef?.files) {
      this.isFileUploaded = !_.isEmpty(this.inputRef.files);
    } else {
      this.isFileUploaded = false;
    }
  }
  @action.bound
  setStatus(val) {
    this.status = val || 'pending';
  }

  @action.bound
  setLoading(arg = !this.isLoading) {
    this.isLoading = !!arg;
  }

  @action.bound
  setSaving(arg = !this.isSaving) {
    this.isSaving = !!arg;
  }

  @action.bound
  setErrorMessage(val) {
    this.errorMessage = val || '';
  }

  @action.bound
  clear() {
    this.dates.clear();
    this.isOpen = false;
    this.file = null;
    if (this.inputRef) this.inputRef.value = '';
    this.listName = '';
    this.isLoading = false;
    this.isSaving = false;
    this.parsedHeaders.clear();
    this.resultHeaders.clear();
    this.newEntries.clear();
    this.saveResults = {
      dupe: [],
      ignored: [],
      incomplete: [],
      invalid: [],
      new: [],
      unassigned: [],
      updated: [],
    };
    this.status = 'pending';
    this.errorMessage = '';
    this.isFileUploaded = false;
  }

  @action.bound
  setup({ storeList, open = this.isOpen }) {
    this.listName = _.get(storeList, 'title', '');
    this.storeList = storeList;

    this.isOpen = !!open;
  }

  @action.bound
  async processFile({ company = this.company, options = this.parseOptions }) {
    runInAction(() => {
      this.company = company;
    });
    const file = await this.setFile();

    return new Promise((resolve, reject) => {
      Papa.parse(
        file,
        _.extend(
          {
            complete: (results) =>
              this.parse({ company, rows: results.data })
                .then(resolve)
                .catch(reject),
          },
          options,
        ),
      );
    });
  }

  @action.bound
  setFile(file = null) {
    if (file) {
      this.file = file;
    } else {
      if (!this.inputRef || _.isEmpty(this.inputRef.files)) {
        dispatch('ui.snackBar.open', 'Please select a file');
        return Promise.reject('Please select a valid file');
      }

      this.file = _.get(this.inputRef, 'files[0]');
    }

    return Promise.resolve(this.file);
  }

  @action.bound
  setRef(ref) {
    this.inputRef = ref;
  }

  @computed
  get allFormats(): string[] {
    return _.reduce(
      this.formats,
      (acc, dateFormat) => {
        const joinedFormats = _.map(this.timeFormats, (tf) =>
          [dateFormat, tf].join(' '),
        );

        acc.push(dateFormat, ...joinedFormats);

        return acc;
      },
      [],
    );
  }

  @computed
  get hasFile() {
    return (
      !!this.file ||
      (this.inputRef && _.get(this.inputRef, 'files.length', 0) > 0)
    );
  }

  setListName(name) {
    return this.set('listName', name);
  }

  @computed
  get newResultCount() {
    return _.get(this.saveResults, 'new', []).length;
  }

  @computed
  get invalidResultCount() {
    return _.get(this.saveResults, 'invalid', []).length;
  }

  @computed
  get dupeResultCount() {
    return _.get(this.saveResults, 'dupe', []).length;
  }

  @computed
  get unassignedResultCount() {
    return _.get(this.saveResults, 'unassigned', []).length;
  }

  @computed
  get incompleteResultCount() {
    return _.reduce(
      _.get(this.saveResults, 'incomplete', []),
      (a, { schedule }) => a + schedule.length,
      0,
    );
  }

  @computed
  get totalShiftCount() {
    return _.reduce(
      this.newEntries,
      (a, { schedule }) => a + schedule.length,
      0,
    );
  }

  @computed
  get savePercentage() {
    return (
      (this.newResultCount +
        this.invalidResultCount +
        this.incompleteResultCount +
        this.unassignedResultCount +
        this.dupeResultCount) /
      this.totalShiftCount
    );
  }

  async parse({
    rows,
    company,
  }: {
    company?: ICompany;
    rows: Array<unknown>;
  }): Promise<void>;
  @action.bound
  async parse() {
    return Promise.reject('Child Class must implement the `parse` function');
  }

  async save(): Promise<void>;
  @action.bound
  async save() {
    return Promise.reject('Child Class must implement the `save` function');
  }

  async getPartnerForName({ displayName, first, last, company }) {
    if (_.isEmpty(displayName) && _.isEmpty(first) && _.isEmpty(last)) {
      this.log.error('Must define some field for searching partner by name');
      return [];
    }

    const res = await dispatch('partners.runQuery', {
      $or: _.compact([
        displayName && {
          displayName: {
            $options: 'i',
            $regex: `.*${displayName}.*`,
          },
        },
        first &&
          last && {
            $and: [
              first && {
                firstName: {
                  $options: 'i',
                  $regex: `.*${first}.*`,
                },
              },
              last && {
                lastName: {
                  $options: 'i',
                  $regex: `.*${last}.*`,
                },
              },
            ],
          },
      ]),
      companies: company.uuid,
    });

    this.log[res.total > 1 ? 'info' : 'silly'](
      'Found %d matching partners',
      res.total,
      {
        data: res.data,
      },
    );

    return {
      partner: res.total === 1 && _.first(res.data),
      partners: res.total > 1 ? res.data : null,
    };
  }

  get(key) {
    this.log.warn('the `get` method is @deprecated. Please use `retrieve`');
    return this.retrieve(key);
  }

  retrieve(key) {
    return this[key];
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action.bound
  set(key, val) {
    if (_.isArrayLikeObject(this[key])) {
      _.each(val, (v) => this[key].push(v));
    } else if (isObservable(this[key])) {
      set(this[key], val);
    } else {
      this[key] = val;
    }
    return Promise.resolve(this[key]);
  }

  sendRejection(message, err) {
    this.log.error(message, err);
    return Promise.reject(message);
  }

  doMapping({ data, mapping }) {
    const newData = _.extend(data, {
      // Extend the updated data in your child class, and then call super.doMapping
      // See the `ui.addressUploader` store for an example of how this can be applied
    });

    // Re-Map source keys to destination keys & nested values
    _(mapping)
      .filter((h) => !!h.mapping)
      .each(
        action((translation) => {
          if (_.get(newData, translation.key)) {
            _.set(newData, translation.mapping, newData[translation.key]);
            delete newData[translation.key];
          }
        }),
      );

    // Transform Values where a transformation is indicated
    _(mapping)
      .filter((h) => _.isFunction(h.transform))
      .each(
        action((translation) => {
          const key = translation.mapping || translation.key;
          if (_.has(newData, key)) {
            _.set(
              newData,
              key,
              translation.transform(_.get(newData, key), key, newData),
            );
          }
        }),
      );

    // Enable a field to set a related field based on a custom `extendFn`
    _(mapping)
      .filter((h) => _.isFunction(h.extendFn))
      .each(
        action((translation) => {
          const key = translation.mapping || translation.key;
          const newProps = translation.extendFn(newData[key], newData);
          _.extend(newData, newProps);
        }),
      );

    return newData;
  }

  // #region Sample CSV Data

  @observable
  includeHowTo = true;

  @action.bound
  toggleHowToNotes(val = !this.includeHowTo) {
    this.includeHowTo = val;
  }

  /**
   * $sampleCSVData
   * @description Reads the `knownFields` of the current uploader instance
   * and constructs sample CSV data for use in a downloader. In order to use
   * this logic, please implement an instance getter on your uploader subclass
   * and use this `$sampleCSVData` as the foundation.
   *
   * @see CustomUserMetricsUploader.js Direct surfacing of functionality
   * @see ShiftUploader.js Buliding on the base functionality with additional data.
   *
   * @description The "private" getter is due to implementation details of mobx.
   * Specifically, computed getters cannot be overridden by child classes, or
   * anywhere in the inheritence chain. See this link for more info:
   * * https://mobx.js.org/refguide/computed-decorator.html
   *
   * @readonly
   * @memberof BaseUploader
   */
  @computed
  get $sampleCSVData() {
    const fields = [];
    const values = [];
    let notes = [];

    _.each(
      this.knownFields,
      ({ key, exampleVal, note, isExampleField = true }) => {
        if (isExampleField) {
          fields.push(key);
          values.push(exampleVal || '');

          if ((note || exampleVal) && this.includeHowTo) {
            notes.push([
              `# ${key}`,
              note || (exampleVal && `EG: ${exampleVal}`),
            ]);
          }
        }
      },
    );

    notes = _.compact([
      this.includeHowTo && [],
      this.includeHowTo &&
        _.fill(
          [
            '# Field Descriptions - Delete before upload',
            ..._.range(_.size(fields) - 1),
          ],
          '___',
          1,
          _.size(fields),
        ),
      ...notes,
    ]);

    return [fields, values, ...notes];
  }
}
