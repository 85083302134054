import type {
  CompanyStatusRecord,
  IAddress,
  IAttribute,
  ICert,
  ICompany,
  IIndustry,
  IPoint,
  IPool,
  IPosition,
  IQual,
  IRole,
  ISearch,
  ITag,
  IWorker,
  MinMaxSearchDef,
  SearchSpecifier,
} from '@shiftsmartinc/shiftsmart-types';
import type { StoreName } from '#/shared/stores';
import type { IStoreFindOpts } from '#/shared/stores/_baseStore';

import { observable, action, set, computed, runInAction, toJS } from 'mobx';
import { dispatch } from 'rfx-core';
import { geocodeByAddress } from 'react-places-autocomplete';
import _ from 'lodash';
import moment from 'moment';
import Promise from 'bluebird';
import { Query } from '@feathersjs/feathers';

import { init as initAddressForm } from '#/shared/forms/address';
import { getChildLogger } from '#/shared/utils/client.logger';

const storeKeys = getStoreKeys();

type SearchCriteriaFilterOptions = {
  hasImage?: boolean;
  isActive?: boolean;
  isPromoted?: boolean;
  isRequired?: boolean;
  isVerified?: boolean;

  loadLinks?: boolean;
  remove?: boolean;
  toggle?: boolean;
} & IStoreFindOpts<IWorker>;

declare type SliderState = {
  /** `false` when the slider is being dragged, `true` when released
   * Controls when the query actually run against the server
   */
  completed?: boolean;
};

const DEFAULT_QUERY_SERVICE = 'userstats';

export default class Searches {
  @observable
  selected: ISearch | IPool = {};

  @observable
  company: Partial<ICompany> = {};

  @computed
  get companyId() {
    return this.company?.uuid || undefined;
  }

  // #region searchCriteria
  /**
   * Search Criteria
   * The following arrays, such as "certs", "roles", etc store the currently selected
   * search critera, mapping to the different search filters. If adding a new search
   * criteria, be sure to add a corresponding `critera.clear()` call in the `clear()`
   * method for this class. Otherwise, we see issues such as EP-941 where certain criteria
   * are "sticky" across pool creation.
   */

  industries = observable.array<IIndustry['uuid']>([]);

  roles = observable.array<IRole['uuid']>([]);

  positions = observable.array<IPosition['uuid']>([]);

  certs = observable.array<ICert['uuid']>([]);

  skills = observable.array<ITag['uuid']>([]);

  employers = observable.array<ITag['uuid']>([]);

  quals = observable.array<IQual['uuid']>([]);

  attributes = observable.array<IAttribute['uuid']>([]);

  terms = observable.array<string>([]);

  countries = observable.array<string>([]);

  states = observable.array<string>([]);

  exclude = observable.array<IWorker['uuid']>([]);

  include = observable.array<IWorker['uuid']>([]);

  /**
   * @typedef {Object} MinMaxSliderValue
   * @prop {number} min: The lower bounds of the selected Slider Value
   * @prop {number} max: The upper bounds of the selected Slider Value
   * @prop {boolean} active - Is the search "active", either expanded, or with values offset from the min/max range
   * @prop {boolean} complete - Whether the slider is finished moving
   *
   * @see {customFields} for default min/max values for custom fields
   */

  @observable
  adherence = { active: false, max: 1, min: 0 };

  @observable
  completionScore = { active: false, max: 1, min: 0 };

  @observable
  rating = { active: false, max: 5, min: 0 };

  @observable
  /**
   * @type {MinMaxSliderValue}
   */
  createdAt = { active: false, max: null, min: null };

  /**
   * Hash of Custom Metric Search field Values
   * @memberof Searches
   *
   * @type {Object<string, MinMaxSliderValue>}
   */
  @observable
  customMetrics = {};

  /**
   * Hash of configuration options for Custom Metric Search Fields
   *
   * @memberof Searches
   *
   * @typedef {{ range: { min: number, max: number }}} CustomFieldConfig
   *
   * @type {Object<string, CustomFieldConfig>}
   */
  @observable customFieldDefs = {};

  @observable
  employmentStatus = {
    active: false,
    path: null,
    query: null,
    status: [],
  };

  @observable
  restrictions = {
    roles: [],
  };

  @observable
  addressForm = null;

  @observable
  location = {
    active: false,
    address: {},
    coordinates: [],
    formattedAddress: '',
    radius: 20,
    type: 'Point',
    zipCode: '',
  };

  @observable
  isFullAddress = false;

  distanceOpts = [
    {
      text: '10 mi',
      value: 10,
    },
    {
      text: '20 mi',
      value: 20,
    },
    {
      text: '50 mi',
      value: 50,
    },
    {
      text: '100 mi',
      value: 100,
    },
  ];

  @observable
  isCustomRadius = false;

  @observable
  radiusOptions = _.clone(this.distanceOpts);

  // #endregion searchCriteria

  @observable
  noSearch = true;

  @observable
  isDirty = false;

  @observable
  workerSource = 'workers';

  @observable
  queryService: 'userstats' | 'users' = DEFAULT_QUERY_SERVICE;

  @observable
  isLoading = {
    adherence: false,
    attributes: false,
    certs: false,
    completionScore: false,
    countries: false,
    createdAt: false,
    employers: false,
    employmentStatus: false,
    industries: false,
    location: false,
    positions: false,
    quals: false,
    rating: false,
    roles: false,
    save: false,
    search: false,
    setup: false,
    skills: false,

    states: false,
  };

  @observable
  isAdding = {
    adherence: false,
    attributes: false,
    certs: false,
    completionScore: false,
    countries: false,
    createdAt: false,
    employers: false,
    employmentStatus: false,
    industries: false,
    location: false,
    positions: false,
    quals: false,
    rating: false,
    roles: false,
    search: false,
    setup: false,
    skills: false,
    states: false,
  };

  @observable
  additionalStores = [];

  _log;

  @computed
  get log() {
    if (_.isEmpty(this._log) || !_.isFunction(this._log.debug)) {
      this._log = getChildLogger('ui.searches');
    }

    return this._log;
  }

  @action
  async setup({
    company = dispatch('auth.getCompany'),
    search = {},
    workerSource = this.workerSource,
    loadWorkers = true,
    forceLoadWorkers = false,
  }: {
    company?: ICompany;
    forceLoadWorkers?: boolean;
    loadWorkers?: boolean;
    search?: Partial<ISearch> | string;
    workerSource?: string;
  } = {}) {
    this.clearSearchCriteria();
    this.setLoading('setup');
    this.setDirty(false);
    this.workerSource = workerSource;

    if (loadWorkers) {
      this.log.debug(`Filtering ${this.workerSource}`);
      // TODO: Shouldn't we be preserving `companyStatus` instead?
      dispatch(`${this.workerSource}.resetSearch`, { preserve: ['companies'] });
    }

    this.log.debug(`Filtering ${this.workerSource}`);

    await this.loadSearch({ company, loadWorkers: false, search });

    if (forceLoadWorkers || (this.hasQuery && loadWorkers)) {
      dispatch(`${this.workerSource}.find`, {
        $client: {
          poolId: search?.uuid,
          queryService: this.queryService,
        },
      });
    }

    this.doneLoading('setup');
  }

  get label(): string {
    return this.selected.label;
  }

  set label(value: string) {
    this.setLabel({ value });
  }

  @action
  setSelected(searchObj) {
    this.selected = searchObj;
  }

  @action
  setLabel({
    value,
    opts,
  }: {
    opts?: { onlyIfEmpty?: boolean };
    value?: string;
  }) {
    if (opts.onlyIfEmpty) {
      this.selected.label = this.selected.label || value;
    } else {
      this.selected.label = value;
    }
  }

  get search(): SearchSpecifier {
    return _.get(this.selected, 'search', {});
  }

  @action
  setNoSearch(val) {
    this.noSearch = !!val;
  }

  @action
  setCustomRadius(val = !this.isCustomRadius) {
    this.isCustomRadius = val;
  }

  get hasQuery() {
    return !this.noSearch;
  }

  @computed
  get hasDirtyFields() {
    const retval = !_.isEmpty(_.pickBy(this.dirtyFields, (v) => !!v));

    if (retval !== this.isDirty) {
      this.log.debug('hasDirtyFields !== isDirty', {
        hasDirtyFields: retval,
        isDirty: this.isDirty,
      });
    }

    return retval;
  }

  @computed
  get dirtyFields() {
    const { search } = this.saveableSearchData;

    const srcKeys = _.keys(this.search);
    const keys = _.keys(search);

    const status = _([...srcKeys, ...keys])
      .uniq()
      .reduce((acc, key) => {
        const src = this.search[key];
        const dst = search[key];

        // const customEqualityComparitor = (a, b) => {
        //   if (_.isArray(a) || _.isArray(b)) {
        //     const diff = _.difference(a || [], b || []);
        //     return !_.isEmpty(diff);
        //   }

        //   return undefined;
        // };

        acc[key] = !_.isEqual(
          src || (_.isArray(dst) ? [] : null),
          dst || (_.isArray(src) ? [] : null),
        );
        return acc;
      }, {});

    this.log.debug('DirtyFields: ', status);

    return status;
  }

  @action
  async setWorkerSource(workerSource: Searches['workerSource']) {
    this.workerSource = workerSource;
  }

  /** Switches the currently active pools query service. If not provided, the value will toggle */
  @action
  setPoolsQuerySource(val?: Searches['queryService'], isInit?: boolean) {
    const isChanged = val !== this.queryService;

    this.queryService =
      val ?? this.queryService === 'users' ? 'userstats' : 'users';

    if (isChanged && !isInit) {
      dispatch(`${this.workerSource}.find`, {
        $client: {
          poolId: this.selected?.uuid,
          queryService: this.queryService,
        },
      });
    }
  }

  @computed
  get activeQueries() {
    const {
      company,
      industries,
      roles,
      positions,
      certs,
      skills,
      employers,
      quals,
      attributes,
      terms,
      adherence,
      rating,
      createdAt,
      location,
      employmentStatus,
      customMetrics,
      countries,
      states,
    } = this;

    const retval = _.pickBy(
      {
        adherence,
        attributes,
        certs,
        company,
        countries,
        createdAt,
        employers,
        employmentStatus,
        industries,
        location,
        positions,
        quals,
        rating,
        roles,
        skills,
        states,
        terms,

        ..._.mapKeys(customMetrics, (val, key) => `customMetrics.${key}`),
      },
      (prop) => !!prop && (_.isArray(prop) ? !_.isEmpty(prop) : prop.active),
    );

    return retval;
  }

  @computed
  get hasActiveQuery(): boolean {
    return !_.isEmpty(this.activeQueries);
  }

  @computed get hasQueryFor(): Record<string, boolean> {
    const x = [
      'company',
      'industries',
      'roles',
      'positions',
      'certs',
      'skills',
      'employers',
      'quals',
      'attributes',
      'terms',
      'adherence',
      'rating',
      'createdAt',
      'location',
      'employmentStatus',
      'countries',
      'states',
      ..._.map(this.customMetrics, (val, key) => `customMetrics.${key}`),
    ];

    const retval = _.reduce(
      x,
      (acc, prop) => {
        const propSearchVal = _.get(this, prop);
        acc[prop] =
          !!propSearchVal &&
          (_.isArray(propSearchVal)
            ? !_.isEmpty(propSearchVal)
            : propSearchVal.active);
        return acc;
      },
      {},
    );

    return retval;
  }

  @action
  setDirty(val = true) {
    this.isDirty = val;
  }

  @action
  async loadSearch({
    search,
    company = search ? search.company : undefined,
    loadWorkers = true,
  }) {
    if (_.isEmpty(search)) {
      return null;
    }

    this.clear();

    this.setLoading('search');

    const searchObj = await dispatch('searches.setSelected', search);

    if (loadWorkers) {
      this.log.debug(`Filtering ${this.workerSource}`);
      dispatch(`${this.workerSource}.resetSearch`, {
        // TODO: Shouldn't we be preserving companyStatus insetad
        preserve: ['companies'],
      });
    }

    if (!_.isEmpty(company)) {
      this.setCompany(company, { noQuery: true });
    }

    await this.initSearch({ search: searchObj });
    await this.initCustomSearch({ search: searchObj });

    // All filters should be set (and no worker query should have fired)
    // Call `workers.find` to fire the search here
    this.setDirty(false);
    if (loadWorkers) {
      this.log.debug(`Filtering ${this.workerSource}`);
      dispatch(`${this.workerSource}.find`, {
        companies: {
          // TODO: What is this trying to do? Original code: `$ne: _.get(this.company, 'uuid', this.company)`
          $ne: this.companyId,
        },
      });
    }

    this.doneLoading('search');

    return searchObj;
  }

  async initCustomSearch({ search }: { search: ISearch }) {
    const searchObj =
      (_.size(this.selected) && this.selected) ||
      (_.isString(search)
        ? await dispatch('searches.get', search, { select: false })
        : search);

    if (this.selected?.uuid !== searchObj?.uuid) {
      this.setSelected(searchObj);
    }

    const customSearchOptions = _.filter(
      this.company?.customMetrics ?? [],
      'enablePools',
    );

    const ps = [];

    if (!_.isEmpty(customSearchOptions)) {
      _.map(customSearchOptions, (customSearchOption) => {
        const {
          key,
          dataType,
          multiplier = /percent/i.test(dataType) ? 100 : 1,
          searchOptions,
        } = customSearchOption;
        const customKey = `customMetrics.${key}`;

        const fieldMinValue = searchOptions?.range?.min ?? 0;
        const fieldMaxValue =
          searchOptions?.range?.max ?? (/percent/i.test(dataType) ? 1.0 : 100);

        const customSearch = {
          complete: true,
          max: _.get(
            this.search,
            `${customKey}.max`,
            fieldMaxValue / multiplier,
          ),
          min: _.get(
            this.search,
            `${customKey}.min`,
            fieldMinValue / multiplier,
          ),
        };

        runInAction(() => {
          set(this.customMetrics, key, customSearch);
          set(this.customFieldDefs, key, {
            ...customSearchOption,
            dataType,
            multiplier,
            range: {
              max: fieldMaxValue,
              min: fieldMinValue,
            },
          });
        });

        ps.push(
          this.setCustomSearch(key, customSearch, {
            noQuery: true,
          }),
        );
      });
    }

    await Promise.all(ps);
  }

  async initSearch({ search }: { search: ISearch }) {
    // Search Object: AKA "The Pool"
    const searchObj = _.isString(search)
      ? await dispatch('searches.get', search, { select: false })
      : search;

    this.setSelected(searchObj);

    this.setPoolsQuerySource(
      search.queryService ?? DEFAULT_QUERY_SERVICE,
      true,
    );

    const tags = this.search.tags;
    const optionalTags = this.search.optionalTags;

    const [tagObjs, optionalTagObjs] = await Promise.all([
      Promise.all(
        _.map(tags, (tag) =>
          dispatch('tags.get', tag).catch((err) =>
            this.log.error(`Failed to load tag: ${tag}`, err),
          ),
        ),
      ),
      Promise.all(
        _.map(optionalTags, (tag) =>
          dispatch('tags.get', tag).catch((err) =>
            this.log.error(`Failed to load tag: ${tag}`, err),
          ),
        ),
      ),
    ]);

    const [processedTags, processedOptionalTags] = await Promise.all([
      Promise.all(
        _.map(tagObjs, (tagObj) =>
          this.processTag(tagObj, { isRequired: true, loadLinks: false }),
        ),
      ),
      Promise.all(
        _.map(optionalTagObjs, (tagObj) =>
          this.processTag(tagObj, { isRequired: false, loadLinks: false }),
        ),
      ),
    ]);

    this.log.debug('Tag Processing Results: ', {
      processedOptionalTags,
      processedTags,
    });
    const ps = [];

    if (!_.isEmpty(this.search.rating)) {
      const rating: SearchSpecifier['rating'] & SliderState = {};
      rating.min = _.get(this.search.rating, 'min', 0);
      rating.max = _.get(this.search.rating, 'max', 5);
      rating.complete = true;
      ps.push(this.setRating(rating, { noQuery: true }));
      this.setNoSearch(false);
    }

    if (!_.isEmpty(this.search.createdAt)) {
      const createdAt: SearchSpecifier['createdAt'] & SliderState = {};
      createdAt.min = _.get(this.search.createdAt, 'min', 0);
      createdAt.max = _.get(this.search.createdAt, 'max', 5);
      createdAt.complete = true;
      ps.push(this.createdAt(createdAt, { noQuery: true }));
      this.setNoSearch(false);
    }

    if (!_.isEmpty(this.search.adherence)) {
      const adherence: SearchSpecifier['adherence'] & SliderState = {};
      adherence.min = _.get(this.search.adherence, 'min', 0.0);
      adherence.max = _.get(this.search.adherence, 'max', 1.0);
      adherence.complete = true;
      ps.push(this.setAdherence(adherence, { noQuery: true }));
      this.setNoSearch(false);
    }

    if (!_.isEmpty(this.search.completionScore)) {
      const completionScore: SearchSpecifier['completionScore'] & SliderState =
        {};
      completionScore.min = _.get(this.search.completionScore, 'min', 0.0);
      completionScore.max = _.get(this.search.completionScore, 'max', 1.0);
      completionScore.complete = true;
      ps.push(this.setCompletionScore(completionScore, { noQuery: true }));
      this.setNoSearch(false);
    }

    if (!_.isEmpty(_.get(this.search, 'loc.coordinates'))) {
      const loc = {
        ...this.search.loc,
        lat: this.search.loc.coordinates[1],
        lng: this.search.loc.coordinates[0],
        zipCode: this.search.loc.zipCode,
      };

      ps.push(this.setLoc(loc, { noQuery: true }));
      ps.push(this.setRadius(this.search.loc.radius, { noQuery: true }));
      this.setNoSearch(false);
    }

    if (!_.isEmpty(this.search.employment)) {
      ps.push(
        this.setEmploymentStatus(this.search.employment, { noQuery: true }),
      );
      this.setNoSearch(false);
    }

    if (!_.isEmpty(this.search.exclude)) {
      ps.push(
        this.setExcluded({ users: this.search.exclude }, { noQuery: true }),
      );
    }
    if (!_.isEmpty(this.search.countries)) {
      ps.push(
        this.setCountries(
          { countries: this.search.countries },
          { noQuery: true },
        ),
      );
      this.setNoSearch(false);
    }
    if (!_.isEmpty(this.search.states)) {
      ps.push(this.setStates(this.search.states, { noQuery: true }));
      this.setNoSearch(false);
    }

    const retval = await Promise.all(ps);

    runInAction(() => {
      this.isFullAddress = !!this.location.formattedAddress;
    });
    if (this.isFullAddress) {
      this.toggleAddress({ show: true });
    }

    return retval;
  }

  /** TODO: This should not be invoked directly, but instead should go through the
   * `ui.searches.set` method like all other invocations
   */
  @action
  private async setCountries(
    { country, countries }: { countries?: string[]; country?: string } = {},
    {
      reset,
      remove,
      ...opts
    }: { remove?: boolean; reset?: boolean } & SearchCriteriaFilterOptions = {},
  ) {
    try {
      let searchCountries = [...this.countries];

      if (reset) {
        searchCountries = countries || _.compact([country]);
      } else if (remove) {
        const removedCountries = countries || _.compact([country]);
        searchCountries = _.difference(searchCountries, removedCountries);
      } else {
        if (country && !_.includes(this.countries, country)) {
          this.setDirty(true);
          searchCountries.push(country);
        }
        const absentCountries =
          countries && _.difference(countries, this.countries);
        if (absentCountries && _.size(absentCountries)) {
          this.setDirty(true);
          searchCountries.push(...absentCountries);
        }
      }

      this.countries.replace(searchCountries);

      this.setLoading('countries');

      const countriesQuery: Query = {};

      if (!_.isEmpty(searchCountries)) {
        countriesQuery.$in = searchCountries;
      }

      this.log.debug(`Filtering ${this.workerSource} by country`);
      const res = await dispatch(
        `${this.workerSource}.find`,
        {
          'localeInfo.country': _.isEmpty(countriesQuery)
            ? undefined
            : countriesQuery,
        },
        opts,
      );

      this.log.debug(
        `Found %d+ Workers from source: ${this.workerSource}`,
        _.size(res),
        res,
      );
    } catch (err) {
      this.log.error('Error setting countries query', err);
    }

    this.doneLoading('countries');

    return this.countries;
  }

  @action
  private async setStates(
    states: string[] = [],
    {
      reset,
      remove,
      ...opts
    }: { remove?: boolean; reset?: boolean } & SearchCriteriaFilterOptions = {},
  ) {
    this.setLoading('states');

    try {
      let selectedStates = [...this.states];

      if (reset) {
        selectedStates = _.compact(states);
      } else if (remove) {
        selectedStates = _.difference(selectedStates, states);
      } else {
        // case select states
        const statesToAdd = _.difference(states || [], this.states);
        if (_.size(statesToAdd)) {
          this.setDirty(true);
          selectedStates.push(...statesToAdd);
        }
      }

      this.states.replace(selectedStates);
      this.log.debug(`Filtering ${this.workerSource} by state`);

      const res = await dispatch(
        `${this.workerSource}.find`,
        {
          'address.state': _.isEmpty(selectedStates)
            ? undefined
            : {
                $in: selectedStates,
              },
        },
        opts,
      );

      this.log.debug(
        `Found %d+ Workers from source: ${this.workerSource}`,
        _.size(res),
        res,
      );
    } catch (err) {
      this.log.error('Error setting states filter', err);
    }

    this.doneLoading('states');

    return this.states;
  }

  processTag(
    tag,
    { isRequired = false, loadLinks = true }: SearchCriteriaFilterOptions = {},
  ) {
    if (!tag) {
      this.log.warn('Tag is not defined');
      return false;
    }
    try {
      return dispatch('ui.searches.set', tag.certType, tag, {
        isRequired,
        loadLinks,
        noQuery: true,
      });
    } catch (err) {
      const msg = `Failed to process tag with type  "${tag.certType}: ${err.message}"`;
      this.log.error(msg, tag, err);
      return Promise.reject(msg);
    }
  }

  @action
  private getMethodAndName(key: StoreName, certType?: ITag['certType']) {
    let store = getStoreName(certType || key);
    if (_.isUndefined(this[store]) && !_.isUndefined(this[`${store}s`])) {
      store += 's';
    }

    let methodName;

    let method = _.noop;
    if (/^customMetrics/i.test(store)) {
      const [, customMetricKey] = store.split('.');

      method = (value, opts) =>
        this.setCustomSearch(customMetricKey, value, opts);
    } else {
      methodName = `set${store.charAt(0).toUpperCase() + store.slice(1)}`;
      method = (() => {
        const m = this[`${methodName}s`];
        if (m) {
          methodName += 's';
          return m;
        }
        return this[methodName];
      })();
    }
    return { method, methodName, store };
  }

  get(val, fallback) {
    return _.get(this, val, fallback);
  }

  // Implemented as functions in support of `dispatch` pseudo-getter
  allTags() {
    const all = _.unionBy(
      [
        this.roles,
        this.positions,
        this.certs,
        this.skills,
        this.employers,
        this.quals,
        this.attributes,
        this.terms,
      ],
      'uuid',
    );

    const reqd = _(all).filter('isRequired').map('uuid').value();

    return reqd;
  }

  // Implemented as functions in support of `dispatch` pseudo-getter
  allOptionalTags() {
    const all = _.unionBy(
      [
        this.roles,
        this.positions,
        this.certs,
        this.skills,
        this.employers,
        this.quals,
        this.attributes,
        this.terms,
      ],
      'uuid',
    );

    const reqd = _(all).reject('isRequired').map('uuid').value();

    return reqd;
  }

  @action
  set(key, value, opts = {}) {
    const { method, methodName } = this.getMethodAndName(key);

    if (_.isFunction(method) && methodName) {
      return dispatch(`ui.searches.${methodName}`, value, opts);
    }
    if (_.isFunction(method)) {
      return method(value, opts);
    }

    try {
      return this.setGeneric(value, { storeName: key });
    } catch (err) {
      this.log.error('Unable to set generic value', err);
    }

    if (/source/i.test(key)) {
      return Promise.resolve({});
    }

    this.log.error(`Unable to load method for "${methodName}"`, {
      key,
      methodName,
    });
    return Promise.reject(`unknown method on ui.searches: "${methodName}"`);
  }

  @action
  setAdding({ storeName, keyName, adding = true }) {
    const key = storeName || keyName;
    if (!_.has(this.isAdding, key)) {
      set(this.isAdding, { [key]: adding });
    } else {
      this.isAdding[key] = adding;
    }

    this.log.debug(`Set adding for ${key} to ${this.isAdding[key]}`);

    if (adding && storeName) {
      dispatch(`${storeName}.search`, '');
    }
  }

  @action
  toggle({ key, uuid, certType }) {
    if (
      /^(rating|adherence|completionScore|createdAt|employmentStatus)$/.test(
        key,
      )
    ) {
      this[key].active = !this[key].active;
      if (!this[key].active) {
        dispatch(
          `ui.searches.set${key.charAt(0).toUpperCase() + key.slice(1)}`,
          { complete: true },
        );
      }

      this.setDirty(true);
      return Promise.resolve(this[key]);
    }

    if (/^(isRequired|hasImage|isActive|isVerified|isPromoted)$/.test(key)) {
      const { store, method, methodName } = this.getMethodAndName(certType);

      if (_.isUndefined(this[store])) {
        this.log.warn('Unknown Local Store: ', store);
        return Promise.reject(
          `Unknown Local Store for Cert type "${certType}"`,
        );
      }
      const cert = _.find(this[store], { uuid });

      if (cert) {
        if (_.isFunction(method)) {
          const opts: SearchCriteriaFilterOptions = { toggle: true };

          opts.isRequired = cert.isRequired;
          opts.isActive = cert.isActive;
          opts.hasImage = cert.hasImage;
          opts.isVerified = cert.isVerified;
          opts.isPromoted = cert.isPromoted;

          switch (key) {
            case 'isRequired':
              opts.isRequired = !cert.isRequired;
              break;
            case 'isActive':
              opts.isActive = !cert.isActive;
              break;
            case 'hasImage':
              opts.hasImage = !cert.hasImage;
              break;
            case 'isVerified':
              opts.isVerified = !cert.isVerified;
              break;
            case 'isPromoted':
              opts.isPromoted = !cert.isPromoted;
              break;
            default:
              break;
          }

          this.setDirty(true);
          return dispatch(`ui.searches.${methodName}`, cert, opts);
        }

        this.log.error(`Unknown Toggle Method for "${methodName}"`);
      }
    }

    if (/^(location)$/.test(key)) {
      this[key].active = !this[key].active;

      if (!this[key].active) {
        this.setLoc({
          active: false,
          address: {},
          formattedAddress: '',
          lat: null,
          lng: null,
          zipCode: '',
        });
        this.toggleAddress({ show: false });
        this.clearAddressForm();
      }

      this.setDirty(true);
      return Promise.resolve(this[key]);
    }

    return Promise.resolve();
  }

  clearFilter({
    tag,
    uuid,
    certType,
  }: {
    certType: ITag['certType'];
    tag: ITag;
    uuid: ITag['uuid'];
  }) {
    const getTag = tag?.uuid
      ? Promise.resolve(tag)
      : dispatch('tags.get', uuid);
    getTag.then((tagObj) => {
      const { method, methodName, store } = this.getMethodAndName(
        tagObj.certType,
      );

      if (_.isFunction(method)) {
        this.setDirty(true);
        return dispatch(`ui.searches.${methodName}`, tagObj, { remove: true });
      }

      try {
        return this.setGeneric(tagObj, { remove: true, storeName: store });
      } catch (err) {
        this.log.error('Unable to set generic value', err);
      }

      this.log.warn(`Unknown Filter Method for "${methodName}"`);
      return Promise.reject(`Unknown tag type ${certType}`);
    });
  }

  setIndustries(
    industry: IIndustry | IIndustry['uuid'],
    opts: SearchCriteriaFilterOptions = {},
  ) {
    if (_.isEmpty(industry)) {
      return Promise.resolve();
    }
    this.setLoading('industries');

    delete opts.isRequired; // eslint-disable-line no-param-reassign

    return this.setTagFilter({
      collection: this.industries,
      opts,
      storeName: 'industries',
      tag: industry,
    })
      .then((i) => {
        if (opts.remove) {
          const remainingIndustries = _.map(this.industries, 'uuid');
          return this.findLinkedRoles(remainingIndustries);
        }

        if (opts.loadLinks) {
          return this.findLinkedRoles(i).then((ids) => {
            this.log.debug('Setting Cert filter to these IDs: ', ids);
            this.log.debug(`Filtering ${this.workerSource}`);
            dispatch(`${this.workerSource}.setCertFilter`, {
              ids,
              isSoftFilter: true,
              union: true,
            });
          });
        }

        this.restrictTags({ restrictBy: ['industries'], storeName: 'roles' });

        return null;
      })
      .then(() => this.setNoSearch(false))
      .catch((err) => this.log.error('Error Setting Industry', err))
      .then(() => this.industries)
      .finally(() => this.doneLoading('industries'));
  }

  @action
  restrictTags({
    storeName,
    restrictBy = [],
    clear,
  }: {
    clear?: boolean;
    restrictBy?: Array<ITag['uuid']>;
    storeName: StoreName;
  }) {
    let query;

    if (clear) {
      this.restrictions[storeName] = [];
      query = {
        'links.uuid': undefined,
      };
    } else {
      const uuids = _.reduce(
        restrictBy,
        (acc, restriction) => _.union(acc, _.map(this[restriction], 'uuid')),
        [],
      );

      this.restrictions[storeName] = uuids;
      query = {
        'links.uuid': { $in: uuids },
      };
    }

    dispatch(`${storeName}.find`, {
      query,
    });
  }

  @action
  async setCompany(
    company: Partial<ICompany>,
    opts: SearchCriteriaFilterOptions,
  ) {
    if (_.isEmpty(company) || _.isString(company)) {
      this.log.warn('[setCompany] invalid company  value', company);
      return null;
    }

    const companyObj = company?.uuid
      ? company
      : await dispatch('companies.get', company, { select: false });
    runInAction(() => {
      this.company = companyObj;
    });

    let companyIds = [this.companyId];

    try {
      dispatch(`${this.workerSource}.setEmploymentStatusFilter`, ['employee'], {
        noQuery: true,
      });
      const partnerFindPromise = dispatch(
        `${this.workerSource}.findByCompany`,
        {
          company,
          opts,
        },
      );

      companyIds = _(companyIds).uniq().compact().value();

      dispatch('quals.find', {
        opts,
        query: { companies: { $in: companyIds } },
      });

      dispatch('positions.find', {
        opts,
        query: { companies: { $in: companyIds } },
      });

      const res = await partnerFindPromise;

      this.log.debug(
        `Found %d+ Workers from source: ${this.workerSource}`,
        _.size(res),
        res,
      );
      this.setNoSearch(false);
    } catch (err) {
      this.log.error('Error setting company query', err);
    } finally {
      this.doneLoading('company');
    }

    return this.company;
  }

  @action
  setRoles(role, opts = {}) {
    if (_.isEmpty(role)) {
      return Promise.resolve();
    }
    this.setLoading('roles');

    return this.setTagFilter({
      collection: this.roles,
      opts,
      storeName: 'roles',
      tag: role,
    })
      .catch((err) => this.log.error('Error Setting Role', err))
      .then(() => this.roles)
      .finally(() => this.doneLoading('roles'));
  }

  @action
  setCerts(cert, opts = {}) {
    if (_.isEmpty(cert)) {
      return Promise.resolve();
    }
    this.setLoading('certs');

    return this.setTagFilter({
      collection: this.certs,
      opts,
      storeName: 'certs',
      tag: cert,
    })
      .catch((err) => this.log.error('Error Setting Cert', err))
      .then(() => this.certs)
      .finally(() => this.doneLoading('certs'));
  }

  @action
  setSkills(skill, opts = {}) {
    if (_.isEmpty(skill)) {
      return Promise.resolve();
    }
    this.setLoading('skills');

    return this.setTagFilter({
      collection: this.skills,
      opts,
      storeName: 'skills',
      tag: skill,
    })
      .catch((err) => this.log.error('Error Setting Cert', err))
      .then(() => this.skills)
      .finally(() => this.doneLoading('skills'));
  }

  @action
  setQuals(quals, opts = {}) {
    if (_.isEmpty(quals)) {
      return Promise.resolve();
    }
    this.setLoading('quals');

    return this.setTagFilter({
      collection: this.quals,
      opts,
      storeName: 'quals',
      tag: quals,
    })
      .catch((err) => this.log.error('Error Setting Qualifications', err))
      .then(() => this.quals)
      .finally(() => this.doneLoading('quals'));
  }

  @action
  setAttributes(attributes, opts = {}) {
    if (_.isEmpty(attributes)) {
      return Promise.resolve();
    }
    this.setLoading('attributes');

    return this.setTagFilter({
      collection: this.attributes,
      opts,
      storeName: 'attributes',
      tag: attributes,
    })
      .catch((err) => this.log.error('Error Setting Attributes', err))
      .then(() => this.attributes)
      .finally(() => this.doneLoading('attributes'));
  }

  @action
  setPositions(position, opts = {}) {
    if (_.isEmpty(position)) {
      return Promise.resolve();
    }
    this.setLoading('positions');

    return this.setTagFilter({
      collection: this.positions,
      opts,
      storeName: 'positions',
      tag: position,
    })
      .catch((err) => this.log.error('Error Setting Qualifications', err))
      .then(() => this.positions)
      .finally(() => this.doneLoading('positions'));
  }

  @action
  setGeneric(
    tag,
    options: SearchCriteriaFilterOptions & { storeName: StoreName },
  ) {
    const { storeName, ...opts } = options ?? {};
    if (_.isEmpty(storeName)) {
      return Promise.reject('Must specify a store name to add tags');
    }
    if (_.isEmpty(tag)) {
      return Promise.resolve();
    }
    this.setLoading(storeName);

    if (!this[storeName]) {
      this[storeName] = observable([]);

      this.additionalStores = this.additionalStores || [];
      this.additionalStores.push(storeName);
    }

    return this.setTagFilter({
      collection: this[storeName],
      opts,
      storeName,
      tag,
    })
      .catch((err) =>
        this.log.error(
          `Error Setting ${
            storeName.charAt(0).toUpperCase() + storeName.slice(1)
          }`,
          err,
        ),
      )
      .then(() => this.quals)
      .finally(() => this.doneLoading(storeName));
  }

  @action
  setEmployers(employer, opts = {}) {
    if (_.isEmpty(employer)) {
      return Promise.resolve();
    }
    this.setLoading('employers');

    return this.setTagFilter({
      collection: this.employers,
      opts,
      storeName: 'employers',
      tag: employer,
    })
      .catch((err) => this.log.error('Error Setting Employer', err))
      .then(() => this.employers)
      .finally(() => this.doneLoading('employers'));
  }

  @action
  setTagFilter({
    tag,
    collection,
    storeName,
    opts = {},
  }: {
    collection: Array<ITag>;
    opts: SearchCriteriaFilterOptions;
    storeName: StoreName;
    tag: ITag | ITag['uuid'];
  }) {
    return (
      _.has(tag, 'uuid')
        ? Promise.resolve(tag)
        : dispatch(`${storeName}.get`, tag)
    )
      .then(
        action((tagObj) => {
          let currentTagFilterObject: ITag = _.find(collection, {
            uuid: tagObj.uuid,
          });
          if (opts.remove) {
            if (currentTagFilterObject) {
              const removedFilterObjects = _.remove(collection, {
                uuid: tagObj.uuid,
              });
              console.assert(!_.find(collection, { uuid: tagObj.uuid }));

              if (_.isArray(removedFilterObjects)) {
                currentTagFilterObject = _.first(removedFilterObjects);
              }
            }
          } else if (!currentTagFilterObject) {
            currentTagFilterObject = _.extend(
              { isRequired: !!opts.isRequired },
              tagObj,
            );
            this.log.debug(
              `Adding "${currentTagFilterObject.title}" to ${storeName} collection`,
            );
            collection.push(currentTagFilterObject);
          }

          _.each(
            ['isRequired', 'isActive', 'hasImage', 'isVerified', 'isPromoted'],
            (prop) => {
              if (
                _.has(opts, prop) &&
                opts[prop] !== currentTagFilterObject[prop]
              ) {
                currentTagFilterObject[prop] = opts[prop];
              }
            },
          );

          return currentTagFilterObject;
        }),
      )
      .then(async (tagObj) => {
        this.setDirty(true);
        const certFilterOpts = {
          ids: tagObj.uuid,
          isSoftFilter: !opts.isRequired,
          op: 'union',
          opts: _.extend({ noQuery: false }, opts),
          toggle: !!opts.toggle,
        };
        if (_.has(opts, 'isRequired')) {
          certFilterOpts.isSoftFilter = !opts.isRequired;
        }
        if (opts.remove) {
          if (_.isEmpty(tagObj)) {
            certFilterOpts.opts.clear = true;
            certFilterOpts.opts.preserve = ['companies'];
          } else {
            certFilterOpts.op = 'remove';
          }
        } else {
          // Don't run the query if we're adding a filter
          // it will be run after the 'then'...
          certFilterOpts.opts.noQuery = true;
        }

        this.log.debug(`Filtering ${this.workerSource}`);
        const ids = await dispatch(
          `${this.workerSource}.setCertFilter`,
          certFilterOpts,
        ).then(() =>
          !opts.remove && opts.loadLinks
            ? this.getLinkedItemsUUIDs(tagObj)
            : [],
        );

        return { ids, tagObj };
      })
      .then(async ({ tagObj, ids }) => {
        this.log.debug(`Filtering ${this.workerSource}`);
        await dispatch(`${this.workerSource}.setCertFilter`, {
          ids,
          isSoftFilter: true,
          opts,
        });

        this.setNoSearch(false);
        return tagObj;
      });
  }

  @action
  toggleAddress({ show = !this.isFullAddress } = {}) {
    if (show) {
      const values = {
        zip: this.location?.zipCode,
        ...this.location,
      };

      this.addressForm = initAddressForm(values);
    }

    this.isFullAddress = show;
  }

  @action
  clearAddressForm() {
    _.result(this.addressForm, 'clear');
    this.addressForm = null;
  }

  @observable
  radiusRef = null;

  @action
  setRadiusRef(ref) {
    this.radiusRef = this.radiusRef || ref;
  }

  @action
  setRadius(radius, opts = {}) {
    if (_.get(this.location, 'radius') !== radius) {
      this.setDirty(true);
    }
    set(this.location, { radius });

    const custom = _.find(this.radiusOptions, { isCustom: true });

    if (!custom) {
      this.radiusOptions.push({
        isCustom: true,
        text: `${radius} miles`,
        value: radius,
      });
    } else {
      _.extend(custom, {
        text: `${radius} miles`,
        value: radius,
      });
    }

    this.setLoc(
      {
        lat: this.location.lat,
        lng: this.location.lng,
        zipCode: this.location.zipCode,
      },
      opts,
    );
  }

  /**
   * setLoc
   * @description Sets the location filter value. This function is extracted from this.setLocation
   * in order to share logic between the different methods of changing the filter criteria.
   *
   * @see setLocation
   * @see setRadius
   *
   * @param {*} param0
   * @param {Number} param0.lat
   * @param {Number} param0.lng
   * @param {String} param0.zipCode
   * @param {Boolean} param0.active
   * @param {any} opts Options passed thru to the `store.find` method as the second argument
   * @param {Boolean} resetZipCodeLocation
   *
   * @todo Check behavior when specifying exact address.
   */
  @action
  setLoc(loc: SearchSpecifier['loc'], options) {
    const {
      lat,
      lng,
      zipCode,
      active = true,
      address,
      formattedAddress,
    } = loc ?? {};

    const { resetZipCodeLocation = false, ...opts } = options;

    this.setLoading('location');
    set(
      this.location,
      _.defaults(
        { active, address, formattedAddress, lat, lng, zipCode },
        this.location,
      ),
    );

    let query: { _geo?: unknown; loc?: unknown } = { loc: undefined };
    let hasFilter = false;
    if (!resetZipCodeLocation && this.location.lat && this.location.lng) {
      const geo = {
        $geoNear: {
          center: [this.location.lng, this.location.lat],
          miles: this.location.radius,
        },
      };
      hasFilter = true;
      query = {
        _geo: geo,
      };
      if (this.workerSource === 'userOnboardings') {
        return dispatch('ui.partnersFilters.setGeo', geo);
      }
    }

    // clear current query
    if (resetZipCodeLocation) {
      query = {};
      if (this.workerSource === 'userOnboardings') {
        // setGeo to null when location is empty
        return dispatch('ui.partnersFilters.setGeo', null);
      }
      if (
        this.workerSource === 'workers' ||
        this.workerSource === 'poolPartners'
      ) {
        _.extend(opts, { resetZipCodeLocation: true, smart: false });
      }
    }
    this.log.debug(`Filtering ${this.workerSource}`);
    return dispatch(`${this.workerSource}.find`, query, opts)
      .then(
        (res) =>
          res &&
          this.log.debug(
            `Found %d+ Workers from source: ${this.workerSource}`,
            _.size(res),
            res,
          ),
      )
      .then(() => hasFilter && this.setNoSearch(false))
      .catch((err) => this.log.error('Error setting location query', err))
      .then(() => this.location)
      .finally(() => this.doneLoading('location'));
  }

  @action.bound
  // eslint-disable-next-line consistent-return
  setLocation({
    zipCode,
    address,
    loc,
    formattedAddress,
    resetZipCodeLocation = false,
  }: {
    address?: IAddress;
    formattedAddress?: string;
    loc?: IPoint;
    resetZipCodeLocation?: boolean;
    zipCode?: string;
  } & (
    | {
        zipCode: string;
      }
    | {
        address: IAddress;
        formattedAddress: string;
        loc: IPoint;
      }
    | {
        resetZipCodeLocation: boolean;
      }
  )) {
    if (resetZipCodeLocation) {
      return this.setLoc({ zipCode: null }, { resetZipCodeLocation });
    }
    if (zipCode || (_.isString(zipCode) && _.size(this.location.zipCode))) {
      if (
        _.get(this.location, 'zipCode') !== zipCode &&
        (_.size(zipCode) === 5 || _.size(zipCode) === 0)
      ) {
        this.setDirty(true);
      }

      set(this.location, { zipCode });

      if (_.size(zipCode) === 5) {
        geocodeByAddress(zipCode)
          .then((res) => {
            const geocodedData = _.first(res);
            if (_.isEmpty(res)) {
              this.setLoc({ lat: null, lng: null });
              return false;
            }
            const lat = geocodedData.geometry.location.lat();
            const lng = geocodedData.geometry.location.lng();
            runInAction(() => {
              set(this.location, { address: {}, formattedAddress: '' });
            });
            return this.setLoc({ lat, lng, zipCode });
          })
          .catch((err) =>
            this.log.error('Error while getting geoCode from zipCode', err),
          );
      } else if (_.size(zipCode) === 0) {
        set(this.location, { address: {}, formattedAddress: '' });
        this.setLoc({ lat: null, lng: null });
      }
    } else if (!_.isEmpty(address)) {
      // set(this.location, { address, formattedAddress });

      const { zip } = address;
      const [lng, lat] = loc.coordinates;
      if (this.location?.lat !== lat || this.location?.lng !== lng) {
        this.setDirty(true);
      }
      this.setLoc({ address, formattedAddress, lat, lng, zipCode: zip });
    }
  }

  @action
  async setExcluded(
    {
      user,
      users,
    }: { user?: IWorker['uuid']; users?: Array<IWorker['uuid']> } = {},
    { reset, ...opts }: SearchCriteriaFilterOptions & { reset?: boolean } = {},
  ) {
    try {
      let excludedUsers = [...this.exclude];

      if (reset) {
        excludedUsers = users || _.compact([user]);
      } else {
        if (user && !_.includes(this.exclude, user)) {
          this.setDirty(true);
          excludedUsers.push(user);
        }
        const absentUsers = users && _.difference(users, this.exclude);
        if (absentUsers && _.size(absentUsers)) {
          this.setDirty(true);
          excludedUsers.push(...absentUsers);
        }
      }

      this.exclude.replace(excludedUsers);

      this.setLoading('exclude');

      const excludeQuery = {};

      if (!_.isEmpty(excludedUsers)) {
        excludeQuery.$nin = excludedUsers;
      }

      this.log.debug(`Filtering ${this.workerSource}`);
      const res = await dispatch(
        `${this.workerSource}.find`,
        {
          uuid: _.isEmpty(excludeQuery) ? undefined : excludeQuery,
        },
        opts,
      );

      this.log.debug(
        `Found %d+ Workers from source: ${this.workerSource}`,
        _.size(res),
        res,
      );
    } catch (err) {
      this.log.error('Error setting exclude query', err);
    }

    this.doneLoading('exclude');

    return this.exclude;
  }

  @action
  @action
  setRating(
    { min = 0, max = 5, complete }: MinMaxSearchDef & SliderState = {},
    opts = {},
  ) {
    if (
      _.get(this.rating, 'min', 0) !== min ||
      _.get(this.rating, 'max', 5) !== max
    ) {
      this.setDirty(true);
    }

    set(this.rating, { max, min });

    if (!complete) {
      return Promise.resolve();
    }
    this.setLoading('rating');

    const ratingQuery = {};

    if (min > 0) {
      ratingQuery.$gte = min;
      this.rating.active = true;
    }
    if (max > min && max < 5) {
      ratingQuery.$lte = max;
      this.rating.active = true;
    }

    this.log.debug(`Filtering ${this.workerSource}`);
    return dispatch(
      `${this.workerSource}.find`,
      { rating: _.isEmpty(ratingQuery) ? undefined : ratingQuery },
      opts,
    )
      .then(
        (res) =>
          res &&
          this.log.debug(
            `Found %d+ Workers from source: ${this.workerSource}`,
            _.size(res),
            res,
          ),
      )
      .then(() => this.setNoSearch(false))
      .catch((err) => this.log.error('Error setting rating query', err))
      .then(() => this.rating)
      .finally(() => this.doneLoading('rating'));
  }

  @action
  setCreatedAt(
    { min, max, complete }: MinMaxSearchDef & SliderState = {},
    opts = {},
  ) {
    if (
      _.get(this.createdAt, 'min') !== min ||
      _.get(this.createdAt, 'max') !== max
    ) {
      this.setDirty(true);
    }

    let clearing = false;

    if (_.isUndefined(min) && _.isUndefined(max)) {
      clearing = true;
      set(this.createdAt, {
        max: null,
        min: null,
      });
    } else {
      set(this.createdAt, {
        max: _.isUndefined(max) ? this.createdAt.max : max,
        min: _.isUndefined(min) ? this.createdAt.min : min,
      });
    }

    if (!complete && !clearing) {
      return Promise.resolve();
    }
    this.setLoading('createdAt');

    const createdAtQuery = {};

    if (this.createdAt.min) {
      createdAtQuery.$gte = moment(this.createdAt.min).toISOString();
      this.createdAt.active = true;
    }

    if (this.createdAt.max) {
      createdAtQuery.$lte = moment(this.createdAt.max).toISOString();
      this.createdAt.active = true;
    }

    this.log.debug(`Filtering ${this.workerSource}`);
    return dispatch(
      `${this.workerSource}.find`,
      {
        createdAt: _.isEmpty(createdAtQuery) ? undefined : createdAtQuery,
      },
      opts,
    )
      .then(
        (res) =>
          res &&
          this.log.debug(
            `Found %d+ Workers from source: ${this.workerSource}`,
            _.size(res),
            res,
          ),
      )
      .then(() => this.setNoSearch(false))
      .catch((err) => this.log.error('Error setting createdAt query', err))
      .then(() => this.createdAt)
      .finally(() => this.doneLoading('createdAt'));
  }

  @action
  setAdherence(
    { min = 0.0, max = 1.0, complete }: MinMaxSearchDef & SliderState = {},
    opts = {},
  ) {
    if (
      _.get(this.adherence, 'min', 0.0) !== min ||
      _.get(this.adherence, 'max', 1.0) !== max
    ) {
      this.setDirty(true);
    }

    set(this.adherence, { max, min });

    if (!complete) {
      return Promise.resolve();
    }
    this.setLoading('adherence');

    const adherenceQuery = {};

    if (min > 0) {
      adherenceQuery.$gte = min;
      this.adherence.active = true;
    }
    if (max > min && max < 1.0) {
      adherenceQuery.$lte = max;
      this.adherence.active = true;
    }

    this.log.debug(`Filtering ${this.workerSource}`);
    return dispatch(
      `${this.workerSource}.find`,
      {
        adherence: _.isEmpty(adherenceQuery) ? undefined : adherenceQuery,
      },
      opts,
    )
      .then(
        (res) =>
          res &&
          this.log.debug(
            `Found %d+ Workers from source: ${this.workerSource}`,
            _.size(res),
            res,
          ),
      )
      .then(() => this.setNoSearch(false))
      .catch((err) => this.log.error('Error setting adherence query', err))
      .then(() => this.adherence)
      .finally(() => this.doneLoading('adherence'));
  }

  @action
  setCompletionScore(
    { min = 0.0, max = 1.0, complete }: MinMaxSearchDef & SliderState = {},
    opts = {},
  ) {
    if (
      _.get(this.completionScore, 'min', 0.0) !== min ||
      _.get(this.completionScore, 'max', 1.0) !== max
    ) {
      this.setDirty(true);
    }

    set(this.completionScore, { max, min });

    if (!complete) {
      return Promise.resolve();
    }
    this.setLoading('completionScore');

    const completionScoreQuery = {};

    if (min > 0) {
      completionScoreQuery.$gte = min;
      this.completionScore.active = true;
    }
    if (max > min && max < 1.0) {
      completionScoreQuery.$lte = max;
      this.completionScore.active = true;
    }

    this.log.debug(`Filtering ${this.workerSource}`);
    return dispatch(
      `${this.workerSource}.find`,
      {
        'accountProps.completionScore': _.isEmpty(completionScoreQuery)
          ? undefined
          : completionScoreQuery,
      },
      opts,
    )
      .then(
        (res) =>
          res &&
          this.log.debug(
            `Found %d+ Workers from source: ${this.workerSource}`,
            _.size(res),
            res,
          ),
      )
      .then(() => this.setNoSearch(false))
      .catch((err) =>
        this.log.error('Error setting completionScore query', err),
      )
      .then(() => this.completionScore)
      .finally(() => this.doneLoading('completionScore'));
  }

  @action
  setTerms(terms: string) {
    if (_.isEmpty(terms)) {
      return Promise.resolve();
    }
    this.setLoading('terms');
    this.terms.push(terms);
    this.setDirty(true);
    this.doneLoading('terms');

    return Promise.resolve(this.terms);
  }

  @action
  setEmploymentStatus(
    value: {
      company: 'me' | 'siblings' | 'relations' | 'children';
      status:
        | CompanyStatusRecord['status']
        | Array<CompanyStatusRecord['status']>;
    } = {},
    opts,
  ) {
    this.setLoading('employmentStatus', true);

    let query: Query = {};

    if (value.status) {
      this.log.debug('Setting employment status', value);

      this.employmentStatus.status = _.isString(value.status)
        ? [value.status]
        : value.status;

      query = {
        'companyStatus.company': this.companyId,
        'companyStatus.status': {
          $in: this.employmentStatus.status.toJS(),
        },
      };

      this.employmentStatus.active = true;

      if (_.isEmpty(this.employmentStatus.path)) {
        this.employmentStatus.path = 'me';
      }
    }

    if (value.company) {
      this.log.debug('setting employment scope', value);

      if (!this.company.path) {
        this.setLoading('employmentStatus', false);
        return false;
      }

      const splitPath = this.company.path.split('/');

      this.employmentStatus.path = value.company;
      this.employmentStatus.active = true;

      switch (value.company) {
        case 'me':
          query['companyStatus.company'] = this.company.path;
          query['companyStatus.path'] = undefined;
          break;
        case 'children':
          query.companies = undefined;
          query['companyStatus.company'] = undefined;
          query['companyStatus.path'] = { $regex: `^${this.company.path}/.+` };
          break;
        case 'siblings':
          query.companies = undefined;
          query['companyStatus.company'] = undefined;
          splitPath.pop();

          query['companyStatus.path'] = {
            $regex: `^${splitPath.join('/')}/[^/]+$`,
          };
          break;
        case 'relations':
          query.companies = undefined;
          query['companyStatus.company'] = undefined;
          query['companyStatus.path'] = { $regex: `^${splitPath[0]}` };
          break;
        default:
          this.log.error(
            'Unknown company hierarchy value, "%s"',
            value.company,
          );
          break;
      }
    }

    if (_.isEmpty(value)) {
      // Reset all possible changes
      query.companies = this.companyId;
      query['companyStatus.status'] = undefined;
      query['companyStatus.company'] = undefined;
      query['companyStatus.path'] = undefined;
    }

    if (_.isEmpty(query)) {
      this.employmentStatus.query = null;
      return Promise.resolve();
    }

    if (!_.isEmpty(value)) {
      this.employmentStatus.query = query;
    }

    this.log.debug('Querying employment status: ', { query });

    return dispatch(`${this.workerSource}.find`, {
      opts,
      query,
    }).finally(() => this.setLoading('employmentStatus', false));
  }

  @action
  setCustomSearch(
    key: string,
    value: MinMaxSearchDef & SliderState,
    opts = {},
  ) {
    const { min, max, complete } = value;
    const customKey = `customMetrics.${key}`;

    const { multiplier = 1, range = { max: 100, min: 0 } } =
      this.customFieldDefs[key] ?? {};
    const currentValue = {
      max: _.get(this.customMetrics[key], 'max', range.max / multiplier),
      min: _.get(this.customMetrics[key], 'min', range.min / multiplier),
    };

    if (currentValue.min !== min || currentValue.max !== max) {
      this.setDirty(true);
    }

    set(this.customMetrics[key], { max, min });

    if (!complete) {
      return Promise.resolve();
    }
    this.setLoading(customKey);

    const minVal = _.toNumber(min);
    const maxVal = _.toNumber(max);

    let customQuery: Query = {};

    if (!maxVal && !minVal) {
      this.customMetrics[key].active = true;
      customQuery = { $gte: undefined, $lte: undefined, $not: { $gt: 0 } };
    } else if (minVal === maxVal && maxVal !== range.max) {
      customQuery = { $gte: minVal, $lte: maxVal, $not: undefined };
    } else {
      if (minVal > range.min / multiplier) {
        this.customMetrics[key].active = true;
        customQuery.$gte = minVal;
        customQuery.$not = undefined;
      }
      if (maxVal > minVal && maxVal < range.max / multiplier) {
        this.customMetrics[key].active = true;
        customQuery.$lte = maxVal;
        customQuery.$not = undefined;
      }
    }

    this.log.debug(`Filtering ${this.workerSource}`);

    return dispatch(
      `${this.workerSource}.find`,
      { [customKey]: _.isEmpty(customQuery) ? undefined : customQuery },
      {
        resetNestedKey: customKey,
        ...opts,
      },
    )
      .then(
        (res) =>
          res &&
          this.log.debug(
            `Found %d+ Workers from source: ${this.workerSource}`,
            _.size(res),
            res,
          ),
      )
      .then(() => this.setNoSearch(false))
      .catch((err) => this.log.error(`Error setting ${customKey} query`, err))
      .then(() => this.customMetrics[key])
      .finally(() => this.doneLoading(customKey));
  }

  @action
  setLoading(key: keyof Searches['isLoading'], loading = true) {
    this.initLoaderVars();

    if (!_.has(this.isLoading, key)) {
      set(this.isLoading, { [key]: loading });
    } else {
      this.isLoading[key] = loading;
    }

    if (loading) {
      setTimeout(() => {
        if (this.isLoading[key]) {
          this.log.warn(`${key} is still loading after 10s`);
          this.doneLoading(key);
        }
      }, 10000);
    }
  }

  @action
  doneLoading(key: keyof Searches['isLoading']) {
    this.initLoaderVars();
    this.isLoading[key] = false;
  }

  @computed
  get loading() {
    return _.some(this.isLoading);
  }

  @computed
  get hasFilters() {
    return (
      !_.isEmpty(this.roles) ||
      !_.isEmpty(this.certs) ||
      !_.isEmpty(this.skills) ||
      !_.isEmpty(this.employers) ||
      this.adherence.active ||
      this.completionScore.active ||
      this.rating.active ||
      !_.isEmpty(this.location.zipCode)
    );
  }

  @action
  async clear({ resetStores }: { resetStores?: boolean } = {}) {
    this.setLoading('setup');
    this.clearWorkerStoreFilters();
    await dispatch('searches.clearSelected', {
      storeName: this.workerSource,
    });

    this.clearSearchCriteria();

    runInAction(() => {
      this.isFullAddress = false;
      this.isCustomRadius = false;
      this.radiusOptions = _.clone(this.distanceOpts);
      this.radiusRef = null;
      this.noSearch = true;

      this.selected = {}; // TODO: assign to null
      this.company = {};
    });

    this.setDirty(false);
    this.initAddingVars(true);
    this.initLoaderVars(true);

    if (resetStores) {
      return this.resetStores();
    }

    return null;
  }

  /**
   * clearSearchCriteria
   * @description Clears out the locally stored "current" search criteria
   */
  @action
  clearSearchCriteria() {
    this.industries.clear();
    this.roles.clear();
    this.positions.clear();
    this.certs.clear();
    this.skills.clear();
    this.employers.clear();
    this.exclude.clear();
    this.quals.clear();
    this.attributes.clear();
    this.terms.clear();
    this.countries.clear();
    this.states.clear();
    _.each(this.additionalStores, (store) => this[store].clear());

    this.adherence = { active: false, max: 1, min: 0 };
    this.completionScore = { active: false, max: 1, min: 0 };
    this.rating = { active: false, max: 5, min: 0 };
    this.createdAt = { active: false, max: null, min: null };
    this.employmentStatus = {
      active: false,
      path: null,
      query: null,
      status: [],
    };
    this.restrictions = {
      roles: [],
    };

    this.clearAddressForm();

    set(this.location, {
      active: false,
      address: {},
      coordinates: [],
      formattedAddress: '',
      radius: 20,
      type: 'Point',
      zipCode: '',
    });
  }

  // TODO: What other stores need to be cleared here? This list has likely been overlooked and is not complete
  resetStores() {
    const stores = [
      'industries',
      'roles',
      'positions',
      'certs',
      'skills',
      'employers',
      'quals',
      'attributes',
      ...(this.additionalStores || []),
    ];

    return Promise.map(stores, (store) => {
      dispatch(`${store}.clearSelected`);
      // EP-950 was causing problem on `positions.find` since it had a "special" signature
      return dispatch(`${store}.find`, {}, { clear: true });
    });
  }

  clearWorkerStoreFilters() {
    this.log.debug(`Filtering ${this.workerSource}`);
    return (
      !/^(users|userOnboardings)$/i.test(this.workerSource) &&
      Promise.all([
        dispatch(`${this.workerSource}.setCertFilter`, {
          op: 'set',
          opts: { noQuery: true },
        }),
        dispatch(
          `${this.workerSource}.setFilter`,
          {
            key: 'adherence',
          },
          { noQuery: true },
        ),
        dispatch(
          `${this.workerSource}.setFilter`,
          {
            key: 'accountProps.completionScore',
          },
          { noQuery: true },
        ),
        dispatch(
          `${this.workerSource}.setFilter`,
          {
            key: 'rating',
          },
          { noQuery: true },
        ),
      ])
    );
  }

  @action
  initLoaderVars(reset?: boolean) {
    if (reset || _.isEmpty(_.keys(this.isLoading))) {
      _.each(
        _.isEmpty(_.keys(this.isLoading)) ? storeKeys : _.keys(this.isLoading),
        action((k) => {
          this.isLoading[k] = false;
        }),
      );
    }
  }

  @action
  initAddingVars(reset?: boolean) {
    if (reset || _.isEmpty(_.keys(this.isAdding))) {
      _.each(
        _.isEmpty(_.keys(this.isAdding)) ? storeKeys : _.keys(this.isAdding),
        action((k) => {
          this.isAdding[k] = false;
        }),
      );
    }
  }

  /** Common Functionality */

  findLinkedRoles(items: ITag | Array<ITag>) {
    const linkedUUIDs = _.isArrayLike(items)
      ? _.map(items, 'uuid')
      : [items.uuid];
    const query = { 'links.uuid': undefined };

    if (!_.isEmpty(linkedUUIDs)) {
      _.set(query, 'links.uuid', {
        $in: linkedUUIDs,
      });
    }

    return dispatch('roles.find', {
      query,
    }).then((res) => {
      this.log.debug(res);

      if (_.isEmpty(res) || _.isEmpty(linkedUUIDs)) {
        return Promise.resolve();
      }

      _.map(res, (role) => this.setRoles(role));

      return _.map(res, 'uuid');
    });
  }

  getLinkedItemsUUIDs(items: ITag | Array<ITag>) {
    const linkedUUIDs = _.isArrayLike(items)
      ? _.map(items, 'uuid')
      : [items.uuid];

    if (_.isEmpty(linkedUUIDs)) {
      return Promise.resolve();
    }

    return dispatch('tags.find', {
      query: {
        certType: null,
        'links.uuid': {
          $in: linkedUUIDs,
        },
      },
      save: false,
    }).then((res) => {
      this.log.debug(res);

      if (_.isEmpty(res)) {
        return Promise.resolve();
      }

      const groups = _.groupBy(res, 'certType');

      const savedStuff = _(groups)
        .keys()
        .map((k) => {
          switch (k) {
            case 'cert':
              return _.map(groups[k], (c) =>
                this.setCerts(c, { noQuery: true }),
              );
            case 'skill':
              return _.map(groups[k], (c) =>
                this.setSkills(c, { noQuery: true }),
              );
            case 'role':
              return _.map(groups[k], (c) =>
                this.setRoles(c, { noQuery: true }),
              );
            case 'qual':
              return _.map(groups[k], (c) =>
                this.setQuals(c, { noQuery: true }),
              );
            case 'attribute':
              return _.map(groups[k], (c) =>
                this.setAttributes(c, { noQuery: true }),
              );
            case 'employer':
              return _.map(groups[k], (c) =>
                this.setEmployers(c, { noQuery: true }),
              );
            default:
              this.log.warn('Unknown Cert Type: ', k);
              return [];
          }
        })
        .flatten()
        .value();

      this.log.debug('Retrieved Linked Items: ', savedStuff);

      return Promise.all(savedStuff).then(() => _.map(res, 'uuid'));
    });
  }

  @action
  getSaveableSearchData({ returnDirtyOnly = !!this.selected.uuid } = {}) {
    const data = this.saveableSearchData;

    if (returnDirtyOnly && !!this.selected.uuid) {
      data.search = this.dirtySearchValues;
    }

    this.log.debug('Returning Savable Search: ', { search: data.search });

    return data;
  }

  @computed
  get dirtySearchValues() {
    const { search } = this.saveableSearchData;

    return _.pickBy(search, (v, k) => this.dirtyFields[k]);
  }

  @computed
  get saveableSearchData() {
    const search = {
      adherence: null,
      completionScore: null,
      countries: null,
      createdAt: null,
      employment: null,
      exclude: null,
      include: null,
      loc: null,
      optionalTags: null,
      rating: null,
      states: null,
      tags: null,
    };

    const {
      industries,
      roles,
      positions,
      certs,
      skills,
      employers,
      quals,
      attributes,
      terms,
      adherence,
      completionScore,
      rating,
      createdAt,
      location,
      label,
      company,
      selected,
      employmentStatus,
      customMetrics,
      countries,
      states,
    } = this;

    _([
      industries,
      roles,
      positions,
      certs,
      skills,
      employers,
      quals,
      attributes,
      terms,
      ...(this.additionalStores || []).map((store) => this[store]),
    ]).each((tags) =>
      _.each(tags, (tag) => {
        if (tag.isRequired) {
          search.tags = search.tags || [];
          search.tags.push(tag.uuid);
        } else {
          search.optionalTags = search.optionalTags || [];
          search.optionalTags.push(tag.uuid);
        }
      }),
    );

    if (adherence.min > 0) {
      search.adherence = _.extend(search.adherence || {}, {
        min: adherence.min,
      });
    }
    if (adherence.max < 1) {
      search.adherence = _.extend(search.adherence || {}, {
        max: adherence.max,
      });
    }

    if (completionScore.min > 0) {
      search.completionScore = _.extend(search.completionScore || {}, {
        min: completionScore.min,
      });
    }
    if (completionScore.max < 1) {
      search.completionScore = _.extend(search.completionScore || {}, {
        max: completionScore.max,
      });
    }

    if (rating.min > 0) {
      search.rating = _.extend(search.rating || {}, { min: rating.min });
    }
    if (rating.max < 5) {
      search.rating = _.extend(search.rating || {}, { max: rating.max });
    }

    if (createdAt.min) {
      search.createdAt = _.extend(search.createdAt || {}, {
        min: moment(createdAt.min).toISOString(),
      });
    }
    if (createdAt.max) {
      search.createdAt = _.extend(search.createdAt || {}, {
        max: moment(createdAt.max).toISOString(),
      });
    }

    // { zipCode: '', lat: null, lng: null, radius: 20, active: false }
    // { zipCode: '', coordinates: [lng, lat], type: 'Point', radius: 20, active: false }
    if ((location.lat || location.lng) && location.active) {
      search.loc = toJS(location);
      search.loc.coordinates = [location.lng, location.lat];
    }

    if (!_.isEmpty(employmentStatus.status)) {
      search.employment = _.pick(employmentStatus, ['status', 'path']);
      search.employment.query = JSON.stringify(employmentStatus.query);
    }

    if (!_.isEmpty(this.exclude)) {
      search.exclude = _.map(this.exclude, (ex) => _.get(ex, 'uuid', ex));
    }
    if (!_.isEmpty(this.include)) {
      search.include = _.map(this.include, (ex) => _.get(ex, 'uuid', ex));
    }
    if (!_.isEmpty(countries)) {
      search.countries = countries;
    }
    if (!_.isEmpty(states)) {
      search.states = states;
    }

    let savedSearchLabel = label;

    if (_.isEmpty(savedSearchLabel)) {
      savedSearchLabel = _.compact([
        _.get(_.first(industries), 'title'),
        _.get(_.first(positions), 'title') || _.get(_.first(roles), 'title'),
        _.get(_.first(certs), 'title'),
        _.get(_.first(quals), 'title'),
        _.get(_.first(attributes), 'title'),
      ]).join(' - ');
    }

    if (_.isEmpty(savedSearchLabel)) {
      savedSearchLabel = `Saved Search ${moment().format('LT')}`;
    }

    _(customMetrics)
      .pickBy(({ active }) => !!active)
      .forEach((customMetric, key) => {
        const { min, max } = customMetric;
        const customKey = `customMetrics.${key}`;

        const customField = this.customFieldDefs[key];

        const { range, multiplier } = customField;

        const searchDef = {};
        if (min * multiplier > range?.min ?? 0) {
          _.extend(searchDef, { min });
        }
        if (max * multiplier < range?.max ?? 100) {
          _.extend(searchDef, { max });
        }

        if (!_.isEmpty(searchDef)) {
          _.set(
            search,
            customKey,
            _.extend(_.get(search, customKey) || {}, searchDef),
          );
        }
      });

    const data: Partial<ISearch> = {
      companies: _.isEmpty(selected.companies)
        ? [_.get(company, 'uuid', company)]
        : selected.companies,
      label: savedSearchLabel,
      querySearch: this.queryService,
      search,
      uuid: selected.uuid,
    };

    return data;
  }
}

/**
 * TODO: unify this with other similar logic
 */
function getStoreName(key: StoreName | string): StoreName {
  return /^industr/.test(key) ? 'industries' : (`${key}` as StoreName);
}

function getStoreKeys() {
  return [
    'setup',
    'search',
    'industries',
    'roles',
    'positions',
    'certs',
    'skills',
    'employers',
    'quals',
    'attributes',
    'rating',
    'createdAt',
    'adherence',
    'completionScore',
    'location',
    'countries',
    'states',
  ];
}
