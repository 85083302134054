import * as React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import AppStore from '#/shared/stores/app';

export interface GlobalEnvironmentBannerProps {
  app: AppStore;
}

/**
 * @name GlobalEnvironmentBanner
 * @description This banner renders at the top of our application in development
 * so we can quickly visualize "staging" from "production" connections/data.
 */
export const GlobalEnvironmentBanner = observer(
  (props: GlobalEnvironmentBannerProps) => {
    const { app } = props;

    // Setup
    const url = app.getServerURI();

    // Hooks
    const [isProduction] = React.useState(/shiftsmart\.com/i.test(url));
    const bgColor = isProduction ? 'bg-red' : 'bg-blue';
    const source = isProduction ? 'PRODUCTION' : 'Development';

    // 🔌 Short Circuit
    if (global.IS_PRODUCTION) return null;

    return (
      <div
        className={classnames(
          `w-100 pv1 ph3 white flex items-center justify-between`,
          bgColor,
        )}
      >
        <div>
          Connected to <b>{source}</b> API
        </div>
        <div>{app.getServerURI()}</div>
      </div>
    );
  },
);
