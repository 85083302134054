import styled, { css } from 'styled-components';

import { UIStoresType } from '#/shared/stores/ui';

const darkRotate = `
  background: black;
  filter: invert(1) hue-rotate(180deg); 
`;

declare type DarkModeProps = {
  allowed?: boolean;
  enabled: UIStoresType['theme']['isDarkModeEnabled'];
};

/**
 * ## DarkMode Theme Provider
 *
 * Applies a crude invert + hue-rotation to "fake" dark mode in the app.
 */
export const DarkMode = styled.div<DarkModeProps>`
  ${({ allowed }) =>
    allowed &&
    css<DarkModeProps>`
      & {
        ${({ enabled }) => !!enabled && darkRotate}
      }

      & img.ui.image {
        ${({ enabled }) => !!enabled && darkRotate}
      }
    `}
`;
