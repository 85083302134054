import _ from 'lodash';

export function getIconDefinition(certType = 'cert', activeItem) {
  const tagType = certType.replace(/ies$/, 'y').replace(/s$/, '');
  const isActive = !!activeItem && RegExp(tagType, 'i').test(activeItem);

  switch (tagType) {
    case 'cert':
      return 'shield';
    case 'qual':
      return isActive ? 'tags' : 'tag';
    case 'skill':
      return 'gem outline';
    case 'attribute':
      return 'object group outline';
    case 'industry':
      // adminMenu: icon="building outline"
      return 'industry';
    case 'role':
      return 'address card outline';
    case 'position':
      return 'id badge outline';
    case 'employer':
      return 'building outline';
    case 'badge':
      return 'certificate';
    case 'taggroup':
      return isActive ? 'pallet' : 'boxes';
    case 'experience':
      return 'list alternate outline';
    default:
      return 'question';
  }
}

export function getTitle(certType = 'cert') {
  switch (certType) {
    case 'cert':
      return 'Certifications';
    case 'qual':
      return 'Tags';

    case 'industry':
    case 'role':
    case 'skill':
    default:
      return _.capitalize(certType).replace(/$/, 's').replace(/ys$/, 'ies');
  }
}

export default {
  getIconDefinition,
  getTitle,
};
