import React from 'react';
import { observer } from 'mobx-react';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import { Icon, Label } from 'semantic-ui-react';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('utils.CompanyStatus');

const isCompanyMember = (entity, company) =>
  !_.isEmpty(entity) &&
  !_.isEmpty(company) &&
  _.includes(entity.companies, company.uuid);

const CompanyStatus = observer(
  ({
    entity,
    worker = entity,
    company,
    iconOnly = false,
    modalName = 'workerMembershipModal',
  }) => {
    if (_.isEmpty(company)) {
      return null;
    }

    const isWorker = isCompanyMember(worker, company);

    const text = !isWorker ? `Add to ${company.name}` : `@${company.name}`;

    const handleTouchTap = () => {
      log.info(`Opening ${modalName} ...`);
      dispatch(`ui.${modalName}.setup`, { entity: worker, open: true });
    };

    return (
      <Label
        as="a"
        basic={!isWorker}
        color={isWorker ? 'green' : 'blue'}
        onClick={handleTouchTap}
        size="tiny"
      >
        <Icon name={isWorker ? 'check' : 'plus'} />
        {!iconOnly && text}
      </Label>
    );
  },
);

export { isCompanyMember };

export default CompanyStatus;
