import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import GenericSearchFilter from '#/shared/components/utils/filters/GenericSearchFilter';

export const defaultCountries = [
  {
    key: 'AT',
    text: 'Austria',
    value: 'AT',
  },
  {
    key: 'AU',
    text: 'Australia',
    value: 'AU',
  },
  {
    key: 'BE',
    text: 'Belgium',
    value: 'BE',
  },
  {
    key: 'BR',
    text: 'Brazil',
    value: 'BR',
  },
  {
    key: 'CA',
    text: 'Canada',
    value: 'CA',
  },
  {
    key: 'DK',
    text: 'Denmark',
    value: 'DK',
  },
  {
    key: 'FI',
    text: 'Finland',
    value: 'FI',
  },
  {
    key: 'FR',
    text: 'France',
    value: 'FR',
  },
  {
    key: 'DE',
    text: 'Germany',
    value: 'DE',
  },
  {
    key: 'IN',
    text: 'India',
    value: 'IN',
  },
  {
    key: 'IE',
    text: 'Ireland',
    value: 'IE',
  },
  {
    key: 'IT',
    text: 'Italy',
    value: 'IT',
  },
  {
    key: 'LU',
    text: 'Luxembourg',
    value: 'LU',
  },
  {
    key: 'MX',
    text: 'Mexico',
    value: 'MX',
  },
  {
    key: 'NL',
    text: 'Netherlands',
    value: 'NL',
  },
  {
    key: 'NZ',
    text: 'New Zealand',
    value: 'NZ',
  },
  {
    key: 'NO',
    text: 'Norway',
    value: 'NO',
  },
  {
    key: 'PL',
    text: 'Poland',
    value: 'PL',
  },
  {
    key: 'PT',
    text: 'Portugal',
    value: 'PT',
  },
  {
    key: 'SA',
    text: 'Saudi Arabia',
    value: 'SA',
  },
  {
    key: 'ZA',
    text: 'South Africa',
    value: 'ZA',
  },
  {
    key: 'ES',
    text: 'Spain',
    value: 'ES',
  },
  {
    key: 'SE',
    text: 'Sweden',
    value: 'SE',
  },
  {
    key: 'TR',
    text: 'Turkey',
    value: 'TR',
  },
  {
    key: 'AE',
    text: 'United Arab Emirates',
    value: 'AE',
  },
  {
    key: 'GB',
    text: 'United Kingdom',
    value: 'GB',
  },
  {
    key: 'US',
    text: 'United States',
    value: 'US',
  },
];

const CountryFilter = observer(
  ({
    title = 'Countries',
    options = defaultCountries,
    countryAcronymsAsOptions,
    selectCountry,
    deselectCountry,
    isEditing = true,
    selected,
    defaultExpanded = false,
    tooltip,
  }: {
    countryAcronymsAsOptions?: string[];
    defaultExpanded?: boolean;
    deselectCountry: (item) => void;
    isEditing?: boolean;
    options?: { key: string; text: string; value: string }[];
    selectCountry: (item) => void;
    selected: string[];
    title?: string;
    tooltip?: string;
  }) => {
    const countryOptions = useMemo(
      () =>
        !_.isEmpty(countryAcronymsAsOptions)
          ? _.compact(
              countryAcronymsAsOptions.map((k) => _.find(options, { key: k })),
            )
          : options,
      [countryAcronymsAsOptions, options],
    );
    return (
      <GenericSearchFilter
        canUpdate={isEditing}
        defaultExpanded={defaultExpanded}
        onDeselect={deselectCountry}
        onSelect={selectCountry}
        options={countryOptions}
        selected={selected}
        title={title}
        tooltip={tooltip}
      />
    );
  },
);

export default CountryFilter;
