import type { ICompany, IPool } from '@shiftsmartinc/shiftsmart-types';

import { set, observable, action, toJS, runInAction, computed } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';
import { Query } from '@feathersjs/feathers';

import { service } from '#/shared/app';

import BaseStore, { IStoreFindOpts } from './_baseStore';
import AuthStore from './auth';

export default class PoolStore extends BaseStore<IPool> {
  constructor() {
    super({
      baseItem: PoolStore.BASE_ITEM,
      searchFields: ['label'],
      serviceName: 'pools',
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  static BASE_ITEM = {
    autoSpecifier: '',
    chatId: null,
    companies: [],
    company: '',
    createdAt: null,
    deleted: false,
    hasMoreWorkers: false,
    isNew: false,
    label: '',
    notes: '',
    path: '',
    poolType: '',
    search: {},
    updatedAt: null,
    users: [],
    uuid: '',

    workerCount: 0, // used to `addBlankPool`
  };

  query = { query: {} };

  lastQuery = null;

  @observable
  filter = 'all';

  poolClearTasks = [];

  /**
   * poolUsers
   * @deprecated 2019-10-02
   *
   * Remove once the deprecated NotificationPrefs & ShiftNotifications components are removed
   */
  get poolUsers() {
    const err = new Error('Property Removed');
    this.log.error(
      'The `pools.poolUsers` property has been removed. ' +
        'A stub object will be returned to prevent deprecated components from breaking',
      err,
    );

    return {};
  }

  @observable
  relatedPools = [];

  @computed
  get poolsList() {
    return _.filter(
      this.list,
      ({ poolType }) =>
        !poolType || /smart|manual|upload|preShift/.test(poolType),
    );
  }

  @computed
  get autoPoolsList() {
    return _.filter(this.list, {
      poolType: 'auto',
    });
  }

  @computed
  get siblingPoolsList() {
    const company = dispatch('auth.getCompany');
    const path = _.get(company, 'path') || company.uuid;
    return _.reject(
      this.relatedPools,
      ({
        company: companyId,
        companyPath: poolCompanyPath = companyId,
        workerCount,
      }) =>
        workerCount < 1 ||
        companyId === company.uuid ||
        poolCompanyPath.split('/').length !== path.split('/').length,
    );
  }

  @computed
  get childrenPoolsList() {
    const company = dispatch('auth.getCompany');
    const path = _.get(company, 'path') || company.uuid;
    return _.reject(
      this.relatedPools,
      ({
        company: companyId,
        companyPath: poolCompanyPath = companyId,
        workerCount,
      }) =>
        workerCount < 1 ||
        companyId === company.uuid ||
        poolCompanyPath.split('/').length <= path.split('/').length,
    );
  }

  @action
  removeItem(item) {
    _.remove(this.list, { uuid: item.uuid });
    _.remove(this.relatedPools, { uuid: item.uuid });
  }

  create({ data = null, params }) {
    if (_.isEmpty(data)) {
      return Promise.reject('No Pool Data Specified');
    }

    const co = dispatch('auth.getCompany') || {};

    let {
      company,
      companies = _.compact([company]),
      companyPath,
    } = {
      ...data,
    };

    if (_.isObject(company)) {
      company = company?.uuid || co.uuid;
    }
    if (_.isEmpty(companies)) {
      companies = [company];
    }
    if (_.isEmpty(companyPath)) {
      companyPath = data.company?.path || co.path;
    }

    const newPool = {
      ...data,
      action: 'add',
      companies,
      company,
      companyPath,
      label: _.get(data.pool, 'label', data.label),
      poolType: _.get(data, 'poolType', 'smart'),
      search: data.search,
      uuid: _.get(data.pool, 'uuid', data.uuid),
      workers: data.workers,
    };

    this.log.debug('Creating New Pool with Data: ', { data: newPool });

    return super
      .create({ data: newPool, params })
      .then((pool) => {
        // eslint-disable-next-line no-param-reassign
        pool.workerCount = data.workers ? data.workers.length : 0;
        return pool;
      })
      .catch((err) => this.logAndThrow(err));
  }

  findByCompany({
    company,
    query,
    opts,
  }: {
    company: ICompany | ICompany['uuid'] | AuthStore['company'];
    opts?: IStoreFindOpts<IPool> & { deselect?: boolean };
    query?: Query;
  }) {
    return super.findByCompany({ company, opts, query });
  }

  @action
  resetSearch({ preserve = [] } = {}) {
    this.query = {
      query: _.pick(this.query.query, _.union(preserve, ['companies'])),
    };
    this.searchValue = '';
    this.pools = [];
    this.certs = [];
    this.softCerts = [];
    this.tags = [];
  }

  find(query = {}, { smart = true, ...rest } = {}) {
    return super.find(query, { smart, ...rest });
  }

  // TODO : refactor to use base store

  remove(poolUUID) {
    return service(this.serviceName)
      .patch(poolUUID, { deleted: true })
      .then((res) => {
        this.removeItem(res);
        return res;
      })
      .catch((err) => {
        this.logAndThrow(err);
      });
  }

  @action.bound
  async setSelected(poolSrc) {
    const pool =
      _.isString(poolSrc) && !_.isEmpty(poolSrc)
        ? await dispatch('pools.get', poolSrc, {
            query: { $client: { populateUserAvatars: true } },
            select: false,
          })
        : poolSrc;

    await super.setSelected(pool);
    const company = dispatch('auth.getCompany');
    const profileMetric =
      company.settings?.partners?.primaryPartnerProfileMetric;
    if (this.selected.uuid) {
      await Promise.all([
        dispatch('poolPartners.find', {
          query: {
            $client: {
              populateUserStats:
                !!profileMetric && profileMetric !== 'rating' && company.uuid,
            },

            // TODO: Check security of setting the company when selecting a pool.
            companyStatus: { $elemMatch: { company: this.selected.company } },
            'pools.uuid': this.selected.uuid,
          },
        }),

        dispatch('poolMembers.selectPool', {
          pool: this.selected,
          query: {
            $client: {
              populateUserStats:
                !!profileMetric && profileMetric !== 'rating' && company.uuid,
            },
          },
        }),
      ]);
    } else {
      dispatch('poolMembers.selectPool', {});
    }

    return this.selected;
  }

  @action
  updateListWithSelected() {
    const pool = this.selected;

    const existing = _.find(this.list, { uuid: pool.uuid });
    if (existing) {
      set(existing, toJS(pool));

      console.assert(
        existing.workerCount === pool.workerCount,
        'Worker counts must match',
      );
    }
  }

  addManyToPool({ workers, company, pool }) {
    return Promise.all(
      workers.map((worker) => this.addToPool({ company, pool, worker })),
    );
  }

  @action
  addToPool({ worker, company, companies, pool = {}, poolAction = 'include' }) {
    const poolId = _.get(pool, 'uuid', false);
    const workerId = _.get(worker, 'uuid', worker);

    if (_.isEmpty(pool) && _.isEmpty(poolId)) {
      return Promise.reject('Pool not specified', pool);
    }

    if (_.isEmpty(workerId)) {
      return Promise.reject('No Worker Defined');
    }

    if (!poolId) {
      const newPool = {
        companies: _.isArray(companies) ? companies : [company.uuid],
        label: pool.label,
        uuid: pool.uuid,
        workers: !poolId && [workerId],
      };

      return service(this.serviceName).create(newPool);
    }

    return service(this.serviceName).patch(
      poolId,
      {
        workers: [workerId],
      },
      {
        query: { action: poolAction },
      },
    );
  }

  removeWorkerFromPool({ pool, worker, poolAction = 'remove' }) {
    const poolId = _.get(pool, 'uuid', pool);
    const workerId = _.get(worker, 'uuid', worker);
    return service(this.serviceName).patch(
      poolId,
      {
        workers: [workerId],
      },
      {
        query: { action: poolAction },
      },
    );
  }

  @action.bound
  async loadRelatedPools({ company, specifier = 'myPartners' }) {
    const co = _.isString(company)
      ? await dispatch('companies.get', company, { select: false })
      : company;

    const splitPath = co.path.split('/');

    let relatedPools = [];
    if (splitPath.length > 1) {
      splitPath.pop();
      splitPath.push('[^/]+$');
    } else {
      splitPath.push('[^/]+$');
    }

    const regex = splitPath.join('/');

    relatedPools = await this.runQuery({
      autoSpecifier: specifier,
      companyPath: { $ne: co.path, $regex: `^${regex}` },
      poolType: 'auto',
    });

    runInAction(() => {
      this.relatedPools = _.get(relatedPools, 'data', relatedPools);
    });

    return this.relatedPools;
  }

  // #region UI Display Logic

  /**
   * getDisplayProps
   * Given a pool, decides how to best display the pool's Image and Label depending on the
   * pool's source. In the case where the pool does not "belong" to the auth'd company, the
   * pool's display name will be returned as the pool company's name, rather than the pool's label.
   *
   *
   * @param {Object<pool>} item The Pool being looked up for display purposes
   * @param {String} defaultImage The default image to display for the pool.
   *
   * @returns {
   *  title: string;
   *  image: string;
   *  isFavorite: bool;
   *  isMine: bool;
   *  companyName: string;
   * }
   */
  getDisplayProps({
    item: pool,
    defaultImage = '/static/img/defaultPoolImg.png',
  }) {
    if (_.isEmpty(pool)) {
      return {};
    }

    const company = dispatch('auth.getCompany');
    const relatedCompanies = dispatch('companies.getRelatedCompanies');

    let companyName = company.name;
    let poolTitle = pool.label;
    const isFavorite = pool.autoSpecifier === 'myFavorites';

    let poolImage = defaultImage;

    const authdCompanyId = company?.uuid ?? '';
    const poolCompanyId = pool.company?.uuid ?? pool.company;

    if (poolCompanyId !== authdCompanyId) {
      const co = _.find(relatedCompanies, {
        uuid: poolCompanyId,
      });

      if (co) {
        poolTitle = co.abbr || co.name || poolTitle;
        poolImage =
          _.get(co, 'images.avatar') ||
          _.get(co, 'images.square') ||
          _.get(co, 'images.logo') ||
          poolImage;
        companyName = co.name;

        if (isFavorite) {
          poolTitle += ' Favorites';
        }
      }
    }

    return {
      companyName,
      image: poolImage,
      isFavorite,
      isMine: poolCompanyId === authdCompanyId,
      title: poolTitle,
    };
  }
}
