import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class CreateShiftModal {
  log = getChildLogger('ui.CreateShiftModal');

  @observable
  isOpen = false;

  @observable
  isEdit = false;

  @observable
  lastSubmittedShiftId = null;

  @action
  setup({
    open = this.isOpen,
    edit = false,
    shift,
    company,
    preserveNotificationPrefs = false,
    isShiftTemplate = false,
    isBulkEdit = false,
  }) {
    dispatch('ui.shiftFormNextGen.setup', {
      company,
      isBulkEdit,
      isTemplate: isShiftTemplate,
      preserveNotificationPrefs,
      shift,
    });
    this.open(open);
    this.isEdit = edit;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    this.isOpen = false;
  }

  @action
  setLastSubmittedShift(shift) {
    this.lastSubmittedShiftId = shift?.uuid ?? shift;
  }

  @action.bound
  async send({ selected, channel }) {
    const log = this.log.getChildLogger('send');
    if (_.isEmpty(channel)) {
      dispatch('ui.snackBar.open', 'Please select a channel');
      return;
    }
    this.clear();
    dispatch('ui.loadingModal.open', { message: 'Sending Shift' });

    try {
      await dispatch('chatChannels.share', {
        channel,
        pts: selected,
        sender: dispatch('auth.getUser'),
      });
      dispatch('ui.snackBar.open', `Shift shared with ${channel.channelName}`);
    } catch (err) {
      log.error('Failed to share shift', err);
      dispatch('ui.snackBar.open', 'Sorry, unable to share shift at this time');
    }

    dispatch('ui.loadingModal.close');
  }
}
