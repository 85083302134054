import type { ICompany, IQual } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { action, observable, set } from 'mobx';

import { app } from '#/shared/app';

import BaseTagStore from './_baseTagStore';

export default class QualificationsStore extends BaseTagStore<IQual> {
  constructor() {
    super({ baseItem: QualificationsStore.BASE_QUAL, serviceName: 'quals' });

    this.favorites = {};

    return this;
  }

  init() {
    super.init();

    app().on('login:company', ({ company }) => {
      this.getFavoriteQual(company.uuid);
    });
  }

  static BASE_QUAL: Partial<IQual> = {
    certType: 'qual',
    isAutoGenerated: false,
  };

  create({ data = null }) {
    if (!_.get(data, 'companies') && !_.get(data, 'company')) {
      const company = _.get(this.query, 'query.companies');

      if (_.isEmpty(company)) {
        return Promise.reject('Unable to create Qual without Company');
      }

      _.extend(data, { companies: _.isArray(company) ? company : [company] });
    }

    return super.create({ data });
  }

  @observable
  favorites: Record<ICompany['uuid'], Promise<IQual>>;

  /**
   * Loads the singular "Favorite" qual for a company used to determine the workers
   * that will be members in the "Favorites" pool. The `quals.favorites` hash object
   * is keyed by the companyId, and returns a Promise.
   *
   * This pattern of caching the promise has the advantage of not allowing dozens of
   * identical requests made at the same time. By "Caching Early", two (nearly)
   * simultaneous calls to this method will cause the first call to call `findOne` on
   * the server, while the second will return the promise of the first call's request.
   *
   * With `favorites` being observable, a subsequent call that creates a new qual will
   * update the underlying promise, and trigger a re-render of any functional-
   * observer components that rely on the value contained within.
   */
  @action.bound
  async getFavoriteQual(companyId: ICompany['uuid'], createIfNone?: boolean) {
    const favorite: Promise<IQual> = this.favorites?.[companyId] ?? null;

    if (!favorite) {
      this.log.debug('Loading Favorite for company', companyId);
      set(
        this.favorites,
        companyId,
        this.findOne({
          query: {
            companies: companyId,
            isAutoGenerated: true,
            title: 'favorite',
          },
        }),
      );
    }

    if (createIfNone && _.isEmpty(await this.favorites[companyId])) {
      this.log.debug('Creating Favorite for company', companyId);
      set(
        this.favorites,
        companyId,
        this.create({
          data: {
            companies: [companyId],
            isAutoGenerated: true,
            title: 'favorite',
          },
        }),
      );
    }

    return this.favorites?.[companyId];
  }
}
