import { dispatch } from 'rfx-core';
import _ from 'lodash';
import moment from 'moment';
import { v4 } from 'uuid';

import { mapUnifiedToSeparate } from '#/shared/forms/formUtils';
import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';

const log = getChildLogger('forms.paymentRules');

export class PaymentRule extends Form {
  hooks = () => ({
    onError() {
      const error = parseErrors(this.errors());
      log.error('Form Errored: ', error);

      let message = error.message || 'Unable to Save Payment Rule';

      if (
        this.$('bonusType').value === 'tiered' &&
        _.isEmpty(this.$('tiers').value)
      ) {
        message = 'Please add at least one tier';
      }

      if (_.isArray(error.errors)) {
        message =
          error.errors.length === 1
            ? error.errors[0]
            : `${error.message}: ${_.first(error.errors)} + ${
                error.errors.length - 1
              } more`;
      }
      dispatch('ui.snackBar.open', message);
    },

    onSuccess(form) {
      let storeName = 'paymentRules';

      const { __t: dataType, uuid } = form.values();

      switch (dataType) {
        case 'payDifferential':
          storeName = 'payDifferentials';
          break;
        case 'paymentBonus':
          storeName = 'paymentBonuses';
          break;
        default:
          break;
      }
      const userIds: string[] = dispatch('ui.bonusFormUploader.getUserIds');
      const storeAction = uuid ? `${storeName}.update` : `${storeName}.create`;

      const data = form.values();
      data.userIds = userIds;
      if (data.bonusType === 'tiered') {
        const completionTab = dispatch('ui.paymentBonusModal.getCompletionTab');
        const { totalAmount, totalGoal } = fitTierFormToData(
          JSON.parse(JSON.stringify(data.tiers)), //TODO: fix this
        );

        _.assign(data, { amount: totalAmount });
        if (completionTab === 'shifts') {
          _.assign(data, { count: totalGoal });
        } else if (completionTab === 'hours') {
          _.assign(data, { duration: totalGoal });
        }

        // generate uuid for tiers
        if (/create$/.test(storeAction)) {
          data.tiers = _.map(data.tiers, (tier) => {
            if (!tier.uuid) {
              tier.uuid = v4();
            }
            return tier;
          });
        }
      }
      if (data.automationSettings.geohashes) {
        const geohashes = data.automationSettings.geohashes.trim();
        data.automationSettings.geohashes = geohashes.split(',');
      }
      if (
        /create$/.test(storeAction) &&
        _(data.companies).compact().isEmpty()
      ) {
        throw new Error('Companies is Required');
      }

      if (data.validFrom && form.$('validFrom').isDirty) {
        data.validFrom = moment(data.validFrom).startOf('day').toDate();
      }
      if (!data.dynamicStart) {
        data.dynamicStart = null;
      }
      if (data.validTo && form.$('validTo').isDirty) {
        data.validTo = moment(data.validTo).endOf('day').toDate();
      }
      if (
        _.includes(
          [BonusTypeFirstShift, BonusTypeFirstOnboarding],
          data.bonusType,
        )
      ) {
        data.count = 1;
      }

      if (!data.recurringBonus) {
        delete data.recurrenceRules;
      }

      if (data.recurringBonus) {
        const cycleStartOption = data.recurrenceRules.cycleStartOption;
        if (!cycleStartOption) {
          log.error('Something went wrong while setting cycleStartOption');
        }

        // keep only the selected option in cycleStart
        const cycleStart: {
          date?: Date | string;
          dom?: number | 'last';
          dow?: number;
        } = {
          [cycleStartOption]: data.recurrenceRules.cycleStart[cycleStartOption],
        };

        if (
          cycleStartOption === 'dom' &&
          data.recurrenceRules.cycleStart?.ldom
        ) {
          cycleStart.dom = 'last';
        }
        data.recurrenceRules.cycleStart = cycleStart;

        delete data.recurrenceRules.cycleStartOption;
      }
      if (data.bonusType !== BonusTypeTiered) {
        delete data.tiers;
      }
      return dispatch(storeAction, { data })
        .then((res) =>
          dispatch(
            'ui.snackBar.open',
            res
              ? `Payment Rule ${form.values().uuid ? 'Updated' : 'Added'}`
              : 'No changes made to Payment Rules',
          ),
        )
        .then(() => dispatch('ui.paymentBonusModal.clear'))
        .then(() => form.clear())
        .catch((err) => {
          form.invalidate(err.message);

          dispatch(
            'ui.snackBar.open',
            err.message || 'Unable to Save Payment Rule',
          );
        });
    },
  });
}

function fitTierFormToData(tiers) {
  const totalAmount = _.sum(_.map(tiers, 'amount'));
  const totalGoal = _.sum(_.map(tiers, 'goal'));

  return {
    totalAmount,
    totalGoal, // same as count or duration
  };
}
function parseErrors(errors) {
  return _.pickBy(errors, (val) => {
    if (!val) {
      return false;
    }

    if (_.isObject(val)) {
      return !_.isEmpty(parseErrors(val));
    }

    return !!val;
  });
}

export const BonusTypeFirstShift = 'firstShift';
export const BonusTypeFirstOnboarding = 'firstOnboarding';
export const BonusTypeCompletion = 'completion';
export const BonusTypeTiered = 'tiered';
// holding back this type for now
export const BonusTypePerformanceBased = 'performanceBased';
export const bonusTypes = [
  BonusTypeFirstShift,
  BonusTypeFirstOnboarding,
  BonusTypeCompletion,
  BonusTypeTiered,
];

export const DaysToCompleteRules = 'numeric|between:1,400';
export const ValidToRules = 'date';
export const ValidFromRules = 'date';
export const CountRules = 'numeric|min:0';
export const DurationRules = 'numeric|min:0';
export const DynamicStartRules = 'string';

export const BonusEligibilityTypeShiftDuration = 'shiftDuration';
export const BonusEligibilityTypePayableDuration = 'payableDuration';
export const BonusEligibilityTypeCheckInOutTime = 'checkInOutTime';
export const bonusEligibilityTypes = [
  BonusEligibilityTypePayableDuration,
  BonusEligibilityTypeShiftDuration,
  BonusEligibilityTypeCheckInOutTime,
];
const BonusEligibilityDescriptions = {
  checkInOutTime: ' CheckOut Time - CheckInTime (Both values must be present)',
  payableDuration:
    'Custom Uploaded Payable Duration Value OR Shift End - Shift Start',
  shiftDuration: 'Shift End - Shift Start',
};
export const weekDaysDropdownOptions = [
  {
    key: 0,
    text: 'Sunday',
    value: 0,
  },
  {
    key: 1,
    text: 'Monday',
    value: 1,
  },
  {
    key: 2,
    text: 'Tuesday',
    value: 2,
  },
  {
    key: 3,
    text: 'Wednesday',
    value: 3,
  },
  {
    key: 4,
    text: 'Thursday',
    value: 4,
  },
  {
    key: 5,
    text: 'Friday',
    value: 5,
  },
  {
    key: 6,
    text: 'Saturday',
    value: 6,
  },
];

// NOTE: "Unified" field definitions are not well supported. If defining a form
// in this manner, you MUST use the mapUnifiedToSeparate utility and pass them into
// the constructor in that way.
export const fields = {
  __t: {
    label: 'Rule Type',
    output: (v) => (_.isEmpty(v) ? undefined : v),
    rules: 'string',
  },
  amount: {
    label: 'Bonus Amount',
    output: (v) => _.toFinite(v),
    rules: 'required|numeric|min:1|max:500|integer',
  },

  'automationSettings.assessmentCompleted': {
    label: 'Partner has Completed their Assessment',
    rules: 'boolean',
  },
  'automationSettings.campaignType': {
    extra: {
      options: [
        {
          key: 1,
          text: 'New Download',
          value: 'newDownload',
        },
        { key: 2, text: 'Latent Capacity', value: 'latentCapacity' },
        { key: 3, text: 'M1 Building', value: 'm1Building' },
        { key: 4, text: 'M1 yield', value: 'm1yield' },
        { key: 5, text: 'M1 Churn', value: 'm1Churn' },
      ],
    },
    label: 'Campaign Type',
  },

  'automationSettings.downloadBeginsAt': {
    label: 'Download Begins At',
    rules: 'date|is_valid_date',
  },

  'automationSettings.downloadEndsAt': {
    label: 'Download Ends At',
    rules: 'date|is_valid_date',
  },

  'automationSettings.geohashes': {
    label: 'Geohashes',
    placeholder: 'list of comma separated geohashes',
  },
  'automationSettings.hasAcceptedFirstShift': {
    label: 'Partner Has Accepted First Shift',
    rules: 'boolean',
  },
  'automationSettings.hasCompletedFirstShift': {
    label: 'Partner Has Completed First Shift',
    rules: 'boolean',
  },
  'automationSettings.hasMature1RoleTier': {
    label: 'Partner is Mature in Role',
    rules: 'boolean',
  },
  'automationSettings.msas': {
    default: [],
    label: 'MSAs',
  },

  bonusEligibilityType: {
    extra: {
      options: _.map(bonusEligibilityTypes, (bonusEligibilityType) => ({
        description: BonusEligibilityDescriptions[bonusEligibilityType],
        key: bonusEligibilityType,
        text: _.startCase(bonusEligibilityType),
        value: bonusEligibilityType,
      })),
    },
    label: 'Bonus Eligibility',
    rules: 'string',
  },
  bonusIncludesWages: {
    label: 'Bonus Amount includes wages earned from completed shifts',
    rules: 'boolean',
  },
  bonusType: {
    extra: {
      options: _.map(bonusTypes, (bonusType) => ({
        key: bonusType,
        text: _.startCase(bonusType),
        value: bonusType,
      })),
    },
    label: 'Bonus Type',
    rules: 'required|string',
  },
  companies: {
    description: 'Applicable Companies',
    label: 'Companies',
  },

  count: {
    label: 'Completed Shift Count Requirement',
    output: (v) => _.toFinite(v),
    rules: CountRules,
  },

  daysToComplete: {
    label: 'Days to Complete',
    rules: DaysToCompleteRules,
  },

  description: {
    label: 'Description',
    rules: 'string',
  },

  duration: {
    label: 'Completed Hours Requirement',
    output: (v) => _.toFinite(v),
    rules: DurationRules,
  },

  dynamicStart: {
    extra: {
      options: ['companyStartDate'],
    },
    label: 'Company Sign Up Date',
    rules: DynamicStartRules,
  },

  // Unique Popup Key
  focusedField: {
    default: null,
  },

  incentivizedBonusPercentage: {
    label: 'Incentivized Bonus Percentage',
    rules: 'numeric|min:1|max:100|integer',
  },

  incentivizedBonusWeeksCount: {
    label: 'The number of past weeks for Incentivized Bonus',
    rules: 'numeric|min:1|max:4|integer',
  },

  isDeleted: {
    default: false,
    label: 'Is this bonus deleted',
    rules: 'boolean',
  },

  key: {
    label: 'key (readonly)',
  },

  maxEligiblePartners: {
    label: 'Maximum Eligible Partners',
    rules: 'numeric|min:1|integer',
  },

  // `period` is used by PaymentDifferential while `daysToComplete` is used by paymentBonuses
  period: {
    extra: {
      options: ['day', 'week', 'month'],
    },
    label: 'Calculation Period',
    rules: 'string',
  },

  'recurrenceRules.cycleDays': {
    label: 'Cycle Days',
    rules: 'required|numeric|between:1,1000',
  },

  'recurrenceRules.cycleStart': {
    label: 'Cycle Start',
  },

  'recurrenceRules.cycleStart.date': {
    label: 'Date',
    rules: 'date|is_valid_date',
  },

  'recurrenceRules.cycleStart.dom': {
    label: 'Day of month',
    rules: 'numeric|min:1|max:31',
  },

  'recurrenceRules.cycleStart.dow': {
    extra: {
      options: weekDaysDropdownOptions,
    },
    label: 'Day of week',
    rules: 'numeric|min:0|max:6',
  },

  'recurrenceRules.cycleStart.ldom': {
    label: 'Last day of the month',
    rules: 'boolean',
  },

  'recurrenceRules.cycleStartOption': {
    default: 'dow',
  },

  recurringBonus: {
    label: 'Recurring Bonus',
    rules: 'boolean',
  },

  requirePerfectAttendance: {
    label: 'Require Perfect Attendance',
    rules: 'boolean',
  },

  restrictToFirstWorkedShifts: {
    label:
      'Restrict to New Partners who have never completed a shift with this company',
    rules: 'boolean',
  },

  roleId: {
    description: 'Applicable Roles',
    label: 'Roles',
  },

  tierAmount: {
    rules: 'numeric|min:1|max:500|integer',
  },

  tierGoal: {
    rules: 'numeric|min:1|max:500|integer',
  },

  tiers: {
    default: null,
    label: 'Tiers',
  },

  title: {
    label: 'Bonus Name',
    rules: 'required|string',
  },

  'totalCompletedShiftsLastNdays.numberOfDays': {
    extra: {
      options: [
        {
          key: 0,
          text: '7',
          value: 7,
        },
        {
          key: 0,
          text: '30',
          value: 30,
        },
      ],
    },
    label: 'Number of last N days to have completed shifts',
    rules: 'numeric|min:0',
  },

  'totalCompletedShiftsLastNdays.numberOfShifts': {
    label: 'Minimum Number of shifts in N number of days',
    rules: 'numeric|min:0',
  },

  userIds: {
    default: [],
    label: 'Users selected for the bonus',
  },

  uuid: {
    label: 'UUID',
    rules: 'string',
  },

  validFrom: {
    description: 'First date this rule will apply',
    label: 'Start Date',
    rules: ValidFromRules,
  },
  validTo: {
    description: 'Last date this rule will apply',
    label: 'End Date ',
    rules: ValidToRules,
  },

  viewInAdvance: {
    label: 'Display the bonus in advance',
    rules: 'boolean',
  },
};

export function init({ values = {}, defaults = {} } = {}) {
  const separatePropertyFields = mapUnifiedToSeparate(fields);

  return new PaymentRule({
    ...separatePropertyFields,
    defaults,
    values: _.defaults(values, defaults),
  });
}
