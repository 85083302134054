import type { IInShiftTask } from '@shiftsmartinc/shiftsmart-types';

import {
  taskStatus,
  ERROR_STATUSES,
} from '#/shared/components/inShiftTasks/utils';

import BaseStore from './_baseStore';

export default class InShiftTasksStore extends BaseStore<IInShiftTask> {
  constructor() {
    super({
      baseItem: InShiftTasksStore.BASE_ITEM,
      serviceName: 'inShiftTasks',
    });

    return this;
  }

  static BASE_ITEM: IInShiftTask = {
    addressObj: null,
    assignedUser: null,
    category: null,
    companyId: null,
    createdAt: null,
    description: null,
    modelName: null,
    sequenceId: null,
    shiftId: null,
    shiftObj: null,
    title: null,
    updatedAt: null,
    uuid: null,
  };

  get numberOfTaskErrors() {
    return this.list.filter((task: IInShiftTask) => {
      const status = taskStatus(task);
      return ERROR_STATUSES.includes(status);
    }).length;
  }
}
