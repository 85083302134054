import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class PartnerAvatarModal {
  log = getChildLogger('ui.PartnerAvatarModal');

  @observable
  isOpen = false;

  @observable
  user = {};

  @observable
  isEditing = false;

  @observable
  storeName = 'workers';

  @action
  setup({
    worker,
    user = worker,
    storeName = 'workers',
    open = this.isOpen,
    fileRef,
  }) {
    this.user = user;
    this.isOpen = open;
    this.storeName = storeName;

    // hhmmmm
    this.isEditing = open;

    const { profileImageURL } = this.user;

    return dispatch('ui.avatarEditor.setup', {
      fileRef,
      imageURL: profileImageURL,
      onSave: this.save,
    });
  }

  @action
  save() {
    const log = this.log.getChildLogger('save');
    const { data } = dispatch('ui.avatarEditor.getImageBlob');
    dispatch(`${this.storeName}.setAvatar`, {
      data,
      userId: _.get(this.user, 'uuid', this.user),
    })
      .then(() => this.clear())
      .catch((err) => {
        log.error('failed to save image', err);
      });
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    dispatch('ui.avatarEditor.clear');
    this.user = {};
    this.isOpen = false;
    this.storeName = 'workers';
    this.isEditing = false;
  }

  // Migrated

  // TODO: Ensure standard user can still edit their image
  // defaultOnSave() {
  //   return dispatch('auth.setAvatar', { data: imageBlob }).then((res) => {
  //     dispatch('ui.avatarEditor.setImage', res.profileImageURL);
  //     dispatch('ui.avatarEditor.toggleEdit');
  //   });
  // }

  @action
  setImage(image) {
    dispatch('ui.avatarEditor.setImage', image);
  }

  @action
  toggleEdit() {
    dispatch('ui.avatarEditor.toggleEdit');
  }
}
