import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export type ICompanyScheduleSummaries = IBaseItem & Record<string, unknown>;

export default class CompanyScheduleSummariesStore extends BaseStore<ICompanyScheduleSummaries> {
  constructor() {
    super({
      baseItem: CompanyScheduleSummariesStore.BASE_ITEM,
      serviceName: 'companyScheduleSummaries',
    });

    return this;
  }

  static BASE_ITEM = {
    assignedCost: null,
    assignedCount: null,
    assignedDuration: null,
    atRiskShifts: null,
    company: null,
    companyPath: null,
    createdAt: null,
    customMetrics: null,
    date: null,
    deletedAt: null,
    filledSlots: null,
    isDeleted: null,
    noShowCost: null,
    noShowCount: null,
    noShowDuration: null,
    positionId: null,
    shiftCount: null,
    steps: null,
    title: null,
    totalHours: null,
    totalSlots: null,
    updatedAt: null,
    uuid: null,
  };
}
