import type {
  IBaseItem,
  IDispatchPref,
  INotificationPref,
  IShift,
  ShiftDispatchEventConfigRecord,
} from '@shiftsmartinc/shiftsmart-types';

import Promise from 'bluebird';
import _, { ListIterator } from 'lodash';
import moment, { Moment } from 'moment';

import { getChildLogger } from '../utils/client.logger';

import BaseStore from './_baseStore';

export type IDispatchPrefs = IBaseItem & Record<string, unknown>;

export default class DispatchPrefsStore extends BaseStore<IDispatchPref> {
  constructor() {
    const baseItem = {
      companies: [],
      prefs: [],
      title: '',
    };
    super({
      baseItem,
      enableLocalResponseEventing: true,
      serviceName: 'dispatchPrefs',
    });

    return this;
  }

  // #region Util Methods

  async getCompanyPrefs({ company, query = {} }) {
    const companyId = _.get(company, 'uuid', company);
    /**
     * While we load `Admin -> Uploaders` directly, the company object is initially empty
     * For the first render, instead of throwing error, logging as a warn
     */
    if (_.isEmpty(companyId) || !_.isString(companyId)) {
      this.log.warn('Invalid Company sent as parameter');
      return [];
    }
    const prefResults = [];
    query.$skip = 0;
    _.set(query, 'companies', companyId);
    const { data, total, limit } = await this.runQuery(query);
    prefResults.push(...data);

    const batches = Math.ceil(total / limit);
    await Promise.map(
      _.range(1, batches),
      async (batch) => {
        query.$skip = batch * limit;
        const result = await this.runQuery(query);
        prefResults.push(...result.data);
      },
      { concurrency: 1 },
    );

    return prefResults;
  }
}

// TODO: Rectify with api/src/services/shifts/utils/notificationPrefUtils

export function getSortedNotificationPrefs(
  list,
  shift: Partial<IShift> & Required<Pick<IShift, 'start' | 'activatedAt'>>,
  filter?: ListIterator<INotificationPref, boolean>,
): Array<INotificationPref & { expectedDelivery: Moment }> {
  return _(list)
    .map((np: INotificationPref) => {
      const expectedDelivery = getExpectedDelivery(np, shift);

      return { expectedDelivery, ...np };
    })
    .filter(filter ?? _.identity)
    .sortBy('expectedDelivery')
    .value();
}

export function getExpectedDelivery(
  pref: ShiftDispatchEventConfigRecord,
  shift?: Partial<IShift> & Required<Pick<IShift, 'start' | 'activatedAt'>>,
): Moment {
  const { sentAt, delay, baselineTime } = pref;
  let expectedDelivery = moment();

  if (sentAt) {
    expectedDelivery = moment(sentAt);
  } else if (!delay) {
    expectedDelivery = moment(baselineTime || shift?.activatedAt || undefined);
  } else if (delay < 0) {
    expectedDelivery = moment(shift?.start).add({ minutes: delay });
  } else if (delay > 0) {
    expectedDelivery = moment(
      baselineTime || shift?.activatedAt || undefined,
    ).add({
      minutes: delay,
    });
  }

  return expectedDelivery;
}
