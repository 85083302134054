import React from 'react';
import { observer } from 'mobx-react';
import { Loader, Progress, Dimmer } from 'semantic-ui-react';
import _ from 'lodash';
import { dispatch } from 'rfx-core';

import Modal from '#/ssm-ui/Modal';

const AgendaProgressLoadingModal = observer(
  ({ isOpen, message, agendaJobProgressObj }) => (
    <Modal
      dimmer="blurring"
      onClose={() => {
        if (
          !_.isEmpty(agendaJobProgressObj) &&
          agendaJobProgressObj.progress >= 100
        ) {
          dispatch('ui.agendaProgressLoadingModal.close');
        }
      }}
      open={isOpen}
      size="small"
      style={{
        backgroundColor: '#FFFFFFCC',
        zIndex: 2000,
      }}
    >
      <Modal.Content className="h5 flex items-center">
        {!_.isEmpty(agendaJobProgressObj) ? (
          <div className="ph4 pv4">
            <Progress
              indicating={true}
              percent={agendaJobProgressObj.progress}
              success={agendaJobProgressObj.progress === 100}
            />
          </div>
        ) : (
          <Dimmer active={true}>
            <Loader content={message || 'Loading'} size="huge" />
          </Dimmer>
        )}
      </Modal.Content>
    </Modal>
  ),
);

export default AgendaProgressLoadingModal;
