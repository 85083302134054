import type { IProject } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export default class ProjectsStore extends BaseStore<IProject> {
  constructor() {
    super({
      baseItem: ProjectsStore.BASE_ITEM,
      serviceName: 'projects',
    });

    return this;
  }

  static BASE_ITEM: IProject = {
    companyId: null,
    createdAt: null,
    uuid: null,
  };
}
