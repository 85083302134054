import type { IPartnerAssessmentStatus } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export default class PartnerAssessmentStatusStore extends BaseStore<IPartnerAssessmentStatus> {
  constructor() {
    super({
      baseItem: PartnerAssessmentStatusStore.BASE_ITEM,
      serviceName: 'partnerAssessmentStatuses',
    });

    return this;
  }

  static BASE_ITEM: IPartnerAssessmentStatus = {
    companyId: null,
    createdAt: null,
    uuid: null,
  };
}
