import type { Question } from 'survey-react';

import { observable, action, set, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.campaignWorkReview');

export default class CampaignWorkReview {
  @observable
  assignment = {};

  @observable
  campaign = {};

  @observable
  isEditing = false;

  @observable
  isConfirmingEdits = false;

  @observable
  editedQuestions = [];

  @observable
  editedValues = {};

  @observable
  titles = {};

  @observable
  questions = [];

  @observable
  isConfirmingRatingChange = false;

  @observable
  workerRating = '';

  @observable
  isConfirmingStatusChange = false;

  @observable
  reviewStatus = '';

  @action
  setup = async ({ assignment, questions }) => {
    this.assignment = assignment;
    this.questions.replace(questions);
    this.reviewStatus = assignment.reviewStatus;

    const campaignId = _.get(this.assignment, 'campaign');

    if (campaignId) {
      const campaign = await dispatch('campaigns.get', campaignId, {
        select: false,
      });

      runInAction(() => {
        set(this.campaign, campaign);
      });
    }
  };

  getAnswer = ({ question }) => ({
    other: this.getCommentText({
      question,
    }),
    text: this.getAnswerText({
      question,
    }),
    value: this.getAnswerValue({
      question,
    }),
  });

  isQuestionDirty({ question }) {
    return (
      _.has(this.editedValues, question.name) ||
      _.has(this.titles, question.name)
    );
  }

  /** #region Question Answer Lookups */
  /** getAnswerValue
   *
   * @param {Question} question : The Question to lookup the value for
   * @param {String} key : The question key, or `question.name`, for use when the question object is not available.
   * @param {Boolean} original : If set, forces return of the original response value
   *
   * @returns the selected value of the specified question.
   */
  getAnswerValue({ question: q, key, original }) {
    const question = q || _.find(this.questions, { name: key });
    let retval = this.assignment?.surveyResponseData?.[question.name] ?? '';

    if (!original && this.isQuestionDirty({ question })) {
      retval = _.get(this.editedValues, `${question.name}`) || '';
    }

    log.silly(
      `Question "${question.name}" has an ${
        original ? 'original ' : ''
      }answer of: `,
      {
        answer: retval,
      },
    );

    return retval;
  }

  /** getAnswerText
   *
   * @param {Question} question : The Question to lookup the value for
   * @param {String} key : The question key, or `question.name`, for use when the question object is not available.
   * @param {Boolean} original : If set, forces return of the original response value
   *
   * @returns the text value of the specified question. This is especially relevant for checkbox and radiogroup
   *          question types as the value is not very helpful on its own. In the case of checkbox question types,
   *          the text values will be joined by commas plus a space (`, `)
   */
  getAnswerText({ question: q, key, original }) {
    const question = q || _.find(this.questions, { name: key });

    const answer = this.getAnswerValue({ original, question });

    switch (question.getType()) {
      case 'checkbox':
        // Checkbox answers are stored in an array, but may be an empty string if no response is present.
        if (!_.isArray(answer)) {
          return answer;
        }
        return (answer || [])
          .map((ans) => this.getSelectionText({ answer: ans, question }))
          .join(', ');

      case 'radiogroup':
        return this.getSelectionText({ answer, question });

      case 'matrixdynamic':
        log.debug('matrixdynamic');
        return '';

      case 'matrixdropdown':
        log.debug('matrixdropdown');
        return '';

      case 'file':
      default:
        return answer;
    }
  }

  /** getSelectionText
   *
   * @param {Question} question : The Question to lookup the value for
   * @param {String} answer : The answer to the question to lookup the text value for
   *
   * @returns the text value of the specified question. This is especially relevant for checkbox and radiogroup
   *          question types as the value is not very helpful on its own. In the case of checkbox question types,
   *          the text will be returned as an array.
   */
  getSelectionText({ answer, question }) {
    const selection = question.choices.find(
      (choice) => _.get(choice, 'value') === answer,
    );
    if (!selection) {
      if (
        answer === 'other' &&
        question.hasOther &&
        question.storeOthersAsComment
      ) {
        return _.get(question, 'otherItem.text', 'Other');
      }
    }

    // Default to the "text" and fallback to the itemValue
    return _.get(selection, 'text', _.get(selection, 'itemValue', selection));
  }

  getCommentText({ question, original }) {
    return !original && this.editedValues[`${question.name}-Comment`]
      ? _.get(this.editedValues, `${question.name}-Comment`, '')
      : _.get(
          this.assignment,
          `surveyResponseData.${question.name}-Comment`,
          '',
        );
  }

  /** #region Event Handlers */

  @action
  toggleEditing(isEditing) {
    this.isEditing = isEditing;
  }

  @action setAssignment = (assignment) => {
    this.assignment = assignment;
  };

  @action setOpenReviewConfirm = (value) => {
    this.isConfirmingStatusChange = value;
  };

  @action setReviewStatus = (value) => {
    this.reviewStatus = value;
  };

  @action
  editQuestionResponse({
    question,
    isComment,
    value,
  }: {
    isComment?: boolean;
    question: Question;
    value?: string | string[];
  }) {
    const questionName = question.name + (isComment ? '-Comment' : '');
    const title = question.title;

    log.silly(`Changing Value of ${questionName} to "${value}"`);

    if (!_.find(this.editedQuestions, { name: questionName })) {
      this.editedQuestions.push(question);
    }
    set(this.editedValues, questionName, value);
    set(this.titles, questionName, title);
  }

  /* #region UI Event Handlers */

  /* #region Question Response Editing Flow */
  @action
  handleSaveClick = () => {
    this.isConfirmingEdits = true;
    this.isEditing = false;
  };

  handleConfirmClick = async ({ user }) => {
    if (_.isEmpty(this.editedValues)) {
      this.clearQuestionResponseEdits();
      return;
    }
    const surveyResponseData = _.get(this.assignment, 'surveyResponseData');
    const newSurveyResponseData = _.extend(
      {},
      surveyResponseData,
      this.editedValues,
    );

    const patchData = {
      reviewLog: this.assignment.reviewLog ? this.assignment.reviewLog : [],
      surveyResponseData: newSurveyResponseData,
      uuid: this.assignment.uuid,
    };

    if (!this.assignment.originalSurveyResponseData) {
      patchData.originalSurveyResponseData = surveyResponseData;
    }

    patchData.reviewLog.push({
      edited: this.editedValues,
      status: this.assignment.reviewStatus,
      time: new Date(),
      user: _.get(user, 'uuid', user),
    });

    try {
      const updatedAssignment = await dispatch('assignments.update', {
        data: patchData,
      });

      this.setAssignment(updatedAssignment);
      this.clearQuestionResponseEdits();
    } catch (err) {
      log.error('Update Assignment Survey Response Failed', err);
      dispatch('ui.snackBar.open', err.message);
    }
  };

  @action
  handleCancelClick = () => {
    if (this.isEditing) {
      this.clearQuestionResponseEdits();
    } else {
      this.isConfirmingEdits = false;
      this.isEditing = true;
    }
  };
  /* #endregion Question Response Editing Flow */

  /* #region Review Status Change Flow */

  @action
  handleReviewStatusChange = ({ value }) => {
    if (this.assignment.reviewStatus === value) {
      return;
    }
    this.reviewStatus = value;
    this.setOpenReviewConfirm(true);
  };

  handleReviewStatusConfirm = async ({ user }) => {
    const patchData = {
      reviewLog: this.assignment.reviewLog ? this.assignment.reviewLog : [],
      reviewStatus: this.reviewStatus,
      uuid: this.assignment.uuid,
    };
    patchData.reviewLog.push({
      status: this.reviewStatus,
      // ATTN: Assignment Status EP-5523
      time: new Date(),
      user: _.get(user, 'uuid', user),
    });
    try {
      const updatedAssignment = await dispatch('assignments.update', {
        data: patchData,
      });

      this.setAssignment(updatedAssignment);
      this.clearReviewStatusEdits();
    } catch (err) {
      log.error('Update Assignment Review Status Failed', err);
      dispatch('ui.snackBar.open', err.message);
    }
  };

  handleReviewStatusCancel = () => {
    this.clearReviewStatusEdits();
  };
  /* #endregion Review Status Change Flow */

  /* #region Worker Rating Change Flow */
  @action
  handleWorkerRatingChange = ({ data }) => {
    if (this.assignment.workerRating === data.rating && !this.workerRating) {
      return;
    }
    this.isConfirmingRatingChange = true;
    this.workerRating = data.rating;
  };

  handleWorkerRatingConfirm = async () => {
    if (this.assignment.workerRating !== this.workerRating) {
      try {
        const updatedAssignment = await dispatch('assignments.rate', {
          assignment: this.assignment,
          notes: '',
          rating: this.workerRating,
          worker: this.assignment.user,
        });

        this.setAssignment(updatedAssignment);
        this.clearWorkerRatingEdits();
      } catch (err) {
        log.error('Update Assignment Rating Failed', err);
        dispatch('ui.snackBar.open', err.message);
      }
    } else {
      this.clearWorkerRatingEdits();
    }
  };

  handleWorkerRatingCancel = () => {
    this.clearWorkerRatingEdits();
  };
  /* #endregion Worker Rating Change Flow */

  /* #endregion UI Event Handlers */

  @action
  clear() {
    this.assignment = {};
    this.campaign = {};
    this.questions.clear();

    this.clearQuestionResponseEdits();
    this.clearReviewStatusEdits();
    this.clearWorkerRatingEdits();
  }

  @action
  clearQuestionResponseEdits() {
    this.isEditing = false;
    this.isConfirmingEdits = false;

    this.editedQuestions.clear();
    this.editedValues = {};
    this.titles = {};
  }

  @action
  clearReviewStatusEdits() {
    this.reviewStatus = this.assignment.reviewStatus || '';
    this.isConfirmingStatusChange = false;
  }

  @action
  clearWorkerRatingEdits() {
    this.isConfirmingRatingChange = false;
    this.workerRating = '';
  }
}
