import type { ITag } from '@shiftsmartinc/shiftsmart-types';

import { action, set, observable } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { service } from '#/shared/app';
import { getChildLogger } from '#/shared/utils/client.logger';

import BaseStore, { StoreConstructorProps } from './_baseStore';

const log = getChildLogger('BaseTagStore');

export default class BaseTagStore<T extends ITag = ITag> extends BaseStore<T> {
  constructor({
    serviceName,
    baseItem = {},
    cacheSize = 200,
    title: baseTitle,
    ...rest
  }: StoreConstructorProps<T>) {
    super({
      baseItem: { ...BaseTagStore.BASE_ITEM, ...baseItem },
      cacheSize,
      serviceName,
      ...rest,
      searchFields: ['title'],
      title: baseTitle || _.get(baseItem, 'certType') || serviceName,
    });
    log.verbose(`Initialized new ${serviceName} instance of BaseTagStore`);

    return this;
  }

  static BASE_ITEM = {
    companies: [],
    connectedBadges: [],
    count: 0,
    edges: [],
    image: null,
    isVerified: false,
    level: null,
    linked: [],
    links: [],
    parent: null,
    title: null,
    users: [],
  };

  @observable
  reviewCerts = false;

  find(query = {}, { clear = false, filter = {}, save = true, ...rest } = {}) {
    const baseQuery = _.isUndefined(query.query) ? { query } : query;

    _.set(baseQuery, 'query.certType', this.baseItem.certType);

    this.log.debug(`Querying ${this.serviceName} on query: `, baseQuery);

    return super
      .find(baseQuery, { clear, filter, save, ...rest })
      .then(() => {
        if (
          this.query.query['links.uuid'] &&
          save &&
          this.$pagination.total < 10
        ) {
          const altQuery = _.cloneDeep(this.query);
          delete altQuery['links.uuid'];
          altQuery.query.$limit = 10;

          return service(this.serviceName)
            .find(altQuery)
            .then(({ data }) => {
              _.each(
                data,
                action((tag) => {
                  if (!_.find(this.list, { uuid: tag.uuid })) {
                    const d = _.cloneDeep(data);
                    d.isUnlinked = true;
                    this.list.push(d);
                  }
                }),
              );

              return this.list;
            });
        }

        return this.list;
      })
      .catch((err) => this.logAndThrow(err));
  }

  /* Actions */

  @action
  setSelected(json = {}) {
    if (_.isEmpty(json)) {
      return this.clearSelected();
    }

    this.log.debug('Setting Selected %s: %o', this.serviceName, json);

    if (_.keys(json).length < 5 || _.isString(json)) {
      set(this.selected, { linked: [] });
      return this.get(_.get(json, 'uuid', json), { force: true });
    }
    this.selected = _.extend({ linked: [] }, json);

    service('certs')
      .find({ query: { 'links.uuid': this.selected.uuid } })
      .then(
        action((res) => {
          this.selected.linked = res.data;
        }),
      );

    return Promise.resolve(this.selected);
  }

  @action
  sortBy(sortBy, opts) {
    let sortOpt = sortBy;

    if (_.isString(sortBy)) {
      let dir = _.get(this.sort, sortBy);
      if (_.isNumber(dir)) {
        dir = dir === 1 ? -1 : 1;
      } else {
        dir = -1;
      }

      switch (sortBy) {
        case 'createdAt':
        case 'count':
          sortOpt = { [sortBy]: dir };
          break;
        default:
          dispatch('ui.snackBar.open', 'Unknown Sort Option');
          return Promise.reject(new Error(`Unknown Sort Option: ${sortBy}`));
      }
    }

    return super.sortBy(sortOpt, opts);
  }

  @action
  toggleReviewModeFilter(review) {
    log.debug('Setting Certs Review Mode: ', review);
    if (this.reviewCerts === review) {
      return Promise.resolve(this.list);
    }

    this.reviewCerts = review;

    let query = { links: undefined };

    if (this.reviewCerts) {
      query = { links: { $eq: [] } };
    }

    return this.find({ query });
  }

  /**
   * createLink
   * Creates a new Hard Link between two items. The link is uni-directional
   * by default, and draws an edge from the item to the linked item.
   *
   * @param {string|object} item The item being updated with the link.
   * @param {string|object} link The item being linked to.
   */
  async createLink({ item, link }) {
    const c = await dispatch('tags.get', _.get(item, 'uuid', item));

    if (_.isEmpty(c)) {
      return Promise.reject('Cert "%s" not found', _.get(item, 'uuid', item));
    }

    const i = _.isString(link) ? await this.get(link) : link;
    const newLink = {
      certType: i.certType,
      createdAt: Date.now(),
      title: i.title,
      uuid: i.uuid,
    };

    let data;

    if (_.has(c, 'links') && _.isArrayLike(c.links)) {
      if (_.find(c.links, { uuid: i.uuid })) {
        this.log.warn('Cert is already linked to this industry');
      } else {
        data = { $push: { links: newLink } };
      }
    } else {
      data = { links: [newLink] };
    }

    if (data) {
      return dispatch('tags.update', { data, id: c.uuid });
    }

    return Promise.resolve();
  }

  getStoreName(dataType = this.title) {
    return dataType.replace(/y$/, 'ie').replace(/([^s]$)/, '$1s');
  }
}
