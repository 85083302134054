import React from 'react';
import { Route, IndexRedirect, IndexRoute } from 'react-router';

import { getChildLogger } from '#/shared/utils/client.logger';

import AppLayout from './containers/AppLayout';
import NotFound from './containers/NotFound';

const log = getChildLogger('routes');

/**
 * @description This should help us begin to make better use of some
 * folder structures that will scale a bit better with our App Size.
 */
function $importRoute(_location, cb, path) {
  return import(/* webpackChunkName: '[route]' */ `../routes/${path}`)
    .then((module) => cb(null, module.default))
    .catch((err) => {
      if (!/document is not defined/i.test(err.message)) {
        log.error('Dynamic "route" loading failed', err);
      }
    });
}

function $import(_location, cb, component) {
  return import(/* webpackChunkName: '[request]' */ `./containers/${component}`)
    .then((module) => cb(null, module.default))
    .catch((err) => {
      if (!/document is not defined/i.test(err.message)) {
        log.error('Dynamic page loading failed', err);
      }
    });
}

/**
 * Roles available in app. See SE-534 for More
 * -------------------------------------------
 * - user: anyone with a login + password
 * - admin: Super user - reserved for internal use
 * - employer: "Admin" of each company - this is the master account
 * - company-agent: "Agent" for each company, restricted permissions
 * - worker: One of our partners - available for work and using the mobile app
 *
 * Permission Aliases:
 * -------------------
 * - "*" : Allows any logged in user to access the route
 * - "guest" : Allows non-authenticated users to access the route
 */

export default (
  <Route className="pl5-ns" component={AppLayout} path="/">
    <IndexRoute
      getComponent={(loc, cb) => $import(loc, cb, 'Dashboard')}
      name="Dashboard"
    />

    <Route
      getComponent={(loc, cb) => $import(loc, cb, 'Auth')}
      path="auth"
      roles={['guest', '*']}
    />

    <Route
      getComponent={(loc, cb) => $import(loc, cb, 'TaskImages')}
      path="task-image"
      roles={['guest', '*']}
    />

    <Route
      getComponent={(loc, cb) => $import(loc, cb, 'Callback')}
      path="callback"
      roles={['guest', '*']}
    />

    {/** SSM Core : START */}

    <Route name="Shifts" path="shifts" roles={['employer', 'company-agent']}>
      <IndexRoute
        getComponent={(loc, cb) => $import(loc, cb, 'ShiftsNextGen')}
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'ShiftUpload')}
        path="upload"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'CreateShift')}
        path="create(/:type)"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'ShiftDetails')}
        path="(:shiftId)"
      />
    </Route>

    <Route
      name="In Shift Tasks"
      path="inshifttasks"
      roles={['employer', 'company-agent']}
    >
      <IndexRoute
        getComponent={(loc, cb) => $import(loc, cb, 'InShiftTasksContainer')}
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'InShiftTaskSummaryPage')}
        path="(:taskId)"
      />
    </Route>

    <Route
      name="Task List Review"
      path="task-review"
      roles={['employer', 'company-agent']}
    >
      <IndexRoute
        getComponent={(loc, cb) => $import(loc, cb, 'TaskListReview')}
      />
    </Route>

    <Route
      name="Dispatch Prefs"
      path="dispatchPrefs"
      roles={['employer', 'company-agent']}
    >
      <IndexRoute
        getComponent={(loc, cb) =>
          $import(loc, cb, 'dispatchPrefs/DispatchPrefsList')
        }
      />
      <Route
        getComponent={(loc, cb) =>
          $import(loc, cb, 'dispatchPrefs/DispatchPrefDetails')
        }
        path="(:dispatchPrefId)"
      />
    </Route>

    <Route
      getComponent={(loc, cb) => $import(loc, cb, 'ScheduleExploration')}
      name="ScheduleExploration"
      path="schedules(/:partnerId)"
      roles={['admin']}
    />

    <Route path="partners" roles={['employer', 'company-agent']}>
      <IndexRoute getComponent={(loc, cb) => $import(loc, cb, 'Partners')} />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'TagUpload')}
        path="tagupload"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'PartnerDetails')}
        path="(:userId)"
      />
    </Route>

    <Route path="pools" roles={['employer', 'company-agent']}>
      <IndexRoute getComponent={(loc, cb) => $import(loc, cb, 'Pools')} />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'PoolUpload')}
        path="upload(/:poolId)"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'Pools')}
        path="(:poolId)"
      />
    </Route>

    <Route
      getComponent={(loc, cb) => $import(loc, cb, 'Chat')}
      path="chats(/:chatId)"
      roles={['employer', 'company-agent', 'admin']}
    />

    {/** SSM Core : END */}

    <Route path="map" roles={['employer', 'company-agent']}>
      <IndexRoute getComponent={(loc, cb) => $import(loc, cb, 'Map')} />
    </Route>

    <Route path="recurringSchedules">
      <IndexRoute
        getComponent={(loc, cb) =>
          $import(loc, cb, 'recurringSchedules/RecurringSchedulesListContainer')
        }
      />
      <Route path=":recurringScheduleId">
        <IndexRoute
          getComponent={(loc, cb) =>
            $import(
              loc,
              cb,
              'admin/recurringSchedules/RecurringScheduleInfoPage',
            )
          }
        />
      </Route>
      <Route path=":recurringScheduleId/details">
        <IndexRoute
          getComponent={(loc, cb) =>
            $import(
              loc,
              cb,
              'admin/recurringSchedules/RecurringScheduleShiftTemplates',
            )
          }
        />
      </Route>
    </Route>
    <Route path="onboarding" roles={['employer', 'company-agent', 'admin']}>
      <IndexRoute
        getComponent={(loc, cb) =>
          $import(loc, cb, 'onboarding/OnboardingListContainer')
        }
      />

      <Route path=":onboardingId">
        <IndexRoute
          getComponent={(loc, cb) =>
            $import(loc, cb, 'onboarding/OnboardingSummaryPage')
          }
        />
      </Route>
      <Route path=":onboardingId/details">
        <IndexRoute
          getComponent={(loc, cb) =>
            $import(loc, cb, 'onboarding/OnboardingStepDetails')
          }
        />
      </Route>
    </Route>
    <Route
      path="videoReviews"
      roles={['employer', 'company-agent', 'video-review']}
    >
      <IndexRoute
        getComponent={(loc, cb) => $import(loc, cb, 'VideoReviews')}
      />
    </Route>

    {/** Retail : START */}
    <Route path="retail" roles={['employer', 'company-agent']}>
      <IndexRoute
        getComponent={(loc, cb) => $import(loc, cb, 'campaigns/Campaigns')}
      />

      <Route path="trainings">
        <IndexRoute getComponent={(loc, cb) => $import(loc, cb, 'Trainings')} />
        <Route
          getComponent={(loc, cb) => $import(loc, cb, 'Trainings')}
          path=":trainingId"
        />
      </Route>

      <Route path="campaigns" roles={['employer', 'company-agent']}>
        <IndexRoute
          getComponent={(loc, cb) => $import(loc, cb, 'campaigns/Campaigns')}
        />

        <Route path="work">
          <IndexRoute
            getComponent={(loc, cb) =>
              $import(loc, cb, 'campaigns/CampaignWork')
            }
          />

          <Route
            getComponent={(loc, cb) =>
              $import(loc, cb, 'campaigns/CampaignWorkReview')
            }
            path=":assignmentId"
          />
        </Route>

        <Route
          getComponent={(loc, cb) =>
            $import(loc, cb, 'campaigns/CampaignReports')
          }
          path="reports"
        />
        <Route
          getComponent={(loc, cb) =>
            $import(loc, cb, 'campaigns/ShopApprovalUploader')
          }
          path="approve"
          roles={['admin']}
        />

        <Route path=":campaignId">
          <IndexRoute
            getComponent={(loc, cb) => $import(loc, cb, 'campaigns/Campaigns')}
          />

          <Route
            getComponent={(loc, cb) =>
              $import(loc, cb, 'campaigns/CampaignQuotaEditor')
            }
            path="quotas"
          />

          <Route path="work">
            <IndexRoute
              getComponent={(loc, cb) =>
                $import(loc, cb, 'campaigns/CampaignWork')
              }
            />

            <Route
              getComponent={(loc, cb) =>
                $import(loc, cb, 'campaigns/CampaignWorkReview')
              }
              path=":assignmentId"
            />
          </Route>
        </Route>
      </Route>

      <Route path="stores" roles={['employer', 'company-agent']}>
        <IndexRoute getComponent={(loc, cb) => $import(loc, cb, 'Stores')} />
        <Route
          getComponent={(loc, cb) => $import(loc, cb, 'Store')}
          path="(:storeId)"
        />
      </Route>
      <Route path="storeLists" roles={['employer', 'company-agent']}>
        <IndexRoute
          getComponent={(loc, cb) =>
            $import(loc, cb, 'storeLists/StoreListsList')
          }
        />
        <Route
          getComponent={(loc, cb) =>
            $import(loc, cb, 'storeLists/StoreListDetails')
          }
          path="(:storeListId)"
        />
      </Route>

      <Route path="surveys" roles={['employer', 'company-agent']}>
        <IndexRoute getComponent={(loc, cb) => $import(loc, cb, 'Surveys')} />

        <Route
          getComponent={(loc, cb) => $import(loc, cb, 'SurveyBuilder')}
          path="builder"
          roles={['employer', 'company-agent']}
        />

        <Route
          getComponent={(loc, cb) => $import(loc, cb, 'SurveyResults')}
          path="(:surveyId)/results"
          roles={['employer', 'company-agent']}
        />

        <Route path="(:surveyId)">
          <IndexRoute getComponent={(loc, cb) => $import(loc, cb, 'Survey')} />
          <Route
            getComponent={(loc, cb) => $import(loc, cb, 'SurveyBuilder')}
            path="edit"
          />
        </Route>
      </Route>

      <Route path="products" roles={['employer', 'company-agent']}>
        <IndexRoute getComponent={(loc, cb) => $import(loc, cb, 'Products')} />
        <Route
          getComponent={(loc, cb) => $import(loc, cb, 'Product')}
          path="(:productId)"
        />
      </Route>
    </Route>
    {/** Retail : END */}

    {/** trainings : START */}
    <Route path="trainings">
      <IndexRoute getComponent={(loc, cb) => $import(loc, cb, 'Trainings')} />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'Trainings')}
        path=":trainingId"
      />
    </Route>
    {/** trainings : END */}

    {/** surveys : START */}
    <Route path="surveys" roles={['employer', 'company-agent']}>
      <IndexRoute getComponent={(loc, cb) => $import(loc, cb, 'Surveys')} />

      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'SurveyBuilder')}
        path="builder"
        roles={['employer', 'company-agent']}
      />

      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'SurveyResults')}
        path="(:surveyId)/results"
        roles={['employer', 'company-agent']}
      />

      <Route path="(:surveyId)">
        <IndexRoute getComponent={(loc, cb) => $import(loc, cb, 'Survey')} />
        <Route
          getComponent={(loc, cb) => $import(loc, cb, 'SurveyBuilder')}
          path="edit"
        />
      </Route>
    </Route>
    {/** surveys : END */}

    <Route path="locationgroups">
      <IndexRoute
        getComponent={(loc, cb) => $import(loc, cb, 'LocationGroups')}
      />
    </Route>

    <Route path="inspections">
      <IndexRoute getComponent={(loc, cb) => $import(loc, cb, 'Inspections')} />
    </Route>

    <Route
      getComponent={(loc, cb) => $import(loc, cb, 'Profile')}
      path="profile"
      roles={['user']}
    />

    <Route path="password" roles={['user']}>
      <IndexRoute
        getComponent={(loc, cb) => $import(loc, cb, 'ChangePassword')}
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'ForgotPassword')}
        path="forgot"
        roles={['*', 'guest']}
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'PasswordReset')}
        path="reset/:token"
        roles={['*', 'guest']}
      />
    </Route>

    <Route
      getComponent={(loc, cb) => $import(loc, cb, 'ActivateAccount')}
      path="activate(/:companyId)"
      roles={['employer', 'company-agent']}
    />

    <Route path="admin" roles={['admin']}>
      <IndexRoute
        getComponent={(loc, cb) => $import(loc, cb, 'AdminContainer')}
      />

      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'admin/UserReviewList')}
        path="users(/:userId)"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'admin/UserReview')}
        path="newusers(/:userId)"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'admin/EmployerUsersList')}
        path="employers(/:userId)"
      />

      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'admin/AdminUsersList')}
        path="administrators(/:userId)"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'admin/AuditLogList')}
        path="audit"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'AdminContainer')}
        path="badges"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'AdminContainer')}
        path="certs"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'AdminContainer')}
        path="quals"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'AdminContainer')}
        path="taggroups"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'AdminContainer')}
        path="skills"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'AdminContainer')}
        path="attributes"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'AdminContainer')}
        path="experiences"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'AdminContainer')}
        path="roles"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'AdminContainer')}
        path="positions"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'AdminContainer')}
        path="industries"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'AdminContainer')}
        path="messagetags"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'AdminContainer')}
        path="pools"
      />
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'VideoInterviews')}
        path="videoInterviews"
      />
      <Route path="opportunities">
        <IndexRoute
          getComponent={(loc, cb) =>
            $import(loc, cb, 'admin/opportunities/OpportunitiesContainer')
          }
        />
        <Route
          getComponent={(loc, cb) =>
            $import(loc, cb, 'admin/opportunities/OpportunityInfoPage')
          }
          path=":opportunityId"
        />
      </Route>
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'AdminContainer')}
        path="utils"
      />

      <Route path="companies(/:companyId)">
        <IndexRoute
          getComponent={(loc, cb) => $import(loc, cb, 'admin/Companies')}
        />
      </Route>

      <Route path="recurringSchedules/:recurringScheduleId">
        <IndexRoute
          getComponent={(loc, cb) =>
            $import(
              loc,
              cb,
              'admin/recurringSchedules/RecurringScheduleInfoPage',
            )
          }
        />
      </Route>

      <Route path="recurringSchedules/:recurringScheduleId/details">
        <IndexRoute
          getComponent={(loc, cb) =>
            $import(
              loc,
              cb,
              'admin/recurringSchedules/RecurringScheduleShiftTemplates',
            )
          }
        />
      </Route>

      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'AdminContainer')}
        path="favorites"
      />

      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'GlobalZones')}
        path="zones"
      />

      <Route
        getComponent={(loc, cb) =>
          $import(loc, cb, 'admin/payments/BulkCreateTransfers')
        }
        path="paymentUpload"
      />

      <Route path="payments">
        <IndexRedirect to="paymentAccounts" />
        <Route
          getComponent={(loc, cb) => $import(loc, cb, 'admin/payments/Config')}
          path="config"
        />
      </Route>
      <Route
        getComponent={(loc, cb) => $import(loc, cb, 'admin/payments/Accounts')}
        path="paymentAccounts(/:accountId)"
      />
      <Route
        getComponent={(loc, cb) =>
          $import(loc, cb, 'admin/payments/Transactions')
        }
        path="transactions"
      />
      <Route
        getComponent={(loc, cb) =>
          $import(loc, cb, 'admin/payments/PaymentProcessingQueue')
        }
        path="paymentProcessingQueue"
      />
    </Route>
    <Route
      getComponent={(loc, cb) => $import(loc, cb, 'admin/Companies')}
      path="company"
    />

    <Route
      getComponent={(loc, cb) => $import(loc, cb, 'roster/RosterIndex')}
      path="roster"
    />

    <Route path="locations">
      <IndexRoute getComponent={(loc, cb) => $import(loc, cb, 'Locations')} />

      <Route
        getComponent={(loc, cb) =>
          $importRoute(loc, cb, 'locations/pages/LocationDetails')
        }
        path=":locationId"
      />
    </Route>

    {process.env.NODE_ENV === 'development' && (
      <Route
        getComponent={(loc, cb) =>
          $import(loc, cb, 'statePlayground/StatePlayground')
        }
        path="state-playground"
      />
    )}

    <Route component={NotFound} path="*" roles={['guest', '*']} status={404} />
  </Route>
);
