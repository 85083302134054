import React from 'react';
import { observer } from 'mobx-react';
import { dispatch } from 'rfx-core';
import { Input } from 'semantic-ui-react';
import _ from 'lodash';
import cx from 'classnames';

const handleSearchChange =
  (storeName) =>
  (e, { value }) => {
    dispatch(`${storeName}.search`, value);
  };

const resetSearch = (storeName) => {
  dispatch(`${storeName}.resetSearch`, { preserve: ['$limit'], refresh: true });
};

const ListSearch: React.FC<{
  [otherProp: string]: unknown;
  className?: string;
  onChange?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  placeholder?: string;
  search?: string;
  storeName: string;
  swallowClicks?: boolean;
}> = observer(
  ({
    search,
    storeName,
    placeholder = 'Search...',
    className,
    swallowClicks,
    onChange,
    onClear,
    ...rest
  }) => (
    <Input
      autoCapitalize="off"
      autoComplete="off"
      autoCorrect="off"
      spellCheck="false"
      {...rest}
      action={
        !!search && {
          basic: true,
          icon: 'close',
          onClick: (e) => {
            if (_.isFunction(onClear)) {
              onClear();
            } else {
              resetSearch(storeName);
            }

            if (swallowClicks) e.stopPropagation();
          },
        }
      }
      className={cx(className, 'icon')}
      icon="search"
      iconPosition="left"
      onChange={
        _.isFunction(onChange) ? onChange : handleSearchChange(storeName)
      }
      onClick={(e) => {
        if (swallowClicks) e.stopPropagation();
      }}
      placeholder={placeholder}
      value={search}
    />
  ),
);

ListSearch.displayName = 'ListSearch';
export default ListSearch;
