import type { StoreName } from '#/shared/stores';
import type { IBaseItem, IUser } from '@shiftsmartinc/shiftsmart-types';
import type BaseStore from '#/shared/stores/_baseStore';

import { MutableRefObject, useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import { useStores } from '#/shared/hooks/useStores';
import { getChildLogger } from '#/shared/utils/client.logger';

export interface useCacheItemProps<T extends IBaseItem = IBaseItem> {
  defaultItem?: T;
  fields?: Array<keyof T>;
  isItemHasData?: boolean;
  item?: T;
  itemId: T['uuid'];
  noExtend?: boolean;
  storeName: StoreName;
  title?: string;
}

export function useCacheItem<T extends IBaseItem = IBaseItem>({
  item,
  itemId,
  storeName,
  title,
  noExtend,
  fields = [],
  defaultItem,
  isItemHasData = true,
}: useCacheItemProps<T>): {
  isLoading: MutableRefObject<boolean>;
  item: T | Record<string, never>;
} {
  const [itemObj, setItemObj] = useState<T | Record<string, never>>(
    defaultItem ?? {},
  );
  const { current: log } = useRef(
    getChildLogger(`useCacheItem.${title || storeName}`),
  );

  // const isLoading = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  const listenerId = useRef<number | string>(-1);

  const { cache, [storeName]: store } = useStores();

  const lookupItem = async (uuid) => {
    if (_.isEmpty(uuid)) {
      log.warn('No UUID provided to lookupUserData method');
      return null;
    }

    // isLoading.current = true;
    setIsLoading(true);

    try {
      let localItem = await cache.get(uuid, {
        fields,
        store: storeName,
      });

      if (_.isEmpty(localItem)) {
        localItem = await (store as BaseStore<T>).get(uuid, { select: false });
      }
      if (!localItem || _.isEmpty(localItem.uuid)) {
        return null;
      }

      log.verbose('Loaded User from Server: ', localItem);

      setItemObj(localItem);

      return localItem;
    } catch (err) {
      log.error(`Failed to load from ${storeName} service`, err);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const onStoreUpdateEvent = (updatedItem) => {
    log.silly('Item Updated Event Fired', {
      oldUUID: itemObj?.uuid,
      updatedItem,
      uuid: updatedItem.uuid,
    });
    if (!itemObj?.uuid) {
      log.silly('Item Object is empty or has no UUID', {
        item: itemObj,
      });
      return;
    }
    if (updatedItem.uuid !== itemObj?.uuid) {
      log.silly(
        'UUID Has Changed from %s -> %s',
        updatedItem.uuid,
        itemObj?.uuid,
      );
      return;
    }

    log.debug('Updating Item object w/ new data from server', {
      item: itemObj,
      updatedItem,
    });

    setItemObj(_.pick(updatedItem, fields));
    cache.set(updatedItem?.uuid, updatedItem, {
      fields,
      store: storeName,
    });
  };

  const checkUserObject = () => {
    if (!_.isEmpty(item) && item === itemObj) {
      log.verbose('Existing item object matches passed object');

      return;
    }

    if (item && !_.isString(item) && itemObj && item.uuid === itemObj?.uuid) {
      log.verbose('Existing item object matches by UUID');
      return;
    }

    if (!item && _.isString(itemId) && itemObj && itemId === itemObj?.uuid) {
      log.verbose('Existing item object matches userId');

      return;
    }

    if (!_.isEmpty(item) && !!_.get(item, 'uuid') && isItemHasData) {
      log.verbose(
        `Setting Full Item to userObject for "${_.get(log, 'displayName')}"`,
      );

      setItemObj(item);
      // @ts-expect-error the `isStub` property is used in some places to show data on load, knowing more data must be loaded from the server
    } else if (item && item.isStub && !noExtend) {
      log.verbose('Stub Item - looking up by user.uuid', item);
      setItemObj(item);

      lookupItem(item.uuid);
    } else if (
      itemId ||
      _.get(item, 'uuid', false) ||
      typeof item === 'string'
    ) {
      const id = itemId || _.get(item, 'uuid', false) || item;
      log.verbose('No Item - looking up by itemId', id);

      if (_.isEmpty(id) || !_.isString(id)) {
        log.warn('Unable to determine itemId', {
          itemId,
          itm: !global.IS_PRODUCTION && item,
        });
        setItemObj({});
        return;
      }

      lookupItem(id);
    } else {
      log.debug('No User Set :(', { extra: { item } });
      setItemObj({});
    }
  };

  const registerListener = () => {
    if (listenerId.current !== -1) {
      log.warn('Listener is already registered');
      return;
    }

    listenerId.current = (store as BaseStore<T>)?.registerEventListener({
      onPatched: onStoreUpdateEvent,
      onUpdated: onStoreUpdateEvent,
    });

    // this.log.debug(
    //   `Registered Listener #${this.listenerId} with the ${this.props.storeName} service`,
    // );
  };

  // Handle the case where the UUID is changed
  useEffect(() => {
    const from = _.get(itemObj, 'uuid');
    const to = itemId || _.get(item, 'uuid');

    if (from && from !== to) {
      checkUserObject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemObj?.uuid, itemId, item?.uuid]);

  // Initialize the item, and register event listeners for realtime updates
  useEffect(() => {
    checkUserObject();
    registerListener();
    return () => {
      if (listenerId.current !== -1) {
        (store as BaseStore<T>)?.deregisterEventListener(listenerId);
      }
    };
  }, []);

  return { isLoading, item: itemObj };
}

export const useCachedUserItem = ({
  user,
  userId,
  storeName = 'workers',
  title,
  noExtend,
  ...props
}: {
  fields?: Array<keyof IUser>;
  noExtend?: boolean;
  storeName?: ('partners' | 'workers' | 'users' | 'employerUsers') & StoreName;
  title?: string;
  user?: IUser;
  userId: IUser['uuid'];
}) => {
  const fields: Array<keyof IUser> = _.union(
    [
      'uuid',
      'displayName',
      'profileImageURL',
      'companies',
      'rating',
      'adherence',
      'phoneNumber',
      'certs',
      'pools',
      'firstName',
      'lastName',
      'accountProps',
      'address',
      'email',
    ],
    props.fields,
  );

  const { item, isLoading } = useCacheItem<IUser>({
    fields,
    isItemHasData: !!_.get(user, 'displayName'),
    item: user,
    itemId: userId,
    noExtend,
    storeName,
    title,
  });

  return {
    isLoading: isLoading?.current ?? isLoading,
    isLoadingRef: isLoading,
    item,
  };
};
