import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import moment from 'moment';
import _ from 'lodash';

import {
  BonusTypeCompletion,
  BonusTypeFirstShift,
  CountRules,
  DaysToCompleteRules,
  DurationRules,
  DynamicStartRules,
  init as initBonusForm,
  ValidFromRules,
  ValidToRules,
  BonusEligibilityTypePayableDuration,
  BonusTypeFirstOnboarding,
  BonusTypePerformanceBased,
  BonusTypeTiered,
} from '#/shared/forms/paymentRules';

export default class PaymentBonusModal {
  @observable
  isOpen = false;

  @observable
  showExtraDateOptions = false;

  @observable
  bonusForm = null;

  @observable
  timeTab = 'dynamic';

  @observable
  completionTab = 'shifts';

  getCompletionTab() {
    return this.completionTab;
  }

  @action async setup() {
    this.clear();
  }

  @action
  async setFormAndOpenModal(
    existingForm,
    companyId = null,
    dontOpenModal = false,
  ) {
    let form = existingForm ?? {};
    if (this.bonusForm) {
      this.bonusForm.clear();
    }
    const defaults = {
      __t: 'paymentBonus',
      companies: [companyId],
      recurrenceRules: {
        cycleDays: 7,
        cycleStart: {
          date: new Date(),
          dom: 1,
          dow: 0,
          ldom: false,
        },
        cycleStartOption: 'dow',
      },
    };
    const positionsWithRoles = await dispatch('positions.findByCompany', {
      company: companyId,
      query: { $sort: { title: 1 }, roleId: { $exists: true } },
    });
    const roleIds = positionsWithRoles.map((position) => position.roleId);

    const roles = await dispatch('roles.find', {
      query: {
        $sort: { title: 1 },
        shiftsmartOfficial: true,
        uuid: { $in: roleIds },
      },
    });

    let firstShiftExists = false;
    let firstOnboardingExists = false;
    let completionShiftByDynamicStartDateExists = false;
    if (!dontOpenModal) {
      // Companies are only allowed to have one firstShift Completion bonus.
      const firstShifts = await dispatch('paymentBonuses.runQuery', {
        query: {
          bonusType: BonusTypeFirstShift,
          companies: [companyId],
          isDeleted: { $ne: true },
        },
      });
      firstShiftExists = !!firstShifts?.total;
      // Companies are only allowed to have one hours/shifts completion or guaranteed
      // bonus where the start time is based off of when the worker signed up with
      // the company
      const completionShifts = await dispatch('paymentBonuses.runQuery', {
        query: {
          bonusType: BonusTypeCompletion,
          companies: [companyId],
          dynamicStart: 'companyStartDate',
          isDeleted: { $ne: true },
        },
      });
      completionShiftByDynamicStartDateExists = !!completionShifts?.total;

      // Companies are only allowed to have one firstOnboarding Completion bonus.
      const firstOnboardings = await dispatch('paymentBonuses.runQuery', {
        query: {
          bonusType: BonusTypeFirstOnboarding,
          companies: [companyId],
          isDeleted: { $ne: true },
        },
      });
      firstOnboardingExists = !!firstOnboardings?.total;
    }

    if (!existingForm) {
      if (!firstShiftExists) {
        form = {
          bonusType: BonusTypeFirstShift,
          dynamicStart: 'companyStartDate',
        };
        this.set('showExtraDateOptions', true);
      }
      if (!firstOnboardingExists) {
        form = {
          bonusType: BonusTypeFirstOnboarding,
          dynamicStart: 'companyStartDate',
        };
        this.set('showExtraDateOptions', true);
      }
      if (firstShiftExists && firstOnboardingExists) {
        form = {
          bonusType: BonusTypeCompletion,
        };
        if (completionShiftByDynamicStartDateExists) {
          this.set('timeTab', 'static');
          form.dynamicStart = null;
        } else {
          form.dynamicStart = 'companyStartDate';
          this.set('showExtraDateOptions', true);
        }
      }
    }
    // check the two cases that we need to limit
    // this might have to be moved into the component if we are going to allow editing
    this.bonusForm = initBonusForm({
      defaults,
      values: form,
    });
    let bonusOptions = [];
    if (firstShiftExists) {
      bonusOptions = [
        BonusTypeFirstOnboarding,
        BonusTypeCompletion,
        BonusTypeTiered,
      ];
    }
    if (firstOnboardingExists) {
      bonusOptions = [
        BonusTypeFirstShift,
        BonusTypeCompletion,
        BonusTypeTiered,
      ];
    }
    if (firstShiftExists && firstOnboardingExists) {
      bonusOptions = [BonusTypeCompletion, BonusTypeTiered];
    }
    if (_.isEmpty(bonusOptions)) {
      bonusOptions = [
        BonusTypeFirstShift,
        BonusTypeFirstOnboarding,
        BonusTypeCompletion,
        BonusTypeTiered,
      ];
    }
    this.bonusForm.$('bonusType').set('extra', {
      options: _.map(bonusOptions, (bonusType) => ({
        key: bonusType,
        text: _.startCase(bonusType),
        value: bonusType,
      })),
    });

    this.bonusForm.$('roleId').set('extra', {
      options: _.map(roles, (role) => ({
        key: role.uuid,
        text: _.startCase(role.title),
        value: role.uuid,
      })),
    });

    if (!dontOpenModal) {
      this.open(true);
      this.initRules();
    }
  }

  initRules = () => {
    this.onTypeChange();
  };

  @action set = (key, value) => {
    this[key] = value;
  };

  @action changeTimeTab = (val) => {
    if (val === 'dynamic') {
      // set
      this.bonusForm.$('dynamicStart').value = 'companyStartDate';
      this.bonusForm
        .$('dynamicStart')
        .set('rules', `required|${DynamicStartRules}`);
      if (_.isEmpty(this.bonusForm.$('incentivizedBonusPercentage').value)) {
        this.bonusForm
          .$('daysToComplete')
          .set('rules', `required|min:1|${DaysToCompleteRules}`);
      }
      // clear
      this.bonusForm.$('validTo').value = '';
      this.bonusForm.$('validTo').set('rules', ValidToRules);
      this.bonusForm.$('validFrom').value = '';
      this.bonusForm.$('validFrom').set('rules', ValidFromRules);
    } else if (val === 'static') {
      // set
      this.bonusForm.$('validTo').set('rules', `required|${ValidToRules}`);
      this.bonusForm.$('validFrom').set('rules', `required|${ValidFromRules}`);
      // clear
      this.bonusForm.$('dynamicStart').value = null;
      this.bonusForm.$('dynamicStart').set('rules', DynamicStartRules);
      this.bonusForm.$('daysToComplete').value = null;
      this.bonusForm.$('daysToComplete').set('rules', DaysToCompleteRules);
    }
    this.set('timeTab', val);
  };

  @action onTypeChange = () => {
    if (_.includes([BonusTypeTiered], this.bonusForm.$('bonusType').value)) {
      this.bonusForm.$('recurringBonus').value = null;
      this.bonusForm.$('count').set('rules', `${CountRules}`);
      this.bonusForm.$('duration').set('rules', `${DurationRules}`);
    }
    if (
      _.includes(
        [BonusTypeFirstShift, BonusTypeFirstOnboarding],
        this.bonusForm.$('bonusType').value,
      )
    ) {
      this.bonusForm.$('count').set('rules', CountRules);
      this.bonusForm.$('count').value = null;
      this.bonusForm.$('duration').set('rules', DurationRules);
      this.bonusForm.$('duration').value = null;
      this.changeTimeTab('dynamic');
      this.bonusForm.$('bonusEligibilityType').value = null;
      this.bonusForm.$('bonusEligibilityType').set('rules', 'string');
    } else if (this.bonusForm.$('bonusType').value === BonusTypeCompletion) {
      this.changeCompletionTab(this.completionTab);
      if (this.bonusForm.$('dynamicStart').value) {
        this.changeTimeTab('dynamic');
      } else {
        this.changeTimeTab('static');
      }
    }
  };

  @action changeCompletionTab = (val) => {
    // clean count and duration fields and set them to optional
    this.clearAndResetCountAndDurationFields();

    this.set('completionTab', val);

    const bonusType = this.bonusForm.$('bonusType').value;
    if (/tiered/i.test(bonusType)) {
      this.bonusForm.$('count').set('rules', `${CountRules}`);
      this.bonusForm.$('duration').set('rules', `${DurationRules}`);

      return;
    }

    if (!_.isEmpty(this.bonusForm.$('incentivizedBonusPercentage').value)) {
      return;
    }

    if (val === 'hours') {
      // make the duration and the eligibility fields required
      this.bonusForm.$('duration').set('rules', `required|${DurationRules}`);
      this.bonusForm.$('bonusEligibilityType').value =
        BonusEligibilityTypePayableDuration;
      this.bonusForm.$('bonusEligibilityType').set('rules', 'required|string');
    } else if (val === 'shifts') {
      // make the count required
      this.bonusForm.$('count').set('rules', `required|${CountRules}`);
    }
  };

  @action clearAndResetCountAndDurationFields = () => {
    this.bonusForm.$('count').value = null;
    this.bonusForm.$('count').set('rules', `${CountRules}`);
    this.bonusForm.$('duration').set('rules', `${DurationRules}`);
    this.bonusForm.$('duration').value = null;
    this.bonusForm.$('bonusEligibilityType').value = null;
    this.bonusForm.$('bonusEligibilityType').set('rules', 'string');
  };

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  getDurationText = (duration) => `${duration} Hour${duration > 1 ? 's' : ''}`;

  getShiftCountText = (count) => `${count} Shift${count > 1 ? 's' : ''}`;

  getOnboardingCountText = (count) => `${count} Onboarding`;

  getBonusMessage = (bonus) => {
    if (bonus.incentivizedBonusPercentage) {
      const noOfWeeks =
        bonus.incentivizedBonusWeeksCount ||
        Math.round(
          moment(bonus.validTo).diff(moment(bonus.validFrom)) /
            (7 * 24 * 60 * 60 * 1000),
        );
      return `The worker has to hit a performance level of completing ${bonus.incentivizedBonusPercentage}% shifts above the number of shifts worked in the past ${noOfWeeks} weeks`;
    }
    if (bonus?.dynamicStart === 'companyStartDate') {
      return `The worker has ${
        bonus.daysToComplete || 0
      } days from their companyStartDate to complete ${
        bonus.bonusType === BonusTypeFirstOnboarding
          ? 'an onboarding'
          : 'a shift'
      } `;
    }
    const durationMessage =
      bonus.duration > 0 ? this.getDurationText(bonus.duration) : '';
    let countMessage = '';
    if (bonus.count > 0) {
      countMessage =
        bonus.bonusType === 'firstOnboarding'
          ? this.getOnboardingCountText(bonus.count)
          : this.getShiftCountText(bonus.count);
    }

    const validTo = moment(bonus.validFrom).format('L LT');
    const validFrom = moment(bonus.validTo).format('L LT');
    const typeMessage =
      bonus.bonusType === 'firstShift'
        ? 'their first shift'
        : `at least ${durationMessage || countMessage}`;
    return `The worker must complete ${typeMessage} between ${validTo} and ${validFrom}`;
  };

  @action
  clear() {
    this.bonusForm = null;
    this.isOpen = false;
    this.showExtraDateOptions = false;
    this.timeTab = 'dynamic';
  }
}
