import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';

import BaseStore from './_baseStore';

export type ITwilioMessaging = IBaseItem & Record<string, unknown>;

export default class TwilioMessagingStore extends BaseStore<ITwilioMessaging> {
  constructor() {
    const baseItem = {};
    super({
      baseItem,
      serviceName: 'twilioMessaging',
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }
}
