import { action, set, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import _ from 'lodash';

import BaseUploader from '#/shared/stores/ui/BaseUploader';

export default class QuotaBulkToggleModal extends BaseUploader {
  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  isUpdating = false;

  @observable
  step = 'upload';

  @observable
  campaign = {};

  @observable
  quotasToClose = [];

  @observable
  quotasToOpen = [];

  @observable
  notFoundQuotas = [];

  @observable
  processedQuotas = 0;

  @observable
  quotasToProcess = 1;

  @observable
  updatePercentage = 0;

  @action
  setup({ campaign, open = this.isOpen }) {
    this.campaign = campaign;

    this.isOpen = open;
  }

  @observable parseOptions = {
    dynamicTyping: false,
    header: true,
    skipEmptyLines: true,
  };

  @action
  async parse({ rows: data }) {
    this.isLoading = true;
    const quotas = _.get(this.campaign, 'quotas', []);
    data.forEach((quotaToChange) => {
      const quota = quotas.find((q) => {
        const match =
          _.trim(q.personaTitle) === _.trim(quotaToChange.persona) &&
          _.trim(q.region) === _.trim(quotaToChange.region);
        return match;
      });
      if (!quota) {
        this.notFoundQuotas.push(quotaToChange);
      } else {
        const oldQuotaClosedStatus = quota.quotaClosed || false;
        const newQuotaClosedStatus = !/false/i.test(quotaToChange.quotaClosed);
        if (oldQuotaClosedStatus !== newQuotaClosedStatus) {
          quota.updateStatus = 'pending';
          if (newQuotaClosedStatus) {
            this.quotasToClose.push(quota);
          } else {
            this.quotasToOpen.push(quota);
          }
        }
      }
    });
    this.isLoading = false;
  }

  @action
  processSelectedQuotaKeysFromTable({ selectedQuotaKeysToToggle }) {
    const quotas = _.get(this.campaign, 'quotas', []);

    selectedQuotaKeysToToggle.forEach((quotaKey) => {
      const matchingQuota = quotas.find((q) => q.key === quotaKey);

      if (!matchingQuota) {
        this.notFoundQuotas.push(quotaKey);
      } else {
        const oldQuotaClosedStatus = !!matchingQuota.quotaClosed;
        const newQuotaClosedStatus = !oldQuotaClosedStatus;
        if (oldQuotaClosedStatus !== newQuotaClosedStatus) {
          matchingQuota.updateStatus = 'pending';
          if (newQuotaClosedStatus) {
            this.quotasToClose.push(matchingQuota);
          } else {
            this.quotasToOpen.push(matchingQuota);
          }
        }
      }
    });
  }

  @action.bound
  setStep(
    step:
      | 'upload'
      | 'closeReview'
      | 'openReview'
      | 'notFoundReview'
      | 'openComplete'
      | 'closeComplete',
  ) {
    this.step = step;
  }

  @action
  async updateQuotas({
    quotaAction = 'close',
    quotas = [],
    campaign = this.campaign,
  }) {
    const log = this.log.getChildLogger('updateQuotas');
    this.isUpdating = true;
    this.processedQuotas = 0;
    this.quotasToProcess = quotas.length;
    await Promise.map(
      quotas,
      async (quota) => {
        log.debug(
          'Updating Quotas: ',
          quotaAction,
          campaign.title,
          quota.region,
          this.processedQuotas / this.quotasToProcess,
        );
        this.set(
          'updatePercentage',
          this.processedQuotas / this.quotasToProcess,
        );
        try {
          const data = {
            quotaClosed: quotaAction === 'close',
            quotaKey: quota.key,
          };
          await dispatch('campaigns.update', {
            data,
            id: campaign.uuid,
          });
          this.updateQuotaStatus(quota, 'updated');
        } catch (error) {
          log.error('Error Toggling Quota: ', error, {
            extra: {
              campaignId: campaign.uuid,
              quotaKey: quota.key,
            },
          });
          this.updateQuotaStatus(quota, 'error');
        }
        this.processedQuotas += 1;
      },
      {
        concurrency: 1,
      },
    );

    this.set('isUpdating', false);
  }

  @action
  updateQuotaStatus(quota, status) {
    set(quota, { updateStatus: status });
  }

  @action
  setLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  clear() {
    this.campaign = {};
    this.isLoading = false;
    this.isUpdating = false;
    this.step = 'upload';
    this.quotasToClose = [];
    this.quotasToOpen = [];
    this.notFoundQuotas = [];
    this.processedQuotas = 0;
    this.quotasToProcess = 1;
    this.updatePercentage = 0;

    this.isOpen = false;
  }
}
