import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';

const log = getChildLogger('forms.tag');

export class TagForm extends Form {
  constructor(...args) {
    super(...args);

    const { certType } = this.values();

    if (/position|qual/i.test(certType)) {
      this.$('companies').set('rules', 'array|required|single_array_element');
      this.$('companies').set('label', 'Company');
      this.$('roleId').set('rules', 'required|string');
    }

    if (/badge/.test(certType)) {
      this.$('image').set('rules', 'string|required');
    }

    return this;
  }

  hooks = () => ({
    onSuccess(form) {
      const storeName = dispatch('ui.tagModal.getStoreName');
      const action = `${storeName}.${
        _.isEmpty(form.values().uuid) ? 'create' : 'update'
      }`;

      const data = form.values();
      const dirtyValues = form.getDirtyValues();

      // Grabs values to unset instead of saving as ""
      const dirtyValuesCopy = _.cloneDeep(dirtyValues);
      const flattenedChangedValues =
        this.flattenByRecursivelyInPlace(dirtyValuesCopy);

      const valuesToUnset = {};
      _.each(flattenedChangedValues, (v, k) => {
        if (this.isEmptyValue(v)) {
          valuesToUnset[k] = '';
        }
      });

      this.omitByRecursivelyInPlace(
        data.settings?.redundancySettings,
        this.isEmptyValue,
      );

      const hasEmptyRedundancySettings = _.isEmpty(
        data.settings?.redundancySettings,
      );

      // Flatten values to avoid overwriting any accidental keys
      this.flattenByRecursivelyInPlace(data);

      // We only want to unset when we have values to unset AND we didn't omit the entire object
      // Because we want no object instead of object with empty keys (which would mess with loadInheritedSettings)
      if (!_.isEmpty(valuesToUnset) && !hasEmptyRedundancySettings) {
        data['$unset'] = valuesToUnset;
      }

      dispatch(action, {
        data,
      })
        .then((res) => dispatch('ui.tagModal.success', res))
        .then(() => dispatch('ui.tagModal.clear'))
        .then(() =>
          dispatch(
            'ui.snackBar.open',
            `${_.capitalize(data.certType ?? 'Tag')} Saved.`,
          ),
        )
        .then(() => form.clear())
        .catch((err) => {
          log.error(`Failed to save ${form.values().certType}`, err);
          form.invalidate(err.message);
          dispatch('ui.snackBar.open', err.message);
        });
    },
  });

  // Can't use _.isEmpty bc we don't numbers omitted
  isEmptyValue(value) {
    return (
      value == null ||
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0) ||
      (typeof Array.isArray(value) && value.length === 0)
    );
  }

  /*
   * When removing fields, remove parent objects so we don't save something like
   * settings: {
   *  redundancySettings: {
   *   autoCreateRedundantShifts: {}
   *  }
   * }
   *
   * because this will override parent settings, so instead we recurse up to be:
   *
   * settings: {
   * redundancySettings: {}
   * }
   */
  omitByRecursivelyInPlace(object, iteratee) {
    _.each(object, (v, k) => {
      // Start as nested as you can go, so you can omit empty objects after omitting the empty values on the way up
      if (_.isObject(v)) {
        this.omitByRecursivelyInPlace(v, iteratee);
      }
      if (iteratee(v, k)) {
        _.unset(object, k);
      }
    });
    return object;
  }

  /*
   * Essentially doing same thing as getDirtyValues, prevents us from overwriting entire settings
   * object and deleting keys that arent in the UI
   */
  flattenByRecursivelyInPlace(object) {
    _.each(object, (v, k) => {
      if (_.isObject(v)) {
        this.flattenByRecursivelyInPlace(v);
        _.each(v, (v2, k2) => {
          object[`${k}.${k2}`] = v2;
          delete object[k];
        });
      }
    });

    return object;
  }
}

const fields = [
  'title',
  'certType',
  'uuid',
  'companies',
  'rates[].title',
  'rates[].rate',
  'rates[].description',
  'roleId',
  'shiftsmartOfficial',
  // Adding any other settings stuff here might break the unsetting/omitting behavior, check onSuccess comment
  'settings.redundancySettings.autoCreateRedundantShifts.increaseFillRateDispatchPref',
  'settings.redundancySettings.autoCreateRedundantShifts.maximumFFRForDupeCreationDecimalPercentage',
  // Badges
  'image',
  'imageFake',
];

const labels = {
  companies: 'Companies',
  image: 'Badge Image',
  rates: 'Wage Levels',
  'rates[].description': 'Description',
  'rates[].rate': 'Rate',
  'rates[].title': 'Title',
  roleId: 'Associated Role',
  'settings.redundancySettings.autoCreateRedundantShifts.increaseFillRateDispatchPref':
    '(Override) Auto Duper Dispatch Pref UUID',
  'settings.redundancySettings.autoCreateRedundantShifts.maximumFFRForDupeCreationDecimalPercentage':
    '(Override) Max FFR for Dupe Creation',
  shiftsmartOfficial:
    'Shiftsmart Official Role (These are roles that can be assigned to projects in the new portal)',
  title: 'Title',
};

const rules = {
  companies: 'array',
  image: 'string',
  imageFake: 'string',
  'rates[].description': 'string',
  'rates[].rate': 'numeric',
  'rates[].title': 'string|required',
  roleId: 'string',
  'settings.redundancySettings.autoCreateRedundantShifts.increaseFillRateDispatchPref':
    'string',
  'settings.redundancySettings.autoCreateRedundantShifts.maximumFFRForDupeCreationDecimalPercentage':
    'numeric|between:0,1',
  shiftsmartOfficial: 'boolean',
  title: 'required|string|between:1,50',
  uuid: 'string',
};

export function init(values = {}) {
  return new TagForm({
    defaults: {
      companies: [],
      rates: [],
      title: '',
      uuid: '',
    },
    fields,
    labels,
    rules,
    values,
  });
}
