import type { IUser } from '@shiftsmartinc/shiftsmart-types';

import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import cx from 'classnames';

import styles from '#/shared/styles/UserInitials.css';
import { getChildLogger } from '#/shared/utils/client.logger';
import { useCachedUserItem } from '#/shared/hooks/useCacheItem';

const log = getChildLogger('ssm.users.UserInitials');

type UserInitialsPropsTypes = {
  circular: boolean;
  className: string;
  /** Value in rem units */
  fontSize: number;
  maxInitials?: number;
  style: unknown;
  user: Partial<IUser> & Required<Pick<IUser, 'displayName'>>;
  userId: string;
};

const UserInitials = observer<React.FC<UserInitialsPropsTypes>>(
  ({
    circular,
    className,
    fontSize,
    style = {},
    user,
    userId,
    maxInitials,
  }) => {
    log.verbose('Initializing UserInitials');

    const { item: userObject } = useCachedUserItem({
      user: user,
      userId: userId,
    });

    const displayName =
      _.get(userObject, 'displayName') ||
      _.get(user, 'displayName') ||
      'Not Available';

    const initials = _(displayName.replace(/de la/, 'd').split(' '))
      .map((d) => _.upperCase(d[0]))
      .take(maxInitials ?? 3)
      .join('');

    let fs = fontSize ?? 1;

    fs = _.size(initials) > 2 ? fs - (_.size(initials) - 2) / 8 : fs;

    if (_.size(initials) > 2) {
      log.info('fontSize: ' + fs);
    }

    return (
      <div
        className={cx(
          className,
          circular && 'br-100 h-100 w-100',
          'flex items-center justify-center',
          'white flex-shrink-0',
          styles.userInitialsContainer,
        )}
        style={{ ...style }}
      >
        <div
          className={cx('tc', 'w-100', circular && 'ma2')}
          style={{
            fontSize: `${fs}rem`,
            lineHeight: `${fs}rem`,
            textShadow: '1px 1px rgba(0,0,0,0.1)',
          }}
        >
          {initials}
        </div>
      </div>
    );
  },
);

UserInitials.displayName = 'UserInitials';

export default UserInitials;
