import BaseTagStore from './_baseTagStore';

export default class BadgesStore extends BaseTagStore {
  constructor() {
    const baseItem = {
      certType: 'badge',
      companies: [],
      createdAt: null,
      image: null,
      isVerified: false,
      level: -1,
      linked: [],
      parent: null,
      title: null,
      updatedAt: null,
      users: [],
      uuid: null,
    };
    super({ baseItem, serviceName: 'badges' });

    return this;
  }

  create({ data = null }) {
    return super.create({ data });
  }
}
