import { action, observable } from 'mobx';
import _ from 'lodash';

export default class WorkerCertModal {
  @observable
  isOpen = false;

  @observable
  worker = {};

  @observable
  workers = [];

  @observable
  selectedTags = [];

  @observable
  company = {};

  @observable
  cert = {};

  @observable
  certTitle = '';

  @observable
  uuid = '';

  @observable
  certType;

  @observable
  storeName = '';

  @action
  setup({
    worker = {},
    workers = [],
    company,
    certTitle,
    cert,
    certType,
    storeName,
    open = this.isOpen,
  }) {
    this.worker = worker;
    this.workers = workers;
    const workerCerts = _.get(worker, 'certs', []);
    const workersCerts = workers.reduce(
      (prev, curr) => [...prev, ...curr.certs],
      [],
    );
    const certs = [...workerCerts, ...workersCerts];
    if (!/cert|role/i.test(certType)) {
      this.selectedTags = certs
        .filter((c) => c.companies.includes(company.uuid))
        .filter((c) => c.certType === certType);
      this.company = company;
    }
    this.cert = cert;
    this.certTitle = certTitle;
    this.certType = certType;
    this.uuid = cert && cert.uuid ? cert.uuid : '';
    this.storeName =
      storeName || (/industr/i.test(certType) ? 'industries' : `${certType}s`);

    this.isOpen = open;
  }

  @action
  clear({ open = false } = {}) {
    this.worker = {};
    this.workers = [];
    this.company = {};
    this.cert = {};
    this.certTitle = '';
    this.certType = '';
    this.storeName = '';
    this.uuid = '';

    this.isOpen = open;
  }

  @action.bound updateSelectedTags(tag) {
    const isSelected = this.selectedTags.find(
      (selectedTag) => selectedTag.uuid === tag.uuid,
    );
    if (isSelected) {
      this.selectedTags = this.selectedTags.filter(
        (selectedTag) => selectedTag.uuid !== tag.uuid,
      );
    } else {
      this.selectedTags.push(tag);
    }
  }
}
