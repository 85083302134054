import type { IBaseItem, IInvites } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from '#/shared/stores/_baseStore';

export default class InvitesStore extends BaseStore<IInvites & IBaseItem> {
  constructor() {
    super({ baseItem: InvitesStore.BASE_ITEM, serviceName: 'invites' });

    return this;
  }

  static BASE_ITEM: IInvites & IBaseItem = {
    _modelName: 'invites',
    companyId: '',
    createdAt: null,
    date: null,
    dateKey: 0,
    eligibleIds: [],
    interactions: [],
    promotedIds: [],
    updatedAt: null,
    userId: '',
    uuid: '',
  };
}
