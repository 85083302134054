import yn from 'yn';
/**
 * toBoolean
 * @param {*} val The Value to be coerced and/or parsed into a boolean
 * @param {*} defaultValue If the value cannot be parsed, return this value (default: false)
 * @returns {boolean} The `val` coerced into a boolean.
 *
 * The following strings are considered `true` (case insensitive)
 * * true
 * * yes
 * * y
 * * 1
 *
 * This relies on the `yn` package implementation
 */

export default function toBoolean(val = '') {
  return yn(val, { default: false });
}

export { toBoolean };
