import _ from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { create, persist } from 'mobx-persist';
import yn from 'yn';

import { getChildLogger } from '#/shared/utils/client.logger';

const hydrate = create({
  // storage: localStorage,
  jsonify: true,
});

export default class Theme {
  log = getChildLogger('ui.Theme');

  init() {
    hydrate('portal.ui.theme', this);

    this.initDarkMode();
  }

  @action.bound
  initDarkMode() {
    const log = this.log.getChildLogger('initDarkMode');
    try {
      this.allowDarkMode = yn(localStorage.getItem('allowDarkMode'));

      if (this.allowDarkMode) {
        const darkModeMediaQuery =
          typeof window !== 'undefined' &&
          window.matchMedia('(prefers-color-scheme: dark)');

        if (darkModeMediaQuery) {
          darkModeMediaQuery.addListener((e) => {
            const darkModeOn = e.matches;
            log.debug(`Dark mode is ${darkModeOn ? '🌒 on' : '☀️ off'}.`);
            runInAction(() => {
              this.systemPrefersDark = darkModeOn;
            });
          });

          this.systemPrefersDark = darkModeMediaQuery.matches;
        }

        // Reorder the darkModeOptions array
        let i = 0;
        while (
          i < _.size(this.darkModeOptions) &&
          _.first(this.darkModeOptions) !== this._isDarkModeEnabled
        ) {
          this.darkModeOptions = [
            ...this.darkModeOptions.slice(1),
            _.first(this.darkModeOptions),
          ];

          i += 1;
        }
      }
    } catch (err) {
      this.allowDarkMode = false;
    }
  }

  @persist
  @observable
  allowDarkMode = false;

  @observable
  systemPrefersDark = null;

  @persist
  @observable
  _isDarkModeEnabled = false;

  @computed
  get darkModeState() {
    if (!this.allowDarkMode) {
      return {};
    }

    switch (this.isDarkModeEnabled) {
      case true:
        return { icon: '🌜', theme: 'Dark', title: 'Enabled' };
      case false:
        return { icon: '🌞', theme: 'Light', title: 'Disabled' };
      default:
        return { icon: '🌞🌜', theme: 'Auto', title: 'Auto' };
    }
  }

  @computed
  get isDarkModeEnabled() {
    return !!this.allowDarkMode && this._isDarkModeEnabled;
  }

  darkModeOptions = [false, true, null];

  @action toggleDarkMode(input?: boolean | null) {
    if (!this.allowDarkMode) {
      return;
    }

    let val = input;
    if (_.isUndefined(val)) {
      // put the first element at the end of the array
      this.darkModeOptions = [
        ...this.darkModeOptions.slice(1),
        _.first(this.darkModeOptions),
      ];
      val = _.first(this.darkModeOptions);
    }
    this._isDarkModeEnabled = val;
  }
}
