import type { ICompany } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { computed } from 'mobx';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';

const log = getChildLogger('forms/customMetricConfig');

export class CustomMetricConfigForm extends Form {
  constructor(setup, config) {
    super(
      setup,
      _.defaultsDeep(config, { options: { retrieveOnlyDirtyValues: true } }),
    );
  }

  hooks = () => ({
    onSuccess(form) {
      let query;

      const values = form.values();
      const companyId = form.$('companyId').value;

      if (!form.$('uuid').value) {
        log.debug('Verifying New Config Defaults');

        if (!_.has(values, 'enablePools')) {
          values.enablePools = false;
        }
      }

      if (_.has(values, 'description')) {
        values.description = _.trim(values.description);
      }

      let data = {
        $push: { customMetrics: { ...values, uuid: uuid() } },
      };

      if (form.$('uuid').value) {
        query = {
          customMetrics: { $elemMatch: { uuid: form.$('uuid').value } },
        };

        const dotted = form.dotify(values);

        data = {
          $set: {
            ..._.mapKeys(dotted, (v, key) => `customMetrics.$.${key}`),
          },
        };
      }

      const dispatchObject = {
        data,
        id: companyId,
        params: {
          query,
        },
      };

      return dispatch('companies.update', dispatchObject)
        .then((res) => {
          if (form.$('onSuccessDispatch').value) {
            dispatch(form.$('onSuccessDispatch').value, res);
          } else {
            dispatch(
              'ui.snackBar.open',
              `Saved ${form.$('uuid').value}` ? 'Changes' : 'New Config',
            );
          }
        })
        .then(() => form.dispose())
        .then(() => form.clear())
        .catch((err) => {
          form.invalidate(err.message);
          dispatch('ui.snackBar.error', err.message);
        });
    },
  });

  @computed
  get isDirty() {
    const rules = this.state?.initial?.props?.rules ?? {};
    const dirtyMap = Array.from(this.fields).reduce((acc, [key, field]) => {
      if (field.isDirty && !/boolean/.test(rules[key] ?? '')) {
        acc[key] = field.isDirty;
      }
      return acc;
    }, {});

    let retval = !_.isEmpty(dirtyMap);

    const enablePools = this.$('enablePools');
    if (!retval && enablePools.value !== enablePools.initial) {
      retval = true;
    }

    return retval;
  }
}

const fields = [
  'companyId',
  'uuid',
  'key',
  'description',
  'dataType',
  'enablePools',
  'label',
  'searchOptions',
  'searchOptions.renderer',
  'searchOptions.range',
  'searchOptions.range.min',
  'searchOptions.range.max',
  'searchOptions.range.step',
  'onSuccessDispatch',
  'enablePartnerView',
  'friendlyMetricName',
];

const labels = {
  dataType: 'Data Type',
  description: 'Description',
  enablePartnerView: 'Display Custom Metric to Worker',
  enablePools: 'Enable as Pool Search Criteria',
  friendlyMetricName: 'Worker Friendly Metric Name',
  key: 'Custom Metric Field Key',
  label: 'Search Label',
  searchOptions: 'Pool Search Options',
  'searchOptions.range': 'Slider Search Range',
  'searchOptions.range.max': 'Maximum Value',
  'searchOptions.range.min': 'Minimum Value',
  'searchOptions.range.step': 'Step Amount',
  'searchOptions.renderer': 'Render Search Component As',
};

const rules = {
  dataType: 'required|string|in:number,percent',
  description: 'string',
  enablePartnerView: 'boolean',
  enablePools: 'boolean',
  friendlyMetricName: 'required_if:enablePartnerView,true|string|min:5',

  key: 'required|string|regex:/^\\w+$/',

  label: 'string',

  'searchOptions.range.max': 'numeric|min:1|required_if:enablePools,true',

  // 'searchOptions.range'
  'searchOptions.range.min': 'numeric|min:0|required_if:enablePools,true',

  'searchOptions.range.step': 'numeric|min:0|required_if:enablePools,true',
  // searchOptions: '',
  'searchOptions.renderer': 'string|in:SliderSearch',
};

const defaults = {
  dataType: 'number',
  enablePartnerView: false,
  enablePools: false,
  'searchOptions.range.max': 100,
  'searchOptions.range.min': 0,
  'searchOptions.range.step': 5,
  'searchOptions.renderer': 'SliderSearch',
};

const extra = {
  dataType: { options: ['number', 'percent'] },
  'searchOptions.renderer': {
    options: [
      { key: 'SliderSearch', text: 'Slider Search', value: 'SliderSearch' },
    ],
  },
};

const observers = {
  dataType: [
    {
      call: ({ form, change }) => {
        const range = form.$('searchOptions.range');

        if (/percent/i.test(change.newValue)) {
          range.set({ max: 100, min: 0 });
        }
      },
      key: 'value',
    },
  ],
};

export function init(
  values = {},
  cxOptions: {
    /**
     * Store Method to be dispatched on success
     * @deprecated prefer the ImperativeHandle approach in passing in `handle`
     */
    onSuccessDispatch?: string;
  },
) {
  const { onSuccessDispatch, ...opts } = cxOptions ?? {};
  if (onSuccessDispatch) {
    _.defaults(values, { onSuccessDispatch });
  }

  const vals: Partial<ICompany['customMetrics'][0]> = values?.uuid
    ? values
    : { ...defaults, ...values };

  return new CustomMetricConfigForm(
    {
      extra,
      fields,
      labels,

      observers,

      rules,
      // defaults,
      // Must merge defaults & values until at least @1.39.0
      values: vals,
    },
    {
      ...opts,
      options: {
        retrieveOnlyDirtyValues: !!vals.uuid,
      },
    },
  );
}
