import type { IUser } from '@shiftsmartinc/shiftsmart-types';

import React from 'react';
import { observer } from 'mobx-react';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import cx from 'classnames';

import { NA } from '#/shared/components/ssm';
import { useCachedUserItem } from '#/shared/hooks/useCacheItem';
import { getChildLogger } from '#/shared/utils/client.logger';
import { useStores } from '#/shared/hooks/useStores';

const log = getChildLogger('utils.Names');

type NamesProps = {
  className?: string;
  defaultText?: string;
  /** show only the user's first name */
  first?: boolean;
  /** show only the user's last name */
  last?: boolean;
  /** Apply the `truncate` css class to cut off the user's name if it is too long */
  truncate?: boolean;
  user?: IUser;
  userId?: IUser['uuid'];
} & (
  | {
      user?: IUser;
      userId: IUser['uuid'];
    }
  | {
      user: IUser;
      userId?: IUser['uuid'];
    }
);

const Names = observer(
  ({
    user,
    userId,
    defaultText,
    truncate,
    className,
    first,
    last,
  }: NamesProps) => {
    const { item: userObject } = useCachedUserItem({
      title: 'Names',
      user,
      userId,
    });
    const { routing } = useStores();
    const name =
      _.get(userObject, 'displayName') || _.get(user, 'displayName') || '';
    let retval = name;
    if (first && !last) {
      retval = _.get(userObject, 'firstName') || _.first(name.split(' '));
    }
    if (last && !first) {
      retval = _.get(userObject, 'lastName') || name.replace(/^\w+ /, '');
    }
    if (retval)
      return (
        <a
          className={className}
          href={`/partners/${userObject.uuid}`}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault(); // href of anchor tag will not work
            if (e.ctrlKey || e.metaKey) {
              routing.push(
                `/partners/${userObject.uuid}`,
                {},
                { openNewTab: true },
              );
              e.stopPropagation(); // opening in new tab prohibit opening partner pop up details card
            }
            return false;
          }}
          style={{ color: 'inherit' }}
        >
          {retval}
        </a>
      );
    return <NA className={cx(truncate && 'truncate')} content={defaultText} />;
  },
);

function getName(user: unknown, noLookup?: boolean) {
  log.verbose('getting User Name based on object', { user });
  if (_.isObject(user) && !_.isEmpty(user.uuid)) {
    const name = formatName(user);
    if (name) return name;
  }

  if (!noLookup) {
    log.verbose('GetName is looking up the worker!');
    return dispatch('partners.get', _.get(user, 'uuid', user), {
      select: false,
    });
  }

  return null;
}
function formatName(user) {
  if (_.isEmpty(user) || _.isString(user)) return null;

  return (
    _.get(user, 'displayName') ||
    [user.firstName, user.lastName].join(' ').trim()
  );
}

export { getName };
export default Names;
