import React, { useState } from 'react';
import _ from 'lodash';
import Truncate from 'react-truncate';
import { observer } from 'mobx-react';

/** TruncateTooltip.jsx
 * -----------------------------------------------------------------------------------------
 * Renders Truncate component and passes all props to it, shows tooltipText in tooltip if truncated
 */

type TruncateToolTipProps = {
  children: React.ReactNode;
  onTruncated?: (isTruncated?: boolean) => void;
  tooltipProps?: unknown;
  tooltipText?: string;
};
const TruncateTooltip = observer(
  ({
    children,
    onTruncated,
    tooltipProps = {},
    tooltipText = '',
    ...rest
  }: TruncateToolTipProps) => {
    const [isTruncated, setIsTruncated] = useState(false);

    const onTruncate = (isTruncatedParam) => {
      setIsTruncated(isTruncatedParam);

      if (_.isFunction(onTruncated)) onTruncated();
    };

    const tooltipAttr = _.extend(
      {},
      tooltipProps,
      isTruncated
        ? {
            'data-inverted': '',
            'data-tooltip': tooltipText,
          }
        : {},
    );

    return (
      <div {...tooltipAttr}>
        <Truncate onTruncate={onTruncate} {...rest}>
          {children}
        </Truncate>
      </div>
    );
  },
);

TruncateTooltip.displayName = 'TruncateTooltip';
export default TruncateTooltip;
