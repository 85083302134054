import { observable, action, set, runInAction, computed } from 'mobx';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import _ from 'lodash';
import moment from 'moment-timezone';

import BaseUploader from '#/shared/stores/ui/BaseUploader';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.bulkRemoveUserUploader');

export default class BulkRemoveUserUploader extends BaseUploader {
  constructor({ title = 'ui.BulkRemoveUserUploader' }) {
    super({ title });

    set(this.saveResults, {
      ignored: [],
      invalid: [],
      new: [],
      updated: [],
    });

    return this;
  }

  @observable saveConfirmMessage = '';

  @computed
  get sampleCSVData() {
    return [this.knownHeaders, ['1234', '']];
  }

  @computed
  get knownHeaders() {
    return ['uuid', 'cancelReason'];
  }

  @observable saveButtonText = 'Save';

  @action.bound
  async parse({ rows }) {
    const caseHeaders = _.map(rows[0], _.toLower);
    this.parsedHeaders.replace(this.knownHeaders);
    const indices = _.reduce(
      this.knownHeaders,
      (acc, header) => {
        acc[header] = _.indexOf(caseHeaders, _.toLower(header));
        return acc;
      },
      {},
    );

    this.log.debug('Loaded Table Indices', { indices });

    const validRows = _(rows)
      .map((row, i) => {
        if (!i) return null;

        this.log.debug(row);

        return _.chain(this.knownHeaders)
          .reduce((acc, colKey) => {
            const colIndex = indices[colKey];

            if (colIndex >= 0) {
              acc[colKey] = row[colIndex];
            }
            return acc;
          }, {})
          .value();
      })
      .reject((rowObj) => _.isEmpty(_.omitBy(rowObj, _.isEmpty)))
      .compact()
      .value();

    this.setStatus('Evaluating');

    runInAction(() => {
      this.list.replace(validRows);
    });

    this.setStatus(`Parsed ${this.list.length} Rows to remove`);
  }

  @action.bound
  async processUserBan(row) {
    const workerId = _.trim(row.uuid);
    const cancelReason = _.trim(row.cancelReason);
    const date = moment().format('MMM Do Y');
    const user = dispatch('auth.getUser');
    const deletedReason =
      cancelReason ||
      `This parnter was Bulk Banned on ${date} by ${user?.displayName}`;

    const bannedUser = await dispatch('workers.update', {
      data: {
        deletedAt: Date.now(),
        deletedBy: user?.uuid,
        deletedReason,
        isDeleted: true,
      },
      id: workerId,
    });

    return bannedUser;
  }

  @action.bound
  async save() {
    this.setStatus('saving');
    this.setSaving(true);

    _.each(this.saveResults, (vals, key) => (this.saveResults[key] = []));

    try {
      const response = await Promise.map(
        this.list,
        async (row) => {
          try {
            const bannedUser = await this.processUserBan(row);
            runInAction(() => {
              this.saveResults.updated.push(bannedUser);
            });
            return bannedUser;
          } catch (error) {
            log.debug(error);
            runInAction(() => {
              this.saveResults.invalid.push(_.extend(row, { err: error }));
            });
            return row;
          }
        },
        { concurrency: 20 },
      );

      runInAction(() => {
        this.list.replace(response);
        this.setStatus('saved');
      });
    } catch (err) {
      this.log.error('Failed to ban users', err);
      dispatch('ui.snackBar.error', `Sorry, something went wrong, ${err}`);
      this.setStatus('error');
      this.clear();
    }

    this.setSaving(false);
  }

  @action.bound
  clear() {
    super.clear();

    this.list.clear();
  }
}
