import type { IOpportunity } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { action } from 'mobx';
import moment from 'moment';
import { dispatch } from 'rfx-core';

import BaseStore from './_baseStore';

export default class OpportunitiesStore extends BaseStore<IOpportunity> {
  constructor() {
    super({
      baseItem: OpportunitiesStore.BASE_ITEM,
      searchFields: ['title'],
      serviceName: 'opportunities',
    });

    return this;
  }

  static BASE_ITEM: IOpportunity = {
    _modelName: null,
    createdAt: null,
    description: null,
    icon: 'merchandiser',
    iconName: '',
    isDeleted: false,
    openings: [],
    search: {},
    status: null,
    title: null,
    updatedAt: null,
    uuid: null,
  };

  @action async getApplicantsStatsForOpening(openingId: string) {
    const applicants: { joinDate: string; uuid: string }[] = await dispatch(
      'users.runAggregate',
      {
        pipeline: [
          {
            $match: {
              companyStatus: {
                $elemMatch: {
                  opportunityOpeningRef: openingId,
                  source: 'inAppOpportunity',
                },
              },
            },
          },
          {
            $unwind: {
              path: '$companyStatus',
            },
          },
          {
            $match: {
              'companyStatus.opportunityOpeningRef': openingId,
            },
          },
          {
            $project: {
              joinDate: '$companyStatus.createdAt',
              uuid: 1,
            },
          },
        ],
      },
    );
    return {
      '1month': _.filter(applicants, (a) =>
        moment(a.joinDate).isAfter(moment().subtract(1, 'month'), 'days'),
      ).length,
      '1week': _.filter(applicants, (a) =>
        moment(a.joinDate).isAfter(moment().subtract(1, 'week'), 'days'),
      ).length,

      '24h': _.filter((a) =>
        moment(a.joinDate).isAfter(moment().subtract(24, 'hours')),
      ).length,
      '2weeks': _.filter(applicants, (a) =>
        moment(a.joinDate).isAfter(moment().subtract(2, 'weeks'), 'days'),
      ).length,
      allTime: _.size(applicants),
    };
  }
}
