import { computed, observable, action } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

export default class FillShiftModal {
  @observable
  isOpen = false;

  @observable
  shift = {};

  @observable
  numberOfSlots = 0;

  @observable
  selectedPools = [];

  @observable
  recommendedPools = [];

  @observable
  sentAssignments = 0;

  @observable
  sendReminder = false;

  @action
  async setup(shift) {
    const company = dispatch('auth.getCompany');
    this.numberOfSlots = shift.slots;
    this.shift = shift;
    const pools = await dispatch('pools.findByCompany', { company });
    const notificationPrefsPoolIds = _.map(
      shift.notificationPrefs,
      (pref) => pref.pool,
    );
    const sentAssignments = await dispatch('assignments.runQuery', {
      $limit: 0,
      ref: shift.uuid,
      status: 'Sent',
    });
    this.set('sentAssignments', sentAssignments?.total ?? 0);
    if (!_.isEmpty(shift.position)) {
      const preselectedPools = _.filter(pools, (pool) => {
        const positions = pool.search.tags;
        const optionalTags = pool?.search?.optionalTags ?? [];
        return (
          _.includes(positions, shift.position.uuid) ||
          _.includes(optionalTags, shift.position.uuid)
        );
      });
      const preselectedPoolIds = preselectedPools
        .map((pool) => pool.uuid)
        .filter((id) => !_.includes(notificationPrefsPoolIds, id));
      this.setSelectedPools(preselectedPoolIds);
      this.setRecommendedPools(preselectedPools);
    }
    this.toggleModal(true);
  }

  @action
  setSelectedPools(values) {
    this.selectedPools.replace(values);
  }

  @action
  setRecommendedPools(values) {
    this.recommendedPools.replace(values);
  }

  @action
  toggleModal(val = !this.isOpen) {
    this.isOpen = val;
  }

  @action
  toggleReminder() {
    this.sendReminder = !this.sendReminder;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value = '') {
    _.set(this, key, value);
  }

  @action
  async submit() {
    if (this.shift.slots !== this.numberOfSlots) {
      await dispatch('shifts.update', {
        data: {
          slots: this.numberOfSlots,
        },
        id: this.shift.uuid,
      });
    }
    if (this.sendReminder) {
      await dispatch('shifts.update', {
        id: this.shift.uuid,
        query: {
          $client: { sendShiftReminders: true },
          assignmentStatus: 'sent',
        },
      });
    }
    if (!_.isEmpty(this.selectedPools)) {
      const notificationPrefs = this.selectedPools.map((pool) => ({
        approval: this.shift?.defaultAssignmentAction === 'approvalRequired',
        approvalType: 'invite',
        delay: 0,
        ignoreRestrictions: true,
        pool,
      }));
      await dispatch('shifts.update', {
        data: {
          $push: {
            notificationPrefs: {
              $each: notificationPrefs,
            },
          },
        },
        id: this.shift.uuid,
      });
    }
    this.clear();
  }

  @computed
  get notEnoughSlots() {
    return (
      /filled/i.test(this.shift?.status) &&
      this.shift?.assignedUsers?.length === this.numberOfSlots
    );
  }

  @action
  clear() {
    this.isOpen = false;
    this.shift = {};
    this.numberOfSlots = 0;
    this.selectedPools.clear();
    this.recommendedPools.clear();
    this.sendReminder = false;
    this.sentAssignments = 0;
  }
}
