import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import { action, computed, observable, set, runInAction } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import moment from 'moment';

import { service } from '#/shared/app';
import { getEmploymentStatus } from '#/shared/utils/employment';

import BaseStore from './_baseStore';

export type INotifications = IBaseItem & Record<string, unknown>;

export default class NotificationsStore extends BaseStore<INotifications> {
  constructor() {
    const baseItem = {
      body: '',
      channel: '',
      deliveredAt: null,
      deliveryStatus: '',
      errorMsg: '',
      method: '',
      notificationId: '',
      notificationType: '',
      refs: [],
      remind: false,
      reminders: [],
      scheduleFor: null,
      user: '',
      viewedAt: null,
    };
    super({ baseItem, serviceName: 'notifications' });

    return this;
  }

  @observable
  filter = {
    category: null,
    deliveryStatus: null,
    method: null,
    notificationType: null,
    scheduleFor: null,
  };

  @observable
  myNotifications = [];

  @observable
  $myNotificationsPagination = {};

  @observable
  categories = [];

  @action
  async loadCategories() {
    if (_.isEmpty(this.categories)) {
      const categories = await service(`${this.serviceName}/categories`).find(
        {},
      );

      runInAction(() => {
        this.categories = _.get(categories, 'results', []);
      });
    }

    return this.categories;
  }

  @computed
  get myNotificationsPagination() {
    const { total = 0, limit = 1, skip = 0 } = this.$myNotificationsPagination;
    return _.extend(this.$myNotificationsPagination, {
      current: Math.ceil((skip - 1) / limit) + 1,
      pages: Math.ceil(total / limit),
    });
  }

  async getForUser({ user, company }) {
    const userId = _.get(user, 'uuid', user);
    const companyId = _.get(company, 'uuid', company);
    try {
      const res = await service(`${this.serviceName}/getForUser`).find({
        query: {
          companyId,
          userId,
        },
      });

      runInAction(() => {
        this.myNotifications = res.data;
        this.$myNotificationsPagination = _.omit(res.data, 'data');
      });

      await this.fetchContentLinks();
    } catch (err) {
      this.log.error('Error getting notifications for user', err);
    }

    return this.myNotifications;
  }

  async fetchContentLinks() {
    await Promise.all(
      _(this.myNotifications)
        .map((n) => (_.has(n, 'notifications') ? n.notifications.toJS() : [n]))
        .flatten()
        .uniqBy((n) => _.get(n.props, 'contentLink') || n.uuid)
        .map(async (n) => {
          let content;
          const contentLink = _.get(n, 'props.contentLink');
          try {
            if (contentLink) {
              const [store, uuid] = _.compact(contentLink.split('/'));
              content = await dispatch('cache.get', uuid, {
                fields: ['title', 'assignedUsers'],
                store,
              });

              this.log.silly('Loaded Content from cache: ', { content });
            }
          } catch (err) {
            this.log.error(
              `Failed to load resource for contentLink: ${contentLink}`,
              err,
            );
          }

          try {
            const au = _.get(content, 'assignedUsers', []);
            const us = _.compact([n.user, _.get(n, 'props.worker')]);
            // const nu = _.map(n.notifications || [], notif =>
            //   _.compact([notif.user, _.get(notif, 'props.worker')]),
            // );

            this.log.silly('User Arrays', { au, us });

            const users = _.union(au, us);

            if (!_.isEmpty(users)) {
              this.log.silly('Loading Users', {
                users,
              });
              await Promise.map(users, async (userId) => {
                this.log.silly(`loading user ${userId}`);

                let retval;

                try {
                  retval = await dispatch('cache.get', userId, {
                    fields: ['displayName'],
                    store: 'users',
                  });

                  this.log.silly(`loaded user ${userId}`, { retval });
                } catch (err) {
                  this.log.error(`Failed to load user`, err, {
                    extra: { userId },
                  });
                  return {};
                }

                return retval;
              });
            }
          } catch (err) {
            this.log.error(`Failed to load users for notification`, err, {
              extra: { uuid: n.uuid },
            });
          }
        }),
    );
  }

  remind({ data: source }) {
    const data = _.pick(source, ['user', 'body', 'refs']);
    data.notificationType = 'sms';

    /** TODO:
     * Determine if this is how we want to track the link to the "source"
     * notification, or if we want to track via a different mechanism.
     * [PDF 2018-08-30]
     */
    data.refs.push(source.uuid);

    return this.create({ data }).then((res) => {
      this.update({
        data: { $push: { reminders: res.uuid } },
        id: source.uuid,
      });

      return res;
    });
  }

  @action.bound
  filterBy(filter, opts) {
    const filters = [
      'deliveryStatus',
      'notificationType',
      'method',
      'scheduleFor',
      'category',
    ];

    if (_.isString(this.filter)) {
      set(this.filter, {
        category: null,
        deliveryStatus: null,
        method: null,
        notificationType: null,
        scheduleFor: null,
      });
    }

    const query = {};

    _.each(
      filters,
      action((filterName) => {
        if (_.has(filter, filterName)) {
          if (
            /notificationType/i.test(filterName) &&
            /other/i.test(filter[filterName])
          ) {
            query[filterName] = { $nin: ['sms', 'pushNotification'] };
          } else {
            query[filterName] = filter[filterName];
          }
          if (/^(all|clear)$/i.test(filter[filterName])) {
            query[filterName] = undefined;
            this.filter[filterName] = undefined;
          } else {
            this.filter[filterName] = filter[filterName];
          }
        }
      }),
    );

    return this.find(query, opts);
  }

  // For ShiftSummaryTab.jsx
  // activityListFiltered - filter out notifications FROM current user
  @computed
  get activityListFiltered() {
    const company = dispatch('auth.getCompany');
    return this.list.filter((n) => {
      if (
        (n.company !== company.uuid &&
          getEmploymentStatus({
            company: company.uuid,
            user: _.get(n, 'props.workerData', {}),
          }) !== 'employee') ||
        /filledShift/i.test(n?.props?.action)
      ) {
        return false;
      }
      return true;
    });
  }

  // Iterate through activityListFiltered, for every partner notification
  // that is stale (newer notification for that partner exists) set stale: true
  @computed
  get activityListStale() {
    const partners = new Set();
    return this.activityListFiltered.map((notification) => {
      const partnerId = _.get(notification, 'props.workerData.uuid');
      // If partner has a previous (more recent) notification, mark stale
      if (partners.has(partnerId)) {
        return _.extend(notification, { stale: true });
      }
      // If first notification for this partner so far, add to set
      partners.add(partnerId);
      return notification;
    });
  }

  // Group similar subsequent notifications into single element (nested array)
  @computed
  get activityListStacked() {
    const list = this.activityListStale;
    const result = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < list.length; i++) {
      if (i > 0) {
        const timestamp = moment(list[i].createdAt);
        const prevTimestamp = moment(list[i - 1].createdAt);
        // If same category and within 1 hour of prev notification
        if (
          list[i - 1].category === list[i].category &&
          Math.abs(timestamp.diff(prevTimestamp, 'seconds')) < 60
        ) {
          // Push to subarray
          const lastResult = result[result.length - 1];
          if (_.isArray(lastResult)) {
            // If last element in result is array, push to it
            lastResult.push(list[i]);
          } else {
            // If last element is not array, create new stack (subarray)
            result[result.length - 1] = [lastResult, list[i]];
          }
          // eslint-disable-next-line no-continue
          continue;
        }
      }
      result.push(list[i]);
    }
    return result;
  }
}
