/* eslint-disable no-param-reassign */
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';

const log = getChildLogger('forms.tagGroup');

export class TagGroupForm extends Form {
  hooks = () => ({
    onSuccess(form) {
      const action = `tagGroups.${
        _.isEmpty(form.values().uuid) ? 'create' : 'update'
      }`;

      if (form.$('type').value === 'grouped' && !form.$('defaultTag').value) {
        form.$('tags').invalidate('Default tag is required.');
        return;
      }
      form.$('tagId').clear();

      // make sure no duplicates
      const uniqueTags = _.uniqBy(form.$('tags').value, 'uuid');

      const data = form.values();
      data.tags = uniqueTags.map((tag, index) => {
        if (data.type === 'grouped') {
          if (tag.uuid === data.defaultTag) {
            tag.index = -1;
          }
        } else {
          tag.index = index;
        }
        return tag;
      });
      data.companies = [data.company];
      delete data.tagId;
      delete data.defaultTag;
      delete data.company;

      dispatch(action, {
        data,
      })
        .then(() => dispatch('ui.tagGroupEditModal.clear'))
        .then(() => dispatch('ui.snackBar.open', 'Tag Saved.'))
        .then(() => form.clear())
        .catch((err) => {
          log.error(`Failed to save tag group`, err);
          form.invalidate(err.message);
          dispatch('ui.snackBar.open', err.message);
        });
    },
  });
}

const fields = [
  'title',
  'uuid',
  'company',
  'type',
  'tagId',
  'tags',
  'tags[].uuid',
  'tags[].title',
  'tags[].index',
  'defaultTag',
];

const rules = {
  company: 'required|string',
  defaultTag: 'required_if:type,grouped|string',
  tagId: 'string',
  tags: 'required|array',
  title: 'required|string|between:1,50',
  type: 'required|string',
  uuid: 'string',
};

const labels = {
  company: 'Company',
  defaultTag: 'Default tag',
  tagId: 'Add a Tag',
  tags: 'Tags',
  title: 'Title',
  type: 'Type',
};

export function init(values = {}) {
  const companies = _.get(values, 'companies', null);
  const tags = _.get(values, 'tags', null);
  if (companies) {
    values.company = _.first(companies);
    delete values.companies;
  }
  if (!_.isEmpty(tags) && _.get(values, 'type') === 'grouped') {
    values.defaultTag = _.get(
      tags.find((tag) => tag.index === -1),
      'uuid',
    );
  }
  return new TagGroupForm({ fields, labels, rules, values });
}
