import { action, computed, observable, runInAction } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.Company');

export default class Company {
  @observable
  activeTab = '';

  @observable
  activeDetailsTab = '';

  @observable
  companyId = '';

  @observable
  isLoading = false;

  @observable
  ownerAndAgents = [];

  @observable
  enableSettingsEdit = false;

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async setup({ company, companies = [], view, preserveTabs }) {
    const companyId = _.get(company, 'uuid', company);

    if (!_.isString(companyId)) {
      log.error('Unable to determine companyId', { data: { company } });
      return Promise.reject(
        new Error('Unable to determine companyId', { data: { company } }),
      );
    }

    runInAction(() => {
      this.companyId = companyId;
      this.isLoading = true;
    });

    try {
      // EP-1135 offshoot: verify user has access to this company
      if (dispatch('abilities.cannot', 'read', company)) {
        log.error(
          'User does not have access to this company. Access will be restricted at a later date',
          { companyId: company.uuid, userId: dispatch('auth.getUser')?.uuid },
        );
      }

      const co = await dispatch('companies.setSelected', company);
      await dispatch('companies.loadHierarchy', { company: co });

      Promise.all([
        dispatch(
          'partners.find',
          {
            $limit: 1,
            companies: companyId,
          },
          { clear: true },
        ).catch((err) => {
          log.error('Error searching for Company Workers', err);
        }),

        dispatch('companies.loadAgents', { company: companyId }),
      ]);

      const companyAgents = await dispatch('companies.loadAgents', { company });

      runInAction(() => {
        this.ownerAndAgents.replace(companyAgents);
      });

      if (!preserveTabs) {
        this.setActiveTab(this.companyId ? 'summary' : view);
        this.setActiveDetailsTab('summary');
      }
    } catch (err) {
      log.error('Error during Company Setup', err);
    }

    runInAction(() => {
      this.isLoading = false;
    });
    return this.companyId;
  }

  @action
  setActiveTab(tab) {
    log.debug(`Setting active tab to "${tab}"`);
    switch (tab) {
      case 'calendar':
      case 'shifts':
      case '':
        this.activeTab = 'calendar';
        dispatch('prefs.setShiftView', this.activeTab);
        return;
      case 'pts':
      case 'review':
        this.activeTab = 'review';
        dispatch('prefs.setShiftView', this.activeTab);
        return;
      default:
        if (!_.find(this.menuTabs, { key: tab })) {
          this.activeTab = 'shifts';
          return;
        }
    }

    this.activeTab = tab;
  }

  @action
  setActiveDetailsTab(tab) {
    this.activeDetailsTab = tab;
  }

  @action
  clear() {
    log.verbose('Clearing');
    this.activeDetailsTab = '';
  }

  @action
  toggleSettingsEdit(val?: boolean) {
    this.enableSettingsEdit = val ?? !this.enableSettingsEdit;
  }
}
