import { createContext } from 'react';
import { action } from 'mobx';
import _ from 'lodash';

import { log } from '#/shared/utils/client.logger';

export default class AppStore {
  ssrLocation = null;

  clientInstance = null;

  @action
  getInstance() {
    log.debug('4. Getting Client Instance from app store');
    return this.clientInstance;
  }

  getPathname() {
    return (
      this.ssrLocation ||
      (/CLIENT/i.test(global.TYPE) && window?.location?.pathname) ||
      'unknown'
    );
  }

  getServerURI() {
    const config = global.CONFIG;

    let uri = `${config.io.protocol || 'http'}://${_.reject(
      [config.io.host, config.io.port],
      (x) => _.isEmpty(`${x}`),
    ).join(':')}`;

    if (process.env.NODE_ENV === 'production' && !_.isEmpty(config.io.prod)) {
      log.warn(
        'The `PROD_IO_HOST` config is deprecated. Please use IO_PROTOCOL + IO_HOST instead',
        { config: config.io },
      );
      uri = ['https://', config.io.prod].join('');
    }

    return uri;
  }

  storesContext = {};

  getStoresContext() {
    return this.storesContext;
  }

  @action setStoresContext(stores) {
    if (!_.isEmpty(this.storesContext)) {
      log
        .getChildLogger('stores.app.setStoresContext')
        .warn('Stores Context has already been initialized');
    }

    this.storesContext = createContext(stores);
  }
}
