import type {
  ICompany,
  IShift,
  Timestamp,
} from '@shiftsmartinc/shiftsmart-types';

import { observable, action } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import request from 'superagent-bluebird-promise';

import { app, service } from '#/shared/app';
import ThingStore from '#/shared/stores/things';
import { IStoreFindOpts } from '#/shared/stores/_baseStore';
import {
  redundancyConstant,
  backupConstant,
  shadowConstant,
} from '#/shared/components/shifts/constants';

export default class ShiftStore extends ThingStore<IShift> {
  constructor({
    serviceName = 'shifts',
    title = 'shifts',
    baseItem = ShiftStore.BASE_ITEM,
  } = {}) {
    super({
      baseItem: { ...ShiftStore.BASE_ITEM, ...baseItem },
      searchFields: [
        'title',
        'searchableText',
        'description',
        'status',
        'formattedAddress',
      ],
      serviceName,
      title,
    });

    return this;
  }

  static BASE_ITEM = {
    appActionType: null,
    children: [],

    defaultAssignmentAction: '',
    defaultChildren: [],
    enablePartnerCancel: false,
    quickTasks: [],
    shiftScheduleRules: {
      workingHoursEnd: null,
      workingHoursStart: null,
    },
    shiftScheduleType: '',
    shiftType: null,

    taskType: [],
  };

  @observable
  activeWorkers = [];

  @observable
  workerAssignments = {};

  @action
  updateList(json, opts) {
    return super.updateList(json, { ...opts });
  }

  @action
  setSelected(data = {}) {
    return super.setSelected(data, {});
  }

  @action
  create({ data, params = {} }) {
    const shift = _.clone(data);

    if (!_.isEmpty(shift.address) && _.isObject(shift.address)) {
      shift.addressObj = shift.address;
      shift.address = _.get(shift.addressObj, 'formattedAddress');
    }

    if (_.isEmpty(shift.address)) {
      delete shift.address;
    }

    return super.create({ data: shift, params });
  }

  @action
  async filterShiftAsRequestBy(
    { param = null, filter = undefined } = {},
    opts?: IStoreFindOpts<IShift>,
  ) {
    const query = {};

    if (param === 'addressRef') {
      _.merge(query, { addressRef: filter });
    }

    if (param === 'start') {
      _.merge(query, { start: filter });
    }

    return this.find({ query }, opts);
  }

  @action
  update({ data = {}, id = data.uuid, query = {} }) {
    const shift = _.clone(data);

    // TODO: Standardize address manipulation between create
    // and update methods, migrate to parent `Things` store
    // implementation. [PDF 2019-03-05]
    if (!_.isEmpty(shift.address) && _.isObject(shift.address)) {
      // TODO: THIS SHOULD BE SIMPLIFIED
      shift.addressObj = shift.address;
      shift.address = _.get(shift.addressObj, 'formattedAddress');
    }

    if (_.isEmpty(shift.address) && shift.address !== '') {
      delete shift.address;
    }

    return super.update({ data: shift, id, params: { query } });
  }

  /* Auxilliary & Utility Methods */

  deleteShift({ shift }) {
    this.log.debug('Removing shift', shift);
    const data = {
      status: 'Deleted',
      uuid: shift.uuid,
    };
    return this.update({ data });
  }

  clone({
    shift,
    thing = shift,
    shiftId,
    thingId = shiftId ?? '',
    shallow = false,
    week = false,
  }) {
    return super.clone({ shallow, thing, thingId, week });
  }

  findActiveShifts(baseQuery) {
    const query = _.defaults(baseQuery, {
      end: { $gte: moment().toDate() },
      start: {
        $lte: moment().add(1, 'h').toDate(),
      },
    });

    this.log.debug('Querying for Active Shifts', query);

    return service(this.serviceName)
      .find({ query })
      .then((json) => {
        this.log.debug('Got Active Shifts: ', json.data);
        return json;
      });
  }

  // obj: <worker: [shifts]>
  @action
  setActiveAssignments(worker, shift) {
    if (!this.workerAssignments[worker]) {
      this.workerAssignments[worker] = [];
    }

    if (_.includes(this.workerAssignments[worker], shift)) {
      return;
    }

    this.workerAssignments[worker].push(shift);
  }

  // TODO: Reconcile with things store `findActiveWorkers` if possible
  // [PDF 2019-03-05]
  findActiveWorkers(baseQuery) {
    let shiftCt = -1;
    return this.findActiveShifts(baseQuery)
      .then((json) => {
        const { data } = json;

        shiftCt = data.length;
        const assignments = _(data)
          .map('assignments')
          .flatten()
          .union()
          .value();

        return Promise.all(
          assignments.map((a) =>
            dispatch('assignments.get', a).then((res) => {
              this.setActiveAssignments(res.user, res.uuid);

              return res.user;
            }),
          ),
        );
      })
      .then((workers) => {
        this.setActiveWorkers(_.uniq(workers));
        this.log.debug(
          'Found %d Active Workers across %d shifts',
          this.activeWorkers.length,
          shiftCt,
          this.activeWorkers,
        );
        return this.activeWorkers;
      });
  }

  /** TODO: Split out logic into a "reports" store/engine/utility */
  @action
  async buildReport({
    ref,
    company,
    start,
    end,
    status,
  }: {
    company?: ICompany['uuid'];
    end?: Timestamp;
    ref?: IShift['uuid'];
    start?: Timestamp;
    status?: IShift['status'];
  }) {
    // Using REST method here to load large amount of data
    // Bypassing the timeout set for sockets
    const accessToken = await app().authentication.getAccessToken();
    const uri = dispatch('app.getServerURI');
    return request
      .get(`${uri}/shifts`)
      .set('Authorization', accessToken)
      .query({ buildReport: true, company, end, ref, start, status })
      .catch((err) => {
        this.log.error('Error Getting Shifts Report', err);

        throw err;
      });
  }

  @observable
  shiftTypes = [
    {
      key: 0,
      text: 'Standard',
      value: 'standard',
    },
    {
      key: 1,
      text: 'Training',
      value: 'training',
    },
    {
      key: 2,
      text: 'Dry-run',
      value: 'dryRun',
    },
    {
      key: 3,
      text: 'Orientation',
      value: 'orientation',
    },
    {
      key: 4,
      text: 'App Action',
      value: 'appAction',
    },
    {
      key: 5,
      text: 'Ambassador',
      value: 'ambassador',
    },
  ];

  @observable
  appActionTypes = [
    {
      key: 0,
      text: 'Base Profile Completion',
      value: 'baseProfileCompletion',
    },
  ];

  get inviteModes() {
    return SHIFT_INVITE_MODES;
  }

  getRedundancyTypeName = (shift: IShift) => {
    if (shift && shift.originalBaseShiftId) {
      if (
        (!shift.redundancyType && shift.redundancyCreationMethod) ||
        shift.redundancyType === 'redundant'
      ) {
        return redundancyConstant[shift.redundancyCreationMethod];
      } else if (shift.redundancyType === 'backup') {
        return backupConstant['manualBackupRedundancy'];
      } else if (shift.redundancyType === 'shadow') {
        return shadowConstant['manualShadowRedundancy'];
      }
    }
    return null;
  };
  getRedundancyTypeColor = (shift: IShift) => {
    if (shift && shift.originalBaseShiftId) {
      if (
        (!shift.redundancyType && shift.redundancyCreationMethod) ||
        shift.redundancyType === 'redundant'
      ) {
        return 'redundant';
      } else if (shift.redundancyType === 'backup') {
        return 'backup';
      } else if (shift.redundancyType === 'shadow') {
        return 'shadow';
      }
    }
    return null;
  };
}

const SHIFT_INVITE_MODES = [
  {
    key: 'none',
    text: 'Use System Default',
    value: null,
  },
  {
    description:
      'Send an individual assignment for each partner invited to each shift (default)',
    key: 'assignments',
    text: 'Create Assignments',
    value: 'assignments',
  },
  {
    description: '(experimental)',
    key: 'invites',
    text: 'Create Invites (beta)',
    value: 'invites',
  },
  {
    description:
      'Create assignments and populate the shift invites service (experimental)',
    key: 'hybrid',
    text: 'Hybrid (create both)',
    value: 'hybrid',
  },
];

export { SHIFT_INVITE_MODES as shiftInviteModes };
