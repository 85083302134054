import { action, observable } from 'mobx';

import { init } from '#/shared/forms/product';

export default class ProductEditModal {
  @observable
  isOpen = false;

  @observable
  form = {};

  @action
  setup({ values, open = this.isOpen }) {
    this.form = init(values);

    this.isOpen = open;
  }

  @action
  clear(args = {}) {
    this.form.clear();

    this.isOpen = !!args.open;
  }
}
