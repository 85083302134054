import type { IStoreList } from '@shiftsmartinc/shiftsmart-types';

import { action } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import BaseStore from './_baseStore';

const SERVICE = 'storeLists';
export default class StoreListStore extends BaseStore<IStoreList> {
  constructor() {
    super({ baseItem: StoreListStore.storeList, serviceName: SERVICE });

    return this;
  }

  static storeList = {
    companies: null,
    stores: [],
    title: null,
  };

  sort = { start: 1 };
}
