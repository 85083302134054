import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';

import BaseStore from './_baseStore';

export type ITagGroups = IBaseItem & Record<string, unknown>;

export default class TagGroupsStore extends BaseStore<ITagGroups> {
  constructor() {
    const baseItem = {
      companies: [],
      createdAt: null,
      isDeleted: null,
      tags: [],
      title: null,
      type: null,
      updatedAt: null,
      uuid: null,
    };
    super({ baseItem, serviceName: 'tagGroups' });

    return this;
  }

  create({ data = null }) {
    if (!_.get(data, 'companies') && !_.get(data, 'company')) {
      const company = _.get(this.query, 'query.companies');

      if (_.isEmpty(company)) {
        return Promise.reject('Unable to create Tag Group without Company');
      }

      _.extend(data, { companies: _.isArray(company) ? company : [company] });
    }

    return super.create({ data });
  }
}
