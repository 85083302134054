import type { ICompany, IUser, UUID } from '@shiftsmartinc/shiftsmart-types';

import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { dispatch } from 'rfx-core';
import cx from 'classnames';
import { Button, Header, Image, Label, Menu, Popup } from 'semantic-ui-react';
import { CSSProperties } from 'styled-components';

import styles from '#/shared/styles/AppNav.css';
import ListSearch from '#/shared/components/utils/ListSearch';

// const log = logdown.getChildLogger('CompanyMenu');

const SelectedCompanyMenu = observer(
  ({
    user,
    company,
    isAdmin,
    impersonatedCompanyId,
    companies,
    searchValue,
    isManager,
    vertical,
    textStyle = {},
    noClick,
  }: {
    companies?: Array<ICompany>;
    company: ICompany;
    impersonatedCompanyId?: string;
    isAdmin?: boolean;
    isManager?: boolean;
    noClick: boolean;
    searchValue?: string;
    textStyle?: unknown;
    user: IUser;
    vertical?: boolean;
  }) => {
    const isImpersonating =
      !_.isEmpty(company.uuid) && impersonatedCompanyId === company.uuid;

    let companyLabel = (
      <CompanyButton
        company={company}
        isAdmin={isAdmin}
        isImpersonating={isImpersonating}
        noClick={noClick}
        style={textStyle}
      />
    );

    if (companies && companies.length > 1) {
      companyLabel = (
        <Button.Group
          basic={true}
          className={cx(isAdmin && 'pointer')}
          color={isImpersonating ? 'green' : undefined}
          fluid={vertical}
        >
          <CompanyButton
            company={company}
            isAdmin={isAdmin}
            isImpersonating={isImpersonating}
            noClick={noClick}
            style={textStyle}
          />

          <CompanySelect
            activeCompany={company}
            companies={companies}
            searchValue={searchValue}
            userId={user.uuid}
          />
        </Button.Group>
      );
    }

    return (
      <>
        {!isManager && (
          <Menu.Item
            className={cx(styles.item)}
            content={
              <Header
                className={styles.header}
                content={companyLabel}
                style={textStyle}
              />
            }
          />
        )}

        {isManager && (
          <Menu.Item
            className={cx(styles.item)}
            content={
              <Header
                className={styles.header}
                content={companyLabel}
                style={textStyle}
              />
            }
            onClick={
              !noClick &&
              (() =>
                dispatch('routing.push', `/companies/${company.uuid}/manage`))
            }
          />
        )}
      </>
    );
  },
);

SelectedCompanyMenu.displayName = 'SelectedCompanyMenu';
export default SelectedCompanyMenu;

const CompanyButton = observer(
  ({
    company,
    isAdmin,
    isImpersonating,
    style: textStyle,
    noClick,
  }: {
    company: ICompany;
    isAdmin: boolean;
    isImpersonating: boolean;
    noClick: boolean;
    style: CSSProperties | undefined;
  }) => {
    let companyName = company.name || 'No Company Selected';

    if (companyName.length > 20) {
      let spaceIndex = companyName.substring(17).search(/[,. ]/);
      if (spaceIndex > 5 || spaceIndex === -1) {
        spaceIndex = 19;
      } else {
        spaceIndex += 17;
      }

      companyName = `${company.name.slice(0, spaceIndex)}…`;
    }

    let content: unknown = companyName;

    if (/…/.test(companyName)) {
      content = (
        <Popup
          content={company.name}
          flowing={true}
          on="hover"
          position="bottom right"
          trigger={<span>{companyName}</span>}
        />
      );
    }

    const style = _.extend(
      {
        color: 'var(--grey10)',
        fontFamily: 'Roboto, "Open Sans", sans-serif !important',
        fontSize: '12px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        fontWeight: '600',
        height: '23px',
        letterSpacing: 'normal',
        textAlign: 'center',
      },
      textStyle,
    );

    const clickProps = !noClick && {
      onClick: () => gotoCompany({ isAdmin, uuid: company.uuid }),
      role: 'button',
    };

    return (
      <div
        className="br2 ph2 pv1 flex items-center"
        style={style}
        {...clickProps}
      >
        {content}
        {isImpersonating && (
          <span className="pl2">
            <Label basic={true} circular={true} color="green" size="mini" />
          </span>
        )}
      </div>
      // <Button
      //   className={cx('compact', isAdmin && 'pointer')}
      //   style={_.extend(style, {
      //     paddingRight: '0.2rem',
      //     paddingLeft: '0.5rem',
      //   })}
      //   color={isImpersonating ? 'green' : undefined}
      //   content={content}
      //   basic
      //   onClick={() => gotoCompany({ uuid: company.uuid, isAdmin })}
      // />
    );
  },
);
CompanyButton.displayName = 'CompanyButton';

const CompanySelect = observer(
  ({ activeCompany, companies, userId, searchValue }) => {
    const content = (
      <Menu secondary={true} vertical={true}>
        <Menu.Header className="pb2">Switch Companies...</Menu.Header>
        <Menu.Item className="pb2" fitted={true}>
          <ListSearch
            className="search"
            onChange={(e, { value }) =>
              dispatch('companies.getForUser', userId, {
                search: value,
              })
            }
            onClear={() => dispatch('companies.getForUser', userId)}
            search={searchValue}
            storeName="companies"
            swallowClicks={true}
          />
        </Menu.Item>
        {_.map(companies, (co) => (
          <Menu.Item
            content={
              <div className="w-100 pv1 flex items-center">
                <div className="h2 w2 ph1">
                  <Image
                    src={co.images && (co.images.avatar || co.images.logo)}
                  />
                </div>
                <div className="flex-grow-1">{co.name}</div>
              </div>
            }
            fitted={true}
            key={co.uuid}
            onClick={() => {
              const currentCoId = _.get(activeCompany, 'uuid', activeCompany);
              dispatch('ui.company.setup', { companies, company: co }).then(
                (selectedCompanyId) => {
                  if (selectedCompanyId !== currentCoId) {
                    dispatch('routing.refresh');
                  }
                },
              );
            }}
          />
        ))}
      </Menu>
    );

    return (
      <Popup
        className="pa2"
        on="click"
        trigger={<Button className="max-w2 fitted-h" icon="angle down" />}
      >
        {content}
      </Popup>
    );
  },
);

CompanySelect.displayName = 'CompanySelect';

function gotoCompany({ uuid, isAdmin }: { isAdmin: boolean; uuid: UUID }) {
  if (_.isEmpty(uuid)) {
    return dispatch('routing.push', `${isAdmin ? '/admin' : ''}/companies`);
  }

  if (isAdmin) {
    return dispatch(
      'routing.push',
      isAdmin ? `/admin/companies/${uuid}` : '/companies',
    );
  }

  return dispatch('ui.company.setup', { company: uuid }).then(() =>
    dispatch('routing.push', '/companies'),
  );
}
