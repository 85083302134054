import { observable, action, set } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class CampaignWork {
  log = getChildLogger('ui.CampaignWorks');

  @observable
  campaign = {};

  @observable
  campaignId = '';

  @observable
  campaignsQuery = {};

  @observable
  storesQuery = {};

  @observable
  productsQuery = {};

  @observable
  personasQuery = {};

  @observable
  surveysQuery = {};

  async setup({ company = dispatch('auth.getCompany'), campaign }) {
    const log = this.log.getChildLogger('setup');
    const companyId = _.get(company, 'uuid', company);
    if (!_.isString(companyId) || _.isEmpty(companyId)) {
      throw new Error('Must specify company');
    }

    const campaignId = _.get(campaign, 'uuid', campaign);

    if (!_.isEmpty(campaign) && !_.isObject(campaign)) {
      const campaignObject = await dispatch('campaigns.get', campaignId, {
        select: false,
      });

      this.setCampaign(campaignObject);
    } else {
      this.setCampaign(campaign || {});
    }

    const campaignRestrictions = dispatch('abilities.buildQueryForSubject', {
      subject: 'campaign',
    });

    const campaignsQuery = _.extend(
      { company: companyId, uuid: campaignId },
      campaignRestrictions,
    );

    let availableProps;

    if (!_.isEmpty(campaignRestrictions)) {
      availableProps = await dispatch(
        'campaigns.loadAvailablePropsForCampaigns',
        { query: campaignsQuery },
      );
    }

    const storesQuery = _.extend(
      { companies: companyId },
      dispatch('abilities.buildQueryForSubject', { subject: 'store' }),
    );

    const productsQuery = _.extend(
      { company: companyId },
      dispatch('abilities.buildQueryForSubject', { subject: 'product' }),
    );

    const personasQuery = _.extend(
      {},
      dispatch('abilities.buildQueryForSubject', { subject: 'persona' }),
    );

    const surveysQuery = _.extend(
      { company: companyId },
      dispatch('abilities.buildQueryForSubject', {
        subject: 'surveyDefinition',
      }),
    );

    if (!_.isEmpty(availableProps)) {
      const storeLists = _(availableProps)
        .map('storeList')
        .uniq()
        .compact()
        .value();
      const products = _(availableProps)
        .map('productUUID')
        .uniq()
        .compact()
        .value();
      const personas = _(availableProps)
        .map('personaUUID')
        .uniq()
        .compact()
        .value();
      const surveys = _(availableProps)
        .map('surveyUUID')
        .uniq()
        .compact()
        .value();

      if (!_.isEmpty(products)) {
        if (!productsQuery.$and) {
          productsQuery.$and = [];
        }
        productsQuery.$and.push({ uuid: { $in: products } });
      }

      if (!_.isEmpty(personas)) {
        if (!personasQuery.$and) {
          personasQuery.$and = [];
        }
        personasQuery.$and.push({ uuid: { $in: personas } });
      }

      if (!_.isEmpty(surveys)) {
        if (!surveysQuery.$and) {
          surveysQuery.$and = [];
        }
        surveysQuery.$and.push({ uuid: { $in: surveys } });
      }

      if (!_.isEmpty(storeLists)) {
        if (!storesQuery.$and) {
          storesQuery.$and = [];
        }
        storesQuery.$and.push({ 'storeLists.uuid': { $in: storeLists } });
      }
    }

    this.setQueries({
      campaigns: campaignsQuery,
      personas: personasQuery,
      products: productsQuery,
      stores: storesQuery,
      surveys: surveysQuery,
    });

    log.debug('Retail Entity Queries configured:', {
      campaigns: this.campaignsQuery,
      personas: this.personasQuery,
      products: this.productsQuery,
      stores: this.storesQuery,
      surveys: this.surveysQuery,
    });

    this.loadAssignments({ campaignsQuery: this.campaignsQuery });
  }

  async loadAssignments({ campaignsQuery = this.campaignsQuery }) {
    const log = this.log.getChildLogger('loadAssignments');
    const assignmentQuery = {
      // ATTN: Assignment Status EP-5523
      $limit: 20,

      // $client: { populateUser: true },
      $sort: {
        updatedAt: -1,
      },

      dataType: 'survey',

      status: 'Completed',
    };

    assignmentQuery.company = dispatch('auth.getCompany').uuid;

    if (this.campaignId) {
      assignmentQuery.campaign = this.campaignId;
    }
    const queryAssignmentsOnLoad = dispatch(
      'auth.can',
      'access',
      'surveyResponses',
      'onLoad',
    );
    const doQueryAssignments =
      !_.isEmpty(this.campaignId) || queryAssignmentsOnLoad;

    if (doQueryAssignments && _.isEmpty(this.campaignId)) {
      const {
        data: foundCampaigns,
        total,
        limit,
      } = await dispatch('campaigns.runQuery', campaignsQuery);

      if (total > limit) {
        log.warn('Unable to load all available campaigns', {
          limit,
          total,
        });
      }

      const campaignUUIDs = _(foundCampaigns).map('uuid').compact().value();

      /** NOTE
       * In the case where campaignUUIDs is empty, this means that the user does not have any accessible
       * campaigns. In this case, we still want to enforce the restriction, and setting `$in : []` does
       * the trick.
       *  */
      assignmentQuery.campaign = { $in: campaignUUIDs };
    }

    const foundAssignments = await dispatch(
      'assignments.find',
      { query: assignmentQuery },
      { clear: true, noQuery: !doQueryAssignments },
    );

    if (doQueryAssignments) {
      log.silly('Found %d Assignments', _.size(foundAssignments));
    }

    if (!doQueryAssignments) {
      dispatch('assignments.emptyList');
    }

    return foundAssignments;
  }

  @action setQueries({ campaigns, stores, products, personas }) {
    if (campaigns) {
      this.setCampaignQuery(campaigns);
    }
    if (stores) {
      this.setStoresQuery(stores);
    }
    if (products) {
      this.setProductsQuery(products);
    }
    if (personas) {
      this.setPersonasQuery(personas);
    }
  }

  @action
  setCampaign(campaign) {
    if (_.isEmpty(campaign)) {
      this.campaign = {};
    } else {
      set(this.campaign, campaign);
    }
    this.setCampaignId(_.get(campaign, 'uuid'));
  }

  @action
  setCampaignId(campaignId) {
    this.campaignId = campaignId || '';
  }

  @action
  setCampaignQuery(query = {}) {
    if (_.isEmpty(query)) {
      this.campaignsQuery = {};
    } else {
      set(this.campaignsQuery, query);
    }
  }

  @action
  setStoresQuery(query = {}) {
    if (_.isEmpty(query)) {
      this.storesQuery = {};
    } else {
      set(this.storesQuery, query);
    }
  }

  @action
  setProductsQuery(query = {}) {
    if (_.isEmpty(query)) {
      this.productsQuery = {};
    } else {
      set(this.productsQuery, query);
    }
  }

  @action
  setPersonasQuery(query = {}) {
    if (_.isEmpty(query)) {
      this.personasQuery = {};
    } else {
      set(this.personasQuery, query);
    }
  }

  @action
  setSurveysQuery(query = {}) {
    if (_.isEmpty(query)) {
      this.surveysQuery = {};
    } else {
      set(this.surveysQuery, query);
    }
  }

  @action clear() {
    this.campaign = {};
    this.campaignsQuery = {};
    this.storesQuery = {};
    this.productsQuery = {};
    this.personasQuery = {};
    this.surveysQuery = {};
  }
}
