import type { IAgendaJobProgress } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { dispatch } from 'rfx-core';

import BaseStore from './_baseStore';

export default class AgendaJobProgressStore extends BaseStore<IAgendaJobProgress> {
  constructor() {
    super({
      baseItem: AgendaJobProgressStore.BASE_ITEM,
      serviceName: 'agendaJobProgress',
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  onPatched = async (data) => {
    const res = await this.onUpdated(data);
    if (data.progress && data.progress >= 100) {
      dispatch('ui.agendaProgressLoadingModal.close', {
        delay: 1000,
        message: 'Shift Activation Complete',
      });
    }
    return res;
  };

  static BASE_ITEM = {
    agendaJobId: '',
    companyId: '',
    errorMsg: '',
    jobName: '',
    progress: undefined,
    status: 'pending',
  };
}
