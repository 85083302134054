import { action, observable, computed, set, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

/**
 * Store for Campaign Details specific logic
 *
 * @export
 * @class CampaignDetails
 */

export default class CampaignDetails {
  log = getChildLogger('ui.CampaignDetails');

  @observable
  campaign = {};

  listenerId = null;

  @observable
  agentQuery = {};

  @action
  async setup({ campaign }) {
    // this.setCampaign(campaign);
    this.setCampaign(campaign);

    dispatch('employerUsers.findByCompany', {
      company: _.get(campaign.company, 'uuid', campaign.company),
      query: {
        companyStatus: { $elemMatch: { roles: 'survey-review' } },
      },
    });

    const { company } = this.campaign;

    this.agentQuery = {
      companyStatus: {
        $elemMatch: {
          company: _.get(company, 'uuid', company),
          roles: 'survey-review',
          status: 'active',
        },
      },
      uuid: { $nin: _.map(this.reviewers, 'uuid') },
    };

    this.listenerId = dispatch('campaigns.registerEventListener', {
      onPatched: (data) =>
        data.uuid === this.campaign.uuid && this.setCampaign(data),
    });
  }

  @action
  setCampaign(val) {
    set(this.campaign, val);
  }

  @computed
  get reviewers() {
    return _.get(this.campaign, 'reviewers') || [];
  }

  @observable showAddReviewer = false;

  @action
  toggleAddReviewer(val = !this.showAddReviewer) {
    this.showAddReviewer = val;
  }

  async addReviewer(user) {
    const log = this.log.getChildLogger('addReviewer');
    if (_.find(this.reviewers, { uuid: user.uuid })) {
      log.debug('User is already present in reviewers for this campaign');
      return;
    }

    const data = _.extend(_.pick(user, ['uuid', 'displayName']), {
      createdAt: new Date(),
      isEnabled: true,
      updatedAt: new Date(),
    });

    try {
      await dispatch('campaigns.update', {
        data: {
          $push: {
            reviewers: data,
          },
        },
        id: this.campaign.uuid,
      });

      this.toggleAddReviewer(false);

      runInAction(() => {
        this.agentQuery.uuid = {
          $nin: _(this.reviewers).map('uuid').union([user.uuid]).value(),
        };
      });
    } catch (err) {
      log.error('Failed to add Reviewer', err, {
        extra: {
          campaign: this.campaign.uuid,
          user: _.get(user, 'uuid', user),
        },
      });
      dispatch('ui.snackBar.error', 'Unable to Add Survey Reviewer');
    }
  }

  async removeReviewer(uuid) {
    const log = this.log.getChildLogger('removeReviewer');
    try {
      await dispatch('campaigns.update', {
        data: {
          $pull: {
            reviewers: { uuid },
          },
        },
        id: this.campaign.uuid,
      });

      runInAction(() => {
        this.agentQuery.uuid = {
          $nin: _(this.reviewers).map('uuid').without(uuid).value(),
        };
      });
    } catch (err) {
      log.error('Failed to remove Reviewer', err, {
        extra: { campaign: this.campaign.uuid, user: uuid },
      });
      dispatch('ui.snackBar.error', 'Unable to Remove Survey Reviewer');
    }
  }

  @action
  clear() {
    this.campaign = {};
    this.agentQuery = null;

    this.showAddReviewer = false;

    if (this.listenerId) {
      dispatch('campaigns.deregisterEventListener', this.listenerId);
      this.listenerId = null;
    }
  }
}
