import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { service } from '#/shared/app';

export default class Payments {
  @observable
  isLoading = false;

  @observable
  userId = null;

  @action
  setup({ user, pendingTransaction = {} }) {
    this.isLoading = true;

    this.userId = _.get(user, 'uuid', user);

    if (_.isEmpty(pendingTransaction.uuid)) {
      this.onCreated = service('payments/transactions').on(
        'created',
        action(this.onNewTransaction),
      );
    }

    this.isLoading = false;

    // this.listenForNewThings();

    // if (_.isEmpty(this.pendingTransaction.refs)) {
    //   this.isLoading = false;
    //   return;
    // }

    // this.loadIncludedThings().then(
    //   action(() => {
    //     this.isLoading = false;
    //   }),
    // );
  }

  onNewTransaction = (item) => {
    if (item.status === 'draft' && item.user === this.userId) {
      // await dispatch('ui.payments.setPendingTransaction', item);
      dispatch('paymentTransactions.setPendingTransaction', item);

      // dispatch('ui.payments.loadIncludedThings');
      // dispatch('ui.payments.listenForNewThings');
    }
  };

  @action
  clear() {
    this.userId = null;

    dispatch('paymentTransactions.setPendingTransaction');

    if (_.isFunction(this.onCreated)) {
      this.onCreated();
      this.onCreated = null;
    }

    this.isLoading = false;
  }
}
