import type { IUser } from '@shiftsmartinc/shiftsmart-types';

import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import {
  parsePhoneNumber,
  PhoneNumber as ParsedPhoneNumberProps,
} from 'libphonenumber-js';
import ReactCountryFlag from 'react-country-flag';
import { Icon, Popup } from 'semantic-ui-react';

import { NA } from '#/shared/components/ssm';

const PhoneNumber = observer(
  ({
    val,
    className,
    defaultText,
  }: {
    className?: string;
    defaultText?: string;
    val: string;
  }) => {
    if (_.isEmpty(val)) {
      return <NA content={defaultText} />;
    }

    try {
      const number = parsePhoneNumber(val, 'US');

      return (
        <span className={className}>
          <UserFlag number={number} />
          {number.format(
            `${/^US$/.test(number.country) ? '' : 'INTER'}NATIONAL`,
          )}
        </span>
      );
    } catch (err) {
      return <span className={className}>{val}</span>;
    }
  },
);

PhoneNumber.displayName = 'PhoneNumber';
export default PhoneNumber;

const UserFlag = observer(
  ({
    number: baseNumber,
    user,
    className,
    showUSA,
  }: {
    className?: string;
    number: ParsedPhoneNumberProps;
    showUSA?: boolean;
    user?: IUser;
  }) => {
    try {
      const number = _.has(baseNumber, 'country')
        ? baseNumber
        : parsePhoneNumber(user.phoneNumber, 'US');

      if (!showUSA && /^US$/.test(number.country)) {
        return null;
      }

      return (
        <Popup
          content={
            <span>
              <Icon name="phone square" />
              {number.country}
            </span>
          }
          trigger={
            <span className={className}>
              <ReactCountryFlag code={number.country || ''} />
            </span>
          }
        />
      );
    } catch (err) {
      return <Icon name="question circle outline" />;
    }
  },
);

export { PhoneNumber, UserFlag };
