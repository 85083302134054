import { observable, action } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.CampaignDispatch');

export default class Dispatch {
  @observable
  campaign = {};

  @observable
  retailer = '';

  @observable
  region = '';

  @observable
  survey = '';

  @observable
  loading = false;

  @observable
  filter = 'all';

  @observable
  filterOptions = [
    { enabled: true, key: 'all', text: 'All Surveys', value: 'all' },
    {
      enabled: true,
      key: 'pending',
      text: 'Pending Surveys',
      value: 'pending',
    },
    { enabled: true, key: 'active', text: 'Active Surveys', value: 'active' },
    { enabled: true, key: 'done', text: 'Completed Surveys', value: 'done' },
    {
      enabled: false,
      key: 'inactive',
      text: 'Dead Surveys',
      value: 'inactive',
    },
  ];

  @action
  setup(campaignSlice) {
    this.loading = true;
    this.campaign = _.get(campaignSlice, 'campaign', {});
    this.retailer = _.get(campaignSlice, 'retailer', null);
    this.region = _.get(campaignSlice, 'region', null);
    this.survey = _.get(campaignSlice, 'survey', null);

    this.query = {
      $limit: 20,
      region: this.region || undefined,
      retailer: this.retailer || undefined,
    };

    this.loading = true;

    return dispatch('stores.find', this.query, { clear: true })
      .then((res) => res)
      .catch(
        action((err) => {
          log.error('Failed to load stores', err);

          this.loading = false;
          return Promise.reject('Unable to load Stores');
        }),
      );
  }
}
