import { observable, action, computed } from 'mobx';
import moment from 'moment';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.dashboard');

export default class Dashboard {
  @observable firstShiftOfDay = null;

  @observable
  positions = ['all'];

  @observable
  allPositions = ['all'];

  @observable
  positionFilter = ['all'];

  @observable
  atRisksShiftIds = [];

  @observable
  todayShiftMetricsIsLoading = true;

  @observable
  atRiskShiftObjs = [];

  @observable
  atRiskShiftObjsIsLoading = false;

  @action
  setAtRiskShiftIds(atRisksShiftIds) {
    this.set('atRisksShiftIds', atRisksShiftIds);
  }

  @action
  async loadPositions() {
    const company = dispatch('auth.getCompany');
    this.set(
      'positions',
      await dispatch('positions.findByCompany', {
        company: company.uuid,
        query: { $sort: { title: 1 } },
      }),
    );
    this.allPositions.replace(_.map(this.positions, 'uuid'));
  }

  @computed get positionFilterSummary() {
    const activeFilters = this.positionFilter.includes('all')
      ? ['All']
      : _(this.positionFilter)
          .map((id) => dispatch('positions.getSync', id))
          .map('title')
          .value();

    return activeFilters.length > 3
      ? `${activeFilters.length} Selected`
      : activeFilters.join(', ');
  }

  @action.bound async togglePosition(position, { checked, exclusive, all }) {
    if (exclusive) {
      this.positionFilter.clear();
    }

    if (all) {
      this.positionFilter.replace(['all']);
    } else if (checked) {
      this.positionFilter.push(position.uuid);
    } else {
      if (this.positionFilter.includes('all')) {
        this.positionFilter.replace(this.allPositions);
      }
      this.positionFilter.remove(position.uuid);
    }

    if (this.positionFilter.length === this.allPositions.length) {
      this.positionFilter.replace(['all']);
    }

    await this.loadTodayShifts();
    await this.loadAtRiskShifts();
  }

  @action
  async loadTodayShifts() {
    this.set('todayShiftMetricsIsLoading', true);
    const company = dispatch('auth.getCompany');
    const query = {
      company: company.uuid,
      start: {
        $gte: moment().startOf('day').toDate(),
        $lte: moment().endOf('day').toDate(),
      },
      status: { $in: ['Active', 'Filled', 'Completed'] },
    };

    if (!this.positionFilter.includes('all')) {
      query['search.tags'] = { $in: this.positionFilter };
    }

    try {
      await dispatch('shifts.find', query, { clear: true });
    } catch (error) {
      log.error('Error loading shifts of today', error, {
        extra: {
          query,
        },
      });
      throw error;
    } finally {
      this.set('todayShiftMetricsIsLoading', false);
    }
  }

  @action
  async loadAtRiskShifts() {
    this.set('atRiskShiftObjsIsLoading', true);
    const query = {
      $sort: { start: 1 },
      uuid: { $in: this.atRisksShiftIds },
    };

    if (!this.positionFilter.includes('all')) {
      query['search.tags'] = { $in: this.positionFilter };
    }

    try {
      // const shifts = await dispatch('shifts.find', query);
      const shifts = await dispatch('shifts.runQuery', query);
      this.set('atRiskShiftObjs', shifts.data);
    } catch (error) {
      this.set('atRiskShiftObjs', []);
    } finally {
      this.set('atRiskShiftObjsIsLoading', false);
    }
  }

  @action
  async loadFirstShiftOfDay() {
    const company = dispatch('auth.getCompany');
    const query = {
      $sort: { start: 1 },
      company: company.uuid,
      start: {
        $gte: moment().startOf('day').toDate(),
      },
      status: { $in: ['Active', 'Filled', 'Completed'] },
    };
    try {
      const response = await dispatch('shifts.runQuery', query);
      this.set('firstShiftOfDay', _.first(response.data));
    } catch (error) {
      log.error('Error loading first shift of the day', error, {
        extra: {
          query,
        },
      });
      throw error;
    }
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    _.set(this, key, value);
  }

  clear() {
    this.firstShiftOfDay = null;
  }
}
