import React from 'react';
import { observer } from 'mobx-react';

import Accordion from '#/shared/components/ssm/Accordion';
import typography from '#/shared/styles/typography.css';
import GenericMultiselectDropdownFilter from '#/shared/components/utils/filters/GenericMultiselectDropdownFilter';

const GenericSearchFilter = observer(
  ({
    title,
    options,
    onSelect,
    onDeselect,
    canUpdate = true,
    selected = [],
    defaultExpanded = false,
    tooltip,
  }: {
    canUpdate?: boolean;
    defaultExpanded?: boolean;
    onDeselect: (item) => void;
    onSelect: (item) => void;
    options: { key: string; text: string; value: string }[];
    selected: string[];
    title: string;
    tooltip?: string;
  }) => (
    <Accordion
      canToggle={true}
      defaultExpanded={defaultExpanded}
      tooltip={tooltip}
      vertical={true}
    >
      <div className="flex">
        <div className="h-100 flex items-center">
          <div className={typography.h3}>{title}</div>
        </div>
      </div>

      <GenericMultiselectDropdownFilter
        canUpdate={canUpdate}
        onDeselect={onDeselect}
        onSelect={onSelect}
        options={options}
        selected={selected}
      />
    </Accordion>
  ),
);

export default GenericSearchFilter;
