import { observable, action, set, runInAction, computed } from 'mobx';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import _ from 'lodash';
import moment from 'moment';
import { Parser } from 'json2csv';

import BaseUploader, {
  KnownFieldConfig,
} from '#/shared/stores/ui/BaseUploader';
import { getStore } from '#/shared/getStores';
import { IS_PRODUCTION } from '#/config/settings';

export default class ShiftPaymentApprovalUpload extends BaseUploader {
  constructor() {
    super({ title: 'ui.ShiftPaymentApprovalUpload' });

    set(this.saveResults, {
      ignored: [],
      invalid: [],
      updated: [],
    });

    return this;
  }

  @observable parseOptions = {
    dynamicTyping: false,
    header: false,
    skipEmptyLines: true,
  };

  /** list
   * Stores an array of rows, Ready for saving.
   */
  @observable
  list = [];

  /**
   * Parsed Headers
   * An array of all headers found in the parsed objects
   *
   * Used to render the column headers in a dynamic table.
   *
   * @memberof ShiftPaymentApprovalUpload
   */
  @observable
  parsedHeaders = [];

  @observable
  resultHeaders = [];

  @computed
  get knownFields(): KnownFieldConfig[] {
    return [
      { key: 'paymentApprovalStatus' },

      { key: 'assignmentUUID' },

      { key: 'shiftUUID' },

      { key: 'shiftTitle' },

      { key: 'companyUUID' },

      { key: 'partnerUUID' },

      { key: 'partnerPhoneNumber' },

      { key: 'partnerName' },

      { key: 'assignmentStatus' },

      { key: 'revisedHours' },

      { key: 'paymentNote' },
    ];
  }

  @computed get knownHeaders() {
    return _.map(this.knownFields, 'key');
  }

  @observable
  includeHowTo = false;

  @computed
  get csvData() {
    return this.$sampleCSVData;
  }

  @observable
  uploadJobSent = false;

  @action.bound
  setUploadJobSent(val: boolean) {
    this.uploadJobSent = val;
  }

  @observable
  uploadStep = 'paymentDetails';

  @action.bound
  setUploadStep(val: 'paymentDetails' | 'paymentApprovals' | 'done') {
    this.uploadStep = val;
  }

  @action
  async setup(opts, ...rest) {
    super.setup(opts, ...rest);

    this.clear();

    this.company = opts.company;

    if (_.isString(this.company)) {
      const company = await dispatch('companies.get', this.company, {
        select: false,
      });
      runInAction(() => {
        this.company = company;
      });
    }
  }

  @action
  clear() {
    super.clear();

    this.saveResults = {
      ignored: [],
      invalid: [],
      updated: [],
    };

    this.setUploadJobSent(false);
    this.setStatus('pending');
    this.setUploadStep('paymentDetails');

    this.list.clear();
    this.parsedHeaders.clear();
    this.resultHeaders.clear();
  }

  @action
  async processFile({ options = this.parseOptions, ...rest }) {
    this.setStatus('loading');

    try {
      await super.processFile({ options, ...rest });

      this.setStatus('loaded');
    } catch (err) {
      this.log.error('Failed to process file', err);

      this.setStatus('error');

      throw err;
    }
  }

  @action
  async parse({ rows }) {
    let parsedHeaders = [];
    const casedHeaders = _.map(rows[0], _.toLower);
    const indices = _.reduce(
      this.knownHeaders,
      (acc, header) => {
        acc[header] = _.indexOf(casedHeaders, _.toLower(header));
        return acc;
      },
      {},
    );

    this.log.debug('Loaded Table Indices', { indices });

    const validRows = _(rows)
      .map((row, i) => {
        if (!i) return null;

        this.log.debug(row);
        return _.reduce(
          this.knownHeaders,
          (acc, colKey) => {
            const colIndex = indices[colKey];

            if (colIndex >= 0) {
              acc[colKey] = row[colIndex];
            }

            return acc;
          },
          {},
        );
      })
      .reject((rowObj) => _.isEmpty(_.omitBy(rowObj, _.isEmpty)))
      .map((row) => {
        parsedHeaders = _.union(parsedHeaders, _.keys(row));
        return row;
      })
      .compact()
      .value();

    this.parsedHeaders = parsedHeaders;

    this.setStatus('Evaluating');
    // eslint-disable-next-line no-console
    console.table(validRows);

    runInAction(() => {
      this.list.replace(validRows);
    });

    this.setStatus('Parsed all Rows');
  }

  async displayUpdatedData(newData, updatedAssignment) {
    if (updatedAssignment) {
      runInAction(() => {
        const updatedData = {
          approvalStatus: _.get(updatedAssignment, 'paymentInfo.approval'),
          payableDuration: _.get(updatedAssignment, 'payableDuration'),
          paymentDue: _.get(updatedAssignment, 'payment.paymentDue'),
          updateStatus: 'updated',
          updatedNote: _.get(updatedAssignment, 'payment.updatedNote'),
        };
        _.extend(newData, updatedData);
        this.saveResults.updated.push(newData);
      });
    } else {
      runInAction(() => {
        _.set(newData, 'updateStatus', 'ignored');
        this.saveResults.ignored.push(newData);
      });
    }
  }

  @action
  async updatePaymentDetails({ newData }) {
    const authUser = dispatch('auth.getUser');
    if (newData.revisedHours || newData.paymentNote) {
      const patchData = {
        'payment.updatedNote': `payment updated during upload on ${moment().format()} by user: ${
          authUser.uuid
        }`,
        uuid: newData.assignmentUUID,
      };
      if (newData.revisedHours) {
        patchData.payableDuration = newData.revisedHours;
      }
      if (newData.paymentNote) {
        patchData['payment.paymentNote'] = newData.paymentNote;
      }
      const updatedAssignment = await dispatch('assignments.update', {
        data: patchData,
      });
      this.displayUpdatedData(newData, updatedAssignment);
    } else {
      this.displayUpdatedData(newData, null);
    }
  }

  @action
  async updatePaymentApproval({ existingAssignment, newData }) {
    if (
      existingAssignment.company === this.company.uuid &&
      newData.paymentApprovalStatus !==
        existingAssignment.paymentInfo.approval &&
      newData.paymentApprovalStatus === 'approved'
    ) {
      const updatedAssignment = await dispatch(
        'paymentTransactions.approveForPayment',
        {
          approvalNote: '',
          assignment: existingAssignment,
        },
      );
      if (existingAssignment.status === 'NoShow') {
        await dispatch('assignments.update', {
          data: { status: 'Completed' },
          id: existingAssignment.uuid,
        });
      }
      this.displayUpdatedData(newData, updatedAssignment);
    } else if (
      existingAssignment.company === this.company.uuid &&
      newData.paymentApprovalStatus !==
        existingAssignment.paymentInfo.approval &&
      newData.paymentApprovalStatus === 'rejected'
    ) {
      const updatedAssignment = await dispatch(
        'paymentTransactions.rejectForPayment',
        {
          approvalNote: '',
          assignment: existingAssignment,
        },
      );
      this.displayUpdatedData(newData, updatedAssignment);
    } else {
      this.displayUpdatedData(newData, null);
    }
  }

  @action
  async save() {
    this.setStatus('saving');
    this.setSaving(true);

    const invalidEntries = this.list.filter((entry) => entry.err);

    if (!_.isEmpty(invalidEntries)) {
      runInAction(() => {
        this.saveResults.invalid.push(...invalidEntries);
      });
      this.setStatus('Error Saving Results');
      this.setSaving(false);
      this.setErrorMessage('Payments to be saved contain errors.');
      return;
    }

    // Reset save results
    this.saveResults = {
      ignored: [],
      invalid: [],
      updated: [],
    };

    dispatch('audit.create', {
      data: {
        action: 'Upload Shift Payment Approvals',
        extra: {
          file: _.pick(this.file, ['name', 'type', 'size']),
          serviceName: 'assignments',
        },
      },
    });

    const settings = _.get(this.company, 'settings', {});
    const enableAsyncUploads = _.get(
      settings,
      'payments.enableAsyncUploads',
      false,
    );

    if (enableAsyncUploads) {
      const parser = new Parser({
        fields: Object.keys(this.list[0]),
      });
      const csvData = parser.parse(this.list);

      const file = new Blob([csvData], { type: 'text/csv' });

      const filename = `${this.company.uuid}/payments/${new Date().getTime()}`;
      const bucketService = 'CLOUD_STORAGE_SCHEDULE';

      const query = {
        action: 'sign',
        bucketService,
        contentType: file.type,
        filename,
        operationName: 'putObject',
        useStagingClient: !IS_PRODUCTION,
      };

      const res = await getStore('gcp').get(null, { query });

      await fetch(res.signedUrl, {
        body: file,
        headers: { 'Content-Type': file.type },
        method: 'PUT',
      });

      try {
        const response = await getStore('paymentUploader').create({
          data: {
            bucketService,
            companyId: this.company.uuid,
            companyName: this.company.name,
            filename,
            useStagingClient: !IS_PRODUCTION,
          },
        });
        if (response.success) {
          this.setStatus('saved');
          this.setSaving(false);
          this.setUploadJobSent(true);
        }
      } catch (err) {
        this.log.error(`Failed to save payment records`, err);
        dispatch('ui.snackBar.error', 'Sorry, something went wrong');
        this.setStatus('error');
      }
    } else {
      try {
        await Promise.each(this.list, async (data) => {
          try {
            if (this.uploadStep === 'paymentDetails') {
              await this.updatePaymentDetails({ newData: data });
            }
            if (this.uploadStep === 'paymentApprovals') {
              const existingAssignment = await dispatch(
                'assignments.get',
                data.assignmentUUID,
              );
              await this.updatePaymentApproval({
                existingAssignment,
                newData: data,
              });
            }
          } catch (err) {
            this.log.error(`Failed to save assignments record`, err);
            runInAction(() => {
              this.saveResults.invalid.push(_.extend(data, { err }));
            });
          }
        });

        if (this.uploadStep === 'paymentDetails') {
          this.setUploadStep('paymentApprovals');
        } else if (this.uploadStep === 'paymentApprovals') {
          this.setUploadStep('done');
          this.setStatus('saved');
        }
      } catch (err) {
        this.log.error(`Failed to save assignments records`, err);

        dispatch('ui.snackBar.error', 'Sorry, something went wrong');
        this.setStatus('error');
      }

      this.setSaving(false);
    }
  }
}
