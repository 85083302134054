import type { IOpportunityOpening } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { toJS } from 'mobx';

import Form from './_.extend';

export class OpportunityOpeningForm extends Form {
  constructor(setup, config) {
    super(
      setup,
      _.defaultsDeep(config, { options: { retrieveOnlyDirtyValues: true } }),
    );
  }

  hooks = () => ({
    onSuccess() {
      this.log.warn('No default submit logic in place');
    },
  });
}

const fields: Array<keyof IOpportunityOpening> = [
  'uuid',
  'companyId',
  'companyName',
  'companyPath',
  'positionId',
  'description',
  'status',
  'defaultTags',
  'defaultCerts',
];

// This _must not_ be passed into the form constructor
const struct: Array<keyof IOpportunityOpening> = [
  'uuid',
  'companyId',
  'companyName',
  'companyPath',
  'positionId',
  'description',
  'status',
  'defaultTags',
  'defaultCerts',
];

const labels = {
  companyId: 'Company',
  companyName: 'Company Name',
  companyPath: 'Company Path',
  description: 'Description',
  positionId: 'Position',
  status: 'Status',
};

const rules = {
  companyId: 'required|string',
  description: 'required|string',
  positionId: 'string',
  status: 'required|string',
  stepTitle: 'required|string',
};

const observers = {
  dataType: [
    {
      call: ({ form, change }) => {
        const range = form.$('searchOptions.range');

        if (/percent/i.test(change.newValue)) {
          range.set({ max: 100, min: 0 });
        }
      },
      key: 'value',
    },
  ],
};

export function init(values: Partial<IOpportunityOpening> = {}, cxOptions) {
  let initials = toJS(values);
  if (!_.isEmpty(initials)) {
    initials = _.map(initials, (value) => {
      _.each(struct, (field) => {
        const [, key] = field.split('.');
        if (!key) return;
        if (!_.has(value, key)) {
          _.set(value, key, '');
        }
      });
      return value;
    });
  }

  const vals: Partial<IOpportunityOpening> = values?.uuid
    ? values
    : { ...values };

  return new OpportunityOpeningForm(
    {
      fields,
      labels,
      observers,

      rules,
      // Must merge defaults & values until at least @1.39.0
      values: vals,
    },
    {
      ...(cxOptions ?? {}),
      options: {
        retrieveOnlyDirtyValues: !!vals?.uuid,
      },
    },
  );
}
