import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { dispatch } from 'rfx-core';

import BaseStore from './_baseStore';

export type ISearch = IBaseItem & Record<string, unknown>;

export default class SearchStore extends BaseStore<ISearch> {
  constructor() {
    const baseItem = {
      companies: [],
      createdAt: null,
      label: null,
      locations: [],
      notes: null,
      search: {
        adherence: null,
        exclude: [],
        inactive: [],
        include: [],
        loc: {
          coordinates: null,
          radius: 20,
          type: 'Point',
          zipCode: null,
        },
        optionalTags: [],
        rating: null,
        tags: [],
        years: null,
      },
      updatedAt: null,
      uuid: null,
      workerCount: 0,
    };
    super({ baseItem, serviceName: 'savedsearches' });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  clearSelected({ storeName = 'partners' } = {}) {
    super.clearSelected();
    if (!/^(userOnboardings)$/i.test(storeName)) {
      dispatch(`${storeName}.setCertFilter`, {
        opts: { clear: true, noQuery: true, perserve: ['companies'] },
      });
    }
  }
}
