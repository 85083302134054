import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

// const log = logdown.getChildLogger('NewCandidateModal');
/**
 * Store for UI Modal where user can Add a Template to their Company
 *
 * @export
 * @class ThingModal
 */

export default class CandidateModal {
  @observable
  isOpen = false;

  @observable
  candidate = {};

  @observable
  job = {};

  @observable
  status = '';

  @observable
  company = {};

  @action.bound
  async setup({
    candidate = {},
    job = {},
    status,
    company,
    open = this.isOpen,
  }) {
    this.candidate = candidate;
    this.job = job;
    this.status = status;
    this.company = company;

    if (!_.get(job, 'uuid') && !!candidate.job) {
      const j = await dispatch('jobs.get', candidate.job);
      this.set('job', j);
    }

    this.set('isOpen', open);
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action.bound
  set(key, value) {
    this[key] = value;
  }

  @action
  clear() {
    this.candidate = {};
    this.job = {};
    this.status = '';
    this.company = {};

    this.isOpen = false;
  }
}
