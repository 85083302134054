import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';
import type {
  DropdownItemProps,
  StrictDropdownItemProps,
} from 'semantic-ui-react';

import React, {
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { Query } from '@feathersjs/feathers';

import { getChildLogger } from '#/shared/utils/client.logger';
import BaseStore, { IBaseStore } from '#/shared/stores/_baseStore';
import { useStore, useStores } from '#/shared/hooks/useStores';
import { StoreName, StoreType } from '#/shared/stores';

import BaseSelect, { IBaseSelectProps } from './BaseSelect';

export interface IndependentSelectProps<
  K extends StoreName,
  S extends BaseStore<IBaseItem> = K extends StoreName
    ? StoreType[K]
    : BaseStore<IBaseItem>,
  T = S['selected'],
> extends IBaseSelectProps<K, S, T> {
  auxOptions?: Array<
    StrictDropdownItemProps & {
      isMenuItemProps?: boolean;
      name?: string;
      onSelect?: (val) => void;
      visible?:
        | boolean
        | ((arg: {
            list: Array<DropdownItemProps>;
            searchValue: string;
          }) => boolean);
    }
  >;
  clearAfterSelect?: boolean;
  debounce?: boolean | number;
  /** Does not call `store.find` unless something is typed in the search box */
  disableEmptyQuery?: boolean;
  /**
   * ## modfiyListBeforeSet
   * The Independent select has query for the list and fetch them and display
   * Before display the list, if it is required to change/modify the list item, can be
   * done by the modify list
   */
  modfiyListBeforeSet?: (
    itemList: Array<Record<string, unknown>>,
  ) => Array<Record<string, unknown>>;
  omitUUID?: boolean;
  /** @deprecated Please use the `onSelect` handler instead */
  onClear?: never;
  onSelect?: (val) => void;
  preloadResults?: boolean;
  query?: Query;
  /** The `uuid` of the selected item. TODO: unify with BaseSelect implementation which expects `T` */
  selected?: T['uuid'];
  selectedObjs?: T | Array<T>;
  selectedValues?: Array<T['uuid']>;

  storeName: string;
}

const IndependentSelect = observer(
  <
    K extends StoreName,
    S extends BaseStore<IBaseItem> = K extends StoreName
      ? StoreType[K]
      : BaseStore<IBaseItem>,
    T = S['selected'],
  >(
    props: IndependentSelectProps<K, S, T>,
  ) => {
    const { storeName } = props;

    const {
      ui: { snackBar },
    } = useStores();
    const store: IBaseStore = useStore(storeName) as unknown as IBaseStore;

    const _searchHandlerRef = useRef(theSearch);
    const { current: executeSearch } = _searchHandlerRef;

    useEffect(() => {
      const selectedId =
        (props.selected as T)?.uuid ?? (props.selected as T['uuid']);
      if (!_.isEmpty(selectedId)) {
        onSearchSelect(null, { value: selectedId });
      }

      if (props.preloadResults) {
        onSearchChange();
      }

      if (props.debounce) {
        const interval = _.isNumber(props.debounce) ? props.debounce : 300;
        _searchHandlerRef.current = _.debounce(theSearch, interval);
      }
    }, []);

    const _log = useRef(getChildLogger(`SSM.Select[${storeName}]`));

    const { current: log } = _log;

    const [_list, setList] = useState([]);
    const [_selected, setSelected] = useState({});
    const [_selectedValues, setSelectedValues] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const selectedObjs = useMemo(() => {
      const objs = props.selectedObjs ?? (props.multiple ? [] : {});

      return _.compact(_.isArray(objs) ? objs : [objs]);
    }, [props.selectedObjs, props.multiple]);

    const _auxOptions = useMemo(() => {
      const searchRegex = new RegExp(_.escapeRegExp(searchValue), 'i');
      const x = _.filter(
        props?.auxOptions ?? [],
        ({ name, text, visible } = {}) =>
          (!!(name || text) && searchRegex.test((name || text) ?? '')) ||
          (_.isFunction(visible)
            ? visible({ list: _list, searchValue: searchValue })
            : !!visible),
      );

      return _.compact(x);
    }, [props.auxOptions, searchValue]);

    const list = useMemo(() => {
      return _.compact([...selectedObjs, ..._list, ..._auxOptions]);
    }, [selectedObjs, _list, _auxOptions]);

    const selected = useMemo<T>(() => {
      if (props.multiple) {
        return null;
      }

      log.debug('Returning Updated Selected Value', {
        _selected: _selected,
        propsSelected: props.selected,
      });
      return _.isUndefined(props.selected) ? _selected : props.selected;
    }, [props.selected, props.multiple, _selected]);

    function clearSelected() {
      setSelectedValues([]);

      setSelected({});
    }

    const selectedValues = useMemo(() => {
      const { selectedValues, multiple } = props;
      if (!multiple) {
        return undefined;
      }

      log.debug('Returning Updated Selected Values', {
        _selectedValues,
        selectedValues,
      });

      return selectedValues ?? _selectedValues;
    }, [props.selectedValues, props.multiple]);

    const value = useMemo(() => {
      const { multiple } = props;
      const val = multiple ? selectedValues : selected;

      const retval = multiple
        ? _(val)
            .map((item) => _.get(item, 'uuid', item))
            .compact()
            .value()
        : _.get(val, 'uuid', val);

      log.debug('Returning Value: ', {
        value: retval,
      });
      return retval;
    }, []);

    const [loadingCounter, setLoadingCounter] = useState(0);
    const isLoading = loadingCounter > 0;

    function setIsLoading(val = !isLoading) {
      let tmp;
      if (val) {
        tmp = loadingCounter + 1;
      } else {
        tmp = loadingCounter - 1;
      }

      setLoadingCounter(Math.max(0, tmp));
    }

    async function onSearchChange(
      e?: SyntheticEvent,
      { searchQuery }: { searchQuery?: string } = {},
    ) {
      if (props?.onSearchChange) {
        props.onSearchChange(e as React.SyntheticEvent<HTMLElement, Event>, {
          searchQuery,
        });

        // When this option is set, the IndependentSelect will no longer function as an IndependentSelect
        if (props.overrideSearchChangeHandler) {
          return;
        }
      }
      setSearchValue(searchQuery || '');
      const { query, omitUUID = true } = props;
      const updatedQuery = store.getQueryForSearch(
        searchValue,
        omitUUID ? _.omit(query, 'uuid') : query,
      );

      const q = _.omitBy(updatedQuery, _.isNil);

      executeSearch(q);
    }

    /** Called indirectly by the debounced `executeSearch` funciton */
    async function theSearch(query) {
      setIsLoading(true);

      const { modfiyListBeforeSet } = props;

      log.debug('Running query for "%s"', searchValue, {
        query,
      });
      try {
        const { data: list } = await store.runQuery(query);
        log.silly('Setting List to updated items', { list });
        setList(
          _.isFunction(modfiyListBeforeSet) ? modfiyListBeforeSet(list) : list,
        );
      } finally {
        setIsLoading(false);
      }
    }

    // TODO: Pretty sure this signature is incorrect
    async function onSearchSelect(
      e?: SyntheticEvent,
      { value }: { value?: string | T | Array<T> } = {},
    ) {
      let val: string | T | Array<T> = value;

      // Handle both direct and indirect use of this function
      // ... and based on BaseSelect, this is the only one which will be called
      if (!val && !(e instanceof Event)) {
        val = e as unknown as string | T | Array<T>;
      } else {
        // eslint-disable-next-line no-debugger
        // lets see if we can hit this
      }

      const { onSelect, query } = props;

      try {
        if (_.isEmpty(val)) {
          clearSelected();
        } else if (_.isArray(val)) {
          const ids = _.map(val, (v) => _.get(v, 'uuid', v));
          const { data } = await store.runQuery({
            uuid: { $in: ids },
          });

          setSelectedValues(data);
          val = data;
        } else if ((val as T)?.uuid || val) {
          const selected = await store.get((val as T)?.uuid || val, {
            select: false,
          });
          setSelected(selected);
          val = selected;
        } else {
          log.warn('Unknown val from `onSearchSelect` handler', { val });
        }

        log.debug('Selected new Option(s)', {
          _selected: !!_selected && _selected,
          _selectedValues: !!_selectedValues && _selectedValues,
          list: list,
          selected: !!selected && selected,
          selectedValues: !!selectedValues && selectedValues,
          val,
        });

        onSearchChange(e);

        if (_.isFunction(onSelect)) {
          await onSelect(val);
        }

        if (props.clearAfterSelect) {
          clearSelected();
        }
      } catch (err) {
        log.error(`Failed to Select ${storeName} Item`, err, {
          extra: { query, value },
        });
        snackBar.error('Sorry, something went wrong');
      }
    }

    // TODO: figure out if this is needed, or if it was only added due to minunderstanding of this component
    // componentDidUpdate(prevProps) {
    //   if (this.props?.options && this.props.options !== prevProps?.options) {
    //     this.setList(this.props.options);
    //   }
    // }

    log.debug('Rendering with Option(s)', {
      _selected: !!_selected && _selected,
      _selectedValues: !!_selectedValues && _selectedValues,
      list: list,
      selected: !!selected && selected,
      selectedProp: props.selected,
      selectedValues: !!selectedValues && selectedValues,
    });

    return (
      <BaseSelect<K, S, T>
        loading={isLoading}
        {...props}
        doNativeSelect={false}
        list={list}
        noListOnEmptySearch={props.noListOnEmptySearch}
        onClear={onSearchSelect}
        onFocus={onSearchChange}
        onSearchChange={onSearchChange}
        onSelect={onSearchSelect}
        selected={selected}
        selectedValues={selectedValues}
      />
    );
  },
);

IndependentSelect.displayName = 'IndependentSelect';
export default IndependentSelect;
