import { isUndefined } from 'lodash';

import { toBoolean } from '#/utils/toBoolean';

export const toNullableBoolean = (val) => {
  if (isUndefined(val) || val === '') {
    return undefined;
  }
  if (/^(null|nil)$/i.test(val)) {
    return null;
  }

  return toBoolean(val);
};
