import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';

const log = getChildLogger('forms.training');

export class TrainingForm extends Form {
  hooks = () => ({
    async onSuccess(form) {
      const uuid = form.initials().uuid || undefined;

      let storeAction = 'trainings.create';
      let successMsg = 'New Training Created';

      const data = form.values();
      const restrictionPools = data?.restrictions?.pools;
      const restrictionTags = data?.restrictions?.tags;
      _.set(data, 'restrictions.pools', _.compact(restrictionPools));
      _.set(data, 'restrictions.tags', _.compact(restrictionTags));

      if (uuid) {
        storeAction = 'trainings.update';
        successMsg = 'Training Saved';
        data.uuid = uuid;
      }

      try {
        const training = await dispatch(storeAction, { data });
        log.debug(`Training ${uuid ? 'updated' : 'created'}:`, training);
        dispatch('ui.snackBar.open', successMsg);

        dispatch('ui.trainingEditModal.clear');
      } catch (err) {
        log.error('Saving Training Failed: ', err);
        form.invalidate(err.message);
        dispatch('ui.snackBar.error', err.message);
      }
    },
  });
}

const fields = [
  'uuid',
  'title',
  'company',
  'surveyDefinition.uuid',
  'surveyDefinition.title',
  'surveyDefinition.gradedQuestionsCount',
  'passingThreshold',
  'positionId',
  'restrictions.pools',
  'restrictions.tags',
  'status',
  'defaultTags',
  'defaultFailTags',
];

const rules = {
  company: 'string',

  defaultFailTags: 'array',

  defaultTags: 'array',

  passingThreshold:
    'required_if:surveyDefinition.gradedQuestionsCount|min:1|max:100|integer',
  /* not certain these are needed */
  'restrictions.pools': 'array',
  'restrictions.tags': 'array',
  'surveyDefinition.gradedQuestionsCount': 'integer',
  'surveyDefinition.title': 'string',
  'surveyDefinition.uuid': 'required|string',
  title: 'required|string',
  uuid: 'string',
};

const labels = {
  defaultFailTags: 'Tags to add if partner fails training',
  defaultTags: 'Tags to add upon completion of training',
  passingThreshold: 'Passing Threshold',

  positionId: 'Position (Use Onboarding/Assessment instead if possible)',
  storeList: 'Training Status',
  surveyDefinition: 'Survey',
  'surveyDefinition.gradedQuestionsCount':
    'Selected Survey Graded Question Count',
  'surveyDefinition.title': 'Selected Survey Title',
  'surveyDefinition.uuid': 'Survey',
  title: 'Training Title',
};

const placeholders = {
  defaultFailTags: 'select one or more tags to add upon failure',
  defaultTags: 'select one or more tags to add upon completion',
  passingThreshold: '% correct needed to pass the Training (1 - 100)',
  positionId: 'Select a company position to add on success',
  'surveyDefinition.gradedQuestionsCount':
    "The selected survey's gradedQuestionCount will appear here",
  'surveyDefinition.title':
    "Once selected, the Survey's title will appear here",
  'surveyDefinition.uuid': 'Select a Survey',
};

const defaultValues = {
  defaultFailTags: [],
  defaultTags: [],
  'restrictions.pools': [],
  'restrictions.tags': [],
  surveyId: '',
};

const extra = {
  defaultFailTags: {
    tooltip:
      'These tags will be added to the user in the case where they fail the training (score < passing threshold)',
  },
  defaultTags: {
    tooltip:
      'These tags will be added to the user after they complete this training. In the case of a graded survey, the tags will only be applied if they pass (score >= passing threshold)',
  },
  positionId: {
    tooltip:
      'Upon successful completion of this training, the selected position will be added to the partner. Using a Company Onboarding or Asessment is preferred unless this is a single-step training.',
  },
};

export function init(values = {}, opts = { src: null }): TrainingForm {
  log.debug('Creating Training from base %s', opts.src);

  log.debug('Creating Training Form from Keys: ', {
    keys: _.keys(values),
    values,
  });

  const form = new TrainingForm({
    extra,
    fields,
    labels,
    placeholders,
    rules,
    values: _.extend({}, defaultValues, values),
  });
  log.debug('Created Training Form with Values: ', form.values());
  return form;
}

export type { TrainingForm as TrainingFormInstance };
