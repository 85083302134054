import { action, observable, runInAction } from 'mobx';
import { create, persist } from 'mobx-persist';
// import { dispatch } from 'rfx-core';
import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

/**
 * UI Backing Store for functionality on the Map.jsx page
 *
 * @export
 * @class Map
 */

const log = getChildLogger('ui.map');

const hydrate = create({
  // storage: localStorage,
  jsonify: true,
});

export default class Map {
  constructor() {
    if (global.TYPE === 'CLIENT') {
      hydrate('ui.map', this);
    }
  }

  @observable
  showThingsList = false;

  @observable
  showPartnerList = false;

  @observable
  clusterThings = [];

  @observable
  viewportIsChanging = false;

  @observable
  isPartnersLoaded = undefined;

  @observable
  isMounted = false;

  initialBounds = {};

  @observable
  partnersStoreName = 'partners';

  @action
  setup() {
    this.isPartnersLoaded = false;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    this[key] = value;
    return Promise.resolve(this[key]);
  }

  get(key) {
    return this[key];
  }

  @action
  togglePartnerList(show = !this.showPartnerList) {
    if (show) {
      this.toggleThingsList(false);
    }
    this.showPartnerList = show;

    if (!this.showPartnerList) {
      log.error(
        'The legacy sourcing functionality has been removed. `clearClusteredCandidates` is no longer available',
      );
    }
  }

  @action
  setPartnerStoreName(partnersStoreName: string) {
    this.partnersStoreName = partnersStoreName;
    return this.partnersStoreName;
  }

  @action
  setIsPartnerLoaded(isPartnersLoaded: boolean) {
    this.isPartnersLoaded = isPartnersLoaded;
  }

  @action
  setIsViewportIsChanging(viewportIsChanging: boolean) {
    this.viewportIsChanging = viewportIsChanging;
  }

  @action
  setFirstLoad(firstLoad: boolean) {
    this.firstLoad = firstLoad;
  }

  @action
  async loadClusteredPartners() {
    log.error(
      'The legacy sourcing functionality has been removed. `clearClusteredCandidates` is no longer available',
    );

    dispatch('ui.snackBar.error', 'Sorry, sourcing unavailable', {
      body: 'The legacy sourcing-based user clustering has been removed',
    });
  }

  @action
  async loadClusteredThings({ getChildrenFn }) {
    this.toggleThingsList(true);
    const children = _.isFunction(getChildrenFn)
      ? getChildrenFn()
      : getChildrenFn;
    log.debug('Loading Clustered Things from leaves', { children });

    const uuids = _.map(children, 'key');

    const { data: things, ...rest } = await dispatch('things.runQuery', {
      $limit: 1000,
      uuid: { $in: uuids },
    });

    if (rest.total > 1000) {
      log.warn('More than 1000 things loaded for cluster', {
        extra: { ...rest, uuids },
      });
    }

    runInAction(() => {
      this.clusterThings.replace(things);
    });

    return this.clusterThings;
  }

  @action
  updateClusteredThings = (shiftList) => {
    if (_.isEmpty(this.clusterThings)) {
      return;
    }
    const coordinates = this.clusterThings?.[0]?.loc?.coordinates;
    // check that cluster has same coordinates on items
    if (
      coordinates?.length !== 2 ||
      !this.clusterThings.every((c) =>
        _.isEqual(c?.loc?.coordinates, coordinates),
      )
    ) {
      return;
    }
    // update clusterThings from filtered shiftList, only with shifts from same coords
    const newClusterThings = _.filter(shiftList, (s) =>
      _.isEqual(s?.loc?.coordinates, coordinates),
    );
    if (_.isEmpty(newClusterThings)) {
      this.toggleThingsList(false);
    } else {
      this.clusterThings.replace(newClusterThings);
    }
  };

  @action
  toggleThingsList(state = !this.showThingsList) {
    if (state) {
      this.togglePartnerList(false);
    }

    this.showThingsList = state;

    if (!this.showThingsList) {
      this.clusterThings.clear();
    }
  }

  @action
  toggleMounted(val = !this.isMounted) {
    this.isMounted = val;
  }

  @action
  clear() {
    this.showThingsList = false;
    this.clusterThings.clear();
    this.showPartnerList = false;
    this.viewportIsChanging = false;
    this.isPartnersLoaded = undefined;
    this.isMounted = false;
  }

  @persist
  @observable
  enableDebugInfo = false;

  @action
  toggleDebugInfo(value = !this.enableDebugInfo) {
    this.enableDebugInfo = value;
  }
}
