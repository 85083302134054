import { action, observable, computed } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import moment from 'moment';

import { init } from '#/shared/forms/tag';
import { getChildLogger } from '#/shared/utils/client.logger';
/**
 * Store for UI Modal where user can Add a Template to their Company
 *
 * @export
 * @class ThingModal
 */

export default class TagModal {
  log = getChildLogger('ui.TagModal');

  @observable
  isOpen = false;

  @observable
  form = {};

  @observable
  storeName = '';

  @observable
  userId = '';

  onSave = null;

  @action
  setup({
    value,
    user,
    company,
    companies = _.compact([company?.uuid ?? company]),

    open = this.isOpen,
    onSave = null,
  }) {
    this.onSave = onSave || null;
    this.userId = user?.uuid ?? user;

    const initData = {
      companies: value?.companies || companies,
      rates: [],
      user: this.userId,
      ...(value ?? {}),
    };

    this.form = init(initData);

    this.isOpen = open;
  }

  @computed
  get certType() {
    try {
      return this.form.$('certType').value;
    } catch (err) {
      return '';
    }
  }

  @computed
  get isEditing() {
    try {
      return !!this.form.$('uuid').value;
    } catch (err) {
      return false;
    }
  }

  success(res) {
    return Promise.resolve(_.isFunction(this.onSave) ? this.onSave(res) : null);
  }

  getStoreName(dataType = this.certType) {
    return dataType.replace(/y$/, 'ie').replace(/([^s]$)/, '$1s');
  }

  upload(e) {
    const log = this.log.getChildLogger('upload');
    this.form.$('imageFake').sync(e);
    const id = `${this.certType}-image-${moment().unix()}`;
    const bucketService =
      process.env.NODE_ENV === 'production' ? 'USER_ASSETS' : 'DEV';
    const file = e.target.files[0];

    dispatch('s3.upload', { bucketService, file, id })
      .then((response) => {
        this.form.$('image').set(response.data.url);
      })
      .catch((error) => {
        this.form.$('image').clear();
        this.form.$('imageFake').clear();
        log.error('Upload Fail', error);
      });
  }

  @action
  clear() {
    this.isOpen = false;

    this.userId = '';
    this.onSave = null;

    this.form.clear();
  }
}
