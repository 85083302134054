import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import isUUID from 'uuid-validate';
import Promise from 'bluebird';

import { formatPhoneNumber } from '#/utils/formatting';
import BaseUploader from '#/shared/stores/ui/BaseUploader';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.MessagePartnersModal');

export default class MessagePartnersModal extends BaseUploader {
  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  error = '';

  @observable
  shiftId = '';

  @observable
  currentStep = 'users'; // users -> confirmation -> complete

  @observable
  store = {};

  @observable
  userList = [];

  @observable
  totalUsers = 0;

  @observable
  messagingCampaign = {};

  @observable
  storeDistanceFilters = [
    {
      key: '1',
      text: '1 Mile',
      value: '1',
    },
    {
      key: '5',
      text: '5 Miles',
      value: '5',
    },
    {
      key: '20',
      text: '20 Miles',
      value: '20',
    },
    {
      key: '50',
      text: '50 Miles',
      value: '50',
    },
  ];

  @observable
  selectedCampaign = null;

  @observable
  selectedSurvey = null;

  @observable
  distanceFilter = null;

  @observable
  requireStoreCompletedBefore = false;

  @observable
  requireNumCompletedShifts = 0;

  @observable
  forceSMS = false;

  @observable
  selectedTag = undefined;

  @observable
  selectedPool = undefined;

  @observable
  body = '';

  @observable
  parsedBody = '';

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    _.set(this, key, value);
  }

  @action
  async setup({ open = !this.isOpen, store }) {
    this.set('isLoading', true);
    this.set('isOpen', open);
    if (store) {
      this.store = store;
      this.error = 'Please select Campaign';
      await this.runMassMessageQuery(true);
    }
    this.set('isLoading', false);
  }

  @action
  async runMassMessageQuery(queryOnly) {
    try {
      const company = dispatch('auth.getCompany');
      const $client = {
        sendCampaignMassMessage: { queryOnly },
      };
      if (!queryOnly) {
        $client.sendCampaignMassMessage = {
          campaignId: this.selectedCampaign.uuid,
          messageType: 'shopInvite',
          storeId: this.store.uuid,
          surveyId: this.selectedSurvey.uuid,
        };
      }
      const query = {
        $client,
      };
      this.set('isLoading', true);

      if (this.distanceFilter) {
        try {
          const distanceQuery = await dispatch('workers.findByLocation', {
            company,
            coordinates: _.get(this.store, 'loc.coordinates'),
            opts: {
              clear: true,
              noQuery: true,
            },
            query,
            radius: this.distanceFilter,
          });

          _.extend(query, _.get(distanceQuery, 'query', {}));
        } catch (error) {
          log.error('Error Getting Workers with Distance Filter', error);
        }
      }
      if (this.requireStoreCompletedBefore) {
        query.$client.requireStoreCompletedBefore = this.store.uuid;
      }
      if (this.requireNumCompletedShifts) {
        query.requireNumCompletedShifts = this.requireNumCompletedShifts;
      }

      // run query

      const response = await dispatch('workers.findByCompany', {
        company,
        opts: { clear: true },
        query,
      });
      this.set('userList', _.clone(response.slice()));
      this.set(
        'totalUsers',
        _.get(
          dispatch('workers.retrieve', '$pagination'),

          'total',

          response.length,
        ),
      );
    } catch (error) {
      log.error('Error Running Mass Message Filter', error);
    }
    this.set('isLoading', false);
  }

  // Function to send custom mass messages
  @action
  async sendMassMessage({
    company,
    recipients,
    recipientQueryString,
    sender,
    body,
    attrs,
    previousMessagingCampaignId,
  }) {
    const data = {
      company: _.get(company, 'uuid', company),
      customFieldsWithUserList: this.saveResults.new,
      messageBody: body,
      messageType: attrs.forceSMS ? 'sms' : 'in-app',
      recipientQueryString,
      recipients: _.map(recipients, (recipient) =>
        _.get(recipient, 'uuid', recipient),
      ),
      sender: _.get(sender, 'uuid', sender),
    };

    if (previousMessagingCampaignId) {
      _.extend(data, { previousMessagingCampaignId });
    }

    const result = dispatch('messagingCampaigns.create', {
      data,
    });

    return result;
  }

  @action
  async retryFailedMessage(messagingCampaign) {
    const {
      uuid,
      failedMessages,
      company,
      sender,
      messageBody,
      recipientQueryString,
    } = messagingCampaign;

    const recipients = _.map(failedMessages, (message) => message.userId);
    let updatedQueryString = '';
    if (recipientQueryString) {
      const updatedQuery = JSON.parse(recipientQueryString);
      updatedQuery.uuid = {
        $in: recipients,
      };
      updatedQueryString = JSON.stringify(updatedQuery);
    }

    const attrs = {};
    if (this.forceSMS) {
      attrs.forceSMS = true;
    }
    const retryMessagingCampaign = await this.sendMassMessage({
      attrs,
      body: messageBody,
      company,
      previousMessagingCampaignId: uuid,
      recipientQueryString: updatedQueryString,
      recipients,
      sender,
    });
    this.setUpMessagingCampaignProgress(retryMessagingCampaign.uuid);
    this.set('currentStep', 'complete');
    return messagingCampaign;
  }

  @action
  async loadUsers(userList) {
    const company = dispatch('auth.getCompany');
    let query = {};
    if (userList) {
      query = { uuid: { $in: userList } };
    } else if (this.selectedTag) {
      query = {
        'certs.uuid': this.selectedTag,
      };
    } else if (this.selectedPool) {
      query = {
        'pools.uuid': this.selectedPool?.uuid,
      };
    }
    if (_.isEmpty(query)) {
      dispatch('workers.emptyList');
      return;
    }
    this.set('isLoading', true);
    const response = await dispatch('workers.findByCompany', {
      company,
      opts: { clear: true },
      query,
    });
    this.setUserList(response);
    this.set('isLoading', false);
  }

  @action
  setUserList(users) {
    this.set('userList', _.clone(users.slice()));
    this.set(
      'totalUsers',
      _.get(dispatch('workers.retrieve', '$pagination'), 'total', users.length),
    );
  }

  @action
  updateSelectedTag(value) {
    this.set('selectedTag', value);
    this.loadUsers();
  }

  @action
  updateSelectedPool(value) {
    this.set('selectedPool', value);
    this.loadUsers();
  }

  @action
  async updateSelectedCampaign(value) {
    this.set('selectedCampaign', value);
    this.set('error', '');
    const response = await dispatch('surveys.runQuery', {
      campaign: value.uuid,
      store: this.store.uuid,
    });
    const surveys = _.get(response, 'data', []);
    this.set('selectedSurvey', _.first(surveys));
  }

  @action
  toggleStoreCompletedBefore(value = !this.requireStoreCompletedBefore) {
    this.set('requireStoreCompletedBefore', value);
    this.runMassMessageQuery(true);
  }

  @action updateDistanceFilter(value) {
    this.set('distanceFilter', value);
    this.runMassMessageQuery(true);
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  submit() {
    this.currentStep = 'confirmation';
  }

  @action
  async confirm() {
    this.set('isLoading', true);
    dispatch('ui.loadingModal.open', { message: 'Creating Chat Channel' });
    const sender = dispatch('auth.getUser');
    const company = dispatch('auth.getCompany');
    if (!_.isEmpty(this.store)) {
      try {
        log.debug('Start creation of conversation mass message');
        await this.runMassMessageQuery(false);
        this.set('currentStep', 'complete');
      } catch (error) {
        log.error('Error creating Mass Message conversations for store', error);
        this.set('error', 'There was an issue, please try again');
        dispatch('ui.snackBar.open', error.message);
      }
    } else if (!_.isEmpty(this.body) && !_.isEmpty(this.userList)) {
      log.debug('Start creation of general mass message');
      const attrs = {};
      if (this.forceSMS) {
        attrs.forceSMS = true;
      }
      try {
        const workerQuery = dispatch('workers.retrieve', 'query.query');
        const workerPagination = dispatch('workers.retrieve', 'pagination');
        let recipientQueryString = '';
        if (workerPagination.total > workerPagination.limit) {
          const query = _.omitBy(workerQuery, (value, key) => /^\$/.test(key));
          recipientQueryString = JSON.stringify(query);
        }
        const messagingCampaign = await this.sendMassMessage({
          attrs,
          body: this.body,
          company: company.uuid,
          recipientQueryString,
          recipients: this.userList,
          sender: sender.uuid,
        });
        this.setUpMessagingCampaignProgress(messagingCampaign.uuid);
        this.set('currentStep', 'complete');
      } catch (error) {
        log.error('Error sending mass message', error);
        this.set('error', 'There was an issue, please try again');
        dispatch('ui.snackBar.open', error.message);
      }
    } else {
      log.error('Invalid message partners modal state');
    }

    this.set('isLoading', false);
    dispatch('ui.loadingModal.close');
  }

  @action
  goBack() {
    this.set('error', '');
    this.set('file', null);
    this.set('currentStep', 'users');
  }

  @action
  clear(args = {}) {
    super.clear();
    this.isOpen = !!args.open;
    this.isLoading = false;
    this.store = {};
    this.selectedCampaign = null;
    this.forceSMS = false;
    this.selectedTag = undefined;
    this.selectedPool = undefined;
    this.body = '';
    this.parsedBody = '';
    this.selectedSurvey = null;
    this.distanceFilter = null;
    this.requireStoreCompletedBefore = false;
    this.requireNumCompletedShifts = 0;
    this.error = '';
    this.userList = [];
    this.totalUsers = 0;
    this.messagingCampaign = {};
    this.currentStep = 'users';
  }

  // BaseUploader methods

  @action
  async parse({ rows }) {
    this.set('isLoading', true);
    this.set('saveResults', {
      dupe: [],
      incomplete: [],
      invalid: [],
      new: [],
    });
    const userList = await Promise.map(
      rows,
      async (row) => {
        const id = _.get(row, 'userIdentifier');
        try {
          let userObj = null;
          if (isUUID(id)) {
            userObj = await dispatch('workers.get', id);
          } else {
            const formattedPhoneNumber = formatPhoneNumber(id);
            const result = await dispatch('workers.runQuery', {
              phoneNumber: formattedPhoneNumber,
            });
            userObj = _.first(_.get(result, 'data', []));
          }
          const userId = _.get(userObj, 'uuid');
          if (!userId) {
            throw new Error(`User identifier not found: ${id}`);
          }
          // eslint-disable-next-line no-param-reassign
          row.userIdentifier = userId;
          this.saveResults.new.push(row);
          return userId;
        } catch (error) {
          log.error('Error getting user: %s', error);
          this.saveResults.invalid.push(id);
          return null;
        }
      },
      { concurrency: 10 },
    );

    if (!_.isEmpty(this.saveResults.invalid)) {
      this.set(
        'error',
        `There was an issue with ${this.saveResults.invalid.length} of the provided user identifiers`,
      );
    } else {
      this.set('error', '');
    }
    this.set('selectedTag', null);
    this.loadUsers(_.uniq(_.compact(userList)));
    this.set('isLoading', false);
    return Promise.resolve();
  }

  @action
  async setUpMessagingCampaignProgress(messagingCampaignId) {
    const messagingCampaign = await dispatch(
      'messagingCampaigns.get',
      messagingCampaignId,
    );
    this.set('messagingCampaign', messagingCampaign);
    this.set('currentStep', 'complete');
  }
}
