import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class SurveyResults {
  log = getChildLogger('ui.SurveyResults');

  @observable
  csvData = '';

  @observable
  isLoading = false;

  @observable
  progress = 0;

  @observable
  completedAtDate = null;

  @action
  setCsvData(data) {
    this.csvData = data;
  }

  @action
  setLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setProgress(p) {
    if (_.isNumber(p)) {
      this.progress = p.toFixed();
    }
  }

  @action
  setCompletedAtDate(date) {
    this.completedAtDate = date;
  }

  @action
  getResults(surveyDefinitionId) {
    const log = this.log.getChildLogger('getResults');
    if (_.isEmpty(surveyDefinitionId)) {
      return Promise.reject('Must Provide Survey Id to load results');
    }

    this.setLoading(true);

    return dispatch('surveyDefinitions.getResults', {
      campaigns: _.map(this.campaigns, 'uuid'),
      completedAtDate: this.completedAtDate,
      onProgress: this.setProgress,
      surveyDefinitionId,
    })
      .then((response) => {
        this.setCsvData(response.body.csvData);
        this.setLoading(false);
        dispatch('audit.create', {
          data: {
            action: 'Survey Results Export',
            extra: {
              surveyDefinitionId,
            },
          },
        });
        return response;
      })
      .catch((err) => {
        this.setLoading(false);
        log.error('Error Getting Survey Results: ', err, {
          extra: {
            surveyDefinitionId,
          },
        });
      });
  }

  @observable
  campaigns = [];

  @action
  addCampaign({ campaign }) {
    this.campaigns.push(campaign);
  }

  @action
  removeCampaign({ uuid }) {
    _.remove(this.campaigns, (c) => c.uuid === uuid);
  }

  @action
  clear() {
    this.csvData = '';
    this.isLoading = false;
    this.campaigns = [];
    this.completedAtDate = null;
  }
}
