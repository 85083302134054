import React from 'react';

// Valid currency code to currency symbol conversion
export enum CURRENCY_SYMBOLS {
  aed = 'DH',
  aud = '$',
  brl = 'R$',
  cad = '$',
  dkk = 'Kr',
  eur = '\u20ac',
  gbp = '\u00A3',
  inr = '\u20B9',
  mxn = '$',
  nok = 'kr',
  nzd = '$',
  pln = 'zł',
  sar = '\uFDFC',
  sek = 'kr',
  try = '₺',
  usd = '$',
  zar = 'R',
}

export function getCurrencySymbol(currencyCode = 'usd') {
  return CURRENCY_SYMBOLS[currencyCode] ?? '$';
}

interface MoneyProps {
  children: string;
  currencyCode: keyof typeof CURRENCY_SYMBOLS;
  forceShowSymbol?: boolean;
}

/* Used to add dynamic currency symbol by using currency code
 *
 *@param forceShowSymbol - Used to force show symbol in case children value is empty
 *@param currencyCode - Used to fetch the currency symbol if no value is provided then we use usd as default
 *@param children
 *@returns React.ReactElement - currency and value
 */
// TODO: Some currencies have their symbol at the start or at the end of the value, current behaviour supports currency in front of value
const Money: React.FC<MoneyProps> = ({
  currencyCode = 'usd',
  forceShowSymbol,
  children,
}) => {
  const currencySymbol = CURRENCY_SYMBOLS[currencyCode];
  const forceShowMoneySymbol = forceShowSymbol || children;

  return (
    <>
      {forceShowMoneySymbol && currencySymbol}
      {children}
    </>
  );
};

export default Money;
