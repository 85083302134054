import _ from 'lodash';
import { action, observable } from 'mobx';

export default class WorkerPoolModal {
  @observable
  isOpen = false;

  @observable
  worker = {};

  @observable
  workers = [];

  @observable
  company = {};

  @observable
  label = '';

  @action
  setup({ worker, workers = [], company, label, open = this.isOpen }) {
    this.worker = worker;
    this.workers = workers.map(w => _.get(w, 'uuid', w));
    this.company = company;
    this.label = label;

    this.isOpen = open;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    this.worker = observable({});
    this.workers = observable([]);
    this.company = observable({});
    this.label = '';

    this.isOpen = false;
  }
}
