import React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';

const Indicator = observer(({ sentinel, icon, size = 'fa', circleClass }) => {
  const ti = icon || 'fa fa-check';
  const fi = icon || 'fa fa-times';

  const bgClass = cx(
    circleClass || (sentinel ? 'green' : 'red'),
    'fa-stack-2x fa fa-circle',
  );
  const iconClass = cx(sentinel ? ti : fi, 'fa-stack-1x fa-inverse');

  return (
    <span className={cx('fa-stack', size)}>
      <i className={bgClass} />
      <i className={iconClass} />
    </span>
  );
});

export default Indicator;
