import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { Item } from 'semantic-ui-react';
import Truncate from 'react-truncate';

import { SimpleAvatar } from '#/shared/components/chat/ChannelAvatar';

export type ToastProps = {
  body?: string | string[] | false;
  description?: string;
  extra?: string;
  header?: string;
  image?: string;
  onClick?: string;
};

export default observer(
  ({ image, header, description, body, extra, onClick }: ToastProps) => (
    <Item.Group>
      <Item onClick={onClick}>
        {image && (
          <SimpleAvatar
            className="h2 w2"
            imageURL={image}
            imgStyle={{ border: '1px solid white' }}
          />
        )}

        <Item.Content>
          {header && (
            <Item.Header
              as="a"
              className="ellipsis overflow-hidden"
              style={{ color: '#FFFFFFFF' }}
            >
              {header}
            </Item.Header>
          )}
          {description && (
            <Item.Meta style={{ color: '#FFFFFFDD' }}>
              <Truncate ellipsis="&hellip;" lines={1}>
                {description}
              </Truncate>
            </Item.Meta>
          )}
          {body && (
            <Item.Description style={{ color: '#FFFFFFDD' }}>
              {_.isArray(body) ? (
                <ul>{_.map(body, (b) => _.isString(b) && <li>{b}</li>)}</ul>
              ) : (
                <Truncate ellipsis="&hellip;" lines={2}>
                  {body}
                </Truncate>
              )}
            </Item.Description>
          )}
          {extra && (
            <Item.Extra style={{ color: '#FFFFFF99' }}>
              <Truncate ellipsis="&hellip;" lines={1}>
                {extra}
              </Truncate>
            </Item.Extra>
          )}
        </Item.Content>
      </Item>
    </Item.Group>
  ),
);
