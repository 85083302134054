import BaseTagStore from './_baseTagStore';

export default class SkillsStore extends BaseTagStore {
  constructor() {
    const baseItem = {
      certType: 'skill',
      companies: [],
      createdAt: null,
      isVerified: false,
      level: -1,
      linked: [],
      parent: null,
      title: null,
      updatedAt: null,
      users: [],
      uuid: null,
    };
    super({ baseItem, serviceName: 'skills' });

    return this;
  }
}
