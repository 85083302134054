import { set, observable, action, computed } from 'mobx';
import _ from 'lodash';

import { service } from '#/shared/app';
import { getChildLogger } from '#/shared/utils/client.logger';

const SERVICE = 'certs';

const log = getChildLogger(`store.tags.${SERVICE}`);

export default class TagsStore {
  static BASE_ITEM = {
    companies: [],
    createdAt: null,
    isVerified: false,
    links: [],
    title: null,
    updatedAt: null,
    users: [],
    uuid: null,
  };

  query = {};

  @observable
  searchValue = '';

  @observable
  filter = 'all';

  @observable
  certs = [];

  @observable
  quals = [];

  @observable
  skills = [];

  @observable
  reviewCerts = false;

  cachedItems = new Map([]);

  @computed
  get list() {
    return _.union(this.certs, this.quals, this.skills);
  }

  /*
    "total": "<total number of records>",
    "limit": "<max number of items per page>",
    "skip": "<number of skipped items (offset)>",
    "current": "<current page number>"
    "pages": "<total number of pages>"
  */
  @observable
  $pagination = {};

  init() {
    // run events on client side-only
    if (global.TYPE === 'CLIENT') this.initEvents();
  }

  initEvents() {
    service(SERVICE).on('created', action(this.onCreated)); // onCreated = (data, params) => {}
    service(SERVICE).on('updated', action(this.onUpdated)); // onUpdated = (data) => {}
    service(SERVICE).on('patched', action(this.onPatched)); // onPatched = (id, data) => {}
    // service(SERVICE).on('removed', action(this.onRemoved));   // onRemoved = (id, params) => {}
  }

  async cacheList(list = this.list) {
    if (global.TYPE === 'SERVER') return this.list;

    this.initCacheVars();
    _.map(
      list,
      (l) =>
        !this.cachedItems.has(l.uuid) &&
        this.cachedItems.set(l.uuid, _.extend(l, { cachedAt: new Date() })),
    );

    this.cleanCache();

    return Promise.resolve(this.cachedItems);
  }

  @action
  async updateList(json, query, filter) {
    if (query && query.certType) {
      if (query.certType === 'cert') {
        this.certs = json.data;
      } else if (query.certType === 'qual') {
        this.quals = json.data;
      } else if (query.certType === 'skill') {
        this.skills = json.data;
      }

      return this[`${query.certType}s`];
    }

    const types = _.groupBy(json.data, 'certType');

    if (json.total > json.limit) {
      log.warn(
        'Only Returning the first %d of %d total',
        json.limit,
        json.total,
      );
    }

    this.certs = types.cert || [];
    this.skills = types.skill || [];

    if (filter.company && !_.isEmpty(types.qual)) {
      const companyUUID = _.get(filter.company, 'uuid', filter.company);
      this.quals = _.filter(types.qual, (q) =>
        _.includes(q.companies, companyUUID),
      );
    } else {
      this.quals = types.qual || [];
    }

    log.debug(
      'Search currently showing %d of %d total Certs',
      this.certs.length,
      this.$pagination.total,
    );

    this.$pagination = _.omit(json, 'data');

    return this.list;
  }

  @action
  emptyList() {
    this.certs = [];
    this.quals = [];
    this.skills = [];
  }

  @computed
  get pagination() {
    const { total, limit, skip } = this.$pagination;
    return _.extend(this.$pagination, {
      current: Math.ceil((skip - 1) / limit) + 1,
      pages: Math.ceil(total / limit),
    });
  }

  isItemMatch(item, matchQuery) {
    let query = _.defaults({ $limit: 0, uuid: item.uuid }, this.query.query);
    if (!!matchQuery && _.keys(matchQuery).length > 1) {
      query = matchQuery;
    }
    return service(SERVICE)
      .find({ query })
      .then((res) => !!res.total);
  }

  addItem(item) {
    if (_.isEmpty(item)) {
      log.debug(`Empty Item in addItem for ${SERVICE}`);
      return;
    }

    log.silly(`new ${SERVICE} was created: `, {
      itemId: item?.uuid,
    });

    if (!_.isEmpty(this.query.query)) {
      this.isItemMatch(item)
        .then((isMatch) => {
          if (isMatch) {
            log.debug(`Adding new ${SERVICE}: `, { item });
            if (_.get(this.query, 'query.certType')) {
              if (item.certType === 'cert') {
                this.pushItem(item, 'certs');
              } else if (item.certType === 'qual') {
                this.pushItem(item, 'quals');
              } else if (item.certType === 'skill') {
                this.pushItem(item, 'skills');
              }
            } else {
              const { certType } = item;
              this.pushItem(item, `${certType}s`);
            }
          }
        })
        .catch((error) => {
          log.error(
            `Error Checking If Item Matches for service: ${SERVICE}`,
            error,
          );
        });
    }
  }

  @action
  pushItem(item, store) {
    this[store].unshift(item);
    // if (this[store].length >= this.$pagination.limit) this[store].pop();
    this.$pagination.total += 1;
  }

  @action
  removeItem(item) {
    if (item.certType === 'cert') {
      _.remove(this.certs, { uuid: item.uuid });
    } else if (item.certType === 'qual') {
      _.remove(this.quals, { uuid: item.uuid });
    } else if (item.certType === 'skill') {
      _.remove(this.skills, { uuid: item.uuid });
    }
  }

  create({ data = null }) {
    // we use factory() just for test
    return service(SERVICE)
      .create(data)
      .catch((err) => log.error(err));
  }

  update({ data = {}, id = data.uuid }) {
    if (_.isEmpty(id)) {
      log.error(
        'No ID Specified in request',
        !global.IS_PRODUCTION && { extra: { data } },
      );
      return Promise.reject('No Cert ID Specified in method call');
    }
    return service(SERVICE)
      .patch(id, data)
      .catch((err) => log.error(err));
  }

  runQuery(query) {
    return service(SERVICE).find({ query });
  }

  findByCompany({ company, certType }) {
    const companyUUID = _.get(company, 'uuid', company);
    const query = {
      certType,
      companies: companyUUID,
    };
    return this.find({ clear: true, query });
  }

  // #bestpractice
  find(query = {}, { clear = false, filter = {}, save = true } = {}) {
    const baseQuery = _.isUndefined(query.query) ? { query } : query;
    if (clear) {
      this.query = baseQuery;
    } else {
      _.merge(this.query, baseQuery);
    }

    if (_.isNull(query.certType)) {
      delete this.query.query.certType;
    }

    if (_.isEmpty(query) && !this.query.query.$limit) {
      this.query.query.$limit = 100;
    }

    _(baseQuery.query)
      .keys()
      .each((key) => {
        if (_.isUndefined(baseQuery.query[key])) {
          delete this.query.query[key];
        }
      });

    log.debug('Querying Certs on query: ', this.query.query);

    return service(SERVICE)
      .find(this.query)
      .then((json) => {
        this.cacheList(json.data);
        return save ? this.updateList(json, query, filter) : json.data;
      });
  }

  get(id) {
    const cached = this.lookup(id);
    if (!_.isEmpty(cached)) {
      return Promise.resolve(cached);
    }
    this.initCacheVars();

    return service(SERVICE)
      .get(id)
      .then((res) => {
        if (res && !this.cachedItems.has(id)) {
          this.cachedItems.set(id, _.extend(res, { cachedAt: new Date() }));
        }
        return res;
      });
  }

  calcEdges(id) {
    if (_.isEmpty(id)) {
      return Promise.reject('Must specify an ID');
    }

    if (this.enableEdgeCalcs) {
      return service(SERVICE)
        .patch(
          id,
          {},
          {
            query: {
              createEdges: true,
            },
          },
        )
        .then((res) => {
          log.debug('Calced edges for id: ', id, res);
        })
        .catch((err) => {
          log.error('edge calculation failed', err);
        });
    }

    log
      .getChildLogger('calcEdges')
      .debug('The `updateCerts` logic is disabled on server');

    return null;
  }

  lookup(id, opts = { async: false }) {
    this.initCacheVars();
    const val = this.cachedItems.get(id);

    if (val) {
      Promise.resolve(() => {
        val.cachedAt = Date.now();
      });
    }

    if (!opts.async) {
      return val;
    }

    if (val) {
      return Promise.resolve(val);
    }

    return this.get(id);
  }

  /* EVENTS */

  onCreated = (item) => this.addItem(item);

  @action
  onUpdated = (data) => {
    if (_.isEmpty(data)) {
      log.debug(`Empty Item in onUpdated for ${SERVICE}`);
      return;
    }
    let list;
    if (data.certType === 'cert') {
      list = this.certs;
    } else if (data.certType === 'qual') {
      list = this.quals;
    } else if (data.certType === 'skill') {
      list = this.skills;
    }
    log.silly('Received Post Update: %O', data);

    this.initCacheVars();
    if (this.cachedItems.has(data.uuid)) {
      this.cachedItems.set(data.uuid, _.extend(data, { cachedAt: new Date() }));
    }

    const existing = _.find(list, { uuid: data.uuid });
    if (existing) {
      set(existing, data);
    }
  };

  onPatched = this.onUpdated;

  // onRemoved = (id, params) => {};

  /* ACTIONS */

  @action
  page(page = 1) {
    const skipPage = this.$pagination.limit * (page - 1);
    const { pages } = this.$pagination;
    if (skipPage < 0 || page > pages) return null;
    return this.find({ query: { $skip: skipPage } });
  }

  @action
  toggleReviewModeFilter(review) {
    log.debug('Setting Certs Review Mode: ', review);
    if (this.reviewCerts === review) {
      return Promise.resolve(this.list);
    }

    this.reviewCerts = review;

    let query = { links: undefined };

    if (this.reviewCerts) {
      query = { links: { $eq: [] } };
    }

    return this.find({ query });
  }

  @action
  search(title = null, { query = {} } = {}) {
    this.searchValue = title || '';

    return this.find({
      query: _.extend(query, {
        $skip: 0,

        title: {
          $options: 'i',
          $regex: `.*${this.searchValue}.*`,
        },
      }),
    });
  }

  @action
  clearSearch() {
    this.searchValue = '';
  }

  @action
  filterBy(filter) {
    this.filter = filter;
    let completed;

    switch (this.filter) {
      case 'all':
        this.query.query.completed = undefined;
        break;
      case 'todo':
        completed = false;
        break;
      case 'done':
        completed = true;
        break;
      default:
        completed = 'all';
    }

    if (filter === 'all') return this.find();
    return this.find({ query: { completed } });
  }

  // #region Internal
  async cleanCache(targetSize = 350) {
    this.initCacheVars();
    if (this.cachedItems.size < targetSize * 0.9) {
      return Promise.resolve();
    }

    return Promise.resolve(() => {
      _(this.cachedItems.values)
        .sortBy(['cachedAt'])
        .forEach(
          action((c) => {
            this.cachedItems.remove(c.uuid);

            return this.cachedItems.size > targetSize * 0.6;
          }),
        );
    });
  }

  /* Init the Cached Items Map if it has not been intialized yet */
  @action
  initCacheVars() {
    if (!_.isFunction(this.cachedItems.set)) {
      this.cachedItems = observable.map({ cachedAt: Date.now() });
    }
  }
}
