/* eslint-disable no-underscore-dangle */
import React from 'react';
import { configure, getApplicationKeyMap, GlobalHotKeys } from 'react-hotkeys';
import cx from 'classnames';

import styles from '#/shared/styles/quickInputWidget.css';

configure({
  ignoreTags: [],
});

const prefix = 'keybinding';

export default class QuickInputWidget extends React.PureComponent {
  state = {
    active: false,
    filterValue: '',
    selectedCommand: -1,
  };

  updateFilterValue = (e) => {
    this.setState({
      filterValue: e.target.value,
      selectedCommand: -1,
    });
  };

  selectCommand(commandIndex, cb) {
    this.setState({ selectedCommand: commandIndex }, cb);
  }

  getAvailableHotKeys() {
    const { filterValue } = this.state;
    const keyMaps = getApplicationKeyMap();
    const hotKeys = Object.values(keyMaps);
    return hotKeys
      .filter((hotKey) => hotKey.name && hotKey.description)
      .filter((hotKey) => {
        const search = new RegExp(['', ...filterValue, ''].join('.*'), 'i');
        return search.test(`${hotKey.name}${hotKey.description}`);
      })
      .map((hotKey) => {
        const keybinding = hotKey.sequences.reduce((acc, cur) => {
          const currentKeybinding = cur.sequence.replace(/\s/g, '');
          return [...acc, currentKeybinding];
        }, []);
        return { ...hotKey, keybinding };
      });
  }

  getKeyMap = () => {
    const { active } = this.state;
    const isWindows = /windows/i.test(global.navigator.userAgent);
    const keyMap = {
      __OPEN__: {
        sequence: isWindows ? 'ctrl+alt+p' : 'meta+shift+p',
      },
    };
    if (active) {
      keyMap.__CLOSE__ = {
        sequence: 'esc',
      };
      keyMap.__PREV_COMMAND__ = {
        sequence: 'up',
      };
      keyMap.__NEXT_COMMAND__ = {
        sequence: 'down',
      };
      keyMap.__EXECUTE_COMMAND__ = {
        sequence: 'enter',
      };
    }
    return keyMap;
  };

  scrollSelectCommandTo(e, selected) {
    const { name, keybinding } = selected;
    const element = e.target.ownerDocument.getElementById(
      `${prefix}-${name}-${keybinding}`.replace(/(?!(\w+))./g, '-'),
    );
    if (element) {
      element.scrollIntoView({ block: 'nearest' });
    }
  }

  handlers = {
    __CLOSE__: () => {
      const { active } = this.state;
      if (active) {
        this.setState({ active: false });
      }
    },
    __EXECUTE_COMMAND__: () => {
      const { selectedCommand } = this.state;
      const availableHotKeys = this.getAvailableHotKeys();
      const { handler } = availableHotKeys[selectedCommand];
      this.setState({ active: false, selectedCommand: -1 }, () => {
        if (typeof handler === 'function') {
          handler();
        }
      });
    },
    __NEXT_COMMAND__: (e) => {
      const { selectedCommand } = this.state;
      const availableHotKeys = this.getAvailableHotKeys();
      if (availableHotKeys.length) {
        let index = selectedCommand + 1;
        index = availableHotKeys[index] ? index : 0;
        const selected = availableHotKeys[index];
        this.scrollSelectCommandTo(e, selected);
        this.selectCommand(index);
      }
    },
    __OPEN__: () => {
      const { active } = this.state;
      if (!active) {
        this.setState({ active: true });
      }
    },
    __PREV_COMMAND__: (e) => {
      const { selectedCommand } = this.state;
      const availableHotKeys = this.getAvailableHotKeys();
      if (availableHotKeys.length) {
        let index = selectedCommand - 1;
        index = availableHotKeys[index] ? index : availableHotKeys.length - 1;
        const selected = availableHotKeys[index];
        this.scrollSelectCommandTo(e, selected);
        this.selectCommand(index);
      }
    },
  };

  renderCommands() {
    const { selectedCommand } = this.state;
    const availableHotKeys = this.getAvailableHotKeys();
    if (availableHotKeys.length) {
      return availableHotKeys.map((hotKey, index) => {
        const { name, description, keybinding } = hotKey;
        const id = `${prefix}-${name}-${keybinding}`.replace(
          /(?!(\w+))./g,
          '-',
        );
        return (
          <div
            className={cx(
              styles.item,
              index === selectedCommand && styles.selected,
            )}
            draggable="false"
            id={id}
            key={id}
            onClick={() =>
              this.selectCommand(index, () =>
                this.handlers.__EXECUTE_COMMAND__(),
              )
            }
            role="presentation"
          >
            <label className={cx('flex flex-auto items-center', styles.label)}>
              <span className={cx('flex-auto', styles.labelName)}>
                {description}
              </span>
              {keybinding.map((keys, i) => (
                <div className={styles.entryKeybinding} key={keys}>
                  <div className={styles.keybinding} title={keys}>
                    {keys.split('+').map((key, $i, arr) => [
                      <span className={styles.key} key={key}>
                        {`${key[0].toUpperCase()}${key.slice(1)}`}
                      </span>,
                      $i !== arr.length - 1 && (
                        <span className={styles.keySeparator} key="separator">
                          +
                        </span>
                      ),
                    ])}
                    {i !== keybinding.length - 1 && (
                      <span
                        className={cx(
                          styles.keySeparator,
                          styles.groupSeparator,
                        )}
                      >
                        or
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </label>
          </div>
        );
      });
    }
    return (
      <div className={styles.item} draggable="false" role="presentation">
        <label className={cx('flex flex-auto items-center', styles.label)}>
          <span className={cx('flex-auto', styles.labelName)}>
            No commands matching
          </span>
        </label>
      </div>
    );
  }

  render() {
    const { active } = this.state;
    return (
      <div
        className={styles.mask}
        onClick={(e) => {
          if (/mask/.test(e.target.className)) {
            this.setState({ active: false });
          }
        }}
        role="presentation"
        style={{ display: active ? 'block' : 'none' }}
      >
        <div className={styles.widget} tabIndex={-1}>
          <GlobalHotKeys
            allowChanges={true}
            handlers={this.handlers}
            keyMap={this.getKeyMap()}
          />
          {this.purple && (
            <div className={styles.headerWrapper}>
              <div
                className={cx('flex-column flex-grow-1 flex', styles.header)}
              >
                <input
                  autoCapitalize="off"
                  autoCorrect="off"
                  className={styles.input}
                  onChange={this.updateFilterValue}
                  placeholder="Type a command to search"
                  spellCheck="false"
                  type="text"
                  wrap="off"
                />
              </div>
            </div>
          )}
          <div className={cx('overflow-y-scroll', styles.list)} role="listbox">
            {this.renderCommands()}
          </div>
        </div>
      </div>
    );
  }
}
