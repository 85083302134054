import type { IUser } from '@shiftsmartinc/shiftsmart-types';

import { action, runInAction, observable } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import isUUID from 'uuid-validate';
import { v4 as generateUUID } from 'uuid';

import ShiftUploader from '#/shared/stores/ui/ShiftUploader';
import { formatPhoneNumber, parseAddress } from '#/utils/formatting';

export default class SchedulingTemplateUploader extends ShiftUploader {
  constructor() {
    super({ title: 'ui.SchedulingTemplateUploader' });
    this.additionalKnownFields = [
      {
        exampleVal:
          'Partner Email or Phone Number. It can be left blank and will be recorded as a slot. ',
        key: 'userId',
      },
    ];
    return this;
  }

  @observable saveConfirmMessage = '';

  @observable saveButtonText = 'Apply';

  @observable noteMessage = '';

  @observable
  includeDispatchPrefs = false;

  @observable
  includeHowTo = false;

  @action
  async save() {
    this.setStatus('saving');
    this.setSaving(true);
    this.set('saveResults', {
      dupe: [],
      incomplete: [],
      invalid: [],
      new: [],
    });

    // Group userIds together
    const company = _.first(this.list)?.company;
    const userIdentifierList = _(this.list).groupBy('userId').keys().value();
    const userIdentifierDict = {};

    // load userIds to grab uuid
    await Promise.map(userIdentifierList, async (identifier) => {
      // if identifier is email
      if (/@/i.test(identifier)) {
        const result = await dispatch('workers.runQuery', {
          $select: ['uuid'],
          companyStatus: {
            $elemMatch: {
              company,
              status: { $in: ['employee', 'active'] },
            },
          },
          email: identifier,
        });
        const user = _.first(_.get(result, 'data', [])) as IUser;
        userIdentifierDict[identifier] = user?.uuid;
      } else if (!isUUID(identifier) && /\d/i.test(identifier)) {
        // if identifier is phoneNumber
        const formattedPhoneNumber = formatPhoneNumber(identifier);
        const result = await dispatch('workers.runQuery', {
          $select: ['uuid'],
          companyStatus: {
            $elemMatch: {
              company,
              status: { $in: ['employee', 'active'] },
            },
          },
          phoneNumber: formattedPhoneNumber,
        });
        const user = _.first(_.get(result, 'data', [])) as IUser;
        userIdentifierDict[identifier] = user?.uuid;
      } else {
        userIdentifierDict[identifier] = identifier;
      }
    });

    // Apply userIdentifier in shift list
    await Promise.map(
      this.list,
      async (shift) => {
        const identifier = shift?.userId;
        try {
          const uuid = userIdentifierDict[identifier];

          // if user identifier is not valid email or phoneNumber and not uuid, throw error
          // unless that field was intentionally left blank.
          if (!isUUID(uuid) && identifier !== '') {
            throw new Error(
              `Invalid userId field - user with ${identifier} could not be found or is not part of the company`,
            );
          }
          runInAction(() => {
            this.saveResults.new.push({
              ...shift,
              address: parseAddress(shift.address),
              formattedAddress: shift.address,
              originalRef: generateUUID(),
              userId: uuid,
            });
          });
          return shift;
        } catch (error) {
          this.log.error('Error getting user: %s', identifier, error);
          runInAction(() => {
            this.saveResults.invalid.push({ ...shift, err: error });
          });
          return null;
        }
      },
      {
        concurrency: 5,
      },
    );

    // if errored results, display error
    if (this.saveResults.invalid.length) {
      this.setStatus('saved');
      this.setSaving(false);
      return;
    }

    // roll shifts together by title, start, and end time
    const groupedShifts = _.groupBy(
      this.saveResults.new,
      (shift) => `${shift.title.trim()}-${shift.start}-${shift.end}`,
    );
    const shiftList = _.map(groupedShifts, (group) => {
      const assignedUserIdsWithEmptyUsersRemoved = _.compact(
        _.map(group, (shift) => shift.userId),
      );
      const numberOfUsers = assignedUserIdsWithEmptyUsersRemoved.length || 1;
      // If there are more users assigned to the shift than the number of
      // slots on the shift allows, lets automatically increment the number of slots.
      if (numberOfUsers > group[0].slots) {
        runInAction(() => {
          group[0].slots = numberOfUsers;
        });
      }
      return _.extend(group[0], {
        assignedUsers: assignedUserIdsWithEmptyUsersRemoved,
      });
    });
    dispatch(
      'ui.createSchedulingTemplateModal.set',
      'previewShifts',
      shiftList,
    );
    dispatch('ui.createSchedulingTemplateModal.set', 'isUploading', false);
    dispatch('ui.createSchedulingTemplateModal.set', 'isUpload', true);

    this.setStatus('saved');
    this.setSaving(false);
  }
}
