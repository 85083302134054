import type { StoreFromName } from './stores/storeTypeUtils';
import type { UIStorePath } from './stores/ui';

import _ from 'lodash';

import { StoreType, stores, StoreName } from './stores';

/**
 * ## getStores
 * Allows you to access the domain stores from anywhere in the code, similar
 * to how we use the `useStores` hook, but this doesn't have to be run from
 * within a component.
 *
 * **WARNING** This should only be used once the stores are fully initialized
 * after app startup (eg: access it from event-handlers, not init tasks). If
 * accessed too early, you may not yet have access to the stores you need.
 */

export function getStores(): StoreType {
  /** @ts-expect-error ts2322 */
  return stores?.$stores ?? {};
}

export function getStore<T extends StoreName | 'ui' | UIStorePath>(
  storePath: T,
): StoreFromName<T> {
  return _.get(stores?.$stores ?? {}, storePath);
}
