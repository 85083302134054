import type {
  ICompanyOnboarding,
  ICompanyOnboardingStep,
  ICompanyOnboardingSubstep,
  UUID,
  ICompany,
  IVideoInterviewQuestion,
} from '@shiftsmartinc/shiftsmart-types';
import type { TabType } from '#/shared/components/tabs/TabViewer';

import {
  action,
  computed,
  IObservableArray,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import uuid from 'uuid';
import Promise from 'bluebird';

import {
  getExtra,
  init as initStepForm,
  OptionType,
  SelectedStepModalOptionType,
} from '#/shared/forms/companyOnboardingsStep';
import {
  CompanyOnboardingDetailsForm,
  init as initCompanyOnboardingDetailsForm,
} from '#/shared/forms/companyOnboardingDetails';
import { ModalRefType } from '#/types/ModalRefType';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.companyOnboardings');

/** @deprecated Do not use TS Enums */
export enum OnboardingTabs {
  'ONBOARDING_DETAILS',
  'STEPS',
  'AFTER_COMPLETION',
}

type ActionAttributes = {
  actionButton: string;
  actionButtonDestination: string;
  actionButtonDestinationType: string;
};
const createDefaultSubstep = (
  showAttributesOption: boolean,
  actionAttributes: ActionAttributes = {
    actionButton: '',
    actionButtonDestination: '',
    actionButtonDestinationType: '',
  },
): ICompanyOnboardingSubstep => {
  const substep: ICompanyOnboardingSubstep = {
    actionButton: actionAttributes.actionButton || '',
    actionButtonDestination: actionAttributes.actionButtonDestination || '',
    actionButtonDestinationType:
      actionAttributes.actionButtonDestinationType || '',
    backgroundCheckType: 'basic_criminal',
    completionRule: 'tagBased',
    copy: '',
    stepStatusTags: {
      endTag: '',
      endTagType: 'quals',
      startTag: '',
      startTagType: 'quals',
    },
    title: 'Default Substep Title',
    trainingId: '',
    uuid: uuid.v4(),
    videoInterviewQuestionIds: [],
  };
  // don't add attribute questions in the default value if the feature flag is not enabled
  if (showAttributesOption) {
    substep.attributeQuestions = {
      description: '',
      isRequired: true,
      selected: [],
    };
  }
  return substep;
};
const defaultFormErrors = {
  stepError: '',
  title: '',
};

const defaultCompletionModalContentObject = {
  actionButton: '',
  additionalInfo: '',
  description: '',
  descriptionTitle: '',
  subTitle: '',
  title: '',
};
export interface OnboardingEditModalProps {
  company: Partial<ICompany>;
  isUpdating?: boolean;
  modalRef?: ModalRefType;
}

export default class CompanyOnboarding {
  storeName = 'Company Onboarding';

  @observable onboardingDetailsForm: CompanyOnboardingDetailsForm =
    initCompanyOnboardingDetailsForm();

  @observable companyOnboarding: ICompanyOnboarding | null;

  @observable onboardingAutoAssignShiftOnComplete = false;

  @observable onboardingEnabledTagsType = 'quals';

  @observable onboardingRejectionTagsType = 'quals';

  @observable lastStep: UUID = null;

  @observable lastSubstep: UUID = null;

  @observable backgroundCheckStep: UUID = null;

  @observable backgroundCheckSubstep: UUID = null;

  @observable
  completionModalContent: ICompanyOnboarding['completionModalContent'] = {};

  @observable formErrors = defaultFormErrors;

  @observable steps: IObservableArray<ICompanyOnboardingStep> =
    observable.array([]);

  @observable stepForms = observable.array([]);

  @observable errorMsg = '';

  @observable isPartnerAttributeCollectionEnabled = false;

  @observable selectedSubstepId = null;

  @observable selectedStepId = null;

  @observable selectedTab = OnboardingTabs.ONBOARDING_DETAILS;

  @observable tabs: TabType[] = [
    { key: OnboardingTabs.ONBOARDING_DETAILS, name: 'Onboarding Details' },
    { key: OnboardingTabs.STEPS, name: 'Steps' },
    // { name: 'After Completion', key: OnboardingTabs.AFTER_COMPLETION },
  ];

  @observable openAddStepModal = false;

  @observable openAddSubStepModal = false;

  @observable stepType: OptionType;

  videoInterviewQuestions: IVideoInterviewQuestion[] = [];

  @action
  getExtra() {
    return getExtra({
      index: null,
      isPartnerAttributeCollectionEnabled:
        this.isPartnerAttributeCollectionEnabled,
    });
  }

  @action
  updateCreateModal({ onboarding, company, qualsList }) {
    if (!_.isNull(onboarding)) {
      _.forEach(onboarding?.steps, (step) => {
        _.forEach(step.substeps, (substep) => {
          _.set(
            substep,
            'stepStatusTags.startTagType',
            this.checkTagType(substep.stepStatusTags?.startTag, qualsList),
          );
          _.set(
            substep,
            'stepStatusTags.endTagType',
            this.checkTagType(substep.stepStatusTags?.endTag, qualsList),
          );
          _.set(substep, 'backgroundCheckType', substep?.backgroundCheckType);
          _.set(
            substep,
            'completionRule',
            substep?.completionRule || 'tagBased',
          );
          _.set(
            substep,
            'attributeQuestions.description',
            substep?.attributeQuestions?.description,
          );
          _.set(
            substep,
            'attributeQuestions.selected',
            substep?.attributeQuestions?.selected || [],
          );
          _.set(
            substep,
            'attributeQuestions.isRequired',
            substep?.attributeQuestions?.isRequired || false,
          );
          _.set(
            substep,
            'videoInterviewQuestionIds',
            substep?.videoInterviewQuestionIds || [],
          );
        });
      });
      this.steps = _.clone(onboarding?.steps ?? []);
      this.updateCompanyOnboarding(onboarding, qualsList);
    } else {
      this.onboardingDetailsForm = initCompanyOnboardingDetailsForm({
        onboardingEnabledTags: [],
        onboardingHideNameAndLogo: false,
        onboardingRejectionTags: [],
        onboardingStatus: 'active',
      });
    }
    this.stepForms.clear();
    this.setIsPartnerAttributeCollectionEnabledFlag(company);

    this.initStepForms();
  }

  @computed
  get hasBGCheckStep() {
    const values = this.stepForms.map((step) => step.values());
    const flattenedSteps = _(values).map('substeps').flatten().value();
    return _.find(flattenedSteps, {
      actionButtonDestination: 'backgroundCheck',
    });
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key: keyof CompanyOnboarding, value: any) {
    _.set(this, key, value);
  }

  @action setCompletionModalContent(field, value) {
    if (_.isEmpty(this.completionModalContent)) {
      this.completionModalContent = defaultCompletionModalContentObject;
    }
    this.completionModalContent[field] = value;
  }

  @action
  setSelectedTab(value: TabType['key']) {
    this.selectedTab = value;
    if (this.selectedTab === OnboardingTabs.STEPS) {
      this.selectedStepId = this.steps[0]?.uuid;
      this.selectedSubstepId = null;
    }
  }

  @action
  initStepForms() {
    const forms = _.map(toJS(this.steps), (step, index) => {
      const form = initStepForm(step, {
        index,
        isPartnerAttributeCollectionEnabled:
          this.isPartnerAttributeCollectionEnabled,
        videoInterviewQuestions: this.videoInterviewQuestions,
      });
      return form;
    });

    this.stepForms.push(...forms);
  }

  @action
  async validatingStep() {
    this.formErrors = defaultFormErrors;
    if (
      this.steps.length === 0 &&
      _.isEmpty(this.onboardingDetailsForm.$('onboardingTitle').value)
    ) {
      this.set('selectedTab', OnboardingTabs.ONBOARDING_DETAILS);
      this.formErrors.title = 'Please add a title';
      dispatch('ui.snackBar.error', 'Please add a title before continuing');
      return false;
    }

    if (this.steps.length !== 0 && !(await this.validateForm())) {
      dispatch('ui.snackBar.open', 'Please fill in the required fields');
      return false;
    }
    return true;
  }

  @action.bound
  async addStep({
    company,
    option,
  }: {
    company: ICompany;
    option: SelectedStepModalOptionType;
  }) {
    if (!this.validatingStep()) {
      return;
    }

    this.setIsPartnerAttributeCollectionEnabledFlag(company);
    let actionAttributes: ActionAttributes = {
      actionButton: option.text || '',
      actionButtonDestination: option.isPageInApp ? option.value : '',
      actionButtonDestinationType: option.isPageInApp
        ? 'pageInApp'
        : option.value || '',
    };
    if (option.skipActionButton) {
      actionAttributes = {
        actionButton: '',
        actionButtonDestination: '',
        actionButtonDestinationType: '',
      };
    }
    const substep = createDefaultSubstep(
      this.isPartnerAttributeCollectionEnabled,
      actionAttributes,
    );

    const step: ICompanyOnboardingStep = {
      stepTitle: `Default Step Title ${this.steps.length + 1}`,
      substeps: [substep],
      uuid: uuid.v4(),
    };

    this.steps.push(step);
    runInAction(() => {
      this.stepForms.push(
        initStepForm(step, {
          index: _.size(this.stepForms),
          isPartnerAttributeCollectionEnabled:
            this.isPartnerAttributeCollectionEnabled,
        }),
      );
      this.selectedStepId = step.uuid;
      this.selectedSubstepId = substep.uuid;
      this.lastStep = step.uuid;
      this.lastSubstep = step.substeps[0].uuid;
    });
  }

  @action
  async removeStep(stepId: string) {
    this.steps.replace(this.steps.filter((step) => step.uuid !== stepId));
    this.stepForms.replace(
      this.stepForms.filter((step) => step.$('uuid').value !== stepId),
    );
    if (this.steps.length === 0) {
      this.selectedStepId = null;
      this.selectedSubstepId = null;
      this.lastStep = null;
      this.lastSubstep = null;
      this.clearBackgroundCheckSubstep();
      return;
    }
    if (stepId === this.backgroundCheckStep) {
      this.clearBackgroundCheckSubstep();
    }
    if (this.lastStep === stepId) {
      const lastStep = this.steps[this.steps.length - 1];
      if (lastStep) {
        this.lastStep = lastStep.uuid;
        this.lastSubstep =
          lastStep.substeps[lastStep.substeps.length - 1].uuid || null;
      }
    }
    this.selectedStepId = null;
    this.selectedSubstepId = null;
  }

  @action
  async setIsPartnerAttributeCollectionEnabledFlag(company: ICompany) {
    this.isPartnerAttributeCollectionEnabled = _.get(
      company,
      'settings.featureFlags.enablePartnerAttributeCollection',
      false,
    );
  }

  @action
  async addSubstep(stepId: UUID, option: OptionType) {
    if (this.steps.length !== 0 && !(await this.validateForm())) {
      return;
    }
    let actionAttributes: ActionAttributes = {
      actionButton: option.text || '',
      actionButtonDestination: option.value || '',
      actionButtonDestinationType: option.isPageInApp
        ? 'pageInApp'
        : option.value || '',
    };
    if (option.skipActionButton) {
      actionAttributes = {
        actionButton: '',
        actionButtonDestination: '',
        actionButtonDestinationType: '',
      };
    }
    const substep = createDefaultSubstep(
      this.isPartnerAttributeCollectionEnabled,
      actionAttributes,
    );
    const stepIndex = this.steps.findIndex((s) => s.uuid === stepId);
    if (stepIndex < 0) {
      log.error('Error add substep to non-existent step', {
        extra: { stepId, steps: toJS(this.steps) },
      });
      dispatch('ui.snackBar.error', 'Something went wrong');
    }

    // push the new substep
    const newStepsValue = toJS(this.steps);
    newStepsValue[stepIndex].substeps.push(substep);
    runInAction(() => {
      this.steps.replace(newStepsValue);
    });

    // Add the new substep to the substeps array
    this.stepForms[stepIndex].$('substeps').add().set('value', substep);
    // NOTE: For some reason, simply calling `substeps.add({ value: substep })` does _NOT WORK_
    // and neither does `substeps.add(substep)` :rage:
    runInAction(() => {
      this.lastSubstep = substep.uuid;
      this.selectedSubstepId = substep.uuid;
      this.selectedStepId = stepId;
    });
  }

  @action
  async removeSubstep({
    stepId,
    substepId,
  }: {
    stepId: UUID;
    substepId: UUID;
  }) {
    const stepIndex = this.steps.findIndex((s) => s.uuid === stepId);
    if (stepIndex < 0 || !substepId) {
      log.error('Error remove substep from non-existent step', {
        extra: { stepId, steps: toJS(this.steps), substepId },
      });
      dispatch('ui.snackBar.error', 'Something went wrong');
    }

    // remove the substep
    const newStepsValue = toJS(this.steps);
    const newSubstepsValue = newStepsValue[stepIndex].substeps.filter(
      (s) => s.uuid !== substepId,
    );
    newStepsValue[stepIndex].substeps = newSubstepsValue;
    this.steps.replace(newStepsValue);

    // update the form
    const stepForm = this.stepForms[stepIndex];
    let substepsFieldValue = stepForm.$('substeps').values();
    substepsFieldValue = substepsFieldValue.filter((s) => s.uuid !== substepId);
    stepForm.update({ substeps: substepsFieldValue });

    if (substepId === this.lastSubstep) {
      this.lastSubstep =
        this.steps[stepIndex].substeps[
          this.steps[stepIndex].substeps.length - 1
        ]?.uuid;
    }
    if (substepId === this.backgroundCheckSubstep) {
      this.clearBackgroundCheckSubstep();
    }
    this.selectedSubstepId = null;
  }

  @action
  setBackgroundCheckSubstep = (substepUUID: string) => {
    if (substepUUID) {
      this.backgroundCheckSubstep = substepUUID;
      const stepId = this.steps.find(
        (step) =>
          step.substeps.filter((substep) => substep.uuid === substepUUID)
            .length > 0,
      ).uuid;
      if (stepId) {
        this.backgroundCheckStep = stepId;
      }
    }
  };

  @action
  clearBackgroundCheckSubstep = () => {
    this.backgroundCheckStep = null;
    this.backgroundCheckSubstep = null;
  };

  @action
  getCommonTags = (data) =>
    data.enabledTags.some((tag) => data.rejectionTags.indexOf(tag) >= 0);

  @action
  validActionButtonType = (steps: Array<ICompanyOnboardingStep>) => {
    let requiredButtonTypeMissing = false;
    steps.forEach((step) => {
      step.substeps.forEach((subStep) => {
        if (
          !_.isEmpty(subStep.actionButton) &&
          _.isEmpty(subStep.actionButtonDestinationType)
        ) {
          requiredButtonTypeMissing = true;
        }
      });
    });
    return requiredButtonTypeMissing;
  };

  @action
  async saveNewOnboarding({
    companyId,
    simplifiedErrorMessage,
    modalRef,
  }: {
    companyId: ICompany['uuid'];
    modalRef: OnboardingEditModalProps['modalRef'];
    simplifiedErrorMessage: boolean;
  }) {
    if (!(await this.validateForm())) {
      dispatch('ui.snackBar.open', 'Please fill in the required fields');

      return;
    }
    const {
      onboardingTitle,
      onboardingRole,
      onboardingEnabledTags,
      onboardingRejectionTags,
      onboardingStatus,
      onboardingDescription,
      onboardingHideNameAndLogo,
    } = this.onboardingDetailsForm.values();

    const stepValues = this.extractStepValues();

    const data = {
      autoAssignShiftOnComplete: this.onboardingAutoAssignShiftOnComplete,
      company: companyId,
      completionModalContent: this.completionModalContent,
      description: onboardingDescription,
      enabledTags: onboardingEnabledTags,
      hideNameAndLogo: onboardingHideNameAndLogo,
      positionId: onboardingRole,
      rejectionTags: onboardingRejectionTags,
      status: onboardingStatus,
      steps: stepValues as unknown as ICompanyOnboardingStep,
      title: onboardingTitle,
    } as unknown as ICompanyOnboarding;

    if (this.getCommonTags(data)) {
      dispatch(
        'ui.snackBar.error',
        'Rejected tags and enable tags can not have common tags',
      );
      return;
    }
    if (!_.isEmpty(data.steps)) {
      if (this.validActionButtonType(data.steps)) {
        dispatch(
          'ui.snackBar.error',
          'Once the ActionButton is selected, the ActionButtonType is required',
        );
        return;
      }
    }

    try {
      if (this.onboardingDetailsForm.$('uuid').value !== '') {
        const updateData = {
          data,
          id: this.onboardingDetailsForm.$('uuid').value,
        };
        await dispatch('companyOnboardings.update', updateData);
      } else {
        await dispatch('companyOnboardings.create', { data });
      }
      modalRef.current.clear();
    } catch (error) {
      runInAction(() => {
        this.errorMsg = error.message;
      });
      log.error('Error creating company onboarding: ', error, {
        extra: {
          companyId,
        },
      });
      dispatch('ui.snackBar.error', 'Onboarding could not be saved', {
        body: simplifiedErrorMessage
          ? 'Please check the required missing fields'
          : error.message,
      });
      return;
    }
    this.clear();
  }

  @action
  async saveUpdatedOnboarding({
    modalRef,
  }: {
    modalRef: OnboardingEditModalProps['modalRef'];
  }) {
    const stepValues = this.extractStepValues();
    if (!(await this.validateForm())) {
      dispatch('ui.snackBar.open', 'Please fill in the required fields');
      return;
    }
    const {
      onboardingTitle,
      onboardingRole,
      onboardingEnabledTags,
      onboardingRejectionTags,
      onboardingStatus,
      onboardingDescription,
      onboardingHideNameAndLogo,
    } = this.onboardingDetailsForm.values();
    const onboardingId = this.companyOnboarding.uuid;
    const updatedData: { data: Partial<ICompanyOnboarding>; id: string } = {
      data: {
        autoAssignShiftOnComplete: this.onboardingAutoAssignShiftOnComplete,
        completionModalContent: this.completionModalContent,
        description: onboardingDescription,
        enabledTags: onboardingEnabledTags,
        hideNameAndLogo: onboardingHideNameAndLogo,
        rejectionTags: onboardingRejectionTags,
        status: onboardingStatus,
        steps: stepValues,
        title: onboardingTitle,
      },
      id: onboardingId,
    };
    if (onboardingRole !== this.companyOnboarding.positionId) {
      updatedData.data.positionId = onboardingRole;
    }
    if (this.getCommonTags(updatedData.data)) {
      dispatch(
        'ui.snackBar.error',
        'Rejected tags and enable tags can not have common tags',
      );
      return;
    }
    if (!_.isEmpty(updatedData.data.steps)) {
      if (this.validActionButtonType(updatedData.data.steps)) {
        dispatch(
          'ui.snackBar.error',
          'Once the ActionButton is selected, the ActionButtonType is required',
        );
        return;
      }
    }
    try {
      await dispatch('companyOnboardings.update', updatedData);
      modalRef.current.clear();
    } catch (error) {
      runInAction(() => {
        this.errorMsg = error.message;
      });
      log.error('Error updating company onboarding: ', error, {
        extra: {
          onboardingId,
        },
      });
      dispatch('ui.snackBar.error', 'Onboarding could not be saved', {
        body: 'Please check the required missing fields',
      });
      return;
    }
    // re-set selected with populate data
    await dispatch('companyOnboardings.get', onboardingId, {
      query: {
        $client: {
          populateOnboardingPartners: true,
          populateStepPartnerCount: true,
          populateStepsCalculations: true,
        },
      },
    });
    this.clear();
  }

  extractStepValues() {
    return _.map(this.stepForms, (stepForm) => {
      const vals = stepForm.values();
      log.debug('Step form has values: ', vals);
      _.forEach(vals.substeps, (substep) => {
        if (
          substep.trainingId &&
          substep.actionButtonDestination !== 'completeTrainings'
        ) {
          substep.trainingId = '';
        }

        if (
          _.has(substep, 'attributeQuestions') &&
          substep.actionButtonDestination !== 'attributeQuestions'
        ) {
          delete substep.attributeQuestions;
        }
        if (
          _.has(substep, 'backgroundCheckType') &&
          substep.actionButtonDestination !== 'backgroundCheck'
        ) {
          delete substep.backgroundCheckType;
        }

        return substep;
      });
      return vals;
    });
  }

  @action
  setTagType({ type, field }) {
    if (field === 'rejection') {
      this.onboardingRejectionTagsType = type;
      this.onboardingDetailsForm.$('onboardingRejectionTags').set([]);
    } else {
      this.onboardingEnabledTagsType = type;
      this.onboardingDetailsForm.$('onboardingEnabledTags').set([]);
    }
  }

  @action
  checkTagType(tags, quals) {
    if (!tags) return 'quals';
    let tag = '';
    if (_.isArray(tags)) {
      tag = _.first(tags);
    } else tag = tags;
    const qual = quals.find((q) => q.uuid === tag);
    if (qual) return 'quals';
    return 'certs';
  }

  @action
  updateCompanyOnboarding(
    onboarding: ICompanyOnboarding,
    qualsList: IObservableArray,
  ) {
    this.onboardingDetailsForm = initCompanyOnboardingDetailsForm({
      onboardingDescription: onboarding.description,
      onboardingEnabledTags: onboarding.enabledTags,
      onboardingHideNameAndLogo: onboarding.hideNameAndLogo,
      onboardingRejectionTags: onboarding.rejectionTags,
      onboardingRole: onboarding.positionId,
      onboardingStatus: onboarding.status,
      onboardingTitle: onboarding.title,
      uuid: onboarding.uuid,
    });

    this.onboardingAutoAssignShiftOnComplete =
      onboarding.autoAssignShiftOnComplete;

    this.onboardingEnabledTagsType = this.checkTagType(
      onboarding.enabledTags,
      qualsList,
    );
    this.onboardingRejectionTagsType = this.checkTagType(
      onboarding.rejectionTags,
      qualsList,
    );
    this.completionModalContent = onboarding.completionModalContent;
    const lastStep = this.steps[this.steps.length - 1];
    if (lastStep) {
      this.lastStep = lastStep.uuid;
      const lastSubstep = lastStep.substeps[lastStep.substeps.length - 1];
      this.lastSubstep = lastSubstep.uuid;
      if (lastSubstep && !_.isEmpty(lastSubstep.backgroundCheckType)) {
        this.setBackgroundCheckSubstep(lastSubstep.uuid);
      }
    }
  }

  @action
  clear() {
    if (this.onboardingDetailsForm) {
      this.onboardingDetailsForm.clear();
    }
    this.completionModalContent = {};
    this.selectedStepId = null;
    this.selectedTab = OnboardingTabs.ONBOARDING_DETAILS;
    this.selectedSubstepId = null;
    this.openAddStepModal = false;
    this.openAddSubStepModal = false;
    this.onboardingEnabledTagsType = 'quals';
    this.onboardingRejectionTagsType = 'quals';
    this.steps.clear();
    this.stepForms.clear();
    this.lastStep = null;
    this.lastSubstep = null;
    this.backgroundCheckSubstep = null;
    this.backgroundCheckStep = null;
    this.errorMsg = '';
    this.formErrors = defaultFormErrors;
  }

  @action
  clearErrors() {
    this.errorMsg = '';
    this.formErrors = defaultFormErrors;
  }

  async validateForm() {
    this.formErrors = defaultFormErrors;
    let isValid = true;
    if (_.isEmpty(this.onboardingDetailsForm.$('onboardingTitle').value)) {
      this.formErrors.title = 'Please add a title';
      dispatch('ui.snackBar.open', 'Please add a title');
      isValid = false;
      this.set('selectedTab', OnboardingTabs.ONBOARDING_DETAILS);
      return false;
    }
    if (_.isEmpty(this.extractStepValues())) {
      this.formErrors.stepError = 'Please add at least one step';
      this.set('selectedTab', OnboardingTabs.STEPS);
      dispatch('ui.snackBar.open', 'Please add at least 1 step');
      isValid = false;
      return false;
    }
    await Promise.map(
      this.stepForms,
      async (step) => {
        // validate steps
        let hasInvalidSubsteps = false;
        await Promise.map(
          step.$('substeps').map((s) => s),
          async (substep) => {
            const res = await substep.validate({ showErrors: true });
            if (!res.isValid) {
              isValid = false;
              this.set('selectedStepId', step.$('uuid').value);
              this.set('selectedSubstepId', substep.$('uuid').value);
              this.set('selectedTab', OnboardingTabs.STEPS);
              hasInvalidSubsteps = true;
            }
          },
          {
            concurrency: 1,
          },
        );

        if (hasInvalidSubsteps) {
          return;
        }

        const response = await step.validate({ showErrors: true });
        if (!response.isValid) {
          isValid = false;
          this.set('selectedTab', OnboardingTabs.STEPS);
          this.set('selectedStepId', step.$('uuid').value);
          this.set('selectedSubstepId', null);
        }
      },
      { concurrency: 1 },
    );
    return isValid;
  }

  @computed
  get mobilePreviewIndex() {
    return (
      this.stepForms
        .map((step) => step.values())
        .findIndex((x) => x.uuid === this.selectedStepId) || 0
    );
  }

  @computed
  get actionButtonOptions() {
    const options =
      this.getExtra()['substeps[].actionButtonDestination'].options;

    if (
      this.lastSubstep === this.selectedSubstepId &&
      this.selectedStepId === this.lastStep
    ) {
      return options;
    }
    return options.filter((x) => x.value !== 'backgroundCheck');
  }
}
