import type { ICompany, UUID, IShift } from '@shiftsmartinc/shiftsmart-types';

import { dispatch } from 'rfx-core';
import moment from 'moment';
import _ from 'lodash';

import { getChildLogger } from './client.logger';

const fileLog = getChildLogger('utils.shifts');

interface IGetGeneratedSmartTitleArgs {
  company?: ICompany['uuid'];
  companyObj?: Partial<ICompany>;
  end?: Date | string;
  role: UUID;
  roleObj?: Partial<{ title: string }>;
  roleTitle?: string;
  shiftObj?: Partial<IShift>;
  start?: Date | string;
}

const DEFAULT_TEMPLATE_STRING =
  '<%= formatted.company%> - <%= roleTitle ? (roleTitle + " - ") : "" %> <%= formatted.dateRange%>';

/**
 * Use to generate the shift title
 * Assuming the shiftTitleTemplate be like "<%= formatted.company%> - <%= roleTitle%> - <%= formattedDateRange%>"
 * @return {string} shiftTitleTemplate
 */

export async function getGeneratedSmartTitle({
  role,
  roleObj = {},
  start,
  end,
  company,
  companyObj = {},
  roleTitle,
  shiftObj = {},
}: IGetGeneratedSmartTitleArgs) {
  if (_.isEmpty(companyObj) && !_.isEmpty(company)) {
    _.assign(
      companyObj,
      await dispatch('companies.get', company, {
        select: false,
      }),
    );
  }

  const rawTemplateString = DEFAULT_TEMPLATE_STRING;
  if (/^[\s'"]*false/i.test(rawTemplateString)) {
    return undefined;
  }
  const shiftTitleTemplate = _.template(rawTemplateString);

  if (_.isEmpty(roleObj) && !_.isEmpty(role)) {
    _.assign(
      roleObj,
      await dispatch('positions.get', role, {
        select: false,
      }),
    );
  }

  const formatted = {
    company: companyObj.abbr || companyObj.name,
    endDay: moment(end).format('ddd'),
    endTime: moment(end).format('h:mmA'),
    startDay: moment(start).format('ddd'),
    startTime: moment(start).format('h:mmA'),
  };
  _.extend(formatted, {
    dateRange: `${formatted.startDay} (${formatted.startTime}-${formatted.endTime})`,
  });

  return shiftTitleTemplate({
    companyObj,
    formatted,
    roleObj,
    roleTitle: roleTitle || roleObj?.title,
    shiftObj,
  });
}

export function validateFlexibleShiftWorkingHours({
  shiftStart,
  shiftEnd,
  shiftScheduleRules,
  ignoreDateDifference = false,
}) {
  const { workingHoursStart, workingHoursEnd } = shiftScheduleRules ?? {};
  const oldWorkingHoursStart = moment(workingHoursStart);
  const oldWorkingHoursEnd = moment(workingHoursEnd);
  let newWorkingHoursStartValue = moment(workingHoursStart);
  let newWorkingHoursEndValue = moment(workingHoursEnd);
  if (!ignoreDateDifference) {
    newWorkingHoursStartValue = moment(shiftStart).set({
      hour: oldWorkingHoursStart.hour(),
      minute: oldWorkingHoursStart.minute(),
      second: 0,
    });
    newWorkingHoursEndValue = moment(shiftEnd).set({
      hour: oldWorkingHoursEnd.hour(),
      minute: oldWorkingHoursEnd.minute(),
      second: 0,
    });
  }
  let flexibleShiftValidationError = '';
  let errorPath = '';
  if (newWorkingHoursStartValue.isAfter(shiftStart)) {
    flexibleShiftValidationError =
      'Working Hours cannot start after shift start';
    errorPath = 'shiftScheduleRules.workingHoursStart';
  } else if (newWorkingHoursEndValue.isBefore(shiftEnd)) {
    flexibleShiftValidationError = 'Working Hours cannot end before shift end';
    errorPath = 'shiftScheduleRules.workingHoursEnd';
  } else if (newWorkingHoursStartValue.isAfter(newWorkingHoursEndValue)) {
    flexibleShiftValidationError =
      'Working Hours Start must be before Working Hours End';
    errorPath = 'shiftScheduleRules.workingHoursStart';
  }

  return {
    errorPath,
    flexibleShiftValidationError,
    newWorkingHoursEndValue: newWorkingHoursEndValue.toDate(),
    newWorkingHoursStartValue: newWorkingHoursStartValue.toDate(),
  };
}

export function validateShiftDuration({ start, end }): string | null {
  const log = fileLog.getChildLogger('validateShiftDuration');
  const duration = moment(end).diff(start, 'hours');
  log.debug('Shift has a duration of %s hours', duration);
  if (duration > 48) {
    return 'Shifts cannot be longer than 48 hours';
  }

  return null;
}
