import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import moment from 'moment';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class SendShiftModal {
  log = getChildLogger('ui.SendShiftModal');

  @observable
  isOpen = false;

  @action
  async setup({ open = this.isOpen, company }) {
    const log = this.log.getChildLogger('setup');
    this.isOpen = open;
    const query = {
      $limit: 10,
      company: company.uuid,
      start: { $gte: moment().startOf('day').toDate() },
      status: 'Active',
    };
    try {
      await dispatch(
        'shifts.find',
        {
          query,
        },
        { clear: true },
      );
    } catch (err) {
      log.error('Failed to retrieve shifts to send', err);
    }
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    this.isOpen = false;
  }

  @action.bound
  async send({ selected, channel }) {
    const log = this.log.getChildLogger('send');
    if (_.isEmpty(channel)) {
      dispatch('ui.snackBar.open', 'Please select a channel');
      return;
    }

    this.clear();
    try {
      dispatch('ui.createShiftModal.send', { channel, selected });
    } catch (err) {
      log.error('Failed to send shift', err);
    }
  }
}
