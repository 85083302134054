import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class WorkerMembershipModal {
  log = getChildLogger('ui.WorkerMembershipModals');

  @observable
  isOpen = false;

  @observable
  entity = {};

  @observable
  existingCompanies = [];

  @observable
  storename = 'partners';

  @action
  setup({
    worker,
    location,
    entity = worker || location,
    isAdmin = false,
    storeName = 'partners',
    open = this.isOpen,
  }) {
    const log = this.log.getChildLogger('setup');
    this.storeName = storeName;
    this.entity = entity;
    const userCompanies = _.map(dispatch('companies.getUserCompanies'), 'uuid');
    const companyIds = _.get(this.entity, 'companies', []);
    dispatch('companies.runQuery', {
      uuid: {
        $in: isAdmin
          ? companyIds
          : _.filter(companyIds, (companyId) =>
              _.includes(userCompanies, companyId),
            ),
      },
    })
      .then(
        action((res) => {
          this.existingCompanies.replace(_.get(res, 'data', []));
        }),
      )
      .catch((error) =>
        log.error('Error Geting Existing Companies for Entity: ', error),
      );

    this.isOpen = open;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  removeCompany({ worker, company }) {
    if (worker.uuid !== this.entity.uuid) {
      return Promise.reject('Selected Worker does not match provideed worker');
    }
    _.remove(this.existingCompanies, (c) => c.uuid === company.uuid);

    this.entity = worker;
    return Promise.resolve(this.entity);
  }

  @action
  addCompany({ worker, company }) {
    if (worker.uuid !== this.entity.uuid) {
      return Promise.reject('Selected Worker does not match provideed worker');
    }
    this.existingCompanies.push(company);

    this.entity = worker;
    return Promise.resolve(this.entity);
  }

  @action
  updateEntity(entity) {
    this.entity = entity;
  }

  @action
  clear() {
    this.storeName = 'partners';
    this.entity = observable({});
    this.isOpen = false;
    this.existingCompanies.clear();
  }
}
