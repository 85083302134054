import { observable, action, set, runInAction, computed } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import BaseUploader, {
  KnownFieldConfig,
} from '#/shared/stores/ui/BaseUploader';

export default class BonusFormUploader extends BaseUploader {
  constructor() {
    super({ title: 'ui.BonusFormUploader' });

    set(this.saveResults, {
      ignored: [],
      invalid: [],
      updated: [],
    });

    return this;
  }

  @observable
  includeHowTo = false;

  @observable parseOptions = {
    dynamicTyping: false,
    header: false,
    skipEmptyLines: true,
  };

  /** list
   * Stores an array of rows, Ready for saving.
   */
  @observable
  list = [];

  /**
   * Parsed Headers
   * An array of all headers found in the parsed objects
   *
   * Used to render the column headers in a dynamic table.
   *
   * @memberof ShiftPaymentApprovalUpload
   */
  @observable
  parsedHeaders = [];

  @observable
  resultHeaders = [];

  @computed
  get knownFields(): KnownFieldConfig[] {
    return [
      {
        exampleVal: '',
        isExampleField: true,
        key: 'userId',
        required: true,
      },
    ];
  }

  @computed
  get sampleCSVData() {
    const sampleData = super.$sampleCSVData;

    return sampleData;
  }

  @computed get knownHeaders() {
    return _.map(this.knownFields, 'key');
  }

  @action
  async setup(opts, ...rest) {
    super.setup(opts, ...rest);

    this.clear();

    this.company = opts.company;

    if (_.isString(this.company)) {
      const company = await dispatch('companies.get', this.company, {
        select: false,
      });
      runInAction(() => {
        this.company = company;
      });
    }
  }

  @action
  clear() {
    super.clear();

    this.saveResults = {
      ignored: [],
      invalid: [],
      updated: [],
    };

    this.list.clear();
    this.parsedHeaders.clear();
    this.resultHeaders.clear();
  }

  @action
  async processFile({ options = this.parseOptions, ...rest }) {
    this.setStatus('loading');

    try {
      await super.processFile({ options, ...rest });
    } catch (err) {
      this.log.error('Failed to process file', err);

      this.setStatus('error');

      throw err;
    }
  }

  @action
  async parse({ rows }) {
    let parsedHeaders = [];
    const casedHeaders = _.map(rows[0], _.toLower);
    const indices = _.reduce(
      this.knownHeaders,
      (acc, header) => {
        acc[header] = _.indexOf(casedHeaders, _.toLower(header));
        return acc;
      },
      {},
    );

    this.log.debug('Loaded Table Indices', { indices });

    const validRows = _(rows)
      .map((row, i) => {
        if (!i) return null;

        this.log.debug(row);
        return _.reduce(
          this.knownHeaders,
          (acc, colKey) => {
            const colIndex = indices[colKey];

            if (colIndex >= 0) {
              acc[colKey] = row[colIndex];
            }

            return acc;
          },
          {},
        );
      })
      .reject((rowObj) => _.isEmpty(_.omitBy(rowObj, _.isEmpty)))
      .map((row) => {
        parsedHeaders = _.union(parsedHeaders, _.keys(row));
        return row;
      })
      .compact()
      .value();

    this.parsedHeaders = parsedHeaders;
    this.setStatus('Evaluating');

    if (_.isEmpty(validRows)) {
      dispatch('ui.snackBar.error', 'CSV must have at least one valid userId');
      throw new Error('CSV must have at least one valid userId');
    }
    const cleanData = this.cleanData(validRows);
    // eslint-disable-next-line no-console
    console.table(cleanData);
    runInAction(async () => {
      this.list.replace(cleanData);
    });

    this.setStatus('Parsed all Rows');
  }

  @action cleanData(rows) {
    return _.uniqBy(rows, 'userId');
  }

  @action
  getUserIds() {
    return this.list.map((row) => row.userId);
  }
}
