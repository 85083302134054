import _ from 'lodash';
import { dispatch } from 'rfx-core';
import moment from 'moment';
import flatten from 'flat';

import { defaultCountries } from '#/shared/components/utils/filters/CountryFilter';
import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';

const log = getChildLogger('forms.campaign');

class CampaignForm extends Form {
  hooks = () => ({
    async onSuccess(form) {
      const uuid = form.initials().uuid || undefined;

      let storeAction = 'campaigns.create';
      let successMsg = 'New Campaign Created';
      let isNew = true;
      const data = form.values();
      const allowedCompletionDOWs = await dispatch(
        'ui.campaignEditModal.getSelectedDays',
      );

      // Call this for both new and existing updates
      this.removePristineNestedFields(data, null, { mutate: true });

      if (uuid) {
        storeAction = 'campaigns.update';
        successMsg = 'Campaign Saved';
        isNew = false;
        data.uuid = uuid;
      }

      if (isNew) {
        data.company = form.initials().company;
        data.start = data.start || form.initials().start;
      }

      if (data.campaignRules) {
        const campaignRules = flatten(_.pick(data, 'campaignRules'));
        delete data.campaignRules;
        _.extend(data, campaignRules);
      }

      if (data.expectedValueConfig) {
        const expectedValueConfig = flatten(
          _.pick(data, 'expectedValueConfig'),
        );
        delete data.expectedValueConfig;
        _.extend(data, expectedValueConfig);
      }

      if (isNew && data.currency === '') {
        delete data.currency;
      }
      if (allowedCompletionDOWs) {
        data.allowedCompletionDOWs = allowedCompletionDOWs
          .map((val) => val.key)
          .sort();
      }

      if (!data.requirePreShopSurvey) {
        data.preShopSurveyDefinition = null;
      }

      if (data.start) {
        data.start = moment(data.start).startOf('day');
      }
      if (data.allowedCompletionStartTime) {
        data.allowedCompletionStartTime = moment(
          data.allowedCompletionStartTime,
        ).format('YYYY-MM-DDTHH:mm:ss'); // this is to help avoid timezone issues by making it a string instead of date.
      }
      if (data.allowedCompletionEndTime) {
        data.allowedCompletionEndTime = moment(
          data.allowedCompletionEndTime,
        ).format('YYYY-MM-DDTHH:mm:ss'); // this is to help avoid timezone issues by making it a string instead of date.
      }
      let setCampaignActive;
      if (data.end) {
        data.end = moment(data.end).endOf('day');
        setCampaignActive = data.end.isAfter(moment());
      }

      if (data.phoneNumber) {
        data.phoneNumber = data.phoneNumber.replace(/([() -])/g, '');
      }

      if (
        !isNew &&
        form.initials().status === 'Completed' &&
        setCampaignActive
      ) {
        data.status = 'Active';
      }
      let campaign;
      try {
        campaign = await dispatch(storeAction, { data });
        dispatch('ui.snackBar.open', successMsg);
        dispatch('ui.campaignEditModal.clear');
        if (isNew) {
          dispatch('routing.push', `/retail/campaigns/${campaign.uuid}`);
        }
      } catch (err) {
        log.error('Saving Campaign Failed: ', err);
        form.invalidate(err.message);
        dispatch('ui.snackBar.open', err.message);
      }
      return campaign;
    },
  });
}

const fields = [
  'uuid',
  'title',
  'campaignType',
  'storeList',
  'storeList.uuid',
  'storeList.title',
  'company',
  'allowedCompletionDOWs',
  'allowedCompletionStartTime',
  'allowedCompletionEndTime',
  'startShopTime',
  'start',
  'end',
  'payRate',
  'currency',
  'phoneCountryCode',
  'phoneNumber',
  'campaignRules.shopsPerWorker',
  'campaignRules.assignedShopsPerWorker',
  'campaignRules.visitsPerStore',
  'campaignRules.partnerVisitsPerStore',
  'requireCheckin',
  'requireImage',
  'enableWorkReview',
  'restrictWorkReview',
  'expireShops',
  'expirationWindow',
  'allowSelfAssignment',
  'autoApproveShops',
  'reactivateQARejectedShops',
  'requirePreShopSurvey',
  'externalLink',
  'helpCenterLink',
  'guidelinesURL',
  'safetyCultureTemplateId',
  'preShopSurveyDefinition.uuid',
  'preShopSurveyDefinition.title',
  'showCustomSurvey',
  'enableSurveyCheckout',
  'customSurveyTitle',
  'customSurveyDescription',
  'expectedValueConfig.assigned',
  'expectedValueConfig.flagged',
  'expectedValueConfig.complete',
  'expectedValueConfig.trigger',
  'billableRate',
  'billableQuota',
  'country',
  'discount',
  'poNumber',
  'project',
  'startShopTimeBusinessDaysOnly',
];

const rules = {
  allowedCompletionDOWs: 'array',
  allowedCompletionEndTime: 'date',
  allowedCompletionStartTime: 'date',
  billableQuota: 'numeric|integer',

  billableRate: 'numeric',
  'campaignRules.assignedShopsPerWorker': 'numeric|min:0|integer',
  'campaignRules.partnerVisitsPerStore':
    'required_if:campaignType,onsite|numeric|min:0|integer',
  'campaignRules.shopsPerWorker':
    'required_if:campaignType,onsite|numeric|min:0|integer',
  'campaignRules.visitsPerStore':
    'required_if:campaignType,onsite|numeric|min:0|integer',
  campaignType: 'string',
  company: 'required|string',
  country: 'string',
  currency: 'required|string',
  customSurveyDescription: 'string',
  customSurveyTitle: 'string',
  discount: 'numeric',
  enableSurveyCheckout: 'boolean',
  enableWorkReview: 'boolean',
  end: 'required|date',
  'expectedValueConfig.assigned': 'numeric|between:0,10|integer',
  'expectedValueConfig.complete': 'numeric|between:0,10|integer',
  'expectedValueConfig.flagged': 'numeric|between:0,10|integer',
  'expectedValueConfig.trigger': 'numeric|between:0,10|integer',
  expirationWindow: 'numeric',
  externalLink: 'string',
  guidelinesURL: 'string',
  helpCenterLink: 'string',
  payRate: 'required|numeric|min:0|integer',
  phoneNumber: 'string',
  poNumber: 'string',
  'preShopSurveyDefinition.title':
    'required_if:requirePreShopSurvey,true|string',

  'preShopSurveyDefinition.uuid':
    'required_if:requirePreShopSurvey,true|string',
  project: 'string',

  restrictWorkReview: 'boolean',
  safetyCultureTemplateId: 'string',
  start: 'required|date',
  startShopTime: 'numeric',

  startShopTimeBusinessDaysOnly: 'boolean',
  'storeList.title': 'required_if:campaignType,onsite|string',
  'storeList.uuid': 'required_if:campaignType,onsite|uuid',
  title: 'required|string',
  uuid: 'string',
};

const labels = {
  allowSelfAssignment: 'Allow Self Assignment?',
  allowedCompletionDOWs: 'The days when you would like the shop to be visited',
  allowedCompletionEndTime: `The latest time of day that the shop can be visited`,
  allowedCompletionStartTime: `The earliest time of day that the shop can be visited`,
  autoApproveShops: 'Auto-Approve Shops',
  billableQuota: 'Billable Quota',
  billableRate: 'Billable Rate ($)',
  campaignRules: 'Rules',
  'campaignRules.assignedShopsPerWorker': 'Assigned Shops / Partner',
  'campaignRules.partnerVisitsPerStore': 'Partner Visits / Store',
  'campaignRules.shopsPerWorker': 'Shops / Partner',
  'campaignRules.visitsPerStore': 'Visits / Store',
  campaignType: 'Campaign Type',
  country: 'Country',
  currency: 'Currency (ISO 4217)',
  customSurveyDescription: 'Custom Survey Description',
  customSurveyTitle: 'Custom Survey Title',
  discount: 'Discount Rate (%)',
  enableSurveyCheckout: 'Require Checkout?',
  enableWorkReview: 'Enable Work Review',
  end: 'Campaign End',
  'expectedValueConfig.assigned': 'Assigned Expected Value',
  'expectedValueConfig.complete': 'Complete Expected Value',
  'expectedValueConfig.flagged': 'Flagged Expected Value',
  'expectedValueConfig.trigger': 'Expected Value Shutoff',
  expirationWindow: 'Time To Complete Shop (Hours)',
  expireShops: 'Auto Expire Shops?',
  externalLink: 'Add an external survey link',
  guidelinesURL: 'Add a guidelines link for this campaign',
  helpCenterLink: 'Add a help center link',
  payRate: 'Shop Pay Rate',
  phoneNumber: 'Phone Number',
  poNumber: 'PO Number',
  preShopSurveyDefinitionId: 'Select Survey',
  project: 'Project Name',
  reactivateQARejectedShops: 'Reactivate QA Rejected Shops',
  requireCheckin: 'Require Checkin?',
  requireImage: 'Require Image?',
  requirePreShopSurvey: `Require a survey/checklist before user can complete shop`,
  restrictWorkReview: 'Restrict Review to Assigned Agents',
  safetyCultureTemplateId: 'SafetyCulture Template ID',
  showCustomSurvey: 'Customize Survey Title and Description?',
  start: 'Campaign Start',
  startShopTime: 'How soon a worker can start a shop',
  storeList: 'Store List',
  title: 'Campaign Title',
};

export const currencyDropdownOptions = [
  {
    key: 'usd',
    text: 'US Dollar',
    value: 'usd',
  },
  {
    key: 'cad',
    text: 'Canadian Dollar',
    value: 'cad',
  },
  {
    key: 'gbp',
    text: 'GB Pound Sterling',
    value: 'gbp',
  },
  {
    key: 'eur',
    text: 'Euro',
    value: 'eur',
  },
  {
    key: 'nok',
    text: 'Norwegian Krone',
    value: 'nok',
  },
  {
    key: 'sek',
    text: 'Swedish krona',
    value: 'sek',
  },
  {
    key: 'dkk',
    text: 'Danish krone',
    value: 'dkk',
  },
  {
    key: 'aud',
    text: 'Australian Dollar',
    value: 'aud',
  },
  {
    key: 'nzd',
    text: 'New Zealand Dollar',
    value: 'nzd',
  },
  {
    key: 'inr',
    text: 'Indian Rupee',
    value: 'inr',
  },
  {
    key: 'mxn',
    text: 'Mexican Peso',
    value: 'mxn',
  },
  {
    key: 'zar',
    text: 'South African Rand',
    value: 'zar',
  },
  {
    key: 'brl',
    text: 'Brazilian Real',
    value: 'brl',
  },
  {
    key: 'pln',
    text: 'Polish Zloty',
    value: 'pln',
  },
  {
    key: 'sar',
    text: 'Saudi Riyal',
    value: 'sar',
  },
  {
    key: 'aed',
    text: 'UAE Dirham',
    value: 'aed',
  },
  {
    key: 'try',
    text: 'Turkish Lira',
    value: 'try',
  },
];

export const shoppableCheckboxOptions = [
  {
    key: 1,
    text: 'Sunday',
    value: 'sun',
  },
  {
    key: 2,
    text: 'Monday',
    value: 'mon',
  },
  {
    key: 3,
    text: 'Tuesday',
    value: 'tue',
  },
  {
    key: 4,
    text: 'Wednesday',
    value: 'wed',
  },
  {
    key: 5,
    text: 'Thursday',
    value: 'thu',
  },
  {
    key: 6,
    text: 'Friday',
    value: 'fri',
  },
  {
    key: 7,
    text: 'Saturday',
    value: 'sat',
  },
];

const extra = {
  allowSelfAssignment: {
    tooltip: 'Shops in this campaign will be visible on the map',
  },
  autoApproveShops: {
    tooltip:
      'Shops in this campaign will be auto-approved for payment if they pass all other fraud rules',
  },
  'campaignRules.assignedShopsPerWorker': {
    tooltip:
      'The total number of shops a partner can have assigned at a given time.',
  },
  'campaignRules.partnerVisitsPerStore': {
    tooltip:
      'The total number of shops a partner can complete at a specific store in a campaign.',
  },
  'campaignRules.shopsPerWorker': {
    tooltip: 'The total number of shops a partner can complete in a campaign.',
  },
  'campaignRules.visitsPerStore': {
    tooltip:
      'The total number of shops a store can have completed in a campaign across all partners.',
  },
  campaignType: {
    options: [
      {
        key: 'onsite',
        text: 'On Site',
        value: 'onsite',
      },
      {
        key: 'remote',
        text: 'Remote',
        value: 'remote',
      },
    ],
  },
  country: {
    options: defaultCountries,
  },
  currency: {
    options: currencyDropdownOptions,
  },
  enableSurveyCheckout: {
    tooltip: 'Prompt workers to check out of mystery shops upon completion',
  },
  enableWorkReview: {
    tooltip: 'Allow `survey-review` agents to view and edit Survey Responses',
  },
  expirationWindow: {
    tooltip:
      'Time the user will have to complete the shop. Default is 24 hours',
  },
  externalLink: {
    tooltip: 'An external link for a survey workers are expected to complete',
  },
  guidelinesURL: {
    tooltip: 'A link to the guidelines for this campaign',
  },
  helpCenterLink: {
    tooltip: 'A link to a help center',
  },
  rejectedShops: {
    tooltip:
      "When a shop is marked 'Rejected' by QA, reopen the shop to workers",
  },
  requireCheckin: {
    tooltip: 'Prompt workers to check into mystery shops upon start',
  },
  safetyCultureTemplateId: {
    tooltip: 'The ID of the SafetyCulture template to use for this campaign',
  },
  shoppableDays: {
    tooltip: 'The days of the week when the store can be visited',
  },
  startShopTime: {
    tooltip:
      'The number of hours a worker must wait before they can start a shop after accepting',
  },
};

export function init(values = {}, opts = { src: null }) {
  log.debug('Creating Campaign from base %s', opts.src);

  log.debug('Creating Campaign Form from Keys: ', _.keys(values));

  const form = new CampaignForm(
    { extra, fields, labels, rules, values },
    {
      ...(opts ?? {}),
      options: { retrieveOnlyDirtyValues: true },
    },
  );
  log.debug('Created Campaign Form with Values: ', form.values());

  return form;
}

export type { CampaignForm };
