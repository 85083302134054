import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';

import BaseStore from './_baseStore';

export type IGCP = IBaseItem & Record<string, unknown>;

export default class GCPStore extends BaseStore<IGCP> {
  constructor() {
    const baseItem = {
      bucketName: '',
    };
    super({ baseItem, serviceName: 'googleCloudStorage' });

    this.selected = _.clone(this.baseItem);

    return this;
  }
}
