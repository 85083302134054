import type { IInShiftTaskItem } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export default class InShiftTaskItemsStore extends BaseStore<IInShiftTaskItem> {
  constructor() {
    super({
      baseItem: InShiftTaskItemsStore.BASE_ITEM,
      serviceName: 'inShiftTaskItems',
    });

    return this;
  }

  static BASE_ITEM: IInShiftTaskItem = {
    category: null,
    checkListValues: null,
    companyId: null,
    createdAt: null,
    description: null,
    expectedTimeInMinutes: null,
    inputType: null,
    modelName: null,
    postImageUploadRequired: null,
    preImageUploadRequired: null,
    sequenceId: null,
    shiftId: null,
    taskContainerId: null,
    title: null,
    updatedAt: null,
    uuid: null,
  };
}
