import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';

const log = getChildLogger('forms.schedulingTemplate');

class SchedulingTemplateForm extends Form {
  hooks = () => ({
    onSuccess() {
      log.debug('SchedulingTemplateForm onSuccess()');
    },
  });
}

const fields = [
  'title',
  'description',
  'start',
  'duration',
  'position',
  'saveAssignedPartners',
  'saveDraftShifts',
];

const rules = {
  start: 'required|date',
  title: 'required|string',
};

const labels = {
  description: 'Description (Optional)',
  duration: 'Range',
  position: 'Position',
  start: 'Start Date',
  title: 'Template Title',
};

// init(values = {}, opts = {})
/** @deprecated 2022-09-26 used only for deprecated CreateSchedulingTemplateModal */
export function init(values = {}) {
  log.debug('Creating Scheduling Template Form from Keys: ', _.keys(values));

  const form = new SchedulingTemplateForm(
    { fields, labels, rules, values },
    // TODO: These configs must be passed under the `options` param. They have been commented out to remove confusion
    // { retrieveOnlyDirtyValues: false },
  );
  log.debug('Created Scheduling Template Form with Values: ', form.values());
  return form;
}
