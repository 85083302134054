import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export type IVideoReview = IBaseItem & {
  companyId: string;
  createdAt: string;
  reviewerId: string;
  reviews: {
    reviewPrompt: string;
    reviewPromptResponse: string;
    reviewPromptType: string;
    videoQuestion: string;
  }[];
  updatedAt: string;
  userId: string;
  uuid: string;
};

export default class VideoReviewStore extends BaseStore<IVideoReview> {
  constructor() {
    const baseItem = {
      companyId: null,
      createdAt: null,
      reviewerId: null,
      reviews: [],
      updatedAt: null,
      userId: null,
      uuid: null,
    };
    super({ baseItem, serviceName: 'videoreviews' });

    return this;
  }
}
