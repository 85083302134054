import type { IUser } from '@shiftsmartinc/shiftsmart-types';

import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import cx from 'classnames';
import { Image } from 'semantic-ui-react';

import UserInitials from '#/shared/components/ssm/users/UserInitials';
import { getChildLogger } from '#/shared/utils/client.logger';
import { useCachedUserItem } from '#/shared/hooks/useCacheItem';

const log = getChildLogger('ssm.users.UserImage');

type UserImagePropTypes = {
  circular: boolean;
  className: string;
  defaultImage: string;
  fontSize: number;
  imageKey: string;
  style: unknown;
  user: IUser;
  userId: string;
};

const UserImage = observer<React.FC<UserImagePropTypes>>(
  ({
    circular = false,
    className,
    defaultImage,
    fontSize,
    imageKey = 'profileImageURL',
    style = {},
    user,
    userId,
  }) => {
    log.verbose('Initializing UserImage');

    const { item: userObject } = useCachedUserItem({
      user: user,
      userId: userId,
    });

    log.verbose(
      `Rendering Imagefor UO:${_.get(userObject, imageKey)} vs PU:${_.get(
        user,
        imageKey,
      )}`,
      {
        user: user,
        userId: userId,
        userObject: userObject,
      },
    );

    const propVal = _.get(userObject, imageKey) || _.get(user, imageKey);

    if (!propVal) {
      return (
        <UserInitials
          user={typeof user === 'object' ? user : userObject}
          {...{
            circular,
            className,
            fontSize,
            style,
          }}
        />
      );
    }

    return (
      <Image
        className={cx(className, circular && 'br-100 h-100 w-100')}
        src={propVal || defaultImage}
        style={style}
      />
    );
  },
);

UserImage.displayName = 'UserImage';

export default UserImage;
