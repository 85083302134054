import _ from 'lodash';

import Form from './_.extend';
/**
 * A simple form for creating and editing inShiftTaskItems
 * No additional hooks
 * Initial values default to empty
 */

export class InShiftTaskForm extends Form {
  constructor(setup) {
    super(setup);
  }
  hooks = () => ({});
}

const fields = [
  'category',
  'checkListItemTitle',
  'checkListItemType',
  'cloneRulesIdToClone',
  'cloneRulesRedirectTo',
  'cloneRulesupdateRelatedTasks',
  'customCopy.checkList',
  'customCopy.cloneMessage',
  'customCopy.completeTaskButton',
  'customCopy.inputNumberRow',
  'customCopy.noCloneMessage',
  'customCopy.pickListAdd',
  'customCopy.postImageButton',
  'customCopy.postImageRow',
  'customCopy.postImageUploadTitle',
  'customCopy.preImageButton',
  'customCopy.preImageRow',
  'customCopy.preImageUploadTitle',
  'customCopy.saveNumberButton',
  'description',
  'evaluationCriteria',
  'expectedTimeInMinutes',
  'imageURL',
  'inputType',
  'isCritical',
  'postImageUploadRequired',
  'preImageUploadRequired',
  'relatedTasksAction',
  'relatedTasksIncludeDenominator',
  'relatedTasksTaskId',
  'sequenceId',
  'task',
  'taskContainerId',
  'title',
  'type',
  'videoURL',
];

const rules = {
  category: 'string|required|between:1,50',
  checkListItemTitle: 'string',
  checkListItemType: 'string',
  cloneRulesIdToClone: 'string',
  cloneRulesRedirectTo: 'boolean',
  cloneRulesupdateRelatedTasks: 'boolean',
  'customCopy.checkList': 'string',
  'customCopy.cloneMessage': 'string',
  'customCopy.completeTaskButton': 'string',
  'customCopy.inputNumberRow': 'string',
  'customCopy.noCloneMessage': 'string',
  'customCopy.pickListAdd': 'string',
  'customCopy.postImageButton': 'string',
  'customCopy.postImageRow': 'string',
  'customCopy.postImageUploadTitle': 'string',
  'customCopy.preImageButton': 'string',
  'customCopy.preImageRow': 'string',
  'customCopy.preImageUploadTitle': 'string',
  'customCopy.saveNumberButton': 'string',
  description: 'string|required',
  evaluationCriteria: 'string',
  expectedTimeInMinutes: 'numeric|required',
  imageUrl: 'string',
  inputType: 'string|required',
  isCritical: 'boolean',
  postImageUploadRequired: 'boolean',
  preImageUploadRequired: 'boolean',
  relatedTasksAction: 'string',
  relatedTasksIncludeDenominator: 'boolean',
  relatedTasksTaskId: 'string',
  sequenceId: 'numeric',
  taskContainerId: 'string',
  title: 'string|required',
  type: 'string',
  videoURL: 'string',
};

const labels = {
  category: 'Section',
  checkListItemTitle: 'Title',
  checkListItemType: 'Type',
  cloneRulesIdToClone: 'Task that will be cloned',
  cloneRulesRedirectTo: 'Redirect to this task after clone',
  cloneRulesupdateRelatedTasks: 'Update Related Tasks',
  'customCopy.checkList': 'Checklist Title',
  'customCopy.cloneMessage': 'Clone Message',
  'customCopy.completeTaskButton': 'Complete Task Button',
  'customCopy.inputNumberRow': 'Input Number Row Text',
  'customCopy.noCloneMessage': 'No Clone Message',
  'customCopy.pickListAdd': 'Picklist Add Button Text',
  'customCopy.postImageButton': 'Post Image Button Text',
  'customCopy.postImageRow': 'Post Image Row Text',
  'customCopy.postImageUploadTitle': 'Post Image Upload Title',
  'customCopy.preImageButton': 'Pre Image Button Text',
  'customCopy.preImageRow': 'Pre Image Row Text',
  'customCopy.preImageUploadTitle': 'Pre Image Upload Title',
  'customCopy.saveNumberButton': 'Save Number Button Text',
  description: 'Description',
  evaluationCriteria:
    "Evaluation Criteria (this won't do anything if the task hasn't been marked as critical)",
  expectedTimeInMinutes:
    'Estimated number of minutes that this task will take to complete',
  imageURL: 'Example Image of Task (must be a jpeg)',
  inputType: 'Select Input Type',
  isCritical: 'Is this a critical task?',
  postImageUploadRequired: 'Require post-task image',
  preImageUploadRequired: 'Require pre-task image',
  relatedTasksAction: 'What type should the checklist row be?',
  relatedTasksIncludeDenominator: 'Do we want to include the denominator?',
  relatedTasksTaskId: 'Task to pull data from',
  sequenceId: 'Sequence ID',
  shiftId: 'Associated Shift',
  taskContainerId: 'Task Container (if applicable)',
  title: 'Title',
  type: 'Task Type',
  videoURL: 'Example Video of Task',
};

export function init({ values = {} }) {
  return new InShiftTaskForm({ fields, labels, rules, values });
}
