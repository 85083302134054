import _ from 'lodash';
import { action } from 'mobx';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';

const log = getChildLogger('forms.userOnboarding');

export class UserOnboardingForm extends Form {
  hooks = () => ({
    async onSuccess(form) {
      const formValues = form.values();
      const companyOnboarding = await dispatch(
        'companyOnboardings.get',
        formValues.companyOnboarding,
      );
      const data = {
        autoAssignShiftOnComplete: companyOnboarding.autoAssignShiftOnComplete,
        company: companyOnboarding.company,
        companyOnboardingId: formValues.companyOnboarding,
        positionId: companyOnboarding.positionId,
        userId: formValues.userId,
      };
      if (!_.isEmpty(companyOnboarding.attributeQuestions?.selected)) {
        _.merge(data, {
          attributeQuestionsStatus: 'incomplete',
        });
      }
      const query = {
        $client: {
          populateCompany: true,
          populateCompanyOnboarding: true,
        },
        userId: formValues.userId,
      };
      dispatch('userOnboardings.create', {
        data,
      })
        .then(() => dispatch('userOnboardings.find', query))
        .then(() => dispatch('ui.userOnboardingModal.open', false))
        .then(() => dispatch('ui.snackBar.open', 'User Onboarding Updated'))
        .then(() => form.clear())
        .catch((err) => {
          log.error('Saving user onboarding failed: ', err);
          form.invalidate(err.message);
          dispatch('i.snackbar.open', err.message);
        });
    },
  });
}

const fields = [
  'uuid',
  'companyOnboarding',
  'userId', // note: changed from `user` --> `userId`
];

const rules = {
  companyOnboarding: 'required|string',
  userId: 'required|string',
  uuid: 'string',
};

const labels = {
  companyOnboarding: 'Company Onboardings',
};

export function init(initialValues = {}) {
  log.debug('Creating User Onboarding Form from Keys: ', _.keys(initialValues));

  const values = _.pick(initialValues, ['uuid', 'companyOnboarding', 'userId']);

  const form = new UserOnboardingForm({ fields, labels, rules, values });

  log.debug('Created User Onboarding Form with Values: ', form.values());
  return form;
}
