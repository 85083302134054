import type {
  ICompanyOnboardingSubstep,
  IUserOnboarding,
} from '@shiftsmartinc/shiftsmart-types';

import { action, computed, observable, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import moment from 'moment';
import _ from 'lodash';
import Promise from 'bluebird';

import { getChildLogger } from '#/shared/utils/client.logger';

import BaseStore from './_baseStore';

const log = getChildLogger('store.userOnboardings');

export default class UserOnboardingsStore extends BaseStore<IUserOnboarding> {
  constructor() {
    super({
      baseItem: UserOnboardingsStore.BASE_ITEM,
      serviceName: 'userOnboardings',
    });

    return this;
  }

  static BASE_ITEM = {
    ...BaseStore.BASE_ITEM,
    company: null,
    companyOnboardingId: null,
    createdAt: null,
    positionId: null,
    status: null,
    stepStatus: null,
    updatedAt: null,
    userId: null,
    uuid: null,
  };

  @observable
  userNote = '';

  @observable
  selectedUsers = [];

  @observable
  selectAllPartners = false;

  @computed
  get allPartnersSelected() {
    return this.selectAllPartners;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value = '') {
    _.set(this, key, value);
  }

  @computed
  get allUsersSelected() {
    return (
      (_.size(this.selectedUsers) === _.size(this.list) ||
        _.size(this.selectedUsers) === this.pagination.total) &&
      _.size(this.selectedUsers) > 0
    );
  }

  @action
  selectUser({ userOnboardingItem, checked }) {
    if (checked) {
      this.selectedUsers.push(userOnboardingItem);
    } else {
      this.selectedUsers = _.filter(
        this.selectedUsers,
        (s) => s.uuid !== userOnboardingItem.uuid,
      );
    }
  }

  @observable allUsersPerPageSelected = [];

  @observable cacheAllUsers = [];

  @action
  async loadAllSelectedUsers() {
    if (!this.selectAllPartners) {
      // check if cachinng already exists
      if (this.cacheAllUsers.length > 0) {
        runInAction(() => {
          this.selectedUsers = this.cacheAllUsers;
          this.set('selectAllPartners', !this.selectAllPartners);
        });
        return;
      }
      let users = this.selectedUsers;
      const { onboardingId, stepId } =
        this.getOnboardingIdAndStepIdFromSelectedUsers(users);
      if (!stepId || !onboardingId) {
        return;
      }
      const newSelectedUsers = await this.getAllPartnersSelected(
        stepId,
        onboardingId,
      );
      users = newSelectedUsers;
      this.allUsersPerPageSelected = users;
      runInAction(() => {
        this.selectedUsers = users;
        this.selectAllPartners = !this.selectAllPartners;
        this.cacheAllUsers = users; // caching to prevent multiple requests
      });
    } else {
      runInAction(() => {
        // this.list contains the list of users per page
        this.selectedUsers = this.list;
        this.selectAllPartners = !this.selectAllPartners;
      });
    }
  }

  @action
  selectAllUsers() {
    if (this.allUsersSelected) {
      this.selectedUsers = [];
    } else {
      this.selectAllPartners = false;
      this.selectedUsers = _.clone(this.list);
    }
  }

  @action
  excludeUser(worker) {
    _.remove(this.list, (ub) => ub.workerDetails.uuid === worker.uuid);
    _.remove(this.selectedUsers, (u) => u?.workerDetails?.uuid === worker.uuid);
  }

  @action
  clearSelectedUsers = () => {
    this.cacheAllUsers = [];
    this.selectAllPartners = false;
    this.selectedUsers = [];
  };

  @action updateStepCount(onboardingId, stepId, count) {
    dispatch('companyOnboardings.updateCurrentOnboardingCount', {
      count,
      onboardingId,
      stepId,
    });
  }

  @action
  async advanceUser({ worker, substeps }) {
    const existingCerts = new Set(worker?.certs?.map((c) => c.uuid));
    let certIds = substeps
      .filter((s) => !existingCerts.has(s.stepStatusTags.endTag))
      .map((s) => s.stepStatusTags.endTag);
    certIds = _.concat(
      certIds,
      substeps
        .filter(
          (s) =>
            s.stepStatusTags?.startTag &&
            !existingCerts.has(s.stepStatusTags.startTag),
        )
        .map((s) => s.stepStatusTags.startTag),
    );
    if (!_.isEmpty(certIds)) {
      try {
        const [certs, quals] = await Promise.all([
          dispatch('certs.find', {
            query: { uuid: { $in: certIds } },
          }),
          dispatch('quals.find', {
            query: { uuid: { $in: certIds } },
          }),
        ]);
        const tags = _.concat(certs, quals);
        if (_.isEmpty(tags)) {
          return null;
        }
        await dispatch('workers.update', {
          data: { $push: { certs: { $each: tags } } },
          id: worker.uuid,
        });
        this.excludeUser(worker);
        return worker;
      } catch (error) {
        log.error('Error while advancing user', error);
        dispatch(
          'ui.snackBar.error',
          `Error while advancing user: ${error.message}`,
        );
      }
    }
    return null;
  }

  @action
  async openBulkMessagesModal({
    selectedUsers,
  }: {
    selectedUsers: IUserOnboarding[];
  }) {
    if (_.isEmpty(selectedUsers)) {
      return;
    }

    dispatch('ui.messagePartnersModal.setup', {
      open: true,
    });
    dispatch(
      'ui.messagePartnersModal.set',
      'userList',
      _.map(selectedUsers, 'workerDetails'),
    );
    dispatch(
      'ui.messagePartnersModal.set',
      'totalUsers',
      _.map(selectedUsers, 'workerDetails')?.length || 0,
    );
  }

  getOnboardingIdAndStepIdFromSelectedUsers(selectedUsers: IUserOnboarding[]) {
    const firstUser = _.first(selectedUsers);
    const onboardingId = firstUser?.companyOnboardingId;
    const stepId = firstUser?.stepStatus?.stepId;
    return { onboardingId, stepId };
  }

  @action
  async advanceSelectedUsers({
    selectedUsers,
    substeps,
  }: {
    selectedUsers: IUserOnboarding[];
    substeps: ICompanyOnboardingSubstep[];
  }) {
    if (_.isEmpty(selectedUsers)) {
      return;
    }
    const { onboardingId, stepId } =
      this.getOnboardingIdAndStepIdFromSelectedUsers(selectedUsers);

    const workers = await dispatch(
      'workers.find',
      { query: { uuid: { $in: _.map(selectedUsers, 'workerDetails.uuid') } } },
      {
        select: false,
      },
    );
    const updatedWorkers = await Promise.map(
      workers,
      async (worker) => await this.advanceUser({ substeps, worker }),
      { concurrency: 50 },
    );

    const count = _.size(_.compact(updatedWorkers));
    this.updateStepCount(onboardingId, stepId, count);
    runInAction(() => {
      this.selectedUsers = [];
    });
  }

  @action
  async bulkCreate({ userIds, companyOnboarding }) {
    dispatch('ui.loadingModal.open', {
      message: 'Create Onboardings',
      total: _.size(userIds),
    });
    const createdOnboardings = _.compact(
      await Promise.map(
        userIds,
        async (userId) => {
          dispatch('ui.loadingModal.increment');

          try {
            const data = {
              company: companyOnboarding.company,
              companyOnboardingId: companyOnboarding.uuid,
              positionId: companyOnboarding.positionId,
              userId,
            };
            return await dispatch('userOnboardings.create', { data });
          } catch (error) {
            log.error('Error while bulk creating user', error);
            return null;
          }
        },
        { concurrency: 10 },
      ),
    );
    dispatch('ui.loadingModal.close', {
      delay: 3000,
      message: `Onboarding Assignment Complete: ${_.size(
        createdOnboardings,
      )} out of ${_.size(userIds)} onboardings created.`,
    });
  }

  @action
  setUserNote(value) {
    this.userNote = value;
  }

  @action
  async saveUserNote({ user, author }) {
    if (!user || !author) return;
    try {
      const note = {
        action: 'note',
        actionUserId: author.uuid,
        createdAt: moment().toDate(),
        description: this.userNote,
      };
      await dispatch('userOnboardings.update', {
        data: {
          $push: { activityLog: note },
        },
        id: user.uuid,
      });
    } catch (error) {
      log.error('Error while adding note', error);
      return;
    }
    runInAction(() => {
      this.userNote = '';
    });
  }

  @action
  getExportHeaders = () => [
    {
      label: 'UUID',
      value: 'uuid',
    },
    {
      label: 'User Name',
      value: (row) => row?.workerDetails?.displayName,
    },
    {
      label: "User's Uuid",
      value: (row) => row?.workerDetails?.uuid,
    },
    {
      label: 'Onboarding ID',
      value: 'companyOnboardingId',
    },
    {
      label: 'Status',
      value: 'status',
    },
    {
      label: 'Current step id',
      value: 'stepStatus.stepId',
    },
  ];

  @action
  async getAllPartnersSelected(stepId, companyOnboardingId) {
    try {
      return (
        await this.runQuery({
          query: {
            $client: {
              filterUserOnboardings: true,
            },
            $limit: this.pagination.total,
            companyOnboardingId,
            'stepStatus.stepId': stepId,
          },
        })
      ).data;
    } catch (e) {
      log.error('Error in fetching data in getAllPartnersSelected', e);
      return [];
    }
  }
}
