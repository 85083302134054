import _ from 'lodash';
import moment from 'moment';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';
import { mapValuesToFields } from './formUtils';

const log = getChildLogger('forms.thing');

export class ThingForm extends Form {
  hooks = () => ({
    onSuccess(form) {
      const storeAction = form.values().uuid
        ? 'things.update'
        : 'things.create';

      dispatch(storeAction, { data: form.values() })
        .then(() => dispatch('ui.thingModal.open', false))
        .then(() => dispatch('ui.snackBar.open', 'Task Saved.'))
        .then(() => form.clear())
        .then(() => dispatch('ui.thingModal.clear'))
        .catch((err) => {
          log.error('Saving Task Failed: ', err);
          form.invalidate(err.message);
          dispatch('ui.snackBar.open', err.message);
        });
    },
  });
}

export const fields = {
  company: {
    rules: 'string',
  },
  customerName: {
    label: 'Customer Name',
    rules: 'string',
  },
  date: {
    label: 'Date',
    rules: 'required|date',
  },
  description: {
    label: 'Description',
    rules: 'required|string|min:5',
  },
  duration: {
    label: 'Duration (hours)',
    rules: 'numeric|max:48',
  },
  end: {
    label: 'End Time',
    rules: 'date',
  },
  isWindow: {
    label: 'Time Window',
    rules: 'boolean',
    value: false,
  },
  marketingCode: {
    label: 'Marketing Code (Optional)',
    rules: 'string',
  },
  phone: {
    label: 'Phone Number',
    rules: 'string',
  },
  primaryContact: {
    label: 'Primary Contact',
    rules: 'string',
  },
  rate: {
    label: 'Pay per Hour',
    rules: 'numeric|min:0',
  },
  slots: {
    label: '# of Workers',
    rules: 'required|numeric|min:1',
    value: 1,
  },
  start: {
    label: 'Start Time',
    rules: 'date',
  },
  title: {
    label: 'Title',
    rules: 'required|string|between:5,100',
  },
  uuid: {
    rules: 'string',
  },
};

export function initThingForm(values = {}, opts = { src: null }) {
  const fieldDef = _.cloneDeep(fields);
  const initialValues = _.cloneDeep(values);
  const isTemplate = /template/i.test(opts.src);

  log.debug('Creating Thing from base %s', opts.src);

  if (!isTemplate && initialValues.start && !initialValues.date) {
    initialValues.date = moment(initialValues.start).toDate();
  } else if (!initialValues.date) {
    initialValues.date = moment().toDate();
  }

  log.debug('Creating Thing Form from Keys: ', _.keys(initialValues));

  const fieldVals = mapValuesToFields(fieldDef, initialValues, isTemplate, {
    roundMinutes: 15,
  });

  const form = new ThingForm({ fields: fieldVals });
  log.debug('Created Thing Form with Values: ', form.values());

  return form;
}
