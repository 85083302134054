import React, { ReactElement, SyntheticEvent, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Modal,
  Popup,
  StrictButtonProps,
  StrictModalProps,
} from 'semantic-ui-react';
import _ from 'lodash';

import GenericModal from '#/ssm-ui/generics/GenericModal';

const PopupConfirmButton: React.FC<
  {
    confirmButtonProps?: StrictButtonProps;
    confirmMessage?: string;
    confirmText?: string;
    disabled?: boolean;
    /** Render the popup confirm as a Modal, not a popup */
    modal?: boolean | StrictModalProps;
    on?: 'click' | 'hover' | 'focus';
    onConfirm?: (e: SyntheticEvent) => void;
    position?:
      | 'top center'
      | 'top left'
      | 'top right'
      | 'bottom right'
      | 'bottom left'
      | 'right center'
      | 'left center'
      | 'bottom center';
    trigger?: ReactElement;
  } & StrictButtonProps
> = observer(
  ({
    on = 'click',
    position = 'top center',
    disabled,
    confirmText = 'Confirm',
    onConfirm,
    trigger: srcTrigger,
    confirmMessage,
    confirmButtonProps,
    modal,
    ...rest
  }) => {
    let trigger = srcTrigger ?? (
      <Button
        {...{
          disabled,
        }}
        {...rest}
        {...(modal
          ? {
              onClick: () => setModalIsOpen(true),
            }
          : {})}
      />
    );

    if (srcTrigger && modal) {
      trigger = React.cloneElement(srcTrigger, {
        onClick: () => setModalIsOpen(true),
      });
    }

    const [isModalOpen, setModalIsOpen] = useState(false);

    if (disabled) return trigger;

    if (modal) {
      return (
        <>
          {trigger}
          <GenericModal
            actions={
              <Button
                {...rest}
                content={confirmText}
                onClick={(e) => {
                  onConfirm?.(e);
                  setModalIsOpen(false);
                }}
                {...(confirmButtonProps ?? {})}
              />
            }
            handleCloseModal={() => setModalIsOpen(false)}
            isModalOpen={isModalOpen}
            modalContent={
              confirmMessage && <div className="ph2 pb2">{confirmMessage}</div>
            }
            noHeader={true}
            {...(!!_.isObject(modal) && { modalProps: modal })}
          ></GenericModal>
        </>
      );
    }

    return (
      <Popup
        content={
          <div className="tc flex-column flex">
            {confirmMessage && <div className="ph2 pb2">{confirmMessage}</div>}
            <Button
              {...rest}
              content={confirmText}
              onClick={onConfirm}
              {...(confirmButtonProps ?? {})}
            />
          </div>
        }
        on={on}
        position={position}
        trigger={trigger}
      />
    );
  },
);

PopupConfirmButton.displayName = 'PopupConfirmButton';

export default PopupConfirmButton;
