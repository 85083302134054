import type { IAddress } from '@shiftsmartinc/shiftsmart-types';

import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import cx from 'classnames';

declare type AddressElementProps = {
  className?: string;
  compact?: boolean;
  data: IAddress['address'];
  fontClass?: string | false;
};

const Address = observer(
  ({
    data = {},
    className,
    compact,
    fontClass = 'lh-title',
  }: AddressElementProps) => {
    const hasAddr = !_(data).values().compact().isEmpty();

    if (hasAddr && _.isString(data)) {
      return <>{data}</>;
    }

    const retval = hasAddr ? (
      <dl className={cx(className, fontClass)}>
        <dd className="ml0">
          {compact
            ? _.compact([data.street1, data.street2]).join(' ')
            : data.street1}
        </dd>
        {!compact && data.street2 && <dd className="ml0">{data.street2}</dd>}
        <dd className="ml0">
          {data.city}, {data.state} {data.zip}
        </dd>
      </dl>
    ) : (
      <span className={cx(className, 'i black-30')}>No Address on File</span>
    );

    return retval;
  },
);

export default Address;

const AltAddress = observer(
  ({
    data = {},
    className,
    compact,
    fontClass = 'lh-title',
  }: AddressElementProps) => {
    const hasAddr = !_(data).values().compact().isEmpty();

    const line2 =
      hasAddr &&
      _.compact([data.city, _.compact([data.state, data.zip]).join(' ')]).join(
        ', ',
      );

    const retval = hasAddr ? (
      <div className={cx(className, fontClass, 'flex-column flex')}>
        <div className="ml0">
          {compact
            ? _.compact([data.street1, data.street2]).join(' ')
            : data.street1}
        </div>
        {!compact && data.street2 && <div className="ml0">{data.street2}</div>}
        {line2 && <div className="ml0">{line2}</div>}
      </div>
    ) : (
      <span className={cx(className, 'i black-30')}>No Address on File</span>
    );

    return retval;
  },
);

const CompactAddress = observer(
  ({ data = {}, className, fontClass = 'lh-title' }: AddressElementProps) => {
    const hasAddr = !_(data).values().compact().isEmpty();

    const line2 =
      hasAddr &&
      _.compact([data.city, _.compact([data.state, data.zip]).join(' ')]).join(
        ', ',
      );

    const retval = hasAddr ? (
      <div className={cx(className, fontClass)}>
        {data.street1} {line2}
      </div>
    ) : (
      <span className={cx(className, 'i black-30')}>No Address on File</span>
    );

    return retval;
  },
);

const getCompactAddressString = ({
  data,
}: Pick<AddressElementProps, 'data'>) => {
  const hasAddr = !_(data).values().compact().isEmpty();

  if (!hasAddr) {
    return null;
  }

  const line2 =
    hasAddr &&
    _.compact([data.city, _.compact([data.state]).join(' ')]).join(', ');

  return _.compact([data.street1, line2]).join(' ');
};

export { AltAddress, CompactAddress, getCompactAddressString };
