import { action, observable, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import moment from 'moment';
import _ from 'lodash';
import flatten from 'flattenjs';

import { init } from '#/shared/forms/task';
import { getChildLogger } from '#/shared/utils/client.logger';
/**
 * Store for UI Modal where user can Add a Sub Task to their Shift
 *
 * @export
 * @class TaskEditModal
 */

export default class TaskEditModal {
  log = getChildLogger('ui.TaskEditModal');

  @observable
  isOpen = false;

  @observable
  company = {};

  @observable
  customer = {};

  @observable
  form = {};

  @observable
  settings = {};

  @action
  async setup({
    task = {},
    company = {},
    customer = {},
    shift = {},
    open = this.isOpen,
  }) {
    const log = this.log.getChildLogger('setup');
    this.isOpen = open; // tmp

    this.company = company;
    this.customer = customer;
    this.shift = shift;

    const start = moment();
    start.minute(_.round(start.minute() / 15.0) * 15.0);

    if (task.uuid) {
      this.form = init(task);
    } else if (this.shift && this.shift.uuid) {
      this.form = init({
        company: this.shift.company,
        date: this.shift.start,
        duration: this.shift.duration,
      });
    } else {
      this.form = init(
        {
          company: this.company,
          end: start.add(4, 'hours').toDate(),
          start: start.toDate(),
        },
        {
          src: 'company',
        },
      );
    }

    if (_.isEmpty(this.company)) {
      await dispatch('companies.get', this.form.values().company)
        .then(
          action((co) => {
            this.company = co;
          }),
        )
        .catch((err) => log.error('Failed to get company', err));
    }

    this.setSettings(_.get(this.company, 'settings.things', {}));

    runInAction(() => {
      this.isOpen = open;
    });
  }

  labels = {
    'estimateDuration.request': 'Request Duration Estimate when Starting Task',
    'estimateDuration.require': 'Require Duration Estimate when Starting Task',
  };

  @action
  setSettings(settings) {
    const flatSettings = _.omitBy(
      flatten.convert(settings),
      (val) => val === null,
    );

    this.settings = _.mapKeys(flatSettings, (val, setting) => {
      const type = _.isBoolean(val) ? 'checkbox' : 'text';
      const key = `settings.${setting}`.replace(/\./g, '~~~');
      const label = this.labels[setting];
      this.form.add(val, { key, type });

      this.form.$(key).set('label', label);
      return key;
    });
  }

  @action
  clear() {
    this.task = {};
    this.shift = {};
    this.customer = {};
    this.settings = {};
    this.company = {};
    // This Throws an error, form is still cleared without this
    // this.form.clear();

    this.isOpen = false;
  }
}
