import type {
  ICompanyOnboardingStep,
  IVideoInterviewQuestion,
} from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { StrictDropdownItemProps } from 'semantic-ui-react';

import { getChildLogger } from '#/shared/utils/client.logger';
import { BackgroundCheckOptions } from '#/utils/backgroundChecks';

import Form from './_.extend';

const log = getChildLogger('forms.companyOnboardingsSteps');

export type SelectedStepModalOptionType = OptionType & { isPageInApp: boolean };

export type OptionType = StrictDropdownItemProps & {
  isPageInApp?: boolean;
  key: string | number;
  skipActionButton?: boolean;
  visible?: boolean;
};

export type ExtraType = {
  'substeps[].actionButtonDestination': { options: OptionType[] };
  'substeps[].actionButtonDestinationType': { options: OptionType[] };
  'substeps[].backgroundCheckType': { options: OptionType[] };
  'substeps[].completionRule': { options: OptionType[] };
  'substeps[].stepStatusTags.startTag': unknown;
};

export class CompanyOnboardingStepForm extends Form {
  hooks = () => ({
    onSuccess() {
      this.log.warn(
        'Form logic is not properly wired into the `onSuccess` hook; This is where to process successful validation after a call to `form.onSubmit`',
      );
    },
  });

  options() {
    return {
      ...super.options,
      retrieveOnlyDirtyValues: false,
    };
  }

  setRulesForSubstep({ step, i }) {
    // Require Destination Type if Action Button is defined
    // The `actionButtonDestinationType` is non-clearable
    step
      .$('actionButtonDestinationType')
      .set('rules', `string|required_with:substeps.${i}.actionButton`);
    // Require BG Check Type if the Action Button destination is "backgroundCheck"
    // BG Check Type is populated by default, so this will rarely get hit
    step
      .$('backgroundCheckType')
      .set(
        'rules',
        `string|required_if:substeps.${i}.actionButtonDestination,backgroundCheck`,
      );
    // Require an endTag if the completion rule is set to "tagBased"
    step
      .$('stepStatusTags.endTag')
      .set('rules', `string|required_if:substeps.${i}.completionRule,tagBased`);
  }
}

const fields = [
  'uuid',
  'stepTitle',
  'positionId',
  'substeps',
  'substeps[].uuid',
  'substeps[].title',
  'substeps[].actionButton',
  'substeps[].actionButtonDestinationType',
  'substeps[].actionButtonDestination',
  'substeps[].backgroundCheckType',
  'substeps[].trainingId',
  'substeps[].stepStatusTags',
  'substeps[].completionRule',
  'substeps[].stepStatusTags.endTag',
  'substeps[].stepStatusTags.startTag',
  'substeps[].stepStatusTags.endTagType',
  'substeps[].stepStatusTags.startTagType',
  'substeps[].copy',
  'substeps[].attributeQuestions',
  'substeps[].attributeQuestions.description',
  'substeps[].attributeQuestions.selected',
  'substeps[].attributeQuestions.isRequired',
  'substeps[].videoInterviewQuestionIds',
];

const labels = {
  stepTitle: 'Step Title',

  'substeps[].actionButton': 'Action Button (Optional)',

  'substeps[].actionButtonDestination': 'Action Button Destination',

  'substeps[].actionButtonDestinationType': 'Action Button Type (Required)',

  'substeps[].attributeQuestions.description': 'Description',

  'substeps[].attributeQuestions.isRequired':
    'Partners are required to answer every question, uncheck to make the questions optional',

  'substeps[].backgroundCheckType': 'Background Check Type',

  'substeps[].completionRule': 'Substep completion rule',

  'substeps[].copy': 'Copy',
  // substeps: 'Sub Steps',
  'substeps[].title': 'Substep Title',
  'substeps[].trainingId': 'Company Trainings',

  'substeps[].videoInterviewQuestionIds': 'Video Interview Questions',
};

const rules = {
  stepTitle: 'required|string',
  'substeps[].actionButton': 'string',
  'substeps[].actionButtonDestination': 'string',
  // ActionButtonDestinationType rule Handled Dynaically in init + add due to https://github.com/foxhound87/mobx-react-form/issues/328
  // was:  'required_with:substeps[].actionButton|string',
  'substeps[].actionButtonDestinationType': 'string',

  'substeps[].attributeQuestions.description': 'string',

  'substeps[].attributeQuestions.isRequired': 'boolean',

  'substeps[].completionRule': 'required|string',

  'substeps[].copy': 'required|string|min:5',

  'substeps[].stepStatusTags.endTag': 'string',

  'substeps[].stepStatusTags.rejectionTag[]': '',

  // was:  'required_if:substeps[].completionRule,tagBased|string',
  'substeps[].stepStatusTags.startTag': 'string',

  'substeps[].title': 'required|string|min:3|max:50',

  // 'substeps[].backgroundCheckType': 'string',
  // ActionButtonDestinationType rule Handled Dynaically in init due to https://github.com/foxhound87/mobx-react-form/issues/328
  // was:  'required_if:substeps[].actionButtonDestination,backgroundCheck|string',
  'substeps[].trainingId': 'string',

  'substeps[].videoInterviewQuestionIds': 'array',
};

const actionButtonDestinationTypeOptions: OptionType[] = [
  { key: 0, text: 'Page in app', value: 'pageInApp', visible: true },
  { key: 1, text: 'Webpage', value: 'webpage', visible: true },
  // { key: 2, text: 'Phone Number', value: 'phoneNumber', visible: true },
  {
    key: 3,
    skipActionButton: true,
    text: 'Holding Step',
    value: 'holding',
    visible: true,
  },
];
const actionButtonDestinationOptions: OptionType[] = [
  // { key: 0, text: 'Get Paid Now', value: 'getPaidNow', visible: true },
  // { key: 1, text: 'Select a Shift', value: 'selectShift' },
  // { key: 2, text: 'Shift Details', value: 'shiftDetails' },
  {
    key: 3,
    text: 'Complete Trainings',
    value: 'completeTrainings',
    visible: true,
  },
  {
    key: 4,
    text: 'Video Interview',
    value: 'onboardingVideoQuestions',
    visible: true,
  },
  // {
  //   key: 5,
  //   text: 'Video Interview Optional',
  //   value: 'onboardingVideoQuestionsOptional',
  //   visible: true,
  // },
  // { key: 5, text: 'Screening Call Page', value: 'screeningCallPage' },
  // { key: 6, text: 'Payment History', value: 'paymentHistory', visible: true },
  // { key: 7, text: 'Payment Setup', value: 'paymentSetup', visible: true },
  // attributes is set further down behind a flag.
  {
    key: 9,
    text: 'Background Check',
    value: 'backgroundCheck',
    visible: true,
  },
  // { key: 10, text: 'W2', value: 'w2', visible: false },
];

const completionRuleOptions: OptionType[] = [
  { key: 'tagBased', text: 'Tag based', value: 'tagBased' },
  { key: 'automatic', text: 'Automatic', value: 'automatic' },
  { key: 'manual', text: 'Manual', value: 'manual' },
];

const observers = {
  dataType: [
    {
      call: ({ form, change }) => {
        const range = form.$('searchOptions.range');

        if (/percent/i.test(change.newValue)) {
          range.set({ max: 100, min: 0 });
        }
      },
      key: 'value',
    },
  ],
  substeps: [
    {
      call: ({ form, change }) => {
        if (_.size(change.newValue) > _.size(change.oldValue)) {
          const index = _.size(change.newValue) - 1;
          const step = form.$(`substeps.${index}`);

          form.setRulesForSubstep({ i: index, step });
        } else {
          log.debug('harumph', { change });
        }
      },
      key: 'value',
    },
  ],
};

declare type OnboardingStepFormOpts = {
  index: number;
  isPartnerAttributeCollectionEnabled: boolean;
  videoInterviewQuestions: IVideoInterviewQuestion[];
};

export const getExtra = (cxOptions?: OnboardingStepFormOpts) => {
  const { isPartnerAttributeCollectionEnabled } = cxOptions ?? {};

  const destinationOptions = _.cloneDeep(
    actionButtonDestinationOptions.filter((a) => a.visible),
  );
  if (isPartnerAttributeCollectionEnabled) {
    destinationOptions.push({
      key: 8,
      text: 'Attributes',
      value: 'attributeQuestions',
      visible: true,
    });
  }

  const extra: ExtraType = {
    'substeps[].actionButtonDestination': { options: destinationOptions },
    'substeps[].actionButtonDestinationType': {
      options: actionButtonDestinationTypeOptions,
    },
    'substeps[].backgroundCheckType': { options: BackgroundCheckOptions },
    'substeps[].completionRule': { options: completionRuleOptions },
    'substeps[].stepStatusTags.startTag': {},
  };
  return extra;
};

export function init(
  values?: Partial<ICompanyOnboardingStep>,
  cxOptions?: OnboardingStepFormOpts,
) {
  const { ...opts } = cxOptions ?? {};

  const extra = getExtra(cxOptions);
  const vals = values?.uuid ? values : { ...(values ?? {}) };

  const form = new CompanyOnboardingStepForm(
    {
      extra,
      fields,
      labels,
      observers,
      rules,
      values: vals,
    },
    {
      ...opts,
      options: {},
    },
  );
  form.$('substeps').map((step, i) => form.setRulesForSubstep({ i, step }));

  return form;
}
