import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';
import _ from 'lodash';
import cx from 'classnames';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('utils.GoogleAutocomplete');

const AutocompleteItem = ({ formattedSuggestion }) => (
  <div className="tl pa2">
    <Icon name="map marker alternate" />
    <strong>{formattedSuggestion.mainText}</strong>{' '}
    <small className="text-muted">{formattedSuggestion.secondaryText}</small>
  </div>
);

const encodeAddressData = (addressDetails) => {
  if (_.isEmpty(addressDetails)) {
    return false;
  }
  const loc = {
    coordinates: [
      addressDetails.geometry.location.lng(),
      addressDetails.geometry.location.lat(),
    ],
    type: 'Point',
  };

  const address = {};
  // eslint-disable-next-line
  for (const addressComponent of addressDetails.address_components) {
    const addressType = addressComponent.types[0];
    switch (addressType) {
      // City
      case 'locality':
        address.city = addressComponent.long_name;
        break;

      // Zip
      case 'postal_code':
        address.zip = addressComponent.long_name;
        break;

      // State
      case 'administrative_area_level_1':
        address.state = addressComponent.short_name;
        break;

      // Street Number
      case 'street_number':
        address.streetNumber = addressComponent.long_name;
        break;

      // Street Name
      case 'route':
        address.streetName = addressComponent.long_name;
        break;

      default:
        break;
    }
  }

  address.street1 = _.compact([address.streetNumber, address.streetName]).join(
    ' ',
  );
  delete address.streetNumber;
  delete address.streetName;

  return {
    address,
    formattedAddress: addressDetails.formatted_address,
    loc,
    placeId: addressDetails.place_id,
  };
};

export const GoogleAutocomplete = observer(
  ({
    className,
    handleSelect,
    placeholder = 'Search Address',
    semantic,
    value,
  }: {
    className: string;
    handleSelect: (args) => void;
    placeholder?: string;
    semantic: boolean;
    value: { [key: string]: string };
  }) => {
    const [address, setAddress] = useState(value);
    const [geocodeReults, setGeocodeReults] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (value) {
        setAddress(_.isString(value) ? value : value.formattedAddress);
      } else {
        setAddress('');
      }
    }, []);

    const handleAddressSelect = (address) => {
      setAddress(address);
      setLoading(true);

      geocodeByAddress(address)
        .then((results) => {
          const addressModel = encodeAddressData(results[0]);
          handleSelect(addressModel);
          setLoading(false);
        })
        .catch((error) => {
          log.error('Error geocoding address', error);
          setLoading(false);
        });
    };

    const handleChange = (address) => {
      setAddress(address);
      setGeocodeReults(null);
    };

    const inputProps = {
      onChange: handleChange,
      placeholder: value || placeholder,
      type: 'text',
      value: address,
    };

    let cssClasses = {
      autocompleteContainer: 'z-100',
      input: 'pb2 pt3 bb b--light-silver bt-0 br-0 bl-0 w-100',
    };

    if (semantic) {
      cssClasses = {
        autocompleteContainer: 'z-100',
        input: 'w-100 pv2 ph3 ba b--light-gray br2',
      };
    }

    return (
      <div className={cx('ssm-places-autocomplete', className)}>
        <PlacesAutocomplete
          autocompleteItem={AutocompleteItem}
          classNames={cssClasses}
          inputProps={inputProps}
          onEnterKeyDown={handleAddressSelect}
          onSelect={handleAddressSelect}
        />
      </div>
    );
  },
);

GoogleAutocomplete.displayName = 'GoogleAutocomplete';
export default GoogleAutocomplete;
