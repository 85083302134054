import { action, observable, runInAction, set } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { init as initAgentForm } from '#/shared/forms/companyAgent';
import { init as initCustomMetricsForm } from '#/shared/forms/customMetricConfig';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.manageCompany');

export default class ManageCompany {
  @observable
  isAgentFormOpen = false;

  @observable
  confirmRemovalOpts = {};

  // instance of Company Agent Form
  @observable
  agentForm = null;

  // instance of Company Custom Metrics Form
  @observable
  customMetricsForm = null;

  @observable
  company = {};

  @observable
  userRolesOptions = [];

  @observable
  agentTypeOptions = [];

  @observable
  agentFilters = { status: 'all' };

  @observable
  agentQueryStatus = [];

  @observable
  isLoadingDetailsPage = false;

  @action.bound
  setIsLoading(val) {
    this.isLoadingDetailsPage = val;
  }

  @action async setup({ company }) {
    this.clear();

    if (_.isEmpty(company) || _.isString(company)) {
      const err = new Error(
        `InvalidParameterType -- \`company\` parameter ${
          _.isEmpty(company) ? 'is required' : 'must be an object'
        }`,
      );
      log.error('Setup Failed', err, { extra: { company } });
    }

    try {
      this.userRolesOptions.replace(dispatch('users.getUserRolesOptions'));
      this.agentTypeOptions.replace(
        dispatch('companies.getAgentTypeOptions', {
          company,
        }),
      );

      set(
        this.company,
        _.isString(company)
          ? await dispatch('companies.get', company)
          : company,
      );
    } catch (err) {
      log.error('Failed to load agents', err, {
        extra: { company: _.get(company, 'uuid', company) },
      });
    }

    return null;
  }

  @action async findAgents(query = {}) {
    const baseQuery = _.isUndefined(query.query) ? { query } : query;
    if (Object.keys(this.company).length !== 0) {
      await dispatch('companies.loadAgents', {
        company: this.company,
        query: baseQuery,
        roles: _.map(this.agentTypeOptions, 'key'),
        status: this.agentQueryStatus,
      });
    }
  }

  @action
  editAgent({
    user = {},
    csr = _.find(user.companyStatus, { company: this.company.uuid }),
  }) {
    this.agentForm = initAgentForm({
      opts: {
        fields: {
          companyRoles: true,
          roles: false,
        },
      },
      values: {
        ...user,
        company: this.company,
        companyRoles: _.get(csr, 'roles', []),
        route: _.get(csr, 'route'),
        status: _.get(csr, 'status'),
      },
    });

    this.isAgentFormOpen = true;
  }

  @action
  selectUserAsAgent({ user = {} }) {
    const csr = _.find(user.companyStatus, { company: this.company.uuid });
    const vals = {
      ...user,
      companyRoles: _.union(csr?.roles ?? [], ['agent']),
    };
    this.agentForm.set(vals);
    log.debug('Form set with new values', {
      src: vals,
      values: this.agentForm.values(),
    });
  }

  @action
  toggleAgentForm(val = !this.isAgentFormOpen) {
    if (this.agentForm) {
      this.agentForm.clear();
    }

    if (val) {
      this.agentForm = initAgentForm({
        values: { company: this.company.uuid, requireMFA: true },
      });
    }

    this.isAgentFormOpen = val;
  }

  @action
  toggleRemoveAgentConfirmation(arg = {}) {
    if (!arg || _.isEmpty(arg)) {
      this.confirmRemovalOpts = {};
    } else {
      set(this.confirmRemovalOpts, {
        csr: arg.csr,
        isOpen: true,
        user: arg.user,
      });
    }
  }

  @action
  async confirmAgentStatusChange({ status = 'former' } = {}) {
    if (
      _(this.confirmRemovalOpts)
        .omitBy((val) => !val || _.isEmpty(val))
        .isEmpty()
    ) {
      return false;
    }

    const { user, csr } = this.confirmRemovalOpts;

    const userId = user.uuid;
    const query = {
      companyStatus: {
        $elemMatch: {
          company: csr.company,
        },
      },
    };

    try {
      await dispatch('employerUsers.update', {
        data: {
          'companyStatus.$.status': status,
        },
        id: userId,
        query,
      });

      runInAction(() => {
        this.confirmRemovalOpts = {};
      });

      return true;
    } catch (err) {
      log.error('Failed to remove company agent');
      dispatch('ui.snackBar.error', 'Sorry, an error occurred', {
        body: err.message,
      });
    }

    return false;
  }

  @action
  customMetricUpdated(/* value */) {
    dispatch('ui.snackBar.open', `Saved Custom Metric`);

    this.toggleEditCustomMetrics(false);
  }

  @action
  toggleEditCustomMetrics(values, opts) {
    if (this.customMetricsForm) {
      this.customMetricsForm.clear();
    }

    this.customMetricsForm = values
      ? initCustomMetricsForm(values, opts)
      : null;
  }

  @action
  clear() {
    this.isLoadingDetailsPage = false;

    this.confirmRemovalOpts = {};

    this.toggleAgentForm(false);
    set(this.company, {});
  }

  @action
  async filterAgentsBy(filter, { param = 'status', noQuery = false } = {}) {
    this.agentFilters[param] = filter;

    if (param === 'status') {
      switch (filter) {
        case 'pending':
          this.agentQueryStatus = 'pending';
          break;
        case 'active':
          this.agentQueryStatus = 'active';
          break;
        case 'former':
          this.agentQueryStatus = { $in: ['former', 'inactive'] };
          break;
        case 'all':
          this.agentQueryStatus = {
            $in: ['former', 'inactive', 'active', 'pending'],
          };
          break;
        default:
          this.agentQueryStatus = filter;
          break;
      }
    }

    if (noQuery) {
      return null;
    }

    return this.findAgents();
  }
}
