import type { IShift, ICompany } from '@shiftsmartinc/shiftsmart-types';

import { action, observable, computed, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import moment from 'moment';
import { StrictDropdownItemProps } from 'semantic-ui-react';

import {
  init as initForm,
  formatDuration,
  ShiftForm,
} from '#/shared/forms/shiftNextGen';
import { formatAddress } from '#/utils/formatting';
import {
  getGeneratedSmartTitle,
  validateFlexibleShiftWorkingHours,
  validateShiftDuration,
} from '#/shared/utils/shifts';
import { getNotificationPrefsData } from '#/utils/notificationPrefs';
import { getChildLogger } from '#/shared/utils/client.logger';

type ShiftFormNextGenShift = Partial<IShift> & {
  addressObj?: any;
  locationType?: 'custom' | 'home' | 'site' | 'remote';
};

export default class ShiftFormNextGen {
  log = getChildLogger('ui.ShiftFormNextGen');

  @observable
  isLoading = false;

  @observable
  confirmationModalVisible = false;

  @observable
  company: ICompany = null;

  @observable
  shift: ShiftFormNextGenShift = {};

  @observable
  form: ShiftForm = null;

  @observable
  positionSelectMode = 'list';

  @observable
  isAutoTitle = false;

  @observable
  originalAddressObj = null;

  @observable isShiftTemplate = false;

  @observable isBulkEditShiftTemplates = false;

  @observable shiftTemplates = [];

  @action.bound
  setLoading(loading) {
    this.isLoading = loading;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action.bound
  set(item, value) {
    _.set(this, item, value);
  }

  @action
  getIsBulkEditShiftTemplates() {
    return this.isBulkEditShiftTemplates;
  }

  @action
  getIsShiftTemplate() {
    return this.isShiftTemplate;
  }

  @action
  setIsShiftTemplate(isTemplate) {
    this.isShiftTemplate = isTemplate;
    dispatch('ui.shiftsNextGen.loadSitesForShiftTemplates');
  }

  @action
  setIsBulkEditShiftTemplates(value) {
    this.isBulkEditShiftTemplates = value;
  }

  @computed
  get steps() {
    return _.compact([
      {
        text: 'Shift Information',
        value: 'core',
      },
      {
        text: 'Add Partners',
        value: 'invites',
      },
      {
        text: 'Set Preferences',
        value: 'advanced',
      },
    ]);
  }

  @computed
  get isPastShift() {
    const endField = this.form?.select?.('end', null, false);
    return !!endField?.value && moment().isAfter(moment(endField.value));
  }

  approvalTypeOptions = [
    {
      key: 0,
      text: 'Invite to Work + No Approval',
      value: 'invite',
      // image: {
      //   src: '/static/img/icon-manager.png',
      // },
    },
    {
      key: 1,
      text: 'Invite to Work + My Approval Required',
      value: 'approvalRequired',
    },
  ];

  @observable locationTypes = {
    custom: true,
    home: false,
    remote: false,
    site: false,
  };

  @action
  setLocationTypeOptions(locationTypes) {
    this.locationTypes = locationTypes;
  }

  @computed get locationTypeOptions(): Array<
    StrictDropdownItemProps & { value: 'custom' | 'home' | 'site' | 'remote' }
  > {
    return _.compact([
      this.locationTypes.site && {
        key: 'site',
        text: 'Saved Location',
        value: 'site',
      },
      this.locationTypes.custom && {
        key: 'custom',
        text: 'Custom Address',
        value: 'custom',
      },
      this.locationTypes.home && {
        key: 'home',
        text: 'Primary Location',
        value: 'home',
      },
      this.locationTypes.remote && {
        key: 'remote',
        text: 'Remote Shift',
        value: 'remote',
      },
    ]);
  }

  /**   @deprecated */
  @observable flexibleShiftMinimumDurationOptions = [
    {
      content: '30 Minutes',
      key: '0.5',
      text: '30 Minutes',
      value: 0.5,
    },
    {
      content: '1 hour',
      key: '1',
      text: '1 hour',
      value: 1,
    },
    {
      content: '2 hours',
      key: '2',
      text: '2 hours',
      value: 2,
    },
    {
      content: '3 hours',
      key: '3',
      text: '3 hours',
      value: 3,
    },
    {
      content: '4 hours',
      key: '4',
      text: '4 hours',
      value: 4,
    },
  ];

  @computed get flexibleShiftTimeIntervalOptions() {
    return [
      {
        content: '15 Minutes',
        key: '15',
        text: '15 Minutes',
        value: 15,
      },
      {
        content: '30 Minutes',
        key: '30',
        text: '30 Minutes',
        value: 30,
      },
      {
        content: '1 hour',
        disabled:
          this.form?.$?.('shiftScheduleRules.minHoursDuration').value < 1,
        key: '60',
        text: '1 hour',
        value: 60,
      },
      {
        content: '2 hours',
        disabled:
          this.form?.$?.('shiftScheduleRules.minHoursDuration').value < 2,
        key: '120',
        text: '2 hours',
        value: 120,
      },
    ];
  }

  @observable step = 'core';

  @observable updatedData = {};

  @computed get prevStep() {
    const prevStepIndex =
      this.steps.findIndex((step) => step.value === this.step) - 1;
    return this.steps[prevStepIndex];
  }

  @computed get nextStep() {
    const nextStepIndex =
      this.steps.findIndex((step) => step.value === this.step) + 1;
    return this.steps[nextStepIndex];
  }

  @action.bound updateStep(value) {
    this.step = value;
  }

  @action.bound
  setupBulkEditing() {
    this.shiftTemplates = dispatch(
      'ui.shiftsNextGen.getSelectedShiftTemplates',
    );

    this.shift = _.cloneDeep(this.shiftTemplates[0]);
    runInAction(() => {
      this.originalAddressObj = this.shift.addressObj || this.company?.address;
    });
    const bulkInitData = {
      addressObj: this.shift.addressObj || this.company?.address,
      locationType: this.shift.locationType,
      status: this.shift.status || 'Active',
    };
    runInAction(() => {
      this.form = initForm(bulkInitData, {});
    });
  }

  @action.bound
  async setup({
    shift: inputShift = {},
    company = dispatch('auth.getCompany'),
    preserveNotificationPrefs = false,
    isTemplate = false,
    isBulkEdit = false,
  }: {
    company?: ICompany;
    isBulkEdit?: boolean;
    isTemplate?: boolean;
    preserveNotificationPrefs?: boolean;
    shift?: Partial<IShift>;
  }) {
    const log = this.log.getChildLogger('setup');
    this.company = company;
    this.isShiftTemplate = isTemplate;
    this.isBulkEditShiftTemplates = isTemplate && isBulkEdit;
    if (isBulkEdit) {
      this.setupBulkEditing();
      return;
    }
    const shift: ShiftFormNextGenShift = _.cloneDeep(inputShift);

    const settings = company?.settings;

    const {
      things: {
        enablePartnerCancel = false,
        enableOnsiteCheckinCode = false,
        enableUniqueAttendanceCodes = false,
        defaultPerShiftAttendanceCodes = false,
        defaultSMS = false,
      } = {},
      shifts: {
        disableShiftAcceptAfterStart = false,
        enableRemoteShifts = false,
        defaultIsRemoteShift = false,
        enableFlatRatePay = false,
        defaultIsFlatRatePay = false,
        defaultAutoSmartTitles = false,
        enableSmartTitles = false,
        enableBulkNotifications = false,
      } = {},
      dispatch: { defaultInviteMode = false } = {},
    } = settings || {};

    if (shift?.uuid && enableSmartTitles) {
      const smartTitle = await getGeneratedSmartTitle({
        companyObj: company,
        end: shift?.end,
        role: _.first(shift.search?.tags),
        start: shift?.start,
      });

      runInAction(() => {
        this.isAutoTitle =
          defaultAutoSmartTitles && _.isEqual(smartTitle, shift?.title);
      });
    } else {
      this.isAutoTitle = defaultAutoSmartTitles;
    }

    const useFlatRatePay = defaultIsFlatRatePay && enableFlatRatePay;

    const minWageRate =
      /active|filled/i.test(shift.status) && !shift.useFlatRatePay
        ? _.get(shift, 'rate') || 0 // Because rate is null
        : 0;
    const rules = {
      rate: _.compact([
        minWageRate && 'required',
        'numeric',
        `min:${minWageRate}`,
      ]).join('|'),
    };

    const companyId = company?.uuid;

    const locTypesInit = this.setupLocationTypeOptions({
      companyId,
    });

    const defaultIsRemote = enableRemoteShifts && defaultIsRemoteShift;
    dispatch('positions.findByCompany', {
      company,
      query: { $sort: { count: -1 } },
    });

    dispatch('dispatchPrefs.findByCompany', { company });

    await locTypesInit;
    const locationType =
      shift.locationType ||
      this.getLocationType({
        defaultIsRemote,
        shift: shift,
      });

    // TODO: typeof ShiftNextGenForm.struct ???
    const initData: Partial<IShift & ShiftForm['fields']> = {
      locationType,
      options: {
        inviteMode: defaultInviteMode,
        ...(shift.options ?? {}),
      },
    };

    if (enableOnsiteCheckinCode && !shift.uuid) {
      const enableCheckinCodeByDefault =
        await this.getEnableCheckinCodeByDefault({
          enableCheckinCode: shift?.enableCheckinCode,
        });
      if (_.isBoolean(enableCheckinCodeByDefault)) {
        initData.enableCheckinCode = enableCheckinCodeByDefault;
      }

      if (enableUniqueAttendanceCodes) {
        initData.useUniqueAttendanceCodes = !!defaultPerShiftAttendanceCodes;
      }
    }

    // Setup the form based on the passed-in shift object
    if (!_.isEmpty(shift)) {
      let sendSMS = false;
      if (!shift?.uuid && defaultSMS) {
        sendSMS = defaultSMS;
      }
      // If editing existing shift
      if (shift.uuid) {
        const addressCore =
          _.get(shift.addressObj, 'address') || shift.location;
        const formattedAddress = shift.formattedAddress || shift.address;

        runInAction(() => {
          _.extend(shift, { address: addressCore, formattedAddress });
        });

        const addressObjType = _.get(shift, 'addressObj.__t');
        if (/^site$/i.test(addressObjType)) {
          runInAction(() => {
            this.originalAddressObj = shift.addressObj;
          });
        }
      }
      if (!this.isShiftTemplate) {
        // Parse initial date values
        if (shift.start && shift.end) {
          let { start, end } = shift;
          const timezone = shift.timezone;
          if (timezone) {
            start = moment
              .tz(start, timezone)
              .seconds(0)
              .milliseconds(0)
              .format('YYYY-MM-DDTHH:mm:ss');
            end = moment
              .tz(end, timezone)
              .seconds(0)
              .milliseconds(0)
              .format('YYYY-MM-DDTHH:mm:ss');

            const whStart = shift.shiftScheduleRules?.workingHoursStart;
            const whEnd = shift.shiftScheduleRules?.workingHoursEnd;

            if (whStart) {
              shift.shiftScheduleRules.workingHoursStart = moment
                .tz(whStart, timezone)
                .seconds(0)
                .milliseconds(0)
                .format('YYYY-MM-DDTHH:mm:ss');
            }
            if (whEnd) {
              shift.shiftScheduleRules.workingHoursEnd = moment
                .tz(whEnd, timezone)
                .seconds(0)
                .milliseconds(0)
                .format('YYYY-MM-DDTHH:mm:ss');
            }
          }
          _.extend(shift, { end, start });
        }
      }

      _.extend(shift, {
        company: companyId,
        enablePartnerCancel,
      });

      if (!shift.uuid) {
        _.extend(shift, { disableShiftAcceptAfterStart });
      }

      if (_.get(shift, 'remindInterval', 0) > 0) {
        shift.sendReminder = true;
      }

      if (shift.positionId) {
        shift.search = _.merge(shift.search, {
          tags: [shift.positionId],
        });
      } else if (_.size(shift.search?.tags) === 1) {
        shift.positionId = _.first(shift.search.tags);
      }

      if (_.size(shift?.preShiftSurveyDefinitions) > 0) {
        shift.enableCheckinSurvey = true;
        shift.preShiftSurveyDefinition = _.first(
          shift.preShiftSurveyDefinitions,
        );
      }

      if (_.size(shift?.postShiftSurveyDefinitions) > 0) {
        shift.enableCheckinSurvey = true;
        shift.postShiftSurveyDefinition = _.first(
          shift.postShiftSurveyDefinitions,
        );
      }

      runInAction(() => {
        this.shift = shift;
      });

      const fieldsToOmit = [
        'children',
        'taskType',
        'searchableText',
        'matches',
        'defaultChildren',
        'isWindow',
        'question',
        'statusLog',
        'quickTasks',
        'locations',
        'uploadedAssets',
      ];
      if (!preserveNotificationPrefs) {
        fieldsToOmit.push('notificationPrefs');
      }
      // This modifies the data we init the form with
      // and excludes any data that is not required for the form to function properly
      const formInitData = _.omit(this.shift, fieldsToOmit);
      _.extend(initData, {
        sendSMS,
        ...formInitData,
      });

      if (!this.shift?.uuid && enableBulkNotifications) {
        initData.enableBulkNotifications = enableBulkNotifications;
      }
    } else {
      _.extend(initData, {
        company: companyId,
        disableShiftAcceptAfterStart,
        enableBulkNotifications,
        enablePartnerCancel,
        isRemote: defaultIsRemote,
        sendSMS: defaultSMS,
        useFlatRatePay,
      });
    }

    runInAction(() => {
      log.debug('Initializing Form', { initData });
      this.form = initForm(initData, { rules });
      if (enableSmartTitles) {
        /// used setTimeout to ignore triggering handlers for any initial set field operation after form initialization
        setTimeout(() => {
          this.registerAutoTitleHandler();
        }, 500);
      }
    });
  }

  @action.bound
  registerAutoTitleHandler() {
    const fields = [
      'duration',
      'slots',
      'currency',
      'rate',
      'pay',
      'bonus',
      'shiftScheduleType',
      'address',
      'address.street1',
      'address.street2',
      'address.city',
      'address.state',
      'address.zip',
      'address.formattedAddress',
      'address.loc',
      'addressRef',
      'addressObj',
      'enableCheckinCode',
      'requireCheckinCode',
      'requireCheckoutCode',
      'formattedAddress',
      'isRemote',
      'enableCheckinCode',
      'start',
      'end',
      'positionId',
      'search.tags',
      'useFlatRatePay',
      'timezone',
    ];
    _.forEach(fields, (fieldName) =>
      this.form
        .$(fieldName)
        .observe((args) => this.isAutoTitle && this.setAutoSmartTitle(args)),
    );
  }

  @action.bound
  async setAutoSmartTitle({ form = this.form, field = {}, change = {} } = {}) {
    const log = this.log.getChildLogger('setAutoSmartTitle');
    if (!_.isEmpty(field) || !_.isEmpty(change)) {
      log.silly(
        `Detected change in ${field?.name}: ${change?.oldValue} => ${change?.newValue}`,
      );
    }

    const company = form?.$('company').value;

    const roles =
      _.isEmpty(form.$('search.tags').value) && !form.$('search.tags').isDirty
        ? form.$('search.tags').initial
        : form.$('search.tags').value;

    const start = moment(form.$('start').value).toDate();
    const end = moment(form.$('end').value).toDate();
    const shiftObj = form.values();

    const shiftTitle = await getGeneratedSmartTitle({
      company,
      end,
      role: _.first(roles),
      shiftObj,
      start,
    });
    if (!_.isEqual(shiftTitle, form?.$('title').value)) {
      form.$('title').set(shiftTitle);
      if (!_.isEmpty(field)) {
        log.silly(
          `Updating title after change to ${field?.name}: ${shiftTitle}`,
        );
      }
    }
  }

  @action.bound
  setIsAutoTitle(isAutoTitle: boolean) {
    this.isAutoTitle == isAutoTitle;
  }

  /**
   * ## submitForm
   * Submits the form (triggering validation) and then calling either
   * the form's `onSuccess` or `onError` handler.
   *
   *
   * @returns unknown
   */
  /**
   * submitForm
   * Runs manual validation, and then submits the form.
   *
   * **WARNING** while we do run the validator and should only
   * be running `form.onSubmit` if all validation passes, in the
   * event that `form.onSubmit` fails (for some reason), this method
   * will return `true` regardless. A `true` return value **_DOES NOT_**
   * guarantee that the submission was successful
   *
   * **IMPORTANT** While the initial  awaited or try/catched as it is
   * merely a trigger for additional processing. Likewise the only error
   * you are likely to catch is if the form is undefined.
   *
   * @param {*} e: ReactSyntheticEvent
   * @returns Promise<boolean> Returns _true_ if the form was submitted to the API, DOES NOT indicate the submission was sucessful
   */
  @action.bound
  async submitForm(e): Promise<boolean> {
    const log = this.log.getChildLogger('submitForm');
    if (this.form) {
      try {
        const isValid = await this.validateForm();
        if (isValid) {
          this.form.onSubmit?.(e);

          // FIXME: the `onSubmit` method is not async, and has no return value.
          // We would need to listen for form events to return a valid form-state
          return true;
        }
      } catch (error) {
        log.debug('Form validation error saving shift: ', error);
      }
    }

    log.verbose('Form validation failed');
    return false;
  }

  @action.bound
  async createShiftConfirm() {
    if (this.isLoading) {
      return false;
    }

    const isValid = await this.validateForm();
    if (isValid) {
      this.toggleConfirmationModal();
      return true;
    }

    this.form.hooks().onError();

    return false;
  }

  @action.bound
  toggleConfirmationModal(state = !this.confirmationModalVisible) {
    this.confirmationModalVisible = state;
  }

  /* 2021-10-11 Pavan
   * Adding in hacky manual validation for these fields
   * until we upgrade form library. Tons of issues trying
   * to validate across all of these fields
   */
  @action.bound
  validateFlexibleShiftSettings() {
    const formValues = this.form.values();
    const { start, end, shiftScheduleRules } = formValues;
    const ignoreDateDifference =
      !moment(shiftScheduleRules.workingHoursStart).isSame(
        moment(shiftScheduleRules.workingHoursEnd),
        'day',
      ) && !!this.form.$('uuid').value;
    const {
      newWorkingHoursStartValue,
      newWorkingHoursEndValue,
      flexibleShiftValidationError,
      errorPath,
    } = validateFlexibleShiftWorkingHours({
      ignoreDateDifference,
      shiftEnd: end,
      shiftScheduleRules,
      shiftStart: start,
    });

    if (!_.isEmpty(flexibleShiftValidationError)) {
      this.form.$(errorPath).invalidate(flexibleShiftValidationError);
    } else {
      this.form
        .$('shiftScheduleRules.workingHoursStart')
        .set(newWorkingHoursStartValue);
      this.form
        .$('shiftScheduleRules.workingHoursEnd')
        .set(newWorkingHoursEndValue);
    }

    return flexibleShiftValidationError;
  }

  @action.bound
  validateShiftDuration() {
    const formValues = this.form.values();
    const { start, end } = formValues;
    const validationError = validateShiftDuration({
      end,
      start,
    });
    if (!_.isEmpty(validationError)) {
      this.form.$('end').invalidate(validationError);
    }

    return validationError;
  }

  @action.bound
  async validateShiftTemplateRequiredFields() {
    if (!_.isEmpty(this.form)) {
      const cycleDays =
        (await dispatch('recurringSchedules.getSelected').cycleDays) || 1;
      const startDayOffset = this.form.$('startDayOffset');
      const endDayOffset = this.form.$('endDayOffset');
      const hourStart = this.form.$('hourStart');
      const hourEnd = this.form.$('hourEnd');
      const minutesStart = this.form.$('minutesStart');
      const minutesEnd = this.form.$('minutesEnd');

      // startDayOffset validation
      if (startDayOffset.value === '') {
        startDayOffset.invalidate('Please add a start day');
      } else if (startDayOffset.value < 1 || startDayOffset.value > cycleDays) {
        this.form
          .$('startDayOffset')
          .invalidate(`The value needs to between 1 and ${cycleDays}`);
      }

      // endDayOffset validation
      if (endDayOffset.value === '') {
        endDayOffset.invalidate('Please add an end day');
      } else if (
        endDayOffset.value < (startDayOffset.value || 1) ||
        endDayOffset.value > cycleDays
      ) {
        this.form
          .$('endDayOffset')
          .invalidate(
            `The value needs to between ${
              startDayOffset.value || 1
            } and ${cycleDays}`,
          );
      }

      // hourStart validation
      if (hourStart.value === '') {
        hourStart.invalidate('Please add an start hour');
      } else if (hourStart.value < 1 || hourStart.value > 24) {
        this.form
          .$('hourStart')
          .invalidate(`The value needs to between 1 and 24`);
      }

      // hourEnd validation
      const minHourValue =
        startDayOffset.value === endDayOffset.value ? hourStart.value : 1;
      if (hourEnd.value === '') {
        hourEnd.invalidate('Please add an end hour');
      } else if (hourEnd.value < minHourValue || hourEnd.value > 24) {
        this.form
          .$('hourEnd')
          .invalidate(`The value needs to between ${minHourValue} and 24`);
      }

      // minutesStart validation
      if (minutesStart.value === '') {
        minutesStart.invalidate('Please add start time minutes');
      } else if (minutesStart.value < 0 || minutesStart.value > 59) {
        this.form
          .$('minutesStart')
          .invalidate(`The value needs to between 0 and 59`);
      }

      // minutesEnd validation
      const minMinutesValue =
        startDayOffset.value === endDayOffset.value &&
        hourStart.value === hourEnd.value
          ? minutesStart.value
          : 0;
      if (minutesEnd.value === '') {
        minutesEnd.invalidate('Please add end time minutes');
      } else if (minutesEnd.value < minMinutesValue || minutesEnd.value > 59) {
        this.form
          .$('minutesEnd')
          .invalidate(`The value needs to between ${minMinutesValue} and 59`);
      }
    }
  }

  @action.bound
  async validateForm() {
    const log = this.log.getChildLogger('validateForm');
    try {
      const result = await this.form.validate({ showErrors: true });
      if (this.isShiftTemplate) {
        await this.validateShiftTemplateRequiredFields();
      }
      // TODO: Result should be `{ isValid }`, but is coming back as straight boolean :hmmm: :rage:
      const isValid = result?.isValid ?? result;
      if (isValid) {
        if (!this.isShiftTemplate) {
          // additional validation to ensure that shift time doesn't exceed 48 hours
          const durationValidationError = this.validateShiftDuration();
          if (!_.isEmpty(durationValidationError)) {
            return false;
          }
        }

        // additional validation for flexible shifts
        if (/flexible/i.test(this.form.$('shiftScheduleType').value)) {
          const flexibleShiftValidationError =
            this.validateFlexibleShiftSettings();
          if (!_.isEmpty(flexibleShiftValidationError)) {
            // TODO: Does form need to be invalidated in addition to the field
            this.form.invalidate(flexibleShiftValidationError);
            return false;
          }
        }
      }
      return !!isValid;
    } catch (err) {
      log.error('caught error while validating result', err);
    }

    return false;
  }

  @action.bound
  sendDetailsUpdateNotification() {
    return this.showPartnerNotifyWarning;
  }

  // Whether to show the warning message stating partners will be notified
  @computed
  get showPartnerNotifyWarning() {
    const log = this.log.getChildLogger('showPartnerNotifyWarning');
    if (
      !this.shift.uuid ||
      !this.form?.$ ||
      !/active|filled/i.test(this.shift.status)
    ) {
      return false;
    }

    // If time or title changed
    if (
      !moment(this.form.$('start')?.value).isSame(
        moment(this.shift.start),
        'minutes',
      ) ||
      !moment(this.form.$('end')?.value).isSame(
        moment(this.shift.end),
        'minutes',
      ) ||
      this.form.$('title').value !== this.shift.title
    ) {
      log.debug('Time or title changed!');
      return true;
    }

    // If address changed
    const shiftAddress =
      this.shift.formattedAddress ||
      formatAddress(_.get(this.shift.addressObj, 'address', {})) ||
      _.get(this.shift, 'address', '');
    const formAddress =
      Boolean(this.form) &&
      Boolean(this.form.$) &&
      (this.form.$('address').has('formattedAddress') &&
      !this.form.has('formattedAddress')
        ? this.form.$('address.formattedAddress').value
        : this.form.$('formattedAddress').value);
    return formAddress !== shiftAddress;
  }

  @computed
  get changesIfSavedWillDisableEditing() {
    const log = this.log.getChildLogger('changesIfSavedWillDisableEditing');
    if (!this.shift.uuid || !this.form?.$ || this.shift?.status === 'Draft') {
      return false;
    }
    const formEnd = this.form.$('end')?.value;
    if (
      !moment(formEnd).isSame(moment(this.shift.end), 'minutes') &&
      moment(formEnd).isBefore(moment.now(), 'minutes')
    ) {
      log.debug('Making this change will disable future editing ');
      return true;
    }
    return false;
  }

  @action.bound
  async saveAsDraft(redirect = true, doThrow?: boolean) {
    if (this.isLoading) {
      return false;
    }

    const isValid = await this.validateForm();
    if (isValid) {
      return await this.save('Draft', redirect);
    }

    this.form.hooks().onError();

    if (doThrow) {
      throw new Error('Form validation failed');
    }

    return false;
  }

  /**
   * ## ui.ShiftFormNextGen.save
   * This method is only hit when CREATING a new shift. If you are attempting
   * to find logic related to UPDATING an existing shift, please see `forms/shiftNextGen.onSuccess`
   *
   * This method calls `doSave`
   */
  @action.bound
  async save(status = '', redirect = true) {
    const log = this.log.getChildLogger('');
    if (this.isLoading) {
      return null;
    }

    try {
      this.setLoading(true);
      dispatch('ui.loadingModal.open', {
        message: 'Saving Shift',
      });

      const data = this.form.values();
      let savedData = null;
      const successMessage = 'Shift Saved!';

      if (data.timezone) {
        data.start = moment
          .tz(
            moment(data.start).format('YYYY-MM-DDTHH:mm:00.000'),
            data.timezone,
          )
          .toDate();
        data.end = moment
          .tz(moment(data.end).format('YYYY-MM-DDTHH:mm:00.000'), data.timezone)
          .toDate();
      }

      if (/^flexible$/i.test(data.shiftScheduleType)) {
        const minHoursDuration = moment(data.end).diff(
          data.start,
          'hours',
          true,
        );
        _.set(data, 'shiftScheduleRules.minHoursDuration', minHoursDuration);

        if (data.timezone) {
          const { workingHoursStart: whStart, workingHoursEnd: whEnd } =
            data.shiftScheduleRules ?? {};

          if (whStart) {
            _.set(
              data,
              'shiftScheduleRules.workingHoursStart',
              moment
                .tz(
                  moment(whStart).format('YYYY-MM-DDTHH:mm:ss'),
                  data.timezone,
                )
                .toDate(),
            );
          }
          if (whEnd) {
            _.set(
              data,
              'shiftScheduleRules.workingHoursEnd',
              moment
                .tz(moment(whEnd).format('YYYY-MM-DDTHH:mm:ss'), data.timezone)
                .toDate(),
            );
          }
        }
      }

      if (_.isEmpty(data.externalId)) {
        delete data.externalId;
      }

      savedData = await this.doSave(data, status);

      this.clear();
      dispatch('dispatchPrefs.clearSelected');
      if (redirect) {
        dispatch('routing.goto', { route: `/shifts/${savedData.uuid}` });
      }

      dispatch('ui.snackBar.open', successMessage, {
        onClick: () =>
          dispatch('routing.goto', { route: `/shifts/${savedData.uuid}` }),
      });

      return savedData;
    } catch (err) {
      log.error('Shift Saving failed', err);
    } finally {
      this.setLoading(false);
      dispatch('ui.loadingModal.close');
    }

    return null;
  }

  /**
   * ## ui.ShiftFormNextGen.doSave
   * This method is only hit when CREATING a new shift. If you are attempting
   * to find logic related to UPDATING an existing shift, please see `forms/shiftNextGen.onSuccess`
   *
   * @param {*} shift Partial<IShift>
   * @param {*} status?: IThing['status']
   * @param {*} sendNotification?: boolean
   *
   * @returns IShift
   * @throws Error if validation or other problem arises
   */
  @action.bound
  async doSave(shift, status = '', sendNotification = true): Promise<IShift> {
    const log = this.log.getChildLogger('doSave');
    if (!shift.positionId) {
      const error = 'A position is required.';
      this.form.invalidate(error);
      dispatch('ui.snackBar.error', error);
      throw Error(error);
    }
    const data = shift;
    let params = {};

    const shiftUUID = this.form.$('uuid').value;

    if (sendNotification) {
      const rawNotificationPrefs = dispatch(
        'ui.shiftInvitations.getNotificationPrefs',
      );
      data.notificationPrefs = getNotificationPrefsData({
        data,
        prefs: rawNotificationPrefs,
      });
    } else {
      data.notificationPrefs = [];
    }

    data.duration = formatDuration({
      end: data.end,
      start: data.start,
    });

    data.company = _.get(data.company, 'uuid', data.company);

    if (!_.isEmpty(status)) {
      data.status = status;
    }

    if (/^(site)$/.test(this.form.$('locationType').value)) {
      delete data.address;
      delete data.addressObj;
    }

    const shiftScheduleType = this.form.$('shiftScheduleType').value;
    if (/^fixed$/i.test(shiftScheduleType)) {
      data.shiftScheduleRules = {};
    }

    if (!_.isEmpty(shift?.preShiftSurveyDefinition?.uuid)) {
      data.preShiftSurveyDefinitions = [
        {
          title: shift.preShiftSurveyDefinition.title,
          uuid: shift.preShiftSurveyDefinition.uuid,
        },
      ];
      delete shift.preShiftSurveyDefinition;
    }
    if (!_.isEmpty(shift?.postShiftSurveyDefinition?.uuid)) {
      data.postShiftSurveyDefinitions = [
        {
          title: shift.postShiftSurveyDefinition.title,
          uuid: shift.postShiftSurveyDefinition.uuid,
        },
      ];
      delete shift.postShiftSurveyDefinition;
    }

    const storeAction = shiftUUID ? `shifts.update` : `shifts.create`;

    // If specified assigned users and creating new shift add client param to
    // create assignments for assignedUsers
    if (!_.isEmpty(data.assignedUsers) && !shiftUUID) {
      data.assignedUsers = data.assignedUsers.map((u) => u.uuid);
      params = {
        query: {
          $client: {
            createAssignedUserAssignments: true,
          },
        },
      };
    }

    if (this.showPartnerNotifyWarning && shiftUUID) {
      params = {
        query: {
          $client: {
            sendDetailsUpdatedNotification: true,
          },
        },
      };
    }

    let savedData = null;
    try {
      log.debug(`Executing Store Method "${storeAction}"`, {
        data,
        params,
      });
      savedData = await dispatch(storeAction, { data, params });
    } catch (err) {
      log.error('Saving Shift Failed: ', err);
      this.form.invalidate(err.message);
      dispatch('ui.snackBar.error', err.message);

      throw err;
    }

    return savedData;
  }

  @action.bound
  getLocationType({ shift = this.shift, defaultIsRemote }) {
    let locationType = defaultIsRemote
      ? 'remote'
      : _.get(_.first(this.locationTypeOptions), 'value', 'custom');

    if (!_.isEmpty(shift.uuid)) {
      const addressObjType = _.get(shift, 'addressObj.__t');
      if (shift.isRemote) {
        locationType = 'remote';
      } else if (/^(site)$/.test(addressObjType)) {
        locationType = addressObjType;
      } else if (!_.isEmpty(shift.addressRef)) {
        locationType = 'custom';
      }
    }

    return locationType;
  }

  @action.bound
  async getCompanySettings(companyId): Promise<ICompany['settings']> {
    const { settings } =
      companyId === this.company?.uuid
        ? this.company
        : (await dispatch('companies.get', companyId, {
            query: { $select: ['uuid', 'settings'] },
            select: false,
          })) ?? {};
    return settings ?? {};
  }

  @action.bound
  async setupLocationTypeOptions({ companyId }) {
    // Load settings from the scoped company, loading from the api if needed
    const settings = await this.getCompanySettings(companyId);
    /** Setup Available Location Types */
    const availableSites = await dispatch('sites.findByCompany', {
      company: companyId,
    });

    runInAction(() => {
      _.extend(this.locationTypes, {
        custom: !settings.shifts?.disableCustomLocations,
        home: false,
        remote:
          settings.shifts?.enableRemoteShifts ??
          this.company?.settings?.shifts?.enableRemoteShifts,
        site: !!_.size(availableSites),
      });
    });
  }

  @action.bound
  async getEnableCheckinCodeByDefault({ enableCheckinCode }) {
    if (_.isBoolean(enableCheckinCode)) {
      return enableCheckinCode;
    }

    return this.company?.settings?.things?.enableCheckinCodeByDefault ?? null;
  }

  @action.bound
  togglePositionSelectMode() {
    this.positionSelectMode =
      this.positionSelectMode === 'search' ? 'list' : 'search';
  }

  @action.bound
  setAssignedUsers(partners) {
    const newAssignedUsers = _.uniqBy(
      _.concat(this.form.$('assignedUsers').value, partners),
      'uuid',
    );
    this.form.$('assignedUsers').set(newAssignedUsers);
  }

  @action.bound
  clear(opts) {
    this.company = null;
    this.shift = {};
    this.isLoading = false;
    this.confirmationModalVisible = false;
    this.originalAddressObj = null;

    if (!opts?.saveInvites) {
      dispatch('ui.shiftInvitations.clear');
    }

    this.form = null;
  }

  @action
  async saveShiftTemplate({
    recurringScheduleId,
  }: {
    recurringScheduleId?: string;
  }) {
    const log = this.log.getChildLogger('saveShiftTemplate');
    if (this.isLoading) {
      return false;
    }
    const isValid = await this.validateForm();
    if (isValid) {
      this.setLoading(true);

      const data = this.form.values();
      data.recurringScheduleId = recurringScheduleId;
      delete data.start;
      delete data.end;
      delete data.duration;

      const rawNotificationPrefs = dispatch(
        'ui.shiftInvitations.getNotificationPrefs',
      );
      data.notificationPrefs = getNotificationPrefsData({
        data,
        prefs: rawNotificationPrefs,
      });

      data.companyId = _.get(data.company, 'uuid', data.company);

      const shiftScheduleType = this.form.$('shiftScheduleType').value;
      if (/^fixed$/i.test(shiftScheduleType)) {
        data.shiftScheduleRules = {};
      }

      let savedData = null;
      try {
        const shiftUUID = this.form.$('uuid').value;
        if (shiftUUID) {
          data.uuid = shiftUUID;
          savedData = await dispatch('shiftTemplates.update', {
            data,
            isBulkEdit: false,
            query: { uuid: shiftUUID },
          });
        } else {
          savedData = await dispatch('shiftTemplates.create', {
            data,
            params: {},
          });
          dispatch('ui.recurringSchedules.updateShiftTemplatesCount', {
            actionType: 'add',
            dayId: Number(data.startDayOffset),
            value: 1,
          });
        }
      } catch (err) {
        log.error('Saving Shift Template Failed: ', err);
        this.form.invalidate(err.message);
        dispatch('ui.snackBar.open', err.message ? err.message : err);
        return false;
      }

      this.clear();
      dispatch('dispatchPrefs.clearSelected');
      dispatch('ui.snackBar.open', 'Shift Template Saved');
      this.setLoading(false);
      dispatch('ui.shiftsNextGen.fetchShiftTemplates');
      return savedData;
    }
    return null;
  }

  @action
  getBulkData() {
    const formData = _.pick(this.form.values(), [
      'status',
      'locationType',
      'addressObj',
    ]);
    const data = {
      status: formData.status,
    };

    if (formData.locationType !== '') {
      data.locationType = formData.locationType;
      data.addressObj = formData.addressObj;
    }

    this.updatedData = data;
    return data;
  }

  @action.bound
  async calculateUpdatedShiftTemplates() {
    const newValues = this.getBulkData();
    const shiftTemplatesToBeUpdated = this.shiftTemplates.map(
      (shiftTemplate) => {
        if (
          shiftTemplate.status !== newValues.status ||
          shiftTemplate.locationType !== newValues.locationType ||
          shiftTemplate.addressObj !== newValues.addressObj
        ) {
          return shiftTemplate;
        }
        return null;
      },
    );

    dispatch(
      'ui.shiftsNextGen.setUpdatedShiftTemplates',
      _.compact(shiftTemplatesToBeUpdated),
    );
  }

  @action
  async saveShiftTemplatesBulkEdit() {
    const log = this.log.getChildLogger('saveShiftTemplatesBulkEdit');
    if (this.isLoading) {
      return false;
    }

    try {
      const data = this.getBulkData();

      const shiftTemplatesUUIDs = this.shiftTemplates.map(
        (shiftObj) => shiftObj.uuid,
      );
      if (!_.isEmpty(shiftTemplatesUUIDs)) {
        await dispatch('shiftTemplates.update', {
          data,
          ids: shiftTemplatesUUIDs,
          isBulkEdit: true,
          query: {},
        });
        dispatch('ui.shiftsNextGen.fetchShiftTemplates');
      }
    } catch (err) {
      log.error('Bulk Editing Shift Templates Failed: ', err);
      this.form.invalidate(err.message);
      dispatch('ui.snackBar.open', err.message ? err.message : err);
      return false;
    }

    dispatch('dispatchPrefs.clearSelected');
    this.clear();
    return null;
  }

  @action
  async bulkDeleteShiftTemplates() {
    const log = this.log.getChildLogger('bulkDeleteShiftTemplates');
    if (this.isLoading) {
      return false;
    }
    const shiftTemplates = dispatch(
      'ui.shiftsNextGen.getSelectedShiftTemplates',
    );
    try {
      const shiftTemplatesUUIDs = shiftTemplates.map(
        (shiftObj) => shiftObj.uuid,
      );
      if (!_.isEmpty(shiftTemplatesUUIDs)) {
        await dispatch('shiftTemplates.removeMany', {
          ids: shiftTemplatesUUIDs,
        });
        dispatch('ui.shiftsNextGen.fetchShiftTemplates');
        dispatch('ui.recurringSchedules.updateShiftTemplatesCount', {
          actionType: 'remove',
          dayId: shiftTemplates[0].startDayOffset,
          value: _.size(shiftTemplatesUUIDs),
        });
      }
    } catch (err) {
      log.error('Bulk Deleting Shift Templates Failed: ', err);
      dispatch('ui.snackBar.open', err.message ? err.message : err);
      return false;
    }

    dispatch('dispatchPrefs.clearSelected');
    return null;
  }
}
