import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useStores } from '#/shared/hooks/useStores';

export interface GlobalCopyToClipboardProps {
  children: React.ReactNode | string;
  className?: string;
  text: string;
}

/**
 * @name GlobalCopyToClipboard
 * @description Copy to clipboard component, useful wrapper around things
 * like a "users email address" that we might want to use for a search
 */
export const GlobalCopyToClipboard = (props: GlobalCopyToClipboardProps) => {
  const { children, text } = props;

  // Hooks
  const { ui } = useStores();

  // Handlers
  const onCopyHandler = () => {
    ui.snackBar.open(`📋 Copied to clipboard.`);
  };

  return (
    <CopyToClipboard onCopy={onCopyHandler} text={text}>
      <button
        className="m0 p0 bn br2 hover-bg-black-10 bg-transparent"
        onClick={(e) => e.stopPropagation()}
      >
        <>{children}</>
      </button>
    </CopyToClipboard>
  );
};
