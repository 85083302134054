import React, { Component } from 'react';
import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Header, Icon, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { getChildLogger } from '#/shared/utils/client.logger';

import { ErrorBoundary } from './ErrorBoundary';

const log = getChildLogger('utils.MapWrapper');

@observer
export default class MapWrapper extends Component {
  @observable showMap = false;

  MapComponent = null;

  static propTypes = {
    useLegacy: PropTypes.bool,
  };

  static defaultProps = {
    useLegacy: false,
  };

  UNSAFE_componentWillMount() {
    if (!global.IS_SSR) {
      log.silly('SSM Map will Mount');

      try {
        // eslint-disable-next-line
        this.MapComponent = require('./' +
          (this.props.useLegacy ? 'Legacy' : 'New') +
          'MapComponent').default;
      } catch (err) {
        log.error('Unable to load Map Component', err);
        this.MapComponent = () => (
          <div className="h-100 w-100">
            <div className="h-100 w-100 bg-light-blue flex-column flex items-center justify-center">
              <Icon color="red" name="ban" size="huge" />
              <Header
                color="red"
                content="Unable to Render Location on Map"
                size="small"
              />
            </div>
          </div>
        );
      }

      runInAction(() => {
        this.showMap = !!this.MapComponent;
      });
    } else {
      log.warn('SSM Map will NOT mount. `window` is undefined');
    }
  }

  render() {
    const { MapComponent, showMap } = this;
    return (
      <div className="h-100 w-100">
        {MapComponent ? (
          <ErrorBoundary title="Map Wapper">
            <MapComponent {...this.props} showPlaceholder={!showMap} />
          </ErrorBoundary>
        ) : (
          <MapLoader />
        )}
      </div>
    );
  }
}

const MapLoader = observer(() => (
  <div className="h-100 w-100">
    <div className="h-100 w-100 bg-light-blue flex items-center justify-center">
      <Loader active={true} inline={true} />
    </div>
  </div>
));

export { MapLoader };
