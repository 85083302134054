import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { dispatch } from 'rfx-core';
import { observer } from 'mobx-react';
import { List, Loader } from 'semantic-ui-react';
import cx from 'classnames';

import EmptyState from '#/shared/components/ssm/EmptyState';
import buttons from '#/shared/styles/buttons.css';
import Modal from '#/ssm-ui/Modal';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ssm.Upsell');

export const Upsell = observer(
  ({
    header = 'This functionality is only available to enterprise accounts.',
    upsellModalStore,
  }) => (
    <>
      <EmptyState
        action={
          <button
            className={cx(buttons.primary, 'mt3')}
            onClick={() => {
              dispatch('ui.upsellModal.request');
            }}
          >
            Get in Touch
          </button>
        }
        body={[
          <p className="f4" key="description">
            You are currently using the lite version of Shiftsmart, upgrade and
            enjoy more features such as:
          </p>,
          <List key="list">
            <List.Item
              className="f4 mb2"
              content="Create smart pools of workers and schedule invites."
              icon="chevron right"
            />
            <List.Item
              className="f4 mb2"
              content="Invite all your existing staff and existing workers"
              icon="chevron right"
            />
            <List.Item
              className="f4 mb2"
              content="Access Shiftsmart database of more than 10,000+ workers"
              icon="chevron right"
            />
            <List.Item
              className="f4 mb2"
              content="Create schedules using templates and auto assign workers using smart rules."
              icon="chevron right"
            />
          </List>,
        ]}
        header={{
          content: header,
          size: 'huge',
          style: {
            marginBottom: '3rem',
          },
        }}
        icon={<img alt="Upgrade Today!" src="/static/img/pools_noaccess.png" />}
        layout="horizontal"
      />

      <UpsellModal
        isLoading={upsellModalStore.isLoading}
        isOpen={upsellModalStore.isOpen}
        requestStatus={upsellModalStore.requestStatus}
      />
    </>
  ),
);

Upsell.displayName = 'Upsell';

Upsell.propTypes = {
  header: PropTypes.string,
  upsellModalStore: PropTypes.object,
};

export default Upsell;

const UpsellModal = observer(({ isOpen, isLoading, requestStatus }) => {
  if (requestStatus.existing) {
    log.debug('User has already submitted a request', requestStatus);
  }

  return (
    <Modal
      content={
        isLoading ? (
          <Loader />
        ) : (
          <EmptyState
            action={
              <button
                className={cx(buttons.primary, 'w-100 mt3')}
                onClick={() => {
                  dispatch('ui.upsellModal.clear');
                }}
              >
                Done
              </button>
            }
            body="We have received your request for moving to an enterprise account, one of our representatives will get in touch with you shortly."
            header={{
              content: (
                <span>
                  Thanks for reaching out!
                  <br />
                  We&apos;ll contact you soon
                </span>
              ),
              size: 'small',
            }}
            icon={
              <img
                alt="Thanks for your message"
                src="/static/img/message_sent.svg"
              />
            }
            style={{ width: '100%' }}
          />
        )
      }
      open={isOpen}
      size="mini"
    />
  );
});
