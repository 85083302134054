import type { ICompany } from '@shiftsmartinc/shiftsmart-types';

import { action, observable } from 'mobx';
import _ from 'lodash';

import { init } from '#/shared/forms/store';

export default class StoreEditModal {
  @observable
  isOpen = false;

  @observable
  selected = {};

  @observable
  form = {};

  @observable
  showAdvanced = false;

  @observable
  company: ICompany = null;

  storeName = 'stores';

  @action
  setup({ customer = {}, company = {}, open = this.isOpen }) {
    this.company = company;
    this.selected = customer;

    if (this.selected && this.selected.uuid) {
      this.form = init(customer);
    } else {
      this.form = init(
        {
          companies: [_.get(this.company, 'uuid', this.company)],
        },
        { addressOptional: true },
      );
    }

    this.showAdvanced = false;

    this.isOpen = open;
  }

  @action.bound
  toggleAdvanced(val = !this.showAdvanced) {
    this.showAdvanced = val;
  }

  @action.bound
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action.bound
  clear(args = {}) {
    this.selected = {};
    this.form.clear();

    this.isOpen = !!args.open;
  }
}
