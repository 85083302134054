import { action, observable, computed } from 'mobx';
import { dispatch } from 'rfx-core';
import moment from 'moment';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class ApplySchedulingTemplateModal {
  log = getChildLogger('ui.ApplySchedulingTemplateModal');

  @observable
  isOpen = false;

  @observable
  reviewItems = [];

  @observable
  isReviewing = false;

  @observable
  isComplete = false;

  @observable
  applyStart = moment().startOf('day');

  @observable
  previewDate = this.applyStart.toDate();

  @observable
  templateList = [];

  @computed
  get templateOptions() {
    return _.map(this.templateList, (t) => ({
      key: t.uuid,
      text: t.title,
      value: t.uuid,
    }));
  }

  @computed
  get templateSelected() {
    return dispatch('schedulingTemplates.retrieve', 'selected');
  }

  @observable
  includePartners = false;

  @action
  setApplyStart(day) {
    this.applyStart = moment(day).startOf('day');
    this.previewDate = moment(day).startOf('day');
  }

  @action
  setup({ open = this.isOpen, applyStart, schedulingTemplates, auth }) {
    this.isOpen = open;
    this.applyStart = applyStart;
    this.templateList = schedulingTemplates.list;
    const company = auth.getCompany();
    schedulingTemplates.find({ company: company.uuid });
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    this[key] = value;
  }

  @action
  setReview(items) {
    const log = this.log.getChildLogger('setReview');
    if (!_.isEmpty(items)) {
      this.reviewItems.replace(items);
      this.isReviewing = true;
    } else {
      log.debug('No reviewItems, submitting');
      this.submit();
    }
  }

  @action
  setComplete(complete = true) {
    this.isComplete = complete;
    if (complete) {
      this.isReviewing = false;
      this.reviewItems = [];
    }
  }

  @computed
  get applyData() {
    return {
      includePartners: this.includePartners,
      start: moment(this.applyStart).startOf('day').toDate(),
      template: this.templateSelected.uuid,
    };
  }

  @action
  async review() {
    const log = this.log.getChildLogger('review');
    try {
      // Apply selected schedulingTemplate
      const res = await dispatch('schedulingTemplates.applyTemplate', {
        ...this.applyData,
        reviewOnly: true,
      });
      log.debug('Received ApplyTemplate request conflicts: ', res);
      this.setReview(res);
    } catch (err) {
      log.error('Failed to review apply scheduling template request', err);
    }
  }

  @action
  async submit() {
    const log = this.log.getChildLogger('submit');
    try {
      const res = await dispatch('schedulingTemplates.applyTemplate', {
        ...this.applyData,
        reviewOnly: false,
      });
      await dispatch('agendaJobProgress.setSelected', res.agendaJobProgress);
      this.setComplete(true);
    } catch (err) {
      log.error('Failed to apply scheduling template', err);
    }
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  back() {
    if (this.isReviewing) {
      this.isReviewing = false;
      this.reviewItems = [];
    } else {
      this.clear();
    }
  }

  @action
  clear() {
    this.isOpen = false;
    this.isReviewing = false;
    this.reviewItems = [];
    this.includePartners = false;
    this.isComplete = false;
  }

  @computed
  get submitButtonText() {
    if (this.isComplete) return 'Done';
    if (this.isReviewing) return 'Yes - Apply Template';
    return 'Apply Template';
  }

  @computed
  get modalTitle() {
    if (this.isComplete) return 'Template Applied';
    if (this.isReviewing) return 'Review Conflicts';
    return 'Apply Scheduling Template';
  }
}
