import type { ICompany, ITraining } from '@shiftsmartinc/shiftsmart-types';

import { action, observable } from 'mobx';
import _ from 'lodash';

import { TrainingFormInstance, init } from '#/shared/forms/training';

export default class TrainingEditModal {
  @observable isOpen = false;

  @observable form: TrainingFormInstance = null;

  @action.bound
  async setup({
    training,
    company,
    open = this.isOpen,
  }: {
    company: ICompany['uuid'];
    open?: boolean;
    training?: ITraining;
  }) {
    if (training?.uuid) {
      this.form = init({
        ...training,
      });
    } else {
      this.form = init({ ...training, company, status: 'draft' });
    }

    this.isOpen = open;
  }

  @action.bound
  clear(args?: { open?: boolean }) {
    this.form.clear();
    this.isOpen = !!args?.open;
  }
}
