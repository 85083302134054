import type { ICampaign } from '@shiftsmartinc/shiftsmart-types';

import { action, observable } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import { dispatch } from 'rfx-core';

import { init, shoppableCheckboxOptions } from '#/shared/forms/campaign';

export default class CampaignEditModal {
  @observable
  isOpen = false;

  @observable
  form = {};

  @observable
  campaignTypes = [
    {
      key: 'onsite',
      text: 'On Site',
      value: 'onsite',
    },
    {
      key: 'remote',
      text: 'Remote',
      value: 'remote',
    },
  ];

  @observable
  selectedDays = [];

  @action
  setup({ campaign = {}, company, open = this.isOpen }) {
    const values: Partial<ICampaign> = _(campaign)
      .omit(['quotas', 'surveys'])
      .cloneDeep();
    const companyId = company?.uuid ?? company ?? campaign?.company;

    dispatch('storeLists.find', {
      companies: companyId,
    });
    dispatch(
      'surveyDefinitions.find',
      {
        company: companyId,
      },
      { clear: true },
    );

    if (values?.uuid) {
      if (values.customSurveyTitle || values.customSurveyDescription) {
        values.showCustomSurvey = true;
      }
      if (values?.preShopSurveyDefinition?.uuid) {
        values.requirePreShopSurvey = true;
      } else {
        values.preShopSurveyDefinition = null;
      }
      this.form = init(values);
      if (!_.isEmpty(values.allowedCompletionDOWs)) {
        const selectedDays = values.allowedCompletionDOWs.map((val) =>
          shoppableCheckboxOptions.find((opt) => opt.key === val),
        );
        this.form.$('allowedCompletionDOWs').set(selectedDays);
        this.selectedDays = selectedDays;
      }
    } else {
      this.form = init({
        campaignType: 'onsite',
        company: companyId,
        expirationWindow: 24,
        start: moment().subtract(1, 'day').toDate(),
      });
    }

    this.isOpen = open;
  }

  @action
  addDOWSelection(value) {
    this.form.$('allowedCompletionDOWs').clear();
    if (this.selectedDays.find((val) => val.text === value.label)) {
      this.selectedDays = this.selectedDays.filter(
        (day) => day.text !== value.label,
      );
      return;
    }

    this.selectedDays.push(
      shoppableCheckboxOptions.find((val) => val.text === value.label),
    );
  }

  @action
  getSelectedDays() {
    return this.selectedDays;
  }

  @action
  clear(args = {}) {
    this.form.clear();
    this.selectedDays = [];
    this.isOpen = !!args.open;
  }
}
