import BaseStore from '#/shared/stores/_baseStore';

export default class SystemStore extends BaseStore<Record<string, unknown>> {
  constructor() {
    super({
      serviceName: 'system',
    });

    return this;
  }
}
