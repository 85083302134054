import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { Icon, Popup, SemanticICONS } from 'semantic-ui-react';
import cx from 'classnames';

import buttonStyles from '#/shared/styles/buttons.css';

type ActionButtonType = {
  disabled?: boolean;
  icon?: SemanticICONS;
  iconStyle?: React.CSSProperties;
  negative?: boolean;
  onClick?: () => void;
  position?: any;
  small?: boolean;
  src?: string;

  style?: any;
  styles?: Record<string, unknown>;
  tooltip?: any;
};
export const ActionButton = observer(
  ({
    src,
    icon,
    onClick,
    tooltip,
    disabled,
    style,
    position = 'bottom middle',
    iconStyle,
    ...rest
  }: ActionButtonType) => {
    const content = (
      <div
        className={cx({
          [buttonStyles.actionButton]: true,
          [buttonStyles.negative]: rest.negative,
          [buttonStyles.disabled]: disabled,
          [buttonStyles.small]: rest.small,
        })}
        onClick={!disabled ? onClick : undefined}
        role="button"
        style={{
          content: src || icon,
          ...style,
        }}
        tabIndex={0}
      >
        {src && (
          <img
            alt=""
            src={src}
            style={_.defaults(rest.styles, {
              marginTop: 3,
            })}
          />
        )}
        {_.isEmpty(src) && icon && (
          <Icon
            className={iconStyle?.color ? iconStyle?.color : 'blue'}
            disabled={disabled}
            name={icon}
          />
        )}
      </div>
    );
    if (tooltip && !disabled) {
      return (
        <Popup
          content={tooltip}
          inverted={true}
          mouseEnterDelay={500}
          on="hover"
          position={position}
          trigger={content}
        />
      );
    }
    return content;
  },
);
ActionButton.displayName = 'ActionButton';

export default ActionButton;
