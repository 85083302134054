import type { IRecurringSchedule } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';

import Form from './_.extend';

export class RecurringScheduleForm extends Form {
  constructor(setup, config) {
    super(
      setup,
      _.defaultsDeep(config, { options: { retrieveOnlyDirtyValues: true } }),
    );
  }

  hooks = () => ({
    onSuccess() {
      this.log.warn('This form does not implement a form.onSuccess handler');
    },
  });
}

const fields = [
  'uuid',
  'title',
  'description',
  'status',
  'cycleDays',
  'cycleStart',
  'cycleStart.dow',
  'cycleStart.dom',
  'cycleStart.ldom',
  'cycleStart.date',
  'effectiveDate',
  'endDate',
  'daysBeforeToRun',
];

const labels = {
  cycleDays: 'Cycle Days',
  cycleStart: 'CycleStart',
  'cycleStart.date': 'Date',
  'cycleStart.dom': 'Day of month',
  'cycleStart.dow': 'Day of week',
  'cycleStart.ldom': 'Last day of the month',
  daysBeforeToRun: 'Days before to run *',
  description: 'Description (Optional)',
  effectiveDate: 'Effective Date *',
  endDate: 'End date (Optional)',
  status: 'Status',
  title: 'Title *',
};

const rules = {
  cycleDays: 'required|numeric|between:1,100',
  cycleStart: '',
  'cycleStart.date': 'date|is_valid_date',
  'cycleStart.dom': 'numeric|min:1|max:31',
  'cycleStart.dow': 'numeric|min:1|max:7',
  'cycleStart.ldom': 'boolean',
  daysBeforeToRun: 'required|numeric|min:0',
  description: 'string:min:5',
  effectiveDate: 'required|date|is_valid_date',
  endDate: 'date|is_valid_date',
  status: 'required|string',
  title: 'required|string|between:5,100',
};

const observers = {};

const defaults = {
  cycleDays: 7,
  cycleStart: {
    date: new Date(),
    dom: 1,
    dow: 1,
    ldom: false,
  },
  status: 'Active',
};

export const weekDaysDropdownOptions = [
  {
    key: 1,
    text: 'Sunday',
    value: 1,
  },
  {
    key: 2,
    text: 'Monday',
    value: 2,
  },
  {
    key: 3,
    text: 'Tuesday',
    value: 3,
  },
  {
    key: 4,
    text: 'Wednesday',
    value: 4,
  },
  {
    key: 5,
    text: 'Thursday',
    value: 5,
  },
  {
    key: 6,
    text: 'Friday',
    value: 6,
  },
  {
    key: 7,
    text: 'Saturday',
    value: 7,
  },
];

const extra = {
  'cycleStart.dow': {
    options: weekDaysDropdownOptions,
  },
};

export function init(
  values?: Partial<IRecurringSchedule>,
  cxOptions?: Record<string, never>,
) {
  const vals = values?.uuid ? values : { ...(values ?? {}) };

  return new RecurringScheduleForm(
    {
      defaults,
      extra,
      fields,

      labels,

      observers,

      rules,
      // Must merge defaults & values until at least @1.39.0
      values: _.defaults(vals, defaults),
    },
    {
      ...(cxOptions ?? {}),
      options: {
        retrieveOnlyDirtyValues: !!vals?.uuid,
      },
    },
  );
}
