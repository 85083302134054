import type { Field as MobxReactFormField } from 'mobx-react-form';
import type { SSMFormInstance } from '#/shared/forms/_.extend';

import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import moment, { MomentFormatSpecification, MomentInput } from 'moment';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import DatePicker from 'react-datepicker';
import {
  Checkbox,
  Icon,
  Input,
  Label,
  Radio,
  Dropdown,
  Segment,
  Popup,
  StrictInputProps,
  StrictTextAreaProps,
  StrictLabelProps,
  StrictDropdownItemProps,
  StrictDropdownProps,
  StrictFormGroupProps,
  SemanticICONS,
  SemanticWIDTHS,
  StrictIconProps,
  Button,
} from 'semantic-ui-react';
import DateTime from 'react-datetime';
import cx from 'classnames';
import { LabelProps } from 'recharts';

import { Form, FormInputLabel } from '#/ssm-ui/semantic/Form';
import { sync } from '#/shared/components/utils/TimePicker';
import TimePickerDropdown from '#/shared/components/common/TimePickerDropdown';
import {
  BaseSelect,
  IndependentSelect,
  TimeSelect,
  GoogleAutocomplete,
  PhoneInput,
} from '#/shared/components/utils';
import shiftFormStyles from '#/shared/styles/shiftForm.css';
import { IBaseSelectProps } from '#/shared/components/utils/BaseSelect';
import RichTextEditor from '#/ssm-ui/RichTextEditor';
import { IndependentSelectProps } from '#/shared/components/utils/IndependentSelect';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('forms.inputs');

let ReactPhoneInput = null;
if (!global.IS_SSR) {
  // SSR breaks because of this input. Need to do a dynamic require only on client side;
  // eslint-disable-next-line global-require
  ReactPhoneInput = require('react-phone-input-2').default;
}

declare type FormFieldType =
  | 'textarea'
  | 'time'
  | 'date'
  | 'date-time'
  | 'phone'
  | 'tel'
  | 'phone-country'
  | 'location'
  | 'customDataMap'
  | 'storeSelect'
  | 'select'
  | 'dropdown'
  | 'checkbox'
  | 'radio'
  | 'currency'
  | StrictInputProps['type'];

declare type FormFieldBase = (
  | {
      field?: MobxReactFormField;
      fieldKey: string;
    }
  | { field: MobxReactFormField; fieldKey?: never }
) & {
  disabled?: boolean;
  /** Form field used to track the currently focused input */
  focusKey?: string;
  type?: FormFieldType;
};

declare type FormFieldProps = FormFieldBase & {
  /** Render _just_ the specified control wrapped in a semantic-ui `Form.Field` component */
  bare?: boolean;
  clearable?: boolean;
  /** Applied to the `className` prop of the enclosing `Form.Field` component */
  fieldClassName?: string;
  /** Override the specified field's label property */
  label?: string | LabelProps;
  /** Hide the label */
  noLabel?: boolean;
  placeholder?: string;
  query?: Record<string, unknown>;
  storeName?: string;
  /** Adds an optional info tooltip to the field, overrides the value of `extra.tooltip` */
  tooltip?: string;
  /** Allows customization of the icon rendered as the trigger for the tooltip. Can be either
   * a semantic-ui icon name, or a full icon-shorthand object */
  tooltipIcon?: SemanticICONS | StrictIconProps;
  type?: string;
  /** Used for displaying validation errors when the rendered field is not the one validated.
   *
   * For example:
   * - the `employer` field may be rendered in the control
   * - `employer.name` has a rule set to `required`
   * - pass in `fieldKey="employer" valueKey="employer.name"`
   *
   * This will result in the label and other info coming from the
   * `employer` field, while the validation is performed on `employer.name`
   */
  valueKey?: string;
  width?: SemanticWIDTHS;
};

declare type FormFieldEventHandlers = {
  onFieldChange?: CallableFunction;
  onFocus?: CallableFunction;
};

export default class Inputs {
  constructor(form) {
    this.form = form;

    this.updatePhoneNumber = this.updatePhoneNumber.bind(this);
    this.updateCountryCode = this.updateCountryCode.bind(this);
    this.updateLocation = this.updateLocation.bind(this);

    this.InlineFieldError.displayName = 'SSM_InlineFieldError';
    this.FieldErrorLabel.displayName = 'SSM_FieldErrorLabel';
    this.FieldInput.displayName = 'SSM_FieldInput';
    this.FieldTextArea.displayName = 'SSM_FieldTextArea';
    this.FieldCheckBox.displayName = 'SSM_FieldCheckBox';
    this.FieldRadio.displayName = 'SSM_FieldRadio';
    this.FieldPhoneNumber.displayName = 'SSM_FieldPhoneNumber';
    this.FieldDropdown.displayName = 'SSM_FieldDropdown';
    this.FieldDatePicker.displayName = 'SSM_FieldDatePicker';
    this.FieldTimePicker.displayName = 'SSM_FieldTimePicker';
    this.FieldDateTimePicker.displayName = 'SSM_FieldDateTimePicker';
    this.FieldGooglePlaces.displayName = 'SSM_FieldGooglePlaces';
    this.FieldValue.displayName = 'SSM_FieldValue';
    this.FieldSelect.displayName = 'SSM_FieldSelect';
    this.Field.displayName = 'SSM_Field';
  }

  form: SSMFormInstance;

  updatePhoneNumber(e, data) {
    e.preventDefault();
    const formatted = data.value.replace(/\D/g, '').slice(-10);
    this.form.$(data.name).set(formatted);
  }

  updateCountryCode = (key, value) => {
    this.form.$(key).set(value);
  };

  updateLocation(result, field, geocoded = false) {
    let faField;

    if (geocoded) {
      field.set(result);

      faField = field.has('formattedAddress') && field.$('formattedAddress');
    } else {
      field.set(_.get(result, 'formattedAddress', ''));
      faField = field;
    }

    if (faField?.value) {
      const val = faField.value.replace(/, USA$/i, '');
      faField.set(val);
    }

    log.debug('Update Location Field', {
      faVal: faField?.value,
      fieldKey: field.name,
      result,
      val: field.value,
    });
  }

  FieldLabel = observer<
    React.FC<
      Pick<
        FormFieldProps,
        'fieldKey' | 'field' | 'label' | 'tooltip' | 'tooltipIcon'
      > & {
        className?: string;
      }
    >
  >(
    ({
      fieldKey,
      field = this.form.$(fieldKey, null, false),
      label,
      tooltip,
      tooltipIcon,
      className,
    }) => {
      const renderedLabel = (_.isString(label) && label) || field?.label || '';
      const renderedTooltip = tooltip || field?.extra?.tooltip;

      if (!(renderedLabel || renderedTooltip)) {
        return null;
      }

      return (
        <FormInputLabel
          className={className}
          required={/required/.test(field?.rules ?? '')}
          requiredError={/required/.test(field?.error ?? '')}
        >
          {!!renderedLabel && renderedLabel}
          {/* The tooltip logic is not compatible with checkbox inputs */}
          {!!renderedTooltip && (
            <span
              style={{
                marginLeft: '4px',
              }}
            >
              <Popup
                content={renderedTooltip}
                position="bottom right"
                trigger={
                  <Icon
                    {..._.extend(
                      {
                        color: 'blue',
                        name: 'info circle',
                      },
                      _.isString(tooltipIcon)
                        ? {
                            name: tooltipIcon,
                          }
                        : tooltipIcon,
                    )}
                  />
                }
              />
            </span>
          )}
        </FormInputLabel>
      );
    },
  );

  InlineFieldError: React.FC<
    FormFieldBase & {
      /** Class applied to the error icon when visible */
      className?: string;
    }
  > = observer(
    ({ fieldKey, field = this.form.$(fieldKey), className }) =>
      !!field.hasError &&
      (!!field.isDirty || this.form.hasError) && (
        <Icon className={className} color="red" name="exclamation circle" />
      ),
  );

  FieldErrorLabel: React.FC<
    FormFieldBase & {
      pointing?: StrictLabelProps['pointing'];
    }
  > = observer(
    ({ fieldKey, field = this.form.$(fieldKey), pointing = true }) =>
      !!field.hasError &&
      !!field.error && (
        <Label color="red" content={field.error} pointing={pointing} />
      ),
  );

  SectionHeading = observer<React.FC<{ fieldKey: string; label?: string }>>(
    ({ fieldKey, label }) => {
      const field = this.form.select(fieldKey, null, false);

      return (
        <FormInputLabel
          className="mb3"
          required={/required/.test(field?.rules ?? '')}
          requiredError={/required/.test(field?.error ?? '')}
          style={{
            color: 'var(--ssm-blue)',
            fontSize: '18px',
            fontWeight: 'bold',
          }}
        >
          {(_.isString(label) && label) || field?.label}
        </FormInputLabel>
      );
    },
  );

  FieldValue: React.FC<{
    fieldKey: string;
    format?:
      | MomentFormatSpecification
      | ((value) => string | React.ReactElement);
  }> = observer(({ fieldKey, format }) => {
    if (_.isEmpty(fieldKey) || _.isEmpty(this.form)) return false;

    const field = this.form.$(fieldKey) || {};

    if (_.isEmpty(field)) return false;

    if (_.isFunction(format)) {
      return <span>{format(field.value)}</span>;
    }
    if (field.value instanceof Date) {
      return (
        <span>{moment(field.value).format((format as string) || 'L')}</span>
      );
    }
    if (field.value instanceof Object) {
      return <span>{JSON.stringify(field.value)}</span>;
    }

    return <span>{field.value}</span>;
  });

  FieldInput: React.FC<
    StrictInputProps &
      FormFieldBase &
      FormFieldEventHandlers & {
        /** Similar to `disabled` but only targets event handlers, not styling */
        readOnly?: boolean;
      }
  > = observer(
    ({ field, type, label, disabled, ...rest }) =>
      !!field && (
        <Input
          {...rest}
          {...field.bind()}
          className="mb2"
          disabled={disabled}
          error={field.hasError && field.isDirty}
          fluid={true}
          icon={
            field.hasError && field.isDirty ? (
              <this.InlineFieldError field={field} />
            ) : (
              rest.icon
            )
          }
          label={label}
          labelPosition={
            rest.labelPosition || _.isEmpty(label) ? undefined : 'right'
          }
          name={field.name}
          onChange={(...args) => {
            if (rest.readOnly) return;
            field.sync(...args);
            const data = args[1];
            if (data && _.isFunction(rest.onFieldChange)) {
              rest.onFieldChange(...args);
            }
          }}
          onFocus={
            rest.readOnly
              ? _.noop
              : (...args) => {
                  rest?.onFocus?.(...args);
                  this.registerFocus({ field, key: rest.focusKey })();
                }
          }
          placeholder={rest.placeholder ?? field.placeholder}
          type={type}
          value={field.value}
        />
      ),
  );

  /* If field is bound with `field.bind()`, label must be set in order to prevent dupe labels */
  FieldTextArea: React.FC<
    StrictTextAreaProps &
      FormFieldBase &
      FormFieldEventHandlers & {
        /** Passed to the TextArea control along with `mb2` */
        className?: string;
      }
  > = observer(
    ({ field, type, label, className, rows = 4, disabled, ...rest }) =>
      !!field && (
        <Form.TextArea
          {...rest}
          {...field.bind()}
          className={cx('mb2', className)}
          disabled={disabled}
          error={field.hasError && field.isDirty}
          icon={<this.InlineFieldError field={field} />}
          label={label}
          name={field.name}
          onChange={field.sync}
          onFocus={this.registerFocus({ field, key: rest.focusKey })}
          placeholder={field.placeholder}
          rows={rows}
          type={type}
          value={field.value}
        />
      ),
  );

  FieldCurrency: React.FC<
    StrictInputProps &
      FormFieldBase &
      FormFieldEventHandlers & {
        /** Similar to `disabled` but only targets event handlers, not styling */
        readOnly?: boolean;
      } & {
        currency?: string;
      }
  > = observer(({ currency = 'usd', ...args }) => {
    const currencyLabel: { content?: string; icon?: SemanticICONS | null } = {
      icon: 'dollar sign',
    };
    if (!/^(us|ca|au)d$/.test(currency)) {
      switch (currency) {
        case 'gbp':
          currencyLabel.icon = 'pound sign';
          break;
        case 'eur':
          currencyLabel.icon = 'euro sign';
          break;
        case 'inr':
          currencyLabel.icon = 'rupee sign';
          break;
        default:
          currencyLabel.icon = null;
          currencyLabel.content = _.upperCase(currency);
          break;
      }
    }

    return (
      <this.FieldInput
        {...args}
        label={currencyLabel}
        labelPosition="left"
        type="number"
      />
    );
  });

  FieldCheckBox: React.FC<FormFieldBase & FormFieldEventHandlers> = observer(
    ({ field = {}, label, disabled, ...rest }) => (
      <Checkbox
        {...rest}
        checked={!!field.value}
        disabled={disabled}
        label={label || field.label}
        onChange={(e, data) => {
          if (!disabled) {
            field.set(data.checked);
            if (_.isFunction(rest.onFieldChange)) {
              rest.onFieldChange(e, data);
            }
          }
        }}
      />
    ),
  );

  /** A Radio Field with pre-defined "Yes" and "No" options */
  FieldYNToggle = observer((props) => (
    <this.FieldRadio
      options={[
        { text: 'yes', value: true },
        { text: 'no', value: false },
      ]}
      {...props}
    />
  ));

  FieldToggle = observer<React.FC<FormFieldBase>>((props) => {
    const { field } = props;
    const options = props.options ?? [
      { key: 'yes', text: 'yes', value: true },
      { key: 'no', text: 'no', value: false },
    ];

    log.debug('rendering options', { options });
    return (
      <Button.Group key={`${field.key}`}>
        {_.map(options, (opt, i) => {
          const val = _.isBoolean(field?.value)
            ? field.value
            : field.value || null;
          const isSelected = val === opt.value;

          if (props.disabled && !isSelected) {
            return null;
          }

          return (
            <Button
              active={isSelected}
              basic={!isSelected}
              content={opt.text}
              key={`${field.key}:${opt.key || opt.text}`}
              onClick={() => field.set('value', opt.value)}
              primary={!props.disabled}
            />
          );
        })}
      </Button.Group>
    );
  });

  FieldTrinaryToggle = observer(
    ({ optNull, optYes, optNo, inheritedValue, ...props }) => {
      const options = useMemo(() => {
        const opts = [
          { key: 'yes', text: optYes || 'yes', value: true },
          { key: 'default', text: optNull || 'null', value: null },
          { key: 'no', text: optNo || 'no', value: false },
        ];

        const val = _.find(opts, { value: inheritedValue || false });

        return _.map(opts, (opt) => {
          if (opt.value === null && val) {
            return { ...opt, text: `${opt.text} (${val.text})` };
          }
          return opt;
        });
      }, [optNull, optYes, optNo, inheritedValue]);

      return <this.FieldToggle options={options} {...props} />;
    },
  );

  FieldRadio: React.FC<
    FormFieldBase & {
      options: Array<{
        disabled?: boolean;
        key: string;
        text: string;
        value: string | number;
      }>;
      vertical?: boolean;
    } & {
      type: 'checkbox' | 'radio';
    }
  > = observer(
    ({
      field,
      options = _.get(field, 'extra.options', []),
      vertical,
      ...rest
    }) => {
      if (!field) return null;

      const buttons = _.map(options, (opt) => (
        <Form.Field key={opt.key}>
          <Radio
            {...rest}
            checked={field.value === opt.value}
            disabled={opt.disabled}
            label={opt.text}
            name={field.name} // field key
            onChange={(e, { value }) => field.set(value)}
            value={opt.value}
          />
        </Form.Field>
      ));

      return vertical ? (
        <Form.Group grouped={true} inline={false}>
          {buttons}
        </Form.Group>
      ) : (
        <Form.Group widths="equal">{buttons}</Form.Group>
      );
    },
  );

  FieldPhoneNumber: React.FC<FormFieldBase> = observer(
    ({ field, disabled, ...rest }) =>
      !!field && (
        <Form.Input
          className="w-100"
          fluid={true}
          icon={<this.InlineFieldError field={field} />}
        >
          <PhoneInput
            {...rest}
            className="mb2"
            disabled={disabled}
            error={field.hasError}
            name={field.name}
            onChange={(e) => {
              this.updatePhoneNumber(e, e.target);
            }}
            onFocus={
              disabled
                ? _.noop
                : this.registerFocus({ field, key: rest.focusKey })
            }
            placeholder={field.label}
            value={field.value}
          />
        </Form.Input>
      ),
  );

  FieldCountryPhoneNumber: React.FC<
    FormFieldBase & {
      countryCodeKey?: string;
    }
  > = observer(
    ({ field, countryCodeKey, ...rest }) =>
      !!field && (
        <ReactPhoneInput
          {...rest}
          buttonStyle={{
            backgroundColor: '#FFF',
            position: 'initial',
          }}
          containerStyle={{
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
          defaultCountry="us"
          disableAreaCodes={true}
          onChange={(value, countryData) => {
            if (countryCodeKey) {
              this.updateCountryCode(
                countryCodeKey,
                _.toUpper(countryData.countryCode),
              );
            }
            field.sync(value);
          }}
          value={field.value}
        />
      ),
  );

  FieldDropdown: React.FC<
    FormFieldBase &
      FormFieldEventHandlers & {
        allowAdditions?: StrictDropdownProps['allowAdditions'];
        onAddItem?: StrictDropdownProps['onAddItem'];
        /** Can be used to skip the internal "onFieldChange" logic */
        onChange?: StrictDropdownProps['onChange'];
        /** For static options, prefer the use of a `extra.options` property on the form definition */
        options?: Array<StrictDropdownItemProps>;
      }
  > = observer(
    ({
      field,
      disabled,
      options = _.get(field, 'extra.options', []),
      ...rest
    }) => {
      if (!field) return null;

      const onFieldChange = (e, { value }) => {
        field.set(value);
        if (_.isFunction(rest.onFieldChange)) {
          rest.onFieldChange(e, { value });
        }
      };

      const onFieldAdd = (e, data) => {
        const { value } = data;

        try {
          if (_.has(field, 'extra.options')) {
            const extra = field.get('extra');
            extra.options.push(value);
            field.set('extra', extra);
          }
        } catch (err) {
          log.error('Failed to Add Option to Builtin Field options');
        }

        (rest.onChange || onFieldChange)(e, data);

        if (_.isFunction(rest.onAddItem)) {
          rest.onAddItem(e, data);
        }
      };

      const opts = _.map(options, (v) =>
        _.isString(v)
          ? {
              key: v,
              text: _.capitalize(v),
              value: v,
            }
          : v,
      );

      return (
        <Dropdown
          placeholder={field.placeholder}
          {...rest}
          disabled={disabled}
          onAddItem={rest.allowAdditions ? onFieldAdd : undefined}
          onChange={rest.onChange || onFieldChange}
          onFocus={
            disabled
              ? _.noop
              : this.registerFocus({ field, key: rest.focusKey })
          }
          options={opts}
          selection={true}
          value={field.value}
        />
      );
    },
  );

  FieldIndependentSelect: React.FC<FormFieldBase & IndependentSelectProps> =
    observer(
      ({
        field,
        objFieldKey,
        storeName,
        onSelect,
        content,
        text,
        placeholder = field?.placeholder,
        query = {},

        multiple,

        ...rest
      }) => {
        const defaultContent = ({ title, label, name, displayName }) => (
          <span>{title || label || name || displayName}</span>
        );

        /** "Selected" Logic
         * This logic belongs in the independent select control, however, since it is
         * as class based component, I'm implementing the logic here. In short, this
         * ensures that the currently selected item is included in the list of available
         * items, otherwise, the selected label does not get rendered
         */
        const [selectedObjs, setSelectedObjs] = useState([]);

        useEffect(() => {
          const loadSelectedObjs = async (vals) => {
            const selectedItems = await Promise.map(vals, async (selected) => {
              const uuid = selected?.uuid ?? selected;

              return (
                _.find(selectedObjs, { uuid }) ??
                dispatch(`${storeName}.get`, uuid ?? selected, {
                  select: false,
                })
              );
            });

            log.debug('INPUT Setting selected items', {
              selectedItems,
            });
            setSelectedObjs(selectedItems);
          };

          log.info('Value changed to: ', { val: field.value });
          if (field?.value && _.isArray(field.value)) {
            loadSelectedObjs(field.value);
          } else if (field?.value) {
            loadSelectedObjs([field.value]);
          }
        }, [field?.value]);

        if (!field) return null;

        const disabled = rest.disabled || rest.readOnly;

        return (
          <IndependentSelect
            {...rest}
            clearable={!disabled && rest.clearable}
            content={content ?? defaultContent}
            disabled={disabled}
            multiple={!!multiple}
            onSelect={(selected) => {
              const objField = this.form.select(objFieldKey, null, false);
              if (!_.isEmpty(selected)) {
                if (multiple) {
                  field.set(_.map(selected, 'uuid'));
                } else {
                  field.set(_.get(selected, 'uuid'));
                }

                if (objField) {
                  objField.set(selected);
                }
              } else {
                field.clear();

                if (objField) {
                  objField.clear();
                }
              }
              setSelectedObjs(multiple ? selected : [selected]);

              onSelect?.(selected);
            }}
            placeholder={_.isEmpty(field.value) ? placeholder : undefined}
            query={query}
            selected={multiple ? undefined : field.value}
            selectedObjs={
              this.form.has(objFieldKey)
                ? this.form.$(objFieldKey).value
                : selectedObjs
            }
            selectedValues={multiple ? field.value : undefined}
            storeName={storeName}
            text={text}
          />
        );
      },
    );

  setFieldSelectValue({
    field,
    val,
    multiple,
  }: {
    field: MobxReactFormField;
    multiple?: boolean;
    val?: unknown;
  }) {
    if (!val) {
      field.clear();
      return;
    }
    if (multiple) {
      if (_.isArray(val)) {
        field.clear();
        const value = _.compact(
          _.map(val, (item) => _.get(item, 'uuid', item)),
        );
        value.forEach((newValue) => field.add({ value: newValue }));
      } else {
        field.clear();
        _.compact([_.get(val, 'uuid', val)]).forEach((newValue) =>
          field.add({ value: newValue }),
        );
      }
    } else {
      field.set(_.get(val, 'uuid', val));
    }
  }

  FieldSelect: React.FC<FormFieldBase & IBaseSelectProps> = observer(
    ({
      field,
      storeName,
      list,
      searchValue,
      placeholder,
      onSelect = _.noop,
      onClear = _.noop,
      disabled,
      ...rest
    }) => {
      if (!field) return null;

      const { rules } = field;
      let min = 0;
      let max = -1;
      let multiple = !!rest.multiple;

      const parts = rules.split('|');
      _.each(parts, (part) => {
        const [key, val] = part.split(':');
        if (/^min$/i.test(key)) {
          min = _.toSafeInteger(val);
        }
        if (/^max$/i.test(key)) {
          max = _.toSafeInteger(val);
        }
        if (/^array$/i.test(key)) {
          multiple = true;
        }
      });

      log.debug(
        'Rendering FieldSelect with min:%d, max:%d, multiple: %s',
        min,
        max,
        multiple,
      );

      return (
        <BaseSelect
          {...rest}
          disabled={disabled}
          list={list}
          multiple={multiple}
          onClear={
            disabled
              ? _.noop
              : () => {
                  this.setFieldSelectValue({ field, multiple });

                  if (_.isFunction(onClear) && onClear !== _.noop) {
                    onClear();
                  } else {
                    onSelect();
                  }
                }
          }
          onSelect={
            disabled
              ? _.noop
              : (val) => {
                  this.setFieldSelectValue({ field, multiple, val });

                  onSelect(val);
                }
          }
          placeholder={placeholder}
          searchValue={searchValue}
          selected={!multiple ? rest.selected || field.value : undefined}
          selectedValues={
            multiple
              ? rest.selectedValues || rest.selected || field.value
              : undefined
          }
          storeName={storeName}
        />
      );
    },
  );

  /**
   * ### updateTimesWithDate
   * Used to propagate a change in the date onto "time" fields.
   *
   * @important This does **not** handle overnight shifts, and will
   * blindly set the date of the start & end fields to that selected.
   *
   * @param {*} value
   * @memberof Inputs
   */
  updateTimesWithDate(field: MobxReactFormField, value?: MomentInput) {
    const timeFields = _.compact([
      field.key !== 'start' && this.form.has('start') && this.form.$('start'),
      field.key !== 'end' && this.form.has('end') && this.form.$('end'),
    ]);

    const day = moment(value);

    _.each(timeFields, (f) => {
      const dt = f.value ? moment(f.value) : moment();

      if (!f.value && f.extra?.default) {
        dt.set(f.extra.default);
      }

      dt.set({ date: day.date(), month: day.month(), year: day.year() });

      f.set(dt.toDate());
    });
  }

  /**
   * ## FieldDatePicker [type="date"]
   * Renders a combination Date-Time Picker Form Field Component
   *
   * @memberof Inputs
   *{
   *   field = {}, :
   *   minDate = true, : the lower bound for the date picker
   *   alt, : @deprecated use the alt date picker interface
   *   disabled, : Disallow editing or selection
   *   noDefault, : Allow an empty value
   *   noCascade, : Do _not_ propagate a date change to the start & end fields on the form
    }
   *
   * @memberof Inputs
   */
  FieldDatePicker: React.FC<FormFieldBase> = observer(
    ({
      field,
      minDate = true,
      alt,
      disabled,
      noDefault,
      noCascade,
      ...rest
    }) => {
      if (!field) return null;

      const value = field.value ? moment(field.value) : moment();

      const onChange = (e, newValue) => {
        log.info('Calendar Date Changed: ', { newValue });

        sync(this.form, field.key)(e, newValue);

        if (!noCascade && (this.form.has('start') || this.form.has('end'))) {
          this.updateTimesWithDate(field, newValue);
        }
      };

      let minDateValue;

      if (minDate === true) {
        minDateValue = moment().toDate();
      } else if (moment(minDate).isValid()) {
        minDateValue = moment(minDate).toDate();
      }

      const momentValue = moment(field.value);
      const hasValue = !!field.value;

      let selectedVal;

      if (hasValue || !noDefault) {
        selectedVal = momentValue.toDate();
      }

      if (alt) {
        /** @deprecated */
        return (
          <div className="w-100">
            <DateTime
              closeOnSelect={true}
              disabled={disabled}
              minDate={minDateValue}
              onChange={disabled ? _.noop : onChange}
              onFocus={
                disabled
                  ? _.noop
                  : this.registerFocus({ field, key: rest.focusKey })
              }
              renderInput={(props, openCalendar) => (
                <Input
                  {...rest}
                  className="w-100"
                  error={!!field.error}
                  name={field.name}
                  onClick={
                    disabled
                      ? _.noop
                      : () => {
                          openCalendar();
                        }
                  }
                  value={
                    !!field.value && momentValue.isValid()
                      ? moment(field.value).format('l')
                      : ''
                  }
                />
              )}
              timeFormat={false}
              value={value}
            />
          </div>
        );
      }

      return (
        <div className="wrapped-datepicker w-100">
          <DatePicker
            {...rest}
            className="w-100 mb2"
            customInput={
              <Input
                {...rest}
                className="w-100"
                error={!!field.error}
                name={field.name}
                value={
                  !!field.value && momentValue.isValid()
                    ? moment(field.value).format('l')
                    : ''
                }
              />
            }
            disabled={disabled}
            minDate={minDateValue}
            name={field.name}
            onChange={disabled ? _.noop : (val, e) => onChange(e, val)}
            onFocus={
              disabled
                ? _.noop
                : this.registerFocus({ field, key: rest.focusKey })
            }
            selected={selectedVal}
            value={selectedVal}
          />
        </div>
      );
    },
  );

  /**
   * ## FieldTimePicker [type="time"]
   * Renders a Time Picker Form Field Component
   *
   * @memberof Inputs
   */
  FieldTimePicker: React.FC<FormFieldBase> = observer(
    ({ field, minDate = true, minutes = 15, disabled, noDefault, ...rest }) => {
      if (!field) return null;

      const hasValue = !!field.value;

      let value;

      if (hasValue || !noDefault) {
        value = roundMinutesTo({ initialValue: field.value, minutes });
      }

      let minTime;
      let maxTime;

      const focusKey = _.get(rest, 'focusKey', field.key);

      if (!value || moment().isSame(value, 'day')) {
        minTime = moment();
        maxTime = moment().set({ hours: 23, minutes: 59 });
      }

      log.verbose('ATP: ', { maxTime, minDate, minTime });

      const onChange = (e, data) => {
        log.info('Time Changed: ', { data, e });

        const interimTime = moment(data.value, 'LT');

        if (interimTime.isValid()) {
          sync(this.form, field.key)(e, { value: interimTime });

          if (this.form.has(this.popupKey)) {
            this.form.$(this.popupKey).set(false);
          }
        }
      };

      const onBlur = (e) => {
        if (_.get(e, 'relatedTarget.id') === focusKey) {
          return;
        }

        if (
          this.form.has(this.popupKey) &&
          this.form.$(this.popupKey).value === focusKey
        ) {
          this.form.$(this.popupKey).set(false);
        }
      };

      return (
        <div>
          <Input
            className="w-100"
            disabled={disabled}
            error={!!field.error}
            name={field.name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={this.registerFocus({ field, key: rest.focusKey })}
            value={value?.format?.('LT') ?? ''}
          />
          {this.form.has(this.popupKey) &&
            this.form.$(this.popupKey).value === focusKey && (
              <div
                className="w1 h1 relative top-0 overflow-visible"
                id={focusKey}
                // This enables the blur/focus logic to work as expected between
                // the input field (above) and the TimeSelect (below)
                onBlur={onBlur}
                role="button"
                tabIndex={0}
              >
                <div
                  className="bg-white"
                  style={{ transform: 'translateX(-20%)', width: '24rem' }}
                >
                  <TimeSelect
                    fieldKey={field.key}
                    form={this.form}
                    maxTime={maxTime}
                    minTime={minTime}
                    value={field.value}
                  />
                </div>
              </div>
            )}
        </div>
      );
    },
  );

  /**
   * ## FieldDateTimePicker [type="date-time"]
   * Renders a combination Date-Time Picker Form Field Component
   *
   * Can be used in "date only" or "time only" mode by toggling the `showDate` and `showTime` props
   *
   * @memberof Inputs
   */
  FieldDateTimePicker: React.FC<FormFieldBase> = observer(
    ({
      fieldKey,
      field = this.form.$(fieldKey),
      minutes = 15,
      styles = shiftFormStyles,
      showDate = true,
      showTime = true,
      startFieldKey,
      endFieldKey,
      onChange,
    }) => {
      if (!field) return null;

      const value = !field.value
        ? roundMinutesTo({ initialValue: field.value, minutes })
        : moment(field.value);

      const propagateChange = (newValue) => {
        const newVal = moment(newValue);

        if (this.form.has(endFieldKey) && fieldKey !== endFieldKey) {
          const endField = this.form.$(endFieldKey);

          if (endField.value) {
            const endVal = moment(endField.value);
            const duration = moment.duration(endVal.diff(newValue)).asHours();
            if (duration > 24) {
              endField.set(
                endVal
                  .set({
                    dayOfYear: newVal.dayOfYear(),
                    year: newVal.year(),
                  })
                  .toDate(),
              );
            }
            /** If start date is after end date then only end date will update */

            if (newVal.isAfter(endField.value)) {
              endField.set(
                endVal
                  .set({
                    dayOfYear: newVal.dayOfYear(),
                    year: newVal.year(),
                  })
                  .toDate(),
              );
            }
            /** Afte Updating Date if still start date is after end date then full end date and time will update */

            if (newVal.isAfter(endField.value)) {
              endField.set(newVal.toDate());
            }
          } else {
            endField.set(newVal.toDate());
          }
          const rule = `required|date|moment_same_or_after:${newVal}`;
          endField.set('rules', rule);
        } else if (this.form.has(startFieldKey) && fieldKey !== startFieldKey) {
          const startField = this.form.$(startFieldKey);
          if (startField.value) {
            const startVal = moment(startField.value);

            const duration = moment.duration(newVal.diff(startVal)).asHours();

            if (duration > 24) {
              startField.set(
                startVal
                  .set({
                    dayOfYear: newVal.dayOfYear(),
                    year: newVal.year(),
                  })
                  .toDate(),
              );
            }

            /** If end date is before start date then only Start date will update */
            if (newVal.isBefore(startField.value)) {
              startField.set(
                startVal
                  .set({
                    dayOfYear: newVal.dayOfYear(),
                    year: newVal.year(),
                  })
                  .toDate(),
              );
            }
            /** Afte Updating Date if still end date is before start date then full start date time will update */

            if (newVal.isBefore(startField.value)) {
              startField.set(newVal.toDate());
            }
          } else {
            startField.set(newVal.toDate());
          }
          const rule = `required|date|moment_same_or_after:${startField.value}`;
          field.set('rules', rule);
        }
      };

      const datePicker = showDate && (
        <DatePicker
          className={cx({
            [styles?.date]: !!styles?.date,
            [styles?.dateOnly]: !showTime,
          })}
          customInput={<Input />}
          minDate={moment().toDate()}
          name={field.name}
          onChange={(newValue, e) => {
            propagateChange(newValue);
            field.set(moment(newValue).toDate());
            return onChange?.(e, field.value, { scope: 'date' });
          }}
          selected={value ? moment(value).toDate() : undefined}
        />
      );

      const timePicker = showTime && (
        <TimePickerDropdown
          className={cx({
            'w-100': !showDate,
          })}
          onChange={(newValue, e) => {
            propagateChange(newValue);
            field.set(moment(newValue).toDate());
            return onChange?.(e, field.value, { scope: 'time' });
          }}
          runOnChangeOnMount={!!value}
          value={value}
        >
          <Form.Input
            className={cx({
              [styles?.time]: !!styles?.time,
              [styles?.timeOnly]: !showDate,
            })}
            error={field.hasError && field.isDirty}
            value={value ? moment(value).format('LT') : null}
          />
        </TimePickerDropdown>
      );

      if (datePicker && timePicker) {
        return (
          <div className="w-100 wrapped-datepicker flex flex-row items-center">
            <div className="w-50">{datePicker}</div>
            <div className="w-50">{timePicker}</div>
          </div>
        );
      }

      return (
        <div className="w-100 wrapped-datepicker flex flex-row items-center">
          {datePicker}
          {timePicker}
        </div>
      );
    },
  );

  FieldGooglePlaces: React.FC<FormFieldBase> = observer(
    ({
      field,
      options = {},
      valueKey = field.name,
      onSelect,
      geocoded = false,
      ...rest
    }) =>
      !!field && (
        <div className="w-100 flex items-center">
          <GoogleAutocomplete
            className="w-100 flex-shrink-1"
            handleSelect={(result) => {
              this.updateLocation(result, field, geocoded);
              onSelect?.(result);
            }}
            onFocus={this.registerFocus({ field, key: rest.focusKey })}
            options={options}
            semantic={true}
            value={this.form.$(valueKey).value}
          />
          <this.InlineFieldError
            className="ph3"
            field={this.form.$(valueKey)}
          />
        </div>
      ),
  );

  FieldRichTextEditor: React.FC<FormFieldBase> = observer(
    ({ field, ...rest }) =>
      !!field && (
        <div className="w-100 flex items-center">
          <RichTextEditor
            {...rest}
            className="w-100"
            contentState={field.value}
            editorClassName="min-h4 ph1"
            isHTML={true}
            onStateChange={(state) => {
              field.set('value', state);
              if (_.isFunction(field.onChange)) {
                field.onChange(state);
              }
            }}
            stripPastedStyles={true}
          />
        </div>
      ),
  );

  formCustomData = (DataRowComponent) =>
    observer(
      ({
        field,
        proto = { value: '' },
        maxItems = 3,
        hideAdd,
        addNewText = 'Add New',
        ...args
      }) => {
        function addCustomData() {
          if (!maxItems || field.value.length < maxItems) {
            field.add(_.clone(proto));
          }
        }

        function deleteCustomData(key) {
          const { minCount, doSave } = args;
          if (!minCount || field.value.length > minCount) {
            field.del(key);

            if (_.isFunction(doSave)) {
              doSave();
            }
          }
        }

        // log.info('component: ', DataRowComponent === CustomTaskData);

        return (
          <div>
            {field.map(
              (data, index) =>
                data && (
                  <DataRowComponent
                    form={this.form}
                    {...args}
                    {...{
                      data,
                      index,
                      onDelete: deleteCustomData,
                    }}
                    key={data.key || index}
                  />
                ),
            )}

            {!hideAdd && (!maxItems || field.value.length < maxItems) && (
              <Label as="a" color="blue" onClick={addCustomData}>
                <div>
                  <Icon name="plus" />
                  {addNewText}
                </div>
              </Label>
            )}
          </div>
        );
      },
    );

  Group: React.FC<
    Partial<FormFieldBase> &
      StrictFormGroupProps & {
        /** Applied to the wrapping semantic-ui `Segment` component */
        className?: string;
        /** Override the specified field's label property */
        label?: string | LabelProps;
        /** Don't wrap children in an additional `<Form.Group>` tag */
        noGroup?: boolean;
        /** Hide the label */
        noLabel?: boolean;
      }
  > = observer(
    ({
      field: srcField,
      fieldKey,
      label: srcLabel,
      className,
      noLabel,
      noGroup,
      children,
      ...rest
    }) => {
      let showSegment = true;
      if (
        _.isEmpty(srcLabel) &&
        _.isEmpty(srcField) &&
        (_.isEmpty(fieldKey) || _.isEmpty(this.form))
      ) {
        showSegment = false;
      }
      let field;

      try {
        field = srcField ?? this.form.$(fieldKey);
      } catch (err) {
        field = null;
      }
      const label = srcLabel || field?.label;

      const groupContent = noGroup ? (
        children
      ) : (
        <Form.Group {...rest}>{children}</Form.Group>
      );

      const innerContent = (
        <>
          {!noLabel && (
            <this.FieldLabel
              field={field}
              label={label}
              {...rest}
              className="pb2 ttu black-60"
            />
          )}

          {groupContent}
        </>
      );

      return showSegment ? (
        <Segment className={cx('flex-column flex', className)}>
          {innerContent}
        </Segment>
      ) : (
        innerContent
      );
    },
  );

  Field = observer<React.FC<FormFieldProps>>(
    ({
      field: srcField,
      width = 16,
      fieldKey = srcField?.key,
      type,
      children,
      bare,
      noLabel: srcNoLabel,
      ...args
    }) => {
      if (
        _.isEmpty(srcField) &&
        (_.isEmpty(fieldKey) || _.isEmpty(this.form))
      ) {
        return null;
      }
      let field;

      try {
        field = srcField ?? this.form.$(fieldKey);
      } catch (err) {
        field = null;
      }

      if (_.isEmpty(field)) return null;

      let InputNode;
      let t = type ?? field.extra?.inputType;
      let noLabel = srcNoLabel;

      if (_.isUndefined(children)) {
        switch (t) {
          case 'textarea':
            InputNode = this.FieldTextArea;
            break;
          case 'time':
            InputNode = this.FieldTimePicker;
            t = undefined;
            break;
          case 'date':
            if (args.alt) {
              this.form.log?.warn('Using deprecated Date Picker');
            }
            InputNode = this.FieldDatePicker;
            t = undefined;
            break;
          case 'date-time':
            InputNode = this.FieldDateTimePicker;
            t = undefined;
            break;
          case 'phone':
          case 'tel':
            InputNode = this.FieldPhoneNumber;
            t = undefined;
            break;
          case 'phone-country':
            InputNode = this.FieldCountryPhoneNumber;
            t = undefined;
            break;
          case 'location':
            InputNode = this.FieldGooglePlaces;
            t = undefined;
            break;
          case 'customDataMap':
            InputNode = this.formCustomData(args.dataNode);
            t = undefined;
            break;
          case 'storeSelect':
            InputNode = this.FieldIndependentSelect;
            t = undefined;
            break;
          case 'select':
            InputNode = this.FieldSelect;
            t = undefined;
            break;
          case 'dropdown':
            InputNode = this.FieldDropdown;
            t = undefined;
            break;
          case 'checkbox':
            InputNode = this.FieldCheckBox;
            noLabel = true;
            t = undefined;
            break;
          case 'trinary':
            InputNode = this.FieldTrinaryToggle;
            t = undefined;
            break;
          case 'toggle':
            InputNode = this.FieldToggle;
            t = undefined;
            break;
          case 'yn':
            InputNode = this.FieldYNToggle;
            t = undefined;
            break;
          case 'radio':
            InputNode = this.FieldRadio;
            t = undefined;
            break;
          case 'currency':
            InputNode = this.FieldCurrency;
            t = undefined;
            break;
          case 'richTextEditor':
            InputNode = this.FieldRichTextEditor;
            t = undefined;
            break;
          default:
            InputNode = this.FieldInput;
            break;
        }
      } else if (!_.isUndefined(type)) {
        log.warn(
          'Cannot specify both `children` and `type` on Form.Field. Defaulting to `children`',
        );
      }

      if (bare) {
        return (
          <Form.Field disabled={args.disabled} width={width}>
            {_.isUndefined(children) ? (
              <InputNode {...args} {...{ field, type: t }} />
            ) : (
              children
            )}
          </Form.Field>
        );
      }

      return (
        <Form.Field
          className={args.fieldClassName}
          disabled={args.disabled}
          width={width}
        >
          {!noLabel && <this.FieldLabel field={field} {...args} />}
          {_.isUndefined(children) ? (
            <InputNode {...args} {...{ field, type: t }} />
          ) : (
            children
          )}
          <this.FieldErrorLabel
            field={args.valueKey ? this.form.$(args.valueKey) : field}
          />
        </Form.Field>
      );
    },
  );

  popupKey = 'focusedField';

  registerFocus({ popupKey = this.popupKey, field = {}, key = field.key }) {
    return () => {
      if (this.form.has(popupKey)) {
        log.debug(`Registering Focus on the "${key}" field`);
        this.form.$(popupKey).set(key);
      }
    };
  }
}

function roundMinutesTo({ initialValue, minutes }) {
  let value = initialValue;

  if (_.isString(value) || _.isDate(value)) {
    value = moment(value || undefined);
  }

  if (!moment.isMoment(value)) {
    value = moment();
    if (value.hours() > 15) value.add({ days: 1 }).set({ hours: 9 });
    else {
      value.set({ hours: value.hours() + 1 });
    }

    value.set({ minutes: 0, seconds: 0 });
  }

  let currentMinutes = value.minutes();

  if (_.isNumber(minutes)) {
    currentMinutes = _.round(value.minutes() / minutes) * minutes;
  }

  value.set({ minutes: currentMinutes, seconds: 0 });

  return value;
}
