import type {
  IBaseItem,
  IVideoInterviewQuestion,
} from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';
import { IVideoReview } from './videoReviews';

export type IVideoResponse = IBaseItem & {
  _id: string;
  companyId: string;
  createdAt: string;
  duration: number;
  question: IVideoInterviewQuestion;
  questionId: string;
  updatedAt: string;
  url: string;
  user: {
    displayName: string;
    uuid: string;
  };
  userId: string;
  uuid: string;
  videoReviews: IVideoReview[];
};

export default class VideoResponseStore extends BaseStore<IVideoResponse> {
  constructor() {
    const baseItem = {
      companyId: null,
      createdAt: null,
      duration: null,
      question: null,
      questionId: null,
      updatedAt: null,
      url: null,
      userId: null,
      uuid: null,
    };
    super({ baseItem, serviceName: 'videoresponses' });

    return this;
  }
}
