import { observer } from 'mobx-react';
import React from 'react';
import { Icon, Image } from 'semantic-ui-react';
import cx from 'classnames';
import styled from 'styled-components';

import { useStores } from '#/shared/hooks/useStores';

const LogoDiv = styled.div.attrs(({ className }) => ({
  className: cx('h-100 f4 flex justify-start items-center', className),
}))`
  user-select: none;
  cursor: 'home';
`;

const SiteLogo = observer(
  ({ collapsed, className }: { className?: string; collapsed?: boolean }) => {
    const { ui, routing } = useStores();

    return (
      <LogoDiv className={className}>
        {!global.IS_SSR && ui.breakpoints.xs && (
          <div className="h-100 ph2 flex items-center">
            <Icon
              className="black-70 pointer"
              name={`${ui.isNavVisible ? 'x' : 'bars'}`}
              onClick={() => ui.toggleNavVisible()}
            />
          </div>
        )}

        <div className="h-100 pl2 flex items-center">
          <Image
            className={cx('flex items-center justify-center')}
            onClick={() => routing.home()}
            src={
              collapsed
                ? '/static/img/iconOnWhite.png'
                : '/static/img/logoOnWhite.png'
            }
            style={{ height: '27px', objectFit: 'contain' }}
          />
        </div>
      </LogoDiv>
    );
  },
);

export default SiteLogo;
