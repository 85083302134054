import { action, observable, set } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.companyImageModal');

export default class SurveyReviewImageModal {
  @observable
  isOpen = false;

  @observable
  survey = {};

  @observable
  showCroppingTools = false;

  @observable
  enableCropping = false;

  @observable
  question = 'avatar';

  @observable
  file = null;

  @action
  setup({ survey, question, imageURL, fileRef, open = this.isOpen }) {
    this.enableCropping = (dispatch('auth.getUser') || {}).isSuperAdmin;
    this.survey = survey;
    this.question = question;

    try {
      dispatch('ui.avatarEditor.setup', {
        fileRef,
        imageURL,
        onSave: this.save,

        scale: 1.0,
      });

      this.open(open);
    } catch (err) {
      log.error('Failed to setup avatar editor', err);

      dispatch('ui.snackBar.error', 'Unable to edit image', {
        body: err.message,
      });

      this.clear();
    }
  }

  @action
  async save({ cropped } = {}) {
    let fileType = 'image/png';

    let file;
    let fileName;

    try {
      if (cropped) {
        const { data: dataURI, canvas } = dispatch(
          'ui.avatarEditor.getImageBlob',
        );

        fileType = dataURI.split(';')[0].replace('data:', '');

        file = await new Promise((resolve) => canvas.toBlob(resolve));
        fileName = this.getFileName(fileType);
      } else {
        if (!_.get(this.file, 'size')) {
          dispatch('ui.snackBar.open', 'Please Select an Image File');
          return;
        }

        file = this.file;
        fileName = this.getFileName(this.file.type);
        fileType = file.type;
      }

      const bucketService =
        process.env.NODE_ENV === 'production' ? 'RETAIL' : 'DEV';

      const result = await dispatch('s3.upload', {
        bucketService,
        file,
        fileType,
        id: fileName,
      });

      dispatch('ui.campaignWorkReview.editQuestionResponse', {
        question: this.question,
        value: result.data.url,
      });

      this.clear();
    } catch (err) {
      log.error('Upload Failed', err, { fileName });

      dispatch('ui.snackBar.error', 'Failed to save image', {
        body: err.message,
      });
    }
  }

  getFileName(fileType) {
    if (!/image\//i.test(fileType)) {
      dispatch('ui.snackBar.open', 'Please select an Image File');
      throw new Error('Please select an image file', {
        fileType,
      });
    }

    return `${this.survey.uuid}-${this.question.name}`;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    dispatch('ui.avatarEditor.clear');
    this.survey = {};
    this.isOpen = false;
    this.question = 'avatar';
    this.showCroppingTools = false;
    this.file = {};

    this.fileURI = null;
  }

  @action
  setImage(image) {
    dispatch('ui.avatarEditor.setImage', image);
  }

  @observable
  fileURI = null;

  @action
  fileChanged(file) {
    set(this, 'file', file);

    this.fileURI = URL.createObjectURL(file);
  }

  @action
  toggleEdit() {
    dispatch('ui.avatarEditor.toggleEdit');
  }

  @action
  toggleCropping(val = !this.showCroppingTools) {
    this.showCroppingTools = val;
  }
}
