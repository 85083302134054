import BaseStore from './_baseStore';

// todo(mattshaffer11): move to types package
interface NotificationV2 {
  body: string;
  context: Record<string, unknown>;
  deliveredAt: string;
  messageTypeId: string;
  status: string;
  type: string;
  userId: string;
  uuid: string;
  vendor: string;
  version: number;
  viewedAt: string;
}

export default class NotificationsV2Store extends BaseStore<NotificationV2> {
  constructor() {
    super({
      baseItem: NotificationsV2Store.BASE_ITEM,
      serviceName: 'notifications/v2',
    });
    return this;
  }

  static BASE_ITEM: NotificationV2 = {
    body: '',
    context: {},
    deliveredAt: null,
    messageTypeId: '',
    status: '',
    type: '',
    userId: '',
    uuid: '',
    vendor: '',
    version: 1,
    viewedAt: null,
  };
}
