import type {
  IAssignment,
  IAssignmentQuery,
  IUser,
  ICompany,
} from '@shiftsmartinc/shiftsmart-types';

import { action, observable, computed, IObservableArray } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.PartnerDetails');

const shiftSort = (a, b) => {
  if (!a.start) return 1;
  if (!b.start) return -1;
  return new Date(a.start).getTime() - new Date(b.start).getTime();
};

export default class PartnerDetails {
  statusMap = {
    'All Shifts': [
      'Assigned',
      'Accepted',
      'Approved',
      'Completed',
      'PendingApproval',
      'Sent',
      'NoShow',
    ],
    Assigned: ['Assigned', 'Accepted', 'Approved'],
    Available: ['PendingApproval', 'Sent'],
    Completed: ['Completed'],
  };

  @observable dataLoading = {
    Bonuses: false,
    Certificates: false,
    Notes: false,
    Notifications: false,
    Payments: false,
    Pools: false,
    Shifts: false,
    Statistics: false,
    Summary: false,
    'Work And Education': false,
  };

  @action.bound setDataLoading(tabName, loading) {
    this.dataLoading[tabName] = loading;
  }

  @observable dataLoaded = {
    Bonuses: false,
    Certificates: false,
    Notes: false,
    Notifications: false,
    Payments: false,
    Pools: false,
    Shifts: false,
    Statistics: false,
    Summary: false,
    'Work And Education': false,
  };

  @action.bound setDataLoaded(tabName) {
    this.dataLoaded[tabName] = true;
  }

  @observable duration = '1 Week';

  @action.bound setDuration(duration) {
    this.duration = duration;
  }

  @observable userStatsData = [];

  @observable loading = false;

  @observable
  isDateCalendarOpen = false;

  @action
  toggleCalendarPopup(open = !this.isDateCalendarOpen) {
    this.isDateCalendarOpen = open;
  }

  @action.bound
  async fetchUserStats({ user, company }) {
    if (dispatch('auth.can', 'read', 'userstatsHistory')) {
      const query = {
        company,
        date: {
          $gte: moment().subtract(1, 'month').toDate(),
          $lte: moment().toDate(),
        },
        user,
      };

      try {
        this.setLoading(true);
        const response = await dispatch('userStatsHistory.find', query);
        const userStats = _(response)
          .map((stat) =>
            _.extend({}, stat, {
              attendance: Math.round(Number(stat.attendance) * 100),
              company: stat.company,
              date: moment(stat.date).format('L'),
              messageRead: Math.round(Number(stat.messageRead) * 100),
              onTime: Math.round(Number(stat.onTime) * 100),
              openShiftPickup: Math.round(Number(stat.openShiftPickup) * 100),
              reliability: Math.round(Number(stat.reliability) * 100),
              user: stat.user,
              uuid: stat.uuid,
            }),
          )
          .orderBy(['date'], ['asc'])
          .value();

        this.setLoading(false);
        this.setUserStatsData(userStats);
      } catch (error) {
        this.setLoading(false);
        log.error('Error Loading User Stats History :', error, {
          extra: {
            companyId: company,
            userId: _.get(user, 'uuid', user),
          },
        });
      }
    } else {
      log.debug('Logged in user does not have access to User Statistics');
    }
  }

  @action
  setup() {
    this.setActiveTab(_.first(this.menuTabs).key);
  }

  @action
  clear() {
    dispatch('partners.clearSelected');
    dispatch('userStats.clearSelected');
    this.setUserStatsData([]);
  }

  @observable activeTab;

  @computed
  get menuTabs() {
    const company = dispatch('auth.getCompany');
    const showBonusesFeature = _.get(
      company,
      'settings.featureFlags.enableBonusVisibilityInEmployerPortal',
    );
    const showShopTab = _.get(company, 'modules.retail');

    return _.compact([
      {
        index: 0,
        key: 'summary',
        title: 'Summary',
      },
      dispatch('auth.can', 'read', 'userstat') && {
        index: 0.1,
        key: 'shiftBasedStatsHistory',
        title: 'Shift Based Stats History',
      },
      dispatch('auth.can', 'read', 'userstat') && {
        index: 1,
        key: 'statistics',
        title: 'Statistics',
      },
      dispatch('auth.can', 'read', 'worker', 'pools') && {
        index: 2,
        key: 'pools',
        title: 'Pools',
      },
      dispatch('auth.can', 'create', 'assignment') && {
        index: 3,
        key: 'shifts',
        title: 'Shifts',
      },
      showShopTab && {
        index: 4,
        key: 'shops',
        title: 'Shops',
      },
      dispatch('auth.can', 'read', 'worker', 'experience') && {
        index: 6,
        key: 'work and education',
        title: 'Work And Education',
      },
      dispatch('auth.can', 'read', 'worker', 'certs') && {
        index: 7,
        key: 'certificates',
        title: 'Certificates',
      },
      showBonusesFeature && {
        index: 8,
        key: 'bonuses',
        title: 'Bonuses',
      },
      dispatch('auth.can', 'read', 'notes') && {
        index: 9,
        key: 'notes',
        title: 'Notes',
      },
      {
        index: 9,
        key: 'profileVideos',
        title: 'Profile Videos',
      },
      {
        index: 10,
        key: 'fraudViolations',
        title: 'Fraud Violations',
      },
    ]);
  }

  @action
  setActiveTab(tab) {
    this.activeTab = tab;

    if (!this.dataLoaded[tab]) {
      this.setDataLoading(tab, true);
    }
  }

  @action.bound
  setLoading(loading) {
    this.loading = loading;
  }

  @action.bound
  setUserStatsData(data) {
    this.userStatsData = data;
  }

  @observable shiftMode: 'calendar' | 'list' = 'calendar';

  @action.bound updateShiftMode(mode) {
    this.shiftMode = mode;
  }

  @observable shiftStatusFilter = 'All Shifts';

  @action.bound setShiftStatusFilter(status) {
    this.shiftStatusFilter = status;
  }

  @observable shiftStart = moment().startOf('week');

  @action.bound updateShiftStart(date) {
    this.shiftStart = date;
  }

  @observable shiftEnd = moment().endOf('week');

  @action.bound updateShiftEnd(date) {
    this.shiftEnd = date;
  }

  @action.bound
  async fetchUserShifts({ user, company }) {
    const query: IAssignmentQuery = {
      company: company.uuid,
      dataType: 'shift',
      start: {
        $gte: this.shiftStart.toDate(),
        $lte: this.shiftEnd.toDate(),
      },
      user: user.uuid,
    };

    if (this.statusMap[this.shiftStatusFilter]) {
      // Hack for handling invites
      query.status = {
        $in: this.statusMap[this.shiftStatusFilter],
      };
    }

    try {
      const scheduleQuery = {
        company: company.uuid,
        user: user.uuid,
        week: this.shiftStart.week(),
      };

      const schedule = await dispatch('userSchedule.find', scheduleQuery);

      log.debug('Loaded Partner Schedule', { schedule, scheduleQuery });
    } catch (error) {
      log.error('Failed to load user schedule', error);
    }
    try {
      return await dispatch('assignments.find', query, {
        clear: true,
      });
    } catch (error) {
      dispatch('ui.snackBar.error', 'Unable to load Shifts for Partner');
    }

    return null;
  }

  @action.bound
  async fetchUserVideoResponses({
    userId,
    companyId,
  }: {
    companyId: ICompany['uuid'];
    userId: IUser['uuid'];
  }) {
    const query = {
      $client: {
        populateVideoReviews: true,
      },
      companyId,
      userId,
    };

    const videoResponses = await dispatch('videoResponses.find', query, {
      clear: true,
    });

    return videoResponses;
  }

  // #region Shift Conflicts (with other companies)
  conflictShifts: IObservableArray<IAssignment> = observable([]);

  @action.bound setConflictShiftsList(shifts) {
    this.conflictShifts.replace(shifts);
    return Promise.resolve(this.conflictShifts);
  }

  async fetchConflictShifts({ user, company }) {
    const query: IAssignmentQuery = {
      company: {
        $ne: company.uuid,
      },
      dataType: 'shift',
      start: {
        $gte: this.shiftStart.toDate(),
        $lte: this.shiftEnd.toDate(),
      },
      status: {
        $in: this.statusMap[this.shiftStatusFilter],
      },
      user: user.uuid,
    };

    try {
      const { data: assignmentsList } = await dispatch(
        'assignments.runQuery',
        query,
      );
      return this.setConflictShiftsList(assignmentsList || []);
    } catch (error) {
      return Promise.reject(error);
    }
  }
  // #endregion Shift Conflicts (other companies)

  // #region Approved Timeoff Requests
  async fetchTimeoffs({ user, company }) {
    const query = {
      company: company.uuid,
      status: 'approved',
      user: user.uuid,
    };

    try {
      return await dispatch('timeOff.find', query, { clear: true });
    } catch (error) {
      dispatch('ui.snackBar.error', 'Unable to load Time Off');
    }

    return null;
  }
  // #endregion Approved Timeoff Requests
}
