import React from 'react';
import { observable, action } from 'mobx';
import _ from 'lodash';
import { ToastContainerProps, toast } from 'react-toastify';

import AvocadoToast, {
  ToastProps,
} from '#/shared/components/utils/AvocadoToast';

declare type SnackBarProps = ToastProps & {
  duration?: number;
  level?: SnackBarLevels;
  pauseOnFocusLoss?: ToastContainerProps['pauseOnFocusLoss'];
};

declare type SnackBarLevels = 'info' | 'warn' | 'error';

declare type LegacyHeaderDef = { message: ToastProps['header'] };
declare type SnackBarHeader = ToastProps['header'] | LegacyHeaderDef;

export default class SnackBar {
  @observable
  duration = 3000;

  styleOptions = {
    error: {
      duration: 10000,
    },
    warn: {
      duration: 10000,
    },
  };

  @action.bound
  open(header: SnackBarHeader, { duration, ...rest }: SnackBarProps = {}) {
    this.render({
      duration,
      props: {
        ...rest,
        header: ((header as LegacyHeaderDef)?.message ?? header) as string,
      },
    });
  }

  @action.bound
  error(header: SnackBarHeader, { duration, ...rest }: SnackBarProps = {}) {
    this.render({
      duration,
      messageType: 'error',
      props: {
        ...rest,
        header: ((header as LegacyHeaderDef)?.message ?? header) as string,
      },
    });
  }

  @action
  warn(header: SnackBarHeader, { duration, ...rest }: SnackBarProps = {}) {
    this.render({
      duration,
      messageType: 'warn',
      props: {
        ...rest,
        header: ((header as LegacyHeaderDef)?.message ?? header) as string,
      },
    });
  }

  @action
  render({
    props,
    messageType = 'info',
    duration,
    pauseOnFocusLoss = true,
  }: {
    duration: number;
    messageType?: SnackBarLevels;
    pauseOnFocusLoss?: ToastContainerProps['pauseOnFocusLoss'];
    props: ToastProps;
  }) {
    const autoClose = this.getAutoClose(duration, messageType);
    const elem = React.createElement(AvocadoToast, props);
    return (toast[messageType] || toast.info)(elem, {
      autoClose,
      pauseOnFocusLoss,
    });
  }

  // @deprecated
  @action
  setup({ message, messageType = 'info', duration }) {
    const autoClose = this.getAutoClose(duration, messageType);

    if (messageType && toast[messageType]) {
      toast[messageType](message, { autoClose });
    }

    toast.info(message, { autoClose });
  }

  getAutoClose(duration, messageType = 'info') {
    const { duration: durationSetting } = _.get(
      this.styleOptions,
      messageType,
      {},
    );
    return duration || durationSetting || this.duration;
  }

  @action
  close() {
    this.duration = 3000;
  }
}
