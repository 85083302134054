import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export type IConfigs = IBaseItem & Record<string, unknown>;

export default class ConfigsStore extends BaseStore<IConfigs> {
  constructor() {
    const baseItem = {
      configData: {},
      name: '',
    };
    super({
      baseItem,
      cacheSize: 100,
      idPath: 'name',
      searchFields: ['name'],
      serviceName: 'configs',
    });

    return this;
  }
}
