import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

import styles from '#/shared/styles/ShiftStatusChip.css';

const ShiftStatusChip = ({
  status,
  className,
  textClass,
  size,
  fluid,
  customStyle,
}) => (
  <div
    className={cx(
      styles.container,
      styles[_.toLower(customStyle ?? status)],
      size && styles[size],
      fluid && styles.fluid,
      className,
    )}
  >
    {!/indicator/i.test(size) && (
      <span className={cx(styles.text, textClass)}>{_.toUpper(status)}</span>
    )}
  </div>
);

ShiftStatusChip.propTypes = {
  className: PropTypes.string,
  fluid: PropTypes.bool,
  size: PropTypes.string,
  status: PropTypes.string.isRequired,
  textClass: PropTypes.string,
};

export default ShiftStatusChip;
