import type { ICompany } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { dispatch } from 'rfx-core';
import { action, toJS, observable } from 'mobx';

import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';
import {
  fields as addrFields,
  rules as addrRules,
  labels as addrLabels,
} from './addressFields';

const log = getChildLogger('forms.company');

export type CompanyFormInstance = CompanyForm;

class CompanyForm extends Form {
  constructor(setup, config) {
    super(
      setup,
      _.defaultsDeep(config, { options: { retrieveOnlyDirtyValues: true } }),
    );
  }

  @observable
  shouldRedirect = false;

  @action
  async setShouldRedirect(value) {
    this.shouldRedirect = value;
  }

  @observable
  afterFinish = null;

  @action
  async setAfterFinish(value) {
    this.afterFinish = value;
  }

  hooks = () => ({
    onError: this.defaultErrorHook,
    onSuccess: this.onSuccess,
  });

  async onSuccess(form) {
    let storeAction = 'companies.create';
    let successMsg = 'New Company Created';

    const isEditing = !!form.$('uuid')?.value;

    if (isEditing) {
      storeAction = 'companies.update';
      successMsg = 'Company Saved';
    }

    const data = isEditing ? form.getDirtyValues() : form.values();

    if (_.isEmpty(data)) {
      log.debug(`No Changes to ${storeAction}. Returning `);

      dispatch('ui.snackBar.open', 'No Changes to Save');

      return dispatch('ui.companyEditModal.clear');
    }

    let company;
    try {
      let params = {};
      if (!isEditing) {
        params = {
          query: {
            $client: {
              setDefaultSettings: data.setDefaultSettings,
            },
          },
        };
      }

      company = await dispatch(storeAction, {
        data: this.dotify(data),
        id: form.$('uuid').value,
        params,
      });

      log.debug(`Completed ${storeAction} with result: `, company);
      form.update(company);

      if (!_.isEmpty(data.parent)) {
        dispatch('companies.loadHierarchy', { company });
      }

      dispatch('ui.snackBar.open', successMsg);
      // onCreate only?
      dispatch('ui.companyEditModal.setCompany', company);

      if (this.afterFinish) {
        this.afterFinish();
      }

      // if you are creating a new company, show messaging service page
      if (!isEditing) {
        dispatch('ui.companyEditModal.toggleMessagingServicePage');
      }
      // if you are editing a company, close the modal
      else {
        dispatch('ui.companyEditModal.clear');
      }
      if (this.shouldRedirect) {
        dispatch(
          'routing.push',
          `${dispatch('routing.isAdminRoute') ? '/admin/' : '/'}companies/${
            company.uuid
          }`,
        );
      }
    } catch (err) {
      form.invalidate(err.message);
      dispatch('ui.snackBar.error', err.message);
    }
    return company;
  }
}

// Note: If adding nested fields, especially to the `limits` schema, be sure to include the field
// by name here. If not added here, the form will be set to an `isDirty` state as "adding fields"
// to a field with subdocuments causes the field, and thus the form, to be marked as `isDirty`
const fields = _.union(
  [
    'uuid',
    'name',
    'abbr',
    'about',
    'address',
    'parent',
    'owner',
    'limits',
    'status',
    'isShiftsmartManaged',
    'setDefaultSettings',
    'loc',
    'loc.coordinates',
    'loc.type',
  ],
  addrFields,
);

const rules = {
  abbr: 'string',
  about: 'string',
  address: 'required',
  name: 'required|string|between:5,50',
  owner: 'string|required_without:uuid',
  parent: 'string',
  uuid: 'string',
  ...addrRules,
};

const labels = _.extend({
  abbr: 'Abbreviated Name',
  about: 'About the Company',
  address: 'Address',
  ...addrLabels,
  isShiftsmartManaged: 'Company is managed by Shiftsmart employees',
  name: 'Company Name',
  owner: 'Primary Account Holder',
  parent: 'Parent Company',
  setDefaultSettings: 'Apply Default Company Settings',
});

const defaults = {
  'loc.coordinates': [0, 0],
};

const extra = {
  status: {
    options: [
      { key: 'draft', text: 'Draft', value: 'draft' },
      { key: 'demo', text: 'Demo', value: 'demo' },
      { key: 'pending', text: 'Pending', value: 'pending' },
      { key: 'active', text: 'Active', value: 'active' },
      { key: 'archived', text: 'Archived', value: 'archived' },
    ],
  },
};

export function init(values = {}) {
  const initials: Partial<ICompany> = toJS(values);

  const coForm = new CompanyForm(
    {
      defaults,
      extra,
      fields,
      labels,
      rules,
      values: initials,
    },
    {
      options: {
        fallback: false,
      },
    },
  );

  return coForm;
}
