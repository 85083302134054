import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Popup, Icon, PopupProps, StrictIconProps } from 'semantic-ui-react';
import _ from 'lodash';

type CustomDelayConfig = {
  close?: number;
  open?: number;
};

export type PopupIconProps = StrictIconProps &
  Pick<
    PopupProps,
    | 'position'
    | 'inverted'
    | 'offset'
    | 'on'
    | 'popperModifiers'
    | 'open'
    | 'children'
    | 'wide'
  > & {
    delay?: CustomDelayConfig | number | boolean;
    tooltip?: PopupProps['content'];
    trigger?: ReactElement;
  };

/**
 * ## PopupIcon
 * @description
 * In the most basic use case, renders a Semantic UI Icon {@see Icon}, coupled with
 * a tooltip. Using this component rather than adhoc Popup components offers many
 * benefits, including a simplified API (tooltip + trigger), as well as consistent
 * appearance and a slight delay before the tooltip opens.
 *
 * ### Basic Usage
 * By default, any non-specified properties will be passed unchanged to a semantic-ui
 * `Icon` component, with the `tooltip` property accepting what will be rendered in
 * the popup.
 *
 * @example
 * ```
 * <PopupIcon
 *    name="info circle"
 *    tooltip="You can use this to create a simple tooltip icon"
 * />
 * ```
 *
 * @description
 * ### Advanced Popup Configuration
 * The following properties can be used to customize the tooltip popup:
 * - position: Where the tooltip should be rendered
 * - inverted: Enabled by default, disabling will render a white tooltip
 * - offset: Array indicating `[x, y]` offset of the tooltip in realtion to the icon or trigger
 * - popperModifiers: Advanced usage, {@see PopupProps.popperModifiers}
 * - on: whether to open the tooltip on `click` or `hover`
 * - open: programatically control whether the tooltip is opened or closed.
 *
 * ### Using a custom Trigger
 * In addition to the basic use case of passing {@see IconProps} to the component, you
 * can pass any valid DOM Elment as a `trigger` prop.
 *
 * ### Tooltip Contents as `children`
 * You can optionally pass the contents of your tooltip as a nested child
 * of the PopupIcon component. This is consistent with the semantic Popup
 * components API.
 */
const PopupIcon: React.FC<PopupIconProps> = observer(
  ({
    tooltip,
    position,
    inverted = true,
    offset,
    trigger: srcTrigger,
    popperModifiers,
    delay,
    on,
    open,
    children,
    wide,
    ...rest
  }) => {
    const trigger = srcTrigger ?? <Icon {...rest} />;

    if (_.isEmpty(tooltip ?? children)) {
      return trigger;
    }

    return (
      <Popup
        content={tooltip ?? children}
        inverted={inverted}
        mouseEnterDelay={
          delay ? (delay as CustomDelayConfig)?.open ?? 500 : 100
        }
        mouseLeaveDelay={
          delay ? (delay as CustomDelayConfig)?.close ?? 250 : 50
        }
        offset={offset}
        on={on ?? 'hover'}
        open={open}
        popperModifiers={popperModifiers}
        position={position}
        trigger={trigger ?? <Icon {...rest} />}
        wide={!!wide}
      />
    );
  },
);

PopupIcon.displayName = 'PopupIcon';

export default PopupIcon;
export { PopupIcon };
