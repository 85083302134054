import type { ICompany, IUser } from '@shiftsmartinc/shiftsmart-types';

import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import { Query } from '@feathersjs/feathers';

import { service } from '#/shared/app';

import WorkerStore from './workers';

export default class EmployerUserStore extends WorkerStore {
  constructor() {
    const baseItem = {};
    super({
      baseItem,
      serviceName: 'user',
      title: 'employerUsers',
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  @observable
  employerRolesFilter = ['employer', 'company-agent'];

  find(inputQuery = {}, opts = {}) {
    const { company, ...query } = _.clone(inputQuery);

    _.defaults(query, {
      roles: { $in: this.employerRolesFilter },
    });

    if (company) {
      return this.findByCompany({ company, opts, query });
    }

    return super.find(query, opts);
  }

  /**
   * When the input query includes the `company` property, augment
   * the search results with the full Company Agents query as constructed
   * by the `findByCompany` method.
   */
  getQueryForSearch(searchString: string, query?: Query): Query {
    const { company, ...q } = query ?? {};
    const superQuery = super.getQueryForSearch(searchString, q);

    if (company) {
      return this.findByCompany({
        company,
        opts: { getQuery: true },
        query: superQuery,
      });
    }

    return superQuery;
  }

  runQuery(inputQuery) {
    const query = _.clone(inputQuery);

    _.defaults(query, { roles: { $in: this.employerRolesFilter } });

    return super.runQuery(query);
  }

  @action
  async findByCompany({ company, status, query = {}, opts = {} }) {
    const companyUUID = _.get(company, 'uuid', company);

    let statuses = ['active'];

    if (!_.isEmpty(status)) {
      statuses = _.isArray(status) ? status : [status];
    }

    const agentTypeOptions = dispatch('companies.getAgentTypeOptions');

    const companyStatusQuery = {
      companyStatus: {
        $elemMatch: {
          company: companyUUID,
          roles: { $in: _.map(agentTypeOptions, 'value') },
          status: { $in: statuses },
        },
      },
      roles: { $in: this.employerRolesFilter },
    };

    if (!companyUUID) {
      delete companyStatusQuery.companyStatus;
    }

    if (_.has(query, 'companyStatus.$elemMatch')) {
      _.merge(
        companyStatusQuery.companyStatus.$elemMatch,
        query.companyStatus.$elemMatch,
      );
    }

    if (!companyUUID && opts.clearCompanyStatus) {
      // TODO: Pass this new opt from the EmployerUsers admin container
      // to clear the list when the company is cleared
      companyStatusQuery.companyStatus = undefined;
    }

    _.extend(companyStatusQuery, _.omit(query, ['companyStatus']));

    if (opts?.getQuery) {
      return companyStatusQuery;
    }

    this.log.debug('Querying Agents with Query: ', {
      query: companyStatusQuery,
    });

    return this.find(companyStatusQuery, opts);
  }

  /**
   * ## addToAgentList
   * It Manually add the agent to the employeUser.list when new agent will be added.
   * It should be called manually
   */
  @action.bound
  addToAgentList(user) {
    if (!_.find(this.list, { uuid: user.uuid })) {
      this.list.push(user);
    }
  }

  /**
   * ## createAgent
   * Creates a new agent record for a new or existing user.
   */
  async createAgent(data = null) {
    if (_.isEmpty(data)) {
      return Promise.reject('No Agent Data submitted');
    }

    const company =
      data.company ||
      dispatch('companies.retrieve', 'selected') ||
      dispatch('auth.getCompany');

    const companyId = _.get(company, 'uuid', company);

    if (_.isEmpty(companyId)) {
      dispatch('ui.snackBar.error', 'Unable to determine selected company');
      throw new Error('Unable to determine selected company');
    }

    let user;
    let existingUsers: Array<IUser>;

    if (data.uuid) {
      existingUsers = [
        await dispatch('users.get', data.uuid, { select: false }),
      ];
    } else {
      const query = {
        $or: [],
        $sort: { createdAt: 1 },
      };

      if (!_.isEmpty(data.phoneNumber)) {
        query.$or.push({ phoneNumber: data.phoneNumber });
      }

      if (!_.isEmpty(data.email)) {
        query.$or.push({ email: data.email });
      }

      ({ data: existingUsers } = !_.isEmpty(query.$or)
        ? await dispatch('users.runQuery', { query })
        : { data: [] });
    }

    // TODO: [EP-1400] Move this logic to the server
    const userRoles: IUser['roles'] = _.union(data.roles || [], [
      'user',
      'employer',
      'company-agent',
    ]);

    if (_.size(existingUsers)) {
      if (existingUsers.length > 1) {
        this.log.warn('Multiple Users found Matching email/phone combination', {
          existingUsers,
        });
      }
      const existingUser = _.first(existingUsers);

      if (existingUser.roles.includes('worker')) {
        this.log.error('Unable to register partner as Agent', {
          extra: { companyId, userId: existingUser.uuid },
        });
        throw new Error(
          'Unable to create agent already registered as a partner',
        );
      }

      const patchData: Partial<Record<keyof IUser | '$addToSet', unknown>> = {};

      // TODO: EP-1134 Determine how to handle dupe phone numbers or emails with existing
      // user accounts; Possible scenario with an employer having a single phone number
      // but multiple email addresses.
      if (existingUser.email !== data.email) {
        this.log.error(
          'Mismatched email in new agent',
          !global.IS_PRODUCTION && {
            extra: {
              existingEmail: existingUser.email,
              existingUser: existingUser.uuid,
              newAgent: data,
            },
          },
        );
      }
      if (existingUser.email !== data.email) {
        this.log.error(
          'Mismatched phoneNumber in new agent',
          !global.IS_PRODUCTION && {
            extra: {
              existingPhone: existingUser.phoneNumber,
              existingUser: existingUser.uuid,
              newAgent: data,
            },
          },
        );
      }

      _.each(['firstName', 'lastName', 'email', 'phoneNumber'], (prop) => {
        if (_.isEmpty(existingUser[prop])) {
          patchData[prop] = data[prop];
        }
      });

      if (_(existingUser.roles).difference(userRoles).size() > 0) {
        patchData.$addToSet = {
          roles: { $each: userRoles },
        };
      }

      if (!_.isEmpty(patchData)) {
        user = await this.update({ data: patchData, id: existingUser.uuid });
      } else {
        user = existingUser;
      }
    } else {
      const newAgent = data;
      newAgent.roles = userRoles;

      user = await service(this.serviceName).create(newAgent);
      this.log.debug('Created new Agent User', user);
    }

    const agent = await dispatch('companies.addAgent', {
      agent: user,
      company,
      companyRoles: data.companyRoles,
      sendWelcomeComms: /sendAt/.test(data.sendWelcome)
        ? data.sendAt
        : !!data.sendWelcome,
    });

    this.addToAgentList(agent);

    this.log.debug('Created agent!', { agent });

    return agent;
  }

  /**
   * ## updateAgent
   *
   * Updates a user who is already an agent for a company
   *
   * NOTE: This logic is HEAVILY dependent on being called from a form instance and
   * can not be used as a general "update agent" method.
   *
   * TODO: Update code to split the "form" logic from the "update agent for company" logic.
   */
  async updateAgent(values = null, form) {
    if (_.isEmpty(values)) {
      return Promise.reject('No Agent Data submitted');
    }

    const data = values;

    // form.each(field => {
    //   if (
    //     (!_.isArray(field.value) && field.isDirty) ||
    //     // The extra check is required due to mobx-react-form#450, resolved in v2.0.4
    //     (_.isArray(field.value) && !_.isEqual(field.initial, field.value))
    //   ) {
    //     _.extend(data, { [field.key]: field.value });
    //   }
    // });

    if (_.isEmpty(data)) {
      this.log.debug('No Changes');
      return null;
    }

    const userId = form.$('uuid').value;

    _.extend(data, { uuid: userId });
    const company =
      data.company ||
      form.$('company').value ||
      dispatch('companies.retrieve', 'selected') ||
      dispatch('auth.getCompany');

    const companyId = (company as ICompany)?.uuid ?? company;

    if (
      _.includes(values.companyRoles || [], 'survey-review') &&
      values.route !== '/retail'
    ) {
      data.route = '/retail';
    } else if (
      (_.isEmpty(values.route) && _.isEmpty(form.$('route').value)) ||
      (!_.isEmpty(data.companyRoles) &&
        !_.includes(data.companyRoles, 'survey-review') &&
        /\/retail/.test(values.route))
    ) {
      data.route = '/';
    }

    // lookup company status since it is not on the form
    const companyStatus = this.loadCompanyStatusRecord({
      companyId,
      workerId: userId,
    });

    const query = {};
    if (
      (data.companyRoles || data.companyStatus || data.route) &&
      !!companyStatus
    ) {
      _.extend(query, {
        companyStatus: {
          $elemMatch: {
            company: companyId,
          },
        },
      });

      if (data.companyRoles) {
        data['companyStatus.$.roles'] = _.uniq(data.companyRoles);
        delete data.companyRoles;
      }

      if (data.companyStatus) {
        data['companyStatus.$.status'] = data.companyStatus;
        delete data.companyStatus;
      }

      if (data.route) {
        data['companyStatus.$.route'] = data.route;
        delete data.route;
      }
    }

    let agent;
    if (companyStatus) {
      agent = await dispatch('users.update', { data, query });
    } else {
      agent = await dispatch('companies.addAgent', {
        agent: data,
        company,
        companyRoles: data.companyRoles,
        sendWelcomeComms: /sendAt/.test(data.sendWelcome)
          ? data.sendAt
          : !!data.sendWelcome,
      });
    }

    this.addToAgentList(agent);
    this.log.debug('Updated agent to new values', { agent });

    return agent;
  }

  async triggerWelcomeComms({ userId, companyId }) {
    if (_.isEmpty(userId)) {
      throw new Error('[triggerWelcomeComms] The `userId` param is required');
    }

    return this.update({
      data: {},
      id: userId,
      query: { $client: { sendWelcomeComms: true }, csrCompany: companyId },
    });
  }
}
