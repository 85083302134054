import { action, observable, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.RetailShopActions');

export default class RetailShopActions {
  @observable
  store = {};

  @observable
  campaign = {};

  @observable
  surveys = [];

  @observable surveyCount = 0;

  @observable
  status = 'Pending';

  @observable
  shopsQuery = {};

  @observable
  isPendingShopModalOpen = false;

  @action
  async setup({ store = {}, companyId }) {
    this.store = store;
    this.isPendingShopModalOpen = false;
    this.surveyCount = 0;

    this.shopsQuery.company = companyId;
    this.shopsQuery.store = store.uuid;
  }

  @action async setCampaign(val) {
    const campaignObj = _.isString(val)
      ? await dispatch('campaigns.get', val, { select: false })
      : val;

    runInAction(() => {
      this.campaign = _.isEmpty(campaignObj.uuid) ? {} : campaignObj;

      if (!_.isEmpty(this.campaign)) {
        this.loadSurveys();
      } else {
        this.shopsQuery = _.pick(this.shopsQuery, ['company', 'store']);
        this.surveys.clear();
      }
    });
  }

  async loadSurveys() {
    _.extend(this.shopsQuery, {
      campaign: this.campaign.uuid,
      status: this.status,
    });

    if (_.isEmpty(this.shopsQuery.campaign)) {
      log.error('Shops query must be populated');
      return;
    }

    const res = await dispatch('surveys.runQuery', {
      query: this.shopsQuery,
    });

    this.setSurveys(res);
  }

  @action setSurveys({ data: surveys, total } = {}) {
    if (_.isEmpty(surveys)) {
      this.surveys.clear();
    } else {
      this.surveys.replace(surveys);
    }

    this.surveyCount = total || 0;
  }

  @action
  togglePendingShopModal(arg = !this.isOpen, { campaign } = {}) {
    if (!arg) {
      this.campaign = {};
      this.surveys.clear();
      this.shopsQuery = _.pick(this.shopsQuery, ['company', 'store']);
      this.surveyCount = 0;
    } else {
      this.status = 'Pending';

      if (!_.isEmpty(campaign)) {
        this.setCampaign(campaign);
      } else {
        dispatch('campaigns.find', { $limit: 20 }, { clear: true });
      }
    }

    this.isPendingShopModalOpen = !!arg;
  }

  async cancelPendingShops() {
    if (
      _.isEmpty(this.shopsQuery.campaign) ||
      _.isEmpty(this.shopsQuery.status)
    ) {
      dispatch('ui.snackBar.error', 'Must filter by campaign first');
    }

    const res = await dispatch('surveys.update', {
      data: { status: 'Canceled' },
      params: { query: this.shopsQuery },
    });

    log.debug('Canceled Surveys: ', { res });

    dispatch('ui.snackBar.open', 'Canceled Shops', {
      body: `${res.length} pending shops have been canceled`,
    });
    this.togglePendingShopModal(false);
  }

  @action
  clear() {
    this.store = {};

    // Pending Shops Modal
    this.isPendingShopModalOpen = false;

    this.status = 'Pending';
    this.campaign = {};
    this.surveys.clear();
    this.shopsQuery = {};
    this.surveyCount = 0;
  }
}
