import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';

export default class DeletePartnerModal {
  @observable
  isOpen = false;

  @observable
  workerId = '';

  @observable
  deletedReason = '';

  @action
  setup({ workerId, open = this.isOpen }) {
    this.workerId = workerId;
    this.isOpen = open;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(field, value) {
    this[field] = value;
  }

  @action
  async save() {
    if (!this.deletedReason) {
      dispatch(
        'ui.snackBar.open',
        'Please include a reason for banning this partner',
      );
      return;
    }

    try {
      await dispatch('workers.remove', this.workerId);
    } catch (err) {
      dispatch(
        'ui.snackBar.error',
        'There was an issue banning this partner: ',
        {
          body: `: ${err?.message}`,
        },
      );
      return;
    }

    try {
      await dispatch('workers.update', {
        data: {
          deletedReason: this.deletedReason,
        },
        id: this.workerId,
      });
    } catch (err) {
      dispatch(
        'ui.snackBar.error',
        'There was an issue saving the reason for the ban: ',
        {
          body: `: ${err?.message}`,
        },
      );
      return;
    }

    this.clear();
  }

  @action
  clear() {
    this.workerId = '';
    this.deletedReason = '';
    this.isOpen = false;
  }
}
