import type { IWorkerReferral } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from '#/shared/stores/_baseStore';

export default class WorkerReferralStore extends BaseStore<IWorkerReferral> {
  constructor({
    serviceName = 'workerReferrals',
    title = 'workerReferral',
  } = {}) {
    super({
      baseItem: WorkerReferralStore.BASE_ITEM,
      serviceName,
      title,
    });
    return this;
  }

  static BASE_ITEM: IWorkerReferral = {
    ...BaseStore.BASE_ITEM,
    companyId: null,
    isEnabled: true,
    link: null,
    signups: [],
    userId: null,
  };
}
