import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

import NA from '#/shared/components/ssm/NA';

interface IDateTimeProps {
  format?: string;
  value: Date;
}
export const DateTime: React.FC<IDateTimeProps> = observer(
  ({ value, format = 'LT, l' }) =>
    value ? <span>{moment(value).format(format)}</span> : <NA />,
);

DateTime.displayName = 'DateTime';
export default DateTime;
