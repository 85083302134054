import { action, computed, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class ShiftReviewsModal {
  log = getChildLogger('ui.ShiftReviewsModal');

  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  selectedShift = '';

  @observable
  selectedAssignment = '';

  @observable
  rating = null;

  @observable
  hoverRating = null;

  @observable
  isHovering = false;

  @observable
  note = '';

  @observable
  count = 0;

  @observable
  company = {};

  @observable
  hideShiftList = false;

  @action
  async setup({ company = {}, open = this.isOpen, shift = {} }) {
    this.set('company', company);
    this.set('isLoading', true);

    const query = {
      $client: { populateUser: true },
      // ATTN: Assignment Status EP-5523
      $select: [
        'uuid',
        'user',
        'start',
        'end',
        'ref',
        'data.uuid',
        'data.title',
        'data.address',
        'data.addressRef',
        'company',
        'workerRating',
        'workerNotes',
      ],

      company: _.get(company, 'uuid', company),

      dataType: 'shift',

      status: 'Completed',

      workerRating: { $exists: false },
    };
    if (!_.isEmpty(shift)) {
      _.extend(query, { ref: shift.uuid });
      this.set('hideShiftList', true);
    } else {
      this.set('hideShiftList', false);
    }

    this.open(open);
    dispatch('assignments.setShiftModal', 'reviews');
    try {
      await dispatch('assignments.loadShiftModalAssignments', { query });
      this.setFirst();
    } catch (error) {
      dispatch('ui.snackBar.open', error.message);
    }
    this.set('isLoading', false);
  }

  @action
  async getCount() {
    const log = this.log.getChildLogger('getCount');
    const company = dispatch('auth.getCompany');
    if (!_.isEmpty(company)) {
      const query = {
        // ATTN: Assignment Status EP-5523
        $limit: 0,

        company: _.get(company, 'uuid', company),

        dataType: 'shift',
        status: 'Completed',
        workerRating: { $exists: false },
      };
      try {
        const response = await dispatch('assignments.runQuery', query);
        const count = _.get(response, 'total', 0);
        this.set('count', count);
      } catch (error) {
        log.error('Error Loading Pending Reviews Count: ', error);
      }
    }
  }

  @action.bound
  setSelectedAssignment(assignmentId) {
    this.selectedAssignment = assignmentId;
    this.rating = 0;
    this.note = '';
  }

  @action.bound
  setSelectedShift(shiftId) {
    this.selectedShift = shiftId;
    this.rating = 0;
    this.note = '';
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    _.set(this, key, value);
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  onHoverRating(value, hover) {
    this.set('hoverRating', value);
    this.set('isHovering', hover);
  }

  @action.bound
  async submitReview({ assignment, rating = 0, note = '' }) {
    return dispatch('assignments.rate', {
      assignment: assignment.uuid,
      note,
      rating,
      worker: assignment.user,
    });
  }

  @computed
  get shiftGroups() {
    const list = dispatch('assignments.retrieve', 'shiftModalList');
    return _.groupBy(list, 'ref');
  }

  @action.bound
  setFirst() {
    const keys = _.keys(this.shiftGroups);
    const selectedShift = _.first(keys);
    const assignments = this.shiftGroups[selectedShift];
    const selectedAssignment = _.first(assignments);
    if (selectedShift && selectedAssignment) {
      this.setSelectedShift(selectedShift);
      this.setSelectedAssignment(selectedAssignment.uuid);
    }
  }

  @action.bound
  doNext({ assignment }) {
    if (Object.keys(this.shiftGroups).length === 0) {
      return false;
    }
    const existing = _.get(this.shiftGroups, assignment.ref, []).filter(
      (a) => a.uuid !== assignment.uuid,
    );
    if (!_.isEmpty(existing)) {
      const nextAssignment = _.first(existing);
      this.setSelectedAssignment(nextAssignment.uuid);
    } else {
      const keys = _.keys(_.omit(this.shiftGroups, assignment.ref));
      const selectedShift = _.first(keys);
      const assignments = this.shiftGroups[selectedShift];
      const selectedAssignment = _.first(assignments);
      this.setSelectedShift(selectedShift);
      this.setSelectedAssignment(selectedAssignment.uuid);
    }

    return true;
  }

  @action
  clear(args = {}) {
    this.isOpen = !!args.open;
    this.selectedShift = '';
    this.selectedAssignment = '';
    this.rating = 0;
    this.note = '';
    dispatch('assignments.setShiftModal', '');
    this.getCount();
  }
}
