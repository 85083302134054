import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { action, observable } from 'mobx';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';
import cx from 'classnames';

import accordion from '#/shared/styles/accordion.css';
import buttons from '#/shared/styles/buttons.css';
import { getChildLogger } from '#/shared/utils/client.logger';

import PopupIcon from './PopupIcon';

const log = getChildLogger('Accordion');

/** Accordion.jsx
 * -----------------------------------------------------------------------------------------
 * @extends {Component<Props>} FilterableTagList
 * @prop {bool} defaultExpanded: Should the accordion be expanded by default
 * @prop {bool} vertical: Controls how the header is rendered. When enabled, the header is
 *        shorter and expects only single item (header) to be passed as the first child.
 * @prop {(bool) => any} onToggle: a function callback that gets called when the accordion
 *        is toggled. The expanded state is passed as the only argument
 * @prop {[Node, Node, ...]} children: Expects 2 or more children. The first child will be
 *        the header, while the second (and others) will be rendered in the body.
 */

@observer
class Accordion extends Component {
  constructor(props) {
    super(props);

    this.setExpanded(
      _.isUndefined(props.expanded) ? !!props.defaultExpanded : props.expanded,
    );
  }

  static propTypes = {
    as: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
      PropTypes.string,
      PropTypes.symbol,
    ]),
    canToggle: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    defaultExpanded: PropTypes.bool,
    expanded: MobxPropTypes.observableObject,
    onToggle: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    tooltip: PropTypes.string,
    vertical: PropTypes.bool,
  };

  static defaultProps = {
    as: 'div',
    canToggle: true,
    title: false,
    vertical: true,
  };

  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultExpanded !== prevProps.defaultExpanded) {
      if (_.isBoolean(this.props.defaultExpanded) && !this.props.expanded) {
        this.setExpanded(this.props.defaultExpanded);
      }
    }
  }

  @observable expanded = false;

  @action
  setExpanded = (
    val = !(_.isUndefined(this.props.expanded)
      ? !!this.expanded
      : this.props.expanded),
  ) => {
    if (_.isFunction(this.props.onToggle)) {
      this.props.onToggle(val);
    }

    log.debug(`${val ? 'Expanding' : 'Collapsing'} Accordion Component`);
    this.expanded = val;
  };

  render() {
    const {
      children,
      vertical,
      canToggle,
      expanded: propsExpanded,
      title,
      tooltip,
    } = this.props;

    const { expanded, setExpanded } = this;

    const body = title ? [children] : _.slice(children, 1);

    const isExpanded =
      !canToggle || (_.isUndefined(propsExpanded) ? !!expanded : propsExpanded);

    const ROOT = this.props.as;

    return (
      <ROOT
        className={cx(
          'w-100 flex-column pa3 flex flex-shrink-0',
          accordion.root,
          vertical && accordion.vertical,
        )}
      >
        <div
          className={cx(
            'ssm-no-text-select flex items-center justify-between',
            vertical ? accordion.headerVertical : accordion.header,
          )}
          onClick={() => canToggle && setExpanded()}
          role="button"
          tabIndex={-42}
        >
          <div className="w-100 flex-shrink-1">{title || children[0]}</div>
          {tooltip && (
            <div>
              <PopupIcon color="grey" name="info circle" tooltip={tooltip} />
            </div>
          )}
          {canToggle && (
            <div>
              <Icon
                className={cx(
                  buttons.ddlCaret,
                  isExpanded && buttons.ddlCaretExpanded,
                )}
                color="grey"
                name="chevron up"
              />
            </div>
          )}
        </div>

        <div
          className={cx(
            'flex-column flex',
            accordion.body,
            isExpanded && accordion.bodyExpanded,
          )}
        >
          {_.map(body, (child) =>
            _.isFunction(child) ? child(isExpanded) : isExpanded && child,
          )}
        </div>
      </ROOT>
    );
  }
}

Accordion.displayName = 'Accordion';
export default Accordion;
