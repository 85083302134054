import moment from 'moment';
import _ from 'lodash';

import {
  roundTime,
  formatDuration,
} from '#/shared/components/utils/TimeSelect';

const sync = (form, elem) => (e, newVal) => {
  let val = _.get(newVal, 'value', newVal);

  if (moment.isMoment(e) || _.isDate(e)) {
    // somethingElse = newVal;
    val = e;
    e = null;
  }

  if (!form.has('duration') || !(form.has('start') && form.has('end'))) {
    return form.$(elem).set(moment.isMoment(val) ? val.toDate() : val);
  }

  let date;
  let start = roundTime({ date: form.$('start').value });
  let end = roundTime({ date: form.$('end').value });
  const diff = form.$('duration').value || null;
  const isWindow = !!form.$('isWindow').value;

  // let ddiff;

  switch (elem) {
    case 'date':
      date = moment(val);

      // if (start && start.isValid()) {
      //   if (end && end.isValid()) {
      //     ddiff = start.diff(end);
      //   }
      //   start.year(date.year());
      //   start.dayOfYear(date.dayOfYear());

      //   form.$('start').set(start.toDate());

      //   if (ddiff) {
      //     end = moment(start).add(ddiff, 'ms');
      //     form.$('end').set(end.toDate());
      //   }
      // }
      // else {
      //   start = moment(date);
      //   start.hour(9);
      //   form.$('start').set(start.toDate());
      //   const d = diff || 8;
      //   form.$('end').set(moment(start).add(d, d < 36 ? 'h' : 'm').toDate());
      // }

      form.$(elem).set(date.toDate());
      break;
    case 'start':
    case 'end':
      start = moment(/start/.test(elem) ? val : start);
      end = moment(/end/.test(elem) ? val : end);

      if (end.isValid() && start.isValid()) {
        const endTimeHours = end.hours() * 60;
        const endTimeMinutes = end.minutes() + endTimeHours;
        const startTimeHours = start.hours() * 60;
        const startTimeMinutes = start.minutes() + startTimeHours;
        if (endTimeMinutes < startTimeMinutes) {
          end = moment(start)
            .add(1, 'd')
            .set('hour', end.hour())
            .set('minute', end.minute());
        } else if (!end.isSame(start, 'day')) {
          end = end.subtract(1, 'd');
        }

        if (!isWindow) {
          form.$('duration').set(formatDuration({ end, form, start }));
        }
      } else if (!isWindow && start.isValid() && _.isNumber(diff)) {
        form.$('end').set(start.add(diff, 'm').toDate());
      }

      form.$(elem).set((/start/.test(elem) ? start : end).toDate());

      break;
    case 'duration':
      if (!isWindow && start.isValid()) {
        form.$('end').set(start.add(val, 'h').toDate());
      }

      form.$(elem).set(val);
      break;
    default:
  }

  return false;
};

export { sync };
