import _ from 'lodash';

const getEmploymentStatus = ({ companyId, user }) => {
  const companyStatusObj = _.find(user?.companyStatus, {
    company: companyId,
  });
  return _.get(companyStatusObj, 'status', '');
};

/**
 * Use to check if a certain status exist for a company in a user company status array
 */
const checkEmploymentStatus = ({ companyId, user, status }) =>
  _.find(
    user?.companyStatus,
    (csr) =>
      csr.company === companyId &&
      (_.isArray(status)
        ? _.includes(status, csr.status)
        : csr.status === status),
  );

export { getEmploymentStatus, checkEmploymentStatus };
