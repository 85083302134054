import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { observer } from 'mobx-react';

/**
 * # Simple User Image
 * Given the props of a user, renders a user's initials in an avatar-like component.
 *
 * For rendering a user Initials based solely on a user ID, see UserInitials
 *
 */
export const SimpleUserInitials: React.FC<{
  circular: boolean;
  className: string;
  displayName: string;
  fontClass: string;
  style: Record<string, unknown>;
}> = observer(
  ({
    className,
    circular = false,
    style = {},
    fontClass,
    displayName = 'Not Available',
  }) => {
    const fontSize = fontClass || (/w-100/.test(className) ? 'f3' : 'f5');

    const initials = _(displayName)
      .split(' ')
      .map((d) => _.upperCase(d[0]))
      .take(3)
      .join('');

    return (
      <div
        className={cx(
          className,
          circular && 'br-100 h-100 w-100',
          'flex items-center justify-center',
          'white flex-shrink-0',
        )}
        style={{ ...style, backgroundColor: '#2a6cc4' }}
      >
        <div
          className={cx(fontSize, 'tc white', 'w-100', circular && 'ma2')}
          style={{
            textShadow: '1px 1px rgba(0,0,0,0.1)',
          }}
        >
          {initials}
        </div>
      </div>
    );
  },
);
