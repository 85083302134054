import { observable, action, set, runInAction } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import { dispatch } from 'rfx-core';

import BaseUploader, {
  KnownFieldConfig,
} from '#/shared/stores/ui/BaseUploader';

const USER_UUID = 'userUUID';
const AMOUNT = 'amount';
const BONUS_DATE = 'bonusDate';
const BONUS_NAME = 'bonusName';
const PAYMENT_NOTE = 'paymentNote';

export default class BulkCreateTransfers extends BaseUploader {
  constructor() {
    super({ title: 'ui.bulkCreateTransfers' });

    set(this.saveResults, {
      dupe: [],
      invalid: [],
      new: [],
      unassigned: [],
    });

    return this;
  }

  @observable parseOptions = {
    dynamicTyping: false,
    header: false,
    skipEmptyLines: true,
  };

  /** list
   * Stores an array of rows, parsed into campaign quota format.
   *
   * Ready for saving.
   */
  @observable
  list = [];

  @observable
  invalidList = [];

  @observable
  userCompanyPairs = [];

  @observable
  userIDs = [];

  @observable
  keyCounts = {};

  @observable
  newRecords = [];

  @observable
  missingHeaders = [];

  @observable
  clearExistingUserCompanyData = true;

  @observable
  selectedCompany = null;

  @observable
  uploadSummary = '';

  workersOfTheSelectedCompany = [];

  csvData = [
    [USER_UUID, AMOUNT, BONUS_DATE, BONUS_NAME, PAYMENT_NOTE],
    [
      '21872b54-594c-42a6-ae38-fef09fa86ec4',
      100,
      '10/6/2019',
      'Sample Bonus Name',
      'Sample Payment Note',
    ],
  ];

  @action
  setUploadSummary(summary) {
    this.uploadSummary = summary;
  }

  @action
  setSelectedCompany(company) {
    this.selectedCompany = company;
  }

  @action
  setup(...args) {
    super.setup(...args);

    this.clear();
  }

  @action
  clear() {
    super.clear();

    this.saveResults = {
      dupe: [],
      invalid: [],
      new: [],
      unassigned: [],
    };

    this.list.clear();
    this.invalidList.clear();
    this.userCompanyPairs.clear();
    this.userIDs.clear();
    this.newRecords.clear();
    this.keyCounts = {};
    this.setMissingHeaders([]);
    this.setSelectedCompany(null);
    this.setUploadSummary('');
  }

  @action
  setMissingHeaders(headers = []) {
    this.missingHeaders = headers;
  }

  @action
  async processFile({ options = this.parseOptions, ...rest }) {
    this.setStatus('loading');

    try {
      await super.processFile({ options, ...rest });
      this.setStatus('loaded');
    } catch (err) {
      this.log.error('Failed to process file', err);

      this.setStatus('error');

      throw err;
    }
  }

  knownHeaders = [
    USER_UUID,
    AMOUNT,
    BONUS_DATE,
    BONUS_NAME,
    PAYMENT_NOTE,

    // Non-Preferred Keys
    'userID',
    'companyID',
  ];

  requiredHeaders = [USER_UUID, AMOUNT, BONUS_DATE, BONUS_NAME, PAYMENT_NOTE];

  get knownFields(): KnownFieldConfig[] {
    return [
      { key: 'date', transform: (val) => moment(val, this.formats).toDate() },

      { key: 'userUUID', transform: _.identity },
    ];
  }

  async isUserGotBonusThisDayAndCompany(row) {
    const query = {
      amount: row.amount,
      bonusDate: row.bonusDate,
      bonusName: row.bonusName,
      category: 'bonus',
      company: this.selectedCompany.uuid,
      user: row[USER_UUID],
    };
    const results = await dispatch('paymentTransactions.findOne', { query });
    return !results;
  }

  async isUserIsInSelectedCompany(row) {
    return this.workersOfTheSelectedCompany.includes(row[USER_UUID]);
  }

  isValidDateFormat(date) {
    return moment(date, this.formats, true).isValid();
  }

  isExceedCompanyMaxBonusAmountLimit(val) {
    const bonusAmount = parseInt(val, 10);
    const companyBonusLimit =
      this.selectedCompany?.settings?.payments?.maxBonusPerDay ?? 300;
    return bonusAmount < companyBonusLimit;
  }

  async getInvalidRowStatus(row) {
    const invalidRowStatuses = [];
    const isAvailableForBonus = await this.isUserGotBonusThisDayAndCompany(row);
    if (!isAvailableForBonus) {
      invalidRowStatuses.push('Already have bonus same day and company');
    }

    if (!this.workersOfTheSelectedCompany.includes(row[USER_UUID])) {
      invalidRowStatuses.push('User does not belong to selected company');
    }

    if (!this.isValidDateFormat(row[BONUS_DATE])) {
      invalidRowStatuses.push('Invalid Date Format');
    }

    if (!this.isExceedCompanyMaxBonusAmountLimit(row[AMOUNT])) {
      invalidRowStatuses.push('Bonus amount exceed the company limit');
    }

    if (!row[BONUS_NAME]) {
      invalidRowStatuses.push('Bonus name not found');
    }

    return invalidRowStatuses;
  }

  @action
  async parse({ rows }) {
    const casedHeaders = _.map(rows[0], _.toLower);
    const casedRequiredHeaders = _.map(this.requiredHeaders, _.toLower);
    const missingHeaders = _.differenceWith(
      casedRequiredHeaders,
      casedHeaders,
      (h1, h2) => h1 === h2,
    );
    this.setMissingHeaders(missingHeaders);
    if (!_.isEmpty(missingHeaders)) {
      throw new Error('Invalid Headers');
    }
    const indices = _.reduce(
      this.knownHeaders,
      (acc, header) => {
        acc[header] = _.indexOf(casedHeaders, _.toLower(header));
        return acc;
      },
      {},
    );

    this.log.debug('Loaded Table Indices', { indices });

    const parsedRows = _(rows)
      .map((row, i) => {
        if (!i) return null;

        this.log.debug(row);

        return _.reduce(
          this.knownHeaders,
          (acc, colKey) => {
            const colIndex = indices[colKey];

            if (colIndex >= 0) {
              acc[colKey] = row[colIndex];
            }

            return acc;
          },
          {},
        );
      })
      .reject((rowObj) => _.isEmpty(_.omitBy(rowObj, _.isEmpty)))
      .map((data) => this.doMapping({ data, mapping: this.knownFields }))
      .compact()
      .value();

    this.setStatus('Evaluating');

    const query = {
      companyStatus: {
        $elemMatch: {
          company: this.selectedCompany.uuid,
          status: 'employee',
        },
      },
      uuid: { $in: parsedRows.map((row) => row.userUUID) },
    };

    const results = await dispatch('workers.runQuery', { query });
    this.workersOfTheSelectedCompany = _.map(results.data, 'uuid');

    const promiseResult = await Promise.all(
      _.map(parsedRows, async (row) => {
        const invalidationMessages = await this.getInvalidRowStatus(row);
        return _.isEmpty(invalidationMessages)
          ? row
          : { ...row, errorNote: invalidationMessages };
      }),
    );

    const totalTransactionAmount = _.sumBy(promiseResult, (row) =>
      row.errorNote ? 0 : parseFloat(row[AMOUNT]),
    );
    const totalTransactionCount = promiseResult.filter(
      (row) => !row.errorNote,
    ).length;
    this.setUploadSummary(
      `You are submitting ${totalTransactionCount} transfers for a total of ${totalTransactionAmount} dollars`,
    );

    runInAction(() => {
      this.list.replace(promiseResult.filter((row) => !row.errorNote));
      this.invalidList.replace(promiseResult.filter((row) => row.errorNote));
    });

    this.setStatus('Parsed all Rows');
  }

  @action
  async save() {
    this.setStatus('saving');
    this.setSaving(true);

    try {
      await dispatch('csvUploads.create', {
        data: {
          company: this.selectedCompany.uuid,
          csvName: this.file?.name,
          csvString: JSON.stringify(this.list),
          uploadType: 'processBulkTransfersCSV',
        },
      });
      this.setStatus('saved');
    } catch (error) {
      this.setStatus('error');
    }
    this.setSaving(false);
  }
}
