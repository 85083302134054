import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import _ from 'lodash';

export declare type IndicatorProps = {
  color: string;
  inline?: boolean;
  size?:
    | 'xxl'
    | '2xl'
    | 'xl'
    | 'extra-large'
    | 'lg'
    | 'large'
    | 'md'
    | 'medium'
    | 'sm'
    | 'small'
    | 'mi'
    | 'mini'
    | 'micro';
  text: string;
};
// TODO: This should inherit the StatusIndicator component
export const Indicator: React.FC<IndicatorProps> = styled.div.attrs<IndicatorProps>(
  ({ text, size, inline }) => ({
    className: cx(
      'fw8 b ph2',
      inline ? 'di' : 'db',
      _.toLower(text) || 'draft',
      {
        ...getFontSize(size),
      },
      'items-center',
    ),
  }),
)<IndicatorProps>`
  & {
    color: ${({ color }) => color};

    user-select: none;
    position: relative;
    text-transform: capitalize;
    white-space: nowrap;
    padding-left: 0.5em;
    padding-right: 0.5em;

    margin-top: auto;
    margin-bottom: auto;
  }

  // The ● status indicator
  &:before {
    font-size: 0.75rem;
    content: '\\2b24';
    position: relative;
    top: -0.15rem;

    box-sizing: content;
  }

  // The text label
  &:after {
    position: relative;
    padding-left: 0.3em;
    content: '${({ text }) => text}';
  }
`;

function getFontSize(size) {
  return {
    f2: /^(xxl)|(2xl)|((double[- ]e?x(tra)?[- ]?l))(ar)?(ge)?$/i.test(size),
    f3: /^e?x(tra)?[- ]?l(ar)?(ge)?$/i.test(size),
    f4: /^l(ar)?ge?$/i.test(size),
    f5: !size || /^m[ie]?d(ium)?$/i.test(size),
    f6: /^sm(all)?$/i.test(size),
    f7: /^mi(ni|cro)?$/i.test(size),
  };
}
