import { action, set, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import _ from 'lodash';

import BaseUploader from '#/shared/stores/ui/BaseUploader';

/** @deprecated 2022-10-11 */
export default class BonusBulkUpdateModal extends BaseUploader {
  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  isUpdating = false;

  @observable
  step = 'upload';

  @observable
  campaign = {};

  @observable
  bonusesToUpdate = [];

  @observable
  notFoundBonusQuotas = [];

  @observable
  processedBonuses = 0;

  @observable
  bonusesToProcess = 1;

  @observable
  updatePercentage = 0;

  @action
  setup({ campaign, open = this.isOpen }) {
    this.campaign = campaign;
    this.isOpen = open;
  }

  @observable
  parseOptions = {
    dynamicTyping: false,
    header: true,
    skipEmptyLines: true,
  };

  @action
  async parse({ rows: data }) {
    this.isLoading = true;
    const quotas = _.get(this.campaign, 'quotas', []);
    data.forEach((quotaToUpdate) => {
      const quota = quotas.find((q) => {
        const match = _.trim(q.key) === _.trim(quotaToUpdate.key);
        return match;
      });

      if (!quota) {
        this.notFoundBonusQuotas.push(quotaToUpdate);
      } else {
        const oldQuotaBonus = quota.bonus || 0;
        const newQuotaBonus = quotaToUpdate.bonus || 0;
        const oldBonusNumber = parseFloat(oldQuotaBonus);
        const newBonusNumber = parseFloat(newQuotaBonus);
        if (oldBonusNumber !== newBonusNumber) {
          /*
            Note:
            Keeping old quota and replacing with new bonus to ensure bulk bonus
            only updates bonus fields - LL
          */
          const quotaCopy = _.extend({}, quota, {
            bonus: newQuotaBonus,
            updateStatus: 'pending',
          });

          this.bonusesToUpdate.push(quotaCopy);
        }
      }
    });

    this.isLoading = false;
  }

  @action
  async updateBonuses({ quotas = [], campaign = this.campaign }) {
    this.isUpdating = true;
    this.processedBonuses = 0;
    this.bonusesToProcess = quotas.length;
    await Promise.map(
      quotas,
      async (quota) => {
        log.debug(
          'Updating Quota Bonuses: ',
          campaign.title,
          quota.region,
          this.processedBonuses / this.bonusesToProcess,
        );
        this.set(
          'updatePercentage',
          this.processedBonuses / this.bonusesToProcess,
        );
        try {
          const data = {
            bonus: quota.bonus,
            quotaKey: quota.key,
          };
          await dispatch('campaigns.update', {
            data,
            id: campaign.uuid,
          });
          this.updateBonusStatus(quota, 'updated');
        } catch (error) {
          log.error('Error Bulk Updating Bonus: ', error, {
            extra: {
              campaignId: campaign.uuid,
              quotaKey: quota.key,
            },
          });
          this.updateBonusStatus(quota, 'error');
        }
        this.processedBonuses += 1;
      },
      {
        concurrency: 1,
      },
    );
    this.set('isUpdating', false);
  }

  @action
  updateBonusStatus(quota, status) {
    set(quota, { updateStatus: status });
  }

  @action
  setLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  clear() {
    this.campaign = {};
    this.isLoading = false;
    this.isUpdating = false;
    this.step = 'upload';
    this.bonusesToUpdate = [];
    this.notFoundBonusQuotas = [];
    this.processedBonuses = [];
    this.bonusesToProcess = 1;
    this.updatePercentage = 0;

    this.isOpen = false;
  }
}
