import React from 'react';
import { dispatch } from 'rfx-core';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Layer, Feature, Marker } from 'react-mapbox-gl';
import { Icon, Label, Popup } from 'semantic-ui-react';
import turfCircle from '@turf/circle';
import { convertLength } from '@turf/helpers';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Avatar from '#/shared/components/utils/Avatar';
import Address from '#/shared/components/utils/Address';
import { ShiftSummaryContent } from '#/shared/components/shifts/ShiftSummaryContent';
import ShiftStatusChip from '#/shared/components/shifts/ShiftStatusChip';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('utils.MapElements');

export {
  RadiusCircles,
  HomeMarker,
  getItemMarker,
  getClusterMarkerFactory,
  MarkerContents,
};

// -------------------------------------------------------------------------------

/** RadiusCircles */
const rcLog = log.getChildLogger('RadiusCircles');
const RadiusCircles = observer(({ items }) => {
  rcLog.debug('RadiusCircles Start');
  const radii = _(items)
    .filter(
      (item) =>
        item &&
        !!item.radius &&
        !_.isEmpty(
          dispatch('ui.mapInstance.getCoordinatesForItem', { item })
            .coordinates,
        ),
    )
    .value();
  if (_.isEmpty(radii)) {
    return false;
  }
  return (
    <Layer
      key="location-group-layer"
      paint={{
        'fill-color': '#2A6CC4',
        'fill-opacity': 0.65,
      }}
      type="fill"
    >
      {_.map(radii, (item) => {
        const center = dispatch('ui.mapInstance.getCoordinatesForItem', {
          item,
        }).coordinates;
        const circleRadius = convertLength(item.radius, 'feet', 'miles');
        const circleGeo = turfCircle(center.slice(), circleRadius, 64, 'miles');
        return (
          <Feature coordinates={_.get(circleGeo, 'geometry.coordinates')} />
        );
      })}
    </Layer>
  );
});
RadiusCircles.displayName = 'RadiusCircles';

/** HomeMarker */
const HomeMarker = observer(
  ({ home }) =>
    !_.isEmpty(home) &&
    getItemMarker({
      item: home,
      opts: {
        icon: {
          circular: true,
          color: 'blue',
          inverted: true,
          name: 'building',
        },
      },
    }),
);
HomeMarker.displayName = 'HomeMarker';

/** getItemMarker */
const imLog = log.getChildLogger('getItemMarker');
function getItemMarker({
  item,
  opts = {},
  onClick,
  onClickWorker,
  onClickThing,
  selected,
  viewport = {},
}) {
  if (_.isEmpty(item)) {
    return false;
  }
  imLog.debug('getMarker Start');
  const { __t: refType, rate, title } = item;
  let popupContent;
  let color = 'purple';
  let clickCb = _.noop;
  let anchorPosition = 'center';
  let on = 'hover';
  let size;
  let attrs;

  const isItemSelected = _.find(selected, { uuid: item?.uuid });

  const coordData = dispatch('ui.mapInstance.getCoordinatesForItem', {
    item,
  });
  let { dataType, coordinates } = coordData;
  const { addr } = coordData;

  if (dataType === 'company') {
    popupContent = item.name;
  } else if (dataType === 'worker') {
    popupContent = item.displayName;
    color = 'blue';
    clickCb = onClick || onClickWorker || clickCb;
  } else if (dataType === 'customer') {
    popupContent = item.title;
    color = 'red';
    clickCb = onClick || onClickThing || clickCb;
  } else if (dataType === 'store') {
    popupContent = item.storeId;
    color = 'red';
    clickCb = onClick || onClickThing || clickCb;
  } else if (dataType === 'address') {
    popupContent =
      _.get(addr, 'formattedAddress') ||
      _.get(item, 'formattedAddress') ||
      item.title;
    clickCb = onClick || onClickThing || clickCb;
  } else if (dataType === 'unknown') {
    // Thing: 'shift', etc
    popupContent = (
      <div className="pa2">
        {item.title}
        <br />
        {(addr && <Address data={addr} />) || _.get(item, 'loc.zip')}
      </div>
    );
    clickCb = onClick || onClickThing || clickCb;
  } else if (dataType === 'cluster') {
    color = dispatch('ui.map.get', 'enableDebugInfo') ? 'purple' : 'blue';
    size = 'large';
    clickCb = onClick || clickCb;
    attrs = { ...attrs, ...item };

    if (item.count === 1) {
      return getItemMarker({
        item: _.first(item.points),
        onClick: onClickWorker,
        opts,
        selected,
        viewport,
      });
    }

    return (
      <Marker {...attrs} anchor="center" coordinates={coordinates}>
        <MarkerContents
          color={color}
          onClick={onClick}
          pointCount={item.count}
        />
      </Marker>
    );
  }

  if (_.isEmpty(coordinates)) {
    dataType = 'address';
    coordinates =
      _.get(item, 'loc.coordinates') || _.get(item, 'address.loc.coordinates');
    popupContent = item.title || item.name || item.storeId;
    color = 'red';
    clickCb = onClick || onClickThing || clickCb;
  }

  if (_.isEmpty(coordinates)) {
    // imLog.silly('No Coordinates found for ', { object });
    return false;
  }

  if (!_.isEmpty(opts.filter)) {
    if (_.isArray(opts.filter) && _.eq(opts.filter, coordinates)) {
      imLog.silly('Marker is being hidden for filter coords: ', {
        filter: opts.filter,
        object: item,
      });
      return false;
    }
    if (_.eq(_.get(opts.filter, 'loc.coordinates'), coordinates)) {
      imLog.silly('Marker is being hidden for filter coords: ', {
        filter: opts.filter,
        object: item,
      });
      return false;
    }
  }

  if (_.includes(_.map(selected, 'uuid'), item.uuid)) {
    color = 'blue';
  }

  imLog.info(`Rendering for data:"${dataType}", ref:"${refType}"`);

  let trigger;

  const selectedStyle = {
    backgroundImage: 'url("/static/img/mapMarker.svg")',
    borderRadius: '50%',
    boxShadow: '0px 0px 36px #0070D5',
    opacity: 1,
  };

  // the partner's picture is only rendered when zoomed in at level 10 or higher.
  if (dataType === 'worker' && viewport.zoom > 10) {
    anchorPosition = 'bottom';
    trigger = (
      <button
        className="bn bg-transparent"
        style={isItemSelected ? selectedStyle : {}}
      >
        <div
          className="tc relative"
          style={{ height: '3rem', width: '2.5rem' }}
        >
          <div className="absolute top-0 left-0 right-0">
            <img alt="" src="/static/img/mapIndicator.svg" />
          </div>
          <div
            className="absolute top-0 left-0 right-0"
            style={{ paddingTop: '3px' }}
          >
            <Avatar
              color={color === 'blue' ? 'var(--blue)' : color}
              isOnMap={true}
              size="h2 w2"
              user={item}
            />
          </div>
        </div>
      </button>
    );
  }

  if (/shift|survey/.test(refType)) {
    anchorPosition = 'bottom';
    on = 'click';
    let label = title;
    let markerColor = 'blue';
    let basic = true;
    if (/survey/i.test(refType)) {
      markerColor = 'red';
      basic = false;
      if (rate) {
        label = `$${rate}`;
      }
    }
    trigger = (
      <Label
        basic={basic}
        color={markerColor}
        content={
          <span className="nowrap max-w5 pointer flex items-center truncate">
            <span className="mr2">
              <ShiftStatusChip size="indicator" status={item.status} />
            </span>
            {label}
          </span>
        }
        pointing="below"
      />
    );
    popupContent = (
      <div className="pt2">
        <ShiftSummaryContent card={true} shift={item} showDOW={true} />
      </div>
    );
  }

  if (!_.isEmpty(opts.icon)) {
    trigger = <Icon {...opts.icon} />;
  }

  if (_.isFunction(opts.markers)) {
    trigger = opts.markers({ thing: item, trigger }) || trigger;
  }

  if (!trigger) {
    trigger = (
      <Label
        onClick={() => clickCb(item.uuid)}
        {...attrs}
        circular={true}
        className="shadow-1"
        color={color}
        empty={true}
        size={size}
      />
    );
  }

  const isPopupTrigger = _.get(trigger, 'type.name') === 'Popup';
  if (_.isString(popupContent)) {
    popupContent = <div className="pa2">{popupContent}</div>;
  }
  imLog.debug('Rendering Marker', {
    color,
    content: popupContent,
    coordinates,
    dataType,
  });

  return (
    <Marker
      anchor={anchorPosition}
      coordinates={coordinates.slice()}
      key={item.uuid || coordinates.join('-')}
      offset={[0, 2]}
      onClick={() => clickCb(item.uuid)}
    >
      {isPopupTrigger ? (
        trigger
      ) : (
        <Popup
          {...attrs}
          content={popupContent}
          on={on}
          position="bottom center"
          style={{
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
          }}
          trigger={trigger}
        />
      )}
    </Marker>
  );
}

/** getClusterMarkerFactory */
function getClusterMarkerFactory(dataType) {
  const ClusterMarkerFactory = (coordinates, pointCount, getLeaves) => {
    let color;
    let count = pointCount;
    let onClick = _.noop;
    switch (dataType) {
      case 'worker':
        color = 'blue';
        onClick = () => {
          dispatch('ui.map.loadClusteredPartners', {
            getChildrenFn: getLeaves,
          });
        };
        break;
      case 'thing':
      case 'shift':
        color = 'red';
        onClick = () => {
          dispatch('ui.map.loadClusteredThings', {
            getChildrenFn: getLeaves,
          });
        };
        break;
      case 'cluster':
        color = dispatch('ui.map.get', 'enableDebugInfo') ? 'orange' : 'blue';

        try {
          count = _(getLeaves())
            .map(({ props = {} }) => {
              const { children } = props;

              if (_.isArray(children)) {
                return _.sumBy(
                  children,
                  (child) =>
                    child?.props?.pointCount ?? child?.props?.count ?? 1,
                );
              }

              return children?.props?.pointCount ?? children?.props?.count ?? 1;
            })
            .sum();
        } catch (err) {
          log.error('Failed to compute Cluster Cluster', err, {
            extra: { coordinates, count, pointCount },
          });
        }

        onClick = () => {
          dispatch('ui.mapInstance.setZoomAndCenter', {
            center: { lat: coordinates[1], lng: coordinates[0] },
            zoomIncrement: 1.5,
          });
        };
        break;
      default:
        color = 'purple';
        break;
    }

    return (
      <Marker {...{ count }} anchor="center" coordinates={coordinates}>
        <MarkerContents
          {...{ count }}
          color={color}
          onClick={onClick}
          pointCount={count}
        />
      </Marker>
    );
  };

  ClusterMarkerFactory.displayName = 'ClusterMarkerFactory';

  return ClusterMarkerFactory;
}

getClusterMarkerFactory.propTypes = {
  children: PropTypes.object,
};

const MarkerContents = observer(
  ({ pointCount, color, onClick, isOnMap = true }) => {
    let compact = false;
    let circular = true;
    let outerMarkerClasses = ''; // 'br-100 h2 w2';
    const markerStyle = {
      marginLeft: '0px',
      zIndex: '100',
    };
    const shadowLabelStyle = { transform: 'scale(1.2)' };

    if (pointCount > 99) {
      compact = true;
      circular = false;
      outerMarkerClasses = 'br-pill';
      _.extend(markerStyle, {
        paddingBottom: '4px',
        paddingTop: '5px',
      });
      shadowLabelStyle.transform = 'scaleX(1.2) scaleY(1.3)';
    }

    return (
      <div
        className={cx(
          'relative flex items-center justify-center',
          outerMarkerClasses,
        )}
      >
        {isOnMap && (
          <div className="absolute">
            <Label
              circular={circular}
              className={cx(compact && 'compact pt1', 'shadow-1')}
              color="white"
              style={{ ...markerStyle, ...shadowLabelStyle }}
            >
              {pointCount}
            </Label>
          </div>
        )}

        <Label
          circular={circular}
          className={cx(compact && 'compact pt1', 'pointer')}
          color={color}
          onClick={onClick}
          style={markerStyle}
        >
          {pointCount}
        </Label>
      </div>
    );
  },
);

MarkerContents.displayName = 'MarkerContents';
