import type { IPartnerAssessmentItem } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export default class PartnerAssessmentItemStore extends BaseStore<IPartnerAssessmentItem> {
  constructor() {
    super({
      baseItem: PartnerAssessmentItemStore.BASE_ITEM,
      serviceName: 'partnerAssessmentItems',
    });

    return this;
  }

  static BASE_ITEM: IPartnerAssessmentItem = {
    companyId: null,
    createdAt: null,
    uuid: null,
  };
}
