import type { IAssignmentQuery } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';

export default class ThingUnassignConfirmModal {
  @observable
  isOpen = false;

  @observable
  loading = false;

  @observable
  isHover = '';

  @observable
  toStatus = '';

  @observable
  assignmentUUID = '';

  @observable
  thing = {};

  @observable
  worker = {};

  @action
  enter(uuid) {
    this.isHover = uuid;
  }

  @action
  leave(uuid) {
    if (this.isHover === uuid) {
      this.isHover = '';
    }
  }

  isHovering(uuid) {
    return this.isHover === uuid;
  }

  @action
  setup({
    thing = {},
    worker = {},
    assignment = {},
    assignmentUUID = _.get(assignment, 'uuid'),
    toStatus,
    open = this.isOpen,
  }) {
    this.thing = thing;
    this.toStatus = toStatus;
    this.assignmentUUID = assignmentUUID;

    if (_.isString(worker)) {
      this.loading = true;

      dispatch('partners.get', worker).then(
        action((result) => {
          this.worker = result;
        }),
      );
    } else {
      this.worker = worker;
    }

    if (!assignmentUUID) {
      this.loading = true;

      return dispatch('assignments.runQuery', {
        ref: thing.uuid,
        status: { $nin: ['Canceled'] },
        user: _.get(worker, 'uuid', worker), // ATTN: Assignment Status EP-5523
      } as IAssignmentQuery).then(
        action((list) => {
          const ass = _.last(list);
          this.loading = false;

          if (!ass) {
            return;
          }

          this.assignmentUUID = _.get(ass, 'uuid');
          this.isOpen = open;
        }),
      );
    }

    this.isOpen = open;
    return Promise.resolve();
  }

  @action
  clear() {
    this.thing = {};
    this.worker = {};
    this.toStatus = '';
    this.assignmentUUID = '';

    this.isOpen = false;
  }
}
