import type { Query } from '@feathersjs/feathers';
import type { IStoreGetOpts } from '#/shared/stores/_baseStore';
import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';
import type { StoreName } from '#/shared/stores';

import { useEffect, useMemo, useRef, useState } from 'react';
import { IObservableObject } from 'mobx/lib/internal';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

import { useStore } from './useStores';

/**
 * # useStoreItem Hook
 * Useful for loading an item from a store for use within a functional component. Insetead
 * of writing a custom `useEffect` hook to take a uuid and load a relevant item, this hook
 * is a drop in solution to loading companies, shifts, etc.
 *
 * ### When to Use
 * Does your component have a uuid that points to a record that you need to display on the page?
 * Are you writing a `useEffect` hook with `item = dispatch('store.get', uuid); setItem(item);`
 * logic? This is the hook for you!
 *
 * ### Usage
 * 1. Most of the time, as currently implemented, there is no need to pass in a value under the
 * `obj` parameter. If a `uuid` value is supplied, a `get` call to the specified `storeName` will
 * always be made.
 * 1. If you are using the `obj` parmeter, ensure that the passed in variable is a state variable
 * or observable object, and under no circumstance should it be a plain variable in the component.
 * If you pass in the prop of `{ obj: {}}`, you're going to have a bad time.
 */
export function useStoreItem<T, ID = T extends IBaseItem ? T['uuid'] : string>({
  storeName,
  uuid,
  obj,
  query,
  opts,
}: {
  /**
   * ### obj
   * As currently implmeneted, the obj parameter will be ignored if a UUID is
   * included, and this parameter should generally be avoided.
   *
   * #### **!!!! WARNING !!!!**
   * Do not pass empty objects, or any vars that are regeneated on
   *             each render loop here or you're going to have a bad time.
   *
   * If you get a "Page is not responding" error, comment out the `obj` and try again.
   */
  obj?: (IObservableObject & T) | T | never;
  /**
   * Options to pass thru to the server `get` call
   *
   * **WARNING** This value is not observed by default, and will
   * not be included in the dependencies array of the underlying
   * hook. If this behavior is required, we can add an additional
   * `additionalDependencies` prop to handle the use case
   */
  opts?: IStoreGetOpts;
  /**
   * A query to restrict the server `get` call by.
   *
   * **WARNING** This value is not observed by default, and will
   * not be included in the dependencies array of the underlying
   * hook. If this behavior is required, we can add an additional
   * `additionalDependencies` prop to handle the use case
   */
  query?: IStoreGetOpts['query'];

  /**
   * ### storeName
   * The name of the store to load the item from
   *
   * @example
   * "shifts", "companies", etc
   */
  storeName: StoreName;
  /**
   * ### uuid
   * The uuid of the store item to load
   */
  uuid: ID;
} & (
  | {
      opts?: never;
      query?: Query;
    }
  | {
      opts?: IStoreGetOpts;
      query?: never;
    }
)): { isLoading: boolean; item: T | Record<string, never> } {
  const { current: log } = useRef(getChildLogger(`useStoreItem.${storeName}`));
  const store = useStore(storeName);

  const [isLoading, setIsLoading] = useState(!!uuid && !obj?.uuid);
  const [item, setItem] = useState<T | Record<string, never>>(obj ?? {});

  useEffect(() => {
    const load = async () => {
      try {
        setIsLoading(true);

        const data = await store.get(uuid, {
          query,
          select: false,
          ...(opts ?? {}),
        });

        setItem(data);

        return;
      } catch (err) {
        log.error('Failed to load store item', err, { obj, uuid });
      } finally {
        setIsLoading(false);
      }
    };

    if (!!uuid && _.isString(uuid) && !obj?.uuid) {
      load();
    } else if (item?.uuid !== obj?.uuid) {
      setItem(obj ?? {});
    }
  }, [store, uuid, obj]);

  return { isLoading, item };
}
