import auth from '@feathersjs/authentication-client';
import { feathers } from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import _ from 'lodash';
import io from 'socket.io-client';
import { dispatch } from 'rfx-core';

import toBoolean from '#/utils/toBoolean';
import {
  DEPLOYMENT_ENV,
  IS_DEVELOPMENT,
  IS_PRODUCTION,
  NODE_ENV,
} from '#/config/settings';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('app', { prefixColor: '#2a6cc4' });

let instance = null;
const config = global.CONFIG;

let uri = `${config.io.protocol || 'http'}://${_.reject(
  [config.io.host, config.io.port],
  (x) => _.isEmpty(`${x}`),
).join(':')}`;

if (IS_PRODUCTION && config.io.prod) {
  const msg = `The "PROD_IO_HOST" config is deprecated. Please use IO_PROTOCOL + IO_HOST instead`;
  log.warn(msg, { config: config.io });
  uri = ['https://', config.io.prod].join('');
} else {
  // Allows an override url via localStorage in non-production environments
  const urlOverride = localStorage.getItem('API_OVERRIDE_URL');
  uri = urlOverride ?? uri;
}

if (IS_DEVELOPMENT) {
  localStorage.setItem('ssmLogLevel', 'debug');
}

log.always(`

  ███████╗██╗  ██╗██╗███████╗████████╗███████╗███╗   ███╗ █████╗ ██████╗ ████████╗
  ██╔════╝██║  ██║██║██╔════╝╚══██╔══╝██╔════╝████╗ ████║██╔══██╗██╔══██╗╚══██╔══╝
  ███████╗███████║██║█████╗     ██║   ███/███╗██╔████╔██║███████║██████╔╝   ██║
  ╚════██║██╔══██║██║██╔══╝     ██║   ╚════██║██║╚██╔╝██║██╔══██║██╔══██╗   ██║
  ███████║██║  ██║██║██║        ██║   ███████║██║ ╚═╝ ██║██║  ██║██║  ██║   ██║
  ╚══════╝╚═╝  ╚═╝╚═╝╚═╝        ╚═╝   ╚══════╝╚═╝     ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝
  --------------------------------------------------------------------------------
      **NODE ENV** : ${NODE_ENV}
      **DEPLOYMENT ENV** : ${DEPLOYMENT_ENV}
      **IO URL** : ${uri}
  --------------------------------------------------------------------------------

`);

export function app() {
  if (instance) return instance;

  setupProxyFormatter();

  // These settings will force websocket connection
  // And skip trying to upgrade from polling
  // This will reduce support for older browsers
  // But should reduce the number of 'No Auth Token' errors
  const socket = io(uri, {
    query: { clientApp: 'employer-web' },
    transports: ['websocket'],
    upgrade: false,
  });

  if (toBoolean(global.IS_STORYBOOK)) {
    log.always('Generating Stub Feathers app for Storybook Config');
    instance = feathers();
  } else {
    instance = feathers();
    instance.configure(socketio(socket, { timeout: 15000 }));
    instance.configure(auth({ storage: localStorage }));

    instance.hooks({
      before: {
        all: [
          // Include the currently active companyId in the Request Params
          (context) => {
            try {
              const companyId = dispatch('auth.getCompany')?.uuid;

              if (!_.isEmpty(companyId)) {
                _.set(context.params, 'query.$client.companyId', companyId);
              }
            } catch (err) {
              log
                .getChildLogger('hooks.before')
                .error('Failed to attach authd companyId to request', err);
            }

            return context;
          },
        ],
      },
    });

    instance.setMaxListeners(20);
  }

  return instance;
}

export function service(name) {
  return app().service(name);
}

// Taken from https://stackoverflow.com/a/55806306/609021
function setupProxyFormatter() {
  if (!IS_DEVELOPMENT) {
    return false;
  }

  if (!global.ENABLE_ENHANCED_PROXY_LOGGING) {
    log.debug('Not enabling Enhanced Proxy Object logging', {
      flag: global.ENABLE_ENHANCED_PROXY_LOGGING,
    });
    return false;
  }

  window.deproxify = (arg) => JSON.parse(JSON.stringify(arg));

  log.debug('Enabling Enhanced Proxy Object logging');
  log.warn(
    '⚠⚠⚠⚠ Functionality only available in Chrome. In order to use this functionality,' +
      ' the "Enable custom formatters" setting must be enabled.\n' +
      'To Enable, open Chrome Devtools => Gear icon left of the "⋮" menu => ' +
      'Preferences => Console => "Enable custom formatters"',
  );

  const proxySet = new WeakSet();
  window.Proxy = new Proxy(Proxy, {
    construct(Target, args) {
      const proxy = new Target(args[0], args[1]);
      proxySet.add(proxy);
      return proxy;
    },
  });

  // Working
  window.devtoolsFormatters = [
    {
      hasBody() {
        return false;
      },
      header(obj) {
        try {
          if (!proxySet.has(obj)) {
            return null;
          }

          const object = window.deproxify(obj);
          return ['object', { object }];
        } catch (err) {
          log.error('Failed to Format Object', err, { obj });
          return null;
        }
      },
    },
  ];

  return true;
}
