import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export type IPersona = IBaseItem & Record<string, unknown>;

export default class PersonaStore extends BaseStore<IPersona> {
  constructor() {
    super({
      baseItem: PersonaStore.BASE_ITEM,
      searchFields: ['title'],
      serviceName: 'personas',
    });

    return this;
  }

  static BASE_ITEM = {
    company: null,

    description: null,

    imageURL: null,

    infoURL: null,

    products: [],
    // Company UUID
    title: null,
  };
}
