import type { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Link } from 'react-router';
import cx from 'classnames';
import isUUID from 'uuid-validate';
import styled, { css, StyledComponent } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import uuid from 'uuid';

import { breakpoints } from '#/ssm-ui';
import SiteLogo from '#/shared/components/ssm/SiteLogo';
import CustomIcons from '#/shared/components/CustomIcons';
import { NavToggleHandle } from '#/shared/components/admin/Menu';
import { useStores } from '#/shared/hooks/useStores';
import { NavItemDefinition } from '#/shared/stores/ui/AppNav';
import PopupIcon from '#/shared/components/ssm/PopupIcon';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('NavBar');

function isSelected({ routes, item }) {
  if (_.isBoolean(item.hidden) && item.hidden) return false;
  if (_.isBoolean(item.visible) && !item.visible) {
    return false;
  }

  const mainPath = _(routes)
    .map('path')
    .reject((p) => isUUID(p))
    .join('/')
    .replace('//', '/');

  // if (item.routeMatch === 'admin' && _.find(routes, r => /admin/i.test(r.path))) debugger;

  const tester = new RegExp(item.routeMatch, 'i');
  let isMatch = tester.test(mainPath);

  if (!isMatch && /\(?:/.test(mainPath) && typeof window !== 'undefined') {
    isMatch = tester.test(window?.location?.pathname);
  }

  if (isMatch) log.debug(`RP "${mainPath}" matches "${tester}"`);
  return isMatch;
}

export const NavBar = observer(({ routes }) => {
  const {
    ui,
    auth: { modules },
  } = useStores();

  const collapsed = ui.isNavCollapsed;

  log.verbose('Rendering NavBar');

  const { linkItems, adminLinks } = ui.appNav;

  // On small screens, menu is hidden behind a hamburger menu and the full nav item should be shown.
  const navIsCollapsed = collapsed && !ui.breakpoints.xs;
  return (
    !global.IS_SSR && (
      <div
        className={`flex-column w-100 h-100 relative flex justify-between ${
          !ui.breakpoints.xs && 'pv3'
        }`}
      >
        {ui.breakpoints.xs && (
          <div className="ph3 bb b--black-20" style={{ height: '70px' }}>
            <SiteLogo collapsed={false} />
          </div>
        )}

        <StyledNavBarRoot isCollapsed={navIsCollapsed} isScrollable={true}>
          {_(linkItems)
            .map(({ key, items }) => (
              <div key={key}>
                {_(items)
                  .map((item, index) => {
                    const isActive = isSelected({ item, routes });
                    return (
                      <div key={`${key}-${item.order}-${item.route}-nav-item`}>
                        <NavItem
                          {...{ item, routes }}
                          collapsed={navIsCollapsed}
                          isActive={isActive}
                        />
                        {_.map(item.childRoutes, (child) => (
                          <NavItem
                            key={uuid.v4()}
                            {...{ item: child, routes }}
                            collapsed={navIsCollapsed}
                            nested={1}
                            parentActive={isActive}
                          />
                        ))}
                        {items.length && items.length - 1 === index ? (
                          <NavSpacer
                            key={`${item.order}-${item.route}-divider`}
                          />
                        ) : null}
                      </div>
                    );
                  })
                  .value()}
              </div>
            ))
            .value()}
        </StyledNavBarRoot>

        <StyledNavBarRoot className="self-end" key="admin-nav-item">
          {_.map(adminLinks, (item) => (
            <NavItem
              {...{ item, routes }}
              collapsed={navIsCollapsed}
              key={`${item.order}-${item.route}-nav-item`}
            />
          ))}
        </StyledNavBarRoot>

        {!ui.breakpoints.xs && (
          <NavToggleHandle
            collapsedWidth={ui.navBarWidth}
            isCollapsed={ui.isNavCollapsed}
            onClick={() => ui.setNavCollapsed()}
          />
        )}
      </div>
    )
  );
});

NavBar.displayName = 'NavBar';

const NavSpacer = styled.div`
  min-height: 25px;
`;

type StyledNavBarRootProps = {
  isCollapsed?: boolean;
  isScrollable?: boolean;
};
const StyledNavBarRoot: StyledComponent<
  'div',
  any,
  StyledNavBarRootProps
> = styled.div.attrs<StyledNavBarRootProps>(({ isScrollable }) => ({
  className: cx(
    'flex flex-column flex-shrink-1 items-start',
    'w-100',
    isScrollable && 'overflow-y-auto overflow-x-hidden mv2',
  ),
}))<StyledNavBarRootProps>`
  ${({ isCollapsed }) => !isCollapsed && `width: 100%`}

  @media ${breakpoints.sm} {
    width: auto;
  }

  @media ${breakpoints.xs} {
    width: 100%;
  }
`;

const NavIcon = styled.div.attrs(() => ({
  className: 'ph3',
}))`
  & > div {
    width: 20px;
    height: 20px;

    i {
      margin-right: 0;
    }
  }
`;

const getNavIconTrigger = (icon) => {
  let IconComponent;

  if (_.isString(icon)) {
    if (/^ssm_icon:/.test(icon)) {
      IconComponent = _.get(
        CustomIcons,
        icon.replace(/^ssm_icon:/, ''),
      ) as React.ReactElement;
    }
  } else {
    IconComponent = icon;
  }

  return (
    <NavIcon>
      <div>
        {IconComponent ? (
          <IconComponent />
        ) : (
          <Icon name={(icon || 'bullseye') as SemanticICONS} />
        )}
      </div>
    </NavIcon>
  );
};

type NavItemProps = {
  collapsed?: boolean;
  isActive?: boolean;
  isChild?: boolean;
  item: NavItemDefinition;
  nested?: number;
  parentActive?: boolean;
  routes: unknown;
};

// @ts-expect-error ts(2322)
const NavItem: React.FC<NavItemProps> = observer(
  ({
    item,
    routes,
    nested = 0,
    isActive = isSelected({ item, routes }),
    parentActive,
    collapsed,
  }) => {
    const { route, icon, title, count } = item;

    if (!collapsed && nested && !parentActive) {
      return false;
    }

    return (
      <NavItemLink
        collapsed={collapsed}
        isActive={isActive}
        isChild={!!nested}
        nested={nested}
        parentActive={parentActive}
        to={route}
      >
        <NavItemContent>
          {collapsed ? (
            <PopupIcon
              delay={{ close: 0, open: 700 }}
              hoverable={true}
              inverted={false}
              popperModifiers={[
                {
                  name: 'preventOverflow',
                  options: {
                    boundariesElement: 'offsetParent',
                  },
                },
              ]}
              position="right center"
              tooltip={title}
              trigger={getNavIconTrigger(icon)}
            />
          ) : (
            getNavIconTrigger(icon)
          )}

          {!collapsed && <NavItemText>{title}</NavItemText>}
        </NavItemContent>
        {typeof count !== 'undefined' ? (
          <MessageCount>{count}</MessageCount>
        ) : null}
      </NavItemLink>
    );
  },
);

NavItem.displayName = 'NavItem';

const MessageCount = styled.div.attrs({
  className: cx('tc', 'white', 'relative'),
})`
  width: 28px;
  height: 20px;
  border-radius: 10px;
  background: #e35c5c;
  top: 25%;
`;

const NavItemText = styled.div`
  width: '65px';
  height: '20px';
  font-family: 'Avenir-Medium', 'Lato';
  font-size: '15px';
  font-weight: '400';
  line-height: '20px';
  position: 'relative';
  padding-left: '11px';
`;

const NavItemLink: StyledComponent<
  Link,
  any,
  Omit<NavItemProps, 'routes' | 'item'>
> = styled(Link)
  .withConfig({
    shouldForwardProp: (prop, defaultfn) =>
      !['collapsed', 'isActive'].includes(prop) && defaultfn(prop),
  })
  .attrs<NavItemProps>(({ collapsed, nested }) => ({
    className: cx('w-100 flex flex-row', (collapsed || nested) && 'pl2'),
  }))<NavItemProps>`
  font-family: 'Avenir-Medium';
  border-left: white solid 4px !important;

  &,
  &:hover {
    color: #494b4e;

    & svg {
      stroke: #494b4e;
    }
  }

  & i {
    width: 20px;
    height: 20px;
  }

  ${({ isActive, isChild }) =>
    isActive &&
    !isChild &&
    css`
      &,
      &:hover,
      &:focus {
        color: #0071d5;
        border-left: #0071d5 solid 4px !important;
      }
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      & svg,
      & i {
        color: #0071d5;
        stroke: #0071d5;
      }
    `}
`;

const NavItemContent = styled.div.attrs(() => ({
  className: 'w-100 flex flex-row',
}))`
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 0.5rem;
  position: relative;
`;
