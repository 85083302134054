import { computed, observable, action, set, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import Promise from 'bluebird';
import moment from 'moment';
import uuid from 'uuid';

import BaseUploader from './BaseUploader';

let log;

const BASE_SAVE_RESULTS = {
  assignments: {
    conflict: [],
    dupe: [],
    invalid: [],
    new: [],
    unassigned: [],
  },
  locations: {
    dupe: [],
    invalid: [],
    new: [],
  },
  positions: {
    dupe: [],
    invalid: [],
    new: [],
  },
  shifts: {
    canceled: [],
    dupe: [],
    invalid: [],
    new: [],
    updated: [],
  },
  users: {
    dupe: [],
    invalid: [],
    new: [],
  },
};

const columnIndexes = {
  hours: 1,
  location: 2,
  position: 3,
  user: 0,
};

export default class MonthlyScheduleUpload extends BaseUploader {
  constructor() {
    super({ title: 'ui.monthlyScheduleUpload' });

    set(this.saveResults, BASE_SAVE_RESULTS);

    this.formats.push('D-MMM');
    log = this.log;
  }

  @observable parseOptions = {
    dynamicTyping: false,
    header: false,
    skipEmptyLines: true,
  };

  @observable
  parsedData = {
    assignments: [],
    locations: [],
    positions: [],
    shifts: new Map(),
    users: [],
  };

  @observable
  startDate = undefined;

  @observable
  endDate = undefined;

  @observable
  stubMaps = {
    abbrToLocation: new Map(),
    nameToMultiUser: new Map(),
    nameToUser: new Map(),
    stubIdToUser: new Map(),
    titleToPosition: new Map(),
  };

  @computed
  get shiftsList() {
    return Array.from(this.parsedData.shifts.values());
  }

  @computed
  get savePercentage() {
    return this.saveProgress.total.percent;
  }

  @computed
  get saveProgress() {
    const userTotal = this.parsedData.users.length;
    const locationTotal = this.parsedData.locations.length;
    const positionTotal = this.parsedData.positions.length;

    const {
      shifts,
      assignments,
      users,
      locations,
      positions,
      // unassigned,
    } = this.saveResults;
    const assignmentTotal =
      this.parsedData.assignments.length + assignments.unassigned.length;
    const shiftTotal = this.shiftsList.length + shifts.canceled.length;

    const savedShifts = _.reduce(shifts, (acc, v) => acc + v.length, 0);
    const savedAssignments = _.reduce(
      assignments,
      (acc, v) => acc + v.length,
      0,
    );
    const savedUsers = _.reduce(users, (acc, v) => acc + v.length, 0);
    const savedLocs = _.reduce(locations, (acc, v) => acc + v.length, 0);
    const savedPositions = _.reduce(positions, (acc, v) => acc + v.length, 0);

    const totalTotal =
      shiftTotal + assignmentTotal + userTotal + locationTotal + positionTotal;
    const totalSaved =
      savedShifts + savedAssignments + savedUsers + savedLocs + savedPositions;

    return {
      assignments: {
        conflict: assignments.conflict.length,
        dupe: assignments.dupe.length,
        invalid: assignments.invalid.length,
        new: assignments.new.length,
        percent: assignmentTotal && savedAssignments / assignmentTotal,
        saved: savedAssignments,
        total: assignmentTotal,
        unassigned: assignments.unassigned.length,
      },
      locations: {
        dupe: locations.dupe.length,
        invalid: locations.invalid.length,
        new: locations.new.length,
        percent: locationTotal && savedLocs / locationTotal,
        saved: savedLocs,
        total: locationTotal,
      },
      positions: {
        dupe: positions.dupe.length,
        invalid: positions.invalid.length,
        new: positions.new.length,
        percent: positionTotal && savedPositions / positionTotal,
        saved: savedPositions,
        total: positionTotal,
      },
      shifts: {
        canceled: shifts.canceled.length,
        dupe: shifts.dupe.length,
        invalid: shifts.invalid.length,
        new: shifts.new.length,
        percent: !!shiftTotal && savedShifts / shiftTotal,
        saved: savedShifts,
        total: shiftTotal,
        updated: shifts.updated.length,
      },
      total: {
        percent: totalTotal && totalSaved / totalTotal,
        saved: totalSaved,
        total: totalTotal,
      },
      users: {
        dupe: users.dupe.length,
        invalid: users.invalid.length,
        new: users.new.length,
        percent: userTotal && savedUsers / userTotal,
        saved: savedUsers,
        total: userTotal,
      },
    };
  }

  @action
  setup(...args) {
    super.setup(...args);
  }

  @action
  clear() {
    super.clear();

    _.each(this.parsedData, (d) => d.clear());
    _.each(this.stubMaps, (d) => d.clear());

    set(this.saveResults, BASE_SAVE_RESULTS);
  }

  @action.bound
  async processFile({ company, options = this.parseOptions }) {
    await super.processFile({ company, options });
  }

  @action.bound
  async parse({ rows, company }) {
    this.setLoading(true);
    try {
      const { startDate, endDate, dates } = await this.parseDates({ rows });

      await Promise.all([
        this.populateUsers({ company, rows }),
        this.populateLocations({ company, rows }),
        this.populatePositions({ company, rows }),
      ]);

      const mainlineRows = _(rows)
        // .slice(0, 100)
        .groupBy((r) => r[0])
        // .pick(['Ely Divina'])
        .value();

      this.log.debug('Parsing Shifts for %d Users: ', _.size(mainlineRows));

      const processedRows = await Promise.map(
        _.keys(mainlineRows),
        async (rawUserName) => {
          const user = this.stubMaps.nameToUser.get(rawUserName);

          if (!user) {
            if (rawUserName)
              this.log.error(
                'Unable to find user "%s"',
                !global.IS_PRODUCTION && rawUserName,
              );

            return null;
          }

          const userRows = mainlineRows[rawUserName];

          this.log.silly('Parsing Schedule for user: ', { user, userRows });

          const assignmentResults = await Promise.map(
            userRows,
            async (row) => {
              const [, shift, location, position] = row;

              const [startString, endString] = shift.split('-');

              const startTime = moment(startString, ['ha', 'h:mma']).set({
                month: startDate.month(),
              });
              const endTime = moment(endString, ['ha', 'h:mma']).set({
                month: startDate.month(),
              });

              if (!startTime.isValid()) {
                this.log.error(
                  'Unable to parse start time for shift from value: "%s"',
                  startString,
                );
                return null;
              }
              if (!endTime.isValid()) {
                this.log.error(
                  'Unable to parse start time for shift from value: "%s"',
                  endString,
                );
                return null;
              }

              const scheduleDays = row.slice(4);

              const assignments = await Promise.map(
                scheduleDays,
                async (hours, i) => {
                  if (_.isEmpty(hours)) {
                    return null;
                  }

                  // See https://github.com/lodash/lodash/issues/1148 for more info
                  // eslint-disable-next-line no-restricted-properties
                  if (!global.isFinite(hours)) {
                    log.debug(
                      `Ignoring Hours set to non-numeric value: ${hours}`,
                    );
                    return null;
                  }

                  const payableDuration = _.toNumber(hours);

                  const scheduleBlockShift = await this.parseShiftData({
                    company,
                    endDate,
                    endTime,
                    location,
                    monthDate: dates[i],
                    payableDuration,
                    position,
                    startDate,
                    startTime,
                  });

                  const assignmentStub = {
                    status: 'Assigned',
                    thing: scheduleBlockShift,
                    worker: user, // ATTN: Assignment Status EP-5523
                  };

                  runInAction(() => {
                    this.parsedData.assignments.push(assignmentStub);

                    if (scheduleBlockShift.stubUUID) {
                      scheduleBlockShift.slots += 1;
                      scheduleBlockShift.isModified = true;
                      this.parsedData.shifts.set(
                        scheduleBlockShift.key,
                        scheduleBlockShift,
                      );
                    } else if (scheduleBlockShift.uuid) {
                      scheduleBlockShift.foundSlots =
                        (scheduleBlockShift.foundSlots || 0) + 1;
                      this.parsedData.shifts.set(
                        scheduleBlockShift.key,
                        scheduleBlockShift,
                      );
                    }
                  });

                  return assignmentStub;
                },
              );

              return _.compact(assignments);
            },
            // We can't increase concurrency here for now
            // Because if we have multiple users with the same shift
            // The Shift Stub won't be in the this.parsedData.shifts
            // cache
            //
            // The stubUUID will be overwritten each time
            // And during save, the assignmentStub won't be
            // able to find the corresponding thing
            { concurrency: 1 },
          );

          return _.compact(assignmentResults);
        },
        // We can't increase concurrency here for now
        // Because if we have multiple users with the same shift
        // The Shift Stub won't be in the this.parsedData.shifts
        // cache
        //
        // The stubUUID will be overwritten each time
        // And during save, the assignmentStub won't be
        // able to find the corresponding thing
        { concurrency: 1 },
      );

      runInAction(() => {
        this.list = _.flatten(processedRows);
      });

      this.setLoading(false);
      return Promise.resolve(this.list);
    } catch (err) {
      this.log.error('Failed to Parse File', err);

      this.setLoading(false);
      throw err;
    }
  }

  @action.bound
  async saveSites() {
    return Promise.map(this.parsedData.locations, async (data) => {
      if (!data.isStub) {
        runInAction(() => {
          this.saveResults.locations.dupe.push(data);
        });
        return data;
      }

      try {
        const loc = await dispatch('sites.create', { data });
        runInAction(() => {
          this.saveResults.locations.new.push(loc);
          this.stubMaps.abbrToLocation.set(loc.abbr, loc.uuid);
        });

        return loc;
      } catch (err) {
        runInAction(() => {
          this.saveResults.locations.invalid.push(_.extend(data, { err }));
        });
      }

      return data;
    });
  }

  @action.bound
  async savePositions() {
    return Promise.map(this.parsedData.positions, async (data) => {
      if (!data.isStub) {
        runInAction(() => {
          this.saveResults.positions.dupe.push(data);
        });
        return data;
      }

      try {
        const position = await dispatch('positions.create', { data });
        runInAction(() => {
          this.saveResults.positions.new.push(position);
          this.stubMaps.titleToPosition.set(position.title, position.uuid);
        });

        return position;
      } catch (err) {
        runInAction(() => {
          this.saveResults.positions.invalid.push(_.extend(data, { err }));
        });
      }

      return data;
    });
  }

  @action.bound
  async saveUsers() {
    const userSaveResults = await Promise.map(
      this.parsedData.users,
      async (data) => {
        if (!data.isStub) {
          runInAction(() => {
            this.saveResults.users.dupe.push(data);
          });

          return data;
        }

        // TODO : add "postion" to worker profile [PDF 2019-04-11]

        try {
          const user = await dispatch('workers.create', { data });

          this.log.debug('Created new user', { user });

          runInAction(() => {
            if (!!data.isStub && data.stubUUID) {
              this.stubMaps.stubIdToUser.set(data.stubUUID, user);
            }
            this.saveResults.users.new.push(user);
          });

          return user;
        } catch (err) {
          this.log.error(
            'Unable to create new user',
            err,
            !global.IS_PRODUCTION && {
              extra: { user: data },
            },
          );
          runInAction(() => {
            this.saveResults.users.invalid.push(data);
          });

          return data;
        }
      },
      { concurrency: 10 },
    );

    const positionSaveResults = await Promise.map(
      userSaveResults,
      async (user) => {
        const foundPositions = _.get(user, 'positions', []);
        // todo, also filter by company;
        const existingPositions = _.filter(user.certs || [], {
          certType: 'position',
        });

        const positionsToPush = [];

        if (!_.isEmpty(foundPositions)) {
          await Promise.map(foundPositions, async (position) => {
            const positionUUID = this.stubMaps.titleToPosition.get(position, {
              select: false,
            });

            const existingPos = _.find(existingPositions, {
              uuid: positionUUID,
            });

            if (!existingPos) {
              log.debug(`Looking up position ${positionUUID}`);
              const cert = await dispatch('positions.get', positionUUID, {
                select: false,
              });

              positionsToPush.push(
                _.pick(cert, ['title', 'certType', 'companies', 'uuid']),
              );
            }

            return existingPos || _.last(positionsToPush);
          });

          if (!_.isEmpty(positionsToPush)) {
            log.debug(
              `Pushing %d New Positions to ${user.displayName}`,
              positionsToPush.length,
            );

            /**
             * TODO: EP-728 Use standardized cert manipulation logic as seen in `workers.addCert`
             * and `workers.removeCert`. Logic will need to be updated to handle arrays
             *  */
            return dispatch('workers.update', {
              data: {
                $push: {
                  certs: { $each: positionsToPush },
                },
              },
              id: user.uuid,
            });
          }
          log.silly(`No New Positions to push for ${user.displayName}`);
        }

        return user;
      },
      { concurrency: 10 },
    );

    return positionSaveResults;
  }

  @action.bound
  async save({ company }) {
    this.setSaving(true);

    const stubMap = {};

    dispatch('audit.create', {
      data: {
        action: 'Upload Monthly Schedule',
        companyId: company.uuid,
        extra: {
          file: _.pick(this.file, ['name', 'type', 'size']),
        },
      },
    });

    try {
      await Promise.all([this.saveSites(), this.savePositions()]);

      await this.saveUsers();

      await Promise.map(
        this.shiftsList,
        async (shiftData) => {
          let shift;

          const {
            uuid: shiftUUID,
            stubUUID,
            slots,
            foundSlots,
            locAbbr,
            posAbbr,
            existingAssignments = [],
            manualUpdate,
          } = shiftData;
          let assignmentsToRemove = [];
          if (shiftUUID && existingAssignments.length) {
            const stubAssignments = this.parsedData.assignments
              .filter(
                (assignment) =>
                  assignment.thing.uuid === shiftUUID &&
                  _.includes(
                    _.map(existingAssignments, 'user'),
                    assignment.worker.uuid,
                  ),
              )
              .map((assignment) => ({
                ref: assignment.thing.uuid,
                user: assignment.worker.uuid,
              }));
            assignmentsToRemove = _.differenceBy(
              existingAssignments,
              stubAssignments,
              'user',
            );
          }
          if (stubUUID) {
            // Create a brand new Shift

            if (locAbbr && this.stubMaps.abbrToLocation.has(locAbbr)) {
              _.set(
                shiftData,
                'addressRef',
                this.stubMaps.abbrToLocation.get(locAbbr),
              );
            }

            if (posAbbr && this.stubMaps.titleToPosition.has(posAbbr)) {
              _.set(shiftData, 'search.tags', [
                this.stubMaps.titleToPosition.get(posAbbr),
              ]);
            }

            shift = await dispatch('shifts.create', { data: shiftData });

            runInAction(() => {
              this.saveResults.shifts.new.push(shift);
            });

            stubMap[stubUUID] = shift.uuid;
          } else if (
            shiftUUID &&
            _.isEmpty(manualUpdate) &&
            (!_.isEmpty(assignmentsToRemove) ||
              (_.isNumber(foundSlots) && slots !== foundSlots))
          ) {
            // Handle a change in the number of assigned partners or a change in assigned partners. Do not decreae the number of slots.
            const data = _.extend({}, shiftData, {
              slots: slots < foundSlots ? foundSlots : slots,
            });
            shift = await dispatch('shifts.update', {
              data,
              id: shiftUUID,
            });

            if (foundSlots < slots || !_.isEmpty(assignmentsToRemove)) {
              // Partner was removed from shift
              _.extend(shift, { removePartner: true });
            }

            runInAction(() => {
              this.saveResults.shifts.updated.push(shift);
            });
          } else if (shiftUUID) {
            // Existing Shift
            shift = shiftData;
            runInAction(() => {
              this.saveResults.shifts.dupe.push(shift);
            });
          } else {
            // Default case is bad, mmmkay?
            this.log.error('Unsure how to handle shift data', {
              extra: { shiftData },
            });
            runInAction(() => {
              this.saveResults.shifts.invalid.push(shiftData);
            });
          }

          if (stubUUID) {
            stubMap[stubUUID] = shift.uuid;
          }
        },
        { concurrency: 5 },
      );

      const allShifts = _.union(
        this.saveResults.shifts.new,
        this.saveResults.shifts.updated,
        this.saveResults.shifts.dupe,
        this.saveResults.shifts.invalid,
      );

      await Promise.map(
        this.parsedData.assignments,
        async (assignmentStub) => {
          let thing;
          try {
            const shiftUUID =
              assignmentStub.thing.uuid ||
              stubMap[assignmentStub.thing.stubUUID];

            if (!shiftUUID) {
              runInAction(() => {
                this.saveResults.assignments.invalid.push(
                  _.extend(assignmentStub, { error: 'No ShiftUUID' }),
                );
              });
              return assignmentStub;
            }

            thing = _.find(allShifts, {
              uuid: shiftUUID,
            });

            if (!thing) {
              runInAction(() => {
                this.saveResults.assignments.invalid.push(
                  _.extend(assignmentStub, {
                    error: `No Thing found with UUID ${shiftUUID}`,
                  }),
                );
              });

              return assignmentStub;
            }

            let { worker: user } = assignmentStub;

            let { uuid: userUUID } = user;

            const { stubUUID, isStub: wasStub } = user;

            if (!userUUID) {
              user = this.stubMaps.stubIdToUser.get(stubUUID);
              userUUID = _.get(user, 'uuid', user);
            }

            if (!userUUID || !_.isString(userUUID)) {
              log.error('Unable to find UUID for User');

              runInAction(() => {
                this.saveResults.assignments.invalid.push(
                  _.extend(assignmentStub, {
                    error: `Unable to find User for Stub UUID ${stubUUID}`,
                  }),
                );
              });

              return assignmentStub;
            }

            if (wasStub) {
              runInAction(() => {
                _.extend(assignmentStub, {
                  worker: user,
                });
              });
            }

            const { data } = await dispatch('assignments.runQuery', {
              ref: shiftUUID,
              status: { $ne: 'Canceled' },
              user: userUUID, // ATTN: Assignment Status EP-5523
            });

            let assignment;

            if (data.length > 0) {
              assignment = _.first(data);

              runInAction(() => {
                this.saveResults.assignments.dupe.push(assignment);
              });
            } else {
              assignment = await dispatch('assignments.create', {
                ...assignmentStub,
                thing,
              });
              runInAction(() => {
                this.saveResults.assignments.new.push(assignment);
              });
            }

            return assignment;
          } catch (err) {
            this.log.error('Unable to schedule shift!', err, {
              assignmentStub,
              thing,
            });

            if (err.code === 409) {
              const conflicted = _.extend({ err }, { assignmentStub });

              await dispatch('assignments.create', {
                ...assignmentStub,
                ignoreConflicts: true,
                thing,
              })
                .then((assignment) => {
                  log.debug('Force-saved conflicted shift', { assignment });

                  runInAction(() => {
                    _.extend(conflicted, { assignment });
                    runInAction(() => {
                      this.saveResults.assignments.conflict.push(conflicted);
                    });
                    // this.saveResults.assignments.new.push(assignment);
                  });
                })
                .catch((e2) => {
                  log.error('Failed to force conflict', e2, {
                    assignmentStub,
                    conflicted,
                  });
                  runInAction(() => {
                    this.saveResults.assignments.conflict.push(conflicted);
                  });
                });
            } else {
              runInAction(() => {
                this.saveResults.assignments.invalid.push(
                  _.extend({ error: 'General Error' }, assignmentStub),
                );
              });
            }

            return assignmentStub;
          }
        },
        { concurrency: 5 },
      );

      await Promise.all([
        this.unassignShifts(),
        this.cancelShifts({ allShifts, company }),
      ]);

      //
    } catch (err) {
      this.log.error('Failed to save Results', err);
    }

    this.setSaving(false);
  }

  @action.bound
  async unassignShifts() {
    const allAssignments = _.union(
      this.saveResults.assignments.new,
      this.saveResults.assignments.conflict,
      this.saveResults.assignments.dupe,
      this.saveResults.assignments.invalid,
      this.saveResults.assignments.unassigned,
    );

    await Promise.map(
      this.saveResults.shifts.updated.filter((s) => s.removePartner),
      async (shift) => {
        const assignmentsToKeep = _.filter(allAssignments, {
          ref: shift.uuid,
        });
        try {
          const { data: assignmentsToCancel } = await dispatch(
            'assignments.runQuery',
            {
              ref: shift.uuid,
              status: { $ne: 'Canceled' },
              uuid: { $nin: _.map(assignmentsToKeep, 'uuid') }, // ATTN: Assignment Status EP-5523
            },
          );
          await Promise.map(assignmentsToCancel, async (assignment) => {
            try {
              const updatedAssignment = await dispatch('assignments.update', {
                data: { status: 'Canceled' }, // ATTN: Assignment Status EP-5523
                id: assignment.uuid,
              });
              runInAction(() => {
                this.saveResults.assignments.unassigned.push(updatedAssignment);
              });
            } catch (error) {
              this.log.error(
                'Error Canceling Assignment in Monthly Uploader: ',
                error,
                {
                  extra: {
                    assignmentId: assignment.uuid,
                    shiftId: shift.uuid,
                  },
                },
              );
            }
          });
        } catch (error) {
          this.log.error('Error removing assigned shifts: ', error, {
            extra: {
              shiftId: shift.uuid,
            },
          });
        }
      },
      { concurrency: 5 },
    );
  }

  @action.bound
  async cancelShifts({ company, allShifts }) {
    const { data: shiftsToCancel } = await dispatch('shifts.runQuery', {
      company: _.get(company, 'uuid', company),
      end: { $lte: this.endDate.toDate() },
      'manualUpdate.updatedAt': { $exists: false },
      source: 'csv-import',
      start: { $gte: this.startDate.toDate() },
      status: { $ne: 'Canceled' },
      uuid: { $nin: _.map(allShifts, 'uuid') },
    });

    const canceledShifts = await Promise.map(shiftsToCancel, async (shift) => {
      try {
        const updatedShift = await dispatch('shifts.update', {
          data: { status: 'Canceled' },
          id: shift.uuid,
        });
        runInAction(() => {
          this.saveResults.shifts.canceled.push(updatedShift);
        });
        return shift;
      } catch (error) {
        this.log.error('Error Canceling Shift in Monthly Uploader: ', error, {
          extra: {
            shiftId: shift.uuid,
          },
        });
        return false;
      }
    });

    return Promise.resolve(canceledShifts);
  }

  async populateUsers({ rows, company }) {
    const users = _(rows)
      .map(`[${columnIndexes.user}]`)
      .uniq()
      .compact()
      // .slice(0, 10)
      .value();

    log.debug('Found %d unique users', users.length, { users });

    await Promise.map(
      users,
      action(async (rawUserName) => {
        if (!_.trim(rawUserName)) {
          log.debug('No User Name Available in row');
          return null;
        }
        const name = rawUserName.split(' ');

        const first = name.pop();
        const last = name.join(' ');

        let user = this.stubMaps.nameToUser.get(rawUserName);

        if (!user) {
          const { partner, partners } = await this.getPartnerForName({
            company,
            displayName: rawUserName,
            first,
            last,
          });

          user = partner;

          if (partners && _.size(partners)) {
            log.warn('Multiple matching Partners found!', {
              extra: { company, partners: !global.IS_PRODUCTION && partners },
            });
            this.stubMaps.nameToMultiUser.set(rawUserName, partners);
            return partners;
          }
        }

        if (!user) {
          user = {
            companies: [company.uuid],
            displayName: `${first} ${last}`,
            firstName: first,
            isStub: true,
            lastName: last,
            stubUUID: uuid.v4(),
          };

          this.log.debug('Adding new stub user for name %s', rawUserName, {
            user,
          });
        }

        if (_.isEmpty(user.positions)) {
          user.positions = _(rows)
            .filter((r) => r[columnIndexes.user] === rawUserName)
            .map(`[${columnIndexes.position}]`)
            .uniq()
            .compact()
            .value();

          log.debug(
            `UserPositions: ${rawUserName} has ${user.positions.length} positions`,

            user.positions.join(', '),
          );
        }

        runInAction(() => {
          this.parsedData.users.push(user);
          this.stubMaps.nameToUser.set(rawUserName, user);
        });

        return user;
      }),
      { concurrency: 10 },
    );
  }

  async populateLocations({ rows, company }) {
    const locations = _(rows).map(`[${columnIndexes.location}]`).uniq().value();

    log.debug('Found %d unique locations', locations.length, { locations });

    await Promise.map(
      locations,
      action(async (locationName) => {
        if (!_.trim(locationName)) {
          return null;
        }

        let location = _.find(this.parsedData.locations, {
          abbr: locationName,
        });

        if (!location) {
          const { data } = await dispatch('sites.runQuery', {
            abbr: locationName,
            companies: _.get(company, 'uuid', company),
          });

          location = _.first(data);

          if (location && location.abbr !== locationName) {
            log.error('WTF! ', { abbr: location.abbr, locationName });
          }
        }

        if (!location) {
          location = {
            __t: 'site',
            abbr: locationName,
            address: company.address,
            companies: [company.uuid],
            isStub: true,
            loc: company.loc,
            name: locationName,
            source: `csv-import-${moment().toISOString()}`,
          };

          this.log.debug('Adding new stub location for name %s', locationName, {
            location,
          });
        }

        runInAction(() => {
          if (
            location &&
            location.uuid &&
            !this.stubMaps.abbrToLocation.has(locationName)
          ) {
            this.stubMaps.abbrToLocation.set(locationName, location.uuid);
          }
          this.parsedData.locations.push(location);
        });

        return location;
      }),
    );
  }

  async populatePositions({ rows, company }) {
    const positions = _(rows).map(`[${columnIndexes.position}]`).uniq().value();

    log.debug('Found %d unique positions', positions.length, { positions });

    await Promise.map(
      positions,
      action(async (positionName) => {
        if (!_.trim(positionName)) {
          return null;
        }

        let position = _.find(this.parsedData.positions, {
          title: positionName,
        });

        if (!position) {
          const { data } = await dispatch('positions.runQuery', {
            companies: company.uuid,
            title: positionName,
          });

          position = _.first(data);

          if (position && position.title !== positionName) {
            log.error('WTF! ', { positionName, title: position.title });
          }
        }

        if (!position) {
          position = {
            companies: [company.uuid],
            isStub: true,
            source: `csv-import-${moment().toISOString()}`,
            title: positionName,
          };

          this.log.debug('Adding new stub position for name %s', positionName, {
            location: position,
          });
        }

        runInAction(() => {
          if (
            position &&
            position.uuid &&
            !this.stubMaps.titleToPosition.has(positionName)
          ) {
            this.stubMaps.titleToPosition.set(positionName, position.uuid);
          }
          this.parsedData.positions.push(position);
        });

        return position;
      }),
    );
  }

  async parseDates({ rows }) {
    const shi = _.findIndex(rows, (r) => /^dates/i.test(r[0]));
    const hi = _.findIndex(rows, (r) => /^employee/i.test(r[0]));

    if (shi === -1) {
      return this.sendRejection(
        'File must contain a valid Summary header starting with "`Dates`" and containing first and last date of the schedule range',
      );
    }

    if (hi === -1) {
      return this.sendRejection(
        'File must contain a valid header row with "`Employee`" in the first column',
      );
    }

    const [summaryHeader, header] = _.pullAt(rows, [shi, hi]);
    log.debug('Found Header and SummaryHeader', { header, summaryHeader });

    const h2 = _.find(rows, (r) => /^employee/i.test(r[0]));
    console.assert(!h2, 'summaryHeader should have been removed');

    const dayRegex = /^(su|mo|tu|we|th|fr|sa)$/i;

    const days = _(summaryHeader)
      .filter((c) => dayRegex.test(c))
      .value();

    const dateRegex = /^\d\d?/;

    const dates = _(header)
      .filter((c) => dateRegex.test(c))
      .value();

    if (days.length !== dates.length) {
      const error = new Error(
        'Number of Days must match number of Dates in first two rows',
      );

      log.error('Parse Dates failed', error);
      throw error;
    }

    const [startDate, endDate] = _(summaryHeader)
      .map((c) => moment(c, this.formats))
      .filter((d) => d.isValid())
      .value();

    if (!startDate || !endDate) {
      return this.sendRejection(
        `Unable to identify start and end dates from header: "${summaryHeader.join(
          ', ',
        )}"`,
      );
    }

    const cursorDate = startDate.clone();

    while (cursorDate.isBefore(endDate) || cursorDate.isSame(endDate, 'day')) {
      runInAction(() => {
        this.dates.push(cursorDate.clone());
      });
      cursorDate.add({ days: 1 });
    }

    if (
      moment()
        .subtract({ months: 3 }) // todo: remove or modify
        .isAfter(startDate)
    ) {
      startDate.add({ years: 1 });
      endDate.add({ years: 1 });
    }

    startDate.startOf('day');
    endDate.endOf('day');

    this.log.debug(
      'Parsing Schedule from %s to %s',
      startDate.format('L'),
      endDate.format('L'),
    );

    runInAction(() => {
      this.startDate = startDate;
      this.endDate = endDate;
    });

    return { dates, endDate, startDate };
  }

  async parseShiftData({
    monthDate,
    startDate,
    endDate,
    startTime,
    endTime,
    company,
    location,
    position,
    payableDuration,
  }) {
    const start = startTime.clone();
    const end = endTime.clone();

    const date = start.clone().set({
      date: monthDate,
      hour: start.hours(),
      minutes: start.minutes(),
      seconds: 0,
    });
    start.set({
      date: monthDate,
    });
    end.set({
      date: monthDate,
    });
    if (end.isBefore(start)) {
      end.add({ days: 1 });
    }

    if (start.isBefore(startDate)) {
      this.log.error(
        'Shift Start is before Schedule Start Date %s < %s',
        start.format('lll'),
        startDate.format('lll'),
      );
    }

    if (start.isAfter(endDate)) {
      this.log.error(
        'Shift End is after Schedule End Date %s > %s',
        start.format('lll'),
        endDate.format('lll'),
      );
    }

    log.silly(
      'Scheduling shift at %s - %s',
      start.format('lll'),
      end.format('LT'),
    );

    return this.getShift({
      company,
      date,
      endTime: end,
      location,
      payableDuration,
      position,
      startTime: start,
    });
  }

  @action.bound
  async getShift({
    company,
    date,
    startTime,
    endTime,
    location,
    position,
    payableDuration,
  }) {
    if (!startTime || !endTime) {
      return null;
    }
    const keyData = {
      date: date.format('YYYY-MM-DD'),
      end: endTime.format('LT'),
      location,
      position,
      start: startTime.format('LT'),
    };

    const locUUID = this.stubMaps.abbrToLocation.get(location);
    const posUUID = this.stubMaps.titleToPosition.get(position);

    const key = JSON.stringify(keyData);

    let shift = this.parsedData.shifts.get(key);

    if (!shift) {
      const query = {
        company: _.get(company, 'uuid', company),
        end: endTime.toDate(),
        source: 'csv-import',
        start: startTime.toDate(),
        status: { $nin: ['Deleted', 'Canceled'] },
      };

      if (locUUID) {
        query.addressRef = locUUID;
      }
      if (posUUID) {
        query.certs = posUUID;
      }

      const { data } = await dispatch('shifts.runQuery', query);

      shift = _.first(data);

      if (data.length > 1) {
        this.log.warn('%d shifts in the DB found matching key', data.length, {
          key,
        });
      }

      if (data.length > 0) {
        const { data: existingAssignments } = await dispatch(
          'assignments.runQuery',
          {
            $select: ['user', 'uuid', 'ref'],
            // ATTN: Assignment Status EP-5523
            ref: shift.uuid,
            status: { $ne: 'Canceled' },
          },
        );
        runInAction(() => {
          _.extend(shift, {
            existingAssignments,
            key,
          });
          this.parsedData.shifts.set(key, shift);
        });
      }
    }

    if (!shift) {
      shift = {
        addressRef: null,
        company: _.get(company, 'uuid', company),
        end: endTime.clone(),
        key,
        locAbbr: location,
        payableDuration,
        posAbbr: position,
        slots: 0,
        source: 'csv-import',

        start: startTime.clone(),
        stubUUID: uuid.v4(),

        title: `${position} Shift (${location})`,
      };

      runInAction(() => {
        this.parsedData.shifts.set(key, shift);
      });
    }

    return shift;
  }
}
