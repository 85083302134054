import { action, observable, set } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { init } from '#/shared/forms/notification';

export default class NotificationDetailsModal {
  @observable
  isOpen = false;

  @observable
  sendReminder = false;

  @observable
  selected = {};

  @observable
  parentId = '';

  @observable
  listener = -1;

  @observable
  form = {};

  @observable
  newlyCreated = false;

  @action
  setup({ selected = {}, uuid, parent, sendReminder, open = this.isOpen }) {
    this.sendReminder = !!sendReminder;
    this.parentId = _.get(parent, 'uuid', parent) || '';

    const finishSetup = action((notification) => {
      this.selected = notification;
      this.isOpen = open;
      this.registerListener();

      if (/draft/.test(this.selected.deliveryStatus)) {
        this.form = init(this.selected);
      }
    });

    if (_.isEmpty(selected) && !_.isEmpty(uuid)) {
      dispatch('notifications.get', uuid, { select: false }).then(
        action(finishSetup),
      );
    } else {
      finishSetup(selected);
    }
  }

  @action
  success(res) {
    set(this.selected, res);
    this.newlyCreated = true;

    return new Promise((resolve) => setTimeout(resolve, 1000));
  }

  @action
  open(arg = !this.isOpen) {
    this.registerListener();
    this.isOpen = !!arg;
  }

  @action
  clear() {
    this.selected = {};
    this.sendReminder = false;
    this.parentId = '';

    this.isOpen = false;

    this.newlyCreated = false;

    if (this.listener !== -1) {
      dispatch('notifications.deregisterEventListener', this.listener);
      this.listener = -1;
    }

    if (this.form && _.isFunction(this.form.clear)) {
      this.form.clear();
    }
  }

  @action.bound
  registerListener() {
    this.listener = dispatch('notifications.registerEventListener', {
      onPatched: this.updateSelection,
      onUpdated: this.updateSelection,
    });
  }

  @action.bound
  updateSelection(data) {
    if (data.uuid && data.uuid === this.selected.uuid) {
      set(this.selected, data);
    }
  }
}
