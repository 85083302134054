import type { ZoneForm } from '#/shared/forms/zone';
import type { AddressForm } from '#/shared/forms/address';
import type { SiteForm } from '#/shared/forms/site';
import type { StoreName } from '#/shared/stores';
import type { ICompany } from '@shiftsmartinc/shiftsmart-types';

import { action, observable, computed } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import flatten from 'flat';

import { formInitializers } from '#/shared/forms';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.addressModal');
/**
 *
 * @export
 * @class AddressModal
 */

export default class AddressModal {
  @observable
  isOpen = false;

  @observable
  companyId = '';

  @observable
  company: ICompany = null;

  @observable
  selectedTier = null;

  @observable
  addresses = [];

  @observable
  form: AddressForm | SiteForm | ZoneForm;

  @observable
  isLoading = false;

  @observable enableAdvanced = false;

  @observable showAdvanced = false;

  @observable storeName: StoreName & ('addresses' | 'sites' | 'zones');

  @observable dataType;

  @action.bound
  setup({
    address = {},
    company = {},
    user = {},
    tier,
    open = this.isOpen,
    options,
    storeName = 'addresses',
    dataType = /addresses/.test(storeName)
      ? 'address'
      : storeName.replace(/s$/, ''),

    enableAdvanced,
  }) {
    this.companyId = _.get(company, 'uuid', company);
    this.company = company;
    this.storeName = storeName;
    this.dataType = dataType;

    this.enableAdvanced = _.isUndefined(enableAdvanced)
      ? /^(sites|zones)$/.test(storeName)
      : !!enableAdvanced;

    const init = formInitializers[storeName];

    if (address && address.uuid) {
      this.form = init(address, { uiStoreName: 'ui.addressModal', ...options });
    } else {
      this.form = init(
        _.extend(
          { companies: [this.companyId], user: _.get(user, 'uuid') },
          _.isNumber(tier) ? { tier } : {},
        ),
        { uiStoreName: 'ui.addressModal', ...options },
      );
    }

    this.isOpen = open;
  }

  @computed
  get address() {
    return _.result(this.form, 'values', {});
  }

  @action
  setSelectedTier(selectedTier) {
    this.selectedTier = selectedTier;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  toggleAdvanced = (val = !this.showAdvanced) => {
    this.showAdvanced = val;
    log.debug(`Show Advanced: ${val ? 'YES' : 'NO'}`);
  };

  @action.bound
  async save({ createNew } = {}) {
    const { form, address } = this;

    const { parentIds: existingParentIds } = form.initials();

    const { company } = form.values();

    const allValues = form.values();

    const setupArgs =
      createNew &&
      _.clone({
        company: this.company,
        dataType: this.dataType,
        open: true,
        storeName: this.storeName,
        tier: this.selectedTier,
      });

    let uuid;
    let formValues: Record<string, unknown> = {};
    let formAction;

    try {
      // ie: "Customer"
      if (form.has('firstName')) {
        if (allValues.firstName || allValues.lastName) {
          // Use "form.values" when constructing names in case only one was changed
          if (_.isEmpty(allValues.name)) {
            form.$('name').set(`${allValues.firstName} ${allValues.lastName}`);
          }

          form
            .$('primaryContact')
            .set(`${allValues.firstName} ${allValues.lastName}`);
        }
      }

      const { isValid } = await form.validate({ showErrors: true });

      if (!isValid) {
        log.error('Form is not valid', form.errors());
        form.defaultErrorHook();
        return false;
      }

      if (form.isPristine) {
        dispatch('ui.snackBar.open', 'No changes made');
        return this.clear();
      }

      uuid = form.$('uuid').value;
      // If we are editing, gather only dirty fields
      if (uuid) {
        form.fields.forEach((f) => {
          if (f.isDirty) {
            formValues[f.key] = f.value;
          }
        });
        form.$('uuid').set(uuid);
      } else {
        formValues = form.values();
      }

      if (_.isEmpty(formValues.address) && !form.$('address').isValid) {
        log.debug('The "Address" field on the form is invalid', {
          field: form.$('address'),
        });

        form.$('address').invalidate('Please enter an address');
        form.defaultErrorHook();
        return false;
      }

      if (formValues.phone) {
        const phoneNumber = formValues.phone;
        formValues.phone =
          phoneNumber.indexOf('+1') < 0
            ? `+1${phoneNumber.slice(-10)}`
            : phoneNumber;
      }

      if (formValues.secondaryPhone) {
        const secondaryPhoneNumber = formValues.secondaryPhone;
        formValues.secondaryPhone =
          secondaryPhoneNumber.indexOf('+1') < 0
            ? `+1${secondaryPhoneNumber.slice(-10)}`
            : secondaryPhoneNumber;
      }

      if (company && !_.includes(address.companies, company.uuid)) {
        const companies = _.union(address.companies, [company.uuid]);
        _.extend(formValues, { companies });
      }

      if (formValues.address) {
        if (formValues.address.formattedAddress) {
          formValues.formattedAddress = formValues.address.formattedAddress;
          delete formValues.address.formattedAddress;
        }

        if (
          formValues.formattedAddress &&
          !_.includes(formValues.formattedAddress, formValues.address.street2)
        ) {
          formValues.formattedAddress.replace(
            formValues.address.street1,
            `${formValues.address.street1} ${formValues.address.street2}`,
          );
        }

        if (_.isEmpty(formValues.address.loc)) {
          delete formValues.address.loc;
        }
      }

      formAction = _.isEmpty(uuid) ? 'create' : 'update';
      log.debug(
        `${_.capitalize(formAction.replace(/e$/, ''))}ing Address w/ Data: `,
        formValues,
      );

      // Set formattedAddress & loc on top level
      ['loc', 'formattedAddress'].forEach((key) => {
        const value = _.get(formValues, `address.${key}`, false);
        if (value) {
          _.set(formValues, key, value);
          delete formValues.address[key];
        }
      });

      if (_.has(formValues, 'address')) {
        // Dotify nested fields to avoid
        // overwriting data
        const dotifiedValues = flatten(_.pick(formValues, 'address'));
        delete formValues.address;
        _.extend(
          formValues,
          _.pickBy(dotifiedValues, (v, k) => form.$(k).isDirty),
        );
      }

      const coordinates = _.get(formValues, 'loc.coordinates');

      if (form.$('loc.coordinates').isDirty || !uuid) {
        if (_.isEmpty(_.compact(coordinates))) {
          const oldValues = !!uuid && form.$('loc.coordinates').default;

          if (!_.isEmpty(oldValues)) {
            _.set(formValues, '$unset.loc', '');
          }

          delete formValues.loc;
        } else if (_.size(formValues.loc.coordinates) < 2) {
          form
            .$('loc.coordinates')
            .invalidate('Coordinates must contain both Lat & Lng Values');
          return false;
        } else {
          formValues.loc.coordinates = _.map(coordinates, _.toNumber);
        }
      } else if (uuid && !!formValues.loc) {
        const dotifiedValues = flatten(_.pick(formValues, 'loc'), {
          maxDepth: 2,
        });
        delete formValues.loc;
        _.extend(
          formValues,
          _.pickBy(dotifiedValues, (v, k) => form.$(k).isDirty),
        );
      }
    } catch (err) {
      log.error('[save] Setup Failed', err);
      throw err;
    }

    if (_.isEmpty(formValues) && !!uuid) {
      dispatch('ui.snackBar.warn', 'No changes made');
      if (createNew) {
        this.setup(setupArgs);
      } else {
        this.clear();
      }
      return null;
    }

    if (form.has('parentIds')) {
      const parentIdsPatchData: Record<string, unknown> = {};

      if (!uuid) {
        parentIdsPatchData.parentIds = _.map(
          (this.form as SiteForm).tiers,
          () => null,
        );
      }

      form.$('parentIds').each((parentIdField, index) => {
        const key = `parentIds.${index}`;
        const val = parentIdField.value || null;
        // Checking if this is a update operation and parentIds are not undefined
        if (uuid && _.size(existingParentIds)) {
          // Only update dirty/changed fields
          if (parentIdField.isDirty) {
            parentIdsPatchData[key] = val;
          }
        } else {
          _.set(parentIdsPatchData, key, val);
        }
      });

      delete formValues.parentIds;
      _.extend(formValues, parentIdsPatchData);

      delete formValues.parentObjs;
    }

    if (form.select('settings', null, false)?.isDirty) {
      const settingsField = form.$('settings');
      const settings = form.state.options.get('retrieveOnlyDirtyValues')
        ? settingsField.value
        : form.removePristineNestedFields(
            _.cloneDeep(settingsField.value),
            settingsField,
          );

      const dotifiedSettings = form.dotify(settings, 'settings');

      delete formValues.settings;
      _.extend(formValues, dotifiedSettings);
    }

    log.debug('Saving Values: ', {
      data: formValues,
      formAction,
      id: uuid,
      store: this.storeName,
    });

    const updatedRes = await dispatch(`${this.storeName}.${formAction}`, {
      data: formValues,
      id: uuid,
    }).catch((err) => {
      log.error('Error creating new address', err);
      form.invalidate(err.message);
      form.defaultErrorHook({ err });

      throw err;
    });

    log.debug(`${formAction}d Address`, updatedRes);
    dispatch(
      'ui.snackBar.open',
      `${_.capitalize(this.dataType)} ${formAction}d`,
    );

    if (createNew) {
      dispatch('ui.addressModal.setup', setupArgs);
    } else {
      this.clear();
    }

    return updatedRes;
  }

  @action
  async removeAddress() {
    const res = await dispatch(`${this.storeName}.remove`, this.address.uuid);
    this.form.init(res);
  }

  @action
  async restoreAddress() {
    const res = await dispatch(`${this.storeName}.update`, {
      data: {
        isDeleted: false,
      },
      id: this.address.uuid,
    });

    this.form.init(res);
  }

  @action
  clear() {
    this.isOpen = false;

    this.companyId = '';

    this.showAdvanced = false;
    this.storeName = 'addresses';

    this.isLoading = false;
  }
}
