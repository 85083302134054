import type {
  ICompany,
  CompanyStatusRole,
} from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { dispatch } from 'rfx-core';
import { IconProps } from 'semantic-ui-react';

export type AgentTypeDDLOption = {
  description?: string;
  disabled?: boolean;
  icon?: IconProps['name'];
  key: CompanyStatusRole;
  text: string;
  value: CompanyStatusRole;
};

/**
 * getAgentTypeOptions
 *
 * @param {Object} [arg1]
 * @param {Object} [arg1.modules] An instance of `company.modules`. Will override modules from the `company`
 *        param if both are specified.
 * @param {Object} [arg1.company] The company to pull `modules` from if the `modules` param is not specified
 * @param {bool} [arg1.loadAll] Boolean flag to load all agent types, ignoring `company` and/or `modules` parameters
 * @param {bool} [arg1.keys] Boolean flag to return an array of the `keys` rather than the full object.
 *
 * @description This method returns an array of objects representing all available agent types for the
 * supplied company. The object format is tailored for the Semantic UI, DDL component. Passing specific
 * options enable customization of what is returned and the format of the returned value.
 *
 * ## Notes
 * 1. If neither a `modules` or `company` param is passed, and the `loadAll` flag is not set, modules
 *    from the current company will be loaded via `auth.getModules` which relies on `auth.getCompany`.
 */
export function getAgentTypeOptions({
  company,
  modules: srcModules,
  loadAll,
  keys,
}: {
  company?: ICompany;
  keys?: boolean;
  loadAll?: boolean;
  modules?: ICompany['modules'];
} = {}): Array<AgentTypeDDLOption | CompanyStatusRole> {
  let modules = srcModules;
  if (_.isEmpty(srcModules)) {
    modules = _.isEmpty(company)
      ? dispatch('auth.getModules')
      : company?.modules ?? {};
  }

  const options: Array<AgentTypeDDLOption> = _.compact([
    {
      description: 'Base agent permission, should be set on all active agents',
      key: 'agent',
      text: 'Agent',
      value: 'agent',
    },
    {
      description:
        'User will be restricted to locations which they are assigned',
      key: 'site-manager',
      text: 'Site Manager',
      value: 'site-manager',
    },
    {
      description: 'Full access to all locations and shifts',
      key: 'manager',
      text: 'Manager',
      value: 'manager',
    },
    {
      description:
        'Able to edit all fields on shifts, as well as update shift status',
      key: 'supervisor',
      text: 'Shift Supervisor',
      value: 'supervisor',
    },
    {
      description:
        'Enables access to Company Details with some editing privileges',
      key: 'admin',
      text: 'Team Admin',
      value: 'admin',
    },
    {
      description:
        'The Primary Account Holder. This can only be changed at the company level',
      disabled: true,
      icon: 'lock',
      key: 'owner',
      text: 'Owner',
      value: 'owner',
    },

    (loadAll || !!modules?.retail) && {
      description:
        'Limited account with access only granted to reviewing survey results',
      key: 'survey-review',
      text: 'Survey Review',
      value: 'survey-review',
    },
    {
      description:
        'Limited account with access only granted to reviewing profile videos',
      key: 'video-review',
      text: 'Video Review',
      value: 'video-review',
    },
  ]);

  if (keys) {
    return _.map(options, 'key');
  }

  return options;
}
