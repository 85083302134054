import _ from 'lodash';

export type AddrDetailFields = keyof {
  [K in (typeof fields)[number]]: unknown;
};
const fields = [
  'address.street1',
  'address.street2',
  'address.city',
  'address.state',
  'address.zip',
] as const;

/** @deprecated   No Models use these, they are exclusively used in frontend forms */
const legacyFields = _.union(
  ['address.formattedAddress', 'address.loc'],
  fields,
);

const rules: Record<AddrDetailFields, string> = {
  'address.city': 'string',
  'address.state': 'string',
  'address.street1': 'string',
  'address.street2': 'string',
  'address.zip': 'string',
};

const labels: Record<AddrDetailFields, string> = {
  'address.city': 'City',
  'address.state': 'State',
  'address.street1': 'Street',
  'address.street2': 'Street Line 2 (optional)',
  'address.zip': 'ZIP Code',
};

function getFields({ noPrefix, prefix = noPrefix ? null : 'address' } = {}) {
  return [
    {
      label: 'Address',
      name: _.compact([prefix, 'formattedAddress']).join('.'),
      rules: 'string',
    },
    {
      label: 'Address',
      name: _.compact([prefix, 'loc']).join('.'),
      rules: '',
    },
    {
      label: 'Street',
      name: _.compact([prefix, 'street1']).join('.'),
      rules: 'string',
    },
    {
      label: 'Street Line 2 (optional)',
      name: _.compact([prefix, 'street2']).join('.'),
      rules: 'string',
    },
    {
      label: 'City',
      name: _.compact([prefix, 'city']).join('.'),
      rules: 'string',
    },
    {
      label: 'State',
      name: _.compact([prefix, 'state']).join('.'),
      rules: 'string',
    },
    {
      label: 'ZIP Code',
      name: _.compact([prefix, 'zip']).join('.'),
      rules: 'string',
    },
  ];
}

/* eslint-disable no-param-reassign */
const unified = _.reduce(
  legacyFields,
  (result, field) => {
    result[field] = {
      labels: labels[field],
      rules: rules[field],
    };

    return result;
  },
  {},
);

const nested = _.map(legacyFields, (field) => ({
  label: labels[field],
  name: field.replace('address.', ''),
  rules: rules[field],
}));

export { legacyFields, fields, rules, labels, nested, unified, getFields };
