import { observable, action } from 'mobx';
import { dispatch } from 'rfx-core';

export default class ChatMenu {
  @observable
  isCreateChatPopupOpen = false;

  @observable
  isSearchOpen = false;

  @action
  handleMenuItemClick(key) {
    dispatch('chatChannels.setSelectedMenuItem', key);
    dispatch('ui.chatMessaging.setActiveStatusFilter', 'unresolved');
  }

  @action
  toggleCreateChatPopup() {
    this.isCreateChatPopupOpen = !this.isCreateChatPopupOpen;
  }

  @action
  toggleChatSearch() {
    this.isSearchOpen = !this.isSearchOpen;
  }

  @action
  clear() {
    this.isCreateChatPopupOpen = false;
    this.isSearchOpen = false;
  }
}
