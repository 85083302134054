import { observable, action } from 'mobx';

import { getChildLogger } from '#/shared/utils/client.logger';
export default class SurveyUpload {
  @observable
  isUploading = false;

  @observable
  uploadProgress = 0;

  log = getChildLogger('ui.Dispatch');

  @action
  upload(arg = !this.isUploading) {
    this.isUploading = arg;
  }

  @action
  setProgress(p) {
    this.uploadProgress = p.toFixed();
  }
}
