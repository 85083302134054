import { action, observable, runInAction } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { init as initForm } from '#/shared/forms/userOnboarding';

export default class UserOnboardingModal {
  @observable
  isOpen = false;

  @observable
  form = {};

  @action
  async setup({ userOnboarding = {}, user = {}, open = this.isOpen }) {
    const userId = user?.uuid ?? user;
    if (_.isEmpty(userId)) {
      throw new Error('No userId found');
    }
    const userObj = user?.companies
      ? user
      : await dispatch('workers.get', userId);
    const userOnboardings = await dispatch(
      'userOnboardings.find',
      {
        userId,
      },
      { save: false },
    );
    const userCompanyOnboardingIds = userOnboardings.map(
      (userOnboard) => userOnboard.companyOnboardingId,
    );
    await dispatch('companyOnboardings.find', {
      $client: {
        populateCompany: true,
      },
      company: { $in: userObj.companies },
      uuid: { $nin: userCompanyOnboardingIds },
    });
    runInAction(() => {
      this.form = initForm({ ...userOnboarding, userId });
      this.isOpen = open;
    });
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    this.isOpen = false;
    this.form.clear();
    this.form = {};
  }
}
