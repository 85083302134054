import { observable, action, computed } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import { parsePhoneNumber } from 'libphonenumber-js';
import Promise from 'bluebird';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class PoolUpload {
  displayCount = 50;

  @observable
  companyId = '';

  @observable
  workerList = {};

  @observable
  file = null;

  @observable
  fileRef = {};

  @observable
  poolName = '';

  @observable
  isLoading = false;

  @observable
  isSaving = false;

  @observable
  isOpen = false;

  @observable
  newPhoneNumbers = [];

  @observable
  workersToBeDeleted = 0;

  @observable
  headers = [];

  @observable
  fileParseError = null;

  @observable
  saveResults = {
    dupe: [],
    invalid: [],
    new: [],
    removed: [],
  };

  @observable
  poolSaveError = null;

  // Object is used to store parsed phone number
  // as key and true as value. This structure will help
  // in capturing  a number only once
  @observable
  parsedPhoneNumbers = {};

  @observable
  invalidPhoneNumbers = [];

  log = getChildLogger('ui.PoolUpload');

  @action
  saving(arg = !this.isSaving) {
    this.isSaving = !!arg;
  }

  @action
  clear() {
    this.poolList = {};
    this.workersToBeDeleted = 0;
    this.isOpen = false;
    this.file = null;
    this.fileRef = {};
    this.poolName = '';
    this.isLoading = false;
    this.parsedPhoneNumbers = {};
    this.isSaving = false;
    this.fileParseError = null;
    this.poolSaveError = null;
    this.newPhoneNumbers.clear();
    this.headers.clear();
    this.invalidPhoneNumbers.clear();
    this.saveResults = { dupe: [], invalid: [], new: [], removed: [] };
  }

  setup({ poolId, company }) {
    this.set('poolId', poolId);
    this.companyId = _.get(company, 'uuid', company);
  }

  setFile(file) {
    return this.set('file', file);
  }

  setRef(ref) {
    return this.set('fileRef', ref);
  }

  setPoolName(name) {
    return this.set('poolName', name);
  }

  @computed
  get savePercentage() {
    return (
      (this.saveResults.new.length +
        this.saveResults.invalid.length +
        this.saveResults.dupe.length +
        this.saveResults.removed.length) /
      (this.newPhoneNumbers.length + this.workersToBeDeleted)
    );
  }

  @action
  setPoolSaveError(err) {
    this.poolSaveError = err;
  }

  @action
  setPhoneNumbers(phoneNumbers) {
    _.each(phoneNumbers, (rawPhoneNumber) => {
      let phoneNumber = rawPhoneNumber.phoneNumber;
      // Add plus to front to make sure parsed properly for country code
      if (phoneNumber.indexOf('+') === -1) {
        phoneNumber = `+${phoneNumber}`;
      }
      try {
        const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
        // only add phone number if it's already not there
        if (!(parsedPhoneNumber.number in this.parsedPhoneNumbers)) {
          this.parsedPhoneNumbers[parsedPhoneNumber.number] = true;
          this.newPhoneNumbers.push({ phoneNumber: parsedPhoneNumber.number });
        }
      } catch (error) {
        this.invalidPhoneNumbers.push(
          `${phoneNumber.phoneNumber}: ${error.message}`,
        );
      }
    });
    const headers = _.keys(phoneNumbers[0]);
    this.set('headers', headers);

    return Promise.resolve(this.newPhoneNumbers);
  }

  @action
  setFileParseError(error) {
    this.set('fileParseError', error);
    return Promise.resolve(this.fileParseError);
  }

  @action
  parseCSVResults() {
    dispatch('ui.poolUpload.isLoading');
  }

  @action
  addInvalid(item) {
    this.saveResults.invalid.push(item);
  }

  @action
  addNew(item) {
    this.saveResults.new.push(item);
  }

  @action
  addDupe(item) {
    this.saveResults.dupe.push(item);
  }

  @action
  addRemoved(item) {
    this.saveResults.removed.push(item);
  }

  async removeUsersFromPool({ pool, users }) {
    const log = this.log.getChildLogger('removeUsersFromPool');
    return Promise.map(
      users,
      async (user) => {
        try {
          log.debug('Removing User: ', user.uuid);
          await dispatch('workers.update', {
            data: {
              $pull: { pools: { uuid: pool.uuid } },
            },
            id: user.uuid,
          });
          this.addRemoved(true);
        } catch (error) {
          log.error('Error Deleted users from existing pool: ', error);
          this.addInvalid(_.extend({}, { error, phoneNumber: user }));
        }
      },
      { concurrency: 20 },
    );
  }

  async savePool({ pool, company, isNew }) {
    const log = this.log.getChildLogger('savePool');
    dispatch('ui.poolUpload.saving', true);

    const newPhoneNumbers = Object.keys(this.parsedPhoneNumbers);
    let targetPool = null;
    // create new pool
    if (isNew) {
      const poolName = _.get(pool, 'name', pool);

      const newPoolData = {
        companies: [company],
        company,
        label: poolName,
        poolType: 'upload',
      };

      // try creating a new pool by this name
      try {
        targetPool = await dispatch('pools.create', { data: newPoolData });
      } catch (error) {
        log.error('Error Creating new upload pool: ', error);
        dispatch('ui.poolUpload.setPoolSaveError', error);
        dispatch('ui.poolUpload.saving', false);
        return Promise.reject(error);
      }
    } else {
      targetPool = pool;
      let response = await dispatch('workers.runQuery', {
        phoneNumber: { $nin: newPhoneNumbers },
        'pools.uuid': pool.uuid,
      });
      log.debug('workers to remove ===>', response);

      this.set('workersToBeDeleted', response.total);

      // delete existing workers if this is an existing pool
      await this.removeUsersFromPool({ pool, users: response.data });

      // Figure out if we need to page through full list
      // Want to keep this on the Front End to give easy feedback to user
      let getMore = response.total > response.data.length;
      while (getMore) {
        log.debug('Loading more users: ', response);
        // eslint-disable-next-line
        response = await dispatch('workers.runQuery', {
          phoneNumber: { $nin: newPhoneNumbers },
          'pools.uuid': pool.uuid,
        });
        // eslint-disable-next-line
        await this.removeUsersFromPool({ pool, users: response.data });
        getMore = response.total > response.data.length;
      }
    }

    dispatch('audit.create', {
      data: {
        action: 'Upload Pool',
        companyId: company,
        extra: {
          file: _.pick(this.file, ['name', 'type', 'size']),
        },
      },
    });
    // Find worker by phone number and then update the pool
    return Promise.all(
      Promise.map(
        newPhoneNumbers,
        async (phoneNumber) => {
          try {
            const worker = await dispatch('workers.findOne', {
              query: { phoneNumber },
            });
            log.debug(worker);
            if (!worker) {
              throw new Error('No worker found by this number');
            }
            if (_.find(worker.pools, { uuid: targetPool.uuid })) {
              return this.addDupe(worker);
            }
            // push the new pool to user
            const res = await dispatch('workers.update', {
              data: { $push: { pools: targetPool } },
              id: worker.uuid,
            });
            this.addNew(res);
          } catch (error) {
            this.addInvalid(_.extend({}, { error, phoneNumber }));
          }
          return true;
        },
        { concurrency: 50 },
      ),
    )
      .then(() => this.saveResults)
      .catch((err) => {
        dispatch('ui.poolUpload.setPoolSaveError', err);
        dispatch('ui.poolUpload.saving', false);
      });
  }

  get(name) {
    return this[name];
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(name, val) {
    if (_.isArrayLikeObject(this[name])) {
      _.each(val, (v) => this[name].push(v));
    } else {
      this[name] = val;
    }
    return Promise.resolve(this[name]);
  }
}
