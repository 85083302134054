import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';

import BaseStore from './_baseStore';

export type IAudit = IBaseItem & Record<string, unknown>;

export default class AuditStore extends BaseStore<IAudit> {
  constructor() {
    super({
      baseItem: {},
      modelName: 'audit',
      serviceName: 'audit',
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }
}
