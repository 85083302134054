import { action, observable } from 'mobx';

export default class WorkerCertAdminModal {
  @observable
  isOpen = false;

  @observable
  worker = {};

  @observable
  cert = {};

  @observable
  uuid = '';

  @action
  setup({ worker, cert, open = this.isOpen }) {
    this.worker = worker;
    this.cert = cert;
    this.uuid = cert && cert.uuid ? cert.uuid : '';

    this.isOpen = open;
  }

  @action
  clear() {
    this.worker = observable({});
    this.cert = observable({});
    this.uuid = '';

    this.isOpen = false;
  }
}
