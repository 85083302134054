import _ from 'lodash';
import React from 'react';
import { observer } from 'mobx-react';

import { Button, Popup } from 'semantic-ui-react';

import cx from 'classnames';

/** @deprecated */
const ActionButtons = observer(({ actionButton, ...rest }) => {
  const actionButtonProps = _.isFunction(actionButton)
    ? actionButton({
        ...rest,
        user: rest.user || rest.worker,
      })
    : actionButton;

  if (_.isEmpty(actionButtonProps) || !actionButtonProps) {
    return false;
  }

  const buttons = _.isArray(actionButtonProps)
    ? _.map(actionButtonProps, abp => (
        <ActionButton
          key={rest.key || rest.content || rest.title}
          {...rest}
          {...abp}
        />
      ))
    : [
        !_.isEmpty(actionButtonProps) && (
          <ActionButton
            key={rest.key || rest.content || rest.title}
            {...rest}
            {...actionButtonProps}
          />
        ),
      ];

  if (_.isEmpty(buttons)) {
    return false;
  }

  return _.isArray(buttons) ? <span>{buttons}</span> : buttons;
});

ActionButtons.displayName = 'ActionButtons';

/** @deprecated */
const ActionButton = observer(
  ({ worker, user, isCompact, popup, selectedPTS, ...rest }) => {
    const ab = (
      <Button
        key={rest.key || rest.content || rest.title}
        size="mini"
        className={cx(isCompact && 'compact')}
        {...rest}
        onClick={e =>
          rest &&
          _.isFunction(rest.onClick) &&
          rest.onClick({
            worker: user || worker,
            user,
            selectedPTS,
            ...rest,
            e,
          })
        }
      />
    );

    if (_.isString(popup)) {
      return (
        <Popup
          key={rest.key || rest.content || rest.title}
          content={popup}
          trigger={ab}
        />
      );
    }
    if (_.isObject(popup) && !_.isEmpty(popup)) {
      return (
        <Popup
          key={rest.key || rest.content || rest.title}
          {...popup}
          trigger={ab}
        />
      );
    }
    return ab;
  },
);

ActionButton.displayName = 'ActionButton';

export default ActionButtons;

export { ActionButtons, ActionButton };
