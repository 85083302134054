import { action, observable } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import { dispatch } from 'rfx-core';

export default class CampaignCloneModal {
  @observable
  isOpen = false;

  @observable
  campaign = {};

  @observable
  title = '';

  @observable
  startDate = '';

  @observable
  endDate = '';

  @observable
  isSaving = false;

  @action
  setup({ campaign = {}, open = this.isOpen }) {
    this.campaign = _.omit(campaign, [
      'uuid',
      'archived',
      'completedAt',
      'actionLog',
      'createdAt',
      'updatedAt',
    ]);
    this.title = `${campaign.title} (Copy)`;
    this.startDate = campaign.start
      ? moment(campaign.start).startOf('day')
      : moment().toDate();
    this.endDate = campaign.end
      ? moment(campaign.end).endOf('day')
      : moment().toDate();

    this.isOpen = open;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(field, value) {
    this[field] = value;
  }

  @action
  async save() {
    if (!this.title) {
      dispatch('ui.snackBar.open', 'Please include a title');
      return;
    }
    if (!this.startDate) {
      dispatch('ui.snackBar.open', 'Please include a start date');
      return;
    }
    if (!this.endDate) {
      dispatch('ui.snackBar.open', 'Please include an end date');
      return;
    }
    this.campaign.status = 'Draft';
    this.campaign.title = this.title;
    this.campaign.start = this.startDate;
    this.campaign.end = this.endDate;

    const newQuotas = _.map(this.campaign.quotas, (quota) => {
      const newQuota = _.omit(quota, [
        'completedCount',
        'assignedCount',
        'autoShutoffDisabled',
        'quotaClosed',
      ]);
      return newQuota;
    });
    this.campaign.quotas = newQuotas;

    this.set('isSaving', true);
    let newCampaign;
    try {
      newCampaign = await dispatch('campaigns.create', {
        data: this.campaign,
      });
    } catch (err) {
      dispatch(
        'ui.snackBar.open',
        'There was an issue saving the campaign: ',
        err,
      );
    }
    this.set('isSaving', false);
    this.clear();
    dispatch('routing.push', `/retail/campaigns/${newCampaign.uuid}`);
  }

  @action
  clear() {
    this.campaign = {};
    this.title = '';
    this.startDate = '';
    this.endDate = '';
    this.isOpen = false;
    this.isSaving = false;
  }
}
