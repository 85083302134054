import type { IUser } from '@shiftsmartinc/shiftsmart-types';

import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Popup } from 'semantic-ui-react';
import cx from 'classnames';

import { SimpleUserImage } from '#/shared/components/ssm/users/SimpleUserImage';
import UserRender from '#/shared/components/ssm/users/UserRender';
import styles from '#/shared/styles/UserStack.css';
import { formatLargeNumber } from '#/utils/formatting';

// Styles:

// const log = logdown.getChildLogger('AvatarUserStack');
interface IUserStackTypes {
  hideTooltips?: boolean;
  imageStyle?: string;
  justify?: string;
  limit?: number;
  onClick?: (user: IUser) => void;
  overlap?: number;
  reverse?: boolean;
  size?: number;
  total?: number;
  users: IUser[];
}

const UserStack: React.FC<IUserStackTypes> = observer(
  ({
    users,
    total = users.length,
    limit = 3,
    reverse,
    size = 44,
    overlap = 4,
    hideTooltips = false,
    imageStyle,
    justify = 'center',
    onClick = () => {},
  }) => {
    // If `limit + 1 <= total`, show all users
    const appliedLimit = total <= limit + 1 ? limit + 1 : limit;
    const overflow = Math.max(total - appliedLimit, 0);
    const noPartnersFontSize = Math.max(Math.ceil(size / 3), 12);
    const noPartnersMarginVertical = Math.floor(
      (size - noPartnersFontSize) / 2,
    );
    const overflowText = overflow
      ? formatLargeNumber({ digits: 1, num: overflow })
      : '';
    return (
      <div style={{ position: 'relative' }}>
        {_.isEmpty(users) ? (
          <div
            className="ssm-no-content items-center"
            style={{
              marginBottom: `${noPartnersMarginVertical}px`,
              marginTop: `${noPartnersMarginVertical}px`,
            }}
          >
            No Partners
          </div>
        ) : (
          <div
            className={cx(
              `flex flex-row`,
              `justify-${justify}`,
              styles.userAvatars,
              reverse && 'flex-row-reverse',
            )}
            style={{ paddingLeft: `${overlap}px` }}
          >
            {!!overflow && (
              <div
                className={`flex items-center justify-center ${styles.userCount}`}
                style={{
                  height: `${size}px`,
                  marginLeft: reverse ? `-${overlap}px` : '0',
                  width: `${size}px`,
                  zIndex: appliedLimit,
                }}
              >
                <span
                  className={styles.userCountText}
                  style={{
                    fontSize:
                      _.size(overflowText) < 4
                        ? `${size / 3}px`
                        : `${size / 3.5}px`,
                    lineHeight: `${size / 3}px`,
                  }}
                >
                  {`+${formatLargeNumber({ digits: 1, num: overflow })}`}
                </span>
              </div>
            )}
            {(users || []).slice(0, appliedLimit).map((user, i) => (
              <div
                key={user.uuid}
                onClick={() => onClick(user)}
                role="button"
                tabIndex={0}
              >
                <UserRender
                  renderUser={({ profileImageURL, displayName }) => (
                    <Popup
                      content={<span className="white fw6">{displayName}</span>}
                      disabled={hideTooltips}
                      inverted={true}
                      key={user.uuid}
                      trigger={
                        <div
                          className={cx(
                            styles.userAvatar,
                            hideTooltips ? '' : styles.userAvatarWithTooltip,
                          )}
                          key={user.uuid}
                          style={{
                            height: `${size}px`,
                            marginLeft: `-${overlap}px`,
                            width: `${size}px`,
                            zIndex: appliedLimit - 1 - i,
                          }}
                        >
                          <SimpleUserImage
                            className="w-100 h-100"
                            displayName={displayName}
                            profileImageURL={profileImageURL}
                            style={imageStyle}
                          />
                        </div>
                      }
                    />
                  )}
                  userObj={_.has(user, 'displayName') ? user : undefined}
                  uuid={user.uuid}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  },
);

UserStack.displayName = 'UserStack';
export default UserStack;
