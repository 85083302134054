import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';

import BaseStore from './_baseStore';

export type IUserLocation = IBaseItem & Record<string, unknown>;

export default class UserLocationStore extends BaseStore<IUserLocation> {
  constructor() {
    super({
      baseItem: UserLocationStore.BASE_ITEM,
      serviceName: 'locations',
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  static BASE_ITEM = {
    _modelName: 'location',
    createdAt: null,
    loc: {},
    updatedAt: null,
    user: null,
  };
}
