import { observable, action } from 'mobx';

export default class PoolDetailsModal {
  @observable
  isOpen = false;

  @observable
  pool = {};

  @action
  setup({ pool, open = this.isOpen }) {
    this.pool = pool;

    this.isOpen = open;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    this.pool = {};

    this.isOpen = false;
  }
}
