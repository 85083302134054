import { action, observable } from 'mobx';
import { extend, find, get, pick } from 'lodash';

import { SSMLogger, getChildLogger } from '#/shared/utils/client.logger';
import { getStore } from '#/shared/getStores';
import { IS_PRODUCTION } from '#/config/settings';

export default class AssetUploader {
  constructor() {
    this.files = [];
    this.results = [];
    this.log = getChildLogger('AssetUploader');
  }

  ALLOWED_FILE_TYPES = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/gif',
    'image/jpeg',
    'image/png',
    'text/csv',
    'video/mp4',
    'video/webm',
  ];
  BUCKET = 'RETAIL_PUBLIC';
  MAX_FILE_SIZE = 150;

  @observable errors = {};
  @observable file = {};
  @observable filePicker = null;
  @observable files = [];
  @observable log: SSMLogger;
  @observable progress = 0;
  @observable results = [];

  @observable uploadStatus = 'Ready';

  @action
  selectFile = async (file) => {
    this.uploadStatus = 'Pending';
    const fileObj = pick(file, ['name', 'lastModified', 'size', 'type']);
    this.files.push(fileObj);
    const signedUrl = await this.processFile(file);
    if (signedUrl) {
      this.onFinishUpload(signedUrl, file);
    } else {
      this.onUploadError(signedUrl);
    }
  };

  @action
  onUploadError = (error) => {
    this.uploadStatus = 'Error';
    this.log.error('Error: ', error);
  };

  @action
  onFinishUpload = (data, file) => {
    this.uploadStatus = 'Complete';

    const fileObj = find(this.files, {
      lastModified: file.lastModified,
      name: file.name,
    });

    const publicUrl = data.split('?')[0];
    extend(fileObj, { publicUrl });
    this.results.unshift(fileObj);

    this.log.debug('Finish: ', publicUrl);
  };

  @action
  clear = (clearList?) => {
    this.uploadStatus = null;
    this.progress = 0;
    this.files = [];

    if (clearList) {
      this.results.clear();
    }
  };

  @action
  getSignedUrl = async (file) => {
    this.log.debug('[getSignedUrl] signing file', { file });
    try {
      const query = {
        action: 'sign',
        bucketService: this.BUCKET,
        contentType: file.type,
        filename: file.name,
        operationName: 'putObject',
        useStagingClient: !IS_PRODUCTION,
      };

      const signedUrlRes = await getStore('gcp').get(null, { query });

      const blob = new Blob([file.content], { type: file.type });

      const uploadRes = await fetch(signedUrlRes.signedUrl, {
        body: blob,
        headers: { 'Content-Type': file.type },
        method: 'PUT',
      });

      this.log.debug('[getSignedUrl] Signed file', { signedUrlRes });

      return uploadRes;
    } catch (err) {
      this.log.error('Error Getting Signed Url', err);
      throw err;
    }
  };

  @action
  processFile = async (file) => {
    const bytesInMegabyte = 1000000;

    if (!file) {
      return false;
    }

    this.log.debug('[selectFile] signing file', { file });
    const fileType = get(file, 'type');
    const fileSize = get(file, 'size');
    if (!this.ALLOWED_FILE_TYPES.includes(fileType)) {
      alert(
        this.errors.unsupported ||
          'Unsupported File Type, please upload a supported file type.',
      );
      return false;
    }
    if (fileSize / bytesInMegabyte > this.MAX_FILE_SIZE) {
      alert(
        this.errors.maxSize ||
          `File too large. Files must be smaller than ${this.MAX_FILE_SIZE}mb`,
      );
      return false;
    }

    const res = await this.getSignedUrl(file);

    if (res.status !== 200) {
      alert('Problem uploading file');
      return false;
    } else {
      return res.url;
    }
  };
}
