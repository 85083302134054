import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class CannedResponses {
  log = getChildLogger('ui.CannedResponses');

  @observable cannedResponse = {};

  @observable isAddingNewResponse = false;

  @observable isUpdatingResponse = false;

  @observable title = '';

  @observable message = '';

  @observable index = '';

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value = '') {
    _.set(this, key, value);
  }

  @action.bound setTitle(title: string) {
    this.title = title;
  }

  @action.bound setMessage(message: string) {
    this.message = message;
  }

  @action.bound setIndex(index: string) {
    this.index = index;
  }

  @action.bound setIsAddingNewResponse(isAddingNewResponse: boolean) {
    this.isAddingNewResponse = isAddingNewResponse;
  }

  @action
  async saveNewResponse({ companyId }) {
    const log = this.log.getChildLogger('saveNewResponse');
    if (_.isEmpty(this.title) || _.isEmpty(this.message)) {
      dispatch('ui.snackBar.error', 'Please add both fields');
      return;
    }
    const data = {
      company: companyId,
      index: _.parseInt(this.index),
      message: this.message,
      title: this.title,
    };
    try {
      await dispatch('cannedResponses.create', { data });
    } catch (error) {
      log.error('Error creating canned response: ', error, {
        extra: {
          companyId,
        },
      });
      dispatch('ui.snackBar.error', 'Something went wrong', {
        body: error.message,
      });
      return;
    }
    this.clear();
  }

  @action
  async saveUpdatedResponse() {
    const log = this.log.getChildLogger('saveUpdatedResponse');
    if (_.isEmpty(this.title) || _.isEmpty(this.message)) {
      dispatch('ui.snackBar.error', 'Please add both fields');
      return;
    }
    const responseId = this.cannedResponse.uuid;
    const updatedData = {
      data: {
        index: _.parseInt(this.index),
        message: this.message,
        title: this.title,
      },
      id: responseId,
    };

    try {
      await dispatch('cannedResponses.update', updatedData);
    } catch (error) {
      log.error('Error updating canned response: ', error, {
        extra: {
          responseId,
        },
      });
      return;
    }
    this.clear();
  }

  @action
  async removeCannedResponse(responseId) {
    const log = this.log.getChildLogger('removeCannedResponse');
    try {
      await dispatch('cannedResponses.remove', responseId);
    } catch (error) {
      log.error('Error removing canned response', error);
    }
  }

  @action
  async updateCannedResponse(response) {
    this.cannedResponse = response;
    this.title = response.title;
    this.message = response.message;
    this.index = response.index;
    this.isUpdatingResponse = true;
  }

  @action
  clear() {
    this.cannedResponse = {};
    this.isAddingNewResponse = false;
    this.isUpdatingResponse = false;
    this.title = '';
    this.message = '';
    this.message = '';
    this.index = '';
  }
}
