import { action, observable } from 'mobx';

import { init as initForm } from '#/shared/forms/surveyDefinition';
import { init as initProductForm } from '#/shared/forms/product';
/**
 * Store for UI Form where user can add a Survey Definition to their Company
 *
 * @export
 * @class SurveyDefinitionForm
 */

export default class SurveyDefinitionForm {
  @observable
  form = {};

  @observable
  productForm = {};

  @action
  setup({ surveyDefinition = {}, companyId }) {
    if (surveyDefinition && surveyDefinition.uuid) {
      this.form = initForm(surveyDefinition, { src: 'surveyDefinition' });
    } else {
      this.form = initForm({ company: companyId });
    }

    this.productForm = initProductForm({ company: companyId });

    return Promise.resolve();
  }

  @action
  clear() {
    this.productForm.clear();
    this.form.clear();
  }
}
