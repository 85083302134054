import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export type ICannedResponses = IBaseItem & {
  company: string;
  index: number;
  message: string;
  title: string;
};
export default class CannedResponsesStore extends BaseStore<ICannedResponses> {
  constructor() {
    super({
      baseItem: CannedResponsesStore.BASE_ITEM,
      serviceName: 'cannedResponses',
    });

    return this;
  }

  static BASE_ITEM = {
    company: null,
    createdAt: null,
    message: null,
    title: null,
    updatedAt: null,
    uuid: null,
  };
}
