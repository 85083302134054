import React from 'react';
import { observer } from 'mobx-react';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import { Dropdown, Label } from 'semantic-ui-react';
import cx from 'classnames';
import styled, { css } from 'styled-components';

import { colors } from '#/ssm-ui';

const CaretTabsContainer = styled.div.attrs({
  className:
    'w-100 flex justify-start items-end overflow-x-scroll overflow-y-hidden',
})`
  justify-content: flex-start;
  align-items: flex-end;
  border-bottom: solid 1px #c8c8c8;

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;
interface ItemProps {
  active?: boolean;
  disabled?: boolean;
}
const Item = styled.div<ItemProps>`
  position: relative;
  height: 100%;
  color: ${colors['text-primary']};
  font-size: 14px;
  font-weight: normal;
  padding: 10px 30px 22px;
  transition: color 0.2s linear;

  &:hover {
    color: ${colors['blue-alt']};
  }
  ${(props) =>
    props.active &&
    css`
      color: ${colors['blue-alt']};
      font-weight: bold;
      &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 11px 11px 11px;
        border-color: transparent transparent #c8c8c8 transparent;
        position: absolute;
        bottom: 0;
        left: calc(50% - 11px);
      }

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 11px 11px 11px;
        border-color: transparent transparent #fbfbfd transparent;
        position: absolute;
        bottom: -2px;
        left: calc(50% - 11px);
      }
    `}
`;

// CP from PageMenu.tsx
const CaretTabs = observer(
  ({
    menuTabs,
    activeTab,
    storeName,
    onSelectAction = 'setActiveTab',
    ...rest
  }) => (
    <CaretTabsContainer>
      {_(menuTabs)
        .sortBy('index')
        .reject((tab) => {
          if (!_.has(tab, 'hidden')) return false;

          return _.isFunction(tab.hidden)
            ? tab.hidden({ ...rest })
            : !!tab.hidden;
        })
        .filter((tab) => {
          if (!_.has(tab, 'visible')) return true;

          return _.isFunction(tab.visible)
            ? tab.visible({ ...rest })
            : !!tab.visible;
        })
        .map((tab) =>
          _.isEmpty(tab.items) ? (
            <CaretTabsItem
              activeTab={activeTab}
              disabled={
                _.isFunction(tab.disabled)
                  ? tab.disabled({ ...rest })
                  : !!tab.disabled
              }
              key={tab.key}
              onSelectAction={onSelectAction}
              rest={rest}
              storeName={storeName}
              tab={tab}
            />
          ) : (
            <Dropdown
              content={tab.title}
              icon={tab.icon}
              item={true}
              key={tab.key}
            >
              <Dropdown.Menu>
                {_.map(tab.items, (item) => (
                  <CaretTabsItem
                    activeTab={activeTab}
                    disabled={
                      _.isFunction(item.disabled)
                        ? item.disabled({ ...rest })
                        : !!tab.disabled
                    }
                    key={item.key}
                    onSelectAction={onSelectAction}
                    rest={rest}
                    storeName={storeName}
                    tab={item}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ),
        )
        .value()}
    </CaretTabsContainer>
  ),
);

CaretTabs.displayName = 'PageMenu';
export default CaretTabs;

const CaretTabsItem = observer(
  ({ tab, activeTab, storeName, onSelectAction, rest }) => {
    const badgeCt = _.isFunction(tab.badge)
      ? tab.badge({ ...rest })
      : tab.badge;

    const disabled = _.isFunction(tab.disabled)
      ? tab.disabled({ ...rest })
      : !!tab.disabled;

    const active = activeTab === tab.key;

    let content = _.isFunction(tab.title) ? tab.title({ ...rest }) : tab.title;

    if (badgeCt) {
      content = (
        <div className="flex items-center">
          <div className={true}>{content}</div>
          <div className="ml1">
            <Label basic={true} circular={true} content={badgeCt} size="mini" />
          </div>
        </div>
      );
    }
    // TODO show tab.icon

    return (
      <Item
        active={active}
        className={cx(tab.className, 'ssm-no-text-select')}
        disabled={disabled}
        key={tab.key}
        onClick={() => {
          if (onSelectAction === null) return;
          if (_.isFunction(onSelectAction)) {
            onSelectAction(tab.key);
            return;
          }

          dispatch(`${storeName}.${onSelectAction}`, tab.key);
          if (_.isFunction(tab.onSelect)) {
            tab.onSelect({ ...rest });
          }
        }}
        role="button"
        style={tab.style}
        tabIndex={-1}
      >
        {content}
      </Item>
    );
  },
);

CaretTabsItem.displayName = 'CaretTabsItem';
