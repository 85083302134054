import { computed, observable, action, set, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import moment from 'moment';
import Promise from 'bluebird';

import BaseUploader from './BaseUploader';

export default class InvoiceAndCreditUpload extends BaseUploader {
  constructor() {
    super({ title: 'ui.invoiceAndCreditUpload' });

    set(this.saveResults, {
      dupe: [],
      incomplete: [],
      invalid: [],
      new: [],
    });

    return this;
  }

  @observable
  list = [];

  @observable
  showList = false;

  csvData = [
    ['uuid', 'action'],
    ['371edb32-06a7-49cf-a382-d38e2c0aa795', 'credit'],
    ['b657c1aa-e49c-42af-a0db-07bfb8d80f7e', 'invoice'],
    ['3a000b44-0e52-4d62-bb67-631f54f411c3', 'invoice'],
    ['9a000b44-0e52-4d62-bb67-631f54f411c3', 'credit'],
  ];

  knownHeaders = ['uuid', 'action'];

  @computed
  get savePercentage() {
    return (
      (this.saveResults.new.length +
        this.saveResults.invalid.length +
        this.saveResults.dupe.length) /
      _.size(this.list)
    );
  }

  @action
  clear() {
    super.clear();

    this.saveResults = {
      dupe: [],
      incomplete: [],
      invalid: [],
      new: [],
    };

    this.list.clear();
    this.showList = false;
  }

  @action
  toggleListVisibility() {
    this.showList = !this.showList;
  }

  @action
  async parse({ rows }) {
    const caseHeaders = _.map(rows[0], _.toLower);
    const indices = _.reduce(
      this.knownHeaders,
      (acc, header) => {
        acc[header] = _.indexOf(caseHeaders, _.toLower(header));
        return acc;
      },
      {},
    );

    this.log.debug('Loaded Table Indices', { indices });

    const validRows = _(rows)
      .map((row, i) => {
        if (!i) return null;

        this.log.debug(row);

        return _.chain(this.knownHeaders)
          .reduce((acc, colKey) => {
            const colIndex = indices[colKey];

            if (colIndex >= 0) {
              acc[colKey] = row[colIndex];
            }
            return acc;
          }, {})
          .value();
      })
      .reject((rowObj) => _.isEmpty(_.omitBy(rowObj, _.isEmpty)))
      .compact()
      .value();

    this.setStatus('Evaluating');

    runInAction(() => {
      this.list.replace(validRows);
    });

    this.setStatus('Parsed all Rows');
  }

  @action
  async processAssignmentsRow(row) {
    const assignment = await dispatch('assignments.get', _.trim(row.uuid));
    if (!assignment.data) {
      runInAction(() => {
        this.saveResults.invalid.push(row.uuid);
      });
      return _.mergeWith(
        {
          errorMsg: `No assignment found with uuid ${row.uuid}`,
          isInvalid: true,
        },
        row,
      );
    }
    const user = dispatch('auth.getUser');

    const timestamp = moment().toDate();
    const patchData = {};
    if (/invoice/i.test(row.action)) {
      patchData.invoicedAt = timestamp;
      patchData.invoicedBy = user.uuid;
    } else if (/credit/i.test(row.action)) {
      patchData.creditedAt = timestamp;
      patchData.creditedBy = user.uuid;
    }

    const [updatedAssignment, updatedShift] = await Promise.all([
      dispatch('assignments.update', {
        data: patchData,
        id: assignment.uuid,
      }),
      dispatch('shifts.update', {
        data: patchData,
        id: assignment.ref,
      }),
    ]);
    this.log.debug('Updated Shift & Assignment', {
      updatedAssignmentId: updatedAssignment.uuid,
      updatedShiftId: updatedShift.uuid,
    });

    return updatedAssignment;
  }

  @action
  async save() {
    this.setStatus('saving');
    this.setSaving(true);

    dispatch('audit.create', {
      data: {
        action: 'Upload Invoice & Credit',
        extra: {
          file: _.pick(this.file, ['name', 'type', 'size']),
        },
      },
    });

    try {
      const response = await Promise.map(
        this.list,
        async (row) => {
          try {
            const updatedAssignment = await this.processAssignmentsRow(row);
            runInAction(() => {
              this.saveResults.new.push(updatedAssignment);
            });
            return updatedAssignment;
          } catch (error) {
            this.log.debug(error);
            runInAction(() => {
              this.saveResults.invalid.push({
                error: error.toString(),
                uuid: row.uuid,
              });
            });
            return row;
          }
        },
        { concurrency: 20 },
      );

      runInAction(() => {
        this.list.replace(response);
        this.setStatus('saved');
      });
    } catch (err) {
      this.log.error('Failed to cancel assignments', err);
      dispatch('ui.snackBar.error', `Sorry, something went wrong, ${err}`);
      this.setStatus('error');
      this.clear();
    }

    this.setSaving(false);
  }
}
