import { action, observable, runInAction } from 'mobx';
import _ from 'lodash';

import { init as initForm } from '#/shared/forms/messageTag';

export default class MessageTagEditModal {
  @observable
  isOpen = false;

  @observable
  messageTag = {};

  @observable
  form = {};

  @observable
  newKeyword = '';

  @action
  async setup({ messageTag = null, open = this.isOpen }) {
    this.messageTag = _.clone(messageTag);

    runInAction(() => {
      this.form = initForm(messageTag);
      this.isOpen = open;
    });
  }

  @action
  addKeyword() {
    if (this.newKeyword) {
      this.form.$('keywords').add({ value: this.newKeyword });
      this.newKeyword = '';
    }
  }

  @action
  removeKeyword(value) {
    this.form.$('keywords').del('value', value);
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    this.messageTag = {};

    if (this.form && this.form.clear) {
      this.form.clear();
    }
    this.newKeyword = '';

    this.isOpen = false;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    _.set(this, key, value);
  }
}
