import type { IUser, IUserSchedule } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { action, observable, runInAction } from 'mobx';

import { service } from '#/shared/app';

import BaseStore from './_baseStore';

export default class UserSchedule extends BaseStore<IUserSchedule> {
  constructor() {
    super({
      serviceName: 'userSchedule',
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  @observable breakdown = { differentials: [], effectiveHours: 0, hours: 0 };

  @observable isCached = false;

  @action
  async find(query = {}, opts = {}) {
    const { clear = false, noCache = false } = opts;

    const baseQuery = _.isUndefined(query.query) ? { query } : query;
    if (clear) {
      this.query = baseQuery;
    } else {
      this.query = _.mergeWith(this.query, baseQuery, (baseVal, srcVal) => {
        if (_.isArray(srcVal)) {
          return srcVal;
        }

        return undefined;
      });
    }

    if (noCache) {
      this.query.query.$client = { noCache: true };
    } else if (_.has(this.query.query, '$client.noCache')) {
      delete this.query.query.$client.noCache;
    }

    const res = await service(this.serviceName).find(this.query);

    runInAction(() => {
      if (_.has(res, 'data')) {
        this.list = res.data;
      } else {
        this.list = res;
      }
      this.breakdown = _.get(res, 'breakdown', {});
      this.isCached = _.get(res, 'isCached', false);
    });

    return this.list;
  }

  @action
  emptyList() {
    if (_.isFunction(this.list.clear)) {
      this.list.clear();
    } else {
      this.list = {};
    }

    this.isCached = false;
  }

  @action
  async clearCacheForUser({
    user,
    week,
    year,
  }: {
    user: string | IUser;
    week?: number;
    year?: number;
  }) {
    const query = {
      user: _.get(user, 'uuid', user),
      week,
      year,
    };

    if (_.isEmpty(query.user) || !_.isString(query.user)) {
      return Promise.reject(new Error('Must Provide user to clear cache for'));
    }

    try {
      const [scheduleIntervals, scheduleSummaries] = await Promise.all([
        service('scheduleIntervals').remove(null, { query }),
        service('scheduleSummaries').remove(null, { query }),
      ]);

      this.log.debug('Cleared Cached Data: ', {
        scheduleIntervals,
        scheduleSummaries,
      });

      await this.find({}, { noCache: true });

      return { scheduleIntervals, scheduleSummaries };
    } catch (err) {
      this.log.error('Error while clearing cached data', err);
      return { err, scheduleIntervals: [], scheduleSummaries: [] };
    }
  }
}

export function translateOTRuleString({ ruleString, differential }) {
  if (!_.includes(ruleString, `@${differential.toString()}x`)) {
    return false;
  }

  const split = ruleString.split(/[.@]/);

  let hrs;
  let days;
  let timeSpan;
  while (split.length) {
    const seg = split.pop();

    if (/\d+h/.test(seg) && !/0h/.test(seg)) {
      hrs = seg.replace('h', '');
    }
    if (/^(day|week)$/.test(seg)) {
      timeSpan = seg;
    }
    if (/\d+d$/.test(seg)) {
      days = seg.replace('d', '');
    }
  }

  if (hrs && days && timeSpan) {
    return `Each ${timeSpan}, on hour ${hrs} of day ${days}, ${differential}x overtime will start`;
  }
  if (hrs && timeSpan) {
    return `Each ${timeSpan}, after ${hrs} hours, ${differential}x overtime will start`;
  }
  if (days && timeSpan) {
    return `Each ${timeSpan} after working ${days} consecutive days, ${differential}x overtime will start`;
  }

  return null;
}
