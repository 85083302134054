import _ from 'lodash';
import addressParser from 'parse-address';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('utils.formatting');

function numberToCurrency(num) {
  const realNum = parseFloat(num);
  return realNum
    ? realNum.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    : '0.00';
}

function formatPhoneNumber(rawNumber) {
  let number = rawNumber;

  // Remove any characters that are not a digit (\d) or the plus sign (+)
  number = number.replace(/[^+\d]/g, '');

  // Handle Area Code + 7 USA Short Form (eg: 415-225-1234) by adding a 1
  if (!/^\+/.test(number) && _.size(number.replace(/\D/g, '')) === 10) {
    number = `1${number}`;
  }

  // Add a leading plus if not already present
  if (!/^[+]/.test(number)) {
    number = `+${number}`;
  }

  return number;
}

function formatAddress(addressObj) {
  // CP from addresses model
  const l1 = _.compact([addressObj?.street1, addressObj?.street2]).join(' ');
  let l2 = `${_.compact([addressObj?.city, addressObj?.state]).join(', ')}`;

  if (!_.isEmpty(addressObj?.zip)) {
    l2 = `${l2} ${addressObj.zip}`;
  }

  return _.compact([l1, l2]).join(', ');
}

function parseAddress(addressString) {
  if (_.isEmpty(addressString)) {
    log.warn('Received empty addressString, returning...');
    return addressString;
  }

  const addressObj = addressParser.parseLocation(addressString);
  return {
    city: addressObj.city,
    state: addressObj.state,
    street1: _.compact([
      addressObj.number,
      addressObj.prefix,
      addressObj.street,
      addressObj.type,
    ]).join(' '),
    street2: _.compact([
      addressObj.sec_unit_type,
      addressObj.sec_unit_num,
    ]).join(' '),
    zip: addressObj.zip,
  };
}

function formatLargeNumber({ num, digits }) {
  const lookup = [
    { symbol: '', value: 1 },
    { symbol: 'k', value: 1e3 },
    { symbol: 'M', value: 1e6 },
    { symbol: 'B', value: 1e9 },
    { symbol: 'T', value: 1e12 },
    { symbol: 'P', value: 1e15 },
    { symbol: 'E', value: 1e18 },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(({ value }) => num >= value);
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}

export {
  numberToCurrency,
  formatPhoneNumber,
  formatAddress,
  parseAddress,
  formatLargeNumber,
};
