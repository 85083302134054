import { getStores } from '#/shared/getStores';

import Form from './_.extend';

class InPersonTrainingShiftsForm extends Form {
  hooks = () => ({
    async onSuccess(form) {
      const { shifts } = getStores();
      shifts.update({
        data: {},
        id: shifts.selected.uuid,
        query: {
          $client: {
            multipleInPersonTrainingShifts: {
              numberOfTimeSlots: form.values().numberOfTimeSlots,
              numberOfTraineesPerTimeSlot:
                form.values().numberOfTraineesPerTimeSlot,
              timeIntervals: form.values().timeIntervals,
            },
          },
        },
      });
      return this.successRedirect();
    },
  });

  successRedirect() {
    const { ui } = getStores();
    ui.snackBar.open(
      `In Person Training Shifts have been successfully created!`,
    );
    ui.createInPersonTrainingShiftsModal.clear();
  }
}

const fields = [
  'numberOfTimeSlots',
  'numberOfTraineesPerTimeSlot',
  'timeIntervals',
];

const rules = {
  numberOfTimeSlots: 'required|numeric|between:1,50',
  numberOfTraineesPerTimeSlot: 'required|numeric|between:1,50',
  timeIntervals: 'required|array',
};

const labels = {
  numberOfTimeSlots: 'Number of Time Slots',
  numberOfTraineesPerTimeSlot: 'Number of Trainees per Time Slot',
};

export const init = ({ values = {} }) => {
  return new InPersonTrainingShiftsForm({ fields, labels, rules, values });
};
