import BaseStore from './_baseStore';

export default class CheckrStore extends BaseStore {
  constructor() {
    super({
      baseItem: CheckrStore.BASE_ITEM,
      serviceName: 'checkr',
    });

    return this;
  }

  static BASE_ITEM = {
    createdAt: null,
    invitationId: null,
    package: null,
    tagHasBeenAdded: false,
    updatedAt: null,
    userId: null,
    uuid: null,
  };
}
