import { init as initSite } from './site';
import { init } from './address';
import { init as initZone } from './zone';

const formInitializers = {
  address: init,
  addresses: init,
  site: initSite,
  sites: initSite,
  zone: initZone,
  zones: initZone,
};

export { formInitializers };

export default formInitializers;
