import { action, observable } from 'mobx';

import { init as initForm } from '#/shared/forms/inPersonTrainingShifts';

export default class CreateInPersonTrainingShiftsModal {
  @observable
  isOpen = false;

  @observable
  form = {};

  @action
  setup() {
    this.isOpen = true;
    this.form = initForm({});
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    this.isOpen = false;
    this.form.clear();
  }
}
