import type {
  FormConstructorConfig,
  FormConstructorSetup,
} from '#/shared/forms/_.extend';
import type {
  ICompany,
  ISite,
  IZone,
  Tier,
} from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { dispatch } from 'rfx-core';
import { action, observable, runInAction } from 'mobx';

import { getChildLogger } from '#/shared/utils/client.logger';
import { GLOBAL_TIERS } from '#/shared/stores/zones';

import { mapOptionsToRules, mapOptionsToFields } from './formUtils';
import { AddressForm } from './address';
import {
  legacyFields as addrFields,
  rules as addrRules,
  labels as addrLabels,
} from './addressFields';

const log = getChildLogger('forms.site');

export class SiteForm<T extends ISite = ISite> extends AddressForm<T> {
  constructor(setup: FormConstructorSetup, config?: FormConstructorConfig) {
    super(setup, config);

    this.uiStoreName = 'ui.addressModal';

    const companyId = _.first(this.$('companies').value) as ICompany['uuid'];

    const isUnscoped = (setup.values?.tier as number) >= 5;
    if (_.isEmpty(companyId) && !isUnscoped) {
      this.log.error('No Company configured for form instance');
      throw new Error(
        'BadInput: Must provide `company` or `companies` when initializing form',
      );
    }

    this.initTiers({ companyId, isGlobal: !!isUnscoped, values: setup.values });

    return this;
  }

  uiStoreName;

  @observable
  tiers: Array<ICompany['settings']['zones']['tiers'][0]>;

  @action.bound
  init(
    values: Partial<T> & {
      companyId?: ICompany['uuid'];
      parentObjs?: Array<IZone>;
    } = {},
  ) {
    super.init({
      parentIds: [null, null, null, null, null],
      // parentObjs: [null, null, null, null, null],
      ...values,
    });
    this.initTiers({
      companyId: values?.companyId || _.first(values?.companies ?? []),
      values,
    });
  }

  @action.bound
  async initTiers({
    companyId,
    values,
    isGlobal,
  }: {
    companyId: ICompany['uuid'];
    isGlobal?: boolean;
    values?: FormConstructorSetup['values'] & {
      parentObjs?: Array<IZone>;
    };
  }) {
    if (isGlobal) {
      runInAction(() => {
        this.tiers = GLOBAL_TIERS as unknown as Tier[];
        if (this.has('parentIds')) {
          this.del('parentIds');
        }
        if (this.has('parentObjs')) {
          this.del('parentObjs');
        }
        if (this.has('isActive')) {
          this.del('isActive');
        }
      });
    } else {
      const company: ICompany = await dispatch('companies.get', companyId, {
        select: false,
      });

      const source = {
        ...values,
        parentObjs: _.reduce(
          values.parentObjs,
          (acc, obj) => {
            acc[obj?.tier] = obj;
            return acc;
          },
          [null, null, null, null, null],
        ),
      };

      runInAction(() => {
        const tmp = [];
        const vals = {
          parentIds: [null, null, null, null, null],
          parentObjs: [null, null, null, null, null],
        };
        _(company?.settings?.zones?.tiers)
          .orderBy(['index'], ['asc'])
          .each((tier, i) => {
            const t = { index: i, ...tier };

            _.each(['parentIds', 'parentObjs'], (fieldKey) => {
              if (!this.$(fieldKey).has(`${t.index}`)) {
                this.$(fieldKey).add({});
              }
              const f = this.$(`${fieldKey}.${t.index}`);

              f.set('label', tier.label);
              f.set('initial', values?.[fieldKey]?.[t.index] ?? null);
              f.set('value', values?.[fieldKey]?.[t.index] ?? null);

              _.set(
                vals,
                `${fieldKey}.${t.index}`,
                source?.[fieldKey]?.[t.index] ?? null,
              );
            });

            tmp.push(t);
          });

        log.debug('Updating Parent Info with vals: ', { vals });

        // this.$('parentIds').set('value', vals.parentIds);
        // this.$('parentObjs').set('value', vals.parentObjs);

        this.tiers = _.orderBy(tmp, ['index'], ['asc']);
      });
    }
  }

  hooks = () => ({
    onSuccess() {
      log.error(
        'The Site Form does not implement a form.onSuccess handler. Please see `ui.addressModal.save` for this functionality',
      );
    },
  });
}

export const fields = _.concat(
  [
    'uuid',
    'companies',
    'address',
    'placeId',
    'formattedAddress',
    'loc.coordinates',
    'loc.type',

    'path',
    'name',
    'abbr',
    'phone',
    'email',
    'contactName',
    'externalId',
    'market',
    'region',
    'notes',
    'source',
    'isSubscriptionOnly',
    'isActive',

    'defaultInviteRadius',
    'parentIds[]',

    'shiftLimit',
    'shiftLimitRotationDays',
    'storeNumber',

    'settings.shiftDispatch.preferredRelaunch',
    'settings.shiftDispatch.preferredRelaunch.chunkDelay',
    'settings.shiftDispatch.preferredRelaunch.enableCustomPush',
    'settings.shiftDispatch.preferredRelaunch.enabled',
    'settings.shiftDispatch.preferredRelaunch.maxPartnerCount',
    'settings.shiftDispatch.preferredRelaunch.preferredRelaunchTimeout',
    'settings.shiftDispatch.preferredRelaunch.testMode',
    'settings.shiftDispatch.preferredRelaunch.userChunkSize',
    'settings.shiftDispatch.preferredRelaunch.enforceRestrictions',

    'settings.bonus.dynamicBonus.amount',
    'settings.bonus.dynamicBonus.delay',
    'settings.bonus.dynamicBonus.enabled',
    'settings.bonus.dynamicBonus.poolIds',
    'settings.bonus.dynamicBonus.bonusPrefTemplateId',
    'settings.bonus.dynamicBonus.bonusPrefTemplateSlug',
    'settings.bonus.dynamicBonus.restrictToWorkersNewToLocation',
    'settings.bonus.dynamicBonus.testMode',
  ],
  addrFields,
);

export const rules = _.defaults(
  {
    abbr: 'string',
    address: '',

    companies: 'array',
    contactName: 'string',
    defaultInviteRadius: 'numeric|min:1',

    email: 'string',
    externalId: 'string',
    formattedAddress: 'string',
    isActive: 'boolean',
    isSubscriptionOnly: 'boolean',
    'loc.coordinates': 'array',
    market: 'string',
    name: 'string|required',
    notes: 'string',
    path: 'string',
    phone: 'string|between:10,12',
    placeId: 'string',
    region: 'string',

    'settings.bonus.dynamicBonus.amount': 'numeric',
    'settings.bonus.dynamicBonus.bonusPrefTemplateId': 'string',
    'settings.bonus.dynamicBonus.bonusPrefTemplateSlug': 'string',
    'settings.bonus.dynamicBonus.delay': 'numeric',
    'settings.bonus.dynamicBonus.enabled': 'boolean',
    'settings.bonus.dynamicBonus.poolIds': 'array',
    'settings.bonus.dynamicBonus.restrictToWorkersNewToLocation': 'boolean',
    'settings.bonus.dynamicBonus.testMode': 'boolean',
    'settings.shiftDispatch.preferredRelaunch.chunkDelay': 'numeric',
    'settings.shiftDispatch.preferredRelaunch.enableCustomPush': 'boolean',

    'settings.shiftDispatch.preferredRelaunch.enabled': 'boolean',
    'settings.shiftDispatch.preferredRelaunch.enforceRestrictions': 'boolean',
    'settings.shiftDispatch.preferredRelaunch.maxPartnerCount': 'numeric',
    'settings.shiftDispatch.preferredRelaunch.preferredRelaunchTimeout':
      'numeric',
    'settings.shiftDispatch.preferredRelaunch.testMode': 'boolean',
    'settings.shiftDispatch.preferredRelaunch.userChunkSize': 'numeric',
    shiftLimit: 'numeric',

    shiftLimitRotationDays: 'numeric',
    source: 'string',
    storeNumber: 'string',
    uuid: 'string',
  },
  addrRules,
);

export const labels = _.defaults(
  {
    abbr: 'Abbreviated Title',
    address: 'Address',
    companies: 'companies',

    contactName: 'Onsite Contact Name',

    defaultInviteRadius: 'Default Invite Radius (miles)',
    email: 'Email Address',
    externalId: 'External ID (unique id)',
    formattedAddress: 'Formatted Address',
    isActive: '',
    isSubscriptionOnly: 'Send notification to subscribed emails only',
    'loc.coordinates': 'Coordinates',
    market: 'Market',
    name: 'Site Name',
    notes: 'Notes',
    parentIds: 'Parent Zone Hierarchy',
    path: 'Hierarchy',
    phone: 'Phone Number',
    placeId: 'Google Place ID',
    region: 'Region',

    settings: 'Location Settings',
    'settings.bonus.dynamicBonus': 'Shift Bonus Configuration',
    'settings.bonus.dynamicBonus.amount': 'Bonus Amount',
    'settings.bonus.dynamicBonus.bonusPrefTemplateId':
      'Select Bonus Pref Template',
    'settings.bonus.dynamicBonus.bonusPrefTemplateSlug':
      'Specify Bonus Pref Template by "slug"',
    'settings.bonus.dynamicBonus.delay': 'Minutes Before Start (negative)',
    'settings.bonus.dynamicBonus.enabled': 'Enable Dynamic Bonus',
    'settings.bonus.dynamicBonus.poolIds': 'Apply to Pools',
    'settings.bonus.dynamicBonus.restrictToWorkersNewToLocation':
      'Restrict to New Workers',
    'settings.bonus.dynamicBonus.testMode': 'Test Mode (no bonuses)',

    'settings.shiftDispatch.preferredRelaunch': 'Dispatch Configuration',
    'settings.shiftDispatch.preferredRelaunch.chunkDelay':
      'Priority Chunk Delay (minutes)',
    'settings.shiftDispatch.preferredRelaunch.enableCustomPush':
      'Use Custom Push Message',
    'settings.shiftDispatch.preferredRelaunch.enabled':
      'Enable Dynamic Priority Relaunch',
    'settings.shiftDispatch.preferredRelaunch.enforceRestrictions':
      'Enforce Restrictions',
    'settings.shiftDispatch.preferredRelaunch.maxPartnerCount':
      'Partners to Target (max)',
    'settings.shiftDispatch.preferredRelaunch.preferredRelaunchTimeout':
      'Priority Window (minutes)',
    'settings.shiftDispatch.preferredRelaunch.testMode':
      'Test Mode (no assignments)',
    'settings.shiftDispatch.preferredRelaunch.userChunkSize':
      'Priority Chunk Size',

    shiftLimit: 'Max number of shifts for partner',
    shiftLimitRotationDays: 'Number of days before partner can revisit',
    source: 'Source',
    storeNumber: 'Store Number',
    uuid: 'UUID',
  },
  addrLabels,
);

const extra = {
  isActive: {
    tooltip:
      'In the Ops Portal and Customer Portal: Controls whether or not the site shows up in filters, and whether its shifts will appear when filtered by a parent territory',
  },
};

/** WARN: Non-Standard second constructor argument; this is _not_ passed to the main form constructor as with other forms */
export function init(values = {}, options) {
  log.debug('Creating Site Form from Keys: ', _.keys(values));

  const ruleOptions = _.clone(rules);

  if (options) {
    mapOptionsToRules(ruleOptions, options);
  }

  if (!_.get(values, 'loc.coordinates')) {
    _.set(values, 'loc.coordinates', [null, null]);
  }
  if (values.parentObjs) {
    _.set(
      values,
      'parentObjs',
      _.reduce(
        values.parentObjs,
        (acc, obj) => {
          acc[obj?.tier] = obj;
          return acc;
        },
        [null, null, null, null, null],
      ),
    );
  }

  const form = new SiteForm(
    {
      extra,
      fields,
      labels,
      rules: ruleOptions,
      values,
    },
    {},
  );

  if (options) {
    mapOptionsToFields(form, options);

    if (options.uiStoreName) {
      form.uiStoreName = options.uiStoreName;
    }
  }

  log.debug('Created Customer Form with Values: ', form.values());
  return form;
}
