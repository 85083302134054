import { observer } from 'mobx-react';
import React from 'react';
import {
  Modal as SemanticModal,
  StrictModalProps,
  TransitionablePortal,
  TransitionProps,
  ModalActions,
  ModalHeader,
  ModalContent,
} from 'semantic-ui-react';

type ModalProps = StrictModalProps & { transition?: TransitionProps };

const ModalRoot = observer<React.FC<ModalProps>>(
  ({
    transition = { animation: 'scale', duration: 200 },
    children,
    ...rest
  }) => (
    <TransitionablePortal open={rest.open} transition={transition}>
      <SemanticModal dimmer="inverted" {...rest} open={true}>
        {children}
      </SemanticModal>
    </TransitionablePortal>
  ),
);

export const Modal: React.FC<ModalProps> & {
  Actions: typeof ModalActions;
  Content: typeof ModalContent;
  Header: typeof ModalHeader;
} = Object.assign(ModalRoot, {
  Actions: SemanticModal.Actions,
  Content: SemanticModal.Content,
  Header: SemanticModal.Header,
});

export default Modal;
