import _ from 'lodash';
import { dispatch } from 'rfx-core';
import { action } from 'mobx';

import { service } from '#/shared/app';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('stores.authManagement');

export default class AuthManagementStore {
  @action
  forgotPassword(value) {
    return service('authManagement')
      .create({
        action: 'sendResetPwd',
        notifierOptions: { lastHost: window.location.host },
        value,
      })
      .catch((err) => {
        log
          .getChildLogger('forgotPassword')
          .error('Error sending reset password url: ', err);
        throw err;
      });
  }

  @action
  resetPassword(value) {
    return service('authManagement')
      .create({ action: 'resetPwdLong', value })
      .catch((err) => {
        const validationErrors = _.get(err, 'data.validationErrors');
        if (_.isEmpty(validationErrors)) {
          log
            .getChildLogger('resetPassword')
            .error('Error resetting user password: ', err);
        }
        throw err;
      });
  }

  @action
  changePassword(data) {
    const value = _.omit(data, 'uuid');
    _.extend(value, { user: _.pick(data, 'uuid') });

    return service('authManagement')
      .create({ action: 'passwordChange', value })
      .then((user) => dispatch('auth.updateUser', user))
      .catch((err) => {
        const validationErrors = _.get(err, 'data.validationErrors');
        if (_.isEmpty(validationErrors)) {
          log
            .getChildLogger('changePassword')
            .error('Error changing user password: ', err);
        }
        throw err;
      });
  }

  @action
  unlockAccount({ userId }) {
    return service('user')
      .patch(userId, {
        accountIsLocked: false,
        accountLockedAt: null,
        failedLoginAttempts: 0,
      })
      .catch((err) => {
        log
          .getChildLogger('unlockAccount')
          .error('Error unlocking user account from admin: ', err);
        throw err;
      });
  }

  checkPassword() {
    if (
      global.TYPE === 'CLIENT' &&
      dispatch('auth.getPasswordUpdateDaysRemaining') <= 0
    ) {
      log.debug('Password is expired, redirecting to password reset');
      dispatch('routing.push', '/password');
      return false;
    }

    return true;
  }

  async requestMagicLink({ email }) {
    let showSuccess;
    try {
      await service('authManagement').create({
        action: 'getMagicKey',
        value: {
          email,
        },
      });
      showSuccess = true;
    } catch (err) {
      // Only show error for codes 400 or 5xx. 404 etc should be hidden
      showSuccess = err.code !== 400 && err.code < 500;

      if (!showSuccess) {
        dispatch('ui.snackBar.error', 'Unable to Request Magic Link', {
          body: [err.message],
        });
      }
    }

    if (showSuccess) {
      dispatch('ui.snackBar.open', 'Magic Link Requested', {
        body: 'Please check your email ...',
      });
    }
  }
}
