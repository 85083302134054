import type { ModalRefType } from '#/types/ModalRefType';

import {
  Form as MobxReactForm,
  Field as MobxReactFormField,
  Options,
  Field,
} from 'mobx-react-form';
import { dispatch } from 'rfx-core';
import { action, computed } from 'mobx';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';
import _ from 'lodash';
import moment from 'moment';
import validateUUID from 'uuid-validate';
import { flatten, unflatten } from 'flat';
import { Ref } from 'react';

import { getChildLogger } from '#/shared/utils/client.logger';

import bindings from './_.bindings';
import Inputs from './_.inputs';

/**
  What can I do with mobx-react-form ?

  API: https://foxhound87.github.io/mobx-react-form/docs/api-reference/
  FIELDS: https://foxhound87.github.io/mobx-react-form/docs/defining-fields.html
  ACTIONS: https://foxhound87.github.io/mobx-react-form/docs/actions/
  EVENTS: https://foxhound87.github.io/mobx-react-form/docs/events/
  VALIDATION: https://foxhound87.github.io/mobx-react-form/docs/validation/
  BINDINGS: https://foxhound87.github.io/mobx-react-form/docs/bindings/
*/

export type SSMFormInstance = Form;

export type FormConstructorSetup<T = Record<string, unknown>> = {
  /**
   * @deprecated - does not behave how you would expect it to; default values _do not_
   * get returned by `form.values()`. If you find a valid use case, please note it */
  defaults?: never;
  extra?: Record<string, unknown>;
  /** @deprecated - should be on options object */
  fallback?: boolean;
  fields: Array<string>;
  labels?: Record<string, string>;
  observers?: Record<
    string,
    Array<{ call: MobxReactForm['MOBXEvent']; key: string }>
  >;
  placeholders?: Record<string, string>;
  rules?: unknown;
  schema?: unknown;
  /** @deprecated Not really deprecated, but please be very intentional in its use */
  struct?: unknown;
  values?: Partial<T>;
};

export type FormConstructorConfig = {
  bindings?: unknown;
  /** A handle generated by the `useImperativeHandle` hook. Used in support of the [Self-Contained Modal State Pattern](https://shiftsmart.atlassian.net/wiki/spaces/ENG/pages/1977122820/Self-Contained+Modal+State+Pattern)  */
  handle?: ModalRefType;
  handlers?: unknown;
  hooks?: unknown;
  name?: string;
  options?: Partial<Options['options']>;

  plugins?: unknown;
  /** A unique title for the form, used for the form's logger instance. If not provided, the form's constructor name will be used  */
  title?: string;
};

const DEFAULT_FORM_OPTIONS: Partial<Options['options']> = {
  retrieveOnlyDirtyValues: false,
  showErrorsOnChange: false,
  showErrorsOnInit: false,
  validateOnBlur: true,
  validateOnChange: true,
  validateOnInit: false,
  validationDebounceWait: 400,
};

class Form extends MobxReactForm {
  inputs: Inputs;

  Field: Inputs['Field'];

  Group: Inputs['Group'];

  Label: Inputs['FieldLabel'];

  FieldValue: Inputs['FieldValue'];

  FieldErrorLabel: Inputs['FieldErrorLabel'];

  FieldInput: Inputs['FieldInput'];

  SectionHeading: Inputs['SectionHeading'];

  /** A log instance registered on each form by default */
  log = null;

  /** A handle generated by the `useImperativeHandle` hook. Used in support of the [Self-Contained Modal State Pattern](https://shiftsmart.atlassian.net/wiki/spaces/ENG/pages/1977122820/Self-Contained+Modal+State+Pattern)  */
  handle: FormConstructorConfig['handle'] | Ref<ModalRefType> | Ref<unknown> =
    null;

  /** @deprecated This should not be used; use the options getter, and see notes below in the form constructor */
  opts: Options;

  constructor(setup?: FormConstructorSetup, config?: FormConstructorConfig) {
    super(setup, {
      ...(config ?? {}),
      options: { ...DEFAULT_FORM_OPTIONS, ...(config?.options ?? {}) },
    });

    const { title = `forms.${this.constructor?.name}`, handle } = config ?? {};

    this.log = getChildLogger(title);
    this.handle = handle;

    if (!setup?.fallback && setup?.struct) {
      this.log.warn(
        '⛔️⛔️⛔️  **WARNING** ⛔️⛔️⛔️ \nUsing the `struct` field definition without the `fallback` ' +
          'option enabled is not recommended. The resulting form will _only_ contain those ' +
          'fields specified in the `struct` parameter, which is not likely what you are trying ' +
          'to do.\n\n' +
          'If you are passing a complete field definition in the `struct` param, you can ' +
          'safely ignore this warning.\n\n -Fowler',
        { struct: setup.struct },
      );
    }

    this.inputs = new Inputs(this);

    this.Field = this.inputs.Field;
    this.Group = this.inputs.Group;
    this.Label = this.inputs.FieldLabel;
    this.FieldErrorLabel = this.inputs.FieldErrorLabel;
    this.SectionHeading = this.inputs.SectionHeading;

    this.$ = this.select;

    return this;
  }

  plugins() {
    const rules = {
      is_valid_date: {
        message: 'Invalid date',
        validateRule: (value) =>
          moment(value).isValid() &&
          moment(value).isBefore(moment('01/01/3000', 'MM/DD/YYYY')),
      },
      moment_same_or_after: {
        message: 'The end date/time must be after the start date/time',
        validateRule: (value, compValue) =>
          moment(value).isSameOrAfter(moment(compValue)),
      },
      single_array_element: {
        message: 'The :attribute should have only one value',
        validateRule: (value) => !!_.isArray(value) && _.size(value) <= 1,
      },
      uuid: {
        message: 'Please select an object',
        validateRule: (value) => validateUUID(value),
      },
    };
    return {
      dvr: dvr({
        extend: ({ validator }) => {
          // here we can access the `validatorjs` instance (validator)
          // and we can add the rules using the `register()` method.
          Object.keys(rules).forEach((key) =>
            validator.register(
              key,
              rules[key].validateRule,
              rules[key].message,
            ),
          );
        },
        package: validatorjs,
      }),
    };
  }

  bindings() {
    return bindings;
  }

  onInit() {
    this.log.silly('Form `onInit` Called');
  }

  defaultErrorHook({ err: e2 }: { err?: Error } = {}) {
    try {
      this.onError(e2);
    } catch (err) {
      const errorMessages = _.values(this.errorsByField);

      if (_.size(errorMessages)) {
        dispatch(
          'ui.snackBar.error',
          'Please correct form errors before continuing',
          {
            body: _.compact(errorMessages),
          },
        );
      } else {
        dispatch(
          'ui.snackBar.error',
          'Please correct form errors before continuing',
          {
            body: err.message,
          },
        );
      }
    }
  }

  onError(error?: Error) {
    this.invalidate('Please Correct all Problems before continuing');

    const message = 'Please correct the following errors';
    const errorMessages = _.values(this.errorsByField);
    const err = error ?? new Error(`${message}: ${errorMessages.join(', ')}`);
    this.log.error('Form Validation Failed: "%s"', message, err, {
      extra: { errorMessages, message },
    });
    this.log.debug('Form with Errors', { form: this });

    throw err;
  }

  // @ts-expect-error class/interface mismatch
  onReset(form) {
    form.each((field) => {
      field.resetValidation();
    });
  }

  // #region Utility Methods
  /**
   * Safely sets the value of a field on the form (Whether that field exists in the form or not)
   */
  @action.bound
  safeFieldSet(setFieldKey: string, value?: Parameters<Form['set']>['1']) {
    if (this.has(setFieldKey)) {
      this.$(setFieldKey).set('value', value);
    }
  }

  /** Safely calls the `field.clear` method on all fields specified in the argument array */
  @action.bound
  safeFieldsClear(...setFieldKeys: Array<string>) {
    _.forEach(setFieldKeys, (setFieldKey) => {
      if (this.has(setFieldKey)) {
        this.$(setFieldKey).clear();
      }
    });
  }

  /**
   * Converts any date fields back to dates, and if the value is unchanged
   * from `initial`, will `reset` the field so it is no longer dirty.
   *
   * ATTN: This mutates the passed-in data object
   */
  @action.bound
  mapMomentBackToDate(
    data,
    formOrField?: Form | Field,
  ): Record<string, unknown> {
    const root = formOrField ?? this;

    _.each(_.keys(data), (path) => {
      const field = root.select(path, null, false);
      if (field && field.hasNestedFields) {
        this.mapMomentBackToDate(data[path], root.$(path));
      } else if (!!data[path] && moment(data[path]).isValid()) {
        if (moment(data[path]).isSame(field.initial)) {
          field.reset();
        } else if (moment.isMoment(data[path])) {
          field.set('value', moment(data[path]).toDate());
        }
      }
    });

    return data;
  }

  /**
   * @name
   * ## `removePristineNestedFields`
   * @description
   * Maps over the passed in values and removes any nested field
   * values that are `pristine` (ie: not dirty). If the resulting parent
   * field is empty, it will be removed from the data object.
   *
   * @deprecated use `getDirtyValues` instead
   * */
  @action.bound
  removePristineNestedFields(
    inputData = this.values(),
    formOrField?: Form | Field,
    options?: { mutate?: boolean },
  ): void {
    const data = options?.mutate ? inputData : _.cloneDeep(inputData);
    const root = formOrField ?? this;

    /* eslint-disable no-param-reassign */
    _.each(_.keys(data), (path) => {
      const field = root.select(path, null, false);
      if (field && field.hasNestedFields) {
        this.removePristineNestedFields(data[path], root.$(path), options);

        // If array has no dirty nested values, the field is not dirty
        if (_.isArray(data[path]) && _.every(data[path], _.isEmpty)) {
          delete data[path];
        } else if (_.isEmpty(data[path])) {
          delete data[path];
        }
      } else if (field && root !== this) {
        // Handle Date Fields
        if (
          !!data[path] &&
          moment(data[path]).isValid() &&
          /date/.test(field.rules)
        ) {
          if (moment(data[path]).isSame(field.initial)) {
            delete data[path];
          }
        } else if (!field.isDirty) {
          delete data[path];
        }
      }
    });
    /* eslint-enable no-param-reassign */

    return data;
  }

  /**
   * @name
   * ## `getDirtyValues`
   *
   * @description
   * Returns a new object with only the dirty values from the form. This method is
   * more reliable than `removePristineNestedFields` because it reduces the form's
   * dirty values first into a flat object, then picks only those values that are dirty.
   * Whereas the `removePristineNestedFields` method often falls subject to dirty nested
   * fields remaining in place (despite being empty) because the parent field is dirty.
   */
  getDirtyValues() {
    const flat = this.dotify(this.values());

    const nonEmptyStringArrays = _(flat)
      .pickBy((v, k) => /\.\d+$/.test(k))
      .reduce((acc, v, k) => {
        _.set(acc, k, v);
        return acc;
      }, {});

    const cleansed = _(flat)
      .extend(nonEmptyStringArrays)
      .reduce((acc, value, key) => {
        const field = this.select(key, null, false);
        if (!field) {
          this.log.silly(`${key} is not a field on this form`);
          return acc;
        }
        if (!field?.isDirty) {
          return acc;
        }
        if (_.isEmpty(value) && field.hasNestedFields) {
          return acc;
        }
        return _.extend(acc, { [key]: value });
      }, {});

    return unflatten(cleansed);
  }

  @computed
  get errorsByField() {
    const errors = _.omitBy(this.dotify(this.errors()), _.isEmpty);

    this.log.debug('Form has dotified errors', { errors });

    return errors;
  }

  fieldToObject(field) {
    const recurse = (f) => {
      const { key, value } = f;

      const retval = { [f.key]: value };

      if (f.hasNestedFields) {
        // eslint-disable-next-line no-restricted-properties
        if (global.isFinite(key)) {
          if (f.isPristine) {
            return {};
          }
        }
        const mapped = _.reduce(
          f.map(recurse),
          (acc, v) => _.extend(acc, v),
          {},
        );
        _.extend(retval, { [key]: mapped });
      }

      return retval;
    };

    const retval = recurse(field);

    return retval;
  }

  dotify(data, prefix?: string) {
    const object =
      data instanceof MobxReactFormField ? this.fieldToObject(data) : data;

    const flattened: Record<string, unknown> = flatten(object);

    return prefix
      ? _.mapKeys(flattened, (v, k) => [prefix, k].join('.'))
      : flattened;
  }
}

export default Form;
