import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { dispatch } from 'rfx-core';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import _ from 'lodash';
import ReactMapboxGl, {
  Layer,
  Source,
  Cluster,
  Feature,
  Marker,
} from 'react-mapbox-gl';
import { Icon } from 'semantic-ui-react';
import geohash from 'ngeohash';

import { MapLoader } from '#/shared/components/utils/MapWrapper';
import {
  RadiusCircles,
  HomeMarker,
  getClusterMarkerFactory,
  getItemMarker,
} from '#/shared/components/utils/MapElements';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('NewMapComponent');

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoicGF2YW5zc20iLCJhIjoiY2o4M3U5MXpsMDF4ZjMycWIyYWEza3lmZiJ9.dv4gWZhzcScEafdWHBvuzQ';

// eslint-disable-next-line no-underscore-dangle
let _MapBox;

const factoryProps = [
  'accessToken',
  'apiUrl',
  'minZoom',
  'maxZoom',
  'scrollZoom',
  'hash',
  'preserveDrawingBuffer',
  'interactive',
  'attributionControl',
  'customAttribution',
  'logoPosition',
  'renderWorldCopies',
  'dragRotate',
  'pitchWithRotate',
  'trackResize',
  'touchZoomRotate',
  'doubleClickZoom',
  'keyboard',
  'dragPan',
  'refreshExpiredTiles',
  'failIfMajorPerformanceCaveat',
  'bearingSnap',
  'antialias',
];

@observer
export default class MapComponent extends Component {
  static propTypes = {
    center: PropTypes.array,
    changeOn: PropTypes.object,
    clusters: PropTypes.array,
    company: PropTypes.object,
    defaultCenter: PropTypes.array,
    enableDebugInfo: PropTypes.bool,
    eventHandlers: PropTypes.object,
    fixed: PropTypes.bool,

    layers: PropTypes.any,

    mapStyle: PropTypes.string,

    markers: PropTypes.oneOfType([
      PropTypes.func,
      MobxPropTypes.objectOrObservableObject,
    ]),

    /* The following props are not used in the app [PDF 04-04-2018] */
    // worker: PropTypes.object,
    onClickThing: PropTypes.func,

    onClickWorker: PropTypes.func,

    selected: PropTypes.array,

    showPlaceholder: PropTypes.bool,

    things: PropTypes.array,

    workers: PropTypes.array,
    zoom: PropTypes.number,
  };

  static defaultProps = {
    changeOn: {
      selected: true,
      things: true,
      workers: true,
    },
    defaultCenter: _.get(dispatch('auth.getCompany'), 'loc.coordinates'),
    eventHandlers: {},
    fixed: true,
    mapStyle: 'outdoors',
  };

  get MapBox() {
    if (!_MapBox) {
      _MapBox = global.IS_SSR ? (
        <div />
      ) : (
        ReactMapboxGl({
          accessToken: process.env.RADAR_PUBLIC_KEY || MAPBOX_ACCESS_TOKEN,
          attributionControl: false,

          ..._.pick(this.props, factoryProps),
        })
      );
    }

    return _MapBox;
  }

  render() {
    if (!process.env.RADAR_PUBLIC_KEY) log.warn('No Radar Public Key');
    const {
      selected,
      workers,
      clusters,
      things,
      layers,
      company,
      markers,
      showPlaceholder,
      mapStyle,
      fixed,
      defaultCenter,
      enableDebugInfo,
    } = this.props;

    if (showPlaceholder || global.TYPE !== 'CLIENT') {
      log.info('Returning loading map placeholder');
      return <MapLoader />;
    }

    const selectedItems = _.compact(
      _.isArrayLikeObject(selected) ? selected : [selected],
    );

    log.debug('Rendering Items', { selectedItems });

    log.info('Rendering %d Shifts/Things', _.size(things), { things });

    const lineLayout = {
      'line-cap': 'round',
      'line-join': 'round',
    };

    const linePaint = {
      'line-color': '#2A6CC4',
      'line-width': 2,
    };

    let mappedRoute;
    let route;

    const { eventHandlers, onClickWorker, onClickThing } = this.props;

    const clusterProps = {
      maxZoom: 100,
      radius: 50,
      zoomOnClick: true,
      zoomOnClickPadding: 250,
    };

    const mapPositionProps = { center: defaultCenter };

    let viewport;

    try {
      viewport = dispatch('ui.mapInstance.getInstanceViewport');
    } catch (err) {
      log.verbose('failed to load instance viewport', err);
    }

    if (fixed) {
      const { zoom, center, bounds } = dispatch(
        'ui.mapInstance.calcMapBounds',
        this.props,
      );

      _.extend(mapPositionProps, { center, fitBounds: bounds, zoom: [zoom] });
    }

    log.debug('Rendering with center/zoom', {
      ...mapPositionProps,
      // ...clusterProps,
    });

    log.silly('Rendering with Items', {
      clusters,
      selectedItems,
      things,
      workers,
    });

    const { MapBox } = this;

    try {
      return (
        <MapBox
          containerStyle={{
            height: '100%',
            width: '100%',
          }}
          style={
            process.env.RADAR_ACCESS_TOKEN
              ? `https://api.radar.io/maps/styles/radar-default-v1?publishableKey=${process.env.RADAR_PUBLIC_KEY}`
              : `mapbox://styles/mapbox/${mapStyle}-v9`
          }
          {...mapPositionProps}
          {...eventHandlers}
        >
          <RadiusCircles items={selectedItems} key="radii-markers" />

          <HomeMarker home={company} key="home-marker" />

          {!!enableDebugInfo && (
            <Layer
              key="geohash-reference-layer"
              paint={{
                'fill-color': 'purple',
                'fill-opacity': 0.1,
                'fill-outline-color': 'purple',
              }}
              type="fill"
            >
              {_.map(_.range(1, 10), (precision) => {
                try {
                  const { center: mapCenter } = mapPositionProps;
                  const hash = geohash.encode(
                    mapCenter[1],
                    mapCenter[0],
                    precision,
                  );

                  const [swLat, swLon, neLat, neLon] =
                    geohash.decode_bbox(hash);
                  const polyCoords = [
                    [swLon, swLat],
                    [swLon, neLat],
                    [neLon, neLat],
                    [neLon, swLat],
                    [swLon, swLat],
                  ];

                  return (
                    <Feature
                      coordinates={[polyCoords]}
                      properties={{ description: hash }}
                    />
                  );
                } catch (err) {
                  log.error('failed to render feature', err);
                  return false;
                }
              })}
            </Layer>
          )}

          {!!enableDebugInfo &&
            _.map(_.range(1, 10), (precision) => {
              try {
                const { center: mapCenter } = mapPositionProps;
                const hash = geohash.encode(
                  mapCenter[1],
                  mapCenter[0],
                  precision,
                );

                const [swLat, swLon, neLat, neLon] = geohash.decode_bbox(hash);
                const polyCoords = [
                  [swLon, swLat],
                  [swLon, neLat],
                  [neLon, neLat],
                  [neLon, swLat],
                  [swLon, swLat],
                ];

                const coords = polyCoords[precision % 4];

                return (
                  <Marker coordinates={coords} key={hash}>
                    <div className="b">
                      {precision}: {hash}
                    </div>
                  </Marker>
                );
              } catch (err) {
                log.error('failed to render feature', err);
                return false;
              }
            })}

          {/* Workers Cluster */}
          <Cluster
            ClusterMarkerFactory={getClusterMarkerFactory('worker')}
            {...clusterProps}
          >
            {(workers || []).map((worker) =>
              getItemMarker({
                item: worker,
                key: worker.uuid,
                onClick: onClickWorker,
                opts: { markers },
                selected,
                viewport,
              }),
            )}
          </Cluster>

          {/* Server Side Clusters */}
          <Cluster
            ClusterMarkerFactory={getClusterMarkerFactory('cluster')}
            {...clusterProps}
            radius={50}
          >
            {(clusters || []).map((cluster) =>
              getItemMarker({
                item: cluster,
                key: cluster.id,
                onClick: () => {
                  dispatch('ui.mapInstance.setZoomAndCenter', {
                    center: _.pick(cluster, ['lat', 'lng']),
                    zoomIncrement: 1.5,
                  });
                },
                onClickWorker,
                opts: {},
                selected,
                viewport,
              }),
            )}
          </Cluster>

          {/* Things Cluster */}
          <Cluster
            ClusterMarkerFactory={getClusterMarkerFactory('thing')}
            {...clusterProps}
          >
            {(things || []).map((thing) => {
              const retval = getItemMarker({
                item: thing,
                key: thing.uuid,
                onClick: onClickThing,
                opts: { markers },
                selected,
                viewport,
              });
              log.info('Rendering Thing: ', { retval, thing });

              return retval;
            })}
          </Cluster>

          {/* Selected Items */}
          {!!selectedItems &&
            _.map(selectedItems, (o) =>
              getItemMarker({
                item: o,
                key: o.uuid,
                onClickThing,
                onClickWorker,
                opts: { filter: company },
                selected,
                viewport,
              }),
            )}

          {mappedRoute && (
            <div>
              <Source geoJsonSource={route} id="source_id" />
              <Layer
                id="layer_id"
                layout={lineLayout}
                paint={linePaint}
                sourceId="source_id"
                type="line"
              />
            </div>
          )}

          {layers}
        </MapBox>
      );
    } catch (err) {
      log.error('Failed to render map', err);

      return (
        <div className="h-100 w-100 bg-light-blue flex items-center justify-center">
          <Icon
            color="grey"
            name="remove"
            size="huge"
            style={{ opacity: '0.5' }}
          />
        </div>
      );
    }
  }
}
