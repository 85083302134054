import React, { ReactNode, useState } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';
import styled, { css, StyledComponent } from 'styled-components';

import SSMPageHeader from '#/shared/components/ssm/SiteHeader';
import { NavBar } from '#/shared/components/ssm/NavBar';
import { ErrorBoundary } from '#/shared/components/utils/ErrorBoundary';
import AdminNavBar from '#/shared/components/admin/Menu';
import { breakpoints } from '#/ssm-ui';
import { getChildLogger } from '#/shared/utils/client.logger';
import { GlobalEnvironmentBanner } from '#/global/components/GlobalEnvironmentBanner';
import { useStores } from '#/shared/hooks/useStores';

// Components
const log = getChildLogger('AppContainer');

type AppContainerProps = {
  children: ReactNode;
  routes: unknown;
};
const AppContainer = observer(({ routes, children }: AppContainerProps) => {
  log.verbose('Rendering App Container');
  const { app, auth, prefs, ui, routing } = useStores();
  const { header } = ui;
  const { user } = auth;

  const heights = [
    { maxSize: 70, minSize: 70, resize: 'fixed', size: 70 },
    { resize: 'stretch' },
  ];

  const isAdminRoute = _(routes).map('path').includes('admin');

  const route =
    _(routes)
      .filter((r) => r.name)
      .last() ||
    _(routes)
      .filter((r) => r.path !== '/' && r.path)
      .first();

  const title =
    _.get(route, 'name') || _.get(route, 'path', '').replace(/\(.*\)/, '');

  const isAuthd = !!auth.check;

  // assume header props;
  const headerProps = _.defaults(header, {
    isAuthd,
    title: _.capitalize(title),
  });

  const isNavbarMounted = isAuthd && auth.passwordUpdateDaysRemaining > 0;
  const isNavbarVisible = ui.isNavVisible || ui.breakpoints.su;

  return (
    <div className="w-100 vh-100 flex-column flex overflow-hidden">
      {auth.passwordUpdateDaysRemaining < 5 &&
        !ui.auth.passwordExpirationBannerHidden && (
          <ErrorBoundary title="AppContainer.Message">
            <Message
              error={auth.passwordUpdateDaysRemaining <= 0}
              warning={
                auth.passwordUpdateDaysRemaining > 0 &&
                auth.passwordUpdateDaysRemaining < 5
              }
            >
              <div className="flex flex-row justify-between">
                <p>
                  {auth.passwordUpdateDaysRemaining > 0
                    ? `Your password will expire in ${
                        auth.passwordUpdateDaysRemaining
                      } day${
                        auth.passwordUpdateDaysRemaining === 1 ? '' : 's'
                      }.`
                    : 'Your password has expired.'}{' '}
                  Click{' '}
                  <span
                    className="b"
                    onClick={() => routing.push('/password')}
                    role="button"
                    tabIndex={-1}
                  >
                    here
                  </span>{' '}
                  to update.
                </p>
                {auth.passwordUpdateDaysRemaining > 0 && (
                  <span
                    className="b"
                    onClick={() => ui.auth.closePasswordExpirationBanner()}
                    role="button"
                    tabIndex={-1}
                  >
                    Dismiss
                  </span>
                )}
              </div>
            </Message>
          </ErrorBoundary>
        )}
      <GlobalEnvironmentBanner app={app} />
      {isAuthd && (
        <div
          className={cx('bg-white', 'pl3 w-100', 'bb b--black-20')}
          style={{
            height: `${heights[0].size}px`,
          }}
        >
          <ErrorBoundary title="AppContainer.SSMPageHeader">
            <SSMPageHeader
              {...headerProps}
              companies={user.isAdmin ? prefs.recentCompanies : prefs.companies}
              isAuthd={isAuthd}
            />
          </ErrorBoundary>
        </div>
      )}

      <div className="w-100 h4 flex-grow-1 flex">
        {isNavbarMounted && (
          <ErrorBoundary title="AppContainer.NavBar">
            <StyledNavBarWrapper
              isAdmin={isAdminRoute}
              isVisible={isNavbarVisible}
              onClick={() => ui.breakpoints.xs && ui.toggleNavVisible(false)}
              width={ui.navBarWidth}
            >
              {!isAdminRoute ? (
                <NavBar routes={routes} />
              ) : (
                <AdminNavBar
                  activeItem={ui.admin.activeItem}
                  collapsed={ui.breakpoints.su && ui.isNavCollapsed}
                />
              )}
            </StyledNavBarWrapper>
          </ErrorBoundary>
        )}
        <div
          className="w-90 flex-grow-1"
          onClick={() => ui.breakpoints.xs && ui.toggleNavVisible(false)}
          role="main"
          style={
            isNavbarMounted && isNavbarVisible
              ? { maxWidth: `${window.innerWidth - ui.navBarWidth}px` }
              : {}
          }
        >
          <ErrorBoundary title="AppContainer.body">{children}</ErrorBoundary>
        </div>
      </div>
    </div>
  );
});

AppContainer.displayName = 'AppContainer';

type NavBarWrapperProps = {
  isAdmin?: boolean;
  isVisible?: boolean;
  width: number;
};

const StyledNavBarWrapper: StyledComponent<
  'div',
  any,
  NavBarWrapperProps
> = styled.div.attrs<NavBarWrapperProps>(({ isAdmin, width }) => ({
  className: cx({
    'flex-shrink-0': true,
    'h-100': true,
    pl3: isAdmin && width > 100,
    'ssm-no-text-select': true,
  }),
}))<NavBarWrapperProps>`
  background: ${({ isAdmin }) => (isAdmin ? '#2a6cc4' : '#ffffff')};
  border-right: #e9ecef solid 1px;
  width: ${({ width }) => width}px;

  @media ${breakpoints.su} {
    height: 100%;
  }

  @media ${breakpoints.xs} {
    ${({ isVisible }) => !isVisible && 'display: none'};
    position: fixed;
    z-index: 1000;

    ${({ isVisible }) =>
      isVisible &&
      css`
        max-height: calc(100vh);
        width: 55%;
        top: 0;
        left: 0;
        max-width: 250px;
        box-shadow: 5px 0 20px 0 rgba(34, 36, 38, 0.12);
      `}
  }
`;
AppContainer.displayName = 'AppContainer';
export default AppContainer;
