import type { ICompanyOnboarding } from '@shiftsmartinc/shiftsmart-types';

import { action, runInAction, observable, computed } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';

import BaseUploader from '../BaseUploader';

export default class UserOnboardingUploader extends BaseUploader {
  constructor() {
    super({ title: 'ui.userOnboardingUploader' });

    return this;
  }

  @observable saveConfirmMessage = '';

  @observable
  parsedHeaders = [];

  @computed
  get sampleCSVData() {
    return [this.knownHeaders, ['userId1', 'companyId1']];
  }

  @computed
  get knownHeaders() {
    return ['userId', 'companyOnboardingId'];
  }

  @observable saveButtonText = 'Save';

  @observable
  list = [];

  @action
  async parse({ rows }) {
    const caseHeaders = _.map(rows[0], _.toLower);
    this.parsedHeaders = this.knownHeaders;
    const indices = _.reduce(
      this.knownHeaders,
      (acc, header) => {
        acc[header] = _.indexOf(caseHeaders, _.toLower(header));
        return acc;
      },
      {},
    );

    this.log.debug('Loaded Table Indices', { indices });

    const validRows = _(rows)
      .map((row, i) => {
        if (!i) return null;

        this.log.debug(row);

        return _.chain(this.knownHeaders)
          .reduce((acc, colKey) => {
            const colIndex = indices[colKey];

            if (colIndex >= 0) {
              acc[colKey] = row[colIndex];
            }
            return acc;
          }, {})
          .value();
      })
      .reject((rowObj) => _.isEmpty(_.omitBy(rowObj, _.isEmpty)))
      .compact()
      .value();

    this.setStatus('Evaluating');

    runInAction(() => {
      this.list.replace(validRows);
    });

    this.setStatus('Parsed all Rows');
    return null;
  }

  @action
  async processUserOnboardingRow(row) {
    const companyOnboarding: ICompanyOnboarding = _.first(
      await dispatch('companyOnboardings.find', {
        query: {
          uuid: _.trim(row.companyOnboardingId),
        },
      }),
    );

    if (_.isEmpty(companyOnboarding)) {
      throw new Error('Company Onboarding not found.');
    }
    const user = _.first(
      await dispatch('workers.find', {
        query: {
          companies: companyOnboarding.company,
          uuid: _.trim(row.userId),
        },
      }),
    );

    if (_.isEmpty(user)) {
      throw new Error(
        'User with this id not found or is not part of this company',
      );
    }
    const existingUserOnboarding = await dispatch('userOnboardings.find', {
      query: {
        companyOnboardingId: _.trim(row.companyOnboardingId),
        userId: _.trim(row.userId),
      },
    });
    if (!_.isEmpty(existingUserOnboarding)) {
      throw new Error('This user was already added to this onboarding');
    }
    const data = {
      company: companyOnboarding.company,
      companyOnboardingId: row.companyOnboardingId,
      positionId: companyOnboarding.positionId,
      userId: row.userId,
    };
    return dispatch('userOnboardings.create', { data });
  }

  @action
  async save() {
    this.setStatus('saving');
    this.setSaving(true);
    this.saveResults = {
      ignored: [],
      invalid: [],
      new: [],
      updated: [],
    };

    try {
      const response = await Promise.map(
        this.list,
        async (row) => {
          try {
            const newUserOnboarding = await this.processUserOnboardingRow(row);
            runInAction(() => {
              this.saveResults.new.push(newUserOnboarding);
            });
            return newUserOnboarding;
          } catch (error) {
            this.log.debug(error);
            runInAction(() => {
              this.saveResults.invalid.push(_.extend(row, { err: error }));
            });
            return row;
          }
        },
        { concurrency: 20 },
      );

      runInAction(() => {
        this.list.replace(response);
        this.setStatus('saved');
      });
    } catch (err) {
      this.log.error('Failed to cancel assignments', err);
      dispatch('ui.snackBar.error', `Sorry, something went wrong, ${err}`);
      this.setStatus('error');
      this.clear();
    }

    this.setSaving(false);
    return null;
  }

  @action
  clear() {
    super.clear();

    this.saveResults = {
      ignored: [],
      invalid: [],
      new: [],
      updated: [],
    };

    this.list.clear();
    this.parsedHeaders.clear();
  }
}
