import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export type IReviews = IBaseItem & Record<string, unknown>;

export default class ReviewsStore extends BaseStore<IReviews> {
  constructor() {
    const baseItem = {
      audioURL: '',
      dataType: '',
      notes: '',
      rating: 0,
      ref: '',
      target: '',
      user: '',
    };

    super({ baseItem, serviceName: 'reviews' });

    return this;
  }
}
