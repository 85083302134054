import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export type ISavedChatFilters = IBaseItem & Record<string, unknown>;

export default class SavedChatFiltersStore extends BaseStore<ISavedChatFilters> {
  constructor() {
    super({
      baseItem: SavedChatFiltersStore.baseCannedResponse,
      serviceName: 'savedChatFilters',
    });

    return this;
  }

  static BASE_ITEM = {
    company: null,
    createdAt: null,
    filterQueryString: null,
    name: null,
    updatedAt: null,
    uuid: null,
  };
}
