import type { DropdownItemProps } from 'semantic-ui-react';
import type {
  IAssignment,
  Timestamp,
  IShift,
  IUser,
} from '@shiftsmartinc/shiftsmart-types';

import { action, computed, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import moment from 'moment-timezone';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.ShiftDetails');

declare type ShiftReminderModalShift = Pick<
  IShift,
  'uuid' | 'title' | 'status' | 'assignedUsers' | 'slots'
>;

export default class ShiftRemindersModal {
  @observable
  isOpen = false;

  @observable
  isConfirmOpen = false;

  @observable
  isLoading = false;

  @observable
  error = '';

  @observable
  shiftId: IShift['uuid'] = '';

  @observable
  shift: Partial<IShift> = {};

  @observable
  assignment: IAssignment = null;

  @observable
  usersList: Array<IUser> = [];

  @observable
  totalUsers = 0;

  @observable
  aggregateData: Record<string, unknown> = {};

  @observable
  sendToStatus: IAssignment['status'] = 'Assigned';

  @observable
  messageLimit = 320;

  @observable
  reminderMessageText = '';

  @observable
  defaultReminderMessageText = '';

  @observable
  isBulk = false;

  @observable
  notificationTypes: Array<'sms' | 'email' | 'pn'> = [];

  @observable
  bulkStart: Timestamp = null;

  @observable
  bulkEnd: Timestamp = null;

  @observable
  activeShifts: Array<ShiftReminderModalShift> = [];

  @observable
  activeShiftsWithUsers: Array<ShiftReminderModalShift> = [];

  @action
  setReminderMessageText({ message = '' }) {
    if (message.length <= this.messageLimit) {
      this.reminderMessageText = message;
    }
  }

  @computed
  get notificationTypeOptions(): Array<DropdownItemProps> {
    return [
      {
        key: 'sms',
        text: `SMS`,
        value: 'sms',
      },
      {
        key: 'pn',
        text: `Push Notification`,
        value: 'pn',
      },
      {
        key: 'email',
        text: `Email`,
        value: 'email',
      },
      // {
      //   key: 'userPreference',
      //   value: 'userPreference',
      //   text: `User Preference`,
      // },
    ];
  }

  @computed
  get statuses(): Array<DropdownItemProps> {
    const assignedCount = _.get(this.aggregateData, 'assigned', 0);
    const seenCount = _.get(this.aggregateData, 'seen', 0);
    const sentCount = _.get(this.aggregateData, 'sent', 0);
    const notConfirmedCount = _.get(this.aggregateData, 'notConfirmed', 0);
    const needBreakCount = _.get(this.aggregateData, 'noBreakTaken', 0);
    const company = dispatch('auth.getCompany');
    const timekeepingBreaksEnabled = Boolean(
      company.settings?.timekeeping?.breakOptions,
    );
    return _.compact([
      {
        key: 'Assigned',
        text: `Partners who have been assigned the shift (${assignedCount})`,
        value: 'Assigned',
      },
      {
        key: 'Sent',
        text: `Partners who have been sent the shift (${sentCount})`,
        value: 'Sent',
      },
      !this.isBulk && {
        key: 'Seen',
        text: `Partners who have seen the shift (${seenCount})`,
        value: 'seen',
      },
      !this.isBulk && {
        key: 'NotConfirmed',
        text: `Partners who have not yet confirmed the shift (${notConfirmedCount})`,
        value: 'NotConfirmed',
      },
      !this.isBulk &&
        timekeepingBreaksEnabled && {
          key: 'needBreak',
          text: `Partners who have not taken breaks (${needBreakCount})`,
          value: 'needBreak',
        },
    ]);
  }

  @action
  getMessageText = ({ status, company, shift, assignment }): string => {
    if (this.isBulk) {
      return '';
    }
    const inProgress =
      shift.start &&
      shift.end &&
      moment().isSameOrAfter(moment(shift.start)) &&
      moment().isSameOrBefore(moment(shift.end));
    const enablePartnerCancel = _.get(
      company,
      'settings.things.enablePartnerCancel',
      false,
    );
    const browserTz = moment().tz(moment.tz.guess()).format('z');
    const firstName = assignment ? _.get(assignment, 'user.firstName', '') : '';
    let messageText = `Heads up - your shift at ${
      company.name
    } starts at ${moment(shift.start).format(
      'LLLL',
    )} ${browserTz}. Check the Shiftsmart app for more details. Text back CONFIRM to confirm your shift ${
      enablePartnerCancel
        ? 'or CANCEL if you can no longer make the shift.'
        : 'or message your manager if something has changed.'
    }`;

    if (inProgress) {
      messageText = `Heads up - your shift at ${
        company.name
      } has already started at ${moment(shift.start).format(
        'LLLL',
      )} ${browserTz}. Check the Shiftsmart app for more details.`;
    }

    if (/^(sent|seen)$/i.test(status)) {
      messageText = `Hi ${firstName} - there are still available spots for the following ${
        shift.isRemote ? 'remote ' : ''
      }shift at ${
        company.name
      }. Text back YES to secure your spot or NO to stop receiving reminders for this shift.`;
    }

    if (/^needbreak$/i.test(status)) {
      messageText = `Are you ready to take a break, ${firstName}? If so, please start your break using the Shiftsmart app.`;
    }
    return messageText;
  };

  @action
  async setup({
    defaultStatus = 'Assigned',
    open = !this.isOpen,
    shiftId = '',
    assignment = null,
    isBulk = false,
    bulkStart = null,
    bulkEnd = null,
    activeShifts = [],
  }: {
    activeShifts?: Array<ShiftReminderModalShift>;
    assignment?: IAssignment;
    bulkEnd?: Timestamp;
    bulkStart?: Timestamp;
    defaultStatus?: IAssignment['status'];
    isBulk?: boolean;
    open?: boolean;
    shiftId?: IShift['uuid'];
  }) {
    this.set('isLoading', true);
    this.set('sendToStatus', defaultStatus);
    this.open(open);

    if (isBulk) {
      this.set('isBulk', true);
      this.set('activeShifts', activeShifts);
      this.set('bulkStart', bulkStart);
      this.set('bulkEnd', bulkEnd);
      this.set('notificationTypes', ['pn']);
      await this.loadAssignments();
      _.forEach(
        _.map(this.statuses, (s) => s.value as IShift['status']),
        (key) => {
          this.loadAssignments(key, true);
        },
      );
    } else {
      if (shiftId) {
        this.set('shiftId', shiftId);
      }
      if (assignment) {
        this.set('assignment', assignment);
      } else {
        try {
          let selectedShift = dispatch('shifts.retrieve', 'selected');
          if (selectedShift.uuid !== shiftId) {
            // Only load the shift again if not already the selected one
            selectedShift = await dispatch('shifts.get', shiftId, {
              select: false,
            });
          }
          this.set('shift', selectedShift);
          await this.loadAssignments();
          const aggregateData = dispatch(
            'ui.shiftDetails.retrieve',
            'aggregateData',
          );
          this.set('aggregateData', aggregateData);
        } catch (err) {
          log.error('Error setting up shiftRemindersModal', err);
        }
      }
    }

    const messageText = this.getMessageText({
      assignment,
      company: dispatch('auth.getCompany'),
      shift: _.has(assignment, 'uuid') ? assignment.data : this.shift,
      status: _.has(assignment, 'uuid')
        ? _.get(assignment, 'status')
        : defaultStatus,
    });
    this.set('defaultReminderMessageText', messageText);
    this.set('isLoading', false);
  }

  @action
  async loadAssignments(
    sendToStatus: string = this.sendToStatus,
    dryRun = false,
  ) {
    /* dry run is used to set the aggregateData for Bulk reminders since
     * we can't call the ui.shiftDetails store with multiple shifts. */
    this.set('isLoading', true);
    const listOfShiftsIds = this.isBulk
      ? _.map(this.activeShifts, (s) => s.uuid)
      : [this.shiftId];
    try {
      const query: Record<string, unknown> = {
        $client: {
          populateUser: ['firstName'],
        },
        $select: [
          'uuid',
          'user',
          'status',
          'title',
          'start',
          'end',
          'rate',
          'formattedAddress',
          'seenAt',
          'ref',
        ],
        ref: { $in: listOfShiftsIds },
        status: { $in: ['Assigned', 'Accepted', 'Approved'] }, // ATTN: Assignment Status EP-5523
      };
      if (/^(seen|sent)$/i.test(sendToStatus)) {
        query.status = 'Sent';
        if (/^seen$/i.test(sendToStatus)) {
          query.seenAt = { $exists: true };
        }
      }
      if (/^notconfirmed$/i.test(sendToStatus)) {
        query.partnerConfirmedAt = { $exists: false };
      }
      if (/^needbreak$/i.test(sendToStatus)) {
        query.checkIn = { $exists: true };
        query.timecard = { $size: 0 };
      }
      const response = await dispatch('assignments.runQuery', query);
      const usersList = _.uniqBy(
        _.map(response.data, (a) => a.user),
        'uuid',
      );
      if (!dryRun) {
        const shiftIdsFromAssignments = _.uniq(
          _.map(response.data, (a) => a.ref),
        );
        const activeShiftsWithUsers = _.filter(this.activeShifts, (a) =>
          _.includes(shiftIdsFromAssignments, a.uuid),
        );
        this.set('usersList', usersList);
        this.set('totalUsers', this.isBulk ? usersList.length : response.total);
        this.set('activeShiftsWithUsers', activeShiftsWithUsers);
      } else {
        const aggregateData = _.cloneDeep(this.aggregateData);
        aggregateData[sendToStatus.toLowerCase()] = usersList.length;
        this.set('aggregateData', aggregateData);
      }
    } catch (error) {
      log.error('Error loading assignments for reminders', error, {
        extra: {
          shiftId: this.shiftId,
        },
      });
    }

    // TODO: This will set `isLoading` to false prematurely
    this.set('isLoading', false);
  }

  @action
  updateStatusToggle(value) {
    this.set('sendToStatus', value);
    this.loadAssignments();
    const messageText = this.getMessageText({
      assignment: null,
      company: dispatch('auth.getCompany'),
      shift: this.shift,
      status: value,
    });

    this.set('defaultReminderMessageText', messageText);
  }

  @action
  setNotificationTypes(notificationTypes) {
    this.notificationTypes = notificationTypes;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    _.set(this, key, value);
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  async submit({ messageText }) {
    const company = dispatch('auth.getCompany');
    this.isLoading = true;
    try {
      if (this.isBulk) {
        await dispatch('shifts.runQuery', {
          $client: {
            sendManualBulkReminders: {
              companyId: company.uuid,
              end: this.bulkEnd,
              notificationTypes: this.notificationTypes,
              shiftIdList: _.map(this.activeShifts, (s) => s.uuid),
              start: this.bulkStart,
              status: this.sendToStatus,
            },
          },
        });
      } else {
        const authUser = dispatch('auth.getUser');
        const data = {
          $push: {
            messages: {
              message: messageText,
              method: 'twilio-notify',
              notificationType: 'reminderRequest',
              sentAt: moment().toDate(),
              user: authUser.uuid,
            },
          },
        };
        if (this.assignment) {
          await dispatch('assignments.update', {
            data,
            id: this.assignment.uuid,
            query: { $client: { sendReminders: true } },
          });
        } else {
          await dispatch('shifts.update', {
            id: this.shiftId,
            query: {
              $client: { sendShiftReminders: true },
              assignmentStatus: this.sendToStatus,
              messageText,
            },
          });
        }
      }
      this.confirm();
    } catch (err) {
      log.error('Failed to submit reminders', err);
      this.set(
        'error',
        'There was an issue sending reminders, please try again',
      );
    }

    this.set('isLoading', false);
  }

  @action
  confirm() {
    this.isOpen = false;
    this.isConfirmOpen = true;
  }

  @action
  clear(args: { open?: boolean } = {}) {
    this.isOpen = !!args.open;
    this.isConfirmOpen = false;
    this.isLoading = false;
    this.error = '';
    this.shiftId = '';
    this.shift = {};
    this.assignment = null;
    this.sendToStatus = 'Assigned';
    this.usersList = [];
    this.totalUsers = 0;
    this.aggregateData = {};
    this.reminderMessageText = '';
    this.defaultReminderMessageText = '';
    this.isBulk = false;
    this.activeShifts = [];
    this.bulkStart = null;
    this.bulkEnd = null;
    this.notificationTypes = [];
  }
}
