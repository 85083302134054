import type { ICompany } from '@shiftsmartinc/shiftsmart-types';

import _, { identity } from 'lodash';
import { Icon, Loader, Menu, StrictMenuProps } from 'semantic-ui-react';
import React from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';
import styled from 'styled-components';

import { StatusIndicator } from '#/ssm-ui/StatusIndicator';
import { CompanyImage } from '#/shared/components/ssm/CompanyImage';
import { FilterRow } from '#/ssm-ui/FilterRow';
import SiteLogo from '#/shared/components/ssm/SiteLogo';
import { useCacheItem } from '#/shared/hooks/useCacheItem';
import { useStores } from '#/shared/hooks/useStores';

import LoggedInUserMenu from './LoggedInUserMenu';

const HeaderDiv = styled.div.attrs(() => ({
  className: 'h-100 flex justify-between items-center',
}))`
  user-select: none;
`;

const SSMPageHeader: React.FC<{
  actions?: Array<unknown>;
  companies: Array<ICompany>;
  isAuthd: boolean;
  menuSize: StrictMenuProps['size'];
}> = observer(({ actions = [], menuSize, isAuthd, companies }) => {
  const { auth, ui } = useStores();
  const { user, company } = auth;

  const collapsed = false;
  return (
    <HeaderDiv>
      <div className="flex items-center">
        <SiteLogo className="pr4" collapsed={collapsed} />
        <SiteTitle collapsed={collapsed} />
      </div>
      <div className="h-100 flex">
        <Menu
          borderless={true}
          className="h-100 pr3 flex items-center"
          id="site-header-actions"
          secondary={true}
          size={menuSize}
        >
          {actions}
        </Menu>
        {isAuthd && user.uuid && (
          <div
            className={cx(
              'h-100 flex items-center',
              ui.breakpoints.xxs ? 'ph2' : 'ph4',
            )}
            id="site-loggedin-status"
          >
            <LoggedInUserMenu
              companies={companies}
              company={company}
              user={user}
            />
          </div>
        )}
      </div>
    </HeaderDiv>
  );
});

SSMPageHeader.displayName = 'SSMPageHeader';
export default SSMPageHeader;

/** In the case where logged in as a child company, render the parent company's info in the banner */
const SiteTitle = observer(() => {
  const { auth } = useStores();

  const { item: company, isLoading } = useCacheItem<ICompany>({
    itemId: _.first(auth.parentCompanyIds) ?? auth.companyId,
    storeName: 'companies',
  });

  if (_.isEmpty(auth.parentCompanyIds)) {
    return null;
  }

  const managedLoc =
    _.size(auth.managedLocations) === 1 && _.first(auth.managedLocations);

  if (isLoading) return null;

  return (
    <FilterRow align="left" className="fw8 f3">
      <div className="bl h2 w1 b--black-20"></div>
      <CompanyImage company={company} height="24" width="24" />
      <div>{company.name}</div>
      {!!managedLoc && company.uuid !== auth.companyId && (
        <div className="black-80">
          {_.capitalize(managedLoc?.__t)} #
          {(managedLoc?.storeNumber &&
            [
              managedLoc.brand,
              !!managedLoc.storeNumber && `#${managedLoc.storeNumber}`,
            ]
              .filter(identity)
              .join(' ')) ||
            managedLoc?.externalId ||
            managedLoc?.name}
        </div>
      )}
    </FilterRow>
  );
});

interface ISelectedItem {
  displayName?: string;
  label?: string;
  name?: string;
  status?: string;
  title?: string;
  uuid: string;
}

function getItemLabel(item: IBaseItem | item): string {
  return (
    (_.isString(item) && item) ||
    item?.title ||
    item?.name ||
    item?.label ||
    item?.displayName
  );
}

const SelectedHeaderItems: React.FC<{
  includeStatus?: boolean;
  isLoading?: boolean;
  /** Executed after routing.pop */
  onPop?: (popCount: number) => void;
  pageTitle?: string;
  selected?:
    | null
    | string
    | ISelectedItem
    | Array<string | ISelectedItem | JSX.Element>;
}> = observer(({ pageTitle, selected, onPop, includeStatus, isLoading }) => {
  const { routing } = useStores();

  const selectedItems = _(_.isArray(selected) ? selected : [selected])
    .compact()
    .filter(
      (s) => _.isFunction(s) || React.isValidElement(s) || !!getItemLabel(s),
    )
    .value();
  const hasSelection = !_.isEmpty(selectedItems);

  if (!hasSelection && !pageTitle) {
    return null;
  }

  const handlePop = (n) => {
    if (n) {
      routing.pop(n);
      onPop?.(n);
    }
  };

  return (
    <span className="nowrap flex items-center">
      {!!pageTitle && (
        <div
          className="pointer"
          onClick={() => handlePop(_.size(selectedItems))}
          role="button"
          tabIndex={-1}
        >
          {pageTitle}
        </div>
      )}
      {!!hasSelection && !!pageTitle && (
        <div>
          <Icon className="pl2" name="angle right" />
        </div>
      )}
      {!!hasSelection &&
        _.map(selectedItems, (item, i) => {
          if (
            (_.isObject(item) && _.isEmpty(item)) ||
            _.isNull(item) ||
            _.isUndefined(item)
          ) {
            return false;
          }
          const isLast = i + 1 === _.size(selectedItems);

          const breadcrumbProps = isLast
            ? {}
            : {
                onClick: () => {
                  handlePop(_.size(selectedItems) - (i + 1));
                },
                role: 'button',
                tabIndex: -42,
              };

          const label = _.startCase(getItemLabel(item));

          const key = _.get(item, 'key') || _.get(item, 'uuid') || label || i; // #NOTE: put the index as key if everything fails

          return (
            <React.Fragment key={key}>
              <div
                className="fw4"
                style={{ color: '#7188b4' }}
                {...breadcrumbProps}
              >
                {label || (React.isValidElement(item) && item) || '-'}
              </div>
              {!isLast && (
                <div>
                  <Icon className="pl2" name="angle right" />
                </div>
              )}
              {isLast && includeStatus && item.status && (
                <span className="pl2">
                  <StatusIndicator
                    status={item.status}
                    storeName={pluralize(item?._modelName)}
                  />
                </span>
              )}
            </React.Fragment>
          );
        })}
      {isLoading && (
        <span className="pl2">
          <Loader active={true} inline={true} size="small" />
        </span>
      )}
    </span>
  );
});

SelectedHeaderItems.displayName = 'SelectedHeaderItems';

const ReactiveHeader: React.FC<{ actions?: any; selected?: unknown }> =
  observer(({ selected, actions }) => {
    if (global.IS_SSR) {
      return <></>;
    }
    const siteHeaderActions = document.getElementById('site-header-actions');
    const siteHeaderSelected = document.getElementById('site-header-selected');

    return (
      <>
        {siteHeaderSelected &&
          createPortal(
            <SelectedHeaderItems selected={selected} />,
            siteHeaderSelected,
          )}

        {siteHeaderActions && createPortal(actions, siteHeaderActions)}
      </>
    );
  });

ReactiveHeader.displayName = 'ReactiveHeader';
export { SelectedHeaderItems, ReactiveHeader, SSMPageHeader as SiteHeader };
