import type { IPaymentTransaction } from '@shiftsmartinc/shiftsmart-types';

import { action, observable, computed, runInAction } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';

import { getChildLogger } from '#/shared/utils/client.logger';
import { getStores } from '#/shared/getStores';

export default class BulkSubmitTransfersModal {
  log = getChildLogger('ui.BulkSubmitTransfersModal');

  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  showActionButtons = true;

  allTransfers = observable.array<
    IPaymentTransaction & { transactionType: 'transfer' }
  >([]);

  selectedTransfers = observable.array<
    IPaymentTransaction & { transactionType: 'transfer' }
  >([]);

  @observable
  stepIndex = 1;

  @action.bound
  setStepIndex(stepIndex) {
    this.stepIndex = stepIndex;
  }

  @action
  async setup() {
    const log = this.log.getChildLogger('setup');
    this.isLoading = true;
    this.isOpen = true;

    const { paymentTransactions } = getStores();

    try {
      await paymentTransactions.find(
        {
          query: {
            $client: { populateUser: true },
            $sort: { createdAt: -1 },
            status: 'draft',
            transactionType: { $nin: ['payout', 'deposit', 'fee'] },
          },
        },
        { clear: true },
      );
    } catch (error) {
      log.error('Error Fetching Transaction Data', error);
    }
    const transactions = paymentTransactions.list;

    this.setTransfersList(transactions);
    this.selectAllTransfers(transactions);

    runInAction(() => {
      this.isLoading = false;
    });
  }

  @action
  async bulkSubmitTransfers() {
    const log = this.log.getChildLogger('bulkSubmitTransfers');
    this.set('isLoading', true);
    this.allTransfers.clear();
    let errorsSubmittingTransfers;
    try {
      errorsSubmittingTransfers = await Promise.reduce(
        this.selectedTransfers,
        async (listOfErrors, transaction) => {
          try {
            await dispatch('paymentTransactions.processPayment', {
              transaction,
            });
          } catch (error) {
            listOfErrors.push(
              _.assignIn(transaction, { errorMsg: error.message }),
            );
            log.error(`[bulkSubmitTransfers] Error Submitting Payment`, error, {
              extra: {
                transactionId: transaction.uuid,
                user: transaction.user,
              },
            });
          }
          return listOfErrors;
        },
        [],
      );
    } catch (error) {
      dispatch('ui.snackBar.error', 'Error Submitting Transfers', {
        body: `: ${error.message}`,
      });
      log.error(`[bulkSubmitTransfers] Error Promise array`, error);
    }
    this.set('isLoading', false);
    this.set('showActionButtons', false);
    if (!_.isEmpty(errorsSubmittingTransfers)) {
      dispatch(
        'ui.snackBar.error',
        `Error Submitting Transfer${
          errorsSubmittingTransfers.length > 1 ? 's' : ''
        }`,
      );
      this.setTransfersList(errorsSubmittingTransfers);
    } else {
      dispatch('ui.snackBar.open', 'All Payment Transfers Submitted');
    }
  }

  @action
  isSelected(item) {
    return _.find(this.selectedTransfers, {
      uuid: item.uuid,
    });
  }

  @action
  toggleSelection(item) {
    if (_.isString(item)) {
      throw new Error('Push Item called with possible UUID, Object Expected');
    }
    const query = { uuid: item.uuid };
    if (_.find(this.selectedTransfers, query)) {
      _.remove(this.selectedTransfers, query);
    } else {
      this.selectedTransfers.unshift(item);
    }
  }

  @computed
  get totalSelectedCount() {
    return this.selectedTransfers.length;
  }

  @computed
  get totalTransferAmount() {
    const updatedAmount = this.selectedTransfers.reduce(
      (total, transfer) => total + transfer.amount,
      0,
    );
    return updatedAmount;
  }

  @computed
  get totalListCount() {
    return this.allTransfers.length;
  }

  @action
  clear() {
    this.resetTransactionsList();
    this.isOpen = false;
    this.isLoading = false;
    this.showActionButtons = true;
    this.allTransfers.clear();
    this.selectedTransfers.clear();
    this.stepIndex = 1;
  }

  @action
  async resetTransactionsList() {
    const log = this.log.getChildLogger('resetTransactionsList');
    try {
      await dispatch(
        'paymentTransactions.find',
        { query: { $client: { populateUser: true } } },
        { clear: true },
      );
    } catch (err) {
      log.error('Error Fetching Transaction Data', err);
    }
  }

  @action
  setTransfersList(transfers?: BulkSubmitTransfersModal['allTransfers']) {
    this.allTransfers.replace(transfers ?? []);
  }

  @action
  selectAllTransfers(
    transfers?: BulkSubmitTransfersModal['selectedTransfers'],
  ) {
    if (transfers) {
      this.selectedTransfers.replace(transfers);
    } else {
      this.selectedTransfers.replace(this.allTransfers);
    }
  }

  @action
  deselectAllTransfers() {
    this.selectedTransfers.clear();
  }

  get(name) {
    return this[name];
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(name, val) {
    this[name] = val;
  }
}
