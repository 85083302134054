import { observable, action } from 'mobx';

export default class ChannelMembersModal {
  @observable
  isOpen = false;

  @observable
  channel = {};

  @action
  setup({ channel = {}, open = this.isOpen }) {
    this.channel = channel;
    this.isOpen = open;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    this.isOpen = false;
    this.channel = {};
  }
}
