import React from 'react';
import styled, { css } from 'styled-components';
import { Form as SemanticForm } from 'semantic-ui-react';
import cx from 'classnames';

const InputLabel = ({
  children,
  required,
  className,
  requiredError,
  style,
}) => (
  <label
    className={cx(
      required && 'ssm-form-required',
      requiredError && 'ssm-form-required-error',
      className,
    )}
    style={style}
  >
    {children}
    {!!required && (
      <span data-position="right center" data-tooltip="This field is required">
        <span className="ssm-form-required-indicator" />
      </span>
    )}
  </label>
);

export const FormInputLabel = styled(InputLabel)`
  &.ssm-form-required-error .ssm-form-required-indicator::after {
    color: var(--red) !important;
  }
`;

// export type FormInstanceProps = import('semantic-ui-react').StrictFormProps & {};
export const Form = styled(SemanticForm)`
  &&& {
    ${(props) =>
      /label/.test(props.requiredIndicator) &&
      css`
        ${FormInputLabel}.ssm-form-required .ssm-form-required-indicator::after {
          content: '(Required)';
          margin-left: 10px;
        }
      `}
    ${(props) =>
      /bold/.test(props.requiredIndicator) &&
      css`
        ${FormInputLabel} {
          font-weight: 300;
        }
        ${FormInputLabel}.ssm-form-required {
          font-weight: 600;
        }
      `}

    ${(props) =>
      (props.requiredIndicator === true ||
        /icon/.test(props.requiredIndicator)) &&
      css`
        ${FormInputLabel}.ssm-form-required .ssm-form-required-indicator::after {
          content: '*';
          color: var(--blue-navy);
          margin-left: 5px;
        }
      `}

    div.disabled {
      opacity: 0.9;

      .ui.input,
      &.dropdown,
      .ssm-places-autocomplete {
        color: unset;

        &,
        input {
          border-color: rgba(34, 36, 38, 0.04);
          color: unset;
        }

        &.dropdown > div.divider.text {
          color: unset;
        }
      }
    }

    &&& div.field .ui.dropdown.disabled > i.dropdown.icon {
      display: none;
    }
  }
`;
