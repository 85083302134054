import { observable, action, set, runInAction, computed } from 'mobx';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import _ from 'lodash';
import moment from 'moment';
import flatten from 'flat';

import BaseUploader, {
  KnownFieldConfig,
} from '#/shared/stores/ui/BaseUploader';

export default class CustomUserMetricsUploader extends BaseUploader {
  constructor() {
    super({ title: 'ui.customUserMetricsUploader' });

    set(this.saveResults, {
      dupe: [],
      invalid: [],
      new: [],
      recalculated: [],
      updated: [],
    });

    return this;
  }

  @observable parseOptions = {
    comments: '#',
    dynamicTyping: false,
    header: false,
    skipEmptyLines: true,
  };

  @computed
  get progress() {
    const total = _.size(this.list);

    const saved = _(this.saveResults).values().map(_.size).sum();

    return 100 * (saved / total);
  }

  /**
   * Parsed Headers
   * An array of all headers found in the parsed objects
   *
   * Used to render the column headers in a dynamic table.
   *
   * @memberof ShiftUploader
   */
  @observable
  parsedHeaders = [];

  @observable
  resultHeaders = [];

  @observable
  userCompanyPairs = [];

  @observable
  userIDs = [];

  @observable
  keyCounts = {};

  @observable
  uploadConcurrency = 1;

  @observable doRecalculation = false;

  @action
  async setup({ storeName, company, ...rest }, ...args) {
    super.setup({ company, storeName, ...rest }, ...args);

    this.clear();

    this.company = company;

    if (_.isString(this.company)) {
      const co = await dispatch('companies.get', this.company, {
        select: false,
      });
      runInAction(() => {
        this.company = co;
      });
    }

    runInAction(() => {
      this.customMetrics.replace(this.company.customMetrics ?? []);
    });
  }

  @action setConcurrency(value) {
    this.uploadConcurrency = value;
  }

  @action toggleRecalc(value = !this.doRecalculation) {
    this.doRecalculation = value;
  }

  @action
  clear() {
    super.clear();

    this.saveResults = {
      dupe: [],
      invalid: [],
      new: [],
      recalculated: [],
      updated: [],
    };

    this.list.clear();
    this.userCompanyPairs.clear();
    this.userIDs.clear();
    this.keyCounts = {};

    this.recalculated = 0;
  }

  @action
  async processFile({ options = this.parseOptions, ...rest }) {
    this.setStatus('loading');

    try {
      await super.processFile({ options, ...rest });

      this.setStatus('loaded');
    } catch (err) {
      this.log.error('Failed to process file', err);

      this.setStatus('error');

      throw err;
    }
  }

  @observable
  customMetrics = [];

  @computed
  get knownFields(): KnownFieldConfig[] {
    return _([
      { exampleVal: '', isExampleField: true, key: 'userId', required: true },
      {
        exampleVal: moment()
          .set({ hours: 9, minutes: 0 })
          .add({ days: 1 })
          .format('L LT'),
        key: 'date',
        note: `The 'date' column accept a wide variety \nof date/time formats. "${moment()
          .set({ hours: 9, minutes: 0 })
          .add({ days: 1 })
          .format('L LT')}" is recommended`,
        required: true,
        transform: (val) => moment(val, this.allFormats).toDate(),
      },
      ..._.map(
        this.customMetrics,
        ({ key, label, description, dataType, isDeleted = false }) => {
          const retval: KnownFieldConfig = {
            exampleVal: '',
            isExampleField: isDeleted !== true,
            key,
            mapping: `customMetrics.${key}`,
            note: _.compact([
              description ||
                `The ${label} field (${key}) should be stored as a ${dataType}`,
              /percent/i.test(dataType) &&
                '. Percent Values should be saved in the interval of [0-1], not [0-100]',
            ]).join(' '),
          };

          if (/number/i.test(dataType)) {
            retval.transform = _.toNumber;
          }
          if (/percent/i.test(dataType)) {
            retval.transform = (val) => _.toFinite(val);
          }

          return retval;
        },
      ),
    ])
      .compact()
      .map((field) => _.defaults(field, { isExampleField: true }))
      .value();
  }

  @computed get knownHeaders() {
    return _.map(this.knownFields, 'key');
  }

  @computed
  get sampleCSVData() {
    const sampleData = super.$sampleCSVData;

    return sampleData;
  }

  @action
  async parse({ rows, company }) {
    const casedHeaders = _.map(rows[0], _.toLower);
    const indices = _.reduce(
      this.knownHeaders,
      (acc, header) => {
        acc[header] = _.indexOf(casedHeaders, _.toLower(header));
        return acc;
      },
      {},
    );

    this.log.debug('Loaded Table Indices', { indices });

    const userIDs = new Set([]);
    const keyCounts = {};
    const parsedHeaders = [];
    const userCompanyPairs = new Set([]);

    const validRows = _(rows)
      .map((row, i) => {
        if (!i) {
          // do we need to skip the first row?
          // apparently so, even though `header` is set to false (should skip)
          return null;
        }

        // TODO: Add this logic to other parsers ... maybe in a pre-parse step?
        if (_(row).compact().isEmpty()) {
          return null;
        }

        this.log.debug(row);

        const userId = _.get(row, indices.userId);

        userCompanyPairs.add(
          JSON.stringify({
            company: company?.uuid ?? company,
            user: userId,
          }),
        );
        userIDs.add(userId);

        keyCounts[userId] = (keyCounts[userId] || 0) + 1;

        // What does this do again?
        const retval = _.chain(this.knownHeaders)
          .reduce(
            (acc, colKey) => {
              const colIndex = indices[colKey];

              if (colIndex >= 0) {
                acc[colKey] = row[colIndex];
              }

              return acc;
            },
            { company: company?.uuid ?? company },
          )
          .mapKeys((val, key) => key.replace(/id$/i, ''))
          .value();

        return retval;
      })

      .reject((rowObj) => _.isEmpty(_.omitBy(rowObj, _.isEmpty)))
      .map((data) => this.doMapping({ data, mapping: this.knownFields }))
      .map((row) => {
        parsedHeaders.push(..._.keys(row));
        return row;
      })
      .compact()
      .value();

    this.parsedHeaders = _.uniq(parsedHeaders);

    this.setStatus('Evaluating');

    // eslint-disable-next-line no-console
    console.table(validRows);

    runInAction(() => {
      this.userCompanyPairs.replace(
        _.map(Array.from(userCompanyPairs), JSON.parse),
      );
      this.list.replace(validRows);
      this.userIDs.replace(Array.from(userIDs));
      set(this.keyCounts, keyCounts);
    });

    this.setStatus('Parsed all Rows');
  }

  @action
  async save() {
    this.setStatus('saving');
    this.setSaving(true);

    dispatch('audit.create', {
      data: {
        action: 'Upload Custom Partner Metrics',
        extra: {
          file: _.pick(this.file, ['name', 'type', 'size']),
        },
      },
    });

    try {
      const newEntries = await Promise.map(
        this.list,
        async (entry) => {
          const key = {
            company: entry.company || this.company.uuid,
            date: entry.date,
            user: entry.userId,
          };
          const data = {
            ...flatten(entry),
          };

          if (_.isEmpty(key.company || key.user || key.date)) {
            runInAction(() => {
              this.saveResults.invalid.push(
                _.extend(data, { err: 'Missing Keys', key }),
              );
            });
            return null;
          }

          try {
            const res = await dispatch('userStatsHistory.create', { data });

            if (res) {
              this.log.debug(
                '%s User/Company/Date USH Record',
                moment(res.createdAt).isSame(res.updatedAt)
                  ? 'Created New'
                  : 'Updated Existing',
                { data: entry, res },
              );
            }

            if (moment(res.createdAt).isSame(res.updatedAt)) {
              runInAction(() => {
                this.saveResults.new.push(res);
              });
            } else {
              runInAction(() => {
                this.saveResults.updated.push(res);
              });
            }

            return res;
          } catch (err) {
            runInAction(() => {
              this.saveResults.invalid.push(_.extend(data, { err, key }));
            });
            return null;
          }
        },
        { concurrency: _.toFinite(this.uploadConcurrency) || 1 },
      );

      this.log.debug('Upserted %d entries', _.size(newEntries));
      // eslint-disable-next-line no-console
      console.table(newEntries);

      if (this.doRecalculation) {
        await this.runRecalculation(false);
      }

      this.setStatus('saved');
    } catch (err) {
      this.log.error('Failed to save user stats', err);

      dispatch('ui.snackBar.error', 'Sorry, something went wrong');
      this.setStatus('error');
    }

    this.setSaving(false);
  }

  @action
  async runRecalculation(isStandalone) {
    this.setStatus('recalculating');

    if (isStandalone) {
      this.setSaving(true);
    }

    // // TODO: Do I need this?
    const res = await Promise.map(
      this.userCompanyPairs,
      async (pair) => {
        const invalidPair = _.find(this.saveResults.invalid, {
          company: pair.company,
          user: pair.user,
        });

        if (invalidPair) {
          this.log.debug('Ignoring "invalid" user company pair', { pair });
          return null;
        }

        this.log.debug('Recalculating Stats for User/Company Pair', { pair });

        try {
          const recalcResult = await dispatch('userStats.recalculate', pair);

          runInAction(() => {
            this.recalculated += 1;
            this.saveResults.recalculated.push(recalcResult);
          });
          return recalcResult;
        } catch (err) {
          this.log.error('Stats recalculation failed', err, { pair });
          return null;
        }
      },
      { concurrency: _.toFinite(this.uploadConcurrency) || 1 },
    );

    // eslint-disable-next-line no-console
    console.table(res);

    if (isStandalone) {
      this.setStatus('recalculated');
      this.setSaving(false);
    }
  }

  @observable recalculated = 0;

  @computed get recalcCount() {
    return _.size(this.saveResults.recalculated);
  }

  @computed
  get recalcProgress() {
    return _.isEmpty(this.userCompanyPairs)
      ? 0
      : this.recalculated / _.size(this.userCompanyPairs);
  }

  @computed get userCompanyPairCount() {
    return _.size(this.userCompanyPairs);
  }
}
