import { observable, action, set, runInAction, computed } from 'mobx';
import { dispatch } from 'rfx-core';
import moment from 'moment';
import Promise from 'bluebird';
import _ from 'lodash';

import BaseUploader, {
  KnownFieldConfig,
} from '#/shared/stores/ui/BaseUploader';

export default class ShiftHoursUpload extends BaseUploader {
  constructor() {
    super({ title: 'ui.ShiftHourUpload' });

    set(this.saveResults, {
      invalid: [],
    });

    return this;
  }

  @observable parseOptions = {
    dynamicTyping: false,
    header: false,
    skipEmptyLines: true,
  };

  /** list
   * Stores an array of rows, Ready for saving.
   */
  @observable
  list = [];

  @observable
  parsedHeaders = [];

  @computed
  get knownFields(): KnownFieldConfig[] {
    return [
      {
        exampleVal: '09e21ed3-8668-423e-be9c-ac06e7a2b414',
        key: 'partnerUUID',
      },

      {
        exampleVal: moment()
          .set({ hours: 9, minutes: 0 })
          .add({ days: 1 })
          .format('L LT'),
        key: 'date',
      },

      { exampleVal: 4, key: 'totalHours' },

      { exampleVal: 'Some payment notes', key: 'paymentNote' },
    ];
  }

  @computed
  get csvData() {
    const fields = [];
    const values = [];

    _.each(this.knownFields, ({ key, exampleVal, isExampleField = true }) => {
      if (isExampleField) {
        fields.push(key);
        values.push(exampleVal || '');
      }
    });

    return [fields, values];
  }

  @observable
  totalPaymentAmount = 0;

  @observable
  totalNumberOfPayments = 0;

  @observable
  totalNumberOfHours = 0;

  @computed get knownHeaders() {
    return _.map(this.knownFields, 'key');
  }

  @action
  async setup(opts, ...rest) {
    super.setup(opts, ...rest);

    this.clear();

    this.company = opts.company;

    if (_.isString(this.company)) {
      const company = await dispatch('companies.get', this.company, {
        select: false,
      });
      runInAction(() => {
        this.company = company;
      });
    }
  }

  @action
  clear() {
    super.clear();

    this.saveResults = {
      invalid: [],
    };

    this.list.clear();
    this.parsedHeaders.clear();
    this.totalPaymentAmount = 0;
    this.totalNumberOfPayments = 0;
    this.totalNumberOfHours = 0;
  }

  @action
  async processFile({ options = this.parseOptions, ...rest }) {
    this.setStatus('loading');

    try {
      await super.processFile({ options, ...rest });

      this.setStatus('loaded');
    } catch (err) {
      this.log.error('Failed to process file', err);

      this.setStatus('error');

      throw err;
    }
  }

  @action
  async parse({ rows }) {
    let parsedHeaders = [];
    const casedHeaders = _.map(rows[0], _.toLower);
    const indices = _.reduce(
      this.knownHeaders,
      (acc, header) => {
        acc[header] = _.indexOf(casedHeaders, _.toLower(header));
        return acc;
      },
      {},
    );

    this.log.debug('Loaded Table Indices', { indices });

    const validRows = _(rows)
      .map((row, i) => {
        if (!i) return null;
        this.log.debug(row);
        return _.reduce(
          this.knownHeaders,
          (acc, colKey) => {
            const colIndex = indices[colKey];

            if (colIndex >= 0) {
              acc[colKey] = row[colIndex];
            }

            return acc;
          },
          {},
        );
      })
      .reject((rowObj) => _.isEmpty(_.omitBy(rowObj, _.isEmpty)))
      .map((row) => {
        parsedHeaders = _.union(parsedHeaders, _.keys(row));
        return row;
      })
      .compact()
      .value();

    this.parsedHeaders = parsedHeaders;

    this.setStatus('Evaluating');
    // eslint-disable-next-line no-console
    console.table(validRows);

    this.calculateAndDisplayRows(validRows);
  }

  @action
  async calculateAndDisplayRows(rows) {
    let totalPaymentAmount = 0;
    let totalNumberOfPayments = 0;
    let totalNumberOfHours = 0;

    try {
      const calculatedRows = await Promise.map(rows, async (row) => {
        const company = this.company;
        const partner = row.partnerUUID;
        const date = row.date.split('/');
        const month = date[0];
        const day = Number(date[1]);
        const year = date[2];
        const startDate = moment(row.date).format();
        const endDate = moment(`${month}/${String(day + 1)}/${year}`).format();
        const totalHours = Number(row.totalHours);
        const paymentNote = row.paymentNote;

        // Confirm the worker is in the selected company
        try {
          const worker = await dispatch('workers.get', partner);
          if (!_.includes(worker.companies, company.uuid)) {
            runInAction(() => {
              this.saveResults.invalid.push(
                `${partner} does not work for ${company.name}`,
              );
            });
          }
        } catch (error) {
          this.log.error(`No worker found for partnerUUID: ${partner}`, error);
          runInAction(() => {
            this.saveResults.invalid.push(
              `No partner with id ${partner} found`,
            );
          });
        }

        // load assignment for that particular date
        const query = {
          company: company.uuid,
          end: { $lt: endDate },
          start: { $gte: startDate },
          user: partner,
        };
        const assignments = await dispatch('assignments.runQuery', query);

        if (!assignments.data) {
          runInAction(() => {
            this.saveResults.invalid.push(
              `No shifts found for partner ${partner} on ${row.date}`,
            );
          });
        }
        const baseRate = assignments.data[0].payment.baseRate;

        const amount =
          Math.round((totalHours * baseRate + Number.EPSILON) * 100) / 100;
        totalPaymentAmount += amount;
        totalNumberOfPayments += 1;
        totalNumberOfHours += totalHours;

        // Confirm the worker’s total hours is less than max per day
        const maxHoursPerDay = _.get(
          company,
          'settings.payments.maxHoursPerDay',
          14,
        );
        if (maxHoursPerDay && maxHoursPerDay < totalHours) {
          runInAction(() => {
            this.saveResults.invalid.push(
              `Total hours is more than max per day for worker: ${partner}`,
            );
          });
        }

        _.forEach(assignments.data, (assignment) => {
          // check to make sure all the assignments have the same baseRate
          if (assignment.payment.baseRate !== baseRate) {
            runInAction(() => {
              this.saveResults.invalid.push(
                `Partner ${partner} has a different base rate than the default base rate of ${baseRate}`,
              );
            });
          }

          // Confirm the worker has not already been paid out for a shift on that company & day yet
          if (assignment.paymentInfo.transactionId) {
            runInAction(() => {
              this.saveResults.invalid.push(
                `Partner ${partner} has already been paid out for shift on ${row.date}`,
              );
            });
          }
        });

        const updatedRow = {
          amount,
          assignmentIds: _.map(assignments.data, 'uuid'),
          company: company.uuid,
          date: row.date,
          partnerUUID: partner,
          paymentNote,
          totalHours,
        };
        return updatedRow;
      });

      if (_.isEmpty(this.saveResults.invalid)) {
        runInAction(() => {
          this.set('totalNumberOfPayments', totalNumberOfPayments);
          this.set('totalPaymentAmount', totalPaymentAmount);
          this.set('totalNumberOfHours', totalNumberOfHours);
          this.list.replace(calculatedRows);
          this.setStatus('Parsed all Rows');
        });
      } else {
        runInAction(() => {
          this.setStatus('Error');
        });
      }
    } catch (error) {
      this.log.error(`No shifts found for given users on data`, error);
      runInAction(() => {
        this.saveResults.invalid.push(
          `No shifts found for given users and date: ${error}`,
        );
      });
    }
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    this[key] = value;
  }

  @action
  async save() {
    this.setSaving(true);
    const company = dispatch('auth.getCompany');

    const csvString = JSON.stringify(this.list);
    const data = {
      company: company.uuid,
      csvName: this.file?.name,
      csvString,
      uploadType: 'hoursUpload',
    };
    try {
      await dispatch('csvUploads.create', { data });
    } catch (err) {
      this.log.error(`Failed to save CSV`, err);
      runInAction(() => {
        this.saveResults.invalid.push(_.extend(data, { err }));
      });
    }

    this.setStatus('saved');
  }
}
