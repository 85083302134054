import { action, observable } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { init as initForm } from '#/shared/forms/worker';

export default class WorkerEditModal {
  @observable
  isOpen = false;

  @observable
  storeName = '';

  @observable
  form = {};

  @action
  setup({
    company = {},
    worker = {},
    addressOptional = true,
    phoneNumberOptional = false,
    storeName,
    open = this.isOpen,
  }) {
    this.storeName = storeName;
    const enableSMSInvite = _.get(company.settings, 'partners.enableSMSInvite');
    const values = _.extend(
      { companies: [_.get(company, 'uuid', company)], storeName },
      worker,
      { sendInvite: enableSMSInvite },
    );
    if (!_.isEmpty(company)) {
      dispatch('positions.findByCompany', {
        company,
        query: { $sort: { count: -1 } },
      });
    }
    this.form = initForm(values, { addressOptional, phoneNumberOptional });
    this.isOpen = open;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
    this.storeName = '';
  }
}
