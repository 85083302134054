import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { dispatch } from 'rfx-core';
import { Button, Grid, Input, Label } from 'semantic-ui-react';
import ReactPivot from 'react-pivot';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('CampaignQuotas');
const defaultPersona = { title: 'Default Persona', uuid: 'default' };

function getRollup(key, quotas) {
  const k2 = JSON.parse(key);

  const quotaLevel = _(quotas).map('level').max();

  if (k2.level < quotaLevel) {
    const k3 = _(k2)
      .omit('level')
      .mapKeys((v, k) => _.camelCase(k))
      .value();
    const childQuotas = _(quotas).filter(k3);

    const rollup = _.extend(
      {
        assigned: 0,
        complete: 0,
        count: 0,
        remaining: 0,
        unassigned: 0,
      },
      {
        assigned: _(childQuotas).sumBy('assignedCount'),
        complete: _(childQuotas).sumBy('completedCount'),
        count: _(childQuotas).sumBy('count'),
      },
    );

    rollup.remaining = rollup.count - rollup.complete;
    rollup.unassigned = rollup.count - rollup.complete - rollup.assigned;

    return rollup;
  }

  return {};
}

/* eslint-disable no-param-reassign */
function pushToStores(m, storeUUID) {
  if (!m.storeIDs) {
    m.storeIDs = [];
  }

  if (!_.includes(m.storeIDs, storeUUID)) {
    m.storeIDs.push(storeUUID);
  }
  return m;
}
/* eslint-enable no-param-reassign */

@observer
export default class CampaignQuotas extends Component {
  static propTypes = {
    campaign: PropTypes.object,
    editing: PropTypes.bool,
    hideActions: PropTypes.bool,
    storeLocations: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.loadQuotaRows = this.loadQuotaRows.bind(this);

    const { storeLocations = [], campaign, editing } = props;

    const isEditing = !(
      !editing &&
      _.isArrayLike(campaign.surveyTasks) &&
      !_.isEmpty(campaign.surveyTasks)
    );

    this.loadQuotaRows({ campaign, isEditing, storeLocations });

    if (!editing) {
      this.activeDimensions =
        _(campaign.quotas)
          .map(({ key }) => _.omit(JSON.parse(key), ['level']))
          .map(_.keys)
          .first() || [];

      if (!_.isEmpty(this.activeDimensions)) {
        _.reverse(this.activeDimensions);
      }

      // Limit Available dimensions to those selected in the setup.
      // this.dimensions = _.filter(
      //   this.dimensions,
      //   dim => this.activeDimensions.indexOf(dim.title) !== -1 || dim.title === 'Store',
      // );
    } else {
      this.dimensions = _.filter(this.dimensions, (dim) => !dim.viewOnly);
      const campaignType = _.get(campaign, 'campaignType');
      if (/^remote$/i.test(campaignType)) {
        this.dimensions = _.filter(this.dimensions, (dim) => dim.remote);
      }
    }

    this.viewStoreListWithFilter = this.viewStoreListWithFilter.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.isEmpty(nextProps)) {
      return;
    }
    const { campaign, editing, storeLocations } = nextProps;

    const isEditing = !(
      !editing &&
      _.isArrayLike(campaign.surveyTasks) &&
      !_.isEmpty(campaign.surveyTasks)
    );

    if (
      this.props.storeLocations.length !== storeLocations.length ||
      this.props.campaign.surveys.length !== campaign.surveys.length ||
      (!isEditing &&
        this.props.campaign.surveyTasks.length !== campaign.surveyTasks.length)
    ) {
      this.loadQuotaRows({ campaign, isEditing, storeLocations });
    }
  }

  @action
  loadQuotaRows = ({
    isEditing,
    storeLocations,
    campaign = {},
    surveys = campaign.surveys,
  }) => {
    if (isEditing) {
      if (!_.isEmpty(storeLocations)) {
        this.rows = _(storeLocations)
          .map((store) =>
            // const splitCount = _.reduce(
            //   surveys,
            //   (count, surveyPair) => count + (_.get(surveyPair, 'personas', []).length || 1),
            //   0,
            // );

            _(surveys)
              .map((surveyPair) => {
                let personas = _.get(surveyPair, 'personas');
                if (_.isEmpty(personas)) {
                  personas = [defaultPersona];
                }
                return personas.map((persona) =>
                  _.extend(
                    _.cloneDeep(store),
                    { personaTitle: persona.title, personaUUID: persona.uuid },
                    // { storeShare: 1 / splitCount },
                    _.omit(surveyPair, ['personas']),
                  ),
                );
              })
              .flatten()
              .value(),
          )
          .flatten()
          .value();
      } else {
        this.rows = _(surveys)
          .map((surveyPair) => {
            let personas = _.get(surveyPair, 'personas');
            if (_.isEmpty(personas)) {
              personas = [defaultPersona];
            }
            return personas.map((persona) =>
              _.extend(
                {},
                { personaTitle: persona.title, personaUUID: persona.uuid },
                // { storeShare: 1 / splitCount },
                _.omit(surveyPair, ['personas']),
              ),
            );
          })
          .flatten()
          .value();
      }
    } else {
      this.rows = _(campaign.surveyTasks)
        .map((survey) => {
          const store = _.isString(survey.store)
            ? _.find(storeLocations, { uuid: survey.store })
            : survey.store;
          const surveyDef =
            _.find(surveys, { surveyUUID: survey.surveyDef }) || {};
          const persona =
            _.find(surveyDef.personas, { uuid: survey.personaUUID }) ||
            defaultPersona;

          return _.extend(survey, {
            personaTitle: persona.title,
            region: store.region,
            retailer: store.retailer,
            storeId: store.storeId,
            storeUUID: store.uuid,
            surveyTitle: surveyDef.surveyTitle,
          });
        })
        .value();
    }

    log.info(
      `Returning ${this.rows.length} rows for ${surveys.length} surveys`,
      { surveys },
    );
    return this.rows;
  };

  saveTheseSnippets() {
    const objectQuotaKeys = _.toArray(this.quotas.keys()).map(JSON.parse);
    const quotaDimensions = this.pivot.state.dimensions;

    const numLevels = quotaDimensions.length;

    const validQuotaKeys = objectQuotaKeys.filter((k) => k.level < numLevels);

    log.info(
      `Qutas have ${numLevels} of description w/ dimensions: [${quotaDimensions.join(
        ',',
      )}]`,
      { validQuotaKeys },
    );

    const quotaAggregations = _(validQuotaKeys)
      .map((k) =>
        _.extend(
          { key: JSON.stringify(k) },
          k,
          this.quotas.get(JSON.stringify(k)),
        ),
      )
      .value();

    const quotaDefinitions = _(quotaAggregations)
      .sort((a, b) => b.level - a.level)
      .map((quota) => {
        log.debug('Checking Quota Def @ Level ', quota.level);
        if (_.isEmpty(quota.count)) {
          const children = _.filter(quotaAggregations, {
            level: quota.level + 1,
          });

          if (_.isEmpty(children)) {
            return quota;
          }

          log.debug('Children ', children);

          _.set(
            quota,
            'count',
            quota.count ||
              _.sumBy(children, (c) => (c.count ? _.toInteger(c.count) : 0)),
          );

          this.quotas.set(quota.key, quota);
        }

        return quota;
      })
      .sort((a, b) => a.level - b.level)
      .map((quota) => {
        if (_.isEmpty(quota.pay) && !_.isNumber(quota.pay)) {
          const query = {
            level: quota.level - 1,
          };

          // eslint-disable-next-line
          for (let i = 0; i < quota.level; i++) {
            const dim = quotaDimensions[i];
            query[dim] = quota[dim];
          }

          const parent = _.find(quotaAggregations, query);

          log.info('Found Parent', parent, query);

          if (_.isEmpty(parent)) {
            return quota;
          }

          log.debug('Parent ', parent);

          _.set(quota, 'pay', quota.pay || parent.pay);

          this.quotas.set(quota.key, quota);
        }

        return quota;
      })
      .value();

    log.info('Cascaded Quota Definitions: ', quotaDefinitions);

    this.pivot.updateRows();

    const saveableQuotas = _(quotaDefinitions)
      .filter((q) => q.level === numLevels - 1)
      .filter((q) => _.toNumber(q.count || 0) > 0)
      .map((val) => _.mapKeys(val, (value, key) => _.camelCase(key)))
      .map((val) => {
        const survey = _.find(this.props.campaign.surveys, {
          surveyTitle: val.survey,
        });

        if (_.isArrayLike(survey.personas)) {
          const persona = _.find(survey.personas, { title: val.persona });
          if (!_.isEmpty(persona)) {
            _.extend(val, { personaUUID: persona.uuid });
          }
        }

        return _.extend(val, _.omit(survey, 'personas'));
      })
      .map((val) => {
        _.set(val, '_id', undefined);
        _.set(val, 'pay', _.toNumber(val.pay));
        _.set(val, 'count', _.toNumber(val.count));
        _.set(val, 'assignedCount', 0);
        _.set(val, 'completedCount', 0);

        return val;
      })
      .value();

    dispatch('campaigns.update', {
      data: { quotas: saveableQuotas },
      id: this.props.campaign.uuid,
    }).then((res) => {
      log.debug('Updated Campaign with quotas: ', {
        campaign: res,
        quotas: saveableQuotas,
      });
      dispatch('ui.campaignConstructor.set', 'quotasDirty', false);
    });
  }

  @observable
  pivot = null;

  @observable
  quotas = new Map([]);

  @observable
  rows = [];

  @observable
  activeDimensions = ['Survey'];

  @observable
  dimensions = [
    { key: 'surveyTitle', remote: true, title: 'Survey', value: 'surveyTitle' },
    { key: 'retailer', remote: false, title: 'Retailer', value: 'retailer' },
    { key: 'region', remote: false, title: 'Region', value: 'region' },
    {
      key: 'storeId',
      remote: false,
      title: 'Store',
      value: 'storeId',
      viewOnly: true,
    },
    {
      key: 'personaTitle',
      remote: true,
      title: 'Persona',
      value: 'personaTitle',
    },
    // { value: 'market', title: 'Market' },
  ];

  calculations = _.compact([
    {
      template: (val, row) => {
        const key = getKey(row);

        const { campaign, editing } = this.props;

        if (!editing) {
          const setting = _.find(campaign.quotas, { key });
          return _.get(setting, 'count', val);
        }

        let countVal;

        const input = (
          <Input
            onChange={(e, { value }) => {
              log.debug('Change Count => %s', value, { e, row });

              const exVal = this.quotas.get(key) || {};
              const v = exVal.count;

              _.extend(exVal, { count: value });
              countVal = value;

              dispatch('ui.campaignConstructor.set', 'quotasDirty', true);
              log.debug(
                'Set Key from %s => %s ',
                v,
                this.quotas.get(key).count,
                exVal,
              );
            }}
            value={countVal}
          />
        );

        if (!this.quotas.has(key)) {
          this.quotas.set(key, {});
        }

        countVal = this.quotas.get(key).count || '';

        if (this.props.editing) {
          return input;
        }

        return <span>{countVal}</span>;
      },
      title: 'Quota',
      value: 'quota',
    },
    {
      template: (val, row) => {
        const key = getKey(row);

        const { campaign, editing } = this.props;

        if (!editing) {
          const setting = _.find(campaign.quotas, { key });
          if (_.has(setting, 'pay')) {
            const pay = _.get(setting, 'pay') || campaign.payRate;
            return `$${_.toNumber(pay).toFixed(2)}`;
          }

          return '-';
        }

        let payVal;

        const input = (
          <Input
            onChange={(e, { value }) => {
              log.debug('Change Pay => %s', value, { e, row });

              const exVal = this.quotas.get(key) || {};
              const v = exVal.pay;

              _.extend(exVal, { pay: value });
              payVal = value;

              dispatch('ui.campaignConstructor.set', 'quotasDirty', true);
              log.debug(
                'Set Key from %s => %s ',
                v,
                this.quotas.get(key).pay,
                exVal,
              );
            }}
            value={payVal}
          />
        );

        if (!this.quotas.has(key)) {
          this.quotas.set(key, {});
        }

        payVal = this.quotas.get(key).pay || '';

        return input;
      },
      title: 'Pay',
      value: 'pay',
    },

    // {
    //   title: '# Stores',
    //   value: 'storeCount',
    //   template: val => <Label content={val} color="green" basic />,
    // },

    // {
    //   title: 'Share',
    //   value: 'storeShare',
    //   template: val => (_.toNumber(val) >= 1 ? val : `${(_.toNumber(val) * 100).toFixed(0)}%`),
    // },

    !this.props.editing
      ? {
          // template: val => <Label content={val} color="green" basic />,
          template: (val, row) => {
            const key = getKey(row);

            const { campaign } = this.props;

            const quota = _.find(campaign.quotas, { key });

            if (!quota) {
              const rollup = getRollup(key, campaign.quotas);

              const retval = rollup.complete || val;
              return !retval ? '0' : retval;
            }
            const completed = _.has(quota, 'completed')
              ? quota.completed
              : quota.completedCount;

            return <Label basic={true} color="green" content={completed} />;
          },

          title: 'Complete',

          value: 'complete',
        }
      : undefined,

    !this.props.editing
      ? {
          // template: val => <Label content={val} color="green" basic />,
          template: (val, row) => {
            const key = getKey(row);

            const { campaign } = this.props;

            const quota = _.find(campaign.quotas, { key });

            if (!quota) {
              const rollup = getRollup(key, campaign.quotas);

              const retval = rollup.assigned || val;
              return retval === 0 ? '0' : retval;
            }

            const assigned = _.has(quota, 'assigned')
              ? quota.assigned
              : quota.assignedCount;

            return <Label basic={true} color="green" content={assigned} />;
          },

          title: 'Assigned',

          value: 'assigned',
        }
      : undefined,

    !this.props.editing
      ? {
          // template: val => <Label content={val} color="green" basic />,
          template: (val, row) => {
            const key = getKey(row);

            const { campaign } = this.props;

            const quota = _.find(campaign.quotas, { key });

            if (!quota) {
              const rollup = getRollup(key, campaign.quotas);

              const retval = rollup.remaining || val;
              return retval === 0 ? '0' : retval;
            }

            const remCount = _.has(quota, 'remaining')
              ? quota.remaining
              : quota.count - quota.completedCount;

            return <Label basic={true} color="green" content={remCount} />;
          },

          title: 'Incomplete',

          value: 'remaining',
        }
      : undefined,

    !this.props.editing
      ? {
          // template: val => <Label content={val} color="green" basic />,
          template: (val, row) => {
            const key = getKey(row);

            const { campaign } = this.props;

            const quota = _.find(campaign.quotas, { key });

            if (!quota) {
              const rollup = getRollup(key, campaign.quotas);

              const retval = rollup.unassigned || val;
              return retval === 0 ? '0' : retval;
            }

            const toAssignCount = _.has(quota, 'unassigned')
              ? quota.unassigned
              : quota.count - quota.completedCount - quota.assignedCount;
            return <Label basic={true} color="green" content={toAssignCount} />;
          },

          title: 'Unassigned',

          value: 'unassigned',
        }
      : undefined,

    !this.props.editing
      ? {
          template: (val, row) => {
            if (!_.get(val, 'length')) {
              return false;
            }

            return (
              <Button
                basic={true}
                color="blue"
                content="Stores >"
                onClick={() => {
                  log.info('linking to stores: ', val, row.storeIDs);

                  this.viewStoreListWithFilter(row);
                }}
              />
            );
          },
          title: 'View Stores',
          value: 'storeIDs',
        }
      : undefined,

    !this.props.editing
      ? {
          template: (val, row) => (
            <Button
              basic={true}
              icon="edit"
              onClick={() => {
                this.editActiveQuota({ row });
              }}
            />
          ),
          title: 'Edit',
          value: 'quotaEdit',
        }
      : undefined,

    !this.props.editing
      ? {
          template: (val, row) => {
            const key = getKey(row);

            const quota = _.find(this.props.campaign.quotas, { key });

            if (quota) {
              return (
                <Button
                  basic={!quota.quotaClosed}
                  icon={quota.quotaClosed ? 'add' : 'close'}
                  onClick={() => {
                    this.closeActiveQuota({ row });
                  }}
                  positive={quota.quotaClosed}
                />
              );
            }

            return undefined;
          },
          title: 'Close Quota',
          value: 'quotaClose',
        }
      : undefined,
  ]);

  viewStoreListWithFilter = (row) => {
    const translatedRow = _.mapKeys(row, (value, key) => _.camelCase(key));
    translatedRow.campaign = this.props.campaign;

    dispatch('ui.campaignDispatch.setup', translatedRow)
      .then(() => {
        dispatch(
          'routing.push',
          `/retail/campaigns/${this.props.campaign.uuid}/dispatch`,
        );
      })
      .catch((err) => {
        log.error('UGG! ', err);
        dispatch('ui.snackBar.open', err.message);
      });
  };

  editActiveQuota = ({ row }) => {
    const key = getKey(row);

    const quota = _.find(this.props.campaign.quotas, { key });

    dispatch('ui.quotaEdit.setup', {
      campaign: this.props.campaign,
      key,
      open: true,
      quota,
    });
  };

  closeActiveQuota = ({ row }) => {
    const key = getKey(row);

    const quota = _.find(this.props.campaign.quotas, { key });

    dispatch('ui.quotaClose.setup', {
      campaign: this.props.campaign,
      key,
      open: true,
      quota,
    });
  };

  reduce = (row, memo) => {
    // if (this.quotas.get(getKey(row))) {
    //   log.info('Reducing from Quota for row', {
    //     row,
    //     quota: this.quotas.get(getKey(row)),
    //   });
    // }
    // memo.quota = this.quotas.get(getKey(row)) || memo.count;

    memo.storeCount = (memo.storeCount || 0) + 1;
    // memo.storeShare = (memo.storeShare || 0) + row.storeShare;
    // memo.storeShare = row.storeShare;

    const { campaign } = this.props;

    if (!this.props.editing) {
      if (_.isArrayLike(this.props.campaign.surveyTasks)) {
        memo.remaining = memo.quota;
        memo.unassigned = memo.quota;

        const isComplete = /completed/i.test(row.status);
        const isAssigned = /assigned|active/i.test(row.status);

        if (isComplete) {
          memo.complete = (memo.complete || 0) + 1;
          memo.remaining = memo.quota - 1;
          memo.unassigned -= 1;
        } else if (isAssigned) {
          memo.assigned = (memo.assigned || 0) + 1;
          memo.unassigned -= 1;
        } else {
          memo.assigned = 0;
          memo.complete = memo.complete || 0;
          memo.complete = memo.assigned || 0;
        }

        // log.debug('Calculated memo for row: ', { memo, row });

        // memo.complete = (memo.complete || 0) + (isComplete ? 1 : 0);
        // memo.assigned = (memo.assigned || 0) + (isAssigned ? 1 : 0);
        // memo.remaining = (memo.remaining || 1) - (isComplete ? 1 : 0);
        // memo.unassigned = (memo.unassigned || 1) - (isComplete || isAssigned ? 1 : 0);

        pushToStores(memo, row.storeUUID);
      } else {
        const key = getKey(row);

        const quota = !!key && _.find(campaign.quotas, { key });

        if (quota) {
          memo.complete = quota.completedCount || 0;
          memo.assigned = quota.assignedCount || 0;
          memo.remaining = quota.count - quota.completedCount || 0;
          memo.unassigned = memo.remaining - memo.assigned;
        }

        // const status = _.random(0, 2);
        // memo.complete = (memo.complete || 0) + (status === 0 ? 1 : 0);
        // memo.assigned = (memo.assigned || 0) + (status === 1 ? 1 : 0);
        // memo.remaining = (memo.remaining || 0) + (status === 2 ? 1 : 0);
        pushToStores(memo, row.storeUUID);
      }
    }
  };

  /* eslint-enable no-param-reassign */
  render() {
    const { editing, hideActions, campaign } = this.props;

    const fileName = `${campaign.title.replace(/\W/g, '')}_quotas.csv`;

    return (
      <div className="w-100 center tc ph3">
        <Grid>
          <Grid.Row>
            <ReactPivot
              activeDimensions={this.activeDimensions.toJS()}
              calculations={this.calculations}
              csvDownloadFileName={fileName}
              dimensions={this.dimensions}
              excludeSummaryFromExport={true}
              nPaginateRows={500}
              reduce={this.reduce}
              ref={action((el) => {
                this.pivot = el;
              })}
              rows={this.rows}
            />
          </Grid.Row>
          {!hideActions && (
            <Grid.Row>
              {editing && (
                <Button
                  content="Save"
                  disabled={
                    !dispatch('ui.campaignConstructor.get', 'quotasDirty')
                  }
                  floated="right"
                  onClick={() => {
                    if (editing) this.saveTheseSnippets();
                  }}
                  primary={true}
                />
              )}
            </Grid.Row>
          )}
        </Grid>
      </div>
    );
  }
}

// getQuotaKey
function getKey(row) {
  try {
    const key = _.extend(
      _(row)
        .pickBy((v, k) => k.indexOf('_') !== 0 && _.isString(v))
        .omit([
          'count',
          'storeCount',
          'assigned',
          'complete',
          'remaining',
          'storeIDs',
        ])
        .mapKeys((v, k) => _.capitalize(k))
        .value(),
      { level: _.get(row, '_level') },
    );

    const retval = JSON.stringify(key);
    log.silly(`getKey: Returning new quota key: "${retval}"`, { row });
    return retval;
  } catch (err) {
    log.error('Unable to get key for row', err, { extra: { row } });

    return null;
  }
}

export { getKey as getQuotaKey };
