import type { Query } from '@feathersjs/feathers';
import type {
  ICompany,
  CompanyStatusRecord,
  CompanyStatusRole,
  IStatusFilterable,
} from '@shiftsmartinc/shiftsmart-types';

import { observable, action, computed } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import Promise from 'bluebird';

import { service } from '#/shared/app';
import { AgentTypeDDLOption, getAgentTypeOptions } from '#/utils/logic/agents';

import BaseStore from './_baseStore';

export default class CompanyStore
  extends BaseStore<ICompany>
  implements IStatusFilterable<ICompany>
{
  constructor({ serviceName = 'companies', title = 'companies' } = {}) {
    super({
      baseFilter: observable({ status: observable.array(['active']) }),
      baseItem: CompanyStore.BASE_ITEM,
      cacheSize: 50,
      enableLocalResponseEventing: true,
      searchFields: ['name'],
      serviceName,
      title,
    });

    return this;
  }

  static BASE_ITEM: Partial<ICompany> = {
    about: null,
    address: {
      city: null,
      state: null,
      street1: null,
      street2: null,
      zip: null,
    },
    agents: [],
    createdAt: null,
    images: {},
    isDeleted: false,

    isShiftsmartManaged: null,

    limits: [],

    modules: {},

    name: null,

    notificationSettings: {},

    owner: null,

    settings: {},

    // EP-1135
    stores: [],

    updatedAt: null,
    uuid: null,
  };

  filter = observable({ status: observable.array(['active']) });

  @observable
  selectedChildren = [];

  @observable
  selectedParent = {};

  @observable
  relatedCompanies = [];

  @observable
  userCompanies = [];

  /*
  "total": "<total number of records>",
  "limit": "<max number of items per page>",
  "skip": "<number of skipped items (offset)>",
  "current": "<current page number>"
  "pages": "<total number of pages>"
*/
  @observable
  $relatedPagination = {};

  @observable
  $userCompaniesPagination = {};

  @action
  updateUserCompanies(json) {
    if (_.isEmpty(json)) {
      this.userCompanies.clear();
    } else {
      this.userCompanies.replace(json.data);
      this.$userCompaniesPagination = _.omit(json, 'data');
    }

    return this.userCompanies;
  }

  getUserCompanies() {
    return this.userCompanies;
  }

  @action
  setSelectedChildren(data = {}) {
    this.selectedChildren = data;
    return this.selectedChildren;
  }

  @action
  setSelectedParent(data = {}) {
    this.selectedParent = data;
    return this.selectedParent;
  }

  @action
  clearSelected() {
    super.clearSelected();
    dispatch('ui.manageCompany.clear');
    return Promise.resolve(this.selected);
  }

  @action
  setRelatedCompanies(json) {
    if (_.isEmpty(json)) {
      this.relatedCompanies = [];
    } else {
      this.relatedCompanies = json.data;
      this.$relatedPagination = _.omit(json, 'data');
    }

    return this.relatedCompanies;
  }

  getRelatedCompanies() {
    return this.relatedCompanies;
  }

  @computed
  get relatedPagination() {
    const { total = 0, limit = 1, skip = 0 } = this.$relatedPagination;
    return _.extend(this.$relatedPagination, {
      current: Math.ceil((skip - 1) / limit) + 1,
      pages: Math.ceil(total / limit),
    });
  }

  @action
  async getForUser(
    userId = null,
    { search, mainList, statusFilterFn, statuses } = {},
  ) {
    const uuid = _.get(userId, 'uuid', userId);

    if (_.isEmpty(uuid)) {
      return null;
    }

    let query;
    try {
      let user = dispatch('auth.getUser');

      if (user.uuid !== uuid) {
        user = await dispatch('users.get', uuid, { select: false });
      }
      if (_.isEmpty(user) || _.isEmpty(user.uuid)) {
        return null;
      }

      let statusFilter = ({ status }) => /active|pending/i.test(status);
      if (_.isFunction(statusFilterFn)) {
        statusFilter = statusFilterFn;
      } else if (statuses && _.isArray(statuses)) {
        statusFilter = ({ status }) =>
          RegExp(statuses.join('|'), 'i').test(status);
      } else if (/all/i.test(statuses)) {
        statusFilter = () => true;
      }

      const companyUUIDs = _(user.companyStatus)
        .filter(statusFilter)
        .map('company')
        .value();

      query = {
        $limit: 50,
        uuid: { $in: companyUUIDs },
      };

      if (!_.isEmpty(search)) {
        this.searchValue = search;

        query.name = {
          $options: 'i',
          $regex: `.*${search}.*`,
        };
      }

      this.log.debug('Querying Companies for user', { query });

      return this.runQuery(query).then((json) =>
        mainList ? this.updateList(json) : this.updateUserCompanies(json),
      );
    } catch (err) {
      this.log.error('Failed to load company list for user', err, {
        extra: { query, userId },
      });

      return null;
    }
  }

  loadHierarchy({ company = this.selected, uuid = company.uuid } = {}) {
    if (_.isEmpty(company.path) && _.isEmpty(uuid)) {
      return Promise.resolve([]);
    }

    return (_.isEmpty(company.path) ? this.get(uuid) : Promise.resolve(company))
      .then((co) => {
        const pathRoot = _.first((co.path || co.uuid).split('/'));

        return service(this.serviceName).find({
          query: {
            $sort: { path: 1 },
            path: { $regex: `^${pathRoot}` },
          },
        });
      })
      .then((res) => this.setRelatedCompanies(res));
  }

  loadChildren({ company = {}, uuid = company.uuid }) {
    if (!uuid) {
      return Promise.resolve([]);
    }

    return service(this.serviceName)
      .find({ query: { path: { $regex: `${uuid}/` } } })
      .then((res) => {
        this.setSelectedChildren(res.data);
        return res.data;
      });
  }

  loadParent({ company = {} }) {
    const companies = company.path.split('/');

    if (companies.length === 1) {
      return Promise.resolve(this.setSelectedParent({}));
    }

    // Pick the second-to-last item in the path
    const parentId = companies[companies.length - 2];

    if (_.isEmpty(parentId)) {
      return Promise.resolve(null);
    }

    return service(this.serviceName)
      .get(parentId)
      .then((res) => this.setSelectedParent(res));
  }

  // #region Company Agents
  // TODO: Move to standalone store

  async addAgent({
    agent,
    companyRoles,
    sendWelcomeComms,
    company = this.selected,
  }) {
    if (_.isEmpty(companyRoles) && _) {
      throw new Error('Agent must have a defined role');
    }
    const companyId = _.get(company, 'uuid', company);

    if (_.isEmpty(companyId)) {
      throw new Error('Company must be defined');
    }

    const agentId = _.get(agent, 'uuid', agent);

    if (_.isEmpty(agentId)) {
      throw new Error("Agent user's UUID must be defined");
    }

    let route;

    // TODO: [EP-1400] Move this logic to the server
    _.map(companyRoles, (coRole) => {
      switch (coRole) {
        case 'survey-review':
          route = '/retail';
          break;
        default:
          route = _.isEmpty(route) ? '' : route;
          break;
      }
    });

    const agentData = {
      companyRoles,
      createdAt: Date.now(),
      route,
      sendWelcomeComms,
      status: 'pending',
      updatedAt: Date.now(),
      user: agentId,
    };

    this.log.debug('Pushing new Agent', { agentData });
    const co = await this.update({
      data: {
        $push: {
          agents: agentData,
        },
      },
      id: companyId,
    });

    this.log.debug('Pushed new agent to company "%s"', co.name);
    /* #endregion Legacy Company Agents */

    return dispatch('employerUsers.get', agentId, {
      reload: true,
      select: false,
    });
  }

  /** companies.loadAgents
   * @description This method will load all agents for a company, and has been adapted from the logic
   *       previously in ui.manageCompany. It takes the following params:
   *
   * @param {Object} arg1
   * @param {Object|String} arg1.company The company or companyID to load agents for.
   * @param {String[]} [arg1.roles] An array of agent-roles to query for.
   *     - By default this will search all possible company-agent roles, but specific roles can be queried
   * @param {Object} [arg1.query] An optional top level query to add to the agents query.
   *     - If the query includes companyStatus as a key at the top level, that key/value will not be
   *       included in the query passed to the server.
   *     - This is useful if you wish to exclude a specific user (ie: the current user)
   * @param {Object|String[]|String} [arg1.status] An object, array, or string, specifying which agent statuses should be included.
   *     - By default, the agent status query will be set to { $nin: ['former', 'inactive'] }, thus
   *       returning active and pending agents.
   *     - A value of “all” can be passed to load all agents regardless of status.
   *
   * note: This returns full user objects, not specific Company Status Records as the old agents logic
   *      did. In order to access an agent’s roles for the specific company, you will need to search the
   *      CSRs via _.find(agent.companyStatus, { company: company.uuid }). I have chosen not to promote
   *      CSR specific fields to the top level at this time as I think that is something more suitable as
   *      a server-side implementation that can ensure a standardized API for “Employer Users” and Agents.
   *
   * EP-1135 NOTE: This code retains the "legacy" signature of adding agents to a company. This is
   *  preserved for a few reasons:
   *
   * 1. The legacy agents must be maintained until this PR is deployed to production
   * 2. The hooks on the `companies` service rely on this format to populate both the legacy
   *    agents array as well as the company status array on the newly added agent
   *
   * */
  async loadAgents({
    company,
    roles = this.getAgentTypeOptions({ keys: true, loadAll: true }),
    query = {},
    status,
  }: {
    company: ICompany | ICompany['uuid'];
    query?: Query;
    roles?: Array<CompanyStatusRole>;
    status?: Query | CompanyStatusRecord['status'];
  }) {
    const companyId = _.get(company, 'uuid', company);
    if (_.isEmpty(companyId) || !_.isString(companyId)) {
      throw new Error('Invalid Company sent as parameter');
    }

    let statusFilter: Query | CompanyStatusRecord['status'] = {
      $nin: ['former', 'inactive'],
    };

    if (_.isObject(status)) {
      statusFilter = status;
    } else if (_.isArray(status)) {
      statusFilter = { $in: status };
    } else if (_.isString(status)) {
      statusFilter = status;
    } else {
      statusFilter = undefined;
    }

    const companyStatus = {
      $elemMatch: {
        company: companyId,
      },
    };

    if (!_.isEmpty(roles)) {
      companyStatus.$elemMatch.roles = { $in: roles };
    }

    if (!_.isEmpty(statusFilter)) {
      companyStatus.$elemMatch.status = statusFilter;
    }

    const agentsQuery = {
      $limit: 150,
      companyStatus,
      roles: { $in: ['company-agent', 'employer'] },
    };
    _.merge(agentsQuery, query?.query);

    try {
      dispatch('employerUsers.find', { query: agentsQuery });

      return null;
    } catch (err) {
      this.log.error('Failed to load agents', err);
      return [];
    }
  }

  /**
   * getAgentTypeOptions
   *
   * @param {Object} [arg1]
   * @param {Object} [arg1.modules] An instance of `company.modules`. Will override modules from the `company`
   *        param if both are specified.
   * @param {Object} [arg1.company] The company to pull `modules` from if the `modules` param is not specified
   * @param {bool} [arg1.loadAll] Boolean flag to load all agent types, ignoring `company` and/or `modules` parameters
   * @param {bool} [arg1.keys] Boolean flag to return an array of the `keys` rather than the full object.
   *
   * @description This method returns an array of objects representing all available agent types for the
   * supplied company. The object format is tailored for the Semantic UI, DDL component. Passing specific
   * options enable customization of what is returned and the format of the returned value.
   *
   * ## Notes
   * 1. If neither a `modules` or `company` param is passed, and the `loadAll` flag is not set, modules
   *    from the current company will be loaded via `auth.getModules` which relies on `auth.getCompany`.
   */
  getAgentTypeOptions({
    company,
    modules: srcModules,
    loadAll,
    keys,
  }: {
    company?: ICompany | ICompany['uuid'];
    keys?: boolean;
    loadAll?: boolean;
    modules?: ICompany['modules'];
  } = {}): Array<AgentTypeDDLOption | CompanyStatusRole> {
    let modules = srcModules;
    if (_.isEmpty(srcModules)) {
      modules = _.isEmpty(company)
        ? dispatch('auth.getModules')
        : _.get(company, 'modules', {});
    }

    return getAgentTypeOptions({
      company,
      keys,
      loadAll,
      modules,
    });
  }

  /**
   * getTitlesForAgentRoles
   *
   * @param {Object} arg1
   * @param {String[]} arg1.roles  An array of company agent type “keys”. Each key will be mapped to a
   *        corresponding title per the value returned from getAgentTypeOptions, defined above.
   *
   * @description Returns titles for an array of agent roles. The primary use case is the display
   *        of an agent's roles for a specific company. For example, `user.companyStatus.$.roles`
   *        may equal `['admin', 'manager']`, which should be displayed as "Team Admin, Manager"
   */

  getTitlesForAgentRoles({ roles }) {
    const agentTypeOptions = this.getAgentTypeOptions({ loadAll: true });
    return _(roles)
      .map((key) => {
        const opt = _.find(agentTypeOptions, { key });

        return _.get(opt, 'text', false);
      })
      .compact()
      .value();
  }

  // #endregion Company Agents

  /* ACTIONS */

  @action
  async patchModules({
    company = this.selected,
    key: k,
    value: v,
    toggle,
    impersonatedCompany,
  }) {
    if (!dispatch('abilities.can', 'update', company, 'settings')) {
      return Promise.reject(new Error('Unauthorized'));
    }

    const key = ['modules', k].join('.').replace('modules.modules', 'modules');

    const value = toggle ? !_.get(company, key, false) : v;

    try {
      await this.update({
        data: { [key]: value },
        id: company.uuid,
      });
      if (impersonatedCompany.uuid === company.uuid) {
        await dispatch('abilities.loadAllAbilities', { company });
      }
    } catch (error) {
      this.logAndThrow(error, { method: 'patchModules' });
    }
  }

  @action
  async patchSettings({ company = this.selected, key: k, value: v, toggle }) {
    if (!dispatch('abilities.can', 'update', company, 'settings')) {
      return Promise.reject(new Error('Unauthorized'));
    }

    const key = ['settings', k]
      .join('.')
      .replace('settings.settings', 'settings');

    const value = toggle ? !_.get(company, key, false) : v;

    return this.update({
      data: { [key]: value },
      id: company.uuid,
    }).catch((err) => this.logAndThrow(err, { method: 'patchSettings' }));
  }

  @action.bound
  async filterBy({ param = null, filter = undefined } = {}, opts) {
    const query = {};

    if (/status/.test(param)) {
      const { checked, exclusive, all } = opts ?? {};

      if (all || /all/i.test(filter)) {
        this.filter.status.replace(['all']);
      } else if (checked) {
        if (exclusive) {
          this.filter.status.replace([filter]);
        } else {
          this.filter.status.push(filter);
        }
      } else {
        if (this.filter.status.includes('all')) {
          this.filter.status.replace(this.allStatuses);
        }
        this.filter.status.remove(filter);
      }

      if (_(this.filter.status).xor(this.allStatuses).isEmpty()) {
        this.filter.status.replace(['all']);
      }

      _.extend(query, {
        status:
          _.isEmpty(this.filter.status) || _.includes(this.filter.status, 'all')
            ? undefined
            : { $in: this.filter.status },
      });
    }

    return this.find(query, opts);
  }

  get allStatuses() {
    return [
      'active',
      'demo',
      'archived',
      'draft',
      'pending',
      'unclaimed',
      null,
    ];
  }

  @computed
  get statusFilter() {
    return this.filter.status ?? this.allStatuses;
  }

  @action.bound
  async closeCompany({ companyId, isSuperAdmin }) {
    if (!isSuperAdmin) {
      return Promise.reject(new Error('Unauthorized'));
    }

    // remove will soft delete
    return this.remove(companyId).catch((err) =>
      this.logAndThrow(err, { method: 'closeCompany' }),
    );
  }
}
