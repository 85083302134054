import type { Paginated, Params, Query } from '@feathersjs/feathers';
import type { IObservableArray } from 'mobx/lib/internal';
import type { ICompany, IBaseItem } from '@shiftsmartinc/shiftsmart-types';
import type AuthStore from '#/shared/stores/auth';

import { action, computed, set, observable, toJS, runInAction } from 'mobx';
import _, { MapCache, MemoizedFunction } from 'lodash';
import BluebirdPromise from 'bluebird';
import moment from 'moment';
import mingo from 'mingo';
import { v4 as genUUID } from 'uuid';
import { dispatch } from 'rfx-core';
import isUUID from 'uuid-validate';
import yn from 'yn';

import { app, service } from '#/shared/app';
import { getChildLogger, SSMLogger } from '#/shared/utils/client.logger';

const storeLog = getChildLogger('baseStore');

// #region Types & Interfaces
export interface IStoreFindOpts<T> {
  /**
   * ### clear
   * Whether to clear out any existing query params. Also see `opts.preserve`
   *
   * @type {(boolean | { preserve?: Array<keyof T> })}
   * @memberof IStoreFindOpts
   */
  clear?:
    | {
        /** @deprecated please use {@see IStoreFindOpts.preserve} instead */
        preserve?: Array<keyof T>;
      }
    | boolean;
  /**
   *
   * @memberof IStoreFindOpts
   */
  hooks?: Array<() => unknown>;

  /**
   * ### noQuery
   * If set to `true`, the query object will be updated, but the query will
   * not be run. The query object will be returned in a promise
   *
   * Alternatively, a function can be passed in to dynamically decide whether
   * or not the query should proceed. The passed in function must return a
   * boolean. If it returns `true` or other truthy value, no query will be
   * run against the server. Otherwise, if a falsey value is returned, the query
   * will proceed and be run against the API.
   *
   * @type {boolean | function}
   * @memberof IStoreFindOpts
   */
  noQuery?: boolean | (() => boolean);
  /**
   * ### preserve
   * An array of query params to preserve by default. See also {@link IStoreFindOpts['preserve']}
   *
   * @type {Array<keyof T>}
   * @memberof IStoreFindOpts
   */
  preserve?: Array<keyof T>;
  /**
   * ### resetNestedKey
   * As explained in full detail on the `find` method, we use the pattern of passing a key with an
   * `undefined` value in your query in order to remove (or delete) the corresponding query key. However,
   * this mecanism only works at the query root level. In some cases, we deal with resetting only one
   * part of a nested query object. This is especially useful when clearing a single filter without having
   * to reset the entire query object
   *
   * ```ts
   * // Imagine the following query object:
   * resultQuery = { customQueryKey: { $not: { $gt: 0 }}};
   * // And then wanting to set the $lte by adding:
   * const lteQuery = { customQueryKey: { $lte: 50 }}
   * // Because of the way the query merge alg works,
   * // the find method constructs the query as:
   * store.find(lteQuery);
   * echo store.query.query; // { customQueryKey: { $not: { $gt: 0 }, $lte: 50 }}
   * // ... which is not at all what we wanted since the `$not` is "stuck" in there
   * ```
   *
   * By leveraging the custom `resetNestedKey` prop, we can instead specify the query as:
   *
   * ```ts
   * query = {
   *   customQueryKey: {
   *     $not: undefined,
   *     $lte: 50
   *   }
   * };
   * // then call find:
   * store.find(query, { resetNestedKey: 'customQueryKey' })
   * // which returns the expected query:
   * echo store.query.query; // { customQueryKey: { $lte: 50 }}
   * ```
   *
   */
  resetNestedKey?: keyof T;
  /**
   * ### save
   * Whether to save the results of the query to the store, or return them directly
   *
   * @type {boolean}
   * @memberof IStoreFindOpts
   */
  save?: boolean;
  /**
   * ### smart
   * When enabled, detects whether the query is unchanged, and will return the curren value of
   * `store.list` instead of re-querying the server
   *
   * @type {boolean}
   * @memberof IStoreFindOpts
   */
  smart?: boolean;
}

export interface IStoreUpdateArgs<T extends IBaseItem = IBaseItem> {
  data: Partial<T>;
  id?: T['uuid'];
  params?: Params;
  query?: Query;
}

export type IStoreUpdateManyArgs<T extends IBaseItem = IBaseItem> = {
  /** the new data for the objects */
  data: Partial<T>;
  /** uuids array for objects that will be updated */
  ids?: Array<T['uuid']>;
  params?: Params;
  /** the selection query for the update */
  query?: Query;
};

export interface IStoreRemoveManyArgs<T extends IBaseItem = IBaseItem> {
  /** uuids array for objects that will be deleted */
  ids: Array<T['uuid']>;
}
export interface IStoreGetOpts {
  /**
   * An optional query to further restrict the
   */
  query?: Query;
  /**
   * When the store has caching enabled, setting this flag will ensure the value
   * is always loaded from the server, not the cache.
   */
  reload?: boolean;
  /**
   * after loading the specified value, call `setSelected` to update the
   * currently selected item
   */
  select?: boolean;
}

export interface IStoreUpdateListOpts {
  /**
   * ### append
   * If set to 'true', the results of the query will be appended to the list
   *
   * @type {boolean}
   * @memberof IStoreUpdateListOpts
   */
  append?: boolean;
}

export interface IStoreRemoveOpts extends Params {
  /**
   * ### multi
   * Enable multiple records (bulk) to be deleted
   *
   * @type {boolean}
   * @memberof IStoreRemoveOpts
   */
  multi?: boolean;
  /**
   * ### query
   * Query to further target which records should be removed. Required
   * if the `multi` param is set
   *
   * @type {Query}
   * @memberof IStoreRemoveOpts
   */
  query?: Query;
}

export type IBaseStore = typeof BaseStore.prototype;

export type StoreConstructorProps<T> = {
  /** Required for IStatusFilterable and IStatusGroupFilterable */
  baseFilter?: unknown;
  baseItem?: Partial<T>;
  cacheSize?: number;
  companyField?: string;
  /**
   * ### debounceSearch
   * This settings will allow to wait for the final searchValue update
   * and help to reduce unecessary api call.
   * If it is true then it will wait for 500 ms,
   * otherwise any number will be considered as waiting ms
   */
  debounceSearch?: boolean | number;
  /** Enables interception of Patch & Create responses to be interpreted as Real Time Events, updating the domain store's list or selected items */
  enableLocalResponseEventing?: boolean;
  /** For the few services which do not use `uuid` as the primary key, an override can be specified here. */
  idPath?: keyof T & string;
  modelName?: string;
  opts?: unknown;
  preserveFields?: Array<string & keyof T>;

  /** @deprecated this flag is a temporary feature flag for enabling $search query */
  searchFields?: Array<string & keyof T> | false;
  serviceName: string;
  title?: string;

  /** prefered method of implementing "text search" for a service. Uses `$search` query param to interpret search on server */
  useApiSearch?: boolean;

  /** @deprecated this flag was a temporary feature flag for enabling atlasSearch for all users for a service */
  useAtlasSearch?: boolean;
};

// #endregion Types & Interfaces
export default class BaseStore<T extends IBaseItem, Filter = unknown> {
  constructor({
    serviceName,
    modelName,
    baseItem = {},
    baseFilter = {},
    cacheSize = 0,
    searchFields = ['title'] as unknown as Array<string & keyof T>,
    title = serviceName,
    preserveFields = [],
    companyField = 'companies',
    useApiSearch,
    useAtlasSearch = false,
    opts = {},
    debounceSearch = 300,
    idPath = 'uuid',
    enableLocalResponseEventing,
  }: StoreConstructorProps<T>) {
    storeLog.verbose(`Initializing new ${serviceName} instance of BaseStore`);
    this.title = title || 'BaseStore';
    this.serviceName = serviceName;
    this._modelName = modelName;
    this.searchFields = searchFields;
    this.companyField = companyField;
    this.preservedFields.replace(preserveFields);
    set(this.baseItem, { ...BaseStore.BASE_ITEM, ...baseItem });
    this._baseFilter = baseFilter;
    set(this.filter, this.baseFilter);
    this.idPath = idPath || 'uuid';

    // TODO: Confirm Observable

    this.options = { ...this.options, ...opts };
    this.useApiSearch = useApiSearch ?? this.useApiSearch;
    this.useAtlasSearch = useAtlasSearch;
    this.enableLocalResponseEventing = enableLocalResponseEventing ?? true;

    this.cacheSize = cacheSize;
    if (this.cacheSize > 0) {
      this.getLocal = _.memoize(this._getLocal);

      // this.logCache();
    } else {
      this.getLocal = this._getLocal;
    }

    if (debounceSearch) {
      this.debounceFind = _.debounce(
        this.debounceFind,
        _.isBoolean(debounceSearch) ? 500 : debounceSearch,
      );
    }

    set(this.selected, _.clone(this.baseItem));

    this.log.debug(
      `Initialized new instance of the BaseStore for "${title}"${
        serviceName !== title ? ` (service: "${serviceName})` : ''
      }`,
    );

    return this;
  }

  useApiSearch =
    localStorage.getItem('useApiSearch') !== null
      ? yn(localStorage.getItem('useApiSearch'))
      : false;

  useAtlasSearch = false;

  title = 'BaseStore';

  companyField: 'company' | 'companies' | 'companyId' | string;

  idPath = 'uuid';

  /** Enables interception of Patch & Create responses to be interpreted as Real Time Events, updating the domain store's list or selected items */
  enableLocalResponseEventing: boolean;

  _modelName: string;

  _log: SSMLogger;

  @computed
  protected get log() {
    const loggerPrefix = `stores.${
      this.title || this.serviceName || 'BaseStore'
    }`;

    if (_.isEmpty(this._log) || !_.isFunction(this._log.debug)) {
      this._log = getChildLogger(loggerPrefix);
    }

    // Handle case where this.title has changed
    if (!RegExp(loggerPrefix).test(_.get(this._log, 'opts.prefix'))) {
      this._log = getChildLogger(loggerPrefix);
    }

    return this._log;
  }

  @observable options = {
    addToListOnCreateEvent: false,
    clearSelectedOnUpdateMismatch: false,
    removeFromListOnUpdateMismatch: false,
  };

  @observable
  serviceName = 'service';

  static BASE_ITEM: IBaseItem = {
    _modelName: '',
    createdAt: null,
    updatedAt: null,
    uuid: null,
  };

  @observable
  baseItem: T = _.clone(BaseStore.BASE_ITEM as T);

  @observable _baseFilter = {};

  get baseFilter() {
    return _.cloneDeep(this._baseFilter);
  }

  query: Params = {};

  lastQuery: Params['query'] = null;

  @observable
  sort = {};

  @observable
  searchFields: Array<string & keyof T> | false = [];

  @observable
  lastStatusCode = null;

  @observable
  searchValue = '';

  @observable
  filter: Partial<Filter> = {};

  list = observable.array<Partial<T> & IBaseItem>([]);

  @observable
  selected: T = _.clone(this.baseItem);

  @observable
  isLoading = false;

  @observable
  loaderStatus = {
    delete: false,
    find: false,
    get: false,
    update: false,
  };

  preservedFields: IObservableArray<string> = observable<string>([]);

  /*
    "total": "<total number of records>",
    "limit": "<max number of items per page>",
    "skip": "<number of skipped items (offset)>",
    "current": "<current page number>"
    "pages": "<total number of pages>"
  */
  @observable
  $pagination: { limit?: number; skip?: number; total?: number } = {};

  debounceFind = this.find;

  @computed
  get pagination(): {
    current: number;
    limit: number;
    pages: number;
    skip: number;
    total: number;
  } {
    const { total = 0, limit = 1, skip = 0 } = this.$pagination;
    return {
      current: Math.ceil((skip - 1) / limit) + 1,
      limit,
      pages: Math.ceil(total / limit),
      skip,
      total,
    };
  }
  // #endregion Instance Variables

  init() {
    // run events on client side-only
    if (global.TYPE === 'CLIENT') this.initEvents();
  }

  // #region Realtime Event Handler Utils
  async addItem(item) {
    if (_.isEmpty(item)) {
      this.log.debug(`Empty Item in addItem for ${this.serviceName}`);
      return null;
    }

    this.log.silly(`New ${this.serviceName} was created: `, {
      itemId: item?.[this.idPath],
    });

    if (!_.isEmpty(this.query.query)) {
      try {
        const isMatch = await this.isItemMatch(item);

        if (isMatch) {
          this.log.debug(`Adding new to ${this.serviceName} list: `, {
            item,
          });
          this.pushItem(_.defaults(item, this.baseItem));
          return item;
        }
      } catch (err) {
        this.log.error(
          `Error Checking If Item Matches for service: ${this.serviceName}`,
          err,
          { extra: { itemId: item?.[this.idPath] } },
        );
      }
    }

    return null;
  }

  /** push item into the list */
  @action
  protected pushItem(item: T): T | null {
    if (_.isString(item)) {
      throw new Error(
        `Push Item called with possible UUID, Object Expected "${item}"`,
      );
    }

    if (_.find(this.list, { [this.idPath]: item[this.idPath] })) {
      return null;
    }

    _.set(item, '_modelName', this.getModelName(item));

    if (this.list.length >= this.pagination.limit) this.list.pop();
    this.list.unshift(item);
    this.$pagination.total += 1;

    /** Reset Smart Queries ???
     * TODO: Determine if this is needed to resolve EP-921
     * ... it shouldn't be necessary 😫
     */
    // this.lastQuery = null;

    return item;
  }

  /**
   * #### removeItem
   * Removes the specified item from the store's list, and updates the pagination
   * total. Items may be specified by either a query, an item, or a uuid.
   */
  @action
  removeItem(item: T | T['uuid'], removeQuery?: Query): Array<T> {
    const query = removeQuery || {
      [this.idPath]: (item as T)?.[this.idPath] ?? (item as T['uuid']),
    };
    if (!_.isEmpty(query)) {
      const removed = _.remove(this.list, query);
      this.$pagination.total -= _.size(removed);

      return removed;
    }

    return [];
  }
  // #endregion

  // #region Clearing & Reset

  @action
  emptyList() {
    this.list.clear();
    this.$pagination = {};
    this.lastQuery = {};
  }

  @action
  clearSearch() {
    this.searchValue = '';
    this.search(null, {}, { noQuery: true });
  }

  @action
  clearQueryPath(path) {
    if (_.has(this.query.query, path)) {
      _.unset(this.query.query, path);
    }
  }

  @action
  setLastStatusCode(code) {
    this.lastStatusCode = code;
  }

  /**
   * #### reset
   * Resets the selected item, list, search, sort, and filter
   */
  @action
  reset(): void {
    this.clearSelected();
    this.emptyList();
    this.resetSearch();

    this.sort = {};
  }

  /**
   * #### resetSearch
   * Resets the searchValue, filter, and parts of the main query related to search.
   *
   * - Uses the `preserve` flag to define which fields to keep in the query
   * - Uses the `refresh` flag to call the store's `find` method
   */
  @action
  resetSearch({
    preserve = [],
    refresh,
  }: { preserve?: Array<keyof T>; refresh?: boolean } = {}): void {
    this.log.debug('Resetting Search...');
    this.query = {
      query: _.pick(this.query.query, _.union(preserve, this.preservedFields)),
    };
    this.searchValue = '';
    this.setLastStatusCode(null);

    if (_.isObject(this.baseFilter) && !_.isEmpty(this.baseFilter)) {
      set(this.filter, this.baseFilter);
    } else if (
      _.isObject(this.filter) &&
      _(this.filter).keys().size() === 1 &&
      _.has(this.filter, 'status')
    ) {
      set(this.filter, { status: 'all' });
    } else if (_.isObject(this.filter)) {
      this.filter = _.mapValues(this.filter, () => undefined);
    } else if (_.isString(this.filter)) {
      this.filter = 'all';
    }

    if (refresh) {
      this.find();
    }
  }

  @action
  clearSort(): void {
    this.sort = {};
  }

  // #endregion

  // #region Crud & Server Operations

  /**
   * ### create
   * Runs a `POST` operation on the server, creating a new record on the server for
   * the given data object.
   */
  async create({
    data,
    params,
  }: {
    data: Omit<T, keyof IBaseItem>;
    params?: Params;
  }): Promise<T> {
    if (_.isEmpty(data)) {
      throw new Error('No Data Specified');
    }

    this.log.debug(`Creating new ${this.serviceName}: `, { data, params });
    return service(this.serviceName)
      .create(data, params)
      .catch((err) => this.logAndThrow(err, { data, params }));
  }

  /**
   *
   * @param {*} query The main query to execute against the backend
   * @param {IStoreFindOpts} opts: A variety of options to control side-effects within the store
   *
   * ### Clearing a Key
   * A specific base query key can be cleared by passing its value as `undefined`. Note that
   * this only works at the top level and will not apply to nested keys.
   *
   * ### Smart Queries:
   * In the case where an the previous query is currently "in progress",
   * return the list object. This will give the calling dispatch access
   * to the object (if it accesses it directly), while the store's "isLoading"
   * prop will enable that component to keep track of the current state.
   * This prevents duplicate server calls from pounding the server, while
   * allowing us to maintain a consistent UX across components.
   */
  public find( query?: Query | { query: Query }, opts?: IStoreFindOpts<T> & IStoreUpdateListOpts); // prettier-ignore
  public find( query?: Query | { query: Query }, opts?: { noQuery: true } & IStoreFindOpts<T> & IStoreUpdateListOpts): Promise<Query>; // prettier-ignore
  public find( query?: Query | { query: Query }, opts?: { noQuery?: false | undefined } & IStoreFindOpts<T> &   IStoreUpdateListOpts): Promise<T[]>; // prettier-ignore
  @action
  public async find(
    query: Query | { query: Query } = {},
    opts?: IStoreFindOpts<T> & IStoreUpdateListOpts,
  ): Promise<T[] | Query> {
    if (dispatch('auth.cannot', 'read', this.modelName)) {
      const body = `User is not granted permission to read the ${this.serviceName} service (${this.modelName}). Proceeding anyway.`;

      this.log.warn(`CASL Violation: ${body}`);
    }

    const {
      save = true,
      clear = false,
      noQuery = false,
      preserve,
      smart = false,
      hooks = [],
      resetNestedKey,
    } = opts ?? {};

    const baseQuery: Query = _.isUndefined(query.query) ? { query } : query;

    if (clear) {
      this.resetSearch({
        preserve:
          preserve ||
          (_.has(clear, 'preserve')
            ? (clear as { preserve: Array<keyof T> }).preserve
            : []),
      });
    }

    /** EP-103 Default merge Behavior
     * By default, `_.merge` will "Array and plain object properties are merged recursively"
     * This means, that when merging two queries, with each containing an array of excluded
     * (or included) uuid's, the old and new arrays will be merged (eg: union), rather than
     * be replaced (as intended). This is the major difference in behavior between merge and
     * extends - heretofor we had treated merge as "extends deep", but this merging of array
     * value props marks a substantial difference.
     */
    this.query = this.mergeQueryObject(baseQuery);

    if (_.isEmpty(query) && !this.query.query.$limit) {
      this.query.query.$limit = 100; // TODO: make configurable on new
    }

    _(baseQuery.query)
      .keys()
      .each((key) => {
        if (_.isUndefined(baseQuery.query[key])) {
          delete this.query.query[key];
        }

        // Inspect and reset any values in the nested key
        // Used when resetting portion of a nested query object
        // {@see IStoreFindOpts['resetNestedKey']}
        if (resetNestedKey === key) {
          _.each(this.query.query[key], (val, nestedKey) => {
            if (_.isUndefined(baseQuery.query[key][nestedKey])) {
              delete this.query.query[key][nestedKey];
            }
          });
        }
      });

    if (baseQuery.query.$sort) {
      this.sortBy(baseQuery.query.$sort, { noQuery: true });
    }

    this.query.query.$sort = this.sort;

    if (_.isFunction(noQuery) ? noQuery() : noQuery) {
      this.log.silly('Not executing query');
      return this.query;
    }

    /**
     * **Smart Query**: If the query is unchanged from the last successful
     * one, return the list directly instead of re-querying the server
     */
    if (
      smart &&
      _.isEqual(
        _.omit(this.lastQuery, ['$client.otelCarrier']),
        _.omit(this.query.query, ['$client.otelCarrier']),
      )
    ) {
      this.log.debug('New Query is unchanged, not executing');
      return this.list;
    }

    /**
     * **Storing the last Query for Smart Queries**
     * Only set the `lastQuery` value IF the result is being saved to `this.list`.
     * Otherwise, the value of `this.list` may be out of sync with the return
     * value of the pre-optimized server call.
     */
    if (save) {
      this.setLastQuery(_.cloneDeep(this.query.query));
    }

    this.isLoading = _.isFunction(noQuery) ? !noQuery() : !noQuery;
    this.loaderStatus.find = this.isLoading;

    if (!_.isEmpty(hooks)) {
      await BluebirdPromise.map(hooks, async (hook) =>
        _.isFunction(hook) ? hook() : false,
      ).catch((err) => {
        this.log.error('Caught error in query hook', err);
        throw err;
      });
    }

    this.log.debug(
      `Querying ${this.serviceName} on query:`,
      toJS(this.query.query),
    );

    try {
      const json = await service(this.serviceName).find(this.query);
      this.setLastStatusCode(200);

      this.cacheList(json.data);
      // return save ? this.updateList(json, opts) : json.data;

      let list = json.data;
      if (save) {
        list = this.updateList(json, opts);
      }

      if (
        save &&
        this.pagination.current !== 1 &&
        this.pagination.current > this.pagination.pages
      ) {
        return (await this.page(1, opts)) || list;
      }

      return list;
    } catch (err) {
      _.extend(err, { query: this.query });
      this.setLastStatusCode(err.code);

      if (save) {
        this.setLastQuery(null);
      }
      this.logAndThrow(err, { method: 'find' });
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.loaderStatus.find = false;
      });
    }

    // TODO: removing this triggers `consistent-return` error for the full method, even though code is not reachable
    return null;
  }

  async findOne({ query = {} }): Promise<T | null> {
    if (_.isEmpty(query)) {
      throw new Error('Empty Query');
    }

    return service(this.serviceName)
      .find({ query: _.extend({ $limit: 1 }, query) })
      .then((response) => _.first(response.data));
  }

  async get(
    id: T['uuid'] | null,
    {
      select = true,
      query,
      reload,
    }: { query?: Query; reload?: boolean; select?: boolean } = {},
  ) {
    if (_.isEmpty(id) && _.isEmpty(query)) {
      this.log.error(
        'No ID Passed to "get" function. Please include a `query` or use the `findOne` method',
      );
      throw new Error(`No ${this.serviceName} ID Specified in method call`);
    }

    if (id && !reload && !!this.cacheSize) {
      try {
        const cached: T = await this.getLocal(id, query);

        if (!_.isEmpty(cached) && !_.isEmpty(cached[this.idPath])) {
          return select ? this.setSelected(cached) : Promise.resolve(cached);
        }
      } catch (err) {
        this.log.error('Failed to load cached value', err);
        this.removeFromCache(id);
      }
    }

    try {
      runInAction(() => {
        this.loaderStatus.get = true;
      });
      const res = await service(this.serviceName).get(id, { query });

      _.set(res, '_modelName', this.getModelName(res));

      if (res && this.cacheSize && !reload && !query?.$select) {
        this.setInCache(id, res);
      }

      if (select) {
        return this.setSelected(res);
      }

      _.extend(res.constructor, { modelName: this.getModelName(res) });
      return res;
    } catch (err) {
      if (/NotFound/i.test(err.name) && select) {
        return this.clearSelected();
      }

      this.logAndThrow(err, { method: 'get' });
    } finally {
      runInAction(() => {
        this.loaderStatus.get = false;
      });
    }

    // TODO: removing this triggers `consistent-return` error for the full method, even though code is not reachable
    return null;
  }

  async remove(id: IBaseItem['uuid'], opts?: IStoreRemoveOpts) {
    if (_.isEmpty(id) && (!opts?.multi || _.isEmpty(opts?.query))) {
      if (!opts?.multi) {
        throw new Error('No UUID Specified for Removal');
      }

      throw new Error('Must have non-empty query to execute multi-remove');
    }
    runInAction(() => {
      this.loaderStatus.delete = true;
    });

    const params = _.omit(opts, ['multi']);

    try {
      const json = await service(this.serviceName).remove(id, params);

      if (opts?.multi) {
        _.map(json.data, (item) => this.removeItem(item));
      } else {
        this.log.debug(
          `Removed ${this.serviceName} with result: ${json[this.idPath]}`,
          json,
        );
        this.removeItem(json);
      }

      return json;
    } catch (err) {
      this.logAndThrow(err, { method: 'remove' });
    } finally {
      runInAction(() => {
        this.loaderStatus.delete = false;
      });
    }

    // TODO: removing this triggers `consistent-return` error for the full method, even though code is not reachable
    return null;
  }

  /**
   * ### update
   *
   * Runs a `PATCH` operation on the server
   */

  update({ data, query, params, id }: { data?: Partial<T>; id?: T['uuid']; params?: never; query: Query; }); // prettier-ignore
  update({ data, query, params, id }: { data?: Partial<T>; id?: T['uuid']; params: Params; query?: never;  }); // prettier-ignore
  update({ data, query, params, id }: { data?: Partial<T>; id: T['uuid']; params?: never; query?: never;  }); // prettier-ignore
  @action.bound
  async update({
    data,
    query,
    params = { query },
    id = data?.[this.idPath] || data?.uuid || null,
  }: IStoreUpdateArgs): Promise<T> {
    if (_.isEmpty(id) && _.isEmpty(params.query)) {
      this.log.error('No ID Specified in update request', data);
      throw new Error(`No ${this.serviceName} ID Specified in method call`);
    }

    runInAction(() => {
      this.loaderStatus.update = true;
    });

    if (!_.isEmpty(query) && !_.has(params, 'query')) {
      _.extend(params, { query });
    }

    return service(this.serviceName)
      .patch(id, data, params)
      .catch((err) => this.logAndThrow(err, { method: 'update' }))
      .finally(() => {
        runInAction(() => {
          this.loaderStatus.update = false;
        });
      });
  }

  /*
   * ### update many
   *
   * Runs a `PATCH` multi operation on the server
   * It can be used either with:
   * ids and data : a multi patch on the objects base on ids
   * OR
   * data and query : a multi patch performed on the api, based on query selection ($client param included)
   *
   *@param data - data object of the modified fields
   *@param params - optional default update params
   *@param query - optional additional query
   *@param ids - an uuids array of the objects that will be updated
   *@returns  - returns an array of the updated objects
   */
  async updateMany({ data, params, ids, query }: IStoreUpdateManyArgs) {
    if (
      (_.isEmpty(ids) && _.isEmpty(data)) ||
      (_.isEmpty(data) && _.isEmpty(query))
    ) {
      this.log.error('No IDS or data Specified in update many request', data);
      throw new Error(
        `No ${this.serviceName} IDS or data specified in this method call`,
      );
    }

    runInAction(() => {
      this.loaderStatus.update = true;
    });

    let updateManyQuery = {};
    const updateManyParams = { ...params, query: {} };
    if (!_.isEmpty(ids)) {
      updateManyQuery = { [this.idPath]: { $in: ids } };
    }
    if (!_.isEmpty(query)) {
      updateManyQuery = { ...query };
    }

    if (!_.isEmpty(updateManyQuery) && !_.has(params, 'query')) {
      updateManyParams.query = _.extend(params, updateManyQuery);
    }

    return service(this.serviceName)
      .patch(null, data, updateManyParams)
      .catch((err) => this.logAndThrow(err, { method: 'updateMany' }))
      .finally(() => {
        runInAction(() => {
          this.loaderStatus.update = false;
        });
      });
  }

  /*
   * ### remove many
   *
   * Runs a `REMOVE` multi operation on the server
   *
   *@param ids - an uuids array of the objects that will be deleted
   *@returns  - returns an array of the deleted objects
   */
  removeMany({ ids }: IStoreRemoveManyArgs) {
    if (_.isEmpty(ids)) {
      this.log.error('No IDS Specified in remove many request');
      throw new Error(
        `No ${this.serviceName} IDS specified in this method call`,
      );
    }
    runInAction(() => {
      this.loaderStatus.delete = true;
    });
    const removeManyQuery = { [this.idPath]: { $in: ids } };

    return this.remove(null, { multi: true, query: removeManyQuery })
      .catch((err) => this.logAndThrow(err, { method: 'removeMany' }))
      .finally(() => {
        runInAction(() => {
          this.loaderStatus.delete = false;
        });
      });
  }

  // #region Aux Crud Operations

  /**
   * For stores that have a `company` or `companies` property, implements the find method
   * to load all records matching that company. Passing in the `query` object allows further
   * query parameters to be passed into the store's `find` method. The `opts` param does the
   * same.
   *
   * ### Usage
   * This method is best used as a part of the `useFetchData` (or other component initialization)
   * stage, as it passes the `clear: true` option to `find` by default. This make query
   * composition more complicated.
   */
  findByCompany({
    company,
    query = {},
    opts: inputOpts = {},
  }: {
    company: ICompany | ICompany['uuid'] | AuthStore['company'];
    opts?: IStoreFindOpts<T> & { deselect?: boolean };
    query?: Query;
  }) {
    if (_.isEmpty(this.companyField)) {
      throw new Error(
        `No Company Field is defined for the ${this.serviceName} store`,
      );
    }
    const { deselect, ...opts } = inputOpts;

    const id = _.get(company, 'uuid', company);
    if (!deselect && (_.isEmpty(id) || !_.isString(id))) {
      return BluebirdPromise.resolve();
    }
    return this.find(
      { query: _.extend({ [this.companyField]: id }, query) },
      _.extend({ clear: true }, opts),
    );
  }

  /**
   * ### runQuery
   * Runs a feathers query directly against the service, returning the raw
   * paginated result.
   */
  async runQuery(query: Query): Promise<Paginated<T>> {
    const q = _.has(query, 'query') ? query.query : query;

    this.log.debug('Executing adhoc query: ', { query });

    return service(this.serviceName)
      .find({ query: q })
      .catch((err) => this.logAndThrow(err, { method: 'runQuery', query: q }));
  }

  /**
   * ### getCount
   * Runs the query against the server, returning the total number of documents
   * matching the query.
   */
  getCount(query?: Query): Promise<number> {
    return service(this.serviceName)
      .find({ query: _.extend(query, { $limit: 0 }) })
      .then(({ total }) => total)
      .catch((err) => this.logAndThrow(err, { method: 'getCount', query }));
  }

  /**
   * ### runAggregate
   * Runs a mongodb aggregate query against the server. You can specify
   * the expected return type via the `A` generic type parameterr
   */
  runAggregate<A = unknown>({
    query,
    group: $group,
    pipeline,
  }: {
    group?: unknown;
    pipeline?: Array<unknown>;
    query?: Query;
  } = {}): Promise<Array<A>> {
    let pl = pipeline;

    if (_.isEmpty(pl)) {
      const $match =
        query ||
        _.pickBy(this.query.query, (v, key) => {
          if (key[0] === '$') {
            return false;
          }

          return true;
        });

      pl = [
        {
          $match,
        },
        {
          $group,
        },
      ];
    }

    this.log.debug('Running Aggregate', pl);

    return this.runQuery({
      _aggregate: pl,
    }) as unknown as BluebirdPromise<Array<A>>;
  }

  // #endregion
  // #endregion

  // #region Actions

  @action
  updateList(
    json: Paginated<T> & {
      /**
       * metadata
       * @deprecated Legacy method of capturing a paginated aggregate query result
       */
      metadata?: unknown;
    },
    opts?: IStoreUpdateListOpts,
  ): this['list'] {
    const { append = false } = opts ?? {};

    const newList = _.map(json.data, (item) => {
      _.set(item, '_modelName', this.getModelName(item));
      return item;
    });
    if (append) {
      this.list.replace(_.concat(this.list, newList));
    } else {
      this.list.replace(newList);
    }
    if (json?.metadata) {
      set(this.$pagination, json.metadata);
    } else {
      this.$pagination = _.omit(json, 'data');
    }

    this.log.debug(
      `Loaded ${this.pagination.skip ? `${this.pagination.skip}-` : ''}${
        this.pagination.skip + _.size(this.list)
      } of ${this.pagination.total} ${this.serviceName}`,
      {
        list: this.list,
        query: this.query.query,
      },
    );

    return this.list;
  }

  setSelected(json: null | undefined | ''): ReturnType<this['clearSelected']>;
  setSelected(
    json: T | T['uuid'],
    opts?: { clientParams: any; reload: boolean },
  ): Promise<T>;
  @action
  async setSelected(
    json: T | T['uuid'],
    { reload = false, clientParams = {} } = {},
  ) {
    if (_.isEmpty(json)) {
      return this.clearSelected();
    }
    if (_.isString(json) || reload) {
      const uuid =
        (json as T)?.[this.idPath] ?? (json as T)?.uuid ?? (json as T['uuid']);
      return this.get(uuid, {
        query: { $client: clientParams },
        reload,
        select: true,
      });
    }

    this.log.debug('Setting Selected %s: %o', this.serviceName, json);
    this.selected = _.defaults(json, this.baseItem);

    _.set(this.selected, '_modelName', this.getModelName(this.selected));

    return this.selected;
  }

  @action
  async clearSelected(): Promise<this['selected']> {
    this.selected = {};

    return this.selected;
  }

  /**
   * ### page
   * Switches to the specified page in the paginated results
   */
  @action
  async page(
    page = 1,
    opts?: IStoreFindOpts<T> & IStoreUpdateListOpts,
  ): Promise<T[] | Query> {
    const skipPage = this.pagination.limit * (page - 1);
    const { pages } = this.pagination;

    if (skipPage < 0 || page > pages) {
      return null;
    }

    return this.find({ query: { $skip: skipPage } }, opts);
  }

  // #endregion Actions

  // #region EVENTS

  initEvents() {
    service(this.serviceName).on('created', action(this.onCreated)); // onCreated = (data, params) => {}
    service(this.serviceName).on('updated', action(this.onUpdated)); // onUpdated = (data) => {}
    service(this.serviceName).on('patched', action(this.onPatched)); // onPatched = (id, data) => {}
    service(this.serviceName).on('removed', action(this.onRemoved));

    app().on('login:company', action(this.onCompanyChange));

    if (this.enableLocalResponseEventing) {
      service(this.serviceName).hooks({
        after: {
          create: [
            (context) => {
              this.onCreated(context.result);
              return context;
            },
          ],
          patch: [
            (context) => {
              this.onPatched(context.result);
              return context;
            },
          ],
        },
      });
    }
  }

  listenerRegistration = {};

  @action
  registerEventListener({
    onCreated,
    onUpdated,
    onPatched,
    onRemoved,
    id = genUUID(),
  }: {
    id?: string;
    onCreated?: (data) => void;
    onPatched?: (data) => void;
    onRemoved?: (id, params) => void;
    onUpdated?: (data) => void;
  }): string {
    if (global.TYPE !== 'CLIENT') return null;

    if (this.listenerRegistration[id]) {
      this.log.error(
        `[registerEventListener] Event Listeners already registered for this ID "${id}"`,
      );
    }

    const listeners = {
      created: _.isFunction(onCreated) && onCreated,
      id,
      patched: _.isFunction(onPatched) && onPatched,
      removed: _.isFunction(onRemoved) && onRemoved,
      updated: _.isFunction(onUpdated) && onUpdated,
    };

    const registered = _(listeners)
      .pickBy((listenerFn) => _.isFunction(listenerFn))
      .reduce((acc, listenerFn, key) => {
        service(this.serviceName).on(key, listenerFn);
        acc[key] = listenerFn;
        this.log.silly(`[registerEventListener] registered ${key} listener`);

        if (/updated/.test(key) && !listeners.patched) {
          this.log.warn(
            '[registerEventListener] Registering "updated" listener, did you mean to listen to "patched" events?',
          );
        }

        return acc;
      }, {});

    if (_.isEmpty(registered)) {
      this.log.warn(
        '[registerEventListener] No valid event listeners registered',
      );
      return null;
    }

    this.log.silly(
      `[registerEventListener] Registering Event Listeners for ${_.keys(
        registered,
      ).join(', ')}`,
      {
        registered,
      },
    );

    this.listenerRegistration[listeners.id] = registered;

    const ct = _.size(this.listenerRegistration);
    this.log[ct % 25 ? 'silly' : 'debug'](
      '[registerEventListener] Current Registered Listeners: %d',
      ct,
    );

    return listeners.id;
  }

  @action
  deregisterEventListener(id) {
    setTimeout(() => {
      if (!id || (!_.isNumber(id) && _.isEmpty(id))) {
        this.log.warn('No ListenerID Specified');
        return;
      }
      const listeners = this.listenerRegistration[id];
      if (_.isEmpty(listeners)) {
        this.log.debug('No Listeners to De-Register');
        return;
      }

      _.map(listeners, (val, key) => {
        if (_.isFunction(val)) {
          service(this.serviceName).removeListener(key, val);
          this.log.silly(`De-Registering Event listeners for key ${key}`);

          delete listeners[key];
        }
      });

      if (!_.isEmpty(listeners)) {
        this.log.error('Failed to remove all listeners', { listeners });
      } else {
        delete this.listenerRegistration[id];
      }
      const ct = _.size(this.listenerRegistration);
      this.log[ct % 25 ? 'silly' : 'debug'](
        '[DeRegister Listeners] Current Registered Listeners: %d',
        ct,
      );
    }, 1);
  }

  /**
   * One of the main, builtin feathers events
   * @typedef  {"onUpdated" | "onCreated" | "onPatched" | "onRemoved"} StoreEvents
   * */

  /**
   * @method bubbleEvent
   * @param {StoreEvents} handler The event handler to call on the parent store
   * @param {Object} data The updated data
   * @param {Object} [params] Any params included in the event
   * ## Proposed Pattern
   * In the case of Payment Rules & Payment Bonuses, the on-updated hook
   * is fired against the "paymentBonuses" store, but the UI is displaying
   * contents of the "paymentRules" store (bonus is a special case of rule).
   *
   * This logic attempts to call the "parent" store to process the update event.
   */
  bubbleEvent(handler, ...rest) {
    if (!/\w+\/\w+/.test(this.serviceName)) {
      return;
    }

    let path = [this?.serviceName];
    let parent = null;

    try {
      path = this?.serviceName?.split('/') || [];
      path.pop();
      parent = path.pop();

      if (!_.isEmpty(parent) && parent !== this.serviceName) {
        dispatch(`${parent}.${handler}`, ...rest);
      }
    } catch (err) {
      storeLog.error('parent service did not have handler in place', err, {
        attemptedParent: _.last(path),
        service: this.serviceName,
      });
    }
  }

  onCreated = async (item) => {
    if (/paymentRules/i.test(this.serviceName)) {
      this.bubbleEvent('onCreated', item);
    }

    if (this.options.addToListOnCreateEvent) {
      return this.addItem(item);
    }
  };

  @action
  onUpdated = async (data) => {
    if (_.isEmpty(data)) {
      this.log.debug(`Empty Item in onUpdated for ${this.serviceName}`);
      return false;
    }

    if (/paymentRules/i.test(this.serviceName)) {
      this.bubbleEvent('onUpdated', data);
    }

    this.log.silly('Received %s Update: %O', this.serviceName, data);

    // Update Cached Item if already present. Ignore uncached item updates
    if (this.isInCache(data[this.idPath])) {
      this.setInCache(data[this.idPath], data);
    }

    const existing = _.find(this.list, { [this.idPath]: data[this.idPath] });
    if (existing && data.updatedAt > existing.updatedAt) {
      if (_.isBoolean(data.deleted) && data.deleted) {
        this.list.remove(existing);
      } else {
        set(existing, data);
      }
    } else if (existing && !existing.updatedAt) {
      this.log.warn('No updatedAt timestamp present in existing data');
    } else if (existing) {
      this.log.silly('Ignoring out of order update event');
    }

    if (
      _.get(this.selected, this.idPath) === data[this.idPath] &&
      data.updatedAt > this.selected.updatedAt
    ) {
      set(this.selected, data);
    }

    const runIsItemMatch = _.some(_.values(this.options));

    if (!_.isEmpty(this.query.query) && runIsItemMatch) {
      const isMatch = await this.isItemMatch(data);

      try {
        if (!isMatch) {
          if (this.options.removeFromListOnUpdateMismatch) {
            this.log.silly(
              `Updated ${this.title} does not match the current query; removing from list`,
              {
                query: this.query.query,
                user: data,
              },
            );

            runInAction(() =>
              _.remove(this.list, { [this.idPath]: data[this.idPath] }),
            );
          }

          if (
            this.options.clearSelectedOnUpdateMismatch &&
            this.selected?.[this.idPath] === data[this.idPath]
          ) {
            this.clearSelected();
          }
        }
      } catch (error) {
        this.log.error(
          `Error Checking If Item Matches for service: ${this.serviceName}`,
          error,
        );
      }
    }

    return true;
  };

  onPatched = this.onUpdated;

  onRemoved = (id, params) => {
    this.log.debug('Item %s was removed', id, params);

    if (/paymentRules/i.test(this.serviceName)) {
      this.bubbleEvent('onRemoved', id, params);
    }

    if (this.selected[this.idPath] === id) {
      this.clearSelected();
    }
    this.removeItem(id);
  };

  /**
   * ## onCompanyChange
   *
   * @description responds to a `login:company` event and, if the current store's
   * query has a `company` parameter set to a UUID, it will call the store's
   * `findByCompany` method with the new company's UUID.
   *
   * #### Example Scenarios
   * For each scenario, the given value is that of `this.query.query`
   * 1. `{ company: 'abc-123-def-456' }`
   *   - Will trigger logic if `companyField === 'company'`
   * 2. `{ companies: 'abc-123-def-456' }`
   *   - Will trigger logic if `companyField === 'companies'`
   * 3. `{ company: { $in: ['abc-123-def-456'] }}`
   *   - Will _NOT_ trigger logic
   * 4. `{ company: undefined }` or `{}`
   *   - Will _NOT_ trigger logic
   */
  onCompanyChange = ({ company }): void => {
    if (!this.companyField) {
      return;
    }

    const companyId = company?.uuid;
    const companyQuery = this.query.query?.[this.companyField];

    if (_.isString(companyQuery) && companyQuery !== companyId) {
      this.log
        .getChildLogger('onCompanyChanged')
        .info('Company Changed, re-loading new company results');
      this.emptyList();
      this.findByCompany({ company: companyId });
    }
  };

  // #endregion EVENTS

  // #region Search, Sort, and Filter

  /**
   * ## getQueryForSearch
   * Given a search string, constructs a query object that will query the collection (<T>)
   * against all fields specified in `this.searchFields`. If an optional `query` argument
   * is passed in, that query object will be used as the base-query in constructing the
   * returned query.
   *
   * If Atlas Search is enabled (via env:ENABLE_ATLAS_SEARCH) _and_ the current service is
   * enabled at the user level, returns an atlas-search compatible query to be run against
   * the server.
   *
   * @param {string} searchString
   * @param {Query<T>} query
   * @return {*}  {Query<T>}
   * @memberof BaseStore
   */
  getQueryForSearch(searchString: string, query?: Query): Query {
    const buildQuery: Query = _.cloneDeep(query ?? {});

    /* If the search string is a UUID, and the store is configured to use the API search,
     * we will use the $search query operator to search for the UUID in the specified field.
     */
    if (this.useApiSearch && isUUID(_.trim(searchString))) {
      buildQuery.$search = {
        fields: [this.idPath || 'uuid'],
        searchValue: _.trim(searchString),
      };
      return buildQuery;
    }

    const forceApiSearch = yn(localStorage.getItem('forceApiSearch'));

    const searchFields = forceApiSearch ? false : this.searchFields;
    if (this.useApiSearch || forceApiSearch) {
      buildQuery.$search = searchString;

      if (searchFields && !_.isEmpty(searchFields)) {
        buildQuery.$search = {
          fields: searchFields,
          searchValue: searchString,
        };
      }
      return buildQuery;
    }

    const authUser = dispatch('auth.getUser');
    const atlasSearchEnabledServices =
      authUser?.accountProps?.atlasSearchEnabledServices ?? [];

    // only enabled
    if (global.ENABLE_ATLAS_SEARCH && this.useAtlasSearch) {
      this.log.debug('Generating Atlas Search query');
      return this.getAtlasQuery(searchString, buildQuery);
    }

    return this.generateRegexSearch(searchString, query);
  }

  generateRegexSearch(searchString, query) {
    const buildQuery: Query = _.cloneDeep(query ?? {});

    this.log.silly('Generating standard regex style search query');
    if (_.isEmpty(searchString)) {
      if (this.searchFields.length > 1) {
        buildQuery.$or = undefined;
      } else if (this.searchFields.length === 1) {
        buildQuery[this.searchFields[0]] = undefined;
      }
    } else if (this.searchFields.length === 1) {
      buildQuery[this.searchFields[0]] = {
        $options: 'i',
        $regex: `.*${this.escapeRegExp(searchString)}.*`,
      };
    } else if (this.searchFields.length > 1) {
      const $or = [];
      _.each(this.searchFields, (field) => {
        $or.push({
          [field]: {
            $options: 'i',
            $regex: `.*${this.escapeRegExp(searchString)}.*`,
          },
        });
      });

      if (!_.isEmpty($or)) {
        buildQuery.$or = $or;
      }
    }

    return buildQuery;
  }

  getAtlasQuery(searchString: string, additionalQuery?: Query): Query {
    return {
      $client: { atlasSearchValue: searchString },
      ...additionalQuery,
    };
  }

  @action
  async search(
    searchValue: string = null,
    query: Query & { noQuery?: never } = {},
    opts?: IStoreFindOpts<T> & IStoreUpdateListOpts,
  ): Promise<Array<T> | Query> {
    this.searchValue = searchValue || '';

    const searchQuery = this.getQueryForSearch(searchValue, query);
    const results = await this.debounceFind(
      { query: { $skip: 0, ...searchQuery } },
      opts,
    );

    return results;
  }

  public sortBy(sortBy: string & keyof T, { noQuery }: { noQuery: true }): Promise<this['sort']>; // prettier-ignore
  public sortBy(sortBy: string & keyof T, { noQuery }: { noQuery?: false | never }): Promise<this['find']>; // prettier-ignore
  @action
  public sortBy(
    sortBy?: string & keyof T,
    { noQuery }: { noQuery?: boolean } = {},
  ): Promise<this['find'] | this['sort']> {
    if (_.isEmpty(sortBy)) {
      this.sort = undefined;
    } else if (
      this.sort &&
      _.isString(this.sort) &&
      this.sort.replace(/[^a-zA-Z.]/gi, '') ===
        sortBy.replace(/[^a-zA-Z.]/gi, '')
    ) {
      // TODO: Is this sort syntax still supported/allowed?
      if (/^-/.test(this.sort)) {
        this.sort = sortBy.replace(/[^a-zA-Z.]/gi, '');
      } else {
        this.sort = `-${sortBy}`;
      }
    } else {
      this.sort = sortBy;
    }

    if (noQuery) {
      return Promise.resolve(this.sort);
    }

    return this.find();
  }

  getSort(): Record<keyof T, 1 | -1> | keyof T | `-${string & keyof T}` {
    return this.sort;
  }

  @action
  filterBy(filter): Promise<Array<T>> {
    this.filter = filter;

    this.log.error(
      'NotImplemented: BaseStore "FilterBy" has no default Impementation',
    );

    return this.find({}) as Promise<Array<T>>;
  }

  // #endregion

  // #region Internal Cache implemenation

  @observable
  cacheSize = 0;

  getLocal: ((id: string, query?: Query) => T) & MemoizedFunction;

  @computed
  get cachedItems(): MapCache | Record<T['uuid'], T> {
    return (
      ('cache' in this.getLocal && (this.getLocal as MemoizedFunction).cache) ??
      ({} as Record<T['uuid'], T>)
    );
  }

  _getLocal(id, query) {
    if (this.cacheSize) {
      this.log.debug(`Returning Uncached Getter for ${this.title} "${id}"`);
    }
    return this.get(id, { query, reload: true, select: false });
  }

  getSync(id) {
    if (this.isInCache(id)) {
      return this.getLocal(id);
    }

    this.get(id);

    return id;
  }

  logCache = (): void => {
    storelog.debug('GetLocal Cache: ', { cache: this.getLocal.cache });
  };

  cacheList(list = this.list) {
    if (global.TYPE === 'SERVER') return;
    if (this.cacheSize <= 0) return;

    _.map(
      list,
      (l) =>
        !this.isInCache(l[this.idPath]) &&
        this.setInCache(l[this.idPath], _.extend(l, { cachedAt: new Date() })),
    );

    this.cleanCache();
  }

  setInCache(id, val): void {
    if (!this.cacheSize) {
      return;
    }
    if (global.TYPE === 'SERVER') return;
    if (!id) {
      return;
    }

    if (isCache(this.cachedItems)) {
      // if (this.isInCache(id)) this.cachedItems.delete(id);

      if (_.isEmpty(val)) {
        return;
      }

      this.cachedItems.set(id, _.extend(val, { cachedAt: new Date() }));
    } else if (this.isInCache(id)) {
      this.log.warn('Unknown Cache Behavior');
      if (_.isEmpty(val)) {
        delete this.cachedItems[id];
        return;
      }
      this.cachedItems[id] = _.extend(val, { cachedAt: new Date() });
    }

    this.cleanCache();
  }

  isInCache(id): boolean {
    if (!this.cacheSize) {
      return false;
    }
    if (_.isFunction(this.cachedItems.has)) {
      return this.cachedItems.has(id);
    }

    return _.has(this.cachedItems, id);
  }

  currentCacheSize(): number {
    if (!this.cacheSize) {
      return 0;
    }
    if (_.has(this.cachedItems, 'size')) return this.cachedItems.size;

    return _.size(this.cachedItems);
  }

  maxAge = { hours: 1 };

  minAge = { minutes: 1 };

  cleanCache(trimPercentage = 0.8) {
    if (!this.cacheSize) {
      return;
    }
    const trimTarget = this.cacheSize * trimPercentage;
    if (this.currentCacheSize() < this.cacheSize) {
      return;
    }

    _(this.cachedItems.values || _.values(this.cachedItems))
      .sortBy(['cachedAt'])
      .forEach((c) => {
        if (moment().subtract(this.minAge).isBefore(c.cachedAt)) {
          this.log.warn('[CACHE] Keeping recent item');
          return false;
        }

        this.removeFromCache(c[this.idPath], c);

        const isExpired = moment().subtract(this.maxAge).isAfter(c.cachedAt);
        const hasMetTaget = this.currentCacheSize() <= trimTarget;
        return isExpired || !hasMetTaget;
      });
  }

  removeFromCache(id: T['uuid'], item?: T) {
    this.log.warn('[CACHE] Removing item from cache', { id, item });
    if (this.cachedItems.delete) this.cachedItems.delete(id);
    if (this.cachedItems.remove) this.cachedItems.remove(id);
  }

  // #endregion Internal Cache Implementation

  // #region CASL Support Utilities

  @computed
  get modelName() {
    const retval =
      this._modelName ||
      (/ies$/.test(this.serviceName)
        ? this.serviceName.replace(/ies$/, 'y')
        : this.serviceName.replace(/s$/, ''));

    this.log.silly('ModelName computed as: "%s"', retval, {
      _modelName: this._modelName,
      serviceName: this.serviceName,
    });

    return retval;
  }

  /**
   * getModelName(<instance?>)
   *
   * @param {object} instance an object instance to be checked for model name
   *
   * @description By default, this method will return the `this.modelName` property
   * from the store. However, it may be overridden by subclasses in order to provide
   * specific modelName values for differentiated models. See `things.js` for an example
   * on how this can be used.
   */
  getModelName(instance?: T): string;
  getModelName() {
    return this.modelName;
  }
  // #endregion CASL Support Utilities

  // #region Util Methods

  /**
   * #### escapeRegExp
   * When querying the server with user input, we want to escape any of the main
   * regex characters so that mongo doesn't interpret the raw query string as a
   * regex. Best example of this would be searching for a phone number; Typing in
   * `+1650...` would result in an (invalid) regex of `.*+1650.*`, which isn't what
   * we want.
   *
   * TODO: Why not just use `_.escapeRegExp` as we have done elsewhere?
   */
  private escapeRegExp = (value = '') =>
    value.replace?.(
      /[-\\[\]\\/\\{\\}\\(\\)\\*\\+\\?\\.\\\\^\\$\\|]/g,
      '\\$&',
    ) ?? value;

  /**
   * ### isItemMatch
   * Given the specified item, does a "mingo" query locally to determine if the item matches
   * the store's current query criteria (ie: If `store.find` was called again, would this item
   * be included in the response?).
   *
   * If, for some reason (query parameter not handled by mingo), an api service call will be made
   * with the limit set to 0 (ie: `getCount`), and the query restricted to the item's specific UUID.
   *
   * @param {T extends BaseItem} item
   * @return {boolean}
   * @memberof BaseStore
   */
  async isItemMatch(item) {
    const log = this.log.getChildLogger('isItemMatch');
    /** Note to future self: without the `cloneDeep`, the value of `query.uuid` is
     * 'reference equal' to `this.query.query.uuid` when the latter is an object. The
     * result is that the call to `_.defaults` below actually modifies the value of
     * `this.query.query.uuid`, thus breaking the behavior of the store's `find` and `list`.
     *
     * So, now that _this_ issue is solved, how many other places in the codebase,
     * where this rough pattern is reproduced, could possibly be affected?
     */

    const query = _(this.query.query)
      // TODO: standardize the "remove all feathers query opts" array
      .omit(['$skip', '$limit', '$client', '$sort', '$select'])
      .cloneDeep();
    let isMatch = false;

    try {
      // Mingo doesn't handle the SSM $search query parameter
      if (!_.isEmpty(query.$search)) {
        const searchString = query.$search?.searchValue ?? query.$search;
        if (searchString) {
          _.extend(query, this.generateRegexSearch(searchString, {}));
        }

        delete query.$search;
      }

      try {
        isMatch = new mingo.Query(query).test(item);
      } catch (err) {
        if (!/(near|\$search)/i.test(err.message)) {
          log.error('[isItemMatch] MingoQuery failed', err, {
            extra: { query },
          });
        }

        const res = await service(this.serviceName)
          .find({
            query: {
              $limit: 0,
              ...query,
              [this.idPath]: item[this.idPath],
            },
          })
          .catch((err2) => {
            // Default to "item does not match" in case of second error
            log.error('backup is item match failed', err2);
            return null;
          });

        isMatch = !!res?.total;

        log.debug(
          'Mingo Failed; fallback DB query returned match for ' +
            item[this.idPath],
          {
            extra: { isMatch, query },
          },
        );
      }
    } catch (error) {
      this.logAndThrow(error, { method: 'isItemMatch', query });
    }

    return isMatch;
  }

  mergeQueryObject(query) {
    return _.mergeWith(this.query, query, (baseVal, srcVal) => {
      if (_.isArray(srcVal)) {
        return srcVal;
      }

      return undefined;
    });
  }

  @action
  private setLastQuery(q: Query) {
    this.lastQuery = q ?? null;
  }

  logAndThrow(
    err: Error & { errors?: Array<string | Error> },
    opts?: {
      data?: unknown;
      method?: string;
      params?: unknown;
      query?: unknown;
    },
  ): never {
    const prefix = !_.isEmpty(opts?.method) ? `[${opts?.method}] ` : '';
    const meta = { error: err, query: this.query, ...(opts ?? {}) };
    if (!_.isEmpty(err.errors)) {
      this.log.error(
        `${prefix}Caught Error: ${err.message}, ${_.map(
          err.errors,
          'message',
        ).join('\n')}`,
        meta,
      );
    } else {
      this.log.error(prefix + err.message, meta);
    }
    throw err;
  }

  /**
   * ### retrieve
   * Returns the value of the specified key.
   * @deprecated Please use direct prop access instead via the useStores hooks
   */
  retrieve(key) {
    return _.get(this, key);
  }
  // #endregion
}

function isCache(arg: unknown | MapCache): arg is MapCache {
  return _.isFunction((arg as MapCache)?.has);
}
