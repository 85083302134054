import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import { action } from 'mobx';
import _ from 'lodash';
import request from 'superagent-bluebird-promise';

import { service } from '#/shared/app';

import BaseStore from './_baseStore';

export type IS3 = IBaseItem & Record<string, unknown>;

export default class S3Store extends BaseStore<IS3> {
  constructor() {
    const baseItem = {
      bucketName: '',
    };
    super({ baseItem, serviceName: 's3' });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  sign({ params }) {
    return service(this.serviceName)
      .get(null, params)
      .catch((err) => {
        this.log.error('Error Getting Signed Url', err);
        throw err;
      });
  }

  @action.bound async upload({
    id,
    file,
    fileType = file.type,
    bucketService = 'DEV',
  }) {
    this.isLoading = true;
    try {
      const params = {
        query: {
          action: 'sign',
          bucketService,
          contentType: file.type,
          filename: id,
        },
      };

      const response = await this.sign({ params });

      const imageURL = response.signedUrl.split('?')[0];

      await request
        .put(response.signedUrl)
        .set({ 'Content-Type': fileType })
        .send(file);

      return this.uploadSuccess({ data: { url: imageURL } });
    } catch (error) {
      return this.uploadFail(error);
    }
  }

  @action.bound uploadSuccess(response) {
    this.isLoading = false;
    return Promise.resolve(response);
  }

  @action.bound uploadFail(error) {
    this.isLoading = false;
    return Promise.reject(error);
  }
}
