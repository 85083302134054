import type {
  ICompany,
  IInShiftTask,
  IInShiftTaskItem,
  IInShiftTaskResult,
  IShift,
} from '@shiftsmartinc/shiftsmart-types';

import { action, computed, observable } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { getStore } from '#/shared/getStores';
import { getChildLogger } from '#/shared/utils/client.logger';
import {
  InShiftTaskForm,
  init as initInShiftTaskForm,
} from '#/shared/forms/inShiftTask';

const log = getChildLogger('ui.inShiftTasks');

export default class InShiftTasks {
  // Filters used on the InShiftTasksContainer page
  @observable start = moment().startOf('day');
  @observable calendarVisible = false;
  @observable filterType: 'assigned' | 'template' = 'template';

  // Form in the InShiftTaskCreateEditModal
  @observable inShiftTaskForm: InShiftTaskForm = initInShiftTaskForm({
    values: { type: 'task' },
  });

  // These control the more complex fields in the InShiftTaskCreateEditModal
  @observable checkListValuesInput = [];
  @observable cloneRulesInput = [];
  @observable relatedTasksInput = [];

  // Modal isOpen variables used across inShiftTasks
  @observable isCreateEditTaskModalOpen = false;
  @observable isCreateTemplateModalOpen = false;
  @observable isDeleteTemplateModalOpen = false;
  @observable isDeleteConfirmationModalOpen = false;
  @observable isUnableToCompleteReasonsModalOpen = false;

  // isSaving / loading variables
  @observable isSavingTask = false;
  @observable isSavingTemplate = false;

  // Some miscellaneous state variables
  @observable newTemplateName = ''; // Set when filling out the input in for creating a new template set
  @observable selectedInShiftTaskTemplateId = ''; // Set when clicking on a template set in InShiftTasksContainer page or the dropdown in Shift Details -> Tasks
  @observable skuToNameMap = {}; // Temporary map we used for finding pretty name for a sku (PepsiCo only)

  // Hardcoded list for select options for checkListValue types on the inShiftTask model
  @observable optionsForCheckListInputType = [
    {
      key: 1,
      text: 'Number',
      value: 'number',
    },
    {
      key: 2,
      text: 'Text',
      value: 'text',
    },
    {
      key: 3,
      text: 'Toggle',
      value: 'toggle',
    },
  ];

  // Hardcoded list for select options for inputType on the inShiftTask model
  @observable optionsForInputType = [
    {
      key: 1,
      text: 'Checklist',
      value: 'checkList',
    },
    {
      key: 2,
      text: 'Picklist',
      value: 'pickList',
    },
    {
      key: 3,
      text: 'Picklist And Counter',
      value: 'pickListAndCounter',
    },
    {
      key: 4,
      text: 'Counter',
      value: 'number',
    },
  ];

  // Functions used for filters on the InShiftTasksContainer main page
  @computed
  get end() {
    return moment(this.start).add(1, 'day').startOf('day');
  }

  @action
  setRange(day) {
    this.start = moment(day).startOf('day');
    this.setCalendarVisible(false);
  }

  @action.bound
  setCalendarVisible(state: boolean) {
    this.calendarVisible = state;
  }

  @action.bound
  setFilterType(state: 'assigned' | 'template') {
    this.filterType = state;
  }

  // Functions around setting checkListValues in the InShiftTaskCreateEditModal
  @action.bound
  async addCheckListValue(checkListItem) {
    this.checkListValuesInput.push(checkListItem);
    this.inShiftTaskForm.$('checkListItemTitle').clear();
  }

  @action.bound
  async moveCheckListValue(index: number, direction: 1 | -1) {
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= this.checkListValuesInput.length) {
      return;
    } else {
      [this.checkListValuesInput[index], this.checkListValuesInput[newIndex]] =
        [this.checkListValuesInput[newIndex], this.checkListValuesInput[index]];
    }
  }

  @action.bound
  removeCheckListValue(index: number) {
    this.checkListValuesInput.splice(index, 1);
  }

  // Functions around setting cloneRules in the InShiftTaskCreateEditModal
  @action.bound
  async addCloneRulesValue(cloneRuleItem) {
    this.cloneRulesInput.push(cloneRuleItem);
    this.inShiftTaskForm.$('cloneRulesIdToClone').clear();
    this.inShiftTaskForm.$('cloneRulesRedirectTo').clear();
    this.inShiftTaskForm.$('cloneRulesupdateRelatedTasks').clear();
  }

  @action.bound
  removeCloneRulesValue(index: number) {
    this.cloneRulesInput.splice(index, 1);
  }

  // Functions around setting relatedTasks in the InShiftTaskCreateEditModal
  @action.bound
  async addRelatedTasksValue(relatedTaskItem) {
    this.relatedTasksInput.push(relatedTaskItem);
    this.inShiftTaskForm.$('relatedTasksTaskId').clear();
    this.inShiftTaskForm.$('relatedTasksIncludeDenominator').clear();
  }

  @action.bound
  removeRelatedTasksValue(index: number) {
    this.relatedTasksInput.splice(index, 1);
  }

  // Functions that handle closing / opening of the InShiftTaskCreateEditModal
  @action.bound
  openCreateEditTaskModal(task?: IInShiftTaskItem) {
    if (!_.isNil(task)) {
      this.updateEditModal({ task });
    } else {
      this.inShiftTaskForm = initInShiftTaskForm({
        values: { type: 'task' },
      });
    }
    this.isCreateEditTaskModalOpen = true;
  }

  @action.bound
  closeCreateEditTaskModal() {
    this.isCreateEditTaskModalOpen = false;
  }

  // Functions that handle closing / opening of the GenericModal used to create templates
  @action.bound
  openTemplateModal() {
    this.isCreateTemplateModalOpen = true;
  }

  @action.bound
  closeTemplateModal() {
    this.isCreateTemplateModalOpen = false;
    this.setNewTemplateName('');
  }

  // Functions that handle closing / opening of the GenericModal used to delete templates
  @action.bound
  openDeleteTemplateModal() {
    this.isDeleteTemplateModalOpen = true;
  }

  @action.bound
  closeDeleteTemplateModal() {
    this.isDeleteTemplateModalOpen = false;
  }

  // Functions that handle closing / opening of the GenericModal used to delete tasks
  @action.bound
  openDeleteConfirmationModal() {
    this.isDeleteConfirmationModalOpen = true;
  }

  @action.bound
  closeDeleteConfirmationModal() {
    this.isDeleteConfirmationModalOpen = false;
  }

  // Functions that handle closing / opening of the InShiftTaskUnableToCompleteReasonsModal
  @action.bound
  openUnableToCompleteReasonsModal() {
    this.isUnableToCompleteReasonsModalOpen = true;
  }

  @action.bound
  closeUnableToCompleteReasonsModal() {
    this.isUnableToCompleteReasonsModalOpen = false;
  }

  // Functions that control the isSaving / loading variables
  @action.bound
  setIsSavingTask(state: boolean) {
    this.isSavingTask = state;
  }

  @action.bound
  setIsSavingTemplate(state: boolean) {
    this.isSavingTemplate = state;
  }

  // Function that control setting the miscellaneous state variables
  @action.bound
  setNewTemplateName(name: string) {
    this.newTemplateName = name;
  }

  @action.bound
  setSelectedTemplateId(templateId: string) {
    this.selectedInShiftTaskTemplateId = templateId;
  }

  @action.bound
  setSkutoNameMap(newMap: any) {
    this.skuToNameMap = newMap;
  }

  /**
   * Performs a patch of inShiftTaskTemplate.settings for the unableToCompleteReasons field
   * @param skipReasons an array of string that match the settings.unableToCompleteReasons in the InShiftTaskTemplate model
   */
  @action.bound
  async patchTemplateUnableToCompleteReasons(skipReasons: string[]) {
    try {
      await getStore('inShiftTaskTemplates').update({
        data: { settings: { unableToCompleteReasons: skipReasons } },
        id: this.selectedInShiftTaskTemplateId,
      });
      getStore('ui.snackBar').open(
        'Successfully updated unable to complete reasons.',
      );
    } catch (error) {
      log.error('Error updating unable to complete reasons: ', error, {
        extra: {
          templateId: this.selectedInShiftTaskTemplateId,
        },
      });
      getStore('ui.snackBar').error(
        'Skip unable to complete reasons could not be updated.',
      );
    }
  }

  /**
   * Creates an inShiftTaskTemplate template with a title
   * @param companyId
   * @param inShiftTasks When this is passed in, we will create a set of template tasks copied from this array
   */
  @action.bound
  async addNewTaskTemplate(
    companyId: ICompany['uuid'],
    inShiftTasks: IInShiftTask[] = null,
  ) {
    this.setIsSavingTemplate(true);
    try {
      const template = await getStore('inShiftTaskTemplates').create({
        data: { companyId, title: this.newTemplateName },
      });
      if (inShiftTasks) {
        await this.createTemplateItemSet(inShiftTasks, template.uuid);
      }
      await this.loadInShiftTaskTemplates(companyId);
      this.closeTemplateModal();
      getStore('ui.snackBar').open('Successfully created new template set.');
    } catch (error) {
      log.error('Error creating new in shift task template: ', error, {
        extra: {
          companyId,
        },
      });
      getStore('ui.snackBar').error('Could not create new template set.');
    } finally {
      this.setIsSavingTemplate(false);
    }
  }

  /**
   * Called in a lot of places. Loads in all inShiftTaskTemplate objects associated with a company
   * @param companyId
   * @param resetSelectedTemplate when true, will set selectedTemplateId to null.
   */
  @action.bound
  async loadInShiftTaskTemplates(
    companyId: ICompany['uuid'],
    resetSelectedTemplate = true,
  ) {
    const query = {
      $client: {
        getTaskCount: true,
      },
      companyId: companyId,
      isDeleted: { $ne: true },
    };
    try {
      await getStore('inShiftTaskTemplates').find(query, {
        clear: true,
      });
      if (resetSelectedTemplate) this.setSelectedTemplateId('');
    } catch (error) {
      log.error('Error fetching available in shift task templates: ', error, {
        extra: {
          companyId,
        },
      });
    }
  }

  /**
   * Bulk deletes a set of tasks. Right now used to bulk delete all of a shift's tasks
   * @param taskIds list of task ids to delete
   */
  @action.bound
  async bulkDeleteTasks(taskIds: string[]) {
    try {
      await getStore('inShiftTasks').removeMany({ ids: taskIds });
      await getStore('ui.shiftDetails').loadInShiftTasks();
      this.closeDeleteConfirmationModal();
      getStore('ui.snackBar').open('Successfully deleted tasks.');
    } catch (error) {
      log.error('Error bulk deleting in Shift Tasks: ', error, {
        extra: {
          taskIds,
        },
      });
      getStore('ui.snackBar').error('Tasks could not be deleted.');
    }
  }

  /**
   * Deletes a singular task. Redirects to the Shift Details page if it is an assigned task
   * @param taskId
   * @param shiftId
   */
  @action.bound
  async deleteTask(taskId: string, shiftId?: string) {
    try {
      await getStore('inShiftTasks').remove(taskId);
      this.closeDeleteConfirmationModal();
      if (shiftId) {
        await getStore('routing').goto({
          route: `/shifts/${shiftId}`,
        });
      } else {
        await getStore('routing').goto({
          route: '/inshifttasks',
        });
      }
      getStore('ui.snackBar').open('Successfully deleted task.');
    } catch (error) {
      log.error('Error deleting in Shift Task: ', error, {
        extra: {
          taskId,
        },
      });
      getStore('ui.snackBar').error('Task could not be deleted.');
    }
  }

  @action.bound
  clearForm() {
    this.inShiftTaskForm.clear();
    this.checkListValuesInput = [];
    this.cloneRulesInput = [];
    this.relatedTasksInput = [];
  }

  /**
   * If creating from scratch, title, description, and category must be set
   * If creating from a template, a template must be selected
   */
  async validateForm() {
    let isValid = true;
    ['title', 'description', 'category', 'type'].forEach((field) => {
      if (!this.inShiftTaskForm.$(field).value) {
        getStore('ui.snackBar').error(
          `Please fill in the ${_.upperCase(field)}`,
        );
        isValid = false;
      }
    });
    if (this.inShiftTaskForm.$('type').value === 'task') {
      ['expectedTimeInMinutes', 'inputType'].forEach((field) => {
        if (!this.inShiftTaskForm.$(field).value) {
          getStore('ui.snackBar').error(
            `Please fill in the ${_.upperCase(field)}`,
          );
          isValid = false;
        }
      });
    }
    return isValid;
  }

  /**
   * Handles loading initial values in for editing
   * @param task
   */
  @action.bound
  updateEditModal({ task }: { task: IInShiftTaskItem }) {
    this.inShiftTaskForm = initInShiftTaskForm({
      values: {
        category: task.category,
        checkListItemTitle: '',
        checkListItemType: '',
        cloneRulesIdToClone: '',
        cloneRulesRedirectTo: false,
        cloneRulesupdateRelatedTasks: false,
        customCopy: task.customCopy,
        description: task.description,
        evaluationCriteria: task.evaluationCriteria,
        expectedTimeInMinutes: task.expectedTimeInMinutes,
        imageURL: task.imageURL,
        inputType: task.inputType,
        isCritical: task.isCritical,
        postImageUploadRequired: task.postImageUploadRequired,
        preImageUploadRequired: task.preImageUploadRequired,
        relatedTasksIncludeDenominator: false,
        sequenceId: task.sequenceId,
        shiftId: task.shiftId,
        taskContainerId: task?.taskContainerId,
        title: task.title,
        type: task.__t ?? 'task',
        videoURL: task.videoURL,
      },
    });
    this.checkListValuesInput = task.checkListValues || [];
    this.cloneRulesInput = task.cloneRules || [];
    this.relatedTasksInput = task.relatedTasks || [];
  }

  /**
   * This allows a user to take a set of inShiftTasks assigned to a shift and create a template set from them.
   * Only should be called by addNewTaskTemplate, which already creates a inShiftTasTemplate
   * @param inShiftTasks
   * @param templateId
   */
  @action.bound
  async createTemplateItemSet(
    inShiftTasks: IInShiftTask[],
    templateId: string,
  ) {
    try {
      await inShiftTasks.forEach(async (task: IInShiftTask) => {
        const newTask = _.cloneDeep(task);
        newTask.templateId = templateId;
        newTask.uuid = uuidv4();

        delete newTask['_id'];
        delete newTask['shiftId'];

        delete newTask['createdAt'];
        delete newTask['updatedAt'];
        await getStore('inShiftTasks').create({ data: { ...newTask } });
      });
    } catch (error) {
      log.error(
        'Error creating In Shift Task template set from current templates:',
        error,
      );
    }
  }

  /**
   * Populates a shift with tasks from the selectedInShiftTaskTemplateId
   * @param companyId
   * @param shiftId
   */
  @action.bound
  async createTasksFromTemplate({
    companyId,
    shiftId,
  }: {
    companyId: ICompany['uuid'];
    shiftId?: IShift['uuid'];
  }) {
    try {
      await getStore('shifts').update({
        data: { inShiftTasksTemplateId: this.selectedInShiftTaskTemplateId },
        id: shiftId,
        query: {
          $client: {
            createInShiftTasksFromTemplateId:
              this.selectedInShiftTaskTemplateId,
          },
        },
      });
      await getStore('ui.shiftDetails').loadInShiftTasks();

      getStore('ui.snackBar').open('Successfully created In Shift Tasks');
      this.closeCreateEditTaskModal();
      this.clearForm();
    } catch (error) {
      getStore('ui.snackBar').error('In Shift Tasks could not be generated.');
      log.error('Error creating Shift Tasks from company templates: ', error, {
        extra: {
          companyId,
        },
      });
    }
    return;
  }

  /**
   * Patches an inShiftTaskResult with any data.
   * Right now, this is used to toggle a preImage or postImage to be manually accepted or not.
   * @param taskResultId
   * @param updateData can be any partial of an inShiftTaskResult
   */
  @action.bound
  async updateTaskResult(
    taskResultId: string,
    updateData: Partial<IInShiftTaskResult>,
  ) {
    this.setIsSavingTask(true);
    try {
      const data = {
        data: {
          ...updateData,
        },
        id: taskResultId,
      };
      await getStore('inShiftTaskResults').update(data);
    } catch (error) {
      log.error('Error updating task result: ', error, {
        extra: {
          taskResultId,
        },
      });
    } finally {
      this.setIsSavingTask(false);
    }
  }

  /**
   * Deletes the selected inShiftTaskTemplate based off of selectedInShiftTaskTemplateId
   * This does not delete the inShiftTasks associated with that template
   */
  @action.bound
  async deleteTaskTemplate({ companyId }: { companyId: ICompany['uuid'] }) {
    try {
      if (!this.selectedInShiftTaskTemplateId) return;
      await getStore('inShiftTaskTemplates').remove(
        this.selectedInShiftTaskTemplateId,
      );
      await this.loadInShiftTaskTemplates(companyId);
      this.closeDeleteTemplateModal();
      getStore('ui.snackBar').open('Successfully deleted Template Set.');
    } catch (error) {
      log.error('Error deleting in shift task template: ', error, {
        extra: {
          template: this.selectedInShiftTaskTemplateId,
        },
      });
      getStore('ui.snackBar').error('Template could not be deleted.');
    }
  }

  /**
   * Core function around creating and saving inShiftTasks
   * @param companyId
   * @param shiftId When present, we're creating a task for a shift, otherwise, it's for a template
   * @param taskId When present, we are editing
   */
  @action.bound
  async saveInShiftTask({
    companyId,
    taskId,
    shiftId,
  }: {
    companyId: ICompany['uuid'];
    shiftId?: IShift['uuid'];
    taskId?: IInShiftTaskItem['uuid'];
  }) {
    if (!(await this.validateForm())) {
      return;
    }
    this.setIsSavingTask(true);

    const {
      category,
      title,
      description,
      imageURL,
      videoURL,
      postImageUploadRequired,
      preImageUploadRequired,
      type,
      expectedTimeInMinutes,
      evaluationCriteria,
      inputType,
      isCritical,
      taskContainerId,
      sequenceId,
      customCopy,
    } = this.inShiftTaskForm.values();
    const customCopyWithoutEmptyValues = _.pickBy(customCopy, (x) => {
      return !!x;
    });
    const data = {
      category,
      checkListValues: this.checkListValuesInput,
      cloneRules: this.cloneRulesInput,
      companyId,
      customCopy: customCopyWithoutEmptyValues,
      description,
      evaluationCriteria,
      expectedTimeInMinutes,
      imageURL,
      inputType,
      isCritical,
      postImageUploadRequired,
      preImageUploadRequired,
      relatedTasks: this.relatedTasksInput,
      taskContainerId,
      title,
      videoURL,
    };

    try {
      // If taskId is present, we are editing an existing task
      if (taskId) {
        // We can't include sequenceId in the first data declaration because it's used for createData as well and messes with auto-sequencing
        const updateData = sequenceId
          ? {
              data: { ...data, sequenceId: sequenceId },
              id: taskId,
            }
          : {
              data,
              id: taskId,
            };
        if (type === 'task') {
          await getStore('inShiftTaskItems').update(updateData);
        } else {
          await getStore('inShiftTaskContainers').update(updateData);
        }
      } else {
        // If the user filled out a sequenceId, we want to use that. otherwise, we enable auto-sequencing
        const createData = _.isNil(shiftId)
          ? {
              data: sequenceId
                ? {
                    ...data,
                    sequenceId: sequenceId,
                    templateId: this.selectedInShiftTaskTemplateId,
                  }
                : { ...data, templateId: this.selectedInShiftTaskTemplateId },
              params: {
                query: { $client: { addSequenceId: !sequenceId } },
              },
            }
          : {
              data: sequenceId
                ? { ...data, sequenceId: sequenceId, shiftId }
                : { ...data, shiftId },
              params: {
                query: { $client: { addSequenceId: !sequenceId } },
              },
            };
        if (type === 'task') {
          await getStore('inShiftTaskItems').create(createData);
        } else {
          await getStore('inShiftTaskContainers').create(createData);
        }
        // If we're creating for a shift, need to reload those shifts in the ShiftDetails store
        if (!_.isNil(shiftId)) {
          await getStore('ui.shiftDetails').loadInShiftTasks();
        } else this.loadInShiftTaskTemplates(companyId, false);
      }

      getStore('ui.snackBar').open('Successfully created new In-Shift Task');
      this.closeCreateEditTaskModal();
      this.clearForm();
    } catch (error) {
      log.error('Error creating in Shift Task: ', error, {
        extra: {
          companyId,
        },
      });
      getStore('ui.snackBar').error('In Shift Task could not be saved.');
    } finally {
      this.setIsSavingTask(false);
    }

    return;
  }
}
