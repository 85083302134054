import { action, computed, observable } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class PartnerTagModal {
  log = getChildLogger('ui.PartnerTagModal');

  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  worker = {};

  @observable
  workers = [];

  @observable
  selectedTags = [];

  @observable
  company = {};

  @observable
  certType = 'qual';

  @computed
  get storeName() {
    switch (this.certType) {
      case 'industry':
        return 'industries';
      default:
        return `${this.certType}s`;
    }
  }

  @computed
  get tagNamePlural() {
    switch (this.certType) {
      case 'qual':
        return 'Tags';
      case 'industry':
        return 'Industries';
      default:
        return `${_.capitalize(this.certType)}s`;
    }
  }

  @computed
  get tagName() {
    switch (this.certType) {
      case 'qual':
        return 'Tag';
      default:
        return _.capitalize(this.certType);
    }
  }

  @action
  setup({
    worker = {},
    workers = [],
    company,
    certType = 'qual',
    open = this.isOpen,
  }) {
    this.worker = worker;
    this.workers = workers;
    this.company = company;
    this.certType = certType;

    this.selectedTags = _.get(worker, 'certs', [])
      .filter((c) =>
        c && _.get(company, 'uuid') ? c.companies.includes(company.uuid) : true,
      )
      .filter((c) => c && c.certType === this.certType);

    this.isOpen = open;
  }

  @action
  clear({ open = false } = {}) {
    this.worker = {};
    this.workers = [];
    this.company = {};
    this.certType = 'qual';

    this.isOpen = open;
  }

  @action
  toggleLoading(loading) {
    this.isLoading = loading;
  }

  @computed
  get addedTags() {
    let existingTags = [];
    if (this.worker) {
      const workerCerts = _.get(this.worker, 'certs', []);
      existingTags = workerCerts
        .filter((c) => c && c.companies.includes(this.company.uuid))
        .filter((c) => c && c.certType === this.certType);
    }

    return _.differenceBy(this.selectedTags, existingTags, 'uuid');
  }

  @computed
  get removedTags() {
    let existingTags = [];
    if (this.worker) {
      const workerCerts = _.get(this.worker, 'certs', []);
      existingTags = workerCerts
        .filter((c) => c && c.companies.includes(this.company.uuid))
        .filter((c) => c && c.certType === this.certType);
    }

    return _.differenceBy(existingTags, this.selectedTags, 'uuid');
  }

  @action.bound updateSelectedTags(tag) {
    const isSelected = this.selectedTags.find(
      (selectedTag) => selectedTag.uuid === tag.uuid,
    );
    if (isSelected) {
      this.selectedTags = this.selectedTags.filter(
        (selectedTag) => selectedTag.uuid !== tag.uuid,
      );
    } else {
      this.selectedTags.push(tag);
    }
  }

  @action.bound
  async bulkAddTags(users) {
    const log = this.log.getChildLogger('bulkAddTags');
    this.toggleLoading(true);
    try {
      await Promise.map(users, async (user) => {
        try {
          const userTags = _.get(user, 'certs', [])
            .filter((c) => c && c.companies.includes(this.company.uuid))
            .filter((c) => c && c.certType === this.certType);
          const tagsToAdd = _.differenceBy(this.addedTags, userTags, 'uuid');

          /**
           * TODO: EP-728 Use standardized cert manipulation logic as seen in `workers.addCert`
           * and `workers.removeCert`. Logic will need to be updated to handle arrays
           *  */
          await dispatch('workers.update', {
            data: { $push: { certs: { $each: tagsToAdd } } },
            id: user.uuid,
          });
        } catch (err) {
          log.error('Failed to apply tag to user: ', err);
        }
      });
      return this.toggleLoading(false);
    } catch (error) {
      log.error('Error Bulk Applying Tags from ParterTagModal: ', error);
      this.toggleLoading(false);
      return Promise.reject(error);
    }
  }
}
