import type { IReferralBonus } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export default class ReferralBonusesStore extends BaseStore<IReferralBonus> {
  constructor() {
    super({
      baseItem: ReferralBonusesStore.BASE_ITEM,
      serviceName: 'paymentRules/referralBonuses',
    });
    return this;
  }

  static BASE_ITEM: IReferralBonus = {
    allowPayoutForPartners: true,
    amount: 0,
    companies: [],
    description: '',
    isEnabled: true,
    maxPaidReferrals: 10,
    onlyEnabledForSavedLocations: false,
    referreeAmount: 0,
    savedLocations: [],
    timeToComplete: 0,
    uuid: '',
  };
}
