export const redundancyConstant = {
  'evfr-redundancy': 'Auto Redundancy',
  manualAdminRedundancy: 'Manual Redundancy',
  newPartnerRedundancy: 'New Partner Redundancy',
};

export const backupConstant = {
  autoBackupRedundancy: 'Auto Backup',
  manualBackupRedundancy: 'Manual Backup',
};

export const shadowConstant = {
  autoShadowRedundancy: 'Auto Shadow',
  manualShadowRedundancy: 'Manual Shadow',
};
