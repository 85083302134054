import type {
  IRecurringSchedule,
  IShiftTemplate,
} from '@shiftsmartinc/shiftsmart-types';
import type { RecurringScheduleModalProps } from '#/shared/components/recurringSchedules/RecurringScheduleForm';

import { action, IObservableArray, observable, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import uuid from 'uuid';

import { init as initRecurringScheduleForm } from '#/shared/forms/recurringSchedule';
import { getChildLogger } from '#/shared/utils/client.logger';

const defaultFormErrors = {
  shiftTemplateError: '',
  title: '',
};

const createDefaultShiftTemplate = () => ({
  dowStart: 1,
  status: 'Draft',
  uuid: uuid.v4(),
});

export default class RecurringSchedules {
  log = getChildLogger('ui.RecurringSchedules');

  @observable recurringSchedule: IRecurringSchedule | null;

  @observable scheduleForm = initRecurringScheduleForm();

  @observable isEditing = false;

  @observable isLoading = false;

  @observable formErrors = defaultFormErrors;

  @observable shiftTemplates: IObservableArray<IShiftTemplate> =
    observable.array([]);

  @observable errorMsg = '';

  @observable openModal = false;

  @observable modalPages = 1;

  @observable currentPage = 1;

  @observable cycleStartOption: 'dow' | 'dom' | 'date' = 'dow';

  @observable
  activeDay = null;

  @observable
  recurringScheduleId = '';

  @action
  setActiveDay(day) {
    this.activeDay = day;
  }

  @action
  getActiveDay() {
    return this.activeDay;
  }

  @action
  setIsLoading(value) {
    this.isLoading = value;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set<T extends keyof this>(key: T, value: this[T]) {
    _.set(this, key, value);
  }

  @action
  setCycleStartOption(value) {
    this.scheduleForm.$('cycleStart.dow').value = '';
    this.scheduleForm.$('cycleStart.dom').value = '';
    this.scheduleForm.$('cycleStart.ldom').value = '';
    this.scheduleForm.$('cycleStart.date').value = '';
    this.cycleStartOption = value;
  }
  /**
   * @param param if shiftTemplates is given as parameter, the pagination view will be shown
   */

  @action
  setup({
    recurringSchedule,
    shiftTemplates = [],
  }: {
    recurringSchedule?: IRecurringSchedule;
    shiftTemplates?: IShiftTemplate[];
  }) {
    if (!_.isEmpty(recurringSchedule)) {
      this.recurringSchedule = recurringSchedule;

      // init the cycleStartOption with the existing key from recurringSchedule.cycleStart
      this.cycleStartOption = _.keys(
        recurringSchedule.cycleStart,
      )[0] as typeof this.cycleStartOption;

      this.scheduleForm = initRecurringScheduleForm(recurringSchedule);
      if (recurringSchedule.cycleStart.dom === 'last') {
        this.scheduleForm.$('cycleStart.ldom').value = true;
        this.scheduleForm.$('cycleStart.dom').value = '';
      }
      this.isEditing = true;
    } else {
      this.recurringSchedule = null;
      this.scheduleForm = initRecurringScheduleForm();
      this.isEditing = false;
    }

    if (!_.isEmpty(shiftTemplates)) {
      const processedTemplates = shiftTemplates.map((template) => {
        if (_.isEmpty(template.uuid)) {
          return {
            ...template,
            status: 'Draft',
            uuid: uuid.v4(),
          } as IShiftTemplate;
        }
        return template;
      });

      this.shiftTemplates.replace(processedTemplates);
    } else {
      this.shiftTemplates.clear();
    }

    this.currentPage = 1;
    this.modalPages = shiftTemplates?.length + 1 || 1;
  }

  @action
  async setCurrentPage(index: number) {
    if (!(await this.validateForm())) {
      return;
    }
    runInAction(() => {
      this.currentPage = index;
    });
  }

  @action
  closeModal(modalRef) {
    if (modalRef) {
      modalRef.current.clear();
    }
  }

  @action
  async setModalPages(modalPages: number) {
    this.modalPages = modalPages;
  }

  @action
  updateShiftTemplatesCount({
    dayId,
    actionType,
    value = 0,
  }: {
    actionType?: 'add' | 'remove';
    dayId: number;
    value: number;
  }) {
    const recurringSchedule = dispatch('recurringSchedules.getSelected');
    if (actionType === 'remove') {
      recurringSchedule.shiftTemplatesPerDay.find(
        ({ day }) => day === dayId,
      ).shiftDayTemplatesCount -= value;
    } else {
      recurringSchedule.shiftTemplatesPerDay.find(
        ({ day }) => day === dayId,
      ).shiftDayTemplatesCount += value;
    }
    dispatch('recurringSchedules.setSelected', recurringSchedule);
  }

  @action
  async saveForm({
    companyId,
    simplifiedErrorMessage = false,
    modalRef,
    isRedirect = false,
  }: {
    companyId: string;
    isRedirect?: boolean;
    modalRef?: RecurringScheduleModalProps['modalRef'];
    simplifiedErrorMessage?: boolean;
  }) {
    const log = this.log.getChildLogger('saveForm');
    if (!(await this.validateForm())) {
      return;
    }

    const cycleStartValid = await this.cycleStartValidation();
    if (!cycleStartValid) {
      return;
    }

    const cycleDaysChangesAreAllowed = await this.checkCycleDaysChanged();
    if (!cycleDaysChangesAreAllowed) {
      return;
    }
    const schedule: Partial<IRecurringSchedule> = {
      ...this.recurringSchedule,
      ...this.scheduleForm.values(),
    };
    if (_.isEmpty(schedule)) {
      return;
    }

    if (schedule.cycleStart) {
      const cycleStart: IRecurringSchedule['cycleStart'] = {
        [this.cycleStartOption]: schedule.cycleStart[this.cycleStartOption],
      };

      if (
        this.cycleStartOption === 'dom' &&
        _.get(schedule.cycleStart, 'ldom')
      ) {
        cycleStart.dom = 'last';
      }

      schedule.cycleStart = cycleStart;
    }

    // save the recurring schedule
    try {
      if (!schedule.uuid) {
        schedule.uuid = uuid.v4();
        schedule.companyId = companyId;
        await dispatch('recurringSchedules.create', {
          data: schedule,
        });
        this.closeModal(modalRef);
      } else {
        await dispatch('recurringSchedules.update', {
          data: schedule,
          id: schedule.uuid,
        });
        this.closeModal(modalRef);
      }
    } catch (error) {
      runInAction(() => {
        this.errorMsg = error.message;
      });
      log.error('Error while saving recurring schedule: ', error, {
        isEditing: this.isEditing,
        schedule,
      });
      dispatch('ui.snackBar.error', 'Schedule could not be saved', {
        body: simplifiedErrorMessage
          ? 'Please check the required missing fields'
          : error.message,
      });
      return;
    }

    this.setIsLoading(true);
    // save the templates
    await dispatch(
      `ui.recurringScheduleUploader.setExistingRecurringSchedule`,
      schedule,
    );
    await dispatch(`ui.recurringScheduleUploader.saveTemplates`);

    if (isRedirect && schedule) {
      dispatch('routing.goto', {
        route: `/admin/recurringSchedules/${schedule.uuid}/details?showDetails=true`,
      });
    }
    this.clear();
    this.setIsLoading(false);
  }

  @action
  async addTemplate(): Promise<void> {
    // validate current form before switching to new template
    if (this.currentPage === 1) {
      if (!(await this.validateForm())) {
        return;
      }
    }

    const template = createDefaultShiftTemplate() as unknown as IShiftTemplate;

    runInAction(() => {
      this.shiftTemplates.push(template);
      this.setModalPages(this.modalPages + 1);
      this.currentPage = this.modalPages;
    });
  }

  @action
  async removeTemplate(id: string) {
    const stepIndex = _.findIndex(this.shiftTemplates, { uuid: id });
    const template = this.shiftTemplates[stepIndex];

    runInAction(() => {
      this.setModalPages(this.modalPages - 1);
      this.currentPage = stepIndex + 1;
      this.shiftTemplates.replace(
        this.shiftTemplates.filter((t) => t.uuid !== id),
      );
    });

    if (template.status !== 'Draft') {
      await dispatch('shiftTemplates.remove', id);
    }
  }

  async validateForm() {
    const response = await this.scheduleForm.validate({ showErrors: true });
    if (!response.isValid) {
      return false;
    }
    return true;
  }

  convertTemplatesToShifts({
    runOnThisDate,
    startDayOffsets,
    addShiftsToOneDay,
    recurringScheduleId,
  }: {
    addShiftsToOneDay: boolean;
    recurringScheduleId: string;
    runOnThisDate: Date;
    startDayOffsets?: Array<number>;
  }) {
    dispatch('recurringSchedules.runQuery', {
      $client: {
        instantlyConvertTemplatesToShiftsData: {
          addShiftsToOneDay,
          runOnThisDate,
          startDayOffsets,
        },
      },
      uuid: recurringScheduleId,
    });
  }

  async cycleStartValidation() {
    const cycleStart = this.scheduleForm.$('cycleStart').value;
    if (this.cycleStartOption === 'dow' && cycleStart.dow === '') {
      dispatch(
        'ui.snackBar.open',
        'Please add a day of week for the cycle start',
      );
      return false;
    }

    if (this.cycleStartOption === 'dom') {
      if (cycleStart.dom === '' && cycleStart.ldom === '') {
        dispatch(
          'ui.snackBar.open',
          'Please add a day of the month for the cycle start',
        );
        return false;
      }
    }
    if (this.cycleStartOption === 'date' && cycleStart.date === '') {
      dispatch('ui.snackBar.open', 'Please add a cycle start date');
      return false;
    }
    return true;
  }

  async checkCycleDaysChanged() {
    const cycleDaysField = this.scheduleForm.$('cycleDays');

    const { value: newValue, initial: initialValue } = cycleDaysField;

    if (initialValue && newValue < initialValue) {
      // check if between newCycleDays and cycleDays are days with shiftTemplates
      const shiftTemplatesPerDayCount = (await dispatch(
        'recurringSchedules.get',
        this.scheduleForm.$('uuid').value,
        {
          query: {
            $client: { populateDaysShiftTemplatesCount: true },
          },
        },
      )) as IRecurringSchedule;
      const canChangeCycleDays = _.range(newValue, initialValue).map(
        (currentDay) =>
          shiftTemplatesPerDayCount.shiftTemplatesPerDay.find(
            (pair) => pair.day === currentDay + 1,
          )?.shiftDayTemplatesCount > 0,
      );
      if (_.includes(canChangeCycleDays, true)) {
        dispatch(
          'ui.snackBar.open',
          'The cycleDays value can not be modified since there are days between the new value and the old one with shift templates',
        );
        return false;
      }
    }

    return true;
  }

  @action
  clear() {
    this.recurringSchedule = null;
    this.scheduleForm = initRecurringScheduleForm();
    this.isEditing = false;
    this.shiftTemplates.clear();
    this.modalPages = 1;
    this.currentPage = 1;
    this.openModal = false;
    this.cycleStartOption = 'dow';
    this.activeDay = null;
  }
}
