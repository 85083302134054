import type { IInShiftTaskContainer } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export default class InShiftTaskContainersStore extends BaseStore<IInShiftTaskContainer> {
  constructor() {
    super({
      baseItem: InShiftTaskContainersStore.BASE_ITEM,
      serviceName: 'inShiftTaskContainers',
    });

    return this;
  }

  static BASE_ITEM: IInShiftTaskContainer = {
    category: null,
    companyId: null,
    createdAt: null,
    description: null,
    modelName: null,
    sequenceId: null,
    shiftId: null,
    title: null,
    updatedAt: null,
    uuid: null,
  };
}
