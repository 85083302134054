import type {
  ICompany,
  IPaymentTransaction,
  IWorker,
} from '@shiftsmartinc/shiftsmart-types';

import { action, computed, observable } from 'mobx';
import _ from 'lodash';

export default class CreateTransactionModal {
  @observable
  isOpen = false;

  @observable
  selectedCompany: ICompany = null;

  @observable
  selectedUser: IWorker = null;

  @observable
  showShifts = false;

  @observable
  selectedShifts = [];

  @observable
  paymentAmount = 0;

  @observable
  transactionCategory: IPaymentTransaction['category'] = null;

  @observable
  comments = '';

  @observable
  errorMessage = '';

  @action
  setup(company?: ICompany, user?: IWorker) {
    this.selectedCompany = company || null;

    if (user?.uuid) {
      this.setSelectedUser(user);
    }

    this.isOpen = true;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = arg;
  }

  @action
  clear() {
    this.isOpen = false;
    this.selectedCompany = null;
    this.selectedUser = null;
    this.showShifts = false;
    this.selectedShifts = [];
    this.paymentAmount = 0;
    this.transactionCategory = null;
    this.comments = '';
    this.errorMessage = '';
  }

  @action
  setSelectedCompany(companyObj?: ICompany) {
    this.selectedCompany = companyObj;
  }

  @computed
  get selectedCompanyId() {
    return this.selectedCompany?.uuid || null;
  }

  @action
  setSelectedUser(userObj?: IWorker) {
    this.selectedUser = userObj ?? null;
  }

  @computed get selectedUserId() {
    return this.selectedUser?.uuid ?? null;
  }

  @action
  setSelectedShifts(value) {
    this.selectedShifts = value;
  }

  @action
  setShowShifts(value) {
    this.showShifts = value;
  }

  /**
   * @param {number} value
   */
  @action
  setPaymentAmount(value) {
    this.paymentAmount = value;
  }

  @action
  setTransactionCategory(value) {
    this.transactionCategory = value || null;
  }

  @action
  setComments(value) {
    this.comments = value;
  }

  @action
  setErrorMessage(value) {
    this.errorMessage = value;
  }
}
