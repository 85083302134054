import type { ISurveyDefinition } from '@shiftsmartinc/shiftsmart-types';

import { observable, action, computed } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import request from 'superagent-bluebird-promise';
import { dispatch } from 'rfx-core';

import { service, app } from '#/shared/app';
import { getChildLogger } from '#/shared/utils/client.logger';

import BaseStore from './_baseStore';

const SERVICE = 'surveydefs';
const log = getChildLogger(`${SERVICE}.store`);
const config = global.CONFIG;

export default class SurveyDefinitionStore extends BaseStore<ISurveyDefinition> {
  constructor() {
    super({
      baseItem: SurveyDefinitionStore.surveyDefinition,
      cacheSize: 0,
      companyField: 'company',
      modelName: 'surveyDefinition',
      searchFields: ['title', 'product.title'],
      serviceName: SERVICE,
    });

    return this;
  }

  static surveyDefinition = {
    company: null,
    model: null,
    product: null,
    title: null,

    uuid: null, // Company UUID
  };

  sort = { title: 1 };

  @observable
  offset = '0';

  @observable
  surveyAssignment = {};

  @computed
  get start() {
    return moment().add(this.offset, this.range).startOf(this.range);
  }

  @computed
  get end() {
    return moment().add(this.offset, this.range).endOf(this.range);
  }

  @action
  prev() {
    this.offset -= 1;
    return this.find();
  }

  @action
  next() {
    this.offset += 1;
    return this.find();
  }

  @action
  setRange(range) {
    if (!/day|week|month|year/i.test(range)) {
      this.range = 'day';
    } else {
      this.range = range.toLowerCase();
    }
    return this.find();
  }

  range() {
    return this.range;
  }

  @action
  create({ data }) {
    const newSurveyDefinition = _.clone(data);
    newSurveyDefinition.model = JSON.parse(data.model);

    return super.create({ data: newSurveyDefinition });
  }

  @action
  update({ data = {}, id = data.uuid }) {
    const surveyDefinition = _.clone(data);
    surveyDefinition.model = JSON.parse(data.model);

    super.update({ data: surveyDefinition, id });
  }

  @action
  getSurveyAssignment(id) {
    return service('assignments')
      .get(id, { query: { action: 'noAuth' } })
      .then((assignment) => this.setSurveyAssignment(assignment));
  }

  @action
  setSurveyAssignment(assignment) {
    // set(this.surveyAssignment, assignment);
    this.surveyAssignment = assignment;
  }

  completeSurveyAssignment(assignment, surveyResponseData) {
    const data = {
      completedAt: moment().toDate(),
      status: 'Completed',
      // ATTN: Assignment Status EP-5523
      surveyResponseData,
    };
    return service('assignments')
      .patch(assignment.uuid, data)
      .then((res) => {
        log.debug('Completed Survey Assignment', res);
        return res;
      })
      .catch((err) => log.error('Error completed survey: ', assignment, err));
  }

  @action
  filterBy(filter) {
    this.filter = filter;
    let status;

    switch (this.filter) {
      case 'pending':
        status = { $in: ['Draft', 'Pending'] };
        break;
      case 'active':
        status = { $in: ['Active', 'Filled'] };
        break;
      case 'inactive':
        status = { $in: ['Closed', 'Canceled', 'Expired', 'Deleted'] };
        break;
      case 'done':
        status = 'Complete';
        break;
      default:
        status = undefined;
    }

    return this.find({ query: { status } });
  }

  @action
  async getResults({
    surveyDefinitionId,
    onProgress = _.noop,
    campaigns = [],
    completedAtDate = null,
  }) {
    // Using REST method here to load large amount of data
    // Bypassing the timeout set for sockets
    const accessToken = await app().authentication.getAccessToken();
    const uri = dispatch('app.getServerURI');
    const $client = {
      campaigns,
      getResults: true,
    };
    if (completedAtDate) {
      _.set(
        $client,
        'completedAtDate',
        moment(completedAtDate).toDate().toISOString(),
      );
    }
    return request
      .get(`${uri}/surveydefs/${surveyDefinitionId}`)
      .set('Authorization', accessToken)
      .on('progress', (e) => onProgress(e.percent))
      .query({
        $client,
      });
  }
}
