import type { StoreName, StoreType } from '#/shared/stores';
import type { UIStorePath } from '#/shared/stores/ui';

import React from 'react';
import _ from 'lodash';
import { dispatch } from 'rfx-core';

/**
 * withStores
 * @param {Component} Component The component to "inject" the "store" prop into
 *
 * @description
 * A drop-in replacement for `@inject('store')`
 */
export const withStores = (Component) => {
  const Context = (props) => {
    const storesContext = dispatch('app.getStoresContext');
    return (
      <storesContext.Consumer>
        {(context) => <Component store={context} stores={context} {...props} />}
      </storesContext.Consumer>
    );
  };
  Context.displayName = 'StoresContext';
  return Context;
};

/**
 * StoresProvider
 * @param {*} param0.children
 *
 * A Context Provider which enables `this.context` to our global stores.
 */
export const StoresProvider = ({ children, value }) => {
  const storesContext = dispatch('app.getStoresContext');
  return (
    <storesContext.Provider value={value}>{children}</storesContext.Provider>
  );
};

/**
 * useStores
 *
 * Enable stores hooks
 *
 * @returns typeof $store
 */
export const useStores = (): StoreType => {
  const store: StoreType = React.useContext(dispatch('app.getStoresContext'));

  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.');
  }

  return store;
};

/**
 * Return a single store, specified by the store name
 *
 * @export
 * @param {T extends keyof typeof $stores} storePath The Store you wish to return
 * @returns typeof $store[T]
 */
export function useStore<T extends StoreName | 'ui' | UIStorePath>(
  storePath: T,
  noThrow = false,
): StoreFromName<T> {
  const store = _.get(useStores(), storePath);

  if (!store && !noThrow) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error(`Unable to load store at path "${storePath}"`);
  }

  return store;
}
