import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { Dimmer, Loader } from 'semantic-ui-react';

import EmptyState from '#/shared/components/ssm/EmptyState';
import { parseQueryString } from '#/shared/utils/parseQueryString';
import { useStores } from '#/shared/hooks/useStores';
import { CompactCompanyListItem } from '#/shared/components/ssm/LoggedInUserMenu';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('NotFound');

export const NotFound = observer(() => {
  const { ui, auth, companies, app, routing, abilities, prefs } = useStores();

  const [isLoaded, setIsLoaded] = useState(null);
  const [pageState, setPageState] = useState({
    body: null,
    doRedirect: true,
    header: 'Page Not Found',
    path: null,
    query: null,
    selectFromCompanies: false,
    timeoutId: null,
  });

  useEffect(() => {
    setIsLoaded(false);
    const tmpState: Partial<typeof pageState> = {};

    const userCompanies = companies.getUserCompanies();
    const user = auth.getUser();

    const query = parseQueryString();
    if (!_.isEmpty(query)) {
      tmpState.query = query;
    }

    const redirect = (!!query && query.redirect) || undefined;

    if (!user?.uuid) {
      routing.push(`/auth`, { redirect });
    } else if (!user.isAdmin && _.isEmpty(userCompanies)) {
      _.extend(tmpState, {
        body: 'Sorry, but this account is not associated with any companies. Please contact your manager for more information',
        doRedirect: false,
        header: 'No Associated Companies',
      });

      ui.setHeader({ onDeselect: _.noop, title: 'Unavailable' });
    } else {
      if ((user.isAdmin || !_.isEmpty(userCompanies)) && !auth.companyId) {
        _.extend(tmpState, {
          doRedirect: false,
          header: 'Please Select a Company',
          selectFromCompanies: true,
        });
      }

      ui.setHeader({
        onDeselect: _.noop,
        title: tmpState.header || 'Not Found',
      });

      tmpState.path = app.getPathname();

      const destinationRoute = prefs.getHomeRoute() || '/';

      if (
        (!redirect || redirect !== destinationRoute) &&
        abilities.can('access', destinationRoute)
      ) {
        // Route to the destination route after a 2s delay
        tmpState.timeoutId = setTimeout(() => {
          setPageState((prev) => ({
            ...prev,
            timeoutId: null,
          }));
          routing.push(destinationRoute);
        }, 2000);
      } else {
        // Otherwise, just stay on the page
        tmpState.doRedirect = false;
      }
    }

    log.warn(`Page is Unavailable: "${pageState.path}"`, {
      extra: { ...pageState, ...tmpState },
    });

    setPageState({ ...pageState, ...tmpState });

    setIsLoaded(true);

    return () => {
      if (pageState.timeoutId !== null) {
        clearTimeout(pageState.timeoutId);
        setPageState((prev) => ({
          ...prev,
          timeoutId: null,
        }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  log.info('Rendering page with state', pageState);

  return (
    <div className="pa5 w-100 tc">
      <Helmet title={!isLoaded ? 'Loading ...' : 'Not Found'} />

      {isLoaded ? (
        <>
          <EmptyState
            action={
              auth.user.isAdmin && pageState.selectFromCompanies
                ? {
                    basic: true,
                    className: 'bare',
                    content: 'Click here to Return to the Admin Portal…',
                    onClick: () => routing.push('/admin'),
                  }
                : undefined
            }
            body={pageState.body}
            header={{
              content: pageState.header,
            }}
            icon="/static/img/not-found.png"
          />

          {!!pageState.selectFromCompanies && (
            <div className="flex justify-center">
              <div className="max-w6 flex-column flex">
                {_.map(companies.userCompanies, (co) => (
                  <CompactCompanyListItem
                    company={co}
                    onSelect={() => {
                      auth
                        .setCompany({ company: co.uuid })
                        .then(() => routing.push('/'));
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          {pageState.doRedirect && (
            <div className="pt3 i fw3 silver">
              Redirecting you to the homepage ...
            </div>
          )}
        </>
      ) : (
        <Dimmer active={true}>
          <Loader active={true} />
        </Dimmer>
      )}
    </div>
  );
});

NotFound.displayName = 'NotFound';
export default NotFound;
