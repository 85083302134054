import type {
  IAssignment,
  ICompany,
  IThing,
  IUser,
} from '@shiftsmartinc/shiftsmart-types';

import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import moment from 'moment';

import { getDateRangeString } from '#/shared/components/utils/DateRangeString';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('AssignShiftModal');

export default class AssignShiftModal {
  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  user = {};

  @observable
  shift = {};

  @observable
  assignment: IAssignment;

  @observable
  company: Partial<ICompany> = {};

  @observable companyLimits = [];

  @observable userAvailability = [];

  @observable userHours = {};

  @observable error = {};

  @observable
  hasAvailabilityForShift = undefined;

  @observable
  hasConflictForShift = undefined;

  @observable
  availabilityFilter = false;

  @observable
  positionFilter = false;

  @observable
  showList = false;

  @action
  async setup({
    user,
    shift,
    assignment,
    showList = false,
    open = this.isOpen,
  }: {
    assignment?: IAssignment;
    open?: boolean;
    shift: IThing;
    showList?: boolean;
    user: IUser;
  }) {
    const company = dispatch('auth.getCompany');
    this.set('company', company);
    this.set('shift', shift);
    this.set('assignment', assignment);
    this.set('showList', showList);
    this.set('positionFilter', !_.isEmpty(shift.position));
    if (showList && !_.isEmpty(shift.position)) {
      await this.queryPartners({ position: shift.position });
    }
    this.open(open);
    if (user) {
      await this.setUser({ user });
    }
    this.set('isLoading', false);
  }

  @action.bound
  async queryPartners({ position }) {
    return dispatch('partners.find', {
      query: {
        'certs.uuid': [position.uuid],
      },
    });
  }

  @action.bound
  async setUser({ user, fetchAssignment = false }) {
    this.set('isLoading', true);
    this.set('user', user);
    this.error = {};

    if (_.isEmpty(user)) {
      return false;
    }

    if (fetchAssignment) {
      const assignment = await dispatch('assignments.findOne', {
        query: {
          ref: this.shift.uuid,
          status: { $nin: ['Canceled', 'Rejected'] },
          user: user.uuid, // ATTN: Assignment Status EP-5523
        },
      });
      this.set('assignment', assignment);
    }

    if (user) {
      await this.loadUserHours({
        shift: this.shift,
        user,
      });
    }

    const availability = dispatch('availability.retrieve', 'list');
    const shiftStart = moment(this.shift.start);
    const userAvailability = _.filter(availability, (a) => {
      if (a.user !== user.uuid) {
        return false;
      }
      if (a.dayOfWeek !== shiftStart.day()) {
        return false;
      }

      if (a.ref) {
        if (!!a.date && shiftStart.isSame(a.date, 'day')) {
          return true;
        }
      } else {
        return true;
      }

      return false;
    });
    this.set('userAvailability', userAvailability);

    this.set('isLoading', false);

    return true;
  }

  @action.bound
  async loadUserHours({ user = this.user, shift = this.shift } = {}) {
    if (_.isEmpty(user)) {
      return false;
    }
    this.set('userHours', {});
    try {
      const [hours, availability] = await Promise.all([
        dispatch('availability.getHoursForUser', {
          company: this.company,
          time: shift.start,
          user,
        }).catch((error) => {
          log.error('Error Loading User Availability: ', error);
          return [];
        }),
        dispatch('availability.getForUser', {
          user,
        }).catch((error) => {
          log.error('Error Loading User Hours: ', error);
          return 0;
        }),
        dispatch(
          'userSchedule.find',
          {
            company: _.get(this.company, 'uuid', this.company),
            pts: _.get(shift, 'ref', shift.uuid),
            user: _.get(user, 'uuid', user),
            week: moment(shift.start).week(),
          },
          { clear: true },
        ).catch((error) => {
          log.error('Error Loading User Schedule: ', error);
          return null;
        }),
      ]);
      this.set('userHours', hours);

      const start = moment(shift.start);
      const userAvailability = _.filter(availability, (a) => {
        if (a.user !== user.uuid) {
          return false;
        }
        if (a.dayOfWeek !== start.day()) {
          return false;
        }

        if (a.ref) {
          if (!!a.date && start.isSame(a.date, 'day')) {
            return true;
          }
        } else {
          return true;
        }

        return false;
      });

      const { hasAvailabilityForShift, hasConflictForShift } = dispatch(
        'availability.checkAvailabilityForThing',
        {
          availability: userAvailability,
          thing: shift,
          user,
        },
      );

      this.set('hasAvailabilityForShift', hasAvailabilityForShift);
      this.set('hasConflictForShift', hasConflictForShift);
    } catch (error) {
      this.set('userHours', {});
      log.error('Error loading user hours or schedule: ', error, {
        shiftId: shift.uuid,
        userId: user.uuid,
      });
    }

    return true;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    _.set(this, key, value);
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  setError({ message, title = 'Error', ...rest }) {
    this.error = {
      message,
      title,
      ...rest,
    };
  }

  @action async cancelAssignment() {
    const { assignment } = this;
    if (_.isEmpty(assignment)) {
      return;
    }
    this.set('isLoading', true);
    try {
      const id = assignment?.uuid;
      const authUser = dispatch('auth.getUser');
      const data = {
        // ATTN: Assignment Status EP-5523
        canceledAt: moment().toDate(),
        canceledBy: authUser.uuid,
        status: 'Canceled',
        updatedNote: `Canceled by Employer: ${authUser.displayName}`,
      };
      await dispatch('assignments.update', { data, id });
      this.clear();
      return;
    } catch (error) {
      log.error('Saving Assignment failed', error);
      this.setError({
        message: 'Canceling Request Failed. Please try again later',
      });
    }
    this.set('isLoading', false);
  }

  @action async submit({ status } = {}) {
    this.set('isLoading', true);
    const { user, shift, assignment, company } = this;
    const authUser = dispatch('auth.getUser');
    const ignoreShiftConflicts = _.get(
      company,
      'settings.things.ignoreShiftConflicts',
      false,
    );
    if (this.hasConflictForShift && !ignoreShiftConflicts) {
      this.setError({
        message: `Sorry, ${
          user.displayName || user.firstName
        } is already scheduled for a shift at this time`,
      });
      this.set('isLoading', false);
      return;
    }

    const assignmentId = assignment?.uuid;

    let actionPromise;
    if (_.isEmpty(assignmentId)) {
      const newAssignment = {
        assignedBy: authUser.uuid,
        status,
        thing: shift,
        worker: user,
      };
      actionPromise = dispatch('assignments.create', newAssignment);
    } else {
      const data = { status: 'Assigned' };

      // Hack to deal with backend logic that strips out any data
      // when we change the status
      actionPromise = dispatch('assignments.update', {
        data,
        id: assignmentId,
      }).then(() =>
        dispatch('assignments.update', {
          data: {
            assignedBy: authUser.uuid,
            canceledAt: null,
            canceledBy: null,
            managerCancelReason: null,
            partnerCancelReason: null,
            updatedNote: `Reassigned by Employer: ${authUser.displayName}`,
          },
          id: assignmentId,
        }),
      );
    }

    try {
      await actionPromise;
      this.clear();
      return;
    } catch (error) {
      log.error('Saving Assignment failed', error);
      const conflicts = _.get(error, 'data.conflicts');
      const defaultErrorMsg =
        'Failed to save assignment. Please try again later';

      if (error.code === 409 && !_.isEmpty(conflicts)) {
        const conflict = _.first(conflicts);

        const { start, end, company: conflictCompanyId } = conflict;

        const timeString = `(${getDateRangeString({
          end,
          start,
          timeFormat: 'h:mma',
        })})`;

        let errorReason = `schedule conflict with another shift ${timeString}`;

        if (company.isShiftsmartManaged || /shiftsmart/i.test(company.name)) {
          const conflictCompany = await dispatch(
            'companies.get',
            conflictCompanyId,
            {
              select: false,
            },
          );
          errorReason = `schedule conflict with ${conflictCompany.name} shift ${timeString}`;
        }

        this.setError({
          message: `${user.displayName} cannot be assigned to this shift due to ${errorReason}`,
          title: 'Scheduling Conflict',
        });
      } else if (/invalid parent state/i.test(error.message)) {
        this.setError({
          message: error?.errors?.status ?? defaultErrorMsg,
        });
      } else {
        this.setError({
          message: defaultErrorMsg,
        });
      }
    }
    this.set('isLoading', false);
  }

  @action.bound
  async toggleAvailabilityFilter(checked) {
    this.availabilityFilter = checked;
  }

  @action
  togglePositionFilter({ position, query = {}, workerStoreName = 'partners' }) {
    const certs = _.clone(query['certs.uuid'] || []);
    if (_.includes(certs, position.uuid)) {
      _.pull(certs, position.uuid);
    } else {
      certs.push(position.uuid);
    }

    this.positionFilter = _.includes(certs, position.uuid);
    dispatch(`${workerStoreName}.find`, {
      query: { 'certs.uuid': _.isEmpty(certs) ? undefined : certs },
    });
  }

  @action
  clear(args = {}) {
    this.isOpen = !!args.open;
    this.isLoading = false;
    if (this.showList) {
      dispatch('partners.search', '');
    }
    this.user = {};
    this.shift = {};
    this.assignment = null;
    this.company = {};
    this.companyLimits = [];
    this.userAvailability = [];
    this.userHours = {};
    this.error = {};
    this.hasAvailabilityForShift = undefined;
    this.hasConflictForShift = undefined;
    this.showList = false;
    this.availabilityFilter = false;
    this.positionFilter = false;
  }
}
