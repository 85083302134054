import { action, computed, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('stores.ui.createChannelModal');

export default class CreateChannelModal {
  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  company = {};

  @observable
  selectedPools = [];

  @observable
  selectedPartners = [];

  @observable
  searchValue = '';

  @observable
  isCreatingChannel = false;

  @observable
  addressForm = {};

  @observable
  error = '';

  @computed
  get selectedUsers() {
    const poolPartners = _.concat([], _.flatMap(this.selectedPools, 'users'));
    return _.uniqBy([...poolPartners, ...this.selectedPartners], 'uuid');
  }

  @action
  async setup({ company, open = this.isOpen }) {
    this.setCompany(company);
    this.setLoading(true);
    this.open(open);

    this.searchPools = dispatch('auth.can', 'read', 'pool');
    this.searchWorkers = dispatch('auth.can', 'read', 'worker');

    await Promise.all([
      this.searchWorkers &&
        dispatch(
          'partners.find',
          {
            $limit: 10,
            companies: company.uuid,
            uuid: { $nin: this.selectedPartners },
          },
          { clear: true },
        ),
      this.searchPools &&
        dispatch(
          'pools.find',
          {
            $client: { populateUserAvatars: true },
            $limit: 10,
            companies: company.uuid,
            poolType: { $ne: 'upload' },
            search: { $ne: null },
            uuid: { $nin: this.selectedPools },
          },
          { clear: true },
        ),
    ]);
    this.setLoading(false);
  }

  @action
  async search(searchValue) {
    this.searchValue = searchValue;
    await Promise.all([
      dispatch('partners.search', searchValue),
      this.searchPools && dispatch('pools.search', searchValue),
    ]);
  }

  @action
  add({ item, listName }) {
    const list = _.get(this, listName);
    list.push(item);
  }

  @action
  remove({ uuid, listName }) {
    const list = _.get(this, listName);
    _.remove(list, (p) => p.uuid === uuid);
  }

  @action
  clearSelected() {
    this.selectedPartners = [];
    this.selectedPools = [];
  }

  @computed
  get totalResults() {
    const poolPagination = dispatch('pools.retrieve', '$pagination');
    const partnerPagination = dispatch('partners.retrieve', '$pagination');
    return (
      _.get(poolPagination, 'total', 0) + _.get(partnerPagination, 'total', 0)
    );
  }

  @action.bound
  setCompany(company = {}) {
    this.company = company;
  }

  @action
  setLoading(loading) {
    this.isLoading = loading;
  }

  @action
  setCreatingChannel(loading) {
    this.isCreatingChannel = loading;
  }

  @action
  setError(msg) {
    this.error = msg;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear(args = {}) {
    this.isOpen = !!args.open;
    this.searchValue = '';
    this.shift = {};
    this.company = {};
    this.selectedPools = [];
    this.selectedPartners = [];
    this.isCreatingChannel = false;
  }

  @action
  async createNewChannel() {
    this.setCreatingChannel(true);
    dispatch('ui.loadingModal.open', { message: 'Creating Chat Channel' });

    const workers = this.selectedUsers;
    let newChannel = null;

    try {
      // Create a Pool Managed Channel
      if (
        this.selectedPools.length === 1 &&
        this.selectedPartners.length === 0
      ) {
        newChannel = await dispatch('chatChannels.createPoolChannel', {
          pools: this.selectedPools,
        });
      }
      // Create a Worker channel of selected worker(s)
      else {
        newChannel = await dispatch('chatChannels.createWorkerChannel', {
          workers,
        });
      }

      dispatch('routing.push', `/chats/${newChannel.uuid}?autoFocus=true`);
      dispatch('ui.chatInput.setMessageText', { value: '' });
    } catch (err) {
      log.error('Failed to create pool channel', err);
      dispatch('ui.snackBar.error', 'Failed to Create Channel');
    } finally {
      this.setCreatingChannel(false);
      dispatch('ui.loadingModal.close');
    }

    return newChannel;
  }

  radiusOptions = [
    {
      text: '100 ft',
      unit: 'feet',
      value: 100,
    },
    {
      text: '200 ft',
      unit: 'feet',
      value: 200,
    },
    {
      text: '300 ft',
      unit: 'feet',
      value: 300,
    },
    {
      text: '400 ft',
      unit: 'feet',
      value: 400,
    },
    {
      text: '500 ft',
      unit: 'feet',
      value: 500,
    },
    {
      text: '1000 ft',
      unit: 'feet',
      value: 1000,
    },
    {
      text: '1500 ft',
      unit: 'feet',
      value: 1500,
    },
    {
      text: '2000 ft',
      unit: 'feet',
      value: 2000,
    },
    {
      text: '2500 ft',
      unit: 'feet',
      value: 2500,
    },
    {
      text: '3000 ft',
      unit: 'feet',
      value: 3000,
    },
  ];
}
