import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';

const log = getChildLogger('forms.experience');

export class ExperienceForm extends Form {
  hooks = () => ({
    onSuccess(form) {
      const storeAction = form.values().uuid
        ? 'partners.updateExperience'
        : 'partners.createExperience';

      dispatch(storeAction, { data: form.values() })
        .then(() => dispatch('ui.experienceEditModal.open', false))
        .then(() => dispatch('ui.snackBar.open', 'Work History Updated.'))
        .then(() => form.clear())
        .catch((err) => {
          log.error('Saving Experience Failed: ', err);
          form.invalidate(err.message);
          dispatch('ui.snackBar.open', err.message);
        });
    },
  });
}

const fields = [
  'uuid',
  'user',
  'title',
  'employer.uuid',
  'employer.logo',
  'employer.name',
  'location',
  'start',
  'end',
  'currentJob',
  'summary',
];

const rules = {
  currentJob: 'boolean',
  'employer.logo': 'string',
  'employer.name': 'string|required',
  'employer.uuid': 'string',
  end: 'date',
  location: 'string',
  start: 'date',
  summary: 'string',
  title: 'string|required',
  user: 'string|required',
  uuid: 'string',
};

const labels = {
  currentJob: 'Is Current Job?',
  employer: 'Employer Name',
  end: 'End Date',
  location: 'Job Location',
  start: 'Start Date',
  summary: 'Job Summary',
  title: 'Job Title',
};

export function init(initialValues = {}) {
  log.debug('Creating Experience Form from Keys: ', _.keys(initialValues));

  const values = _.pick(initialValues, [
    'uuid',
    'user',
    'title',
    'employer',
    'location',
    'summary',
    'start',
    'end',
    'currentJob',
  ]);

  const form = new ExperienceForm({ fields, labels, rules, values });

  log.debug('Created Experience Form with Values: ', form.values());
  return form;
}
