import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import moment from 'moment';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.CheckInCheckOutModal');

export default class CheckInCheckOutModal {
  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  error = '';

  @observable
  assignment = null;

  @observable
  checkInTime = null;

  @observable
  checkOutTime = null;

  @observable
  markedNoShow = false;

  @observable
  displayedField = '';

  @action
  async setup({ open = !this.isOpen, assignment = null, displayedField = '' }) {
    this.set('isLoading', true);
    if (assignment) {
      this.set('assignment', assignment);
      this.set(
        'checkInTime',
        moment(_.get(assignment, 'checkIn.timestamp', assignment.start)),
      );
      this.set(
        'checkOutTime',
        moment(_.get(assignment, 'checkOut.timestamp', assignment.end)),
      );
      this.set('displayedField', displayedField);
      if (/noshow/i.test(assignment.status)) this.set('markedNoShow', true); // ATTN: Assignment Status EP-5523
      this.open(open);
    } else {
      log.error('No assignment passed to checkInCheckOutModal.setup');
    }
    this.set('isLoading', false);
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    _.set(this, key, value);
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  async submit() {
    this.isLoading = true;
    try {
      // If changed no show checkbox
      if (this.markedNoShow !== /noshow/i.test(this.assignment.status)) {
        // ATTN: Assignment Status EP-5523
        const data = {
          status: this.markedNoShow ? 'NoShow' : 'Completed',
          uuid: _.get(this.assignment, 'uuid', this.assignment), // ATTN: Assignment Status EP-5523
        };
        // TODO should completedAt be set on backend?
        //  Should it show current time, checkOutTime or end of shift?
        await dispatch('assignments.update', { data });
      } else {
        const user = dispatch('auth.getUser').uuid;
        const data = {
          checkIn: {
            timestamp: moment(this.checkInTime),
            user,
          },
          checkOut: {
            timestamp: moment(this.checkOutTime),
            user,
          },
          uuid: _.get(this.assignment, 'uuid', this.assignment),
        };
        // Only patch times that changed
        if (_.has(this.assignment, 'checkIn.timestamp')) {
          if (
            moment(this.checkInTime).isSame(
              moment(this.assignment.checkIn.timestamp),
              'minute',
            )
          ) {
            delete data.checkIn;
          }
        }
        if (_.has(this.assignment, 'checkOut.timestamp')) {
          if (
            moment(this.checkOutTime).isSame(
              moment(this.assignment.checkOut.timestamp),
              'minute',
            )
          ) {
            delete data.checkOut;
          }
        }

        if (this.displayedField === 'checkin') delete data.checkOut;

        // If times not changed, do nothing
        if (_.has(data, 'checkIn') || _.has(data, 'checkOut')) {
          await dispatch('assignments.update', { data });
        } else {
          log.debug('Check-in/out times unchanged');
        }
      }

      this.open(false);
    } catch (err) {
      log.error('Failed to update checkIn and checkOut time', err);
      this.set(
        'error',
        'Failed to update check-in and check-out time, please try again later',
      );
    }
    this.set('isLoading', false);
  }

  @action
  clear(args = {}) {
    this.isOpen = !!args.open;
    this.isLoading = false;
    this.error = '';
    this.assignment = null;
    this.checkInTime = null;
    this.checkOutTime = null;
    this.displayedField = '';
  }
}
