import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class AppealShiftResultModal {
  log = getChildLogger('ui.AppealShiftResultModal');

  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  assignment = {};

  @action
  async setup({ assignmentId = '', open = this.isOpen }) {
    this.set('isLoading', true);
    const assignmentResult = await dispatch('assignments.get', assignmentId);

    this.set('assignment', assignmentResult);
    this.open(open);
    this.set('isLoading', false);
  }

  @action
  clear(args = {}) {
    this.isOpen = !!args.open;
    this.isLoading = false;
    this.assignment = {};
  }

  @action
  set(key, value) {
    _.set(this, key, value);
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }
}
