import type { IWorker, IPool } from '@shiftsmartinc/shiftsmart-types';

import { action, computed, observable, runInAction, flow } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';
import { getStore } from '#/shared/getStores';

const log = getChildLogger('ui.poolDetails');

export default class PoolDetails {
  @observable
  activeTab = 'partners';

  @observable
  activeTabIndex = 0;

  @observable
  activePartnersTabIndex = 0;

  @observable
  activePartnersTab = 'included';

  @observable
  activeDetailsTab = 'summary';

  @observable
  isEditing = false;

  @observable
  isTitleEditing = false;

  @observable
  confirmDeleteModalText = null;

  @observable
  confirmDeleteModalIsOpen = false;

  @observable
  confirmSaveModalText = null;

  @observable
  confirmSaveModalIsOpen = false;

  @observable
  confirmSaveModalSaveAction = null;

  @observable
  isWorkersLoaded = false;

  @observable
  includedWorkers = [];

  @observable
  excludedWorkers = [];

  @observable
  inactiveWorkers = [];

  @observable
  addedWorkers = [];

  @observable
  removedWorkers = [];

  @observable
  company = {};

  @computed
  get companyId() {
    return this.company?.uuid;
  }

  @computed
  get companyPath() {
    return this.company?.path;
  }

  pool: IPool | Record<string, never> = {};

  @observable label = '';

  @observable
  workerSource = 'poolPartners';

  @observable
  isLoading = false;

  @observable
  showInfoPane = false;

  @observable
  showPoolCreateModal = false;

  @action.bound
  async setup({ pool: srcPool, company }) {
    this.setLoading(true);
    let pool;

    if (_.isString(srcPool) && /create/i.test(srcPool)) {
      // TODO: Should company be an object, or force it to a string
      pool = { company, isNew: true, search: {}, uuid: null };
    } else if (_.isString(srcPool)) {
      pool = await dispatch('pools.get', srcPool, { select: false });
    } else {
      pool = srcPool;
    }

    const poolId = _.get(pool, 'uuid', pool);

    if (!_.isEmpty(poolId)) {
      dispatch('ui.chatMessaging.loading', true);
    }

    const authdCompany = dispatch('auth.getCompany');
    const poolCompanyId = pool.company?.uuid ?? pool.company;

    const companyId = poolCompanyId || company?.uuid || company;
    let companyObj = authdCompany;

    if (companyId !== companyObj?.uuid) {
      companyObj =
        companyId === company?.uuid
          ? company
          : await dispatch('companies.get', companyId, { select: false });
    }

    runInAction(() => {
      this.company = companyObj;
    });

    if (!!poolId && dispatch('auth.cannot', 'read', pool)) {
      this.setLoading(false);
      const err = new Error('Not Authorized: Unable to read selected pool');
      err.code = 401;
      throw err;
    }

    await this.clear();

    try {
      dispatch('things.emptyList');

      const companyIds = [this.companyId];

      dispatch('quals.find', {
        query: { companies: { $in: companyIds } },
      });

      dispatch('positions.find', {
        query: { companies: { $in: companyIds } },
      });

      dispatch('pools.setSelected', pool);
      this.setPool(pool);

      /**
       *  TODO: EP-949 Do we want to check the companies array anymore?
       *
       * 1. Check companyStatus array instead (ensures active)
       * 2. Don't check company at all, rely on pools array on user object
       *    to be maintained and up to date
       * 3. Check the `companyStatus` array instead
       */
      const poolMembersQuery = {};

      // If we have an existing pool to load ...
      if (!_.isEmpty(poolId)) {
        _.extend(poolMembersQuery, {
          'pools.uuid': poolId,
        });
        // this.startPoolChat({ pool, user, loadOnly: true });
      }
      // Otherwise, assume we are creating a new pool
      else {
        this.editing(true);
      }

      // TODO: DO both of these always need to run? or can we
      // only setup searches if creating/editing?
      await Promise.all([
        dispatch(`${this.workerSource}.findByCompany`, {
          company: this.companyId,
          opts: {
            noQuery: !!poolId,
          },
          query: poolMembersQuery,
        }),
        dispatch('ui.searches.setup', {
          company: this.company,
          loadWorkers: false,
          search: this.pool,
          workerSource: this.workerSource,
        }),
      ]);
    } catch (err) {
      log.error('Failed to run setup on ui.PoolDetails', err);

      dispatch('ui.chatMessaging.loading', false);
      this.setLoading(false);

      throw err;
    }

    this.loadExcludedWorkers(pool);
    this.loadInactiveWorkers(pool);
    dispatch('ui.chatMessaging.loading', false);
    if (!this.isEditing) {
      setTimeout(() => {
        this.setLoading(false);
      }, 2000);
    } else {
      this.setLoading(false);
    }

    this.setActivePartnersTab(0, 'included');
    return this.pool;
  }

  @action
  setPool(value = {}) {
    this.pool = value;
    this.label = _.get(value, 'label') || '';
  }

  @action
  setLabel(value) {
    this.label = value || '';
    _.set(this.pool, 'label', this.label);
  }

  @action
  setLoading(val = true) {
    log.debug('PoolLoading is pre: %s => %s', this.isLoading, val);
    this.isLoading = val;
    log.debug('PoolLoading post: %s ?= %s', this.isLoading, val);
  }

  @action
  editing(arg = !this.isEditing) {
    this.isEditing = !!arg;

    this.setActiveDetailsTab('summary');
  }

  @action
  editingTitle(arg = !this.isTitleEditing) {
    this.isTitleEditing = !!arg;
  }

  @action
  workersLoaded(arg = !this.isWorkersLoaded) {
    this.isWorkersLoaded = !!arg;
  }

  @action
  confirmDeleteModal(arg = !this.confirmDeleteModalIsOpen, text) {
    this.confirmDeleteModalIsOpen = !!arg;

    if (this.confirmDeleteModalIsOpen) {
      this.confirmDeleteModalText = text;
    } else {
      this.confirmDeleteModalText = null;
    }
  }

  @action
  confirmSaveModal(
    arg = !this.confirmSaveModalIsOpen,
    text,
    confirmSaveModalSaveAction,
  ) {
    this.confirmSaveModalIsOpen = !!arg;

    if (this.confirmSaveModalIsOpen) {
      this.confirmSaveModalText = text;
      this.confirmSaveModalSaveAction = confirmSaveModalSaveAction;
    } else {
      this.confirmSaveModalText = null;
      this.confirmSaveModalSaveAction = null;
    }
  }

  @action
  setActiveTab(tabIndex, tab) {
    this.activeTab = tab;
    this.activeTabIndex = tabIndex;
  }

  @action
  setActivePartnersTab(tabIndex, tab) {
    this.activePartnersTab = tab;
    this.activePartnersTabIndex = tabIndex;
  }

  @action
  setActiveDetailsTab(tab) {
    this.activeDetailsTab = tab;

    if (/summary|workers|chat|filters/.test(this.activeDetailsTab)) {
      this.toggleInfoPane(true);
    }
  }

  @action
  toggleInfoPane(state = !this.showInfoPane) {
    this.showInfoPane = state;
  }

  @action
  togglePoolCreateModal(val = !this.showPoolCreateModal) {
    this.showPoolCreateModal = val;
  }

  loadThingsForPool(pool = '') {
    const uuid = _.get(pool, 'uuid', pool);
    if (_.isEmpty(uuid)) {
      return Promise.resolve();
    }

    return Promise.all([
      dispatch(
        'things.find',
        { query: { __t: { $exists: false }, pool: uuid, status: 'open' } },
        { clear: true, noDate: true },
      ),
      dispatch(
        'shifts.find',
        { query: { pool: uuid, status: 'open' } },
        { clear: true, noDate: true },
      ),
    ])
      .then((res) => {
        log.debug('loaded matching things: ', { things: res });
      })
      .catch((err) => {
        log.error('Failed to load things for pool', err);
      });
  }

  @action
  calcWorkerIntersections({ pool, poolMembers, allWorkers }) {
    let addedWorkers = [];
    let removedWorkers = [];

    if (this.isEditing) {
      this.includedWorkers = _.filter(poolMembers, (w) =>
        _.includes(pool.search.include || [], w.uuid),
      );

      const xord = _.xorBy(poolMembers, allWorkers, 'uuid');
      addedWorkers = _.filter(
        xord,
        (w) => !!_.find(allWorkers, { uuid: w.uuid }),
      );
      removedWorkers = _.filter(
        xord,
        (w) =>
          !_.includes(pool.search.include, w.uuid) &&
          !!_.find(poolMembers, { uuid: w.uuid }),
      );

      log.debug('Worker Intersections: ', {
        added: addedWorkers.length,
        existing: poolMembers.length,
        included: this.includedWorkers.length,
        removed: removedWorkers.length,
      });
    }

    this.addedWorkers.replace(addedWorkers);
    this.removedWorkers.replace(removedWorkers);

    return {
      addedWorkers,
      removedWorkers,
    };
  }

  async recalcPool(pool: IPool) {
    if (pool.poolType !== 'smart') {
      return;
    }

    const res = await getStore('pools').update({
      data: {},
      id: pool.uuid,
      query: { $client: { action: 'recalculate' } },
    });

    if (res?.result === 'OK') {
      getStore('ui.snackBar').open('Pool Recalculating', {
        message: res.message,
      });
    } else {
      getStore('ui.snackBar').open('Recalculation Failed', {
        message: res.message,
      });
    }
  }

  @action
  loadAvailableWorkers(pool, load = true) {
    if (load) {
      return dispatch(
        'partners.find',
        {
          query: { 'pools.uuid': { $ne: _.get(pool, 'uuid', pool) } },
        },
        {
          clear: true,
          preserve: ['companies', 'companyStatus'],
        },
      ).then((res) => {
        log.debug(
          'Loaded %d Workers not in the %s pool',
          res.length,
          pool.title,
        );
        this.workersLoaded(true);
      });
    }

    return dispatch('partners.removeQueryKey', 'pools.uuid').then(() =>
      this.workersLoaded(false),
    );
  }

  @action
  loadExcludedWorkers(pool) {
    const exclude = _.get(pool || this.pool, 'search.exclude');
    if (_.isEmpty(exclude)) {
      return;
    }
    dispatch('partners.runQuery', { uuid: { $in: exclude } }).then(
      action(({ data: excluded, total }) => {
        this.excludedWorkers.replace(excluded);

        if (exclude.length < total) {
          this.log.warn(
            'Not all excluded workers were loaded. Pagination may be required',
          );
        }
      }),
    );
  }

  @action
  loadInactiveWorkers(pool) {
    const inactive = _.get(pool || this.pool, 'search.inactive');
    if (_.isEmpty(inactive)) {
      return;
    }
    dispatch('partners.runQuery', { uuid: { $in: inactive } }).then(
      action(({ data }) => {
        this.inactiveWorkers.replace(data);
      }),
    );
  }

  @action
  hideInactiveWorkers() {
    this.excludedWorkers.clear();
  }

  addUser({ pool, excludedWorkers }) {
    return async (user) => {
      let addUserToPoolPromise = Promise.resolve();
      const userId = _.get(user, 'uuid', user);

      const companyId = _.get(pool.company, 'uuid', pool.company);
      let status;

      // 1. Check Company Status to ensure partner is eligible to be added.
      if (_.has(user, 'companyStatus')) {
        ({ status } = _.find(user.companyStatus, { company: companyId }) || {});
      } else {
        const u =
          (await dispatch('partners.get', userId, {
            query: { $select: ['companyStatus'] },
          })) || {};
        ({ status } = _.find(u.companyStatus, { company: companyId }) || {});
      }

      if (!status) {
        log.error('Unable to determine Company Status for User', {
          extra: { company: companyId, user: userId },
        });
      } else if (/inactive|former/i.test(status)) {
        log.warn(`Cannot add ${status} worker to a pool`, {
          extra: { company: companyId, user: userId },
        });
        dispatch(
          'ui.snackBar.open',
          `Cannot add ${status} workers to pools. Please reactivate their account before continuing`,
        );

        return;
      }

      // 2. Add worker to pool
      if (_.isEmpty(_.get(pool, 'uuid', pool))) {
        // 2a. Directly if pool has not been created
        runInAction(() => {
          this.includedWorkers.push(user);
        });
      } else {
        // 2b. Via addToPool method for existing pools
        addUserToPoolPromise = dispatch('pools.addToPool', {
          companies: pool.companies,
          pool,
          worker: user,
        });
      }

      await addUserToPoolPromise;

      const prevExcludedWorker = _.find(excludedWorkers, { uuid: userId });

      if (prevExcludedWorker) {
        runInAction(() => {
          _.remove(excludedWorkers, { uuid: userId });
        });

        dispatch(
          'ui.searches.setExcluded',
          { users: _.map(excludedWorkers, 'uuid') },
          { reset: true },
        );

        if (excludedWorkers.length + this.inactiveWorkers.length === 0) {
          this.setActivePartnersTab(0, 'included');
        }
        console.assert(
          !_.find(excludedWorkers, { uuid: userId }),
          'User should no longer be in the removed workers array',
        );
      }
    };
  }

  excludeUser({ pool, poolMembers = [] }) {
    return async (user) => {
      if (!_.isEmpty(_.get(pool, 'uuid', pool))) {
        // TODO: As currently built, this just uses the list on the frontend, and will delete pools that should not be. Fix or remove [Pavan 2022-11-01]
        const poolMembersCount = poolMembers?.length - 1 || 0;
        log.silly('pool members count: ', poolMembersCount);
        // if (poolMembersCount <= 0) {
        //   this.confirmDeleteModal(
        //     true,
        //     'Excluding this user will result in no users for this pool, so the pool will be deleted. Are you sure?',
        //   );
        // } else {
        try {
          await dispatch('pools.removeWorkerFromPool', {
            pool,
            poolAction: 'exclude',
            worker: user,
          });
          dispatch('ui.searches.setExcluded', {
            user: _.get(user, 'uuid', user),
          });
          runInAction(() => {
            this.excludedWorkers.push(user);
          });
        } catch (err) {
          log.error('Error excluding user from pool', err);
        }
        //}
      }
    };
  }

  startPoolChat({ pool, loadOnly, user }) {
    if (_.get(pool, 'poolType') === 'upload') {
      return false;
    }
    if (!_.isEmpty(pool.chatId)) {
      return dispatch('chatChannels.doChannelSelect', {
        channel: pool.chatId,
      }).catch((err) => log.error('Error Loading Pool Chat', err));
    }

    if (loadOnly) return Promise.resolve('Not Creating new Channel');

    dispatch('ui.loadingModal.open');
    return dispatch('chatChannels.createPoolChannel', {
      company: this.companyId,
      pools: [pool],
      sender: user,
    })
      .then((res) => {
        dispatch('chatChannels.doChannelSelect', { channel: res.uuid });
      })
      .catch((err) => {
        log.error('Error Loading Pool Chat', err);
      })
      .finally(() => dispatch('ui.loadingModal.close'));
  }

  @action
  deselectPool() {
    return Promise.all([
      dispatch('chatChannels.clearSelected'),
      dispatch('pools.clearSelected'),

      dispatch('ui.searches.clear', { resetStores: true }),
      dispatch(`${this.workerSource}.setCertFilter`, { op: 'set' }),
      dispatch(`${this.workerSource}.emptyList`),
      dispatch(`${this.workerSource}.resetSearch`),
    ]);
  }

  @action.bound
  async save(opts) {
    dispatch('ui.searches.setLoading', 'save', true);
    const poolData = dispatch('ui.searches.getSaveableSearchData');

    const workers = dispatch(`${this.workerSource}.retrieve`, 'list');
    const { total } = dispatch(`${this.workerSource}.retrieve`, 'pagination');

    const hasMore =
      total > _.size(workers) || (opts?.force && !_.size(workers));

    try {
      // Note: saveFilter swalows all errors :(
      const pool = await this.saveFilter({
        hasMore,
        pool: poolData,
        workers: _.union(workers, _.get(poolData.search, 'include') || []),
      });

      log.debug('Created new Pool', { pool });

      return pool;
    } catch (err) {
      log.error('Error saving pool', { data: poolData, error: err });
    } finally {
      setTimeout(() => dispatch('ui.searches.setLoading', 'save', false), 500);
    }
  }

  @action
  saveFilter({
    pool,
    workers,
    hasMore,
    title,
  }: {
    hasMore: boolean;
    pool: Partial<IPool>;
    title?: string;
    workers: IWorker[];
  }) {
    let search = _.get(pool, 'search');
    // TODO: Compare with "savableSearchData"
    if (_.isEmpty(search)) {
      search = {
        adherence: dispatch('ui.searches.get', 'adherence'),
        customMetrics: dispatch('ui.searches.get', 'customMetrics'),
        optionalTags: dispatch('ui.searches.get', 'allOptionalTags'),
        rating: dispatch('ui.searches.get', 'rating'),
        tags: dispatch('ui.searches.get', 'allTags'),
      };
      if (!_.get(search.rating, 'active')) {
        delete search.rating;
      }
      if (!_.get(search.adherence, 'active')) {
        delete search.adherence;
      }
    }

    if (pool.uuid) {
      log.debug(`Saving Changes to Smart Pool ${pool.uuid} with filter: `, {
        search,
      });

      const searchProps = _.mapKeys(search, (val, key) =>
        ['search', key].join('.'),
      );
      if (_.isNull(searchProps['search.include'])) {
        delete searchProps['search.include'];
      }
      const data = {
        hasMore,
        smartPool: true,
        uuid: pool.uuid,
        workers: workers.map((w) => _.get(w, 'uuid', w)),
        ...searchProps,
      };

      data.queryString = JSON.stringify(
        dispatch(`${this.workerSource}.retrieve`, 'query.query'),
      );
      this.setLoading(true);
      return dispatch('pools.update', {
        data,
        query: {
          $client: { action: 'add' },
        },
      }).finally(() => {
        dispatch('ui.poolDetails.editing', false);
        dispatch('poolMembers.setIsUpdatingPoolFilterCriteria', true);
      });
    }

    log.debug('saveNewPool');
    const label = this.label || pool.label || title;

    search.include = _.isEmpty(search.include)
      ? _.map(this.includedWorkers, (w) => _.get(w, 'uuid', w))
      : search.include;
    search.exclude = _.isEmpty(search.exclude)
      ? _.map(this.excludedWorkers, (w) => _.get(w, 'uuid', w))
      : search.exclude;

    const companies = pool.companies || [
      _.get(pool.company, 'uuid', pool.company),
    ];
    const company = pool.company || _.first(companies);
    const companyPath = pool.companyPath || this.companyPath;

    const newPoolData = _.defaults(
      {
        companies,
        company,
        companyPath,
        hasMore,
        label,
        search,
        workers: workers.map((w) => _.get(w, 'uuid', w)),
      },
      pool,
    );

    newPoolData.queryString = JSON.stringify(
      dispatch(`${this.workerSource}.retrieve`, 'query.query'),
    );

    let newPool;

    return dispatch('pools.create', { data: newPoolData })
      .then((result) => {
        dispatch('routing.replace', `/pools/${result.uuid}`);

        newPool = result;

        // if (_.isEmpty(result.users)) {
        const users = _(workers)
          .map((w) => _.pick(w, ['uuid', 'displayName', 'profileImageURL']))
          .reject(_.isEmpty)
          .value();
        _.extend(result, {
          users,
        });
        // }

        log.debug(
          'Changing Worker Count from %d to %d',
          result.workerCount,
          workers.length,
        );
        _.extend(result, { workerCount: workers.length });

        return dispatch('pools.setSelected', result);
      })
      .then((result) => {
        log.debug('got result (selected) pool', result);
        return Promise.all([
          dispatch('ui.poolDetails.editing', false),
          dispatch('ui.searches.clearWorkerStoreFilters'),
        ]);
      })
      .then(
        action(() => {
          // TODO: Determine if needed or harmful
          log.debug('Extending new Smart Pool with filter: ', {
            search,
          });
          _.extend(pool, {
            search,
            workers: workers.map((w) => _.get(w, 'uuid', w)),
          });
          return pool;
        }),
      )
      .then(() => {
        dispatch(`${this.workerSource}.findByCompany`, {
          company: newPool.company,
          query: { 'pools.uuid': newPool.uuid },
        });
      })
      .then(() => {
        dispatch('pools.updateListWithSelected');

        return newPool;
      })
      .catch((err) => {
        log.error('Failed to save pool', err);
        const errors = _.get(err, 'errors');

        if (_.has(errors, 'label') && _.isEmpty(pool.label)) {
          dispatch('ui.snackBar.open', 'Please enter a Label for this Pool');
        }

        dispatch('ui.snackBar.open', 'Failed to save pool');
      });
  }

  /** clear
   * This is a test of `mobx.flow` + a generator function. In this use case,
   * the utility is limited, but gets around the case of async store actions
   * throwing strict-mode-violations for any code that modifies an observable
   * after an `await`, and eliminates the need to wrap code in `runInAction`
   * blocks.
   */
  clear = flow(function* clearFlow() {
    this.deselectPool();

    yield dispatch(
      `${this.workerSource}.find`,
      {},
      { clear: true, noQuery: true },
    );

    this.activeTab = 'partners';
    this.activeDetailsTab = 'summary';
    this.showInfoPane = false;
    this.isEditing = false;
    this.isTitleEditing = false;
    this.confirmDeleteModalText = null;
    this.confirmDeleteModalIsOpen = false;
    this.confirmSaveModalText = null;
    this.confirmSaveModalIsOpen = false;
    this.confirmSaveModalSaveAction = null;

    this.isWorkersLoaded = false;
    this.includedWorkers.clear();
    this.excludedWorkers.clear();

    this.pool = {};
  });

  @action
  reset() {
    this.isEditing = false;
    this.isTitleEditing = false;

    return dispatch('pools.get', this.pool.uuid, {
      select: false,
    }).then((poolObj) =>
      dispatch('ui.searches.initSearch', { search: poolObj }),
    );
  }

  /** Old Stuff & Un-migrated code */

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    this[key] = value;
    return Promise.resolve(this[key]);
  }

  get(arg) {
    return this[arg];
  }

  /* Vars */
  @computed
  get detailsTabs() {
    return _.compact([
      {
        index: 0,
        key: 'summary',
        title: 'Filter Criteria',
      },
      // {
      //   key: 'filters',
      //   icon: 'filter',
      //   title: 'Editing Pool Criteria',
      // },
      {
        disabled: () => this.isEditing,
        hidden: ({ pool }) => /auto/.test(pool.poolType),
        index: 1,
        key: 'workers',
        onSelect: ({ pool }) =>
          dispatch('ui.poolDetails.loadAvailableWorkers', pool),
        title: 'Add Partners',
      },
      {
        disabled: ({ pool }) => this.isEditing || /auto/.test(pool.poolType),
        index: 3,
        key: 'chat',
        onSelect: ({ pool, user }) =>
          dispatch('ui.poolDetails.startPoolChat', { pool, user }),
        title: 'Chat',
      },
    ]);
  }

  @observable
  poolTitleRef = null;

  @observable
  poolNotesRef = null;

  // #region Local Functions
  setTitleRef(ref) {
    this.poolTitleRef = _.get(ref, 'current', ref);
  }

  setNotesRef(ref) {
    this.poolNotesRef = _.get(ref, 'current', ref);
  }

  getTitleRef() {
    return this.poolTitleRef;
  }

  getNotesRef() {
    return this.poolNotesRef;
  }

  setTitle(selected: IPool) {
    return action((e) => {
      if (e) {
        e.stopPropagation();
      }
      const val = _.get(this.poolTitleRef, 'value');
      log.debug('Setting New Pool Label to "%s"', val);
      selected.label = val; // eslint-disable-line no-param-reassign

      const notes = _.get(this.poolNotesRef, 'value');
      log.debug('Setting New Pool Notes to "%s"', notes);
      selected.notes = notes; // eslint-disable-line no-param-reassign
    });
  }
}
