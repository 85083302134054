import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';
const log = getChildLogger('forms.employerUser');

class EmployerUserForm extends Form {
  hooks = () => ({
    onSuccess(form) {
      const uuid = form.initials().uuid || undefined;

      let storeAction = 'users.create';
      let successMsg = 'New Employer Account Created';
      let isNew = true;
      const createAdmin = form.initials().createAdmin || false;

      if (uuid) {
        storeAction = 'users.update';
        successMsg = 'Employer Account Saved';
        isNew = false;
      }

      const data = form.values();

      if (_.has(data, 'phoneNumber')) {
        data.phoneNumber =
          data.phoneNumber.indexOf('+1') < 0
            ? `+1${data.phoneNumber.slice(-10)}`
            : data.phoneNumber;
      }

      if (_.has(data, 'firstName') || _.has(data, 'lastName')) {
        data.displayName = `${_.get(
          data,
          'firstName',
          form.initials().firstName,
        )} ${_.get(data, 'lastName', form.initials().lastName)}`;
      }

      if (isNew) {
        data.companies = form.initials().companies;
      }

      // Create Admin
      if (createAdmin) {
        data.roles = ['user', 'admin'];
      } else {
        data.roles = ['user', 'employer'];
      }

      // require MFA for all employer users
      data.requireMFA = true;

      log.debug(`Calling "${storeAction}" with values: `, data);
      dispatch(storeAction, { data, id: uuid })
        .then((res) => {
          log.debug('Created new Employer User: ', { res });
          if (data.isOwner) {
            data.companies.map((c) =>
              dispatch('companies.update', {
                data: { owner: res.uuid, uuid: c },
              }),
            );
          }
        })
        .then(() => dispatch('ui.employerEditModal.open', false))
        .then(() => dispatch('ui.snackBar.open', successMsg))
        .then(() => form.clear())
        .catch((err) => {
          log.error('Employer User Form saving failed: ', err);
          form.invalidate(err.message);
          dispatch('ui.snackBar.open', err.message);

          if (!_.isEmpty(err.errors)) {
            form.each((field) => {
              if (_.isEmpty(err.errors[field.key])) {
                field.validate();
              }

              field.invalidate();
            });
          }
        });
    },
  });
}

const fields = [
  'uuid',
  'firstName',
  'lastName',
  'email',
  'phoneNumber',
  'companies',
  'position',
  'isOwner',
];

const rules = {
  companies: 'array',
  email: 'email|string|between:5,50',
  firstName: 'required|string|between:2,50',
  isOwner: 'boolean',
  lastName: 'required|string|between:2,50',
  phoneNumber: 'string|between:10,12',
  position: 'string|between:2,30',
  uuid: '',
};

const labels = {
  companies: 'Companies',
  email: 'Email Address',
  firstName: 'First Name',
  isOwner: 'Assign this user as "Owner"?',
  lastName: 'Last Name',
  phoneNumber: 'Mobile Phone',
  position: 'Position (optional)',
  uuid: '',
};

export function init(values = {}) {
  log.debug('Initialzing Employer User form w/ Values: ', values);
  return new EmployerUserForm(
    { fields, labels, rules, values },
    { options: { retrieveOnlyDirtyValues: true } },
  );
}
