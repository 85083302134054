import type { StoreName } from '#/shared/stores';
import type { IZone, CompanySettings } from '@shiftsmartinc/shiftsmart-types';

import {
  observable,
  action,
  set,
  runInAction,
  computed,
  IObservableArray,
} from 'mobx';
import BBPromise from 'bluebird';
import _ from 'lodash';
import flatten from 'flat';
import isUUID from 'uuid-validate';

import BaseUploader, {
  type KnownFieldConfig,
} from '#/shared/stores/ui/BaseUploader';
import { toBoolean } from '#/utils/toBoolean';
import { UUID_GROUP_OPPORTUNITIES } from '#/config/uuids';
import { toNullableBoolean } from '#/utils/toNullableBoolean';
import { getStore, getStores } from '#/shared/getStores';

export default class AddressUploader extends BaseUploader {
  constructor() {
    super({ title: 'ui.AddressUploader' });

    set(this.saveResults, {
      ignored: [],
      invalid: [],
      new: [],
      updated: [],
    });

    return this;
  }

  @observable
  externalIdIsUnique = false;

  @action.bound
  toggleExternalIdIsUnique(val = !this.externalIdIsUnique) {
    this.externalIdIsUnique = val;
  }

  storeName: StoreName & ('sites' | 'zones');

  @observable parseOptions = {
    dynamicTyping: false,
    header: false,
    skipEmptyLines: true,
  };

  /** list
   * Stores an array of rows, Ready for saving.
   */
  list: IObservableArray = observable([]);

  @observable
  selectedTier;

  zoneList: IObservableArray = observable([]);

  /**
   * Parsed Headers
   * An array of all headers found in the parsed objects
   *
   * Used to render the column headers in a dynamic table.
   *
   * @memberof AddressUploader
   */
  parsedHeaders: IObservableArray = observable([]);

  resultHeaders: IObservableArray = observable([]);

  @computed
  get knownFields(): KnownFieldConfig[] {
    const isSite = /^site/.test(this.storeName);
    const isZone = /^zone/.test(this.storeName);
    const isOpportunityCompany = _.includes(
      UUID_GROUP_OPPORTUNITIES,
      this.company.uuid,
    );

    const tierFields = _.flatMap(
      this.tierSettings,
      ({ key, index }): KnownFieldConfig[] => [
        {
          key: _.snakeCase(`tier ${index} uuid`),
          mapping: `parentIds.${index}`,
          note: `The UUID of the tier #${index} parent for this location. For ${
            this.company?.name || 'this company'
          }, this maps to the "${key}"`,
        },
        {
          key: _.snakeCase(`tier ${index} name`),
          note: `When first uploading stores, before parentIds have been uploaded, this field can be used to specify the name of the parent tier #${index} for this location. For ${
            this.company?.name || 'this company'
          }, this maps to the "${key}". Any new tiers will be created "*" appended to the newly created parent. IMPORTANT: Please upload tiers first if possible, starting from the top tier.`,
        },
        {
          isExampleField: false,
          key: _.snakeCase(`tier ${index} external id`),
          noDL: true,
        },
      ],
    );

    const fieldsConfig: KnownFieldConfig[] = [
      { exampleVal: '', key: 'uuid' },
      ...(isSite || isZone ? tierFields : []),

      !isZone && {
        exampleVal: '123 Fake St.',
        key: 'street1',
        mapping: 'address.street1',
      },
      !isZone && {
        exampleVal: 'Unit #7',
        key: 'street2',
        mapping: 'address.street2',
      },
      !isZone && {
        exampleVal: 'Springfield',
        key: 'city',
        mapping: 'address.city',
      },
      !isZone && {
        exampleVal: 'NO',
        key: 'state',
        mapping: 'address.state',
      },
      !isZone && {
        exampleVal: '43928',
        key: 'zip',
        mapping: 'address.zip',
      },
      !isZone && {
        exampleVal: '',
        key: 'lat',
        mapping: 'loc.coordinates[1]',
        transform: _.toNumber,
      },
      !isZone && {
        exampleVal: '',
        key: 'lng',
        mapping: 'loc.coordinates[0]',
        transform: _.toNumber,
      },

      // common subclass keys
      (isSite || isZone) && {
        exampleVal: isZone ? '' : 'Leftorium Store A1',
        key: 'name',
        note: `An identifying name for the location. EG: "Springfield Mall" or "Leftorium #A1". Typically a combination of 'brand' and 'storeNumber'`,
      },

      isSite && {
        exampleVal: '8889761237',
        key: 'phone',
      },
      isSite && {
        exampleVal: 'Leftorium',
        key: 'brand',
        note: 'The "brand"/"banner"/"chain" name of the location. EG: "Kwik-E-Mart" or "Sears"',
      },
      isSite && {
        exampleVal: '',
        key: 'storeNumber',
        note: 'A non-unique identifier string for the location. Do not include the `#` symbol',
      },
      (isSite || isZone) && {
        exampleVal: '',
        key: 'externalId',
        note: `A reference to a unique identifier in the customer's remote system. Should be unique to this company`,
      },
      (isSite || isZone) && {
        exampleVal: '',
        key: 'notes',
        note: `Optional notes to include about this location that may be shown to a partner. EG: "Please park at the back of the store in the 'employee parking' area."`,
      },
      !isZone && { isExampleField: false, key: 'placeId' },

      // site keys
      isSite && {
        exampleVal: 'HQ',
        key: 'abbr',
        note: `An internally referenced unique value, should be 10 characters or less. EG: "TRGT-0373"`,
      },
      isSite && {
        exampleVal: null,
        key: 'defaultInviteRadius',
        transform: _.toNumber,
      },

      {
        isNullable: true,
        key: 'isActive',
        transform: toNullableBoolean,
      },

      // Supported but not "core" fields
      { isExampleField: false, key: 'path' },
      { isExampleField: false, key: 'companies' },
      { isExampleField: false, key: 'formattedAddress' },
      { isExampleField: false, key: 'isDeleted', transform: toBoolean },
      { isExampleField: false, key: 'source' },

      isOpportunityCompany && {
        exampleVal: false,
        key: 'enableOpportunities',
        mapping: 'settings.opportunities',
        transform: toBoolean,
      },
    ];

    return _(fieldsConfig)
      .compact()
      .map((field) => _.defaults(field, { isExampleField: true }))
      .value();
  }

  @computed get knownHeaders() {
    return _.map(this.knownFields, 'key');
  }

  @action getDownloadHeaders() {
    const headers = _(this.knownFields)
      .reject('noDL')
      .map((field) => ({
        label: field.key,
        value: field.mapping || field.key,
      }))
      .value();

    return headers;
  }

  @computed
  get csvData() {
    return this.$sampleCSVData;
  }

  @computed
  get tierSettings() {
    return _(this.company?.settings?.zones?.tiers)
      .filter(
        (setting) =>
          setting.isActive &&
          (this.selectedTier ? setting.index < this.selectedTier.index : true),
      )
      .sortBy(['index'])
      .value() as CompanySettings['zones']['tiers'];
  }

  @action
  async setup(opts) {
    super.setup(opts);

    this.clear();

    this.storeName = opts.storeName || 'addresses';
    this.company = opts.company;

    if (_.isString(this.company)) {
      const company = await getStore('companies').get(this.company, {
        select: false,
      });
      runInAction(() => {
        this.company = company;
      });
    }
  }

  @action
  clear() {
    super.clear();

    this.saveResults = {
      dupe: [],
      ignored: [],
      incomplete: [],
      invalid: [],
      new: [],
      unassigned: [],
      updated: [],
    };

    this.list.clear();
    this.zoneList.clear();
    this.parsedHeaders.clear();
    this.resultHeaders.clear();
  }

  @action
  async processFile({ options = this.parseOptions, ...rest }) {
    this.setStatus('loading');

    try {
      await super.processFile({ options, ...rest });

      this.setStatus('loaded');
    } catch (err) {
      this.log.error('Failed to process file', err);

      this.setStatus('error');

      throw err;
    }
  }

  @action
  setSelectedTier(selectedTier) {
    if (selectedTier) {
      this.storeName = 'zones';
      this.selectedTier = selectedTier;
    } else {
      this.storeName = 'sites';
      this.selectedTier = null;
    }
  }

  getZoneFromLocal({
    name: lookupName,
    externalId: lookupExternalId,
    tier,
    list = this.zoneList,
  }: {
    externalId?: string;
    list?: Array<any>;
    name?: string;
    tier: number;
  }) {
    const retval = _.find(
      list,
      ({ externalId: existingExtId, name: existingName, tier: existingTier }) =>
        _.isEqual(existingTier, tier) &&
        /* Either exact name match, or name with "*" suffix (case insensitive) */
        ((lookupName &&
          existingName &&
          (_.isEqual(_.lowerCase(existingName), _.lowerCase(lookupName)) ||
            new RegExp(
              `^${_.escapeRegExp(lookupName)}( \\(provisional\\))$`,
              'i',
            ).test(existingName))) ||
          /* check against the externalId if provided */
          (existingExtId &&
            lookupExternalId &&
            _.isEqual(
              _.lowerCase(existingExtId),
              _.lowerCase(lookupExternalId),
            ))),
    );

    return retval;
  }

  @action
  async enlistUpdatableZoneData({
    row,
    tier,
    parentIds,
  }: {
    parentIds?: string[];
    row: Record<string, string>;
    tier: number;
  }): Promise<IZone['uuid'] | null> {
    const { zones } = getStores();
    const parentUUID =
      _.trim(row[_.snakeCase(`tier ${tier} uuid`)]) ||
      _.trim(row[_.camelCase(`tier ${tier} uuid`)]) ||
      row.parentIds?.[tier]; // TBD if needed

    const parentName =
      _.trim(row[_.snakeCase(`tier ${tier} name`)]) ||
      _.trim(row[_.camelCase(`tier ${tier} name`)]);

    let zone;

    if (isUUID(parentUUID)) {
      const existingVal = _.find(this.zoneList, { uuid: parentUUID });

      zone =
        existingVal ||
        (await zones.get(parentUUID, { select: false }).catch(_.noop));

      if (zone && !existingVal) {
        runInAction(() => {
          this.zoneList.push(zone);
        });
      }
    }

    if (zone?.uuid) {
      return zone.uuid;
    }

    const externalId =
      _.trim(row[_.snakeCase(`tier ${tier} external id`)]) ||
      _.trim(row[_.camelCase(`tier ${tier} external id`)]);

    if (
      _.isEmpty(parentName) &&
      _.isEmpty(externalId) &&
      _.isEmpty(parentUUID)
    ) {
      return null;
    }

    try {
      // looking for region in the local cache
      zone = this.getZoneFromLocal({
        externalId: externalId,
        name: parentName,
        tier: tier,
      });

      // looking for region data from API, since it does not appear in the local cache
      if (!zone) {
        zone = await zones.findOne({
          query: {
            companies: this.company?.uuid,
            ...(!!externalId && {
              externalId: { $options: 'i', $regex: `0*${externalId}$` },
            }),
            ...(!!parentName && {
              name: {
                $options: 'i',
                $regex: `${parentName}( \\(provisional\\))$`,
              },
            }),
            tier,
          },
        });

        if (zone) {
          runInAction(() => {
            this.zoneList.push(zone);
          });
        }
      }

      // update name when tierKey_name column found
      if (zone && zone.uuid) {
        return zone.uuid;
      }

      const newZoneName = `${parentName || externalId || parentUUID}*`;

      const newEntry = {
        companies: [this.company?.uuid || 'unknown'],
        externalId: externalId,
        name: newZoneName,
        parentIds: _.range(_.size(this.company?.settings?.zones?.tiers)).map(
          (index) => parentIds[index] || null,
        ),
        tier,
      };
      zone = await zones.create({ data: newEntry });

      runInAction(() => {
        this.zoneList.push(zone);
      });

      return zone.uuid;
    } catch (error) {
      this.log.error('Failed to process file', error);
    }
    return null;
  }

  @action
  async parentZoneSave() {
    const {
      ui: { loadingModal },
    } = getStores();
    /// enlist zone data in existing and new
    if (_.size(this.tierSettings) || this.selectedTier) {
      const list = await BBPromise.map(
        this.list,
        async (data) => {
          const ids = await _.reduce(
            this.tierSettings,
            async (parentIdsPromise, tierSetting) => {
              // As parentIds is getting from async function, we  have to wait for the result;
              const parentIds = await parentIdsPromise;
              const zoneId: IZone['uuid'] = await this.enlistUpdatableZoneData({
                parentIds,
                row: data,
                tier: tierSetting.index,
              });
              parentIds[tierSetting.index] = zoneId;

              runInAction(() => {
                delete data[tierSetting.key];
                delete data[_.snakeCase(`${tierSetting.key} id`)];
                delete data[_.snakeCase(`${tierSetting.key} name`)];
                delete data[_.snakeCase(`tier ${tierSetting.index} uuid`)];
                delete data[_.snakeCase(`tier ${tierSetting.index} name`)];
              });
              return Promise.resolve(parentIds);
            },
            BBPromise.resolve([null, null, null, null, null]),
          );

          const tier = this.selectedTier ? this.selectedTier.index : undefined;
          const parentIds = _.range(
            _.size(this.company?.settings?.zones?.tiers),
          ).map((index) => ids[index] || null);

          loadingModal.increment();

          if (data.uuid) {
            runInAction(() => {
              delete data.parentIds;
            });
          }

          return _.merge(
            _.cloneDeep(data),
            _.isEmpty(data.uuid)
              ? {
                  parentIds,
                  tier,
                }
              : {
                  ..._.reduce(
                    parentIds,
                    (state, uuid, index) => ({
                      ...state,
                      [`parentIds.${index}`]: uuid,
                    }),
                    {},
                  ),
                  tier,
                },
          );
        },
        { concurrency: 1 },
      );

      runInAction(() => {
        this.list.replace(list);
      });
    }
  }

  @action
  async parse({ rows }) {
    const {
      ui: { loadingModal },
    } = getStores();
    let parsedHeaders = [];
    const casedHeaders = _.map(rows[0], _.toLower);
    const indices = _.reduce(
      this.knownHeaders,
      (acc, header) => {
        acc[header] = _.indexOf(casedHeaders, _.toLower(header));
        return acc;
      },
      {},
    );

    this.log.debug('Loaded Table Indices', { indices });
    loadingModal.open({
      message: 'Parsing Rows',
      total: _.size(rows),
    });

    const validRows = _(rows)
      .map((row, i) => {
        if (!i) return null;

        this.log.debug(row);

        return _.reduce(
          this.knownHeaders,
          (acc, colKey) => {
            const colIndex = indices[colKey];

            if (colIndex >= 0) {
              acc[colKey] = row[colIndex];
            }

            return acc;
          },
          {},
        );
      })
      .reject((rowObj) => {
        const isReject = _.isEmpty(_.omitBy(rowObj, _.isEmpty));
        if (isReject) {
          loadingModal.increment();
        }
        return isReject;
      })
      .map((data) => this.doMapping({ data, mapping: this.knownFields }))
      .map((row) => {
        parsedHeaders = _.union(parsedHeaders, _.keys(row));

        if (_(row.loc?.coordinates).compact().size() === 2) {
          _.set(row, 'loc.type', 'Point');
        }

        loadingModal.increment();
        return row;
      })
      .compact()
      .value();

    this.parsedHeaders.replace(parsedHeaders);

    this.setStatus('Evaluating');

    // eslint-disable-next-line no-console
    console.table(validRows);
    runInAction(() => {
      this.list.replace(validRows);
    });

    this.setStatus('Parsed all Rows');
    loadingModal.close({
      message: 'Correlating Region & Markets',
    });
  }

  @action
  async save() {
    const {
      audit,
      ui: { loadingModal, snackBar },
    } = getStores();

    loadingModal.open({ message: 'processing results' });
    this.setStatus('saving');
    this.setSaving(true);

    audit.create({
      data: {
        action: 'Upload Addresses',
        extra: {
          file: _.pick(this.file, ['name', 'type', 'size']),
          serviceName: this.storeName,
        },
      },
    });

    try {
      loadingModal.open({
        message: 'Processing zone Records',
        total: _.size(this.list),
      });

      await this.parentZoneSave();

      const {
        newEntries = [],
        existingEntries = [],
        externalIdEntries = [],
      } = _.groupBy(this.list, ({ uuid, externalId }) => {
        if (uuid) {
          return 'existingEntries';
        }
        if (externalId && this.externalIdIsUnique) {
          return 'externalIdEntries';
        }

        return 'newEntries';
      });

      let externalExisting = 0;
      let externalNew = 0;

      loadingModal.open({
        message: 'Checking entries with externalId',
        total: _.size(externalIdEntries),
      });

      const store = getStore(this.storeName);

      // TODO: External IDs are checked in the api `/sites/hooks.before:checkExisting`; use that instead
      await BBPromise.map(
        externalIdEntries,
        async (data) => {
          try {
            const existing = await store.findOne({
              query: {
                companies: this.company.uuid,
                externalId: data.externalId,
              },
            });

            if (existing?.uuid) {
              existingEntries.push({ ...data, uuid: existing.uuid });
              externalExisting += 1;
              return;
            }
          } catch (err) {
            this.log.error('Unable to load existing from externalId', {
              data,
              externalId: data.externalId,
            });
          }
          loadingModal.increment();
          newEntries.push(data);
          externalNew += 1;
        },
        { concurrency: 1 },
      );

      this.log.debug(
        'Of %d entries with external IDs, new: %d, existing: %d',
        _.size(externalIdEntries),
        externalNew,
        externalExisting,
      );

      loadingModal.open({
        message: `Creating ${_.size(newEntries)} new entries`,
        total: _.size(newEntries),
      });

      await BBPromise.map(
        newEntries,
        async (data) => {
          try {
            const doc = await store.create({
              data,
              params: {
                query: {
                  $client: {
                    disableUpsertChecks: !this.externalIdIsUnique,
                  },
                },
              },
            });

            runInAction(() => {
              this.saveResults.new.push(doc);
              this.resultHeaders.replace(
                _.union(
                  this.resultHeaders,
                  _(doc)
                    .keys()
                    .reject((k) => /^_/.test(k))
                    .value(),
                ),
              );
            });
          } catch (err) {
            this.log.error(`Failed to save ${this.storeName} record`, err);
            runInAction(() => {
              this.saveResults.invalid.push(_.extend(data, { err }));
            });
          }

          loadingModal.increment();
        },
        { concurrency: 1 },
      );

      // eslint-disable-next-line no-console
      console.table(this.saveResults.new);

      loadingModal.open({
        message: `Updating ${_.size(existingEntries)} Existing Entries`,
        total: _.size(existingEntries),
      });
      await BBPromise.map(
        existingEntries,
        async (data) => {
          try {
            const existingDoc = await store.get(data.uuid, {
              query: { companies: this.company.uuid },
              select: false,
            });

            const existing = flatten(existingDoc);
            const newData: Record<string, unknown> = flatten(data);

            const arrays = {};
            let patchData = _.pickBy(newData, (val, key) => {
              const fieldDef = _.find(this.knownFields, { key });

              // Capture any dotified array values
              if (/\w+\.\d+/.test(key)) {
                _.set(arrays, key, val);
              }

              if (
                !_.isNumber(val) &&
                !_.isBoolean(val) &&
                _.isEmpty(val) &&
                _.isEmpty(existing[key]) &&
                !fieldDef?.isNullable
              ) {
                this.log.silly('"%s" is Double Empty', {
                  ex: existing[key],
                  val,
                });
                return false;
              }

              if (_.isEqual(val, existing[key])) {
                this.log.silly('"%s" is Equal', { ex: existing[key], val });
                return false;
              }

              this.log.silly('"%s" Are DIFFERENT', { ex: existing[key], val });
              return true;
            });

            // When adding a new array field (eg: parentIds), we need to pass in an array,
            // and not use dot notation; otherwise we get an object instead of an array.
            _.each(arrays, (val, key) => {
              if (!_.has(existingDoc, key)) {
                patchData = _.omitBy(patchData, (v, k) =>
                  RegExp(`^${key}`).test(k),
                );
                patchData[key] = val;
              }
            });

            if (_.isEmpty(patchData)) {
              runInAction(() => {
                this.saveResults.ignored.push(existing);
                this.resultHeaders.replace(
                  _.union(
                    this.resultHeaders,
                    _(existing)
                      .keys()
                      .reject((k) => /^_/.test(k))
                      .value(),
                  ),
                );
              });
            } else {
              this.log.debug(
                'Patch Existing Doc with Data: %s',
                JSON.stringify(patchData, null, 2),
                { existing, newData },
              );

              this.log.debug(`Updating ${this.storeName} record`, {
                patchData,
              });

              const doc = await store.update({
                data: patchData,
                id: data.uuid,
              });

              runInAction(() => {
                this.saveResults.updated.push(doc);
                this.resultHeaders.replace(
                  _.union(
                    this.resultHeaders,
                    _(doc)
                      .keys()
                      .reject((k) => /^_/.test(k))
                      .value(),
                  ),
                );
              });
            }
          } catch (err) {
            this.log.error(`Failed to save ${this.storeName} record`, err);
            runInAction(() => {
              this.saveResults.invalid.push(_.extend(data, { err }));
            });
          }

          loadingModal.increment();
        },
        { concurrency: 1 },
      );

      this.setStatus('saved');

      loadingModal.close({
        delay: 2000,
        message: 'Complete!',
      });
    } catch (err) {
      loadingModal.close({
        delay: 2000,
        message: "shoot, that's not right",
      });
      this.log.error(`Failed to save ${this.storeName} records`, err);

      snackBar.error('Sorry, something went wrong', {
        body: err.message,
      });
      this.setStatus('error');
    }

    this.setSaving(false);
  }

  doMapping({ data, mapping }) {
    const newData = _.extend(data, {
      companies: [this.company.uuid],
    });

    return super.doMapping({ data: newData, mapping });
  }
}
