import { set, observable, action, runInAction } from 'mobx';
import { create, persist } from 'mobx-persist';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import Promise from 'bluebird';

import { service } from '#/shared/app';
import { getChildLogger } from '#/shared/utils/client.logger';

const SERVICE = 'prefs';
const fileLog = getChildLogger(`stores.${SERVICE}`);

const doHydrate = create({
  // storage: localStorage,
  jsonify: true,
});

const hydrate = async ({ userId, store }) => {
  if (global.TYPE !== 'CLIENT') {
    _.set(store, 'userId', userId || '');
    return store;
  }

  const log = fileLog.getChildLogger('hydrate');
  try {
    if (_.isEmpty(userId)) return Promise.resolve();

    log.debug('Constructing PrefsStore for user', userId);

    if (!!store && _.isObject(store.companyId) && _.isEmpty(store.companyId)) {
      _.set(store, 'companyId', null);
    }

    const hydratedStore = await doHydrate(`${SERVICE}.${userId}`, store, {
      userId,
    });

    log.debug('Hydrated from Local Storage', { hydratedStore });
    return hydratedStore;
  } catch (err) {
    log.error('Hydration Failed', err);
    return store;
  }
};

export default class PrefsStore {
  query = {};

  @persist
  @observable
  userId = '';

  @persist
  @observable
  companyId = '';

  @persist
  @observable
  impersonatedCompanyId = '';

  @persist
  @observable
  autoImpersonateAccounts = false;

  @persist
  @observable
  homeRoute = '/';

  @persist
  @observable
  shiftPageView = 'calendar';

  @persist
  @observable
  shiftRangeView = 'week';

  @persist
  @observable
  shiftView = 'review';

  @observable
  companies = [];

  @persist('list')
  @observable
  recentCompanies = [];

  @persist
  @observable
  displayLocalTimes = true;

  @action.bound
  toggleTimezoneDisplay(val = !this.displayLocalTimes) {
    fileLog
      .getChildLogger('toggleTimezoneDisplay')
      .debug('Set: ' + val.toString());
    this.displayLocalTimes = val;
  }

  @action.bound
  async setup(user) {
    const log = fileLog.getChildLogger('setup');
    const userId = _.get(user, 'uuid', user);

    if (_.isEmpty(userId) || !_.isString(userId)) {
      log.error('no user passed to setup');
      return this.teardown();
    }

    let company;

    const { isAdmin } = user;

    try {
      const companies = await dispatch('companies.getForUser', userId, {
        statuses: ['active', 'pending'],
      });

      const allCompanies = companies;

      await Promise.map(companies, async (parentCompany) => {
        const childCompanies = await dispatch('companies.loadChildren', {
          company: parentCompany,
        });
        _.forEach(childCompanies, (childCompany) =>
          allCompanies.push(childCompany),
        );
      });

      this.setCompanies({ companies: allCompanies });

      log.debug('User Prefs Setup', userId);

      const res = await hydrate({ store: this, userId });

      log.debug('hydrate result', {
        co: this.companyId,
        impersonated: this.impersonatedCompanyId,
        res,
        userId,
      });

      // Select the company
      if (
        !_.isEmpty(this.impersonatedCompanyId) &&
        (isAdmin ||
          _.find(this.companies, { uuid: this.impersonatedCompanyId }))
      ) {
        // If the selected impersonated companyId is valid (in user companies || user is admin)
        log.debug('calling auth.setCompany (impersonated)', {
          companyId: this.impersonatedCompanyId,
        });
        company = await dispatch('auth.setCompany', {
          company: this.impersonatedCompanyId,
          impersonate: true,
        });
      } else if (
        !!this.companyId &&
        (isAdmin || _.find(this.companies, { uuid: this.companyId }))
      ) {
        // If the selected company is valid (in user companies || user is admin)
        log.debug('calling auth.setCompany Pre-Hydrated', {
          companyId: this.companyId,
        });
        company = await dispatch('auth.setCompany', {
          company: this.companyId,
        });
      } else {
        log.debug(
          'No Default company for user - selecting first from list',
          userId,
        );
        const myCompany =
          _.find(this.companies, { owner: userId }) || _.first(this.companies);
        log.debug('calling auth.setCompany (From List)', { myCompany });
        company = await dispatch('auth.setCompany', {
          company: myCompany,
        });
      }
      /* SSM-610
      Validate stored recent companies 
      */
      const activeCompanies = await dispatch('companies.find', {
        query: {
          uuid: {
            $in: _.map(this.recentCompanies, 'uuid'),
          },
        },
      });
      runInAction(() => {
        const validUUIDS = _.map(activeCompanies, 'uuid');
        this.recentCompanies = _.filter(this.recentCompanies, ({ uuid }) =>
          _.includes(validUUIDS, uuid),
        );
      });

      /** EP-127
       * Clear out any companies that the user doesn't have access to.
       */

      if (!isAdmin) {
        const companiesUUIDs = _.map(allCompanies, 'uuid');

        runInAction(() => {
          this.recentCompanies = _.filter(this.recentCompanies, ({ uuid }) =>
            _.includes(companiesUUIDs, uuid),
          );
        });
      }
    } catch (err) {
      log.error('Failed to setup prefs', err, {
        extra: { user: _.get(user, 'uuid', user) },
      });
    }

    log.debug('hydrated company: ', company.name || company.uuid || 'NONE!');

    return company;
  }

  @action
  teardown() {
    this.userId = '';
    this.companyId = '';
    this.homeRoute = '';
  }

  init() {
    // run events on client side-only
    if (global.TYPE === 'CLIENT') this.initEvents();
  }

  initEvents() {
    // service(SERVICE).on('created', action(this.onCreated)); // onCreated = (data, params) => {}
    service(SERVICE).on('updated', action(this.onUpdated)); // onUpdated = (data) => {}
    service(SERVICE).on('patched', action(this.onUpdated)); // onPatched = (id, data) => {}
    // service(SERVICE).on('removed', action(this.onRemoved));   // onRemoved = (id, params) => {}
  }

  @action
  setCompanies({ companies = [] }) {
    if (_.isEmpty(companies)) {
      this.companies.clear();
    } else {
      this.companies.replace(companies);
    }
  }

  @action
  setAutoImpersonateAccounts(value) {
    this.autoImpersonateAccounts = value;
  }

  @action
  async setCompany({ company, user, impersonate = false, isAdmin }) {
    const log = fileLog.getChildLogger('setCompany');
    let loadedCompany = company;
    try {
      if (!_.isEmpty(company) && _.isString(company)) {
        loadedCompany = await dispatch('companies.get', company);
      } else if (impersonate && _.isEmpty(company)) {
        this.impersonatedCompanyId = '';

        if (!_.isEmpty(this.companyId)) {
          return dispatch('auth.setCompany', { company: this.companyId });
        }
        return {};
      } else if (!impersonate && _.isEmpty(company)) {
        this.companyId = '';
        return {};
      }

      log.debug(
        'setting company to: ',
        _.get(loadedCompany, 'uuid', 'no uuid'),
        _.get(loadedCompany, 'name', 'no name'),
      );
      const uuid = _.get(loadedCompany, 'uuid', loadedCompany);

      if (_.isEmpty(uuid)) {
        return Promise.reject('No Company loaded');
      }

      log.debug('Setting Selected Company to ', loadedCompany);

      const csr =
        _.find(user.companyStatus, {
          company: loadedCompany.uuid,
        }) || {};

      this.setHomeRoute(_.get(csr, 'route') || '/');

      if (impersonate) {
        this.impersonatedCompanyId = uuid;

        if (isAdmin) {
          const existing = this.companies.find(
            (co) => co.uuid === loadedCompany.uuid,
          );

          if (existing) {
            this.companies.remove(existing);
          } else {
            this.companies.pop();
          }
          this.companies.unshift(loadedCompany);
        }
      } else {
        this.companyId = uuid;
      }
      runInAction(() => {
        this.recentCompanies.unshift(loadedCompany);
        this.recentCompanies = _(this.recentCompanies)
          .uniqBy('uuid')
          .slice(0, 20)
          .value();
      });
    } catch (err) {
      log.error('Failed to set company', err, {
        extra: { companyId: company.uuid, impersonate, isAdmin },
      });
    }

    return loadedCompany;
  }

  getCompanyId() {
    return this.companyId;
  }

  getHomeRoute() {
    return this.homeRoute;
  }

  getShiftRangeView() {
    return this.shiftRangeView;
  }

  getShiftPageView() {
    return this.shiftPageView;
  }

  @action
  setHomeRoute(route) {
    const log = fileLog.getChildLogger('setHomeRoute');
    log.debug('Setting Home Route to ', route);
    this.homeRoute = route || '/';
    return this.homeRoute;
  }

  @action
  setShiftView(view) {
    this.shiftView = view;
  }

  @action
  setShiftRangeView(view) {
    this.shiftRangeView = view;
  }

  @action
  setShiftPageView(view) {
    this.shiftPageView = view;
  }

  /* EVENTS */

  // onCreated = item => this.addItem(item);

  @action
  onUpdated = (data) => {
    const log = fileLog.getChildLogger('onUpdated');
    if (_.isEmpty(data)) {
      log.debug(`Empty Item in onUpdated for ${SERVICE}`);
      return;
    }
    log.silly('Received Post Update: %O', data);

    const existing = _.find(this.list, { uuid: data.uuid });
    if (existing) {
      set(existing, data);
    }

    if (this.selected.uuid === data.uuid) {
      set(this.selected, data);
    }
  };

  // onPatched = (id, data) => {};

  // onRemoved = (id, params) => {};

  /* ACTIONS */
}
