import _ from 'lodash';
import { v4 as uuid } from 'uuid';

export function getNotificationPrefsData({
  prefs,
  data,
  thing = data,
  log = { debug: _.get(console, 'log', _.noop) },
  isNP = true,
}) {
  const approvalRequired =
    _.get(thing, 'defaultAssignmentAction') === 'approvalRequired';
  const notificationPrefs = _(prefs)
    .map(unzipNotificationPrefs)
    .flatten()
    .map((pref, index) =>
      _.extend(
        {
          pool: null,
          prefID: uuid(),
          users: [],
        },
        !/dynamicBonus/.test(pref.category) && {
          approval:
            approvalRequired || _.get(pref, 'approval', approvalRequired),
          approvalType: _.get(thing, 'defaultAssignmentAction'),
          ignoreRestrictions: _.get(pref, 'ignoreRestrictions'),
        },
        /dynamicBonus/.test(pref.category) && {
          poolIds: pref.poolIds ?? [],
        },
        _.pick(
          pref,
          _.compact([
            isNP && 'baselineTime',
            'category',
            'prefID',
            'delay',
            'sentAt',
            'pool',
            'uuid',
            'users',
            'poolIds',
            'settings',
            'templateId',
            'maxRadius',
            // Dynamic Bonus Fields
            'isActive',
            'slug',
            'poolIds',
            'settings',
          ]),
        ),
        {
          index,
          status: pref?.status === 'draft' ? undefined : pref.status,
        },
      ),
    )
    .value();

  log.debug('[getNotificationPrefsData] Filled in prefs data with defaults', {
    notificationPrefs,
    prefs,
  });

  return notificationPrefs;
}

/**
 * unzipNotificationPrefs
 *
 * This logic exists primarily to accomodate legacy notification prefs logic that
 * restricted data to one-pool-per-pref-level. Newer "pools" property/specifications avoides
 * this restriction and obviates the need for this code.
 *
 * @param {*} pref Notification/Dispatch Pref Level, possibly contining multiple pools.
 * @returns Notification Prefs Array containing a single netry for each pool in the root pref.
 */
export function unzipNotificationPrefs(pref) {
  if (
    /dynamicBonus/.test(pref.category) ||
    /workFrequency/.test(pref.category)
  ) {
    return [pref];
  }

  const replacePrefID =
    pref.pools.length > 1 ||
    (!_.isEmpty(pref.pools) && !_.isEmpty(pref.partners));
  const unzippedNotificationPrefs = pref.pools.map((pool) =>
    _.extend({}, pref, {
      isPreferred: !!pool.isPreferred,
      pool: pool.uuid,
      prefID: replacePrefID ? uuid() : pref.prefID,
    }),
  );
  if (pref.partners.length > 0) {
    const unpreferredUsersNotificationPref = pref.partners.some(
      (p) => !p.isPreferred,
    )
      ? _.extend({}, _.omit(pref, 'pools'), {
          isPreferred: false,
          prefID: replacePrefID ? uuid() : pref.prefID,
          users: pref.partners.filter((p) => !p.isPreferred).map((p) => p.uuid),
        })
      : null;
    const preferredUsersNotificationPref = pref.partners.some(
      (p) => p.isPreferred,
    )
      ? _.extend({}, _.omit(pref, 'pools'), {
          isPreferred: true,
          prefID: replacePrefID ? uuid() : pref.prefID,
          users: pref.partners.filter((p) => p.isPreferred).map((p) => p.uuid),
        })
      : null;

    if (unpreferredUsersNotificationPref)
      unzippedNotificationPrefs.push(unpreferredUsersNotificationPref);

    if (preferredUsersNotificationPref)
      unzippedNotificationPrefs.push(preferredUsersNotificationPref);
  }
  return unzippedNotificationPrefs;
}
