export const effects = {
  boxShadowButtonBasic: '0 1px 2px 0 rgba(0, 0, 0, 0.08)',
  boxShadowButtonPrimary: '0 6px 5px 0 rgba(142, 184, 255, 0.5)',

  /* Box shadow */
  boxShadowCard: '0 11px 9px -6px rgba(197, 197, 197, 0.38)',

  boxShadowHeader: '0 2px 16px 0 rgba(127, 133, 139, 0.2)',

  boxShadowHeavy: '0 5px 15px 0 rgba(0, 0, 0, 0.5)',

  /* Transition */
  fastTransition: 'box-shadow 0.2s, transform 0.2s, text-shadow 0.2s',

  /** Gradients */
  gradientPrimary: 'linear-gradient(259deg, #2755c2, #1a71cf)',

  gradientSecondary: 'linear-gradient(254deg, #8b8b8b, #5a5a5a)',

  gradientTertiary: 'linear-gradient(252deg, #f1f1f1, #f1f1f1)',

  shadowBasic: 'rgba(0, 0, 0, 0.08)',

  /** Shadows */
  shadowCard: 'rgba(197, 197, 197, 0.38)',

  shadowDark: 'rgba(0, 0, 0, 0.5)',

  shadowHeader: 'rgba(127, 133, 139, 0.2)',

  shadowPrimary: 'rgba(142, 184, 255, 0.5)',

  slowTransition: 'box-shadow 0.4s, transform 0.4s, text-shadow 0.4s',

  textShadowBasic: '1px 2px 3px rgba(0, 0, 0, 0.08)',
};
