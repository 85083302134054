import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import { action } from 'mobx';
import { dispatch } from 'rfx-core';
import moment from 'moment';
import _ from 'lodash';

import BaseStore from './_baseStore';

export type ISchedulingTemplate = IBaseItem & Record<string, unknown>;

export default class SchedulingTemplateStore extends BaseStore<ISchedulingTemplate> {
  constructor() {
    const baseItem = {
      company: null,
      description: null,
      end: null,
      shifts: [],
      start: null,
      title: null,
      uuid: null,
    };
    super({
      baseItem,
      serviceName: 'schedulingTemplates',
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  @action
  async createFromShifts({ title, description, shifts }) {
    dispatch('ui.loadingModal.open', {
      message: 'Creating Scheduling Template',
    });
    const company = dispatch('auth.getCompany').uuid;
    const firstShiftStart = _.minBy(shifts, (shift) =>
      moment(shift.start).valueOf(),
    )?.start;
    const start = moment(firstShiftStart).startOf('day').toDate();
    const lastShiftEnd = _.maxBy(shifts, (shift) =>
      moment(shift.end).valueOf(),
    )?.end;
    const end = moment(lastShiftEnd).add(1, 'day').startOf('day').toDate();
    const updatedShifts = _.map(shifts, (shift) => {
      const duration = moment(shift.end).diff(moment(shift.start), 'hours');
      const updatedShift = Object.assign(shift, {
        duration: String(duration),
        payableDuration: duration,
      });
      return updatedShift;
    });
    const newSchedulingTemplate = {
      company,
      description,
      end,
      shifts: updatedShifts,
      start,
      title,
    };
    let res = [];
    try {
      res = await this.create({ data: newSchedulingTemplate });

      this.log.debug('createTemplate result: ', res);
    } catch (err) {
      this.log.error(
        'Failed to review conflicts for new scheduling template: ',
        err,
      );
    }
    dispatch('ui.loadingModal.close');
    return res;
  }

  // If reviewOnly = true, retrieve shift errors for createTemplate request and
  // do not create template (yet)
  @action
  async createFromRange({
    title,
    description,
    start,
    end,
    saveDraftShifts = false,
    reviewOnly = false,
    position,
  }) {
    dispatch('ui.loadingModal.open', {
      message: 'Creating Scheduling Template',
    });
    const company = dispatch('auth.getCompany').uuid;
    const newSchedulingTemplate = {
      company,
      description,
      end,
      saveDraftShifts,
      start,
      title,
    };
    if (position) {
      newSchedulingTemplate.position = position;
    }
    let res = [];
    try {
      if (reviewOnly) {
        res = await this.create({
          data: newSchedulingTemplate,
          params: {
            query: {
              $client: { getCreateTemplateReview: true, skipEvents: true },
            },
          },
        });
      } else {
        res = await this.create({ data: newSchedulingTemplate });
      }
      this.log.debug('createTemplate result: ', res);
    } catch (err) {
      this.log.error(
        'Failed to review conflicts for new scheduling template: ',
        err,
      );
    }
    dispatch('ui.loadingModal.close');
    return res;
  }

  // If reviewOnly = true, retrieve conflicts/errors for applyTemplate request and
  // do not apply template (yet)
  @action
  async applyTemplate({
    template = this.selected,
    start,
    includePartners = false,
    reviewOnly = false,
  }) {
    dispatch('ui.loadingModal.open', {
      message: 'Applying Scheduling Template',
    });
    const company = dispatch('auth.getCompany').uuid;
    let res = [];
    try {
      if (reviewOnly) {
        res = await this.create({
          data: { company, includePartners, uuid: template },
          params: {
            query: {
              $client: {
                applyTemplateStart: start,
                getApplyTemplateReview: true,
                skipEvents: true,
              },
            },
          },
        });
      } else {
        res = await this.update({
          data: { company, includePartners, uuid: template },
          query: { $client: { applyTemplateStart: start } },
        });
      }
    } catch (err) {
      this.log.error('Failed to apply scheduling template', err);
    }
    dispatch('ui.loadingModal.close');
    return res;
  }
}
