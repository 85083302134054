import BaseTagStore from './_baseTagStore';

export default class IndustryStore extends BaseTagStore {
  constructor() {
    super({ baseItem: IndustryStore.BASE_ITEM, serviceName: 'industries' });

    return this;
  }

  static BASE_ITEM = {
    certType: 'industry',
    level: -1,
    parent: '',
  };
}
