export const breakpoints = {
  hsu: '(min-height: 768px)',

  // ** height
  hus: '(max-height: 767px)',

  lg: '(min-width: 1200px) and (max-width: 1440px)',

  lu: '(min-width: 1200px)',

  md: '(min-width: 992px) and (max-width: 1199px)',

  mu: '(min-width: 992px)',

  sm: '(min-width: 768px) and (max-width: 991px)',

  su: '(min-width: 768px)',

  ul: '(max-width: 1199px)',

  um: '(max-width: 991px)',

  // Under Specific Breakpoints
  // Naming scheme interpreted as "under {size}"", `us = 'under small'`
  us: '(max-width: 767px)',

  ux: '(max-width: 1440px)',

  uxxl: '(max-width: 1679px)',

  // MacBook Air
  xl: '(min-width: 1441px) and (max-width: 1679px)',

  xs: '(max-width: 767px)',

  xu: '(min-width: 1441px)',

  xxl: '(min-width: 1680px)',

  // Specific Ranges
  xxs: '(max-width: 479px)',
  // Breakpoint and above.
  // Naming scheme interpreted as "{size} and up", `su = 'small and up'`
  xxsu: '(min-width: 480px)',
};
export type SSMBreakPointsType = Partial<
  Record<keyof typeof breakpoints, boolean>
>;
