import { autorun } from 'mobx';
import { DependencyList, useEffect } from 'react';

export function useAutorunEffect(
  create: () => (() => void) | void,
  deps: DependencyList,
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(() => autorun(() => create()), deps ?? []);
}
