import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EmptyState from '#/shared/components/ssm/EmptyState';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('utils.ErrorBoundary');

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    log.error('Error Boundary Hit', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="pa5 w-100 tc">
          <EmptyState
            body="Please reload the page"
            header={{
              content: 'Something went wrong!',
            }}
            icon="/static/img/not-found.png"
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
