import _ from 'lodash';
import { dispatch } from 'rfx-core';
import flatten from 'flat';

import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';
import {
  fields as addrFields,
  rules as addrRules,
  labels as addrLabels,
} from './addressFields';

const log = getChildLogger('forms.store');

class StoreForm extends Form {
  hooks = () => ({
    async onSuccess(form) {
      const formAction = form.initials().uuid ? 'update' : 'create';
      const formValues = form.values();

      if (/update/i.test(formAction)) {
        ['loc', 'formattedAddress'].forEach((key) => {
          const value = _.get(formValues, `address.${key}`, false);
          if (value) {
            _.set(formValues, key, value);
            delete formValues.address[key];
          }
        });

        if (_.has(formValues, 'address')) {
          const address = flatten(_.pick(formValues, 'address'));
          delete formValues.address;
          _.extend(formValues, address);
        }
        this.removePristineNestedFields(formValues, null, { mutate: true });
      } else {
        const companyId = _.get(dispatch('auth.getCompany'), 'uuid');
        formValues.companies = _.uniq([
          ...(form.initials().companies || []),
          companyId,
        ]);
      }

      if (_.has(formValues, 'loc.coordinates')) {
        const coordinates = _.isEmpty(form.initials().loc.coordinates)
          ? formValues.loc.coordinates
          : _.map(form.initials().loc.coordinates, (val, index) =>
              _.toNumber(formValues.loc.coordinates[index] || val),
            );

        if (!_.isEmpty(coordinates)) {
          const loc = {
            coordinates,
            type: 'Point',
          };

          formValues.loc = loc;
        } else {
          delete formValues.loc;
        }
      }

      let store;
      try {
        store = await dispatch(`stores.${formAction}`, {
          data: formValues,
          id: form.initials().uuid,
        });
        log.debug(`${formAction}d a store: `, { store });
        dispatch('ui.snackBar.open', `Store ${_.capitalize(formAction)}d`);
        dispatch('ui.storeEditModal.clear');
      } catch (err) {
        form.invalidate(err.message);
        dispatch('ui.snackBar.error', err.message);
        throw err;
      }

      return store;
    },
  });
}

const fields = _.union(
  [
    'uuid',
    'retailer',
    'storeId',
    'region',
    'market',
    'address',
    'companies',
    'loc.coordinates',
    'loc.coordinates.1',
    'loc.coordinates.0',
    'loc.type',
  ],
  addrFields,
);

const rules = _.merge(
  {
    market: 'string',
    region: 'string',
    retailer: 'required|string',
    storeId: 'required|string',
    uuid: 'string',
  },
  addrRules,
);

const labels = _.merge(
  {
    'loc.coordinates': 'Coordinates',
    market: 'Market',
    region: 'Region',
    retailer: 'Retailer',
    storeId: 'Store ID',
    uuid: null,
  },
  addrLabels,
);

export function init(values = {}, cxOptions?: { addressOptional?: boolean }) {
  let formRules = rules;

  const { addressOptional, ...opts } = cxOptions ?? {};

  if (addressOptional) {
    formRules = _.mapValues(rules, (value, key) => {
      if (/^address/i.test(key)) {
        return value.replace(/required[|]?/i, '');
      }
      return value;
    });
    log.debug('Striped out "Required" address form rules', formRules);
  }

  log.debug('Initialzing Store form w/ Values: ', values);
  return new StoreForm(
    { fields, labels, rules: formRules, values },
    { ...opts, options: { retrieveOnlyDirtyValues: true } },
  );
}
