import { observable, action, runInAction } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.activateAccount');

export default class AssignmentApprovalModal {
  @observable
  header = 'Activate Your Account';

  @observable company = {};

  @observable user = {};

  @observable canAccept = false;

  @observable isLoading = true;

  @observable agreement = [];

  @action
  setup = async ({ companyId, user }) => {
    if (!_.isObject(user) || _.isEmpty(user.uuid)) {
      const err = new Error('Must supply user object');
      log.error('Invalid Parameters', err);
      throw err;
    }

    const csr = _.find(user.companyStatus, { company: companyId });

    if (csr && !/^pending$/i.test(csr.status)) {
      log.debug('User is not in a "pending" status, redirecting to home', {
        csr,
        status: csr.status,
        userId: user.uuid,
      });

      setTimeout(() => dispatch('routing.home'), 200);
      return;
    }

    let company;

    if (_.isEmpty(companyId)) {
      company = _.find(user.companyStatus, { status: 'pending' });
    } else {
      company = await dispatch('companies.get', companyId);
    }

    runInAction(() => {
      this.user = user;
      this.company = company;
      this.agreement = _.get(this.company, 'settings.agreements.employer', []);
      this.header = _.compact([
        !_.isEmpty(this.agreement) && 'Review the agreement below and ',
        `Activate your ${company.name} account now`,
      ]);
      this.canAccept = _.isEmpty(this.agreement);

      this.isLoading = false;
    });
  };

  @action
  onAgree = async () => {
    if (!this.canAccept) {
      dispatch('ui.snackBar.error', 'Please Review the Usage Agreement');
      return;
    }

    try {
      const patchData = {
        $addToSet: { roles: { $each: ['employer', 'company-agent'] } },
        'companyStatus.$.status': 'active',
        'companyStatus.$.updatedAt': Date.now(),
      };
      const params = {
        query: {
          companyStatus: {
            $elemMatch: {
              company: this.company.uuid,
              status: { $in: ['pending', 'sent'] },
            },
          },
        },
      };

      log.debug('Updating User to ');
      const updatedUser = await dispatch('employerUsers.update', {
        data: patchData,
        id: this.user.uuid,
        params,
      });

      const csr = _.find(updatedUser.companyStatus, {
        company: this.company.uuid,
      });

      if (csr.status !== 'active') {
        throw new Error(
          'CSR Record was not properly updated. Expected `accepted`, got `%s`',
          _.get(csr, 'status'),
        );
      }

      dispatch('ui.snackBar.open', `Welcome to ${this.company.name}`, {
        body: 'Redirecting to your home page ...',
      });
      await dispatch('auth.updateUser', updatedUser);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      dispatch('routing.home');
    } catch (err) {
      log.error('Company Status update failed', err);
      dispatch('ui.snackBar.error', 'Sorry', {
        body: 'Unable to join company at this time',
      });
    }
  };

  @action
  setAgreementRead = (val = true) => {
    this.canAccept = val;
  };

  @action
  clear = () => {
    this.header = '';
    this.company = {};
    this.user = {};
    this.canAccept = false;
    this.isLoading = true;
    this.agreement = [];
  };
}
