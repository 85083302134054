import { Progress } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

export const StaffingFillProgressBar = styled(Progress)`
  &:after {
    content: '${(props) => props.fillRate}';
    position: absolute;
    top: 0;
    font-weight: 600;
    line-height: 1.1;
    ${({ labelPosition }) =>
      labelPosition === 'left'
        ? css`
            left: -2.75rem;
          `
        : css`
            right: -2.75rem;
          `}

    color: red;
    ${({ indicating }) => indicating && `color: #e6bb48;`}
    ${({ success }) => success && `color: #21ba45;`}
    ${({ inactive }) => inactive && `color: grey;`}
    //if match multiple condition, bottom style will get the priority
  }

  &.ui.progress {
    width: 85%;
    background-color: #e9ecef;
  }
  &.ui.progress .bar {
    min-width: auto;
  }
  &.ui.progress .label {
    margin-top: 6px;
    font-size: 0.95rem;
    /* text-align: left; */
    font-weight: normal;
    color: #494b4e;
    display: flex;
  }
`;
