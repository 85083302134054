import { action, observable, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';
import { init as initForm } from '#/shared/forms/timecard';

const log = getChildLogger('ui.TimecardModal');

export default class TimecardModal {
  @observable
  isOpen = false;

  @observable
  assignment = {};

  @observable
  form = {};

  @observable
  isLoading = false;

  @observable
  categoryDurationOptions = [];

  @observable
  popupsState = [];

  @action
  async setup({ open = !this.isOpen, assignment = {} }) {
    this.set('isLoading', true);
    this.set('isOpen', open);
    try {
      const assignmentId = _.get(assignment, 'uuid', assignment);
      const company = dispatch('auth.getCompany');
      this.categoryDurationOptions = _.get(
        company,
        'settings.timekeeping.breakOptions',
        [],
      ).map((option) => ({
        key: _.compact([option.breakCategory, option.breakDuration]).join('<>'),
        text: `${option.breakDuration} min ${
          /unpaid/i.test(option.breakCategory) ? 'Unpaid' : 'Paid'
        }`,
        value: _.compact([option.breakCategory, option.breakDuration]).join(
          '<>',
        ),
      }));
      const assignmentResult = await this.loadAssignment(assignmentId);

      runInAction(() => {
        this.assignment = assignmentResult;
        this.popupsState.replace(
          _.map(assignmentResult?.timecard, () => false),
        );
        this.form = initForm(assignmentResult);
      });
    } catch (error) {
      log.error('Error setting up TimecardModal', error);
    }
    this.set('isLoading', false);
  }

  @action
  addTimecardRow = () => {
    this.popupsState.push(false);
  };

  @action
  removeTimecardRow = (index) => {
    this.popupsState.splice(index, 1);
  };

  @action
  async loadAssignment(uuid) {
    this.set('isLoading', true);
    const assignmentResult = await dispatch('assignments.get', uuid, {
      query: { $client: { populateUser: true } },
    });

    this.set('isLoading', false);
    return assignmentResult;
  }

  @action
  closePopupAtIndex(index) {
    this.popupsState[index] = false;
  }

  @action
  openPopupAtIndex(index) {
    this.popupsState[index] = true;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    _.set(this, key, value);
  }

  @action
  retrieve(key) {
    return _.get(this, key);
  }

  @action
  clear(args = {}) {
    this.isOpen = !!args.open;
    this.assignment = {};
    this.form = {};
  }
}
