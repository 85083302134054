import { Timestamp } from '@shiftsmartinc/shiftsmart-types';

import { service } from '../app';

import BaseStore from './_baseStore';

interface Remap {
  createdAt: Timestamp;
  modelName: string;
  updatedAt: Timestamp;
  uuid: string;
}

export default class InShiftTaskTemplateRemapStore extends BaseStore<Remap> {
  constructor() {
    super({ baseItem: null, serviceName: 'inShiftTaskTemplateRemap' });

    return this;
  }

  updateUpcomingShifts(params: {
    query: { company: string; inShiftTasksTemplateId: string };
  }) {
    return service(this.serviceName)
      .get(null, params)
      .catch((err) => {
        this.log.error(
          'Error updating upcoming shift linked to the template',
          err,
        );
        throw err;
      });
  }
}
