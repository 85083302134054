import type { ICompanyOnboardingSubstep } from '@shiftsmartinc/shiftsmart-types';

import { action, computed, IObservableArray, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import moment from 'moment';

export default class PartnersFilters {
  @observable
  storeName = 'userOnboardings';

  @observable
  storeQuery = {};

  @observable
  substepFilter: IObservableArray<string> = observable.array(['all']);

  @observable
  allSubsteps: IObservableArray<ICompanyOnboardingSubstep> = observable.array([
    'all',
  ]);

  @observable
  signUpDateFilter: IObservableArray<string> = observable.array([]);

  @observable
  workerQuery = {};

  @observable
  searchTerm = '';

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value = '') {
    _.set(this, key, value);
  }

  @action
  async setStoreName(name) {
    this.storeName = name;
  }

  @action
  async setStoreQuery(query) {
    this.storeQuery = query;
  }

  @action.bound filterSignUpDate(option) {
    if (_.includes(this.signUpDateFilter, option)) {
      this.signUpDateFilter.replace([]);
    } else {
      this.signUpDateFilter.replace([option]);
    }
    this.filterBy();
  }

  @computed get signUpDateFilterSummary() {
    const option = _.find(this.getDefaultDateRangeOptions(), [
      'value',
      _.first(this.signUpDateFilter),
    ]);
    return option?.text ?? '';
  }

  @action searchWorker(term) {
    this.searchTerm = term;
    this.filterBy();
  }

  @action.bound toggleSubstep(substep, { checked, exclusive, all }) {
    if (exclusive) {
      this.substepFilter.clear();
    }

    if (all) {
      this.substepFilter.replace(['all']);
    } else if (checked) {
      this.substepFilter.push(substep);
    } else {
      if (this.substepFilter.includes('all')) {
        this.substepFilter.replace(this.allSubsteps);
      }
      this.substepFilter.remove(substep);
    }

    if (!exclusive && _.size(this.substepFilter) === _.size(this.allSubsteps)) {
      this.substepFilter.replace(['all']);
    }
    this.filterBy();
  }

  @computed get substepFilterSummary() {
    return `${_.size(this.substepFilter)} Selected`;
  }

  @action
  setGeo(geo) {
    if (geo) {
      this.workerQuery = { ...this.workerQuery, _geo: geo };
    } else {
      // eslint-disable-next-line no-underscore-dangle
      delete this.workerQuery._geo;
    }
    this.filterBy();
  }

  checkForLocation() {
    if (this.workerQuery) {
      const newQuery = {
        // eslint-disable-next-line no-underscore-dangle
        _geo: this.workerQuery._geo,
      };
      return newQuery;
    }
    return {};
  }

  @action
  async filterBy() {
    const query = {};
    this.workerQuery = this.checkForLocation();
    if (!this.substepFilter.includes('all')) {
      const filter = {
        'stepStatus.substepStatus': {
          $elemMatch: {
            status: 'completed',
            substepId: {
              $in: this.substepFilter,
            },
          },
        },
      };
      _.assign(query, filter);
    }
    if (!_.isEmpty(this.searchTerm)) {
      _.assign(this.workerQuery, {
        $or: [
          {
            displayName: {
              $options: 'i',
              $regex: `.*${this.searchTerm}.*`,
            },
          },
          {
            phoneNumber: {
              $regex: `.*${this.searchTerm}.*`,
            },
          },
          {
            email: {
              $options: 'i',
              $regex: `.*${this.searchTerm}.*`,
            },
          },
        ],
      });
    }

    if (!_.isEmpty(this.signUpDateFilter)) {
      const end = moment();
      const value = _.first(this.signUpDateFilter);
      let start;
      if (value === 30) {
        start = moment().subtract({ days: 30 }).startOf('day');
      } else if (value === 60) {
        start = moment().subtract({ days: 60 }).startOf('day');
      } else if (value === 90) {
        start = moment().subtract({ days: 90 }).startOf('day');
      } else if (value > 90) {
        end.subtract({ days: 90 });
      }
      _.assign(this.workerQuery, {
        createdAt: {
          ...(start && { $gte: new Date(start.toISOString()) }),
          $lte: new Date(end.toISOString()),
        },
      });
    }

    if (_.size(_.compact(Object.values(this.workerQuery))) !== 0) {
      _.assign(query, {
        workerQuery: this.workerQuery,
      });
    } else {
      query.workerQuery = {};
    }

    _.assign(query, {
      $client: {
        filterUserOnboardings: this.storeName === 'userOnboardings',
      },
    });
    _.assign(query, this.storeQuery);
    return await this.find(query, { clear: true });
  }

  @action
  async clearFilters(filter, query) {
    if (!filter || filter === 'substepFilter') {
      this.substepFilter = ['all'];
      if (query && query['stepStatus.substepStatus']) {
        delete query['stepStatus.substepStatus'];
      }
    }
    if (!filter) {
      if (query?.workerQuery) {
        delete query.workerQuery;
        this.signUpDateFilter = [];
        this.signUpDateFilter.replace([]);
        await dispatch('searches.clearSelected', {
          storeName: this.storeName,
        });
      }
    }
  }

  @action
  async find(inputQuery = {}, opts = {}) {
    const query = _.clone(inputQuery);
    // eslint-disable-next-line no-underscore-dangle
    if (inputQuery._geo) {
      const newQuery = {
        ...query?.workerQuery,
        // eslint-disable-next-line no-underscore-dangle
        _geo: inputQuery._geo,
      };
      _.assign(query, {
        $client: {
          filterUserOnboardings: this.storeName === 'userOnboardings',
        },
        workerQuery: newQuery,
      });
      // eslint-disable-next-line no-underscore-dangle
      delete query._geo;
    }

    return await dispatch(`${this.storeName}.find`, query, opts);
  }

  getDefaultDateRangeOptions = () => [
    {
      key: 30,
      text: '< 30 days',
      value: 30,
    },
    {
      key: 60,
      text: '< 60 days',
      value: 60,
    },
    {
      key: 90,
      text: '< 90 days',
      value: 90,
    },
    {
      key: 91,
      text: '>= 90 days',
      value: 91,
    },
  ];
}
