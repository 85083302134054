/* eslint quote-props: 0 */ // --> OFF
import type { ISurvey } from '@shiftsmartinc/shiftsmart-types';

import { observable, action, computed } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import { dispatch } from 'rfx-core';
import request from 'superagent-bluebird-promise';

import ThingStore from '#/shared/stores/things';

/** TODO: Much of this store can be removed as it inherits from both ThingStore and BaseStore */
export default class SurveyStore extends ThingStore<ISurvey> {
  constructor({
    serviceName = 'surveys',
    title = 'surveys',
    baseItem = SurveyStore.BASE_ITEM,
  } = {}) {
    super({
      baseItem,
      searchFields: [
        'title',
        'description',
        'campaignTitle',
        'qutoaDef.productTitle',
        'quotaDef.surveyTitle',
        'quotaDef.persona',
      ],
      serviceName,
      title,
    });

    this.log.debug('Created new "SurveyStore"');
    return this;
  }

  static BASE_ITEM = {
    campaign: '',
    campaignTitle: '',
    currency: 'usd',
    isTraining: false,
    pay: 0,
    personaUUID: '',
    quotaDef: '',
    quotaKey: '',
    requireCheckin: false,
    requireImage: false,
    store: '',
    surveyDef: '',
  };

  sort = { createdAt: -1 };

  @observable
  upload = {};

  findPriority({
    company,
    limit = 10,
    date = moment().subtract(1, 'w').toDate(),
    status = ['Pending', 'Active'],
  }) {
    this.$pagination.limit = limit;

    const companyId = _.get(company, 'uuid', company);

    const query = {
      query: {
        company: companyId,
        start: { $gte: date },
        status: { $in: status },
      },
    };

    return this.find(query);
  }

  /* Auxilliary & Utility Methods */

  clone({ survey }) {
    const pickValues = [
      'address',
      'campaign',
      'campaignTitle',
      'company',
      'currency',
      'description',
      'isTraining',
      'pay',
      'quotaDef',
      'quotaKey',
      'requireCheckin',
      'requireImage',
      'autoApproveShops',
      'surveyDef',
      'title',
      'personaUUID',
      'phoneNumber',
    ];

    const newSurvey = _.pick(survey, pickValues);
    newSurvey.store = _.get(survey, 'store.uuid', survey.store);
    newSurvey.status = 'Pending';

    this.log.debug('Cloning survey with data: ', newSurvey);

    const query = {
      noGeocode: true,
    };
    return this.create({ data: newSurvey, params: { query } }).catch((err) =>
      this.logAndThrow(err),
    );
  }

  @action
  registerHandler({ event = 'created', fn }) {
    _.set(this, `handlers[${event}]`, _.get(this, `handlers[${event}]`, []));
    this.handlers[event].push(fn);

    return Promise.resolve(this.handlers[event].count);
  }

  /* EVENTS */

  onCreated = (item) => {
    if (_.isEmpty(item)) {
      return;
    }
    const event = 'created';

    if (_.has(this, `handlers[${event}]`)) {
      while (!_.isEmpty(this.handlers[event])) {
        const fn = this.handlers[event].shift();
        if (_.isFunction(fn)) fn(item);
      }
    } else {
      this.addItem(item);
    }
  };

  @computed
  get allStatuses() {
    return ['Active', 'Pending', 'Completed', 'Filled', 'Draft', 'Canceled'];
  }
}
