import type { IZone } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';

import SitesStore from './sites';

// @ts-expect-error zones stores different geo data than sites
export default class ZoneStore extends SitesStore<IZone> {
  constructor() {
    super({
      baseItem: ZoneStore.BASE_ITEM,
      searchFields: ['name'],
      serviceName: 'zones',
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  static BASE_ITEM: IZone = {
    ...SitesStore.BASE_ITEM,
    loc: {
      coordinates: [],
      type: 'Polygon',
    },
    srcProperties: {},
    tier: null,
  };
}

export const defaultTierIcons = [
  'globe',
  'sitemap',
  'map outline',
  'crosshairs',
  'map marker alternate',
];

export const GLOBAL_TIERS = [
  {
    index: 5,
    isActive: true,
    key: 'country',
    label: 'Country',
  },
  {
    index: 6,
    isActive: true,
    key: 'division',
    label: 'Division',
  },
  {
    index: 7,
    isActive: true,
    key: 'state',
    label: 'State',
  },
  {
    index: 8,
    isActive: true,
    key: 'county',
    label: 'County',
  },
  {
    index: 10,
    isActive: false,
    key: 'region',
    label: 'Region',
  },
  {
    index: 11,
    isActive: true,
    key: 'cbsa',
    label: 'CBSA',
  },
  {
    index: 12,
    isActive: true,
    key: 'msa',
    label: 'MSA',
  },
  {
    index: 13,
    isActive: true,
    key: 'microSA',
    label: 'microSA',
  },
];
