import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';

const log = getChildLogger('forms.companyOnboardingDetails');

export class CompanyOnboardingDetailsForm extends Form {
  hooks() {
    return {
      onError: (form) => {
        try {
          this.onError(form);
        } catch (error) {
          log.error('Error while saving job opening form');
        }
      },
    };
  }
}

const fields = [
  'uuid',
  'onboardingTitle',
  'onboardingDescription',
  'onboardingRole',
  'onboardingEnabledTags',
  'onboardingRejectionTags',
  'onboardingStatus',
  'onboardingHideNameAndLogo',
];

const rules = {
  onboardingTitle: 'string|required',
};

const labels = {
  onboardingDescription: 'Description (optional)',
  onboardingEnabledTags: 'Enable',
  onboardingHideNameAndLogo: 'Hide company logo and name',
  onboardingRejectionTags: 'Rejection Tag',
  onboardingRole: 'Role',
  onboardingStatus: 'Onboarding Status',
  onboardingTitle: 'Onboarding Title',
  uuid: '',
};

export function init(
  values = {
    onboardingEnabledTags: [],
    onboardingHideNameAndLogo: false,
    onboardingRejectionTags: [],
    onboardingStatus: 'active',
  },
) {
  return new CompanyOnboardingDetailsForm({ fields, labels, rules, values });
}
