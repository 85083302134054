import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import cx from 'classnames';

// Components
import { Label, Popup } from 'semantic-ui-react';

// const log = logdown.getChildLogger('ThingStatusIndicator');

const thingStatusMap = {
  draft: {
    color: 'grey',
    basic: true,
    transitions: {
      pending: {},
      active: {},

      canceled: {},
      deleted: {},
      edit: {},
    },
    title: 'Pending',
  },
  pending: {
    color: 'grey',
    basic: false,
    transitions: {
      active: {},

      canceled: {},
      deleted: {},
      edit: {},
    },
  },
  active: {
    color: 'blue',
    basic: false,
    transitions: {
      draft: { 'assignments.length': '0' },

      canceled: {},
      edit: {},
    },
  },
  inProgress: {
    color: 'blue',
    basic: false,
    transitions: {
      edit: {},
    },
  },
  filled: {
    color: 'green',
    basic: false,
    transitions: {
      draft: {},
      canceled: {},
      deleted: {},
      edit: {},
    },
  },
  canceled: {
    color: 'red',
    basic: false,
    transitions: {},
  },
  expired: {
    color: 'orange',
    basic: true,
    transitions: {},
  },
  deleted: {
    color: 'red',
    basic: true,
    transitions: {},
  },
  completed: {
    color: 'green',
    basic: false,
    transitions: {},
  },
  assigned: {
    color: 'blue',
    basic: false,
    transitions: {},
  },
  accepted: {
    color: 'blue',
    basic: false,
    transitions: {},
  },
};

thingStatusMap.complete = thingStatusMap.completed;
thingStatusMap.review = thingStatusMap.inProgress;

const assignmentStatusMap = thingStatusMap;

assignmentStatusMap.assigned = assignmentStatusMap.active;

const checkThingPermission = (thing = {}, action) => {
  const status = thing.status;

  return !!_.get(thingStatusMap, _.toLower(`${status}.transitions.${action}`));
};

const checkAssignmentPermission = (assignment = {}, action) => {
  const status = assignment.status;
  return !!_.get(
    assignmentStatusMap,
    _.toLower(`${status}.transitions.${action}`),
  );
};

const ThingStatusIndicator = observer(
  ({
    thing,
    assignment,
    bare,
    className,
    prefixText,
    size,
    chip,
    statusKey = 'status',
  }) => {
    if ((!thing || !thing.uuid) && (!assignment || !assignment.uuid)) {
      return chip ? (
        <Label
          basic
          content="N/A"
          className={cx(className, 'small-caps')}
          size={size}
        />
      ) : (
        <Label circular empty basic size={size} />
      );
    }

    let statusSettings;

    if (thing) {
      statusSettings = thingStatusMap[_.toLower(thing[statusKey])] || {
        color: 'grey',
      };
      statusSettings.title = thing[statusKey] || 'n/a';
    } else if (assignment) {
      statusSettings = assignmentStatusMap[
        _.toLower(assignment[statusKey])
      ] || {
        color: 'grey',
      };
      statusSettings.title = assignment[statusKey] || 'n/a';
    }

    if (chip) {
      const content = _.toUpper(statusSettings.title);
      return (
        <Label
          content={content}
          className={cx(className, 'small-caps')}
          color={statusSettings.color}
          basic={statusSettings.basic}
          size={size}
        />
      );
    }

    const indicator = (
      <Label
        circular
        empty
        color={statusSettings.color}
        basic={statusSettings.basic}
        size={size}
      />
    );

    if (bare) {
      return (
        <Popup
          content={(thing || assignment)[statusKey]}
          trigger={<span className={className}>{indicator}</span>}
        />
      );
    }

    const status = _.toLower(
      statusSettings.title || _.get(thing || assignment, 'status'),
    );

    return (
      <span className={cx('status', className)}>
        {!_.isEmpty(prefixText) && <span className="pr1">{prefixText}</span>}
        <span className="ttc">{`${status} `}</span>
        {indicator}
      </span>
    );
  },
);

export default ThingStatusIndicator;

export { checkAssignmentPermission, checkThingPermission };
