import type { IRecurringSchedule } from '@shiftsmartinc/shiftsmart-types';

import { action } from 'mobx';

import BaseStore from './_baseStore';

export default class RecurringScheduleStore extends BaseStore<IRecurringSchedule> {
  constructor({
    serviceName = 'recurringSchedules',
    title = 'recurringSchedules',
  } = {}) {
    super({
      baseItem: RecurringScheduleStore.BASE_ITEM,
      serviceName,
      title,
    });

    return this;
  }

  static BASE_ITEM: IRecurringSchedule = {
    ...BaseStore.BASE_ITEM,
    activityLog: [],
    companyId: null,

    cycleDays: null,
    cycleStart: { dow: null },

    daysBeforeToRun: null,
    description: null,
    effectiveDate: null,
    endDate: null,
    lastRanAt: null,

    status: null,
    title: null,
  };

  @action
  getSelected() {
    return this.selected;
  }
}
