import { observable, action } from 'mobx';
import { dispatch } from 'rfx-core';

export default class LoadingModal {
  @observable
  message = null;

  @observable
  isOpen = false;

  @action
  open({ message = null } = {}) {
    this.message = message;
    this.isOpen = true;
  }

  @action
  close({ delay = 0, message = null } = {}) {
    setTimeout(
      action(() => {
        dispatch('agendaJobProgress.clearSelected');
        this.isOpen = false;
        this.message = null;
      }),
      delay,
    );

    this.message = message || null;
  }
}
