import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export type IMessagingCampaigns = IBaseItem & Record<string, unknown>;

export default class MessagingCampaignsStore extends BaseStore<IMessagingCampaigns> {
  constructor() {
    super({
      baseItem: MessagingCampaignsStore.BASE_ITEM,
      companyField: 'company',
      serviceName: 'messagingCampaigns',
    });

    return this;
  }

  static BASE_ITEM = {
    _modelName: '',
    company: null,
    createdAt: null,
    deliveryStats: {
      error: 0,
      sent: 0,
    },
    failedMessages: [],
    messageBody: null,
    messageType: null,
    recipients: [],
    sendAt: null,
    sender: null,
    status: null,
    updatedAt: null,
    uuid: null,
  };
}
