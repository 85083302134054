import type { IAddress } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';

import Form, { FormConstructorConfig, FormConstructorSetup } from './_.extend';
import {
  fields as addrFields,
  rules as addrRules,
  labels as addrLabels,
  AddrDetailFields,
} from './addressFields';

type FieldKeyedConfig = {
  [K in (typeof baseFields)[number]]: unknown;
} & Record<AddrDetailFields, unknown>;

type AddrFields = keyof FieldKeyedConfig;
const baseFields = [
  'uuid',
  'companies',
  'address',
  'brand',
  'placeId',
  'settings.opportunities',
  'settings.checkinCodeBypass',
  'settings.disablePushBackFlexShifts',
  'settings.newCheckinFlowEnabled',
  'settings.newConditionalCheckinFlowEnabled',
  'settings.showSecurityBadgeOnCheckIn',
  'settings.useDSFillPredictionRedundancy',
  'settings.maxPushBeyondFlexWindow',
  'settings.shifts.durationAutoCheckout',
  'formattedAddress',
  'parentIds',
  'parentObjs',
  'loc',
  'loc.coordinates',
  'loc.coordinates.1',
  'loc.coordinates.0',
  'loc.type',
  'isDeleted',
] as const;

const fields = _.concat(baseFields, addrFields);

export const rules = _.extend(
  {
    brand: 'string',
    companies: 'array',
    formattedAddress: 'string',
    'loc.coordinates': 'array',
    placeId: 'string',
    'settings.checkinCodeBypass': 'boolean',
    'settings.disablePushBackFlexShifts': 'boolean',
    'settings.maxPushBeyondFlexWindow': 'numeric',
    'settings.newCheckinFlowEnabled': 'boolean',
    'settings.newConditionalCheckinFlowEnabled': 'boolean',
    'settings.opportunities': 'boolean',
    'settings.shifts.durationAutoCheckout': 'numeric',
    'settings.showSecurityBadgeOnCheckIn': 'boolean',
    'settings.useDSFillPredictionRedundancy': 'boolean',
    uuid: 'string',
  },
  addrRules,
);

export const labels = _.extend(
  {
    address: 'Address',
    brand: 'Brand',
    companies: 'Companies',
    formattedAddress: 'Formatted Address',
    'loc.coordinates': 'Coordinates',
    placeId: 'Google Place ID',
    'settings.checkinCodeBypass': 'Enable Checkin Code Bypass Unassignment',
    'settings.disablePushBackFlexShifts':
      'Disable the ability for flexible shifts to be pushed back at this location',
    'settings.maxPushBeyondFlexWindow':
      'Max number of minutes after flex window end that a shift can be autopunted to',
    'settings.newCheckinFlowEnabled':
      'Enable New Checkin Flow after partner adds wrong code',
    'settings.newConditionalCheckinFlowEnabled':
      'Enable New Conditional based Checkin Flow',
    'settings.opportunities': 'Enable Opportunities',
    'settings.shifts.durationAutoCheckout': 'Duration AutoCheckout ',
    'settings.showSecurityBadgeOnCheckIn':
      'Enable showing the security badge on the checkin modal',
    'settings.useDSFillPredictionRedundancy': `Enable off platform redundancy (data science model)`,
    uuid: 'UUID',
  },
  addrLabels,
);

type PrefixedObj<PRE> = PRE extends undefined
  ? Record<AddrFields, string>
  : Record<`${string & PRE}.${AddrFields}`, string>;

export function getFormDefProperties<
  PRE extends string = null,
  FORM_OBJ = PrefixedObj<PRE>,
>({ prefix }: { prefix?: string } = {}): {
  fields: Array<keyof FORM_OBJ>;
  labels: FORM_OBJ;
  rules: FORM_OBJ;
} {
  return {
    fields: _.map(fields, (k) =>
      _.compact([prefix, k]).join('.'),
    ) as unknown as Array<keyof FORM_OBJ>,
    labels: _.mapKeys(labels, (v, k) =>
      _.compact([prefix, k]).join('.'),
    ) as unknown as FORM_OBJ,
    rules: _.mapKeys(rules, (v, k) =>
      _.compact([prefix, k]).join('.'),
    ) as unknown as FORM_OBJ,
  };
}

export type AddressFormInstance = AddressForm;

class AddressForm<T extends IAddress = IAddress> extends Form {
  constructor(
    {
      fields: f,
      rules: r,
      labels: l,
      values: v,
      ...restSetup
    }: FormConstructorSetup<T>,
    config?: FormConstructorConfig,
  ) {
    const values = _.clone(v);

    if (!values.loc || _.isEmpty(values.loc.coordinates)) {
      _.set(values, 'loc.coordinates', [null, null]);
    }

    const arg1 = {
      fields: _.union(f, fields),
      labels: _.defaults(l, labels),
      rules: _.defaults(r, rules),
      values,
      ...restSetup,
    };

    super(arg1, config);
  }
}

function init(values = {}) {
  return new AddressForm({ fields, labels, rules, values });
}

export { init, AddressForm };
