import _ from 'lodash';
import { observe, observable } from 'mobx';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('stores.ui.wokerSearchBar');

export default class WorkerSearchBar {
  @observable
  poolsIsOpen = true;

  @observable
  certsIsOpen = true;

  init() {
    observe(this, 'poolsIsOpen', (change) => {
      if (_.isEmpty(change)) return;

      if (change.oldValue !== change.newValue) {
        log.debug('Clearning Pool Filter');
        dispatch('partners.setPoolFilter', { poolIds: [] });
      }
    });

    observe(this, 'certsIsOpen', (change) => {
      if (_.isEmpty(change)) return;

      if (change.oldValue !== change.newValue) {
        log.debug('Clearning Cert Filter');
        dispatch('partners.setCertFilter', { op: 'set' });
      }
    });
  }
}
