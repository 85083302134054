import { action, computed, observable, runInAction, set } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';
import moment from 'moment';

import { service } from '#/shared/app';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.Launchpad');

export default class Launchpad {
  @observable
  selectedDay = moment();

  @observable
  activeTab = 'active';

  @observable
  isLoading = false;

  @observable
  stats = [];

  @observable
  shiftCountByStatus = {};

  @observable
  actionableShifts = 0;

  @observable
  currentScope = 'dates';

  approvalList = observable([]);

  @computed
  get approvalListFiltered() {
    return this.approvalList.filter((a) =>
      _.includes(['requested', 'PendingApproval'], a.status),
    );
  }

  initEvents() {
    service('assignments').on('updated', this.onUpdated);
    service('assignments').on('patched', this.onUpdated);
  }

  @action
  setup({ selectedDay }) {
    this.initEvents();
    this.selectedDay = selectedDay;
  }

  /* Originally copied from onUpdated handler in baseStore */
  @action
  onUpdated = (data) => {
    if (_.isEmpty(data)) {
      log.debug(`Empty Item in onUpdated for Launchpad store`);
      return false;
    }
    log.silly('Received %s Update: %O', 'Launchpad', data);

    const existing = _.find(this.approvalList, { uuid: data.uuid });
    if (existing && data.updatedAt > existing.updatedAt) {
      if (_.isBoolean(data.deleted) && data.deleted) {
        this.approvalList.remove(existing);
      } else {
        set(existing, data);
      }
    } else if (existing && !existing.updatedAt) {
      log.warn('No updatedAt timestamp present in existing data');
    } else if (existing) {
      log.warn('Ignoring out of order update event');
    }

    return true;
  };

  @action
  setSelectedDay(day) {
    dispatch('routing.addQuery', {
      currentDay: _.isNull(day) ? null : day.toISOString(),
    });
    this.selectedDay = day;
  }

  @action.bound
  async fetchApprovalList({ start, end }) {
    const assignments = await dispatch('assignments.runQuery', {
      start: {
        $gte: start.toDate(),
        $lte: end.toDate(),
      },
      status: 'PendingApproval',
    });

    log.debug('fetched assignments pending approval: ', { assignments });
    runInAction(() => {
      this.approvalList.replace([...assignments.data]);
    });
  }

  @action
  setCurrentScope(scope) {
    this.currentScope = scope;
  }

  @observable notificationsTable = null;

  @observable notificationScrollTop = 0;

  @action
  setRef({ notifications = this.notificationsTable }) {
    this.notificationsTable = notifications;
  }

  @action
  async setSelectedNotification({ notification } = {}) {
    if (this.notificationsTable && notification) {
      this.notificationScrollTop = this.notificationsTable.scrollTop;
    }

    await dispatch('ui.notifications.setSelected', {
      group: notification,
    });

    if (this.notificationsTable) {
      this.notificationsTable.scrollTo(
        0,
        notification ? 0 : this.notificationScrollTop,
      );
    }
  }

  @action
  setLoading(val = true) {
    this.isLoading = val;
  }

  @action
  setActiveTab(tab) {
    log.debug(`Setting active tab to "${tab}"`);
    let range = dispatch('ui.shiftsNextGen.getRange');
    switch (tab) {
      case 'active':
        break;
      case 'approval':
        if (!_.isNull(this.selectedDay)) {
          range = {
            end: moment(this.selectedDay).endOf('day'),
            start: moment(this.selectedDay).startOf('day'),
          };
        }
        this.fetchApprovalList(range);
        break;
      default:
        break;
    }
    this.activeTab = tab;
  }

  @action
  clear() {
    this.activeTab = 'active';
  }
}
