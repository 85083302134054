import { observable, action } from 'mobx';

export default class AssignmentApprovalModal {
  @observable
  isOpen = false;

  @observable
  assignment = {};

  @observable
  approvalAction = '';

  @observable
  approvalNote = '';

  @observable
  target = '';

  @action
  setup({ assignment = {}, open = this.isOpen, approvalAction, target }) {
    this.isOpen = open;
    this.assignment = assignment;
    this.approvalAction = approvalAction;
    this.target = target;
  }

  @action
  updateNote({ data }) {
    this.approvalNote = data.value;
  }

  @action
  clear() {
    this.isOpen = false;
    this.assignment = {};
    this.approvalAction = '';
    this.approvalNote = '';
    this.target = '';
  }
}
