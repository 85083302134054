import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export type IMessage = IBaseItem & Record<string, unknown>;

export default class MessageStore extends BaseStore<IMessage> {
  constructor() {
    super({
      baseItem: MessageStore.BASE_ITEM,
      searchFields: ['body'],
      serviceName: 'messages',
    });

    return this;
  }

  static BASE_ITEM = {
    body: null,
    channelSid: null,
    createdAt: null,
    from: {
      phoneNumber: null,
      uuid: null,
    },
    messageAttributes: {},
    messageCreated: null,
    messageIndex: null,
    messageType: null,
    messagingServiceSid: null,
    rawMessage: {},
    sid: null,
    status: null,
    to: {
      phoneNumber: null,
      uuid: null,
    },
    updatedAt: null,
  };
}
