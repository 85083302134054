import moment from 'moment';
import { observable, action } from 'mobx';
import _ from 'lodash';

export default class DateTimeSelect {
  @observable
  dateRangeStart = moment();

  @observable
  dateTime = null;

  @action
  get(key) {
    if (!_.has(this, key) && /^dateRangeStart$/.test(key)) {
      this[key] = moment();
    }
    return this[key];
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    this[key] = value;
    return Promise.resolve(this[key]);
  }
}
