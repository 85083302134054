/**
 * "Thing" is a arbitrary umbrella term for shift-related resources
 * Acts as superclass for other stores i.e. ShiftTemplate, etc.
 */
import type { ICompany, IShift, IThing } from '@shiftsmartinc/shiftsmart-types';

import { set, observable, action, computed, runInAction } from 'mobx';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import { Query } from '@feathersjs/feathers';

import { service } from '#/shared/app';
import { formatDate, addToDate } from '#/utils/date';
import { getStore, getStores } from '#/shared/getStores';

import BaseStore, { IBaseStore } from './_baseStore';

export default class ThingStore<
  T extends IThing = IThing,
> extends BaseStore<T> {
  constructor({
    serviceName = 'things',
    title = 'things',
    baseItem = ThingStore.BASE_ITEM,
    searchFields = [],
    ...rest
  } = {}) {
    super({
      baseItem: { ...ThingStore.BASE_ITEM, ...baseItem },
      searchFields: _.union(['title'], searchFields),
      serviceName,
      title,
      ...rest,
    });

    return this;
  }

  static BASE_ITEM = {
    activatedAt: null,
    address: null,
    assignedUsers: [],
    assignments: [{}],
    autoLaunch: null,
    company: null,
    completedAt: null,
    customer: null,
    description: null,
    duration: null,
    end: null,
    expiresAt: null,
    isWindow: false,
    marketingCode: null,

    // ----
    matches: {
      pools: [],
      users: [],
    },

    options: null,

    path: [],
    question: null,

    questionType: null,

    rate: null,

    remindInterval: 0,

    requirements: {},

    searchableText: null,

    slots: null,

    source: null,

    start: null,

    status: null,
    statusLog: [],
    title: null,
    uploadedAssets: [],
    user: null,
  };

  sort = undefined;

  @observable
  range = 'day';

  @observable
  offset = '0';

  @observable
  filter = 'all';

  @computed
  get filterOptions() {
    return [
      {
        enabled: true,
        key: 'all',
        text: `All ${_.capitalize(this.title)}`,
        value: 'all',
      },
      {
        enabled: true,
        key: 'pending',
        text: `Pending ${_.capitalize(this.title)}`,
        value: 'pending',
      },
      {
        enabled: true,
        key: 'active',
        text: `Active ${_.capitalize(this.title)}`,
        value: 'active',
      },
      {
        enabled: true,
        key: 'done',
        text: `Completed ${_.capitalize(this.title)}`,
        value: 'done',
      },
      {
        enabled: false,
        key: 'inactive',
        text: `Removed ${_.capitalize(this.title)}`,
        value: 'inactive',
      },
    ];
  }

  /** Overrides the baseStore getModelName to respect the `__t` of the thing */
  getModelName(instance) {
    const modelName = _.get(instance, '_modelName');

    if (instance && (!modelName || modelName === 'thing')) {
      return _.get(instance, '__t') || this.modelName;
    }

    return this.modelName;
  }

  @action
  clearSelected() {
    dispatch('addresses.clearSelected');

    return super.clearSelected();
  }

  @computed
  get start() {
    return moment().add(this.offset, this.range).startOf(this.range);
  }

  @computed
  get end() {
    return moment().add(this.offset, this.range).endOf(this.range);
  }

  @action
  prev() {
    this.offset -= 1;
    return this.find();
  }

  @action
  next() {
    this.offset += 1;
    return this.find();
  }

  @action
  setSort(sort = {}) {
    if (_.isEmpty(sort)) {
      return;
    }

    this.sort = sort;
  }

  @action
  setRange(range) {
    if (!/day|week|month|year/i.test(range)) {
      this.range = 'day';
    } else {
      this.range = range.toLowerCase();
    }
    return this.find();
  }

  @action
  create({ data, ...rest }) {
    const thing = _.clone(data);
    _.extend(thing, getStartAndEnd(thing));

    return super.create({ data: thing, ...rest });
  }

  @action
  update({
    data = {},
    id = data.uuid,
    params,
    query,
    isUpdatingMany = false,
    ids = [],
  }: Parameters<IBaseStore['update']>[0]) {
    const thing = _.clone(data);
    if (data.date || data.start || data.end) {
      _.extend(thing, getStartAndEnd(thing));
    }

    this.log.debug(`Patching ${this.title} %s w/ Data:`, id, thing);
    if (isUpdatingMany) {
      return this.updateMany({ data: thing, ids, params, query });
    }
    return super.update({ data: thing, id, params, query });
  }

  findPriority({
    company,
    limit = 10,
    date = moment().subtract(1, 'w').toDate(),
    status = ['Pending', 'Active'],
  }) {
    this.$pagination.limit = limit;

    const companyId = _.get(company, 'uuid', company);

    const query = {
      query: {
        company: companyId,
        start: { $gte: date },
        status: { $in: status },
      },
    };

    return this.find(query);
  }

  @action
  async find(query = {}, inputOpts = {}) {
    const opts = _.clone(inputOpts);
    opts.hooks = [
      ...(inputOpts.hooks || []),
      () =>
        _.has(this.query.query, 'status') &&
        _.includes(
          _.get(this.query.query.status, '$in', this.query.query.status),
          'all',
        ) &&
        runInAction(() => {
          _.pull(
            _.get(this.query.query.status, '$in', this.query.query.status),
            'all',
          );
        }),
      () =>
        _.has(this.query.query, 'status') &&
        _.isEmpty(
          _.get(this.query.query.status, '$in', this.query.query.status),
        ) &&
        runInAction(() => {
          this.log.debug('Removing empty query.status: ', {
            status: this.query.query.status,
          });
          delete this.query.query.status;
        }),
    ];

    return super.find(query, opts);
  }

  @action.bound
  async findByCompany({
    company = dispatch('auth.getCompany'),
    query = {},
    opts,
  }: Parameters<BaseStore<T>['findByCompany']>[0]) {
    const companyId: ICompany['uuid'] =
      (company as ICompany)?.uuid ?? (company as ICompany['uuid']);

    const companyQuery: Query = {
      company: { $in: [companyId] },
    };

    return this.find({ query: _.extend(companyQuery, query) }, opts);
  }

  /* Auxilliary & Utility Methods */

  changeWeek({ weeks, start, end, onChange }) {
    const startDay = moment(start);

    if (!_.isUndefined(weeks)) {
      startDay.add(weeks, 'weeks');
    }

    const gte = startDay.startOf('week');

    const lte = end
      ? moment(end).add(weeks, 'weeks')
      : gte.clone().endOf('week');

    const query = {
      start: {
        $gte: gte.toDate(),
        $lte: lte.toDate(),
      },
    };

    return this.find(query).then((things) => {
      if (_.isFunction(onChange)) {
        onChange({ end: lte, shifts: things, start: startDay, things });
      }

      return { end: lte, shifts: things, start: startDay, things };
    });
  }

  @action
  setActiveWorkers(workers) {
    this.activeWorkers = workers;
  }

  getAssignments({ query = {}, shiftCt, thing }) {
    return (
      thing
        ? service(this.serviceName).get(_.get(thing, 'uuid', thing), { query })
        : service(this.serviceName).find({ query })
    )
      .then((response) => {
        if (_.has(response, 'assignments')) {
          return response.assignments;
        }

        const { data, total } = response;
        if (_.isNumber(shiftCt)) {
          // eslint-disable-next-line no-param-reassign
          shiftCt = total;
        }
        return _(data).map('assignments').flatten().union().value();
      })
      .then((assignments) =>
        Promise.all(assignments.map((a) => dispatch('assignments.get', a))),
      );
  }

  findActiveWorkers(baseQuery) {
    const query = _.defaults(baseQuery, {
      end: { $gte: moment().toDate() },
      start: {
        $lte: moment().add(1, 'h').toDate(),
      },
    });

    const shiftCt = -1;
    return this.getAssignments({ query, shiftCt })
      .then((assignments) => Promise.all(_.map(assignments, 'user')))
      .then((workers) => {
        this.setActiveWorkers(_.uniq(workers));
        this.log.debug(
          'Found %d Active Workers across %d shifts',
          this.activeWorkers.length,
          shiftCt,
          this.activeWorkers,
        );
        return this.activeWorkers;
      });
  }

  assign({ thing, worker }) {
    const workerId = _.get(worker, 'uuid', worker);
    const id = _.get(thing, 'uuid', thing);
    this.log.debug('Assigning worker %s to %s', workerId, id);

    return service(this.serviceName)
      .patch(id, { $push: { assignments: workerId } })
      .then((res) => {
        this.log.debug('Post Assignment: ', res);

        return res;
      })
      .catch((err) => this.log.error(err));
  }

  updateIntakeTask({ task = this.selected, user = {} }) {
    const userUUID = _.get(user, 'uuid', user);
    const taskUUID = _.get(task, 'uuid', task);
    if (_.isEmpty(taskUUID) || _.isEmpty(userUUID)) {
      return false;
    }
    const data = {
      id: taskUUID,
      user: userUUID,
    };
    return this.update({ data, id: data.id }).then((res) => {
      const customerUUID = _.get(res, 'customer.uuid', res.customer);
      dispatch('customers.update', { user: res.user }, customerUUID);
    });
  }

  @action.bound
  async bulkReactivate() {
    const { ui } = getStores();
    const eligibleShifts = _(this.list).filter({ status: 'Active' }).value();

    if (_.isEmpty(eligibleShifts)) {
      ui.snackBar.error('No active shifts found in list');
      return;
    }

    ui.loadingModal.open({
      message: 'Reactivating Shifts',
      total: _.size(eligibleShifts),
    });

    await Promise.mapSeries(eligibleShifts, async (shift) => {
      ui.loadingModal.setCurrent(shift.title);
      await this.reactivate(shift.uuid);
      ui.loadingModal.increment();
      await new Promise((resolve) => setTimeout(resolve, 250));
    });

    ui.loadingModal.close({
      delay: 5000,
      message: `Triggered Reactivation for ${_.size(eligibleShifts)} shifts`,
    });
  }

  @action.bound
  async reactivate(thing: T | T['uuid'], opts) {
    const {
      ui: { snackBar },
      system,
    } = getStores();
    const shiftId = (thing as IThing)?.uuid ?? thing;
    const data = {
      status: 'Active',
    };

    try {
      const updatedShift = await this.update({
        data,
        id: shiftId,
        query: {
          status: { $in: ['Active'] },
        },
      });

      const { notificationPrefs } = updatedShift;

      const sentNPs = _.filter(notificationPrefs, { status: 'sent' });

      const results = await Promise.mapSeries(
        sentNPs,
        async ({ prefID }, i) => {
          const scheduleResult = await system.update({
            data: {
              attrs: {
                prefID,
                reactivate: true,
                shiftId,
              },
              name: 'processNotificationPref',
            },
            id: 'scheduleJob',
          });
          this.log.debug(`job #${i} scheduled`, { scheduleResult });

          return { ...scheduleResult, prefID };
        },
      );

      const reactivatedCt = _(results).filter({ status: 'ok' }).size();
      snackBar.open(`Reactivated Shift and Re-Queued ${reactivatedCt} NPs`);
    } catch (error) {
      this.log.error('Failed to reactivate shift', { error, shiftId });
      snackBar.error('Shift Reactivation Failed', { body: error?.message });
    }
  }

  activateThing({ thing }) {
    const data = {
      status: 'Active',
    };
    return this.update({ data, id: _.get(thing, 'uuid', thing) });
  }

  @action
  async activateThings({
    things,
    status = 'Draft',
    batchNotifications = true,
  }) {
    const log = this.log.getChildLogger('activateThings');
    const filteredThings = _.filter(things, { status });
    const company = dispatch('auth.getCompany');
    const sendActivationInBulk =
      company?.settings?.things?.sendActivationInBulk;
    // if enabled, schedule job to activate shifts instead
    if (sendActivationInBulk && batchNotifications) {
      dispatch('ui.agendaProgressLoadingModal.open', {
        message: 'Activating Shifts',
      });
      try {
        const res = await this.update({
          data: { dataType: this.serviceName, status: 'Active' },
          isUpdatingMany: true,
          query: {
            $client: {
              activateThings: {
                companyId: company.uuid,
                shiftIds: _.map(filteredThings, 'uuid'),
              },
            },
          },
        });

        const agendaJobProgress = res?.agendaJobProgress;
        if (agendaJobProgress) {
          await dispatch('agendaJobProgress.setSelected', agendaJobProgress);
        }
        dispatch('ui.agendaProgressLoadingModal.close', {
          delay: 1000,
          message: 'Shift Activation Complete',
        });
      } catch (error) {
        log.error('Failed to bulk activate things', error);
      }
    } else {
      dispatch('ui.loadingModal.open', {
        message: 'Activating Shifts',
        total: things.length,
      });
      await Promise.map(
        filteredThings,
        async (thing) => {
          try {
            await this.activateThing({ thing });
          } catch (error) {
            log.error(`Failed to activate ${_.get(thing, '__t')}`, error, {
              extra: { thing },
            });
          }

          dispatch('ui.loadingModal.increment');
        },
        { concurrency: 50 },
      );
      dispatch('ui.loadingModal.close', {
        delay: 1000,
        message: 'Shift Activation Complete',
      });
    }
  }

  /**
   *
   * Used when clicking "Activate All Shifts"
   */
  @action
  async activateAllThingsFromModal({ batchNotifications = true }) {
    dispatch('ui.agendaProgressLoadingModal.open', {
      message: 'Activating all shifts in the selected time range',
    });
    try {
      const range: { end: Moment; start: Moment } = dispatch(
        'ui.shiftsNextGen.getRange',
      );
      const { start, end } = range;

      const company = dispatch('auth.getCompany');
      const res = await this.update({
        data: { dataType: 'shifts', status: 'Active' },
        isUpdatingMany: true,
        query: {
          $client: {
            activateAllThings: {
              batchNotifications,
              companyId: company.uuid,
              end: end.toDate(),
              start: start.toDate(),
            },
          },
        },
      });

      const agendaJobProgress = res?.agendaJobProgress;
      if (agendaJobProgress) {
        await dispatch('agendaJobProgress.setSelected', agendaJobProgress);
      }
    } catch (error) {
      this.log.error('Failed to activate ALL things', error);
    }
  }

  /* EVENTS */

  onCreated = (item) => this.addItem(item);

  @action
  onUpdated = (data) => {
    if (_.isEmpty(data)) {
      this.log.debug(`Empty Item in onUpdated for ${this.serviceName}`);
      return;
    }
    this.log.silly('Received Thing Update: %O', data);

    let existing;

    if (data.status === 'Deleted' || data.status === 'Canceled') {
      _.remove(this.list, { uuid: data.uuid });
    } else {
      existing = _.find(this.list, { uuid: data.uuid });
    }

    if (existing && data.updatedAt > existing.updatedAt) {
      set(existing, data);
    } else if (existing && !existing.updatedAt) {
      this.log.warn('No updatedAt timestamp present in existing data');
    } else if (existing) {
      this.log.warn('Ignoring out of order update event');
    }

    if (
      _.get(this.selected, 'uuid') === data.uuid &&
      data.updatedAt > this.selected.updatedAt
    ) {
      set(this.selected, data);
    }
  };

  // onPatched = (id, data) => {};

  // onRemoved = (id, params) => {};

  /* ACTIONS */

  @action
  filterBy(filter) {
    if (_.has(filter, 'filter') && filter?.param) {
      if (
        filter.param === 'addressRef' ||
        filter.param === 'parentAddressRef'
      ) {
        return this.find({
          query: {
            [filter.param]: filter.filter,
          },
        });
      }
    }

    this.filter = filter;
    let status;

    switch (this.filter) {
      case 'pending':
        status = { $in: ['Draft', 'Pending'] };
        break;
      case 'active':
        status = { $in: ['Active', 'Filled'] };
        break;
      case 'filled':
        status = { $in: ['Filled'] };
        break;
      case 'unfilled':
        status = { $in: ['Active'] };
        break;
      case 'inactive':
        status = { $in: ['Canceled', 'Expired', 'Deleted'] };
        break;
      case 'done':
        status = 'Completed';
        break;
      case 'all':
      default:
        status = { $nin: ['Deleted', 'Canceled'] };
    }

    this.clearQueryPath('status');
    return this.find({ query: { status } });
  }

  @observable
  cloneTotal = 0;

  @observable
  cloneCompleted = 0;

  get cloningProgress() {
    if (!this.cloneTotal) {
      return -1;
    }

    return this.cloneCompleted / this.cloneTotal;
  }

  defaultPickValues = [
    'company',
    'description',
    'duration',
    'end',
    'isRemote',
    'pay',
    'rate',
    'search',
    'slots',
    'start',
    'title',

    'addressRef',
    'parentAddressRefs',

    // "Shift Specific"
    'appActionType',
    'autoLaunch',
    'bonus',
    'defaultChildren',
    'disablePartnerCancel',
    'disableShiftAcceptAfterStart',
    'enableBulkNotifications',
    'enableCheckinCode',
    'enablePartnerCancel',
    'excludeFromDashboardCalc',
    'externalId',
    'externalLink',
    'externalLinkButtonText',
    'inShiftTasksTemplateId',
    'inviteMode', // Leave in for backwards compatibility?
    'inviteRadius',
    'isWindow',
    'notificationPrefs',
    'options',
    'photoUploadRequired',
    'positionId',
    'postShiftSurveyDefinitions',
    'preShiftSurveyDefinitions',
    'remindInterval',
    'requireCheckinCode',
    'requireCheckoutCode',
    'roleId',
    'sendCheckinReminder',
    'sendReminder',
    'sendSMS',
    'shiftScheduleRules',
    'shiftScheduleType',
    'shiftType',
    'taskType',
    'useFlatRatePay',

    // Dispatch
    'inviteMode',
  ] as const;

  async clone({ thing, thingId = '', shallow = false, week = false }) {
    let thingObj = thing;
    /**
     * if thingObj's uuid does not match thingId, and thingId is non-null, then
     * reassign thingObj
     */
    if (!_.isEmpty(thingId) && thingId !== thingObj?.uuid) {
      thingObj = _.find(this.list, { uuid: thingId });

      if (!thingObj) {
        return this.logAndThrow(
          new Error(`Not found for ${this.serviceName}: ${thingId}`),
        );
      }
    }

    const pickValues: Array<keyof IShift> = [...this.defaultPickValues];

    /**
     * NOTE: The following code should be extracted to a transform function | Young [08-01-2022]
     */

    const newShift = _.pick(thingObj, pickValues);

    // company info is denormalized, so we unwrap the id and assign it
    if (newShift.company?.uuid) {
      newShift.company = newShift.company.uuid;
    }

    newShift.notificationPrefs = filterNotificationPrefs(
      newShift.notificationPrefs,
    );

    this.log.debug(
      `Cloning thing of type ${this.serviceName} with data`,
      newShift,
    );

    if (week) {
      newShift.start = addToDate(1, 'week', newShift.start);
      newShift.end = addToDate(1, 'week', newShift.end);

      this.log.debug(
        `Clone ${this.serviceName} with new week`,
        formatDate('MM/DD', newShift.start),
      );
    }

    try {
      const response = await service(this.serviceName).create(newShift);
      this.log.debug(`Created Thing: ${response}`);

      return response;
    } catch (e) {
      return this.logAndThrow(e);
    }
  }

  @action
  async cloneWeek({ shifts = this.list, onComplete = false } = {}) {
    this.cloneTotal = shifts.length;
    this.cloneCompleted = 0;

    // refetch this data because "shifts" contains data from ShiftsNextGen.fetchData, which doesn't include notificationPrefs due to performance issues
    const shiftIds = _.map(shifts, 'uuid');
    const { data: shiftsData } = await this.runQuery({
      $select: this.defaultPickValues,
      uuid: { $in: shiftIds },
    });

    return Promise.map(
      shiftsData,
      (shift) =>
        this.clone({ thing: shift, week: true }).then(
          action(() => {
            this.cloneCompleted += 1;
          }),
        ),
      { concurrency: 10 },
    ).then(() => {
      if (_.isFunction(onComplete)) {
        onComplete();
      } else {
        this.changeWeek({
          onChange: this.onDateChange,
          start: this.currentDay,
          weeks: 1,
        });
      }

      setTimeout(() => {
        this.clearCloneStats();
      }, 500);
    });
  }

  @action
  clearCloneStats() {
    this.cloneTotal = 0;
    this.cloneCompleted = 0;
  }

  // #region migrate ui.shiftsNextGen back to base

  // #region Shift Status Filtering
  @computed
  get allStatuses() {
    return _.compact([
      'Active',
      'Filled',
      'Completed',
      'Draft',
      'Pending',
      'Expired',
      !!getStore('auth').company?.settings?.featureFlags
        ?.showCanceledFilterOption && 'Canceled',
    ]);
  }

  @action.bound toggleShiftStatus(label, { checked, exclusive, all }) {
    let filter = _.clone(this.statusFilter);

    if (exclusive) {
      filter = [];
    }
    if (all || /all/i.test(label)) {
      filter = ['all'];
    } else if (checked) {
      filter.push(label);
    } else {
      if (filter.includes('all')) {
        filter = [...this.allStatuses];
      }
      _.pull(filter, label);
    }

    if (_.xor(filter, this.allStatuses).length === 0) {
      filter = ['all'];
    }

    if (filter.length === 0 || _.includes(filter, 'all')) {
      this.find({ status: undefined });
    } else {
      this.find({ status: { $in: filter } });
    }
  }

  @computed get statusFilterSummary() {
    let currentStatusFilters = this.statusFilter;
    if (
      this.statusFilter.includes('all') ||
      _.without(this.allStatuses, ...this.statusFilter).length === 0
    ) {
      currentStatusFilters = ['All'];
    }

    return currentStatusFilters.length > 3
      ? `${currentStatusFilters.length} Selected`
      : currentStatusFilters.join(', ');
  }
  // #endregion Shift Status Filtering

  // #endregion migrate ui.shiftsNextGen back to base

  @computed get statusFilter() {
    const filter = _.compact(
      _.get(this.query.query, 'status.$in') || [
        _.get(this.query.query, 'status'),
      ],
    );

    if (_.isEmpty(filter)) {
      return ['all'];
    }

    return filter;
  }

  // categories for manager cancel reasons
  @computed
  get categories() {
    const company = dispatch('auth.getCompany');
    const companyCancelReasons =
      company?.settings?.shifts?.companyCancelReasons || [];
    const companyCancelReasonsObjs = companyCancelReasons.map((reason) => ({
      key: _.camelCase(reason),
      text: reason,
      value: _.camelCase(reason),
    }));
    return _.uniqBy(
      _.concat(
        _.compact([
          {
            key: '1',
            text: 'The partner is no longer interested in the role',
            value: 'notInterested',
          },
          {
            key: '2',
            text: 'The partner has a scheduling conflict',
            value: 'schedulingConflict',
          },
          {
            key: '3',
            text: 'Shift location is inconvenient for partner',
            value: 'inconvenientLocation',
          },
          {
            key: '4',
            text: 'Scheduled by mistake',
            value: 'scheduledByMistake',
          },
          {
            key: '5',
            text: 'The partner ran into issue while commuting',
            value: 'commutingIssue',
          },
          {
            key: '6',
            text: 'The partner had a personal emergency',
            value: 'emergency',
          },
          {
            key: '7',
            text: 'The partner does not feel well',
            value: 'sick',
          },
          company.isShiftsmartManaged && {
            key: '8',
            text: 'The partner did not show up for the shift',
            value: 'noShow',
          },
        ]),
        companyCancelReasonsObjs,
        [
          {
            key: '8',
            text: 'None of the above',
            value: 'other',
          },
        ],
      ),
      'value',
    );
  }
}

// TODO: Do we still need this? Zibo [2019-04-22]
// TODO: I believe it is still viable, but should be restricted to `thing.__t = shift/task`(?)
function getStartAndEnd(thing) {
  const startTime = moment.tz(thing.start, thing.timezone);
  if (thing.date) {
    _.set(thing, 'start', moment(thing.date));
  } else {
    _.set(thing, 'start', moment.tz(thing.start, thing.timezone));
  }
  _.set(thing, 'end', moment.tz(thing.end, thing.timezone));

  thing.start.set({
    hour: startTime.get('hour'),
    minute: startTime.get('minute'),
    second: 0,
  });

  // TODO: Consistently store duration as Minutes

  if (thing.isWindow) {
    _.set(thing, 'duration', Number((thing.duration * 60).toFixed(0)) / 60);
  } else if (thing.duration && !thing.end) {
    _.set(thing, 'end', moment(thing.start).add(thing.duration, 'hours'));
  } else if (thing.end && !thing.duration) {
    _.set(thing, 'duration', thing.end.diff(thing.start, 'minutes') / 60);
  }

  // eslint-disable-next-line no-param-reassign
  delete thing.date;
  _.set(thing, 'start', thing.start ? thing.start.toDate() : null);
  _.set(thing, 'end', thing.end ? thing.end.toDate() : null);

  return thing;
}

function filterNotificationPrefs(prefs) {
  return _(prefs)
    .reject(notificationPrefIsInvalid)
    .map(sanitizeNotificationPref)
    .value();
}

function sanitizeNotificationPref(pref) {
  return _.omit(pref, [
    'sentAt',
    'baselineTime',
    'status',
    'processedIndex',
    'log',
    'ignoredUsers',
  ]);
}

function notificationPrefIsInvalid({ category, note }) {
  return (
    /unavailable|conflict|oversubscribed|restricted|retroactive|dynamicBonus/i.test(
      category,
    ) || /Auto-Invited/i.test(note)
  );
}
