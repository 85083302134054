import type { IAddress } from '@shiftsmartinc/shiftsmart-types';
import type { StoreName } from '#/shared/stores';

import { action } from 'mobx';
import _ from 'lodash';

import { service } from '#/shared/app';

import BaseStore, { StoreConstructorProps } from './_baseStore';

export default class AddressStore<
  T extends IAddress = IAddress,
> extends BaseStore<T> {
  constructor({
    serviceName = 'addresses',
    title = serviceName,
    baseItem,
    searchFields,
    ...rest
  }: Partial<StoreConstructorProps<T>> = {}) {
    super({
      ...rest,
      baseItem: { ...AddressStore.BASE_ITEM, ...((baseItem ?? {}) as T) },
      debounceSearch: 300,
      searchFields: _.isArray(searchFields)
        ? _.union(searchFields, ['formattedAddress'])
        : false,
      serviceName,
      title: _.compact(_.uniq(['addresses', title])).join('.'),
    });

    return this;
  }

  static BASE_ITEM: IAddress = {
    __t: 'address',
    address: {
      city: null,
      state: null,
      street1: null,
      street2: null,
      zip: null,
    },
    companies: [],
    formattedAddress: null,
    loc: { coordinates: [], type: 'Point' },
    placeId: null,
    ...BaseStore.BASE_ITEM,
  };

  initEvents() {
    super.initEvents();
    /* 
      As `addresses` store can contain zone or sites type data or combine both. 
      After updating these data, it will update the list or selected item of this store.
      For the `zones` store or `sites` store, we do not invoke these events, as they have their own.
    */
    if (this.serviceName === 'addresses') {
      service('zones').on('created', action(this.onCreated)); // onCreated = (data, params) => {}
      service('zones').on('updated', action(this.onUpdated)); // onUpdated = (data) => {}
      service('zones').on('patched', action(this.onPatched)); // onPatched = (id, data) => {}
      service('zones').on('removed', action(this.onRemoved));
      service('sites').on('created', action(this.onCreated)); // onCreated = (data, params) => {}
      service('sites').on('updated', action(this.onUpdated)); // onUpdated = (data) => {}
      service('sites').on('patched', action(this.onPatched)); // onPatched = (id, data) => {}
      service('sites').on('removed', action(this.onRemoved));
    }
  }

  create(arg1) {
    return super.create(_.has(arg1, 'data') ? arg1 : { data: arg1 });
  }

  reset(...args) {
    this.log.warn(
      'the `reset` method is depreacted. Please use `resetSearch` instead',
    );
    return this.resetSearch(...args);
  }

  resetSearch({ ...args } = {}) {
    return super.resetSearch({
      ...args,
    });
  }

  find(query = {}, opts = {}) {
    let params = opts;
    if (_.isBoolean(opts)) {
      this.log.warn(
        `${this.title}.find is using a deprecated method signature`,
      );
      params = { clear: opts };
    }
    return super.find(query, params);
  }

  @action
  addCompany({ store, company = {} }) {
    return service(this.serviceName)
      .patch(store.uuid, { $push: { companies: company.uuid } })
      .catch((err) => this.log.error(err));
  }

  @action
  removeCompany({ store, company = {} }) {
    if (!_.includes(store.companies, company.uuid)) {
      return false;
    }
    return service(this.serviceName)
      .patch(store.uuid, { $pull: { companies: company.uuid } })
      .catch((err) => this.log.error(err));
  }

  @action
  filterBy(filter) {
    this.filter = filter;
    let isShoppable;

    switch (this.filter) {
      case 'all':
        this.query.query.isShoppable = undefined;
        break;
      case 'shoppable':
        isShoppable = true;
        break;
      case 'unshoppable':
        isShoppable = false;
        break;
      default:
        isShoppable = 'all';
    }

    if (filter === 'all') return this.find();
    return this.find({ query: { isShoppable } });
  }

  // async resetSetting(setting, live) {
  //   const companyId = '6c8724ca-4c6a-46d9-b5fe-e3dc32b4ccf6';
  //   const { data: affected } = await this.runQuery({
  //     query: {
  //       companies: companyId,
  //       [setting]: { $exists: true },
  //     },
  //   });

  //   this.log.debug('found matching addresses', {
  //     affected,
  //     affectedCt: affected.length,
  //   });

  //   if (live) {
  //     return Promise.map(affected, ({ uuid }) => {
  //       return this.update({
  //         data: {
  //           $unset: { [setting]: 1 },
  //         },
  //         id: uuid,
  //       });
  //     });
  //   }
  // }
}
