import type { IAttribute } from '@shiftsmartinc/shiftsmart-types';

import BaseTagStore from './_baseTagStore';

export default class AttributeStore extends BaseTagStore<IAttribute> {
  constructor() {
    super({
      baseItem: {
        certType: 'attribute',
        questions: [],
        title: null,
        uuid: null,
      },
      serviceName: 'attributes',
    });

    return this;
  }
}
