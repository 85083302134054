import type { IChannel } from '@shiftsmartinc/shiftsmart-types';

import {
  observable,
  action,
  computed,
  set,
  runInAction,
  IObservableArray,
} from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';
import moment from 'moment';
import Promise from 'bluebird';

import { service } from '#/shared/app';
import { getFormattedStartEndDate } from '#/utils/date';

import BaseStore from './_baseStore';

export default class ChatChannelStore extends BaseStore<
  IChannel & { isStub?: boolean }
> {
  constructor({ serviceName = 'channels', title = 'chatChannels' } = {}) {
    super({
      baseItem: ChatChannelStore.BASE_ITEM,
      preserveFields: ['companies'],
      searchFields: ['channelName', 'lastMessage.body', 'users.displayName'],
      serviceName,
      title,
    });

    this.filter = ['all'];

    this.log.debug('Created new "ChatChannel" Store');
    return this;
  }

  static BASE_ITEM = {
    assignedTo: null,
    channelImage: null,
    channelName: null,
    channelType: {},
    companies: [],
    createdAt: null,
    isBroadcast: false,
    lastMessage: {},
    resolved: false,
    sid: null,
    tags: [],
    unreadCount: 0,
    updatedAt: null,
    users: [],
  };

  @observable
  isLoadingChannel = false;

  @observable isLoadingRecents = false;

  @observable
  isLoadingUnreadMessageCount = false;

  @observable
  isLoadingUnresolvedChatCounts = false;

  @observable
  _unreadCountChannelMap = [];

  @observable
  selectedMessage = { uuid: null };

  @observable
  recentMessages = [];

  @observable
  selectedMenuItem = 'assignedToSelf';

  @observable
  unresolvedChatCounts = {
    allCount: 0,
    myMessagesCount: 0,
    newCount: 0,
  };

  @observable
  filterBarOpen = false;

  @observable
  customMenuItems = [];

  @observable
  customDropdownItems = [];

  @observable
  messageTagSearchKeyword = '';

  @observable
  customFilterSearchKeyword = '';

  @observable
  activeDropdownFilter = '';

  @observable
  messageTagList = [];

  @observable
  freezeChannelList = false;

  @observable
  viewOptions = {
    showAllChannels: false,
  };

  @observable
  messageOptionsIds = [];

  @observable
  selectedChannels = [];

  @action
  bulkSelectChannel({ channel, checked }) {
    if (checked) {
      this.selectedChannels.push(channel);
    } else {
      this.selectedChannels.replace(
        _.filter(this.selectedChannels, (c) => c.sid !== channel.sid),
      );
    }
  }

  @action
  clearSelectedChannels() {
    this.selectedChannels = [];
  }

  @action toggleViewOption(key, value = !this.viewOptions[key]) {
    this.viewOptions[key] = value;
  }

  /** NOTE (CP from assignments store)
   * Normally, we don't want to override the initEvents method from the
   * base store, since it handles the standard `on*` events wrt the events
   * sent from the server. However, it is not easy to override arrow functions
   * from a parent class from a achild class, so we must rename the function
   * and _not_ call `super.initEvents`.
   *
   * In this case, we want to update unreadMessageCount when receiving a channel
   * update where lastConsumedIndex or lastMessage.index has changed
   */
  initEvents() {
    this.log.debug('chatChannels store initEvents()');
    service(this.serviceName).on('created', action(this.onCreated));
    service(this.serviceName).on('updated', action(this.onUpdatedLocal));
    service(this.serviceName).on('patched', action(this.onUpdatedLocal));
    service(this.serviceName).on('removed', action(this.onRemoved));
  }

  @action
  async setup({ company, chatId }) {
    const existing = chatId ? _.find(this.list, { uuid: chatId }) : false;
    const clearQuery =
      this.searchValue === '' &&
      (_.includes(this.filter, 'all') || !this.filter.length) &&
      !existing;

    const query = {
      $limit: 20,
      companies: company.uuid,
      'lastMessage.messageCreated': { $exists: true },
    };

    this.toggleViewOption('showAllChannels', false);
    await this.find(query, {
      clear: clearQuery,
      noQuery: true,
    });
    // this.loadUnreadMessageCount();
    this.searchMessageTag({}, {});
    this.filterBy({ channelTypes: this.filter });
    this.loadMessageOptions();
  }

  findExistingChannel({ query }) {
    return service(this.serviceName)
      .find({ query })
      .catch((err) => {
        this.log.error('Error finding channels', err);
        return Promise.reject(err);
      });
  }

  getBySid(sid) {
    const c = _.find(this.list, { sid });

    if (c) {
      return Promise.resolve(c);
    }

    return this.findExistingChannel({ query: { sid } }).then((res) => {
      const cs = res.data;
      return _.first(cs);
    });
  }

  @action
  search(searchValue = null, ...rest) {
    this.searchValue = searchValue || '';
    return Promise.resolve(this.debouncedSearch(this.searchValue, ...rest));
  }

  debouncedSearch = _.debounce(this.doSearch, 500);

  doSearch(searchValue = null, { query = {}, ...opts } = {}) {
    const searchedValue = this.searchValue || searchValue || '';
    const buildQuery = _.extend({ $skip: 0 }, query);

    if (_.isEmpty(this.searchValue)) {
      buildQuery.$or = undefined;
    } else {
      const $or = [];
      _.each(this.searchFields, (field) => {
        if (/users/i.test(field)) {
          const userField = _.split(field, '.')[1];
          $or.push({
            users: {
              $elemMatch: {
                [userField]: {
                  $options: 'i',
                  $regex: `.*${searchedValue}.*`,
                },
                userType: 'worker',
              },
            },
          });
        } else {
          $or.push({
            [field]: {
              $options: 'i',
              $regex: `.*${searchedValue}.*`,
            },
          });
        }
      });
      buildQuery.$or = $or;
    }

    return this.find({ query: buildQuery }, opts);
  }

  @action
  find(query, opts) {
    this.log.debug('Sorting channels by: ', {
      sort: JSON.stringify(this.sort),
    });
    _.set(opts, 'smart', true);

    return super.find(query, opts).finally(() => {
      // this.checkOnlineStatusForList();
    });
  }

  checkOnlineStatusForList() {
    return _(this.list)
      .filter((c) => /one-on-one/i.test(_.get(c, 'channelType.category')))
      .map((ch) => ch.users.slice())
      .flatten()
      .map('uuid')
      .uniq()
      .each((user) => {
        dispatch('twilioConversations.checkOnlineStatus', { user });
      });
  }

  @action
  async getChannelForUser({ userId, company, stub, create }) {
    const query = {
      'channelType.category': 'one-on-one',
      companies: company,
      'users.uuid': userId,
    };

    const { data } = await this.findExistingChannel({ query });

    const retval = _.first(data) || null;

    if (retval || (!stub && !create)) {
      return retval;
    }

    const user = await dispatch('partners.get', userId);

    if (create) {
      if (dispatch('auth.can', 'update', user, 'chat')) {
        // this.removeStubForUser(userId);

        return this.createWorkerChannel({
          workers: [user],
        });
      }

      return dispatch('ui.snackBar.warn', 'Messaging Disabled', {
        body: 'Only partners currently scheduled for a shift are available for chat',
      });
    }

    if (stub) {
      const channelStub = _.clone(ChatChannelStore.BASE_ITEM);

      channelStub.isStub = true;
      channelStub.uuid = userId; // ?
      channelStub.users = [_.defaults({ userType: 'worker' }, user)];
      channelStub.channelType = { category: 'one-on-one' };

      return channelStub;
    }

    return retval;
  }

  removeStubForUser(userId) {
    try {
      // FIXME: This looks broken in many ways; query should be second arg, and category does not exist at the root level
      const existingStubs = this.removeItem({
        // should be channelType.category
        category: 'one-on-one',

        isStub: true,

        uuid: userId,
      });

      this.log.debug('Removed Stubs: ', existingStubs);
    } catch (err) {
      this.log.warn('Unable to remove stub', err);
    }
  }

  /* ACTIONS */

  @action
  async setManagerSelected() {
    const company = dispatch('auth.getCompany');
    const managerChannelRes = await this.runQuery({
      'channelType.category': 'internal',
      companies: company.uuid,
      deleted: { $ne: true },
    });
    const managerChannel = _.first(managerChannelRes?.data);
    if (_.isEmpty(managerChannel)) {
      this.log.error(
        'Trying to select nonexistent manager channel! Returning...',
      );
      return;
    }
    dispatch('routing.push', `/chats/${managerChannel.uuid}?autoFocus=true`);
    dispatch('ui.chatInput.setMessageText', { value: '' });
  }

  @action
  setSelected(...args) {
    return super.setSelected(...args).then(async (res) => {
      // set selected for twilio side object
      await dispatch('twilioConversations.setSelected', res);
      this.setSelectedMessage();
      return res;
    });
  }

  @action.bound
  async doChannelSelect({ channel: c, sid, worker, company }) {
    this.isLoadingChannel = true;

    let channel = {};
    if (c && _.isString(c)) {
      channel = _.find(this.list, { uuid: c });
      if (!channel) {
        channel = await this.get(c, { select: false });
      }
    } else if (sid && worker) {
      channel = await this.get(null, {
        query: {
          sid,
          users: _.get(worker, 'uuid', worker),
        },
      });
    }

    dispatch('ui.chatMessaging.clear');

    if (_.isEmpty(channel)) {
      runInAction(() => {
        this.isLoadingChannel = false;
      });
      throw new Error('Failed to find channel', {
        channel: c,
        company,
        sid,
        worker: _.get(worker, 'uuid', _.isString(worker) && worker),
      });
    }

    this.log.debug(
      'Selecting Channel: ',
      _.get(channel, 'channelName', channel),
    );

    let selected;

    try {
      dispatch('ui.chatInput.setup', { channel });
      if (channel.uuid !== this.selected?.uuid) {
        selected = await this.setSelected(channel);
      }

      const { channelType } = selected;
      // TODO: Differentiate between conversation and chat channel!
      let isTwilioLoaded = false;
      try {
        isTwilioLoaded = await dispatch(
          'twilioConversations.loadMessages',
          selected,
        );
      } catch (err) {
        this.log.error('Error Loading Twilio Conversations Messages', err);
        isTwilioLoaded = false;
      }

      this.log.debug(
        `${isTwilioLoaded ? 'Loaded' : 'Did not Load'} Twilio Messages`,
      );

      const workerUUID =
        _.get(worker, 'uuid', worker) ||
        (/one-on-one/.test(channelType.category) &&
          _.find(channel.users, { userType: 'worker' }, {}).uuid);

      if (!workerUUID || _.isEmpty(workerUUID)) {
        dispatch('messages.emptyList');
        dispatch('ui.chatMessaging.setRecipient');
      }

      // if (/one-on-one/.test(channelType.category) && workerUUID) {
      //   dispatch('ui.chatMessaging.setRecipient', workerUUID);
      // }
    } catch (err) {
      runInAction(() => {
        this.isLoadingChannel = false;
      });
      this.log.error('Failed to select channel and messages', err);
      throw err;
    }

    runInAction(() => {
      this.isLoadingChannel = false;
    });

    return selected;
  }

  @action
  clearSelected() {
    this.setSelectedMessage();
    dispatch('twilioConversations.clearSelected');
    return super.clearSelected();
  }

  @action
  setSelectedMenuItem(value) {
    this.selectedMenuItem = value;
    return this.filterBy({ channelTypes: this.filter });
  }

  @action
  toggleFilterBar(value = !this.filterBarOpen) {
    this.filterBarOpen = value;
  }

  @action
  filterBy({ channelTypes, refs, skipQuery, didCompanyChange = false }) {
    this.filter = channelTypes;
    if (skipQuery) {
      return false;
    }

    // Don't query chat channels if admin and not shiftsmart managed company
    const user = dispatch('auth.getUser');
    const company = dispatch('auth.getCompany');
    if (user.isAdmin && !company?.isShiftsmartManaged) {
      this.emptyList();
      return false;
    }

    const query = {
      $and: [],
      $client: {},
      $limit: 20,
      $skip: 0,
    };

    if (_.includes(this.filter, 'partners')) {
      query.$and.push({
        'channelType.category': { $nin: ['group'] },
      });
    }
    if (_.includes(this.filter, 'group')) {
      query.$and.push({ 'channelType.category': 'group' });
    }
    if (_.includes(this.filter, 'shifts')) {
      query.$and.push({
        'channelType.category': 'group',
        'channelType.refType': 'shift',
      });
    }
    if (_.includes(this.filter, 'shiftsToday')) {
      query.$client.getChannelsByShiftQuery = {
        $or: [
          {
            start: {
              $gte: moment().startOf('day').toDate(),
              $lte: moment().endOf('day').toDate(),
            },
          },
          {
            end: {
              $gte: moment().startOf('day').toDate(),
              $lte: moment().endOf('day').toDate(),
            },
          },
        ],
        __t: 'shift',
        assignedUsers: { $gt: [] },
        company: company.uuid,
        shiftType: 'standard',
        status: { $in: ['Active', 'Filled', 'Completed'] },
      };
    }
    if (_.includes(this.filter, 'unresolved')) {
      query.$and.push({ resolved: false });
    }
    if (_.includes(this.filter, 'resolved')) {
      query.$and.push({ resolved: true });
    }
    if (_.includes(this.filter, 'unread')) {
      query.$client.getUnreadChannels = true;
    }
    if (_.includes(this.filter, 'shift')) {
      query.$client.getChannelsByShiftQuery = {
        uuid: _.get(refs, 'shift'),
      };
    }
    if (_.includes(this.filter, 'cert')) {
      query.$client.getChannelsByCertId = _.get(refs, 'cert');
    }
    if (_.includes(this.filter, 'certAggregate')) {
      query.$client.getChannelsByCertIdAggregate = _.get(refs, 'certAggregate');
    }
    if (_.includes(this.filter, 'messageTag')) {
      query.$and.push({ 'tags.uuid': _.get(refs, 'messageTag') });
    }
    if (_.includes(this.filter, 'agent')) {
      query.$and.push({ 'assignedTo.uuid': _.get(refs, 'agent') });
    }
    if (_.includes(this.filter, 'readyForShift')) {
      query.$and.push({ 'shiftProperties.readyForShift': true });
    }
    if (this.selectedMenuItem === 'assignedToSelf') {
      query.$and.push({ 'assignedTo.uuid': user.uuid });
    } else if (this.selectedMenuItem === 'unassigned') {
      query.$and.push({ assignedTo: null });
    }

    if (_.isEmpty(query.$and)) {
      query.$and = undefined;
    }

    if (_.isEmpty(query.$client)) {
      query.$client = undefined;
    }

    if (didCompanyChange) {
      query.companies = company.uuid;
    }

    return this.find(query, {
      preserve: _.compact([
        !didCompanyChange && 'companies',
        '$sort',
        '$limit',
      ]),
    });
  }

  @action assignChannelToSelf(uuid) {
    const user = dispatch('auth.getUser') || {};
    this.assignChannelToUser(uuid, user);
  }

  @action
  async assignChannelToUser(uuid, user) {
    const authUser = dispatch('auth.getUser');
    const worker = authUser.uuid === user.uuid ? 'you' : user.displayName;
    dispatch('ui.snackBar.open', `Channel assigned to ${worker}`);
    const displayName = _.get(user, 'displayName');
    const userId = _.get(user, 'uuid');
    try {
      await this.update({
        data: { assignedTo: { displayName, uuid: userId } },
        id: uuid,
      });
    } catch (error) {
      this.log.error(
        `Error assigning channelId ${uuid} to ${displayName}, ${userId}`,
        error,
      );
    }
  }

  @action
  async unassignChannel(uuid) {
    try {
      await this.update({ data: { assignedTo: null }, id: uuid });
      dispatch('ui.snackBar.open', 'Channel unassigned successfully');
    } catch (error) {
      this.log.error(`Error unassigning channelId ${uuid}`);
    }
  }

  @action
  async markChannelResolved(uuid) {
    try {
      await this.update({
        data: {
          assignedTo: null,
          resolved: true,
        },
        id: uuid,
      });
      this.clearSelected();
      dispatch('ui.snackBar.open', 'Channel Marked as Resolved');
    } catch (error) {
      this.log.error('Error marking channel resolved: ', error, {
        extra: { uuid },
      });
    }
  }

  @action
  async markChannelUnresolved(uuid) {
    try {
      await this.update({
        data: {
          assignedTo: null,
          resolved: false,
        },
        id: uuid,
      });
      this.clearSelected();
      dispatch('ui.snackBar.open', 'Channel Marked as Unresolved');
    } catch (error) {
      this.log.error('Error marking channel unresolved: ', error, {
        extra: { uuid },
      });
    }
  }

  @action
  searchMessageTag = async (e, { searchQuery }) => {
    const company = dispatch('auth.getCompany');
    this.set('messageTagSearchKeyword', searchQuery);
    const query = {
      companies: company.uuid,
    };
    const updatedQuery = dispatch(
      'messageTags.getQueryForSearch',
      this.messageTagSearchKeyword,
      query,
    );
    const { data } = await dispatch('messageTags.runQuery', updatedQuery);
    this.set('messageTagList', data);
  };

  @action
  loadMessageOptions = async () => {
    try {
      const response = await dispatch('configs.findOne', {
        query: {
          name: 'predefinedSSMManagedMessagingTags',
        },
      });
      this.set('messageOptionsIds', response?.configData ?? []);
    } catch (error) {
      this.log.warn('Error loading message options configData', error);
    }
  };

  @action
  setActiveDropDownFilter(value: string) {
    this.set('activeDropdownFilter', value);
  }

  @action
  setCustomFilterSearchKeyword(value: string) {
    this.set('customFilterSearchKeyword', value);
  }
  /* DATA */

  debouncedLoadUnresolvedChatCount = _.debounce(
    this.loadUnresolvedChatCounts,
    500,
  );

  @action
  async loadUnresolvedChatCounts() {
    const company = dispatch('auth.getCompany');
    this.set('isLoadingUnresolvedChatCounts', true);
    try {
      this.log.debug('Loading UnresolvedChatCounts');
      const res = await this.runQuery({
        $client: {
          getUnresolvedChatCounts: true,
        },
        companies: company.uuid,
      });
      this.set('unresolvedChatCounts', res);
    } catch (err) {
      this.log.error('Failed to load unresolved chat count', err);
    }
    this.set('isLoadingUnresolvedChatCounts', false);
  }

  @action
  async loadUnreadMessageCount() {
    const company = dispatch('auth.getCompany');
    this.set('isLoadingUnreadMessageCount', true);
    try {
      const res = await this.runQuery({
        $client: {
          getUnreadMsgCount: true,
        },
        companies: company.uuid,
      });
      this.setUnreadCountChannelMap(res);
    } catch (err) {
      this.log.error('Failed to load unread message count', err);
    }
    this.set('isLoadingUnreadMessageCount', false);
  }

  @action
  async loadRecentMessages({ user, incomingOnly }) {
    runInAction(() => {
      this.isLoadingRecents = true;
    });

    try {
      this.log.debug(
        `Loading Recent Messages for ${this.list.length} user channels`,
      );
      const rawMessages = await Promise.map(this.list, async (channel) => {
        try {
          const channelUser = _.find(channel.users, { uuid: user.uuid });

          if (!channelUser) {
            return [];
          }

          const loadedMessages = await dispatch(
            'twilioConversations.loadMessages',
            channel,
            {
              setSelected: false,
            },
          );

          const messages = _(loadedMessages)
            .reject(
              (m) =>
                _.isEmpty(m.body) || (incomingOnly && m.author === user.uuid),
            )
            .map((m) => _.extend(m, { channel: channel.uuid }))
            .value();

          return messages;
        } catch (error) {
          this.log.error('Error loading recent messages for', channel, error);
          return [];
        }
      });

      const recentMessages = _(rawMessages)
        .map((ml) => (_.isFunction(ml.toJS) ? ml.toJS() : ml || []))
        .flatten()
        .sortBy((m) => -(m.timestamp || m.createdAt))
        .value();

      runInAction(() => {
        this.recentMessages = recentMessages;
      });
    } catch (err) {
      this.log.error('Failed to load recent messages', err);
    }

    runInAction(() => {
      this.isLoadingRecents = false;
    });
  }

  @action
  setUnreadCountChannelMap(val) {
    this._unreadCountChannelMap = val;
  }

  /** #region User Actions */
  async create(data = {}) {
    try {
      const newChatChannel = await service('channels').create(data);
      return newChatChannel;
    } catch (error) {
      this.log.error('Error creating chat channel: ', error);
      throw error;
    }
  }

  async createWorkerChannel({
    workers,
    company = dispatch('auth.getCompany').uuid,
    sender = dispatch('auth.getUser').uuid,
  }) {
    let category = 'one-on-one';
    if (workers.length > 1) {
      category = 'group';
    }

    const query = {
      'channelType.category': category,
      companies: _.get(company, 'uuid', company),
      'users.uuid': { $all: _.map(workers, 'uuid') },
    };

    let res;

    try {
      res = await dispatch('chatChannels.findExistingChannel', {
        query,
      });
    } catch (err) {
      this.log.error('Error finding existing channel', err);
      this.setCreatingChannel(false);
      dispatch('ui.loadingModal.close');
      throw err;
    }

    if (!_.isEmpty(res.data)) {
      const existingChannels = _.filter(res.data, (channel) => {
        const w = _.filter(channel.users, { userType: 'worker' });

        return w.length === workers.length;
      });

      if (existingChannels.length > 1) {
        this.log.warn('Multiple chat Channels found for query', {
          channels: res.data,
          query,
        });
      }

      const existingChannel = _.first(existingChannels);
      if (!_.isEmpty(existingChannel)) {
        return existingChannel;
      }
    }

    const newChannel = await this.create({
      channelType: {
        category,
      },
      company,
      sender,
      workers,
    });

    this.log.debug('Created new Worker Channel:', newChannel);
    return newChannel;
  }

  async createPoolChannel({
    pools,
    company = dispatch('auth.getCompany').uuid,
    sender = dispatch('auth.getUser').uuid,
  }) {
    const pool = _.first(pools);

    if (pools.length > 1) {
      this.log.error('Attempting to create channel with multiple pools', {
        extra: { company, pools },
      });
    }

    this.log.debug('Creating pool channel:', pools);

    if (pool.chatId) {
      return { uuid: pool.chatId };
    }

    const poolId = _.get(pool, 'uuid', pool);
    if (_.isEmpty(poolId)) {
      throw new Error('Please define a pool');
    }
    const companyId = _.get(company, 'uuid', company);
    if (_.isEmpty(company)) {
      throw new Error('Please define a company');
    }

    try {
      const newPoolChannel = await this.create({
        channelType: {
          category: 'group',
        },
        company: companyId,
        isBroadcast: true,
        pool: poolId,
        sender: _.get(sender, 'uuid', sender),
      });
      await dispatch('pools.update', {
        data: { chatId: newPoolChannel.uuid, uuid: poolId },
      });
      this.log.debug('Created new Pool Channel:', newPoolChannel);
      return newPoolChannel;
    } catch (err) {
      this.log.error('Error Creating Pool Chat: ', err);
      throw err;
    }
  }

  async createShiftGroupChannel({
    shift = {},
    workers = [],
    status,
    sender,
    company,
  }) {
    const uuid = _.get(shift, 'uuid', shift);
    if (_.isEmpty(uuid)) {
      throw new Error('Please define valid shift or shift UUID');
    }
    if (_.isEmpty(status)) {
      throw new Error('Please define valid shift status');
    }
    if (!_.get(company, 'settings.things.enableShiftGroupMessages', false)) {
      throw new Error('Shift Group Messages company setting not enabled');
    }
    if (sender.isAdmin && !company?.isShiftsmartManaged) {
      throw new Error(
        'Admin users cannot send messages for non Shiftsmart managed companies',
      );
    }

    let shiftGroupChannel = {};
    let existingChannels = null;

    const query = {
      'channelType.category': 'group',
      'channelType.props.status': status,
      'channelType.refType': 'shift',
      'channelType.refUUID': uuid,
    };
    try {
      const response = await this.findExistingChannel({
        query,
      });
      existingChannels = response.data;
    } catch (err) {
      this.log.error('Error finding existing shift group channel', err);
    }
    if (_.isEmpty(existingChannels)) {
      const data = {
        channelName: `${shift.title}`,
        channelType: {
          category: 'group',
          props: {
            status,
          },
          refType: 'shift',
          refUUID: uuid,
        },
        company: _.get(company, 'uuid', company),
        isBroadcast: true,
        sender: _.get(sender, 'uuid', sender),
        workers: workers.map((w) => _.extend(w, { userType: 'worker' })),
      };
      try {
        shiftGroupChannel = await this.create(data);
      } catch (err) {
        this.log.error('Error creating new shift group channel', err);
      }
    } else {
      // update existing channel to include any additional users
      const foundChannel = _.first(existingChannels);
      shiftGroupChannel = await dispatch('chatChannels.update', {
        data: {},
        id: foundChannel.uuid,
        query: { $client: { updateShiftChatMembership: true } },
      });
    }

    return shiftGroupChannel;
  }

  async setupManagerChannel({ user, company }) {
    if (_.isString(company)) {
      const err = new Error(
        'InvalidParameterType -- `company` parameter must be an object',
      );
      this.log.error('[setupManagerChannel] Failed', err, {
        extra: { company },
      });
    }

    const companyId = company.uuid;

    // TODO: EP-881 `company.owner` is source of truth, but should be validated against csr records
    const companyAgents = await dispatch('companies.loadAgents', {
      company,
      query: { uuid: { $ne: company.owner } },
    });

    if (_.isEmpty(companyAgents)) {
      this.log.debug(
        '[setupManagerChannel] Company has no non-owner agents. returning',
        { extra: { company: companyId, user: user.uuid } },
      );
      return;
    }

    // check to see if internal chat is created yet, if not, create one
    const response = await dispatch('chatChannels.runQuery', {
      'channelType.category': 'internal',
      companies: companyId,
      deleted: { $ne: true },
    });
    const internalResult = response.data.filter(
      (c) => _.get(c, 'channelType.category', '') === 'internal',
    );

    if (_.isEmpty(internalResult)) {
      // TODO: Refactor create
      await this.create({
        channelName: 'Managers',
        channelType: { category: 'internal' },
        company,
        sender: user,
      });
    } else {
      // check if members are same
      if (internalResult.length > 1) {
        this.log.warn('Multiple Manager Internal chats!!');
      }

      // TODO: EP-1163: CSR Status should be handled by the `companies.loadAgents` method
      const managers = _(companyAgents)
        .map('uuid')
        .concat(company.owner)
        .compact()
        .value();

      const internalChannel = _.first(internalResult);

      const hasSameMembers = _(internalChannel.users)
        .map('uuid')
        .xorWith(managers, _.isEqual)
        .isEmpty();

      if (!hasSameMembers) {
        this.log.debug(
          'Wrong number of members!!',
          managers,
          internalChannel.users,
        );
        await service('channels').patch(
          internalChannel.uuid,
          { users: managers },
          {
            query: { $client: { action: 'addNewMembers' } },
          },
        );
      }
    }
  }

  async share({ channel, sender, pts }) {
    const forceSMS = dispatch('ui.chatInput.get', 'forceSMS');
    const attributes = {
      forceSMS,
      sender: {
        displayName: sender.displayName,
        profileImage: sender.profileImageURL,
        userType: 'employer',
        uuid: sender.uuid,
      },
      timestamp: Date.now(),
      type: 'shift',
      uuid: pts.uuid,
    };

    const body = `New Shift Posted \n${pts.title}\n${getFormattedStartEndDate({
      end: pts.end,
      start: pts.start,
    })}`;

    dispatch('ui.chatInput.toggleForceSMS', false);
    return dispatch('twilioConversations.sendMessage', {
      attributes,
      message: body,
      sid: channel.sid,
    });
  }

  removeUser({ channel, user }) {
    if (_.isEmpty(user.uuid)) {
      return Promise.reject('User UUID is not Defined');
    }
    const data = {
      $pull: {
        users: {
          uuid: user.uuid,
        },
      },
      uuid: channel.uuid,
    };

    // If channel is linked to pool, unlink
    if (
      _.get(channel, 'channelType.refType') === 'pool' &&
      _.get(channel, 'channelType.refUUID')
    ) {
      _.extend(data, {
        $unset: {
          'channelType.refType': '',
          'channelType.refUUID': '',
        },
      });
      // Update chatId in pool object
      dispatch('pools.update', {
        data: { chatId: null, uuid: channel.channelType.refUUID },
      });
    }
    return this.update({ data });
  }

  async tempRemoveUser({ channel }) {
    const currentUser = dispatch('auth.getUser');
    const data = { userId: currentUser.uuid, uuid: channel.uuid };
    return this.update({
      data,
      params: { query: { $client: { tempRemoveUser: true } } },
    });
  }

  addUsers({ channel, users }) {
    const data = {
      users,
      uuid: channel.uuid,
    };
    // on join conversation add only sender as twilio participant
    const authUser = dispatch('auth.getUser');
    const arrUser = users?.[0];
    const readdTempRemovedEmployer =
      users?.length === 1 && _.get(arrUser, 'uuid', arrUser) === authUser.uuid;
    const params = {
      query: {
        $client: {
          action: 'addNewMembers',
          readdTempRemovedEmployer,
        },
      },
    };
    return this.update({ data, params });
  }

  /** #region Message Selection */
  @action
  setSelectedMessage({ message } = {}) {
    if (_.isEmpty(message)) {
      this.selectedMessage = {};
    } else {
      set(this.selectedMessage, message);
    }
    return Promise.resolve(this.selectedMessage);
  }

  @computed
  get readStats() {
    return this.getReadStats(this.selectedMessage);
  }

  getReadStats(message = this.selectedMessage) {
    if (_.isEmpty(message)) {
      return {};
    }

    const isCustom = message.sid !== this.selectedMessage.sid;

    return {
      author: isCustom ? this.getAuthor(message) : this.author,
      messageUsers: isCustom
        ? this.getMessageUsers(message)
        : this.messageUsers,
      notSeenBy: isCustom ? this.getNotSeenBy(message) : this.notSeenBy,
      seenBy: isCustom ? this.getSeenBy(message) : this.seenBy,
    };
  }

  @computed
  get seenBy() {
    return this.getSeenBy();
  }

  getSeenBy(message = this.selectedMessage) {
    const index = _.get(message, 'index', false);
    if (index === false) {
      return [];
    }
    const isCustom = message.sid !== this.selectedMessage.sid;
    return _.filter(
      isCustom ? this.getMessageUsers(message) : this.messageUsers,
      ({ lastConsumedIndex }) => lastConsumedIndex >= index,
    );
  }

  @computed
  get notSeenBy() {
    return this.getNotSeenBy();
  }

  getNotSeenBy(message = this.selectedMessage) {
    const index = _.get(message, 'index', false);
    if (index === false) {
      return [];
    }
    const isCustom = message.sid !== this.selectedMessage.sid;
    return _.filter(
      isCustom ? this.getMessageUsers(message) : this.messageUsers,
      ({ lastConsumedIndex, userType }) =>
        lastConsumedIndex < index && userType === 'worker',
    );
  }

  debouncedLoadCustomFilterItems = _.debounce(this.loadCustomFilterItems, 500);

  @action
  async loadCustomFilterItems() {
    const company = dispatch('auth.getCompany');
    const customFilters = await dispatch('savedChatFilters.find', {
      company: company.uuid,
    });

    const customMenuItems = await Promise.map(
      customFilters.filter((f) => f.displayToggle),
      async (item) => {
        const query = {
          $and: [{ 'lastMessage.messageCreated': { $exists: true } }],
          $client: {},
          $limit: 0,
          $skip: 0,
        };

        const filter = JSON.parse(item.filterQueryString);

        if (filter.status === 'unresolved') {
          query.$and.push({ resolved: false });
        }
        if (filter.status === 'resolved') {
          query.$and.push({ resolved: true });
        }

        if (filter.type === 'all') {
          query.$and.push({ 'channelType.category': 'one-on-one' });
        }
        if (filter.type === 'partners') {
          query.$and.push({
            'channelType.category': { $nin: ['group'] },
          });
        }
        if (filter.type === 'group') {
          query.$and.push({ 'channelType.category': 'group' });
        }
        if (filter.type === 'shifts') {
          query.$and.push({
            'channelType.category': 'group',
            'channelType.refType': 'shift',
          });
        }

        if (filter.tag) {
          query.$client.getChannelsByCertId = filter.tag;
        }

        if (filter.messageTag) {
          query.$and.push({ 'tags.uuid': filter.messageTag });
        }

        if (_.isEmpty(query.$and)) {
          query.$and = undefined;
        }
        if (_.isEmpty(query.$client)) {
          query.$client = undefined;
        }

        const res = await this.runQuery(query);
        const { total } = res;

        return {
          count: total,
          displayToggle: true,
          isCustom: true,
          key: item.uuid,
          title: item.name,
        };
      },
    );
    this.set('customMenuItems', customMenuItems);

    const customDropdownItems = customFilters
      .filter((i) => !i.displayToggle)
      .map((item) => ({
        count: null,
        displayToggle: false,
        isCustom: true,
        key: item.uuid,
        title: item.name,
      }));
    this.set('customDropdownItems', customDropdownItems);
  }

  @computed
  get menuItems() {
    const primaryMenuItems = {
      allList: [
        {
          count: _.get(this.unresolvedChatCounts, 'allCount'),
          displayToggle: true,
          key: 'unresolved',
          title: 'All',
        },
      ],
      myAndNewList: [
        {
          count: _.get(this.unresolvedChatCounts, 'myMessagesCount'),
          displayToggle: true,
          key: 'assignedToSelf',
          title: 'My Messages',
        },
        {
          count: _.get(this.unresolvedChatCounts, 'newCount'),
          displayToggle: true,
          key: 'unassigned',
          title: 'New',
        },
      ],
    };

    return _.concat(
      primaryMenuItems.myAndNewList,
      this.customMenuItems,
      primaryMenuItems.allList,
    );
  }

  @computed
  get dropdownItems() {
    return this.customDropdownItems;
  }

  @action
  async gotoNextInbox() {
    const currentIndex = _.findIndex(this.menuItems, {
      key: this.selectedMenuItem,
    });
    let nextIndex = currentIndex + 1;
    if (currentIndex === this.menuItems.length - 1) {
      if (_.isEmpty(this.dropdownItems)) {
        nextIndex = 0;
      } else {
        nextIndex = -1;
      }
    }
    const selectedMenuItem =
      nextIndex < 0 ? _.first(this.dropdownItems) : this.menuItems[nextIndex];
    await this.setSelectedMenuItem(selectedMenuItem?.key);
    if (nextIndex === -1) {
      this.set('activeDropdownFilter', selectedMenuItem?.key);
      this.set('customFilterSearchKeyword', '');
    } else {
      this.set('activeDropdownFilter', '');
      this.set('customFilterSearchKeyword', '');
    }
    if (selectedMenuItem.isCustom) {
      await dispatch('ui.chatMessaging.setCustomFilter', {
        uuid: selectedMenuItem.key,
      });
    } else {
      dispatch('ui.chatMessaging.setActiveStatusFilter', 'unresolved');
      dispatch('ui.chatMessaging.resetFilters');
    }
    const firstChannel = _.first(this.sortedList);
    if (!_.isEmpty(firstChannel)) {
      dispatch('routing.push', `/chats/${firstChannel.uuid}?autoFocus=false`);
    }
  }

  @computed
  get author() {
    return this.getAuthor();
  }

  getAuthor(message = this.selectedMessage) {
    const author = _.get(message, 'author');
    if (!author) return null;

    return _.find(this.channelUsers, { uuid: author });
  }

  @computed
  get messageUsers() {
    return this.getMessageUsers();
  }

  getMessageUsers(message = this.selectedMessage) {
    const author = _.get(message, 'author');
    if (!author) return [];
    return _.filter(
      this.channelUsers,
      ({ uuid, userType }) => uuid !== author && userType !== 'employer',
    );
  }

  @computed
  get channelUsers() {
    return _.get(this.selected, 'users', []);
  }

  @computed
  get unreadMessageCount() {
    return _.reduce(this._unreadCountChannelMap, (acc, c) => acc + c.unread, 0);
  }

  @computed
  get channelType() {
    return _.get(this.selected, 'channelType.category');
  }

  @computed
  get sortedList() {
    const lastMessageSort = (channel) => {
      const lm = _.get(channel, 'lastMessage', {});
      const oldLm = _.get(channel, 'oldLastMessage');
      if (oldLm && this.freezeChannelList)
        return _.get(oldLm, 'messageCreated');
      return _.get(lm, 'messageCreated') || _.get(channel, 'createdAt');
    };

    return _.orderBy(
      _.uniqBy(this.list, 'uuid'),
      [lastMessageSort],
      ['desc'],
    ).filter((channel) => {
      if (this.viewOptions.showAllChannels) {
        return true;
      }

      const hasLastMessage = _.get(
        channel,
        'lastMessage.messageCreated',
        false,
      );
      const isManagerChat = channel?.channelType?.category === 'internal';

      return (
        hasLastMessage ||
        _.get(this.selected, 'uuid') === channel.uuid ||
        isManagerChat
      );
    });
  }

  @computed
  get newMessageCount() {
    const user = dispatch('auth.getUser');
    if (!this.freezeChannelList) {
      return 0;
    }
    return this.sortedList.filter(
      (channel) =>
        _.has(channel, 'oldLastMessage') &&
        _.get(channel, 'lastMessage.sender') !== user.uuid,
    ).length;
  }

  /* Overwrites baseStore onUpdated method to
   * update unreadCountChannelMap
   */
  @action
  onUpdatedLocal = async (data) => {
    if (_.isEmpty(data)) {
      this.log.debug(`Empty Item in onUpdated for ${this.serviceName}`);
      return;
    }

    // If user not in channel, do nothing
    const user = dispatch('auth.getUser');
    if (!_.some(data.users, (u) => u.uuid === user.uuid)) {
      return;
    }

    const company = dispatch('auth.getCompany');
    // If chat channel not for selected company, do nothing
    if (!_.includes(data.companies, company.uuid)) {
      return;
    }

    const { updatedFields = [] } = data;

    if (
      _.some(updatedFields, (field) =>
        _.includes(['resolved', 'assignedTo'], field),
      )
    ) {
      // We only want to recalc these if resolve or assignedTo changed on a channel
      this.debouncedLoadUnresolvedChatCount();
    }
    if (_.includes(updatedFields, 'tags')) {
      // We only want to recalc these if the tags changed on a channel
      this.debouncedLoadCustomFilterItems();
    }

    // Calc new unread count for this channel and update in unreadCountChannelMap
    // const newChannelUser = _.find(data.users, { uuid: user.uuid });
    // const newLastMessageIndex = Math.max(
    //   _.get(data, 'lastMessage.index', 0),
    //   0,
    // );
    // const newLastConsumedIndex = Math.max(
    //   _.get(newChannelUser, 'lastConsumedIndex', -1),
    //   -1,
    // );
    // const newUnreadCount = newLastMessageIndex - newLastConsumedIndex;

    // const channelIndex = _.findIndex(this._unreadCountChannelMap, {
    //   uuid: data.uuid,
    // });
    // const existingUnreadCount = this._unreadCountChannelMap[channelIndex];
    // this._unreadCountChannelMap[channelIndex] = _.extend(
    //   {},
    //   existingUnreadCount,
    //   { unread: newUnreadCount },
    // );

    const isInList = _.find(this.list, { uuid: data.uuid });
    const updatedMatchesSearchValue = this.channelMatchesSearchValue(data);
    if (!isInList) {
      let addToList = updatedMatchesSearchValue;
      if (this.selectedMenuItem === 'assignedToSelf') {
        addToList = _.get(data, 'assignedTo.uuid') === user.uuid && addToList;
      } else if (this.selectedMenuItem === 'unassigned') {
        addToList = _.isEmpty(_.get(data, 'assignedTo.uuid')) && addToList;
      }
      if (_.includes(this.filter, 'partners')) {
        addToList =
          _.get(data, 'channelType.category') === 'one-on-one' && addToList;
      }
      if (_.includes(this.filter, 'group')) {
        addToList =
          _.get(data, 'channelType.category') === 'group' && addToList;
      }
      if (_.includes(this.filter, 'shifts')) {
        addToList = _.get(data, 'channelType.refType') === 'shift' && addToList;
      }
      if (_.includes(this.filter, 'unresolved')) {
        addToList = !data.resolved && addToList;
      }
      if (_.includes(this.filter, 'resolved')) {
        addToList = data.resolved && addToList;
      }
      if (_.includes(this.filter, 'messageTag')) {
        const messageTag = dispatch(
          'ui.chatMessaging.retrieve',
          'activeMessageTagListFilter',
        );
        addToList = _.find(data.tags, { uuid: messageTag }) && addToList;
      }
      if (
        (_.includes(this.filter, 'all') ||
          _.includes(this.filter, undefined) ||
          _.isEmpty(this.filter)) &&
        !_.includes(this.filter, 'unresolved')
      ) {
        addToList = true;
      }

      if (addToList) {
        this.list.unshift(data);
      }
    }

    const assignedAndUnassignedMenu =
      this.selectedMenuItem === 'unassigned' && _.has(data, 'assignedTo.uuid');
    const unassignedAndAssignedMenu =
      this.selectedMenuItem === 'assignedToSelf' &&
      _.get(data, 'assignedTo', null) === null;

    if (
      isInList &&
      ((data.resolved && _.includes(this.filter, 'unresolved')) ||
        assignedAndUnassignedMenu ||
        unassignedAndAssignedMenu ||
        !updatedMatchesSearchValue)
    ) {
      _.remove(this.list, { uuid: data.uuid });
    }

    const oldLastMessageCreated = _.get(isInList, 'lastMessage.messageCreated');
    const newLastMessageCreated = _.get(data, 'lastMessage.messageCreated');
    const hasOldMessage = _.has(isInList, 'oldLastMessage');

    if (
      isInList &&
      moment(newLastMessageCreated).isAfter(moment(oldLastMessageCreated)) &&
      !hasOldMessage
    ) {
      // save the old lastMessage.messageCreated
      data.oldLastMessage = isInList.lastMessage;
    }

    this.onUpdated(data);
  };

  // checks if a channel matches current search value
  // returns true if no search value
  channelMatchesSearchValue(channel) {
    if (this.searchValue) {
      return this.searchFields.some((field) => {
        const searchRegex = new RegExp(`.*${this.searchValue}*.`, 'i');
        const testString = /users/i.test(field)
          ? _.get(channel, 'users', [])
              .map((usr) => usr.displayName)
              .join('')
          : _.get(channel, field, '');

        if (searchRegex.test(testString)) {
          return true;
        }
        return false;
      });
    }
    return true;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action.bound
  set(key, value) {
    this[key] = value;
  }
}
