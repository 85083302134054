import type { ICarouselCardTemplate } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from '#/shared/stores/_baseStore';

export default class CarouselCardTemplatesStore extends BaseStore<ICarouselCardTemplate> {
  constructor() {
    super({
      serviceName: 'carouselCardTemplates',
    });

    return this;
  }
}
