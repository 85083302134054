import React from 'react';
import { Router, RouterProps } from 'react-router';
import { Provider, Observer } from 'mobx-react';

import { AbilityContext } from '#/web/context/ability';
// import IdleDetector from '#/shared/components/utils/IdleDetector';
import { useFetchData } from '#/shared/hooks/useFetchData';
import { useStores } from '#/shared/hooks/useStores';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('App');

type AppProps = {
  routerProps: RouterProps;
};

const App = ({ routerProps }: AppProps) => {
  useFetchData(
    {
      fetchData: async () => {
        log.verbose('Root fetchData');
        return Promise.resolve();
      },
    },
    [],
  );
  const store = useStores();
  return (
    <Provider store={store}>
      <Observer>
        {() => (
          <AbilityContext.Provider value={store.abilities.access}>
            {/* <IdleDetector /> */}
            <Router {...routerProps} />
          </AbilityContext.Provider>
        )}
      </Observer>
    </Provider>
  );
};

App.displayName = 'App';
export default App;
