export const colors = {
  /* Shift Status Colors */
  active: '#1665d8',

  bgInfo: '#f3f9ff',

  bgLight: '#f1f1f1',

  bgLightBlue: '#9ab2cc',

  bgLightest: '#f6f9fd',

  bgLightestBlue: '#e5f2ff',

  /* Suggestions UI Colors */
  bgSuggestion: '#fffbf9',

  bgWhite: '#ffffff',

  black: 'rgb(0,0,0)',

  /** Blacks */
  black05: 'rgba(0,0,0,0.05)',

  black10: 'rgba(0,0,0,0.1)',

  black20: 'rgba(0,0,0,0.2)',

  black30: 'rgba(0,0,0,0.3)',

  black40: 'rgba(0,0,0,0.4)',

  black50: 'rgba(0,0,0,0.5)',

  black60: 'rgba(0,0,0,0.6)',

  black70: 'rgba(0,0,0,0.7)',

  black80: 'rgba(0,0,0,0.8)',

  black90: 'rgba(0,0,0,0.9)',

  /** Global Color Vars per http://zpl.io/29OAjDd */
  blue: '#1a71cf',

  'blue-25': '#1a71cf44',

  'blue-50': '#1a71cf88',

  'blue-75': '#1a71cfcc',

  /** Alt Colors */
  'blue-alt': '#2670d3',

  'blue-alt-2': '#4471a0',

  'blue-alt-3': '#2a6cc4',

  'blue-alt-4': '#2077d6',

  'blue-alt-5': '#8096ae',

  'blue-alt-6': '#2655c1',

  'blue-navy': '#0c3272',

  borderDark: '#d3d8e0',

  borderDarkAlt: '#d8dce6',

  /** Borders */
  borderLight: '#f1f1f1',

  borderMid: '#eaedf3',

  borderSuggestion: '#e69c3c',

  'bright-blue': '#2290F2',

  completed: '#7188b4',

  draft: '#bcbcbc',

  filled: '#19a974',

  green: '#19a974',

  'green-25': '#19a97444',

  'green-50': '#19a97488',

  'green-75': '#19a974cc',

  grey1: '#f6f9fd',

  grey10: '#3e3f42',

  grey11: '#e6e5ec',

  grey2: '#f5f5f5',

  grey3: '#f1f1f1',

  grey3_5: '#fbfbfd',

  grey4: '#d8d8d8',

  grey5: '#bababa',

  grey6: '#9ea0a5',

  grey7: '#8a8b8c',

  grey8: '#838383',

  orange: '#f6ab2f',

  'orange-25': '#f6ab2f44',

  'orange-50': '#f6ab2f88',

  'orange-75': '#f6ab2fcc',

  /** Backgrounds */
  pageContentBG: '#fafafa',

  red: '#e6492d',

  'red-25': '#e6492d44',

  'red-50': '#e6492d88',

  'red-75': '#e6492dcc',

  'red-alt': '#cd2b3f',

  'ssm-blue': '#2a6cc4',

  steel: '#7188b4',

  'steel-25': '#7188b444',

  'steel-50': '#7188b488',

  'steel-75': '#7188b4cc',

  /* Onboarding added colors */
  success: '#32A623',

  'text-clickable': '#357edd',

  'text-meta': '#9ea0a5',

  'text-meta-alt': '#1665d8',

  'text-meta-alt2': '#212122',

  /** Text Colors */
  'text-primary': '#3e3f42',

  'text-secondary': '#6b6c6f',

  'text-tertiary': '#bcbcbc',

  violet: '#6758f3',

  'violet-25': '#6758f344',

  'violet-50': '#6758f388',

  'violet-75': '#6758f3cc',

  white: '#fff',

  white25: '#FFFFFF44',

  white50: '#FFFFFF88',

  white75: '#FFFFFFcc',

  yellow: '#facf55',

  'yellow-25': '#facf5544',

  'yellow-50': '#facf5588',

  'yellow-75': '#facf55cc',
};

export default colors;
