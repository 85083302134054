import { computed, observable, action, set, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import { parsePhoneNumber } from 'libphonenumber-js';
import _ from 'lodash';
import Promise from 'bluebird';

import BaseUploader from './BaseUploader';

export default class CertsUploader extends BaseUploader {
  constructor() {
    super({ title: 'ui.certsUploader' });

    set(this.saveResults, {
      dupe: [],
      incomplete: [],
      invalid: [],
      new: [],
    });

    return this;
  }

  @observable
  list = [];

  @observable
  showList = false;

  csvData = [
    ['uuid', 'cert', 'action', 'phoneNumber'],
    ['b43a6d40-bfb4-48a1-a235-ff15510e7af6', 'sample cert', '', ''],
    ['', 'sample cert 2', 'remove', '12065661947'],
  ];

  knownHeaders = ['uuid', 'cert', 'action', 'phoneNumber'];

  @computed
  get savePercentage() {
    return (
      (this.saveResults.new.length +
        this.saveResults.invalid.length +
        this.saveResults.dupe.length) /
      _.size(this.list)
    );
  }

  @action
  clear() {
    super.clear();

    this.saveResults = {
      dupe: [],
      incomplete: [],
      invalid: [],
      new: [],
    };

    this.list.clear();
    this.showList = false;
  }

  @action
  toggleListVisibility() {
    this.showList = !this.showList;
  }

  @action
  async parse({ rows }) {
    const caseHeaders = _.map(rows[0], _.toLower);
    const indices = _.reduce(
      this.knownHeaders,
      (acc, header) => {
        acc[header] = _.indexOf(caseHeaders, _.toLower(header));
        return acc;
      },
      {},
    );

    this.log.debug('Loaded Table Indices', { indices });

    const validRows = _(rows)
      .map((row, i) => {
        if (!i) return null;

        this.log.debug(row);

        return _.chain(this.knownHeaders)
          .reduce((acc, colKey) => {
            const colIndex = indices[colKey];

            if (colIndex >= 0) {
              acc[colKey] = row[colIndex];
            }
            return acc;
          }, {})
          .value();
      })
      .reject((rowObj) => _.isEmpty(_.omitBy(rowObj, _.isEmpty)))
      .compact()
      .value();

    this.setStatus('Evaluating');

    runInAction(() => {
      this.list.replace(validRows);
    });

    this.setStatus('Parse all Rows');
  }

  @action
  async getOrCreateCertObjects(certs) {
    const certObjects = {};
    await Promise.map(
      certs,
      async (certTitle) => {
        let certObject = await dispatch('certs.findOne', {
          query: {
            certType: 'cert',
            title: certTitle,
          },
        });
        // create a new tag if it doesn't exist already
        if (!certObject) {
          certObject = await dispatch('certs.create', {
            data: {
              certType: 'cert',
              title: certTitle,
            },
          });
        }
        // add tag object in container
        certObjects[certTitle] = certObject;
      },
      { concurrency: 10 },
    );

    return certObjects;
  }

  @action
  async processWorkerRow(row, certObject) {
    let identifier;
    if (row.uuid) {
      identifier = _.trim(row.uuid);
    } else {
      identifier = _.trim(row.phoneNumber);
      if (identifier.indexOf('+') === -1) {
        identifier = `+${identifier}`;
      }
      identifier = parsePhoneNumber(identifier).number;
    }
    const key = identifier.indexOf('+') === -1 ? 'uuid' : 'phoneNumber';
    const worker = await dispatch('workers.findOne', {
      query: { [key]: identifier },
    });

    if (!worker) {
      runInAction(() => {
        this.saveResults.invalid.push(identifier);
      });
      return _.mergeWith(
        {
          errorMsg: `No worker found with identifier ${identifier}`,
          isInvalid: true,
        },
        row,
      );
    }

    const existingCerts = _(worker.certs)
      .filter((c) => c.certType === 'cert')
      .map('uuid')
      .uniq()
      .value();
    if (_.includes(existingCerts, certObject.uuid)) {
      this.log.debug(`Worker already has ${certObject.title} cert`);
      if (_.lowerCase(row.action) === 'remove') {
        await dispatch('workers.update', {
          data: {
            $pull: {
              certs: {
                certType: 'cert',
                uuid: certObject.uuid,
              },
            },
          },
          id: worker.uuid,
        });
      }
      return row;
    }
    if (
      !_.includes(existingCerts, certObject.uuid) &&
      row.action === 'remove'
    ) {
      // User doesn't have the cert, no need to patch
      return row;
    }

    return dispatch('workers.update', {
      data: { $push: { certs: certObject } },
      id: worker.uuid,
    });
  }

  @action
  async save() {
    this.setStatus('saving');
    this.setSaving(true);

    dispatch('audit.create', {
      data: {
        action: 'Upload User Certs',
        extra: {
          file: _.pick(this.file, ['name', 'type', 'size']),
        },
      },
    });

    try {
      // prepare de-duplicated array of all non-empty certs to be be uploaded for all the users
      const certs = _(this.list).map('cert').map(_.trim).uniq().value();

      const certObjects = await this.getOrCreateCertObjects(certs);

      const response = await Promise.map(
        this.list,
        async (row) => {
          const certObject = certObjects[_.trim(row.cert)];
          try {
            const updatedWorker = await this.processWorkerRow(row, certObject);
            runInAction(() => {
              this.saveResults.new.push(updatedWorker);
            });
            return updatedWorker;
          } catch (error) {
            this.log.debug(error);
            runInAction(() => {
              this.saveResults.invalid.push({
                error: error.toString(),
                identifier: row.uuid || row.phoneNumber,
              });
            });
            return row;
          }
        },
        { concurrency: 20 },
      );

      runInAction(() => {
        this.list.replace(response);
        this.setStatus('saved');
      });
    } catch (err) {
      this.log.error('Failed to save user certs', err);
      dispatch('ui.snackBar.error', `Sorry, something went wrong, ${err}`);
      this.setStatus('error');
      this.clear();
    }

    this.setSaving(false);
  }
}
