import { observable, action, computed, set, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import moment from 'moment';
import Promise from 'bluebird';

import { getChildLogger } from '#/shared/utils/client.logger';
import BaseUploader, {
  KnownFieldConfig,
} from '#/shared/stores/ui/BaseUploader';

export default class ShopApprovalUploader extends BaseUploader {
  constructor() {
    super({ title: 'ui.ShopApprovalUploader' });

    set(this.saveResults, {
      invalid: [],
      new: [],
      updated: [],
    });

    return this;
  }

  storeName = 'assignments';

  @observable
  saveConfirmMessage = 'Would you like to approve these shops?';

  @observable
  saveButtonText = 'Save';

  @observable
  parsedHeaders = [];

  @observable
  resultHeaders = [];

  @observable
  newShops = [];

  @observable
  byCampaign = [];

  @observable
  fileParseError = null;

  @observable
  saveError = null;

  @observable
  includePayments = false;

  log = getChildLogger('ui.ShopApprovalUploader');

  @computed
  get knownFields(): KnownFieldConfig[] {
    const fieldConfig: KnownFieldConfig[] = [
      { exampleVal: '', key: 'uuid' },
      { exampleVal: '', key: 'campaign' },
      { exampleVal: 'approve', key: 'action' },
    ];

    return _(fieldConfig)
      .compact()
      .map((field) => _.defaults(field, { isExampleField: true }))
      .value();
  }

  @computed get knownHeaders() {
    return _.map(this.knownFields, 'key');
  }

  @computed get sampleCSVData() {
    return [
      ['uuid', 'campaign', 'action'],
      ['sampleShopUUID', 'sampleCampaignUUID', 'reject'],
    ];
  }

  @action
  clear() {
    super.clear();
    this.saveResults = {
      invalid: [],
      new: [],
      updated: [],
    };
    this.list.clear();
    this.parsedHeaders.clear();
    this.resultHeaders.clear();
    this.includePayments = false;
    this.newShops.clear();
    this.byCampaign.clear();
  }

  @action
  async processFile({ options = this.parseOptions, ...rest }) {
    this.setStatus('loading');
    try {
      await super.processFile({ options, ...rest });
      this.setStatus('loaded');
    } catch (err) {
      this.log.error('Failed to process file', err);
      this.setStatus('error');
      throw err;
    }
  }

  @action
  async parse({ rows }) {
    let parsedHeaders = [];
    const casedHeaders = _.map(rows[0], _.toLower);
    const indices = _.reduce(
      this.knownHeaders,
      (acc, header) => {
        acc[header] = _.indexOf(casedHeaders, _.toLower(header));
        return acc;
      },
      {},
    );
    this.log.debug('Loaded Table Indices', { indices });
    const validRows = _(rows)
      .map((row, i) => {
        if (!i) return null;
        this.log.debug(row);
        return _.reduce(
          this.knownHeaders,
          (acc, colKey) => {
            const colIndex = indices[colKey];
            if (colIndex >= 0) {
              acc[colKey] = row[colIndex];
            }
            return acc;
          },
          {},
        );
      })
      .reject((rowObj) => _.isEmpty(_.omitBy(rowObj, _.isEmpty)))
      .map((data) => this.doMapping({ data, mapping: this.knownFields }))
      .map((row) => {
        parsedHeaders = _.union(parsedHeaders, _.keys(row));
        return row;
      })
      .compact()
      .value();
    await this.setShops(validRows);

    runInAction(() => {
      this.parsedHeaders = parsedHeaders;
    });

    this.setStatus('Evaluating');

    // eslint-disable-next-line no-console
    console.table(validRows);

    runInAction(() => {
      this.list.replace(validRows);
    });

    this.setStatus('Parsed all Rows');
  }

  @computed
  get savePercentage() {
    const { updated, invalid } = this.saveResults;
    return (updated.length + invalid.length) / this.newShops.length;
  }

  @action
  toggleIncludePayments() {
    this.set('includePayments', !this.includePayments);
  }

  @action
  async setShops(shops) {
    const groupByCampaign = _.groupBy(shops, 'campaign');
    const byCampaign = await Promise.map(
      _.keys(groupByCampaign),
      async (campaignId) => {
        let campaign;
        try {
          campaign = await dispatch('campaigns.get', campaignId);
          if (dispatch('auth.cannot', 'read', campaign)) {
            this.log.error('Cannot Read Campaign', {
              extra: { campaign: campaignId },
            });
            return null;
          }
        } catch (error) {
          this.log.error('Error Loading Campaign: ', error);
        }
        const allShops = groupByCampaign[campaignId];
        const approvedShops = _.filter(allShops, { action: 'approve' });
        const rejectedShops = _.filter(allShops, { action: 'reject' });
        return {
          approvedShops,
          campaignId,
          campaignTitle: campaign.title,
          rejectedShops,
        };
      },
    );
    this.set('byCampaign', _.compact(byCampaign));
    this.set('newShops', shops);
    return Promise.resolve(this.newShops);
  }

  @action
  async save() {
    this.setSaving(true);
    const userId = await dispatch('auth.getUser')?.uuid;
    dispatch('audit.create', {
      data: {
        action: 'Upload Survey Approvals/Rejections',
        extra: {
          file: _.pick(this.file, ['name', 'type', 'size']),
        },
      },
    });
    try {
      await Promise.map(
        this.newShops,
        async (shop) => {
          try {
            let data;
            let res;
            const actionName = shop?.action ?? 'approve';
            if (this.includePayments) {
              const payload = {
                approvalNote: `Bulk Approval By userId: ${userId} at ${moment().toISOString()}`,
                assignment: shop,
              };
              if (actionName === 'approve') {
                res = await dispatch(
                  'paymentTransactions.approveForPayment',
                  payload,
                );
              } else if (actionName === 'reject') {
                res = await dispatch(
                  'paymentTransactions.rejectForPayment',
                  payload,
                );
              }
            } else {
              data = {
                'qaInfo.approvalNote': `Bulk Updated By userId: ${userId} at ${moment().toISOString()}`,
                'qaInfo.status':
                  actionName === 'approve' ? 'approved' : 'rejected',
              };
              res = await dispatch('assignments.update', {
                data,
                id: shop.uuid,
                query: { checkQaStatus: true },
              });
            }
            const updatedShop = _.merge(shop, res);
            this.addUpdated(updatedShop);
          } catch (error) {
            this.addFailed(_.extend({}, shop, { error }));
          }
        },
        { concurrency: 1 },
      );
    } catch (error) {
      this.set('saveError', error);
      this.setSaving(false);
    }
  }

  @action
  setFileParseError(error) {
    this.set('fileParseError', error);
    return Promise.resolve(this.fileParseError);
  }

  @action
  addUpdated(item) {
    this.saveResults.updated.push(item);
  }

  @action
  addFailed(item) {
    this.saveResults.invalid.push(item);
  }
}
