import BaseTagStore from './_baseTagStore';

export default class PositionStore extends BaseTagStore {
  constructor() {
    const baseItem = {
      certType: 'position',
      companies: [],
      linked: [],
      source: '',
      title: null,
      users: [],
    };
    super({ baseItem, serviceName: 'positions' });

    return this;
  }
}
