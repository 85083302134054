import { observable, action, computed } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import Promise from 'bluebird';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class StoreUpload {
  @observable
  storeList = {};

  @observable
  file = null;

  @observable
  fileRef = {};

  @observable
  listName = '';

  @observable
  isLoading = false;

  @observable
  isSaving = false;

  @observable
  isOpen = false;

  @observable
  newStores = [];

  @observable
  storeHeaders = [];

  @observable
  saveResults = {
    dupe: [],
    invalid: [],
    new: [],
  };

  log = getChildLogger('ui.StoreUpload');

  @action
  saving(arg = !this.isSaving) {
    this.isSaving = !!arg;
  }

  @action
  clear() {
    this.storeList = {};
    this.isOpen = false;
    this.file = null;
    this.fileRef = {};
    this.listName = '';
    this.isLoading = false;
    this.isSaving = false;
    this.newStores.clear();
    this.storeHeaders.clear();
    this.saveResults = { dupe: [], invalid: [], new: [] };
  }

  setup({ storeList, open = this.isOpen }) {
    this.set('listName', _.get(storeList, 'title', ''));
    this.set('storeList', storeList);

    this.set('isOpen', !!open);
  }

  setFile(file) {
    return this.set('file', file);
  }

  setRef(ref) {
    return this.set('fileRef', ref);
  }

  setListName(name) {
    return this.set('listName', name);
  }

  @computed
  get savePercentage() {
    return (
      (this.saveResults.new.length +
        this.saveResults.invalid.length +
        this.saveResults.dupe.length) /
      this.newStores.length
    );
  }

  @action
  setStores(stores) {
    _.each(stores, (store) => this.newStores.push(store));

    const headers = _.keys(stores[0]);
    this.set('storeHeaders', headers);

    return Promise.resolve(this.newStores);
  }

  async saveStores({ company, mapping = [] }) {
    const log = this.log.getChildLogger('saveStores');
    dispatch('ui.storeUpload.saving', true);
    const companyUUID = _.get(company, 'uuid', company);

    const storeListData = {
      companies: [companyUUID],
      title: this.listName,
    };

    let storeList = this.storeList;
    if (!this?.storeList?.uuid) {
      log.debug('Creating a new store list');
      storeList = await dispatch('storeLists.create', { data: storeListData });
    }

    log.debug('New store list', storeList.uuid);

    dispatch('storeLists.setSelected', storeList);
    dispatch('stores.setStoreListFilter', storeList.uuid);
    dispatch('audit.create', {
      data: {
        action: 'Upload Stores',
        companyId: companyUUID,
        extra: {
          file: _.pick(this.file, ['name', 'type', 'size']),
        },
      },
    });

    return Promise.all(
      Promise.map(
        this.newStores,
        (store) => {
          const newData = this.doMapping({
            companyUUID,
            mapping,
            store,
            storeList,
          });

          if (_.get(newData, 'loc.coordinates')) {
            _.set(newData, 'loc.type', 'Point');
          }

          log.debug('Saving new Store: ', newData);

          return dispatch('stores.create', { data: newData })
            .then(
              action((res) => {
                if (res.isPatched) {
                  this.saveResults.dupe.push(res);
                } else {
                  this.saveResults.new.push(res);
                }
                return res;
              }),
            )
            .catch(
              action((err) => {
                this.saveResults.invalid.push(_.extend(store, err));
              }),
            );
        },
        { concurrency: 20 },
      ),
    ).then(() => this.saveResults);
  }

  doMapping({ store, mapping, storeList, companyUUID }) {
    const newData = _.extend(store, {
      companies: [companyUUID],
      storeLists: [{ title: storeList.title, uuid: storeList.uuid }],
    });

    // Re-Map source keys to destination keys & nested values
    _(mapping)
      .filter((h) => !!h.mapping)
      .each(
        action((translation) => {
          if (_.get(newData, translation.key)) {
            _.set(newData, translation.mapping, newData[translation.key]);
            delete newData[translation.key];
          }
        }),
      );

    // Transform Values where a transformation is indicated
    _(mapping)
      .filter((h) => _.isFunction(h.transform))
      .each(
        action((translation) => {
          const key = translation.mapping || translation.key;
          if (_.get(newData, key)) {
            _.set(newData, key, translation.transform(_.get(newData, key)));
          }
        }),
      );

    return newData;
  }

  get(name) {
    return this[name];
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(name, val) {
    if (_.isArrayLikeObject(this[name])) {
      _.each(val, (v) => this[name].push(v));
    } else {
      this[name] = val;
    }
    return Promise.resolve(this[name]);
  }
}
