import React from 'react';
import { observer } from 'mobx-react';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import { Button } from 'semantic-ui-react';
import cx from 'classnames';

import { isCompanyMember } from '#/shared/components/utils/CompanyStatus';
import styles from '#/shared/styles/CertList.css';

const AddEndorsement = observer(
  ({ worker, company, certType, size, cert = {}, isAdmin = false }) => {
    if (!isCompanyMember(worker, company) && !isAdmin) {
      return null;
    }

    const handleTouchTap = () => {
      const certTitle = cert.uuid ? cert.title : '';
      dispatch('ui.workerCertModal.setup', {
        cert,
        certTitle,
        certType,
        company,
        open: true,
        worker,
      });
    };

    let certTypeTitle = _.capitalize(certType);

    if (/qual/i.test(certType)) {
      certTypeTitle = 'Tag';
    }

    const action = `Add ${certTypeTitle}`;

    return (
      <Button
        className={cx(styles.basic)}
        compact={true}
        content={action}
        icon="plus square"
        onClick={handleTouchTap}
        positive={true}
        size={size}
      />
    );
  },
);

export default AddEndorsement;
