import type { IStore } from '@shiftsmartinc/shiftsmart-types';

import { action } from 'mobx';
import _ from 'lodash';

import { service } from '#/shared/app';
import { IStoreUpdateArgs } from '#/shared/stores/_baseStore';

import AddressStore from './address';

export default class StoreStore extends AddressStore<IStore> {
  constructor() {
    super({
      baseItem: StoreStore.BASE_ITEM,
      searchFields: [
        'retailer',
        'storeId',
        'region',
        'market',
        'formattedAddress',
      ],
      serviceName: 'stores',
    });

    return this;
  }

  static BASE_ITEM = {
    isShoppable: false,
    market: '',
    name: '',
    products: [],
    region: '',
    retailer: '',
    storeId: '',
    storeLists: [],
  };

  // TODO: Remove this method once all `stores` update methods are updated
  update(
    arg1: IStoreUpdateArgs = {},
    /** @deprecated */
    arg2?: unknown,
  ) {
    const arg = arg1;
    if (!_.isUndefined(arg2) || !_.has(arg1, 'data')) {
      this.log.warn(
        '`update` is using a deprecated method signature. Please update to the `baseStore` implementation',
      );
      arg.data = arg1;
      arg.id = arg2;
    }
    return super.update(arg);
  }

  resetSearch({ preserve = [], refresh = true }) {
    return super.resetSearch({
      preserve: _.uniq(['storeLists.uuid', ...preserve]),
      refresh,
    });
  }

  @action
  search(title = null, ...rest) {
    if (title === null) {
      return this.resetSearch({
        preserve: ['storeLists.uuid'],
        refresh: true,
      });
    }

    return super.search(title, ...rest);
  }

  @action
  addStoreList({ storeId, storeList }) {
    const newStoreList = {
      title: storeList.title,
      uuid: storeList.uuid,
    };
    return service(this.serviceName)
      .patch(storeId, { $push: { storeLists: newStoreList } })
      .catch((err) => this.logAndThrow(err));
  }

  @action
  removeStoreList({ storeId, storeListId }) {
    return service(this.serviceName)
      .patch(storeId, { $pull: { storeLists: { uuid: storeListId } } })
      .catch((err) => this.logAndThrow(err));
  }

  @action
  setStoreListFilter(storeListUUID) {
    this.log.debug('setStoreListFilter', storeListUUID);

    if (storeListUUID === this.storeListFilter) {
      this.log.warn('storeListFilter Unchanged');
      return Promise.resolve(this.list);
    }

    const query = {};

    if (_.isEmpty(storeListUUID)) {
      this.storeListFilter = null;
      if (this.query.query) {
        delete this.query.query['storeLists.uuid'];
      }
    } else {
      this.storeListFilter = storeListUUID;
      query['storeLists.uuid'] = this.storeListFilter;
    }

    return this.find({ query });
  }
}
