import type { ICompany } from '@shiftsmartinc/shiftsmart-types';

import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { mapUnifiedToSeparate } from '#/shared/forms/formUtils';
import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';

const log = getChildLogger('forms.companyAgent');

export class CompanyAgent extends Form {
  hooks = () => ({
    onError() {
      const error = parseErrors(this.errors());
      log.error('Form Errored: ', error);

      let message = error.message || 'Unable to add new Agent';

      if (_.isArray(error.errors)) {
        message =
          error.errors.length === 1
            ? error.errors[0]
            : `${error.message}: ${_.first(error.errors)} + ${
                error.errors.length - 1
              } more`;
      }
      dispatch('ui.snackBar.open', message);
    },

    async onSuccess(form) {
      const isEditingAgent = !!form.$('uuid').initial;
      const storeAction = isEditingAgent
        ? 'employerUsers.updateAgent'
        : 'employerUsers.createAgent';

      const values = form.values();
      if (_.isEmpty(values.phoneNumber)) {
        delete values.phoneNumber;
      }

      try {
        const agent = (await dispatch(storeAction, values, form)) ?? values;

        const wasAgentAdded = !!_.find(agent.companyStatus, {
          company: form.$('company').value,
        });

        dispatch(
          'ui.snackBar.open',
          wasAgentAdded
            ? `Agent ${isEditingAgent ? 'Updated' : 'Added'}`
            : 'No changes made to agent',
        );
        dispatch('ui.manageCompany.toggleAgentForm', false);

        form.clear();
      } catch (err) {
        form.invalidate(err.message);

        dispatch('ui.snackBar.error', err.message || 'Unable to add new Agent');
      }
    },
  });
}

function parseErrors(errors) {
  return _.pickBy(errors, (val) => {
    if (!val) {
      return false;
    }

    if (_.isObject(val)) {
      return !_.isEmpty(parseErrors(val));
    }

    return !!val;
  });
}

// NOTE: "Unified" field definitions are not well supported. If defining a form
// in this manner, you MUST use the mapUnifiedToSeparate utility and pass them into
// the constructor in that way.
export const fields = {
  // Company Status Record Fields
  company: { rules: 'string' },

  companyRoles: {
    default: [],
    label: 'Company Roles',
    rules: 'required|array',
  },

  email: {
    label: 'Email',
    placeholder: 'Insert Agent’s Email',
    rules: 'required|email|string|between:5,50',
  },

  existingUser: {
    label: 'Select Existing User',
    placeholder:
      'Type here to search for existing users (existing agents will be excluded from search',
  },

  firstName: {
    label: 'First Name',
    rules: 'required|string',
  },

  // Unique Popup Key
  focusedField: {
    default: null,
  },

  lastName: {
    label: 'Last Name',
    rules: 'required|string',
  },

  phoneNumber: {
    label: 'Phone Number',
    placeholder: 'Insert (mobile) Phone Number',
    rules: 'string|between:10,15',
  },

  requireMFA: {
    label: 'Require MFA',
    placeholder: true,
    rules: 'boolean',
  },

  roles: {
    label: 'User Roles',
    rules: 'required|array',
  },

  route: {
    default: '/',
    label: 'Home Route',
    rules: 'string',
  },

  sendAt: {
    label: 'Schedule Welcome Email to be Sent At:',
    rules: 'date',
  },

  sendWelcome: {
    label: 'Welcome Email',
  },

  status: {
    label: 'Company Status',
    rules: 'string',
  },

  uuid: {
    rules: 'string',
  },
};

const extras = {
  sendWelcome: {
    options: [
      { key: false, text: 'None', value: false },
      { key: true, text: 'Send Now', value: true },
      {
        key: 'sendAt',
        text: 'Scheduled',
        value: 'sendAt',
        // disabled: true,
      },
    ],
  },
};

const defaults = {
  companyRoles: ['agent'],
  roles: ['user', 'employer', 'company-agent'],
  sendWelcome: false,
};

export function init({
  values = {},

  opts = { fields: { companyRoles: true, roles: false } },
}: {
  opts?: { fields?: { companyRoles: boolean; roles: boolean } };
  values?: Partial<Record<keyof typeof fields, unknown>>;
} = {}) {
  const rawFields = _.cloneDeep(fields);

  if (opts.fields) {
    _.map(opts.fields, (val, key) => {
      if (!val) {
        delete rawFields[key];
      }
    });
  }

  const extra = {
    ...extras,
    companyRoles: {
      options: dispatch('companies.getAgentTypeOptions', {
        company: values.company,
      }),
    },
  };

  const separatePropertyFields = mapUnifiedToSeparate(rawFields);

  return new CompanyAgent(
    {
      ...separatePropertyFields,
      extra,
      values: {
        ..._.defaults(values, defaults),
        company:
          (values.company as ICompany)?.uuid ??
          (values.company as ICompany['uuid']),
      },
    },
    {
      options: {
        fallback: false,
        retrieveOnlyDirtyValues: !!values?.uuid,
      },
    },
  );
}
