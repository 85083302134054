import { action, observable } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';

export default class AssociatedAccountsModal {
  @observable
  isOpen = false;

  @observable
  associatedAccounts = [];

  @observable
  fingerprint = '';

  @action
  setup(associatedAccounts, fingerprint) {
    this.isOpen = true;
    this.associatedAccounts = associatedAccounts;
    this.fingerprint = fingerprint;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = arg;
  }

  @action
  clear() {
    this.isOpen = false;
    this.associatedAccounts = [];
    this.fingerprint = '';
  }

  @action
  async removeAssociatedDevice(workerId, fingerprint) {
    await dispatch('workers.update', {
      data: {
        $pull: {
          authorizedDevices: {
            fingerprint,
          },
        },
      },
      id: workerId,
    });
    this.updateAccountList(workerId);
  }

  @action
  updateAccountList(workerId) {
    const updatedAccounts = _.filter(
      this.associatedAccounts,
      (acc) => acc.uuid !== workerId,
    );
    this.associatedAccounts = updatedAccounts;
  }
}
