import type { IPartnerAssessmentResult } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export default class PartnerAssessmentResultStore extends BaseStore<IPartnerAssessmentResult> {
  constructor() {
    super({
      baseItem: PartnerAssessmentResultStore.BASE_ITEM,
      serviceName: 'partnerAssessmentResults',
    });

    return this;
  }

  static BASE_ITEM: IPartnerAssessmentResult = {
    companyId: null,
    createdAt: null,
    uuid: null,
  };
}
