import type {
  IAssignment,
  IAssignmentQuery,
  IUser,
  IWorker,
  ISite,
  IShift,
  ICompany,
  IPosition,
  IShiftTemplate,
  IZone,
} from '@shiftsmartinc/shiftsmart-types';

import {
  observable,
  computed,
  set,
  action,
  runInAction,
  reaction,
  IObservableArray,
} from 'mobx';
import moment from 'moment-timezone';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';
import { getStore } from '#/shared/getStores';

const log = getChildLogger('ui.shiftsNextGen');

export default class ShiftsNextGen {
  @observable isLoading = false;

  @observable companyFilter = '';

  @observable hasFiltersURLUpdate = false;

  @action.bound
  setHasFiltersURLUpdate(flag) {
    this.hasFiltersURLUpdate = flag;
  }

  @computed get companyFiltersOptions() {
    const selectedColor = 'blue';
    return [
      {
        key: 'all',
        label: {
          basic: !_.isEmpty(this.companyFilter),
          circular: true,
          color: _.isEmpty(this.companyFilter) && selectedColor,
          empty: true,
        },
        text: 'All Shifts',
        value: '',
      },
      {
        key: 'own',
        label: {
          basic: !_.isEqual(this.companyFilter, 'own'),
          circular: true,
          color: _.isEqual(this.companyFilter, 'own') && selectedColor,
          empty: true,
        },
        text: 'My Shifts',
        value: 'own',
      },

      { className: 'divider', key: 'divider', value: {} },
    ];
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action.bound
  set<T extends keyof this>(key: T, value: this[T]) {
    _.set(this, key, value);
  }

  @action.bound
  async setCompanyFilter(e, { value }) {
    this.companyFilter = value;
    return this.fetchData();
  }

  @action.bound
  setLoading(isLoading) {
    this.isLoading = isLoading;
  }

  // #region Page Tabs & Ranges
  @observable start = moment().startOf('week');

  @computed
  get end() {
    return moment(this.start).add(1, this.activeRangeTab).startOf('day');
  }

  @action.bound
  getRange() {
    return {
      end: this.end,
      start: this.start,
    };
  }

  @observable activeTab = 'calendar';

  @action.bound
  toggleTab(tab) {
    dispatch('routing.addQuery', {
      view: tab,
    });
    this.hasFiltersURLUpdate = true;
    this.activeTab = tab;
    dispatch('prefs.setShiftPageView', tab);
    this.fetchData();
  }

  @action.bound
  setTab(tab) {
    this.activeTab = tab;
  }

  @observable activeContentTab = 'partners';

  @action.bound
  toggleContentTab(tab) {
    this.activeContentTab = tab;
    if (/partners/i.test(tab)) {
      dispatch('routing.addQuery', {
        content: tab,
        range: this.activeRangeTab,
      });
      // this.setRangeTab('week');
    } else {
      if (/day/i.test(this.activeRangeTab)) {
        dispatch('routing.addQuery', {
          content: tab,
          range: 'day',
        });
      }
      this.setRangeTab('day');
    }
  }

  @computed
  get contentTabs() {
    return _([
      {
        index: 0,
        key: 'partners',
        title: 'Partners',
      },
      {
        index: 1,
        key: 'shifts',
        title: 'Shifts',
      },
    ])
      .compact()
      .value();
  }

  @observable
  activeRangeTab: 'day' | 'week' = 'week';

  @action.bound
  setDateRange(start) {
    this.start = moment(start).startOf(
      this.activeTab === 'list' ? 'day' : this.activeRangeTab,
    );
    dispatch('routing.addQuery', {
      start: moment(start).toISOString(),
    });
    this.toggleCalendarPopup(false);
    this.loadAndCalcShiftStats();
  }

  @action.bound
  setRangeTab(tab, start?) {
    if (!/day|week/i.test(tab)) log.error('Invalid range tab: ', tab);
    if (this.activeRangeTab !== tab) {
      if (start) {
        this.start = moment(start).startOf('day');
      } else if (tab === 'day' && this.start.isSame(moment(), 'weeks')) {
        // If switching to day view and viewing current week,
        // switch to current day
        this.start = moment().startOf('day');
      } else {
        this.start.startOf(tab);
      }
      dispatch('routing.addQuery', {
        content: this.activeContentTab,
        range: tab,
        start: moment(this.start).toISOString(),
      });
      this.activeRangeTab = tab;
      dispatch('prefs.setShiftRangeView', tab);
    }
    this.loadAndCalcShiftStats();
  }
  // #endregion Page Display

  @observable
  isDateCalendarOpen = false;

  @action.bound
  toggleCalendarPopup(open = !this.isDateCalendarOpen) {
    this.isDateCalendarOpen = open;
  }

  sortKeys = ['status'];

  sortDirs = ['asc'];

  @computed
  get groupedShiftList() {
    return _(this.shiftList)
      .orderBy(this.sortKeys, this.sortDirs)
      .groupBy(this.groupBy)
      .value();
  }

  // Formats groupedShiftList for virtualized list
  @computed
  get shiftsCalendarDayData() {
    return _.reduce(
      this.groupedShiftList,
      (acc, value, key) => {
        acc.push(key, ...value);
        return acc;
      },
      [],
    );
  }

  @observable searchValue = '';

  @action.bound
  updateSearchValue(searchValue) {
    this.searchValue = searchValue;
  }

  partnerList = observable.array<IWorker>([]);

  shiftList = observable.array<IShift>([]);

  shiftTemplatesList = observable.array<IShiftTemplate>([]);

  selectedShiftTemplates = observable.array<IShiftTemplate>([]);

  updatedShiftTemplates = observable.array<IShiftTemplate>([]);

  assignmentList = observable.array<IAssignment>([]);

  @action.bound
  async setup({
    shiftsStore,
    assignmentsStore,
    partnersStore,
    start,
    range = dispatch('prefs.getShiftRangeView'),
    view = dispatch('prefs.getShiftPageView'),
    content = 'partners',
    statusFilter,
    populateAssignmentStats = true,
    loadPositions = true,
    loadSites = true,
    loadPartners = true,
    loadAssignments = true,
    positionFilter = [],
    siteFilter = [],
  }) {
    this.setLoading(true);
    dispatch('shifts.emptyList');
    this.shiftList = shiftsStore?.list ?? dispatch('shifts.retrieve', 'list');
    this.assignmentList =
      assignmentsStore?.list ?? dispatch('assignments.retrieve', 'list');
    this.partnerList =
      partnersStore?.list ?? dispatch('partners.retrieve', 'list');

    this.start = moment(start).startOf(range);
    this.activeTab = view;
    this.activeRangeTab = range;
    this.activeContentTab = content;

    this.pinnedPartnerIds.clear();
    // this.positionFilter = ['all'];

    this.searchValue = '';
    this.partnerSearchValue = '';

    if (!_.isEmpty(statusFilter)) {
      this.shiftStatusFilter.replace(statusFilter);
    }

    if (!_.isEmpty(positionFilter)) {
      this.positionFilter.replace(positionFilter);
    }

    if (!_.isEmpty(siteFilter)) {
      this.siteFilter.replace(siteFilter);
    }

    const company = dispatch('auth.getCompany');

    let positions = [];
    if (loadPositions) {
      positions = await dispatch('positions.findByCompany', {
        company: company.uuid,
        query: { $sort: { title: 1 } },
      });
    }

    let sites = [];
    if (loadSites) {
      sites = await dispatch('sites.findByCompany', {
        company: company.uuid,
      });
    }

    runInAction(() => {
      this.allPositions.replace(_.map(positions, 'uuid'));
      this.allSites.replace(_.map(sites, 'uuid').concat(['isRemote']));
    });

    await this.fetchData({
      clear: _.isEmpty(this.searchValue),
      loadAssignments,
      populateAssignmentStats,
    });
    this.filterSelectedShiftsThatAreInView();
    if (loadPartners) {
      dispatch('partners.findByCompany', {
        company,
        opts: { clear: true },
      });
    }
    this.loadAndCalcShiftStats();
  }

  @action.bound
  async loadSitesForShiftTemplates() {
    const companyId = dispatch('auth.getCompany')?.uuid;
    const sites = await dispatch('sites.findByCompany', {
      company: companyId,
    });
    runInAction(() => {
      this.allSites.replace(_.map(sites, 'uuid').concat(['isRemote']));
    });
  }

  @action.bound
  async fetchData({ loadAssignments = true, ...opts } = {}) {
    const select = [
      'uuid',
      'start',
      'end',
      'autoLaunch',
      'timezone',
      'company',
      'description',
      'rate',
      'pay',
      'useFlatRatePay',
      'bonus',
      'currency',
      // 'customer',
      'address',
      'addressRef',
      'location',
      'duration',
      'expiresAt',
      'title',
      'slots',
      'assignments',
      'assignedUsers',
      // 'notificationPrefs',
      'status',
      //  'children',
      'path',
      'search',
      'updatedAt',
      '__t',
      'isRemote',

      'shiftScheduleType',
      'shiftScheduleRules',

      'shiftType',
      'appActionType',
      'enableBulkNotifications',
      'externalLink',
      'externalLinkButtonText',
      'originalBaseShiftId',
      'redundancyCreationMethod',
      'redundancyType',
    ];
    switch (this.activeTab) {
      case 'calendar':
        switch (this.activeContentTab) {
          case 'shifts':
            this.fetchShifts(_.extend({}, opts));
            break;
          case 'partners':
            if (loadAssignments) {
              this.fetchPartnerAssignmentsData(opts);
            } else {
              this.setLoading(false);
            }
            break;
          default:
            log.warn('Unknown Active Content Tab');
            break;
        }
        break;
      case 'list':
      case 'map':
        await this.fetchShifts({ ...opts, select });
        break;
      case 'shiftTemplates':
        await this.fetchShiftTemplates({ ...opts, select });
        break;
      default:
        log.warn(`Unknown Active Tab: "${this.activeTab}"`);
        break;
    }
  }

  @action.bound
  async fetchShifts({
    clear = true,
    populateAssignmentStats = false,
    select = [],
    start = this.start,
    end = this.end,
    statusQuery = undefined,
  } = {}) {
    const company = dispatch('auth.getCompany');
    const siteId = _.first(dispatch('ui.filters.getSiteIds'));
    const isRemote = dispatch('ui.filters.getIsRemote');

    if (siteId && !_.includes(this.siteFilter, siteId)) {
      this.siteFilter.replace([siteId]);
    }

    // If there is no company or no statuses have been selected, return an empty array
    if (_.isEmpty(company) || _.isEmpty(this.shiftStatusFilter)) {
      dispatch('shifts.emptyList');
      this.setLoading(false);
      return Promise.resolve([]);
    }
    const companyQuery = [{ company: company.uuid }];

    const shiftLimit = this.activeTab === 'list' ? 250 : 1000;
    const query = {
      // Prevent baseStore from merging text regex $or with date range $or
      // by moving the date range $or into an $and expression
      $and: [
        {
          $or: [
            {
              start: {
                $gte: moment(start).startOf('day').toDate(),
                $lt: moment(end).startOf('day').toDate(),
              },
            },
            {
              end: {
                $gt: moment(start).startOf('day').toDate(),
                $lte: moment(end).startOf('day').toDate(),
              },
            },
          ],
        },
        {
          $or: companyQuery,
        },
        {
          $or: [
            { isRemote: true, shiftType: { $ne: 'training' } },
            { isRemote: false },
          ],
        },
      ],

      $client: {
        populateCompany: true,
      },
      $limit: shiftLimit,
      status: {
        $in: this.shiftStatusFilter.includes('all')
          ? this.allShiftStatuses
          : this.shiftStatusFilter, // ATTN: Assignment Status EP-5523
      },
    };

    if (populateAssignmentStats) {
      _.extend(query.$client, {
        populateAssignmentStats: true,
      });
    }

    if (!_.isEmpty(select)) {
      query.$select = select;
    }

    if (this.zoneFilter) {
      query.parentAddressRefs = this.zoneFilter;
    }

    if (
      !_.isEmpty(this.positionFilter) &&
      !this.positionFilter.includes('all')
    ) {
      const positionFilters = [...this.positionFilter];
      const noRoleIndex = this.positionFilter.indexOf('noRole');
      if (noRoleIndex !== -1) {
        positionFilters[noRoleIndex] = '';
      }
      query['search.tags'] = { $in: positionFilters };
    }

    if (
      !_.isEmpty(this.siteFilter) &&
      !this.siteFilter.includes('all') &&
      !isRemote
    ) {
      const removedIsRemote = this.siteFilter.filter(
        (site) => site !== 'isRemote',
      );
      if (_.size(removedIsRemote) > 0) {
        query.addressRef = {
          $in: removedIsRemote,
        };
      }
      query.isRemote = false;
    }

    if (isRemote) {
      query.isRemote = true;
    }

    if (!_.isEmpty(statusQuery) && _.isObject(statusQuery)) {
      query.status = statusQuery;
    }

    try {
      this.setLoading(true);
      const shifts = await dispatch('shifts.find', query, {
        clear,
      });
      this.setLoading(false);
      return Promise.resolve(shifts);
    } catch (error) {
      log.error('Error Loading Shifts :', error);
      this.setLoading(false);
      return Promise.reject(error);
    }
  }

  @action.bound
  async fetchShiftTemplates({ clear = true, statusQuery } = {}) {
    const company = dispatch('auth.getCompany');
    // If there is no company or no statuses have been selected, return an empty array
    if (_.isEmpty(company) || _.isEmpty(this.shiftStatusFilter)) {
      dispatch('shiftTemplates.emptyList');
      return Promise.resolve([]);
    }

    const recurringSchedule = await dispatch('recurringSchedules.getSelected');
    const day = dispatch('ui.recurringSchedules.getActiveDay');
    const query = {
      $limit: 20,
      recurringScheduleId: recurringSchedule.uuid,
      startDayOffset: day,
      status: {
        $in: this.shiftStatusFilter.includes('all')
          ? this.allShiftTemplatesStatuses
          : this.shiftStatusFilter,
      },
    };

    if (!_.isEmpty(this.siteFilter) && !this.siteFilter.includes('all')) {
      const removedIsRemote = this.siteFilter.filter(
        (site) => site !== 'isRemote',
      );
      if (_.size(removedIsRemote) > 0) {
        query.addressRef = {
          $in: removedIsRemote,
        };
      }
      query.isRemote = true;
    }

    if (!_.isEmpty(statusQuery) && _.isObject(statusQuery)) {
      query.status = statusQuery;
    }
    try {
      this.setLoading(true);
      // paginate through it and then set the list
      const shiftTemplates = await dispatch('shiftTemplates.find', query, {
        clear,
      });
      runInAction(() => set(this.shiftTemplatesList, shiftTemplates));
      this.setLoading(false);
      return Promise.resolve(shiftTemplates);
    } catch (error) {
      log.error('Error Loading shiftTemplates :', error);
      this.setLoading(false);
      return Promise.reject(error);
    }
  }

  @action.bound
  async fetchPartnerAssignmentsData({
    fetch = true,
    clear = true,
    populateAssignmentStats = true,
  } = {}) {
    try {
      this.setLoading(true);
      let shifts = dispatch('shifts.retrieve', 'list');
      if (fetch) {
        shifts = await this.fetchShifts({ populateAssignmentStats });
      }
      const shiftIds = _.map(shifts, 'uuid');
      const query: IAssignmentQuery = {
        $client: { populateUser: true },
        $limit: 1000,
        ref: { $in: shiftIds },
      };

      if (!this.filter.assignmentStatus.includes('all')) {
        query.status = { $in: this.filter.assignmentStatus };
      } else {
        query.status = { $in: this.allAssignmentStatuses };
      }

      if (!this.shiftStatusFilter.includes('all')) {
        query['data.status'] = { $in: this.shiftStatusFilter };
      }

      const assignments = await dispatch('assignments.find', query, { clear });
      const users = assignments.map((assignment) => assignment.user?.uuid);
      // update partner list to include assignments
      const assignmentPartners = await dispatch('partners.runQuery', {
        query: { uuid: { $in: users } },
      });
      const fullPartnerList = _(this.partnerList)
        .concat(assignmentPartners.data)
        .uniqBy('uuid')
        .value();
      runInAction(() => set(this.partnerList, fullPartnerList));
    } catch (error) {
      log.error('Error Loading Shift Partners Tab Data: ', error);
    }
    this.setLoading(false);
  }

  filterAssignmentsForValidPartners(v) {
    return (
      _.find(this.partnerList, { uuid: v.user.uuid }) ||
      this.pinnedPartnerIsInThePartnerList(v.uuid)
    );
  }

  @computed
  get assignmentListDay() {
    return _(this.assignmentList)
      .filter((v) => !!this.filterAssignmentsForValidPartners(v))
      .groupBy('user.uuid')
      .value();
  }

  @computed
  get assignmentListWeek() {
    return _(this.assignmentList)
      .filter((v) => !!this.filterAssignmentsForValidPartners(v))
      .groupBy('user.uuid')
      .mapValues((v) =>
        _(v)
          .groupBy((shift) => moment(shift.start).date())
          .value(),
      )
      .value();
  }

  // List of partners with no shifts for the selected range
  @computed
  get unassignedPartners() {
    const assignmentList = /week/i.test(this.activeRangeTab)
      ? this.assignmentListWeek
      : this.assignmentListDay;
    return _(this.partnerList)
      .filter((p) => !_.has(assignmentList, p.uuid))
      .map((p) =>
        _.pick(p, ['uuid', 'displayName', 'profileImageURL', 'rating']),
      )
      .orderBy([this.partnerSortValue], [this.partnerSortDir])
      .value();
  }

  // Formats array to pass to virtualized list
  @computed
  get assignedAndUnassignedPartnerData() {
    const assignmentList = /week/i.test(this.activeRangeTab)
      ? this.assignmentListWeek
      : this.assignmentListDay;
    const partnersWithAssignments = _.orderBy(
      Object.entries(assignmentList)
        .map(([k, v]) => ({
          assignmentData: v,
          user: /week/i.test(this.activeRangeTab)
            ? _.first(v[Object.keys(v)[0]]).user
            : _.first(v).user,
          uuid: k,
        }))
        // Filter out pinned partners
        .filter((v) => !this.pinnedPartnerIsInThePartnerList(v.uuid)),
      [`user.${this.partnerSortValue}`],
      [this.partnerSortDir],
    );
    const partnersWithoutAssignments = this.unassignedPartners.filter(
      (v) => !this.pinnedPartnerIsInThePartnerList(v.uuid),
    );
    return _.concat(partnersWithAssignments, partnersWithoutAssignments);
  }

  @computed
  get openShiftsArray() {
    if (this.isLoading) return [];
    return _(this.shiftList)
      .filter((shift) => /pending|draft|active|expired/i.test(shift.status))
      .map((shift) => {
        // populate AssignmentStats
        const assigned = _(this.assignmentList)
          .filter((a) => a.ref === shift.uuid)
          .filter((a) => /assigned|accepted|approved|completed/i.test(a.status))
          .value().length;
        const assignmentStats = {
          assigned,
        };
        return _.extend({}, shift, {
          assignmentStats,
          openSlots: shift.slots - assigned,
        });
      })
      .filter((shift) => shift.slots > shift.assignmentStats.assigned)
      .value();
  }

  @observable
  partnerRowHovered = '';

  @action.bound
  setHovered = (partnerId, hover) => {
    if (partnerId && hover) {
      log.silly('SetHovered: %s hovered', partnerId);
      this.partnerRowHovered = partnerId;
    } else if (!hover && this.partnerRowHovered === partnerId) {
      log.silly('SetHovered: %s unhovered', partnerId);
      this.partnerRowHovered = '';
    } else {
      log.debug('SetHovered: %s othered', partnerId || this.partnerRowHovered, {
        hover,
        partnerId,
      });
    }
  };

  @observable
  showOpenShiftsRow = false;

  @action.bound
  toggleOpenShiftsRow(show = !this.showOpenShiftsRow) {
    this.showOpenShiftsRow = show;
  }

  /* react-window List component's onScroll handler
   * onScroll(scrollDirection, scrollOffset, scrollUpdateWasRequested)
   */
  @action.bound
  onListScroll({ scrollDirection, scrollOffset }) {
    if (scrollDirection === 'forward' && scrollOffset > 0) {
      if (this.totalOpenSlots === 0) {
        this.toggleOpenShiftsRow(false);
      }
    }
  }

  @action.bound
  redirectToCreateShift() {
    const start = this.start;
    // Defaults to 12:00pm - 3:00pm
    let initialShift = {
      end: moment(start).set({ hour: 15, minute: 0 }).toDate(),
      start: moment(start).set({ hour: 12, minute: 0 }).toDate(),
    };
    // If current time is after start, set start and end to tomorrow 12pm-3pm
    if (moment().isAfter(initialShift.start)) {
      initialShift = {
        end: moment().add(1, 'day').set({ hour: 15, minute: 0 }).toDate(),
        start: moment().add(1, 'day').set({ hour: 12, minute: 0 }).toDate(),
      };
    }

    dispatch('ui.shiftFormNextGen.setup', {
      company: dispatch('auth.getCompany'),
      shift: initialShift,
    });
    dispatch('routing.push', '/shifts/create/continue');
  }

  @computed
  get shiftsByDay() {
    return _(this.shiftList)
      .groupBy((shift) => moment(shift.start).date())
      .value();
  }

  @computed
  get openShifts() {
    return _(this.openShiftsArray)
      .groupBy((shift) => moment(shift.start).date())
      .value();
  }

  // #region Shift Status Filtering
  get allShiftStatuses() {
    return getStore('shifts').allStatuses;
  }

  allShiftTemplatesStatuses = observable.array<IShiftTemplate['status']>([
    'Draft',
    'Active',
    'Disabled',
  ]);

  shiftStatusFilter = observable.array<
    string | IShift['status'] | IShiftTemplate['status']
  >(['all']);

  @action.bound
  toggleShiftStatus(label, { checked, exclusive, all }) {
    if (exclusive) {
      this.shiftStatusFilter.clear();
    }
    if (all || /all/i.test(label)) {
      this.shiftStatusFilter.replace(['all']);
    } else if (checked) {
      this.shiftStatusFilter.push(label);
    } else {
      if (this.shiftStatusFilter.includes('all')) {
        const isShiftTemplate = dispatch(
          'ui.shiftFormNextGen.getIsShiftTemplate',
        );
        if (isShiftTemplate) {
          this.shiftStatusFilter.replace(this.allShiftTemplatesStatuses);
        } else {
          this.shiftStatusFilter.replace(this.allShiftStatuses);
        }
      }
      this.shiftStatusFilter.remove(label);
    }

    if (this.shiftStatusFilter.length === this.allShiftStatuses.length) {
      this.shiftStatusFilter.replace(['all']);
    }

    dispatch('routing.addQuery', {
      shiftStatusFilter: this.shiftStatusFilter.slice(),
    });
    this.hasFiltersURLUpdate = true;
    this.fetchData();
    this.loadAndCalcShiftStats();
  }

  @computed get shiftStatusFilterSummary() {
    let currentStatusFilters = _.clone(this.shiftStatusFilter) as Array<string>;
    if (
      this.shiftStatusFilter.includes('all') ||
      _.without(this.allShiftStatuses, ...this.shiftStatusFilter).length === 0
    ) {
      currentStatusFilters = ['All'];
    }

    return currentStatusFilters.length > 3
      ? `${currentStatusFilters.length} Selected`
      : currentStatusFilters.join(', ');
  }

  @computed get shiftTemplateStatusFilterSummary() {
    let currentStatusFilters = _.clone(this.shiftStatusFilter) as Array<string>;
    if (
      this.shiftStatusFilter.includes('all') ||
      _.without(this.allShiftTemplatesStatuses, ...this.shiftStatusFilter)
        .length === 0
    ) {
      currentStatusFilters = ['All'];
    }

    return currentStatusFilters.length > 3
      ? `${currentStatusFilters.length} Selected`
      : currentStatusFilters.join(', ');
  }
  // #endregion Shift Status Filtering

  // #region Position Filtering
  allPositions = observable.array<IPosition['uuid']>([]);

  positionFilter = observable.array<string | IPosition>(['all']);

  @action.bound
  togglePosition(position, { checked, exclusive, all, noRole }) {
    if (exclusive) {
      this.positionFilter.clear();
    }

    if (all) {
      this.positionFilter.replace(['all']);
    } else if (checked) {
      if (noRole) this.positionFilter.push('noRole');
      else this.positionFilter.push(position.uuid);
    } else {
      if (this.positionFilter.includes('all')) {
        this.positionFilter.replace(this.allPositions.concat(['noRole']));
      }
      if (noRole) this.positionFilter.remove('noRole');
      else this.positionFilter.remove(position.uuid);
    }

    if (this.positionFilter.length === this.allPositions.length + 1) {
      this.positionFilter.replace(['all']);
    }

    dispatch('routing.addQuery', {
      positionFilter: this.positionFilter.slice(),
    });
    this.fetchData();
    this.loadAndCalcShiftStats();
  }

  @computed get positionFilterSummary() {
    const activeFilters = this.positionFilter.includes('all')
      ? ['All']
      : _(this.positionFilter)
          .map((id) => {
            if (id === 'noRole' || id === '') {
              return { title: 'No Role' };
            }
            return dispatch('positions.getSync', id);
          })
          .map('title')
          .value();

    return activeFilters.length > 3
      ? `${activeFilters.length} Selected`
      : activeFilters.join(', ');
  }
  // #endregion Position Filtering

  // #region Location/Site Filtering
  allSites = observable.array<ISite>([]);

  siteFilter = observable.array<string | ISite>(['all']);

  zoneFilter?: IZone['uuid'] = null;

  @action.bound
  applyZoneFilter(zoneId: IZone['uuid']) {
    this.zoneFilter = zoneId;
    this.fetchData();
  }

  @action.bound
  toggleSite(site, { checked, exclusive, all }) {
    if (exclusive) {
      this.siteFilter.clear();
    }

    if (all) {
      this.siteFilter.replace(['all']);
    } else if (checked) {
      this.siteFilter.push(site.uuid);
    } else {
      if (this.siteFilter.includes('all')) {
        this.siteFilter.replace(this.allSites);
      }
      this.siteFilter.remove(site.uuid);
    }

    if (this.siteFilter.length === this.allSites.length) {
      this.siteFilter.replace(['all']);
    }

    dispatch('routing.addQuery', {
      siteFilter: this.siteFilter.slice(),
    });
    this.hasFiltersURLUpdate = true;
    this.fetchData();
    this.loadAndCalcShiftStats();
  }

  @action.bound
  setSiteFilter(sites) {
    this.siteFilter.replace(sites.slice());

    dispatch('routing.addQuery', {
      siteFilter: this.siteFilter.slice(),
    });
    this.hasFiltersURLUpdate = true;
    this.fetchData();
  }

  @computed get siteFilterSummary() {
    const sitesList = dispatch('sites.retrieve', 'list');
    const activeFilters = this.siteFilter.includes('all')
      ? ['All']
      : _(sitesList)
          .filter((site) => _.includes(this.siteFilter, site.uuid))
          .map('name')
          .value();

    return activeFilters.length > 3
      ? `${activeFilters.length} Selected`
      : activeFilters.join(', ');
  }

  @action.bound
  resetFilters() {
    this.hasFiltersURLUpdate = false;
    this.shiftStatusFilter.replace(['all']);
    this.positionFilter.replace([]);
    this.siteFilter.replace(['all']);
    this.fetchData();
  }
  // #endregion Location/Site Filtering

  // #region Assignment Filtering
  allAssignmentStatuses = observable.array<string>([
    // 'Sent',
    'Assigned',
    'Approved',
    'Accepted',
    'Completed',
  ]);

  @observable
  filter = { assignmentStatus: observable.array<string>(['all']) };

  @action.bound
  toggleAssignmentStatus(label, { checked, exclusive, all }) {
    if (exclusive) {
      this.filter.assignmentStatus.clear();
    }
    if (all || /all/i.test(label)) {
      this.filter.assignmentStatus.replace(['all']);
    } else if (checked) {
      this.filter.assignmentStatus.push(label);
    } else {
      if (this.filter.assignmentStatus.includes('all')) {
        this.filter.assignmentStatus.replace(this.allAssignmentStatuses);
      }
      this.filter.assignmentStatus.remove(label);
    }

    if (
      this.filter.assignmentStatus.length === this.allAssignmentStatuses.length
    ) {
      this.filter.assignmentStatus.replace(['all']);
    }

    this.fetchData();
  }

  @computed get assignmentStatusFilterSummary() {
    let currentStatusFilters = _.clone(this.shiftStatusFilter) as Array<string>;
    if (
      _.isEqual(
        this.filter.assignmentStatus.slice().sort(),
        this.allAssignmentStatuses.slice().sort(),
      )
    ) {
      currentStatusFilters = ['All'];
    }

    return currentStatusFilters.length > 3
      ? `${currentStatusFilters.length} Selected`
      : currentStatusFilters.join(', ');
  }

  // #endregion Assignment Filtering

  // #region Filtering and Grouping
  @observable groupBy = 'status';

  @observable groupByOptions = [
    // {
    //   key: 'locations',
    //   text: 'Locations',
    //   value: 'locations',
    // },
    {
      key: 'status',
      text: 'Shift Status',
      value: 'status',
    },
    {
      key: 'role',
      text: 'Role',
      value: 'position.title',
    },
  ];

  @computed get selectedGroupBy() {
    return _.find(this.groupByOptions, { value: this.groupBy }) || {};
  }

  @action.bound
  setGroupBy({ value }) {
    this.groupBy = value;
  }

  @computed
  get isFiltered() {
    return !(
      this.searchValue === '' &&
      _.includes(this.shiftStatusFilter, 'all') &&
      _.includes(this.filter.assignmentStatus, 'all') &&
      _.includes(this.positionFilter, 'all')
    );
  }
  // #endregion Filtering and Grouping

  // #region Partner Pinning
  pinnedPartnerIds = observable.array<IUser['uuid']>([]);

  pinnedPartnerIsInThePartnerList(uuid) {
    return (
      _.includes(this.pinnedPartnerIds, uuid) &&
      _.find(this.partnerList, (partner) => partner.uuid === uuid)
    );
  }

  @computed
  get pinnedPartners() {
    const assignmentList = /week/i.test(this.activeRangeTab)
      ? this.assignmentListWeek
      : this.assignmentListDay;
    return _.concat(
      Object.entries(assignmentList)
        .map(([k, v]) => ({
          assignmentData: v,
          uuid: k,
        }))
        // Filter by pinned partners
        .filter((v) => this.pinnedPartnerIsInThePartnerList(v.uuid)),
      this.unassignedPartners.filter((v) =>
        this.pinnedPartnerIsInThePartnerList(v.uuid),
      ),
    );
  }

  @action
  pinPartner = async (partnerId) => {
    if (_.includes(this.pinnedPartnerIds, partnerId)) {
      _.pull(this.pinnedPartnerIds, partnerId);
    } else {
      this.pinnedPartnerIds.push(partnerId);
    }
  };

  @action
  unpinAllPartners = () => {
    this.pinnedPartnerIds.clear();
  };
  // #endregion Partner Pinning

  // #region Partner Searching
  @observable
  partnerSearchValue = '';

  @action.bound
  setPartnerSearch(value) {
    log.debug('setPartnerSearch');
    this.partnerSearchValue = value;
    this.debouncedPartnerSearch();
  }

  debouncedPartnerSearch = _.debounce(this.doPartnerSearch, 500);

  // TODO add an 'includeQuery' prop to append conditions to the search methods $or object
  @action.bound
  doPartnerSearch() {
    const value = this.partnerSearchValue;
    log.debug('doPartnerSearch');
    return dispatch(
      'partners.find',
      {
        $or: [
          {
            displayName: {
              $options: 'i',
              $regex: `.*${value}.*`,
            },
          },
          {
            phoneNumber: {
              $regex: `.*${value}.*`,
            },
          },
          {
            email: {
              $options: 'i',
              $regex: `.*${value}.*`,
            },
          },
        ],
        $skip: 0,
      },
      { clear: true, save: true },
    );
  }
  // #endregion Partner Searching

  // #region Partner Sorting
  partnerSortOptions = [
    {
      key: 'displayName',
      text: 'Name',
      value: 'displayName',
    },
    {
      key: 'rating',
      text: 'Rating',
      value: 'rating',
    },
  ];

  @observable
  partnerSortValue = 'displayName';

  @computed
  get partnerSortDir() {
    return this.partnerSortValue === 'rating' ? 'desc' : 'asc';
  }

  @action.bound
  setPartnerSort(value) {
    this.partnerSortValue = value;
  }
  // #endregion Partner Sorting

  // #region Shift Actions
  @observable
  isAssignModalOpen = false;

  @observable
  thingToAssign: Partial<IShift> = {};

  @observable
  partnerToAssign: Partial<IWorker> = {};

  @observable
  assignModalContent = '';

  @observable
  assignModalError = '';

  @action
  toggleAssignModal = (
    open = !this.isAssignModalOpen,
    thing = {},
    partner = {},
  ) => {
    this.assignModalError = '';
    if (!_.isEmpty(thing)) this.thingToAssign = thing;
    if (!_.isEmpty(partner)) this.partnerToAssign = partner;
    if (open) {
      const baseContent = `${this.partnerToAssign.displayName} will be assigned to ${this.thingToAssign.title}`;
      this.assignModalContent = _([
        baseContent,
        'and will receive a notification immediately.',
      ])
        .compact()
        .value()
        .join(' ');
    } else {
      this.assignModalContent = '';
    }
    this.isAssignModalOpen = open;
  };

  // Assigns worker with uuid: workerId to thing with uuid: thingId
  // Displays confirmation modal if shift status is 'active'
  @action
  assign = async ({ thingId, workerId, confirm }) => {
    try {
      const thing = await dispatch('things.get', thingId);
      if (confirm || _.includes(thing.path, '/')) {
        const partner = await dispatch('users.get', workerId);
        if (_.includes(thing.path, '/')) {
          const parentId = _.first(_.split(thing.path, '/'));
          const parentThing = await dispatch('things.get', parentId);
          this.toggleAssignModal(true, parentThing, partner);
        } else {
          this.toggleAssignModal(true, thing, partner);
        }
      } else {
        await dispatch('assignments.assign', {
          thingId,
          workerId,
        });
      }
    } catch (err) {
      log.error(err);
    }
  };

  @action
  confirmAssign = async () => {
    try {
      const assignment = await dispatch('assignments.assign', {
        thingId: this.thingToAssign.uuid,
        workerId: this.partnerToAssign.uuid,
      });
      const children = _.get(assignment, 'data.children', []); // assignment.data
      this.toggleAssignModal(false);
      if (!_.isEmpty(children)) {
        this.fetchData();
      }
    } catch (error) {
      runInAction(() => {
        this.assignModalError = error.message;
      });
      log.error('Error assigning shift: ', error);
    }
  };

  @observable
  isUnassignModalOpen = false;

  @observable
  assignmentToCancel = '';

  @observable
  unassignModalContent = '';

  @observable
  partnerToUnassign = {};

  @action
  toggleUnassignModal = (open = !this.isUnassignModalOpen) => {
    this.isUnassignModalOpen = open;
  };

  @action
  unassign = async ({ assignmentId, shift, partner, confirm }) => {
    if (confirm) {
      this.assignmentToCancel = assignmentId;
      this.partnerToUnassign = partner;
      this.unassignModalContent = `${partner.displayName} will be unassigned from ${shift.title}.`;

      this.toggleUnassignModal(true);
    } else {
      dispatch('ui.loadingModal.open', { message: 'Un-assigning' });
      const authUser = dispatch('auth.getUser');
      try {
        await dispatch('assignments.update', {
          data: {
            canceledAt: moment().toDate(),
            // ATTN: Assignment Status EP-5523
            canceledBy: authUser.uuid,
            status: 'Canceled',
            updatedNote: `Canceled by Employer: ${authUser.displayName}`,
          },
          id: assignmentId,
        });
      } catch (err) {
        log.error('Failed to cancel assignment', err);
      }
      dispatch('ui.loadingModal.close');
      // TODO handle assignment update instead of re-fetching
      // assignment update object does not include populateUser
      this.fetchData();
    }
  };

  @observable
  isConfirmActivateModalOpen = false;

  @action.bound
  toggleConfirmActivateModal(open = !this.isConfirmActivateModalOpen) {
    this.isConfirmActivateModalOpen = open;
    if (!open) this.shiftToActivate = {};
  }

  @observable
  shiftToActivate = {};

  @action.bound
  activateShift({ shift }) {
    this.shiftToActivate = shift;
    this.isConfirmActivateModalOpen = true;
  }
  // #endregion Shift Actions

  // #region Shift Stats
  @observable isLoadingStats = false;

  @action.bound
  setLoadingStats(isLoading) {
    this.isLoadingStats = isLoading;
  }

  @observable
  shiftStats = {};

  @action
  calcStats = (assList, shiftList) => {
    if (_.isEmpty(assList) && _.isEmpty(shiftList)) {
      this.shiftStats = {};
      return;
    }

    const assGroupedByDay = _.groupBy(assList, (ass) =>
      moment(ass.start).date(),
    );

    const shiftsGroupedByDay = _.groupBy(shiftList, (shift) =>
      moment(shift.start).date(),
    );

    // Add keys for days with shifts but no assignments
    _.mergeWith(assGroupedByDay, shiftsGroupedByDay, (assValue, shiftValue) => {
      if (_.isEmpty(assValue) && !_.isEmpty(shiftValue)) {
        return [];
      }
      return assValue;
    });

    // Calc cumulative shift stats for each day
    const statsAndSlotCounts = _.mapValues(assGroupedByDay, (assArray, key) => {
      const slotCount = _.reduce(
        shiftsGroupedByDay[key],
        (prev, curr) => prev + (curr.slots || 0),
        0,
      );

      const shiftCount = _.get(shiftsGroupedByDay[key], 'length', 0);
      const shiftsForDay = _.get(shiftsGroupedByDay, key, []);
      const assignmentCount = shiftsForDay.reduce(
        (prev, curr) => prev + (curr.assignments ? curr.assignments.length : 0),
        0,
      );

      return _.reduce(
        assArray,
        (acc, assObj) => ({
          assignmentCount,
          estimatedCost:
            acc.estimatedCost + _.get(assObj, 'payment.paymentDue', 0),
          scheduledHours: acc.scheduledHours + assObj.duration,
          shiftCount,
          slotCount,
        }),
        {
          assignmentCount,
          estimatedCost: 0,
          scheduledHours: 0,
          shiftCount,
          slotCount,
        },
      );
    });

    this.shiftStats = _.mapValues(statsAndSlotCounts, (stats) =>
      _.extend(stats, {
        percentFilled: stats.assignmentCount / stats.slotCount,
      }),
    );
  };

  @action
  loadAndCalcShiftStats = async () => {
    if (this.activeTab === 'list') {
      return false;
    }
    const company = dispatch('auth.getCompany');
    const shiftQuery = {
      $limit: 1000,
      company: company.uuid,
      start: {
        $gte: moment(this.start).startOf('day').toDate(),
        $lt: moment(this.end).startOf('day').toDate(),
      },
    };
    // apply filters to shifts query
    if (!this.shiftStatusFilter.includes('all')) {
      shiftQuery.status = { $in: this.shiftStatusFilter };
    } else {
      shiftQuery.status = { $nin: ['Deleted', 'Canceled'] };
    }
    if (
      !_.isEmpty(this.positionFilter) &&
      !this.positionFilter.includes('all')
    ) {
      const positionFilters = [...this.positionFilter];
      const noRoleIndex = this.positionFilter.indexOf('noRole');
      if (noRoleIndex !== -1) {
        positionFilters[noRoleIndex] = '';
      }
      shiftQuery['search.tags'] = { $in: positionFilters };
    }
    if (!this.siteFilter.includes('all')) {
      const removedIsRemote = this.siteFilter.filter(
        (site) => site !== 'isRemote',
      );
      if (_.size(removedIsRemote) > 0) {
        shiftQuery.addressRef = {
          $in: removedIsRemote,
        };
      }
    }
    const shifts = await dispatch('shifts.runQuery', shiftQuery);
    const shiftIds = _.map(shifts.data, 'uuid');
    const assQuery = {
      // ATTN: Assignment Status EP-5523
      $limit: 1000,

      ref: { $in: shiftIds },
      status: { $in: ['Assigned', 'Accepted', 'Approved', 'Completed'] },
    };
    const assignments = await dispatch('assignments.runQuery', assQuery);
    log.debug('Loaded Shifts and Assignments: ', assignments, shifts);
    this.calcStats(assignments.data, shifts.data);
    // update map cluster data with new filtered shifts list
    dispatch('ui.map.updateClusteredThings', shifts.data);
  };

  statsReaction = reaction(
    () => _.union(this.shiftList, this.assignmentList).map((a) => a.status),
    (shiftsAndAssignments) => {
      if (_.isEmpty(shiftsAndAssignments)) return;
      if (this.activeTab === 'list') return;
      if (this.isFiltered) {
        this.loadAndCalcShiftStats();
      } else {
        this.calcStats(this.assignmentList, this.shiftList);
      }
    },
  );

  // #region Shift Stats @computed
  @computed
  get totalOpenSlots() {
    return this.openShiftsArray.reduce(
      (prev, curr) => prev + (curr.openSlots || 0),
      0,
    );
  }

  @computed
  get totalSlots() {
    if (_.isEmpty(this.shiftStats)) return 0;
    let totalSlots = 0;
    const end = this.activeRangeTab === 'week' ? 7 : 1;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < end; ++i) {
      const date = moment(this.start).add(i, 'days').date();
      totalSlots += _.get(this.shiftStats[date], 'slotCount', 0);
    }
    return totalSlots;
  }

  @computed
  get totalAssignments() {
    if (_.isEmpty(this.shiftStats)) return 0;
    let totalAssignments = 0;
    const end = this.activeRangeTab === 'week' ? 7 : 1;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < end; ++i) {
      const date = moment(this.start).add(i, 'days').date();
      totalAssignments += _.get(this.shiftStats[date], 'assignmentCount', 0);
    }
    return totalAssignments;
  }

  @computed
  get percentFilled() {
    return Math.round((this.totalAssignments / this.totalSlots) * 100) || 0;
  }
  // #endregion Shift Stats @computed
  // #endregion Shift Stats

  selectedShifts: IObservableArray<IShift> = observable.array([]);

  @computed
  get allShiftsSelected() {
    return (
      this.selectedShifts.length === this.shiftList.length &&
      this.selectedShifts.length > 0
    );
  }

  @computed
  get allShiftTemplatesSelected() {
    return (
      this.selectedShiftTemplates.length === this.shiftTemplatesList.length &&
      this.selectedShiftTemplates.length > 0
    );
  }

  @action.bound
  selectShift({ shift, checked }) {
    if (checked) {
      this.selectedShifts.push(shift);
    } else {
      this.selectedShifts.replace(
        _.filter(this.selectedShifts, (s) => s.uuid !== shift.uuid),
      );
    }
  }

  @action.bound
  selectAllShifts() {
    if (this.allShiftsSelected) {
      this.selectedShifts.clear();
    } else {
      this.selectedShifts.replace(_.clone(this.shiftList));
    }
  }

  @action.bound
  clearSelectedShifts() {
    this.selectedShifts.clear();
  }

  @action.bound
  selectShiftTemplate({ shiftTemplate, checked }) {
    if (checked) {
      this.selectedShiftTemplates.push(shiftTemplate);
    } else {
      this.selectedShiftTemplates.replace(
        _.filter(
          this.selectedShiftTemplates,
          (s) => s.uuid !== shiftTemplate.uuid,
        ),
      );
    }
  }

  @action.bound
  selectAllShiftTemplates() {
    if (this.allShiftTemplatesSelected) {
      this.selectedShiftTemplates.clear();
    } else {
      this.selectedShiftTemplates.replace(_.clone(this.shiftTemplatesList));
    }
  }

  @action.bound
  getSelectedShiftTemplates() {
    return this.selectedShiftTemplates;
  }

  @action.bound
  clearShiftTemplatesLists() {
    this.setTab('shiftTemplates');
    this.selectedShiftTemplates.clear();
    this.shiftTemplatesList.clear();
    this.updatedShiftTemplates.clear();
  }

  @action.bound
  setUpdatedShiftTemplates(templates) {
    this.updatedShiftTemplates.replace(templates);
  }

  @action.bound
  filterSelectedShiftsThatAreInView() {
    const listOfShiftIds = _.map(this.shiftList, 'uuid');
    this.selectedShifts.replace(
      _.filter(this.selectedShifts, (ss) =>
        _.includes(listOfShiftIds, ss.uuid),
      ),
    );
  }

  @action
  cancelShift = async ({ shift }, showSnackBar = true) => {
    const data = {
      status: 'Canceled',
      uuid: shift.uuid,
    };
    try {
      const res = await dispatch('shifts.update', { data });
      if (showSnackBar) {
        dispatch('ui.snackBar.open', 'Shift Canceled');
      }
      this.selectShift({ checked: false, shift });
      return res;
    } catch (error) {
      log.error('Error Removing Shift: ', error);
      return dispatch('ui.snackBar.open', 'Error: Shift was Not Canceled');
    }
  };

  @action.bound
  cancelSelectedShifts = async ({ selectedShifts }) => {
    if (!selectedShifts.length) {
      dispatch('ui.snackBar.open', 'No Shifts Selected');
      return;
    }
    dispatch('ui.loadingModal.open', {
      message: 'Canceling Shifts',
      total: selectedShifts.length,
    });
    await Promise.map(
      selectedShifts,
      async (shift) => {
        try {
          await this.cancelShift({ shift }, false);
        } catch (error) {
          log.error(`Failed to cancel ${_.get(shift, '__t')}`, error, {
            extra: { shift },
          });
        }
        dispatch('ui.loadingModal.increment');
      },
      { concurrency: 50 },
    );

    dispatch('ui.loadingModal.close', {
      delay: 1000,
      message: 'Shift Cancelation Completed',
    });
    dispatch('ui.snackBar.open', 'Shifts Canceled');
  };

  @action.bound
  getActiveRangeTab() {
    return this.activeRangeTab;
  }
}
