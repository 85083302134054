import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export type IInteractions = IBaseItem & Record<string, unknown>;

export default class InteractionsStore extends BaseStore<IInteractions> {
  constructor() {
    const baseItem = {
      company: '',
      messages: [],
      user: '',
    };

    super({ baseItem, searchFields: [], serviceName: 'interactions' });

    return this;
  }
}
