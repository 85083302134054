import type {
  IAssignment,
  ICompany,
  IUser,
} from '@shiftsmartinc/shiftsmart-types';

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import isUUID from 'uuid-validate';

import { PopupIcon } from '#/shared/components/ssm';
import { useCacheItem } from '#/shared/hooks/useCacheItem';

import { LocationWarningLabel } from './LocationWarningLabel';

type TimecardIndicatorLabelProps = {
  assignment: ITimecardAssignment;
} & (
  | { isCheckIn: true; isCheckOut?: never }
  | { isCheckIn?: never; isCheckOut: true }
);

const StatusLabelRow = styled.span.attrs(() => ({
  className: 'ph1 flex items-center nowrap',
}))``;

export const StyledStatus = styled.span.attrs(() => ({
  className: 'br2 ba tc ttu',
}))<{
  color: string;
}>`
  height: calc(1rem + 2px);
  font-size: 0.8rem;
  line-height: initial;
  margin-bottom: 2px;
  padding: 0 0.25rem;
  border-color: ${({ color }) => color};
  color: ${({ color }) => color};
  user-select: none;
  text-transform: uppercase;
`;

export interface ITimecardAssignment
  extends Pick<
    IAssignment,
    'company' | 'start' | 'end' | 'checkIn' | 'checkOut'
  > {
  data?: IAssignment['data'];
  locationExceptions?: IAssignment['locationExceptions'];
  /**
   * ### ref
   * @deprecated if manually specifying a ref, please pass as {@see ITimecardAssignment.refId}
   */
  ref?: IAssignment['ref'];

  /**
   * ### refId
   * Ref to the UUID of the linked Shift
   *
   * **NOTE** This property has been renamed to `refId` in order to avoid the
   * react limitation that `ref` is a reserved word.
   */
  refId?: IAssignment['ref'];
  status?: IAssignment['status'];
  /**
   * ### user
   * The userId (user.uuid) of the assigned user
   */
  user: IUser['uuid'];
}

const TimecardStatusIndicators: React.FC<TimecardIndicatorLabelProps> =
  observer(({ assignment, isCheckIn, isCheckOut }) => {
    if (_.isEmpty(assignment.checkIn?.timestamp)) {
      return null;
    }

    let timecardEntry;
    let phase;

    if (isCheckIn) {
      timecardEntry = assignment.checkIn;
      phase = 'in';
    } else if (isCheckOut) {
      timecardEntry = assignment.checkOut;
      phase = 'out';
    } else {
      return null;
    }

    if (
      phase === 'out' &&
      moment().isBetween(assignment.checkIn.timestamp, assignment.end)
    ) {
      return (
        <div className="pl1">
          <StyledStatus color="green">
            {(/Assigned|Accepted|Approved/i.test(assignment.status)
              ? 'In Progress'
              : assignment.status) || 'In Progress'}
          </StyledStatus>
        </div>
      );
    }

    return timecardEntry ? (
      <StatusLabelRow>
        <TimingStatusLabel
          assignment={assignment}
          phase={phase}
          timecardEntry={timecardEntry}
        />
        {timecardEntry.override && (
          <LocationWarningLabel
            assignment={assignment}
            timecardEntry={timecardEntry}
          />
        )}
        {timecardEntry.usedCheckinCode && (
          <UsedTimeCardCodeIndicator
            usedCheckinCode={timecardEntry.usedCheckinCode}
          />
        )}
        {timecardEntry.usedCheckoutCode && (
          <UsedTimeCardCodeIndicator
            usedCheckoutCode={timecardEntry.usedCheckoutCode}
          />
        )}
      </StatusLabelRow>
    ) : null;
  });

const TimingStatusLabel: React.FC<{
  assignment: ITimecardAssignment;
  phase: 'in' | 'out';
  timecardEntry: IAssignment['checkIn'] | IAssignment['checkOut'];
}> = observer(({ timecardEntry, assignment, phase }) => {
  const { item: company, isLoading: companyIsLoading } = useCacheItem<ICompany>(
    {
      itemId: assignment.company,
      storeName: 'companies',
    },
  );

  if (companyIsLoading) {
    return null;
  }

  const graceDuration = _.get(company, 'settings.timekeeping.graceDuration', 5);
  const warningDuration = _.get(
    company,
    'settings.timekeeping.warningDuration',
    10,
  );

  const indexTime = /^in$/.test(phase) ? assignment.start : assignment.end;
  const deltaMinutes = Math.abs(
    moment(indexTime).diff(timecardEntry.timestamp, 'minutes'),
  );
  let severity: null | 'error' | 'warning' = null;
  let state: null | 'early' | 'late' = null;

  let label;
  let tooltip;

  if (
    moment(timecardEntry.timestamp)
      .add(graceDuration, 'minutes')
      .isBefore(indexTime)
  ) {
    state = 'early';
    severity = moment(timecardEntry.timestamp)
      .add(warningDuration, 'minutes')
      .isBefore(indexTime)
      ? 'error'
      : 'warning';
  } else if (
    moment(timecardEntry.timestamp)
      .subtract(graceDuration, 'minutes')
      .isAfter(indexTime)
  ) {
    state = 'late';
    severity = moment(timecardEntry.timestamp)
      .subtract(warningDuration, 'minutes')
      .isAfter(indexTime)
      ? 'error'
      : 'warning';
  } else {
    label = null;
  }

  /**
   * For flexible shift, we do not show the label `early` or `late` if
   * Worker has early check out
   * or
   * Worker has late check in
   */
  if (
    /flexible/i.test(assignment?.data?.shiftScheduleType) &&
    ((/^early$/.test(state) && /^out$/.test(phase)) ||
      (/^late$/.test(state) && /^in$/.test(phase)))
  ) {
    return null;
  }

  if (severity) {
    const color = severity === 'error' ? 'var(--red)' : 'var(--orange)';

    label = <StyledStatus color={color}>{state}</StyledStatus>;

    const hours = Math.floor(deltaMinutes / 60);
    const minutes = deltaMinutes % 60;

    const timeFrame = hours
      ? `${hours}:${_.padStart(`${minutes}`, 2, '0')} hours`
      : `${deltaMinutes} minutes`;

    tooltip = (
      <div className="flex">
        <Icon name="clock" style={{ color }} />
        Check-{phase} was {timeFrame} {state}
      </div>
    );
  }

  return tooltip ? (
    <PopupIcon
      popperModifiers={[
        {
          name: 'preventOverflow',
          options: {
            boundariesElement: 'offsetParent',
          },
        },
      ]}
      trigger={label}
      wide={true}
    >
      {tooltip}
    </PopupIcon>
  ) : (
    label
  );
});

const UsedTimeCardCodeIndicator = ({
  usedCheckinCode,
  usedCheckoutCode,
}:
  | { usedCheckinCode: true; usedCheckoutCode?: never }
  | { usedCheckinCode?: never; usedCheckoutCode: true }) =>
  usedCheckinCode || usedCheckoutCode ? (
    <Popup
      inverted={true}
      on="hover"
      popperModifiers={[
        {
          name: 'preventOverflow',
          options: {
            boundariesElement: 'offsetParent',
          },
        },
      ]}
      trigger={
        <Icon
          className="pl1 lh-solid"
          color="green"
          name="check square outline"
        />
      }
      wide={true}
    >
      <p style={{ color: 'var(--green)' }}>
        <Icon name="check square outline" />
        Entered {usedCheckinCode ? 'Checkin' : 'Checkout'} Code
      </p>
    </Popup>
  ) : null;

export const WasEditedIndicator = ({ user, timecard, simple = 'true' }) => {
  const [displayName, setDisplayName] = useState();

  useEffect(() => {
    if (simple) return;

    if (user?.displayName) {
      setDisplayName(user?.displayName);
    } else if (isUUID(user)) {
      dispatch('users.get', user, {
        query: {
          $select: ['uuid', 'displayName'],
        },
        select: false,
      }).then(({ displayName: dn }) => setDisplayName(dn));
    }
  }, [user, simple]);

  if (
    !timecard ||
    !timecard.user ||
    timecard.user === (user?.uuid || user) ||
    (timecard.user && timecard.loc)
  ) {
    return null;
  }

  if (simple) {
    return user ? (
      <sup
        className="ssm-violet"
        style={{
          fontSize: '0.9em',
          position: 'relative',
          top: '-0.25em',
          userSelect: 'none',
          verticalAlign: 'baseline',
        }}
      >
        •
      </sup>
    ) : null;
  }

  return !_.isEmpty(user) ? (
    <PopupIcon
      tooltip={
        <p style={{ color: 'var(--green)' }}>
          <Icon name="edit" />
          Timecard last edited by {displayName ?? user}
        </p>
      }
      trigger={<Icon name="pencil" size="small" />}
      wide={true}
    />
  ) : null;
};

export const TimecardCheckInIndicators: React.FC<{
  assignment: ITimecardAssignment;
}> = observer(({ assignment }) => (
  <TimecardStatusIndicators assignment={assignment} isCheckIn={true} />
));
export const TimecardCheckOutIndicators: React.FC<{
  assignment: ITimecardAssignment;
}> = observer(({ assignment }) => (
  <TimecardStatusIndicators assignment={assignment} isCheckOut={true} />
));
