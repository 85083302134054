import type {
  CompanyModules,
  ICompany,
  IAbility,
  RuleAction,
  IBaseItem,
} from '@shiftsmartinc/shiftsmart-types';
import type { Query } from '@feathersjs/feathers';
import type { StoreName } from '#/shared/stores';

import _ from 'lodash';
import { observable, action, computed, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import { Ability, RawRule, Rule } from '@casl/ability';
import { rulesToQuery } from '@casl/ability/extra';

import { app } from '#/shared/app';
import { getChildLogger } from '#/shared/utils/client.logger';

import BaseStore from './_baseStore';

export default class AbilitiesStore extends BaseStore<IAbility> {
  constructor() {
    super({
      searchFields: ['roles', 'modules', 'rules.subject', 'rules.actions'],
      serviceName: 'abilities',
    });

    return this;
  }

  init() {
    app().on('logout', this.clear);
    app().on(
      'login:company',
      action(({ company, user }) => {
        this.initSession({ company, user });
      }),
    );
    app().on(
      'logout:company',
      action(() => {
        this.company = null;
        this.clear();
      }),
    );
  }

  @action.bound
  clear(): void {
    this.log.debug('Resetting Access');
    this.access = new Ability([]);
    this.session = null;
  }

  /**
   * ### access
   * Stores the calculated CASL Ruleset; the brains behind the store.
   *
   * @memberof AbilitiesStore
   */
  @observable
  access: Ability = new Ability([]);

  /**
   * ### variables
   * The variables used to hydrate rule conditions
   *
   * #### Default Variables
   *
   * ##### company
   * The logged in/impoersonated company object, can be used to support rules like this:
   *   `allow(['update'], 'company', ['owner'], { uuid: '${company.uuid}' });`
   *
   * ##### user
   * The logged in user object, which enables user editing rules like:
   *   `allow('update', 'user', { uuid: '${user.uuid}' });`
   *
   * #### Additional Variables
   *
   *
   * @memberof AbilitiesStore
   */
  @observable
  variables: Record<string, unknown> = {};

  get user() {
    return dispatch('auth.getUser');
  }

  @observable
  company: ICompany = null;

  @computed
  get enabledModules(): Array<keyof CompanyModules> {
    return _(this.company?.modules)
      .pickBy((val) => !!val)
      .keys()
      .value() as Array<keyof CompanyModules>;
  }

  getSubjectName: (string) => string = getSubjectName;

  session = null;

  @action.bound
  async initSession({ user, company }) {
    if (global.TYPE !== 'CLIENT') return null;

    const log = this.log.getChildLogger('initSession');
    log.debug('START');

    await this.initVariables({ company, user });

    if (this.user?.uuid !== user?.uuid) {
      log.debug('Changing Active User');
      this.session = null;
    }
    if (this.company?.uuid !== company?.uuid) {
      log.debug('Changing Active Company');
      this.session = null;
      this.company = company;
    }

    if (!this.session) {
      log.debug('Reloading all Abilities');
      this.session = this.loadAllAbilities({ company });
    }

    log.debug('Returning Abilities Session');
    return this.session;
  }

  @action.bound
  async loadAllAbilities({ company }): Promise<AbilitiesStore['access']> {
    const log = this.log.getChildLogger('loadAllAbilities');
    log.debug('START');

    let abilities: Array<RawRule> = [];

    try {
      const parentCompanyIds = dispatch('auth.getParentCompanyIds');

      abilities = (await this.runQuery({
        $client: {
          calcAbilities: {
            parentCompanyIds,
          },
        },
      })) as unknown as Array<RawRule>;
      log.debug('Loaded %d abilities from server', _.size(abilities));

      runInAction(() => {
        this.access = new Ability(abilities, { subjectName: getSubjectName });
      });

      /**
       * #### abilities:loaded
       * emit the `abilities:loaded` event to let the app know that it can
       * recalculate what is and is not visible or queried in the app.
       */
      app().emit(`abilities:loaded`, {
        company,
        user: this.user,
      });

      const flattenized = _(this.access.rules)
        .map((rule) =>
          _.mapValues(rule, (val, key) => {
            if (_.isDate(val)) return undefined;
            return _.isArray(val) ? val.join(',') : val;
          }),
        )
        .sortBy('fields')
        .value();

      log.debug('Loaded Abilities for user', {
        access: this.access,
        flattenized,
      });
    } catch (err) {
      log.error('unknown error while calculating abilities', err);
    }

    return this.access;
  }

  /**
   * ## initVariables
   *
   * Initializes the `variables` instance var with the relevant contents.
   *
   * These variables are interpolated into the rule conditions are in the
   * @see hydrateRuleConditionVariables method as a part of each rule's
   * initialziation process.
   *
   * ### Variable Formatting
   *
   * #### Objects
   * Objects can be attached to the variables object as-is
   *
   * #### Arrays
   * Arrays **MUST** be stringified to be properly interpolated into the rule
   * template string. A parsing error will occur and the rule will fail to
   * be initialized if this step is not taken.
   *   *
   *
   * @param {*} [{ user = this.user, company = this.company }={}]
   * @memberof AbilitiesStore
   */
  @action.bound
  async initVariables({ user = this.user, company = this.company } = {}) {
    const variables = {
      company: { ...(company ?? {}) },
      user: { ...(user ?? {}) },
    };

    runInAction(() => {
      this.variables = variables;
    });
  }

  @action.bound
  async getFlatMappedRules(abilities) {
    const log = this.log.getChildLogger('getFlatMappedRules');
    log.debug('START');

    const rules = _(abilities || []).map((ability) => {
      if (!ability.rules) {
        // TODO: Remove this after verifying rules in prod DB
        log.error(
          'Using Legacy Rule Format ... Run "Seed Permissions" in full refresh mode in `admin/utils/DB Data Seeders` as soon as possible',
        );
      }

      const rs = ability.rules || [ability];
      if (process.env.NODE_ENV === 'development') {
        return _.map(rs, (rule) => ({
          ..._.omit(ability, ['rules']),
          ...rule,
        }));
      }

      return rs;
    });

    log.debug('Parsing Rules: ', { rules: rules.value() });

    return rules
      .flatten()
      .map((rule) => {
        try {
          const conditions = this.hydrateRuleConditionVariables(
            rule.conditions,
            this.variables,
          );

          _.set(rule, 'conditions', conditions);

          return rule;
        } catch (err) {
          log.error('Error in hydrating rule', err, { rule });
          return null;
        }
      })
      .compact()
      .value();
  }

  /**
   * ## hydrateRuleConditionVariables
   *
   * Complex rule conditions are stored in their stringifed form on the backend, and
   * this method "fills in" the variables so that the correct permissions are had.
   *
   * ### Examples
   * For example, this rule condition is used for "Can I Edit a User":
   *
   *     `{ uuid: '${user.uuid}'}`
   *
   * When this method runs and initializes the permissions for a user with the uuid
   * value of `some-arbitra-ry-uuid-value`, the condition variables will be interpolated
   * into:
   *
   *     { uuid: 'some-arbitra-ry-uuid-value' }
   *
   * So that when I run the "can" check, the result is true for my own user, but not for other users
   *
   *     can('update', myUserObject) // returns true
   *     can('update', otherUserObject) // returns false since `otherUserObject.uuid !== 'some-arbitra-ry-uuid-value'
   *
   * ### Variables
   * The variables interpolated into the rule conditions are setup in the `initVariables`
   * method which is executed on init/setup of this store.
   *
   * @see AbilitiesStore.initVariables
   *
   * #### IMPORTANT: Array variables must be stored in stringified form on the variables object
   *
   * @param {*} conditions
   * @param {*} variables
   * @return {*}
   * @memberof AbilitiesStore
   */
  @action.bound
  hydrateRuleConditionVariables(conditions, variables) {
    if (_.isEmpty(conditions)) {
      return conditions;
    }
    const log = this.log.getChildLogger('hydrateRuleConditionVariables');

    let conditionsString = _.isString(conditions)
      ? conditions
      : JSON.stringify(conditions);
    try {
      //  ** IMPORTANT ** array values MUST be pre-stringified
      conditionsString = conditionsString.replace(/":\["(\$\{.*?)"\]/g, '":$1');
    } catch (err) {
      log.error('Failed to replace array template vars', err, {
        extra: {
          companyId: this.company?.uuid,
          conditionsString,
          userId: this.user?.uuid,
        },
      });
    }

    try {
      return JSON.parse(_.template(conditionsString)(variables));
    } catch (err) {
      log.error('Unable to parse conditions: ', err, { conditions });
      return null;
    }
  }

  /**
   * ## buildQueryForSubject
   *
   * Based on a casl subject and action, builds a MongoDB compatible query that can be sent to
   * the server to return _only_ results that match the casl restrictions.
   *
   * For example, this can be used to load a restricted list of pools (@see Pools.jsx:fetchData). Since
   * the pools access rule has complex conditions defined, these casl restrictions are translated into
   * a query so that the user only sees the pools they have access to.
   *
   * @param {*} {
   *     subject: inputSubject,
   *     action: inputAction = 'read',
   *   }
   * @return {*}
   * @memberof AbilitiesStore
   */
  @action.bound
  buildQueryForSubject({
    subject: inputSubject,
    action: inputAction = 'read',
  }): Query {
    const log = this.log.getChildLogger(`buildQueryForSubject`);
    const querySubject = getSubjectName(inputSubject);

    function convertToMongoQuery({
      conditions,
      inverted,
      ...rule
    }: Rule): Query {
      if (_.isEmpty(conditions)) {
        throw new Error('Rule must have conditions', {
          ...rule,
          conditions,
          inverted,
        });
      }
      return inverted ? { $nor: [conditions] } : conditions;
    }

    const mongoQuery = rulesToQuery(
      this.access,
      inputAction,
      querySubject,
      convertToMongoQuery,
    );

    if (_.isEmpty(mongoQuery)) {
      log.debug(`No Mongo Query Restrictions found for "${querySubject}"`);
      return {};
    }

    log.debug('Generated Mongo Query Restrictions: ', {
      mongoQuery,
    });

    return _.has(mongoQuery, '$and') ? mongoQuery : { $and: [mongoQuery] };
  }

  /**
   * ## role
   * @deprecated this appears to be dead logic
   */
  @computed
  get role() {
    const log = this.log.getChildLogger('get:role');
    if (_.isEmpty(this.user) || !this.user.uuid) {
      log.debug('role: no user');
      return null;
    }

    if (!this.company?.uuid) {
      log.debug('role: no company');
      return null;
    }

    if (this.company?.owner === this.user.uuid) {
      return 'owner';
    }

    // TODO: No `agent` object found on old auth store. When was it removed?
    if (!_.isEmpty(this.agent)) {
      return this.agent.role;
    }

    return null;
  }

  /**
   * ## permissions
   * @deprecated this appears to be dead logic
   */
  @computed
  get permissions() {
    const log = this.log.getChildLogger('get:permissions');
    if (_.isEmpty(this.user) || !this.user.uuid) {
      log.debug('role: no user');
      return [];
    }

    if (!this.company?.uuid) {
      log.debug('role: no company');
      return [];
    }

    if (this.company?.owner === this.user.uuid) {
      return ['*'];
    }

    // TODO: No `agent` object found on old auth store. When was it removed?
    if (!_.isEmpty(this.agent)) {
      return this.agent.permissions;
    }

    return [];
  }

  @action.bound
  can(
    userAction: RuleAction,
    services: ServicesDefProp,
    fields?: string,
  ): boolean {
    const subjects = _.isArray(services) ? services : [services];

    // If the session is null, the init has not progressed past the earliest stage
    if (!_.isFunction(this.access?.can) || _.isNil(this.session)) {
      this.log
        .getChildLogger('can')
        .error('Access Rules have not been initialized');
      return false;
    }
    return _.every(subjects, (subject) =>
      this.access.can(userAction, subject, fields),
    );
  }

  @action.bound
  cannot(
    userAction: RuleAction,
    services: ServicesDefProp,
    fields?: string,
  ): boolean {
    const subjects = _.isArray(services) ? services : [services];
    // If the session is null, the init has not progressed past the earliest stage
    if (!_.isFunction(this.access?.cannot) || _.isNil(this.session)) {
      this.log
        .getChildLogger('cannot')
        .error('Access has not been initialized');
      return true;
    }
    return _.some(subjects, (subject) =>
      this.access.cannot(userAction, subject, fields),
    );
  }
}

export { getSubjectName };

const log = getChildLogger('abilities.getSubjectName');

function getSubjectName(subject: string | IBaseItem) {
  if (!subject || typeof subject === 'string') {
    return subject;
  }

  const Type: { modelName?: string; name?: string } =
    typeof subject === 'object' ? subject.constructor : subject;

  const retval =
    _.get(subject, '_modelName') ||
    _.get(subject, 'modelName') ||
    _.get(subject, '__t') ||
    Type.modelName ||
    Type.name;

  log.silly(`result "${retval}"`, { subject, type: Type });

  return retval;
}

type ServicesDefProp = StoreName | IBaseItem | Array<StoreName> | string;
