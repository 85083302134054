import React from 'react';
import { observer } from 'mobx-react';
import { Popup } from 'semantic-ui-react';

import { useStore } from '#/shared/hooks/useStores';

/**
 * ## DarkModeStateToggle
 * Renders the current state of the "Dark Mode" toggle from the UI store, and supports
 * toggling between the different options available and defined in the ui store:
 * - `null`: Use the System setting
 * - `true`: Override and enable dark mode
 * - `false`: Override and disable dark mode (default)
 *
 * @warning This component enables an alpha-level implementation of dark mode in the
 * web portal. For this reason, it is restricted to dev mode only.
 */
export const DarkModeStateToggle: React.FC = observer(() => {
  const { theme } = useStore('ui');

  if (!theme.allowDarkMode) {
    return null;
  }

  return (
    <div className="flex items-center">
      <Popup
        content={`Dark Mode: ${theme.darkModeState.title}`}
        position="top center"
        trigger={<span className="f2 pv1">{theme.darkModeState.icon}</span>}
      />
    </div>
  );
});
DarkModeStateToggle.displayName = 'DarkModeStateToggle';
