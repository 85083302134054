import type { IAvailability } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { dispatch } from 'rfx-core';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import BaseStore from './_baseStore';

const moment = extendMoment(Moment);

export default class AvailabilityStore extends BaseStore<
  IAvailability & { isAvailable?: false | 'available' | 'conflict' }
> {
  constructor() {
    const baseItem = {};
    super({ baseItem, searchFields: [], serviceName: 'availability' });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  async getForUser({ user }) {
    const userId = _.get(user, 'uuid', user);

    if (_.isEmpty(userId) || !_.isString(userId)) {
      return Promise.reject(new Error('Must Provide valid userId'));
    }

    const res = await this.find({ user: userId });

    return res;
  }

  async getHoursForUser({ user, company, time }) {
    const userId = _.get(user, 'uuid', user);

    if (_.isEmpty(userId) || !_.isString(userId)) {
      return Promise.reject(new Error('Must Provide valid userId'));
    }

    const $match = {
      company: _.get(company, 'uuid', company),
      dataType: 'shift',
      user: userId,
    };

    if (time) {
      _.extend($match, {
        start: {
          $gte: moment(time).startOf('week'),
          $lte: moment(time).endOf('week'),
        },
      });
    }

    const pipeline = [
      {
        $match,
      },
      {
        $group: {
          _id: {
            company: '$company',
            status: '$status',
            week: { $week: '$start' },
            year: { $year: '$start' },
          },
          count: { $sum: 1 },
          hours: { $sum: '$duration' },
        },
      },
    ];

    const res = await dispatch('assignments.runAggregate', { pipeline });

    const completedStatuses = ['Completed'];
    const activeStatuses = ['EnRoute', 'Active', 'InProgress'];
    const scheduledStatuses = ['Approved', 'Accepted', 'Assigned'];
    const allStatusesRegex = new RegExp(
      _.union(completedStatuses, activeStatuses, scheduledStatuses).join('|'),
    );
    const completedStatusesRegex = new RegExp(completedStatuses.join('|'));
    const activeStatusesRegex = new RegExp(activeStatuses.join('|'));

    const scheduledStatusesRegex = new RegExp(scheduledStatuses.join('|'));

    const breakdown = _.map(res, (r) => ({ ...r.id, ..._.omit(r, ['id']) }));

    this.log.debug(
      'Breakdown of assignments: ',
      JSON.stringify(breakdown, null, 2),
      res,
    );

    const retval = _(breakdown).reduce(
      (acc, curr) => {
        this.log.debug('Current status item: ', JSON.stringify(curr, null, 2));
        if (completedStatusesRegex.test(curr.status)) {
          acc.completed = (acc.completed || 0) + curr.hours;
        }
        if (activeStatusesRegex.test(curr.status)) {
          acc.active = (acc.active || 0) + curr.hours;
        }
        if (scheduledStatusesRegex.test(curr.status)) {
          acc.scheduled = (acc.scheduled || 0) + curr.hours;
        }

        if (!allStatusesRegex.test(curr.status)) {
          acc[curr.status] = (acc[curr.status] || 0) + curr.hours;
          acc.other = (acc.other || 0) + curr.hours;
        } else {
          acc.total = (acc.total || 0) + curr.hours;
        }
        return acc;
      },
      { breakdown },
    );

    this.log.debug('Hours for user: ', {
      retval,
    });

    return retval;
  }

  checkAvailabilityForThing({ user = {}, availability, thing }) {
    let hasAvailabilityForShift;
    let hasConflictForShift;

    if (_.isArray(availability)) {
      hasAvailabilityForShift = _.find(availability, {
        isAvailable: 'available',
        user: user.uuid,
      });

      hasConflictForShift = !!_.find(availability, (a) => {
        if (user.uuid !== a.user) {
          return false;
        }
        if (!!thing && a.ref && a.ref === thing.uuid) {
          // filter out availability based on existing assignments
          return false;
        }
        if (a.isAvailable === 'conflict') {
          const shiftStart = moment(thing.start);
          const shiftEnd = moment(thing.end);
          const conflictDate = moment(a.date);
          const conflictLocalStart = moment.utc(a.start, 'Hmm').set({
            date: conflictDate.date(),
            month: conflictDate.month(),
            year: conflictDate.year(),
          });
          const conflictLocalEnd = moment.utc(a.end, 'Hmm').set({
            date: conflictDate.date(),
            month: conflictDate.month(),
            year: conflictDate.year(),
          });
          const shiftRange = moment.range(shiftStart, shiftEnd);
          const conflictRange = moment.range(
            conflictLocalStart,
            conflictLocalEnd,
          );
          return conflictRange.overlaps(shiftRange);
        }

        return false;
      });
    }

    let availabilityText =
      !hasConflictForShift && hasAvailabilityForShift ? 'is' : 'is not';

    if (!hasConflictForShift && _.isUndefined(hasAvailabilityForShift)) {
      availabilityText = 'may be';
    }

    return {
      availabilityText,
      hasAvailabilityForShift,
      hasConflictForShift,
    };
  }
  /** EVENTS */

  onCreated = (data) => {
    /** Until we have a better idea of performance of new availability service
     * disable 'addItem' method in store, since it will query the backend service, and
     * run multiple aggregate methods for each 'created'
     *
     * This becomes a problem since we currently 'remove' and 'create' new availability
     * each time a user updates
     */
    this.log.silly('New Availability Created: ', data);
  };
}
