import '#/web/tailwind.css';
import '#/shared/styles/_.global.css';

import React from 'react';
import Helmet from 'react-helmet';
import _ from 'lodash';
import { MatchMediaProvider } from 'mobx-react-matchmedia';
import { ToastContainer, toast } from 'react-toastify';
import { observer } from 'mobx-react';

import AppContainer from '#/shared/components/utils/AppContainer';
import LoadingModal from '#/shared/components/utils/LoadingModal';
import AgendaProgressLoadingModal from '#/shared/components/utils/AgendaProgressLoadingModal';
import QuickInputWidget from '#/shared/components/utils/QuickInputWidget';
import styles from '#/shared/styles/AppLayout.css';
import { ErrorBoundary } from '#/shared/components/utils/ErrorBoundary';
import { DarkMode } from '#/ssm-ui/DarkMode';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('AppLayout');

import { useFetchData } from '../hooks/useFetchData';
import { useStores } from '../hooks/useStores';

type AppLayoutProps = {
  children: JSX.Element[] | JSX.Element;
  routes: unknown;
};

const AppLayout = observer(({ children, routes }: AppLayoutProps) => {
  const { ui, auth, agendaJobProgress } = useStores();
  useFetchData(
    {
      fetchData: async () => {
        const userId = _.get(auth.user, 'uuid');
        if (global.TYPE === 'CLIENT' && _.isEmpty(userId)) {
          log.warn('No User setup in client');
        } else if (global.TYPE !== 'CLIENT' && _.isEmpty(userId)) {
          log.warn('No User setup in ssr');
        }

        // Removed to prevent duplicate initialization
        // return store.prefs.setup(store.auth.user);
        return Promise.resolve();
      },
    },
    [],
  );

  return (
    <MatchMediaProvider breakpoints={ui.breakpoints}>
      {auth.jwt && <QuickInputWidget />}

      <ToastContainer
        autoClose={ui.snackBar.duration}
        position={toast.POSITION.BOTTOM_RIGHT}
      />

      <DarkMode
        allowed={ui.theme.allowDarkMode}
        className="fw3"
        enabled={ui.theme.isDarkModeEnabled ?? ui.theme.systemPrefersDark}
      >
        <ErrorBoundary title="AppContainer">
          <AppContainer routes={routes}>
            <Helmet
              defaultTitle="Employer Platform"
              htmlAttributes={{ lang: 'en' }}
              titleTemplate="Shiftsmart - %s"
            />
            <div className={styles.content}>{children}</div>
          </AppContainer>
        </ErrorBoundary>
        <LoadingModal />
        <AgendaProgressLoadingModal
          agendaJobProgressObj={agendaJobProgress.selected}
          isOpen={ui.agendaProgressLoadingModal.isOpen}
          message={ui.agendaProgressLoadingModal.message}
        />
      </DarkMode>
    </MatchMediaProvider>
  );
});

AppLayout.displayName = 'AppLayout';
export default AppLayout;
