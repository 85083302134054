import type { StoreName, StoreType } from '#/shared/stores';
import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import { dispatch } from 'rfx-core';
import {
  StrictDropdownProps,
  DropdownItemProps,
  DropdownProps,
} from 'semantic-ui-react';
import _ from 'lodash';
import cx from 'classnames';
import { ObservableValue } from 'mobx/lib/internal';
import { ReactNodeLike } from 'prop-types';

import BaseStore from '#/shared/stores/_baseStore';
import { getChildLogger } from '#/shared/utils/client.logger';
import { Dropdown } from '#/ssm-ui/semantic';

const log = getChildLogger('utils.BaseSelect');
export interface IBaseSelectProps<
  K extends StoreName,
  S extends BaseStore<IBaseItem> = K extends StoreName
    ? StoreType[K]
    : BaseStore<IBaseItem>,
  T = S['selected'],
> extends Omit<StrictDropdownProps, 'clearable'> {
  clearable?: boolean | 'native' | 'legacy';
  content?: DropdownItemProps['content'];
  ctrClass?: string;
  ddlAs?: typeof React.Component | React.FC;
  description?: DropdownItemProps['description'] &
    ((item: DropdownItemProps) => string | React.ReactElement);

  doNativeSelect?: boolean;
  dropDownIcon?: DropdownProps['icon'];
  labelKey?: string;
  list?: S['list'] | Array<S['selected']>;
  noListOnEmptySearch?: boolean;
  onBlur?: () => void;
  onClear?: () => void;
  onClick?: () => void;
  onSelect?: (val?: T) => void;

  open?: boolean;

  searchValue?: ObservableValue<string> | string;
  selected?: T;

  selectedValues?: Array<T>;

  storeName: K;
}

type OnSelectCallback<T> = (
  e: SyntheticEvent,
  { value }: { value: IBaseSelectOption<T> | T },
) => void;

interface IBaseSelectOption<T> extends DropdownItemProps {
  extra: T | IBaseSelectOption<T>;

  onSelect?: OnSelectCallback<T>;
}

const BaseSelect = observer(
  <
    K extends StoreName,
    S extends BaseStore<IBaseItem> = K extends StoreName
      ? StoreType[K]
      : BaseStore<IBaseItem>,
    T = S['selected'],
  >({
    storeName,
    trigger,
    search = _.identity,
    searchValue,
    list,
    selected,
    selectedValues,
    className,
    ctrClass,
    onFocus: onFocusFn = () => _.isEmpty(list) && dispatch(`${storeName}.find`),
    onSearchChange: onSearchChangeFn,
    onSelect,
    onClear,
    content,
    description,
    text,
    open,
    onClick,
    onBlur,
    title, // TODO: `title` does not exist on DropdownItemProps; did we mean `header`? If so, we'll need to change the behavior here
    placeholder,
    multiple = false,
    selectOnBlur = false,
    doNativeSelect = true,
    disabled,
    clearable = true,
    direction,
    labelKey,
    as,
    ddlAs,
    dropDownIcon = 'dropdown',
    loading,
    onOpen = _.noop,
    onClose = _.noop,
  }: IBaseSelectProps<K, S, T>) => {
    const instanceLog = getChildLogger(`utils.BaseSelect.${storeName}`);

    const rawSelected = optionIsEmpty(selected) ? null : selected;
    const rawSelectedValues = _.reject(
      selectedValues || [],
      (o) => optionIsEmpty(o) || !_.isString(o),
    );

    const options = getDropdownOptions<T>({
      content,
      description,
      labelKey,
      list,
      selected: rawSelected,
      selectedValues: rawSelectedValues,
      text,
      title,
    });

    instanceLog.silly(
      `${storeName} Curent Selection: "%s"`,
      rawSelected ? getLabel(rawSelected, labelKey) : 'null',
      {
        rawSelected,
        rawSelectedValues,
      },
    );

    let value = _.get(rawSelected, 'uuid', rawSelected);

    if (!_.isUndefined(selectedValues) && !multiple) {
      instanceLog.error(
        `${storeName} Selected Values passed, but multiple is not enabled`,
      );
    }

    if (multiple) {
      value = _(rawSelectedValues)
        .map((item) => _.get(item, 'uuid', item))
        .compact()
        .value();
    } else if (optionIsEmpty(value)) {
      value = null;
    }

    instanceLog.silly(
      `${storeName} Selected Value (should be UUID if item(s) is selected)`,
      { options, value },
    );

    const DDL = ddlAs ?? Dropdown;

    const onChange = onSearchSelect({ doNativeSelect, onSelect, storeName });

    const onPressClear = clearSelected({ doNativeSelect, onClear, storeName });

    const elements = (
      <>
        <DDL
          attached="left"
          className={className}
          clearable={!!clearable}
          direction={direction}
          disabled={disabled}
          fluid={true}
          icon={dropDownIcon}
          loading={loading}
          multiple={multiple}
          onBlur={onBlur}
          onChange={(
            e,
            {
              value: val,
              options: opts,
            }: {
              options: Array<IBaseSelectOption<T>>;
              value?: IBaseSelectOption<T>['value'];
            },
          ) => {
            if (!_.isEmpty(val)) {
              // TODO: This functionality has not been updated for for `multiple` select'
              const selectedOption = (
                _.find(opts, { value: val }) as IBaseSelectOption<T>
              )?.extra;

              log.info('selected', selectedOption);

              if (
                _.isFunction((selectedOption as IBaseSelectOption<T>)?.onSelect)
              ) {
                (selectedOption as IBaseSelectOption<T>).onSelect(e, {
                  value: selectedOption,
                });
              } else {
                onChange(e, { value: val });
              }
            } else {
              onPressClear();
            }
          }}
          onClick={onClick}
          onClose={onClose}
          onFocus={onFocusFn}
          onOpen={onOpen}
          onSearchChange={
            _.isFunction(onSearchChangeFn)
              ? onSearchChangeFn
              : onSearchChange({ storeName })
          }
          open={open}
          options={options}
          placeholder={placeholder}
          search={search}
          searchQuery={searchValue as string}
          selectOnBlur={selectOnBlur}
          selectOnNavigation={false}
          selection={true}
          trigger={trigger}
          value={value}
        />
      </>
    );

    if (as) {
      const Component = as;
      return <Component className={ctrClass}>{elements}</Component>;
    }

    return (
      <div className={cx('flex justify-between', ctrClass || 'w-100')}>
        {elements}
      </div>
    );
  },
);

BaseSelect.displayName = 'BaseSelect';

export default BaseSelect;

declare type DDLItemPropSpec =
  | ReactNodeLike
  | ((
      option: DropdownItemProps,
      index: number,
      options: Array<DropdownItemProps>,
    ) => ReactNodeLike);

/**
 * ### getOptions
 * Given a list of options, as well as either a selected value or a list of selected values, returns an array of
 * options compatible with the DropdownProps['options'] prop. The notable, non-standard value on the options is
 * an `extra` property which contains the full input object. EG: If an `IUser` object is passed in, the returned
 * option (in the list) will have `extra: IUser`.
 *
 * The `text`, `content` and `description` properties may have thier values defined either by a string or a function,
 * specified either gloablly as a property on the BaseSelect (or derived) component, or specified individually
 * on the passed in list options. The latter approach is useful when specifying custom, or "auxOptions" based DDL
 * list items.
 */
export function getDropdownOptions<T>({
  list,
  selected,
  selectedValues,
  labelKey,
  ...baseOptions
}: {
  content?: DDLItemPropSpec;
  description?: DDLItemPropSpec;
  labelKey?: string;
  list: Array<DropdownItemProps>;
  selected?: string | T;
  // todo rectify selected option type
  selectedValues?: Array<string | T>;
  text?: DDLItemPropSpec;
  title?: DDLItemPropSpec;
}): Array<
  IBaseSelectOption<T> & {
    content?: string | Element;
    description?: string | Element;
    extra: DropdownItemProps;
    key: string;
    text: string;
    title?: string | Element;
    value: string;
  }
> {
  function getOptionKey(key, option, ...rest) {
    const root = option[key] ?? baseOptions[key];

    if (_.isFunction(root)) {
      return root(option, ...rest);
    }
    if (_.isString(root)) {
      return root;
    }

    return root ?? undefined;
  }

  function expandOption(option, ...rest) {
    const text =
      getOptionKey('text', option, ...rest) ?? getLabel(option, labelKey);
    return {
      content: getOptionKey('content', option, ...rest),
      description: getOptionKey('description', option, ...rest),
      extra: option,
      key: option.uuid ?? option.value,
      text,
      title: getOptionKey('title', option, ...rest) ?? text,
      value: option.uuid ?? option.value,
    };
  }

  return _(list)
    .unionBy(
      [!_.isString(selected) && selected, ...selectedValues],
      (o) => _.find([o?.uuid, o?.value], _.identity) || '',
    )
    .reject(optionIsEmpty)
    .map((option, index, options) => {
      const retval = expandOption(option, index, options);

      if (!retval.content) {
        retval.content = <div className="ph2">{retval.text}</div>;
      }

      return retval;
    })
    .value();
}

function onSearchChange({ storeName }) {
  return (e, { searchQuery }) => {
    dispatch(`${storeName}.search`, searchQuery);
  };
}

function onSearchSelect<S extends StoreName>({
  storeName,
  onSelect,
  doNativeSelect,
}: {
  doNativeSelect: IBaseSelectProps<S>['doNativeSelect'];
  onSelect: IBaseSelectProps<S>['onSelect'];
  storeName: S;
}) {
  return async (e, { value }) => {
    const val = _.isArray(value)
      ? (await dispatch(`${storeName}.runQuery`, { uuid: { $in: value } })).data
      : await dispatch(`${storeName}.get`, value, { select: doNativeSelect });
    await dispatch(`${storeName}.clearSearch`);

    if (_.isFunction(onSelect)) {
      onSelect(val);
    }
  };
}

function clearSelected({ storeName, onClear, doNativeSelect }) {
  return async () => {
    if (doNativeSelect) {
      await dispatch(`${storeName}.clearSelected`);
    }

    if (_.isFunction(onClear)) {
      onClear();
    }
  };
}

function getLabel(val, labelKey) {
  try {
    return _.toString(
      (!!labelKey && _.get(val, labelKey)) ||
        val.name ||
        val.title ||
        val.displayName ||
        val.label ||
        val,
    );
  } catch (err) {
    return _.isEmpty(val) ? 'unknown' : _.get(val, 'uuid') || _.toString(val);
  }
}

function optionIsEmpty(o) {
  return (
    _.isEmpty(o) ||
    (_.isObject(o) && 'uuid' in o && _.isEmpty((o as IBaseItem).uuid)) ||
    (_.isObject(o) && _(o).omitBy(_.isEmpty).isEmpty())
  );
}
