import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import { observable, action } from 'mobx';

import BaseStore from './_baseStore';

export type ITraining = IBaseItem & Record<string, unknown>;

export default class TrainingStore extends BaseStore<ITraining> {
  // TODO: Refactor to init fields using the baseStore constructor
  static BASE_ITEM = {
    company: null,
    restrictions: {
      pools: [],
      tags: [],
    },
    status: null,
    surveyDefinition: null,
    title: null,
    uuid: null,
  };

  serviceName = 'trainings';

  searchFields = ['title'];

  baseItem = TrainingStore.BASE_ITEM;

  @observable
  filter = { status: 'all' };

  /* ACTIONS */

  @action
  filterBy(filter, { param = 'status', noQuery = false } = {}) {
    this.filter[param] = filter;

    const query = {};

    if (param === 'status') {
      switch (filter) {
        case 'draft':
          query.status = 'draft';
          break;
        case 'active':
          query.status = 'active';
          break;
        case 'closed':
          query.status = 'closed';
          break;
        case 'all':
          query.status = undefined;
          break;
        default:
          query.status = filter;
          break;
      }
    }

    if (noQuery) {
      return Promise.resolve();
    }

    return this.find({ query });
  }
}
