import type { IUser } from '@shiftsmartinc/shiftsmart-types';

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import _ from 'lodash';
import { Image, Popup } from 'semantic-ui-react';

import Names from '#/shared/components/utils/Names';
import UserInitials from '#/shared/components/ssm/users/UserInitials';
import { getChildLogger } from '#/shared/utils/client.logger';
import { useCachedUserItem } from '#/shared/hooks/useCacheItem';

const log = getChildLogger('utils.Avatar');

type AvatarPropTypes = {
  align: string;
  className: string;
  color: string;
  image: string;
  imgStyle: unknown;
  inline: boolean;
  isOnMap: boolean;
  nameOnly: boolean;
  popup: boolean;
  profileImage: string;
  showName: boolean;
  size: string;
  user: IUser;
  userId: string;
};

const Avatar = observer<React.FC<AvatarPropTypes>>(
  ({
    align = 'tc',
    className,
    color,
    image,
    imgStyle,
    inline,
    isOnMap,
    nameOnly,
    popup,
    profileImage,
    showName,
    size,
    user,
    userId,
  }) => {
    log.verbose('Initializing Avatar');

    const { item: userObject } = useCachedUserItem({
      user: user,
      userId: userId,
    });

    const [sizeIncrement, setSizeIncrement] = useState('small');
    const [imgClass, setImgClass] = useState('');
    const [ctrClass, setCtrClass] = useState('');

    const getImgClass = () => {
      return inline
        ? cx('dib center grow br-100', size || 'h2 w2')
        : cx('dib center', size || 'w3 h3');
    };

    const getContainerClass = () => {
      return cx(inline ? 'dib fl' : '', className, align);
    };

    const checkIconSize = () => {
      setImgClass(
        inline
          ? cx('dib center grow br-100', size || 'h2 w2')
          : cx('dib center', size || 'w3 h3'),
      );
      setCtrClass(cx(inline ? 'dib fl' : '', className, align));

      if (/h1 w1|w1 h1/i.test(size)) {
        setSizeIncrement('mini');
      } else if (/h2 w2|w2 h2/i.test(size)) {
        setSizeIncrement(undefined);
      } else if (/h3 w3|w3 h3/i.test(size)) {
        setSizeIncrement('big');
      } else if (/h4 w4|w4 h4/i.test(size)) {
        setSizeIncrement('huge');
      } else if (/mini|tiny|small|large|big|huge|massive/i.test(size)) {
        setSizeIncrement(_.toLower(size));
      }
    };

    useEffect(() => {
      checkIconSize();
    }, [imgClass, ctrClass, sizeIncrement]);

    useEffect(() => {
      setCtrClass(getContainerClass());
    }, [ctrClass]);

    useEffect(() => {
      setImgClass(getImgClass());
    }, [imgClass]);

    useEffect(() => {
      setSizeIncrement(getSizeIncrement(size));
    }, [sizeIncrement]);

    const namesClass = cx('db mt2', inline ? 'ml2' : '');

    if (nameOnly) {
      return <Names user={userObject} />;
    }

    const name = _.get(userObject, 'displayName') || _.get(user, 'displayName');

    log.verbose(`Rendering Avatar for ${name}`, {
      user: user,
      userId: userId,
      userObject: userObject,
    });

    const defaultProfilePic = '/static/img/defaultProfilePic.png';
    const profileImageURL =
      image ||
      profileImage ||
      _.get(userObject, 'profileImageURL') ||
      _.get(userObject, 'imageURL') ||
      defaultProfilePic;

    const imageStyle =
      isOnMap || (color && profileImageURL === defaultProfilePic)
        ? {
            backgroundColor: color || 'blue',
            border: `1px solid ${color || 'blue'}`,
          }
        : {
            backgroundColor: 'white',
            border: 'none',
          };

    if (defaultProfilePic === profileImageURL && !_.has(imgStyle, 'border')) {
      imageStyle.border = '1px solid rgba(42, 108, 196, 0.5)';
    }

    const content = (
      <div className={cx('avatar_xx h-100 flex-shrink-0', ctrClass)}>
        <div
          className={cx('dib br-100 h-100 avatar_xy overflow-hidden')}
          style={
            _.get(imgStyle, 'border') && {
              border: '1px solid rgba(42, 108, 196, 0.5)',
            }
          }
        >
          {profileImageURL === defaultProfilePic ? (
            <UserInitials
              user={userObject}
              userId={
                _.get(userObject, 'uuid') || _.get(user, 'uuid') || userId
              }
              {...{ className: cx(imgClass) }}
              fontClass={!sizeIncrement ? 'f5' : 'f2'}
            />
          ) : (
            <Image
              alt={_.get(this, 'userObject.profileImageURL', 'Profile Image')}
              circular={true}
              className={imgClass}
              src={profileImageURL}
              style={_.extend(
                { flexShrink: 0, objectFit: 'cover' },
                imageStyle,
                imgStyle,
              )}
            />
          )}
        </div>
        {showName && userObject && (
          <div
            className={cx(!!inline && 'dib ml2 v-top')}
            style={inline ? { lineHeight: '2rem' } : {}}
          >
            <Names
              className={namesClass}
              user={userObject}
              userId={_.get(userObject, 'uuid') || userId}
            />
          </div>
        )}
      </div>
    );

    if (popup) {
      return (
        <Popup
          content={<Names className={namesClass} user={userObject} />}
          trigger={content}
        />
      );
    }

    return content;
  },
);

Avatar.displayName = 'Avatar';

export default Avatar;

function getSizeIncrement(size) {
  if (/mini|tiny|small|large|big|huge|massive/i.test(size)) {
    return _.toLower(size);
  }
  if (/h1 w1|w1 h1/i.test(size)) {
    return 'small';
  }
  if (/h2 w2|w2 h2/i.test(size)) {
    return undefined;
  }
  if (/h3 w3|w3 h3/i.test(size)) {
    return 'big';
  }
  if (/h4 w4|w4 h4/i.test(size)) {
    return 'huge';
  }

  return undefined;
}

export { getSizeIncrement };
