import { action, observable, runInAction } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { CompanyFormInstance, init as initForm } from '#/shared/forms/company';
import { getChildLogger } from '#/shared/utils/client.logger';

export default class CompanyEditModal {
  log = getChildLogger('ui.CompanyEditModals');

  @observable
  showMessagingServicePage = false;

  @observable
  showAreaCodeInputMessaging = false;

  @observable
  showAreaCodeInputConversations = false;

  @observable
  areaCodeMessaging = '';

  @observable
  areaCodeConversations = '';

  @observable
  showSuccessMessaging = false;

  @observable
  showSuccessConversations = false;

  @observable
  isOpen = false;

  @observable
  company = {};

  @observable
  owner = {};

  @observable
  parent = {};

  @observable
  form: CompanyFormInstance;

  @action.bound
  async setup({
    company = {},
    parent,
    open = this.isOpen,
    owner = company.owner,
    /** Whether to redirect to the company details page after saving. By default, only true for new companies */
    redirect = !company?.uuid,
    afterFinish,
  }) {
    this.company = company;

    const parentIDorObj =
      parent || (company.path || '').split('/').slice(0, -1).pop();

    await Promise.all([this.setParent(parentIDorObj), this.setOwner(owner)]);

    runInAction(() => {
      this.form = initForm(this.company);
      this.form.setShouldRedirect(redirect);
      this.form.setAfterFinish(afterFinish);

      this.isOpen = open;
    });
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  async setOwner(user) {
    const owner =
      _.isString(user) && !_.isEmpty(user)
        ? await dispatch('users.get', user, { select: false })
        : user;

    runInAction(() => {
      this.owner = owner || {};
    });
  }

  @action
  async setParent(company) {
    const parent =
      _.isString(company) && !_.isEmpty(company)
        ? await dispatch('companies.get', company, { select: false })
        : company;

    runInAction(() => {
      this.parent = parent || {};
    });
  }

  @action
  createCompany() {
    // create new company
    this.form.submit();

    // display messaging service page
    this.showMessagingServicePage = true;
  }

  @action
  toggleMessagingServicePage() {
    this.showMessagingServicePage = !this.showMessagingServicePage;
  }

  @action
  addTwilioMessagingService() {
    // show area code input for messaging
    this.showAreaCodeInputMessaging = true;
  }

  @action
  addConversationsMessagingService() {
    // show area code input for conversations
    this.showAreaCodeInputConversations = true;
  }

  @action
  cancelTwilioMessagingService() {
    // hide area code input for messaging
    this.showAreaCodeInputMessaging = false;
  }

  @action
  cancelConversationsMessagingService() {
    // hide area code input for messaging
    this.showAreaCodeInputConversations = false;
  }

  @action
  setAreaCodeMessaging(areaCode) {
    this.areaCodeMessaging = areaCode;
  }

  @action
  setAreaCodeConversations(areaCode) {
    this.areaCodeConversations = areaCode;
  }

  @action
  setCompany(company) {
    this.company = company;
  }

  @action
  createMessagingService = async () => {
    const log = this.log.getChildLogger('createMessagingService');
    // check that area code is not empty
    if (_.isEmpty(this.areaCodeMessaging)) {
      dispatch(
        'ui.snackBar.error',
        'This field cannot be empty. Please enter an area code.',
      );
      return;
    }

    // check that area code contains only numbers
    const isValid = /^\d+$/.test(this.areaCodeMessaging);
    if (!isValid) {
      dispatch(
        'ui.snackBar.error',
        'This field can only contain numbers. Please enter a valid area code.',
      );
      return;
    }

    // check that area code does not exceed 3 numbers
    if (this.areaCodeMessaging.length !== 3) {
      dispatch(
        'ui.snackBar.error',
        'Please enter a valid three-digit area code.',
      );
      return;
    }

    const data = {
      areaCode: this.areaCodeMessaging,
      company: this.company,
      serviceType: 'messaging',
    };

    try {
      // create messaging service
      await dispatch('twilioMessaging.create', { data });

      // display success snackbar
      dispatch(
        'ui.snackBar.open',
        `Successfully created Twilio Messaging Service`,
      );

      // close area code input
      this.messagingServiceSuccess();
    } catch (err) {
      log.error('Failed to create Messaging Service: ', err);
      dispatch('ui.snackBar.error', err.message);
    }
  };

  @action
  createConversationsService = async () => {
    const log = this.log.getChildLogger('createConversationsService');
    // check that area code is not empty
    if (_.isEmpty(this.areaCodeConversations)) {
      dispatch(
        'ui.snackBar.error',
        'This field cannot be empty. Please enter an area code.',
      );
      return;
    }

    // check that area code contains only numbers
    const isValid = /^\d+$/.test(this.areaCodeConversations);
    if (!isValid) {
      dispatch(
        'ui.snackBar.error',
        'This field can only contain numbers. Please enter a valid area code.',
      );
      return;
    }

    // check that area code does not exceed 3 numbers
    if (this.areaCodeConversations.length !== 3) {
      dispatch(
        'ui.snackBar.error',
        'Please enter a valid three-digit area code.',
      );
      return;
    }

    const data = {
      areaCode: this.areaCodeConversations,
      company: this.company,
      serviceType: 'conversations',
    };

    try {
      // create messaging service
      await dispatch('twilioMessaging.create', { data });

      // display success snackbar
      dispatch(
        'ui.snackBar.open',
        `Successfully created Conversations Messaging Service`,
      );

      // close area code input
      this.conversationsServiceSuccess();
    } catch (err) {
      log.error('Failed to create Conversations Service: ', err);
      dispatch('ui.snackBar.error', err.message);
    }
  };

  @action
  conversationsServiceSuccess() {
    // hide input for area code
    this.showAreaCodeInputConversations = false;

    // display success message instead of button
    this.showSuccessConversations = true;
  }

  @action
  messagingServiceSuccess() {
    // hide input for area code
    this.showAreaCodeInputMessaging = false;

    // display success message instead of button
    this.showSuccessMessaging = true;
  }

  @action
  clear() {
    // close modal
    this.isOpen = false;

    this.company = {};
    this.parent = {};
    this.owner = {};

    // prevent messaging service page from appearing when modal reopens
    this.showMessagingServicePage = false;

    // clear input fields and success messages
    this.showAreaCodeInputMessaging = false;
    this.showAreaCodeInputConversations = false;
    this.showSuccessMessaging = false;
    this.showSuccessConversations = false;

    this.form?.clear?.();
    this.form = null;
  }
}
