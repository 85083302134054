import type {
  IInShiftTaskItem,
  IPoint,
  Timestamp,
} from '@shiftsmartinc/shiftsmart-types';

import distance from '@turf/distance';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Icon } from 'semantic-ui-react';

export const taskStatusDisplayMap = {
  imageNotSubmitted: {
    color: '#D5553A',
    icon: <Icon color="red" name="exclamation triangle" />,
  },
  imageNotValid: {
    color: '#D5553A',
    icon: <Icon color="red" name="exclamation triangle" />,
  },
  invalidTaskLocation: {
    color: '#D5553A',
    icon: <Icon color="red" name="exclamation triangle" />,
  },
  invalidTaskTime: {
    color: '#D5553A',
    icon: <Icon color="red" name="exclamation triangle" />,
  },
  longTaskTime: {
    color: '#D5553A',
    icon: <Icon color="red" name="exclamation triangle" />,
  },
  taskCompleted: {
    color: '#4FA778',
    icon: <Icon color="green" name="check circle" />,
  },
  taskInProgress: {
    color: '#000000',
    icon: <Icon name="hourglass end" style={{ color: '#6D6D6D' }} />,
  },
  taskNotStarted: {
    color: '#000000',
    icon: <Icon name="exclamation triangle" style={{ color: '#6D6D6D' }} />,
  },
  template: {
    color: '#000000',
    icon: <Icon name="edit outline" style={{ color: '#6D6D6D' }} />,
  },
};

export const MIN_EXTRA_TIME_FOR_LONG_TASK = 10 * 60; // 10 minutes -> seconds
export const EPSILON_FOR_DISTANCE = 1000; // feet
export const ERROR_STATUSES = [
  'imageNotSubmitted',
  'imageNotValid',
  'invalidTaskLocation',
  'invalidTaskTime',
  'longTaskTime',
];

export const isTaskPreImageSubmitted = (selectedTask: IInShiftTaskItem) => {
  const { taskResultObj: taskResult = {} } = selectedTask;
  const preImage = taskResult?.preImage ?? {};
  return !_.isNil(preImage?.imageURL);
};

export const isTaskPostImageSubmitted = (selectedTask: IInShiftTaskItem) => {
  const { taskResultObj: taskResult = {} } = selectedTask;
  const postImage = taskResult?.postImage ?? {};
  return !_.isNil(postImage?.imageURL);
};

export const isTaskStarted = (selectedTask: IInShiftTaskItem) => {
  const { taskResultObj: taskResult = {} } = selectedTask;
  const start = taskResult?.start ?? {};
  return !_.isNil(start?.timestamp) && !_.isNil(start?.loc);
};

export const isTaskEnded = (selectedTask: IInShiftTaskItem) => {
  const { taskResultObj: taskResult = {} } = selectedTask;
  const end = taskResult?.end ?? {};
  return !_.isNil(end?.timestamp) && !_.isNil(end?.loc);
};

export const taskHasUserAssigned = (selectedTask: IInShiftTaskItem) => {
  return !_.isNil(selectedTask?.assignedUser);
};

export const isLocationValid = (
  shiftLocation?: IPoint,
  resultLocation?: IPoint,
) => {
  if (!shiftLocation || !resultLocation) return false;
  return Boolean(
    distance(shiftLocation, resultLocation, {
      units: 'feet',
    }) <= EPSILON_FOR_DISTANCE,
  );
};

export const isTimeValid = (
  shiftStart: Timestamp,
  shiftEnd: Timestamp,
  timeToCheck: Timestamp,
) => {
  return moment(timeToCheck).isBetween(moment(shiftStart), moment(shiftEnd));
};

export const isImageValid = (
  selectedTask: IInShiftTaskItem,
  timeToCheck: Timestamp,
  locationToCheck: IPoint,
  imageType: 'pre' | 'post',
) => {
  const { shiftObj = {}, taskResultObj = {} } = selectedTask;
  const shiftStartBound = moment(shiftObj?.start).subtract(1, 'hours').toDate();

  const shiftEndBound = moment(shiftObj?.end).add(1, 'hours').toDate();

  if (imageType === 'post' && !!taskResultObj?.postImage?.imageAccepted)
    return true;
  else if (imageType === 'pre' && !!taskResultObj?.preImage?.imageAccepted)
    return true;
  else {
    return (
      isTimeValid(shiftStartBound, shiftEndBound, timeToCheck) &&
      isLocationValid(selectedTask.addressObj.loc, locationToCheck)
    );
  }
};

export const taskStatus = (selectedTask: IInShiftTaskItem) => {
  const {
    preImageUploadRequired,
    postImageUploadRequired,
    templateId,
    taskResultObj: taskResult,
  } = selectedTask;

  const shiftStartBound = moment(selectedTask?.shiftObj?.start)
    .subtract(1, 'hours')
    .toDate();

  const shiftEndBound = moment(selectedTask?.shiftObj?.end)
    .add(1, 'hours')
    .toDate();
  const isTemplate = !_.isNil(templateId);
  if (isTemplate) return 'template';
  else if (!isTaskStarted(selectedTask)) return 'taskNotStarted';
  else if (
    isTaskStarted(selectedTask) &&
    ((preImageUploadRequired && !isTaskPreImageSubmitted(selectedTask)) ||
      (postImageUploadRequired && !isTaskPostImageSubmitted(selectedTask)))
  )
    return 'imageNotSubmitted';
  else if (isTaskStarted(selectedTask) && isTaskEnded(selectedTask)) {
    if (
      selectedTask?.expectedTimeInMinutes &&
      moment(taskResult.end.timestamp).diff(
        moment(taskResult.start.timestamp),
        'seconds',
      ) >=
        selectedTask.expectedTimeInMinutes + MIN_EXTRA_TIME_FOR_LONG_TASK
    ) {
      return 'longTaskTime';
    } else if (
      !isTimeValid(
        shiftStartBound,
        shiftEndBound,
        taskResult?.start?.timestamp,
      ) ||
      !isTimeValid(shiftStartBound, shiftEndBound, taskResult?.end?.timestamp)
    ) {
      return 'invalidTaskTime';
    } else if (
      !isLocationValid(selectedTask?.addressObj?.loc, taskResult.start.loc) ||
      !isLocationValid(selectedTask?.addressObj?.loc, taskResult.end.loc)
    ) {
      return 'invalidTaskLocation';
    } else if (
      (preImageUploadRequired &&
        !isImageValid(
          selectedTask,
          taskResult.preImage.imageTimestamp,
          taskResult.preImage.imageLocation,
          'pre',
        )) ||
      (postImageUploadRequired &&
        !isImageValid(
          selectedTask,
          taskResult.postImage.imageTimestamp,
          taskResult.postImage.imageLocation,
          'post',
        ))
    ) {
      return 'imageNotValid';
    } else {
      return 'taskCompleted';
    }
  }
  return 'taskInProgress';
};
