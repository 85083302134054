import BaseStore from './_baseStore';

/*
 * NOTE:  References Payments Service as a way to implement custom methods
 *  @external https://feathersjs.com/api/client/rest.html#custom-methods-1
 */

type ProcessProvisionalApprovalsJobArgs = {
  companyId: string;
  date: string | Date;
};

export default class PaymentsStore extends BaseStore<null> {
  constructor({ serviceName = 'payments' } = {}) {
    super({
      baseItem: null,
      serviceName,
    });

    return this;
  }

  async scheduleAutoprocessingJob({
    date,
    companyId,
  }: ProcessProvisionalApprovalsJobArgs): Promise<{ message: string }> {
    return this.create({
      data: { companyId, date },
      params: { query: { $client: { processProvisionalApprovals: true } } },
    });
  }
}
