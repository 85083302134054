import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { mapUnifiedToSeparate } from '#/shared/forms/formUtils';
import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';

const log = getChildLogger('forms.notification');

export class NotificationForm extends Form {
  hooks = () => ({
    onSuccess(form) {
      const storeName = 'notifications';
      const action = `${storeName}.${
        _.isEmpty(form.values().uuid) ? 'create' : 'update'
      }`;

      if (_.isEmpty(form.values().category)) {
        form.$('category').set('admin');
      }
      if (
        _.isEmpty(form.values().deliveryStatus) ||
        form.values().deliveryStatus === 'draft'
      ) {
        form.$('deliveryStatus').set('pending');
      }

      dispatch(action, {
        data: form.values(),
      })
        .then((res) => {
          dispatch(
            `notifications.${action === 'update' ? 'onUpdated' : 'onCreated'}`,
            res,
          );
          return dispatch('ui.notificationDetailsModal.success', res);
        })
        .then(() => dispatch('ui.notificationDetailsModal.clear'))
        .then(() => dispatch('ui.snackBar.open', 'Notification Scheduled'))
        .then(() => form.clear())
        .catch((err) => {
          log.error('Failed to save notification', err);
          form.invalidate(err.message);
          dispatch('ui.snackBar.open', err.message);
        });
    },
  });
}

// NOTE: "Unified" field definitions are not well supported. If defining a form
// in this manner, you MUST use the mapUnifiedToSeparate utility and pass them into
// the constructor in that way.
export const fields = {
  body: {
    label: 'Message Body',
    rules: 'required|string',
  },
  category: {
    label: 'Category',
  },
  deliveryStatus: {
    label: 'Delivery Status',
  },
  method: {
    rules: 'string',
  },
  notificationType: {
    rules: 'string',
  },
  scheduleFor: {
    label: 'Delivery Schedule',
  },
  user: {
    rules: 'required|string',
  },
  uuid: {
    rules: 'string',
  },
};

export function init(values = {}) {
  return new NotificationForm({ ...mapUnifiedToSeparate(fields), values });
}
