import type { IWorker } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import flatten from 'flat';

import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';
import {
  legacyFields as addrFields,
  rules as addrRules,
  labels as addrLabels,
} from './addressFields';

const log = getChildLogger('forms.worker');

class WorkerForm extends Form {
  hooks = () => ({
    async onSuccess(form) {
      const uuid = form.initials().uuid || undefined;
      const csrCompany = form.initials().csrCompany || undefined;
      const data = form.values();
      const storeName = form.values().storeName || 'partners';
      // Get sendInvite value when not dirty (not in form.values())
      const sendInvite = _.has(data, 'sendInvite')
        ? data.sendInvite
        : form.initials().sendInvite;

      let storeAction = `${storeName}.create`;
      let successMsg = 'New Worker Created';
      let isNew = true;

      if (uuid) {
        storeAction = `${storeName}.update`;
        successMsg = 'Worker Saved';
        isNew = false;

        this.removePristineNestedFields(data, null, { mutate: true });
      } else if (sendInvite) {
        // Is creating new partner
        storeAction = `${storeName}.sendInvites`;
      }

      if (data.phoneNumber) {
        data.phoneNumber = data.phoneNumber.replace(/[^0-9+]/g, '');
      }

      if (!_.isEmpty(data.positions)) {
        const certs = await Promise.map(data.positions, (p) =>
          dispatch('positions.get', p),
        );
        delete data.positions;
        _.extend(data, { certs });
      }

      if (data.localeInfo) {
        if (_.get(data, 'localeInfo.defaultCurrency')) {
          _.set(
            data.localeInfo,
            'defaultCurrency',
            data.localeInfo.defaultCurrency.toLowerCase(),
          );
        }

        const localeInfo = flatten(_.pick(data, 'localeInfo'));
        delete data.localeInfo;
        _.extend(data, localeInfo);
      }

      if (_.has(data, 'firstName') || _.has(data, 'lastName')) {
        data.displayName = `${_.get(
          data,
          'firstName',
          form.initials().firstName,
        )} ${_.get(data, 'lastName', form.initials().lastName)}`;
      }

      if (_.get(data, 'address', false)) {
        data.loc = data.address.loc || undefined;
        data.addressString = data.address.formattedAddress;
        let address = _.pick(data.address, [
          'street1',
          'street2',
          'city',
          'state',
          'zip',
        ]);
        address = flatten({ address });
        delete data.address;
        _.extend(data, address);
      }

      let query;
      if ((data.externalId || data.externalId === '') && csrCompany) {
        const csrPatchData = {
          'companyStatus.$.externalId': data.externalId,
          uuid,
        };
        query = { companyStatus: { $elemMatch: { company: csrCompany } } };
        _.extend(data, csrPatchData);
        delete data.externalId;
      }

      if (_.isEmpty(data)) {
        log.debug('No changes to save ...');
        dispatch('ui.snackBar.open', 'No changes made');
        dispatch('ui.workerEditModal.open', false);
        form.clear();
        return;
      }

      log.debug(`Calling "${storeAction}" with values: `, data);
      try {
        const res = await dispatch(storeAction, { data, id: uuid, query });

        if (isNew) {
          dispatch(`${storeName}.setSelected`, { worker: res });
        }

        dispatch('ui.workerEditModal.open', false);
        dispatch('ui.snackBar.open', successMsg);
        form.clear();
      } catch (err) {
        let message = err.message.replace(/"/g, '');
        log.error('Worker Form saving failed: ', err);
        if (err.code === 409) {
          message = `User with ${message}`;
        }
        form.invalidate(message);
        dispatch('ui.snackBar.open', message);

        if (!_.isEmpty(err.errors)) {
          form.each((field) => {
            if (_.isEmpty(err.errors[field.key])) {
              field.validate();
            }

            field.invalidate();
          });
        }
      }
    },
  });
}

const fields = _.union(
  [
    'uuid',
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
    'address',
    'companies',
    'positions',
    'sendInvite',
    'storeName',
    'localeInfo.country',
    'localeInfo.defaultCurrency',
    'externalId',
    'csrCompany',
  ],
  addrFields,
);

const rules = _.merge(
  {
    companies: 'array',
    email: 'email|string|between:5,50',
    externalId: 'string',
    firstName: 'required|string|between:2,50',
    lastName: 'required|string|between:2,50',
    'localeInfo.country': 'string|between:2,2',
    'localeInfo.defaultCurrency': 'string|between:3,3',
    phoneNumber: 'required|string',
    uuid: '',
  },
  addrRules,
);

const labels = _.merge(
  {
    address: 'Address',
    companies: 'Companies',
    email: 'Email Address',
    externalId: 'External ID',
    firstName: 'First Name',
    lastName: 'Last Name',
    'localeInfo.country': 'Country',
    'localeInfo.defaultCurrency': 'Currency',
    phoneNumber: 'Mobile Phone',
    positions: 'Roles',
    uuid: '',
  },
  addrLabels,
);

export function init(
  values?: Partial<IWorker>,
  cxOptions?: { addressOptional: false; phoneNumberOptional: false },
) {
  let formRules = rules;
  const { addressOptional, phoneNumberOptional, ...opts } = cxOptions;
  if (addressOptional) {
    formRules = _.mapValues(rules, (value, key) => {
      if (/^address/i.test(key)) {
        return value.replace(/required[|]?/i, '');
      }
      return value;
    });
    log.debug('Striped out "Required" address form rules', formRules);
  }

  if (phoneNumberOptional) {
    formRules.phoneNumber = 'string';
  }

  if (_.isEmpty(_.get(values, 'address.formattedAddress'))) {
    _.set(values, 'address.formattedAddress', values.addressString);
  }

  log.debug('Initialzing Worker form w/ Values: ', values);
  return new WorkerForm(
    { fields, labels, rules: formRules, values },
    { ...opts, options: { retrieveOnlyDirtyValues: !!values.uuid } },
  );
}
