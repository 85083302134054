import type { IRole } from '@shiftsmartinc/shiftsmart-types';

import BaseTagStore from './_baseTagStore';

export default class RoleStore extends BaseTagStore<IRole> {
  constructor() {
    super({
      baseItem: {
        certType: 'role',
        companies: [],
        createdAt: null,
        isVerified: false,
        level: -1,
        linked: [],
        parent: null,
        title: null,
        updatedAt: null,
        users: [],
        uuid: null,
      },
      serviceName: 'roles',
    });

    return this;
  }
}
