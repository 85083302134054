import { action, observable, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

/**
 * Store for UI Modal where user can add a storeList to a store
 *
 * @export
 * @class UpsellModal
 */

export default class UpsellModal {
  log = getChildLogger('ui.UpsellModal');

  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  requestStatus = {};

  @action
  async request({ open = true } = {}) {
    const log = this.log.getChildLogger('request');
    this.isOpen = open;
    this.isLoading = true;

    let requestStatus = {};
    try {
      const newRequest = {
        company: dispatch('auth.getCompany').uuid,
        user: dispatch('auth.getUser').uuid,
      };

      const { data: existing } = await dispatch('interactions.runQuery', {
        query: newRequest,
      });

      if (_.isEmpty(existing)) {
        requestStatus = await dispatch('interactions.create', {
          data: newRequest,
        });
      } else {
        requestStatus = _.last(existing);
        requestStatus.existing = true;
      }
    } catch (err) {
      log.error('Failed to create request status', err);
      requestStatus = {};
    }

    runInAction(() => {
      this.requestStatus = requestStatus;
      this.isLoading = false;
    });
  }

  @action
  clear() {
    this.requestStatus = {};

    this.isOpen = false;
    this.isLoading = false;
  }
}
