/**
 * @description Certificate and Qualification UUIDs
 * Please follow the naming convention, using a prefix of "UUID_CERT_"
 */
export const UUID_CERT_BACKGROUND_CHECK = `f110e618-0f68-433a-af75-bca31120038e`;
export const UUID_CERT_BASIC_CRIMINAL = '2d0ec64c-6b29-40a9-a1d2-a4942c437bf3';
export const UUID_CERT_CIRCLE_K_RFS = '1ae28c82-87bd-4df3-8a0d-8a86fc04dc0e';
export const UUID_CERT_MOTOR_VEHICLE = '3de36ec6-4788-43e6-bd41-ff509afb9de3';
export const UUID_CERT_NEEDS_SCREENING = '0fdc3184-331e-4a10-ac20-6b8e59240897';
// export const UUID_QUAL_XXXXX = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx';

/**
 * @description Company UUIDs
 * Please follow the naming convention, using a prefix of "UUID_" + "COMPANY_NAME"
 */
export const UUID_CIRCLE_K = '6c8724ca-4c6a-46d9-b5fe-e3dc32b4ccf6';
export const UUID_CIRCLE_K_TAG = '5475d529-a0cb-4a62-9d28-e89a6d6b04ef';
export const UUID_CIRCLE_K_JACKSONVILLE =
  'da532ea5-9fed-46cf-a5cc-6dd7721411b6';

export const UUID_EG_AMERICA = 'dfa04ae6-496c-4148-966d-ddb25f468fcd';
export const UUID_GPM = '626bc876-220e-4096-9f40-841ef6edc52f';
export const UUID_MYSTERY_SHOPPING = '335f404a-803a-40d8-a305-188932804d64';
export const UUID_SAMSCLUB_INSPECTIONS = '00ba527c-f7bc-4157-83c4-662b59e7c4e4';

export const UUID_SHIFTSMART = '8ab33b07-fe61-425a-8cda-ec2ee826d305';
export const UUID_CERT_MS_NEEDS_SCREENING = `c039ae9f-d73a-4904-9e30-5e4c7670e227`;
export const UUID_SHIFTSMART_MYSTERY_SHOPPING_SA = `d9acc064-0e2a-440d-9cb2-ffae51b15f30`; // Saudi Arabia

/**
 * @description UUID Groups
 * Please follow the naming convention, using a prefix of "UUID_GROUP_" + "XXX"
 */
export const UUID_GROUP_OPPORTUNITIES = [UUID_CIRCLE_K, UUID_EG_AMERICA];
