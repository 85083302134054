import type { IUser } from '@shiftsmartinc/shiftsmart-types';

import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { useCachedUserItem } from '#/shared/hooks/useCacheItem';

type UserRenderPropTypes = {
  renderUser: (IUser) => ReactElement;
  userObj?: IUser;
  uuid: string;
};

// TODO make reusable for other displayName populated text
const UserRender = observer<React.FC<UserRenderPropTypes>>(
  ({ renderUser, userObj, uuid }) => {
    const { item: user } = useCachedUserItem({
      user: userObj,
      userId: uuid,
    });

    return !_.isEmpty(user) ? renderUser(user) : null;
  },
);

UserRender.displayName = 'UserRender';

export default UserRender;
