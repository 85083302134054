import type {
  IOpportunity,
  IOpportunityOpening,
  ICompany,
  SearchSpecifier,
  ICert,
} from '@shiftsmartinc/shiftsmart-types';
import type { ISearch } from '../searches';

import { action, IObservableArray, observable, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import uuid from 'uuid';
import BluebirdPromise from 'bluebird';

import { init as initOpeningForm } from '#/shared/forms/opportunityOpening';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ui.opportunities');

const createDefaultOpening = (): IOpportunityOpening => ({
  companyId: '',
  companyName: '',
  companyPath: '',
  description: '',
  positionId: '',
  search: {},
  status: 'active',
  uuid: uuid.v4(),
});

const defaultFormErrors = {
  iconName: '',
  openingError: '',
  title: '',
};

export default class Opportunities {
  @observable opportunity: IOpportunity | null;

  @observable isAddingNewOpportunity = false;

  @observable isUpdatingOpportunity = false;

  @observable opportunityTitle: IOpportunity['title'] = '';

  @observable opportunityDescription: IOpportunity['description'] = '';

  @observable opportunityStatus: IOpportunity['status'] = 'active';

  @observable opportunityIcon: IOpportunity['iconName'] = null;

  @observable opening: IOpportunityOpening | null;

  @observable formErrors = defaultFormErrors;

  @observable openings: IObservableArray<IOpportunityOpening> =
    observable.array([]);

  @observable openingsForms = observable.array([]);

  @observable errorMsg = '';

  @observable modalPages = 1;

  @observable currentPage = 1; // default the opportunity details without openings will be the first page

  @observable opportunitySearchCriteria: SearchSpecifier | null;

  @observable
  activeOpening: string = null;

  @action
  getActiveOpening() {
    return this.activeOpening;
  }

  @action
  setActiveOpening(openingId: string) {
    this.activeOpening = openingId;
  }

  @action
  async setup({ opportunity }: { opportunity: IOpportunity }) {
    this.openings = observable.array(_.clone(opportunity?.openings ?? []));
    this.updateOpportunity(opportunity);
    this.openingsForms.clear();

    await this.initOpeningForms();

    const search: Pick<ISearch, 'search'> = {
      search: opportunity.search,
    };

    runInAction(() => {
      this.isUpdatingOpportunity = true;
      this.opportunitySearchCriteria = search.search;
    });

    await dispatch('ui.searches.setup', {
      company: null,
      forceLoadWorkers: true,
      search,
      workerSource: 'workers',
    });
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set<T extends keyof this>(key: T, value: this[T]) {
    _.set(this, key, value);
  }

  @action
  setIcon(value: IOpportunity['iconName'] | null) {
    this.opportunityIcon = value;
    this.formErrors.iconName = '';
  }

  @action
  async cacheCurrentPageSearchCriteria() {
    const { search } = await dispatch('ui.searches.getSaveableSearchData');
    // it's necessary to deep clone this computed value because its nested objects values can change
    const lastSearchCriteriaProgress = _.cloneDeep(search);

    // if switched from opportunity page to a opening page
    if (!this.opening) {
      // save the progress of the opportunity search criteria
      runInAction(() => {
        this.opportunitySearchCriteria = lastSearchCriteriaProgress;
      });
    } else {
      // else save the progress of the opening search criteria before switching
      this.openings.replace(
        this.openings.map((o) => {
          if (!_.isEmpty(this.opening) && this.opening.uuid === o.uuid) {
            return { ...o, search: lastSearchCriteriaProgress };
          }
          return o;
        }),
      );
    }
  }

  @action
  async setOpening(openingId: string | null): Promise<void> {
    if (this.opening?.uuid === openingId) {
      return;
    }

    await this.cacheCurrentPageSearchCriteria();

    // case switched to the opportunity page (first page)
    if (!openingId) {
      this.opening = null;
      await dispatch('ui.searches.setup', {
        company: null,
        forceLoadWorkers: true,
        search: { search: this.opportunitySearchCriteria },
        workerSource: 'workers',
      });
    } else {
      // case switched from any page to a opening page
      this.opening = this.openings.find((o) => o.uuid === openingId) || null;

      await dispatch('ui.searches.setup', {
        company: null,
        loadWorkers: false,
        search: { search: this.opening.search },
        workerSource: 'workers',
      });
      await dispatch(`workers.setCertFilter`, {
        ids: this.opportunitySearchCriteria.tags || [],
        reset: false,
      });
    }
  }

  @action changeOpportunityStatus = () => {
    this.opportunityStatus =
      this.opportunityStatus === 'active' ? 'inactive' : 'active';
  };

  @action changeOpeningStatus = () => {
    this.openingsForms.replace(
      this.openingsForms.map((openingForm) => {
        if (
          !_.isEmpty(this.opening) &&
          openingForm.$('uuid').value === this.opening.uuid
        ) {
          openingForm.$('status').value =
            openingForm.$('status').value === 'active' ? 'inactive' : 'active';
          return openingForm;
        }
        return openingForm;
      }),
    );
    this.openings.replace(
      this.openings.map((currentOpening) => {
        if (
          !_.isEmpty(this.opening) &&
          currentOpening.uuid === this.opening.uuid
        ) {
          return {
            ...currentOpening,
            status: currentOpening.status === 'active' ? 'inactive' : 'active',
          };
        }
        return currentOpening;
      }),
    );
  };

  @action
  fillCompanyFields({ companyData }: { companyData: ICompany }) {
    if (companyData) {
      this.openingsForms.replace(
        this.openingsForms.map((openingForm) => {
          if (
            !_.isEmpty(this.opening) &&
            openingForm.$('uuid').value === this.opening.uuid
          ) {
            openingForm.$('companyName').value = companyData.name;
            openingForm.$('companyPath').value = companyData.path;
            return openingForm;
          }
          return openingForm;
        }),
      );
      this.openings.replace(
        this.openings.map((currentOpening) => {
          if (
            !_.isEmpty(this.opening) &&
            currentOpening.uuid === this.opening.uuid
          ) {
            return {
              ...currentOpening,
              companyName: companyData.name,
              companyPath: companyData.path,
            };
          }
          return currentOpening;
        }),
      );
    }
  }

  @action
  async initOpeningForms() {
    const detailedOpenings = await this.populateOpeningsWithDefaultTags(
      this.openings,
    );

    const forms = _.map(detailedOpenings, (opening) => {
      const form = initOpeningForm(opening);
      return form;
    });

    runInAction(() => {
      this.openingsForms.push(...forms);
    });
  }

  @action
  async noDuplicateTitle() {
    const opportunities = await dispatch(
      'opportunities.find',
      {
        isDeleted: false,
      },
      { clear: true },
    );

    const opportunity = _.filter(
      opportunities,
      (opp) =>
        _.lowerCase(opp.title) === _.lowerCase(this.opportunityTitle) &&
        opportunity?.uuid === this.opportunity?.uuid,
    );
    return _.isEmpty(opportunity);
  }

  @action
  async addOpening(): Promise<void> {
    this.formErrors = defaultFormErrors;
    if (this.openings.length === 0) {
      if (_.isEmpty(this.opportunityTitle)) {
        this.formErrors.title = 'Please add a title';
        return;
      }
      if (_.isEmpty(this.opportunityIcon)) {
        this.formErrors.iconName = 'Please select an icon';
        return;
      }
    }

    if (this.openings.length !== 0 && !(await this.validateForm())) {
      return;
    }

    const checkTitle = await this.noDuplicateTitle();
    if (!checkTitle && this.modalPages === 1) {
      this.setDuplicateTitleError();
      return;
    }

    const opening = createDefaultOpening();

    this.openings.push(opening);
    runInAction(() => {
      this.openingsForms.push(initOpeningForm(opening));
      this.setModalPages(this.modalPages + 1);
      this.currentPage = this.modalPages;
    });
  }

  @action
  async removeOpening(id: string) {
    const openingId = id || (!_.isEmpty(this.opening) && this.opening.uuid);
    const stepIndex = this.openings.indexOf(
      this.openings.filter((opening) => opening.uuid === openingId)[0],
    );
    this.setModalPages(this.modalPages - 1);
    this.currentPage = stepIndex + 1;
    this.openings.replace(
      this.openings.filter((opening) => opening.uuid !== openingId),
    );
    this.openingsForms.replace(
      this.openingsForms.filter(
        (opening) => opening.$('uuid').value !== openingId,
      ),
    );
  }

  @action
  async setCurrentPage(index: number) {
    if (this.currentPage === 1) {
      const checkTitle = await this.noDuplicateTitle();
      if (!checkTitle) {
        this.setDuplicateTitleError();
        return;
      }
    }
    if (this.currentPage > 1) {
      const openingFormIndex = this.currentPage - 2; // Pagination component is indexed from 1
      const currentForm = this.openingsForms[openingFormIndex];
      if (currentForm) {
        const response = await currentForm.validate({ showErrors: true });
        if (!response.isValid) {
          return;
        }
      }
    }
    // set this.opening for selected page
    if (index > 1) {
      const openingIndex = index - 2;
      const openingToBeSelected = this.openings[openingIndex];
      if (openingToBeSelected) {
        this.setOpening(openingToBeSelected.uuid);
      }
    }
    runInAction(() => {
      this.currentPage = index;
      if (index === 1) {
        this.setOpening(null);
      }
    });
  }

  @action
  setDuplicateTitleError() {
    this.formErrors.title =
      'Opportunity title already exists. Please choose a different title.';
  }

  @action
  setEmptyOpeningsError() {
    this.formErrors.openingError = 'Please add at least one opening';
  }

  @action
  setModalPages(modalPages: number) {
    this.modalPages = modalPages;
  }

  @action
  setIsAddingNewOpp() {
    this.isAddingNewOpportunity = true;
  }

  @action
  fetchAllPartnerCount() {
    dispatch(
      'opportunities.find',
      {
        $client: { populateApplicantsCount: true },
        $sort: { createdAt: -1 },
      },
      { clear: true },
    );
  }
  @action
  async saveNewOpportunity({
    simplifiedErrorMessage,
  }: {
    simplifiedErrorMessage: boolean;
  }) {
    await this.cacheCurrentPageSearchCriteria();

    const openingsValues = this.extractOpeningValues();
    if (!(await this.validateForm())) {
      return false;
    }
    this.setIsAddingNewOpp();

    const data: Partial<IOpportunity> = {
      description: this.opportunityDescription,
      iconName: this.opportunityIcon,
      openings: openingsValues,
      search: this.opportunitySearchCriteria,
      status: this.opportunityStatus,
      title: this.opportunityTitle,
      uuid: uuid.v4(),
    };

    try {
      await dispatch('opportunities.create', {
        data,
      });
      this.fetchAllPartnerCount();
    } catch (error) {
      runInAction(() => {
        this.errorMsg = error.message;
      });
      log.error('Error creating opportunity: ', error);
      dispatch('ui.snackBar.error', 'Opportunity could not be saved', {
        body: simplifiedErrorMessage
          ? 'Please check the required missing fields'
          : error.message,
      });
      return false;
    }
    this.clear();
    return true;
  }

  @action
  async saveUpdatedOpportunity(): Promise<boolean> {
    await this.cacheCurrentPageSearchCriteria();

    const openingValues = this.extractOpeningValues();
    if (!(await this.validateForm())) {
      return false;
    }

    const opportunityId = this.opportunity.uuid;

    const updatedData: { data: Partial<IOpportunity>; id: string } = {
      data: {
        description: this.opportunityDescription,
        iconName: this.opportunityIcon,
        openings: openingValues,
        search: this.opportunitySearchCriteria,
        status: this.opportunityStatus,
        title: this.opportunityTitle,
      },

      id: opportunityId,
    };

    try {
      await dispatch('opportunities.update', updatedData);
      this.fetchAllPartnerCount();
    } catch (error) {
      runInAction(() => {
        this.errorMsg = error.message;
      });
      log.error('Error updating opportunity: ', error, {
        extra: {
          opportunityId,
        },
      });
      dispatch('ui.snackBar.error', 'Opportunity could not be saved', {
        body: 'Please check the required missing fields',
      });
      return false;
    }
    this.clear();
    return true;
  }

  extractOpeningValues(): IOpportunityOpening[] {
    return _.map(this.openingsForms, (openingForm) => {
      const newValue: IOpportunityOpening & Record<string, any> = {
        ...openingForm.values(),
        uuid: openingForm.$('uuid').value,
      };
      const opening = _.find(this.openings, { uuid: newValue.uuid });
      newValue.search = opening?.search || {};
      if (_.isEmpty(newValue.status)) {
        newValue.status = 'active';
      }
      log.debug('Opening form has values: ', newValue);

      newValue.defaultTagIds = [
        ..._.map(newValue.defaultTags, 'uuid'),
        ..._.map(newValue.defaultCerts, 'uuid'),
      ];

      delete newValue.defaultTags;
      delete newValue.defaultCerts;

      return { ...opening, ...newValue };
    });
  }

  @action
  updateOpportunity(opportunity) {
    this.opportunity = opportunity;
    this.opportunityTitle = opportunity.title;
    this.opportunityDescription = opportunity.description;
    this.opportunityStatus = opportunity.status;
    this.opportunityIcon = opportunity.iconName;
    this.isUpdatingOpportunity = true;
    this.currentPage = 1;
    this.modalPages = this.openings.length + 1;
  }

  @action
  clear() {
    this.opportunity = null;
    this.isAddingNewOpportunity = false;
    this.isUpdatingOpportunity = false;
    this.opportunityTitle = '';
    this.opportunityDescription = '';
    this.openings.clear();
    this.opening = null;
    this.opportunitySearchCriteria = null;
    this.openingsForms.clear();
    this.errorMsg = '';
    this.formErrors = defaultFormErrors;
    this.currentPage = 1;
    this.modalPages = 1;
    this.opportunityIcon = null;

    dispatch('ui.searches.clear', { resetStores: true });
    dispatch(`workers.setCertFilter`, { op: 'set' });
  }

  @action
  async validateForm() {
    this.formErrors = defaultFormErrors;
    let isValid = true;
    if (_.isEmpty(this.opportunityTitle)) {
      this.formErrors.title = 'Please add a title';
      isValid = false;
      this.setCurrentPage(1);
    }
    if (_.isEmpty(this.opportunityIcon)) {
      this.formErrors.iconName = 'Please select an icon';
      isValid = false;
      this.setCurrentPage(1);
    }
    const checkTitle = await this.noDuplicateTitle();
    if (!checkTitle) {
      this.setDuplicateTitleError();
      isValid = false;
      this.setCurrentPage(1);
    }
    if (_.isEmpty(this.extractOpeningValues())) {
      this.setEmptyOpeningsError();
      isValid = false;
      this.setCurrentPage(1);
    }
    await BluebirdPromise.map(
      this.openingsForms,
      async (sf, idx) => {
        const response = await sf.validate({ showErrors: true });
        if (!response.isValid) {
          isValid = false;
          runInAction(() => {
            this.currentPage = idx + 2;
          });
        }
      },
      { concurrency: 1 },
    );
    return isValid;
  }

  async populateOpeningsWithDefaultTags(openings: IOpportunityOpening[]) {
    const allDefaultTagIds = _.flatten(_.map(openings, 'defaultTagIds'));
    const res = await dispatch('certs.runQuery', {
      uuid: { $in: allDefaultTagIds },
    });
    const defaultTags: ICert[] = res?.data;
    const defaultTagsMap = _(defaultTags).keyBy('uuid').value();

    return openings.map((o) => {
      const openingDefaultTags = _.compact(
        _.map(o.defaultTagIds, (id) => defaultTagsMap[id]),
      );

      return {
        ...o,
        defaultCerts: _.filter(openingDefaultTags, {
          certType: 'cert',
        }) as ICert[],
        defaultTags: _.filter(openingDefaultTags, {
          certType: 'qual',
        }) as ICert[],
      };
    });
  }

  opportunityIconOptions = [
    {
      image: {
        avatar: true,
        src: '/static/img/opportunities/merchandiser.png',
      },
      key: 'merchandiser',
      text: 'Merchandiser',
      value: 'merchandiser',
    },
    {
      image: { avatar: true, src: '/static/img/opportunities/inspector.png' },
      key: 'inspector',
      text: 'Inspector',
      value: 'inspector',
    },
    {
      image: { avatar: true, src: '/static/img/opportunities/tech.png' },
      key: 'tech',
      text: 'Tech',
      value: 'tech',
    },
    {
      image: { avatar: true, src: '/static/img/opportunities/food.png' },
      key: 'food',
      text: 'Food',
      value: 'food',
    },
  ];
}
