import { action, computed, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import Promise from 'bluebird';

import { getChildLogger } from '#/shared/utils/client.logger';
export default class ShiftInvitationsModal {
  // #region Store Properties
  log = getChildLogger('ui.shiftInvitationsModal');

  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  shift = {};

  @observable
  selectedPoolsToInvite = [];

  @observable
  selectedPoolsToAssign = [];

  @observable
  selectedPartnersToInvite = [];

  @observable
  selectedPartnersToAssign = [];

  @observable
  isRecommendedInvitationsOpen = false;

  @observable
  searchValue = '';

  @observable
  distanceFilter = 0;

  @observable
  roleFilter = '';

  @observable
  tags = [];

  @observable
  selectedPref = null;

  @observable
  assignLimit = 0;

  @observable
  assignCount = 0;

  @observable
  loc = null;

  @observable
  saveNotificationPrefsOnSubmit = false;

  @observable
  companyTags = [];

  @observable
  tagFilter = '';

  @observable
  displayPools = true;

  @observable
  hidePoolsAndInvitations = false;

  // #endregion Store Properties

  // #endregion UI Store Methods
  @action
  async setup({
    company = null,
    shift = {},
    notificationPrefs = [],
    open = this.isOpen,
    tags = [],
    role = '',
    prefID = null,
    saveNotificationPrefsOnSubmit = false,
    hidePoolsAndInvitations = false,
  }) {
    this.clearInputAndFilters();
    this.setLoading(true);
    const companyObj = company || dispatch('auth.getCompany');
    const companyTags = await dispatch('quals.findByCompany', {
      company: companyObj.uuid,
    });
    // the usecase of role/tags has changed. Have removed the initial setup
    // of role/tags but left the rest of the logic so that if the admin wants
    // to filter after intitial load they can.
    role = '';
    tags = [];
    this.set('companyTags', companyTags);
    this.setShift(shift);
    this.setCompany(companyObj);
    this.open(open);
    this.set('roleFilter', role);
    this.set('tags', tags);
    this.set('selectedPref', prefID);

    // For ShiftsNextGen partner week popup props
    this.set('assignLimit', _.get(shift, 'slots'));
    this.set('assignCount', _.get(shift, 'assignedUsers', []).length);
    this.set('loc', _.get(shift, 'loc') || _.get(companyObj, 'loc'));
    this.set('saveNotificationPrefsOnSubmit', saveNotificationPrefsOnSubmit);

    this.set('hidePoolsAndInvitations', hidePoolsAndInvitations);
    if (hidePoolsAndInvitations) {
      this.set('displayPools', false);
    }

    this.setLoading(false);
  }

  @action
  toggleRecommendedInvitations(open = !this.isRecommendedInvitationsOpen) {
    this.isRecommendedInvitationsOpen = open;
  }

  @action
  async setSearchValue(searchValue) {
    this.searchValue = searchValue;
    if (!_.isEmpty(searchValue)) this.isRecommendedInvitationsOpen = false;
  }

  @action
  add({ item, listName }) {
    const list = _.get(this, listName);
    if (_.isArray(item)) {
      list.push(...item);
    } else {
      list.push(item);
    }
  }

  @action
  remove({ uuid, listName }) {
    const list = _.get(this, listName);
    _.remove(list, (p) => p.uuid === uuid);
  }

  /**
   * NOTE: The notificationPrefs saved here will be restructured via #/utils/notificationPrefs/{unzipNotificationPrefs} before saving to shifts.notificationPrefs or saving as dispatchPrefs
   */
  @action
  async submit() {
    const log = this.log.getChildLogger('submit');
    if (
      !_.isEmpty(this.selectedPartnersToInvite) ||
      !_.isEmpty(this.selectedPoolsToInvite)
    ) {
      if (!_.isEmpty(this.selectedPref)) {
        // Add to existing notification pref
        dispatch('ui.shiftInvitations.addToNotificationPref', {
          partners: this.selectedPartnersToInvite,
          pools: this.selectedPoolsToInvite,
          prefID: this.selectedPref,
        });
      } else {
        // Add new notification pref
        dispatch('ui.shiftInvitations.addNotificationPref', {
          ignoreRestrictions: _.isEmpty(this.roleFilter),
          partners: this.selectedPartnersToInvite,
          pools: this.selectedPoolsToInvite,
        });
        if (this.saveNotificationPrefsOnSubmit) {
          dispatch('ui.shiftInvitations.saveUpdatedNotificationPrefs', {
            shift: this.shift,
          });
        }
      }
    }

    const partnersToAssign = this.selectedPartnersToAssign;
    let poolPartnersToAssign = [];
    if (!_.isEmpty(this.selectedPoolsToAssign)) {
      poolPartnersToAssign = _.flatten(
        this.selectedPoolsToAssign.map((p) => p.users),
      );
    }
    const allPartnersToAssign = _.concat(
      partnersToAssign,
      poolPartnersToAssign,
    );

    if (_.isEmpty(_.get(this.shift, 'uuid'))) {
      // Assigning users during shift creation
      dispatch('ui.shiftFormNextGen.setAssignedUsers', allPartnersToAssign);
    } else {
      // Disabled auto shiftInvitation.setup call on shift update
      dispatch('ui.shiftInvitations.setShouldSetupOnShiftUpdate', false);
      try {
        await Promise.map(allPartnersToAssign, async (partner) => {
          let assignment = await dispatch('assignments.runQuery', {
            ref: this.shift.uuid,
            status: 'Sent',
            user: partner.uuid,
          });
          assignment = _.first(assignment.data);
          if (assignment) {
            const id = _.get(assignment, 'uuid');
            const data = { status: 'Assigned' };
            await dispatch('assignments.update', { data, id });
          } else {
            await dispatch('assignments.assign', {
              thingId: this.shift.uuid,
              workerId: partner.uuid,
            });
          }
        });
      } catch (err) {
        log.error('Failed to assign partners', err);
      }
      // Re Enabled auto shiftInvitation.setup call on shift update
      dispatch('ui.shiftInvitations.setShouldSetupOnShiftUpdate', true);
    }

    // Make sure to route the user to the invitations tab if any invites have been saved
    if (
      !this.hidePoolsAndInvitations &&
      (!_.isEmpty(this.selectedPartnersToInvite) ||
        !_.isEmpty(this.selectedPoolsToInvite))
    ) {
      dispatch('ui.shiftDetails.setActiveTab', 'invitations');
    }
    this.clear();
  }

  @action
  clearInputAndFilters() {
    this.searchValue = '';
    this.distanceFilter = 0;
    this.roleFilter = '';
    this.tagFilter = '';
    this.displayPools = true;
  }
  @action
  clearSelected() {
    this.selectedPartnersToInvite = [];
    this.selectedPartnersToAssign = [];
    this.selectedPoolsToInvite = [];
    this.selectedPoolsToAssign = [];
  }

  @action.bound
  setShift(shift = {}) {
    this.shift = shift;
  }

  @action.bound
  setCompany(company = {}) {
    this.company = company;
  }

  @action
  setLoading(loading) {
    this.isLoading = loading;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    _.set(this, key, value);
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  async setCertFilter(value, type) {
    this.set('isLoading', true);
    this[`${type}Filter`] = value;
    const selectedTagsAndRoles = _.compact([this.roleFilter, this.tagFilter]);
    if (!_.isEmpty(selectedTagsAndRoles)) {
      await dispatch('partners.find', {
        query: {
          'certs.uuid': { $all: selectedTagsAndRoles },
        },
      });
      if (this.displayPools) {
        await dispatch('pools.find', {
          query: {
            'search.tags': {
              $all: selectedTagsAndRoles,
            },
          },
        });
      }
    } else {
      await dispatch('partners.find', {
        query: { 'certs.uuid': undefined },
      });
      if (this.displayPools) {
        await dispatch('pools.find', {
          query: {
            'search.tags': undefined,
          },
        });
      }
    }
    this.set('isLoading', false);
  }

  @action
  async setDistanceFilter(value, loc) {
    this.set('isLoading', true);
    this.distanceFilter = value;
    if (value) {
      const geo = {
        $geoNear: {
          center: loc?.$geometry?.coordinates ?? loc?.coordinates ?? loc ?? [],
          miles: value,
        },
      };
      await dispatch('partners.find', {
        _geo: geo,
      });
    } else {
      await dispatch('partners.find', { _geo: undefined });
    }
    this.set('isLoading', false);
  }

  @action
  async toggleDisplayPools(value) {
    const log = this.log.getChildLogger('toogleDisplayPools');
    this.set('isLoading', true);
    this.displayPools = !!value;
    const selectedTagsAndRoles = _.compact([this.roleFilter, this.tagFilter]);
    const poolQuery = {};
    if (!_.isEmpty(selectedTagsAndRoles)) {
      poolQuery['search.tags'] = { $all: selectedTagsAndRoles };
    } else {
      poolQuery['search.tags'] = undefined;
    }
    log.debug('Query for pools: ', poolQuery);
    if (this.displayPools) {
      await dispatch('pools.find', poolQuery, { smart: false });
    } else {
      dispatch('pools.emptyList');
    }
    this.set('isLoading', false);
  }

  @action
  clear(args = {}) {
    this.isOpen = !!args.open;
    this.searchValue = '';
    this.shift = {};
    this.company = {};
    this.clearSelected();
    this.isRecommendedInvitationsOpen = false;
    this.distanceFilter = 0;
    this.roleFilter = '';
    this.tags = [];
    this.selectedPref = null;
    this.assignCount = 0;
    this.assignLimit = 0;
    this.loc = null;
    this.showTimeWindowEdit = false;
    this.window = {};
    this.companyTags = [];
    this.isLoading = false;
    this.tagFilter = '';
    this.displayPools = true;
    this.hidePoolsAndInvitations = false;
  }

  // #endregion UI Store Methods

  // #region Computed Properties

  @computed
  get selectedPartners() {
    return _.concat(
      this.selectedPartnersToAssign.map((p) =>
        _.extend(p, { selectedAction: 'assign' }),
      ),
      this.selectedPartnersToInvite.map((p) =>
        _.extend(p, { selectedAction: 'invite' }),
      ),
    );
  }

  @computed
  get selectedPools() {
    return _.concat(
      this.selectedPoolsToAssign.map((p) =>
        _.extend(p, { selectedAction: 'assign' }),
      ),
      this.selectedPoolsToInvite.map((p) =>
        _.extend(p, { selectedAction: 'invite' }),
      ),
    );
  }

  @computed
  get allSelectedPartners() {
    const selectedPoolPartners = _.flatten(
      this.selectedPools.map((p) => p.users),
    );
    return _.uniqBy(
      _.concat(selectedPoolPartners, this.selectedPartners),
      'uuid',
    );
  }

  @computed
  get allSelectedPartnersToAssign() {
    const selectedPoolPartnersToAssign = _.flatten(
      this.selectedPoolsToAssign.map((p) => p.users),
    );
    return _.uniqBy(
      _.concat(selectedPoolPartnersToAssign, this.selectedPartnersToAssign),
      'uuid',
    );
  }

  @computed
  get totalResults() {
    const poolPagination = dispatch('pools.retrieve', '$pagination');
    const partnerPagination = dispatch('partners.retrieve', '$pagination');
    return (poolPagination?.total ?? 0) + (partnerPagination?.total ?? 0);
  }

  @computed
  get distanceFilterOptions() {
    return [
      {
        key: 0,
        text: 'All',
        value: 0,
      },
      {
        key: 1,
        text: 'Within 10 miles',
        value: 10,
      },
      {
        key: 2,
        text: 'Within 50 miles',
        value: 50,
      },
      {
        key: 3,
        text: 'Within 100 miles',
        value: 100,
      },
    ];
  }

  @computed
  get poolFilterOptions() {
    return [
      {
        key: 0,
        text: 'Yes',
        value: true,
      },
      {
        key: 1,
        text: 'No',
        value: false,
      },
    ];
  }

  @computed
  get roleFilterOptions() {
    const roles = dispatch('positions.retrieve', 'list');
    const roleOptions = roles.map((r, i) =>
      _.extend(r, { key: i + 1, text: r.title, value: r.uuid }),
    );

    return [
      {
        key: 0,
        text: 'All',
        value: '',
      },
      ...roleOptions,
    ];
  }

  @computed
  get tagFilterOptions() {
    const tagOptions = this.companyTags.map((r, i) =>
      _.extend(r, { key: i + 1, text: r.title, value: r.uuid }),
    );

    return [
      {
        key: 0,
        text: 'All',
        value: '',
      },
      ...tagOptions,
    ];
  }
}
