import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('stores.ui.certLinkModal');

export default class CertLinkModal {
  @observable
  isOpen = false;

  @observable
  cert = {};

  @observable
  certTitle = '';

  @observable
  storeName = '';

  @observable
  uuid = '';

  @observable
  certType;

  @observable
  field = null;

  @observable
  linkedCerts = [];

  @observable
  onSave = null;

  @action
  setup({
    certTitle,
    cert,
    certType,
    field = 'links',
    storeName,
    open = this.isOpen,
    onSave,
  }) {
    if (!storeName) {
      dispatch('ui.snackBar.error', 'Cannot create links to unknown Cert type');
      return;
    }

    dispatch(
      `${storeName}.${/qual/i.test(storeName) ? 'findByCompany' : 'find'}`,
      /qual/i.test(storeName) ? { company: _.first(cert.companies) } : {},
    );

    this.cert = cert;
    this.certTitle = certTitle;
    this.storeName = storeName;
    this.certType = certType;
    this.field = field;
    this.uuid = cert && cert.uuid ? cert.uuid : '';
    this.onSave = onSave;

    if (_.get(this.cert, [this.field, 'toJS'])) {
      this.linkedCerts = _.cloneDeep(_.get(this.cert, this.field).toJS());
    }

    this.isOpen = open;
  }

  @action
  toggleCert(cert) {
    const uuid = _.get(cert, 'uuid', cert);
    if (_.find(this.linkedCerts, { uuid })) {
      _.remove(this.linkedCerts, { uuid });
    } else {
      this.linkedCerts.push(cert);
    }
  }

  @action
  save({
    cert = this.cert,
    linkedCerts = this.linkedCerts,
    onSave = this.onSave,
  }) {
    log.debug('Saving Cert Link', { cert, linkedCerts });
    const addedLinks = _.reject(linkedCerts, (lc) =>
      _.find(cert[this.field], { uuid: lc.uuid }),
    );
    const removedLinks = _.reject(cert[this.field], (cl) =>
      _.find(linkedCerts, { uuid: cl.uuid }),
    );

    let store = 'certs';

    if (_.has(cert, 'employer')) {
      store = 'experiences';
    }

    return Promise.all(
      _.union(
        _.map(addedLinks, (link) =>
          dispatch(`${store}.update`, {
            data: {
              $push: {
                [this.field]: _.pick(link, [
                  'uuid',
                  'certType',
                  'parent',
                  'title',
                  'level',
                ]),
              },
            },
            id: cert.uuid,
          }),
        ),
        _.map(removedLinks, (link) =>
          dispatch(`${store}.update`, {
            data: { $pull: { [this.field]: { uuid: link.uuid } } },
            id: cert.uuid,
          }),
        ),
      ),
    )
      .then((res) => {
        log.debug('result: ', res);

        return dispatch('ui.certLinkModal.clear');
      })
      .then(() => {
        if (_.isFunction(onSave)) {
          onSave({
            added: addedLinks,
            all: linkedCerts,
            removed: removedLinks,
          });
        }
      });
  }

  @action
  clear({ open = false } = {}) {
    this.cert = observable({});
    this.certTitle = '';
    this.certType = '';
    this.storeName = '';
    this.uuid = '';
    this.linkedCerts = [];
    this.onSave = null;
    this.field = null;

    this.isOpen = open;
  }
}
