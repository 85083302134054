import type { IChannel } from '@shiftsmartinc/shiftsmart-types';

import { computed, action, observable, runInAction } from 'mobx';
import Promise from 'bluebird';
import promiseRetry from 'promise-retry';
import { dispatch } from 'rfx-core';
import { Client } from '@twilio/conversations';
import _ from 'lodash';
import moment from 'moment';

import { app, service } from '#/shared/app';
import { getChildLogger } from '#/shared/utils/client.logger';

const SERVICE = 'twilioConversations';

const log = getChildLogger('stores.twilioConversations');

export default class TwilioConversationsStore {
  messagingClient = null;

  @observable
  selected = null;

  @observable
  messageData = {};

  @observable
  messages = [];

  @observable
  $isLoading = {
    channels: false,
    messages: false,
    messagingClient: false,
  };

  /* Set of User UUIDs that are typing */
  @observable
  typingMembers = [];

  setInitialized = _.noop;

  @observable.shallow cachedTwilioChannels = {};

  // TODO: Add online status functions from twilioChat
  @observable
  onlineUsers = new Map([]);

  initializationComplete = new Promise((resolve) => {
    this.setInitialized = resolve;
  });

  @computed
  get isLoading() {
    return _.some(this.$isLoading) ? this.$isLoading : false;
  }

  @action
  async get(sid, params) {
    log.debug('looking up by SID: ', sid);
    const cachedChannel = _.get(this.cachedTwilioChannels, sid);
    if (cachedChannel) {
      log.debug('returning twilio channel from cache');
      return Promise.resolve(cachedChannel);
    }

    await this.initializationComplete;

    if (params?.chat) {
      try {
        // SLOW on initial load
        const twilioChat = await this.messagingClient.getConversationBySid(sid);
        _.set(this.cachedTwilioChannels, sid, twilioChat);
        return twilioChat;
      } catch (error) {
        this.clearMessages();
        log.error('Error getting twilio conversation by sid', error);
        throw error;
      }
    }
    const twilioConversation = await service(SERVICE).get(sid);
    _.set(this.cachedTwilioChannels, sid, twilioConversation);
    return twilioConversation;
  }

  init() {
    // run events on client side-only
    if (global.TYPE === 'CLIENT') {
      this.initEvents();
    }
  }

  initEvents() {
    app().on('logout', () => {
      if (this.messagingClient) {
        this.messagingClient.shutdown();
        localStorage.removeItem('twilioConversationsToken');
        localStorage.removeItem('twilioConversationsTokenExpiration');
      }
    });
  }

  initChannelEvents(twilioChat) {
    if (twilioChat) {
      twilioChat.on('messageAdded', this.addNewMessageToChannel(twilioChat));
      twilioChat.on('messageUpdated', this.updateMessage(twilioChat));
      twilioChat.on('typingStarted', (member) =>
        this.updateTypingMembers(true, member),
      );
      twilioChat.on('typingEnded', (member) =>
        this.updateTypingMembers(false, member),
      );
    }
  }

  clearEvents(twilioChat) {
    // eslint-disable-next-line
    twilioChat._events.messageAdded = twilioChat._events.messageAdded.slice(
      0,
      1,
    );
    // eslint-disable-next-line
    twilioChat._events.messageUpdated = twilioChat._events.messageUpdated.slice(
      0,
      1,
    );
    // eslint-disable-next-line
    twilioChat._events.typingStarted = twilioChat._events.typingStarted.slice(
      0,
      1,
    );
    // eslint-disable-next-line
    twilioChat._events.typingEnded = twilioChat._events.typingEnded.slice(0, 1);
    twilioChat.removeListener('messageAdded', () =>
      log.debug('Conversation listener removed - messageAdded'),
    );
    twilioChat.removeListener('messageUpdated', () =>
      log.debug('Conversation listener removed - messageUpdated'),
    );
    twilioChat.removeListener('typingStarted', () =>
      log.debug('Conversation listener removed - typingStarted'),
    );
    twilioChat.removeListener('typingEnded', () =>
      log.debug('Conversation listener removed - typingEnded'),
    );
  }

  async setSelected(channel) {
    let twilioConvoChat = null;
    try {
      await promiseRetry(
        async (retry) => {
          try {
            twilioConvoChat = await dispatch(
              'twilioConversations.get',
              channel?.sid,
              {
                chat: true,
              },
            );
            log.debug('convo chat: ', { twilioConvoChat });
          } catch (error) {
            if (error.status === 403) {
              log.debug('User is not currently active on the channel', {
                error,
              });
              await this.joinOrRejoinChannel(
                channel,
                dispatch('auth.getUser').uuid,
              );
            } else {
              log.error('Error setting selected conversation channel', {
                error,
              });
            }
            retry(error);
          }
        },
        { minTimeout: 100, retries: 2 },
      );
    } catch (error) {
      log.error('Error loading selected Twilio Conversation', error);
      dispatch('ui.snackBar.error', 'Something went wrong, please try again');
    }
    // clear current listeners, if selected exists
    if (this.selected) {
      this.clearEvents(this.selected);
    }
    this.set('selected', twilioConvoChat);
    // add in new listeners to new selected
    this.initChannelEvents(twilioConvoChat);
  }

  joinChannelRequests = new Map([]);

  /** butterfly-in-hand.gif "is this a semaphore?" */
  joinLock = false;

  /**
   * ### joinOrRegionChannel
   * @description
   * depending on whether the user is in the channel or not, will either
   * join the channel (if not) or call `readdTempRemovedEmployer` as needed.
   *
   * This is semaphore locked to only run once at a time and to return a
   * cached promise, preventing* the possibility of calling it twice
   */
  async joinOrRejoinChannel(channel: IChannel, userId) {
    const key = channel.uuid + ':' + userId;
    let i = 0;
    while (i++ < 150 && this.joinLock && !this.joinChannelRequests.has(key)) {
      setTimeout(() => {
        // thumb twiddling intensifies
      }, 10);
    }

    if (!this.joinChannelRequests.has(key)) {
      this.joinLock = true;
      const fn = async () => {
        const channelUser = _.find(channel?.users, { uuid: userId });

        if (channelUser) {
          log.debug(
            'User has been removed from the channel: calling readdTempRemovedEmployer...',
          );
          dispatch('ui.snackBar.open', 'Re-Entering Channel');
          await service('channels').patch(
            channel.uuid,
            {},
            {
              query: {
                $client: { readdTempRemovedEmployer: true },
              },
            },
          );
        } else {
          log.debug('User is not in the channel', {
            channelId: channel.uuid,
            userId: userId,
          });
          dispatch('ui.snackBar.open', 'Joining Channel');

          await dispatch('chatChannels.addUsers', {
            channel,
            users: [userId],
          });
        }
      };

      this.joinChannelRequests.set(key, fn());

      this.joinLock = false;
    }
    return this.joinChannelRequests.get(key);
  }

  clearSelected() {
    if (!_.isEmpty(this.selected)) {
      this.clearEvents(this.selected);
    }
    this.set('selected', null);
  }

  addNewMessageToChannel = (sid) => (message) => {
    this.addNewMessage(sid, message);
  };

  @action.bound
  addNewMessage(twilioChat, message) {
    // only add if message added for currently selected channel
    if (_.get(message, 'conversation.sid') === _.get(twilioChat, 'sid')) {
      const m = _.cloneDeep(message.state);
      let existingMessageIndex = -1;
      if (!_.isEmpty(m.attributes) && m.attributes?.timestamp) {
        existingMessageIndex = _.findLastIndex(
          this.messages,
          (_message) =>
            _message.attributes.timestamp === message.attributes.timestamp,
        );
      }
      if (existingMessageIndex !== -1) {
        runInAction(() => {
          _.extend(this.messages[existingMessageIndex], {
            ...m,
            error: false,
            loading: false,
          });
        });
      } else {
        this.messages.push(m);
      }
      this.updateConsumptionHorizon({
        message,
        twilioConversation: twilioChat,
      });
    }
  }

  @action.bound
  updateMessage =
    (twilioChat) =>
    ({ message, updateReasons }) => {
      // update message
      if (_.get(message, 'conversation.sid') === _.get(twilioChat, 'sid')) {
        log.debug('Convo updateMessage Event:', message, updateReasons);

        const m = _.cloneDeep(message.state);

        // Patch existing message
        const existingMessage = _.find(this.messages, { sid: m.sid });
        if (existingMessage) {
          runInAction(() => {
            _.extend(existingMessage, m);
          });
        }

        // Update selected message object in chatChannels if currently selected
        if (dispatch('chatChannels.retrieve', 'selectedMessage')?.sid === m.sid)
          dispatch('chatChannels.setSelectedMessage', { message: m });
      }
      return true;
    };

  @action.bound
  async setupMessaging({ user, company }) {
    if (_.includes(user.roles, 'customer')) {
      return false;
    }

    const chatEnabled = dispatch('auth.can', 'create', 'twilioChat');
    if (!chatEnabled) {
      return false;
    }

    if (this.$isLoading.messagingClient) {
      log.debug('Messaging Already being setup');
      return Promise.resolve({});
    }

    const isEmployer = !!_.find(user.roles, (r) =>
      /employer|company-agent|admin/.test(r),
    );

    log.debug('Getting Twilio Conversation Token for %s', `${user.uuid}`);

    // TODO: Use CASL Chat Permissions
    if (company?.settings?.chat?.enableManagerChat) {
      await dispatch('chatChannels.setupManagerChannel', { company, user });
    }

    if (_.isEmpty(this.messagingClient)) {
      log.debug('Creating new messaging client');
      try {
        let getServerGeneratedToken = true;
        const cachedToken = localStorage.getItem('twilioConversationsToken');
        const cachedTokenExpiration = localStorage.getItem(
          'twilioConversationsTokenExpiration',
        );

        if (cachedToken && cachedTokenExpiration) {
          log.debug(
            'Checking cached twilioChat token for validity',
            cachedToken,
            cachedTokenExpiration,
          );
          getServerGeneratedToken =
            moment().isAfter(moment(cachedTokenExpiration)) ||
            moment(cachedTokenExpiration).diff(moment(), 'minutes') <= 60;
        }
        getServerGeneratedToken = true;

        let twilioToken;
        if (getServerGeneratedToken) {
          const tokenResponse = await service(SERVICE).get(null, {
            query: {
              action: 'twilioToken',
              device: 'browser',
              isEmployer,
              user: user.uuid,
            },
          });
          twilioToken = tokenResponse.token;
          log.debug('Setting twilioChat token from server locally');
          localStorage.setItem('twilioConversationsToken', tokenResponse.token);
          localStorage.setItem(
            'twilioConversationsTokenExpiration',
            tokenResponse.tokenExpiration,
          );
        } else {
          log.debug('Using locally cached token');
          twilioToken = cachedToken;
        }

        if (global.IS_PRODUCTION) {
          await this.connectMessagingClient(twilioToken);
        }
        if (!_.isEmpty(this.messagingClient)) {
          this.setInitialized();
        }

        return this.messagingClient;
      } catch (err) {
        log.error('Error Initializing Twilio Conversations Chat Client: ', err);
        throw err;
      }
    }

    return this.messagingClient;
  }

  @action
  async connectMessagingClient(token) {
    if (this.$isLoading.messagingClient) {
      log.debug('Messaging Client Being Created');
      return Promise.resolve({});
    }
    if (this.messagingClient) {
      log.debug('Messaging Client Already Exists');
      return Promise.resolve(this.messagingClient);
    }
    this.$isLoading.messagingClient = true;
    try {
      const client = new Client(token);

      client.on('initFailed', ({ error }) => {
        log.error('Error Initializing Twilio Conversations Client: ', error);
        return false;
      });

      client.on('initialized', () => {
        log.debug('Twilio Conversations Client initialized successfully!');
      });

      const currentFriendlyName = _.get(client, 'user.friendlyName', '');
      const user = dispatch('auth.getUser');
      if (
        _.isEmpty(currentFriendlyName) ||
        currentFriendlyName !== user.firstName
      ) {
        await client.user.updateFriendlyName(user.firstName);
      }
      this.set('messagingClient', client);
      this.set('$isLoading.messagingClient', false);
      return client;
    } catch (err) {
      log.error('Error Initializing Twilio Conversations Client: ', err);
      return false;
    }
  }

  @action.bound
  async loadMessages(channel) {
    // get twilioChannel
    const { sid, uuid } = channel;

    // Update cachedUnreadHorizon
    const user = dispatch('auth.getUser');

    const channelUser = _.find(channel.users, { uuid: user.uuid });
    const lastMessage = _.get(channel, 'lastMessage', {});
    const unreadMsg =
      _.get(lastMessage, 'index', 0) -
      _.get(channelUser, 'lastConsumedIndex', -1);

    if (unreadMsg) {
      _.extend(this.selected, { cachedUnreadHorizon: unreadMsg });
    }

    // load messages
    const res = await promiseRetry(
      async (retry) => {
        try {
          const conversationChannel =
            this.selected?.sid === sid
              ? this.selected
              : await this.get(sid, { chat: true });
          // SLOW on initial load
          const messageData = await conversationChannel.getMessages();
          this.set('messageData', messageData);
          const messageList = messageData.items.map((m) => m.state);
          this.set('messages', messageList);
          const message = _.last(messageList);
          if (!_.isEmpty(uuid) && channelUser) {
            this.updateConsumptionHorizon({
              message,
              twilioConversation: conversationChannel,
            });
          }
          return messageData;
        } catch (error) {
          if (error.status === 403) {
            log.debug('User is not currently active on the channel', { error });
            await this.joinOrRejoinChannel(channel, user.uuid);
            retry(error);
          } else {
            log.error('Error loading messages for conversation channel', {
              error,
            });
          }
          this.set('messages', { items: [] });
          throw error;
        }
      },
      { minTimeout: 100, retries: 2 },
    );

    return res;
  }

  @action.bound
  async loadMoreMessages(channel) {
    const { sid } = channel;
    const selectedSid = _.get(this.selected, 'conversationSid');
    if (sid === selectedSid && this.messageData.hasPrevPage) {
      const messageData = await this.messageData.prevPage();
      this.set('messageData', messageData);
      const newMessageList = this.messageData.items.map((m) => m.state);
      const messageList = _.concat(newMessageList, this.messages);
      this.set('messages', messageList);
    }
  }

  @action.bound
  async sendMessage({ sid, selectedTwilioChannel, message, attributes }) {
    if (!sid && _.isEmpty(selectedTwilioChannel)) {
      const error = new Error('No channel specified', {
        sid,
      });
      throw error;
    }
    if (!attributes) {
      return Promise.reject('Attributes required for user info', {
        attributes,
        sid,
      });
    }
    const localMessage = {
      attributes,
      author: attributes.sender.uuid,
      body: message,
      error: false,
      index: this.messages.length,
      loading: true,
      sid: attributes.timestamp,
    };
    try {
      const twilioChannel =
        selectedTwilioChannel || (await this.get(sid, { chat: true }));

      runInAction(() => {
        this.messages.push(localMessage);
      });

      const newMessage = await twilioChannel.sendMessage(message, attributes);
      log.debug('sent new message: ', newMessage);
      const newLocalMessageIndex = _.findLastIndex(
        this.messages,
        (_message) =>
          _message.attributes.timestamp === localMessage.attributes.timestamp,
      );
      runInAction(() => {
        _.extend(this.messages[newLocalMessageIndex], {
          error: false,
          loading: false,
        });
      });
      return newMessage;
    } catch (error) {
      log.error('Error Sending Twilio Conversation Message', error);
      const newLocalMessageIndex = _.findLastIndex(
        this.messages,
        (_message) =>
          _message.attributes.timestamp === localMessage.attributes.timestamp,
      );
      runInAction(() => {
        _.extend(this.messages[newLocalMessageIndex], {
          error: true,
          loading: false,
        });
      });

      throw error;
    }
  }

  @action.bound
  clearMessages() {
    this.messages = [];
  }

  updateConsumptionHorizon({ message, twilioConversation }) {
    if (_.isEmpty(twilioConversation) || _.isEmpty(message)) {
      return false;
    }

    const user = dispatch('auth.getUser');

    twilioConversation.advanceLastReadMessageIndex(message.index);
    const query = {
      users: {
        $elemMatch: { uuid: user.uuid },
      },
    };
    const attributes = _.get(twilioConversation, 'attributes', {});
    const data = {
      'users.$.lastConsumedIndex': message.index,
      'users.$.reminderIndex': -1,
      'users.$.twilioRemoved': false,
      uuid: attributes.channelUuid,
    };
    return dispatch('chatChannels.update', { data, params: { query } });
  }

  // Update attributes for message at messageIndex on the currently selected channel
  @action.bound
  async updateMessageAttributes({ messageIndex, data }) {
    try {
      let response = {};
      const messages = await this.selected.getMessages(1, messageIndex);
      if (messages.items.length) {
        const message = messages.items[0];
        const attributes = _.extend({}, message.attributes, data);
        log.debug('Patching message', message);
        response = await message.updateAttributes(attributes);
      }
      log.debug('Message updated', response);
      return response;
    } catch (error) {
      log.error('Message update failed', error);
      throw error;
    }
  }

  // Update body of message at messageIndex on the currently selected channel
  @action.bound
  async updateMessageBody({ messageIndex, body }) {
    try {
      let response = {};
      const messages = await this.selected.getMessages(1, messageIndex);
      if (messages.items.length) {
        const message = messages.items[0];
        log.debug('Patching message, original body: ', message.body);
        response = await message.updateBody(body);
        await this.updateMessageAttributes({
          data: { edited: true },
          messageIndex,
        });
      }
      log.debug('Message updated', response);
      return response;
    } catch (error) {
      log.error('Message update failed', error);
      throw error;
    }
  }

  @action
  updateTypingMembers(isTyping, member) {
    if (member.conversation?.sid !== this.selected?.sid) {
      return false;
    }

    if (isTyping) this.typingMembers.push(member.state.identity);
    else _.remove(this.typingMembers, (i) => i === member.state.identity);
    return false;
  }

  @action.bound
  async sendTypingSignal() {
    if (_.isFunction(this.selected?.typing)) {
      this.selected.typing();
    }
  }

  @action.bound
  async checkOnlineStatus({ user }) {
    const identity = _.get(user, 'uuid', user);
    if (_.isEmpty(identity)) {
      return Promise.reject('Unknown User');
    }

    await this.initializationComplete;

    log.verbose('Twilio Chat is initialized. Checking Online Status');

    try {
      const cached = this.onlineUsers.get && this.onlineUsers.get(identity);

      if (
        cached &&
        moment().subtract({ minutes: 30 }).isBefore(cached.timestamp)
      ) {
        log.verbose(
          `Cached user ${identity} status of ${
            cached.online ? 'ONLINE' : 'OFFLINE'
          }`,
        );
        return cached.online;
      }
      const twilioUser = await this.messagingClient.getUser(identity);

      this.updateUserOnlineStatus({ identity, online: twilioUser.isOnline });

      return twilioUser.isOnline;
    } catch (err) {
      log.error('Failed to load online status for user', err);
      return null;
    }
  }

  @action
  updateUserOnlineStatus({ identity, online }) {
    log.debug(
      `Setting user ${identity} status to ${online ? 'ONLINE' : 'OFFLINE'}`,
    );

    this.onlineUsers.set(identity, {
      online,
      timestamp: Date.now(),
    });
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action.bound
  set(key, value) {
    _.set(this, key, value);
  }
}
