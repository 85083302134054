import { observable, action } from 'mobx';
import _ from 'lodash';

export default class WorkerPoolFilter {
  @observable
  selectedPools = [];

  @action
  setPools({ pools, values }) {
    let selectedPools = _.intersectionBy(this.selectedPools, values, 'value');
    selectedPools = _.unionBy(selectedPools, pools, 'value');
    this.selectedPools = _.uniqBy(selectedPools, 'value');
  }
  @action
  clear() {
    this.selectedPools = [];
  }
}
