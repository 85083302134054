import Form from './_.extend';

export class StoreListForm extends Form {
  hooks = () => {};
}

const fields = ['uuid', 'title', 'stores'];

const rules = {
  stores: 'array|required',
  title: 'string|required',
};

const labels = {
  stores: 'Stores',
  title: 'Store List Name',
  uuid: '',
};

export function init(
  values = {
    stores: [],
    title: '',
  },
) {
  return new StoreListForm({ fields, labels, rules, values });
}
