import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

export default class QuotaEdit {
  @observable
  isOpen = false;

  @observable
  campaign = {};

  @observable
  key = '';

  @observable
  quota = {};

  @observable
  updateData = {};

  @action
  setup({ key, quota, campaign, open = this.isOpen }) {
    this.key = key;
    this.quota = quota;
    this.campaign = campaign;
    this.updateData = {};

    this.isOpen = open;
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(data) {
    _.extend(this.quota, data);
    _.extend(this.updateData, data);
  }

  save() {
    if (_.isEmpty(this.updateData)) {
      return this.clear();
    }

    const patchData = _.extend(
      {
        quotaKey: this.key,
      },
      this.updateData,
    );

    dispatch('campaigns.update', { data: patchData, id: this.campaign.uuid });

    return this.clear();
  }

  @action
  clear() {
    this.key = '';
    this.quota = {};
    this.campaign = '';
    this.updateData = {};

    this.isOpen = false;
  }
}
