import type { IUser } from '@shiftsmartinc/shiftsmart-types';

import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import cx from 'classnames';

import { NA } from '#/shared/components/ssm';
import { getChildLogger } from '#/shared/utils/client.logger';
import { useCachedUserItem } from '#/shared/hooks/useCacheItem';

const log = getChildLogger('utils.WorkerProp');

type WorkerPropPropTypes = {
  defaultText: string;
  formatterFn: () => void;
  prop: string;
  truncate: boolean;
  user: IUser;
  userId: string;
};

const WorkerProp = observer<React.FC<WorkerPropPropTypes>>(
  ({ defaultText, formatterFn, prop, truncate, user, userId }) => {
    log.verbose('Initializing WorkerProp');

    const { item: userObject } = useCachedUserItem({
      user: user,
      userId: userId,
    });

    log.silly(
      `Rendering ${prop} for UO:${_.get(userObject, prop)} vs PU:${_.get(
        user,
        'prop',
      )}`,
      {
        user: user,
        userId: userId,
        userObject: userObject,
      },
    );

    const propVal = _.get(userObject, prop) || _.get(user, prop);

    if (propVal && propVal !== -1) {
      if (_.isFunction(formatterFn)) {
        return formatterFn(propVal);
      }

      return <span>{propVal}</span>;
    }

    return <NA className={cx(truncate && 'truncate')} content={defaultText} />;
  },
);

WorkerProp.displayName = 'WorkerProp';

export default WorkerProp;
