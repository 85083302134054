import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import { filterButtonBase } from '#/ssm-ui/Buttons';

import { breakpoints } from '../breakpoints';

export const Dropdown: typeof SemanticDropdown = styled(SemanticDropdown)`
  // Style the base DDL Trigger
  &.ui.selection.dropdown {
    height: 38px;
    min-height: 38px;

    ${filterButtonBase}

    white-space: nowrap;

    i.icon,
    i.clear {
      margin-left: 5px;
      z-index: 2;
    }

    :hover,
    :focus,
    :active {
      border: solid 1px var(--borderDarkAlt) !important;
    }
  }

  // Style the "open" dropdown and menu contents
  &.ui.selection.active.dropdown {
    border: 1px solid #d4d4d5 !important;
    border-radius: 4px 4px 4px 4px !important;
    box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%),
      0 2px 10px 0 rgb(34 36 38 / 15%);

    .menu {
      margin-top: 8px;

      border: 1px solid #d4d4d5 !important;
      border-radius: 0.28rem;
      font-weight: 400;
      font-style: normal;
      box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%),
        0 2px 10px 0 rgb(34 36 38 / 15%);
      padding: 0 0 0 0;
      width: max-content;

      @media ${breakpoints.us} {
        max-width: calc(100vw - 20px);
        & > .item {
          padding-left: 0.5rem !important;
          padding-right: 0.5rem !important;
        }
      }

      .item.divider {
        pointer-events: none !important;
        height: 4px;
        background-color: var(--grey3);
        padding: 0 0 0 0 !important;
        margin: 0 0 0 0 !important;
      }
    }
  }

  &.ui.dropdown .divider.default.text {
    font-weight: 300;
    font-size: 14px;
    color: rgb(62, 63, 66, 0.4);
  }
`;
