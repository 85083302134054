import type { IThing, IShift } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import moment from 'moment';
import { dispatch } from 'rfx-core';
import flatten from 'flat';

import { currencyDropdownOptions } from '#/shared/forms/campaign';
import { shiftInviteModes } from '#/shared/stores/shifts';
import { getChildLogger } from '#/shared/utils/client.logger';
import { app } from '#/shared/app';

import { getFormDefProperties } from './address';
import Form from './_.extend';

const log = getChildLogger('forms.shift');

function formatDuration({ start, end }) {
  return (moment(end).diff(moment(start), 'minutes') / 60).toFixed(2);
}

const handleFormChanges = ({ form }) => {
  const enableCheckinCode = form.$('enableCheckinCode').value;

  // if enable checkin code is disabled, we can not ask for checkin code in checkin operation
  if (!enableCheckinCode) {
    form.$('requireCheckinCode').set(false);
    form.$('requireCheckoutCode').set(false);
  }
};

export class ShiftForm extends Form {
  hooks = () => ({
    onError: () => this.defaultErrorHook(),

    /** form.onSuccess
     * This method is only hit when EDITING an existing shift. If you are attempting
     * to find logic related to creating a new shift, please see `ui.ShiftFormNextGen.doSave`
     */
    async onSuccess(form) {
      const storeName = 'shifts';
      const uuid = form.$('uuid').value || undefined;
      const storeAction = uuid ? `${storeName}.update` : `${storeName}.create`;

      let data = form.values();

      data = this.mapMomentBackToDate(data);
      if (uuid) {
        data = this.removePristineNestedFields(data);
      }

      if (data.company) {
        data.company = _.get(data.company, 'uuid', data.company);
      }
      const timezone = data.timezone || form.$('timezone').value;

      data.start = moment(form.$('start').value).toDate();
      data.end = moment(form.$('end').value).toDate();

      if (data.start || data.end) {
        data.duration = formatDuration({
          end: data.end,
          start: data.start,
        });
      }

      if (timezone) {
        data.start = moment
          .tz(moment(data.start).format('YYYY-MM-DDTHH:mm:ss'), timezone)
          .toDate();
        data.end = moment
          .tz(moment(data.end).format('YYYY-MM-DDTHH:mm:ss'), timezone)
          .toDate();
      }

      if (/^flexible$/i.test(form.initials()?.shiftScheduleType)) {
        if (data.shiftScheduleRules) {
          _.extend(data, flatten(_.pick(data, 'shiftScheduleRules')));
        }
        // Can't use raw duration because it is a string :(
        const minHoursDuration = moment(data.end).diff(
          data.start,
          'hours',
          true,
        );
        data['shiftScheduleRules.minHoursDuration'] = minHoursDuration;

        if (timezone) {
          const { workingHoursStart: whStart, workingHoursEnd: whEnd } =
            data.shiftScheduleRules ?? {};

          if (whStart) {
            data['shiftScheduleRules.workingHoursStart'] = moment
              .tz(moment(whStart).format('YYYY-MM-DDTHH:mm:ss'), timezone)
              .toDate();
          }
          if (whEnd) {
            data['shiftScheduleRules.workingHoursEnd'] = moment
              .tz(moment(whEnd).format('YYYY-MM-DDTHH:mm:ss'), timezone)
              .toDate();
          }
        }
        delete data.shiftScheduleRules;
      }

      const locationType = form.$('locationType').value;
      let addressObj;

      if (/^(site)$/.test(locationType)) {
        if (form.initials().isRemote) {
          data.isRemote = false;
        }
        if (!_.get(data, 'addressObj.uuid')) {
          delete data.addressObj;
        }
        delete data.address;
      } else if (/^(remote)$/.test(locationType)) {
        data.address = '';
        data.addressRef = null;
        data.formattedAddress = '';
        delete data.addressObj;
      } else if (form.$('address').isDirty) {
        // Fixes cloned shift changing original shift address
        delete data.addressRef;

        if (_.isObject(data.address)) {
          addressObj = data.address;
          delete data.address;
        }
      }

      const shiftScheduleType = data.shiftScheduleType;
      if (/^fixed$/i.test(shiftScheduleType)) {
        data.shiftScheduleRules = {};
      }

      let query = {};
      const sendDetailsUpdateNotification = dispatch(
        'ui.shiftFormNextGen.sendDetailsUpdateNotification',
      );
      if (sendDetailsUpdateNotification && uuid) {
        query = {
          $client: {
            sendDetailsUpdatedNotification: true,
          },
        };
      }

      if (!_.isEmpty(data?.preShiftSurveyDefinition?.uuid)) {
        data.preShiftSurveyDefinitions = [
          {
            title: data.preShiftSurveyDefinition.title,
            uuid: data.preShiftSurveyDefinition.uuid,
          },
        ];
        delete data.preShiftSurveyDefinition;
      }

      if (!_.isEmpty(data?.postShiftSurveyDefinition?.uuid)) {
        data.postShiftSurveyDefinitions = [
          {
            title: data.postShiftSurveyDefinition.title,
            uuid: data.postShiftSurveyDefinition.uuid,
          },
        ];
        delete data.postShiftSurveyDefinition;
      }

      if (_.has(data, 'enableCheckinSurvey') && !data.enableCheckinSurvey) {
        data.preShiftSurveyDefinitions = [];
        data.postShiftSurveyDefinitions = [];
        delete data.preShiftSurveyDefinition;
        delete data.postShiftSurveyDefinition;
      }

      // Cleanup unneeded props
      delete data.locationType;

      if (_.isEmpty(data)) {
        log.debug('No changes to save ...');
        dispatch('ui.snackBar.open', 'No changes made');
        dispatch('ui.createShiftModal.clear');
        dispatch('ui.shiftFormNextGen.clear', { saveInvites: true });
        dispatch('ui.loadingModal.close');
        return;
      }

      if (uuid) {
        const search = data.search;
        // Change to path (dotified) notation to support patching
        data = form.dotify(_.omit(data, ['search']));
        data.search = search;
      }

      // When setting a new (custom) address, we don't want the values dotified, as the
      // API hook relies on them being in an object format. The `shifts` store's `update`
      // method will move `data.address` to `data.addressObj`. We should probably just
      // start over; too much legacy code here
      if (!_.isEmpty(addressObj)) {
        data.address = addressObj;
      }

      try {
        const res = await dispatch(storeAction, { data, id: uuid, query });
        const currentStoreSelected = dispatch('shifts.retrieve', 'selected');
        if (
          currentStoreSelected &&
          res.uuid === currentStoreSelected.uuid &&
          res.updatedAt > currentStoreSelected.updatedAt
        ) {
          dispatch('shifts.setSelected', res);
        }
        dispatch('ui.createShiftModal.setLastSubmittedShift', res);
        dispatch('ui.snackBar.open', 'Shift Saved!');
        dispatch('ui.createShiftModal.clear');
        dispatch('ui.shiftFormNextGen.clear', { saveInvites: true });
      } catch (error) {
        log.error('Saving Shift Failed: ', error);
        form.invalidate(error.message);
        dispatch('ui.snackBar.error', error.message);
      } finally {
        dispatch('ui.loadingModal.close');
      }
    },
  });
}

const addressObjProps = getFormDefProperties<'addressObj'>({
  prefix: 'addressObj',
});

const fields = [
  'uuid',
  'title',
  'description',
  'company',
  'status',
  'start',
  'end',
  'duration',
  'startDayOffset',
  'endDayOffset',
  'autoLaunch',
  'hourStart',
  'hourEnd',
  'minutesStart',
  'minutesEnd',
  'slots',
  'currency',
  'rate',
  'pay',
  'bonus',
  'shiftScheduleType',
  'shiftScheduleRules.minHoursDuration',
  'shiftScheduleRules.durationIntervalInMinutes',
  'shiftScheduleRules.workingHoursStart',
  'shiftScheduleRules.workingHoursEnd',

  // Address
  'addressRef',
  'parentAddressRefs',
  'addressObj',
  // The name field is added from the ISite schema/form
  'addressObj.name',
  ...addressObjProps.fields,

  'formattedAddress',
  'inviteRadius',

  // Legacy Address Fields
  'address',
  'address.street1',
  'address.street2',
  'address.city',
  'address.state',
  'address.zip',
  'address.formattedAddress',
  'address.loc',

  'useFlatRatePay',
  'enablePartnerCancel',
  'disableShiftAcceptAfterStart',

  'options.inviteMode',
  'externalId',
  'externalLink',
  'externalLinkButtonText',
  'photoUploadRequired',
  'disablePartnerCancel',
  'enableCheckinCode',
  'requireCheckinCode',
  'requireCheckoutCode',
  'useUniqueAttendanceCodes',
  'enableCheckinSurvey',
  'postShiftSurveyDefinition.uuid',
  'postShiftSurveyDefinition.title',
  'preShiftSurveyDefinition.uuid',
  'preShiftSurveyDefinition.title',
  // 'repeats',
  'sendSMS',
  'sendReminder',
  'sendCheckinReminder',
  'remindInterval',
  'isWindow',
  'requireSameWorker',

  'locationType',
  'isRemote',
  // 'overtimePay',
  'defaultAssignmentAction',

  'search.tags',
  'positionId',

  'assignedUsers',

  'excludeFromDashboardCalc',
  'notificationPrefs',
  'enableBulkNotifications',
  'activatedAt',

  'roleId',
  'roleTitle',
  'shiftType',
  'appActionType',
  'isFirstShift',
  'isBillable',

  'timezone',
] as const;

const defaultRules = {
  appActionType: 'string',
  bonus: 'numeric|min:0',
  company: 'string',
  currency: 'string',
  description: 'string:min:5',
  disablePartnerCancel: 'boolean',
  duration: 'required|numeric|max:48',
  enableCheckinCode: 'boolean',
  enableCheckinSurvey: 'boolean',

  end: 'required|date|is_valid_date',

  endDayOffset: 'numeric',

  excludeFromDashboardCalc: 'boolean',

  // 'options.inviteMode': 'string',
  externalId: 'string',
  externalLink: 'string',

  externalLinkButtonText: 'string',

  hourEnd: 'numeric',

  hourStart: 'numeric',

  inviteRadius: 'numeric',

  isBillable: 'boolean',

  isFirstShift: 'boolean',
  minutesEnd: 'numeric',

  minutesStart: 'numeric',
  pay: 'numeric|min:0',
  photoUploadRequired: 'boolean',
  positionId: 'string',
  rate: 'numeric|min:0',
  remindInterval: 'numeric|min:0|max:144',
  requireCheckinCode: 'boolean',
  requireCheckoutCode: 'boolean',
  requireSameWorker: 'boolean',
  roleId: 'string',
  sendCheckinReminder: 'boolean',
  sendReminder: 'boolean',

  'shiftScheduleRules.durationIntervalInMinutes': 'numeric',
  'shiftScheduleRules.minHoursDuration': 'numeric',
  'shiftScheduleRules.workingHoursEnd':
    'required_if:shiftScheduleType,flexible|date|is_valid_date',
  'shiftScheduleRules.workingHoursStart':
    'required_if:shiftScheduleType,flexible|date|is_valid_date',
  shiftScheduleType: 'string',
  shiftType: 'string',
  slots: 'required|numeric|min:1',
  start: 'required|date|is_valid_date',
  startDayOffset: 'numeric',
  status: 'string',
  timezone: 'string',
  title: 'required|string|between:5,100',
  uuid: 'string',
};

const defaultLabels = {
  appActionType: 'APP ACTION TYPE',
  bonus: 'SHIFT BONUS',
  currency: 'CURRENCY',
  description: 'SHIFT DESCRIPTION',
  duration: 'duration',
  enableCheckinCode: 'Enable Checkin Code',
  enableCheckinSurvey: 'Require a survey before user can check into shift',
  end: 'END',
  endDayOffset: 'END DAY',
  externalId: 'EXTERNAL ID',
  externalLink: 'EXTERNAL LINK',
  externalLinkButtonText: 'EXTERNAL LINK BUTTON TEXT (OPTIONAL)',
  hourEnd: 'END HOUR',
  hourStart: 'START HOUR',
  inviteRadius: 'Invite Radius (miles)',
  isBillable: 'SHIFT IS BILLABLE',
  isFirstShift: 'FIRST SHIFT?',
  minutesEnd: 'END MINUTE',
  minutesStart: 'START MINUTE',
  'options.inviteMode': 'Invite Mode',
  pay: 'FIXED WAGE',
  photoUploadRequired: 'Require Photo Upload on Checkin',
  positionId: 'Position',
  rate: 'Hourly WAGE',
  requireCheckinCode: 'Require Checkin Code',
  requireCheckoutCode: 'Require Checkout Code',
  'shiftScheduleRules.durationIntervalInMinutes': 'TIME INTERVAL',
  'shiftScheduleRules.minHoursDuration': 'MINIMUM SHIFT DURATION',
  'shiftScheduleRules.workingHoursEnd': 'WORKING HOURS END',
  'shiftScheduleRules.workingHoursStart': 'WORKING HOURS START',
  shiftScheduleType: 'Schedule Type',
  slots: 'NUMBER OF SLOTS',
  start: 'START',

  startDayOffset: 'START DAY',
  status: 'SHIFT TEMPLATE STATUS ',
  timezone: 'timezone',
  title: 'SHIFT TITLE',
  useFlatRatePay: 'Wage Type',
  useUniqueAttendanceCodes: 'Generate Unique Codes',
};

const defaultValues = {
  address: {
    city: '',
    formattedAddress: '',
    loc: '',
    state: '',
    street1: '',
    street2: '',
    zip: '',
  },
  assignedUsers: [],
  currency: 'usd',
  defaultAssignmentAction: 'invite',
  disablePartnerCancel: false,
  disableShiftAcceptAfterStart: false,
  duration: 8,
  enableCheckinCode: false,
  enableCheckinSurvey: false,
  enablePartnerCancel: false,

  end: moment().add(4, 'hours').startOf('hour').toDate(),

  excludeFromDashboardCalc: false,

  formattedAddress: '',

  parentAddressRefs: [],

  // repeats: 'oneTimeOnly',
  photoUploadRequired: false,

  // overtimePay: true,
  positionId: '',

  requireCheckinCode: false,

  requireCheckoutCode: false,

  requireSameWorker: true,

  roleId: '',

  sendCheckinReminder: false,

  sendReminder: false,

  sendSMS: false,
  shiftScheduleRules: {
    durationIntervalInMinutes: 60,
    minHoursDuration: 8,
    workingHoursEnd: moment().add(4, 'hours').startOf('hour').toDate(),
    workingHoursStart: moment().add(1, 'hour').startOf('hour').toDate(),
  },
  shiftScheduleType: 'fixed',
  shiftType: 'standard',

  slots: 1,

  start: moment().add(1, 'hour').startOf('hour').toDate(),
  status: 'Active',
  useFlatRatePay: false,
  useUniqueAttendanceCodes: false,
};

const handleTimeChange = ({ field, change }) => {
  // EP-4748 Listen for `showError` changes and make sure
  // it is not set to false when the field still has an error.
  if (/\.(end)$/.test(field.path)) {
    if (change.newValue !== field.hasError) {
      field.showErrors(field.hasError);
    }
  }
};

const shiftTemplateStatusOptions = [
  {
    key: '0',
    text: 'Active',
    value: 'Active',
  },
  {
    key: '1',
    text: 'Draft',
    value: 'Draft',
  },
  {
    key: '2',
    text: 'Disabled',
    value: 'Disabled',
  },
];

const observers = {
  addressObj: [
    {
      call: async ({ form }) => {
        const addressObj = form.$('addressObj').value;

        try {
          if (_.has(addressObj, 'loc.coordinates')) {
            const { timezone } = await app()
              .service('addresses/lookup')
              .get('timezone', {
                query: {
                  coordinates: addressObj.loc.coordinates,
                },
              });

            log.debug('Loaded timezone for address', {
              addressId: addressObj?.uuid,
              timezone,
            });

            if (timezone !== form.$('timezone').value) {
              form.$('timezone').set(timezone);
            }
          }
        } catch (err) {
          log.error('Failed to lookup timezone', err);
        }
      },
      key: 'value',
    },
  ],
  enableCheckinCode: [
    {
      call: handleFormChanges,
      key: 'value',
    },
  ],
  end: [
    // { key: 'errorSync', call: handleTimeChange },
    { call: handleTimeChange, key: 'showError' },
  ],
  formattedAddress: [
    {
      call: ({ form }) => {
        if (form.$('locationType').value !== 'custom') {
          return;
        }

        if (form.$('formattedAddress').isPristine) {
          // Reset the Address Fields if the change is reverted
          if (form.$('addressRef').initial) {
            form.select('addressRef', null, false)?.reset?.();
            form.select('address', null, false)?.reset?.();
          }
          return;
        }

        // Clear the Address Ref when the custom address changes
        form.$('addressRef').set('value', null);
      },
      key: 'value',
    },
  ],
};

const placeholders = {
  externalLink: 'https://google.com',
  externalLinkButtonText: 'Open External Link',
  timezone: 'select to override default location timezone',
};

const extra = {
  currency: {
    options: currencyDropdownOptions,
  },
  'options.inviteMode': {
    options: _.map(shiftInviteModes, (mode) => _.omit(mode, 'description')),
  },
  'shiftScheduleRules.minHoursDuration': {
    options: [
      {
        content: '30 Minutes',
        key: '0.5',
        text: '30 Minutes',
        value: 0.5,
      },
      {
        content: '1 hour',
        key: '1',
        text: '1 hour',
        value: 1,
      },
      {
        content: '2 hours',
        key: '2',
        text: '2 hours',
        value: 2,
      },
      {
        content: '3 hours',
        key: '3',
        text: '3 hours',
        value: 3,
      },
      {
        content: '4 hours',
        key: '4',
        text: '4 hours',
        value: 4,
      },
    ],
  },
  shiftScheduleType: {
    options: [
      {
        content: 'Fixed Time',
        key: 'fixed',
        text: 'Fixed Time',
        value: 'fixed',
      },
      {
        content: 'Flexible Time',
        key: 'flexible',
        text: 'Flexible Time',
        value: 'flexible',
      },
    ],
  },
  status: {
    options: shiftTemplateStatusOptions,
  },
  useFlatRatePay: {
    options: [
      { key: 0, text: 'Hourly', value: false },
      {
        key: 1,
        text: 'Flat Rate',
        value: true,
      },
    ],
  },
};

export function init<T extends IThing = IShift>(
  values: Partial<T> = {},
  cxOptions?: { rules?: Record<(typeof fields)[0], string> },
) {
  const vals = _.extend(
    {},
    defaultValues,
    _.pick(values, _.union(fields, ['__t'])),
  );

  const { rules, ...opts } = cxOptions ?? {};

  const form = new ShiftForm(
    {
      extra,
      fields: [...fields],
      labels: defaultLabels,
      observers,
      placeholders,
      rules: _.extend({}, defaultRules, rules),
      values: vals,
    },
    {
      ...opts,
      options: {
        retrieveOnlyDirtyValues: !!values?.uuid,
        showErrorsOnChange: false,
      },
    },
  );

  log.debug('Created Shift Form with Values: ', form.initials());

  return form;
}

export { formatDuration };

export type { ShiftForm as IShiftForm };
