import type { IStore } from '@shiftsmartinc/shiftsmart-types';

import { action, observable } from 'mobx';

/**
 * Store for UI Modal where user can add a storeList to a store
 *
 * @export
 * @class StoreListModal
 */

export default class StoreListModal {
  @observable
  isOpen = false;

  @observable
  store: IStore = {};

  @action
  setup({ store = {}, open = this.isOpen }) {
    this.store = store;

    this.isOpen = open;
  }

  @action
  clear() {
    this.store = {};

    this.isOpen = false;
  }
}
