import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import {
  DEPLOYMENT_ENV,
  IS_DEVELOPMENT,
  IS_PRODUCTION,
  APP_VERSION,
} from '#/config/settings';
import { getChildLogger } from '#/shared/utils/client.logger';

const logger = getChildLogger('datadog');

/**
 * @external https://docs.datadoghq.com/logs/log_collection/javascript/
 * @external https://docs.datadoghq.com/real_user_monitoring/browser/
 * @external https://docs.datadoghq.com/integrations/rum_react/
 */
export const init = () => {
  if (IS_DEVELOPMENT) logger.log(`🐶 Datadog (client)`);

  // Setup
  const SAMPLE_RATE = IS_PRODUCTION ? 20 : 0;

  // Startup
  datadogLogs.init({
    clientToken: 'pub47797fb3b5e2742b6d497c9d553edc58',
    env: DEPLOYMENT_ENV,
    forwardErrorsToLogs: true,
    sampleRate: SAMPLE_RATE,
    service: 'employer.shiftsmart.com',
    sessionSampleRate: SAMPLE_RATE,
    site: 'datadoghq.com',
    telemetrySampleRate: SAMPLE_RATE,
    version: APP_VERSION,
  });

  datadogRum.init({
    applicationId: '9bf28aba-61bf-47bc-bf37-58b38df439ff',
    clientToken: 'pub47797fb3b5e2742b6d497c9d553edc58',
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['clickmap'],
    env: DEPLOYMENT_ENV,
    sampleRate: SAMPLE_RATE,
    service: 'employer.shiftsmart.com',
    sessionReplaySampleRate: SAMPLE_RATE,
    sessionSampleRate: SAMPLE_RATE,
    site: 'datadoghq.com',
    telemetrySampleRate: SAMPLE_RATE,
    traceSampleRate: SAMPLE_RATE,
    trackFrustrations: true,
    trackInteractions: true,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version: APP_VERSION,
  });
};
