import type { IBaseItem, RuleAction } from '@shiftsmartinc/shiftsmart-types';

import { useCallback, useEffect, useState } from 'react';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { useStores } from '#/shared/hooks/useStores';
import { app as client } from '#/shared/app';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('hooks.useAuth');

type IUseAuthHook = {
  (): boolean;
  (
    action: RuleAction,
    subject: string | { $or: Array<string> },
    fields?: string,
    skip?: boolean,
  ): boolean;
  <T extends IBaseItem>(
    action: RuleAction,
    subject: T,
    fields?: string,
    skip?: boolean,
  ): boolean;
};

export const useAuth: IUseAuthHook = <T extends IBaseItem | unknown>(
  action?: RuleAction,
  subject?: string | { $or: Array<string> } | T,
  fields?: string,
  skip?: boolean,
) => {
  if (skip) {
    return undefined;
  }
  const stores = useStores();
  const { app, auth, abilities, routing } = stores;

  const [isAuthorized, setIsAuthorized] = useState(false);

  const runAuthCheck = useCallback(() => {
    if (auth.check && !abilities.session) {
      log.error('Abilities not initialized');
      return;
    }
    const redirect = app.getPathname();

    log.info(`Running auth check on path "%s"`, redirect);
    if (!auth.check) {
      log.warn('User is not authenticated');
      setIsAuthorized(false);
      routing.push(
        '/auth',
        redirect && !/unknown/.test(redirect) ? { redirect } : undefined,
      );
    } else if (action || subject) {
      let cannot = false;

      if (_.isObject(subject) && subject?.$or) {
        cannot = !_.some(subject.$or, (val) =>
          abilities.can(action, val, fields),
        );
      } else if (_.isString(subject)) {
        cannot = abilities.cannot(action, subject, fields);
      }

      if (cannot) {
        setIsAuthorized(false);

        if (process.env.NODE_ENV !== 'production') {
          dispatch('ui.snackBar.error', `NO ACCESS to "${redirect}" [UA]`, {
            body: `Logged in user does not have access to this route. This message is only shown in dev`,
          });
        }

        const canAccessPath = !!redirect && abilities.can('access', redirect);

        routing.push(`/not-found`, canAccessPath ? { redirect } : undefined);
      } else {
        setIsAuthorized(true);
      }
    }
    // action, subject, fields are the primary dependencies, as stores are not expected to change.
  }, [action, subject, fields, abilities.session, auth, app, routing]);

  useEffect(() => {
    runAuthCheck();
    // Only run the auth-check when the `subject` changes, other changes are handled separately
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  useEffect(() => {
    client().on('abilities:loaded', runAuthCheck);
    return () => {
      client().off('abilities:loaded', runAuthCheck);
    };
  }, []);

  return isAuthorized;
};
