import { action, observable } from 'mobx';
import _ from 'lodash';

import { init as initForm } from '#/shared/forms/experience';

export default class ExperienceEditModal {
  @observable
  isOpen = false;

  @observable
  form = {};

  @action
  setup({ position = {}, worker = {}, open = this.isOpen }) {
    const user = {};
    if (!_.isEmpty(_.get(worker, 'uuid', worker))) {
      user.user = _.get(worker, 'uuid', worker);
    }
    const values = _.extend(position, user);
    this.form = initForm(values);
    this.isOpen = open;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    this.isOpen = false;
    this.form.clear();
    this.form = {};
  }
}
