import { observable, action, set, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import _ from 'lodash';
import moment from 'moment';

import BaseUploader, {
  KnownFieldConfig,
} from '#/shared/stores/ui/BaseUploader';

export default class PartnerStatsUploader extends BaseUploader {
  constructor() {
    super({ title: 'ui.partnerStatsUploader' });

    set(this.saveResults, {
      dupe: [],
      invalid: [],
      new: [],
      unassigned: [],
    });

    return this;
  }

  @observable parseOptions = {
    dynamicTyping: false,
    header: false,
    skipEmptyLines: true,
  };

  /** list
   * Stores an array of rows, parsed into campaign quota format.
   *
   * Ready for saving.
   */
  @observable
  list = [];

  @observable
  userCompanyPairs = [];

  @observable
  userIDs = [];

  @observable
  keyCounts = {};

  @observable
  newRecords = [];

  @observable
  clearExistingUserCompanyData = true;

  csvData = [
    [
      'user',
      'company',
      'date',
      'messageRead',
      'openShiftPickup',
      'hoursPerWeek',
      'reliability',
      'onTime',
      'adherence',
      'attendance',
      'ratingCt',
      'rating',
      'shiftCt',
    ],
    [
      'a5311239-ee50-4203-85fc-35d79920db4c',
      '353d4ab4-52ce-462f-adb6-533dcba39892',
      '10/6/19',
      '0.7078',
      '0.2414',
      '0',
      '0.0822',
      '0.8461',
      '0.7522',
      '0.8852',
      '0.9645',
      '0',
      '4.8',
      '0',
    ],
  ];

  @action
  setup(...args) {
    super.setup(...args);

    this.clear();
  }

  @action
  clear() {
    super.clear();

    this.saveResults = {
      dupe: [],
      invalid: [],
      new: [],
      unassigned: [],
    };

    this.list.clear();
    this.userCompanyPairs.clear();
    this.userIDs.clear();
    this.newRecords.clear();
    this.keyCounts = {};
  }

  @action
  async processFile({ options = this.parseOptions, ...rest }) {
    this.setStatus('loading');

    try {
      await super.processFile({ options, ...rest });

      this.setStatus('loaded');
    } catch (err) {
      this.log.error('Failed to process file', err);

      this.setStatus('error');

      throw err;
    }
  }

  knownHeaders = [
    'user',
    'company',
    'date',
    'messageRead',
    'openShiftPickup',
    'hoursPerWeek',
    'reliability',
    'onTime',
    'adherence',
    'attendance',
    'ratingCt',
    'rating',
    'shiftCt',

    // Non-Preferred Keys
    'userID',
    'companyID',
  ];

  get knownFields(): KnownFieldConfig[] {
    return [
      { key: 'date', transform: (val) => moment(val, this.formats).toDate() },
    ];
  }

  @action
  async parse({ rows }) {
    const casedHeaders = _.map(rows[0], _.toLower);
    const indices = _.reduce(
      this.knownHeaders,
      (acc, header) => {
        acc[header] = _.indexOf(casedHeaders, _.toLower(header));
        return acc;
      },
      {},
    );

    this.log.debug('Loaded Table Indices', { indices });

    const userCompanyPairs = new Set([]);
    const userIDs = new Set([]);
    const keyCounts = {};

    const validRows = _(rows)
      .map((row, i) => {
        if (!i) return null;

        this.log.debug(row);

        const user = _.get(row, indices.user) || _.get(row, indices.userID);
        const company =
          _.get(row, indices.company) || _.get(row, indices.companyID);

        const userCompanyKey = JSON.stringify({ company, user });

        userCompanyPairs.add(userCompanyKey);
        userIDs.add(user);

        keyCounts[userCompanyKey] = (keyCounts[userCompanyKey] || 0) + 1;

        return _.chain(this.knownHeaders)
          .reduce((acc, colKey) => {
            const colIndex = indices[colKey];

            if (colIndex >= 0) {
              acc[colKey] = row[colIndex];
            }

            return acc;
          }, {})
          .mapKeys((val, key) => key.replace(/id$/i, ''))
          .value();
      })
      .reject((rowObj) => _.isEmpty(_.omitBy(rowObj, _.isEmpty)))
      .map((data) => this.doMapping({ data, mapping: this.knownFields }))
      .compact()
      .value();

    this.setStatus('Evaluating');

    // eslint-disable-next-line no-console
    console.table(validRows);

    runInAction(() => {
      this.list.replace(validRows);
      this.userCompanyPairs.replace(
        _.map(Array.from(userCompanyPairs), JSON.parse),
      );
      this.userIDs.replace(Array.from(userIDs));
      set(this.keyCounts, keyCounts);
    });

    this.setStatus('Parsed all Rows');
  }

  @action
  async save() {
    this.setStatus('saving');
    this.setSaving(true);

    dispatch('audit.create', {
      data: {
        action: 'Upload Partner Stats',
        extra: {
          file: _.pick(this.file, ['name', 'type', 'size']),
        },
      },
    });
    try {
      if (this.clearExistingUserCompanyData) {
        await Promise.map(
          this.userCompanyPairs,
          async (pair) => {
            this.log.debug(
              `Removing User/Company Pair: ${JSON.stringify(pair)}`,
            );

            if (_.isEmpty(pair.user) || _.isEmpty(pair.company)) {
              this.log.error('User Company Pair is invalid', { pair });
              return;
            }
            await dispatch('userStatsHistory.remove', null, {
              multi: true,
              query: pair,
            });
          },
          { concurrency: 20 },
        );
      }

      const newEntries = await Promise.map(
        this.list,
        (entry) => {
          const res = dispatch('userStatsHistory.create', { data: entry });
          this.log.debug('Created new USH Record', { data: entry, res });
          return res;
        },
        { concurrency: 20 },
      );

      this.log.debug('Created %d new entries', _.size(newEntries));
      // eslint-disable-next-line no-console
      console.table(newEntries);

      const res = await Promise.map(
        this.userCompanyPairs,
        async (pair) => {
          this.log.debug('Recalculating Stats for User/Company Pair', { pair });
          return dispatch('userStats.recalculate', pair);
        },
        { concurrency: 10 },
      );

      // eslint-disable-next-line no-console
      console.table(res);

      runInAction(() => {
        this.newRecords.replace(res);
      });

      this.setStatus('saved');
    } catch (err) {
      this.log.error('Failed to save user stats', err);

      dispatch('ui.snackBar.error', 'Sorry, something went wrong');
      this.setStatus('error');
    }

    this.setSaving(false);
  }
}
