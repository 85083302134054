import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { dispatch } from 'rfx-core';
import cx from 'classnames';
import { Button } from 'semantic-ui-react';

export const EditProfilePicButton = observer(
  ({
    user,
    wide = false,
    fluid = !wide,
    primary = true,
    basic = !wide,
    icon = 'id badge outline',
    adminLabelProps,
    className = cx(wide && 'dib', 'link'),
    content = 'Edit Pic',
    storeName,
    fileRef,
    ...rest
  }) => (
    <Button
      key="editProfilePic"
      {...rest}
      basic={basic}
      className={className}
      content={content}
      fluid={fluid}
      icon={icon}
      label={adminLabelProps || undefined}
      labelPosition={adminLabelProps ? 'right' : undefined}
      onClick={(e) => {
        e.stopPropagation();
        dispatch('ui.partnerAvatarModal.setup', {
          fileRef: _.isFunction(fileRef) ? fileRef() : fileRef,
          open: true,
          storeName,
          user,
        });
      }}
      primary={primary}
    />
  ),
);
EditProfilePicButton.displayName = 'EditProfilePicButton';
