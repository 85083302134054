import { action, observable } from 'mobx';

export default class WorkerSnoozeConfirm {
  @observable
  isOpen = false;

  @observable
  entity = {};

  @action
  setup({ worker, entity = worker, open = this.isOpen }) {
    this.entity = entity;
    this.isOpen = open;
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  clear() {
    this.entity = observable({});
    this.isOpen = false;
  }
}
