import { action, observable } from 'mobx';

export default class WorkerDetails {
  @observable
  doShowSkills = false;

  @action
  showSkills(arg = !this.doShowSkills) {
    this.doShowSkills = !!arg;
  }

  @action
  clear() {
    this.doShowSkills = false;
  }
}
