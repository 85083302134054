/**
 * UI Store for StoreList
 *
 * @export
 * @class StoreList
 *
 * TODO: Merge other StoreList related things here
 */
import type { IStoreList } from '@shiftsmartinc/shiftsmart-types';

import { action, observable } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';

import {
  StoreListForm,
  init as initStoreListForm,
} from '#/shared/forms/storeLists';
import { getChildLogger } from '#/shared/utils/client.logger';

export default class StoreLists {
  log = getChildLogger('ui.StoreLists');

  @observable
  isModalOpen = false;

  @observable
  isDeleteModalOpen = false;

  @observable
  isModalLoading = false;

  // Form
  @observable form: StoreListForm = initStoreListForm();

  @observable formErrors: { stores?: string; title?: string } = {};

  @action
  showModal(value: boolean) {
    this.isModalOpen = value;
  }

  @action
  showDeleteModal(value: boolean) {
    this.isDeleteModalOpen = value;
  }

  @action
  initForm(values) {
    this.form = initStoreListForm(values);
  }

  @action
  async openEdit({ uuid }) {
    const values = await dispatch('storeLists.get', uuid, {
      query: {
        $client: {
          loadStores: true,
        },
      },
      select: false,
    });

    const formValues = _.cloneDeep(values);
    formValues.stores = _.map(values.stores, 'uuid');

    this.initForm(formValues);
    this.showModal(true);
  }

  @action.bound
  validateForm() {
    const values = this.form.values();
    // TODO: Why isn't this automatically checked on init?
    if (_.isEmpty(values.title)) {
      this.formErrors.title = 'Please add a title';
      return false;
    }

    if (_.isEmpty(values.stores)) {
      this.formErrors.stores = 'Please add at least one store';
      return false;
    }

    return true;
  }

  @action.bound
  clearFormErrors() {
    this.formErrors = {};
  }

  @action.bound
  clearForm() {
    this.form.clear();
    this.clearFormErrors();
  }

  @action
  setModalLoading(value = false) {
    this.isModalLoading = value;
  }

  // TODO: Handle Errors
  @action
  async saveForm({ companyId }): Promise<IStoreList | unknown> {
    const log = this.log.getChildLogger('saveForm');
    try {
      this.setModalLoading(true);
      if (!this.validateForm()) {
        return null;
      }
      const values = this.form.values();
      const params = {
        companies: [companyId],
      };

      if (_.isEmpty(values.uuid)) {
        const result = await this.create(values, params);
        this.clearForm();
        this.showModal(false);
        dispatch('routing.push', `retail/storeLists/${result.uuid}`);

        return result;
      }

      const result = await this.update(values, params);
      this.clearForm();
      this.showModal(false);
      return result;
    } catch (error) {
      log.error('Error creating store list: ', error);
      dispatch('ui.snackBar.error', 'Failed to create store list!');
      return null;
    } finally {
      this.setModalLoading(false);
    }
  }

  @action
  handleDelete() {
    this.showDeleteModal(true);
  }

  @action
  async remove(storeListId): Promise<void> {
    const log = this.log.getChildLogger('remove');
    try {
      this.setModalLoading(true);
      await dispatch('storeLists.remove', storeListId);
      this.showDeleteModal(false);
      await dispatch('routing.push', `retail/storeLists`);
      await dispatch('storeLists.clearSelected');
    } catch (error) {
      log.error('Error deleting store list', error);
    } finally {
      this.setModalLoading(false);
    }
  }

  async create(values, params): Promise<IStoreList> {
    const data = { ...values, ...params };
    const result = await dispatch('storeLists.create', { data });

    return result;
  }

  async update(values, params): Promise<IStoreList> {
    const data = { ...values, ...params };

    const result = await dispatch('storeLists.update', {
      data,
    });

    // TODO: Sync selected on update
    const response = await dispatch('storeLists.get', values.uuid, {
      query: {
        $client: {
          loadStores: true,
        },
      },
      select: true,
    });

    return response;
  }
}
