import type { ICert } from '@shiftsmartinc/shiftsmart-types';

import BaseTagStore from './_baseTagStore';

export default class CertStore extends BaseTagStore<ICert> {
  constructor() {
    super({
      baseItem: {
        certType: 'cert',
        companies: [],
        createdAt: null,
        level: -1,
        linked: [],
        parent: null,
        title: null,
        updatedAt: null,
        users: [],
        uuid: null,
      },
      serviceName: 'certs',
    });

    return this;
  }
}
