import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { action, observable } from 'mobx';
import moment from 'moment';

import BaseStore from './_baseStore';

export type ICheckin = IBaseItem & Record<string, unknown>;

export default class CheckinStore extends BaseStore<ICheckin> {
  constructor() {
    const baseItem = {
      checkinType: null,
      createdAt: null,
      loc: {},
      ref: {
        refType: '',
        refUUID: '',
      },
      timestamp: null,
      updatedAt: null,
      user: null,
      uuid: null,
    };
    super({ baseItem, serviceName: 'checkins' });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  // @observable checkinsByUser = new Map();
  @observable
  checkinsByUser = {};

  @observable
  minDate = null;

  @observable
  maxDate = null;

  @observable
  dateRange = [];

  @action
  find(query, ...args) {
    _.set(this.query, 'query.$limit', 500);
    return super.find(query, ...args).finally(() => {
      this.updateSummaryStats();
    });
  }

  changeWeek({ weeks, start, end }) {
    const startDay = moment(start);

    if (!_.isUndefined(weeks)) {
      startDay.add(weeks, 'weeks');
    }

    const gte = startDay.startOf('week');

    const lte = end
      ? moment(end).add(weeks, 'weeks')
      : gte.clone().endOf('week');

    const query = {
      timestamp: {
        $gte: gte.toDate(),
        $lte: lte.toDate(),
      },
    };

    return this.find(query);
  }

  @action
  updateSummaryStats() {
    const min = _.minBy(this.list, (c) => c.timestamp);
    const max = _.maxBy(this.list, (c) => c.timestamp);
    const minDate = !min ? moment() : moment(min.timestamp);
    const maxDate = !max ? moment() : moment(max.timestamp);

    const { $gte: start = minDate, $lte: end = maxDate } = _.get(
      this,
      'query.query.timestamp',
      {},
    );

    this.minDate = moment(start).startOf('day');
    this.maxDate = moment(end).endOf('day');

    this.log.debug('Date Range: ', {
      max: this.maxDate.format('L LT ZZ'),
      min: this.minDate.format('L LT ZZ'),
    });

    this.dateRange = _.map(
      _.range(
        this.minDate.startOf('day').valueOf(),
        this.maxDate.endOf('day').valueOf(),
        1000 * 60 * 60 * 24,
      ),
      (k) => {
        this.log.debug('Day: ', k);
        return moment(k);
      },
    );

    const byUser = _.groupBy(this.list, 'user');

    _.map(
      byUser,
      action((checkins, user) => {
        const cis = [];

        _(checkins)
          .sortBy(['timestamp'])
          .each((ci) => {
            let last = _.last(cis);

            if (last && last.checkin && last.checkout) {
              last = null;
            }

            // if (last && last.checkin && ci.checkinType === 'checkout') {
            //   const ltc = moment(last.checkin);
            //   ltc.add(ltc.utcOffset(), 'minutes');
            //   const tc = moment(ci.timestamp);
            //   tc.add(tc.utcOffset(), 'minutes');
            //   if (!ltc.isSame(tc, 'day')) {
            //     last = null;
            //   }
            // }

            if (!last) {
              last = _.defaults(
                { [ci.checkinType]: ci },
                { checkin: null, checkout: null },
              );
              cis.push(last);
            } else {
              last[_.get(ci, 'checkinType')] = ci;
            }
          });
        this.checkinsByUser[user] = cis;
        //       this.checkinsByUser.set(user, cis);
      }),
    );
  }
}
