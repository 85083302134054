import type {
  IAssignment,
  IUser,
  IShift,
  ICompany,
} from '@shiftsmartinc/shiftsmart-types';

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import distance from '@turf/distance';
import { point, featureCollection } from '@turf/helpers';
import turfCenter from '@turf/center';
import turfExtent from 'turf-extent';
import { autorun } from 'mobx';
import { Icon } from 'semantic-ui-react';

import PopupIcon from '#/shared/components/ssm/PopupIcon';
import MapWrapper from '#/shared/components/utils/MapWrapper';
import { useStoreItem } from '#/shared/hooks/useStoreItem';
import { getChildLogger } from '#/shared/utils/client.logger';
import { useCachedUserItem, useCacheItem } from '#/shared/hooks/useCacheItem';

const log = getChildLogger('shifts.details.LocationWarningLabel');

/**
 * ## LocationWarningLabel
 *
 * @description When the worker checks in outside the shift's checkin radius, this
 * component will render a red globe indicating this status. On hover, the a tooltip
 * will either render the worker's location at checkin/checkout along with the
 * calculated distance between the worker and the shift, of, if no GPS information
 * was returned, a message indicating "No GPS information available"
 *
 * @param props.timecardEntry
 * @param props.assignment
 *
 * @returns {React.ReactElement}
 */
export const LocationWarningLabel = observer(
  ({
    timecardEntry,
    assignment,
  }: {
    assignment: Pick<IAssignment, 'ref' | 'user' | 'company'> & {
      /**
       * ### ref
       * Ref to the UUID of the linked Shift
       */
      ref: IAssignment['ref'];
      /**
       * ### refId
       * @deprecated please pass `ref` instead to be consistent with assignments schema
       */
      refId?: IAssignment['ref'];
      /**
       * ### user
       * The userId (user.uuid) of the assigned user
       */
      user: IUser['uuid'];
    };
    timecardEntry: IAssignment['checkIn'] | IAssignment['checkOut'];
  }) => {
    const { refId, ref = refId, user, company } = assignment;
    const { override: isOverride, loc: checkInOutLocation } = timecardEntry;
    const { item: shiftObj } = useStoreItem<IShift>({
      storeName: 'shifts',
      uuid: ref,
    }) as { item: IShift };

    const { item: workerObj } = useCachedUserItem({
      storeName: 'workers',
      user: _.isString(user) ? undefined : user,
      userId: (user as IUser)?.uuid ?? (user as IUser['uuid']),
    });

    const { item: companyObj } = useCacheItem<ICompany>({
      itemId: company,
      storeName: 'companies',
    });

    /**
     * Check if a location is valid
     * A location is invalid when the coordinates is either empty or [0, 0] or [null, null]
     * @param location
     * @returns boolen
     */
    const isValidLocation = (location) => {
      const coordinates = _.get(location, 'coordinates', [null, null]);
      if (!coordinates[0] && !coordinates[1]) {
        return false;
      }
      return true;
    };

    const shiftLocation = shiftObj.loc;

    const [milesAway, setMilesAway] = useState(0);
    const [mapViewport, setMapViewport] = useState({
      bounds: undefined,
      center: shiftLocation?.coordinates || checkInOutLocation?.coordinates,
    });

    useEffect(
      () =>
        autorun(() => {
          if (
            !isOverride ||
            !isValidLocation(shiftLocation) ||
            !isValidLocation(checkInOutLocation)
          ) {
            return;
          }

          const points = featureCollection([
            point(shiftLocation?.coordinates.slice()),
            point(checkInOutLocation?.coordinates.slice()),
          ]);

          setMapViewport({
            bounds: turfExtent(points),
            center: turfCenter(points)?.geometry?.coordinates,
          });

          setMilesAway(
            shiftLocation && checkInOutLocation
              ? distance(shiftLocation, checkInOutLocation, {
                  units: 'miles',
                })
              : -1,
          );
        }),
      [isOverride, shiftLocation, checkInOutLocation],
    );

    if (!isOverride) {
      return null;
    }

    /* TODO: load actual checkin radius setting */
    const checkinRadius =
      companyObj?.settings?.things?.customCheckinDistance ?? 1;

    log.silly('worker vs checkin', {
      checckinLoc: checkInOutLocation?.coordinates?.slice(),
      workerLoc: workerObj.loc?.coordinates?.slice(),
      workerObj,
    });

    const isOutsideRadius = milesAway > checkinRadius;

    /**
     * For valid location the map will be rendered on 1s delay
     * Otherwise the pop up content will be triggered immediately
     */
    return isValidLocation(checkInOutLocation) ? (
      <div className="flex">
        <div className="ph2">
          <PopupIcon
            color={isOutsideRadius ? 'red' : 'blue'}
            delay={{ open: 500 }}
            name="globe"
            offset={[-10, 0]}
            on={['click']}
            style={{ cursor: 'context-menu' }}
            tooltip={
              !_.isEmpty(mapViewport.bounds) && (
                <div className="flex-column flex">
                  <div className="h5 w5">
                    <MapWrapper
                      bounds={mapViewport.bounds}
                      center={mapViewport.center}
                      enable={true}
                      fitBoundsOptions={{ padding: 25 }}
                      fixed={true}
                      markers={({ thing, trigger }) => {
                        if (thing?.uuid === shiftObj.uuid)
                          return <Icon color="blue" name="building" />;
                        return trigger;
                      }}
                      selected={{ ...shiftObj, radius: checkinRadius * 5280 }}
                      useLegacy={true}
                      workers={[{ ...workerObj, loc: checkInOutLocation }]}
                      zoom={_.isEmpty(mapViewport.bounds) ? 10 : undefined}
                    />
                  </div>

                  <div className="b pt3 w-100 flex justify-between">
                    <div>
                      <div className="flex justify-start">
                        <div className="pr2">
                          <Icon inverted={true} name="bullseye" />
                        </div>
                        {checkinRadius} mi
                      </div>
                      <div className="f7 ttu">Checkin Radius</div>
                    </div>
                    <div
                      style={{
                        color: isOutsideRadius ? 'var(--red)' : 'white',
                      }}
                    >
                      <div className="flex justify-end">
                        {milesAway === -1 ? 'unknown' : milesAway.toFixed(2)} mi
                        <div className="pl2">
                          <Icon name="location arrow" />
                        </div>
                      </div>
                      <div className="f7 ttu">Reported Dist</div>
                    </div>
                  </div>
                </div>
              )
            }
          />
        </div>
      </div>
    ) : (
      <PopupIcon
        className="pl1"
        color="red"
        name="globe"
        tooltip={
          <p style={{ color: 'var(--red)' }}>No GPS Information Available</p>
        }
      />
    );
  },
);
