import { action, computed, observable } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';

export default class ChatTagsModal {
  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  selectedTags = [];

  @observable
  certType = 'messageTags';

  @observable
  channel = {};

  @observable
  bulkChannels = [];

  @observable
  storeName = 'messageTags';

  @observable
  tagName = 'Message Tag';

  @observable
  tagNamePlural = 'Message Tags';

  @observable
  company = {};

  @action
  setup({ open = this.isOpen, channel = {}, bulkChannels = [], company = {} }) {
    const channelTags = _.get(channel, 'tags', []);
    this.selectedTags = _.cloneDeep(channelTags);
    this.channel = channel;
    this.bulkChannels = bulkChannels;
    this.company = company;
    this.isOpen = open;
  }

  @action
  open() {
    this.isOpen = true;
  }

  @action
  clear() {
    this.channel = {};
    this.bulkChannels = [];
    this.isOpen = false;
    this.company = {};
  }

  @computed
  get addedTags() {
    let existingTags;
    if (this.channel) {
      existingTags = _.get(this.channel, 'tags', []);
    }
    return _.differenceBy(this.selectedTags, existingTags, 'uuid');
  }

  @computed
  get removedTags() {
    let existingTags;
    if (this.channel) {
      existingTags = _.get(this.channel, 'tags', []);
    }
    return _.differenceBy(existingTags, this.selectedTags, 'uuid');
  }

  @action.bound
  updateSelectedTags(tag) {
    const isSelected = this.selectedTags.find(
      (selectedTag) => selectedTag.uuid === tag.uuid,
    );
    if (isSelected) {
      this.selectedTags = this.selectedTags.filter(
        (selectedTag) => selectedTag.uuid !== tag.uuid,
      );
    } else {
      this.selectedTags.push(tag);
    }
  }

  @action.bound
  async submit() {
    const removedTags = _.map(this.removedTags, (tag) => ({
      title: tag.title,
      uuid: tag.uuid,
    }));
    const addedTags = _.map(this.addedTags, (tag) => ({
      title: tag.title,
      uuid: tag.uuid,
    }));
    if (_.isEmpty(addedTags) && _.isEmpty(removedTags)) {
      return this.clear();
    }

    // add tags to multiple channels in bulk
    if (this.bulkChannels.length > 0 && !_.isEmpty(addedTags)) {
      try {
        await Promise.map(this.bulkChannels, async (channel) => {
          const channelTags = _.get(channel, 'tags');
          const tagsToAdd = _.differenceBy(addedTags, channelTags, 'uuid');
          dispatch('chatChannels.update', {
            data: {
              $push: { tags: { $each: tagsToAdd } },
              uuid: channel.uuid,
            },
          });
        });
      } catch (error) {
        dispatch('ui.snackBar.open', error.message);
      }
      dispatch('chatChannels.clearSelectedChannels');
    }

    // add/remove tags to channels individually
    if (!this.bulkChannels.length) {
      try {
        await Promise.all([
          !_.isEmpty(addedTags) &&
            dispatch('chatChannels.update', {
              data: {
                $push: { tags: { $each: addedTags } },
                uuid: this.channel.uuid,
              },
            }),
          !_.isEmpty(removedTags) &&
            dispatch('chatChannels.update', {
              data: {
                $pull: {
                  tags: {
                    uuid: { $in: _.map(removedTags, 'uuid') },
                  },
                },
                uuid: this.channel.uuid,
              },
            }),
        ]);
      } catch (error) {
        dispatch('ui.snackBar.open', error.message);
      }
    }

    return this.clear();
  }
}
