import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { dispatch } from 'rfx-core';
import { action, observable, computed, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import Promise from 'bluebird';
import { Button, Icon } from 'semantic-ui-react';
import cx from 'classnames';
import styled from 'styled-components';

import PopupIcon from '#/shared/components/ssm/PopupIcon';
import { getChildLogger } from '#/shared/utils/client.logger';

let DownloadButton = styled(CSVLink)`
  height: 100%;
  padding: 13px 20px !important;
`;

const DownloadIcon = styled(Icon)`
  margin: 0 !important;
`;
const DownloadText = styled.span`
  margin-left: 5px;
`;
@inject('store')
@observer
export default class CSVDownloader extends Component {
  static propTypes = {
    buttonName: PropTypes.string,
    exportSelected: PropTypes.bool,
    getHeadersFn: PropTypes.func,
    headers: PropTypes.array,
    selectedItems: PropTypes.array,
    shouldDownloadAllPages: PropTypes.bool,
    sourceStore: PropTypes.object,
    store: PropTypes.object,
    storeName: PropTypes.string,
  };

  @computed
  get log() {
    if (!this._log) {
      this._log = getChildLogger(
        `${this.props.storeName || this.dataStore.title}.CSVDownloader`,
      );
    }
    return this._log;
  }

  @computed
  get dataStore() {
    const { store, sourceStore, storeName } = this.props;

    return _.isEmpty(sourceStore) ? store[storeName] : sourceStore;
  }

  @computed
  get icon() {
    switch (this.status) {
      case 'loading':
      case 'parsing':
        return 'refresh';
      case 'error':
        return 'exclamation circle';
      case null:
      case 'ready':
      default:
        return 'download';
    }
  }

  @computed
  get color() {
    switch (this.status) {
      case 'ready':
      case 'parsing':
      case 'loading':
        return 'green';
      case 'error':
        return 'red';
      case null:
      default:
        return undefined;
    }
  }

  @computed
  get onClick() {
    switch (this.status) {
      case null:
        return this.props.exportSelected
          ? this.parseSelected
          : this.captureData;
      case 'ready':
        return this.downloadData;
      default:
        return _.noop;
    }
  }

  @observable
  csvData = '';

  @observable
  status = null;

  @action
  setStatus(status) {
    this.status = status || null;
  }

  @action parseSelected = async () => {
    try {
      this.setStatus('loading');
      const headers =
        this.props.headers ||
        (_.isFunction(this.props.getHeadersFn) && this.props.getHeadersFn());
      const parserOpts = {
        fields: headers,
      };
      const parser = new Parser(parserOpts);
      const csvData = parser.parse(this.props.selectedItems);

      runInAction(() => {
        this.csvData = csvData;
      });

      this.setStatus('ready');
      dispatch('ui.snackBar.open', 'Your Data is Ready', {
        body: 'Please click the button again to download search results as a CSV',
      });
    } catch (err) {
      this.log.error('Failed to capture data', err);
      dispatch('ui.snackBar.error', err.message);

      this.setStatus('error');
    }
  };

  @action
  captureData = async () => {
    try {
      this.setStatus('loading');
      dispatch('ui.loadingModal.open', {
        message: `Loading ${_.startCase(this.props.storeName)}`,
      });

      const results = [];

      const { query } = _.cloneDeep(this.dataStore.query);
      query.$skip = 0;
      const { data, total, limit } = await this.dataStore.runQuery(query);
      results.push(...data);

      dispatch('ui.loadingModal.increment', {
        complete: 1,
        total: Math.ceil(total / _.size(data)),
      });

      if (this.props.shouldDownloadAllPages) {
        const batches = Math.ceil(total / limit);
        await Promise.map(
          _.range(1, batches),
          async (batch) => {
            query.$skip = batch * limit;
            const result = await this.dataStore.runQuery(query);
            results.push(...result.data);

            dispatch('ui.loadingModal.increment', {});
          },
          { concurrency: 1 },
        );
      }

      this.setStatus('parsing');
      dispatch('ui.loadingModal.open', {
        message: `Parsing ${_.startCase(this.props.storeName)}`,
        total: 0,
      });

      const headers =
        this.props.headers ||
        (_.isFunction(this.props.getHeadersFn) && this.props.getHeadersFn());

      const parserOpts = { fields: headers };
      const parser = new Parser(parserOpts);
      const csvData = parser.parse(results);

      runInAction(() => {
        this.csvData = csvData;
      });

      this.setStatus('ready');
      dispatch('ui.loadingModal.close', {
        delay: 1000,
        message: 'Data Generation Complete...',
      });
      dispatch('ui.snackBar.open', 'Your Data is Ready', {
        body: 'Please click the button again to download search results as a CSV',
      });
    } catch (err) {
      this.log.error('Failed to capture data', err);
      dispatch('ui.loadingModal.close', {
        delay: 500,
        message: 'Data Generation Failed',
      });
      dispatch('ui.snackBar.error', err.message);

      this.setStatus('error');
    }
  };

  downloadData = () => {
    setTimeout(() => this.setStatus(null), 500);
  };

  render() {
    if (!this.props.exportSelected) {
      DownloadButton = styled(CSVLink)``;
    }

    const downloadFileName =
      this.props?.downloadFileName ??
      `company_${this.props.storeName}_${moment().toISOString()}.csv`;
    return !/^ready$/i.test(this.status) ? (
      <PopupIcon
        tooltip={
          !this.props.shouldDownloadAllPages && !this.status
            ? `Download the current page data as CSV`
            : null
        }
        trigger={
          <Button
            basic={/loading/.test(this.status) || this.props.exportSelected}
            loading={/loading/.test(this.status)}
            onClick={this.onClick}
          >
            <DownloadIcon color={this.color} name={this.icon} />
            {this.props.buttonName && (
              <DownloadText className="b">{this.props.buttonName}</DownloadText>
            )}
          </Button>
        }
      />
    ) : (
      <div onClick={() => this.downloadData()} role="button" tabIndex={-42}>
        <DownloadButton
          className={cx('ui button', this.color)}
          data={this.csvData}
          filename={downloadFileName}
          target="_blank"
        >
          <DownloadIcon name="download" />
          {this.props.buttonName && (
            <DownloadText className="b">{this.props.buttonName}</DownloadText>
          )}
        </DownloadButton>
      </div>
    );
  }
}
