import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class ActivateShiftModal {
  log = getChildLogger('ui.ActivateShiftModal');

  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  shift = {};

  @action
  async setup({ shift = {}, open = this.isOpen }) {
    this.set('shift', shift);
    this.open(open);
    this.set('isLoading', false);
  }

  @action
  async activate() {
    const shift = this.shift;
    if (_.isEmpty(shift)) {
      this.log.warn('No shift specified. Cannot activate empty shift obj.');
    }

    const data = {
      status: 'Active',
      uuid: shift.uuid,
    };

    dispatch('ui.loadingModal.open', { message: 'Activating' });
    try {
      await dispatch(`${_.get(shift, '__t')}s.update`, { data });
    } catch (err) {
      this.log.error(err);
      this.log.error('Failed to activate shift: ', shift);
    }
    dispatch('ui.loadingModal.close');
    dispatch('ui.activateShiftModal.clear');
  }

  @action
  clear(args = {}) {
    this.isOpen = !!args.open;
    this.isLoading = false;
    this.shift = {};
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    _.set(this, key, value);
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }
}
