import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';

import BaseStore from './_baseStore';

export type IPaymentUploader = IBaseItem & Record<string, unknown>;

export default class PaymentUploaderStore extends BaseStore<IPaymentUploader> {
  constructor() {
    const baseItem = { csvFile: '' };
    super({ baseItem, serviceName: 'paymentUploader' });

    this.selected = _.clone(this.baseItem);

    return this;
  }
}
