import type { IVideoInterviewQuestion } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export default class VideoInterviewQuestionStore extends BaseStore<IVideoInterviewQuestion> {
  constructor() {
    const baseItem = {
      createdAt: null,
      question: null,
      reviewQuestions: null,
      updatedAt: null,
      userId: null,
      uuid: null,
    };
    super({ baseItem, serviceName: 'videoInterviewQuestions' });

    return this;
  }
}
