import { action, observable, computed } from 'mobx';
import { dispatch } from 'rfx-core';
import moment from 'moment';
import _ from 'lodash';

import { init as initForm } from '#/shared/forms/schedulingTemplate';
import { formatDateRange } from '#/utils/date';

/** @deprecated 2022-09-26 in favor of recurring schedules */
export default class CreateSchedulingTemplateModal {
  @observable
  isOpen = false;

  @observable
  form = {};

  @observable
  reviewItems = [];

  @observable
  isReviewing = false;

  @observable
  isUploading = false;

  @observable
  isUpload = false;

  @observable
  isComplete = false;

  @observable
  isLoadingPreview = false;

  @observable
  previewShifts = [];

  @observable
  previewDate = moment().startOf('week');

  @action
  setup({ open = this.isOpen, template = {} }) {
    // Reset isComplete on setup rather than onClose,
    // So UI doesnt flash beginning state when close/clear
    

    this.isComplete = false;
    this.isOpen = open;
    this.form = initForm(template);
    dispatch('ui.createSchedulingTemplateModal.fetchTemplatePreview');
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    this[key] = value;
  }

  @action
  setReview(items) {
    if (!_.isEmpty(items)) {
      this.reviewItems.replace(items);
      this.isReviewing = true;
    } else {
      log.debug('No reviewItems, submitting');
      this.submit();
    }
  }

  @action
  setComplete(complete = true) {
    this.isComplete = complete;
    if (complete) {
      this.isReviewing = false;
      this.reviewItems = [];
    }
  }

  @action
  async validateForm() {
    try {
      const result = await this.form.validate({ showErrors: true });
      if (result.isValid) {
        return Promise.resolve({ result });
      }
      return Promise.reject({ result });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  @computed
  get createData() {
    const data = this.form.values();
    return {
      title: data.title,
      description: data.description,
      start: moment(data.start).startOf('day').toDate(),
      end: moment(data.start)
        .add(
          this.ranges[data.duration].duration,
          this.ranges[data.duration].unit,
        )
        .startOf('day')
        .toDate(),
      saveDraftShifts: data.saveDraftShifts,
      position: data.position,
    };
  }

  @action
  async review() {
    try {
      await this.validateForm();
      const res = await dispatch('schedulingTemplates.createFromRange', {
        ...this.createData,
        reviewOnly: true,
      });
      this.setReview(res);
    } catch (err) {
      log.error('Failed to review scheduling template form', err);
    }
  }

  @action
  async submit() {
    try {
      if (this.isUpload) {
        await dispatch('schedulingTemplates.createFromShifts', {
          ...this.createData,
          shifts: this.previewShifts,
        });
      } else {
        await dispatch('schedulingTemplates.createFromRange', {
          ...this.createData,
          reviewOnly: false,
        });
      }
      this.setComplete(true);
    } catch (err) {
      log.error('Failed to create scheduling template', err);
    }
  }

  @action
  async fetchTemplatePreview(position) {
    this.set('isLoadingPreview', true);
    try {
      const company = dispatch('auth.getCompany');
      const data = this.form.values();
      const excludeShifts = ['Canceled', 'Expired', 'Deleted'];

      if (!data.saveDraftShifts) excludeShifts.push('Draft');
      const shiftQuery = {
        company: company.uuid,
        status: {
          $nin: excludeShifts,
        },
        start: {
          $gte: moment(data.start).startOf('day').toDate(),
          $lt: moment(data.start)
            .add(
              this.ranges[data.duration].duration,
              this.ranges[data.duration].unit,
            )
            .startOf('day')
            .toDate(),
        },
        $limit: 1000,
      };
      if (position) {
        shiftQuery['search.tags'] = position;
      }


      log.debug('querying template preview shifts: ', shiftQuery);
      const rawShifts = await dispatch('shifts.runQuery', shiftQuery);
      const userIds = _(rawShifts.data)
        .map('assignedUsers')
        .flatten()
        .uniq()
        .value();
      const removeUsersQuery = {
        uuid: { $in: userIds },
        companyStatus: {
          $elemMatch: {
            company: company.uuid,
            status: { $ne: 'employee' },
          },
        },
        $select: ['uuid'],
      };
      const removedUsers = await dispatch('workers.runQuery', removeUsersQuery);
      const removedUsersIds = _.map(removedUsers.data, 'uuid');

      const shifts = _.map(rawShifts.data, shift => {
        _.pullAll(shift.assignedUsers, removedUsersIds);
        return shift;
      });
      log.debug('fetched template preview shifts: ', shifts);
      this.set('previewShifts', shifts);
    } catch (err) {
      log.error('Failed to fetch shifts for template preview', err);
    }
    this.set('isLoadingPreview', false);
  }

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }

  @action
  back() {
    if (this.isReviewing) {
      this.isReviewing = false;
      this.reviewItems = [];
    } else {
      this.clear();
    }
  }

  @action
  clear() {
    this.isOpen = false;
    this.isReviewing = false;
    this.reviewItems = [];
    this.isUploading = false;
    this.isUpload = false;

    if (this.form) this.form.clear();
  }

  @computed
  get submitButtonText() {
    if (this.isComplete) return 'Done';
    if (this.isReviewing) return 'Yes - Save Template';
    return 'Create Template';
  }

  @computed
  get modalTitle() {
    if (this.isComplete) return 'Template Saved';
    if (this.isReviewing) return 'Review Shifts';
    return 'Create New Scheduling Template';
  }

  @computed
  get rangeOptions() {
    return _.map(this.ranges, ({ value, duration, unit }) => {
      const start =
        Boolean(this.form) && Boolean(this.form.$)
          ? moment(this.form.$('start').value)
          : moment().startOf(unit);
      const end = moment(start).add(duration, unit);
      const dateRangeString = formatDateRange({ start, end });
      const text = `${duration} ${duration === 1 ? _.capitalize(unit) : `${_.capitalize(unit)}s`
        } (${dateRangeString})`;
      const key = duration.toString() + unit;
      return {
        key,
        value,
        text,
      };
    });
  }

  ranges = [
    {
      value: 0,
      duration: 1,
      unit: 'day',
    },
    {
      value: 1,
      duration: 1,
      unit: 'week',
    },
    {
      value: 2,
      duration: 2,
      unit: 'week',
    },
    {
      value: 4,
      duration: 4,
      unit: 'week',
    },
  ];
}
