import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';
import cx from 'classnames';

import { getChildLogger } from '#/shared/utils/client.logger';

// Components

const log = getChildLogger('TimeSelect');

const TimeSelect = observer(({ value, form, fieldKey, values, onChange }) => {
  const time = roundTime({ date: value });

  const field = form && form.$(fieldKey);

  const minutes = time.minutes();

  const updateDuration = cascadeDurationUpdate(form, fieldKey);
  const setAM = toggleAmPm({ ampm: 'am', fieldKey, form, onChange, values });
  const setPM = toggleAmPm({ ampm: 'pm', fieldKey, form, onChange, values });

  const cellStyle = { borderCollapse: 'collapse', padding: '0.4rem 0' };

  log.verbose('Rendering Time Select w/ Values: ', {
    amPm: time.hours() < 12 ? 'AM' : 'PM',
    hours: time.hours(),
    minutes: time.minutes,
    time,
    value,
  });

  return (
    <div className="flex-column tc bl bt b--black-30 flex">
      <div className="h2 flex">
        {_.range(0, 12).map((x) => (
          <div
            className={cx(
              'time-select-cell',
              'flex-grow-1',
              time.hours() % 12 === x ? 'bg-blue white b' : 'near-black',
              'pointer',
              'br bb b--black-30',
            )}
            key={x}
            onClick={() => {
              const t = time.hours(time.hours() >= 12 ? x + 12 : x);
              if (field) {
                field.sync(t.toDate());
                updateDuration();
              } else if (onChange) {
                onChange({ value: t });
              }
            }}
            style={_.extend(cellStyle, { width: `${100 / 12}%` })}
          >
            {!x ? '12' : x}
          </div>
        ))}
      </div>
      <div className="h2 flex">
        {_.range(0, 60, 15).map((x) => (
          <div
            className={cx(
              'time-select-cell',
              'flex-grow-1',
              'br bb b--black-30',
              minutes === x ? 'bg-blue white b' : 'near-black',
              'pointer',
            )}
            key={x}
            onClick={() => {
              const t = time.minutes(x);
              if (field) {
                field.sync(t.toDate());
                updateDuration();
              } else if (onChange) {
                onChange({ value: t });
              }
            }}
            style={_.extend(cellStyle, { width: `${100 / 4}%` })}
          >
            :{!x ? '00' : x}
          </div>
        ))}
      </div>
      <div className="h2 flex">
        <div
          className={cx(
            'time-select-cell',
            'flex-grow-1',
            'br bb b--black-30',
            time.hours() < 12 || !time.hours()
              ? 'bg-blue white b'
              : 'near-black',
            'pointer',
          )}
          onClick={() => {
            setAM(time);

            if (form) {
              updateDuration();
            }
          }}
          style={cellStyle}
        >
          AM
        </div>
        <div
          className={cx(
            'time-select-cell',
            'flex-grow-1',
            'br bb b--black-30',
            time.hours() >= 12 ? 'bg-blue white b' : 'near-black',
            'pointer',
          )}
          onClick={() => {
            setPM(time);

            if (form) {
              updateDuration();
            }
          }}
          style={cellStyle}
        >
          PM
        </div>
      </div>
    </div>
  );
});

export default TimeSelect;

export { cascadeDurationUpdate as doDurationUpdate, roundTime, formatDuration };

function cascadeDurationUpdate(form, field) {
  return () => {
    if (!hasTimeRange(form)) {
      return;
    }

    const start = roundTime({ date: form.$('start').value });
    let end = roundTime({ date: form.$('end').value });
    const duration = form.has('duration') ? form.$('duration').value : 0;

    if (field === 'start' && start.isAfter(end)) {
      end = start.clone().add(duration, 'hours');
      form.$('end').set(end.toDate());
    } else if (field === 'end' && start.isAfter(end)) {
      end = end.add(12, 'hours');
      form.$('end').set(end.toDate());
    }

    if (form.has('duration')) {
      form.$('duration').set(formatDuration({ end, form, start }));
    }
  };
}

function roundTime({ date }) {
  const dt = moment(date).isValid() ? moment(date) : moment();

  const offset = dt.minutes() % 15;

  dt.set({
    minutes: dt.minutes() + (offset ? 15 - offset : offset),
    seconds: 0,
  });

  log.info(`set time's minutes to ${dt.minutes()}`);

  return dt;
}

function formatDuration({ start, end, form }) {
  let minuteDiff = end.diff(start, 'minutes');
  let e;

  while (minuteDiff > 24 * 60) {
    e = e || end.clone();
    e = e.subtract(12, 'hours');
    minuteDiff = e.diff(start, 'minutes');
  }

  if (e) {
    form.$('end').set(e.toDate());
  } else {
    e = end;
  }

  const rounded = _.toInteger(minuteDiff / 15) * 15;

  let precision = 0;

  if (rounded % 60 === 0) {
    precision = 0;
  } else if (rounded % 30 === 0) {
    precision = 1;
  } else if (rounded % 15 === 0) {
    precision = 2;
  }

  const formattedDuration = (rounded / 60).toFixed(precision);

  log.debug(
    `Time Range from ${start.format('LTS')} - ${e.format(
      'LTS',
    )} (${formattedDuration}h)`,
  );

  return formattedDuration;
}

function toggleAmPm({ form, fieldKey, values, ampm, onChange }) {
  return (time) => {
    let start;
    let end;
    let val;

    const calcTimeRange = hasTimeRange(form);

    if (calcTimeRange) {
      if (form) {
        start = roundTime({ date: form.$('start').value });
        end = roundTime({ date: form.$('end').value });
      } else {
        start = moment(values.start);
        end = moment(values.end);
      }
    } else {
      val = roundTime({ date: form.$(fieldKey).value });
    }

    if (/^am$/i.test(ampm)) {
      if (time.hours() < 12) {
        return;
      }

      if (/^start$/i.test(fieldKey)) {
        start.subtract(12, 'hours');

        if (form) {
          form.$('start').set(start.toDate());
        } else {
          onChange({ attr: 'start', value: start });
        }
      } else if (/^end$/i.test(fieldKey)) {
        end.subtract(12, 'hours');

        if (end.isBefore(start)) {
          end.add(1, 'days');
        }

        if (form) {
          form.$('end').set(end.toDate());
        } else {
          onChange({ attr: 'end', value: end });
        }
      } else {
        val.subtract(12, 'hours');
        if (form) {
          form.$(fieldKey).set(val.toDate());
        } else {
          onChange({ attr: fieldKey, value: val });
        }
      }
    } else {
      if (time.hours() >= 12) {
        return;
      }

      if (/^start$/i.test(fieldKey)) {
        start.add(12, 'hours');

        if (form) {
          form.$('start').set(start.toDate());
        } else {
          onChange({ attr: 'start', value: start });
        }
      } else if (/^end$/i.test(fieldKey)) {
        end.add(12, 'hours');
        if (end.diff(start, 'hours') > 24) {
          end.subtract(24, 'hours');
        }
        if (form) {
          form.$('end').set(end.toDate());
        } else {
          onChange({ attr: 'end', value: end });
        }
      } else {
        val.add(12, 'hours');
        if (form) {
          form.$(fieldKey).set(val.toDate());
        } else {
          onChange({ attr: fieldKey, value: val });
        }
      }
    }
  };
}

function hasTimeRange(form) {
  return form.has('start') && form.has('end');
}
