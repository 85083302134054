import type { RuleAction } from '@shiftsmartinc/shiftsmart-types';

import { useEffect, useState } from 'react';
import _ from 'lodash';

import { useAuth } from '#/utils/useAuth';
import { getChildLogger } from '#/shared/utils/client.logger';

type UseFetchDataProps = {
  can?: {
    action: RuleAction;
    fields?: string;
    subject: string | { $or: Array<string> };
  };
  /** Optional canAccess will take only subject, where permission default to action */
  canAccess?: string;
  /** Optional Destructor function to be called on component unmount; use to cleanup any listeners or persistent handles */
  clearData?: () => void;
  /** Function to be called on mount, or whenever an item in the dependencies array (or, by default, the params) changes */
  fetchData: (params: Record<string, unknown>) => void | Promise<void>;
  /** Parameters from the enclosing container or component. Used to form the default dependencies array. */
  params?: Record<string, unknown>;
};

/**
 * # useFetchData
 * This hook serves as a way to replace the functionality previously available via the
 * static `fetchData` method called by rfx-core via react-router v3's onRouteMatch handler.
 *
 * The hook takes, as named parameters (in the first argument):
 * * - `fetchData`: the async logic to execute (ie: "Fetch")
 * * - `clearData`: the logic to execute on component unmount (ie: "Teardown")
 * * - `params`: the params object to be passed to `fetchData`
 *
 * By default, the values in the `params` object will be used as the dependencies array
 * passed to the underlying `useEffect` hook.
 *
 * An optional second arg, `dependencies` can be used to fine-tune when the fetchData method
 * is called. @see useEffect for information on the dependencies array and how the fetchData
 * and clearData function arguments will be called.
 *
 * @export
 * @param {{ fetchData?: () => any, clearData?: () => any, params?: any }} { fetchData, clearData, params }
 * @param {Array<any>} [manualDependencies=[]]
 */
export function useFetchData(
  {
    fetchData,
    clearData,
    params,
    canAccess,
    can = { action: 'access', subject: canAccess },
  }: UseFetchDataProps,
  /** Use to manually specify the dependencies, just like as used by `useEffect`. If omitted, the values of the `params` will be used */
  manualDependencies?: Array<unknown>,
) {
  const isAuthorized = useAuth(
    can.action,
    can.subject,
    can?.fields,
    !can?.subject,
  );
  const [log] = useState(getChildLogger('useFetchData'));
  const dependencies = manualDependencies ?? _.values(params);

  if (!_.isArray(dependencies)) {
    throw new Error('hook dependencies must be passed as an array');
  }
  if (can.subject) {
    dependencies.push(isAuthorized);
  }

  useEffect(
    () => {
      log.debug('Re-Fetching based on changes');
      if (can.subject && !isAuthorized) {
        return;
      }

      fetchData?.(params);
      return clearData;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies,
  );
}
