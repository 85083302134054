import type { IExperience } from '@shiftsmartinc/shiftsmart-types';

import { observable, action, runInAction } from 'mobx';
import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { service } from '#/shared/app';

import BaseStore from './_baseStore';

export default class ExperienceStore extends BaseStore<IExperience> {
  constructor({ serviceName = 'experience', ...rest } = {}) {
    super({
      baseItem: ExperienceStore.baseExperience,
      cacheSize: 1000,
      searchFields: ['title'],
      serviceName,
      ...rest,
    });

    return this;
  }

  static baseExperience = {
    employer: {
      logo: null,
      name: null,
      uuid: null,
    },
    end: null,
    links: [],
    location: null,
    start: null,
    summary: null,
    title: null,
    user: null,
  };

  @observable
  reviewExperience = false;

  @action
  removeItem(item) {
    const selectedWorker = dispatch('workers.get', item.user, {
      select: false,
    });

    if (!_.isEmpty(selectedWorker.uuid) && selectedWorker.uuid !== item.user) {
      return false;
    }

    return !!super.removeItem(item);
  }

  /* Actions */

  @action
  toggleReviewModeFilter(review) {
    this.log.debug('Setting Experience Review Mode: ', review);
    if (this.reviewExperience === review) {
      return Promise.resolve(this.list);
    }

    this.reviewExperience = review;

    let query = { links: undefined };

    if (this.reviewExperience) {
      query = { links: { $eq: [] } };
    }

    return this.find({ query });
  }

  @action
  async setSelected(json = {}, ...rest) {
    if (_.isEmpty(json)) {
      return this.clearSelected();
    }
    await super.setSelected(json, ...rest);

    try {
      const res = await service('certs').find({
        query: { 'links.uuid': this.selected.uuid },
      });

      runInAction(() => {
        this.selected.linked = res.data;
      });
    } catch (err) {
      this.log.error('Failed to set linked certs', err, { uuid: json?.uuid });
    }

    return this.selected;
  }

  async linkExperience({ industry, experience }) {
    const c = await dispatch(
      'experience.get',
      _.get(experience, 'uuid', experience),
    );

    if (_.isEmpty(c)) {
      return Promise.reject(
        'Cert "%s" not found',
        _.get(experience, 'uuid', experience),
      );
    }

    const i = _.isString(industry) ? await this.get(industry) : industry;
    const newLink = {
      createdAt: Date.now(),
      title: i.title,
      uuid: i.uuid,
    };

    let data;

    if (_.has(c, 'links') && _.isArrayLike(c.links)) {
      if (_.find(c.links, { uuid: i.uuid })) {
        this.log.warn('Cert is already linked to this industry');
      } else {
        data = { $push: { links: newLink } };
      }
    } else {
      data = { links: [newLink] };
    }

    if (data) {
      return dispatch('tags.update', { data, id: c.uuid });
    }

    return Promise.resolve();
  }
}
