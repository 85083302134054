import { action, observable } from 'mobx';

/**
 * Store for UI Modal where user can view details about a Inspection
 *
 * @export
 * @class InspectionDetailsModal
 */

export default class InspectionDetailsModal {
  @observable
  isOpen = false;

  @action
  open(arg = !this.isOpen) {
    this.isOpen = !!arg;
  }
}
