import { observable, action, set, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import Promise from 'bluebird';
import moment from 'moment';

import BaseUploader from './BaseUploader';

const sleep = (m) => new Promise((r) => setTimeout(r, m));

export default class WeeklyScheduleUpload extends BaseUploader {
  constructor() {
    super({ title: 'ui.weeklyScheduleUpload' });

    set(this.saveResults, {
      dupe: [],
      invalid: [],
      new: [],
      unassigned: [],
    });
  }

  @observable parseOptions = {
    dynamicTyping: false,
    header: false,
    skipEmptyLines: true,
  };

  @action
  setup(...args) {
    super.setup(...args);
  }

  @action
  clear() {
    super.clear();

    this.saveResults = {
      dupe: [],
      invalid: [],
      new: [],
      unassigned: [],
    };
  }

  @action.bound
  async processFile({ company, options = this.parseOptions }) {
    await super.processFile({ company, options });
  }

  @action
  async parse({ rows, company }) {
    const log = this.log.getChildLogger('parse');
    const days = [];
    const dates = [];

    _(rows)
      .filter((r) => /^(day|date)$/i.test(r[0]))
      .each((row) => {
        if (/day/i.test(row[0])) {
          days.push(..._.compact(row.slice(2)));
        } else if (/date/i.test(row[0])) {
          dates.push(..._.compact(row.slice(2)));
        }
      });

    this.dates = _.map(dates, (val) => moment(val, this.formats));

    const mainStartIndex = _.findIndex(rows, (r) => /^name$/i.test(r[0]));

    const scheduleHeader = _.first(
      rows.slice(mainStartIndex, mainStartIndex + 1),
    );
    const scheduleRows = rows.slice(mainStartIndex + 1);

    log.debug('Got Days and dates: ', {
      dates,
      days,
      parsedDates: this.dates,
      scheduleHeader,
    });

    const partnerSchedules = await Promise.map(scheduleRows, async (row) => {
      const partnerName = row[0];
      const partnerRole = row[1];

      const rawSchedule = row.slice(2);

      const { partner, partners } = await this.getPartnerForName({
        company,
        displayName: partnerName,
      });

      const schedule = _(this.dates)
        .map((d) => d.clone())
        .map((d, i) => {
          const sIndex = i * 2;

          if (
            _.isEmpty(rawSchedule[sIndex]) &&
            _.isEmpty(rawSchedule[sIndex + 1])
          ) {
            return null;
          }

          const start =
            !!rawSchedule[sIndex] &&
            moment(rawSchedule[sIndex], this.timeFormats);
          const end =
            !!rawSchedule[sIndex + 1] &&
            moment(rawSchedule[sIndex + 1], this.timeFormats);

          const dayObj = {
            date: d.date(),
            month: d.month(),
            year: d.year(),
          };

          if (start && start.isValid()) {
            start.set(dayObj);
          }

          if (end && end.isValid()) {
            end.set(dayObj);
          }

          const duration = end ? end.diff(start, 'minutes') : null;

          if (duration < 0) {
            log.error('Bad duration value', {
              duration,
              end,
              source: {
                end: rawSchedule[sIndex + 1],
                start: rawSchedule[sIndex],
              },
              start,
            });
          }

          if (!(start && start.isValid() && end && end.isValid())) {
            log.error('Start or end is invalid', {
              duration,
              end,
              source: {
                end: rawSchedule[sIndex + 1],
                start: rawSchedule[sIndex],
              },
              start,
            });
          }

          return {
            duration,
            end,
            start,
          };
        })
        .compact()
        .value();

      return {
        name: partnerName,
        partner,
        partnerName,
        partners,
        rawSchedule,
        schedule,
        title: partnerRole,
      };
    });

    log.debug('Got raw Schedule', { partnerSchedules });

    runInAction(() => {
      this.list = partnerSchedules;
    });

    return Promise.resolve(partnerSchedules);
  }

  @action.bound
  async save({ company }) {
    const log = this.log.getChildLogger('save');
    dispatch('ui.weeklyScheduleUpload.setSaving', true);
    const companyUUID = _.get(company, 'uuid', company);

    dispatch('audit.create', {
      data: {
        action: 'Upload Schedule',
        companyId: companyUUID,
        extra: {
          file: _.pick(this.file, ['name', 'type', 'size']),
        },
      },
    });
    await Promise.map(
      this.list,
      async ({ partner, schedule, title, ...rest }) => {
        const shifts = await Promise.map(schedule, async (shiftData) => {
          const data = {
            assignTo: _.compact([_.get(partner, 'uuid')]),
            company: companyUUID,
            description: `${title} from ${[
              !!shiftData.start && shiftData.start.format('LT'),
              !!shiftData.end && shiftData.end.format('LT'),
            ].join('-')}`,
            end: shiftData.end.toDate(),
            source: 'csv-import',
            start: shiftData.start.toDate(),
            title, // TODO: handle assignment on backend
          };

          try {
            const shift = await dispatch('shifts.create', { data });

            log.debug('Created Shift: ', shift.uuid, { shift });

            if (_.isEmpty(partner)) {
              log.warn('No partner for shift');

              runInAction(() => {
                this.saveResults.unassigned.push({
                  ...rest,
                  ...shift,
                });
              });

              return shift;
            }
            await dispatch('assignments.create', {
              status: 'Assigned',
              thing: shift,
              worker: partner,
            });

            /** TODO: Remove this sleep function after people complain about the
             * uploader being too slow. 🤣
             *
             * ... just kidding - this is here to ensure that the assignment has
             * been created and the newly created shift shows up as "assigned"
             *
             * ... but we could also remove it if ppl complain.
             */
            await sleep(1000);

            const assignedShift = await dispatch('shifts.get', shift.uuid, {
              select: false,
            });

            log.debug('Assigned Shift: ', assignedShift.uuid, {
              assignedShift,
            });

            runInAction(() => {
              this.saveResults.new.push(assignedShift);
            });

            return assignedShift;
          } catch (err) {
            log.error('Failed to create shift:', err, { extra: { data } });

            runInAction(() => {
              this.saveResults.invalid.push(_.extend(data, { err }));
            });
          }

          return null;
        });

        log.debug(
          'processed %d shifts for %s',
          _.compact(shifts).length,
          partner.displayName,
        );

        return shifts;
      },
      { concurrency: 5 },
    );
  }
}
