import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';

const log = getChildLogger('forms.product');

export class ProductForm extends Form {
  hooks = () => ({
    onSuccess(form) {
      const storeAction = form.values().uuid
        ? 'products.update'
        : 'products.create';

      dispatch(storeAction, {
        data: { ...form.values(), uuid: form.$('uuid').value || undefined },
      })
        .then(() => dispatch('ui.snackBar.open', 'Product Saved.'))
        .then(() => form.clear())
        .catch((err) => {
          log.error('Saving Product Failed: ', err);
          form.invalidate(err.message);
          dispatch('ui.snackBar.open', err.message);
        });
    },
  });
}

const fields = ['uuid', 'title', 'company', 'guidelinesURL'];

const rules = {
  company: 'string',
  guidelinesURL: 'string',
  title: 'string',
  uuid: 'string',
};

const labels = {
  company: 'Company',
  guidelinesURL: 'GuidelinesURL',
  title: 'Title',
};

export function init(values = {}, opts = { src: null }) {
  log.debug('Creating Product from base %s', opts.src);

  log.debug('Creating Product Form from Keys: ', _.keys(values));

  const form = new ProductForm({ fields, labels, rules, values });
  log.debug('Created Product Form with Values: ', form.values());

  return form;
}
