import { observable, action, computed, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import Promise from 'bluebird';

import { getChildLogger } from '#/shared/utils/client.logger';

export default class CustomerUpload {
  @observable
  customerList = {};

  @observable
  file = null;

  @observable
  fileRef = {};

  @observable
  listName = '';

  @observable
  isLoading = false;

  @observable
  isSaving = false;

  @observable
  isOpen = false;

  @observable
  isSite = false;

  @observable
  newCustomers = [];

  @observable
  customerHeaders = [];

  @observable
  saveResults = {
    dupe: [],
    invalid: [],
    new: [],
  };

  @computed
  get knownHeaders() {
    return [
      { key: 'Client ID', mapping: 'externalId', required: !this.isSite },
      { key: 'FirstName', mapping: 'firstName', required: !this.isSite },
      { key: 'LastName', mapping: 'lastName', required: !this.isSite },
      { key: 'Address', mapping: 'address.street1', required: false },
      { key: 'Street2', mapping: 'address.street2', required: false },
      { key: 'City', mapping: 'address.city', required: false },
      { key: 'State', mapping: 'address.state', required: false },
      { key: 'Zip', mapping: 'address.zip', required: false },
      { key: 'ZIP', mapping: 'address.zip', required: false },
      { key: 'Phone1', mapping: 'phone', required: false },
      { key: 'Cell', mapping: 'secondaryPhone', required: false },
      { key: 'Route ID', mapping: 'externalId', required: this.isSite },
      {
        key: 'Distribution Center Name',
        mapping: 'name',
        required: this.isSite,
      },
      { key: 'Street Address', mapping: 'address.street1', required: false },
    ];
  }

  log = getChildLogger('ui.CustomerUpload');

  setup({ customerList, open = this.isOpen }) {
    this.set('listName', _.get(customerList, 'title', ''));
    this.set('customerList', customerList);

    this.set('isOpen', !!open);
  }

  @action
  saving(arg = !this.isSaving) {
    this.isSaving = !!arg;
  }

  setFile(file) {
    return this.set('file', file);
  }

  setRef(ref) {
    return this.set('fileRef', ref);
  }

  setListName(name) {
    return this.set('listName', name);
  }

  setIsSite(val) {
    return this.set('isSite', val);
  }

  @computed
  get savePercentage() {
    return (
      (this.saveResults.new.length +
        this.saveResults.invalid.length +
        this.saveResults.dupe.length) /
      this.newCustomers.length
    );
  }

  @action
  setCustomers(customers) {
    _.each(customers, (customer) => this.newCustomers.push(customer));

    const headers = _.keys(customers[0]);
    this.set('customerHeaders', headers);

    return Promise.resolve(this.newCustomers);
  }

  async saveCustomers({ company }) {
    const log = this.log.getChildLogger('saveCustomers');
    dispatch('ui.customerUpload.saving', true);
    const companyUUID = _.get(company, 'uuid', company);

    dispatch('audit.create', {
      data: {
        action: 'Upload Customers',
        companyId: companyUUID,
        extra: {
          file: _.pick(this.file, ['name', 'type', 'size']),
        },
      },
    });
    return Promise.all(
      Promise.map(
        this.newCustomers,
        async (customer) => {
          let newData = this.mapTranslations({
            companyUUID,
            customer,
          });

          const address = _.get(newData, 'address') || {};

          if (_.isEmpty(address) || _(address).values().compact().isEmpty()) {
            log.error(
              'Row has no address data: ',
              new Error('Row has now address data'),
              {
                extra: { customer, data: newData },
              },
            );

            runInAction(() => {
              this.saveResults.invalid.push(newData);
            });
            return customer;
          }

          if (_.isEmpty(address.street2) && _.includes(address.street1, '#')) {
            const split = address.street1.split('#');
            address.street1 = _.trim(split[0]);
            address.street2 = `#${_.trim(split[1])}`;
          }

          newData.formattedAddress = _.compact([
            address.street1,
            address.street2,
            address.city,
            address.state,
            address.zip,
          ]).join(', ');

          newData.address = address;

          newData.firstName = newData.firstName || '';
          newData.lastName = newData.lastName || '';

          newData.name = this.isSite
            ? newData.name
            : `${_.trim(newData.firstName.replace('*', ''))} ${_.trim(
                newData.lastName.replace('*', ''),
              )}`;

          newData.primaryContact = newData.name;

          newData.phone = `+1${_.get(newData, 'phone', '').replace(/\D/g, '')}`;

          log.debug(
            `Saving new ${this.isSite ? 'Site' : 'Customer'}: `,
            newData,
          );

          const service = this.isSite ? 'sites.create' : 'customers.create';
          newData = this.isSite ? { data: newData } : newData;
          return dispatch(service, newData)
            .then(
              action((res) => {
                if (res.isPatched) {
                  this.saveResults.dupe.push(res);
                } else {
                  this.saveResults.new.push(res);
                }
                return res;
              }),
            )
            .catch(
              action((err) => {
                log.error('Customer could not be saved: ', err, {
                  extra: { customer, data: newData },
                });
                this.saveResults.invalid.push(_.extend(customer, err));
              }),
            );
        },
        { concurrency: 10 },
      ),
    ).then(() => this.saveResults);
  }

  mapTranslations({ customer, companyUUID }) {
    const newData = _.extend(customer, {
      companies: [companyUUID],
    });

    // Re-Map source keys to destination keys & nested values
    _(this.knownHeaders)
      .filter((h) => !!h.mapping)
      .each(
        action((translation) => {
          if (_.get(newData, translation.key)) {
            _.set(newData, translation.mapping, newData[translation.key]);
            delete newData[translation.key];
          }
        }),
      );

    // Transform Values where a transformation is indicated
    _(this.knownHeaders)
      .filter((h) => _.isFunction(h.transform))
      .each(
        action((translation) => {
          const key = translation.mapping || translation.key;
          if (_.get(newData, key)) {
            _.set(newData, key, translation.transform(_.get(newData, key)));
          }
        }),
      );

    return newData;
  }

  get(name) {
    return this[name];
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(name, val) {
    if (_.isArrayLikeObject(this[name])) {
      _.each(val, (v) => this[name].push(v));
    } else {
      this[name] = val;
    }
    return Promise.resolve(this[name]);
  }

  @action
  clear() {
    this.customerList = {};
    this.isOpen = false;
    this.file = null;
    this.fileRef = {};
    this.listName = '';
    this.isLoading = false;
    this.isSaving = false;
    this.isSite = false;
    this.newCustomers.clear();
    this.customerHeaders.clear();
    this.saveResults = { dupe: [], invalid: [], new: [] };
  }
}
