import type { IAssignment } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import Promise from 'bluebird';

import { getStores } from '#/shared/getStores';
import { getChildLogger } from '#/shared/utils/client.logger';
const log = getChildLogger('payments.revertPaymentTransactions');

/**
 * Given an array of assignment uuid's, will reset the payment approval
 * and delete any existing errored-transaction.
 *
 * The logic will be skipped if any of the following are true:
 * - The paymentInfo.status is anything other than "draft" or `null`
 * - The payment.paymentDue is !== 0
 * - A transaction exists with a status other than `errored`
 *
 * The return value will include `updated`, `ignored` and `errored` arrays
 */
export async function resetPaymentApprovals(
  assignmentIds: Array<IAssignment['uuid']>,
  {
    limit = _.size(assignmentIds),
    live = false,
    clearTransaction = live,
  }: {
    clearTransaction?: boolean;
    limit?: number;
    live?: boolean;
  },
) {
  const { auth, assignments, paymentTransactions } = getStores();

  const { data: assignmentsToReset, total } = await assignments.runQuery({
    query: {
      $limit: limit,
      uuid: { $in: assignmentIds },
    },
  });

  if (total > _.size(assignmentsToReset)) {
    log.warn(
      `Only processing the first ${_.size(assignmentsToReset)} of ${total}`,
    );
  }

  const patchData = {
    'paymentInfo.approval': null,
    'paymentInfo.approvalNote': null,
    'paymentInfo.log': {
      $push: {
        $each: [
          {
            attr: 'status',
            status: null,
            time: new Date(),
            user: auth.userId,
          },
          {
            attr: 'approval',
            status: null,
            time: new Date(),
            user: auth.userId,
          },
        ],
      },
    },
    'paymentInfo.status': null,
    'paymentInfo.transactionId': null,
  };

  const results = await Promise.reduce(
    assignmentsToReset,
    async (acc, assignment) => {
      const { paymentInfo, payment, uuid } = assignment;

      const transaction =
        !!paymentInfo.transactionId &&
        (await paymentTransactions.get(paymentInfo.transactionId, {
          select: false,
        }));

      try {
        if (paymentInfo.status !== 'draft' && !!paymentInfo.status) {
          acc.ignored.push({
            reason: 'status not draft or empty: ' + paymentInfo.status,
            uuid,
          });
        } else if (payment.paymentDue !== 0) {
          acc.ignored.push({ reason: 'paymentDue != 0', uuid });
        } else if (transaction && transaction.status !== 'error') {
          acc.ignored.push({
            reason: 'transaction has non-error status: ' + transaction.status,
            uuid,
          });
        } else {
          let res;
          let trans;
          const pd = { ...patchData };

          pd['paymentInfo.log'].$push.$each.push({
            attr: 'transactionIdRemoved',
            status: paymentInfo.transactionId,
            time: new Date(),
            user: auth.userId,
          });

          if (live) {
            res = await assignments.update({
              data: pd,
              id: uuid,
            });
            if (clearTransaction && paymentInfo.transactionId) {
              trans = await paymentTransactions.remove(
                paymentInfo.transactionId,
              );
            }
          } else {
            log.debug('TEST: Patch Assignment with Data', {
              data: pd,
              transaction,
              uuid,
            });
          }

          acc.updated.push({
            paymentInfoResult: res?.paymentInfo,
            transaction: trans,
            uuid,
          });
        }
      } catch (error) {
        log.error('Failed while processing', error);
        acc.errored.push({ reason: error.message, uuid });
      }

      return acc;
    },
    {
      errored: [],
      ignored: [],
      updated: [],
    },
  );

  log.debug('Completed %s with Results: ', live ? 'execution' : 'test run', {
    results,
  });

  return results;
}
