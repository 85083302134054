import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export type IProduct = IBaseItem & Record<string, unknown>;

export default class ProductStore extends BaseStore<IProduct> {
  constructor() {
    super({
      baseItem: ProductStore.BASE_ITEM,
      searchFields: ['title'],
      serviceName: 'products',
    });

    return this;
  }

  static BASE_ITEM = {
    company: null,
    // Company UUID
    imageURL: null,
    title: null,
  };
}
