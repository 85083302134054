import _ from 'lodash';

import BaseStore from './_baseStore';

export default class TaskImageStore extends BaseStore {
  constructor() {
    super({
      baseItem: TaskImageStore.BASE_ITEM,
      serviceName: 'taskimage',
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  static BASE_ITEM = {
    createdAt: '',
    imageURL: '',
    senderPhoneNumber: '',
    updatedAt: '',
    uuid: '',
  };
}
