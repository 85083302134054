import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';

/**
 * Store for UI Modal where user can clone a survey at a store
 *
 * @export
 * @class userModal
 */

export default class SurveyCloneConfirmModal {
  @observable
  isOpen = false;

  @observable
  isLoading = false;

  @observable
  survey = {};

  @action
  setup({ survey = {}, open = this.isOpen }) {
    this.survey = survey;

    this.isOpen = open;
  }

  @action
  toggleLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  clone({ survey }) {
    this.toggleLoading(true);
    return dispatch('surveys.clone', { survey })
      .then(() => {
        dispatch('ui.snackBar.open', 'Survey Cloned');
        this.toggleLoading(false);
        return this.clear();
      })
      .catch((error) => {
        dispatch('ui.snackBar.open', error.msg);
        this.toggleLoading(false);
        return this.clear();
      });
  }

  @action
  clear() {
    this.survey = {};

    this.isOpen = false;
  }
}
