import type { IUser } from '@shiftsmartinc/shiftsmart-types';

import React from 'react';
import { observer } from 'mobx-react';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import { Dropdown, Menu, Label } from 'semantic-ui-react';
import cx from 'classnames';

import styles from '#/shared/styles/PageMenu.css';

interface ISubTab {
  badge?: string;
  className?: string;
  disabled?: string;
  icon?: string;
  key?: string;
  onSelect?: string;
  style?: string;
  title?: string;
}

interface IPageMenuProps {
  activeOnly?: boolean;
  activeTab: string;
  menuTabs: Array<
    ISubTab & {
      hidden?: boolean;
      items?: Array<ISubTab>;
      visible: boolean;
    }
  >;
  onSelectAction?: string;
  // Search comes from Selected field in the Pools Store
  pool?: Record<string, unknown>;
  search?: Record<string, unknown>;
  storeName: string;
  // rest
  user?: IUser; // pool comes from Selected field in the Pools Store
}

const PageMenu: React.FC<IPageMenuProps> = observer(
  ({
    menuTabs,
    activeTab,
    storeName,
    onSelectAction = 'setActiveTab',
    activeOnly = false,
    ...rest
  }) => (
    <Menu.Menu>
      {_(menuTabs)
        .sortBy('-index')
        .reject((tab) => {
          if (!_.has(tab, 'hidden')) return false;

          return _.isFunction(tab.hidden)
            ? tab.hidden({ ...rest })
            : !!tab.hidden;
        })
        .filter((tab) => {
          if (activeOnly && tab.key !== activeTab) {
            return false;
          }

          if (!_.has(tab, 'visible')) return true;

          return _.isFunction(tab.visible)
            ? tab.visible({ ...rest })
            : !!tab.visible;
        })
        .map((tab) =>
          _.isEmpty(tab.items) ? (
            <PageMenuItem
              activeTab={activeTab}
              key={tab.key}
              onSelectAction={onSelectAction}
              rest={rest}
              storeName={storeName}
              tab={tab}
            />
          ) : (
            <Dropdown
              content={tab.title}
              icon={tab.icon}
              item={true}
              key={tab.key}
            >
              <Dropdown.Menu>
                {_.map(tab.items, (item) => (
                  <PageMenuItem
                    activeTab={activeTab}
                    key={item.key}
                    onSelectAction={onSelectAction}
                    rest={rest}
                    storeName={storeName}
                    tab={item}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ),
        )
        .value()}
    </Menu.Menu>
  ),
);

PageMenu.displayName = 'PageMenu';
export default PageMenu;
export { PageMenu };

interface IPageMenuItemProps {
  activeTab?: string;
  onSelectAction?: string;
  rest?: {
    pool?: Record<string, unknown>;
    search?: Record<string, unknown>;
    selectedMessage?: Record<string, unknown>;
    user?: IUser;
  };
  storeName?: string;
  tab?: ISubTab;
}

const PageMenuItem: React.FC<IPageMenuItemProps> = observer(
  ({ tab, activeTab, storeName, onSelectAction, rest }) => {
    const badgeCt = _.isFunction(tab.badge)
      ? tab.badge({ ...rest })
      : tab.badge;

    let content = _.isFunction(tab.title) ? tab.title({ ...rest }) : tab.title;

    if (badgeCt) {
      content = (
        <div className="flex items-center">
          <div>{content}</div>
          <div className="ml1">
            <Label basic={true} circular={true} content={badgeCt} size="mini" />
          </div>
        </div>
      );
    }

    return (
      <Menu.Item
        active={activeTab === tab.key}
        className={cx(
          styles.item,
          activeTab === tab.key && styles.active,
          tab.className,
        )}
        content={content}
        disabled={
          _.isFunction(tab.disabled)
            ? tab.disabled({ ...rest })
            : !!tab.disabled
        }
        icon={tab.icon}
        key={tab.key}
        onClick={() => {
          if (onSelectAction === null) return;
          if (_.isFunction(onSelectAction)) {
            onSelectAction(tab.key);
            return;
          }

          dispatch(`${storeName}.${onSelectAction}`, tab.key);
          if (_.isFunction(tab.onSelect)) {
            tab.onSelect({ ...rest });
          }
        }}
        style={tab.style}
      />
    );
  },
);

PageMenuItem.displayName = 'PageMenuItem';
