import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import {
  Button,
  Header,
  Icon,
  Image,
  StrictButtonProps,
  StrictHeaderProps,
  StrictIconProps,
} from 'semantic-ui-react';
import cx from 'classnames';

import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('ssm/EmptyState');

export type EmptyStateProps = {
  /** Semantic UI Button props _or_ a JSX Element to render under the Header & Body */
  action?: React.ReactElement | StrictButtonProps;
  /** Content to render secondary to the Header */
  body?: string | React.ReactElement;
  /** Class names passed to the outer, containing div */
  className?: string;
  /** Remove vertical padding */
  compact?: boolean;
  /** Primary heading */
  header?: string | StrictHeaderProps;
  /** Whether to display the default placehodler image, or a specific image specified by its path */
  icon?: boolean | string;
  /** Should the icon and content be stacked (vertical) or side-by-side (horizontal) */
  layout?: 'vertical' | 'horizontal';
  /** CSS Props to pass into the div containing the main content (na for horizontal mode) */
  style?: unknown;
};

const EmptyState = observer(
  ({
    header: headerProps,
    body: bodyProps,
    icon: iconProps,
    action,
    className,
    layout = 'vertical',
    style = {},
    compact,
  }: EmptyStateProps) => {
    let button = action;

    if (
      _.isObject(button) &&
      ((button as StrictButtonProps).content ||
        (button as StrictButtonProps).icon ||
        (button as StrictButtonProps).onClick)
    ) {
      button = (
        <Button
          className="pv2"
          content="New Chat"
          onClick={() => log.warn('No Action assigned to this button')}
          {...button}
        />
      );
    }

    const header = _.isString(headerProps) ? (
      <Header content={headerProps} size="large" />
    ) : (
      <Header {...{ size: 'large', ...headerProps }} />
    );
    const body = _.isString(bodyProps) ? <p>{bodyProps}</p> : bodyProps;

    const icon = !!iconProps && getIcon(iconProps);

    if (layout === 'horizontal') {
      return (
        <div
          className="h-75 w-100 pa4 tc flex-column flex items-center justify-center"
          style={{ minHeight: '450px' }}
        >
          {header}

          <div
            className={cx('flex items-center justify-between', className)}
            style={{
              maxWidth: '100%',
              width: '65rem',
            }}
          >
            <div className="w-50 flex-column flex items-center justify-center">
              {icon}
            </div>

            <div className="w-50 tl ml4">
              {body}
              {button && <div className="w-100 tc">{button}</div>}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={cx(
          'h-100 w-100 flex items-start justify-center',
          !compact && 'pt5',
          className,
        )}
      >
        <div
          className="pa4 pt0 tc flex-column flex w-80 items-center justify-center"
          style={_.defaults(style, {
            width: '28rem',
          })}
        >
          {icon}
          {header}

          {body}

          {button}
        </div>
      </div>
    );
  },
);

EmptyState.displayName = 'EmptyState';

export default EmptyState;

function getIcon(iconProps) {
  let icon;

  if (_.isArray(iconProps)) {
    icon = (
      <Icon.Group size="massive">
        <Icon color="blue" name="chat" size="large" {...iconProps[0]} />
        {iconProps[1] &&
          (_.isObject(iconProps[1]) &&
          !React.isValidElement(React.isValidElement(iconProps[1])) ? (
            <Icon
              name="group"
              size="small"
              style={{
                color: 'white',
                marginLeft: '-0.2rem',
                marginTop: '-0.4rem',
              }}
              {...iconProps[1]}
            />
          ) : (
            React.isValidElement(iconProps[1]) && iconProps[1]
          ))}
      </Icon.Group>
    );
  } else if (
    _.isObject(iconProps) &&
    ((iconProps as StrictIconProps).name || (iconProps as StrictIconProps).size)
  ) {
    icon = (
      <Icon
        name="group"
        size="small"
        style={{
          color: 'white',
          marginLeft: '-0.2rem',
          marginTop: '-0.4rem',
        }}
        {...iconProps}
      />
    );
  } else if (_.isString(iconProps)) {
    icon = <Image alt="" fluid={true} src={iconProps} />;
  }

  if (_.isUndefined(icon) || icon === true) {
    icon = (
      <Image
        alt=""
        fluid={true}
        src="/static/img/chat_empty.png"
        srcSet="/static/img/chat_empty.png 1x,
                    /static/img/chat_empty@2x.png 2x,
                    /static/img/chat_empty@3x.png 3x"
      />
    );
  }

  return icon;
}
