import type { IShift, ISite } from '@shiftsmartinc/shiftsmart-types';

import { action, computed, observable, runInAction, set, toJS } from 'mobx';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import _ from 'lodash';
import moment from 'moment-timezone';
import flatten from 'flat';
import isUUID from 'uuid-validate';
import { Parser } from 'json2csv';
import { v4 as uuid } from 'uuid';

import { toBoolean } from '#/utils/toBoolean';
import BaseUploader, {
  type KnownFieldConfig,
} from '#/shared/stores/ui/BaseUploader';
import { parseAddress } from '#/utils/formatting';
import { currencyDropdownOptions } from '#/shared/forms/campaign';
import { validateFlexibleShiftWorkingHours } from '#/shared/utils/shifts';
import { chunkifiedLoader } from '#/utils/chunkifiedLoader';
import { getStores, getStore } from '#/shared/getStores';
import { StoreName } from '#/shared/stores';
import { IS_PRODUCTION } from '#/config/settings';

type ShiftUploaderKnownFieldConfig = KnownFieldConfig & {
  enum?: string[];
};

export default class ShiftUploader extends BaseUploader {
  constructor({ title = 'ui.ShiftUploader' }) {
    super({ title });

    set(this.saveResults, {
      ignored: [],
      invalid: [],
      new: [],
      updated: [],
    });

    set(this.summary, {
      ignored: 0,
      invalid: 0,
      new: 0,
      updated: 0,
    });

    set(this.summaryIds, {
      ignored: [],
      invalid: [],
      new: [],
      updated: [],
    });

    return this;
  }

  // #region Observables
  summary = observable({
    ignored: 0,
    invalid: 0,
    new: 0,
    updated: 0,
  });

  summaryIds = observable({
    ignored: [],
    invalid: [],
    new: [],
    updated: [],
  });

  @observable
  storeFullValues = false;

  storeName: StoreName & 'shifts' = 'shifts';

  @observable saveConfirmMessage =
    'When uploading records, existing records will only be updated if the `uuid` value is specified. Otherwise, duplicate entries may be created.';

  @observable saveButtonText = 'Save';

  @observable parseOptions = {
    comments: '#',
    dynamicTyping: false,
    header: false,
    skipEmptyLines: true,
  };

  @observable
  includeDispatchPrefs = true;

  @observable
  dispatchPrefs = observable.array([]);

  @observable
  additionalKnownFields = observable.array<ShiftUploaderKnownFieldConfig>([]);

  @observable
  excludedFields = observable.array<KnownFieldConfig['key']>([]);

  @observable
  isEditMode = false;

  @observable
  indices = {};

  @observable
  uploadProgress = 0;

  @observable
  processingHasFinished = false;

  @observable
  showShiftTimeAlert = false;

  @observable
  uploadJobSent = false;

  // #endregion

  // #region Computed
  @computed get getEditableFields() {
    const editableFields = [
      'title',
      'description',
      'bonus',
      'rate',
      'dispatchPref',
      'slots',
      'pay',
      'useFlatRatePay',
      'checkinCode',
      'checkoutCode',
      'useUniqueAttendanceCodes',
      'inShiftTasksTemplateId',
      'isBillable',
      'positionId',
      'roleId',
      'start',
      'end',
      'externalLink',
      'externalLinkButtonText',
      'location',
      'address',
      'addressRef',
      'locationType',
      'inviteRadius',
      'parentAddressRefs',
      'workingHoursStart',
      'workingHoursEnd',
      'shiftScheduleContract',
      'timezone',
      'dismissalPayMarkdown',
      'autoLaunch',
      'status',
      'managerCancelReason',
    ];

    return editableFields;
  }

  @computed
  get noteMessage() {
    let message =
      'This process will create new document and will not reconcile with existing records';
    if (this.isEditMode) {
      message =
        'Existing Shifts will be updated. All included columns/fields will be overwritten. Only include columns you want to update';
    }
    return message;
  }

  @computed
  get knownFields(): ShiftUploaderKnownFieldConfig[] {
    const fieldConfig: ShiftUploaderKnownFieldConfig[] = [
      { exampleVal: '', isExampleField: false, key: 'uuid' },

      ...this.additionalKnownFields,

      { exampleVal: 'Sample Shift Title', key: 'title' },
      {
        exampleVal: 'Longer descriptive information for the shift.',
        key: 'description',
      },
      {
        exampleVal: moment()
          .set({ hours: 9, minutes: 0 })
          .add({ days: 1 })
          .format('L LT'),
        key: 'startTime',
        mapping: 'start',
        note: `The 'startTime' and 'endTime' columns accept a wide variety \nof date/time formats. "${moment()
          .set({ hours: 9, minutes: 0 })
          .add({ days: 1 })
          .format('L LT')}" is recommended`,
        transform: (val) => moment(val, this.allFormats).toDate(),
      },
      {
        exampleVal: moment()
          .set({ hours: 17, minutes: 0 })
          .add({ days: 1 })
          .format('L LT'),
        key: 'endTime',
        mapping: 'end',
        transform: (val) => moment(val, this.allFormats).toDate(),
      },
      {
        exampleVal: '',
        isExampleField: this.flatRateIsEnabled,
        key: 'useFlatRatePay',
        transform: toBoolean,
      },
      {
        exampleVal: 0,
        isExampleField: this.flatRateIsEnabled,
        key: 'flatRatePay',
        mapping: 'pay',
        transform: _.toNumber,
      },
      {
        exampleVal: '13',
        key: 'wage',
        mapping: 'rate',
        transform: _.toNumber,
      },
      { isExampleField: false, key: 'rate', transform: _.toNumber },
      {
        exampleVal: 1,
        isExampleField: false,
        key: 'slots',
        transform: _.toNumber,
      },
      {
        exampleVal: '',
        key: 'location',
        mapping: 'address',
        note: `${
          'Location can be any one of the following:\n' +
          '* Empty for the default address \n' +
          '* The UUID of a specific Company Location \n' +
          '* The NAME or externalId of a specific Company Location \n' +
          '* A full Address string for custom addresses \n'
        }${this.remoteIsEnabled ? '* `Remote` for a remote shift' : ''}`,
        transform: this.parseLocation,
      },
      {
        exampleVal: this.defaultIsRemote ? true : '',
        isExampleField: this.remoteIsEnabled,
        key: 'isRemote',
        transform: toBoolean,
      },
      {
        exampleVal: '',
        key: 'positionId',
        note: 'One UUID corresponding to company position',
      },
      {
        exampleVal: 'invite',
        key: 'approvalType',
        mapping: 'defaultAssignmentAction',
        note:
          'Configures approval and default assignment actions, and may \n' +
          'be configured to yes/true/single/required to require manager \n' +
          'approval, or no/false/invite to not require approval',
        transform: this.parseApprovalType,
      },
      {
        key: 'sendSMS',
        note: 'Will send SMS Status Change notifications for \nthe shift if set to yes/true/1',
        transform: toBoolean,
      },
      {
        key: 'sendReminder (hours)',
        mapping: 'remindInterval',
        note:
          'A numeric value indicating a number of hours before \nthe shift to send a ' +
          'reminder notification',
        transform: _.toSafeInteger,
      },
      {
        key: 'dispatchPref',
        note:
          'The UUID of a Dispatch Pref Template. These values can \nbe seen by selecting ' +
          'the **Include Dispatch Prefs** \noption when downloading the sample CSV file',
      },
      {
        isExampleField: false,
        key: 'externalId',
      },
      {
        exampleVal: 'https://google.com',
        isExampleField: this.externalLinkIsEnabled,
        key: 'externalLink',
      },
      {
        exampleVal: 'Open External Link',
        key: 'externalLinkButtonText',
      },
      {
        exampleVal: true,
        isExampleField: this.checkinRemindersIsEnabled,
        key: 'sendCheckinReminder',
        transform: toBoolean,
      },
      {
        exampleVal: '',
        isExampleField: this.excludeFromDashboardCalcIsEnabled,
        key: 'excludeFromDashboardCalc',
        note: 'Shift will not be included in the dashboard calculations',
        transform: toBoolean,
      },
      {
        exampleVal: 'standard',
        isExampleField: this.shiftTypeIsEnabled,
        key: 'shiftType',
        note:
          'Shift Type can be used to differentiate shifts.\nThe valid types of ' +
          "shifts are: 'standard', 'training', 'dryRun',  'orientation' or 'ambassador'.\nIf you do not fill " +
          "in this column it will default to 'standard'.",
      },
      {
        exampleVal: '',
        isExampleField: this.disableShiftAcceptAfterStartIsEnabled,
        key: 'disableShiftAcceptAfterStart',
        note: 'Partners will not be reinvited to the shift after the shift has already started',
        transform: toBoolean,
      },
      // { key: 'invitationImmediate' },
      // { key: 'invitationDelay' },
      // { key: 'hourDelay' },
      // Supported but not "core" fields
      { isExampleField: false, key: 'status' },
      { isExampleField: false, key: 'path' },
      { isExampleField: false, key: 'formattedAddress' },
      { isExampleField: false, key: 'isDeleted', transform: toBoolean },
      {
        enum: ['portal', 'csv-import'],
        isExampleField: false,
        key: 'source',
      },

      {
        exampleVal: false,
        isExampleField: this.photoUploadRequirementIsEnabled,
        key: 'photoUploadRequired',
        note: 'Photo upload will be required to check into shift',
        transform: toBoolean,
      },
      {
        isExampleField: this.enableCheckinSurvey,
        key: 'postShiftSurvey',
        mapping: 'postShiftSurveyDefinitions',
        note: 'The UUID of a training/survey. If set, a worker will be prompted to complete the survey after checking into their shift',
      },
      {
        exampleVal: this.bulkNotificationsIsEnabled,
        isExampleField: this.bulkNotificationsIsEnabled,
        key: 'enableBulkNotifications',
        note:
          'Setting to true will disable individual notifications for ' +
          'assigning and inviting to shifts and enable bulk notifying workers of these shifts',
        transform: toBoolean,
      },
      {
        exampleVal: false,
        key: 'disablePartnerCancel',
        note: 'Partners are not allowed to cancel their shifts',
        transform: toBoolean,
      },
      this.enableOnsiteCheckinCode && {
        exampleVal: this.defaultEnableCheckinCode,
        key: 'enableCheckinCode',
        note: 'Generate OnSite Checkin Code',
        transform: toBoolean,
      },
      this.enableOnsiteCheckinCode && {
        exampleVal: false,
        key: 'requireCheckinCode',
        note: 'Require checkin code for all checkins on a shift',
        transform: toBoolean,
      },
      this.enableOnsiteCheckoutCode && {
        exampleVal: false,
        key: 'requireCheckoutCode',
        note: 'Require checkout code for all checkouts on a shift',
        transform: toBoolean,
      },
      this.enableOnsiteCheckinCode && {
        exampleVal: undefined,
        extendFn: (val, row) =>
          val
            ? {
                useUniqueAttendanceCodes: _.isBoolean(
                  row.useUniqueAttendanceCodes,
                )
                  ? row.useUniqueAttendanceCodes
                  : true,
              }
            : {},
        isExampleField: false,
        key: 'checkinCode',
        note: 'Define a custom checkin code for this shift. CAUTION: This code will differ from all other checkin codes for shifts at this location on the specified day',
      },
      this.enableOnsiteCheckoutCode && {
        exampleVal: undefined,
        extendFn: (val, row) =>
          val
            ? {
                useUniqueAttendanceCodes: _.isBoolean(
                  row.useUniqueAttendanceCodes,
                )
                  ? row.useUniqueAttendanceCodes
                  : true,
              }
            : {},
        isExampleField: false,
        key: 'checkoutCode',
        note: 'Define a custom checkout code for this shift. CAUTION: This code will differ from all other checkout codes for shifts at this location on the specified day',
      },
      this.enableUniqueAttendanceCodes && {
        exampleVal: undefined,
        isExampleField: true,
        key: 'useUniqueAttendanceCodes',
        note: 'Require checkout code for all checkouts on a shift',
        transform: toBoolean,
      },
      {
        exampleVal: null,
        key: 'inviteRadius',
        transform: _.toNumber,
      },
      {
        exampleVal: null,
        isExampleField: false,
        key: 'bonus',
        transform: _.toNumber,
      },
      {
        exampleVal: 'usd',
        isExampleField: this.currencyIsEnabled,
        key: 'currency',
        transform: (val) => this.parseCurrency(val),
      },
      {
        exampleVal: null,
        isExampleField: true,
        key: 'timezone',
        transform: this.parseTimezone,
      },
      {
        exampleVal: 'fixed',
        isExampleField: this.flexibleShiftsEnabled,
        key: 'shiftScheduleType',
      },
      {
        exampleVal: moment()
          .set({ hours: 9, minutes: 0 })
          .add({ days: 1 })
          .format('L LT'),
        isExampleField: this.flexibleShiftsEnabled,
        key: 'workingHoursStart',
        mapping: 'shiftScheduleRules.workingHoursStart',
        transform: (val) => moment(val, this.allFormats).toDate(),
      },
      {
        exampleVal: moment()
          .set({ hours: 17, minutes: 0 })
          .add({ days: 1 })
          .format('L LT'),
        isExampleField: this.flexibleShiftsEnabled,
        key: 'workingHoursEnd',
        mapping: 'shiftScheduleRules.workingHoursEnd',
        transform: (val) => moment(val, this.allFormats).toDate(),
      },
      {
        exampleVal: moment()
          .set({ hours: 7, minutes: 0 })
          .add({ days: 1 })
          .format('L LT'),
        isExampleField: true,
        key: 'autoLaunch',
        transform: (val) => moment(val, this.allFormats).toDate(),
      },
      {
        key: 'scheduleContractStart',
        mapping: 'shiftScheduleContract.start',
        note: 'The start timestamp for the shift from client perspective',
        transform: (val) => moment(val, this.allFormats).toDate(),
      },
      {
        key: 'scheduleContractEnd',
        mapping: 'shiftScheduleContract.end',
        note: 'The end timestamp for the shift from client perspective',
        transform: (val) => moment(val, this.allFormats).toDate(),
      },
      {
        exampleVal: null,
        isExampleField: this.allowBackupShifts,
        key: 'originalBaseShiftId',
        transform: this.parseOrginalBaseShiftId,
      },
      {
        exampleVal: null,
        isExampleField: this.allowBackupShifts,
        key: 'redundancyType',
        note:
          'The following fields are allowed: dupe, backup, or shadow\n' +
          '* Dupe is a redundant shift (manual) \n' +
          '* Make sure the originalBaseShiftId column is set',
        transform: this.parseRedundancyType,
      },
      {
        exampleVal: null,
        isExampleField: false,
        key: 'geofenceTemplateId',
        note: 'The UUID of a Geofence Template for floater shifts',
      },
      {
        exampleVal: undefined,
        isExampleField: false,
        key: 'forecastedFillRate',
        note: 'The FFR of the shift',
        transform: _.toNumber,
      },
      {
        exampleVal: '',
        isExampleField: this.allowFirstShifts,
        key: 'isFirstShift',
        transform: toBoolean,
      },
      {
        exampleVal: true,
        isExampleField: true,
        key: 'isBillable',
        transform: toBoolean,
      },
      {
        exampleVal: '',
        isExampleField: true,
        key: 'inShiftTasksTemplateId',
      },
      {
        exampleVal: 0.6,
        isExampleField: false,
        key: 'dismissalPayMarkdown',
        note: 'The markdown for dismissal pay 0.6 means 60% of the original wage, applicable only for redundant shifts early dismissal',
        transform: this.parseDismissalPayMarkDown,
      },
      {
        exampleVal: 'Canceled',
        isExampleField: false,
        key: 'status',
        note: 'Status of the shift, use this only when bulk canceling the shift',
      },
      {
        exampleVal: 'scheduledByMistake',
        isExampleField: false,
        key: 'managerCancelReason',
        note: 'Reason to cancel the shift',
      },
    ];
    return _(fieldConfig)
      .compact()
      .filter((f) => !this.excludedFields.includes(f.key))
      .map((field) => _.defaults(field, { isExampleField: true }))
      .value();
  }

  @computed get defaultIsRemote() {
    return !!_.get(this.company, 'settings.shifts.defaultIsRemoteShift');
  }

  @computed get remoteIsEnabled() {
    return !!_.get(this.company, 'settings.shifts.enableRemoteShifts');
  }

  @computed get currencyIsEnabled() {
    return !!_.get(this.company, 'settings.shifts.enableShiftCurrency');
  }

  @computed get externalLinkIsEnabled() {
    return !!_.get(this.company, 'settings.things.enableExternalLink');
  }

  @computed get flatRateIsEnabled() {
    return !!_.get(this.company, 'settings.shifts.enableFlatRatePay');
  }

  @computed get checkinRemindersIsEnabled() {
    return !!_.get(this.company, 'settings.shifts.enableCheckinReminders');
  }

  @computed get excludeFromDashboardCalcIsEnabled() {
    return !!_.get(this.company, 'settings.shifts.excludeFromDashboardCalc');
  }

  @computed get shiftTypeIsEnabled() {
    return !!_.get(this.company, 'settings.shifts.shiftType');
  }

  @computed get enableOnsiteCheckinCode() {
    return !!_.get(this.company, 'settings.things.enableOnsiteCheckinCode');
  }

  @computed get enableOnsiteCheckoutCode() {
    return !!_.get(this.company, 'settings.things.enableOnsiteCheckoutCode');
  }

  @computed get enableUniqueAttendanceCodes() {
    return !!_.get(this.company, 'settings.things.enableUniqueAttendanceCodes');
  }

  @computed get defaultEnableCheckinCode() {
    return !!_.get(this.company, 'settings.things.enableCheckinCodeByDefault');
  }

  @computed get photoUploadRequirementIsEnabled() {
    return !!_.get(
      this.company,
      'settings.things.enablePhotoUploadRequirement',
    );
  }

  @computed get disableShiftAcceptAfterStartIsEnabled() {
    return !!_.get(
      this.company,
      'settings.shifts.disableShiftAcceptAfterStart',
    );
  }

  @computed get bulkNotificationsIsEnabled() {
    return !!_.get(this.company, 'settings.shifts.enableBulkNotifications');
  }

  @computed get flexibleShiftsEnabled() {
    return !!_.get(this.company, 'settings.shifts.enableFlexibleShifts');
  }

  @computed get allowBackupShifts() {
    return !!_.get(this.company, 'settings.shifts.allowBackupShifts');
  }

  @computed get allowFirstShifts() {
    return !!_.get(this.company, 'settings.shifts.firstShiftCreation');
  }

  @computed get enableCheckinSurvey() {
    return !!_.get(this.company, 'settings.shifts.enableCheckinSurvey');
  }

  @computed get knownHeaders() {
    return _.map(this.knownFields, 'key');
  }

  @computed get savedLocationRequired() {
    return !!_.get(
      this.company,
      'settings.shifts.requireSavedLocationOnUpload',
      false,
    );
  }

  @computed get disableCustomLocations() {
    return _.get(this.company, 'settings.shifts.disableCustomLocations', null);
  }

  @computed
  get sampleCSVData() {
    if (this.isEditMode) {
      const editableSampleTitles = [
        'uuid',
        'title',
        'description',
        'bonus',
        'rate',
        'dispatchPref',
        'slots',
        'flatRatePay',
        'useFlatRatePay',
        'isBillable',
        'startTime',
        'endTime',
        'externalLink',
        'externalLinkButtonText',
        'location',
        'scheduleContractStart',
        'scheduleContractEnd',
      ];
      const editableSampleDescriptions = [
        '1234', // uuid
        'Sample Edited Shift Title', // title
        'Updated Description', // description
        undefined, // bonus
        undefined, // rate
        undefined, // dispatchPref
        1, // slots
        0, // flat rate pay
        false, // use flat rate pay
        false, // is billable
        'Updated Start time (Only works for fixed shifts)',
        'Updated End time (Only works for fixed shifts)',
        'External Link',
        'External Link Button Text',
        'Sample Location',
        'Updated Start time from client perspective. When not provided on shift creation it will be filled by startTime. (Only works for fixed shifts)',
        'Updated End time from client perspective.  When not provided on shift creation it will be filled by endTime. (Only works for fixed shifts)',
      ];
      return [editableSampleTitles, editableSampleDescriptions];
    }
    const sampleData = super.$sampleCSVData;

    let dispatchPrefs = [];

    if (this.includeDispatchPrefs) {
      dispatchPrefs = _.compact([
        this.includeDispatchPrefs && [],
        this.includeDispatchPrefs && [
          '# Dispatch Prefs',
          'UUID',
          'Default',
          'Pref',
          'Approval',
          'Approval Type',
          'Delay',
          'Pool UUID',
          'User UUIDs',
          'Max Radius',
          '___',
        ],
      ]);

      _.each(this.dispatchPrefs, ({ uuid, title, isDefault, prefs }) => {
        dispatchPrefs.push([
          `# ${title}`,
          uuid,
          isDefault,
          _.get(prefs, '[0].index', ''),
          _.get(prefs, '[0].approval', ''),
          _.get(prefs, '[0].approvalType', ''),
          _.get(prefs, '[0].delay', ''),
          _.get(prefs, '[0].pool', ''),
          _.get(prefs, '[0].users', ''),
          _.get(prefs, '[0].maxRadius', ''),
        ]);

        _.each(prefs.slice(1), (pref) => {
          dispatchPrefs.push([
            '#',
            '',
            '',
            _.get(pref, 'index', ''),
            _.get(pref, 'approval', ''),
            _.get(pref, 'approvalType', ''),
            _.get(pref, 'delay', ''),
            _.get(pref, 'pool', ''),
            _.get(pref, 'users', ''),
            _.get(pref, 'maxRadius', ''),
          ]);
        });
      });
    }

    if (!_.isEmpty(dispatchPrefs)) {
      sampleData.push(...dispatchPrefs);
    }

    return sampleData;
  }

  // #endregion

  @action.bound
  toggleEditMode(val = !this.isEditMode) {
    this.isEditMode = val;
  }

  @action.bound
  setUploadJobSent(val: boolean) {
    this.uploadJobSent = val;
  }

  @action.bound
  async setup(opts) {
    super.setup(opts);

    this.clear();

    this.storeName = opts.storeName || 'shifts';
    this.company = opts.company;

    if (_.isString(this.company)) {
      const company = await dispatch('companies.get', this.company, {
        select: false,
      });
      runInAction(() => {
        this.company = company;
      });
    }

    await this.loadDispatchPrefs();
  }

  @action.bound
  async loadDispatchPrefs() {
    let dispatchPrefs;

    try {
      const prefsRes = await dispatch('dispatchPrefs.getCompanyPrefs', {
        company: this.company,
      });
      dispatchPrefs = _.get(prefsRes, 'data', prefsRes);
    } catch (error) {
      this.log.error('Error on prefs retrieve', error.message);
      throw error;
    }

    runInAction(() => {
      this.dispatchPrefs.replace(dispatchPrefs);
    });
  }

  @action.bound
  toggleDispatchPrefs(val = !this.includeDispatchPrefs) {
    this.includeDispatchPrefs = val;

    if (this.includeDispatchPrefs) {
      this.loadDispatchPrefs();
    }
  }

  @action.bound
  clear() {
    super.clear();
    this.isEditMode = false;
    this.list.clear();
    this.parsedHeaders.clear();
    this.resultHeaders.clear();
    this.uploadProgress = 0;
    this.showShiftTimeAlert = false;
    this.uploadJobSent = false;

    set(this.summary, {
      ignored: 0,
      invalid: 0,
      new: 0,
      updated: 0,
    });

    set(this.summaryIds, {
      ignored: [],
      invalid: [],
      new: [],
      updated: [],
    });
  }

  @action.bound
  async processFile({ options = this.parseOptions, ...rest }) {
    this.setStatus('loading');
    this.setLoading(true);

    try {
      await super.processFile({ options, ...rest });

      this.setStatus('loaded');
    } catch (err) {
      this.log.error('Failed to process file', err);

      this.setStatus('error');

      throw err;
    } finally {
      this.setLoading(false);
    }
  }

  @action.bound
  async evaluating({ validRows, lastChunk }) {
    const { savedLocationRequired, disableCustomLocations } = this;

    /** Pre-load adddresses, positions and surveys to prevent duplicate calls */
    const knownAddresses = await chunkifiedLoader(validRows, {
      query: {
        $select: [
          'defaultInviteRadius',
          'externalId',
          'name',
          'parentIds',
          'uuid',
        ],
        companies: this.company.uuid,
      },
      queryKey: 'name',
      rowFieldKey: 'address',
      service: 'sites',
    });

    const knownPositionsByUUID = await chunkifiedLoader(validRows, {
      query: {
        $select: ['uuid', 'roleId'],
        companies: this.company.uuid,
      },
      queryKey: 'uuid',
      rowFieldKey: 'positionId',
      service: 'positions',
    });

    const knownSurveyDefsByUUID = await chunkifiedLoader(validRows, {
      query: {
        $select: ['uuid', 'title'],
        companies: this.company.uuid,
      },
      queryKey: 'uuid',
      rowFieldKey: 'postShiftSurveyDefinitions',
      service: 'surveyDefinitions',
    });

    this.log.info('pre loaded values', {
      knownAddresses,
      knownPositionsByUUID,
      knownSurveyDefsByUUID,
    });

    type RowFieldKeys = (typeof this.knownHeaders)[number];
    type Row = Record<RowFieldKeys, string>;

    const locatedRows = await Promise.map(
      validRows,
      async (row: Row) => {
        let retval: Partial<Row & IShift> = { ...row };
        if (
          /tbd/i.test(row.locationType) ||
          /address/i.test(row.locationType)
        ) {
          const { address } = retval;
          let site: ISite;
          let loadFromDB = true;

          if (_.get(knownAddresses, address)) {
            site = _.get(knownAddresses, address);
            this.log.silly('Loading site from cache: ', { address });
            loadFromDB = false;
          }

          if (loadFromDB) {
            this.log.silly('Loading site from DB: ', { address });
            const { data: sites, total } = await dispatch('sites.runQuery', {
              $or: [
                {
                  externalId: {
                    $options: 'i',
                    $regex: '^' + address + '$',
                  },
                },
                {
                  name: {
                    $options: 'i',
                    $regex: '^' + address + '$',
                  },
                },
              ],
              companies: this.company.uuid,
            }).catch((err) => {
              this.log.error('Failed to load site from address string', err, {
                address,
              });

              return [];
            });

            if (total > 1) {
              this.log.warn('Found %d matching sites, expected 1', total);
            }

            site = _.first(sites);

            if (site) {
              _.set(knownAddresses, address, site);
            }
          }

          if (site) {
            const inviteRadius =
              retval?.inviteRadius || site?.defaultInviteRadius || undefined;
            retval = {
              ...retval,
              address: null,
              addressRef: site.uuid,
              inviteRadius,
              locationType: 'site',
              parentAddressRefs: site.parentIds,
            };
          } else {
            if (savedLocationRequired) {
              _.set(row, 'err', `No Saved Location Found w/ ${address}`);
            }
            if (disableCustomLocations) {
              _.set(row, 'err', 'Custom Location Creation is disabled');
            }

            this.log.warn('Unable to find matching address for string', {
              address,
            });
          }
        }

        // Add in roleId and search.tags based on positionId
        if (!_.isEmpty(_.get(retval, 'positionId'))) {
          const positionId = retval.positionId;
          const position = knownPositionsByUUID[positionId];
          if (position) {
            const positionId = retval.positionId;
            if (!this.isEditMode) {
              retval.search = { tags: [positionId] };
            }
            retval.roleId = position.roleId;

            if (!retval.roleId) {
              _.set(retval, 'err', `No Role Found for Position ${positionId}`);
            }
          } else {
            _.set(retval, 'err', `Unknown position for uuid ${positionId}`);
          }
        }

        if (!_.isEmpty(_.get(retval, 'inShiftTasksTemplateId'))) {
          const templateId = retval.inShiftTasksTemplateId;
          const existingTemplates = await getStore('inShiftTaskTemplates').find(
            {
              query: { companyId: this.company.uuid, uuid: templateId },
            },
          );
          if (_.isEmpty(existingTemplates)) {
            retval.err = `Unknown in shift task template for uuid ${templateId}`;
          }
        }

        if (!_.isEmpty(_.get(retval, 'postShiftSurveyDefinitions'))) {
          const surveyDefIds = (retval as Row).postShiftSurveyDefinitions;
          const surveyDefIdsArray = surveyDefIds?.split('|');

          let hasErrors = false;
          let error = 'No Survey Definition Found for UUIDs:';
          const postShiftSurveyDefinitions = [];

          surveyDefIdsArray.forEach((surveyDefId) => {
            const surveyDef = knownSurveyDefsByUUID[surveyDefId];
            if (_.isEmpty(surveyDef)) {
              hasErrors = true;
              error = error.concat(` ${surveyDefId}`);
            } else {
              postShiftSurveyDefinitions.push({
                title: surveyDef.title,
                trainingId: null,
                uuid: surveyDef.uuid,
              });
            }
          });

          retval.postShiftSurveyDefinitions = postShiftSurveyDefinitions;

          if (hasErrors) {
            retval.err = error;
          }
        }

        if (!_.isEmpty(_.get(retval, 'dispatchPref'))) {
          const dispatchPrefId = (retval as Row).dispatchPref;
          const dispatchPref = this.dispatchPrefs.find(
            (dp) => dp.uuid === dispatchPrefId,
          );
          if (_.isEmpty(dispatchPref)) {
            _.set(
              retval,
              'err',
              `No Dispatch Pref Found for UUID ${dispatchPref}`,
            );
          }
        }

        const shiftIsRemote =
          _.get(retval, 'isRemote', false) && this.remoteIsEnabled;

        if (
          this.savedLocationRequired &&
          _.isEmpty(_.get(retval, 'addressRef')) &&
          !shiftIsRemote &&
          !this.isEditMode
        ) {
          _.set(retval, 'err', 'No Saved Address Ref Provided');
        } else if (_.isString(retval.address) && !!retval.address) {
          if (this.disableCustomLocations === false) {
            retval.address = parseAddress(retval.address);
          } else if (!shiftIsRemote) {
            _.set(retval, 'err', 'New addresses are not allowed');
          }
        }

        return retval;
      },
      { concurrency: 1 },
    );

    runInAction(() => {
      this.list.push(...locatedRows);
    });
  }

  @action.bound
  getFileHeaders({ rows }) {
    const casedHeaders = _.map(rows[0], _.toLower);
    const indices = _.reduce(
      this.knownHeaders,
      (acc, header) => {
        acc[header] = _.indexOf(casedHeaders, _.toLower(header));
        return acc;
      },
      {},
    );

    this.indices = indices;
    this.log.debug('Loaded Table Indices', { indices });
  }

  @action.bound
  async parseChunk({ chunkOfRows, lastChunk, firstChunk }) {
    if (firstChunk) {
      this.getFileHeaders({ rows: chunkOfRows });
    }
    let parsedHeaders = [];
    const validRows = _(chunkOfRows)
      .map((row, i) => {
        if (!i && firstChunk) {
          return null;
        }

        this.log.debug(row);

        return _.reduce(
          this.knownHeaders,
          (acc, colKey) => {
            const colIndex = this.indices[colKey];

            if (colIndex >= 0) {
              // The .trim() is prevent any unwanted white spacing issues from the csv
              acc[colKey] = row[colIndex]?.trim();
            }

            return acc;
          },
          {},
        );
      })
      // FIXME: !!!We need to do different parsing based on if editing or not!!!
      .reject((rowObj) => _.isEmpty(_.omitBy(rowObj, _.isEmpty)))
      .map((data) => this.doMapping({ data, mapping: this.knownFields }))
      .map((row) => {
        parsedHeaders = _.union(parsedHeaders, _.keys(row));

        if (!_.isEmpty(row.loc?.coordinates)) {
          _.set(row, 'loc.type', 'Point');
        }

        return row;
      })
      .map((row) => {
        if (!moment(row.start).isValid()) {
          _.set(row, 'err', 'Shift start time is formatted incorrectly.');
          this.showShiftTimeAlert = true;
        }
        if (!moment(row.end).isValid()) {
          _.set(row, 'err', 'Shift end time is formatted incorrectly.');
          this.showShiftTimeAlert = true;
        }
        if (
          row.start &&
          row.end &&
          moment(row.end).isBefore(moment(row.start))
        ) {
          _.set(
            row,
            'err',
            'Shift cannot be uploaded with a start time after the end time.',
          );
          this.showShiftTimeAlert = true;
        }
        if (row.start && moment(row.start).isBefore(moment())) {
          _.set(row, 'warn', 'Shift has a start time in the past.');
        }

        return row;
      })
      .map((row) => {
        if (!this.isEditMode && !row.positionId) {
          _.set(
            row,
            'err',
            'All shifts must now have positionId (previously role) set',
          );
        }
        return row;
      })
      .map((row) => {
        if (!row.useFlatRatePay && row.rate == null && _.has(row, 'rate')) {
          _.set(
            row,
            'err',
            'Shift cannot be uploaded with an empty wage if useFlatRatePay is disabled.',
          );
          this.showShiftTimeAlert = true;
        }
        return row;
      })
      .map((row) => {
        if (row.useFlatRatePay && row.pay == null) {
          _.set(
            row,
            'err',
            'Shift cannot be uploaded with an empty flatRatePay if useFlatRatePay is enabled.',
          );
          this.showShiftTimeAlert = true;
        }
        return row;
      })
      .map(({ shiftScheduleRules, ...row }) => {
        if (/flexible/i.test(row?.shiftScheduleType)) {
          const {
            workingHoursStart: uploadedWorkingHoursStart,
            workingHoursEnd: uploadedWorkingHoursEnd,
          } = shiftScheduleRules ?? {};
          const {
            newWorkingHoursStartValue,
            newWorkingHoursEndValue,
            flexibleShiftValidationError,
          } = validateFlexibleShiftWorkingHours({
            ignoreDateDifference: true,
            shiftEnd: row.end,
            shiftScheduleRules,
            shiftStart: row.start,
          });

          const isStartValid =
            uploadedWorkingHoursStart &&
            moment(newWorkingHoursStartValue).isValid();
          const isEndValid =
            uploadedWorkingHoursEnd &&
            moment(newWorkingHoursEndValue).isValid();

          if (!isStartValid) {
            _.set(
              row,
              'err',
              'Working hours start time is formatted incorrectly.',
            );
            this.showShiftTimeAlert = true;
          }
          if (!isEndValid) {
            _.set(
              row,
              'err',
              'Working hours end time is formatted incorrectly.',
            );
            this.showShiftTimeAlert = true;
          }

          // eslint-disable-next-line no-param-reassign
          row.shiftScheduleRules = {
            workingHoursEnd: newWorkingHoursEndValue,
            workingHoursStart: newWorkingHoursStartValue,
          };

          // Set slots to 1 if flexible shift
          _.set(row, 'slots', 1);

          if (flexibleShiftValidationError) {
            _.set(row, 'err', flexibleShiftValidationError);
          }
        }

        return row;
      })
      .map((row) => {
        let schedule_contract_start: string;
        let schedule_contract_end: string;

        if (row.shiftScheduleContract) {
          schedule_contract_start = row.shiftScheduleContract.start;
          schedule_contract_end = row.shiftScheduleContract.end;
        }
        if (!moment(schedule_contract_start).isValid()) {
          _.set(
            row,
            'err',
            'Schedule Contract Start time is formatted incorrectly.',
          );
          this.showShiftTimeAlert = true;
        }
        if (!moment(schedule_contract_end).isValid()) {
          _.set(
            row,
            'err',
            'Schedule Contract End time is formatted incorrectly.',
          );
          this.showShiftTimeAlert = true;
        }
        if (
          schedule_contract_start &&
          schedule_contract_end &&
          moment(schedule_contract_end).isBefore(
            moment(schedule_contract_start),
          )
        ) {
          _.set(
            row,
            'err',
            'Shift cannot be uploaded with a Schedule Contract start time after the end time.',
          );
          this.showShiftTimeAlert = true;
        }
        return row;
      })
      .map((row) => {
        if (row.timezone) {
          const start = moment(row.start).format('YYYY-MM-DDTHH:mm:ss');
          const end = moment(row.end).format('YYYY-MM-DDTHH:mm:ss');
          _.set(row, 'start', moment.tz(start, row.timezone).toDate());
          _.set(row, 'end', moment.tz(end, row.timezone).toDate());

          if (/flexible/i.test(row?.shiftScheduleType)) {
            const flexStart = moment(
              row.shiftScheduleRules.workingHoursStart,
            ).format('YYYY-MM-DDTHH:mm:ss');
            const flexEnd = moment(
              row.shiftScheduleRules.workingHoursEnd,
            ).format('YYYY-MM-DDTHH:mm:ss');

            _.set(
              row.shiftScheduleRules,
              'workingHoursStart',
              moment.tz(flexStart, row.timezone).toDate(),
            );
            _.set(
              row.shiftScheduleRules,
              'workingHoursEnd',
              moment.tz(flexEnd, row.timezone).toDate(),
            );
          }
          if (row.shiftScheduleContract) {
            const schedule_contract_start = moment(
              row.shiftScheduleContract.start,
            ).format('YYYY-MM-DDTHH:mm:ss');
            const schedule_contract_end = moment(
              row.shiftScheduleContract.end,
            ).format('YYYY-MM-DDTHH:mm:ss');
            _.set(
              row.shiftScheduleContract,
              'start',
              moment.tz(schedule_contract_start, row.timezone).toDate(),
            );
            _.set(
              row.shiftScheduleContract,
              'end',
              moment.tz(schedule_contract_end, row.timezone).toDate(),
            );
          }
        }
        return row;
      })
      .compact()
      .value();

    if (firstChunk) {
      this.parsedHeaders.replace(parsedHeaders);
    }

    this.setStatus('Evaluating');

    await this.evaluating({
      lastChunk,
      validRows,
    });
  }

  @action.bound
  async parse({ rows }) {
    // split the results in 10% batches from the total of rows
    const chunkLength = 200;
    const chunksArray = _.chunk(rows, chunkLength);
    let p = 0;

    await Promise.map(
      chunksArray,
      async (chunk, index) => {
        await this.parseChunk({
          chunkOfRows: chunk,
          firstChunk: index === 0,
          lastChunk: index === _.size(chunksArray) - 1,
        });

        runInAction(() => {
          this.uploadProgress = Math.round((++p / _.size(chunksArray)) * 100);
        });
      },
      { concurrency: 1 },
    );

    if (this.showShiftTimeAlert) {
      // eslint-disable-next-line no-alert
      alert('One or more of the shifts have an error with the shift times');
    }

    const { existingEntries = [] } = _.groupBy(this.list, ({ uuid }) =>
      _.isEmpty(uuid) ? 'newEntries' : 'existingEntries',
    );

    let hasEmptyFields = false;
    const editableFields = this.getEditableFields;
    editableFields.forEach((field: string) => {
      const emptyValuesForField = _(existingEntries)
        .filter(
          (row) =>
            _.has(row, field) &&
            !_.isBoolean(row[field]) &&
            !_.isNumber(row[field]) &&
            _.isEmpty(row[field]),
        )
        .value();
      if (!_.isEmpty(emptyValuesForField)) {
        hasEmptyFields = true;
      }
    });

    if (hasEmptyFields) {
      // eslint-disable-next-line no-alert
      alert(
        'Some of your rows have empty values. These will be overwritten on upload! Only include columns you want to update',
      );
    }

    runInAction(() => {
      this.list.replace(_.orderBy(this.list, 'err', 'asc'));
      this.setStatus('Parsed all Rows');
      this.uploadProgress = 100;
    });
    // eslint-disable-next-line no-console
    console.table(this.list);
  }

  @action.bound
  async save() {
    this.setStatus('saving');
    this.setSaving(true);

    runInAction(() => {
      this.uploadProgress = 0;
    });

    dispatch('audit.create', {
      data: {
        action: 'Upload Addresses',
        extra: {
          file: _.pick(this.file, ['name', 'type', 'size']),
          serviceName: this.storeName,
        },
      },
    });

    try {
      const {
        newEntries = [],
        existingEntries = [],
        invalidEntries = [],
      } = _.groupBy(this.list, ({ uuid, err }) => {
        if (err) {
          return 'invalidEntries';
        }

        return _.isEmpty(uuid) ? 'newEntries' : 'existingEntries';
      });

      if (!_.isEmpty(invalidEntries)) {
        runInAction(() => {
          this.saveResults.invalid.push(...invalidEntries);
        });
        this.setStatus('Error Saving Results');
        this.setSaving(false);
        this.setErrorMessage('Shifts to be saved contain errors.');
        return;
      }

      await this.createNewRecords(newEntries);
      await this.updateExistingRecords(existingEntries);

      if (!_.isEmpty(this.summaryIds.invalid)) {
        this.log.info(
          `Saved ${this.summary.invalid} ${this.storeName} records`,
          {
            uuids: this.summaryIds.invalid,
          },
        );
      }

      this.setStatus('saved');
    } catch (err) {
      this.log.error(`Failed to save ${this.storeName} records`, err);

      dispatch('ui.snackBar.error', 'Sorry, something went wrong');
      this.setStatus('error');
    }

    this.setSaving(false);
  }

  @action.bound
  private async createNewRecords(rows) {
    const { [this.storeName]: shiftsStore } = getStores();

    const defaults = {
      enableCheckinCode: !!this.defaultEnableCheckinCode,
    };

    const shiftsToUpload = await Promise.each(rows, async (data) => {
      const newShift: Partial<IShift> = _.cloneDeep(data);

      return {
        ...defaults,
        ...newShift,
      };
    });

    const settings = _.get(this.company, 'settings', {});
    const enableAsyncUploads = _.get(
      settings,
      'shifts.enableAsyncUploads',
      false,
    );

    if (enableAsyncUploads && shiftsToUpload.length > 0) {
      const fieldsForCsv = new Set<string>();
      const shiftsWithUUIDs = shiftsToUpload.map((shift: IShift) => {
        const shiftFields = Object.keys(shift);
        shiftFields.forEach((key) => fieldsForCsv.add(key));
        shift.uuid = uuid();
        return shift;
      });

      // const uniqueFields = _.uniq(_.flattenDeep(fieldsForCsv));
      const parser = new Parser({
        fields: ['uuid', ...fieldsForCsv],
      });

      const csvData = parser.parse(shiftsWithUUIDs);

      const file = new Blob([csvData], { type: 'text/csv' });

      const filename = `${this.company.uuid}/shifts/${new Date().getTime()}`;
      const bucketService = 'CLOUD_STORAGE_SCHEDULE';

      const query = {
        action: 'sign',
        bucketService,
        contentType: file.type,
        filename,
        operationName: 'putObject',
        useStagingClient: !IS_PRODUCTION,
      };

      const res = await getStore('gcp').get(null, { query });

      await fetch(res.signedUrl, {
        body: file,
        headers: { 'Content-Type': file.type },
        method: 'PUT',
      });

      try {
        const response = await getStore('shiftUploader').create({
          data: {
            bucketService,
            companyId: this.company.uuid,
            companyName: this.company.name,
            filename,
            useStagingClient: !IS_PRODUCTION,
          },
        });
        if (response.success) {
          this.setUploadJobSent(true);
        }
      } catch (err) {
        this.log.error(`Failed to save ${this.storeName} records`, err);
        dispatch('ui.snackBar.error', 'Sorry, something went wrong');
        this.setStatus('error');
      }
    } else {
      await Promise.each(
        shiftsToUpload,
        async (data: IShift, index: number) => {
          try {
            const doc = await shiftsStore.create({
              data,
            });

            runInAction(() => {
              this.summary.new += 1;
              this.summaryIds.new.push(doc.uuid);

              if (this.storeFullValues) {
                this.saveResults.new.push(doc);
                this.resultHeaders = _.union(
                  this.resultHeaders,
                  _(doc)
                    .keys()
                    .reject((k) => /^_/.test(k))
                    .value(),
                );
              }
            });
          } catch (err) {
            this.log.error(`Failed to save ${this.storeName} record`, err);
            runInAction(() => {
              this.summary.invalid += 1;
              this.summaryIds.invalid.push(`${data.title}::${err.message}`);

              this.saveResults.invalid.push(_.extend(data, { err }));
            });
          } finally {
            runInAction(() => {
              this.uploadProgress = Math.round((index / rows.length) * 100);
            });
          }
        },
      );
    }
  }

  @action.bound
  private async updateExistingRecords(rows) {
    const { [this.storeName]: shiftsStore } = getStores();

    // only allow editing of flat rate pay if company setting is enabled
    const editableFields = this.flatRateIsEnabled
      ? this.getEditableFields
      : _.without(this.getEditableFields, 'pay', 'useFlatRatePay');
    let wrongFields = new Set(); //should show what wrong fields are added

    const invalidExistingEntries = _.filter(rows, (item) => {
      const invalidEntry = _.omit(item, ['uuid', ...editableFields]);
      wrongFields = new Set([...wrongFields, ..._.keys(invalidEntry)]);
      return !_.isEmpty(invalidEntry);
    });

    if (!_.isEmpty(invalidExistingEntries)) {
      this.setStatus('Error Saving Results');
      this.setSaving(false);
      this.setErrorMessage(
        `Only the following fields are editable with the bulk uploader for this company: ${editableFields.join(
          ', ',
        )}, but the following fields were included: ${Array.from(
          wrongFields,
        ).join(', ')}`,
      );
      return;
    }

    await Promise.each(
      rows,
      async (data, index) => {
        try {
          const { uuid: shiftId } = data;

          const existingDoc = await dispatch(`${this.storeName}.get`, shiftId, {
            query: { company: this.company.uuid },
            select: false,
          });

          const existing = flatten(_.cloneDeep(existingDoc));
          const newData = flatten<Partial<IShift>, Record<string, unknown>>(
            _.cloneDeep(data),
          );

          const patchData = _.pickBy(newData, (val, key) => {
            if (
              !_.isNumber(val) &&
              !_.isBoolean(val) &&
              _.isEmpty(val) &&
              _.isEmpty(existing[key])
            ) {
              this.log.silly('"%s" is Double Empty', {
                ex: existing[key],
                val,
              });
              return false;
            }

            if (_.isEqual(val, existing[key])) {
              this.log.silly('"%s" is Equal', { ex: existing[key], val });
              return false;
            }

            this.log.silly('"%s" Are DIFFERENT', { ex: existing[key], val });
            return true;
          });

          if (_.isEmpty(patchData)) {
            runInAction(() => {
              this.summary.ignored += 1;
              this.summaryIds.ignored.push(shiftId);

              if (this.storeFullValues) {
                this.saveResults.ignored.push(existing);
                this.resultHeaders = _.union(
                  this.resultHeaders,
                  _(existing)
                    .keys()
                    .reject((k) => /^_/.test(k))
                    .value(),
                );
              }
            });

            return;
          }

          this.log.debug(
            'Patch Existing Doc with Data: %s',
            JSON.stringify(patchData, null, 2),
            { existing, newData },
          );
          let query = {};
          if (patchData?.inShiftTasksTemplateId) {
            query = {
              $client: {
                createInShiftTasksFromTemplateId:
                  patchData?.inShiftTasksTemplateId,
              },
            };
          }

          const doc = await shiftsStore.update({
            data: patchData,
            id: shiftId,
            query,
          });

          runInAction(() => {
            this.summary.updated += 1;
            this.summaryIds.updated.push(shiftId);

            if (this.storeFullValues) {
              this.saveResults.updated.push(doc);
              this.resultHeaders = _.union(
                this.resultHeaders,
                _(doc)
                  .keys()
                  .reject((k) => /^_/.test(k))
                  .value(),
              );
            }
          });
        } catch (err) {
          this.log.error(`Failed to save ${this.storeName} record`, err);
          runInAction(() => {
            this.summary.invalid += 1;
            this.summaryIds.invalid.push(`${data.uuid}::${err.message}`);

            this.saveResults.invalid.push(_.extend(data, { err }));
          });
        } finally {
          runInAction(() => {
            this.uploadProgress = Math.round((index / rows.length) * 100);
          });
        }
      },
      // { concurrency: 1 }, // inapplicable to Promise.each
    );

    this.log.info(`Saved ${this.summary.updated} ${this.storeName} records`, {
      uuids: this.summaryIds.updated,
    });

    if (this.storeFullValues) {
      // eslint-disable-next-line no-console
      console.table(this.saveResults.new);
    }
  }

  doMapping({ data, mapping }) {
    const settings = _.get(this.company, 'settings', {});
    const enablePartnerCancel = _.get(
      settings,
      'things.enablePartnerCancel',
      false,
    );
    let newData = data;
    if (!data.uuid) {
      newData = _.extend(data, {
        company: this.company.uuid,
        enablePartnerCancel,
        source: 'csv-import',
      });
    }

    return super.doMapping({ data: newData, mapping });
  }

  /* eslint-disable no-param-reassign */
  parseLocation(locationString, key, row) {
    if (/^remote$/i.test(locationString)) {
      row.addressRef = null;
      row.isRemote = true;
      row.locationType = 'remote';
      return null;
    }
    if (isUUID(locationString)) {
      row.addressRef = locationString;
      row.locationType = 'address';
      return locationString;
    }
    row.addressRef = null;
    row.locationType = 'tbd';
    return locationString;
  }

  parseApprovalType = (val) => {
    //  ['assign', 'invite', 'approvalRequired', 'doubleApprovalRequired'];
    if (/^(yes|true|single|approvalRequired|required)$/i.test(val)) {
      return 'approvalRequired';
    }
    if (/^(double|doubleApprovalRequired)$/i.test(val)) {
      return 'doubleApprovalRequired';
    }
    if (/^assign$/i.test(val)) {
      return 'assign';
    }
    if (/^(invite|no|false)$/i.test(val)) {
      return 'invite';
    }

    return 'invite';
  };

  parseCurrency = (value: string) => {
    const currencyOption = _.find(currencyDropdownOptions, { value });
    if (currencyOption) {
      return value;
    }
    return 'usd';
  };

  parseTimezone = (value: string) => {
    const timezoneInfo = moment.tz.zone(value);
    if (!_.isNull(timezoneInfo)) {
      return value;
    }

    return undefined;
  };

  parseToArray = (val) => {
    if (/,|;/.test(val)) {
      return _(val)
        .split(/,|;/)
        .map((e) => _.trim(e))
        .value();
    }
    return [val];
  };
  parseOrginalBaseShiftId = (orginalBaseShiftId, key, row) => {
    if (orginalBaseShiftId) {
      row.redundancyCreationMethod = 'manualAdminRedundancy';
    }
    return orginalBaseShiftId;
  };
  parseRedundancyType = (redundancyType, key, row) => {
    const validStates = ['dupe', 'backup', 'shadow', 'floater'];
    const lowercaseType = _.toLower(redundancyType);
    const validType = _.includes(validStates, lowercaseType);

    if (validType && row.originalBaseShiftId) {
      if (lowercaseType === 'dupe') {
        row.redundancyCreationMethod = 'manualAdminRedundancy';
      }
      return lowercaseType;
    }
    if (!validType && row.originalBaseShiftId) {
      // if this column isn't set, don't store originalBaseShiftId
      row.originalBaseShiftId = undefined;
    }
    if (validType && lowercaseType === 'floater') {
      row.redundancyCreationMethod = 'manualFloaterCreationRedundancy';
      row.sendCheckinReminder =
        row.sendCheckinReminder ?? this.checkinRemindersIsEnabled;
      return lowercaseType;
    }
    return undefined;
  };

  parseDismissalPayMarkDown = (dismissalPayMarkdown, key, row) => {
    if (!row.redundancyType) {
      return undefined;
    }
    dismissalPayMarkdown = _.toNumber(dismissalPayMarkdown);
    return _.inRange(dismissalPayMarkdown, 0.1, 1)
      ? dismissalPayMarkdown
      : undefined;
  };
}
