import { action, observable } from 'mobx';

/**
 * Store for UI Modal where user can view responses from a survey
 *
 * @export
 * @class SurveyResponseModal
 */

export default class SurveyResponseModal {
  @observable
  isOpen = false;

  @observable
  assignment = {};

  @action
  setup({ assignment = {}, open = this.isOpen }) {
    this.assignment = assignment;
    this.isOpen = open;
  }

  @action
  clear() {
    this.isOpen = false;
    this.assignment = {};
  }
}
