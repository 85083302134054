const BackgroundCheckOptions = [
  { key: 1, text: 'MVR', value: 'mvr' },
  {
    key: 2,
    text: 'Driver basic plus federal',
    value: 'driver_basic_plus_federal',
  },
  {
    key: 3,
    text: 'Basic with federal criminal',
    value: 'basic_with_federal_criminal',
  },
  {
    key: 4,
    text: 'Basic criminal',
    value: 'basic_criminal',
  },
  {
    key: 5,
    text: 'Premium criminal',
    value: 'premium_criminal',
  },
  {
    key: 6,
    text: 'Eventus Check',
    value: 'eventus_package',
  },
  {
    key: 7,
    text: '9 Panel Drug Screening',
    value: 'drug_screening_nine_panel',
  },
  {
    key: 8,
    text: 'Pepsi Beverages Package',
    value: 'pepsi_beverages_package',
  },
  {
    key: 10,
    text: 'Pepsi Foods Package',
    value: 'pepsi_foods_package',
  },
  {
    key: 11,
    text: 'Pepsi Foods Package New',
    value: 'pepsi_foods_package_new',
  },
  {
    key: 12,
    text: '10 Panel Drug Screening',
    value: 'drug_screening_ten_panel',
  },
];

export { BackgroundCheckOptions };
