import type {
  IChannel,
  ICompany,
  IWorker,
} from '@shiftsmartinc/shiftsmart-types';

import { action, observable, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';

import { getChannelWorker } from '#/shared/components/chat/ChannelSummary';

export default class ChatInput {
  @observable
  messageText = '';

  @observable
  enableForceSMS = false;

  @observable
  forceSMS = false;

  @observable cannedResponsesPopupOpen = false; // CannedResponseSearch

  @action
  async setup({ channel }: { channel: IChannel }) {
    const company: ICompany = dispatch('auth.getCompany');
    const channelType = channel?.channelType?.category ?? false;
    this.enableForceSMS =
      _.get(company, 'settings.chat.enableForceSMS', false) &&
      channelType === 'one-on-one';

    if (this.enableForceSMS) {
      const worker = getChannelWorker({ channel });
      if (worker?.uuid) {
        const { accountProps } = ((await dispatch(
          'workers.get',
          worker.uuid,
        )) ?? {}) as Partial<IWorker>;
        if (
          !accountProps?.sendSms ||
          accountProps?.smsBlocked ||
          _.includes(
            accountProps?.unsubscribedMsids,
            company.twilioConversationsMessagingServiceSid,
          )
        ) {
          runInAction(() => {
            this.enableForceSMS = false;
          });
        }
      }
    }

    runInAction(() => {
      this.forceSMS = false;
    });
  }

  @action
  send({ conversation, user, channel } = {}) {
    const value = this.messageText;
    if (!_.isEmpty(value)) {
      const sid = conversation.sid || channel.sid;
      const attributes = {
        sender: {
          displayName: user.displayName,
          profileImage: user.profileImageURL,
          userType: 'employer',
          uuid: user.uuid,
        },
        timestamp: `${Date.now()}`,
        type: 'text',
      };
      attributes.forceSMS = this.forceSMS;
      dispatch('twilioConversations.sendMessage', {
        attributes,
        message: value,
        selectedTwilioChannel: conversation,
        sid,
      });
    }

    this.setMessageText({ value: '' });
    this.toggleForceSMS(false);
  }

  @action
  clear() {}

  /* Interactions */

  @action
  toggleForceSMS(state = !this.forceSMS) {
    this.forceSMS = this.enableForceSMS ? state : false;
  }

  @action
  setMessageText({ value }) {
    this.messageText = value;
  }

  @action
  get(key) {
    return _.get(this, key);
  }

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value = '') {
    _.set(this, key, value);
  }
}
