import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';

import BaseStore from './_baseStore';

export type IShiftUploader = IBaseItem & Record<string, unknown>;

export default class ShiftUploaderStore extends BaseStore<IShiftUploader> {
  constructor() {
    const baseItem = { csvFile: '' };
    super({ baseItem, serviceName: 'shiftUploader' });

    this.selected = _.clone(this.baseItem);

    return this;
  }
}
