import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export type IMessageTag = IBaseItem & Record<string, unknown>;

export default class MessageTagStore extends BaseStore<IMessageTag> {
  constructor() {
    const baseItem = {
      companies: [],
      createdAt: null,
      keywords: [],
      title: null,
      updatedAt: null,
      uuid: null,
    };
    super({ baseItem, serviceName: 'messageTags' });

    return this;
  }
}
