import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import cx from 'classnames';
import React, { useState } from 'react';
import { ContentBlock, Editor, EditorProps } from 'react-draft-wysiwyg';
import styled from 'styled-components';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import _ from 'lodash';

import colors from './colors';

type ToolBarOption =
  | 'blockType'
  | 'colorPicker'
  | 'embedded'
  | 'emoji'
  | 'fontFamily'
  | 'fontSize'
  | 'history'
  | 'image'
  | 'inline'
  | 'link'
  | 'list'
  | 'remove'
  | 'textAlign';

interface RichTextEditorProps extends Omit<EditorProps, 'contentState'> {
  className?: string;
  contentState?: ContentState | string;
  isHTML?: boolean;
  onStateChange?: (content: ContentState | string) => void;
  toolBarOptions?: ToolBarOption[];
}

export const convertEditorStateToRawDbOjbect = (editorStateContent) =>
  JSON.stringify(convertToRaw(editorStateContent));

export const convertRawDbOjbectToEditorStateOjbect = (rawJson) =>
  convertFromRaw(JSON.parse(rawJson));

const EditorContainer = styled.div`
  border: 1px solid #f1f1f1;
  border-top: 0px;
  border-radius: 2px;
  & .public-DraftEditor-content .public-DraftStyleDefault-block {
    margin: 0 !important;
  }
`;

/**
 * @name RichTextEditor
 * @external https://jpuri.github.io/react-draft-wysiwyg/#/docs
 * @description A WYSIWYG editor built using ReactJS and DraftJS.
 */
const RichTextEditor = (props: RichTextEditorProps) => {
  const {
    className,
    contentState = null,
    isHTML = false,
    onStateChange,
    toolBarOptions = ['inline', 'colorPicker', 'list', 'image', 'link'],
    ...rest
  } = props;

  // Hooks
  const [editorState, setEditorState] = useState(() => {
    if (!_.isEmpty(contentState)) {
      let state = contentState as ContentState;
      if (isHTML) {
        const blocksFromHTML = htmlToDraft(contentState as string);
        state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
      }
      return EditorState.createWithContent(state);
    }

    return EditorState.createEmpty();
  });

  // Handlers
  const onCustomBlockRender = (block: ContentBlock) => {
    const type = block.getType();

    switch (type) {
      case 'paragraph':
        return { element: 'p' };
      case 'heading-one':
        return { element: 'h2' };
      case 'heading-two':
        return { element: 'h2' };
      case 'heading-three':
        return { element: 'h3' };
      case 'heading-four':
        return { element: 'h4' };
      case 'heading-five':
        return { element: 'h5' };
      case 'heading-six':
        return { element: 'h6' };
      case 'unordered-list-item':
        return { element: 'ul' };
      case 'ordered-list-item':
        return { element: 'ol' };

      // Fallback to paragraphs for consistent styling
      case 'unstyled':
        return { element: 'p' };

      default:
        return { element: 'div' };
    }
  };

  const onEditorStateChange = (state: EditorState) => {
    const content = state.getCurrentContent();

    if (onStateChange) {
      if (isHTML) {
        onStateChange(draftToHtml(convertToRaw(content)));
      } else {
        onStateChange(content);
      }
    }

    setEditorState(state);
  };

  return (
    <EditorContainer className={cx(className, 'wysiwyg')}>
      <Editor
        customBlockRenderFunc={onCustomBlockRender}
        editorClassName="wysiwyg"
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{ options: toolBarOptions }}
        toolbarStyle={{ backgroundColor: colors.grey2 }}
        {...rest}
      />
    </EditorContainer>
  );
};

export default RichTextEditor;
