import _ from 'lodash';
import { computed } from 'mobx';
import { dispatch } from 'rfx-core';

import { getChildLogger, SSMLogger } from '#/shared/utils/client.logger';

import Form from './_.extend';

const log = getChildLogger('forms.surveyDefinition');

export class SurveyDefinitionForm extends Form {
  constructor(...args) {
    super(...args);
    this.log = getChildLogger('forms.surveyDefinition');
    return this;
  }

  log: SSMLogger;

  @computed
  get gradedQuestionsCount() {
    if (this.$('model').value) {
      try {
        const surveyModel = JSON.parse(this.$('model').value);
        if (surveyModel.pages) {
          const gradedQuestionsCount = _(surveyModel.pages)
            .map((page) => page.elements || page.questions || [])
            .flatten()
            .value()
            .filter((q) => 'correctAnswer' in q).length;

          this.log.debug('Calculated graded Question count', {
            gradedQuestionsCount,
          });
          return gradedQuestionsCount;
        }
        throw new Error(
          'Survey model was not parsed or was incorrectly formatted',
        );
      } catch (err) {
        this.log.error('gradedQuestionsCount calculation failed', err);
        dispatch(
          'ui.snackBar.error',
          `Survey model was not parsed or was incorrectly formatted`,
        );
        return -1;
      }
    } else return -1;
  }

  hooks = () => ({
    async onSuccess(form) {
      const storeAction = form.values().uuid
        ? 'surveyDefinitions.update'
        : 'surveyDefinitions.create';

      try {
        const data = {
          ...form.values(),
          gradedQuestionsCount: form.gradedQuestionsCount,
        };
        await dispatch(storeAction, {
          data,
        });
        dispatch('ui.snackBar.open', 'Survey Definition saved.');
        form.clear();
        dispatch('routing.push', '/retail/surveys');
        return false;
      } catch (err) {
        this.log.error('Saving Survey Definition Failed: ', err);
        form.invalidate(err.message);
        dispatch('ui.snackBar.open', err.message);
        return true;
      }
    },
  });
}

const fields = [
  'uuid',
  'title',
  'product',
  'product.uuid',
  'product.title',
  'company',
  'model',
  'trainingURL',
];

const rules = {
  company: 'required|string',
  'product.title': 'required|string',
  'product.uuid': 'required|string',
  title: 'required|string',
  trainingURL: 'string',
  uuid: 'string',
};

const labels = {
  product: 'Survey Product',
  title: 'Survey Title',
  trainingURL: 'Training Link',
};

export function init(values = {}, opts = { src: null }) {
  const initLog = log.getChildLogger('init');
  initLog.debug('Creating Survey Definition from base %s', opts.src);

  initLog.debug('Creating Survey Definition Form from Keys: ', _.keys(values));

  const fieldVals = _.clone(values);
  fieldVals.model =
    typeof values.model === 'string'
      ? values.model
      : JSON.stringify(values.model);
  const form = new SurveyDefinitionForm({
    fields,
    labels,
    rules,
    values: fieldVals,
  });

  initLog.debug('Created Survey Definition Form with Values: ', {
    srcQuestionCount: values.gradedQuestionsCount,
    valQuestionCt: form.gradedQuestionsCount,
    values: form.values(),
  });
  return form;
}
