import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';
// import _ from 'lodash';

export default class QuotaClose {
  log = getChildLogger('uiQuotaClose');

  @observable
  isOpen = false;

  @observable
  campaign = {};

  @observable
  key = '';

  @observable
  isLoading = false;

  @observable
  quota = {};

  @action
  setup({ key, quota, campaign, open = this.isOpen }) {
    this.key = key;
    this.quota = quota;
    this.campaign = campaign;

    this.isOpen = open;
  }

  async confirm() {
    const log = this.log.getChildLogger('confirm');
    const data = {
      quotaClosed: !this.quota.quotaClosed,
      quotaKey: this.key,
    };

    try {
      this.setLoading(true);
      await dispatch('campaigns.update', {
        data,
        id: this.campaign.uuid,
      });
    } catch (error) {
      log.error('Error Toggling Surveys By Quota: ', error);
    }
    this.setLoading(false);
    return this.clear();
  }

  @action
  setLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  clear() {
    this.key = '';
    this.quota = {};
    this.campaign = {};
    this.isLoading = false;

    this.isOpen = false;
  }
}
