import React from 'react';
import { observer } from 'mobx-react';
import MaskedInput from 'react-text-mask';

const mask = [
  '(',
  /[2-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const PhoneInput = observer((props) => (
  <MaskedInput mask={mask} placeholder="+1 (123) 456-7890" {...props} />
));

export default PhoneInput;
