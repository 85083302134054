import type { IAssignment } from '@shiftsmartinc/shiftsmart-types';
import type { PopupProps } from 'semantic-ui-react';
import type { StoreName } from '#/shared/stores';

import React, { useEffect, useState } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { getDynamicStatus } from '#/shared/stores/assignments';
import PopupIcon from '#/shared/components/ssm/PopupIcon';

import { getStatusMap } from './statusMap';
import { IndicatorProps, Indicator } from './Indicator';

declare type StatusIndicatorProps = {
  color?: string;
  iconOnly?: boolean;
  inline?: IndicatorProps['inline'];
  param?: string;
  size?: IndicatorProps['size'];
  status: string;
  storeName?: Parameters<typeof getStatusMap>[0]['storeName'];
};

export const StatusIndicator = observer<React.FC<StatusIndicatorProps>>(
  (props) => {
    const { status, size, inline, storeName, param, iconOnly } = props;
    const { text = _.startCase(status), color } = getStatusMap({
      param,
      status,
      storeName,
    });

    return (
      <Indicator
        color={props.color || color}
        inline={inline}
        size={size}
        text={!iconOnly && text}
      />
    );
  },
);

export const CompanyStatusIndicator = observer(
  ({ ...rest }: { status: string } & Record<string, unknown>) => (
    <StatusIndicator storeName="companies" {...rest} />
  ),
);

export const AssignmentStatusIndicator = observer(
  ({
    status,
    checkInAt,
    checkOutAt,
    tooltip,
    ...rest
  }: {
    checkInAt: IAssignment['checkIn']['timestamp'];
    checkOutAt: IAssignment['checkIn']['timestamp'];
    status: IAssignment['status'];
    tooltip?: PopupProps['content'];
  } & Record<string, unknown>) => {
    const [dynamicStatus, setStatus] = useState(status || '');
    useEffect(
      () =>
        autorun(() => {
          const s = getDynamicStatus({ checkInAt, checkOutAt, status });
          setStatus(s);
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [status],
    );

    return (
      <PopupIcon
        delay={{
          close: 0,
          open: 500,
        }}
        inverted={false}
        on="click"
        popup={true}
        position="bottom center"
        tooltip={tooltip}
        trigger={
          <StatusIndicator
            status={dynamicStatus}
            storeName="assignments"
            {...rest}
          />
        }
        wide=""
      />
    );
  },
);
