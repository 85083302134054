import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';

import BaseStore from './_baseStore';

export type IUserShiftScoreHistory = IBaseItem & Record<string, unknown>;

export default class UserShiftScoreHistoryStore extends BaseStore<IUserShiftScoreHistory> {
  constructor() {
    const baseItem = {
      assignmentId: null,
      excludeFromScoreAndHistoryLog: null,
      isDeleted: null,
      pointsEarned: null,
      roleId: null,
      shiftId: null,
      shiftStartTime: null,
      shiftTitle: null,
      userId: null,
      uuid: null,
      wasNotPenalizedCancellation: null,
      wasNotPenalizedCancellationButLostPoints: null,
      wasOnTime: null,
      wasPenalizedCancellation: null,
      wasShiftBonused: null,
      wasShiftNoShow: null,
      wasShiftWorked: null,
    };
    super({
      baseItem,
      serviceName: 'userShiftScoreHistory',
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }
}
