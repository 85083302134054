import React from 'react';
import cx from 'classnames';

interface INAProps {
  className?: string;
  content?: string;
}

const NA: React.FC<INAProps> = ({ className, content = 'N/A' }) => (
  <span className={cx('i silver', className)}>{content}</span>
);

NA.displayName = 'NotAvailable';
export default NA;
