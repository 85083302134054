import type {
  IWorker,
  ICert,
  ICompany,
  IUser,
  IUserCert,
  CompanyStatusRecord,
  Timestamp,
} from '@shiftsmartinc/shiftsmart-types';

import { action, computed, set, observable, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import moment from 'moment';
import { Paginated, Query } from '@feathersjs/feathers';

import { service } from '#/shared/app';
import { getStore } from '#/shared/getStores';
import { IS_DEVELOPMENT } from '#/config/settings';

import BaseStore, { IStoreFindOpts } from './_baseStore';

/**
 * The Worker Store serves as the base store for all "worker" based stores. Separate stores
 * are created to track different classes of workers, and to avoid confusion in the current
 * list available to the logged in user. The two primary stores are:
 *
 * 1. Sourcing: Lists and allows actions on workers who are NOT a member of your company
 * 2. Partners: Lists and allows actions on workers are ARE a part of your company.
 *
 * @export
 * @class WorkerStore
 * @extends {BaseStore}
 */
export default class WorkerStore<
  T extends IWorker = IWorker,
> extends BaseStore<T> {
  constructor({ serviceName = 'workers', title, opts, ...rest } = {}) {
    super({
      baseItem: WorkerStore.BASE_ITEM,
      debounceSearch: true,
      opts: {
        ...{
          clearSelectedOnUpdateMismatch: true,
          removeFromListOnUpdateMismatch: true,
        },
        ...opts,
      },
      searchFields: ['displayName', 'phoneNumber', 'email'],
      serviceName,
      title: _.compact(['workers', title]).join('.'),
      useAtlasSearch: true,
      ...rest,
    });

    return this;
  }

  query = { query: {} };

  lastQuery = null;

  @observable
  searchValue = '';

  // TODO: Default Filter Props can be removed once migrated to MobX 5 [PDF 2018-07-13]
  @observable
  filter = WorkerStore.baseFilterObject;

  static get baseFilterObject() {
    return {
      displayName: null,
      email: null,
      experience: null,
      hasAvailability: null,
      hasCerts: null,
      hasCompany: null,
      hasResume: null,
      hasScore: null,
      hasSkills: null,
      introVideo: null,
      isDeleted: null,
      isDisabled: null,
      isFlagged: null,
      phoneNumber: null,
      profilePic: null,
    };
  }

  @observable
  pools = [];

  @observable
  certs = [];

  @observable
  softCerts = [];

  static BASE_ITEM = {
    accountProps: {
      completionScore: null,
      initialComs: { email: null, sms: null },
      invitePending: null,
      onboardingRequired: false,
    },
    address: {
      city: null,
      state: null,
      street1: null,
      street2: null,
      zip: null,
    },
    addressString: null,
    adherence: 0,
    availability: [],
    certs: [],
    companies: [],
    companyStatus: [],
    deletedAt: null,
    disabledAt: null,
    displayName: null,
    email: null,
    experience: [],
    firstName: null,
    flaggedAt: null,
    isDeleted: false,
    isDisabled: false,
    isFlagged: false,
    lastName: null,
    loc: {},
    phoneNumber: null,
    pools: [],
    profileImageURL: null,

    rating: 0,
    resumeImageURL: null,
    roles: [],
    tags: [],
    username: null,
    uuid: null,
  };

  // @observable selected = _.clone(this.baseUser);

  @observable
  multiSelect = [];

  @observable
  reviewPartners = false;

  @observable
  availabilityToggle = false;

  @observable
  isLoading = false;

  @observable
  employmentStatusFilter: Array<CompanyStatusRecord['status']> = [
    // 'contractor',
    // 'temp',
    'employee',
    'active',
    // 'inactive',
    // 'former',
  ];

  @observable
  serviceName = 'workers';

  @computed
  get canExport() {
    // Only allow export if there is some filtering
    // And the number of results of the current search is less than 2000
    return (
      !_.isEmpty(_.omit(this.query.query, '$sort')) &&
      this.pagination.total <= 2000
    );
  }

  @observable
  buildingCSV = false;

  @observable
  csvData = '';

  init() {
    // run events on client side-only
    if (global.TYPE === 'CLIENT') this.initEvents();
    service(this.serviceName).timeout = 20000;
  }

  @action
  emptyList() {
    this.list.clear();
    this.$pagination = {};
    this.lastQuery = {};
  }

  @action
  toggleAvailabilityFilter(filter) {
    this.availabilityToggle = filter;
  }

  @action
  toggleReviewModeFilter(review) {
    this.log.debug('Setting Review Mode: ', review);
    if (this.reviewPartners === review) {
      return Promise.resolve(this.list);
    }

    this.reviewPartners = review;

    let query = { needsReview: undefined }; //not sure here

    if (this.reviewPartners) {
      query = { needsReview: true };
    }

    return this.find({ query });
  }

  @action
  toggleWorker({ worker, reload = false }) {
    this.log.debug('Toggling Worker', worker);

    if (this.selected.uuid === _.get(worker, 'uuid', worker)) {
      return this.clearSelected();
    }
    return this.setSelected(worker, { reload });
  }

  getSelected() {
    return this.selected;
  }

  @action
  async setSelected(
    worker,
    { reload = false, populateCertsCompany = false } = {},
  ) {
    await super.setSelected(worker, {
      clientParams: {
        populateCertsCompany: populateCertsCompany
          ? ['images', 'name']
          : populateCertsCompany,
      },
      reload,
    });
    this.loadWorkerProps(); // SE-919 ???

    return this.selected;
  }

  async loadWorkerProps() {
    if (_.isEmpty(this.selected.uuid)) {
      return null;
    }

    const { uuid } = this.selected;

    return Promise.all([
      dispatch('experiences.find', { user: uuid }, { clear: true }),
    ]);
  }

  @action
  async clearSelected() {
    if (this.selected.uuid) {
      dispatch('experiences.emptyList');
    }
    dispatch('ui.workerDetails.clear');
    this.selected = {};
    return this.selected;
  }

  // TODO: can this be in the baseStore?
  // Removes the specified key from the query
  @action
  removeQueryKey(key) {
    delete this.query.query[key];
    return Promise.resolve();
  }

  // TODO: Move to more appropriate Place
  // Multi-Select used on WorkerPoolModal
  @action
  toggleMultiSelect({ worker, workers = [worker] }) {
    _.each(workers, (w) => {
      if (_.includes(this.multiSelect, w)) {
        _.pull(this.multiSelect, w);
      } else {
        this.multiSelect.push(w);
      }
    });
  }

  @action
  clearMultiSelect() {
    this.multiSelect = [];
  }

  create({ data, ...rest }) {
    const newWorker = data;

    if (_.isString(data.companies)) {
      newWorker.companies = [data.companies];
    }

    newWorker.loc = data.loc || _.get(data, 'address.loc');

    return super.create({ data: newWorker, ...rest });
  }

  // TODO: Move to "user" or "company-user" store...
  async createAgent(data = null) {
    if (_.isEmpty(data)) {
      return Promise.reject('No Agent Data submitted');
    }

    const newAgent = data;

    newAgent.roles = ['user', 'company-agent'];

    const agent = await service('user').create(newAgent);

    this.log.debug('Created new Agent', agent);

    await dispatch('companies.addAgent', {
      agent,
      role: data.role,
    });

    return agent;
  }

  setAvatar({ userId, data, serviceName = this.serviceName }) {
    if (_.isEmpty(userId)) {
      return Promise.reject('Must specify UserID');
    }

    const authUser = dispatch('auth.getUser');

    if (!authUser || !(userId === authUser.uuid || authUser.isAdmin)) {
      return Promise.reject('Not Authorized');
    }

    return service(serviceName)
      .patch(userId, { profileImage: data })
      .then((res) => {
        this.log.debug('Uploaded Image! Response:', res);
        return res;
      })
      .catch((err) => {
        this.log.error('Failed Uploading Image', err);
      });
  }

  /** get
   * @param {string} userId
   * @param {object} opts
   * @description The workers store based `get` method differs from the baseStore implementation
   * in that it does not automatically select the queried partner.
   */
  async get(userId, opts) {
    try {
      return await super.get(userId, { select: false, ...opts });
    } catch (err) {
      return this.logAndThrow('Error Loading User', err, {
        method: 'get',
      });
    }
  }

  @action
  resetSearch({ preserve = [] } = {}) {
    super.resetSearch({ preserve });
    this.certs = [];
    this.softCerts = [];
    this.tags = [];
  }

  /** Find
   * Per EP-456, this method has been migrated to the baseStore's method signature.
   * It has been migrated in a way that will log warnings if the old signature is
   * used, but it should continue to work as expected. [PDF 2019-06-03]
   */
  @action
  find(query = {}, opts = {}) {
    const baseQuery = _.get(query, 'query', query);
    const deprecatedClear = _.get(query, 'clear');
    const deprecatedOpts = _.get(query, 'opts');
    const resetZipCodeSearch = _.get(opts, 'resetZipCodeLocation');
    const cleanCertsQuery = _.get(opts, 'cleanCertsQuery');
    if (cleanCertsQuery) {
      this.query = {
        query: _.omit(this.query.query, ['$and', '$or', 'certs.uuid']),
      };
    }
    if (resetZipCodeSearch) {
      // eslint-disable-next-line no-underscore-dangle
      delete this.query.query._geo;
    }
    delete opts.resetZipCodeLocation;

    _.defaults(opts, deprecatedOpts, { hooks: [], smart: true });

    if (!_.isUndefined(deprecatedClear)) {
      this.log.warn(
        'The "clear" param in the first argument is deprecated, please move to the second "opts" argument for consistency with other stores',
      );
    }

    if (!_.isUndefined(deprecatedOpts)) {
      this.log.warn(
        'The "opts" param in the first argument is deprecated, please move contents to the second "opts" argument for consistency with other stores',
      );
    }

    // eslint-disable-next-line no-param-reassign
    opts.hooks = [
      ...opts.hooks,
      () => {
        _.merge(this.query.query, { $client: { legacy: false } });
      },
    ];
    return super.find(baseQuery, opts);
  }

  /* ACTIONS */

  @action
  page(page = 1) {
    const skipPage = this.pagination.limit * (page - 1);
    const { pages } = this.pagination;
    if (skipPage < 0 || page > pages) return null;
    return this.find({ $skip: skipPage });
  }

  // #region Search & Filters

  @action
  clearSearch() {
    this.searchValue = '';
  }

  @action
  findByCompany({ company, query = {}, opts: inputOpts = {} }) {
    const { deselect, ...opts } = inputOpts;

    const companyUUID = _.get(company, 'uuid', company);

    if (!companyUUID) {
      this.log.warn('Clearing Company Filter');
    } else {
      this.log.debug(`Filtering Workers by Company: ${companyUUID}`);
    }

    const fullQuery = this.getCompanyStatusQuery(query, {
      companyId: companyUUID,
      employmentStatusFilter: this.employmentStatusFilter,
    });

    return this.find(fullQuery, opts);
  }

  getCompanyStatusQuery(
    query,
    opts?: {
      cleanCompanyQueryParam?: boolean;
      companyId?: ICompany['uuid'];
      employmentStatusFilter?: Array<CompanyStatusRecord['status']>;
    },
  ) {
    const companyId = opts.companyId || query?.companies;

    const fullQuery = _.extend(
      {
        $skip: 0,
        // companies: companyUUID,
        companyStatus: companyId
          ? {
              $elemMatch: {
                company: companyId,
                status: {
                  $in: opts?.employmentStatusFilter ?? ['active', 'employee'],
                },
              },
            }
          : undefined,
      },
      query,
    );

    if (opts.cleanCompanyQueryParam && companyId) {
      delete fullQuery.companies;
    }

    return fullQuery;
  }

  async runQuery(query: Query): Promise<Paginated<T>> {
    const q = this.getCompanyStatusQuery(query?.query ?? query, {
      cleanCompanyQueryParam: false,
    });

    return super.runQuery(q);
  }

  @action
  async findByLocation({
    company = dispatch('auth.getCompany'),
    query = {},
    radius,
    coordinates,
    lat = _.get(coordinates, '[1]'),
    lng = _.get(coordinates, '[0]'),
    box,
    maxRadius = 500,
    minRadius = 5,
    opts,
  }: {
    box: number[][];
    company?: ICompany;
    coordinates: [number, number];
    lat?: number;
    lng?: number;
    maxRadius?: number;
    minRadius?: number;
    opts?: any;
    query?: Query;
    radius: number;
  }) {
    const {
      radius: mapRadius,
      lat: mapLat,
      lng: mapLng,
    } = dispatch('ui.mapInstance.getMapViewportInfo');

    if ((radius || mapRadius) > maxRadius) {
      throw new Error(
        '[findByLocation] Please zoom in to search nearby partners',
      );
    }

    const center = [lng || mapLng, lat || mapLat];

    if (!_(center).compact().size() || !(radius || mapRadius)) {
      throw new Error(
        '[findByLocation] Center and Radius must be specified to search',
      );
    }

    const geo = {
      $geoNear: {
        center,
        miles: Math.max(radius || mapRadius, minRadius),
      },
    };

    this.log.debug(
      '[findByLocation] Querying workers within map bounds%s: ',
      !_.isEmpty(box) ? ' (ignoring `box` parameter)' : '',
      JSON.stringify(geo, null, 2),
    );

    const workers = await this.findByCompany({
      company,
      opts: {
        // TODO: Unclear what purpose this served, as the `loadLocations` switch triggered
        // a call to the legacy workerLocations store.
        loadLocations: true,
        ...opts,
      },
      query: {
        ...query,
        _geo: geo,
      },
    });

    this.log.debug(
      '[findByLocation] Found %d workers within map bounds',
      _.size(workers),
    );

    return workers;
  }

  @action
  setEmploymentStatusFilter(value, opts = {}) {
    if (
      _.isArray(value)
        ? value.length !== this.employmentStatusFilter.length ||
          !_.every(value, (val) => _.includes(this.employmentStatusFilter, val))
        : !_.includes(this.employmentStatusFilter, value)
    ) {
      this.emptyList();
    }

    this.employmentStatusFilter.replace(
      _.compact(_.isArray(value) ? value : [value]),
    );

    if (_.isEmpty(this.employmentStatusFilter)) {
      this.employmentStatusFilter = ['active', 'employee'];
    }

    const query = {
      $skip: 0,
      companyStatus: {
        $elemMatch: {
          company:
            _.get(this.query.query, 'companyStatus.$elemMatch.company') ||
            _.get(this.query.query, 'companies') ||
            undefined,
          status: { $in: this.employmentStatusFilter },
        },
      },
    };

    return this.find({ query }, opts);
  }

  @action
  setPoolFilter({ poolIds }, opts = { noQuery: false, save: true }) {
    if (_.isEmpty(poolIds)) {
      this.pools = [];
    } else if (_.isArray(poolIds)) {
      this.pools = poolIds;
    } else {
      this.pools = [poolIds];
    }

    const query = this.pools.length
      ? { 'pools.uuid': { $in: this.pools.toJS() } }
      : { 'pools.uuid': undefined };

    return this.find(query, opts);
  }

  @action
  setFilter({ key, value }, opts = { noQuery: false, save: true }) {
    const query = {};

    this.log.debug(`Setting up filter "${key}" for "${value}"`);

    if (_.isUndefined(value)) {
      query[key] = undefined;
    } else if (_.isObject(value)) {
      query[key] = {};

      if (value.min) {
        _.extend(query[key], { $gte: value.min });
      }

      if (key === 'adherence' && value.max && value.max !== 100) {
        _.extend(query[key], { $lte: value.max });
      }

      if (key === 'rating' && value.max && value.max !== 5) {
        _.extend(query[key], { $lte: value.max });
      }
    } else if (_.isNumber(value)) {
      query[key] = { $gte: value };
    }

    if (_.isEmpty(query[key])) {
      query[key] = undefined;
    }

    this.log.debug(`Filtering ${key} based on query ${query}`);

    return this.find(query, opts);
  }

  // #region Admin Filters
  @action
  setAdminFilter({ propName, val }) {
    this.log.debug(
      `Setting up Admin filter "${propName}" for "${val}" Currently: "${this.filter[propName]}"`,
    );

    if (_.isUndefined(val)) {
      this.filter[propName] = _.isNull(this.filter[propName]) ? true : null;
    } else {
      this.filter[propName] = val;
    }

    if (!_.isNull(this.filter[propName])) {
      const query = {};

      /* eslint-disable no-case-declarations */
      switch (propName) {
        /** Null/Empty checks on Array Fields */
        case 'hasCompany':
          delete this.query.query.companies;
          query.companies = this.filter[propName]
            ? {
                $gt: [],
              }
            : {
                $lte: [],
              };
          break;
        case 'hasAvailability':
          delete this.query.query.availability;
          query.availability = this.filter[propName]
            ? {
                $gt: [],
              }
            : {
                $lte: [],
              };
          break;

        /** Null/Empty Checks on scalar props */
        case 'profilePic':
          delete this.query.query.profileImageURL;
          query.profileImageURL = this.filter[propName]
            ? {
                $ne: null,
              }
            : {
                $eq: null,
              };
          break;
        case 'hasResume':
          delete this.query.query.resumeImageURL;
          query.resumeImageURL = this.filter[propName]
            ? {
                $ne: null,
              }
            : {
                $eq: null,
              };
          break;
        case 'introVideo':
          delete this.query.query.profileVideoURL;
          query.profileVideoURL = this.filter[propName]
            ? {
                $nin: [null, ''],
              }
            : {
                $in: [null, ''],
              };
          break;

        case 'hasScore':
          delete this.query.query['accountProps.completionScore'];
          _.extend(query, {
            'accountProps.completionScore': this.filter[propName]
              ? {
                  $exists: true,
                }
              : {
                  $exists: false,
                },
          });
          break;

        /** Certs, tags, etc */
        case 'experience':
          delete this.query.query['certs.certType'];
          const experienceQuery = this.setCertQuery(
            'employer',
            this.filter[propName],
          );

          _.extend(query, experienceQuery);
          this.log.debug('Has Experience filter: ', query);

          break;
        case 'hasSkills':
          const skillsQuery = this.setCertQuery('skill', this.filter[propName]);

          _.extend(query, skillsQuery);
          this.log.debug('Has Skills filter: ', query);

          break;
        case 'hasCerts':
          const certQuery = this.setCertQuery('cert', this.filter[propName]);

          _.extend(query, certQuery);
          this.log.debug('Has Certs filter: ', query);

          break;

        /** Booleans */
        case 'isDeleted':
        case 'isDisabled':
        case 'isFlagged':
        default:
          delete this.query.query[propName];
          query[propName] = this.filter[propName] ? true : { $ne: true };
          break;
      }

      if (!_.isEmpty(query)) {
        return this.find(query, { isAdmin: true });
      }
    } else {
      let queryProp;

      switch (propName) {
        case 'profilePic':
          queryProp = 'profileImageURL';
          break;
        case 'hasResume':
          queryProp = 'resumeImageURL';
          break;
        case 'introVideo':
          queryProp = 'profileVideoURL';
          break;
        case 'experience':
          const experienceQuery = this.setCertQuery(
            'employer',
            this.filter[propName],
          );
          if (_.isEmpty(experienceQuery)) {
            delete this.query.query['certs.certType'];
            return this.find({});
          }
          return this.find(experienceQuery);
        case 'isDeleted':
        case 'isDisabled':
        case 'isFlagged':
          queryProp = propName;
          break;

        case 'hasCompany':
          queryProp = 'companies';
          break;
        case 'hasSkills':
        case 'hasCerts':
          const certType = /skills/i.test(propName) ? 'skill' : 'cert';
          const certQuery = this.setCertQuery(certType, this.filter[propName]);
          if (_.isEmpty(certQuery)) {
            delete this.query.query['certs.certType'];
            return this.find({});
          }
          return this.find(certQuery);
        case 'hasAvailability':
          queryProp = 'availability';
          break;
        case 'hasScore':
          queryProp = 'accountProps.completionScore';
          break;
        default:
          queryProp = propName;
          break;
      }

      if (queryProp && _.has(this.query.query, queryProp)) {
        delete this.query.query[queryProp];
        return this.find({}, { isAdmin: true });
      }
    }
    /* eslint-enable no-case-declarations */

    return Promise.resolve([]);
  }

  @action
  clearAdminFilter(opts) {
    const query = _(this.filter)
      .omitBy((v) => !_.isObject(v) && !_.isBoolean(v))
      .mapKeys(
        action((v, k) => {
          this.filter[k] = null;
          switch (k) {
            case 'profilePic':
              return 'profileImageURL';
            case 'introVideo':
              return 'profileVideoURL';
            case 'hasCompany':
              return 'companies';
            case 'hasSkills':
            case 'hasCerts':
              return 'certs.certType';
            case 'hasScore':
              return 'accountProps.completionScore';
            case 'hasAvailability':
              return 'availability';
            default:
              return k;
          }
        }),
      )
      .mapValues(() => undefined)
      .value();

    return this.find({ query }, { ...opts, isAdmin: true });
  }

  // #endregion Admin Filters

  // #endregion Search & Filters

  // #region "Cert" Operations

  @action.bound
  async addCert({
    worker,
    endorser,
    company,
    title,
    cert,
  }: {
    cert?: ICert | IUserCert;
    company: ICompany | ICompany['uuid'];
    endorser?: IUser['uuid'] | IUser;
    title?: string;
    worker: IWorker;
  }) {
    const workerId = _.get(worker, 'uuid', worker);
    if (_.isEmpty(workerId)) {
      throw new Error('Worker ID Not Defined');
    }

    if (_.isEmpty(cert)) {
      throw new Error('Must specify a cert');
    }

    const companyId = _.get(company, 'uuid', company);
    const endorserId = _.get(endorser, 'uuid', endorser);
    const certUUID = _.get(cert, 'uuid', cert);

    if (!certUUID || !_.find(worker.certs, { uuid: certUUID })) {
      const newCert = {
        certType: cert.certType,
        companies: _.compact([companyId]),
        title: title || cert.title,
        users: _.compact([endorserId]),
        uuid: certUUID,
      };

      if (newCert.certType === 'cert') {
        // Verify cert for now if employer adds it
        newCert.isVerified = true;
      }

      if (cert.certType === 'attribute') {
        newCert.question = cert.question;
        newCert.users = [];
        newCert.companies = [];
      }

      this.log.debug('adding cert: ', newCert);
      try {
        // TODO: Ensure same cert is not added multiple times
        const res = await service(this.serviceName).patch(workerId, {
          $push: { certs: newCert },
        });
        // after a new cert is added/removed, the list of certs does not have the company object anymore
        // this.setSelected(workerId, {
        //   populateCertsCompany: true,
        // });
        this.log.debug('Added New Cert: ', res);
        return res;
      } catch (error) {
        this.log.error('Unable to patch ' + newCert.certType + ' to worker');
        throw error;
      }
    }

    if (endorser) {
      return this.endorseCert({ cert, company, endorser, worker });
    }

    throw new Error('unsure what to do here');
  }

  @action
  endorseCert({ worker, endorser, company, cert = {} }) {
    const workerId = _.get(worker, 'uuid', worker);
    if (_.isEmpty(workerId)) {
      return Promise.reject('Worker ID Not Defined');
    }

    const certUUID = _.get(cert, 'uuid', cert);

    if (_.isEmpty(certUUID)) {
      return Promise.reject('Must Specify Cert To Remove');
    }

    const query = {
      certs: {
        $elemMatch: { uuid: certUUID },
      },
    };
    const endorserId = _.get(endorser, 'uuid', endorser);
    const companyId = _.get(company, 'uuid', company);

    const data = { $push: {} };

    if (companyId) {
      _.extend(data.$push, { 'certs.$.companies': companyId });
    }
    if (endorserId) {
      _.extend(data.$push, { 'certs.$.users': endorserId });
    }

    if (_.isEmpty(data.$push)) {
      return Promise.reject('Must Specify Endorser or Company to pull');
    }

    return this.update({ data, id: workerId, query }).then((res) => {
      this.log.debug('endorseCert Result: ', {
        cert: _.find(res.certs, { uuid: certUUID }),
      });
      // after a new cert is added/removed, the list of certs does not have the company object anymore
      this.setSelected(workerId, {
        populateCertsCompany: true,
      });
      return res;
    });
  }

  @action
  removeCertEndorsement({ worker, endorser, company, cert = {} }) {
    const workerId = _.get(worker, 'uuid', worker);
    if (_.isEmpty(workerId)) {
      return Promise.reject('Worker ID Not Defined');
    }

    const certUUID = _.get(cert, 'uuid', cert);

    if (_.isEmpty(certUUID)) {
      return Promise.reject('Must Specify Cert To Remove');
    }

    const query = {
      certs: {
        $elemMatch: { uuid: certUUID },
      },
    };
    const endorserId = _.get(endorser, 'uuid', endorser);
    const companyId = _.get(company, 'uuid', company);

    const data = { $pull: {} };

    if (companyId) {
      _.extend(data.$pull, { 'certs.$.companies': companyId });
    }
    if (endorserId) {
      _.extend(data.$pull, { 'certs.$.users': endorserId });
    }

    if (_.isEmpty(data.$pull)) {
      return Promise.reject('Must Specify Endorser or Company to pull');
    }

    return this.update({ data, id: workerId, query }).then((res) => {
      this.log.debug('removeCertEndorsement Result: ', {
        cert: _.find(res.certs, { uuid: certUUID }),
      });
      // after a new cert is added/removed, the list of certs does not have the company object anymore
      this.setSelected(workerId, {
        populateCertsCompany: true,
      });
      return res;
    });
  }

  @action
  patchPositionPrimaryStatus({
    worker,
    user,
    key: position,
    value: isPrimary,
  }) {
    const partnerUUID = _.get(worker, 'uuid', worker);
    const certUUID = _.get(position, 'uuid', position);
    if (_.isEmpty(partnerUUID)) {
      return Promise.reject('Partner UUID Not Defined');
    }
    const query = {
      certs: {
        $elemMatch: { uuid: certUUID },
      },
    };

    const data = {
      'certs.$.props.isPrimary': isPrimary,
    };
    return this.adminUpdateCert({ data, query, user, worker }).then((res) => {
      this.log.debug('userPositionUpdate Result: ', {
        cert: _.find(res.certs, { uuid: certUUID }),
      });
      return res;
    });
  }

  @action.bound
  async removeCert({
    worker,
    cert,
    user,
  }: {
    cert: ICert | ICert['uuid'];
    user?: IUser | IUser['uuid'];
    worker: IWorker | IWorker['uuid'];
  }) {
    const workerId = _.get(worker, 'uuid', worker);
    const certUUID = _.get(cert, 'uuid', cert);

    if (_.isEmpty(certUUID)) {
      return Promise.reject('Must Specify Cert To Remove');
    }

    const data = {
      $pull: { certs: { uuid: certUUID } },
    };

    let res;

    if (/qual/.test(cert.certType)) {
      res = await this.update({
        data,
        id: workerId,
        params: {
          query: {
            $client: {
              populateCertsCompany: ['images', 'name'],
            },
          },
        },
      });
    } else {
      res = await this.adminUpdateCert({ data, user, worker });
    }

    this.log.debug('Removed Cert: ', res);
    return res;
  }

  @action
  adminVerifyCert({ worker, cert, user }) {
    const certUUID = _.get(cert, 'uuid', cert);

    if (_.isEmpty(certUUID)) {
      return Promise.reject('Must Specify Cert To Verify');
    }

    const query = {
      certs: {
        $elemMatch: { uuid: certUUID },
      },
    };

    const data = {
      $push: {
        'certs.$.verification': {
          userName: user.email,
          userUUID: user.uuid,
          verifiedAt: moment().toDate(),
        },
      },
      'certs.$.isVerified': true,
      'certs.$.props.isVerified': true,
    };

    return this.adminUpdateCert({ data, query, user, worker }).then((res) => {
      this.log.debug('adminVerifyCert] Result: ', {
        cert: _.find(res.certs, { uuid: certUUID }),
      });
      return res;
    });
  }

  @action
  adminPromoteCert({ worker, cert, user, val = true }) {
    const certUUID = _.get(cert, 'uuid', cert);

    if (_.isEmpty(certUUID)) {
      return Promise.reject('Must Specify Role To Promote');
    }

    if (cert.certType !== 'role') {
      this.log.warn('Promoting is only intended for "Roles"');
    }

    const query = {
      certs: {
        $elemMatch: { uuid: certUUID },
      },
    };

    const data = {
      'certs.$.props.isPromoted': val,
    };

    return this.adminUpdateCert({ data, query, user, worker }).then((res) => {
      this.log.debug('AdminPromoteCert] Result: ', {
        cert: _.find(res.certs, { uuid: certUUID }),
      });
      return res;
    });
  }

  @action
  adminUpdateCert({ worker, user = dispatch('auth.getUser'), data, query }) {
    const workerId = _.get(worker, 'uuid', worker);
    if (_.isEmpty(workerId)) {
      return Promise.reject('Worker ID Not Defined');
    }

    if (!user.isAdmin) {
      return Promise.reject('Acting User must be Admin');
    }

    return this.update({ data, id: workerId, query });
  }

  setCertQuery(certType, value) {
    const certsCertType = this.query.query['certs.certType'] || {};

    const $all = _.get(certsCertType, '$all', []);
    const $nin = _.get(certsCertType, '$nin', []);

    if (_.isNull(value) || _.isUndefined(value)) {
      if (_.includes($nin, certType)) {
        _.pull($nin, certType);
      }
      if (_.includes($all, certType)) {
        _.pull($all, certType);
      }
    } else if (value) {
      if (!_.includes($all, certType)) {
        $all.push(certType);
      }
      if (_.includes($nin, certType)) {
        _.pull($nin, certType);
      }
    } else {
      if (!_.includes($nin, certType)) {
        $nin.push(certType);
      }

      if (_.includes($all, certType)) {
        _.pull($all, certType);
      }
    }

    const query = {};

    if (!_.isEmpty($all)) {
      query['certs.certType'] = _.extend(query['certs.certType'], { $all });
    }
    if (!_.isEmpty($nin)) {
      query['certs.certType'] = _.extend(query['certs.certType'], { $nin });
    }
    return query;
  }

  @action
  setCertFilter({
    ids = [],
    isSoftFilter = false,
    op = 'union',
    opts = { clear: false, noQuery: false, save: true },
    toggle,
    reset,
  }: {
    ids: Array<ICert['uuid']>;
    isSoftFilter?: boolean;
    op: 'union' | 'remove' | 'set';
    opts?: IStoreFindOpts<IWorker>;
    reset?: boolean;
    toggle?: boolean;
  }): ReturnType<WorkerStore['find']> {
    this.log.debug('setCertFilter', ids);

    const certIds = _.map(
      _.isString(ids) || _.has(ids, 'uuid') ? [ids] : ids.slice(),
      (i) => _.get(i, 'uuid', i),
    );
    const union = /union/i.test(op);
    const remove = /remove/i.test(op) || !!opts.clear || !!opts.remove;
    const isSetOperation = /set/i.test(op);

    if (remove) {
      this.log.debug(
        `Removing certs: ${certIds.join(',')} from tags ${this.tags.length}`,
        this.tags,
      );
      this.tags = _.pullAllBy(
        this.tags,
        _.map(certIds, (c) => ({ uuid: c })),
        'uuid',
      );
      this.log.debug(
        `removed certs: ${certIds.join(',')} from tags ${this.tags.length}`,
        this.tags,
      );
    }

    // Union is default and adds the passed certs to the existing filter;
    if (union) {
      _.each(certIds, (uuid) => {
        let tag = _.find(this.tags, { uuid });

        if (tag) {
          if (isSoftFilter) {
            tag.isRequired = false;
          } else {
            tag.isRequired = true;
          }
        } else {
          tag = { uuid };
          if (isSoftFilter) {
            tag.isRequired = false;
          } else {
            tag.isRequired = true;
          }

          this.tags.push(tag);
        }

        _.each(
          ['isRequired', 'isActive', 'hasImage', 'isVerified', 'isPromoted'],
          (prop) => {
            tag[prop] = !!(_.has(opts, prop) ? opts[prop] : tag[prop] || false);
          },
        );
      });

      // TODO: Figure out toggle;
      if (isSoftFilter) {
        this.softCerts = _.union(this.softCerts, certIds);
        if (toggle) {
          _.pullAll(this.certs, certIds);
        }
      } else {
        this.certs = _.union(this.certs, certIds);
      }
    }

    // Set is used to override the existing filter or reset it entirely;
    if (isSetOperation) {
      this.tags = _.map(certIds, (uuid) => ({ required: !isSoftFilter, uuid }));
    }

    if (reset) {
      this.tags.clear();
    }

    return this.buildTagQuery({ opts });
  }

  @observable
  tags = [];

  buildTagQuery({
    opts,
  }: {
    opts: IStoreFindOpts<IWorker>;
  }): ReturnType<WorkerStore['find']> {
    // tag: { uuid, name, required, image, active, reviewed }
    const coreTagsQuery = {};
    const tagWithDetailsQuery = {};

    const reqWithParams = _.filter(
      this.tags,
      (t) => t.hasImage || t.isActive || t.isVerified || t.isPromoted,
    );

    if (reqWithParams.length) {
      const ands = [];
      const ors = [];

      _.each(reqWithParams, (t) => {
        const obj = { uuid: t.uuid };

        if (t.hasImage) {
          obj['props.hasImage'] = true;
        }
        if (t.isActive) {
          obj['props.isActive'] = true;
        }
        if (t.isVerified) {
          obj['props.isVerified'] = true;
        }
        if (t.isPromoted) {
          obj['props.isPromoted'] = true;
        }

        if (t.isRequired) {
          ands.push({ certs: { $elemMatch: obj } });
        } else {
          ors.push({ certs: { $elemMatch: obj } });
        }
      });

      if (ands.length) {
        tagWithDetailsQuery.$and = ands;
      }
      if (ors.length) {
        tagWithDetailsQuery.$or = ors;
      }
    }

    const coreTags = _.reject(
      this.tags,
      (t) => !!_.find(reqWithParams, { uuid: t.uuid }),
    );

    if (coreTags.length) {
      const $all = [];
      const $in = [];

      _.each(coreTags, (t) => {
        if (t.isRequired) {
          $all.push(t.uuid);
        }

        $in.push(t.uuid);
      });

      if ($all.length) {
        _.extend(coreTagsQuery, { $all });
      }
      if ($in.length) {
        _.extend(coreTagsQuery, { $in });
      }
    }

    const query = {};

    if (!_.isEmpty(tagWithDetailsQuery)) {
      _.extend(query, tagWithDetailsQuery);
    } else {
      _.extend(query, { $and: undefined, $or: undefined });
    }

    if (!_.isEmpty(coreTags)) {
      _.extend(query, { 'certs.uuid': coreTagsQuery });
    } else {
      _.extend(query, { 'certs.uuid': undefined });
    }

    return this.find(query, {
      ...opts,
      cleanCertsQuery: true,
    });
  }

  /**
   * New Cert Filters:
   *
   * 1. Cert is Required
   * 1a. ... with required "image"
   * 1b. ... with required "non-expired"
   * 1c. ... with required "verified"
   * 2. Cert is Optional
   *
   */

  // #endregion

  @action.bound
  resetCSV() {
    this.csvData = '';
    this.buildingCSV = false;
  }

  @action.bound
  async exportData() {
    if (!this.canExport) {
      throw new Error(
        'Cannot Export Data containing more than 2000 results. Please refine search or contact support',
      );
    }

    const query = {
      ..._.cloneDeep(this.query.query),
      exportData: true,
    };
    query.$client = { ...(query.$client ?? {}), exportData: true };

    this.buildingCSV = true;

    try {
      const res = await service(this.serviceName).find({ query });
      const data = res.data[0];

      if (!_.has(data, 'csvData')) {
        throw new Error('CSV Data not included in response');
      }

      runInAction(() => {
        this.csvData = data.csvData;
        this.buildingCSV = false;
        dispatch('audit.create', {
          data: {
            action: 'Worker Export',
            extra: {
              query,
            },
          },
        });
        return res;
      });
    } catch (err) {
      this.log.error('Error build csv export for worker query: ', err, {
        extra: {
          query: JSON.stringify(query),
        },
      });
      runInAction(() => {
        this.buildingCSV = false;
      });
      throw err;
    }
  }

  // #region Experience & Employer Methods
  createExperience({ data }) {
    return service('experience')
      .create(data)
      .catch((err) => {
        this.log.error('Failed to Create Worker Experience ', err);
        throw err;
      });
  }

  updateExperience({ data }) {
    if (_.isEmpty(data.uuid)) {
      return Promise.reject('Experience UUID required for update');
    }
    return service('experience')
      .patch(data.uuid, data)
      .catch((err) => {
        this.log.error('Failed to Update Worker Experience ', err);
        throw err;
      });
  }

  removeExperience(experienceUUID) {
    return service('experience')
      .patch(experienceUUID, { deleted: true })
      .then((res) => {
        this.onExperienceRemoved(res);
        return res;
      })
      .catch((err) => {
        this.log.error('Error Removing Experience: ', err);
        throw err;
      });
  }

  /**
   * onExperienceCreated
   *
   * Event fired when a new experience record is added
   */
  @action
  onExperienceCreated = (data) => {
    if (_.isEmpty(data)) {
      return;
    }
    if (this.selected.uuid === data.user) {
      this.selected.experience.push(data);
    }
  };

  /**
   * onExperiencePatched
   *
   * Event fired when a new experience record is updated
   */
  @action
  onExperiencePatched = (data) => {
    if (_.isEmpty(data)) {
      return;
    }
    if (this.selected.uuid === data.user) {
      const existing = _.find(this.selected.experience, { uuid: data.uuid });
      if (existing) {
        set(existing, data);
      }
    }
  };

  /**
   * onExperienceRemoved
   *
   * Event fired when a new experience record is removed
   */
  @action
  onExperienceRemoved = (data) => {
    if (_.isEmpty(data)) {
      return;
    }
    if (this.selected.uuid === data.user) {
      this.selected.experience = _.reject(this.selected.experience, {
        uuid: data.uuid,
      });
    }
  };

  // #endregion

  // #region Employer & Company Manipulation

  findOrCreateEmployer({ company }) {
    return service('employers')
      .find({ query: { name: company.name } })
      .then((res) => {
        if (_.first(res.data)) {
          return _.first(res.data);
        }

        return service('employers').create(company);
      })
      .catch((err) => {
        this.log.error('Failed to Find or Create Employer ', err);
        throw err;
      });
  }

  /** `addCompany` and `removeCompany
   *
   * Used by the WorkerMeembershipModal to add and remove companies from a worker.
   */
  @action
  addCompany({ worker, company = {} }) {
    const workerId = _.get(worker, 'uuid', worker);
    if (_.isEmpty(workerId)) {
      return Promise.reject('Worker ID Not Defined');
    }

    return service(this.serviceName)
      .patch(workerId, { $push: { companies: company.uuid } })
      .catch((err) => this.log.error(err));
  }

  @action
  removeCompany({ worker, company = {}, reason }) {
    if (!_.includes(worker.companies, company.uuid)) {
      return false;
    }
    const workerId = _.get(worker, 'uuid', worker);
    if (_.isEmpty(workerId)) {
      return Promise.reject('Worker ID Not Defined');
    }
    const hookData = {
      companyId: company.uuid,
      reason,
      status: 'former',
      workerId,
    };
    const params = {
      query: {
        $client: {
          updateCompanyStatusData: hookData,
        },
      },
    };
    return service(this.serviceName)
      .patch(workerId, { $pull: { companies: company.uuid } }, params)
      .catch((err) => this.log.error(err));
  }

  @action
  updateCompanyStatus({
    worker,
    company,
    status,
    reactivateOn,
    reason,
  }: {
    company: ICompany | ICompany['uuid'];
    reactivateOn?: Timestamp;
    reason: string;
    status: CompanyStatusRecord['status'];
    worker: IWorker | IWorker['uuid'];
  }) {
    if (_.isEmpty(status)) {
      return Promise.reject('Must specify new status');
    }
    const workerId = _.get(worker, 'uuid', worker);
    if (_.isEmpty(workerId)) {
      return Promise.reject('Worker ID Not Defined');
    }

    const companyId = _.get(company, 'uuid', company);
    if (_.isEmpty(companyId)) {
      return Promise.reject('company ID Not Defined');
    }

    const patchData = { companyId, reason, status, workerId };
    if (reactivateOn && status === 'inactive') {
      patchData['reactivateOn'] = reactivateOn;
    } else if (/employee|active/i.test(status)) {
      patchData['reactivateOn'] = null;
    }

    const params = {
      query: {
        $client: {
          updateCompanyStatusData: patchData,
        },
      },
    };
    return service(this.serviceName)
      .patch(workerId, {}, params)
      .catch((err) => this.logAndThrow(err));
  }

  async loadCompanyStatusRecord({
    workerId,
    worker,
    companyId,
  }: (
    | { worker?: IWorker; workerId: IWorker['uuid'] }
    | {
        worker: IWorker & Required<Pick<IWorker, 'companyStatus'>>;
        workerId?: IWorker['uuid'];
      }
  ) & { companyId: ICompany['uuid'] }) {
    const user = worker?.companyStatus
      ? worker
      : await this.get(workerId, { query: {}, select: false });
    return _.find(user?.companyStatus, { company: companyId });
  }

  @observable
  filterLocation = null;

  @action
  setFilterLocation(location) {
    this.filterLocation = location;
  }
  @action
  clearFilterLocation() {
    this.filterLocation = null;
  }

  @action.bound
  async bulkApplyPosition(qualId, positionId, opts) {
    if (!IS_DEVELOPMENT) {
      this.log.error('method not available');
      return null;
    }

    if (!qualId && !opts?.userIds) {
      throw new Error('must specify qualId or userIds');
    }

    const companyId = opts?.companyId ?? '6c8724ca-4c6a-46d9-b5fe-e3dc32b4ccf6';
    const query = this.getCompanyStatusQuery(
      {
        $and: [
          qualId ? { 'certs.uuid': qualId } : { uuid: { $in: opts.userIds } },
          { 'certs.uuid': { $nin: [positionId] } },
        ],
        $select: ['uuid', 'certs', 'displayName'],
      },
      { companyId },
    );

    const list = await this.find(query);

    this.log.debug('loaded partners missing the position', {
      ...this.pagination,
      list: list,
    });

    const qual =
      !!qualId &&
      (await getStore('quals').get(qualId, {
        query: {},
        select: false,
      }));

    const position = await getStore('positions').get(positionId, {
      query: {},
      select: false,
    });

    if (!position) {
      throw new Error('could not find position');
    }

    this.log.debug('Applying position to tag', { position, qual });

    const updates = await _(list)
      .slice(0, opts?.limit ?? 10)
      .reduce(async (prev: Promise<string[]>, worker) => {
        const ra = await prev;

        try {
          if (!!qualId && !_.find(worker.certs, { uuid: qualId })) {
            throw new Error('worker is missing qual');
          }
          if (_.find(worker.certs, { uuid: positionId })) {
            throw new Error('worker already has position');
          }

          if (opts?.live) {
            const res = await this.addCert({
              cert: position,
              company: companyId,
              worker: worker,
            });

            this.log.debug('patched worker', { res });

            ra.push(res.uuid);
          } else {
            ra.push('np:' + worker.uuid);
          }
        } catch (err) {
          this.log.error('error patching worker', { err });
        }

        return ra;
      }, Promise.resolve([]));

    this.log.debug('completed updates', { updates });
  }
}
