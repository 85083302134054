import { ICarouselCard } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from '#/shared/stores/_baseStore';

export default class CarouselCardsStore extends BaseStore<ICarouselCard> {
  constructor() {
    super({
      serviceName: 'carouselCards',
    });

    return this;
  }
}
