import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import UserImage from '#/shared/components/ssm/users/UserImage';

const styles = {
  avatar: {
    borderColor: '#FFFFFFFF',
    borderStyle: 'solid',
    borderWidth: '0',
    height: null,
    objectFit: 'cover',
    padding: 0,
    width: null,
  },
  avatarBottom: {
    marginTop: '0.5px',
  },
  avatarContainer: {
    borderColor: '#2a6cc4',
    borderRadius: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
    padding: 0,
  },
  avatarLeft: {
    marginRight: '0.5px',
  },
  avatarRight: {
    marginLeft: '0.5px',
  },
  avatarTop: {
    marginBottom: '0.5px',
  },
};

class GroupAvatar extends React.PureComponent {
  static propTypes = {
    overrideStyles: PropTypes.object,
    userIDs: PropTypes.array,
    users: PropTypes.array,
  };

  static defaultProps = {
    overrideStyles: {},
    userIDs: [],
    users: [],
  };

  render() {
    const { userIDs, users, overrideStyles } = this.props;

    const useIDs = _.isEmpty(users);
    const userArray = useIDs ? userIDs : users;

    return (
      <div
        className="w-100 h-100 br-100 flex flex-wrap overflow-hidden"
        style={styles.avatarContainer}
      >
        <UserImage
          style={{
            ...overrideStyles,
            ...styles.avatar,
            ...styles.avatarLeft,
            ...styles.avatarTop,
            height:
              userArray.length === 1
                ? 'calc(100% - 0.5px)'
                : 'calc(50% - 0.5px)',
            width:
              // eslint-disable-next-line no-nested-ternary
              userArray.length <= 2
                ? userArray.length === 1
                  ? '100%'
                  : 'calc(100% - 0.5px)'
                : 'calc(50% - 0.5px)',
          }}
          user={!useIDs && users[0]}
          userId={useIDs ? userIDs[0] : ''}
        />
        {_.get(userArray, '[1]') && (
          <UserImage
            style={{
              ...overrideStyles,
              ...styles.avatar,
              ...styles.avatarRight,
              ...styles.avatarTop,
              height: 'calc(50% - 0.5px)',
              width:
                userArray.length <= 2
                  ? 'calc(100% - 0.5px)'
                  : 'calc(50% - 0.5px)',
            }}
            user={!useIDs && users[1]}
            userId={useIDs ? userIDs[1] : ''}
          />
        )}
        {_.get(userArray, '[2]') && (
          <UserImage
            style={{
              ...overrideStyles,
              ...styles.avatar,
              ...styles.avatarLeft,
              ...styles.avatarBottom,
              height: 'calc(50% - 0.5px)',
              width: userArray.length <= 3 ? '100%' : 'calc(50% - 0.5px)',
            }}
            user={!useIDs && users[2]}
            userId={useIDs ? userIDs[2] : ''}
          />
        )}
        {_.get(userArray, '[3]') && (
          <UserImage
            className="h-50 w-50"
            style={{
              ...overrideStyles,
              ...styles.avatar,
              ...styles.avatarRight,
              ...styles.avatarBottom,
              height: 'calc(50% - 0.5px)',
              width: 'calc(50% - 0.5px)',
            }}
            user={!useIDs && users[3]}
            userId={useIDs ? userIDs[3] : ''}
          />
        )}
      </div>
    );
  }
}

GroupAvatar.displayName = 'GroupAvatar';
export default GroupAvatar;
