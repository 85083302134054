import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { dispatch } from 'rfx-core';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import _ from 'lodash';
import ReactMapboxGl, {
  Layer,
  Feature,
  Source,
  Marker,
  Cluster,
} from 'react-mapbox-gl';
import { Icon, Label, Popup } from 'semantic-ui-react';
import { isObservableArray } from 'mobx';
import turfCircle from '@turf/circle';
import { convertLength } from '@turf/helpers';

import Address from '#/shared/components/utils/Address';
import Avatar from '#/shared/components/utils/Avatar';
import { MapLoader } from '#/shared/components/utils/MapWrapper';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('LegacyMapComponent');

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoicGF2YW5zc20iLCJhIjoiY2o4M3U5MXpsMDF4ZjMycWIyYWEza3lmZiJ9.dv4gWZhzcScEafdWHBvuzQ';

const MapBox = ReactMapboxGl({
  accessToken: process.env.RADAR_PUBLIC_KEY || MAPBOX_ACCESS_TOKEN,
  attributionControl: false,
});

/** LegacyMapComponent @deprecated [PDF 2019-11-01]
 * This component is preserved in its current state while each use of our Map component can be assesed for correct functionailty with the refactored MapComponent.
 */

@observer
export default class LegacyMapComponent extends Component {
  static propTypes = {
    center: MobxPropTypes.arrayOrObservableArray,
    changeOn: PropTypes.object,
    company: PropTypes.object,
    eventHandlers: PropTypes.object,
    home: PropTypes.object,
    layers: PropTypes.any,
    mapStyle: PropTypes.string,

    markers: PropTypes.oneOfType([
      PropTypes.func,
      MobxPropTypes.objectOrObservableObject,
    ]),

    /* The following props are not used in the app [PDF 04-04-2018] */
    // worker: PropTypes.object,
    onClickThing: PropTypes.func,

    onClickWorker: PropTypes.func,

    selected: PropTypes.oneOfType([
      MobxPropTypes.arrayOrObservableArray,
      MobxPropTypes.objectOrObservableObject,
    ]),

    showPlaceholder: PropTypes.bool,

    things: MobxPropTypes.arrayOrObservableArray,
    workers: MobxPropTypes.arrayOrObservableArray,
    zoom: PropTypes.number,
  };

  static defaultProps = {
    changeOn: {
      selected: true,
      things: true,
      workers: true,
    },
    eventHandlers: {},
    mapStyle: 'outdoors',
  };

  getRadiusCircles = ({ items }) => {
    log.debug('getRadiusCircles Start');
    const radii = _(items)
      .filter(
        (item) =>
          item &&
          !!item.radius &&
          !_.isEmpty(
            dispatch('ui.mapInstance.getCoordinatesForItem', { item })
              .coordinates,
          ),
      )
      .value();

    if (_.isEmpty(radii)) {
      return false;
    }

    return (
      <Layer
        key="location-group-layer"
        paint={{
          'fill-color': '#2A6CC4',
          'fill-opacity': 0.65,
        }}
        type="fill"
      >
        {_.map(radii, (item) => {
          const center = dispatch('ui.mapInstance.getCoordinatesForItem', {
            item,
          }).coordinates;
          const circleRadius = convertLength(item.radius, 'feet', 'miles');
          const circleGeo = turfCircle(
            center.slice(),
            circleRadius,
            64,
            'miles',
          );

          return (
            <Feature coordinates={_.get(circleGeo, 'geometry.coordinates')} />
          );
        })}
      </Layer>
    );
  };

  getMarker = (object, opts = {}) => {
    if (_.isEmpty(object)) {
      return false;
    }
    log.debug('getMarker Start');
    const { onClickWorker, onClickThing } = this.props;
    let popupContent;
    let color = 'purple';
    let clickCb = _.noop;

    const coordData = dispatch('ui.mapInstance.getCoordinatesForItem', {
      item: object,
    });

    let { dataType, coordinates } = coordData;
    const { addr } = coordData;

    if (dataType === 'company') {
      popupContent = object.name;
    } else if (dataType === 'worker') {
      popupContent = object.displayName;
      color = 'blue';
      clickCb = onClickWorker || clickCb;
    } else if (dataType === 'customer') {
      popupContent = object.title;
      color = 'red';
      clickCb = onClickThing || clickCb;
    } else if (dataType === 'store') {
      popupContent = object.storeId;
      color = 'red';
      clickCb = onClickThing || clickCb;
    } else if (dataType === 'address') {
      popupContent =
        addr.formattedAddress || object.formattedAddress || object.title;
      clickCb = onClickThing || clickCb;
    } else if (dataType === 'unknown') {
      // Thing: 'shift', etc
      popupContent = (
        <span>
          {object.title}
          <br />
          {(addr && <Address data={addr} />) || _.get(object, 'loc.zip')}
        </span>
      );
      clickCb = onClickThing || clickCb;
    }

    if (_.isEmpty(coordinates)) {
      dataType = 'address';
      coordinates =
        _.get(object, 'loc.coordinates') ||
        _.get(object, 'address.loc.coordinates');
      popupContent = object.title || object.name || object.storeId;
      color = 'red';
      clickCb = onClickThing || clickCb;
    }

    if (_.isEmpty(coordinates)) {
      // log.silly('No Coordinates found for ', { object });
      return false;
    }

    if (!_.isEmpty(opts.filter)) {
      if (_.isArray(opts.filter) && _.eq(opts.filter, coordinates)) {
        log.silly('Marker is being hidden for filter coords: ', {
          filter: opts.filter,
          object,
        });
        return false;
      }

      if (_.eq(_.get(opts.filter, 'loc.coordinates'), coordinates)) {
        log.silly('Marker is being hidden for filter coords: ', {
          filter: opts.filter,
          object,
        });
        return false;
      }
    }

    if (_.includes(_.map(this.props.selected, 'uuid'), object.uuid)) {
      color = 'blue';
    }

    let trigger = <Label circular={true} color={color} empty={true} />;

    if (dataType === 'worker') {
      trigger = (
        <button className="bn bg-transparent">
          <div
            className="tc relative"
            style={{ height: '3rem', width: '2.5rem' }}
          >
            <div className="absolute top-0 left-0 right-0">
              <img alt="" src="/static/img/mapIndicator.svg" />
            </div>
            <div
              className="absolute top-0 left-0 right-0"
              style={{ paddingTop: '3px' }}
            >
              <Avatar
                color={color === 'blue' ? 'var(--blue)' : color}
                isOnMap={true}
                size="h2 w2"
                user={object}
              />
            </div>
          </div>
        </button>
      );
    }

    const { __t: refType, rate, title } = object;

    if (/shift|survey/.test(refType) && rate) {
      const label = rate || title;
      trigger = (
        <button className="bn bg-transparent">
          <div
            className="tc relative"
            style={{ height: '3rem', width: '2.5rem' }}
          >
            <Label color="red" pointing="below">
              ${label}
            </Label>
          </div>
        </button>
      );
    }

    if (!_.isEmpty(opts.icon)) {
      trigger = <Icon {...opts.icon} />;
    }

    if (_.isFunction(opts.markers)) {
      trigger = opts.markers({ thing: object, trigger });
    }

    const isPopupTrigger = _.get(trigger, 'type.name') === 'Popup';

    log.debug('Rendering Marker', {
      color,
      content: popupContent,
      coordinates,
      dataType,
    });

    log.silly(`Rendering marker at [${coordinates.slice()}]`);

    return (
      <Marker
        anchor="center"
        coordinates={coordinates.slice()}
        key={object.uuid || coordinates.join('-')}
        offset={[0, 2]}
        onClick={() => clickCb(object.uuid)}
      >
        {isPopupTrigger
          ? trigger
          : !!trigger && (
              <Popup content={popupContent} on="hover" trigger={trigger} />
            )}
      </Marker>
    );
  };

  clusterMarker = (dataType) => (coordinates, pointCount) => {
    let color;

    switch (dataType) {
      case 'worker':
        color = 'blue';
        break;
      case 'thing':
      case 'shift':
        color = 'red';
        break;
      default:
        color = 'purple';
        break;
    }

    return (
      <Marker anchor="center" coordinates={coordinates}>
        <div className="h2 w2 relative flex items-center justify-center">
          <div className="absolute">
            <div className="h2 w2 br-100 shadow-1 bg-white" />
          </div>

          <Label
            circular={true}
            className="pointer"
            color={color}
            onClick={() => {
              log.error('Legacy Sourcing Functionality has been Removed');
            }}
            style={{
              height: '0.4rem',
              marginLeft: '0px',
              width: '0.4rem',
              zIndex: '100',
            }}
          >
            {pointCount}
          </Label>
        </div>
      </Marker>
    );
  };

  render() {
    if (!process.env.RADAR_PUBLIC_KEY) log.warn('No Radar Public Key');
    const {
      selected,
      center,
      zoom,
      workers,
      things,
      layers,
      home,
      company = home,
      markers,
      showPlaceholder,
      mapStyle,
      fixed,
      bounds: srcBounds,
      fitBoundsOptions,
    } = this.props;

    if (showPlaceholder || global.TYPE !== 'CLIENT') {
      log.info('Returning loading map placeholder');
      return <MapLoader />;
    }

    if (!_.isUndefined(home)) {
      log.warn('The `home` prop has been @deprecated in favor of `company`');
    }

    const selectedItems = _.isArrayLikeObject(selected) ? selected : [selected];

    log.debug('Rendering Items', { selectedItems });

    let defaultCenter;
    let defaultZoom;
    let bounds = srcBounds;

    if (!fixed) {
      ({ defaultCenter, defaultZoom, bounds } = dispatch(
        'ui.mapInstance.calcCenterAndZoom',
        {
          center,
          company,
          selected: selectedItems,
          things,
          workers,
          zoom,
        },
      ));

      log.silly('Rendering at default center/zoom', {
        bounds,
        defaultCenter,
        defaultZoom,
      });
    }

    const fitBounds = !_.isEmpty(bounds) ? bounds : undefined;

    const lineLayout = {
      'line-cap': 'round',
      'line-join': 'round',
    };

    const linePaint = {
      'line-color': '#2A6CC4',
      'line-width': 2,
    };

    let mappedRoute;
    let route;

    // if (_.has(worker, 'location.loc.coordinates') &&
    //    !_.isEmpty(worker.location.loc.coordinates)) {
    //   mappedRoute = [defaultCenter.slice(),
    //     _.get(worker, 'location.loc.coordinates', []).slice()];
    //   const start = defaultCenter.slice();
    //   const end = _.get(worker, 'location.loc.coordinates', defaultCenter).slice();

    //   const cir = greatCircle(start, end, { name: 'Path to Task' });

    //   route = {
    //     type: 'geojson',
    //     data: cir,
    //   };
    // }

    const { eventHandlers } = this.props;

    let z = zoom;
    let c = center;

    if (!fixed && _.size(fitBounds) && !z) {
      z = defaultZoom;
    }
    if (!fixed && !c) {
      c = defaultCenter;
    }

    log.silly('Rendering with z&c', {
      defaults: {
        bounds,
        defaultCenter,
        defaultZoom,
      },
      lat: c && (c.lat || c[1]),
      lng: c && (c.lng || c[0]),
      zoom: z,
    });

    if (_.isEmpty(c)) {
      c = defaultCenter ||
        _.get(selected, '[0].loc.coordinates') ||
        _.get(company, 'loc.coordinates') || [
          -97.63761745855135, 38.487129976744995,
        ];

      log.silly('Changed Rendering center', {
        lat: c && (c.lat || c[1]),
        lng: c && (c.lng || c[0]),
      });
    }

    if (isObservableArray(c)) {
      c = c.slice();
    }
    if (_.isObject(c) && _.has(c, 'lat')) {
      c = [c.lng, c.lat];
    }

    log.debug('Rendering with center/zoom', {
      bounds: fitBounds,
      center: c,
      zoom: z,
    });

    try {
      return (
        <MapBox
          center={c}
          containerStyle={{
            height: '100%',
            width: '100%',
          }}
          fitBounds={fitBounds}
          fitBoundsOptions={fitBoundsOptions}
          style={
            process.env.RADAR_ACCESS_TOKEN
              ? `https://api.radar.io/maps/styles/radar-default-v1?publishableKey=${process.env.RADAR_PUBLIC_KEY}`
              : `mapbox://styles/mapbox/${mapStyle}-v9`
          }
          zoom={z ? [z] : undefined}
          {...eventHandlers}
        >
          {this.getRadiusCircles({ items: selectedItems })}

          <Cluster ClusterMarkerFactory={this.clusterMarker('worker')}>
            {(workers || []).map((o) => this.getMarker(o, { markers }))}
          </Cluster>
          <Cluster ClusterMarkerFactory={this.clusterMarker('thing')}>
            {(things || []).map((o) => this.getMarker(o, { markers }))}
          </Cluster>
          {!!selectedItems &&
            _.map(selectedItems, (o) =>
              this.getMarker(o, { filter: company, markers }),
            )}
          {mappedRoute && (
            <div>
              <Source geoJsonSource={route} id="source_id" />
              <Layer
                id="layer_id"
                layout={lineLayout}
                paint={linePaint}
                sourceId="source_id"
                type="line"
              />
            </div>
          )}
          {company &&
            this.getMarker(company, {
              icon: { color: 'blue', name: 'building' },
            })}
          {layers}
        </MapBox>
      );
    } catch (err) {
      log.error('Failed to render map', err);

      return (
        <div className="h-100 w-100 bg-light-blue flex items-center justify-center">
          <Icon
            color="grey"
            name="remove"
            size="huge"
            style={{ opacity: '0.5' }}
          />
        </div>
      );
    }
  }
}
