import _ from 'lodash';

import BaseStore from './_baseStore';

export default class PaymentCustomers extends BaseStore {
  constructor() {
    const baseItem = {
      objectType: null,
      stripeToken: null,
      user: null,
    };
    super({ baseItem, serviceName: 'payments/customers' });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  findByCompany() {
    return Promise.reject(new Error('Not Implemented for Payment Customers'));
  }
}
