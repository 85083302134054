import { Tab as SemanticTab, Menu as SemanticMenu } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { breakpoints } from '#/ssm-ui';

const menuTabStyles = css`
  &,
  & > .ui.menu,
  & > .ui.menu > .item {
    font-family: Avenir, Avenir-Medium, Lato !important;
  }

  .ui.secondary.pointing.menu {
    border-bottom: 1px solid #e9ecef;
    ${(props) =>
      props.backgroundColor &&
      css`
        background-color: ${props.backgroundColor};
        box-shadow: none;
      `}
  }
  .ui.secondary.pointing.menu .item {
    ${(props) =>
      props.small &&
      css`
        font-size: 13px !important;
        padding: 0;
      `}
    padding: 34px 30px 27px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #a0a3a7;
  }
  .ui.secondary.pointing.menu .item:hover {
    color: #0071d5;
    background-color: #f6f9fc;
  }
  .ui.secondary.pointing.menu .item.active {
    color: #0071d5;
    border-bottom: 3px solid #0071d5;
  }

  @media ${breakpoints.xxs} {
    .ui.secondary.pointing.menu .item {
      padding: 10px 14px 7px;
      font-weight: 800;
      font-size: 16px;
      line-height: 18px;
      color: #a0a3a7;
    }

    .ui.secondary.pointing.menu,
    .ui.secondary.pointing.menu .item {
      background-color: #f6f9fc;
    }
  }
`;

export const Tab = styled(SemanticTab)`
  ${menuTabStyles}
`;

export const StyledMenu = styled(SemanticMenu)`
  ${menuTabStyles}
`;
