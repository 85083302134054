import { action, observable } from 'mobx';

export default class CampaignConstructor {
  @observable
  quotasDirty = false;

  /** @deprecated Create explicit setter action for each property instead */
  @action
  set(key, value) {
    this[key] = value;
    return Promise.resolve(this[key]);
  }

  get(key) {
    return this[key];
  }

  @action
  clear() {
    this.quotasDirty = false;
  }
}
