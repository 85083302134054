import type { IAddress, ISite } from '@shiftsmartinc/shiftsmart-types';

import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';

import { getZoneIdsFromTiers } from '#/shared/components/utils/LocationFilter/utils';
import { ILocationFilter } from '#/shared/components/utils/LocationFilter/ILocationFilter';

export default class ThingReassignCLocationFilter implements ILocationFilter {
  @observable
  siteIds: Array<IAddress['uuid']> = [];

  @observable
  selectedZoneIds: Array<IAddress['uuid']> = [];

  @observable
  isRemote = false;

  @action.bound
  setSiteIds(siteIds: Array<IAddress['uuid']> = []) {
    this.isRemote = false;
    this.siteIds = siteIds;
  }

  @action.bound
  setIsRemote(isRemote: boolean) {
    this.isRemote = isRemote;
    if (isRemote) {
      this.siteIds = null;
    }
  }

  @action.bound
  setSelectedZoneIds({
    selectedZone,
    tierIndex,
  }: {
    selectedZone?: ISite;
    tierIndex?: number;
  }) {
    this.selectedZoneIds = getZoneIdsFromTiers({
      company: dispatch('auth.getCompany'),
      selectedZone,
      selectedZoneIds: this.selectedZoneIds,
      tierIndex,
    });
  }
}
