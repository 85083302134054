import type {
  IAssignment,
  IPaymentTransaction,
} from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { action, observable, observe, set } from 'mobx';
import { dispatch } from 'rfx-core';

import { service } from '#/shared/app';
import { resetPaymentApprovals } from '#/utils/payments/resetPaymentApprovals';

import BaseStore from './_baseStore';

export default class PaymentTransactions extends BaseStore<IPaymentTransaction> {
  constructor() {
    super({
      baseItem: {
        amount: null,
        currency: null,
        destination: null,
        externalId: null,

        log: [],

        refObjs: [],

        // rawTransactionData: {},
        refs: [],
        status: null,
        user: null,
      },
      serviceName: 'payments/transactions',
    });

    set(this.selected, _.clone(this.baseItem));
    set(this.pendingTransaction, _.clone(this.baseItem));

    this.disposers.push(
      observe(this.pendingTransaction, 'status', ({ newValue }) => {
        if (!/pending|draft/i.test(newValue)) {
          this.pendingTransaction = _.clone(this.baseItem);
        }
      }),
    );

    return this;
  }

  @observable
  isSubmitting = false;

  get baseFilter() {
    return { category: 'all', status: 'pending', transactionType: 'all' };
  }

  @observable
  filter = this.baseFilter;

  init() {
    // run events on client side-only
    if (global.TYPE === 'CLIENT') this.initEvents();
  }

  initEvents() {
    super.initEvents();
    service(this.serviceName).on('updated', action(this.onPendingUpdated));
    service(this.serviceName).on('patched', action(this.onPendingUpdated));
  }

  disposers = [];

  @observable
  pendingTransaction = {};

  @action.bound
  updateList(json) {
    super.updateList(json);
    const pending =
      _.find(this.list, { status: 'pending' }) ||
      _.find(this.list, { status: 'draft' });

    if (pending) {
      this.setPendingTransaction(pending);
    } else if (_.get(this.pendingTransaction, 'uuid', false)) {
      if (_.find(this.list, { uuid: this.pendingTransaction.uuid })) {
        this.setPendingTransaction();
      }
    }
  }

  @action.bound
  filterBy(filter, { param = 'status', noQuery = false } = {}) {
    this.filter[param] = filter;

    const query = {};

    if (param === 'status') {
      switch (filter) {
        case 'pending':
          query.status = { $in: ['pending', 'draft'] };
          break;
        case 'submitted':
          query.status = 'submitted';
          break;
        case 'complete':
          query.status = 'complete';
          break;
        case 'error':
          query.status = 'error';
          break;
        case 'all':
          query.status = undefined;
          break;
        default:
          query.status = filter;
          break;
      }
    } else if (/^(category|transactionType)$/.test(param)) {
      switch (filter) {
        case null:
        case '':
          query[param] = { $in: [null, ''] };
          break;
        case 'all':
          query[param] = undefined;
          break;
        default:
          query[param] = filter;
      }
    }

    if (noQuery) {
      return Promise.resolve();
    }

    return this.find({ query });
  }

  get filterOptions() {
    return {
      category: [
        {
          hidden: false,
          key: 'all',
          text: 'All Categories',
          title: 'Category',
          value: 'all',
        },

        { key: 'none', text: 'None', value: null },
        {
          hidden: false,
          key: 'adjustment',
          text: 'Adjustment',
          value: 'adjustment',
        },
        {
          hidden: false,
          key: 'referralBonus',
          text: 'Referral Bonus',
          value: 'referralBonus',
        },
        {
          hidden: false,
          key: 'completionBonus',
          text: 'Completion Bonus',
          value: 'completionBonus',
        },
        {
          hidden: false,
          key: 'firstShiftCompletionBonus',
          text: 'First Shift Complete Bonus',
          value: 'firstShiftCompletionBonus',
        },
        {
          hidden: false,
          key: 'travelReimbursement',
          text: 'Travel Reimbursement',
          value: 'travelReimbursement',
        },
        { hidden: false, key: 'payment', text: 'Payment', value: 'payment' },
        { hidden: false, key: 'other', text: 'Other', value: 'other' },
        {
          hidden: false,
          key: 'bonus',
          text: 'Bonus',
          value: 'bonus',
        },
      ],
      status: [
        {
          hidden: false,
          key: 'all',
          text: 'All Statuses',
          title: 'Status',
          value: 'all',
        },
        { hidden: false, key: 'pending', text: 'Pending', value: 'pending' },
        {
          hidden: false,
          key: 'submitted',
          text: 'Submitted',
          value: 'submitted',
        },
        { hidden: false, key: 'complete', text: 'Complete', value: 'complete' },
        { hidden: false, key: 'error', text: 'Error', value: 'error' },
      ],
      transactionType: [
        {
          hidden: false,
          key: 'all',
          text: 'All Types',
          title: 'Type',
          value: 'all',
        },

        { hidden: false, key: 'payout', text: 'Payout', value: 'payout' },
        { hidden: false, key: 'transfer', text: 'Transfer', value: 'transfer' },
        { hidden: false, key: 'fee', text: 'Fee', value: 'fee' },
      ],
    };
  }

  @action.bound
  setPendingTransaction(transaction = _.clone(this.baseItem)) {
    this.pendingTransaction = transaction; // _.defaults(transaction, this.baseItem);

    if (_.isEmpty(this.pendingTransaction.refs)) {
      return Promise.resolve(this.pendingTransaction);
    }

    return this.loadIncludedThings(this.pendingTransaction)
      .then(() => {
        this.log.debug(
          'Transaction ref objs: ',
          this.pendingTransaction.refObjs,
        );
      })
      .then(() => this.pendingTransaction);
  }

  onPendingUpdated = (data) => {
    if (_.isEmpty(data)) {
      this.log.debug(
        'Empty Item in onPendingUpdated for payments/transactions',
      );
      return;
    }
    if (data.uuid === this.pendingTransaction.uuid) {
      set(this.pendingTransaction, data);

      this.loadIncludedThings(this.pendingTransaction);
    }
  };

  loadIncludedThings = (transaction) => {
    if (_.isEmpty(transaction.refs)) {
      return Promise.resolve();
    }

    if (_.isEmpty(transaction.refs)) {
      this.setRefData(transaction, []);
      return [];
    }

    return dispatch('assignments.runQuery', {
      $limit: transaction.refs.length,
      uuid: { $in: transaction.refs.slice() },
    })
      .then((res) => {
        this.setRefData(transaction, res.data);
      })
      .catch((err) => this.log.error('Error loading included things', err));
  };

  @action.bound
  setRefData = (transaction, refObjs) => {
    // set(transaction, { refObjs: observable(refObjs) });
    if (!_.isArrayLike(transaction.refObjs)) {
      transaction.refObjs = observable([]);
    }
    _.each(refObjs, (ref) => {
      transaction.refObjs.push(ref);
    });

    transaction.refs.replace(refObjs);
  };
  /* eslint-enable no-param-reassign */

  findByCompany() {
    return Promise.reject(
      new Error('Not Implemented for Payment Transactions'),
    );
  }

  approveForPayment({ assignment, approvalNote }) {
    const assignmentData = {
      'paymentInfo.approval': 'approved',
      'paymentInfo.approvalNote': approvalNote,
    };

    return dispatch('assignments.update', {
      data: assignmentData,
      id: _.get(assignment, 'uuid', assignment),
    });
  }

  rejectForPayment({ assignment, approvalNote }) {
    const assignmentData = {
      'paymentInfo.approval': 'rejected',
      'paymentInfo.approvalNote': approvalNote,
      'qaInfo.approvalNote': approvalNote,
      'qaInfo.status': 'rejected',
    };
    return dispatch('assignments.update', {
      data: assignmentData,
      id: _.get(assignment, 'uuid', assignment),
    });
  }

  @action.bound
  toggleSubmitting(submitting) {
    this.isSubmitting = submitting;
  }

  processPayment({ transaction }) {
    if (this.isSubmitting) {
      this.log.debug('Already Submitting Transaction');
      return Promise.reject('Transaction already submitted');
    }
    this.toggleSubmitting(true);
    const uuid = _.get(transaction, 'uuid', transaction);
    const data = {
      status: 'pending',
      uuid,
    };

    return this.update({ data }).then((res) => {
      this.toggleSubmitting(false);
      return res;
    });
  }

  /**
   * ### Reset Approvals
   * Given a list of assignment IDs, resets the payment approvals field, and removes any
   * Transaction Records (if necessary). This method will _only_ reset payments that have
   * errored and that had a zero payout amount.
   *
   * **NOTE** This is not presently surfaced in the UI and is preserved for the resolution
   * of issues like BUG-1306 where payments were accidentally approved, but no transfers
   * were actually completed.
   */
  resetApprovals(
    assignmentIds: Array<IAssignment['uuid']>,
    opts?: {
      clearTransaction?: boolean;
      limit?: number;
      live?: boolean;
    },
  ) {
    return resetPaymentApprovals(assignmentIds, opts);
  }
}
