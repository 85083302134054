import _ from 'lodash';
import moment from 'moment';

export {
  mapValuesToFields,
  mapOptionsToFields,
  mapOptionsToRules,
  getFlatFieldDefinition,
};
function mapValuesToFields(fields, values, isTemplate = false, opts = {}) {
  const fieldDef = _.cloneDeep(fields);

  _(fieldDef)
    .keys()
    .each((key) => {
      const val = values[key];

      if (isTemplate && /uuid/i.test(key)) {
        fieldDef[key].value = null;
      }

      if (
        /date/.test(fieldDef[key].rules) &&
        /required/.test(fieldDef[key].rules)
      ) {
        const date = moment(val);
        if (opts.roundMinutes) {
          date.minute(
            _.round(date.minute() / (opts.roundMinutes * 1.0)) *
              opts.roundMinutes,
          );
        }
        fieldDef[key].value = date.toDate();
      }
      if (/string/.test(fieldDef[key].rules) && _.isObject(val)) {
        fieldDef[key].value = _.get(val, 'uuid', null);
      } else if (val) {
        fieldDef[key].value = val;
      } else if (!val && !fieldDef[key].value) {
        fieldDef[key].value = null;
      }
    });

  return fieldDef;
}
function mapOptionsToRules(rules, options) {
  if (_.isEmpty(rules)) throw new Error('Please specify form rules');
  if (_.isEmpty(options)) {
    return;
  }

  _(options)
    .keys()
    .each((k) => {
      const opts = options[k];
      if (!_.has(opts, 'required')) {
        return;
      }

      if (!rules[k]) return;

      const ruleRA = rules[k].split('|');
      if (opts.required && !_.includes(ruleRA, 'required')) {
        _.set(rules, k, ruleRA.push('required').join('|'));
      } else if (!opts.required && _.includes(ruleRA, 'required')) {
        _.set(rules, k, _.pull(ruleRA, 'required').join('|'));
      }
    });
}
function mapOptionsToFields(form, options) {
  if (!_.get(form, '$')) throw new Error('Please specify a valid form');
  if (_.isEmpty(options)) {
    return;
  }

  _(options)
    .keys()
    .each((k) => {
      const field = form.has(k) && form.$(k);

      if (!field) return;

      const opts = options[k];

      if (_.has(opts, 'label')) {
        field.set('label', opts.label);
      }
    });
}
/* eslint-disable no-param-reassign */
function getSeparatedProperties(fields, key) {
  return _.reduce(
    fields,
    (val, f) => {
      val[f.name] = f[key];
      if (!_.isEmpty(f.fields)) {
        _.each(f.fields, (subField) => {
          val[`${f.name}[].${subField.name}`] = subField[key];
        });
      }

      return val;
    },
    {},
  );
}
/* eslint-enable no-param-reassign */
function getFlatFieldDefinition(fields) {
  const flatFields = _.flatMap(fields, (f, k) => {
    const fieldArray = [_.get(f, 'name', k)];
    if (!_.isEmpty(f.fields)) {
      _.each(f.fields, (subField) => {
        fieldArray.push(`${f.name}[].${subField.name}`);
      });
    }
    return fieldArray;
  });

  return {
    defaults: getSeparatedProperties(fields, 'default'),
    fields: flatFields,
    labels: getSeparatedProperties(fields, 'label'),
    placeholders: getSeparatedProperties(fields, 'placeholder'),
    rules: getSeparatedProperties(fields, 'rules'),
  };
}

export function mapUnifiedToSeparate(fields) {
  return _.reduce(
    fields,
    (separate, fieldConfig, fieldKey) => {
      separate.fields.push(fieldKey);

      _.each(fieldConfig, (val, propKey) => {
        const separateConfigKey = !/^(extra|related|validateWith|)$/.test(
          propKey,
        )
          ? propKey.replace(/([^s])$/, '$1s')
          : propKey;
        if (!separate[separateConfigKey]) {
          separate[separateConfigKey] = {};
        }

        separate[separateConfigKey][fieldKey] = val;
      });

      return separate;
    },
    {
      fields: [],
    },
  );
}
