import React from 'react';
import cx from 'classnames';
import { Image } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import { SimpleUserInitials } from '#/shared/components/ssm/users/SimpleUserInitials';

/**
 * # Simple User Image
 * Given the props of a user, renders a user Image "Avatar" based on the props.
 * If no image is available, the user's initials will be used.
 *
 * For rendering a user Image based solely on a user ID, see UserImage
 *
 */

export const SimpleUserImage: React.FC<{
  circular?: boolean;
  className?: string;
  displayName: string;
  profileImageURL: string;
  style?: Record<string, unknown>;
}> = observer(
  ({
    className,
    circular = false,
    style = {},
    profileImageURL,
    displayName,
  }) => {
    if (!profileImageURL) {
      return (
        <SimpleUserInitials
          displayName={displayName}
          {...{
            circular,
            className,
            fontClass: 'f5',
            style,
          }}
        />
      );
    }

    return (
      <Image
        className={cx(className, circular && 'br-100 h-100 w-100')}
        src={profileImageURL}
        style={style}
      />
    );
  },
);
