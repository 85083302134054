import { observable, action } from 'mobx';
import _ from 'lodash';

export default class LoadingModal {
  @observable
  message = null;

  @observable
  isOpen = false;

  @observable
  total = 0;

  @observable
  complete = 0;

  @observable current = null;

  /**
   * ## open
   * Opens or updates the Loading Modal overlay
   * */
  @action.bound
  open({
    message,
    total = this.total,
  }: {
    /** The message to display */
    message?: string;
    /** The total number of records to display */
    total?: number;
  } = {}) {
    this.isOpen = true;
    this.message = message || this.message;
    this.total = total || 1;
    this.complete = 0;
  }

  @action.bound
  increment({
    inc = true,
    complete,
    total,
  }: { complete?: number; inc?: boolean; total?: number } = {}) {
    if (inc) {
      this.complete += _.isNumber(inc) ? inc : 1;
    } else if (complete) {
      this.complete = complete;
    }

    if (!_.isUndefined(total)) {
      this.total = total;
    }
  }

  @action.bound
  close({ delay = 0, message }: { delay?: number; message?: string } = {}) {
    setTimeout(
      action(() => {
        this.isOpen = false;
        this.message = null;
        this.total = 0;
        this.complete = 0;
      }),
      delay,
    );

    this.current = null;
    this.message = message || null;
  }

  @action.bound
  setCurrent(current) {
    this.current = current;
  }
}
