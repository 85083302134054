import { observable, action, set, runInAction, computed } from 'mobx';
import { dispatch } from 'rfx-core';
import Promise from 'bluebird';
import _ from 'lodash';

import BaseUploader, {
  KnownFieldConfig,
} from '#/shared/stores/ui/BaseUploader';

export default class ShiftRatingUpload extends BaseUploader {
  constructor() {
    super({ title: 'ui.ShiftRatingUpload' });

    set(this.saveResults, {
      ignored: [],
      invalid: [],
      updated: [],
    });

    return this;
  }

  @observable parseOptions = {
    dynamicTyping: false,
    header: false,
    skipEmptyLines: true,
  };

  /** list
   * Stores an array of rows, Ready for saving.
   */
  @observable
  list = [];

  /**
   * Parsed Headers
   * An array of all headers found in the parsed objects
   *
   * Used to render the column headers in a dynamic table.
   *
   * @memberof ShiftRatingUpload
   */
  @observable
  parsedHeaders = [];

  @observable
  resultHeaders = [];

  // TODO: Consider put the `progress` getter to the baseUploader store
  @computed
  get progress() {
    const total = _.size(this.list);
    const saved = _(this.saveResults).values().map(_.size).sum();
    return 100 * (saved / total);
  }

  @computed
  get knownFields(): KnownFieldConfig[] {
    return [
      {
        key: 'rating',
        transform: _.toNumber,
      },
      { key: 'assignmentUUID' },

      { key: 'shiftUUID' },

      { key: 'shiftTitle' },

      { key: 'companyUUID' },

      { key: 'partnerUUID' },

      { key: 'partnerPhoneNumber' },

      { key: 'partnerName' },

      { key: 'assignmentStatus' },
    ];
  }

  @observable
  includeHowTo = false;

  @computed
  get csvData() {
    return this.$sampleCSVData;
  }

  @computed get knownHeaders() {
    return _.map(this.knownFields, 'key');
  }

  @action
  async setup(opts, ...rest) {
    super.setup(opts, ...rest);

    this.clear();

    this.company = opts.company;

    if (_.isString(this.company)) {
      const company = await dispatch('companies.get', this.company, {
        select: false,
      });
      runInAction(() => {
        this.company = company;
      });
    }
  }

  @action
  clear() {
    super.clear();

    this.saveResults = {
      ignored: [],
      invalid: [],
      updated: [],
    };

    this.list.clear();
    this.parsedHeaders.clear();
    this.resultHeaders.clear();
  }

  @action
  async processFile({ options = this.parseOptions, ...rest }) {
    this.setStatus('loading');

    try {
      await super.processFile({ options, ...rest });

      this.setStatus('loaded');
    } catch (err) {
      this.log.error('Failed to process file', err);

      this.setStatus('error');

      throw err;
    }
  }

  @action
  async parse({ rows }) {
    let parsedHeaders = [];
    const casedHeaders = _.map(rows[0], _.toLower);
    const indices = _.reduce(
      this.knownHeaders,
      (acc, header) => {
        acc[header] = _.indexOf(casedHeaders, _.toLower(header));
        return acc;
      },
      {},
    );

    this.log.debug('Loaded Table Indices', { indices });

    const validRows = _(rows)
      .map((row, i) => {
        if (!i) return null;

        this.log.debug(row);

        return _.reduce(
          this.knownHeaders,
          (acc, colKey) => {
            const colIndex = indices[colKey];

            if (colIndex >= 0) {
              acc[colKey] = row[colIndex];
            }

            return acc;
          },
          {},
        );
      })
      .reject((rowObj) => _.isEmpty(_.omitBy(rowObj, _.isEmpty)))
      .map((row) => {
        parsedHeaders = _.union(parsedHeaders, _.keys(row));
        return row;
      })
      .compact()
      .value();

    this.parsedHeaders = parsedHeaders;

    this.setStatus('Evaluating');

    // eslint-disable-next-line no-console
    console.table(validRows);

    runInAction(() => {
      this.list.replace(validRows);
    });

    this.setStatus('Parsed all Rows');
  }

  @action
  async save() {
    this.setStatus('saving');
    this.setSaving(true);

    dispatch('audit.create', {
      data: {
        action: 'Upload Rating',
        extra: {
          file: _.pick(this.file, ['name', 'type', 'size']),
          serviceName: 'assignments',
        },
      },
    });

    try {
      await Promise.each(
        this.list,
        async (data) => {
          try {
            const existing = await dispatch(
              'assignments.get',
              data.assignmentUUID,
            );
            if (
              data.rating &&
              existing.company === this.company.uuid &&
              _.toNumber(data.rating) !== existing.workerRating
            ) {
              await dispatch('assignments.rate', {
                assignment: existing,
                notes: '',
                rating: data.rating,
                worker: existing.user,
              });
              runInAction(() => {
                _.set(data, 'updateStatus', 'updated');
                this.saveResults.updated.push(data);
              });
            } else {
              runInAction(() => {
                _.set(data, 'updateStatus', 'ignored');
                this.saveResults.ignored.push(data);
              });
            }
          } catch (err) {
            this.log.error(`Failed to save assignments record`, err);
            runInAction(() => {
              this.saveResults.invalid.push(_.extend(data, { err }));
            });
          }
        },
        { concurrency: 1 },
      );

      this.setStatus('saved');
    } catch (err) {
      this.log.error(`Failed to save assignments records`, err);

      dispatch('ui.snackBar.error', 'Sorry, something went wrong');
      this.setStatus('error');
    }

    this.setSaving(false);
  }
}
